<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliates">
        <div class="flex flex-row">
          <div class="flex flex-col w-1/4">
            <div class="flex">
              <div class="w-2/3 mr-4">
                <gd-dropdown
                  v-model="selectedFunnel"
                  size="full"
                  :placeholder="$t('frontend_funnels')"
                  :options="funnelOptions"
                  @change="changedSearchOrFilter"
                />
              </div>
              <div class="flex">
                <gd-dropdown
                  v-model="selectedRowSize"
                  size="small"
                  :options="rowSizeOptions"
                  @change="onPageSizeChanged()"
                />
                <label class="mt-2">{{ $t('frontend_rows') }}</label>
              </div>
            </div>
          </div>
          <div class="w-1/2"></div>
          <div class="flex flex-col w-1/4">
            <div class="flex">
              <text-input
                v-model="searchPhrase"
                v-debounce:1s="changedSearchOrFilter"
                value="searchPhrase"
                :placeholder="$t('frontend_search')"
                :debounce-events="['input', 'keydown']"
              />
            </div>
          </div>
        </div>

        <div id="affiliates-list-view" class="mt-4">
          <ag-grid-vue
            class="ag-theme-material"
            style="width: 100%;"
            :dom-layout="domLayout"
            :grid-options="gridOptions"
            :column-defs="affiliatesTableColumnDefs"
            :pagination="true"
            :row-height="affiliatesTableRowHeight"
            :row-buffer="rowBuffer"
            :row-model-type="rowModelType"
            :datasource="dataSource"
            :pagination-page-size="paginationPageSize"
            :cache-overflow-size="cacheOverflowSize"
            :infinite-initial-row-count="infiniteInitialRowCount"
            :max-blocks-in-cache="maxBlocksInCache"
            :cache-block-size="cacheBlockSize"
            :suppress-row-click-selection="true"
            :suppress-cell-selection="true"
            :row-class="rowClass"
            :row-class-rules="rowClassRules"
            :context="context"
            :framework-components="frameworkComponents"
            :row-selection="rowSelection"
            :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
            @grid-ready="onGridReady"
            @model-updated="onModelUpdated"
            @row-selected="onRowSelected"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import Affiliate from '@/models/Affiliate';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import AffiliateFullNameRenderer from '@/renderers/affiliateFullNameRenderer';
import AffiliateSalesRenderer from '@/renderers/affiliateSalesRenderer';
import AffiliateRefundsRenderer from '@/renderers/affiliateRefundsRenderer';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Affiliates' };
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      referredBy: this.$route.query.referred_by,
      gridOptions: null,
      gridApi: null,
      dataSource: null,
      affiliates: [],
      paginationPageSize: 25,
      cacheBlockSize: 25,
      affiliatesTableRowHeight: 40,
      rowBuffer: 0,
      rowModelType: 'infinite',
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2,
      domLayout: 'autoHeight',
      affiliatesTableColumnDefs: null,
      searchPhrase: '',
      rowClassRules: {},
      rowClass: 'affiliates-row',
      selectedRowSize: 25,
      context: null,
      frameworkComponents: null,
      rowSelection: 'multiple',
      isAllSelected: false,
      unselectedAffiliateIds: [],
      selectedRowLength: 0,
      funnels: [],
      selectedFunnel: 0,
    };
  },
  computed: {
    funnelOptions() {
      return this.funnels.map(funnel => ({ value: funnel.id, text: funnel.name }));
    },
    rowSizeOptions() {
      return [5, 10, 25, 50, 100];
    },
  },
  async beforeMount() {
    this.gridOptions = {};

    this.context = { componentParent: this };
    this.frameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer
    };

    this.affiliatesTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_sub_aff_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'name',
            colId: 'affiliateFullName',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_clicks'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_impression'),
            field: 'impressions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_uniques'),
            field: 'uniques',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_conversions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_signups'),
            field: 'total_optins',
            colId: 'affiliateOptins',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_sales'),
            field: 'total_sales',
            colId: 'affiliateSales',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_total_sales_amount'),
            field: 'human_readable_total_sales_amount',
            colId: 'affiliateSalesAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_refunds'),
            field: 'total_refunds',
            colId: 'affiliateRefunds',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_total_refunds_amount'),
            field: 'human_readable_total_refunds_amount',
            colId: 'affiliateRefundsAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_groovesell_comissions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned_comissions'),
            field: 'human_readable_total_commissions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid_commisions'),
            field: 'human_readable_pending_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_due_commisions'),
            field: 'human_readable_processed_without_advances',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_vendors_being_processed'),
            field: 'human_readable_in_process_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_vendors_amount_paid'),
            field: 'human_readable_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_comissions_on_hold'),
            field: 'human_readable_on_hold_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_vendors_alltime_earned'),
            field: 'human_readable_alltime_earned_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_vendors_alltime_paid'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_vendors_alltime_cash'),
            field: 'human_readable_commission_advance',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_affiliate_all_time_claw_backs'),
            field: 'human_readable_clawbacks',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_affiliate_index_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_affiliate_index_email'),
            field: 'email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc']
          }
        ]
      }
    ];
  },
  async created() {
    this.$wait.start('affiliates');
    const funnels = await Funnel.$get();

    if (funnels.length > 0) {
      this.selectedFunnel = funnels[0].id;
    }

    this.$wait.end('affiliates');
  },
  methods: {
    onModelUpdated() {
      if (this.isAllSelected) {
        this.gridApi.forEachNode(rowNode => {
          if (!this.unselectedAffiliateIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },
    onRowSelected(event) {
      if (
        !event.node.selected &&
        this.unselectedAffiliateIds.indexOf(event.node.data.id) === -1
      ) {
        this.unselectedAffiliateIds.push(event.node.data.id);
      } else {
        this.unselectedAffiliateIds = this.unselectedAffiliateIds.filter(
          item => item !== event.node.data.id
        );
      }
    },
    onPageSizeChanged() {
      this.gridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.selectedRowSize
      );
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
    },
    debouncedSearchOrFilterCaller: _.debounce(function() {
      this.changedSearchOrFilter();
    }, 1000),
    async changedSearchOrFilter() {
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function(params) {
        self.$loader.start('getDataSource');

        const affiliatesData = await Affiliate.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .custom('affiliates/second-tier-affiliates')
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            search: self.searchPhrase,
            funnels: self.selectedFunnel,
            sortBy: params.sortModel,
            referredBy: self.referredBy
          })
          .get();

        let rowsThisPage = affiliatesData.data || [];
        const totalNumberOfRows = affiliatesData.meta.total;
        self.funnels = affiliatesData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
  }
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>
<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#second-tier-affiliates-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
