<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_funnel') }}
          </label>
          <gd-dropdown
            v-model="selectedFunnel"
            size="full"
            :options="funnelOptions"
            :placeholder="$t('frontend_groovesell_contracts_step2_select_funnel')"
          />
        </div>
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_product') }}
          </label>
          <gd-dropdown
            v-model="selectedProduct"
            size="full"
            :options="productOptions"
            :placeholder="$t('frontend_groovesell_select_product')"
          />
        </div>
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_price_point') }}
          </label>
          <gd-dropdown
            v-model="selectedPricePoint"
            size="full"
            :options="pricePointOptions"
            :placeholder="$t('frontend_groovesell_select_price_point')"
          />
        </div>
      </div>
    </v-wait>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'Step2',
  components: {
  },
  props: {
    model: {
      required: true,
    },
    funnels: {
      required: true,
    },
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      localModel: this.model,
      products: null,
      pricePoints: null,
      selectedFunnel: 0,
      selectedProduct: 0,
      selectedPricePoint: 0,
      isProductFirstCall: true,
      isPricePointFirstCall: true,
    };
  },
  computed: {
    funnelOptions() {
      if (!this.funnels) return [];
      return this.funnels.map(funnel => ({ value: funnel.id, text: funnel.name }));
    },
    productOptions() {
      if (!this.products) {
        return [
          { value: 0, text: this.$t('frontend_groovesell_any_product') }
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_groovesell_any_product') },
        ...this.products.map((product) => {
          return { value: product.id, text: product.name };
        }),
      ];
    },
    pricePointOptions() {
      if (!this.pricePoints) {
        return [
          { value: 0, text: this.$t('frontend_groovesell_any_price_point') }
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_groovesell_any_price_point') },
        ...this.pricePoints.map((pricePoint) => {
          return { value: pricePoint.id, text: pricePoint.name };
        }),
      ];
    },
  },
  validations: {
    localModel: {
      funnel_id: {
        required,
      },
      product_id: {
        required,
      },
      price_point_id: {
        required,
      },
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
    selectedFunnel() {
      if (this.selectedFunnel == '0' || this.selectedFunnel == '') {
        this.products == null;
        this.pricePoints = null;
        this.localModel.funnel_id = 0;
      } else {
        this.$loader.start('selectedFunnel');

        let selectedFunnel = this.funnels.find(
          (funnel) => funnel['id'] === this.selectedFunnel
        );

        this.products = selectedFunnel['products'];
        this.localModel.funnel_id = this.selectedFunnel;

        if(!this.isProductFirstCall) {
          this.resetProductSelections();
        }

        this.isProductFirstCall = false;

        this.$loader.end('selectedFunnel');
      }
    },
    selectedProduct() {
      if (this.selectedProduct == '0' || this.selectedProduct == '') {
        this.pricePoints == null;
        this.localModel.product_id = 0;
        this.resetPricePointSelections();
      } else {
        this.$loader.start('selectedProduct');

        let selectedFunnel = this.funnels.find(
          (funnel) => funnel['id'] === this.selectedFunnel
        );
        let selectedProduct = selectedFunnel['products'].find(
          (product) => product['id'] === this.selectedProduct
        );
        this.localModel.product_id = this.selectedProduct;
        this.pricePoints = selectedProduct['price_points'];

        if (!this.isPricePointFirstCall) {
          this.resetPricePointSelections();
        }

        this.isPricePointFirstCall = false;

        this.$loader.end('selectedProduct');
      }
    },
    selectedPricePoint() {
      this.$loader.start('selectedPricePoint');
      this.localModel.price_point_id = this.selectedPricePoint;
      this.$loader.end('selectedPricePoint');
    },
  },
  async created() {
    this.initializeSelectedValues();
  },
  methods: {
    resetProductSelections() {
      this.pricePoints = null;
      this.localModel.product_id = 0;
      this.selectedProduct = 0;
      this.resetPricePointSelections();
    },
    resetPricePointSelections() {
      this.localModel.price_point_id = 0;
      this.selectedPricePoint = 0;
    },
    initializeSelectedValues() {
      this.selectedFunnel = this.localModel.funnel_id;
      this.selectedProduct = this.localModel.product_id;
      this.selectedPricePoint = this.localModel.price_point_id;
    },
    showContractExistError() {
      this.alertProps = {
        show: true,
        variant: 'error',
        title: this.$t('frontend_error'),
        message: this.$t('frontend_groovesell_contract_swal_conflicting_contract'),
      };
    },
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
