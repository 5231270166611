<template>
    <div>
        <gd-modal name="transaction-detail-modal" :title="transactionDetailsModalTitle" width="80%" bg-type="regular">
            <div v-if="selectedTransaction" class="flex">
                <div class="flex flex-col w-3/4 px-4" style="min-height: 60vh">
                    <gd-tabs :tabs="transactionDetailTabs" :current-tab="transactionDetailsTab" :highlight-tabs="highLightTabs"
                        highlight-text="Fraud Alert" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                        :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
                        :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                        :line-class="'gs-default-tabs-active-line'" @onClick="handleTransactionsDetailTabChange" />
                    <div class="mt-2">
                        <transaction v-if="transactionDetailsTab === 'transaction'"
                            :selectedTransaction="selectedTransaction" :authenticatedUser="authenticatedUser" />
                        <customer v-else-if="transactionDetailsTab === 'customer'" :selectedTransaction="selectedTransaction" />
                        <custom-fields v-else-if="transactionDetailsTab === 'custom_fields'"
                            :selectedTransaction="selectedTransaction" />
                        <affiliate v-else-if="transactionDetailsTab === 'affiliate'"
                            :selectedTransaction="selectedTransaction" />
                        <retries v-else-if="transactionDetailsTab === 'retries'" :selectedTransaction="selectedTransaction" />

                    </div>
                </div>
                <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
                    <div class="my-auto flex flex-col">
                        <gd-button v-if="!selectedTransaction.is_partially_refunded &&
                            selectedTransaction.gateway.toLowerCase() == 'paypal' &&
                            selectedTransaction.total_amount > 0 &&
                            selectedTransaction.status == 'captured' &&
                            !selectedTransaction.is_refunded &&
                            !selectedTransaction.gateway_transaction_id.startsWith('I-')" size="auto" variant="primary"
                            class="mb-4" @click="refundTransaction">{{ $t('frontend_analytics_refund_transaction')
                            }}</gd-button>

                        <gd-button v-else-if="selectedTransaction.total_amount > 0 &&
                            selectedTransaction.status == 'captured' &&
                            !selectedTransaction.is_refunded &&
                            !selectedTransaction.is_partially_refunded" size="auto" variant="primary" class="mb-4"
                            @click="refundTransaction">{{ $t('frontend_analytics_refund_transaction') }}</gd-button>
                        <gd-button v-else size="auto" disabled class="mb-4">{{
                            $t('frontend_analytics_refunded_transaction')
                        }}</gd-button>
                            <gd-button v-if="
                                selectedTransaction.total_amount > 0 &&
                                selectedTransaction.status == 'captured' &&
                                !selectedTransaction.is_refunded &&
                                !selectedTransaction.is_partially_refunded &&
                                !selectedTransaction.gateway_transaction_id.startsWith('I-')" size="auto" variant="primary"
                            class="mb-4" @click="refundTransaction('partial_refund')">{{
                                $t('frontend_analytics_refund_transaction_partially') }}</gd-button>

                        <gd-button v-if="selectedTransaction.status == 'captured' &&
                            selectedTransaction.is_active &&
                            selectedTransaction.payments_left &&
                            selectedTransaction.payments_left != 'Subscription ended'" size="auto" variant="primary" class="mb-4"
                            @click="openConfirmModal('cancelTransaction')">{{ $t('frontend_analytics_cancel_future_payments') }}</gd-button>
                        <gd-button v-else size="auto" disabled class="mb-4">{{
                            $t('frontend_analytics_cancel_future_payments')
                        }}</gd-button>

                        <gd-button v-if="selectedTransaction.suspected_affiliate_fraud == 1" size="auto" variant="primary"
                            class="mb-4" @click="openConfirmModal('unmarkAffiliateFraudTransaction')">{{
                                $t('frontend_analytics_mark_as_not_affiliate')
                            }}</gd-button>

                        <gd-button v-if="selectedTransaction.affiliate_id" size="auto" variant="primary" class="mb-4"
                            @click="openConfirmModal('removeAffiliateFromTransaction')">{{ $t('frontend_analytics_remove_affiliate') }}</gd-button>

                        <gd-button v-if="selectedTransaction.total_amount > 0 &&
                            selectedTransaction.status == 'failed' &&
                            !selectedTransaction.is_refunded &&
                            selectedTransaction.is_active" size="auto" variant="primary" class="mb-4"
                            @click="openConfirmModal('retryTransaction')">{{ $t('frontend_analytics_retry_transaction') }}</gd-button>

                        <gd-button v-if="selectedTransaction.status == 'captured'" size="auto" variant="primary" class="mb-4"
                            @click="downloadTransactionInvoice">{{ $t('frontend_analytics_download_invoice') }}</gd-button>

                        <gd-button v-if="selectedTransaction.assisted_affiliate_id" size="auto" variant="primary" class="mb-4"
                            @click="openConfirmModal('removeAssist')">{{ $t('frontend_analytics_remove_assist') }}</gd-button>
                        <div v-if="selectedTransaction.refunded_note" class="flex flex-col w-full mt-8 bg-grey-100 p-1">
                            <label class="font-semibold text-sm text-grey-700">
                                <info-icon class="h-4 w-4 fill-current" />
                                {{ selectedTransaction.refunded_note }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </gd-modal>
        <confirm-modal
            name="confirm-transaction-detail-modal"
            :message-content="messageContent"
            :button-text="buttonText"
            button-size="auto"
            @confirm="applyAction"
        />
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Transaction from '../tabs/Transaction';
import Customer from '../tabs/Customer';
import Affiliate from '../tabs/Affiliate';
import CustomFields from '../tabs/CustomFields';
import Retries from '../tabs/Retries';
import createLinkMixin from '@/mixins/createLinkMixin';
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
    components: {
        Transaction,
        Customer,
        Affiliate,
        CustomFields,
        Retries,
        InfoIcon,
    },
    mixins: [createLinkMixin],
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
        highLightTabs: {
            required: true,
        },
        reloadData: {
            required: true,
            type: Function,
        },
        authenticatedUser: {
            default: null,
        },
    },
    watch: {
        selectedTransaction: {
            handler() {
                this.transactionDetailsModalTitle = this.selectedTransaction?.transaction_id;
            },
            deep: true,
        },
    },
    data() {
        return {
            transactionDetailsModalTitle: this.selectedTransaction?.transaction_id,
            transactionDetailsTab: 'transaction',
            doNotTriggerWebhook: 0,
            chargeBackAffiliate: 0,
            actionType: '',
            messageContent: '',
            buttonText: '',
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
        }
    },
    methods: {
        openConfirmModal(actionType) {
            this.actionType = actionType;

            switch (actionType) {
                case 'cancelTransaction':
                    this.messageContent = this.$t('frontend_analytics_future_transactions_canceled');
                    this.buttonText = this.$t('frontend_analytics_future_transactions_canceled_confirm');
                    break;
                case 'unmarkAffiliateFraudTransaction':
                    this.messageContent = this.$t('frontend_analytics_transaction_not_suspicious');
                    this.buttonText = this.$t('frontend_analytics_markas_notsuspcious');
                    break;
                case 'removeAffiliateFromTransaction':
                    this.messageContent = this.$t('frontend_remove_affiliate_warning');
                    this.buttonText = this.$t('frontend_remove_affiliate_confirm');
                    break;
                case 'retryTransaction':
                    this.messageContent = this.$t('frontend_retry_transaction_warning', {appTitle: this.whitelabelAppData('sell').title});
                    this.buttonText = this.$t('frontend_retry_transaction_confirm');
                    break;
                case 'removeAssist':
                    this.messageContent = this.$t('frontend_remove_assist_warning');
                    this.buttonText = this.$t('frontend_remove_assist_confirm');
                    break;
            }

            this.$root.$emit('modal-open', 'confirm-transaction-detail-modal');
        },
        applyAction() {
            switch (this.actionType) {
                case 'cancelTransaction':
                    this.cancelTransaction();
                    break;
                case 'unmarkAffiliateFraudTransaction':
                    this.unmarkAffiliateFraudTransaction();
                    break;
                case 'removeAffiliateFromTransaction':
                    this.removeAffiliateFromTransaction();
                    break;
                case 'retryTransaction':
                    this.retryTransaction();
                    break;
                case 'removeAssist':
                    this.removeAssist();
                    break;
            }
        },
        handleTransactionsDetailTabChange(newTab) {
            this.transactionDetailsTab = newTab;
        },
        async cancelTransaction() {
            const self = this;
            this.$loader.start('cancelTransaction');
            const cancelProcessData = await axios.post('/transactions/cancel', {
                transaction_id: self.selectedTransaction.id,
            });
            await this.reloadData();

            const cancelData = cancelProcessData.data;
            const cancelStatus = cancelData.status;
            if (cancelStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_analytics_future_transactions_canceled_success'),
                    message: this.$t('frontend_analytics_future_transactions_canceled_success_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else if (cancelStatus == 'manual') {
                this.alertProps = {
                    show: true,
                    variant: 'info',
                    title: this.$t('frontend_analytics_manual_cancelation_needed'),
                    message: cancelData.message,
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_analytics_future_transactions_canceled_failed'),
                    message: this.$t('frontend_analytics_future_transactions_canceled_failed_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            }

            this.$loader.end('cancelTransaction');
        },
        async unmarkAffiliateFraudTransaction() {
            const self = this;
            this.$loader.start('unmarkAffiliateFraudTransaction');
            const notSuspiciousResponseData = await axios.post(
                '/transactions/mark-not-suspicious',
                {
                    transaction_id: self.selectedTransaction.id,
                }
            );
            await this.reloadData();

            const notSuspiciousData = notSuspiciousResponseData.data;
            const notSuspiciousStatus = notSuspiciousData.status;
            if (notSuspiciousStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_record_successful'),
                    message: this.$t('frontend_marked_as_not_suspicious_successfully'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_record_failed'),
                    message: this.$t('frontend_unable_to_mark_transaction_not_suspicious'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            }

            this.$loader.end('unmarkAffiliateFraudTransaction');
        },
        async removeAffiliateFromTransaction() {
            const self = this;
            this.$loader.start('removeAffiliateFromTransaction');
            const removeAffiliateResponseData = await axios.post(
                '/transactions/remove-affiliate',
                {
                    transaction_id: self.selectedTransaction.id,
                }
            );
            await this.reloadData();

            const removeAffiliateData = removeAffiliateResponseData.data;
            const removeAffiliateStatus = removeAffiliateData.status;
            if (removeAffiliateStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_affiliate_removal_successful_title'),
                    message: this.$t('frontend_affiliate_removal_successful_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_affiliate_removal_failed_title'),
                    message: this.$t('frontend_affiliate_removal_failed_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            }

            this.$loader.end('removeAffiliateFromTransaction');
        },
        async retryTransaction() {
            const self = this;
            this.$loader.start('retryTransaction');
            const manualRetryProcessData = await axios.post(
                '/transactions/manual-retry',
                {
                    transaction_id: self.selectedTransaction.id,
                }
            );
            await this.reloadData();

            const manualRetryData = manualRetryProcessData.data;
            const manualRetryStatus = manualRetryData.status;
            if (manualRetryStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_dunning_attempt_successful_title'),
                    message: this.$t('frontend_dunning_attempt_successful_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_dunning_attempt_unsuccessful_title'),
                    message: this.$t('frontend_dunning_attempt_unsuccessful_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            }

            this.$loader.end('retryTransaction');
        },
        async downloadTransactionInvoice() {
            this.$loader.start('downloadTransactionInvoice');
            const paramsData = { transaction_id: this.selectedTransaction.id };
            await this.createOpenLink('transactions/generate-invoice-download-link', paramsData);
            this.$loader.end('downloadTransactionInvoice');

        },
        async removeAssist() {
            const self = this;
            this.$loader.start('removeAssist');
            const assistProcessData = await axios.post(
                '/transactions/remove-assist',
                {
                    transaction_id: self.selectedTransaction.id,
                }
            );
            await this.reloadData();

            const assistData = assistProcessData.data;
            const assistStatus = assistData.status;
            if (assistStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_removal_of_assist_successful_title'),
                    message: this.$t('frontend_removal_of_assist_successful_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_removal_of_assist_failed_title'),
                    message: this.$t('frontend_removal_of_assist_failed_message'),
                };
                this.$root.$emit('modal-close', 'transaction-detail-modal');
            }

            this.$loader.end('removeAssist');
        },
        async refundTransactionStep1(type) {
            const result = await Swal.fire({
                title: this.$t(`frontend_analytics_${type}_transactions`),
                html: this.$t(`frontend_analytics_${type}_transactions_text`),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: this.$t(`frontend_process_${type}_with`) + this.selectedTransaction.gateway,
                denyButtonText: this.$t(`frontend_analytics_mark_${type}`),
                cancelButtonText: 'Cancel',
                showCloseButton: true,

            });

            const processGateway = result.isConfirmed ? true : false;
            const cancelRefund = result.isDismissed ? true : false;

            return { processGateway, cancelRefund };
        },

        async refundTransactionStep2(type, messageAlert) {
            const result = await Swal.fire({
                input: 'text',
                inputLabel: this.$t('frontend_analytics_transactions_note'),
                inputPlaceholder: this.$t('frontend_analytics_transactions_enter_note'),
                inputValidator: (value) => { },
                title: this.$t('frontend_sure'),
                text: messageAlert,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t(`frontend_confirm_${type}_transaction`),
            });

            const textnote = result.value;
            const cancelRefund = result.isDismissed ? true : false;

            return { textnote, cancelRefund };
        },

        async refundTransactionStep3(type) {
            if (type != 'partial_refund') {
                return { refundedValue: 0, cancelRefund: false };
            }

            const result = await Swal.fire({
                title: this.$t('frontend_partial_refund_amount'),
                input: 'text',
                inputPlaceholder: this.$t('frontend_enter_amount'),
                inputValidator: (value) => {
                    const numberRegex =
                        /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
                    if (!value) {
                        return this.$t('frontend_analytics_enter_valid_number');
                    }
                    if (!numberRegex.test(value)) {
                        return this.$t('frontend_analytics_enter_valid_number');
                    }
                    if (value <= 0) {
                        return this.$t('frontend_analytics_enter_valid_number');
                    }
                    if (value > this.selectedTransaction.total_amount / 100) {
                        return this.$t('frontend_analytics_enter_correct_amount');
                    }
                },
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('frontend_confirm_partial_refund_transaction'),
                onOpen: () => {
                    const sp1 = Swal.getContent().querySelector('.swal2-input');
                    const sp2 = Swal.getContent().querySelector('#swal2-content');
                    const parentDiv = sp2.parentNode;
                    parentDiv.insertBefore(sp1, sp2);
                },
                customClass: {
                    title: 'refundSwalTitle',
                },
            });

            const refundAmount = result.value;
            const cancelRefund = result.isDismissed ? true : false;

            return { refundAmount, cancelRefund };
        },

        async refundTransaction(type = "refund") {
            let accept;
            let processGateway;
            let textnote;
            let cancelRefund;
            let refundAmount;

            ({ processGateway, cancelRefund } = await this.refundTransactionStep1(type));

            if (cancelRefund) {
                return;
            }

            ({ refundAmount, cancelRefund } = await this.refundTransactionStep3(type));

            if (cancelRefund) {
                return;
            }

            const messageAlert = processGateway ? this.$t('frontend_confirm_refund_with_gateway', {
                amount: type == 'refund' ? this.selectedTransaction.human_readable_price : refundAmount,
                gateway: this.selectedTransaction.gateway
            }) : this.$t(`frontend_confirm_mark_transaction_${type}`);

            ({ textnote, cancelRefund } = await this.refundTransactionStep2(type, messageAlert));

            if (cancelRefund) {
                return;
            }

            let queue = [{
                title: this.$t('frontend_sure'),
                text: this.$t('frontend_analytics_refund_warning'),
                confirmButtonText: this.$t(`frontend_confirm_${type}_transaction`),
            }];

            if (this.selectedTransaction.is_refund_period_over) {
                queue.unshift({
                    title: '<span style="color: #dd3333;">' + this.$t('frontend_analytics_exceeded') + '</span>',
                    text: this.$t('frontend_analytics_exceeded_text'),
                })
            }

            ({ value: accept } = await Swal.mixin({
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('frontend_analytics_process_to_refund'),
            }).queue(queue));

            if (accept) {
                await this.fireRefundTransaction(this.selectedTransaction.id, type, processGateway, textnote, refundAmount);
            }
        },

        async fireRefundTransaction(transactionId, type, processGateway, textnote, refundAmount) {
                this.$loader.start('refundTransaction');
                const refundProcessData = await axios.post('/transactions/refund', {
                    transaction_id: transactionId,
                    process_gateway: processGateway,
                    note: textnote,
                    amount: refundAmount,
                    is_partial_refund: type == 'partial_refund',
                });

                await this.reloadData();

                const refundData = refundProcessData.data;
                const refundStatus = refundData.status;
                if (refundStatus == 'success') {
                    this.alertProps = {
                        show: true,
                        variant: 'success',
                        title: this.$t('frontend_analytics_refund_sucess'),
                        message: this.$t('frontend_analytics_transaction_refund_sucess'),
                    };
                    this.$root.$emit('modal-close', 'transaction-detail-modal');
                } else {
                    this.alertProps = {
                        show: true,
                        variant: 'error',
                        title: this.$t('frontend_analytics_refund_failed'),
                        message: this.$t('frontend_analytics_refund_failed_message') + refundData.message,
                    };
                    this.$root.$emit('modal-close', 'transaction-detail-modal');
                }

                this.$loader.end('refundTransaction');
        }

    },

    computed: {
        transactionDetailTabs() {
            if (this.selectedTransaction && this.selectedTransaction.has_failed_previously) {
                return [
                    { title: this.$t('frontend_analytics_transaction'), value: 'transaction' },
                    { title: this.$t('frontend_analytics_customer'), value: 'customer' },
                    { title: this.$t('frontend_analytics_affiliate'), value: 'affiliate' },
                    { title: this.$t('frontend_analytics_failed_transaction_logs'), value: 'retries' },
                ];
            }
            if (this.selectedTransaction && this.selectedTransaction.buyer_custom_fields) {
                return [
                    { title: this.$t('frontend_analytics_transaction'), value: 'transaction' },
                    { title: this.$t('frontend_analytics_customer'), value: 'customer' },
                    { title: this.$t('frontend_analytics_custom_fields'), value: 'custom_fields' },
                    { title: this.$t('frontend_analytics_affiliate'), value: 'affiliate' },
                ];
            }
            return [
                { title: this.$t('frontend_analytics_transaction'), value: 'transaction', },
                { title: this.$t('frontend_analytics_customer'), value: 'customer' },
                { title: this.$t('frontend_analytics_affiliate'), value: 'affiliate' },
            ];
        },
    }
}
</script>
