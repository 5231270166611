// CONFIGS
const themeConfig = {
  theme: 'light', // options[String]: 'light'(default), 'dark', 'semi-dark'
  appPrimaryColor: 'var(--brand-primary)', // options[Boolean]: true, false(default)
  navbarColor: '#fff', // options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'hidden', // options[String]: floating(default) / static / sticky / hidden
  footerType: 'static', // options[String]: static(default) / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  waitSpinnerColor: '#3d4852'
  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};

export default themeConfig;
