<template>
  <gd-modal
    name="embed-widget-code-modal"
    :title="$t('frontend_groovesell_promotional_tools_widgets')"
  >
    <div class="flex flex-col m-10">
      <div class="flex flex-col w-full mb-4">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_resource_index_embed_code') }}
        </label>
        <text-area
          :readonly="true"
          :value="selectedFunnelEmbedCode"
          :placeholder="$t('frontend_resource_index_embed_code')"
          :select-all="true"
        />
      </div>
      <div class="flex flex-row w-full mb-6 justify-end">
        <gd-button
          v-clipboard:copy="selectedFunnelEmbedCode"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          size="auto"
          variant="primary"
        >
          {{ $t('frontend_resource_index_copy_to_clipboard') }}
        </gd-button
        >
      </div>
    </div>
  </gd-modal>
</template>

<script>
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
  props: {
    funnel: {
      type: Object,
      default: () => {},
    },
    selectedPermalink: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedFunnelEmbedCode() {
      if (this.funnel && this.funnel.slug) {
        const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
        const sellAppTitle = this.whitelabelAppData('sell').title.toLowerCase();
        console.log('siteAppurl', siteAppurl);
        return `<div style="width:100%; min-height:220px;">
                  <link href="https://${this.funnel.slug}.${siteAppurl}/promoembed/css/app.css?n=1" rel="stylesheet">
                  <${sellAppTitle}-promo-tools permalink="${this.selectedPermalink}"></${sellAppTitle}-promo-tools>
                  <script src="https://${this.funnel.slug}.${siteAppurl}/promoembed/js/app.js?n=1"><\/script>
                </div>`;
      }

      return '';
    },
  },
  methods: {
    onCopy() {
      Notify.success(this.$t('embed_code_copied'));
    },
    onError() {
      Notify.fail(this.$t('unable_to_copy_embed_code'));
    },
  }
}
</script>
