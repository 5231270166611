<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_groovesell_coupons_step4_apply_discount_to') }}
        </label>
        <gd-dropdown
          v-model="localModel.apply_discount"
          size="full"
          :options="applyDiscountOptions"
          :placeholder="$t('frontend_groovesell_coupons_step4_apply_discount_to')"
        />
      </div>

      <div
        v-if="localModel.apply_discount == 3"
        class="flex flex-col w-1/2 mb-6"
      >
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_groovesell_coupons_step4_number_of_payments') }}
        </label>
        <text-input
          v-model="localModel.no_of_payments"
          :validator="$v.localModel.no_of_payments"
          :attribute="$t('frontend_groovesell_coupons_step4_number_of_payments')"
          value="localModel.no_of_payments"
          :placeholder="$t('frontend_groovesell_coupons_step4_number_of_payments')"
        />
      </div>

      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_groovesell_coupons_step2_discount_type') }}
        </label>
        <gd-dropdown
          v-model="localModel.expiration_rule"
          size="full"
          :options="expirationRuleOptions"
          @change="makeExpirationEmpty"
        />
      </div>

      <div
        v-if="localModel.expiration_rule == 3"
        class="flex flex-col w-1/2 mb-6"
      >
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_groovesell_coupons_step4_sale_amount') }}
        </label>
        <text-input
          v-model="localModel.expiration"
          :validator="$v.localModel.expiration"
          :attribute="$t('frontend_groovesell_coupons_step4_sale_amount')"
          value="localModel.expiration"
          :placeholder="$t('frontend_groovesell_coupons_step4_sale_amount')"
        />
      </div>

      <div
        v-if="localModel.expiration_rule == 2"
        class="flex flex-col w-1/2 mb-6"
      >
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_date') }}
        </label>
        <date-picker
          v-model="localModel.expiration"
          variant="base"
          :validator="$v.localModel.expiration"
          :attribute="$t('frontend_groovesell_by_date')"
          value="localModel.expiration"
          :placeholder="$t('frontend_groovesell_by_date')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators';
import DatePicker from '@groovepages/gd-ui-kit/components/DateTimePicker';
import moment from 'moment';

export default {
  name: 'Step4',
  components: {
    DatePicker
  },
  props: {
    model: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  computed: {
    applyDiscountOptions() {
      return [
        { value: 1, text: this.$t('frontend_groovesell_coupons_step4_all_payments') },
        { value: 2, text: this.$t('frontend_groovesell_coupons_step4_first_payment_only') },
        { value: 3, text: this.$t('frontend_groovesell_coupons_step4_first_few_payments') }
      ];
    },
    expirationRuleOptions() {
      return [
        { value: 1, text: this.$t('frontend_groovesell_no_expiration') },
        { value: 2, text: this.$t('frontend_groovesell_by_date') },
        { value: 3, text: this.$t('frontend_groovesell_by_sales') }
      ];
    }
  },
  validations: {
    localModel: {
      no_of_payments: {
        required: requiredIf(object => {
          return object.apply_discount == 3;
        }),
        numeric,
        isValidNumberOfPayments(value, object) {
          if (object.apply_discount == 3 && value < 1) {
            return false;
          }

          return true;
        }
      },
      expiration: {
        required: requiredIf(object => {
          return object.expiration_rule == 2 || object.expiration_rule == 3;
        }),
        isValidExpiration(value, object) {
          const rule = object.expiration_rule;

          if (rule == '1') {
            return true;
          }

          if (rule == '2') {
            return moment(value, moment.ISO_8601, true).isValid();
          }

          if (rule == '3') {
            return /^\d+$/.test(value);
          }

          return false;
        }
      }
    }
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    makeExpirationEmpty() {
      this.localModel.expiration = '';
    }
  }
};
</script>
