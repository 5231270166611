import FunnelProducts from '@/pages/products/index';
import CreateProduct from '@/pages/products/create';
import DefaultCheckoutLink from '@/pages/products/checkout_links/default_checkout_link';
import ProductCheckoutLinks from '@/pages/products/checkout_links/index';
import CreateBump from '@/pages/bumps/create';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/funnels/:id/products',
    name: 'products',
    component: FunnelProducts,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_products')
    }
  },

  {
    path: '/funnels/:id/products/create',
    name: 'create_product',
    component: CreateProduct,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_add_new_product')
    }
  },
  {
    path: '/funnels/:id/products/create/:product_id',
    name: 'edit_product',
    component: CreateProduct,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_product')
    }
  },
  {
    path: '/checkout-links',
    name: 'default_checkout_links',
    component: DefaultCheckoutLink,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_checkout_links')
    }
  },
  {
    path: '/funnels/:id/products/:product_id/checkout-links',
    name: 'productsCheckoutLinks',
    component: ProductCheckoutLinks,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_products_checkout_links')
    }
  },
  {
    path: '/funnels/:id/bumps/create',
    name: 'create_bump',
    component: CreateBump,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_add_new_atc_bump')
    }
  },
  {
    path: '/funnels/:id/bumps/create/:product_id',
    name: 'edit_bump',
    component: CreateBump,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_atc_bump')
    }
  },
];
