<template>
    <gd-modal name="secondtier-affiliates-modal" :title="$t('frontend_groovesell_routes_second_tier_affiliates')"
        width="1080px" height="80%" :no-overflow="true" bg-type="regular">
        <div class="flex flex-col m-6 mb-4">
            <div v-if="affiliateAffiliates && affiliateAffiliates.length > 0">
                <div id="second-tier-affiliates-list-view" class="-mx-6 flex flex-row">
                    <v-client-table :data="affiliateAffiliates" :columns="secondTierAffiliatesColumns"
                        :options="secondTierAffiliatesTableOptions" />
                </div>
            </div>
            <div v-else class="p-4">
                {{ $t('frontend_affiliate_second_tier_no_sec_tier_aff') }}
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        affiliateAffiliates: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            secondTierAffiliatesColumns: ['name', 'email'],
            secondTierAffiliatesTableOptions: {
                headings: {},
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
        }
    }
}
</script>