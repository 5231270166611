<template>
  <div v-if="hasVideoReviews" class="flex flex-col">
    <!-- Tabs Section -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs 
        :tabs="videoReviewsTabs"
        :current-tab="videoReviewsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleVideoReviewsTabChange', $event)"
      />
    </div>

    <!-- Video Reviews Content -->
    <div class="mt-2">
      <div v-for="(review, index) in funnelResource.video_reviews" :key="index">
        <div v-if="isActiveTab(review.id)" class="pb-8">
          <!-- Description -->
          <div class="flex flex-col w-full mb-6">
            <div class="flex flex-row w-1/2">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_affiliate_promo_description') }}
              </label>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-1/2">
                <text-area
                  v-model="review.description"
                  class="h-32 w-full"
                  :placeholder="$t('frontend_affiliate_promo_description')"
                  readonly
                />
              </div>
              <div class="w-1/4"></div>
              <div class="w-1/4 flex flex-row justify-end">
                <div>
                  <vue-goodshare-facebook
                    :page_url="review.affiliate_link"
                    :page_description="review.description"
                    :quote="review.description"
                    title_social="Share On Facebook"
                    has_icon
                  ></vue-goodshare-facebook>
                </div>
              </div>
            </div>
          </div>

          <!-- Video Embed -->
          <div class="flex flex-row">
            <div class="flex flex-col w-1/2 mb-6">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_affiliate_promo_video') }}
              </label>
              <div class="embed-container">
                <iframe :src="generateVideoIframe(review.video)"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_affiliate_promo_no_video') }}
  </div>
</template>

<script>
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue';
import urlParser from 'js-video-url-parser/lib/base';

export default {
  components: {
    VueGoodshareFacebook,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    videoReviewsTab: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasVideoReviews() {
      return this.funnelResource &&
        this.funnelResource.video_reviews &&
        this.funnelResource.video_reviews.length;
    },
    videoReviewsTabs() {
      return this.funnelResource?.video_reviews?.map(review => ({
        title: review.name,
        value: `video_review_tab_${review.id}`,
      })) || [];
    },
  },
  methods: {
    isActiveTab(reviewId) {
      return this.videoReviewsTab === `video_review_tab_${reviewId}`;
    },
    generateVideoIframe(video) {
      const videoInfo = urlParser.parse(video);

      if (videoInfo.provider == 'youtube') {
        return `https://www.youtube.com/embed/${videoInfo.id}`;
      }

      if (videoInfo.provider == 'vimeo') {
        return `https://player.vimeo.com/video/${videoInfo.id}`;
      }
    },
  },
};
</script>
