<template>
  <div>
    <gd-modal name="payment-gateway-modal" width="900px" :title="selectedIntegrationTitle
      ? selectedIntegrationTitle
      : $t('frontend_groovesell_paymeny_gateway_list')
      ">
      <div class="overflow-y-auto custom-scrollbars scroll-modal">
        <div class="px-6 py-6">
          <div class="flex flex-wrap" v-if="!showIntegrationForm">
            <div v-for="(integration, index) in availableIntegrations" :key="index" class="p-2" :class="integration.name === 'groove_pay' ||
              integration.name === 'groovepay_automated'
              ? 'w-full'
              : 'w-1/4'
              ">
              <div v-if="
                integration.name === 'groove_pay' ||
                integration.name === 'groovepay_automated'
              ">
                <div class="
                      flex
                      border border-grey-300
                      rounded
                      p-4
                      text-grey-darkest
                      cursor-pointer
                      relative
                    " @click="
                      handleIntegrationClick(
                        integration.fields,
                        integration.name,
                        integration.display_name
                      )
                      ">
                  <div class="w-32">
                    <img src="@/assets/images/logo/groovepay-integration.png" alt="" />
                    <div class="flex w-full justify-center mt-4">
                      <gd-button size="auto" variant="primary" @click="openGroovepay">Signup</gd-button>
                    </div>
                  </div>
                  <div class="flex flex-col flex-1 ml-4">
                    <p class="text-lg font-bold mb-2">
                      {{ $t('frontend_product_step3_groovesell_is_best', {
                        appTitle: whitelabelAppData('sell').title, payAppTitle:
                          whitelabelAppData('pay').title
                      }) }}
                    </p>
                    <p class="text-sm mb-2">
                      <span class="font-bold">As Low As 2.85%</span><span class="text-xs">+ $0.25 per transaction for
                        USA</span>
                    </p>
                    <p class="text-sm font-bold mb-2">
                      The best and most affordable way to accept credit cards
                    </p>
                    <p class="text-sm mb-2">
                      {{ $t('groovepay_has_no', { appTitle: whitelabelAppData('pay').title }) }}
                    </p>
                  </div>
                  <div class="absolute top-0 right-0 -mt-6 -mr-6">
                    <img src="@/assets/images/icons/recommended-icon.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="h-full relative" v-if="
                integration.name !== 'groove_pay' &&
                integration.name !== 'groovepay_automated'
              ">
                <div class="
                    flex flex-col
                    h-full
                    items-center
                    border border-grey-300
                    rounded
                    overflow-hidden
                    p-4
                    text-grey-darkest
                  " :class="isGroovester(integration.groove_only)
                    ? 'cursor-pointer'
                    : 'pointer-events-none'
                    " @click="
                      handleIntegrationClick(
                        integration.fields,
                        integration.name,
                        integration.display_name
                      )
                      ">
                  <div>
                    <paypal-logo-icon v-if="integration.name == 'paypal' || integration.name == 'paypal_commerce'"
                      class="h-16" />
                    <stripe-logo-icon v-else-if="integration.name == 'stripe'" class="h-16" />
                    <img v-else
                      :src="'https://assets.grooveapps.com/payment-gateway/' + integration.name + '-integration.png'"
                      alt="" class="h-16" />
                  </div>
                  <div class="mt-4">
                    {{ integration.display_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-2" v-if="showIntegrationForm">
            <div v-if="
              selectedIntegrationType == 'braintree' ||
              selectedIntegrationType == 'authorize.net'
            " class="
                mb-4
                bg-grey-100
                text-sm
                font-semibold
                text-grey-700
                p-2
              ">
              <info-icon class="w-4 h-4 fill-current inline" />
              <div class="inline">
                Note : For Braintree and Authorize.net payment gateway the
                currency of integration account should match with the funnel
                currency.
              </div>
            </div>
            <div v-if="
              selectedIntegrationType == 'nmi' ||
              selectedIntegrationType == 'paypal'
            " class="
                mb-4
                bg-grey-100
                text-sm
                font-semibold
                text-grey-700
                p-2
              ">
              <info-icon class="w-4 h-4 fill-current inline" />
              <div class="inline">
                Note : For NMI and Paypal we only support USD make sure the
                funnel currency is in USD .
              </div>
            </div>
            <div class="flex flex-col mb-4">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step3_name')
              }}</label>
              <text-input v-model="selectedIntegrationName" :validator="$v.selectedIntegrationName"
                :attribute="$t('frontend_product_step3_name')" class="text-dark text-sm"
                :placeholder="$t('frontend_product_step3_name')" />
            </div>
            <div v-for="(field, index) in formfields" :key="index" class="flex flex-col mb-4">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ formfields[index].label }}
              </label>
              <span class="text-xs mb-1 text-grey" v-if="formfields[index].info">
                {{ formfields[index].info }}
              </span>
              <text-input v-model="formfields[index].value" :validator="$v.formfields.$each[index].value"
                :attribute="formfields[index].label" class="text-dark text-sm" value="formfields[index].value"
                :placeholder="formfields[index].label" />
            </div>
            <div class="flex justify-end">
              <gd-button size="auto" @click="backToGateway">{{ $t('frontend_groovesell_back_to_list') }}</gd-button>
              <gd-button class="ml-4" size="auto" variant="primary" @click="registerIntegration">{{
                $t('frontend_product_step3_create') }}</gd-button>
            </div>
          </div>
        </div>
      </div>
    </gd-modal>
  </div>
</template>

<script>
import axios from 'axios';
import Auth from '@groovepages/gd-lib/auth';
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators';
import Integration from '@/models/Integration';
import createLinkMixin from '@/mixins/createLinkMixin';
import { userRegion } from '@/utils';
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';
import StripeLogoIcon from '@/assets/images/logo/StripeLogoIcon.svg';
import PaypalLogoIcon from '@/assets/images/logo/PaypalLogoIcon.svg';

export default {
  name: 'PaymentGateway',
  mixins: [createLinkMixin],
  components: {
    InfoIcon,
    StripeLogoIcon,
    PaypalLogoIcon,
  },
  props: {
    localGatewayList: {
      required: true,
    },
  },
  data() {
    return {
      userRegion,
      widthModal: '680px',
      paymentIntegrations: [],
      userData: {},
      selectedIntegrationTitle: '',
      selectedIntegrationType: '',
      selectedIntegrationName: '',
      formfields: [],
      showIntegrationForm: false,
      localGatewayIntegrations: this.localGatewayList,
    };
  },
  validations: {
    formfields: {
      $each: {
        value: {
          required,
        },
      },
    },
    selectedIntegrationName: {
      required,
      maxLength: maxLength(100),
    },
    form: {},
  },
  watch: {
    localGatewayIntegrations() {
      this.$emit('updateIntegration', this.localGatewayIntegrations);
    },
  },
  computed: {
    availableIntegrations() {
      if (this.userRegion == 'brazil') {
        return this.paymentIntegrations.filter(el => {
          return ['paypal_commerce', 'stripe'].includes(el.name)
        })
      }
      return this.paymentIntegrations.filter((el) => {
        return this.isGroovester(el.groove_only);
      });
    },

    formattedFormfields() {
      const formattedFields = {};

      for (let i = 0; i < this.formfields.length; i++) {
        formattedFields[this.formfields[i].key] = this.formfields[i].value;
      }

      return formattedFields;
    },
  },
  async created() {
    this.userData = Auth.authenticatedUser();
    this.getintegrations();
  },
  methods: {
    openGroovepay() {
      const payAppTitle = this.whitelabelAppData('pay').title.toLowerCase();
      const sellAppTitle = this.whitelabelAppData('sell').title.toLowerCase();

      this.openLink(`https://app.${payAppTitle}.com/?refId=${sellAppTitle}`, '_blank');
    },
    async getintegrations() {
      const { data } = await axios.get(
        `${window.config.apiCoreUrl}/paymentintegrationdata`
      );

      this.paymentIntegrations = data;
    },
    isGroovester(groove_only) {
      if (!groove_only) {
        return true;
      }
      const { email } = this.userData;
      const address = email.split('@').pop();
      const digitalAppTitle = this.whitelabelAppData('digital')?.title.toLowerCase() || 'groovedigital';

      return address === `${digitalAppTitle}.com`;
    },
    handleIntegrationClick(fields, integration, integration_display_name) {
      switch (integration) {
        case 'paypal_commerce':
          this.ConnectPaypalCommerce();
          break;
        default:
          this.getFields(fields, integration, integration_display_name);
      }
    },
    getFields(fields, name, display_name) {
      this.selectedIntegrationType = name;
      this.formFieldsResponse = JSON.parse(fields);
      this.formfields = [];

      for (const prop in this.formFieldsResponse) {
        if (this.formFieldsResponse.hasOwnProperty(prop)) {
          const newFormField = {};
          newFormField.key = this.formFieldsResponse[prop].key;
          newFormField.label = this.formFieldsResponse[prop].label;
          newFormField.value = '';
          if (this.formFieldsResponse[prop].info) newFormField.info = this.formFieldsResponse[prop].info;
          this.formfields.push(newFormField);
        }
      }

      this.selectedIntegrationTitle = this.$t(
        'frontend_groovesell_connect_your_account',
        { gateway: display_name }
      );
      this.showIntegrationForm = true;
      // this.$root.$emit('modal-open', 'add-integration-modal');
    },
    async registerIntegration() {
      this.$v.selectedIntegrationName.$touch();
      const isValid1 = !this.$v.selectedIntegrationName.$invalid;

      this.$v.formfields.$touch();
      const isValid2 = !this.$v.formfields.$invalid;
      if (isValid1 && isValid2) {
        this.$loader.start('registerIntegration');
        const newIntegration = new Integration({
          name: this.selectedIntegrationName,
          platform: this.selectedIntegrationType,
          details: this.formattedFormfields,
        });

        const savedIntegrationData = await newIntegration.save();
        const newlyAddedIntegrationData = await Integration.$find(
          savedIntegrationData.data.id
        );
        this.localGatewayIntegrations.push(newlyAddedIntegrationData);
        this.$root.$emit('modal-close', 'payment-gateway-modal');
        this.selectedIntegrationName = '';
        this.$loader.end('registerIntegration');
        this.backToGateway();
      }
    },
    async ConnectPaypalCommerce() {
      this.$loader.start('ConnectPaypalCommerce');
      const funnel_id = this.$route.params.id;
      const { product_id } = this.$route.params;
      this.tokenData = await axios.post('/paypal_commerce', {
        funnel_id,
        product_id,
      });

      const paypalWindow = window.open(
        this.tokenData.data.links[1].href,
        '_blank'
      );

      this.$loader.end('ConnectPaypalCommerce');
      this.$root.$emit('modal-close', 'payment-gateway-modal');
    },
    backToGateway() {
      this.showIntegrationForm = false;
      this.selectedIntegrationTitle = this.$t(
        'frontend_groovesell_paymeny_gateway_list'
      );
    },
  },
};
</script>
