<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-row justify-end">
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createNewBanner"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div
        v-if="hasBanners"
        class="flex flex-row flex-wrap"
      >
        <div
          v-for="(banner, bannerIndex) in localFunnelResource.banners"
          class="w-1/3 px-2"
        >
          <div class="flex flex-col w-full">
            <div class="w-full justify-center flex flex-row font-semibold text-md text-grey-700 mb-2">
              {{ banner.name }}
              <gd-button
                variant="outline"
                size="iconlg"
                :title="$t('frontend_add')"
                class="ml-2"
                @click="deleteBanner(bannerIndex)"
              >
                <trash-icon class="w-5 h-5 fill-current" />
              </gd-button>
            </div>
            <div class="w-full text-center">
              <img :src="banner.image" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        {{ $t('frontend_resources_you_currently_dont_have') }}
      </div>
    </div>

    <gd-modal
      name="add-new-banners-modal"
      :title="$t('frontend_resources_add_new_banner')"
      :no-overflow="true"
    >
      <div class="flex flex-col m-8">
        <div class="flex flex-col w-full mb-6">
          <div class="flex flex-col mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_name') }}
            </label>
            <text-input
              v-model="addedBannerName"
              value="addedBannerName"
              :placeholder="$t('frontend_resources_name')"
            />
          </div>

          <div class="flex flex-col mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_link_to') }}
            </label>
              <gd-dropdown
                v-model="addedBannerLandingPageId"
                size="full"
                max-height="165px"
                :options="landingPagesOptions"
              />
          </div>
          <div class="flex flex-col mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_add_banner') }}
            </label>
            <div class="w-full flex flex-row">
              <image-picker
                v-model="addedBannerImage"
                :primary="true"
              ></image-picker>
            </div>
          </div>
          <div class="flex flex-row w-full mb-6 justify-end">
            <gd-button
              v-if="canAddBanner"
              class="mb-4"
              size="auto"
              variant="primary"
              @click="addBanner"
            >
              {{ $t('frontend_resources_add_banner') }}
            </gd-button>
            <gd-button
              v-else
              size="auto"
              disabled
              class="mb-4"
            >
              {{ $t('frontend_resources_add_banner') }}
            </gd-button>
          </div>
        </div>
      </div>
    </gd-modal>
  </div>
</template>

<script>
import generateNumberMixin from '@/mixins/generateNumberMixin';
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    ImagePicker,
    TrashIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFunnelResource: this.funnelResource,
      addedBannerName: '',
      addedBannerLandingPageId: '',
      addedBannerImage: '',
    };
  },
  computed: {
    landingPagesOptions() {
      return this.landingPages.map((landingPage) => {
        return {
          value: landingPage.id,
          text: landingPage.description,
        };
      });
    },
    hasBanners() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.banners &&
        this.localFunnelResource.banners.length
      );
    },
    canAddBanner() {
      return (
        this.addedBannerImage != '' &&
        this.addedBannerLandingPageId &&
        this.addedBannerLandingPageId != '' &&
        this.addedBannerLandingPageId > 0 &&
        this.addedBannerName &&
        this.addedBannerName != ''
      );
    },
  },
  methods: {
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    createNewBanner() {
      this.addedBannerImage = '';
      this.addedBannerName = '';
      if (this.landingPages.length) {
        this.addedBannerLandingPageId = this.landingPages[0].id;
      }

      this.$root.$emit('modal-open', 'add-new-banners-modal');
    },
    addBanner() {
      const newPostId = this.generateNumber();
      this.localFunnelResource.banners.push({
        id: newPostId,
        name: this.addedBannerName,
        image: this.addedBannerImage,
        landingPage: this.addedBannerLandingPageId,
      });

      this.updateFunnelResource();
      this.$root.$emit('modal-close', 'add-new-banners-modal');
    },
    deleteBanner(index) {
      this.localFunnelResource.banners.splice(index, 1);
      this.updateFunnelResource();
    },
  }
}
</script>
