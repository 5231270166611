var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('info-panel',{staticClass:"my-4 relative"},[_c('template',{slot:"content"},[_c('div',{staticClass:"w-full h-full rounded-t-lg relative"},[(_vm.gatewayIntegration.platform == 'stripe')?_c('stripe-logo-icon',{staticClass:"w-full h-52 object-contain"}):(_vm.gatewayIntegration.platform == 'paypal' || _vm.gatewayIntegration.platform == 'paypal_commerce')?_c('paypal-logo-icon',{staticClass:"w-full h-52 object-contain"}):_c('div',{staticClass:"bg-cover bg-no-repeat bg-center h-full w-full",style:({
          backgroundImage:
            'url(' +
            require('@/assets/images/logo/' +
              _vm.gatewayIntegration.platform +
              '-integration.png') +
            ')',
        })})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex w-full justify-between items-center"},[_c('p',{staticClass:"text-sm short-text mx-4"},[_vm._v(_vm._s(_vm.gatewayIntegration.name))]),_c('drop-menu',{attrs:{"menu-items":_vm.menuItems,"small":""}})],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }