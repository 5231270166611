<template>
  <v-wait for="dashboard">
    <div>
      <!-- ROW 1 -->
      <div class="flex mb-4 justify-end">
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 flex flex-row justify-end pr-5">
          <date-range-picker
            ref="picker"
            v-model="dashboardDateRange"
            :opens="opens"
            :auto-apply="autoApply"
            :max-date="maxDateForFilter"
            :start-date="customStartDate"
            :end-date="customEndDate"
            :ranges="customDateRanges"
          >
            <div slot="input" slot-scope="picker">
              {{ picker.startDate | moment('MM/DD/YYYY') }} -
              {{ picker.endDate | moment('MM/DD/YYYY') }}
            </div>
          </date-range-picker>
          <div class="ml-2">
            <gd-button
              variant="primary"
              @click="fetchDashboard"
            >
              {{ $t('frontend_submit') }}
            </gd-button>
          </div>
        </div>
      </div>

      <!-- ROW 2 -->
      <div class="flex flex-wrap flex-row mb-48">
        <div
          v-for="(card, index) in [
            { title: $t('frontend_resources_impressions'), data: impressions },
            { title: $t('frontend_resources_uniques'), data: uniques },
            { title: $t('frontend_resources_signups'), data: optins },
            { title: $t('frontend_resources_sales'), data: sales },
            { title: $t('frontend_resources_commisions'), data: commissions },
          ]"
          :key="index"
          class="
            w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 pl-5 pr-5 mb-4
          "
        >
          <dashboard-card>
            <template slot="header">{{ card.title }}</template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ card.data | numFormat }}
              </div>
            </template>
            <template slot="footer"></template>
          </dashboard-card>
        </div>
      </div>
    </div>
  </v-wait>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dashboardDateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      opens: 'left',
      autoApply: true,
      maxDateForFilter: new Date(),
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
      },
      impressions: 0,
      uniques: 0,
      sales: 0,
      optins: 0,
      commissions: 0,
    };
  },
  async mounted() {
    await this.fetchDashboard();
  },
  methods: {
    async fetchDashboard() {
      const self = this;
      self.$wait.start('dashboard');
      const dashboardData = await axios.get(
        `affiliates/${this.funnelResource.funnel_id}/dashboard`,
        {
          params: {
            fromDate: moment(self.dashboardDateRange.startDate).format(
              'MM/DD/YYYY'
            ),
            toDate: moment(self.dashboardDateRange.endDate).format(
              'MM/DD/YYYY'
            ),
          },
        }
      );
      this.impressions = dashboardData.data.impressions;
      this.uniques = dashboardData.data.uniques;
      this.sales = dashboardData.data.sales;
      this.optins = dashboardData.data.optins;
      this.commissions = dashboardData.data.commissions;
      self.$wait.end('dashboard');
    },
  },
};
</script>
