<template>
    <div class="mt-6">
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_font_family')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <gd-dropdown
                        v-model="localModel.customization_details.customization_details.font_family"
                        max-height="124px"
                        :options="fontFamiliesOptions"
                        size="full"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_heading_color')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <color-picker data-cy="localModel.customization_details.customization_details.heading_color"
                        v-model="localModel.customization_details.customization_details.heading_color"
                        class="w-24 border-2 border-grey-300 rounded" :colors="colors"
                        :cookies-origin="'groovesell_funnel_' + prductDetails.funnel_id"
                        @change="changedHeadingColor()"></color-picker>
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_heading_size')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <gd-dropdown
                        v-model="localModel.customization_details.customization_details.heading_size"
                        :options="sizesOptions"
                        size="full"
                        @change="changedHeadingSize(localModel.customization_details.customization_details.heading_size)"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_font_color')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <color-picker data-cy="
                      localModel.customization_details.customization_details
                        .lable_color" v-model="localModel.customization_details.customization_details.lable_color"
                        class="w-24 border-2 border-grey-300 rounded" :colors="colors"
                        :cookies-origin="'groovesell_funnel_' + prductDetails.funnel_id"
                        @change="changedLabelColor()"></color-picker>
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_form_field_size')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <gd-dropdown
                        v-model="localModel.customization_details.customization_details.input_size"
                        :options="sizesOptions"
                        size="full"
                        @change="changedInputSize(localModel.customization_details.customization_details.input_size)"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_button_size')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <gd-dropdown
                        v-model="localModel.customization_details.customization_details.button_size"
                        :options="sizesOptions"
                        size="full"
                        @change="changedButtonSize(localModel.customization_details.customization_details.button_size)"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row w-full justify-start mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_full_width_button')
                    }}</label>
                </div>
                <div class="w-1/3">
                    <gd-toggle v-model="localModel.customization_details.customization_details.button_full_width" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';

export default {
    name: 'TabCustomization',
    components: {
        ColorPicker,
    },
    props: {
        model: {
            required: true,
        },
        prductDetails: {
            required: true,
        },
        colors: {
            required: true,
        },

    },
    data() {
        return {
            localModel: this.model,
            fontFamilies: [
                {
                    id: 1,
                    value: 'Abril Fatface',
                },
                {
                    id: 2,
                    value: 'Amatic SC',
                },
                {
                    id: 3,
                    value: 'Architects Daughter',
                },
                {
                    id: 4,
                    value: 'Asap',
                },
                {
                    id: 5,
                    value: 'Balsamiq Sans',
                },
                {
                    id: 6,
                    value: 'Barlow',
                },
                {
                    id: 7,
                    value: 'Bebas Neue',
                },
                {
                    id: 8,
                    value: 'Bitter',
                },
                {
                    id: 9,
                    value: 'Cabin',
                },
                {
                    id: 10,
                    value: 'Cairo',
                },
                {
                    id: 11,
                    value: 'Cormorant Garamond',
                },
                {
                    id: 12,
                    value: 'Crimson Text',
                },
                {
                    id: 13,
                    value: 'Dancing Script',
                },
                {
                    id: 14,
                    value: 'Fira Sans',
                },
                {
                    id: 15,
                    value: 'Fjalla One',
                },
                {
                    id: 16,
                    value: 'Indie Flower',
                },
                {
                    id: 17,
                    value: 'Josefin Sans',
                },
                {
                    id: 18,
                    value: 'Lato',
                },
                {
                    id: 19,
                    value: 'Libre',
                },
                {
                    id: 20,
                    value: 'Libre Franklin',
                },
                {
                    id: 21,
                    value: 'Lobster',
                },
                {
                    id: 22,
                    value: 'Lora',
                },
                {
                    id: 23,
                    value: 'Martel',
                },
                {
                    id: 24,
                    value: 'Merriweather',
                },
                {
                    id: 25,
                    value: 'Montserrat',
                },
                {
                    id: 26,
                    value: 'Mukta',
                },
                {
                    id: 27,
                    value: 'Noto+Sans',
                },
                {
                    id: 28,
                    value: 'Noto+Sans+KR',
                },
                {
                    id: 29,
                    value: 'Noto Sans',
                },
                {
                    id: 30,
                    value: 'Noto Serif',
                },
                {
                    id: 31,
                    value: 'Nunito Sans',
                },
                {
                    id: 32,
                    value: 'Nunito',
                },
                {
                    id: 33,
                    value: 'Old Standard TT',
                },
                {
                    id: 34,
                    value: 'Open Sans Condensed',
                },
                {
                    id: 35,
                    value: 'Open Sans',
                },
                {
                    id: 36,
                    value: 'Oswald',
                },
                {
                    id: 37,
                    value: 'Overpass',
                },
                {
                    id: 38,
                    value: 'Oxygen',
                },
                {
                    id: 39,
                    value: 'PT Sans Narrow',
                },
                {
                    id: 40,
                    value: 'PT Sans',
                },
                {
                    id: 41,
                    value: 'PT Serif',
                },
                {
                    id: 42,
                    value: 'Pacifico',
                },
                {
                    id: 43,
                    value: 'Playfair Display',
                },
                {
                    id: 44,
                    value: 'Poppins',
                },
                {
                    id: 45,
                    value: 'Raleway',
                },
                {
                    id: 46,
                    value: 'Roboto Condensed',
                },
                {
                    id: 47,
                    value: 'Roboto Slab',
                },
                {
                    id: 48,
                    value: 'Roboto',
                },
                {
                    id: 49,
                    value: 'Rubik',
                },
                {
                    id: 50,
                    value: 'Shadows Into Light',
                },
                {
                    id: 51,
                    value: 'Signika',
                },
                {
                    id: 52,
                    value: 'Slabo 27px',
                },
                {
                    id: 53,
                    value: 'Source Code Pro',
                },
                {
                    id: 54,
                    value: 'Source Sans',
                },
                {
                    id: 55,
                    value: 'Source Serif Pro',
                },
                {
                    id: 56,
                    value: 'Tajawal',
                },
                {
                    id: 57,
                    value: 'Titillium Web',
                },
                {
                    id: 58,
                    value: 'Ubuntu',
                },
                {
                    id: 59,
                    value: 'Work Sans',
                },
            ],
            sizes: [
                {
                    id: 1,
                    title: this.$t('frontend_small'),
                    value: 'small',
                },
                {
                    id: 2,
                    title: this.$t('frontend_regular'),
                    value: 'regular',
                },
                {
                    id: 3,
                    title: this.$t('frontend_large'),
                    value: 'large',
                },
            ],
        }
    },
    computed: {
        fontFamiliesOptions() {
            return this.fontFamilies.map((family) => {
                return {
                    value: family.value,
                    text: family.value,
                };
            });
        },
        sizesOptions() {
            return this.sizes.map((size) => {
                return {
                    value: size.value,
                    text: size.title,
                };
            });
        },
    },
    watch: {
        model: {
            handler() {
                this.$emit('update-model', this.localModel)
            },
            deep: true,
        },
    },
    methods: {
        changedLabelColor() {
            if (this.localModel.customization_details.customization_details.lable_color != '') {
                const values = this.localModel.customization_details.customization_details.lable_color
                    .replace(/rgba?\(/, '')
                    .replace(/\)/, '')
                    .replace(/[\s+]/g, '')
                    .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.customization_details.customization_details.lable_color = colorHex;
                this.$emit('attribute-changed', {
                    key: 'lable_color',
                    value: colorHex,
                });
            }
        },
        changedInputSize(size) {
            const obj = {
                "small": {
                    lable_size: "0.700rem",
                    input_size: "2rem",
                    inputSize: "small"
                },
                "regular": {
                    lable_size: "0.875rem",
                    input_size: "2.5rem",
                    inputSize: "regular"
                },
                "large": {
                    lable_size: "1rem",
                    input_size: "3rem",
                    inputSize: "large"
                },
                "default": {
                    lable_size: "0.875rem",
                    input_size: "2rem",
                    inputSize: "regular"
                }
            };
            const attr = obj[size] || obj['default'];
            this.localModel.customization_details.customization_details.lable_size = attr.lable_size;
            this.$emit('attribute-changed', {
                key: 'lable_size',
                value: attr.lable_size,
            });
            this.$emit('attribute-changed', {
                key: 'input_size',
                value: attr.input_size,
            });
            this.$emit('attribute-changed', {
                key: 'inputSize',
                value: attr.inputSize,
            });

        },
        changedButtonSize(size) {
            let buttonSize = '';
            let button_size = '';
            let button_padding_bottom = '';
            let button_padding_top = '';
            let button_text = '';

            if (size == 'small') {
                buttonSize = 'small';
                button_size = '1rem';
                button_padding_bottom = '1rem';
                button_padding_top = '1rem';
                button_text = '12px';
                this.localModel.customization_details.customization_details.button_padding_bottom = '1rem';
                this.localModel.customization_details.customization_details.button_padding_top = '1rem';
            } else if (size == 'regular') {
                buttonSize = 'regular';
                button_size = '2rem';
                button_padding_bottom = '1.5rem';
                button_text = '12px';
                this.localModel.customization_details.customization_details.button_padding_bottom = '1.5rem';
                button_padding_top = '1.5rem';
                this.localModel.customization_details.customization_details.button_padding_top = '1.5rem';
            } else if (size == 'large') {
                buttonSize = 'large';
                button_size = '4rem';
                button_text = '18px';
            } else {
                buttonSize = 'regular';
                button_size = '2rem';
                this.localModel.customization_details.customization_details.button_padding_bottom = '1rem';
                this.localModel.customization_details.customization_details.button_padding_top = '1rem';
                button_text = '14px';
            }
            this.$emit('attribute-changed', {
                key: 'button_size',
                value: button_size,
            });
            this.$emit('attribute-changed', {
                key: 'button_padding_bottom',
                value: button_padding_bottom,
            });
            this.$emit('attribute-changed', {
                key: 'button_padding_top',
                value: button_padding_top,
            });
            this.$emit('attribute-changed', {
                key: 'button_text',
                value: button_text,
            });
            this.$emit('attribute-changed', {
                key: 'buttonSize',
                value: buttonSize,
            });

        },
        changedHeadingSize(size) {
            let heading_size = '';
            let hedingSize = '';
            if (size == 'small') {
                hedingSize = 'small';
                heading_size = '14px';
            } else if (size == 'regular') {
                hedingSize = 'regular';
                heading_size = '16px';
            } else if (size == 'large') {
                hedingSize = 'large';
                heading_size = '20px';
            } else {
                heading_size = '16px';
            }
            this.$emit('attribute-changed', {
                key: 'heading_size',
                value: heading_size,
            });
            this.$emit('attribute-changed', {
                key: 'hedingSize',
                value: hedingSize,
            });
        },
        changedHeadingColor() {
            if (this.localModel.customization_details.customization_details.heading_color != '') {
                const values =
                    this.localModel.customization_details.customization_details.heading_color
                        .replace(/rgba?\(/, '')
                        .replace(/\)/, '')
                        .replace(/[\s+]/g, '')
                        .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.customization_details.customization_details.heading_color = colorHex;
                this.$emit('attribute-changed', {
                    key: 'heading_color',
                    value: colorHex,
                });
            }
        },
        componentToHex(c) {
            const hex = c.toString(16);
            return hex.length == 1 ? `0${hex}` : hex;
        },
        rgbToHex(r, g, b) {
            return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
        },
    }
}
</script>