import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openSalesDetail() {
      this.params.context.componentParent.$refs.vendorDetailModal.openSalesDetail(
        this.params.data.id
      );
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openSalesDetail">
            {{this.params.value}}
        </span>
    `
});
