<template>
    <div>
        <gd-modal name="senders-detail-modal" :title="$t('frontend_groovesell_send_export_report')" width="40%"
            height="auto" class="custom-modal">
            <div class="flex flex-col m-6 mb-4">
                <div class="flex flex-row w-full mb-2 items-center">
                    <gd-toggle :dark="true" class="rounded-full" v-model="isToggleOn" />
                    <label class="font-semibold mr-2 ml-2">
                        {{ $t('frontend_groovesell_select_main_account_email_heading') }}
                    </label>
                </div>

                <div class="flex flex-col">
                    <span style="color: gray; font-size: 12px;">({{ loggedInUserEmail }})</span>
                </div>

                <label v-if="!isToggleOn" class="font-semibold text-sm text-grey-700 mb-2 mt-4">
                    <!-- Add mt-4 to create some space between elements -->
                    {{ $t('frontend_groovesell_select_recipients_heading') }}
                </label>
                <treeselect v-if="!isToggleOn" v-model="recipientMail" :multiple="true" :openDirection="'below'"
                    :options="recipientOptions" :placeholder="$t('frontend_groovesell_add_recipients')" />
                <div v-if="!isToggleOn && recipientMail.length === 0" class="error-message mt-2">
                    <!-- Add mt-2 to create space between elements -->
                    {{ $t('frontend_groovesell_error_message_for_recipient') }}
                </div>

                <div class="flex flex-row w-full my-6 justify-end">
                    <gd-button size="md" variant="primary" :disabled="!isToggleOn && recipientMail.length === 0"
                        @click="handlButtonClick()">
                        {{ $t('frontend_send') }}
                    </gd-button>
                </div>
            </div>
        </gd-modal>
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>

</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import axios from 'axios';
import moment from 'moment';

export default {
    components: {
        Treeselect,
    },
    props: {
        loggedInUserEmail: {
            required: true,
        },
        recipientOptions: {
            required: true,
        },
        recipients: {
            required: true,
        },
        filtersData: {
            required: true,
        },
        allPagesExport: {
            required: true,
        },
        transactionsTab: {
            required: true,
        },

    },
    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
            isToggleOn: true,
            recipientMail: [],
            isAllsuccess: false,
            isAllfailed: false,
        }
    },

    /** watch filters_data */
    watch: {
        filtersData: {
            handler: function (val) {
                this.filtersData = val;
            },
            deep: true,
        },
    },
    methods: {

        handlButtonClick() {
            if (this.transactionsTab == 'failed') {
                return this.allPagesExport ? this.exportFailedTransactionData() : this.exportThisPageFailedTransactionData();
            }

            return this.exportData();
        },

        async exportData() {
            const payload = {
                funnels: this.filtersData.filteredFunnels,
                transactions: this.filtersData.selectedTransactionFilters,
                advancedTransactions: this.filtersData.filteredAdvancedTransactions,
                search: this.filtersData.searchPhrase,
                excludeFreeTransactions: this.filtersData.excludeFreeTransactions,
                affiliates: this.filtersData.filteredAffiliates,
                secondTierAffiliates: this.filtersData.filteredSecondTierAffiliates,
                fromDate: moment(this.filtersData.transactionsDateRange.startDate).format('MM/DD/YYYY'),
                toDate: moment(this.filtersData.transactionsDateRange.endDate).format('MM/DD/YYYY'),
                showCustomField: this.filtersData.showCustomField
            };

            if (!this.allPagesExport) {
                payload.page = this.filtersData.page;
                payload.limit = this.filtersData.limit;
            }

            if (!this.isToggleOn && this.recipientMail.length > 0) {
                payload.recipients = this.recipientMail.length > 0 ? this.recipientMail : this.recipients;
            }
            await axios.post('/generate-export-link', payload);
            this.$root.$emit('modal-close', 'senders-detail-modal');
            this.showExportMessage();

        },

        async exportFailedTransactionData() {
            this.isAllfailed = true;
            const payload = {
                funnels: this.filtersData.filteredFunnels,
                transactions: this.filtersData.filteredFailedTransactions,
                search: this.filtersData.searchPhrase,
                affiliates: this.filtersData.filteredAffiliates,
                fromDate: moment(this.filtersData.transactionsDateRange.startDate).format('MM/DD/YYYY'),
                toDate: moment(this.filtersData.transactionsDateRange.endDate).format('MM/DD/YYYY'),
            };
            if (!this.isToggleOn && this.recipientMail.length > 0) {
                payload.recipients = this.recipientMail.length > 0 ? this.recipientMail : this.recipients;
            }
            await axios.post('/generate-failed-transactions-export-link', payload);
            this.$root.$emit('modal-close', 'senders-detail-modal');
            this.showExportMessage(true)
        },

        async exportThisPageFailedTransactionData() {
            const payload = {
                funnels: this.filtersData.filteredFunnels,
                transactions: this.filtersData.filteredFailedTransactions,
                search: this.filtersData.searchPhrase,
                affiliates: this.filtersData.filteredAffiliates,
                secondTierAffiliates: this.filtersData.filteredSecondTierAffiliates,
                fromDate: moment(this.filtersData.transactionsDateRange.startDate).format('MM/DD/YYYY'),
                toDate: moment(this.filtersData.transactionsDateRange.endDate).format('MM/DD/YYYY'),
                page: this.filtersData.failedPage,
                limit: this.filtersData.failedLimit,
            };
            await axios.post('/generate-failed-transactions-export-link', payload);
            this.$root.$emit('modal-close', 'senders-detail-modal');
            this.showExportMessage(true)
        },
        showExportMessage(failed = false) {
            const loggedInUserEmail = this.loggedInUserEmail;
            const recipientEmail = this.recipientMail.join(', ');

            let textMessage = failed ? this.$t('frontend_groovesell_failed_transaction_message') : this.$t('frontend_groovesell_transaction_message');

            if (!this.isToggleOn && recipientEmail.length > 0) {
                textMessage = textMessage + " " + `${recipientEmail}`;
            } else {
                textMessage = textMessage + " " + `${loggedInUserEmail}`;
            }

            this.alertProps = {
                show: true,
                variant: 'success',
                title: this.$t('frontend_groovesell_export_data'),
                message: textMessage,
            };
        },

    }
}
</script>
