<template>
  <div>
    <div v-if="hasVideoReviews" class="flex flex-col">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-tabs
          :tabs="videoReviewsTabs"
          :current-tab="localVideoReviewsTab"
          :error-tabs="videoReviewsErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleVideoReviewsTabChange"
          :enable-dragging="true"
          @updateTabOrder="handleVideoReviewsTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createVideoReview"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(review, index) in localFunnelResource.video_reviews" :key="index">
          <div v-if="isVideoReviewTabActive(review)" class="pb-8">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_name') }}
                </label>
                <text-input
                  v-model="review.name"
                  :validator="$v.localFunnelResource.video_reviews.$each[index].name"
                  :attribute="$t('frontend_groovesell_video_review_name')"
                  :placeholder="$t('frontend_resources_name')"
                />
              </div>

              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle class="h-6 mt-2" v-model="review.enabled" :value="review.enabled" />
                <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index)">
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_link_to') }}
                </label>
                <gd-dropdown
                  v-model="review.landingPage"
                  size="full"
                  :options="landingPagesOptions"
                />
              </div>
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex flex-row w-1/2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_description') }}
                </label>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-model="review.description"
                    class="h-32 w-full"
                    :placeholder="$t('frontend_resources_description')"
                    :validator="$v.localFunnelResource.video_reviews.$each[index].description"
                    :attribute="$t('frontend_desc')"
                  />
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToVideoReview(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActions(index)"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_video') }}
                </label>
                <text-input
                  v-model="review.video"
                  :validator="$v.localFunnelResource.video_reviews.$each[index].video"
                  :attribute="$t('frontend_resources_video')"
                  :placeholder="$t('frontend_resources_video')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-button
          size="auto"
          variant="primary"
          class="mb-3 mt-3"
          @click="createVideoReview"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_ou_currently_donot_have_any_video') }}
    </div>
    <confirm-modal
      name="confirm-video-review-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteVideo"
    />
  </div>
</template>

<script>
import urlParser from 'js-video-url-parser/lib/base';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import { required, minLength, maxLength, url } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  components: {
    TrashIcon,
  },
  mixins: [generateNumberMixin],
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    videoReviewsTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoReviewId: null,
      localFunnelResource: this.funnelResource,
      localVideoReviewsTab: this.videoReviewsTab,
    };
  },
  computed: {
    landingPagesOptions() {
      return this.landingPages.map((landingPage) => {
        return {
          value: landingPage.id,
          text: landingPage.description,
        };
      });
    },
    hasVideoReviews() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.video_reviews &&
        this.localFunnelResource.video_reviews.length
      );
    },
    videoReviewsTabs() {
      let videoReviewsTabs = [];

      if (this.hasVideoReviews) {
        for (let i = 0; i < this.localFunnelResource.video_reviews.length; i++) {
          videoReviewsTabs.push({
            title: this.localFunnelResource.video_reviews[i].name,
            value: `video_review_tab_${this.localFunnelResource.video_reviews[i].id}`,
          });
        }
      }

      return videoReviewsTabs;
    },
    videoReviewsErrorTabs() {
      let videoReviewsErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.video_reviews.$each.$iter) {
        if (this.$v.localFunnelResource.video_reviews.$each.$iter[index].$error) {
          videoReviewsErrorTabs.push(
            `video_review_tab_${this.$v.localFunnelResource.video_reviews.$each.$iter[index].$model.id}`
          );
        }
      }

      return videoReviewsErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActions(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToVideoReview(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleVideoReviewsTabChange(newTab) {
      this.localVideoReviewsTab = newTab || this.localVideoReviewsTab;
      this.$emit('handleVideoReviewsTabChange', this.localVideoReviewsTab);
    },
    handleVideoReviewsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('video_review_tab_', ''))
      );

      this.localFunnelResource.video_reviews.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createVideoReview() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.video_reviews.length + 1;
      const newLandingPageId = this.landingPages[0].id;

      this.localFunnelResource.video_reviews.push({
        id: newPostId,
        name: `Video Review ${newPostCount}`,
        description: '',
        video: '',
        landingPage: newLandingPageId,
      });

      this.localVideoReviewsTab = `video_review_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleVideoReviewsTabChange();
    },
    openConfirmModal(videoReviewId) {
      this.videoReviewId = videoReviewId;
      this.$root.$emit('modal-open', 'confirm-video-review-delete-modal');
    },
    async deleteVideo() {
      this.localFunnelResource.video_reviews.splice(this.videoReviewId, 1);
      this.localVideoReviewsTab = '';

      if (this.localFunnelResource.video_reviews.length > 0) {
        let nextOpenIndex = 0;
        if (this.videoReviewId != 0) {
          nextOpenIndex = this.videoReviewId - 1;
        }

        const nextOpenId = this.localFunnelResource.video_reviews[nextOpenIndex].id;
        this.localVideoReviewsTab = `video_review_tab_${nextOpenId}`;
      }
      this.updateFunnelResource();
      this.handleVideoReviewsTabChange();
    },
    addAffiliateNameToVideoReview(index) {
      this.localFunnelResource.video_reviews[
        index
      ].description = `${this.localFunnelResource.video_reviews[index].description}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToVideoReview(index, landingPageId) {
      this.localFunnelResource.video_reviews[
        index
      ].description = `${this.localFunnelResource.video_reviews[index].description}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    isVideoReviewTabActive(review) {
      return this.localVideoReviewsTab === `video_review_tab_${review.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      video_reviews: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          description: {
            required,
            minLength: minLength(3),
          },
          video: {
            required,
            url,
            isValidVideoURL(value, object) {
              if (urlParser.parse(value) === undefined) {
                return false;
              }
              return true;
            },
          },
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
