<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs :tabs="dataSharingTabs" :current-tab="dataSharingTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleDataSharingTabChange" />
        </div>
        <div class="mt-6">
            <div v-if="dataSharingTab == 'affiliate_data_sharing'">
                <div class="w-full flex">
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm font-bold"></label>
                    </div>
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm font-bold">
                            {{ $t('frontend_view') }}
                        </label>
                    </div>
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm font-bold">
                            {{ $t('frontend_export') }}
                        </label>
                    </div>
                </div>

                <div class="w-full flex mt-4">
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm">{{
                            $t('frontend_email')
                        }}</label>
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.email.view"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.email.view" />
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.email.export"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.email.export" />
                    </div>
                </div>

                <div class="w-full flex mt-4">
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm">{{
                            $t('frontend_phone')
                        }}</label>
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.phone.view"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.phone.view" />
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.phone.export"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.phone.export" />
                    </div>
                </div>

                <div class="w-full flex mt-4">
                    <div class="w-1/6">
                        <label class="w-full text-grey-dark text-sm">{{
                            $t('frontend_address')
                        }}</label>
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.address.view"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.address.view" />
                    </div>
                    <div class="w-1/6">
                        <gd-toggle v-model="localModel.affiliate_data_share_details.data_sharing_options.address.export"
                            :value="localModel.affiliate_data_share_details.data_sharing_options.address.export" />
                    </div>
                </div>
            </div>
            <div v-else-if="dataSharingTab == 'affiliate_bonus_integrations'">
                <div class="flex flex-row w-full mb-6 mt-2">
                    <div class="w-1/4">
                        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{
                            $t('frontend_product_step9_Affiliate_Can_Drop_Pixel')
                        }}</label>
                    </div>
                    <div>
                        <gd-toggle v-model="localModel.affiliate_data_share_details.affiliate_can_drop_pixel"
                            :value="localModel.affiliate_data_share_details.affiliate_can_drop_pixel" />
                    </div>
                </div>

                <div class="flex flex-row w-full mb-6 mt-2">
                    <div class="w-1/4">
                        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{
                            $t('frontend_product_step9_Affiliate_integration')
                        }}</label>
                    </div>
                    <div>
                        <gd-toggle v-model="localModel.affiliate_data_share_details.affiliate_integration"
                            :value="localModel.affiliate_data_share_details.affiliate_integration" />
                    </div>
                </div>

                <div class="flex flex-row w-full mb-6 mt-2">
                    <div class="w-1/4">
                        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{
                            $t('frontend_product_step9_Affiliate_webhook')
                        }}</label>
                    </div>
                    <div>
                        <gd-toggle v-model="localModel.affiliate_data_share_details.affiliate_bonus_webhook"
                            :value="localModel.affiliate_data_share_details.affiliate_bonus_webhook" />
                    </div>
                </div>

                <div class="flex flex-row w-full mb-6 mt-2">
                    <div class="w-1/4">
                        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{
                            $t('frontend_product_step9_Affiliate_membership')
                        }}</label>
                    </div>
                    <div>
                        <gd-toggle v-model="localModel.affiliate_data_share_details.affiliate_bonus_membership"
                            :value="localModel.affiliate_data_share_details.affiliate_bonus_membership" />
                    </div>
                </div>
                <div class="flex flex-row w-full mb-6 mt-2">
                    <div class="w-1/4">
                        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{
                            $t('frontend_product_step9_Affiliate_file') }}</label>
                    </div>
                    <div>
                        <gd-toggle v-model="localModel.affiliate_data_share_details.affiliate_bonus_file"
                            :value="localModel.affiliate_data_share_details.affiliate_bonus_file" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    props: {
        model: {
            type: Object,
            required: true
        }

    },
    watch: {
        model: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true
        }
    },
    data() {
        return {
            localModel: this.model,
            dataSharingTab: 'affiliate_data_sharing',
            dataSharingTabs: [
                {
                    title: this.$t('frontend_product_step9_affiliate_data_sharing'),
                    value: 'affiliate_data_sharing',
                },
                {
                    title: this.$t('frontend_product_step9_affiliate_integrations'),
                    value: 'affiliate_bonus_integrations',
                },
            ],
        }
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
        handleDataSharingTabChange(newTab) {
            this.dataSharingTab = newTab;
        },

    },

}
</script>