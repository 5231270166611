<template>
    <div>
        <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="bg-white rounded p-2 mb-2">
            <div v-for="(pricePoint, index) in automations.price_points" :key="index">
                <div v-if="pricePointsTab ===
                    'data_sharing_product_price_point_' + pricePoint.id
                    " class="flex flex-col w-full">
                    <div class="flex flex-col w-full bg-white mb-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        pricePointsTabs: {
            type: Array,
            required: true,
        },
        pricePointsTab: {
            type: String,
            required: true,
        },
        handlePricePointsTabChange: {
            type: Function,
            required: true,
        },
        automations: {
            type: Object,
            required: true,
        },
    },

    computed: {
        pricePointsErrorTabs() {
            return [];
        },
    }
}

</script>