var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-wait',{attrs:{"for":"dashboard"}},[_c('div',[_c('div',{staticClass:"flex mb-4 justify-end"},[_c('div',{staticClass:"w-full md:w-1/3 lg:w-1/3 xl:w-1/3 flex flex-row justify-end pr-5"},[_c('date-range-picker',{ref:"picker",attrs:{"opens":_vm.opens,"auto-apply":_vm.autoApply,"max-date":_vm.maxDateForFilter,"start-date":_vm.customStartDate,"end-date":_vm.customEndDate,"ranges":_vm.customDateRanges},scopedSlots:_vm._u([{key:"input",fn:function(picker){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(picker.startDate,'MM/DD/YYYY'))+" - "+_vm._s(_vm._f("moment")(picker.endDate,'MM/DD/YYYY'))+" ")])}}]),model:{value:(_vm.dashboardDateRange),callback:function ($$v) {_vm.dashboardDateRange=$$v},expression:"dashboardDateRange"}}),_c('div',{staticClass:"ml-2"},[_c('gd-button',{attrs:{"variant":"primary"},on:{"click":_vm.fetchDashboard}},[_vm._v(" "+_vm._s(_vm.$t('frontend_submit'))+" ")])],1)],1)]),_c('div',{staticClass:"flex flex-wrap flex-row mb-48"},_vm._l(([
          { title: _vm.$t('frontend_resources_impressions'), data: _vm.impressions },
          { title: _vm.$t('frontend_resources_uniques'), data: _vm.uniques },
          { title: _vm.$t('frontend_resources_signups'), data: _vm.optins },
          { title: _vm.$t('frontend_resources_sales'), data: _vm.sales },
          { title: _vm.$t('frontend_resources_commisions'), data: _vm.commissions },
        ]),function(card,index){return _c('div',{key:index,staticClass:"w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 pl-5 pr-5 mb-4"},[_c('dashboard-card',[_c('template',{slot:"header"},[_vm._v(_vm._s(card.title))]),_c('template',{slot:"content"},[_c('div',{staticClass:"w-full h-full relative text-4xl font-semibold"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(card.data))+" ")])]),_c('template',{slot:"footer"})],2)],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }