<template>
    <gd-modal name="transaction-detail-modal" :title="transactionDetailsModalTitle" width="80%" bg-type="regular">
        <div v-if="selectedTransaction" class="flex">
            <div class="flex flex-col px-4" style="min-height: 60vh">
                <gd-tabs :tabs="transactionDetailTabs" :current-tab="transactionDetailsTab" :highlight-tabs="highLightTabs"
                    highlight-text="Fraud Alert" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                    :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
                    :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                    :line-class="'gs-default-tabs-active-line'" @onClick="handleTransactionsDetailTabChange" />
                <div class="mt-2">
                    <transaction v-if="transactionDetailsTab === 'transaction'"
                        :selectedTransaction="selectedTransaction" />
                    <customer v-if="transactionDetailsTab === 'customer'" :selectedTransaction="selectedTransaction" />
                    <retries v-if="transactionDetailsTab === 'retries'" :selectedTransaction="selectedTransaction" />
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Transaction from '../tabs/Transaction';
import Customer from '../tabs/Customer';
import Retries from '../tabs/Retries';
export default {
    components: {
        Transaction,
        Customer,
        Retries,
    },
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { }
        },
    },
    computed: {
        transactionDetailsModalTitle() {
            return this.selectedTransaction?.transaction_id
        },
        transactionDetailTabs() {
            if (this.selectedTransaction && this.selectedTransaction.has_failed_previously) {
                return [
                    { title: this.$t('frontend_analytics_transaction'), value: 'transaction' },
                    { title: this.$t('frontend_affiliate_transactions_customer'), value: 'customer' },
                    { title: this.$t('frontend_gduikit_sidebar_affiliate_affiliate'), value: 'affiliate' },
                    { title: this.$t('frontend_analytics_failed_transaction_logs'), value: 'retries' },
                ];
            }
            return [
                { title: this.$t('frontend_analytics_transaction'), value: 'transaction' },
                { title: this.$t('frontend_affiliate_transactions_customer'), value: 'customer' },
            ];
        },
        highLightTabs() {
            const highLightTabs = [];
            if (this.selectedTransaction.suspected_affiliate_fraud == 1) {
                highLightTabs.push('affiliate');
            }
            return highLightTabs;
        }
    },
    data() {
        return {
            transactionDetailsTab: 'transaction',
        }
    },
    methods: {
        handleTransactionsDetailTabChange(newTab) {
            this.transactionDetailsTab = newTab;
        },
    }
}

</script>