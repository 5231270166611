<template>
  <div v-if="hasFacebookAds" class="flex flex-col">
    <!-- Facebook Ads Tabs -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="facebookAdsTabs"
        :current-tab="facebookAdsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleFacebookAdsTabChange', $event)"
      />
    </div>

    <!-- Facebook Ads Content -->
    <div class="mt-2">
      <div v-for="(ad, index) in funnelResource.facebook_ads" :key="index">
        <div v-if="isCurrentFacebookAdTab(ad)" class="pb-8">
          <!-- Ad Copy -->
          <div class="flex flex-col w-full mb-6">
            <div class="flex flex-row w-1/2">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_affiliate_promo_ad_copy') }}
              </label>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-1/2">
                <text-area
                  v-model="ad.copy"
                  class="h-32 w-full"
                  :placeholder="$t('frontend_affiliate_promo_ad_copy')"
                  :readonly="true"
                />
              </div>
              <div class="flex items-center content-center w-1/4">
                <div class="ml-2">
                  <gd-button
                    v-clipboard:copy="ad.copy"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    size="icon"
                    variant="primary"
                    :title="$t('frontend_affiliate_promo_copy_fb_ad')"
                  >
                    <copy-icon class="w-5 h-5 fill-current" />
                  </gd-button>
                </div>
              </div>
              <div class="w-1/4 flex flex-col text-right"></div>
            </div>
          </div>

          <!-- Suggested Interests -->
          <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_affiliate_promo_suggested_interest') }}
            </label>
            <text-area
              v-model="ad.interests"
              class="h-16 w-full"
              :readonly="true"
              :placeholder="$t('frontend_affiliate_promo_suggested_interest')"
            />
          </div>

          <!-- Ad Images -->
          <div class="flex flex-col w-full mb-6">
            <div class="w-full flex-row">
              <label class="w-full font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_affiliate_promo_ad_images') }}
              </label>
            </div>
            <div v-if="ad.images && ad.images.length" class="flex flex-row flex-wrap">
              <div v-for="(image, imageIndex) in ad.images" class="w-1/5 px-2">
                <div class="flex flex-col w-full">
                  <div class="w-full text-center mt-2">
                    <img :src="image" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ $t('frontend_affiliate_promo_no_ad_images') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_affiliate_no_facebook_ads') }}
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    facebookAdsTab: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasFacebookAds() {
      return (
        this.funnelResource &&
        this.funnelResource.facebook_ads &&
        this.funnelResource.facebook_ads.length
      );
    },
    facebookAdsTabs() {
      return (
        this.funnelResource.facebook_ads?.map(ad => ({
          title: ad.name,
          value: `facebook_ad_tab_${ad.id}`,
        })) || []
      );
    },
  },
  methods: {
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
    isCurrentFacebookAdTab(ad) {
      return this.facebookAdsTab === `facebook_ad_tab_${ad.id}`;
    },
  },
};
</script>
