<template>
  <div>
    <div class="flex flex-row justify-start pr-2 mb-4">
      <gd-toggle class="mr-2" v-model="localModel.has_skip_fields" :value="localModel.has_skip_fields" />
      <label class="mr-4">
        {{ $t('frontend_checkout_links_step7_skip_billing_address') }}
      </label>
    </div>

    <div class="flex flex-col w-full bg-white rounded mt-4" v-if="localModel.has_skip_fields">
      <div class="" style="min-width: 40vw">
        <div class="flex flex-row p-2 mb-2">
          <div class="w-1/6"></div>
          <div class="w-1/6 text-center">
            {{ $t('frontend_skip_bill_info') }}
          </div>
          <div class="w-1/6 text-center">{{ $t('frontend_skip_tos') }}</div>
          <div class="w-1/6 text-center">{{ $t('frontend_skip_phone') }}</div>
        </div>

        <div style="height: calc(60vh - 15rem)">
          <div class="w-full mb-2 max-h-full flex flex-col overflow-y-auto">
            <div class="flex flex-row p-2" v-for="(pricePoint, index) in pricepointsForProjectionsFiltered" :key="index">
              <div class="w-1/6">{{ pricePoint.name }}</div>

              <!-- skip_billing_info -->
              <div class="w-1/6 text-center">
                <div v-if="pricePoint.price > 0">N/A</div>
                <div class="pl-12" v-else>
                  <gd-toggle
                    v-model="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_billing_info"
                    :value="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_billing_info" />
                </div>
              </div>

              <!-- skip_tos -->
              <div class="w-1/6 text-center">
                <div v-if="pricePoint.price > 0">N/A</div>
                <div class="pl-12" v-else>
                  <gd-toggle
                    v-model="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_terms_or_service"
                    :value="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_terms_or_service" />
                </div>
              </div>
              <div class="w-1/6 text-center">
                <div v-if="pricePoint.price > 0">N/A</div>
                <div class="pl-12" v-else>
                  <gd-toggle v-model="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_phone"
                    :value="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].skip_phone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BillingAdressStep',
  components: {
  },
  props: {
    model: {
      required: true,
    },
    pricepointTabs: {
      required: true,
    },
    selectPricePoint: {
      required: true,
    },
    pricepointsForProjections: {
      required: true,
    },
    projectionsPricePointsTab: {
      required: true,
    },
    handleProjectionPricePointTabChange: {
      required: true,
    },
  },

  data() {
    return {
      askForPasswordType: [
        {
          id: 1,
          title: this.$t('frontend_ask_for_pass_start'),
          description: '',
        },
        {
          id: 0,
          title: this.$t('frontend_ask_for_pass_end'),
          description: '',
        },
      ],
      askPasswordValue: 1,
      test: false,
      localModel: this.model,
      pricePointTabsEnabled: [],
      skip_field_arr: [],
      pricepointsForProjectionsFiltered: [],
      // ask_for_password_select: 0,
      ask_for_password_select: 1,
      projectionsPPointsTab: this.projectionsPricePointsTab,
    };
  },
  computed: {
    movePasswordFieldSwith() {
      let hasPasswordEnable = false;
      for (let i = 0; i < this.localModel.skip_field_arr.length; i++) {
        if (this.localModel.skip_field_arr[i].ask_for_password === true) {
          hasPasswordEnable = true;
        }
      }
      return hasPasswordEnable;
    },
  },
  watch: {
    localModel: {
      deep: true,
      handler() {
        this.$emit('updateModel', this.localModel);
      },
    },
  },

  async created() {


    if (this.localModel.checkout_link_page_options.move_password_to_last_step) {
      this.askPasswordValue = 0;
    }

    const enabledPricePoints = this.selectPricePoint.filter((pricePoint) => {
      return pricePoint.enabled;
    });

    const enabledPricePointIdArr = enabledPricePoints.map((point) => {
      return point.id;
    });

    for (let i = 0; i < enabledPricePoints.length; i++) {
      this.pricePointTabsEnabled.push({
        title: enabledPricePoints[i].name,
        value: `price_point_${enabledPricePoints[i].id}`,
      });
    }

    for (let i = 0; i < this.pricepointsForProjections.length; i++) {
      if (enabledPricePointIdArr.indexOf(this.pricepointsForProjections[i].id) != -1 && this.pricepointsForProjections[i].price == 0) {
        this.pricepointsForProjectionsFiltered.push(
          this.pricepointsForProjections[i]
        );
      }
    }

    if (this.pricepointsForProjectionsFiltered.length)
      this.projectionsPPointsTab = `price_point_${this.pricepointsForProjectionsFiltered[0].id}`;
  },

  methods: {
    changeAskPasswordType(value) {
      this.askPasswordValue = value;
      this.localModel.checkout_link_page_options.move_password_to_last_step = value == 1 ? false : true;
    },

    localModelPricePointIndex(pricePointId) {
      return this.localModel.skip_field_arr.findIndex(
        (pricepoint) => pricepoint.id == pricePointId
      );
    },

    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
