<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_coupons_step2_coupon_code') }}</label>
          <text-input
            v-model="localModel.coupon_code"
            :validator="$v.localModel.coupon_code"
            :attribute="$t('frontend_groovesell_coupons_step2_coupon_code')"
            value="localModel.coupon_code"
            :placeholder="$t('frontend_groovesell_coupons_step2_coupon_code')"
            @input="delayTouch($v.localModel.coupon_code)"
          />
        </div>

        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_coupons_step2_discount_type') }}</label>
          <gd-dropdown
            v-model="localModel.discount_type"
            size="full"
            :options="discountTypeOptions"
            :placeholder="$t('frontend_groovesell_coupons_step2_discount_type')"
          />
        </div>

        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_coupons_step2_discount_amount') }}</label>
          <text-input
            v-model="localModel.discount"
            :validator="$v.localModel.discount"
            :attribute="$t('frontend_groovesell_coupons_step2_discount_amount')"
            value="localModel.discount"
            :placeholder="$t('frontend_groovesell_coupons_step2_discount_amount')"
          />
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength, decimal } from 'vuelidate/lib/validators';

const touchMap = new WeakMap();
export default {
  name: 'Step2',
  props: {
    model: {
      required: true
    },
    funnels: {
      required: true
    },
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  validations: {
    localModel: {
      coupon_code: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
        async couponCodeShouldbeUnique(value) {
          const check_coupon_code = await axios.post('/validate-coupon-code', {
            coupon_id: this.localModel.id,
            coupon_code: value
          });

          if (check_coupon_code.data.status == 'error') {
            return false;
          }

          return true;
        }
      },
      discount_type: {
        required
      },
      discount: {
        required,
        decimal,
        isValidDiscount(value, object) {
          const discountType = object.discount_type;

          if (discountType === 1 || discountType === '1') {
            return true;
          }

          if (discountType === 2 || discountType === '2') {
            return value >= 0 && value <= 100;
          }

          return false;
        }
      }
    }
  },
  computed: {
    discountTypeOptions() {
      return [
        { value: 1, text: this.$t('frontend_groovesell_coupons_step2_amount') },
        { value: 2, text: this.$t('frontend_percentage') + ' (%)' }
      ];
    }
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    }
  },
  async created() {
    this.$v.$touch();
  },
  methods: {
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  }
};
</script>
