<template>
  <div>
    <gd-modal
      name="create-promo-area-modal"
      :title="$t('frontend_resources_index_create_new_funnel')"
      width="50%"
      @closed="modalClosed"
    >
      <div class="flex flex-col mx-4 my-0">
        <gd-tabs
          wrapper-class="pt-4 gs-default-tabs"
          tab-class="pb-2 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          :tabs="addFunnelResourceTabs"
          :current-tab="currentAddFunnelResourceTab"
          @onClick="handleAddFunnelResourceTabChange"
        />
        <div v-if="currentAddFunnelResourceTab == 'info'" class="mt-2">
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_name')}}
            </label>
            <text-input
              v-model="localModel.name"
              :validator="$v.localModel.name"
              :attribute="$t('frontend_resources_campaign_name')"
              value="localModel.name"
              :placeholder="$t('frontend_resources_campaign_name')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_default_landing_page') }}
            </label>
            <gd-dropdown
              v-if="funnel && funnel.main_product && funnel.main_product.affiliate_landing_pages"
              v-model="localModel.landing_page_id"
              size="full"
              max-height="124px"
              :options="landingPagesOptions"
              :placeholder="$t('frontend_resources_default_landing_page')"
            />
          </div>
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_resources_custom_tutorial_video') }}
            </label>
            <gd-toggle
              v-model="localModel.use_custom_video"
              :value="localModel.use_custom_video"
            />
          </div>
          <div v-if="localModel.use_custom_video" class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_custom_video_link') }}
            </label>
            <text-input
              v-model="localModel.video_link"
              :validator="$v.localModel.video_link"
              :attribute="$t('frontend_resources_custom_video_link')"
              value="localModel.video_link"
              :placeholder="$t('frontend_resources_custom_video_link')"
            />
          </div>
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_resources_show_related_funnels') }}
            </label>
            <gd-toggle
              v-model="localModel.show_more_funnels"
              :value="localModel.show_more_funnels"
            />
          </div>
          <div v-if="localModel.show_more_funnels" class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_related_funnels') }}
            </label>
            <multi-select
              track-by="id"
              label="name"
              :class="{ 'invalid': $v.localModel.related_funnels.$error }"
              :placeholder="$t('frontend_affiliate_settings_select_one')"
              :searchable="false"
              :multiple="true"
              :allow-empty="false"
              v-model="localModel.related_funnels"
              :options="relatedFunnelsList"
            >
            </multi-select>
            <span v-if="$v.localModel.related_funnels.$error" class="text-danger text-sm">
              {{ $t('please_select_atleast_one_funnel') }}
            </span>
          </div>
        </div>
        <div v-else-if="currentAddFunnelResourceTab == 'branding'" class="mt-2">
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_header_text') }}
            </label>
            <text-input
              v-model="localModel.header_text"
              :validator="$v.localModel.header_text"
              :attribute="$t('frontend_header_text')"
              value="localModel.header_text"
              :placeholder="$t('frontend_header_text')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_signup_text') }}
            </label>
            <text-input
              v-model="localModel.signup_text"
              :validator="$v.localModel.signup_text"
              :attribute="$t('frontend_signup_text')"
              value="localModel.signup_text"
              :placeholder="$t('frontend_signup_text')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_login_text') }}
            </label>
            <text-input
              v-model="localModel.login_text"
              :validator="$v.localModel.login_text"
              :attribute="$t('frontend_login_text')"
              value="localModel.login_text"
              :placeholder="$t('frontend_login_text')"
            />
          </div>
        </div>
        <div v-else class="mt-2">
          <div class="h-64 mb-4">
            <draggable
              v-model="localModel.landing_pages"
              :scroll-sensitivity="200"
              tag="ul"
              class="w-full my-4 max-h-full flex flex-col overflow-y-auto"
            >
              <li
                v-for="(landingPage, index) in localModel.landing_pages"
                :key="index"
                class="border-grey-300 py-2"
                :class="{
                  'border-b': index != localModel.landing_pages.length - 1,
                }"
              >
                <div class="flex flex-row justify-between w-full pr-4">
                  <div class="flex flex-row">
                    <drag-icon />
                    <p class="ml-2 text-md">
                      {{ landingPage.description }}
                    </p>
                  </div>
                  <div class="mr-3 flex flex-row">
                    <gd-toggle
                      v-model="landingPage.enabled"
                      :value="landingPage.enabled"
                    />
                  </div>
                </div>
              </li>
            </draggable>
          </div>
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button
            size="md"
            variant="primary"
            @click="createPromoArea"
          >
            {{ $t('frontend_resource_index_Create') }}
          </gd-button>
        </div>
      </div>
    </gd-modal>

    <gd-modal
      name="edit-promo-area-modal"
      :title="$t('frontend_groovesell_edit_funnel_promo_tools')"
      @closed="modalClosed"
    >
      <div class="flex flex-col mx-4 my-0">
        <gd-tabs
          wrapper-class="pt-4 gs-default-tabs"
          tab-class="pb-2 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          :tabs="addFunnelResourceTabs"
          :current-tab="currentAddFunnelResourceTab"
          @onClick="handleAddFunnelResourceTabChange"
        />
        <div v-if="currentAddFunnelResourceTab == 'info'" class="mt-2">
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_name') }}
            </label>
            <text-input
              v-model="localModel.name"
              :validator="$v.localModel.name"
              :attribute="$t('frontend_resources_campaign_name')"
              value="localModel.name"
              :placeholder="$t('frontend_resources_campaign_name')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_default_landing_page') }}
            </label>
            <gd-dropdown
              v-if="funnel && funnel.main_product && funnel.main_product.affiliate_landing_pages"
              v-model="localModel.landing_page_id"
              size="full"
              max-height="165px"
              :options="landingPagesOptions"
              :placeholder="$t('frontend_resources_default_landing_page')"
            />
          </div>
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_resources_custom_tutorial_video') }}
            </label>
            <gd-toggle
              v-model="localModel.use_custom_video"
              :value="localModel.use_custom_video"
            />
          </div>
          <div
            v-if="localModel.use_custom_video"
            class="flex flex-col w-full mb-4"
          >
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_custom_video_link') }}
            </label>
            <text-input
              v-model="localModel.video_link"
              :validator="$v.localModel.video_link"
              :attribute="$t('frontend_resources_custom_video_link')"
              value="localModel.video_link"
              :placeholder="$t('frontend_resources_custom_video_link')"
            />
          </div>
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_resources_show_related_funnels') }}
            </label>
            <gd-toggle
              v-model="localModel.show_more_funnels"
              :value="localModel.show_more_funnels"
            />
          </div>
          <div
            v-if="localModel.show_more_funnels"
            class="flex flex-col w-full mb-4"
          >
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_related_funnels') }}
            </label>
            <multi-select
              v-model="localModel.related_funnels"
              track-by="id"
              label="name"
              :class="{ 'invalid': $v.localModel.related_funnels.$error }"
              :placeholder="$t('frontend_affiliate_settings_select_one')"
              :searchable="false"
              :multiple="true"
              :allow-empty="false"
              :options="relatedFunnelsList"
            >
            </multi-select>
            <span v-if="$v.localModel.related_funnels.$error" class="text-danger text-sm">
              {{ $t('please_select_atleast_one_funnel') }}
            </span>
          </div>
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_resources_is_blocked_resources') }}
            </label>
            <gd-toggle
              v-model="localModel.show_more_resources"
              :value="localModel.show_more_resources"
            />
          </div>
          <div
            v-if="localModel.show_more_resources"
            class="flex flex-col w-full mb-4"
          >
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resources_blocked_funnel_resources') }}
            </label>
            <multi-select
              v-model="localModel.related_resources"
              track-by="id"
              label="name"
              :class="{ 'invalid': $v.localModel.related_resources.$error }"
              :placeholder="$t('frontend_resources_blocked_funnel_resources')"
              :searchable="false"
              :multiple="true"
              :allow-empty="false"
              :options="otherResources"
            >
            </multi-select>
            <span v-if="$v.localModel.related_resources.$error" class="text-danger text-sm">
              {{ $t('please_select_atleast_one_funnel_resource') }}
            </span>
          </div>
        </div>
        <div v-else-if="currentAddFunnelResourceTab == 'branding'" class="mt-2">
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_header_text') }}
            </label>
            <text-input
              v-model="localModel.header_text"
              :validator="$v.localModel.header_text"
              :attribute="$t('frontend_header_text')"
              value="localModel.header_text"
              :placeholder="$t('frontend_header_text')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_signup_text') }}
            </label>
            <text-input
              v-model="localModel.signup_text"
              :validator="$v.localModel.signup_text"
              :attribute="$t('frontend_signup_text')"
              value="localModel.signup_text"
              :placeholder="$t('frontend_signup_text')"
            />
          </div>
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_login_text') }}
            </label>
            <text-input
              v-model="localModel.login_text"
              :validator="$v.localModel.login_text"
              :attribute="$t('frontend_login_text')"
              value="localModel.login_text"
              :placeholder="$t('frontend_login_text')"
            />
          </div>
        </div>
        <div v-else class="mt-2">
          <div class="h-64 mb-4">
            <draggable
              v-model="localModel.landing_pages"
              :scroll-sensitivity="200"
              tag="ul"
              class="w-full my-4 max-h-full flex flex-col overflow-y-auto"
            >
              <li
                v-for="(landingPage, index) in localModel.landing_pages"
                :key="index"
                class="border-grey-300 py-2"
                :class="{
                  'border-b': index != localModel.landing_pages.length - 1,
                }"
              >
                <div class="flex flex-row justify-between w-full pr-4">
                  <div class="flex flex-row">
                    <drag-icon />
                    <p class="ml-2 text-md">
                      {{ landingPage.description }}
                    </p>
                  </div>
                  <div class="mr-3 flex flex-row">
                    <gd-toggle
                      v-model="landingPage.enabled"
                      :value="landingPage.enabled"
                    />
                  </div>
                </div>
              </li>
            </draggable>
          </div>
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button
            size="md"
            variant="primary"
            @click="editPromoArea"
          >
            {{ $t('frontend_resource_index_update') }}
          </gd-button
          >
        </div>
      </div>
    </gd-modal>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import getVideoId from 'get-video-id';
import router from '@/router';
import draggable from 'vuedraggable';
import DragIcon from '@/assets/images/icons/DragIcon.svg';

export default {
  components: {
    draggable,
    DragIcon,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    funnel: {
      type: Object,
    },
    funnels: {
      type: Array,
      required: true,
    },
    resource: {
      type: Object,
    },
    resources: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      addFunnelResourceTabs: [
        { title: this.$t('frontend_groovesell_tools_affiliate_modal_tabs_info'), value: 'info' },
        { title: this.$t('frontend_vendors_links'), value: 'links' },
        { title: this.$t('branding'), value: 'branding' },
      ],
      currentAddFunnelResourceTab: 'info',
    }
  },
  computed: {
    landingPagesOptions() {
      return this.funnel.main_product.affiliate_landing_pages.map((landingPage) => {
        return {
          value: landingPage.id,
          text: landingPage.description,
        };
      });
    },
    relatedFunnelsList() {
      const relatedFunnelsList = [];

      this.funnels.forEach((funnel) => {
        if (this.funnel.id != funnel.id) {
          relatedFunnelsList.push({ id: funnel.id, name: funnel.name });
        }
      });

      return relatedFunnelsList;
    },
    otherResources() {
      const otherResourcesList = [];

      this.resources.forEach((res) => {
        if (this.resource.id != res.id && res.is_active == 1) {
          otherResourcesList.push({ id: res.id, name: res.name });
        }
      });

      return otherResourcesList;
    },
  },
  methods: {
    modalClosed() {
      this.$v.$reset();
    },
    handleAddFunnelResourceTabChange(newTab) {
      this.currentAddFunnelResourceTab = newTab;
    },
    async createPromoArea() {
      this.$v.localModel.$touch();

      if (
        !this.$v.localModel.name.$invalid &&
        !this.$v.localModel.video_link.$invalid &&
        !this.$v.localModel.related_funnels.$invalid
      ) {
        this.$loader.start('createPromoArea');
        let resource = this.resource;

        resource.name = this.localModel.name;
        resource.landing_page_id = this.localModel.landing_page_id;
        resource.use_custom_video = this.localModel.use_custom_video;
        resource.video_details = getVideoId(this.localModel.video_link);
        resource.show_more_funnels = this.localModel.show_more_funnels;
        resource.related_funnels = this.localModel.related_funnels;
        resource.landing_pages = this.localModel.landing_pages;
        resource.header_text = this.localModel.header_text;
        resource.login_text = this.localModel.login_text;
        resource.signup_text = this.localModel.signup_text;
        const savedResource = await resource.save();
        this.$loader.end('createPromoArea');

        router.push({
          name: 'funnel_resource_assets',
          params: {
            id: savedResource.data.funnel_id,
            resource_id: savedResource.data.id,
          },
        });
      }
    },
    async editPromoArea() {
      this.$v.localModel.$touch();

      if (
        !this.$v.localModel.name.$invalid &&
        !this.$v.localModel.video_link.$invalid &&
        !this.$v.localModel.related_funnels.$invalid &&
        !this.$v.localModel.related_resources.$invalid
      ) {
        this.$loader.start('editPromoArea');
        let resources = this.resources;
        let resource = this.resource;

        resource.name = this.localModel.name;
        resource.landing_page_id = this.localModel.landing_page_id;
        resource.use_custom_video = this.localModel.use_custom_video;
        resource.video_details = getVideoId(this.localModel.video_link);
        resource.show_more_funnels = this.localModel.show_more_funnels;
        resource.related_funnels = this.localModel.related_funnels;
        resource.show_more_resources = this.localModel.show_more_resources;
        resource.related_resources = this.localModel.related_resources;
        resource.landing_pages = this.localModel.landing_pages;
        resource.header_text = this.localModel.header_text;
        resource.login_text = this.localModel.login_text;
        resource.signup_text = this.localModel.signup_text;
        resource.disable_branding = this.localModel.disable_branding;

        await resource.save();

        const editedResource = resources
          .map((item) => item.id)
          .indexOf(resource.id);

        resources[editedResource].name = resource.name;

        this.$emit('updateResources', resources);
        this.$emit('updateResource', resource);

        this.$root.$emit('modal-close', 'edit-promo-area-modal');
        this.$loader.end('editPromoArea');
      }
    },
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      video_link: {
        required: requiredIf((object) => {
          return object.use_custom_video;
        }),
        validVideoLink(value, object) {
          if (object.use_custom_video) {
            const videoDetails = getVideoId(value);
            if (videoDetails.id && videoDetails.service) {
              return true;
            }

            return false;
          }

          return true;
        },
      },
      related_funnels: {
        minimumNoOfFunnels(value, object) {
          if (object.show_more_funnels) {
            return value?.length > 0;
          }

          return true;
        },
      },
      related_resources: {
        minimumNoOfFunnelResources(value, object) {
          if (object.show_more_resources) {
            return value?.length > 0;
          }

          return true;
        },
      },
      header_text: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      login_text: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      signup_text: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
    },
  },
}
</script>
<style>
.invalid .multiselect__tags {
  border-color: red !important;
}
</style>
