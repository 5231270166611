import storage from 'local-storage-fallback';

export default {
  namespaced: true,
  state: {
    is_demo_mode: false
  },
  getters: {
    isDemoMode: state => {
      if (storage.getItem('is_demo_mode')) {
        return storage.getItem('is_demo_mode');
      }
      return state.is_demo_mode;
    }
  },
  mutations: {
    setDemoMode(state, { mode }) {
      if (mode) {
        state.is_demo_mode = 1;
        storage.setItem('is_demo_mode', true);
      } else {
        state.is_demo_mode = 0;
        storage.removeItem('is_demo_mode');
      }
    }
  },
  actions: {
    async changeDemoMode({ commit }, mode) {
      commit('setDemoMode', { mode });
    }
  }
};
