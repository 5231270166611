<template>
  <div>
    <div class="flex flex-row justify-start pr-2 mb-4">
      <gd-toggle class="mr-2" v-model="localModel.has_password_fields" :value="localModel.has_password_fields" />
      <label class="mr-4">{{ $t('frontend_checkout_links_step6_collect') }}</label>
    </div>

    <div class="flex flex-col w-full bg-white rounded mt-4" v-if="localModel.has_password_fields">
      <div class="" style="min-width: 40vw">
        <div class="flex flex-row p-2 mb-2">
          <div class="w-1/4"></div>

          <div class="w-1/4">
            {{ $t('frontend_ask_user_name') }}
          </div>
          <div class="w-1/4">
            {{ $t('frontend_ask_for_pass') }}
          </div>
        </div>

        <div style="height: calc(52vh - 15rem)">
          <div class="w-full mb-2 max-h-full flex flex-col overflow-y-auto">
            <div class="flex flex-row p-2" v-for="(pricePoint, index) in pricepointsForProjectionsFiltered" :key="index">
              <div class="w-1/4">{{ pricePoint.name }}</div>

              <!-- ask_user_name -->
              <div class="w-1/4">
                <gd-toggle v-model="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].ask_username"
                  :value="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].ask_username" />
              </div>

              <div class="w-1/4">
                <gd-toggle v-model="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].ask_for_password"
                  :value="localModel.skip_field_arr[localModelPricePointIndex(pricePoint.id)].ask_for_password" />
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-row mb-1" v-if="model.is_onestep != 1 && movePasswordFieldSwith">
          <div v-for="(askForPasswordType, index) in askForPasswordType" :key="index" class="flex w-1/2 mr-4">
            <checkout-radio-button v-model="askPasswordValue" :option="askForPasswordType"
              @onUpdateOption="changeAskPasswordType"></checkout-radio-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutRadioButton from '../../../../components/CheckoutRadioButton.vue';

export default {
  name: 'PasswordsStep',
  components: {
    CheckoutRadioButton,
  },
  props: {
    model: {
      required: true,
    },
    pricepointTabs: {
      required: true,
    },
    selectPricePoint: {
      required: true,
    },
    pricepointsForProjections: {
      required: true,
    },
    projectionsPricePointsTab: {
      required: true,
    },
    handleProjectionPricePointTabChange: {
      required: true,
    },
    selectedMultiStepType: {
      required: true,
    },
  },

  data() {
    return {
      askPasswordValue: 1,
      test: false,
      localModel: this.model,
      pricePointTabsEnabled: [],
      skip_field_arr: [],
      pricepointsForProjectionsFiltered: [],
      // ask_for_password_select: 0,
      ask_for_password_select: 1,
      projectionsPPointsTab: this.projectionsPricePointsTab,
    };
  },
  computed: {
    movePasswordFieldSwith() {
      let hasPasswordEnable = false;
      for (let i = 0; i < this.localModel.skip_field_arr.length; i++) {
        if (this.localModel.skip_field_arr[i].ask_for_password === true) {
          hasPasswordEnable = true;
        }
      }
      return hasPasswordEnable;
    },

    askForPasswordType() {
      if (this.selectedMultiStepType == 1) {
        return [
          {
            id: 1,
            svg: ['ContactAndPassword', 'Billing', 'Payment'],
            label: this.$t('frontend_checkout_links_step6_collect_pass_at_start')
          },
          {
            id: 0,
            svg: ['Contact', 'Billing', 'Password', 'Payment'],
            label: this.$t('frontend_checkout_links_step6_collect_pass_at_end')
          },
        ];
      }
      if (this.selectedMultiStepType == 2) {
        return [
          {
            id: 1,
            svg: ['EmailAndPassword', 'Contact', 'Billing', 'Payment'],
            label: this.$t('frontend_checkout_links_step6_collect_pass_at_start')
          },
          {
            id: 0,
            svg: ['Email', 'Contact', 'Billing', 'Password', 'Payment'],
            label: this.$t('frontend_checkout_links_step6_collect_pass_at_end')
          },
        ];
      }

      return [
        {
          id: 1,
          svg: ['EmailAndPassword', 'Order'],
          label: this.$t('frontend_checkout_links_step6_collect_pass_at_start')
        },
        {
          id: 0,
          svg: ['Email', 'OrderAndPassword'],
          label: this.$t('frontend_checkout_links_step6_collect_pass_at_end')
        },
      ];
    },
  },
  watch: {
    localModel: {
      deep: true,
      handler(){
        this.$emit('updateModel', this.localModel);
      },
    },
  },

  async created() {
    if (this.localModel.checkout_link_page_options.move_password_to_last_step) {
      this.askPasswordValue = 0;
    }

    const enabledPricePoints = this.selectPricePoint.filter((pricePoint) => {
      return pricePoint.enabled;
    });

    const enabledPricePointIdArr = enabledPricePoints.map((point) => {
      return point.id;
    });

    for (let i = 0; i < enabledPricePoints.length; i++) {
      this.pricePointTabsEnabled.push({
        title: enabledPricePoints[i].name,
        value: `price_point_${enabledPricePoints[i].id}`,
      });
    }

    for (let i = 0; i < this.pricepointsForProjections.length; i++) {
      if (enabledPricePointIdArr.indexOf(this.pricepointsForProjections[i].id) != -1) {
        this.pricepointsForProjectionsFiltered.push(
          this.pricepointsForProjections[i]
        );
      }
    }

    if (this.pricepointsForProjectionsFiltered.length)
      this.projectionsPPointsTab = `price_point_${this.pricepointsForProjectionsFiltered[0].id}`;
  },

  methods: {
    changeAskPasswordType(value) {
      this.askPasswordValue = value;
      this.localModel.checkout_link_page_options.move_password_to_last_step = value != 1
    },

    localModelPricePointIndex(pricePointId) {
      return this.localModel.skip_field_arr.findIndex(
        (pricepoint) => pricepoint.id == pricePointId
      );
    },

    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
