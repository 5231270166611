<template>
    <div>
        <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step9_jv_affiliates')
            }}</label>
            <text-input v-model="localModel.jv_page" :validator="validation.localModel.jv_page"
                :attribute="$t('frontend_product_step9_jv_affiliates')" value="localModel.jv_page"
                :placeholder="$t('frontend_product_step9_jv_affiliates')" />
        </div>
    </div>
</template>
<script>
export default {

    props: {
        model: {
            type: Object,
            required: true
        },
        validation: {
            required: true
        }

    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true
        }
    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: ''
        }
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        }

    },

}
</script>