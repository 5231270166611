<template>
  <div v-if="hasEnabledSwipes" class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="swipesTabs"
        :current-tab="swipesTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleSwipesTabChange', $event)"
      />
    </div>
    <div class="mt-2">
      <div v-for="(swipe, index) in funnelResource.swipes" :key="index">
        <div v-if="isSwipesTabActive(swipe)" class="pb-8">
          <div
            v-for="(subject, subjectIndex) in swipe.subjects"
            class="flex flex-col sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-6"
          >
            <label v-if="subjectIndex == 0" class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_resource_subject') }}
            </label>
            <div class="flex flex-row">
              <text-input
                v-model="swipe.subjects[subjectIndex]"
                :readonly="true"
                :placeholder="$t('frontend_resource_subject')"
              />
            </div>
          </div>

          <div class="flex flex-col w-full mb-6">
            <div class="flex flex-row sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
              <div class="w-1/2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_body') }}
                </label>
              </div>
              <div class="flex flex-row justify-end mb-2 w-1/2">
                <div class="flex flex-row">
                  <div class="font-semibold text-sm text-grey-700 mr-2">
                    {{ $t('frontend_resources_view_html') }}
                  </div>
                  <gd-toggle
                    v-model="isSwipesPageHTMLView"
                    :value="isSwipesPageHTMLView"
                    @change="$emit('updateCurrentShownSwipesQuillEditorIndex', -1)"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                <text-area
                  v-if="isSwipesPageHTMLView"
                  v-model="swipe.htmlBody"
                  class="swipesEditor w-full"
                  :placeholder="$t('frontend_resource_body')"
                  variant="dark"
                  :readonly="true"
                />
                <quill-editor
                  v-else
                  :ref="'swipesEditor_' + index"
                  v-model="swipe.htmlBody"
                  :options="swipeEditorOptions"
                  class="swipesEditor w-full"
                  :disabled="true"
                  @ready="$emit('updateCurrentShownSwipesQuillEditorIndex', index)"
                ></quill-editor>
              </div>
              <div class="flex items-center content-center">
                <div class="ml-2">
                  <gd-button
                    v-if="isSwipesPageHTMLView"
                    v-clipboard:copy="swipe.htmlBody"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    size="iconlg"
                    variant="primary"
                    :title="$t('frontend_resource_copy_swipe')"
                  >
                    <copy-icon class="w-5 h-5 fill-current" />
                  </gd-button>
                  <div
                    v-else-if="currentShownSwipesQuillEditorIndex == index && !isSwipesPageHTMLView"
                  >
                    <gd-button
                      v-clipboard:copy="getRichTextFromSwipesEditor(index)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      size="iconlg"
                      variant="primary"
                      :title="$t('frontend_resource_copy_swipe')"
                    >
                      <copy-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full mt-32 mb-6">
            <div class="flex flex-row w-1/2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_resource_text_view') }}</label>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                <textarea
                  v-model="swipe.textBody"
                  :placeholder="$t('frontend_resource_text_body')"
                  class="swipesEditor w-full resize-y border rounded border-grey-200 p-2"
                  @click="handleTextareaClick"
                  readonly="readonly"
                ></textarea>
              </div>
              <div class="flex items-center content-center">
                <div class="ml-2">
                  <gd-button
                    v-clipboard:copy="swipe.textBody"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    size="iconlg"
                    variant="primary"
                    :title="$t('frontend_resource_copy_swipe')"
                  >
                    <copy-icon class="w-5 h-5 fill-current" />
                  </gd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>{{ $t('frontend_resource_no_swipes') }}</div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    quillEditor,
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      default: () => {},
    },
    swipesTab: {
      type: String,
      default: '',
    },
    currentShownSwipesQuillEditorIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isSwipesPageHTMLView: false,
      swipeEditorOptions: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    hasEnabledSwipes() {
      return (
        this.funnelResource &&
        this.funnelResource.swipes &&
        this.funnelResource.swipes.filter(el => el.enabled).length
      );
    },
    swipesTabs() {
      return this.funnelResource.swipes.filter(el => el.enabled).map(swipe => ({
        title: swipe.name,
        value: `swipe_tab_${swipe.id}`,
      }));
    },
  },
  methods: {
    isSwipesTabActive(swipe) {
      return this.swipesTab === `swipe_tab_${swipe.id}`;
    },
    getRichTextFromSwipesEditor(index) {
      if (
        this.$refs[`swipesEditor_${index}`] &&
        this.$refs[`swipesEditor_${index}`][0]
      ) {
        return this.$refs[`swipesEditor_${index}`][0].quill.getText();
      }
      return '';
    },
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
