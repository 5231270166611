<template>
    <div class="-mt-8">
        <div v-if="filteredProductList.length" class="mt-4">
            <assets-list>
                <template slot="gridContent">
                    <ul role="list" class="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8">
                        <li v-for="(product, index) in filteredProductList" :key="'grid_p_' + index" class="relative">
                            <product-grid-asset :product="product" :admin="isAdmin" :funnel="funnel"
                                :productType="productsTab" @onProductEdit="edit"
                                @onProductDelete="handleDeleteProduct" @onProductCheckOutLinks="openCheckoutPage"
                                @onProductProjection="openProjectionsModal" @onProductTracking="openTrackingModal"
                                @onProductClone="openCloneProduct" @onProductEnableTestMode="openConfirmModal"
                                @onManagePromotools="funnelPromoTools" />
                        </li>
                        <li v-if="productsTab == 'all'" v-for="(bumpproduct, index) in bumps" :key="'grid_b_' + index"
                            class="relative">
                            <product-grid-asset :product="bumpproduct" :admin="isAdmin" :funnel="funnel"
                                productType="atc_order_bumps" @onProductEdit="editBump" @onProductDelete="deleteBump"
                                @onProductCheckOutLinks="openCheckoutPage" @onProductProjection="openProjectionsModal"
                                @onProductTracking="openTrackingModal" @onProductClone="openCloneProduct"
                                @onProductEnableTestMode="openConfirmModal" @onManagePromotools="funnelPromoTools" />
                        </li>
                    </ul>
                </template>
                <template slot="listContent">
                    <div class="flex flex-col">
                        <div v-for="(product, index) in filteredProductList" :key="'list_p_' + index">
                            <product-list-asset :product="product" :admin="isAdmin" :productType="productsTab"
                                :funnel="funnel" @onProductEdit="edit" @onProductDelete="handleDeleteProduct"
                                @onProductCheckOutLinks="openCheckoutPage" @onProductProjection="openProjectionsModal"
                                @onProductTracking="openTrackingModal" @onProductClone="openCloneProduct"
                                @onProductEnableTestMode="openConfirmModal" @onManagePromotools="funnelPromoTools" />
                        </div>
                        <div v-if="productsTab == 'all'" v-for="(product, index) in bumps" :key="'list_b_' + index">
                            <product-list-asset :product="product" :admin="isAdmin" productType="atc_order_bumps"
                                :funnel="funnel" @onProductEdit="editBump" @onProductDelete="deleteBump"
                                @onProductCheckOutLinks="openCheckoutPage" @onProductProjection="openProjectionsModal"
                                @onProductTracking="openTrackingModal" @onProductClone="openCloneProduct"
                                @onProductEnableTestMode="openConfirmModal" @onManagePromotools="funnelPromoTools" />
                        </div>
                    </div>
                </template>
            </assets-list>
        </div>
        <div v-else class="mt-12">
            {{ $t('frontend_product_no_frontend_products') }}
        </div>
        <confirm-modal
            name="confirm-test-mode-modal"
            :confirm-message="confirmMessage"
            :message-content="messageContent"
            button-size="auto"
            @confirm="enableTestModeProduct"
        />
    </div>
</template>
<script>
import AssetsList from '@/components/AssetsList';
import ProductGridAsset from '@/components/ProductGridAsset';
import ProductListAsset from '@/components/ProductListAsset';
import axios from 'axios';

export default {
    components: {
        AssetsList,
        ProductGridAsset,
        ProductListAsset
    },
    props: {
        funnel: {
            type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        bumps: {
            type: Array,
            required: true
        },
        isAdmin: {
            required: true
        },
        productsTab: {
            type: String,
            required: true
        },
       
        deleteProduct: {
            type: Function,
            required: true
        },
        deleteBump: {
            type: Function,
            required: true
        },
        openProjectionsModal: {
            type: Function,
            required: true
        },
        openTrackingModal: {
            type: Function,
            required: true
        },
        openCloneProduct: {
            type: Function,
            required: true
        },

    },
    data() {
        return {
            productId: null,
            status: null,
            confirmMessage: '',
            messageContent: ''
        };
    },
    computed: {
        gridStyle() {
            return {
                gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
            };
        },
        filteredProductList() {
            switch (this.productsTab) {
                case 'all':
                    return this.products;
                case 'frontend':
                    return this.products.filter(product => product.type === 1);
                case 'upsell_downsell':
                    return this.products.filter(product => product.type === 2);
                case 'order_bumps':
                    return this.products.filter(product => product.type === 3);
                case 'atc_order_bumps':
                    return this.bumps;
                default:
                    return [];
            }
        },
    },
    methods: {
        funnelPromoTools(funnel_id) {
            this.$router.push({
                name: 'funnel_resources',
                params: { id: funnel_id },
            });
        },
        openConfirmModal(id, status) {
            this.productId = id;
            this.status = status;
            this.confirmMessage = status
                ? this.$t('frontend_groovesell_menu_items_enable_test_mode')
                : this.$t('frontend_groovesell_menu_items_disable_test_mode');
            this.messageContent = status
                ? this.$t('frontend_groovesell_swal_enable_test_mode_status_true_text')
                : this.$t('frontend_groovesell_swal_enable_test_mode_status_false_text');
            this.$root.$emit('modal-open', 'confirm-test-mode-modal');
        },
        async enableTestModeProduct() {
            await axios.post('make-product-in-test-mode', {
                product_id: this.productId,
                is_test_mode_enabled: this.status,
            });

            this.products.map((v, i) => {
                if (v.id === this.productId) {
                    this.products[i].is_test_mode_enabled = this.status;
                }
            });
        },
        openCheckoutPage(productId) {
            this.$router.push({
                name: 'productsCheckoutLinks',
                params: {
                    id: this.funnel.id,
                    product_id: productId,
                },
            });
        },

        edit(productId, is_complete, tab) {
            const name = (this.productsTab == 'atc_order_bumps' || tab == 'atc_order_bumps') ? 'edit_bump' : 'edit_product';
            const params = { id: this.funnel.id, product_id: productId };
            const query = is_complete ? { validate: 1 } : {};
            this.$router.push({ name, params, query });
        },
        editBump(productId, is_complete) {
            this.edit(productId, is_complete, 'atc_order_bumps');
        },
        handleDeleteProduct(product, is_complete) {
            if (this.productsTab == 'atc_order_bumps') {
                this.deleteBump(product, is_complete);
            } else {
                this.deleteProduct(product, is_complete);
            }
        }
    }
}
</script>
