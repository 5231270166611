<template>
  <v-wait-component :for="waitsFor">
    <gd-loader slot="waiting"/>
    <slot></slot>
  </v-wait-component>
</template>
<script>
import GdLoader from '@groovepages/gd-ui-kit/components/Loader';

export default {
  components: {
    GdLoader
  },
  props: {
    for: [String, Array]
  },
  computed: {
    waitsFor() {
      return this.for;
    }
  }
};
</script>
