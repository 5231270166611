<template>
    <div>
        <gd-modal name="user-profile-modal" :title="$t('frontend_groovesell_billing_update_invoice')" width="80%"
            bg-type="regular">
            <div class="flex flex-wrap w-full bg-white rounded p-4">
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_billing_first_name')
                    }}</label>
                    <text-input v-model="purchaseDetail.buyer_fname" :validator="$v.purchaseDetail.buyer_fname"
                        :attribute="$t('frontend_first_name')" value=""
                        :placeholder="$t('frontend_groovesell_billing_first_name')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_billing_last_name')
                    }}</label>
                    <text-input v-model="purchaseDetail.buyer_lname" :validator="$v.purchaseDetail.buyer_lname"
                        :attribute="$t('frontend_last_name')" value=""
                        :placeholder="$t('frontend_groovesell_billing_last_name')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_phone') }}</label>
                    <text-input v-model="purchaseDetail.buyer_phone" :validator="$v.purchaseDetail.buyer_phone"
                        :attribute="$t('frontend_phone')" value="" :placeholder="$t('frontend_phone')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_company') }}</label>
                    <text-input v-model="purchaseDetail.buyer_company" :validator="$v.purchaseDetail.buyer_company"
                        :attribute="$t('frontend_company')" value="" :placeholder="$t('frontend_company')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_address') }}</label>
                    <text-input v-model="purchaseDetail.buyer_address" :validator="$v.purchaseDetail.buyer_address"
                        :attribute="$t('frontend_address')" value="" :placeholder="$t('frontend_address')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_city') }}</label>
                    <text-input v-model="purchaseDetail.buyer_city" :validator="$v.purchaseDetail.buyer_city"
                        :attribute="$t('frontend_city')" value="" :placeholder="$t('frontend_city')" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_country') }}
                    </label>

                    <country-select v-model="purchaseDetail.buyer_country" :validator="$v.purchaseDetail.buyer_country"
                        top-country="US" :class-name="country_select_classes" :country="purchaseDetail.buyer_country" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_state') }}
                    </label>
                    <region-select v-model="purchaseDetail.buyer_state" :validator="$v.purchaseDetail.buyer_state"
                        :class-name="region_select_classes" :country="purchaseDetail.buyer_country"
                        :region="purchaseDetail.buyer_state" />
                </div>
                <div class="flex flex-col w-1/2 mb-6 px-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_zipcode') }}</label>
                    <text-input v-model="purchaseDetail.buyer_postal_code" attribute="Zip" value="" placeholder="Zip" />
                </div>
            </div>

            <div class="mb-6 pr-6 flex justify-end">
                <gd-button size="auto" variant="primary" class="mb-4" @click="updateUserProfile">
                    {{ $t('frontend_groovesell_billing_update_invoice') }}
                </gd-button>
            </div>
        </gd-modal>
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    props: {
        purchaseDetail: {
            required: true
        }

    },
    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: ''
            }
        }
    },
    computed: {
        country_select_classes() {
            return 'w-full px-4 h-10 text-grey-600 outline-none rounded-lg border border-solid border-grey-200 rounded-lg bg-white';
        },

        region_select_classes() {
            return 'w-full px-4 h-10 text-grey-600 outline-none rounded-lg border border-solid border-grey-200 rounded-lg bg-white';
        },
    },
    validations: {
        purchaseDetail: {
            buyer_fname: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            buyer_lname: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            buyer_company: {
                required,
                maxLength: maxLength(50)
            },
            buyer_phone: {
                required
            },
            buyer_address: {
                required,
                maxLength: maxLength(60)
            },
            buyer_city: {
                required,
                maxLength: maxLength(40)
            },
            buyer_postal_code: {
                required,
                maxLength: maxLength(20)
            },
            buyer_country: {
                required
            },
            buyer_state: {
                required
            }
        },
    },
    methods: {
        async updateUserProfile() {
            this.$v.purchaseDetail.$touch();
            const isValid = !this.$v.purchaseDetail.$invalid;
            if (isValid) {
                const self = this;
                this.$loader.start('updateUserProfile');
                const purchaseDetails = await axios.post('/update-purchase-detail', self.purchaseDetail);
                const status = purchaseDetails.data.status.toLowerCase();
                if (status == 'success') {
                    this.$loader.end('updateUserProfile');
                    this.$emit('refresh')
                    this.$root.$emit('modal-close', 'user-profile-modal');
                } else {
                    this.alertProps = {
                        show: true,
                        variant: 'error',
                        title: this.$t('frontend_error'),
                        message: this.$t('frontend_groovesell_swal_billing_unable_update_details')
                    }
                    this.$loader.end('updateUserProfile');
                }
            }
        },
    }
}
</script>