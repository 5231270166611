<template>
    <gd-modal name="add-new-post-sale-integration-modal" :title="$t('frontend_product_step5_add_new_integration')"
        :no-overflow="true">
        <div class="flex flex-col m-8">
            <div class="flex flex-col w-full mb-6">
                <div class="w-full flex flex-col mr-2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_product_step8_type')
                    }}</label>
                    <gd-dropdown :v-bind="integrationType" :value="integrationType" :options="availableIntegrations"
                        size="full" @change="setValue($event)" @keyup="setValue($event)" />
                </div>
                <div v-if="integrationType && integrationType != ''" class="w-full -mx-4">
                    <config-menu :key="configMenuKey" :initial-values="componentConfigValues" :plugin-id="pluginId"
                        :resources-path="resourcesPath" :server-path="serverPath" :plugin="integrationType"
                        :app-data="pluginSiteData" layout="vertical" :is-dark="false" :show-button-borders="false"
                        :show-notifications-on-save="false" :opened-from="'groovesell'"
                        :btn-label="$t('frontend_sites_integration_modal_add_integration')" @update="addPostSaleIntegration"
                        @menu-updated="configMenuItemsUpdated"></config-menu>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import auth from '@groovepages/gd-lib/auth';
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';

export default {
    props: {
        integrationType: {
            type: String,
            required: true,
        },
        addPostSaleIntegration: {
            type: Function,
            required: true,
        },
        configMenuItemsUpdated: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            configMenuKey: 1,
            componentConfigValues: [],
            pluginId: '',

        }
    },
    computed: {
        pluginSiteData() {
            return this.$store.getters.pluginSiteSettings;
        },
        serverPath() {
            const digitalAppTitle = this.whitelabelAppData('digital')?.title.toLowerCase() || 'groovedigital';
            return `${window.gdEnv.VUE_APP_API_URL}/${digitalAppTitle}/plugins/call/`;
        },
        resourcesPath() {
            return `${window.gdEnv.VUE_APP_STORAGE_URL}/plugins/`;
        },
        availableIntegrations() {
            const user = auth.authenticatedUser();
            if (user.metadata && user.metadata.integrations) {
                const integrations = [];
                user.metadata.integrations.map((value, key) => {
                    const integrationType = value.type.split('-')[0];
                    const integrationPlugin = `${integrationType}-component`;
                    if (integrations.findIndex((x) => x.value == integrationPlugin) == -1) {
                        integrations.push({
                            text:
                                integrationType.charAt(0).toUpperCase() +
                                integrationType.slice(1),
                            value: integrationPlugin,
                        });
                    }
                });

                return integrations;
            }

            return [];
        },
    },
    methods: {
        setValue(value) {
            this.pluginId = '';
            this.componentConfigValues = [];
            this.configMenuKey = this.configMenuKey + 1;
            this.emitData('integrationType', value);
            this.emitData('pluginId', '');
            this.emitData('componentConfigValues', []);
            this.emitData('configMenuKey', this.configMenuKey);
        },
        emitData(key, value) {
            this.$emit('update-data', {
                key: key,
                value: value,
            });
        },
    }
}

</script>