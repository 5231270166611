<template>
  <gd-login :callback="callback" class="bg-secondary"></gd-login>
</template>

<script>
import GdLogin from '@groovepages/gd-ui-kit/components/Login';
import axios from 'axios';
import FunnelAffiliate from '@/models/FunnelAffiliate';

export default {
  layout: 'basic',
  components: {
    GdLogin,
  },
  data() {
    return {
      callback: (user) => {
        if (!user.auth_keycloak) {
          axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            'x-auth-provider': 'auth0',
          };
        } else {
          delete axios.defaults.headers.common['x-auth-provider'];
        }

        const self = this;
        this.$store.commit('login', user);

        if (typeof Tooltip !== 'undefined') {
          console.log('Setting value in tooltip');
          let userLevel = 0;

          if (user.roles.indexOf('groove_future_apps') >= 0) {
            userLevel = 1;
          } else if (user.roles.indexOf('groovepages_user') >= 0) {
            userLevel = 2;
          }

          Tooltip.API.updateUserData({
            userId: user.id,
            userLevel,
          });
        } else {
          console.log('Unable to set value in tooltip');
        }

        if (
          self.$route.query &&
          self.$route.query.redirect_funnel_id &&
          self.$route.query.redirect_funnel_id != ''
        ) {
          const funnelAffiliate = new FunnelAffiliate({});
          funnelAffiliate.funnel_id = self.$route.query.redirect_funnel_id;

          if (
            self.$route.query.gd_jv_cookie &&
            self.$route.query.gd_jv_cookie != ''
          ) {
            funnelAffiliate.gd_jv_cookie = self.$route.query.gd_jv_cookie;
          } else {
            funnelAffiliate.gd_jv_cookie = null;
          }

          if (
            self.$route.query.gs_jv_cookie &&
            self.$route.query.gs_jv_cookie != ''
          ) {
            funnelAffiliate.gs_jv_cookie = self.$route.query.gs_jv_cookie;
          } else {
            funnelAffiliate.gd_jv_cookie = null;
          }

          funnelAffiliate.save().then(() => {
            self.$router.push({
              name: 'promos_dashboard',
              params: { id: self.$route.query.redirect_funnel_id },
            });
          });
        } else {
          self.$router.push({ name: 'dashboard' });
        }
      },
    };
  },
};
</script>
