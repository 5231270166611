<template>
  <div>
    <div v-if="localFunnels.length" class="mt-4">
      <assets-list>
        <template slot="header-left">
          <div class="relative inline-flex">
            <gd-dropdown
              v-model="localSelectedOrder"
              size="auto"
              :options="sortOrderOptions"
              @change="changedSortOrder"
            />
          </div>
        </template>
        <template slot="gridContent">
          <div class="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8">
            <funnel-grid-asset
              v-for="(funnel, index) in localFunnels" :key="index"
              :funnel="funnel"
              @onManageProducts="products"
              @onManagePromotools="funnelPromoTools"
              @onEditFunnel="$emit('onEditFunnel', $event)"
              @onCloneFunnels="$emit('onCloneFunnels', $event)"
              @onDeleteFunnels="openConfirmModal"
            />
          </div>
        </template>
        <template slot="listContent">
          <div class="flex flex-col">
            <div v-for="(funnel, index) in localFunnels" :key="index">
              <funnel-list-asset
                :funnel="funnel"
                @onManageProducts="products"
                @onManagePromotools="funnelPromoTools"
                @onEditFunnel="$emit('onEditFunnel', $event)"
                @onCloneFunnels="$emit('onCloneFunnels', $event)"
                @onDeleteFunnels="openConfirmModal"
              />
            </div>
          </div>
        </template>
      </assets-list>
    </div>
    <div v-else class="mt-8">
      <p>{{ $t('frontend_funnels_you_currently') }}</p>
    </div>
    <confirm-modal
      name="confirm-funnel-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteFunnel"
    />
  </div>
</template>

<script>
import AssetsList from '@/components/AssetsList';
import FunnelGridAsset from '@/components/FunnelGridAsset';
import FunnelListAsset from '@/components/FunnelListAsset';
import Funnel from '@/models/Funnel';

export default {
  components: {
    AssetsList,
    FunnelGridAsset,
    FunnelListAsset,
  },
  props: {
    funnels: {
      type: Array,
      required: true,
    },
    selectedOrder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localFunnels: this.funnels,
      localSelectedOrder: this.selectedOrder,
      funnelId: null,
    };
  },
  computed: {
    sortOrderOptions() {
      return [
        { value: 'created_at', text: this.$t('frontend_groovesell_created_oldest_to_newest') },
        { value: '-created_at', text: this.$t('frontend_groovesell_created_newest_to_oldest') },
        { value: '-updated_at', text: this.$t('frontend_groovesell_updated_oldest_to_newest') },
        { value: 'updated_at', text: this.$t('frontend_groovesell_updated_newest_to_oldest') },
        { value: 'name', text: this.$t('frontend_name') + ' (A-Z)' },
        { value: '-name', text: this.$t('frontend_name') + ' (Z-A)' },
      ];
    },
  },
  methods: {
    products(funnel_id) {
      this.$emit('onManageProducts', funnel_id);
    },
    async changedSortOrder() {
      this.$loader.start('changedSortOrder');
      this.localFunnels = await Funnel.orderBy(this.localSelectedOrder).$get();
      this.$emit('updateFunnels', this.localFunnels);
      this.$emit('updateSelectedOrder', this.localSelectedOrder);
      this.$loader.end('changedSortOrder');
    },
    funnelPromoTools(funnel_id) {
      this.$router.push({
        name: 'funnel_resources',
        params: { id: funnel_id },
      });
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-funnel-delete-modal');
      this.funnelId = id;
    },
    async deleteFunnel() {
      this.$loader.start('deleteFunnel');
      const funnel = await Funnel.$find(this.funnelId);
      await funnel.delete();
      const deleted_funnel = this.localFunnels.map((item) => item.id).indexOf(this.funnelId);
      this.localFunnels.splice(deleted_funnel, 1);
      this.$emit('updateFunnels', this.localFunnels);
      this.$loader.end('deleteFunnel');
    },
  },
};
</script>
