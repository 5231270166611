<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="legalPricePointsTabs" :error-tabs="legalPricePointsErrorTabs" :current-tab="legalPricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleLegalPricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="legalPricePointsTab === 'legal_price_point_' + pricePoint.id
                    ">
                    <div class="flex flex-row w-1/2 mr-2">
                        <div v-for="(legalType, index) in legalTypes" :key="index" class="flex w-1/2 mr-4">
                            <gd-custom-radio v-model="pricePoint.legal_options.enabled"
                                :option="legalType"></gd-custom-radio>
                        </div>
                    </div>
                    <div v-if="pricePoint.legal_options.enabled == 1" class="flex flex-col w-1/2 mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_product_step5_legal_text')
                        }}</label>
                        <div class="flex flex-col">
                            <quill-editor ref="legalTextEditor" v-model="pricePoint.legal_options.html"
                                :options="legalEditorOptions" class="min-h-32"></quill-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor';

export default {
    components: {
        quillEditor,
    },
    props: {
        model: {
            required: true,
        },
        validation: {
            required: true,
        },
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },
    data() {
        return {
            localModel: this.model,
            legalPricePointsTab: '',
            legalEditorOptions: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['link'],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                    ],
                },
            },
            legalTypes: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step5_legal_types0_title'),
                    description: this.$t('frontend_product_step5_legal_types0_desc'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step5_legal_types1_title'),
                    description: this.$t('frontend_product_step5_legal_types1_desc'),
                },
            ],
        }
    },
    mounted() {
        this.legalPricePointsTab = this.localModel.pricing_points.length ? `legal_price_point_${this.localModel.pricing_points[0].id}` : ''
    },
    computed: {
        legalPricePointsTabs() {
            const pricePointTabs = [];

            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `legal_price_point_${this.localModel.pricing_points[i].id}`,
                });
            }

            return pricePointTabs;
        },
        legalPricePointsErrorTabs() {
            const legalPricePointErrorTabs = [];

            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error) {
                    legalPricePointErrorTabs.push(`legal_price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`);
                }
            }

            return legalPricePointErrorTabs;
        },
    },
    methods: {
        handleLegalPricePointsTabChange(tab) {
            this.legalPricePointsTab = tab;
        },
    },
    beforeDestroy() {
        if (typeof this.legalTextEditor !== 'undefined') {
            this.legalTextEditor.destroy();
        }
    }



}

</script>