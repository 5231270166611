<template>
  <div class="w-full min-h-full bg-white p-4">
    <v-wait for="cookie">
      <div slot="waiting">
        <div class="mt-4 text-lg text-grey-700 font-medium text-center w-full">
          {{ $t('frontend_resources_connecting', {appTitle: whitelabelAppData('sell').title}) }}
        </div>
      </div>
      <div></div>
    </v-wait>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  layout: 'iframe',
  metaInfo() {
    return { title: this.whitelabelAppData('sell').title };
  },
  data() {
    return {
      funnelId: 0,
      gdCookie: '',
      gsCookie: '',
    };
  },
  async created() {
    this.$wait.start('cookie');
    await this.handleRouteQuery();
    this.$wait.end('cookie');
  },
  methods: {
    async handleRouteQuery() {
      const { query } = this.$route;

      if (query.funnel_id && query.funnel_id != '') {
        this.funnelId = query.funnel_id;

        if (query.gd_cookie && query.gd_cookie != '') {
          this.gdCookie = query.gd_cookie;
        }

        if (query.gs_cookie && query.gs_cookie != '') {
          this.gsCookie = query.gs_cookie;
        }

        if (this.gdCookie != '' && this.gsCookie != '') {
          await this.setCookies();
          this.redirectToUrl(query.url);
        }
      }
    },
    async setCookies() {
      const cookieOptions = {
        expires: 3650,
        sameSite: 'None',
        secure: true,
      };

      await Cookies.set(`gd_jv_f${this.funnelId}`, this.gdCookie, cookieOptions);
      await Cookies.set(`gs_jv_f${this.funnelId}`, this.gsCookie, cookieOptions);
    },
    redirectToUrl(url) {
      window.location.replace(url);
    },
  },
};
</script>
