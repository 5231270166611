<template>
    <gd-modal name="leaderboards-edit-prize-modal" :title="$t('frontend_groovesell_edit_prize')" height="80%" width="80%"
        :no-overflow="true" allowOverflow>
        <div class="flex flex-row m-3 mb-1">
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }}</label>
                <text-input v-model="localEditSinglePriceModel.pricePointName" :attribute="$t('frontend_name')"
                    :value="localEditSinglePriceModel.pricePointName"
                    :validator="$v.localEditSinglePriceModel.pricePointName" :placeholder="$t('frontend_name')" />
            </div>
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_value') }}</label>
                <gd-currency-input v-model="localEditSinglePriceModel.pricePointValue"
                    :currency-code="pricePointCurrency" />
            </div>
        </div>

        <div class="flex flex-row m-3 mb-1">
            <div class="flex flex-col w-full mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_position') }}</label>
                <div>
                    <gd-dropdown
                        v-model="localEditSinglePriceModel.pricePositionOptionsValue"
                        class="w-full"
                        max-height="165px"
                        :options="localEditSinglePriceModel.pricePositionOptions"
                    />

                    <div v-if="$v.localEditSinglePriceModel.pricePositionOptionsValue.$error
                        " class="text-red text-sm">
                        {{
                            $t(
                                'frontend_plugin_groovesell_pricePositionOptionsValue_error'
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">Thumbnail</label>
                <image-picker v-model="localEditSinglePriceModel.prize_image" :primary="true"></image-picker>
            </div>
        </div>

        <div class="flex justify-end mr-5 mb-5">
            <gd-button class="mb-5" variant="primary" @click="updatePrize">{{ $t('frontend_update') }}</gd-button>
        </div>
    </gd-modal>
</template>
<script>
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
export default {
    props: {
        editSinglePriceModel: {
            type: Object,
            required: true,
        },
        pricePointCurrency: {
            type: String,
            required: true,
        },
        updatePrize: {
            type: Function,
            required: true,
        },
    },
    validations: {

        localEditSinglePriceModel: {
            pricePointName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(255),
            },
            pricePointValue: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(255),
            },
            pricePositionOptionsValue: {
                required,
            },
        },
    },
    components: {
        ImagePicker,
    },
    watch: {
        localEditSinglePriceModel: {
            handler: function (val) {
                this.$v.localEditSinglePriceModel.$touch();
                this.$emit('update-attributes', {
                    key: 'editSinglePriceModel',
                    value: val,
                });
            },
            deep: true,
        },
    },
    data() {
        return {
            localEditSinglePriceModel: this.editSinglePriceModel,
        };
    },
}
</script>
