<template>
  <div class="w-full">
    <info-panel class="my-4 relative info-panel-wrapper-inner w-full">
      <template slot="content">
        <div class="w-full h-full rounded-t-lg relative">
          <div
            class="
              show_panel
              absolute
              w-full
              h-full
              rounded-t-lg
              bg-grey-transparent
              opacity-0
              hover:opacity-100
              flex
              items-center
              justify-center
            "
          >
            <gd-button variant="primary" size="md" @click="sendToEditProduct">
              {{ $t('frontend_product_edit') }}
            </gd-button>
          </div>
          <div class="w-full h-44 flex justify-center items-center text-primary py-2">
            <img
              class="h-full w-full object-contain"
              v-if="productImage"
              :src="productImage"
            />
            <gifts-icon v-else class="w-full h-full fill-current" />
          </div>
          <div class="flex justify-end bottom-0 right-0 absolute mb-1">
            <gd-tag
              v-if="product.is_test_mode_enabled"
              class="mr-1"
              variant="warning"
              size="sm"
            >
              {{ $t('frontend_groove_sell_span_test_mode') }}
            </gd-tag>
            <gd-tag class="mr-1" variant="primary" size="sm">
              {{ productTypeName }}
            </gd-tag>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="w-full text-sm relative">
          <div class="h-full px-4 flex items-center">
            {{ product.name }}
          </div>

          <div
            class="
              flex
              justify-between
              border-t border-solid border-grey-200
              w-full
              show_panel
              opacity-0
              hover:opacity-100
              absolute
              top-0
              bg-white
              h-full
              p-4
              items-center
              rounded-b-lg
            "
          >
            <div class="flex items-center gap-x-4 text-grey-600">
              <span v-for="(option, index) in menuItemsIcons" :key="index">
                <span
                  class="fill-current cursor-pointer"
                  @click="option.callback()"
                  v-tooltip="option.text"
                >
                  <component :is="option.icon" class="w-5 h-5" />
                </span>
              </span>
            </div>
            <drop-menu :menu-items="menuItems" dropMenuClass="absolute bottom-0 mt-13 z-40" small></drop-menu>
          </div>
        </div>
      </template>
    </info-panel>
  </div>
</template>

<script>
import ChartBarIcon from '@/assets/images/icons/ChartBarIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import CloneIcon from '@/assets/images/icons/CloneIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';
import GdTag from '@groovepages/gd-ui-kit/components/Tag';

export default {
  name: 'ProductGridAsset',
  components: {
    GiftsIcon,
    GdTag,
  },
  props: {
    product: {},
    productType: {},
    admin: {
      default: false,
    },
    funnel: {},
  },
  computed: {
    productTypeName() {
      let productTypeName = '';

      if (this.productType != 'atc_order_bumps') {
        if (this.product.type == 1) productTypeName = this.$t('frontend_groovesell_tag_frontend');
        if (this.product.type == 2) productTypeName = this.$t('frontend_product_index_tab_upsell_downsell');
        if (this.product.type == 3) productTypeName = this.$t('frontend_product_step1_order_bump');
      } else {
        productTypeName = this.$t('upsell_add_to_cart_order_bumps');
      }

      return productTypeName;
    },
    productImage() {
      let hasImage = false;

      if (
        this.product.checkout_page_options &&
        this.product.checkout_page_options != null &&
        this.product.checkout_page_options.product_image &&
        this.product.checkout_page_options.product_image != ''
      )
        hasImage = this.product.checkout_page_options.product_image;

      return hasImage;
    },
    isProductComplete() {
      let productComplete = true;
      if (this.product.is_wizard_complete && this.product.is_complete) {
        productComplete = false;
      }
      return productComplete;
    },
    menuItemsIcons() {
      const menuItemsIcons = [];
      menuItemsIcons.push({
        icon: PencilIcon,
        text: this.$t('frontend_product_edit'),
        callback: () =>
          this.$emit('onProductEdit', this.product.id, this.isProductComplete),
      });
      if (this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: ShoppingCartIcon,
          text: this.$t('forntend_checkout_links'),
          callback: () => this.$emit('onProductCheckOutLinks', this.product.id),
        });
      }
      if (this.admin && this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: ChartBarIcon,
          text: this.$t('frontend_groovesell_menu_items_projections'),
          callback: () => this.$emit('onProductProjection', this.product.id),
        });
      }
      if (this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: CloneIcon,
          text: this.$t('frontend_groovesell_menu_items_clone_product'),
          callback: () => this.$emit('onProductClone', this.product.id),
        });
      }
      menuItemsIcons.push({
        icon: MegaphoneIcon,
        text: this.$t('frontend_funnels_affiliate_signup_promo_tools'),
        callback: () => this.$emit('onManagePromotools', this.funnel.id),
      });
      return menuItemsIcons;
    },
    menuItems() {
      const menuItems = [];
      if (this.productType != 'atc_order_bumps') {
        if (!this.product.is_test_mode_enabled) {
          menuItems.push({
            text: this.$t('frontend_groovesell_menu_items_enable_test_mode'),
            callback: () =>
              this.$emit('onProductEnableTestMode', this.product.id, 1),
          });
        } else {
          menuItems.push({
            text: this.$t('frontend_groovesell_menu_items_disable_test_mode'),
            callback: () =>
              this.$emit('onProductEnableTestMode', this.product.id, 0),
          });
        }
      }
      if (this.productType != 'atc_order_bumps') {
        menuItems.push({
          text: this.$t('frontend_groovesell_menu_items_tracking_links'),
          callback: () => this.$emit('onProductTracking', this.product.id),
        });
      }
      if (this.product.is_main != true) {
        menuItems.push({
          text: this.$t('frontend_groovesell_menu_items_delete_product'),
          callback: () => this.$emit('onProductDelete', this.product.id),
        });
      }
      if (
        this.productType == 'all' &&
        !this.product.hasOwnProperty('is_main')
      ) {
        const Trackingindex = menuItems.findIndex(
          (x) => x.text == this.$t('frontend_groovesell_menu_items_tracking_links')
        );
        menuItems.splice(Trackingindex, 1);
        const checkOutLinkindex = menuItems.findIndex(
          (x) => x.text == this.$t('forntend_checkout_links')
        );
        menuItems.splice(checkOutLinkindex, 1);
        const cloneindex = menuItems.findIndex(
          (x) => x.text == this.$t('frontend_product_clone')
        );
        menuItems.splice(cloneindex, 1);
      }

      return menuItems;
    },
  },
  methods: {
    backgroundImageUrl(url) {
      if (url) {
        return `url("${url}")`;
      }

      return '';
    },
    sendToEditProduct() {
      this.$emit('onProductEdit', this.product.id, this.isProductComplete);
    },
  },
};
</script>
