<template>
  <div class="flex w-full flex-col justify-center">
    <div
      class="relative border w-full mr-6 bg-white rounded-lg p-6 border-solid border-grey-300"
      :class="{
        'hover:cursor-pointer': optionEnabled,
        'hover:cursor-not-allowed text-grey-600': !optionEnabled,
        'bg-primary-lightest shadow-outline-blue': activeOption === optionValue
      }"
      tabindex="0"
      @click="updateActiveOption"
      @keydown.space="updateActiveOption"
    >
      <div
        v-if="optionEnabled"
        class="flex justify-end items-center mb-3 absolute top-0 right-0 mt-2 mr-2 text-green-dark"
      >
        <check-circle-icon class="w-5 h-5 fill-current" />
      </div>

      <div class="flex justify-center items-center mb-3">
         <slot name="icon" />
      </div>

      <div class="mb-1 font-semibold text-center">
        <p class="text-md">{{ optionName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@/assets/images/icons/CheckCircleIcon.svg';

export default {
  name: 'FontAwesomeRadio',
  components: {
    CheckCircleIcon
  },
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption'
  },
  props: [
    'optionName',
    'activeOption',
    'optionEnabled',
    'optionValue'
  ],
  methods: {
    updateActiveOption() {
      if (this.optionEnabled) {
        this.$emit('onUpdateOption', this.optionValue);
      }
    }
  }
};
</script>

<style>
.checkoutRadioIcon {
  width: 80px;
  height: 80px;
}
</style>
