<template>
  <div v-if="hasLogoutPageAds" class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="logoutPageAdsTabs"
        :current-tab="logoutPageAdsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleLogoutPageAdsTabChange', $event)"
      />
    </div>
    <div class="mt-2">
      <div v-for="(ad, index) in funnelResource.logout_page_ads" :key="index">
        <div v-if="isLogoutPageAdTabActive(ad.id)" class="pb-8">
          <div class="flex flex-col justify-center align-center text-center items-center w-full mb-6">
            <div v-html="ad.body"></div>
          </div>

          <div class="w-full flex flex-row justify-center mt-2">
            <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/3 xl:w-1/3"></div>
            <div class="w-4/6 sm:w-4/6 md:w-4/6 lg:w-1/3 xl:w-1/3 flex flex-row">
              <textarea
                v-model="ad.body"
                :placeholder="$t('frontend_resource_index_embed_code')"
                class="h-64 w-full resize-y border rounded border-grey-200 p-2"
                @click="handleTextareaClick"
                readonly="readonly"
              ></textarea>
              <div class="ml-2 flex items-center">
                <gd-button
                  v-clipboard:copy="ad.body"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  size="iconlg"
                  variant="primary"
                  :title="$t('frontend_resource_copy_embed')"
                >
                  <copy-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
            <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/3 xl:w-1/3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_resources_no_logout_page') }}
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    logoutPageAdsTab: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasLogoutPageAds() {
      return (
        this.funnelResource &&
        this.funnelResource.logout_page_ads &&
        this.funnelResource.logout_page_ads.filter((el) => {
          return el.enabled;
        }).length
      );
    },
    logoutPageAdsTabs() {
      return this.enabledLogoutPageAds.map((ad) => ({
        title: ad.name,
        value: `logout_page_ad_tab_${ad.id}`,
      }));
    },
    enabledLogoutPageAds() {
      return (
        this.funnelResource?.logout_page_ads?.filter((ad) => ad.enabled) || []
      );
    },
  },
  methods: {
    isLogoutPageAdTabActive(adId) {
      return this.logoutPageAdsTab === `logout_page_ad_tab_${adId}`;
    },
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
