<template>
    <gd-modal name="advanced-filters-modal" :title="$t('frontend_groovesell_modal_advanced_filters')" height="90vh"
        width="80%" bg-type="regular" allowOverflow>
        <template slot="actions">
            <div class="justify-end">
                <gd-button
                    size="md"
                    :variant="defaultFilters ? 'base' : 'primary'"
                    :disabled="defaultFilters"
                    @click="emitStartSearch"
                >
                    {{ $t('frontend_analytics_apply_filters') }}
                </gd-button>
            </div>
        </template>
        <div class="flex flex-col m-6 mb-4">
            <div class="flex flex-col w-full mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_funnels')
                }}</label>
                <treeselect v-model="LocalfilteredFunnels" :multiple="true" :options="funnelsDropdown"
                    :placeholder="$t('frontend_groovesell_funnels_filter')" @input="filteredFunnelsChanged" />
            </div>
            <div class="flex flex-col w-full mr-2 mt-4">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_affiliates')
                }}</label>
                <div class="w-full flex flex-row">
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                                    'border-primary': filteredAffiliatesType === 1,
                                    'border-grey-200': filteredAffiliatesType !== 1,
                                }" @click="updateFilteredAffiliatesType(1)"
                            @keydown.space="updateFilteredAffiliatesType(1)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 1, 'border-grey': filteredAffiliatesType !== 1,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredAffiliatesType === 1,
                                        'text-grey-700': filteredAffiliatesType !== 1,
                                    }">{{ $t('frontend_analytics_all') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_all_transactions') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredAffiliatesType === 2, 'border-grey-200': filteredAffiliatesType !== 2,
                        }" @click="updateFilteredAffiliatesType(2)" @keydown.space="updateFilteredAffiliatesType(2)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 2, 'border-grey': filteredAffiliatesType !== 2,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredAffiliatesType === 2,
                                        'text-grey-700': filteredAffiliatesType !== 2,
                                    }">{{ $t('frontend_analytics_only_affiliates') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_the_sales') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredAffiliatesType === 3, 'border-grey-200': filteredAffiliatesType !== 3,
                        }" @click="updateFilteredAffiliatesType(3)" @keydown.space="updateFilteredAffiliatesType(3)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 3, 'border-grey': filteredAffiliatesType !== 3,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredAffiliatesType === 3,
                                        'text-grey-700': filteredAffiliatesType !== 3,
                                    }">{{ $t('frontend_analytics_only_non_affiliates') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_sales_without') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredAffiliatesType === 4, 'border-grey-200': filteredAffiliatesType !== 4,
                        }" @click="updateFilteredAffiliatesType(4)" @keydown.space="updateFilteredAffiliatesType(4)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 4, 'border-grey': filteredAffiliatesType !== 4,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredAffiliatesType === 4,
                                        'text-grey-700': filteredAffiliatesType !== 4,
                                    }">{{ $t('frontend_groovesell_customers_specific_aff') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_sales_made_by') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="filteredAffiliatesType == 4" class="w-1/2 mb-4">
                    <gd-button size="auto" variant="primary" class="mt-3" @click="openFilteredAffiliatesModal">{{
                        filteredAffiliates.length }}
                        {{ $t('frontend_analytics_affiliates_selected') }}</gd-button>
                </div>
            </div>
            <div class="flex flex-col w-full mr-2 mt-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_second_tier_affiliates')
                }}</label>
                <div class="w-full flex flex-row">
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                                    'border-primary': filteredSecondTierAffiliatesType === 1,
                                    'border-grey-200': filteredSecondTierAffiliatesType !== 1,
                                }" @click="updateFilteredSecondTierAffiliatesType(1)"
                            @keydown.space="updateFilteredSecondTierAffiliatesType(1)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredSecondTierAffiliatesType === 1, 'border-grey': filteredSecondTierAffiliatesType !== 1,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredSecondTierAffiliatesType === 1,
                                        'text-grey-700': filteredSecondTierAffiliatesType !== 1,
                                    }">{{ $t('frontend_analytics_all') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_all_transactions') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredSecondTierAffiliatesType === 2, 'border-grey-200': filteredSecondTierAffiliatesType !== 2,
                        }" @click="updateFilteredSecondTierAffiliatesType(2)" @keydown.space="updateFilteredSecondTierAffiliatesType(2)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredSecondTierAffiliatesType === 2, 'border-grey': filteredSecondTierAffiliatesType !== 2,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredSecondTierAffiliatesType === 2,
                                        'text-grey-700': filteredSecondTierAffiliatesType !== 2,
                                    }">{{ $t('frontend_analytics_only_affiliates') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_the_sales') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredSecondTierAffiliatesType === 3, 'border-grey-200': filteredSecondTierAffiliatesType !== 3,
                        }" @click="updateFilteredSecondTierAffiliatesType(3)" @keydown.space="updateFilteredSecondTierAffiliatesType(3)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredSecondTierAffiliatesType === 3, 'border-grey': filteredSecondTierAffiliatesType !== 3,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredSecondTierAffiliatesType === 3,
                                        'text-grey-700': filteredSecondTierAffiliatesType !== 3,
                                    }">{{ $t('frontend_analytics_only_non_affiliates') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_sales_without') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex w-1/4 mr-4">
                        <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                            'border-primary': filteredSecondTierAffiliatesType === 4, 'border-grey-200': filteredSecondTierAffiliatesType !== 4,
                        }" @click="updateFilteredSecondTierAffiliatesType(4)" @keydown.space="updateFilteredSecondTierAffiliatesType(4)">
                            <label class="flex cursor-pointer">
                                <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                    'border-grey bg-primary text-primary activeOptionRadio': filteredSecondTierAffiliatesType === 4, 'border-grey': filteredSecondTierAffiliatesType !== 4,
                                }"></span>
                                <div class="ml-2 flex flex-col w-full">
                                    <label class="font-semibold" :class="{
                                        'text-primary': filteredSecondTierAffiliatesType === 4,
                                        'text-grey-700': filteredSecondTierAffiliatesType !== 4,
                                    }">{{ $t('frontend_groovesell_customers_specific_aff') }}</label>
                                    <div class="text-grey-500">
                                        {{ $t('frontend_analytics_only_sales_made_by') }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="filteredSecondTierAffiliatesType == 4" class="w-1/2 mb-4">
                    <gd-button size="auto" variant="primary" class="mt-3" @click="openFilteredSecondTierAffiliatesModal">{{
                        filteredSecondTierAffiliates.length }}
                        {{ $t('frontend_analytics_affiliates_selected') }}</gd-button>
                </div>
            </div>
            <div class="flex flex-col w-full mr-2 mt-2 mb-8">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_advance_transaction')
                }}</label>
                <gd-dropdown
                    v-model="localFilteredAdvancedTransactions"
                    size="full"
                    :placeholder="$t('frontend_affiliate_transactions_advance_transaction_filter')"
                    :options="filteredTransactionOptions"
                    @change="filteredAdvancedTransactionsChanged"
                />
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';

export default {
    components: {
        Treeselect,
    },
    props: {
        filteredFunnels: {
            type: Array,
            default: () => [],
        },
        funnelsDropdown: {
            type: Array,
            default: () => [],
        },
        filteredAffiliates: {
            type: Array,
            default: () => [],
        },
        filteredSecondTierAffiliates: {
            type: Array,
            default: () => [],
        },
        filteredAdvancedTransactions: {
            default: 0,
        },
    },
    data() {
        return {
            localFilteredAdvancedTransactions: this.filteredAdvancedTransactions,
            LocalfilteredFunnels: this.filteredFunnels
        }
    },
    watch: {
        filteredFunnels: {
            handler: function (val, oldVal) {
                this.LocalfilteredFunnels = val;
            },
            deep: true
        },
        filteredAdvancedTransactions: {
            handler: function (val, oldVal) {
                this.localFilteredAdvancedTransactions = val;
            },
            deep: true
        },
    },

    computed: {
        defaultFilters() {
            return this.localFilteredAdvancedTransactions == 0 &&
            this.LocalfilteredFunnels?.length == 0 &&
            this.filteredAffiliates?.length == 1 && 
            this.filteredAffiliates[0].id == 0 &&
            this.filteredAffiliates[0].name === 'All' &&
            this.filteredSecondTierAffiliates?.length == 1 &&
            this.filteredSecondTierAffiliates[0].id == 0 &&
            this.filteredSecondTierAffiliates[0].name === 'All';
        },
        filteredAffiliatesType() {
            const assignedTo = this.filteredAffiliates;
            if (assignedTo.length == 0) {
                return 4;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == 0) {
                return 1;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == -1) {
                return 3;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == -2) {
                return 2;
            }
            return 4;
        },
        filteredSecondTierAffiliatesType() {
            const assignedTo = this.filteredSecondTierAffiliates;
            if (assignedTo.length == 0) {
                return 4;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == 0) {
                return 1;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == -1) {
                return 3;
            }
            if (assignedTo.length > 0 && assignedTo[0].id == -2) {
                return 2;
            }
            return 4;
        },
        filteredTransactionOptions() {
            return [
                { value: 0, text: this.$t('frontend_analytics_all') },
                { value: 1, text: this.$t('frontend_analytics_first_sale_or') },
                { value: 2, text: this.$t('frontend_analytics_first_sale_after_trail') },
                { value: 3, text: this.$t('frontend_analytics_rebills_only') }
            ];
        },
    },

    methods: {
        filteredAdvancedTransactionsChanged() {
            this.$emit('filter-changed', {
                key: 'filteredAdvancedTransactions',
                value: this.localFilteredAdvancedTransactions
            });
        },
        filteredFunnelsChanged() {
            this.$emit('filter-changed', {
                key: 'filteredFunnels',
                value: this.LocalfilteredFunnels,
            })
        },
        updateFilteredAffiliatesType(type) {
            if (this.filteredAffiliatesType != type) {
                let filteredAffiliates = [];
                if (type == 1) {
                    filteredAffiliates = [{ id: 0, name: 'All' }];
                } else if (type == 2) {
                    filteredAffiliates = [{ id: -2, name: 'Only Affiliates' }];
                } else if (type == 3) {
                    filteredAffiliates = [{ id: -1, name: 'Only Non-Affiliates' }];
                }
                this.$emit('filter-changed', {
                    key: 'filteredAffiliates',
                    value: filteredAffiliates,
                    startSearch: false
                })
            }
        },
        updateFilteredSecondTierAffiliatesType(type) {
            if (this.filteredSecondTierAffiliatesType != type) {
                let filteredAffiliates = [];
                if (type == 1) {
                    filteredAffiliates = [{ id: 0, name: 'All' }];
                } else if (type == 2) {
                    filteredAffiliates = [{ id: -2, name: 'Only Affiliates' }];
                } else if (type == 3) {
                    filteredAffiliates = [{ id: -1, name: 'Only Non-Affiliates' }];
                }
                this.$emit('filter-changed', {
                    key: 'filteredSecondTierAffiliates',
                    value: filteredAffiliates,
                    startSearch: false
                })
            }
        },
        openFilteredAffiliatesModal() {
            this.$root.$emit('modal-open', 'filters-affiliates-modal');
        },
        openFilteredSecondTierAffiliatesModal() {
            this.$root.$emit('modal-open', 'filters-second-tier-affiliates-modal');
        },
        emitStartSearch() {
            if (this.filteredAffiliates.length === 0) {
                this.$emit('filter-changed', {
                    key: 'filteredAffiliates',
                    value: [{ id: -2, name: 'Only Affiliates' }],
                });
            }
            if (this.filteredSecondTierAffiliates.length === 0) {
                this.$emit('filter-changed', {
                    key: 'filteredSecondTierAffiliates',
                    value: [{ id: -2, name: 'Only Affiliates' }],
                });
            }

            this.$emit('apply-filters');
            this.$root.$emit('modal-close', 'advanced-filters-modal');
        },
    }
}
</script>