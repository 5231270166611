<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="socialMediaPostsTabs"
        :current-tab="socialMediaPostsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleSocialMediaPostsTabChange"
      />
    </div>

    <div class="mt-2">
      <div v-if="socialMediaPostsTab == 'facebook'">
        <div v-if="hasEnabledFacebookPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="facebookPostsTabs"
              :current-tab="facebookPostsTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleFacebookPostsTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in funnelResource.social_media_posts.facebook" :key="index">
              <div v-if="isActiveFacebookPostTab(post)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_post') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="post.post"
                        class="swipesEditor w-full"
                        :placeholder="$t('frontend_resources_post')"
                        :readonly="true"
                      />
                    </div>
                    <div class="w-1/2 flex flex-row justify-end">
                      <div>
                        <vue-goodshare-facebook
                          :page_url="post.affiliate_link"
                          :page_description="post.post"
                          :quote="post.post"
                          title_social="Share On Facebook"
                          has_icon
                        ></vue-goodshare-facebook>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_facebook_posts') }}
        </div>
      </div>
      <div v-else-if="socialMediaPostsTab == 'twitter'">
        <div v-if="hasEnabledTwitterPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="twitterTweetsTabs"
              :current-tab="twitterTweetsTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleTwitterTweetsTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in funnelResource.social_media_posts.twitter" :key="index">
              <div v-if="isActiveTwitterPostTab(post)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_tweet') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="post.tweet"
                        class="swipesEditor w-full"
                        :placeholder="$t('frontend_resources_tweet')"
                        :readonly="true"
                      />
                    </div>
                    <div class="w-1/2 flex flex-row justify-end">
                      <div>
                        <vue-goodshare-twitter
                          :page_url="post.affiliate_link"
                          :page_title="post.tweet"
                          :page_description="post.tweet"
                          :quote="post.tweet"
                          title_social="Share On Twitter"
                          has_icon
                        ></vue-goodshare-twitter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_tweets') }}
        </div>
      </div>
      <div v-else-if="socialMediaPostsTab == 'linkedin'">
        <div v-if="hasEnabledLinkedInPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="linkedinPostsTabs"
              :current-tab="linkedinPostsTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleLinkedinPostsTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in funnelResource.social_media_posts.linkedin" :key="index">
              <div v-if="isActiveLinkedinPostTab(post)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_post') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="post.post"
                        class="swipesEditor w-full"
                        :placeholder="$t('frontend_resources_post')"
                        :readonly="true"
                      />
                    </div>
                    <div class="w-1/2 flex flex-row justify-end">
                      <div>
                        <vue-goodshare-linked-in
                          :page_url="post.affiliate_link"
                          :page_description="post.post"
                          :quote="post.post"
                          title_social="Share On LinkedIn"
                          has_icon
                        ></vue-goodshare-linked-in>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_linked_in') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue';
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue';
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue';

export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareLinkedIn,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    facebookPostsTab: {
      type: String,
      default: '',
    },
    twitterTweetsTab: {
      type: String,
      default: '',
    },
    linkedinPostsTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      socialMediaPostsTabs: [
        { title: this.$t('frontend_resources_facebook'), value: 'facebook' },
        { title: this.$t('frontend_resources_twitter'), value: 'twitter' },
        { title: this.$t('frontend_resources_linkedin'), value: 'linkedin' },
      ],
      socialMediaPostsTab: 'facebook',
    };
  },
  computed: {
    facebookPostsTabs() {
      return this.generateSocialMediaTabs(
        this.funnelResource.social_media_posts.facebook,
        'fb_post_tab_'
      );
    },
    twitterTweetsTabs() {
      return this.generateSocialMediaTabs(
        this.funnelResource.social_media_posts.twitter,
        'twitter_tweet_tab_'
      );
    },
    linkedinPostsTabs() {
      return this.generateSocialMediaTabs(
        this.funnelResource.social_media_posts.linkedin,
        'linkedin_post_tab_'
      );
    },
    hasEnabledFacebookPosts() {
      return (
        this.funnelResource &&
        this.funnelResource.social_media_posts &&
        this.funnelResource.social_media_posts.facebook &&
        this.funnelResource.social_media_posts.facebook.some(post => post.enabled)
      );
    },
    hasEnabledTwitterPosts() {
      return (
        this.funnelResource &&
        this.funnelResource.social_media_posts &&
        this.funnelResource.social_media_posts.twitter &&
        this.funnelResource.social_media_posts.twitter.some(post => post.enabled)
      );
    },
    hasEnabledLinkedInPosts() {
      return (
        this.funnelResource &&
        this.funnelResource.social_media_posts &&
        this.funnelResource.social_media_posts.linkedin &&
        this.funnelResource.social_media_posts.linkedin.some(post => post.enabled)
      );
    },
  },
  methods: {
    handleSocialMediaPostsTabChange(newTab) {
      this.socialMediaPostsTab = newTab;
    },
    generateSocialMediaTabs(posts, valuePrefix) {
      const tabs = [];
      if (this.funnelResource && posts) {
        for (let i = 0; i < posts.length; i++) {
          if (posts[i].enabled) {
            tabs.push({
              title: posts[i].name,
              value: `${valuePrefix}${posts[i].id}`,
            });
          }
        }
      }
      return tabs;
    },
    filterEnabledPosts(posts) {
      return posts.filter(post => post.enabled);
    },
    isActiveFacebookPostTab(post) {
      return this.facebookPostsTab === `fb_post_tab_${post.id}`;
    },
    isActiveTwitterPostTab(post) {
      return this.twitterTweetsTab === `twitter_tweet_tab_${post.id}`;
    },
    isActiveLinkedinPostTab(post) {
      return this.linkedinPostsTab === `linkedin_post_tab_${post.id}`;
    },
  },
};
</script>
