<template>
  <div>
    <div
      v-if="hasBlogReviews"
      class="flex flex-col"
    >
      <div
        class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4"
      >
        <gd-tabs
          :tabs="blogReviewsTabs"
          :current-tab="localBlogReviewsTab"
          :error-tabs="blogReviewsErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleBlogReviewsTabChange"
          :enable-dragging="true"
          @updateTabOrder="handleBlogReviewsTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createBlogReviews"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div
          v-for="(review, index) in localFunnelResource.blog_reviews"
          :key="index"
        >
          <div
            v-if="isBlogReviewTabActive(review)"
            class="pb-8"
          >
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label
                  class="font-semibold text-sm text-grey-700 mb-2"
                >
                  {{ $t('frontend_resources_name') }}
                </label>
                <text-input
                  v-model="review.name"
                  :validator="$v.localFunnelResource.blog_reviews.$each[index].name"
                  :attribute="$t('frontend_groovesell_review_name')"
                  value="review.name"
                  :placeholder="$t('frontend_resources_name')"
                />
              </div>

              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle
                  class="h-6 mt-2"
                  v-model="review.enabled"
                  :value="review.enabled"
                />
                <gd-button
                  variant="outline"
                  size="iconlg"
                  class="ml-4"
                  @click="openConfirmModal(index)"
                >
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-col w-1/2 mb-6">
              <label
                class="font-semibold text-sm text-grey-700 mb-2"
              >
                {{ $t('frontend_resources_title') }}
              </label>
              <text-input
                v-model="review.title"
                :validator="$v.localFunnelResource.blog_reviews.$each[index].title"
                :attribute="$t('frontend_groovesell_review_title')"
                value="review.title"
                :placeholder="$t('frontend_resources_title')"
              />
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex flex-row w-1/2">
                <div class="w-1/2">
                  <label
                    class="font-semibold text-sm text-grey-700 mb-2"
                  >
                    {{ $t('frontend_resources_post') }}
                  </label>
                </div>
                <div class="flex flex-row justify-end mb-2 w-1/2">
                  <div class="flex flex-row">
                    <div
                      class="font-semibold text-sm text-grey-700 mr-2"
                    >
                      {{ $t('frontend_resources_view_html') }}
                    </div>
                    <gd-toggle
                      v-model="isBlogReviewsPageHTMLView"
                      :value="isBlogReviewsPageHTMLView"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-if="isBlogReviewsPageHTMLView"
                    v-model="review.htmlBody"
                    class="swipesEditor w-full"
                    value="review.htmlBody"
                    :placeholder="$t('frontend_resources_post')"
                    variant="dark"
                  />
                  <quill-editor
                    v-else
                    :ref="'reviewsEditor_' + index"
                    v-model="review.htmlBody"
                    :options="reviewsEditorOptions"
                    class="swipesEditor w-full"
                  ></quill-editor>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToBlogReviews(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActionsBlogReviews(index)"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col w-full mt-32 mb-6">
              <div class="flex flex-row w-1/2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_text_view') }}
                </label>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <textarea
                    v-model="review.textBody"
                    value="review.textBody"
                    :placeholder="$t('frontend_resources_text_body')"
                    class="swipesEditor w-full resize-y border rounded border-grey-200 p-2"
                  ></textarea>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToReviewsTextView(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActionsTextView(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4"
      >
        <gd-button
          size="auto"
          variant="primary"
          class="mt-3 mb-3"
          @click="createBlogReviews"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_you_currently_donthave_any_blog') }}
    </div>
    <confirm-modal
      name="confirm-blog-review-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteBlogReview"
    />
  </div>
</template>

<script>
import Quill from 'quill';
import { quillEditor } from 'vue-quill-editor';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    quillEditor,
    TrashIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    reviewsEditorOptions: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    blogReviewsTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      blogReviewId: null,
      localFunnelResource: this.funnelResource,
      localBlogReviewsTab: this.blogReviewsTab,
      isBlogReviewsPageHTMLView: false,
    };
  },
  computed: {
    hasBlogReviews() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.blog_reviews &&
        this.localFunnelResource.blog_reviews.length
      );
    },
    blogReviewsTabs() {
      let blogReviewsTabs = [];

      if (this.hasBlogReviews) {
        for (let i = 0; i < this.localFunnelResource.blog_reviews.length; i++) {
          blogReviewsTabs.push({
            title: this.localFunnelResource.blog_reviews[i].name,
            value: `blog_review_tab_${this.localFunnelResource.blog_reviews[i].id}`,
          });
        }
      }

      return blogReviewsTabs;
    },
    blogReviewsErrorTabs() {
      let blogReviewsErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.blog_reviews.$each.$iter) {
        if (this.$v.localFunnelResource.blog_reviews.$each.$iter[index].$error) {
          blogReviewsErrorTabs.push(
            `blog_review_tab_${this.$v.localFunnelResource.blog_reviews.$each.$iter[index].$model.id}`
          );
        }
      }

      return blogReviewsErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActionsBlogReviews(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToBlogReviews(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsTextView(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToReviewsTextView(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleBlogReviewsTabChange(newTab) {
      this.localBlogReviewsTab = newTab || this.localBlogReviewsTab;
      this.$emit('handleBlogReviewsTabChange', this.localBlogReviewsTab);
    },
    handleBlogReviewsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('blog_review_tab_', ''))
      );
      console.log(updatedOrder);

      this.localFunnelResource.blog_reviews.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createBlogReviews() {
      const newReviewId = this.generateNumber();
      const newReviewCount = this.localFunnelResource.blog_reviews.length + 1;

      this.localFunnelResource.blog_reviews.push({
        id: newReviewId,
        name: `Review ${newReviewCount}`,
        title: this.$t('frontend_groovesell_tools_affiliate_edit_blog_review_title'),
        htmlBody: '',
        textBody: '',
      });

      this.localBlogReviewsTab = `blog_review_tab_${newReviewId}`;
      this.updateFunnelResource();
      this.handleBlogReviewsTabChange();
    },
    openConfirmModal(blogReviewId) {
      this.blogReviewId = blogReviewId;
      this.$root.$emit('modal-open', 'confirm-blog-review-delete-modal');
    },
    async deleteBlogReview() {
      this.localFunnelResource.blog_reviews.splice(this.blogReviewId, 1);
      this.localBlogReviewsTab = '';

      if (this.localFunnelResource.blog_reviews.length > 0) {
        let nextOpenIndex = 0;
        if (this.blogReviewId != 0) {
          nextOpenIndex = this.blogReviewId - 1;
        }

        const nextOpenId = this.localFunnelResource.blog_reviews[nextOpenIndex].id;
        this.localBlogReviewsTab = `blog_review_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleBlogReviewsTabChange();
    },
    addAffiliateNameToBlogReviews(index) {
      const range =
        this.$refs[`reviewsEditor_${index}`][0].quill.getSelection(true);

      if (range) {
        this.$refs[`reviewsEditor_${index}`][0].quill.insertText(
          range.index,
          '##affiliateName##'
        );
      }
    },
    addAffiliateLinkToBlogReviews(index, landingPageId) {
      const range =
        this.$refs[`reviewsEditor_${index}`][0].quill.getSelection(true);
      if (range) {
        if (range.length == 0) {
          this.$refs[`reviewsEditor_${index}`][0].quill.insertText(
            range.index,
            `##affiliateLink${landingPageId}##`
          );
        } else {
          this.$refs[`reviewsEditor_${index}`][0].quill.format(
            'link',
            `##affiliateLink${landingPageId}##`,
            Quill.sources.USER
          );
        }
      }
    },
    addAffiliateNameToReviewsTextView(index) {
      this.localFunnelResource.blog_reviews[
        index
      ].textBody = `${this.localFunnelResource.blog_reviews[index].textBody}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToReviewsTextView(index, landingPageId) {
      this.localFunnelResource.blog_reviews[
        index
      ].textBody = `${this.localFunnelResource.blog_reviews[index].textBody}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    isBlogReviewTabActive(review) {
      return this.localBlogReviewsTab === `blog_review_tab_${review.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      blog_reviews: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
