<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded">
      <div class="flex flex-col w-full">
        <div class="flex flex-col w-1/2 mb-6 bg-grey-100 p-1">
          <label class="font-semibold text-sm text-grey-700">
            <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
            {{ $t('frontend_product_step5_note') }}
          </label>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex flex-col w-1/2 mb-1">
            <label class="font-semibold text-md text-grey-700 mb-2">{{
              $t('frontend_product_step5_custom_css_track')
            }}</label>
          </div>
          <div class="flex flex-row w-full mb-10">
            <text-area v-model="prductDetails.checkout_page_options.tracking_codes"
              value="prductDetails.checkout_page_options.tracking_codes"
              :placeholder="$t('frontend_product_step5_custom_css_track')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
  name: 'CustomCssStep',

  components: {
    InfoIcon,
  },

  props: {
    prductDetails: {
      required: true,
    },
    model: {
      required: true,
    },
  },

  data() {
    return {
    };
  },
  methods: {
    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>