<template>
  <div class="custom-scrollbars overflow-y-auto bg-secondary pb-6">
    <v-wait for="assets">
      <div class="relative container mx-auto h-full flex flex-col justify-start">
        <div class="flex flex-col w-full bg-white rounded mt-8 p-4">
          <div class="flex flex-row w-full">
            <div class="w-3/4 flex flex-col">
              <div class="flex flex-col mb-2">
                <h4 class="font-semibold text-grey-700">
                  {{ $t('frontend_resources_promo_area') }}
                </h4>
              </div>
              <div class="flex flex-col mb-2">
                <span class="text-sm text-grey-700">{{
                  $t('frontend_resources_you_can_add')
                }}</span>
              </div>
            </div>
            <div class="w-1/4">
              <div class="flex justify-end">
                <gd-button
                  size="auto"
                  variant="primary"
                  class="ml-4 mt-3"
                  @click="saveAssets"
                  >{{ $t('frontend_save_exit') }}</gd-button
                >
              </div>
            </div>
          </div>

          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
            <gd-tabs
              :tabs="assetsTabs"
              :current-tab="assetsTab"
              :error-tabs="assetsErrorTabs"
              :wrapper-class="'pt-4 px-0 gs-default-tabs'"
              :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'"
              @onClick="handleAssetsTabChange"
            />
          </div>
          <div class="mt-2">
            <div v-if="assetsTab === 'swipes'">
              <swipes-tab
                :funnel-resource="funnelResource"
                :swipes-tab="swipesTab"
                :swipe-editor-options="swipeEditorOptions"
                :landing-pages="landingPages"
                @handleSwipesTabChange="handleSwipesTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'banners'">
              <banners-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'thankyouPageAds'">
              <thankyou-page-ads-tab
                :funnel-resource="funnelResource"
                :thankyou-page-ads-tab="thankyouPageAdsTab"
                :ads-editor-options="adsEditorOptions"
                :landing-pages="landingPages"
                @handleThankyouPageAdsTabChange="handleThankyouPageAdsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'signatures'">
              <signatures-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :email-signatures-tab="emailSignaturesTab"
                :forum-signatures-tab="forumSignaturesTab"
                @handleEmailSignaturesTabChange="handleEmailSignaturesTabChange"
                @handleForumSignaturesTabChange="handleForumSignaturesTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'socialMediaPosts'">
              <social-media-posts-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :facebook-posts-tab="facebookPostsTab"
                :twitter-tweets-tab="twitterTweetsTab"
                :linkedin-posts-tab="linkedinPostsTab"
                @handleFacebookPostsTabChange="handleFacebookPostsTabChange"
                @handleTwitterTweetsTabChange="handleTwitterTweetsTabChange"
                @handleLinkedinPostsTabChange="handleLinkedinPostsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'blogReviews'">
              <blog-reviews-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :blog-reviews-tab="blogReviewsTab"
                :reviews-editor-options="reviewsEditorOptions"
                @handleBlogReviewsTabChange="handleBlogReviewsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'videoReviews'">
              <video-reviews-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :video-reviews-tab="videoReviewsTab"
                @handleVideoReviewsTabChange="handleVideoReviewsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'facebookAds'">
              <facebook-ads-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :facebook-ads-tab="facebookAdsTab"
                @handleFacebookAdsTabChange="handleFacebookAdsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'logoutPageAds'">
              <logout-page-ads-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :logout-page-ads-tab="logoutPageAdsTab"
                :ads-editor-options="adsEditorOptions"
                @handleLogoutPageAdsTabChange="handleLogoutPageAdsTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
            <div v-else-if="assetsTab === 'allArticles'">
              <all-articles-tab
                :funnel-resource="funnelResource"
                :landing-pages="landingPages"
                :press-releases-tab="pressReleasesTab"
                :articles-tab="articlesTab"
                @handlePressReleasesTabChange="handlePressReleasesTabChange"
                @handleArticlesTabChange="handleArticlesTabChange"
                @updateFunnelResource="updateFunnelResource"
              />
            </div>
          </div>
        </div>
      </div>
    </v-wait>

    <portal to="headerBreadCrumb">
      <div class="flex items-center cursor-pointer -ml-4" @click="sendToFunnelPromoAreas">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import axios from 'axios';
import FunnelResource from '@/models/FunnelResource';
import { required, minLength, maxLength, url } from 'vuelidate/lib/validators';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import Auth from '@groovepages/gd-lib/auth';

import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
// or, from individual modules
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import AlignAction from 'quill-blot-formatter/dist/actions/align/AlignAction';
import DeleteAction from 'quill-blot-formatter/dist/actions/DeleteAction';
import ResizeAction from 'quill-blot-formatter/dist/actions/ResizeAction';
import ImageSpec from 'quill-blot-formatter/dist/specs/ImageSpec';

import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import ImagePickerWithoutModal from '@groovepages/gd-ui-kit/components/ImagePicker';
import VueFlip from 'vue-flip';
import urlParser from 'js-video-url-parser/lib/base';
import 'js-video-url-parser/lib/provider/youtube';
import 'js-video-url-parser/lib/provider/vimeo';
import SwipesTab from './assets_partials/SwipesTab.vue';
import BannersTab from './assets_partials/BannersTab.vue';
import ThankyouPageAdsTab from './assets_partials/ThankyouPageAdsTab.vue';
import SignaturesTab from './assets_partials/SignaturesTab.vue';
import SocialMediaPostsTab from './assets_partials/SocialMediaPostsTab.vue';
import BlogReviewsTab from './assets_partials/BlogReviewsTab.vue';
import VideoReviewsTab from './assets_partials/VideoReviewsTab.vue';
import FacebookAdsTab from './assets_partials/FacebookAdsTab.vue';
import LogoutPageAdsTab from './assets_partials/LogoutPageAdsTab.vue';
import AllArticlesTab from './assets_partials/AllArticlesTab.vue';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/blotFormatter', BlotFormatter);

const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [DeleteAction, ResizeAction, AlignAction];
  }

  init() {
    this.formatter.quill.root.addEventListener('click', (event) => {
      const el = event.target;

      if (!(el instanceof HTMLElement) || el.tagName !== 'IMG') {
        return;
      }

      this.img = el;
      this.formatter.show(this);
    });

    // handling scroll event
    this.formatter.quill.root.addEventListener('scroll', () => {
      this.formatter.repositionOverlay();
    });

    // handling align
    this.formatter.quill.on('editor-change', (eventName, ...args) => {
      if (eventName === 'selection-change' && args[2] === 'api') {
        setTimeout(() => {
          this.formatter.repositionOverlay();
        }, 10);
      }
    });
  }

  setSelection() {
    const ImageBlot = Quill.import('formats/image');
    const Parchment = Quill.import('parchment');

    const image = Parchment.find(this.getTargetElement());

    if (image instanceof ImageBlot) {
      this.formatter.quill.setSelection(
        image.offset(this.formatter.quill.scroll),
        1,
        'user'
      );
    }
  }
}

export default {
  layout: 'defaultWithoutSidebar',
  middleware: 'auth',
  metaInfo() {
    return { title: 'Promo Area Assets' };
  },
  components: {
    ImagePicker,
    ImagePickerWithoutModal,
    VueFlip,
    SwipesTab,
    BannersTab,
    ThankyouPageAdsTab,
    SignaturesTab,
    SocialMediaPostsTab,
    BlogReviewsTab,
    VideoReviewsTab,
    FacebookAdsTab,
    LogoutPageAdsTab,
    AllArticlesTab,
    ChevronLeftIcon,
  },
  data() {
    return {
      funnel: null,
      funnelResource: null,
      isSwipesEnabled: false,
      assetsTabs: [],
      assetsTab: 'swipes',
      swipesTab: '',
      swipeEditorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
          imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                if (file.size > 2048 * 1024) {
                  alert(this.$t('upload_file_less_than_2mb'));
                  reject(this.$t('file_too_big'));
                }

                const formData = new FormData();
                formData.append('file', file);

                axios
                  .post(
                    `${window.gdEnv.VUE_APP_API_URL}/${this.getDigitalAppTitle()}/images/upload/quill`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    }
                  )
                  .then((response) => {
                    resolve(response.data.url);
                  })
                  .catch((error) => {
                    alert(error);
                    reject(error);
                  });
              });
            },
          },
          blotFormatter: {
            specs: [CustomImageSpec],
          },
        },
      },
      adsEditorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
          imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                if (file.size > 2048 * 1024) {
                  alert(this.$t('upload_file_less_than_2mb'));
                  reject(this.$t('file_too_big'));
                }

                const formData = new FormData();
                formData.append('file', file);

                axios
                  .post(
                    `${window.gdEnv.VUE_APP_API_URL}/${this.getDigitalAppTitle()}/images/upload/quill`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    }
                  )
                  .then((response) => {
                    resolve(response.data.url);
                  })
                  .catch((error) => {
                    alert(error);
                    reject(error);
                  });
              });
            },
          },
          blotFormatter: {
            specs: [CustomImageSpec],
          },
        },
      },
      facebookPostsTab: '',
      twitterTweetsTab: '',
      linkedinPostsTab: '',
      emailSignaturesTab: '',
      forumSignaturesTab: '',
      pressReleasesTab: '',
      articlesTab: '',
      blogReviewsTab: '',
      thankyouPageAdsTab: '',
      logoutPageAdsTab: '',
      facebookAdsTab: '',
      videoReviewsTab: '',
      reviewsEditorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
          imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                if (file.size > 2048 * 1024) {
                  alert(this.$t('upload_file_less_than_2mb'));
                  reject(this.$t('file_too_big'));
                }

                const formData = new FormData();
                formData.append('file', file);

                axios
                  .post(
                    `${window.gdEnv.VUE_APP_API_URL}/${this.getDigitalAppTitle()}/images/upload/quill`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    }
                  )
                  .then((response) => {
                    resolve(response.data.url);
                  })
                  .catch((error) => {
                    alert(error);
                    reject(error);
                  });
              });
            },
          },
          blotFormatter: {
            specs: [CustomImageSpec],
          },
        },
      },
    };
  },
  validations: {
    funnelResource: {
      swipes: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          subjects: {
            $each: {
              required,
              minLength: minLength(1),
              maxLength: maxLength(300),
            },
          },
          $trackBy: 'id',
        },
      },
      social_media_posts: {
        facebook: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        twitter: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            tweet: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        linkedin: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
      },
      blog_reviews: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },

      thankyou_page_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },

      logout_page_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },

      facebook_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          copy: {
            required,
            minLength: minLength(3),
          },
          interests: {},
          $trackBy: 'id',
        },
      },

      video_reviews: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          description: {
            required,
            minLength: minLength(3),
          },
          video: {
            required,
            url,
            isValidVideoURL(value, object) {
              if (urlParser.parse(value) === undefined) {
                return false;
              }
              return true;
            },
          },
          $trackBy: 'id',
        },
      },

      signatures: {
        email: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        forum: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
      },

      press_releases: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          press_release: {
            required,
            minLength: minLength(3),
          },
          $trackBy: 'id',
        },
      },
      articles: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          article: {
            required,
            minLength: minLength(3),
          },
          $trackBy: 'id',
        },
      },
    },
  },
  computed: {
    assetsErrorTabs() {
      let assetsErrorTabs = [];
      const assetMappings = {
        swipes: 'swipes',
        blog_reviews: 'blogReviews',
        thankyou_page_ads: 'thankyouPageAds',
        logout_page_ads: 'logoutPageAds',
        facebook_ads: 'facebookAds',
        video_reviews: 'videoReviews',
        social_media_posts: 'socialMediaPosts',
        press_releases: 'pressReleases',
        articles: 'articles',
        signatures: 'signatures'
      };

      Object.keys(assetMappings).filter((key) => {
        if (this.$v.funnelResource[key]?.$error) {
          assetsErrorTabs.push(assetMappings[key]);
        }
      });

      return assetsErrorTabs;
    },
    landingPages() {
      const landingPages = [];

      const hasFunnel = this.funnel && this.funnel.funnel_products_count > 0;
      const mainProduct = hasFunnel && this.funnel.main_product;

      if (mainProduct && mainProduct.affiliates_enabled && mainProduct.affiliate_landing_pages && mainProduct.affiliate_landing_pages.length > 0) {
        const allLandingPages = mainProduct.affiliate_landing_pages;

        for (const landingPage of allLandingPages) {
          const isAssignedToZero = landingPage.assigned_to.length == 1 && landingPage.assigned_to[0].id == 0;

          if (isAssignedToZero) {
            landingPages.push({
              id: landingPage.id,
              description: landingPage.description,
            });
          }
        }
      }

      return landingPages;
    },
  },
  async created() {
    const user = Auth.authenticatedUser();
    this.$wait.start('assets');

    this.funnel = await Funnel.$find(this.$route.params.id);
    this.funnelResource = await FunnelResource.$find(
      this.$route.params.resource_id
    );

    const resourceTabs = [
      { key: 'swipes', label: 'frontend_resources_swipes' },
      { key: 'blogReviews', label: 'frontend_resources_blog_reviews' },
      { key: 'allArticles', label: 'frontend_resources_blog_articles' },
      { key: 'banners', label: 'frontend_resources_banners' },
      { key: 'thankyouPageAds', label: 'frontend_resources_thankyou' },
      { key: 'logoutPageAds', label: 'frontend_resources_logout_page' },
      { key: 'socialMediaPosts', label: 'frontend_resources_social_media' },
      { key: 'videoReviews', label: 'frontend_resources_video_reviews' },
      { key: 'facebookAds', label: 'frontend_resources_facebook_ads' },
      { key: 'signatures', label: 'frontend_resources_signatures' }
    ];

    resourceTabs.forEach(tab => {
      this.assetsTabs.push({
        title: this.$t(tab.label),
        value: tab.key
      });
    });

    this.swipesTab = this.updateTabIfEmpty(this.swipesTab, this.funnelResource, 'swipes', 'swipe');
    this.thankyouPageAdsTab = this.updateTabIfEmpty(this.thankyouPageAdsTab, this.funnelResource, 'thankyou_page_ads', 'thankyou_page_ad');
    this.emailSignaturesTab = this.updateTabIfEmpty(this.emailSignaturesTab, this.funnelResource.signatures, 'email', 'email_signature');
    this.forumSignaturesTab = this.updateTabIfEmpty(this.forumSignaturesTab, this.funnelResource.signatures, 'forum', 'forum_signature');
    this.facebookPostsTab = this.updateTabIfEmpty(this.facebookPostsTab, this.funnelResource.social_media_posts, 'facebook', 'fb_post');
    this.twitterTweetsTab = this.updateTabIfEmpty(this.twitterTweetsTab, this.funnelResource.social_media_posts, 'twitter', 'twitter_tweet');
    this.linkedinPostsTab = this.updateTabIfEmpty(this.linkedinPostsTab, this.funnelResource.social_media_posts, 'linkedin', 'linkedin_post');
    this.blogReviewsTab = this.updateTabIfEmpty(this.blogReviewsTab, this.funnelResource, 'blog_reviews', 'blog_review');
    this.facebookAdsTab = this.updateTabIfEmpty(this.facebookAdsTab, this.funnelResource, 'facebook_ads', 'facebook_ad');
    this.videoReviewsTab = this.updateTabIfEmpty(this.videoReviewsTab, this.funnelResource, 'video_reviews', 'video_review');
    this.logoutPageAdsTab = this.updateTabIfEmpty(this.logoutPageAdsTab, this.funnelResource, 'logout_page_ads', 'logout_page_ad');
    this.pressReleasesTab = this.updateTabIfEmpty(this.pressReleasesTab, this.funnelResource, 'press_releases', 'press_release');
    this.articlesTab = this.updateTabIfEmpty(this.articlesTab, this.funnelResource, 'articles', 'articles');

    window.toggleSwitchValue = this.toggleSwitchValue;
    this.$wait.end('assets');
  },
  methods: {
    getDigitalAppTitle() {
      return this.whitelabelAppData('digital')?.title.toLowerCase() || 'groovedigital';
    },
    updateFunnelResource(funnelResource) {
      this.funnelResource = funnelResource;
    },
    updateTabIfEmpty(tab, resource, key, tabName) {
      if (tab === '' && resource && resource[key] && resource[key].length > 0) {
        tab = `${tabName}_tab_${resource[key][0].id}`;
      }
      return tab;
    },
    toggleSwitchValue(type) {
      const propertyMap = {
        1: 'swipes_enabled',
        2: 'blog_reviews_enabled',
        3: 'banners_enabled',
        4: 'thankyou_page_ads_enabled',
        5: 'logout_page_ads_enabled',
        6: 'facebook_ads_enabled',
        7: 'social_media_posts_enabled',
        8: 'press_releases_enabled',
        9: 'video_reviews_enabled',
        10: 'signatures_enabled'
      };
      const propertyName = propertyMap[type];
      
      if (propertyName) {
        this.funnelResource[propertyName] = !this.funnelResource[propertyName];
      }
    },
    async saveAssets() {
      this.$root.$emit('trigger-validation');
      this.$v.$touch();
      const isValid = !this.$v.$invalid;

      if (isValid) {
        this.$loader.start('saveAssets');
        await this.funnelResource.save();
        this.$loader.end('saveAssets');
        this.$router.push({
          name: 'funnel_resources',
          params: { id: this.funnel.id },
        });
      }
    },
    sendToFunnelPromoAreas() {
      this.$router.push({
        name: 'funnel_resources',
        params: { id: this.funnel.id },
      });
    },
    handleAssetsTabChange(newTab) {
      this.assetsTab = newTab;
    },
    handleSwipesTabChange(newTab) {
      this.swipesTab = newTab;
    },
    handleBlogReviewsTabChange(newTab) {
      this.blogReviewsTab = newTab;
    },
    handleFacebookAdsTabChange(newTab) {
      this.facebookAdsTab = newTab;
    },
    handleVideoReviewsTabChange(newTab) {
      this.videoReviewsTab = newTab;
    },
    handleThankyouPageAdsTabChange(newTab) {
      this.thankyouPageAdsTab = newTab;
    },
    handleLogoutPageAdsTabChange(newTab) {
      this.logoutPageAdsTab = newTab;
    },
    handlePressReleasesTabChange(newTab) {
      this.pressReleasesTab = newTab;
    },
    handleArticlesTabChange(newTab) {
      this.articlesTab = newTab;
    },
    handleFacebookPostsTabChange(newTab) {
      this.facebookPostsTab = newTab;
    },
    handleTwitterTweetsTabChange(newTab) {
      this.twitterTweetsTab = newTab;
    },
    handleLinkedinPostsTabChange(newTab) {
      this.linkedinPostsTab = newTab;
    },
    handleEmailSignaturesTabChange(newTab) {
      this.emailSignaturesTab = newTab;
    },
    handleForumSignaturesTabChange(newTab) {
      this.forumSignaturesTab = newTab;
    },
  },
};
</script>

<style>
.swipesEditor {
  height: 32rem;
}

.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}

.embed-container {
  margin-bottom: 1.1em;
  margin-top: 1.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}
</style>

<style lang="scss">
.tgl {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2em;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: sans-serif;
    font-size: 11px;
    perspective: 100px;
    &:after,
    &:before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 4px;
    }

    &:after {
      content: attr(data-tg-on);
      background: #02c66f;
      transform: rotateY(-180deg);
    }

    &:before {
      background: var(--brand-primary);
      content: attr(data-tg-off);
    }

    &:active:before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &:before {
      transform: rotateY(180deg);
    }

    &:after {
      transform: rotateY(0);
      left: 0;
      background: #15b85a;
    }

    &:active:after {
      transform: rotateY(20deg);
    }
  }
}
</style>
<style scoped>
.custom-scrollbars {
  height: calc(100vh - 4rem)
}
</style>