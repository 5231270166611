<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col w-1/2">
      <!-- button_after_text -->
      <div
        class="flex flex-col w-1/2 mb-6"
        v-if="localModel.customization_details.embed_button.button_type == 1"
      >
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_button_text') }}
        </label>
        <text-input
          v-model="localModel.customization_details.embed_button.button_after_text"
          :attribute="$t('frontend_button_text')"
          :placeholder="$t('frontend_bumps_button_after_text')"
        />
      </div>

      <div class="flex flex-row w-1/2 mb-6">
        <!-- button_after_bg_color -->
        <div class="flex flex-col w-1/2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_bg_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.embed_button.button_after_bg_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
            ></color-picker>
          </div>
        </div>

        <!-- button_after_font_color -->
        <div
          class="flex flex-col w-1/2 ml-2"
          v-if="localModel.customization_details.embed_button.button_type == 1"
        >
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_font_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.embed_button.button_after_font_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
            ></color-picker>
          </div>
        </div>
      </div>

      <div class="flex flex-row w-1/2 mb-6">
        <!-- button_before_border_size -->
        <div class="flex flex-col w-1/2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_border_size') }}
          </label>
          <select
            v-model="localModel.customization_details.embed_button.button_after_border_size"
            class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
          >
            <option value="2">{{ $t('frontend_groovesell_bumps_step4_none') }}</option>
            <option value="3">{{ $t('frontend_groovesell_bumps_step4_small') }}</option>
            <option value="1">{{ $t('frontend_groovesell_bumps_step4_regular') }}</option>
            <option value="4">{{ $t('frontend_groovesell_bumps_step4_large') }}</option>
          </select>
        </div>

        <!-- button_after_border_color -->
        <div
          class="flex flex-col w-1/2 ml-2"
          v-if="localModel.customization_details.embed_button.button_type == 1"
        >
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_border_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.embed_button.button_after_border_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
            ></color-picker>
          </div>
        </div>
      </div>

    </div>

    <div
      class="w-1/2 flex flex-col justify-center items-center px-8"
      :style="[customColor]"
    >
      <gd-button
        v-if="localModel.customization_details.embed_button.button_type == 1"
        variant="primary"
        :size="embedButtonSize"
        class="custom-embed-after-button-color font-bold"
        :class="{
          'rounded-full': localModel.customization_details.embed_button.button_shape == 2,
          'w-32 py-6': localModel.customization_details.embed_button.button_size == 1,
          'w-64 py-6 text-lg': localModel.customization_details.embed_button.button_size == 2,
          'w-18 text-xs': localModel.customization_details.embed_button.button_size == 3,
          'shadow-sm': localModel.customization_details.embed_button.button_shadow == 1,
          'shadow': localModel.customization_details.embed_button.button_shadow == 2,
          'shadow-lg': localModel.customization_details.embed_button.button_shadow == 3,
          'shadow-xl': localModel.customization_details.embed_button.button_shadow == 4,
          'border-2': localModel.customization_details.embed_button.button_after_border_size == 1,
          'border-none': localModel.customization_details.embed_button.button_after_border_size == 2,
          'border': localModel.customization_details.embed_button.button_after_border_size == 3,
          'border-4': localModel.customization_details.embed_button.button_after_border_size == 4,
        }"
        :key="buttonSizeKey"
      >
        <em class="fas fa-minus-circle mr-1"></em>
        {{ localModel.customization_details.embed_button.button_after_text }}
      </gd-button>
      <gd-toggle
        v-else
        class="custom-embed-toggle-color"
        v-model="embedToggleValue"
        :value="embedToggleValue"
      >
      </gd-toggle>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';

export default {
  components: {
    ColorPicker,
  },
  props: {
    model: {
      required: true,
    },
    colors: {
      required: true,
    },
    customColor: {
      required: true,
    },
    embedButtonSize: {
      required: true,
    },
    buttonSizeKey: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      embedToggleValue: true,
    }
  },
}
</script>
