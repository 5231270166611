<template>
    <div>
        <div class="w-full">
            <label class="text-base font-bold text-grey-800 mb-2">{{
                $t('frontend_link')
            }}</label>
            <text-input v-model="checkout_link" class="w-full mt-2" :readonly="true" value="checkout_link"
                :placeholder="$t('frontend_pgroovesell_checkout_link')" :select-all="true" />
        </div>
        <div class="w-full text-center mb-4">
            <gd-button v-clipboard:copy="checkout_link" v-clipboard:success="onCopy" v-clipboard:error="onError" variant="primary"
                size="md" class="mt-6">
                {{ $t('frontend_copy') }}
            </gd-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        checkout_link: {
            required: true
        },
        onCopy: {
            type: Function,
            required: true
        },
        onError: {
            type: Function,
            required: true
        }
    }
}
</script>