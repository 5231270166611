<template>
  <gd-modal
    name="transaction-detail-modal"
    :title="customerDetailsModalTitle"
    width="80%"
    bg-type="regular"
  >
    <div v-if="selectedCustomer" class="flex">
      <div class="flex flex-col w-full px-4" style="min-height: 60vh;">
        <gd-tabs
          :tabs="customerDetailTabs"
          :current-tab="customerDetailsTab"
          :highlight-tabs="highLightTabs"
          highlight-text="Fraud Alert"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-2 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleTransactionsDetailTabChange"
        />
        <div class="mt-2">
          <div v-if="customerDetailsTab === 'profile information'">
            <div class="flex flex-row">
              <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_buyer_name') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{$store.getters['internal/isDemoMode'] ? 'xxxx-xxxx-xxxx' : customerDetail.buyer_name }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_email') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{$store.getters['internal/isDemoMode'] ? 'xxxx-xxxx-xxxx' : customerDetail.email }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_address') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                      {{$store.getters['internal/isDemoMode'] ? 'xxxx-xxxx-xxxx' : customerDetail.buyer_address }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_city') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.buyer_city }}
                  </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_state') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.buyer_state }}
                  </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_postal_code') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.buyer_postal_code }}
                  </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_country') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.buyer_country }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="customerDetailsTab === 'revenue'">
            <div class="flex flex-row">
              <div class="w-full flex flex-wrap">
                <div class="w-1/4 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_total_revenue') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.total_revenue }}
                  </label>
                </div>
                <div class="w-1/4 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_total_refund') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.total_refunds }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_groovesell_total_revenue_excluding_refund') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ customerDetail.total_revenue_excluding_refund }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="customerDetailsTab === 'transactions'">
            <div class="flex flex-row">
              <div class="w-full flex flex-wrap">
                <div
                  v-if="customerTransactions && customerTransactions.length > 0"
                  class="flex w-full flex-col m-6 mb-4"
                >
                  <div>
                    <div
                      id="second-tier-affiliates-list-view"
                      class="-mx-6 flex flex-row"
                    >
                      <v-client-table
                        :data="customerTransactions"
                        :columns="customerTransactionsColumns"
                        :options="customerTransactionTableOptions"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>{{ $t('frontend_groovesell_no_transactions_found') }}</div>
              </div>
            </div>
          </div>
          <div v-if="customerDetailsTab === 'refund'">
            <div class="flex flex-row">
              <div class="w-full flex flex-wrap">
                <div
                  v-if="customerRefund && customerRefund.length > 0"
                  class="flex w-full flex-col m-6 mb-4"
                >
                  <div>
                    <div
                      id="second-tier-affiliates-list-view"
                      class="-mx-6 flex flex-row"
                    >
                      <v-client-table
                        :data="customerRefund"
                        :columns="customerTransactionsColumns"
                        :options="customerRefundTableOptions"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>{{ $t('frontend_groovesell_no_refund_found') }}</div>
              </div>
            </div>
          </div>
          <div v-if="customerDetailsTab === 'active subscriptions'">
            <div class="flex flex-row">
              <div class="w-full flex flex-wrap">
                <div
                  v-if="activeSubscription && activeSubscription.length > 0"
                  class="flex w-full flex-col m-6 mb-4"
                >
                  <div>
                    <div
                      id="second-tier-affiliates-list-view"
                      class="-mx-6 flex flex-row"
                    >
                      <v-client-table
                        :data="activeSubscription"
                        :columns="activeSubscriptionColumns"
                        :options="activeSubscriptionOptions"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>{{ $t('frontend_groovesell_no_active_subscription_found') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import CustomerReport from '@/models/CustomerReport';

export default {
  data() {
    return {
      customerDetailsModalTitle: null,
      selectedCustomer: null,
      customerDetailsTab: 'profile information',
      highLightTabs: [],
      customerDetail: null,
      customerTransactions: [],
      customerTransactionsColumns: [
        'funnel',
        'product',
        'price_point',
        'transaction_id',
        'human_readable_price',
        'formatted_date_time'
      ],
      customerTransactionTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          human_readable_price: this.$t('frontend_amount'),
          funnel: this.$t('frontend_funnel'),
          transaction_id: this.$t('frontend_transaction_id'),
          price_point: this.$t('frontend_price_point'),
          product: this.$t('frontend_product')
        },
        perPage: 5,
        filterByColumn: false,
        filterable: false
      },
      customerRefund: [],
      customerRefundTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          human_readable_price: this.$t('frontend_amount'),
          funnel: this.$t('frontend_funnel'),
          transaction_id: this.$t('frontend_transaction_id'),
          price_point: this.$t('frontend_price_point'),
          product: this.$t('frontend_product')
        },
        perPage: 5,
        filterByColumn: false,
        filterable: false
      },
      activeSubscription: [],
      activeSubscriptionColumns: [
        'funnel',
        'product',
        'price_point',
        'transaction_id',
        'formatted_date_time'
      ],
      activeSubscriptionOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          human_readable_price: this.$t('frontend_amount'),
          funnel: this.$t('frontend_funnel'),
          transaction_id: this.$t('frontend_transaction_id'),
          price_point: this.$t('frontend_price_point'),
          product: this.$t('frontend_product')
        },
        perPage: 5,
        filterByColumn: false,
        filterable: false
      }
    };
  },
  computed: {
    customerDetailTabs() {
      let customerDetailTabs = [
        { title: this.$t('frontend_profile_information'), value: 'profile information' },
        { title: this.$t('frontend_revenue'), value: 'revenue' },
        { title: this.$t('frontend_transactions'), value: 'transactions' },
        { title: this.$t('frontend_refund'), value: 'refund' },
        { title: this.$t('frontend_active_subscription'), value: 'active subscriptions' }
      ];
      return customerDetailTabs;
    },
  },
  methods: {
    async openCustomerDetail(transactionId) {
      this.$loader.start('openCustomerDetail');
      this.selectedCustomer = await CustomerReport.find(transactionId);
      this.highLightTabs = [];

      this.customerDetail = this.selectedCustomer.data[0];
      this.customerTransactions = this.selectedCustomer.transactions;
      this.customerRefund = this.selectedCustomer.refunds;
      this.activeSubscription = this.selectedCustomer.active_subsctiption;
      this.customerDetailsModalTitle = this.selectedCustomer.data[0].buyer_name;
      this.$root.$emit('modal-open', 'transaction-detail-modal');
      this.$loader.end('openCustomerDetail');
    },
    handleTransactionsDetailTabChange(newTab) {
      this.customerDetailsTab = newTab;
    }
  }
};
</script>
