import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openCustomerDetail() {
      this.params.context.componentParent.$refs.transactionDetailModal.openCustomerDetail(
        this.params.data.id
      );
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openCustomerDetail">
            <span v-if="$store.getters['internal/isDemoMode']">
                xxxx-xxxx-xxxx
            </span>
            <span v-else>{{this.params.value}}</span>
        </span>
    `
});
