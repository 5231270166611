<template>
    <div class="mt-6">
        <div class="flex flex-row">
            <div class="flex flex-col w-full mr-20">
                <div class="flex flex-row justify-start pr-2 mb-4">
                    <gd-toggle class="mr-2" v-model="localdisableBrandingBar" :value="localdisableBrandingBar"
                        @change="disableBrandingBarChange" />
                    <label class="mr-4">{{
                        $t('frontend_checkout_links_step4_disable_branding_bar')
                    }}</label>
                </div>

                <div class="flex flex-col mb-1" v-if="!localdisableBrandingBar">
                    <p class="font-semibold mb-1">
                        {{ $t('frontend_product_step5_upload') }}
                    </p>
                    <div class="w-1/2">
                        <image-picker v-model="prductDetails.checkout_page_options.product_image"
                            :primary="true"></image-picker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
export default {
    components: {
        ImagePicker
    },
    props: {
        prductDetails: {
            type: Object,
        },
        disableBrandingBar: {
            default: false
        }
    },
    watch: {
        localProductDetails: {
            handler: function (val, oldVal) {
                this.$emit('attribute-changed', {
                    key: 'prductDetails',
                    value: val
                });
            },
            deep: true
        },
    },
    data() {
        return {
            localdisableBrandingBar: this.disableBrandingBar,
            localProductDetails: this.prductDetails,
        }
    },
    methods: {
        disableBrandingBarChange(value) {
            const v = value == false || value == 'false' ? 0 : 1
            this.$emit('attribute-changed', {
                key: 'disableBrandingBar',
                value: v
            });
            this.$emit('disableBrandingBarChange', v);

        },
    }

}
</script>