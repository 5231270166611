<template>
  <div class="flex flex-col">
    <!-- All Articles Tabs -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="allArticlesTabs"
        :current-tab="allArticlesTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleAllArticlesTabChange"
      />
    </div>

    <!-- Press Releases Section -->
    <div class="mt-2">
      <div v-if="allArticlesTab === 'pressReleases'">
        <div v-if="hasPressReleases" class="flex flex-col">
          <!-- Press Releases Tabs -->
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="pressReleasesTabs"
              :current-tab="pressReleasesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handlePressReleasesTabChange', $event)"
            />
          </div>
          <!-- Press Releases Content -->
          <div class="mt-2">
            <div v-for="(release, index) in funnelResource.press_releases" :key="index">
              <div v-if="isActivePressReleaseTab(release)" class="pb-8">
                <!-- Press Release Details -->
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_promo_press_release') }}</label>
                  </div>
                  <!-- TextArea and Copy Button for Press Release -->
                  <div class="flex flex-row w-full">
                    <div class="w-1/2">
                      <text-area
                        v-model="release.press_release"
                        class="swipesEditor w-full"
                        :placeholder="$t('frontend_affiliate_promo_post')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center w-1/4">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="release.press_release"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="icon"
                          variant="primary"
                          :title="$t('frontend_affiliate_promo_copy_press_release')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_affiliate_promo_no_press_release') }}
        </div>
      </div>

      <!-- Articles Section -->
      <div v-else-if="allArticlesTab === 'articles'">
        <div v-if="hasArticles" class="flex flex-col">
          <!-- Articles Tabs -->
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="articlesTabs"
              :current-tab="articlesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleArticlesTabChange', $event)"
            />
          </div>
          <!-- Articles Content -->
          <div class="mt-2">
            <div v-for="(article, index) in funnelResource.articles" :key="index">
              <div v-if="isActiveArticleTab(article)" class="pb-8">
                <!-- Article Details -->
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_promo_article') }}</label>
                  </div>
                  <!-- TextArea and Copy Button for Article -->
                  <div class="flex flex-row w-full">
                    <div class="w-1/2">
                      <text-area
                        v-model="article.article"
                        class="swipesEditor w-full"
                        :placeholder="$t('frontend_affiliate_promo_article')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center w-1/4">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="article.article"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="icon"
                          variant="primary"
                          :title="$t('frontend_groovesell_copy_article')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_affiliate_promo_no_articles') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    pressReleasesTab: {
      type: String,
      default: '',
    },
    articlesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      allArticlesTabs: [
        { title: 'Articles', value: 'articles' },
        { title: 'Press Releases', value: 'pressReleases' },
      ],
      allArticlesTab: 'articles',
    };
  },
  computed: {
    hasPressReleases() {
      return this.funnelResource &&
        this.funnelResource.press_releases &&
        this.funnelResource.press_releases.length;
    },
    hasArticles() {
      return this.funnelResource &&
          this.funnelResource.articles &&
          this.funnelResource.articles.length;
    },
    pressReleasesTabs() {
      return this.funnelResource.press_releases.map(release => ({
        title: release.name,
        value: `press_release_tab_${release.id}`,
      }));
    },
    articlesTabs() {
      return this.funnelResource.articles.map(article => ({
        title: article.name,
        value: `articles_tab_${article.id}`,
      }));
    },
  },
  methods: {
    handleAllArticlesTabChange(newTab) {
      this.allArticlesTab = newTab;
    },
    isActivePressReleaseTab(release) {
      return this.pressReleasesTab === `press_release_tab_${release.id}`;
    },
    isActiveArticleTab(article) {
      return this.articlesTab === `articles_tab_${article.id}`;
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
