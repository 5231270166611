import store from '@/store';
import Model from './Model';

export default class LeadsReport extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'abandoned-cart';
  }

  get rendered_full_name() {
    if (store.getters['internal/isDemoMode']) {
      return 'xxxxxxx';
    }

    return this.full_name;
  }

  get rendered_email() {
    if (store.getters['internal/isDemoMode']) {
      return 'xxxxxxx@xxxxxx.xxx';
    }

    return this.email;
  }
}
