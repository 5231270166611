<template>
    <gd-modal name="affiliate-bonuses-modal" :title="$t('frontend_affiliate_promos_affiliate_bonuses')" width="80%">
        <gd-tabs :tabs="affiliateBonusesTabs" :current-tab="affiliateBonusesTab"
            :wrapper-class="'pt-4 px-2 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
            :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
            :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiliateBonusTabChange" />

        <div class="mt-2">
            <div v-for="(bonus, index) in affiliateBonuses" :key="index" class="px-2">
                <div v-if="affiliateBonusesTab === 'affiliate_bonus_' + bonus.product_id
                    " style="height: calc(80vh - 15rem)">
                    <div class="mt-2 px-4 h-full max-h-full overflow-y-auto">
                        <div v-for="(pricePoint, pricePointIndex) in bonus.pricepoints" :key="pricePointIndex"
                            class="flex flex-col w-full">
                            <div class="flex flex-col w-1/2 mb-6">
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ pricePoint.pricepoint_name }}
                                </label>
                                <multi-select v-model="pricePoint.bonuses" class="w-full" track-by="id" label="name"
                                    :placeholder="$t('frontend_affiliate_promos_add_your_bonuses')" :options="bonuses"
                                    :searchable="false" :multiple="true" :allow-empty="true" :taggable="true"
                                    tag-position="bottom">
                                </multi-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row-reverse pr-2 pb-4">
            <gd-button variant="primary" size="md" class="mt-6" @click="saveAffiliateBonuses">{{
                $t('frontend_save') }}</gd-button>
        </div>
    </gd-modal>
</template>
<script>
import AffiliateBonus from '@/models/AffiliateBonus';

export default {
    props: {
        affiliateBonuses: {
            required: true,
        },
        bonuses: {
            required: true,
        },
        affiliateBonusesTab: {
            required: true,
        },
    },
    computed: {
        affiliateBonusesTabs() {
            const affiliateBonusTabs = [];
            for (let i = 0; i < this.affiliateBonuses.length; i++) {
                affiliateBonusTabs.push({
                    title: this.affiliateBonuses[i].product_name,
                    value: `affiliate_bonus_${this.affiliateBonuses[i].product_id}`,
                });
            }

            return affiliateBonusTabs;
        },
    },
    methods: {
        handleAffiliateBonusTabChange(newTab) {
            this.$emit('affiliate-bonuses-tab-change', newTab)
        },
        async saveAffiliateBonuses() {
            this.$loader.start('saveAffiliateBonuses');
            for (let i = 0; i < this.affiliateBonuses.length; i++) {
                const pricePoints = this.affiliateBonuses[i].pricepoints;

                for (let j = 0; j < pricePoints.length; j++) {
                    const affiliateBonusId = pricePoints[j].id;
                    const affiliateBonus = await AffiliateBonus.$find(affiliateBonusId);
                    affiliateBonus.bonuses = pricePoints[j].bonuses;
                    await affiliateBonus.save();
                }
            }

            this.$loader.end('saveAffiliateBonuses');
            this.$root.$emit('modal-close', 'affiliate-bonuses-modal');
        },
    }
}
</script>
