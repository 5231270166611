<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                    <div class="flex flex-col w-full mb-6 mt-8">
                        <div v-if="pricePoint.price > 0" class="flex flex-row w-2/3">
                            <div v-for="(bumpType, bumpIndex) in orderBumpStatus" :key="bumpIndex" class="flex flex-1 mr-4">
                                <gd-custom-radio v-model="pricePoint.offer_bump_enable"
                                    :option="bumpType"></gd-custom-radio>
                            </div>
                        </div>
                        <div v-else class="flex flex-row w-2/3">
                            <div v-for="(bumpType, bumpIndex) in orderBumpTypesForFreePricePoint" :key="bumpIndex"
                                class="flex flex-1 mr-4">
                                <gd-custom-radio v-model="pricePoint.offer_bump_enable"
                                    :option="bumpType"></gd-custom-radio>
                            </div>
                        </div>
                    </div>

                    <div v-if="pricePoint.offer_bump_enable" class="w-full flex flex-col">
                        <div class="flex flex-col w-full" v-if="pricePoint.offer_bump_options &&
                            pricePoint.offer_bump_options.length > 0
                            ">
                            <div class="flex flex-col w-full" :key="offerBumpIndex" v-for="(
                        offerBump, offerBumpIndex
                      ) in pricePoint.offer_bump_options">
                                <hr v-if="offerBumpIndex > 0" class="border-grey-100 -mx-4" />
                                <div class="flex flex-row w-full mt-2">
                                    <div class="w-1/2">
                                        <gd-button v-if="offerBumpIndex == 0" size="auto" variant="primary" class="mt-3"
                                            @click="createOfferBump(index)">+ {{ $t('frontend_add_presale_bump')
                                            }}</gd-button>
                                    </div>
                                    <div class="w-1/2 flex flex-row-reverse">
                                        <gd-button variant="outline" size="icon" class="mr-2"
                                            @click="openConfirmModal(index, offerBumpIndex)">
                                            <trash-icon class="h-5 w-5 fill-current"/>
                                        </gd-button>
                                    </div>
                                </div>
                                <div class="flex flex-row w-full mt-2">
                                    <div class="flex flex-col w-2/3 pr-4">
                                        <div class="w-full flex flex-col mb-6">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                $t('frontend_heading') }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-full mr-2">
                                                    <text-input
                                                        v-model="offerBump.heading_line"
                                                        :validator="$v.localModel.pricing_points.$each[index].offer_bump_options.$each[offerBumpIndex].heading_line"
                                                        :attribute="$t('frontend_heading')" value="offerBump.heading_line"
                                                        :placeholder="$t('frontend_heading')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full flex flex-col mb-6">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_desc')
                                            }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-full mr-2">
                                                    <quill-editor ref="preSellOrderBumpEditor"
                                                        v-model="offerBump.description"
                                                        :options="postSellOrderBumpEditorOptions"
                                                        :class="{'invalid': $v.localModel.pricing_points.$each[index].offer_bump_options.$each[offerBumpIndex].description.$error}"
                                                        class="h-32"
                                                    ></quill-editor>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex flex-col w-full mb-6 mt-8">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                $t('frontend_product') }}</label>
                                            <gd-dropdown
                                                v-model="offerBump.product_id"
                                                size="full"
                                                :options="pricePointFunnelProductsOptions(pricePoint)"
                                            />
                                        </div>
                                        <div class="flex flex-col w-full mb-6">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                $t('frontend_price_point') }}</label>
                                            <gd-dropdown
                                                v-model="offerBump.price_point_id"
                                                size="full"
                                                :options="selectedProductPricePointsOptions(pricePoint, offerBump.product_id)"
                                            />
                                        </div>
                                        <div class="w-full flex flex-col mb-6">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_style')
                                            }}</label>
                                            <div class="w-full flex flex-row">
                                                <gd-dropdown
                                                    v-model="offerBump.style_id"
                                                    size="full"
                                                    :options="styleOptionsList"
                                                />
                                            </div>
                                        </div>
                                        <div class="w-full flex flex-col mb-8">
                                            <div class="w-full flex">
                                                <div class="flex flex-col w-1/2">
                                                    {{ $t('frontend_display_thumbnail') }}
                                                    <gd-toggle v-model="offerBump.display_thumbnail" />
                                                </div>

                                                <div class="flex flex-col w-1/2">
                                                    {{ $t('frontend_display_bouncing_arrow') }}
                                                    <gd-toggle v-model="offerBump.display_bouncing" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" flex flex-col w-1/3 px-4 border-l border-grey-100">
                                        <div class="w-full flex flex-col mb-6">
                                            <label class=" w-full text-grey-dark text-sm font-semibold mb-32">{{ $t('frontend_preview') }}</label>
                                            <div v-if="offerBump.product_id && offerBump.price_point_id" class="w-full flex flex-row">
                                                <div class="w-full mr-2">
                                                    <style1 v-if="offerBump.style_id == 1" :key="offerBumpComponentKey"
                                                        :heading="offerBump.heading_line"
                                                        :description="offerBump.description"
                                                        :arrow="offerBump.display_bouncing"
                                                        :thumbnail="offerBump.display_thumbnail"
                                                        :selected-pricepoint="offerBump.price_point_id"
                                                        :selected-product="offerBump.product_id"
                                                        :all-products="pricePoint.funnelProducts" />
                                                    <style2 v-if="offerBump.style_id == 2" :key="offerBumpComponentKey"
                                                        :heading="offerBump.heading_line"
                                                        :description="offerBump.description"
                                                        :arrow="offerBump.display_bouncing"
                                                        :thumbnail="offerBump.display_thumbnail"
                                                        :selected-pricepoint="offerBump.price_point_id"
                                                        :selected-product="offerBump.product_id"
                                                        :all-products="pricePoint.funnelProducts" />
                                                    <style3 v-if="offerBump.style_id == 3" :key="offerBumpComponentKey"
                                                        :heading="offerBump.heading_line"
                                                        :description="offerBump.description"
                                                        :arrow="offerBump.display_bouncing"
                                                        :thumbnail="offerBump.display_thumbnail"
                                                        :selected-pricepoint="offerBump.price_point_id"
                                                        :selected-product="offerBump.product_id"
                                                        :all-products="pricePoint.funnelProducts" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="flex flex-col w-full">
                                <div class="flex flex-row w-full mt-2">
                                    <div class="w-1/2">
                                        <gd-button size="auto" variant="primary" class="mt-3" @click="createOfferBump(index)">
                                            <plus-icon class="h-5 w-5 fill-current"/>
                                            {{ $t('frontend_add_presale_bump') }}
                                        </gd-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="delete-offer-bump-confirm-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_bouses_delete_confirmation_text')"
            @confirm="deleteOfferBump"
        />
    </div>
</template>
<script>
import { Quill, quillEditor } from 'vue-quill-editor';
import Style3 from '@/components/Style3.vue';
import Style2 from '@/components/Style2.vue';
import Style1 from '@/components/Style1.vue';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import {
  required,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';

export default {
    components: {
        Quill,
        quillEditor,
        Style1,
        Style2,
        Style3,
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            required: true
        },
    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: '',
            orderBumpStatus: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step6_no_dont'),
                    description: this.$t('frontend_product_step6_the_checkout'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step6_yes_activate'),
                    description: this.$t('frontend_product_step6_the_checkout_page'),
                },
            ],
            orderBumpTypesForFreePricePoint: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step6_no_dont'),
                    description: this.$t('frontend_product_step6_the_checkout'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step6_yes_activate'),
                    description: this.$t('frontend_product_step6_the_checkout_page'),
                    is_disabled: true,
                },
            ],
            postSellOrderBumpEditorOptions: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ],
                },
            },
            styleOptionsList: [
                { text: `${this.$t('frontend_style')} 1`, value: 1 },
                { text: `${this.$t('frontend_style')} 2`, value: 2 },
                { text: `${this.$t('frontend_style')} 3`, value: 3 },
            ],
            offerBumpComponentKey: 1,
            offerBumpIndex: null,
            pricingPointIndex: null,
        }
    },
    computed: {
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];

            let pricePointIndex;
            console.log('this.$v.localModel.pricing_points', this.$v.localModel.pricing_points)
            for (pricePointIndex in this.$v.localModel.pricing_points.$each.$iter) {
                if (
                this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$error
                ) {
                pricePointErrorTabs.push(
                    `price_point_${this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`
                );
                }
            }

            return pricePointErrorTabs;
        },
    },
    mounted() {
        if (this.pricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    watch:{
        localModel: {
            handler: function (val, oldVal) {
                this.$emit('update-model', val);
            },
            deep: true
        }
    },
    methods: {
        async changedProduct(pPoint, event) {},

        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
        createOfferBump(index) {
            this.localModel.pricing_points[index].offer_bump_options.push({
                display_thumbnail: 0,
                display_bouncing: 0,
                heading_line: '',
                description: '',
                style_id: 1,
                product_id: 0,
                price_point_id: 0,
            });
        },
        openConfirmModal(pricingPointIndex, offerBumpIndex) {
            this.$root.$emit('modal-open', 'delete-offer-bump-confirm-modal');
            this.pricingPointIndex = pricingPointIndex;
            this.offerBumpIndex = offerBumpIndex;
        },
        deleteOfferBump() {
            this.localModel.pricing_points[this.pricingPointIndex].offer_bump_options.splice(
                this.offerBumpIndex,
                1
            );
        },
        pricePointFunnelProductsOptions(pricePoint) {
            return pricePoint.funnelProducts.map((product) => {
                return {
                    text: product.name,
                    value: product.id,
                };
            });
        },
        selectedProductPricePointsOptions(pricePoint, productId) {
            const funnelProduct = pricePoint.funnelProducts.find(
                (product) => product.id == productId
            );

            if (funnelProduct) {
                return funnelProduct.price_points.map((pricePoint) => {
                    return {
                        text: pricePoint.name,
                        value: pricePoint.id,
                    };
                });
            }
            return [];
        },
        selectedProductPricePoints(pricePoint, productId) {
            const funnelProduct = pricePoint.funnelProducts.find(
                (product) => product.id == productId
            );

            if (funnelProduct) {
                return funnelProduct.price_points;
            }
            return [];
        },
    },
    validations: {
        localModel: {
            pricing_points: {
                required,
                minLength: minLength(1),
                $each: {
                    offer_bump_options: {
                        $each: {
                            heading_line: {
                                required: requiredIf((object, value) => {
                                    if (object) {
                                        return true;
                                    }

                                    return false;
                                }),
                            },
                            description: {
                                required: requiredIf((object, value) => {
                                    if (object) {
                                        return true;
                                    }

                                    return false;
                                }),
                            },
                        },
                    },
                },
            },
        },
    },
}
</script>
