var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex flex-col w-full mr-20"},[_c('div',{staticClass:"flex flex-col w-full mb-4"},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('frontend_checkout_links_step4_select_template'))+" ")]),_c('div',{staticClass:"flex flex-row w-full mt-2"},_vm._l((_vm.templateTypes),function(templateType,index){return _c('div',{key:index,staticClass:"w-1/3 mr-4"},[_c('checkout-template-radio',{attrs:{"option":templateType},model:{value:(_vm.prductDetails.checkout_page_options.look_and_feel.template_type),callback:function ($$v) {_vm.$set(_vm.prductDetails.checkout_page_options.look_and_feel, "template_type", $$v)},expression:"prductDetails.checkout_page_options.look_and_feel.template_type"}})],1)}),0)]),(_vm.prductDetails.checkout_page_options.look_and_feel.template_type == 4)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row"},[_c('p',{staticClass:"flex flex-col w-1/3 font-semibold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_primary_color'))+" ")]),_c('p',{staticClass:"flex flex-col w-1/3 font-semibold mb-2 ml-10"},[_vm._v(" "+_vm._s(_vm.$t('frontend_secondary_color'))+" ")])]),_c('div',{staticClass:"flex flex-row mb-8"},[_c('div',{staticClass:"flex flex-col w-1/3",class:{
                        'pointer-events-none': _vm.prductDetails.checkout_page_options.look_and_feel.template_type != 4,
                    }},[_c('color-picker',{staticClass:"border-2 w-24 border-grey-300 rounded",attrs:{"data-cy":"localModel.primary_color","colors":_vm.colors,"cookies-origin":'groovesell_funnel_' + _vm.prductDetails.funnel_id},on:{"change":function($event){return _vm.changedLogoBG(1)}},model:{value:(_vm.localModel.primary_color),callback:function ($$v) {_vm.$set(_vm.localModel, "primary_color", $$v)},expression:"localModel.primary_color"}})],1),_c('div',{staticClass:"flex flex-col w-1/3 ml-10",class:{
                        'pointer-events-none':
                            _vm.prductDetails.checkout_page_options.look_and_feel
                                .template_type != 4,
                    }},[_c('color-picker',{staticClass:"border-2 w-24 border-grey-300 rounded",attrs:{"data-cy":"localModel.secondary_color","colors":_vm.colors,"cookies-origin":'groovesell_funnel_' + _vm.prductDetails.funnel_id},on:{"change":function($event){return _vm.changedLogoBG(2)}},model:{value:(_vm.localModel.secondary_color),callback:function ($$v) {_vm.$set(_vm.localModel, "secondary_color", $$v)},expression:"localModel.secondary_color"}})],1)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }