<template>
  <div class="flex flex-col">
    <div v-if="hasBanners" class="flex flex-row flex-wrap">
      <div v-for="(banner, bannerIndex) in funnelResource.banners" class="w-1/3 px-2">
        <div class="flex flex-col w-full h-full">
          <!-- Banner Name -->
          <div class="w-full justify-center flex flex-row font-semibold text-md text-grey-700 mb-2">
            {{ banner.name }}
          </div>

          <!-- Banner Image -->
          <div class="w-full text-center">
            <img :src="banner.image" />
          </div>

          <!-- Embed Textarea and Copy Button -->
          <div class="w-full flex flex-row mt-2 mt-auto">
            <textarea
              v-model="banner.embed"
              :placeholder="$t('frontend_groovesell_banner_embed_code')"
              class="h-16 w-full resize-y border rounded border-grey-200 p-2"
              readonly="readonly"
              @click="handleTextareaClick"
            ></textarea>
            <div class="ml-2 flex items-center">
              <gd-button
                v-clipboard:copy="banner.embed"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                size="icon"
                variant="primary"
                :title="$t('frontend_resource_copy_banner_embed')"
              >
                <copy-icon class="w-5 h-5 fill-current" />
              </gd-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('frontend_affiliate_promo_no_banners') }}
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasBanners() {
      return this.funnelResource && this.funnelResource.banners && this.funnelResource.banners.length;
    },
  },
  methods: {
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
