var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-row w-full mt-2"},[_c('div',{staticClass:"flex flex-row flex-wrap w-11/12 mt-8"},_vm._l((_vm.localModel.checkout_page_options.form_fields),function(formField,index){return _c('div',{key:index,staticClass:"w-1/4 flex flex-col mb-4"},[_c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-1/5 flex flex-row"},[_c('gd-checkbox',{attrs:{"variant":index < 4 ? 'base': 'primary',"disabled":index < 4 ? true : false},model:{value:(formField.enabled),callback:function ($$v) {_vm.$set(formField, "enabled", $$v)},expression:"formField.enabled"}})],1),_c('div',{staticClass:"w-4/5 flex flex-row"},[_c('span',{staticClass:"pl-2 pb-2 pt-1 text-sm mr-2"},[_vm._v(" "+_vm._s(formField.label)+" ")]),(formField.type == 'custom')?_c('div',{staticClass:"text-red cursor-pointer",on:{"click":function($event){return _vm.openConfirmModal(index)}}},[_c('trash-icon',{staticClass:"h-5 w-5 fill-current"})],1):_vm._e()])]),_c('div',{staticClass:"w-full flex",class:{
                    'invisible': formField.type == 'fname' ||
                        formField.type == 'lname' ||
                        formField.type == 'email' ||
                        formField.type == 'countryAndState' ||
                        formField.type == 'address' ||
                        formField.type == 'cityAndPostalCode' ||
                        formField.enabled == false
                }},[_c('div',{staticClass:"w-1/5 flex flex-row"},[_c('gd-toggle',{attrs:{"value":formField.mandatory,"disable":true},model:{value:(formField.mandatory),callback:function ($$v) {_vm.$set(formField, "mandatory", $$v)},expression:"formField.mandatory"}})],1),_c('div',{staticClass:"w-4/5 flex flex-row"},[_c('span',{staticClass:"pl-2 pb-2 pt-1 text-sm mr-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_products_is_mandatory'))+" ")])])])])}),0),_c('div',{staticClass:"w-2/12 flex flex-row-reverse"},[_c('gd-button',{staticClass:"ml-4",attrs:{"variant":"outline","size":"auto"},on:{"click":_vm.openCustomFieldModal}},[_c('plus-icon',{staticClass:"h-5 w-5 fill-current"}),_vm._v(" "+_vm._s(_vm.$t('frontend_product_step5_add_custom')))],1)],1)]),_c('confirm-modal',{attrs:{"name":"confirm-remove-form-field-modal","message-content":_vm.$t('frontend_non_revertable'),"button-text":_vm.$t('frontend_delete_confirm'),"button-size":"auto"},on:{"confirm":_vm.removeFormField}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }