<template>
    <gd-modal name="filters-affiliates-modal" :title="$t('frontend_affiliates')" width="70%" height="80%" bg-type="dark" allowOverflow>
        <div class="px-4">
            <div class="flex pt-2 pr-2">
                <div class="w-1/2 flex flex-row pl-2">
                    <affiliates-auto-suggest funnel="0" :excluded="excludedFilteredAffiliates"
                        @onAffiliateAdded="addedAffiliateToFilter" />
                </div>
            </div>
            <div class="flex mt-2 pt-2 pr-2">
                <div class="w-full flex flex-row">
                    <affiliate-list-view funnel="0" :affiliate-ids="selectedFilteredAffiliates"
                        @onAffiliateRemoved="removedAffiliateFromFilter" />
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';
export default {
    components: {
        AffiliatesAutoSuggest,
        AffiliateListView,
    },
    computed: {
        excludedFilteredAffiliates() {
            return this.selectedFilteredAffiliates;
        },
        selectedFilteredAffiliates() {
            return this.filteredAffiliates.map((a) => a.id);
        },
    },
    props: {
        filteredAffiliates: {
            required: true,
        },
    },

    methods: {
        addedAffiliateToFilter(affiliate) {
            if (this.filteredAffiliates.map((a) => a.id).indexOf(affiliate.id) === -1) {
                const newFilteredAffiliates = [...this.filteredAffiliates, affiliate];
                this.$emit('filter-changed', {
                    key: 'filteredAffiliates',
                    value: newFilteredAffiliates,
                    startSearch: false,
                })
            }
        },

        removedAffiliateFromFilter(affiliateId) {
            const removedAffiliate = this.filteredAffiliates
                .map((item) => item.id)
                .indexOf(affiliateId);
            const newFilteredAffiliates = [...this.filteredAffiliates];
            newFilteredAffiliates.splice(removedAffiliate, 1);
            this.$emit('filter-changed', {
                key: 'filteredAffiliates',
                value: newFilteredAffiliates,
                startSearch: false,
            });
        },
    }

}

</script>