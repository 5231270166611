<template>
    <gd-modal name="copy-link-modal" :title="$t('frontend_groovesell_copy_leader_board_link')" width="60%" allowOverflow>
        <!-- Leader Board Link -->
        <div style="min-width: 40vw">
            <div class="flex flex-col mx-4">
                <div class="w-full mt-12">
                    <label class="font-semibold text-sm text-grey-700 mb-2">Link</label>
                    <text-input v-model="leaderboardLink" class="w-full mt-2" :readonly="true" value="leaderboardLink"
                        :placeholder="$t('frontend_groovesell_leader_board_link')" :select-all="true" />
                </div>
                <div class="w-full text-center mb-12">
                    <gd-button v-clipboard:copy="leaderboardLink" v-clipboard:success="onCopy" v-clipboard:error="onError"
                        variant="primary" size="md" class="mt-6">
                        {{ $t('frontend_copy') }}
                    </gd-button>
                </div>
            </div>
        </div>

        <!-- Leader Board Embeed Link -->
        <div style="min-width: 40vw">
            <div class="flex flex-col mx-4">
                <div class="w-full mt-12">
                    <label class="font-semibold text-sm text-grey-700 mb-2">Embed Code</label>
                    <text-area v-model="leaderboard_embed" class="w-full mt-2" :readonly="true"
                        value="leaderboard.leaderboard_embed" :placeholder="$t('frontend_groovesell_leader_board_link')"
                        :select-all="true" />
                </div>
                <div class="w-full text-center mb-12">
                    <gd-button v-clipboard:copy="leaderboard_embed" v-clipboard:success="onCopy" v-clipboard:error="onError"
                        variant="primary" size="md" class="mt-6">
                        {{ $t('frontend_copy') }}
                    </gd-button>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
    props: {
        leaderboard: {
            required: true
        }
    },
    computed: {
        leaderboardLink() {
            if (this.leaderboard && this.leaderboard.permalink) {
                const site_appurl = window.config.appUrl.replace(/^https?:\/\//i, '');
                return `https://leaderboards.${site_appurl}/leaderboard/${this.leaderboard.permalink}`;
            }
            return '';
        },
        leaderboard_embed() {
            return this.leaderboard ? this.leaderboard.leaderboard_embed : '';
        },
    },
    methods: {
        onCopy() {
            Notify.success(this.$t('frontend_copy_link'));
        },

        onError() {
            Notify.fail(this.$t('frontend_copy_link_fail'));
        },
    }
}
</script>