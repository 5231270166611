import Marketplace from '@/pages/marketplace/index';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/affiliate-marketplace',
    name: 'affiliate_marketplace',
    component: Marketplace,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_marketplace'),
      layout: 'defaultForAffiliates'
    }
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: Marketplace,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_marketplace')
    }
  }
];
