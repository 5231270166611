<template>
    <gd-modal name="checkout-link-modal" :title="$t('frontend_pgroovesell_checkout_link')" width="1020px">
        <div class="flex" style="min-width: 40vw">
            <vertical-tabs :tabs="checkoutLinkModalTabs" :currentTab="checkoutLinkCurrentTab"
                :wrapper-class="'pt-5 w-1/3 border-r border-grey-200 pb-10'" @onClick="handleCheckoutLinkModalTabChange" />
            <div class="w-full py-5 px-6">
                <div v-if="checkoutLinkCurrentTab == 'link'">
                    <div class="w-full">
                        <label class="text-base font-bold text-grey-800 mb-2">{{
                            $t('frontend_link')
                        }}</label>
                        <text-input v-model="checkout_link" class="w-full mt-2" :readonly="true" value="checkout_link"
                            :placeholder="$t('frontend_pgroovesell_checkout_link')" :select-all="true" />
                    </div>
                    <div class="w-full text-center mb-4">
                        <gd-button v-clipboard:copy="checkout_link" v-clipboard:success="onCopy" v-clipboard:error="onError"
                            variant="primary" size="md" class="mt-6">
                            {{ $t('frontend_copy') }}
                        </gd-button>
                    </div>
                </div>

                <div v-if="checkoutLinkCurrentTab == 'embed'">
                    <div class="w-full">
                        <label class="text-base font-bold text-grey-800 mb-2">{{
                            $t('frontend_embed_link')
                        }}</label>
                        <text-area v-model="checkout_embed" class="w-full mt-2" :readonly="true" value="checkout_embed"
                            :placeholder="$t('frontend_pgroovesell_checkout_link')" :select-all="true" />
                    </div>
                    <div class="w-full text-center mb-4">
                        <gd-button v-clipboard:copy="checkout_embed" v-clipboard:success="onCopy"
                            v-clipboard:error="onError" variant="primary" size="md" class="mt-6">
                            {{ $t('frontend_copy') }}
                        </gd-button>
                    </div>
                </div>

                <div v-if="checkoutLinkCurrentTab == 'popup'">
                    <div class="w-full">
                        <label class="text-base text-grey-800 mb-2">
                            {{ $t('frontend_groovesell_link_checkout_popup_modal_text', {pagesAppTitle: whitelabelAppData('pages').title}) }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import VerticalTabs from '@/components/VerticalTabs';
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
    components: {
        VerticalTabs,
    },
    props: {
        checkout_link: {
            required: true
        },
        checkout_embed: {
            required: true
        },

    },
    data() {
        return {
            checkoutLinkCurrentTab: 'link',
            checkoutLinkModalTabs: [
                {
                    title: this.$t('frontend_product_hosted_checkout'),
                    value: 'link',
                },
                {
                    title: this.$t('frontend_product_embedded_into'),
                    value: 'embed',
                },
                {
                    title: this.$t('frontend_groovesell_link_checkout_popup_modal'),
                    value: 'popup',
                },
            ],
        }
    },
    methods: {
        handleCheckoutLinkModalTabChange(newTab) {
            this.checkoutLinkCurrentTab = newTab;
        },
        onCopy() {
            Notify.success(this.$t('frontend_product_step11_success'));
        },

        onError() {
            Notify.fail(this.$t('frontend_unable_copy_tracking_code'));
        },
    }
}
</script>