<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="allArticlesTabs"
        :current-tab="allArticlesTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleAllArticlesTabChange"
      />
    </div>
    <div class="mt-2">
      <div v-if="allArticlesTab === 'pressReleases'">
        <div v-if="hasEnabledPressReleases" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="pressReleasesTabs"
              :current-tab="pressReleasesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handlePressReleasesTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(release, index) in funnelResource.press_releases" :key="index">
              <div v-if="isPressReleaseTabActive(release)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_press_releases') }}
                    </label>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="release.press_release"
                        class="swipesEditor w-full"
                        value="release.press_release"
                        :placeholder="$t('frontend_resources_post')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="release.press_release"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="iconlg"
                          variant="primary"
                          :title="$t('frontend_resources_copy_press_release')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_press_release') }}
        </div>
      </div>
      <div v-else-if="allArticlesTab === 'articles'">
        <div v-if="hasEnabledArticles" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="articlesTabs"
              :current-tab="articlesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleArticlesTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(article, index) in funnelResource.articles" :key="index">
              <div v-if="isArticleTabActive(article)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_article') }}
                    </label>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="article.article"
                        class="swipesEditor w-full"
                        value="article.article"
                        :placeholder="$t('frontend_article')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="article.article"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="iconlg"
                          variant="primary"
                          :title="$t('frontend_groovesell_copy_article')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_articles_added_in_this') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    pressReleasesTab: {
      type: String,
      default: '',
    },
    articlesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      allArticlesTabs: [
        { title: this.$t('frontend_resources_articles'), value: 'articles' },
        { title: this.$t('frontend_resources_press_releases'), value: 'pressReleases' },
      ],
      allArticlesTab: 'articles',
    };
  },
  computed: {
    hasEnabledPressReleases() {
      return this.funnelResource
        && this.funnelResource.press_releases
        && this.funnelResource.press_releases.filter(el => el.enabled).length;
    },
    hasEnabledArticles() {
      return this.funnelResource
        && this.funnelResource.articles
        && this.funnelResource.articles.filter(el => el.enabled).length;
    },
    enabledArticles() {
      return this.funnelResource.articles.filter(el => el.enabled);
    },
    pressReleasesTabs() {
      return this.funnelResource.press_releases.filter(el => el.enabled).map(release => ({
        title: release.name,
        value: `press_release_tab_${release.id}`,
      }));
    },
    articlesTabs() {
      return this.enabledArticles.map(article => ({
        title: article.name,
        value: `articles_tab_${article.id}`,
      }));
    },
  },
  methods: {
    handleAllArticlesTabChange(newTab) {
      this.allArticlesTab = newTab;
    },
    isPressReleaseTabActive(release) {
      return this.pressReleasesTab === `press_release_tab_${release.id}`;
    },
    isArticleTabActive(article) {
      return this.articlesTab === `articles_tab_${article.id}`;
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
