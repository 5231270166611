<template>
  <div>
    <tracking></tracking>
  </div>
</template>

<script>
import Tracking from './index.vue';

export default {
  layout: 'defaultForAffiliates',
  components: {
    Tracking,
  },
};
</script>
