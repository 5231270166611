<template>
  <default
    sidebar="affiliates"
  />
</template>
<script>
import Default from '@/layouts/default';

export default {
  components: {
    Default
  },
};
</script>
