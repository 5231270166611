<template>
    <gd-modal name="affiliate-links-modal" :title="$t('frontend_affiliate_links')">
        <div class="flex flex-col">
            <div v-if="affiliateLinks.length">
                <gd-tabs :tabs="affiliateLinksTabs" :current-tab="affiliateLinksTab"
                    :wrapper-class="'pt-4 px-2 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                    :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                    :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiliateLinksTabChange" />

                <div>
                    <div v-if="affiliateLinksTab === 'aff_links'" style="height: calc(80vh - 15rem)">
                        <div class="mt-8 px-4 h-full max-h-full overflow-y-auto">
                            <div v-for="(affiliateLink, affiliateLinkIndex) in directAffiliateLinks"
                                :key="affiliateLinkIndex" class="flex flex-col mb-6">
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ affiliateLink.name }}
                                </label>
                                <div class="flex flex-row">
                                    <text-input v-model="affiliateLink.link" class="mr-2" :readonly="true"
                                        value="affiliateLink.link"
                                        :placeholder="$t('frontend_affiliate_promos_affiliate_link')" :select-all="true" />
                                    <gd-button v-clipboard:copy="affiliateLink.link" v-clipboard:success="onCopy"
                                        v-clipboard:error="onError" size="iconlg" variant="primary"
                                        :title="$t('frontend_affiliate_promos_copy_affiliate_link')">
                                        <copy-icon class="w-5 h-5 fill-current" />
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="affiliateLinksTab === 'jv_broker_links'" class="mt-8 px-4">
                        <div v-for="(affiliateLink, affiliateLinkIndex) in jvBrokerLinks" :key="affiliateLinkIndex"
                            class="flex flex-col mb-6">
                            <label class="font-semibold text-sm text-grey-700 mb-2">
                                {{ affiliateLink.name }}
                            </label>
                            <div class="flex flex-row">
                                <text-input v-model="affiliateLink.link" class="mr-2" :readonly="true"
                                    value="affiliateLink.link" :placeholder="$t('frontend_groovesell_copy_jv_broker_link')"
                                    :select-all="true" />
                                <gd-button v-clipboard:copy="affiliateLink.link" v-clipboard:success="onCopy"
                                    v-clipboard:error="onError" size="iconlg" variant="primary"
                                    :title="$t('frontend_groovesell_copy_jv_broker_link')">
                                    <copy-icon class="w-5 h-5 fill-current" />
                                </gd-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                {{ $t('frontend_affiliate_index_unable_to_fetch') }}
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Notify from '@groovepages/gd-ui-kit/notify';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
    components: {
        CopyIcon,
    },
    props: {
        affiliateLinks: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            affiliateLinksTab: 'aff_links',
        }
    },
    computed: {
        jvBrokerLinks() {
            return this.affiliateLinks.filter((link) => {
                return link.type == 'second_tier';
            });
        },
        directAffiliateLinks() {
            return this.affiliateLinks.filter((link) => {
                return link.type == 'affiliate';
            });
        },
        affiliateLinksTabs() {
            const secondTierLinksLength = this.affiliateLinks.filter((link) => {
                return link.type == 'second_tier';
            }).length;

            if (secondTierLinksLength > 0) {
                return [
                    {
                        title: this.$t('frontend_affiliate_link_tabs_afiliate_links'),
                        value: 'aff_links',
                    },
                    {
                        title: this.$t(
                            'frontend_affiliate_link_tabs_afiliate_jv_broker_links'
                        ),
                        value: 'jv_broker_links',
                    },
                ];
            }
            return [
                {
                    title: this.$t('frontend_affiliate_link_tabs_afiliate_links'),
                    value: 'aff_links',
                },
            ];
        },
    },
    methods: {
        handleAffiliateLinksTabChange(tab) {
            this.affiliateLinksTab = tab;
        },
        onCopy() {
            Notify.success(this.$t('frontend_aff_link_copied'));
        },
        onError() {
            Notify.fail(this.$t('frontend_aff_link_unable_copy'));
        },
    }
}
</script>