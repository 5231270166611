<template>
  <div>
    <div class="w-full">
      <v-wait for="funnels">
        <filters
          :filters-model="filtersModel"
          :selected-row-length="selectedRowLength"
          :grid-api="gridApi"
          :unselected-affiliate-ids="unselectedAffiliateIds"
          :is-all-selected="isAllSelected"
          :filtered-model="filteredModel"
          :funnels="funnels"
          @updateFilters="updateFilters"
          @openSecondTierExportModal="openSecondTierExportModal"
          @selectAllAffiliates="selectAllAffiliates"
          @unselectAllAffiliates="unselectAllAffiliates"
          @clearFilters="clearFilters"
          @applyFilters="applyFilters"
        />

        <div id="affiliates-list-view" class="mt-4">
          <ag-grid-vue
            class="ag-theme-material"
            style="width: 100%"
            :dom-layout="domLayout"
            :grid-options="gridOptions"
            :column-defs="affiliatesTableColumnDefs"
            :pagination="true"
            :row-height="affiliatesTableRowHeight"
            :row-buffer="rowBuffer"
            :row-model-type="rowModelType"
            :datasource="dataSource"
            :pagination-page-size="paginationPageSize"
            :cache-overflow-size="cacheOverflowSize"
            :infinite-initial-row-count="infiniteInitialRowCount"
            :max-blocks-in-cache="maxBlocksInCache"
            :cache-block-size="cacheBlockSize"
            :suppress-row-click-selection="true"
            :suppress-cell-selection="true"
            :row-class="rowClass"
            :row-class-rules="rowClassRules"
            :context="context"
            :framework-components="frameworkComponents"
            :row-selection="rowSelection"
            :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
            @grid-ready="onGridReady"
            @model-updated="onModelUpdated"
            @row-selected="onRowSelected"
            @selection-changed="onSelectionChanged"
          ></ag-grid-vue>
        </div>

        <affiliate-detail-modal
          ref="affiliateDetailModal"
          :selected-affiliate="selectedAffiliate"
          :filters-model="filtersModel"
          @updateSelectedAffiliate="updateSelectedAffiliate"
          @changedSearchOrFilter="changedSearchOrFilter"
        />

        <affiliates-second-tier-export-modal
          :export-second-tier-affiliates-response="exportSecondTierAffiliatesResponse"
          @getSecondTierStatus="getSecondTierStatus"
          @unselectAllAffiliates="unselectAllAffiliates"
        />
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { AgGridVue } from 'ag-grid-vue';
import Funnel from '@/models/Funnel';
import SecondTierAffiliate from '@/models/SecondTierAffiliate';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AffiliateFullNameRenderer from '@/renderers/affiliateFullNameRenderer';
import AffiliateSalesRenderer from '@/renderers/affiliateSalesRenderer';
import AffiliateRefundsRenderer from '@/renderers/affiliateRefundsRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';
import Filters from './second_tier_index_partials/Filters.vue';
import AffiliateDetailModal from './second_tier_index_partials/modals/AffiliateDetailModal.vue';
import AffiliatesSecondTierExportModal from './second_tier_index_partials/modals/AffiliatesSecondTierExportModal.vue';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Second Tier Affiliates' };
  },
  mixins: [createLinkMixin],
  components: {
    AgGridVue,
    Filters,
    AffiliateDetailModal,
    AffiliatesSecondTierExportModal,
},
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      filteredModel: {
        secondTierCommissionsAmount: 0,
        filteredDueSecondTierCommissionsType: false,
        filteredSecondTierDueAmount: 0,
      },
      gridOptions: {},
      gridApi: null,
      dataSource: null,
      affiliates: [],
      paginationPageSize: 25,
      cacheBlockSize: 25,
      affiliatesTableRowHeight: 40,
      rowBuffer: 0,
      rowModelType: 'infinite',
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2,
      domLayout: 'autoHeight',
      affiliatesTableColumnDefs: null,
      filtersModel: {
        filteredAffiliateTypes: 0,
        searchPhrase: '',
        selectedRowSize: 25,
        selectedFunnel: 0,
      },
      rowClassRules: {},
      rowClass: 'affiliates-row',
      context: null,
      frameworkComponents: null,
      selectedAffiliate: null,
      rowSelection: 'multiple',
      isAllSelected: false,
      unselectedAffiliateIds: [],
      selectedRowLength: 0,
      funnels: [],
      exportSecondTierAffiliatesResponse: {
        paypal: {
          count: 0,
          amount: '$0.00',
        },
        check: {
          count: 0,
          amount: '$0.00',
        },
        wire: {
          count: 0,
          amount: '$0.00',
        },
        on_hold: {
          count: 0,
          amount: '$0.00',
        },
        total: {
          count: 0,
          amount: '$0.00',
        },
        hash: '',
      },
    };
  },
  async beforeMount() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer,
    };

    this.affiliatesTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_affiliate_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'rendered_name',
            cellRenderer: 'affiliateFullNameRenderer',
            colId: 'affiliateFullName',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'rendered_username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_second_tier_comissions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned_comissions'),
            field: 'human_readable_total_second_tier_commissions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid_comissions'),
            field: 'human_readable_pending_second_tier_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_due_comissions'),
            field: 'human_readable_processed_without_advances',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_being_processed_comissions'),
            field: 'human_readable_in_process_second_tier_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_amount_paid'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_comissions_on_hold'),
            field: 'human_readable_on_hold_second_tier_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_all_paid_comissions'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_email'),
            field: 'rendered_email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
    ];
  },
  async created() {
    this.$wait.start('funnels');
    const funnels = await Funnel.$get();

    if (funnels.length > 0) {
      this.funnels = funnels;
      this.filtersModel.selectedFunnel = funnels[0].id;
    }

    this.$wait.end('funnels');
  },
  methods: {
    updateSelectedAffiliate(affiliate) {
      this.selectedAffiliate = affiliate;
    },
    updateFilters(model) {
      this.filtersModel = model;
      this.changedSearchOrFilter();
    },
    applyFilters(model) {
      this.filteredModel = model;
      this.changedSearchOrFilter();
      this.isAllSelected = false;
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },
    clearFilters() {
      this.filteredModel.secondTierCommissionsAmount = 0;
      this.filteredModel.dueSecondTierCommissionsType = false;
      this.filteredModel.secondTierDueAmount = 0;
      this.changedSearchOrFilter();
    },
    async getSecondTierStatus(affiliateSecondTierPaymentExportId) {
      try {
        const { data } = await axios.get(`affiliate-payment-export-status/${affiliateSecondTierPaymentExportId}`);
        this.handleStatus(data.status, data);
      } catch (error) {
        this.handleError();
      }
    },
    handleStatus(status, data) {
      switch (status) {
        case 'processing':
          setTimeout(() => this.getSecondTierStatus(), 5000);
          break;
        case 'processed':
          this.handleProcessedStatus(data);
          break;
        default:
          this.handleError();
          break;
      }
    },
    handleProcessedStatus(data) {
      this.openLink(data.download_link, '_self');
      this.unselectAllAffiliates();

      this.alertProps = {
        show: true,
        variant: 'success',
        title: this.$t('frontend_processed_successfully'),
        message: this.$t('frontend_groovesell_swal_export_file_successfully'),
      };
      this.$root.$emit('modal-close', 'affiliates-second-tier-export-modal');
    },
    handleError() {
      this.alertProps = {
        show: true,
        variant: 'error',
        title: this.$t('frontend_error'),
        message: this.$t('frontend_backend_error'),
      };
    },
    openSecondTierExportModal(exportLinkData) {
      this.exportSecondTierAffiliatesResponse.paypal = exportLinkData.data.paypal;
      this.exportSecondTierAffiliatesResponse.wire = exportLinkData.data.wire;
      this.exportSecondTierAffiliatesResponse.hash = exportLinkData.data.hash;
      this.exportSecondTierAffiliatesResponse.on_hold = exportLinkData.data.on_hold;
      this.exportSecondTierAffiliatesResponse.total = exportLinkData.data.total;

      this.$root.$emit(
        'modal-open',
        'affiliates-second-tier-export-modal'
      );
    },
    onModelUpdated() {
      if (this.isAllSelected) {
        const self = this;

        this.gridApi.forEachNode((rowNode) => {
          if (!self.unselectedAffiliateIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },
    onRowSelected(event) {
      if ( !event.node.selected && this.unselectedAffiliateIds.indexOf(event.node.data.id) === -1) {
        this.unselectedAffiliateIds.push(event.node.data.id);
      } else {
        this.unselectedAffiliateIds = this.unselectedAffiliateIds.filter(
          (item) => item !== event.node.data.id
        );
      }
    },
    selectAllAffiliates() {
      this.isAllSelected = true;
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
      });

      this.unselectedAffiliateIds = [];
    },
    unselectAllAffiliates() {
      this.isAllSelected = false;
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });

      this.unselectedAffiliateIds = [];
    },
    async changedSearchOrFilter() {
      this.dataSource = await this.getDataSource(Number(this.filtersModel.selectedRowSize));
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.filtersModel.selectedRowSize));
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getDataSource');

        const affiliatesData = await SecondTierAffiliate.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            affiliates: self.filtersModel.filteredAffiliateTypes,
            search: self.filtersModel.searchPhrase,
            funnels: self.filtersModel.selectedFunnel,
            sortBy: params.sortModel,
            commissionsAmount: self.filteredModel.secondTierCommissionsAmount,
            dueSecondTierCommissionsType:
              self.filteredModel.dueSecondTierCommissionsType,
            dueSecondTierAmount: self.filteredModel.secondTierDueAmount,
          })
          .get();

        let rowsThisPage = affiliatesData.data || [];
        const totalNumberOfRows = affiliatesData.meta.total;
        self.funnels = affiliatesData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.filtersModel.selectedRowSize));
    },
  },
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#second-tier-affiliates-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
