<template>
  <div v-if="hasAffiliateLinks" class="flex flex-col">
    <!-- Affiliate Links Tabs -->
    <gd-tabs
      :tabs="affiliateLinksTabs"
      :current-tab="affiliateLinksTab"
      wrapper-class="pt-4 px-0 gs-default-tabs"
      tab-class="pb-4 mr-4 gs-default-tabs-item"
      tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
      line-class="gs-default-tabs-active-line"
      @onClick="handleAffiliateLinksTabChange"
    />

    <!-- Affiliate Links Content -->
    <div>
      <div v-if="isAffiliateLinksTab('aff_links')" class="mt-8 px-4">
        <div v-for="(affiliateLink, index) in directAffiliateLinks" :key="index" class="flex flex-col mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ affiliateLink.name }}
          </label>
          <div class="flex flex-row">
            <text-input
              v-model="affiliateLink.link"
              class="mr-2"
              :readonly="true"
              value="affiliateLink.link"
              :placeholder="$t('frontend_affiliate_promos_affiliate_link')"
              :select-all="true"
            />
            <gd-button
              v-clipboard:copy="affiliateLink.link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              size="iconlg"
              variant="primary"
              :title="$t('frontend_affiliate_promos_copy_affiliate_link')"
            >
              <copy-icon class="w-5 h-5 fill-current" />
            </gd-button>
          </div>
        </div>
      </div>

      <div v-if="isAffiliateLinksTab('jv_broker_links')" class="mt-8 px-4">
        <div v-for="(affiliateLink, index) in jvBrokerLinks" :key="index" class="flex flex-col mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ affiliateLink.name }}
          </label>
          <div class="flex flex-row">
            <text-input
              v-model="affiliateLink.link"
              class="mr-2"
              :readonly="true"
              value="affiliateLink.link"
              :placeholder="$t('frontend_groovesell_jv_broker_link')"
              :select-all="true"
            />
            <gd-button
              v-clipboard:copy="affiliateLink.link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              size="iconlg"
              variant="primary"
              :title="$t('frontend_groovesell_copy_jv_broker_link')"
            >
              <copy-icon class="w-5 h-5 fill-current" />
            </gd-button>
          </div>
        </div>

        <p class="mt-2">
          <span class="text-red font-semibold">{{ $t('frontend_analytics_transactions_note') }}</span>
          {{ $t('affiliate_recruitement_description') }}
        </p>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_affiliate_promo_no_affiliate') }}
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      affiliateLinksTab: 'aff_links',
    };
  },
  computed: {
    hasAffiliateLinks() {
      return (
        this.funnelResource &&
        this.funnelResource.affiliate_links &&
        this.funnelResource.affiliate_links.length
      );
    },
    affiliateLinksTabs() {
      if (this.funnelResource && this.funnelResource.affiliate_links) {
        const hasSecondTierLinks = this.funnelResource.affiliate_links.some((link) => link.type == 'second_tier');
        return [
          { title: this.$t('frontend_resource_my_affiliate_links'), value: 'aff_links' },
          ...(hasSecondTierLinks ? [{ title: this.$t('frontend_resource_my_jv_broker'), value: 'jv_broker_links' }] : []),
        ];
      }
      return [];
    },
    directAffiliateLinks() {
      return (
        this.funnelResource &&
        this.funnelResource.affiliate_links &&
        this.funnelResource.affiliate_links.filter((link) => link.type === 'affiliate') || []
      );
    },
    jvBrokerLinks() {
      return (
        this.funnelResource &&
        this.funnelResource.affiliate_links &&
        this.funnelResource.affiliate_links.filter((link) => link.type === 'second_tier') || []
      );
    },
  },
  methods: {
    handleAffiliateLinksTabChange(newTab) {
      this.affiliateLinksTab = newTab;
    },
    isAffiliateLinksTab(tab) {
      return this.affiliateLinksTab === tab;
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
