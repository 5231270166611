import Vue from 'vue';
import Vuex, { mapState } from 'vuex';
import core from '@groovepages/gd-lib/core-store';
import records from '@groovepages/gd-lib/records-store';
import domains from '@groovepages/gd-lib/domain-store';
import subdomains from '@groovepages/gd-lib/subdomains-store';
import internal from './modules/internal';

Vue.use(Vuex);

Vue.mixin({
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    whitelabelAppData(slug) {
      return this.$store.state.brandData.apps.filter(app => app.slug == slug)[0];
    }
  }
});

export default new Vuex.Store({
  ...core,
  modules: {
    core,
    records,
    domains,
    subdomains,
    internal,
  },
  getters: {
    user: state => state.user,
    pluginSiteSettings: (state) => {
      return {
        integrations:
          (state.user &&
            state.user.metadata &&
            state.user.metadata.integrations) ||
          []
      };
    },
    isPlatinumUser: state => {
      return state.user.roles.indexOf('groove_future_apps') >= 0;
    },
    isGroovePagesUser: state => {
      return state.user.roles.indexOf('groovepages_user') >= 0;
    },
  }
});
