<template>
    <div>
        <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step4_sales_page_url')
            }}</label>
            <text-input v-model="local_sales_page" :validator="validation.localModel.sales_page"
                :attribute="$t('frontend_product_step4_sales_page_url')" value="sales_page"
                :placeholder="$t('frontend_product_step4_sales_page')" />
        </div>
    </div>
</template>
<script>

export default {
    props: {
        sales_page: {
            required: true,
        },
        validation: {
            required: true,
        },
    },
    data() {
        return {
            local_sales_page: this.sales_page,
        };
    },
    watch: {
        local_sales_page() {
            this.validation.localModel.sales_page.$touch();
            this.$emit('update-model', {
                sales_page: this.local_sales_page
            });
        },
    }


}
</script>
