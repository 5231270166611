<template>
  <div>
    <gd-button
      size="expand"
      :variant="buyButtonVariants"
      :overrideClass="buyButtonClasses"
      :disabled="isProcessing"
      @click="purchasePostSaleBump"
    >
      {{ buynowText }}
    </gd-button>

    <gd-button
      size="expand"
      class="mt-4"
      variant="outline"
      :overrideClass="noThanksButtonClasses"
      @click="clickedNoThanks"
    >
      {{ $t('frontend_no_thanks') }}
    </gd-button>
  </div>
</template>

<script>
export default {
  props: {
    buynowText: String,
    isProcessing: Boolean,
    product: Object,
  },
  computed: {
    buyButtonVariants() {
      const templateType = this.product.checkout_page_options.look_and_feel.template_type;

      return !this.isProcessing && (templateType != 2 || templateType != 3)
        ? 'primary'
        : 'base';
    },
    buyButtonClasses() {
      const templateType = this.product.checkout_page_options.look_and_feel.template_type;
      let overideClass = '';
      if (!this.isProcessing && templateType == 2) {
        overideClass = 'bg-blue hover:bg-blue-dark border-none';
      }

      if (!this.isProcessing && templateType == 3) {
        overideClass = 'bg-grey-600 hover:bg-grey-600 border-none';
      }

      return overideClass;
    },
    noThanksButtonClasses() {
      const templateType = this.product.checkout_page_options.look_and_feel.template_type;
      let overideClass = '';
      if (templateType == 2) {
        overideClass = 'border-blue text-blue';
      }

      if (templateType == 3) {
        overideClass = 'border-grey-600 text-grey-600';
      }

      return overideClass;
    },
  },
  methods: {
    clickedNoThanks() {
      this.$emit('clickedNoThanks');
    },
    purchasePostSaleBump() {
      this.$emit('purchasePostSaleBump');
    },
  },
};
</script>
