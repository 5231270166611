import Model from './Model';

export default class CheckoutLink extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'checkout-links';
  }

  get checkout_link() {
    const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
    if (this.has_custom_domain && this.custom_domain) {
      return `https://${this.custom_domain}/checkout/${this.permalink}`;
    }
    return `https://${this.subdomain}.${siteAppurl}/checkout/${this.permalink}`;
  }

  get checkout_embed() {
    const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
    const groovesellWhiteLabelAppName = window.config.groovesellWhiteLabelAppName;
    return `<div style="min-height:220px; width: 100%; max-width:500px"><link href="https://${this.subdomain}.${siteAppurl}/embed/css/app.css?n=1" rel="stylesheet"><${groovesellWhiteLabelAppName}-embed subdomain="${this.subdomain}" checkout="${this.permalink}"></${groovesellWhiteLabelAppName}-embed><script src="https://${this.subdomain}.${siteAppurl}/embed/js/app.js?n=1"></script></div>`;
  }
}
