<template>
  <div class="min-h-full h-auto bg-secondary pb-6">
    <div class="relative flex flex-col justify-start w-full bg-white px-5">
      <div class="flex flex-row w-full container mx-auto">
        <gd-tabs
          :tabs="tabs"
          :current-tab="currentTab"
          :disable-not-completed="true"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
        />
        <div class="flex flex-row ml-8">
          <gd-button
            v-if="currentTab != 'info'"
            size="auto"
            class="mr-4 mt-3"
            @click="moveBackOnWizard"
          >
            {{ $t('frontend_tracking_back') }}
          </gd-button>
          <gd-button
            v-if="currentTab != 'finish'"
            variant="primary"
            size="auto"
            class="mr-4 mt-3"
            @click="moveNextOnWizard"
          >
            {{ $t('frontend_bumps_save_next') }}
          </gd-button>
          <gd-button
            v-else
            variant="primary"
            size="auto"
            class="mr-4 mt-3"
            @click="saveAndExitWizard"
          >
            {{ $t('frontend_bumps_save_exit') }}
          </gd-button>
        </div>
      </div>
    </div>

    <v-wait for="create_tracking_link">
      <div class="px-5">
        <div class="relative flex flex-col justify-start w-full h-full mt-8 container mx-auto">
          <div v-if="currentTab === 'info'">
            <info-tab
              ref="info"
              :model="model"
              :domains="domains"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('info', $event)"
            ></info-tab>
          </div>

          <div v-else-if="currentTab === 'type'">
            <type-tab
              ref="type"
              :model="model"
              :funnels="funnels"
              :affiliates="affiliate_funnels"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('type', $event)"
            ></type-tab>
          </div>

          <div v-if="currentTab === 'goals'">
            <goals-tab
              ref="goals"
              :model="model"
              :funnels="funnels"
              :affiliate-funnels="affiliate_funnels"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('goals', $event)"
            ></goals-tab>
          </div>

          <div v-else-if="currentTab === 'advancedSettings'">
            <advanced-settings-tab
              ref="advancedSettings"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('advancedSettings', $event)"
            ></advanced-settings-tab>
          </div>

          <div v-else-if="currentTab === 'finish'">
            <finish-tab
              ref="finish"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('finish', $event)"
            ></finish-tab>
          </div>
        </div>
      </div>
    </v-wait>

    <portal to="headerBreadCrumb">
      <div class="cursor-pointer flex items-center -ml-4" @click="sendToTrackingLinks">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import TrackingLink from '@/models/TrackingLink';
import axios from 'axios';
import InfoTab from './create_partials/InfoTab.vue';
import TypeTab from './create_partials/TypeTab.vue';
import GoalsTab from './create_partials/GoalsTab.vue';
import AdvancedSettingsTab from './create_partials/AdvancedSettingsTab.vue';
import FinishTab from './create_partials/FinishTab.vue';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

export default {
  layout: 'defaultWithoutSidebar',
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('create_tracking_link') };
  },
  components: {
    InfoTab,
    TypeTab,
    GoalsTab,
    AdvancedSettingsTab,
    FinishTab,
    ChevronLeftIcon,
  },
  data() {
    return {
      tabs: [
        {
          title: `<span class="text-sm font-semibold">1. ${this.$t('frontend_groovesell_tracking_create_tabs1')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'info',
        },
        {
          title: `<span class="text-sm font-semibold">2. ${this.$t('frontend_groovesell_tracking_create_tabs2')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'type',
        },
        {
          title: `<span class="text-sm font-semibold">3. ${this.$t('frontend_groovesell_tracking_create_tabs3')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'goals',
        },
        {
          title: `<span class="text-sm font-semibold">4. ${this.$t('frontend_groovesell_tracking_create_tabs4')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'advancedSettings',
        },
        {
          title: `<span class="text-sm font-semibold">5. ${this.$t('frontend_groovesell_tracking_create_tabs5')}<span>`,
          value: 'finish',
        },
      ],
      postValidationTab: 'type',
      currentTab: 'info',
      edit_link: false,
      trackingLink: null,
      domains: [],
      domain: 0,
      model: {
        name: '',
        description: '',
        type: 1,
        domain: 0,
        redirect_parameters: {
          type: 1,
          url: this.groovepagesUrl(),
          funnel_id: 0,
          product_id: 0,
          link_id: 0,
        },
        advanced_settings: {
          tracking_codes: '',
          cpc: 0,
          tracking_ids: '',
        },
        goals: [],
        use_tracking_ids: 0,
        tracking_link: null,
      },
      funnels: null,
      affiliate_funnels: null,
    };
  },
  async created() {
    this.$wait.start('create_tracking_link');

    if (this.$route.params.link_id == undefined) {
      this.initializeTrackingLink();
    } else {
      await this.fetchTrackingLink(this.$route.params.link_id);
      this.updateModelFromTrackingLink();
      this.edit_link = true;
    }

    await this.loadData();
    this.$wait.end('create_tracking_link');
  },

  methods: {
    groovepagesUrl() {
      const pagesAppTitle = this.whitelabelAppData('pages').title.toLowerCase();
      return `https://${pagesAppTitle}.com`;
    },
    async initializeTrackingLink() {
      this.trackingLink = new TrackingLink({});
    },

    async fetchTrackingLink(id) {
      this.trackingLink = await TrackingLink.$find(id);
    },

    updateModelFromTrackingLink(updateTrackingParameters = true) {
      const properties = [
        'name', 'description', 'type', 'redirect_parameters', 'advanced_settings',
        'goals', 'use_tracking_ids', 'tracking_link', 'domain'
      ];
      properties.forEach(prop => {
        this.model[prop] = this.trackingLink[prop];
      });

      if (updateTrackingParameters) {
        this.model.tracking_parameters = this.trackingLink.tracking_parameters;
      }
    },

    async loadData() {
      const user_domains = await axios.get(`get-user-domains/2`);
      this.domains = user_domains.data.data;
      this.model.domain = this.domains[0]?.id;
      const { data } = await axios.get('funnels-breakdown');
      this.funnels = data.funnels;
      this.affiliate_funnels = data.affiliate_funnels;
    },

    sendToTrackingLinks() {
      const routeName = window.location.href.includes('affiliate-tracking') 
        ? 'affiliate_tracking' 
        : 'tracking';
      this.$router.push({ name: routeName });
    },

    moveNextOnWizard() {
      const index = this.tabs.findIndex(tab => tab.value === this.currentTab);
      const nextTab = this.tabs[index + 1]?.value;
      this.postValidationTab = nextTab;
      
      return this.$refs[this.currentTab]?.validate();
    },
    moveBackOnWizard() {
      const index = this.tabs.findIndex(tab => tab.value === this.currentTab);
      const prevTab = this.tabs[index - 1]?.value;
      this.postValidationTab = prevTab;
      
      return this.$refs[this.currentTab]?.validate();
    },
    async saveAndExitWizard() {
      await this.saveAllInfo();
      this.sendToTrackingLinks();
    },
    async saveBasicTrackingLinkInfo() {
      this.$loader.start('saveBasicTrackingLinkInfo');
      this.setTrackingLinkFromModel();
      const savedTrackingLinkData = await this.trackingLink.save();

      if (!this.edit_link) {
        this.trackingLink = await TrackingLink.$find(savedTrackingLinkData.data.id);
      }

      this.updateModelFromTrackingLink();
      this.edit_link = true;
      this.$loader.end('saveBasicTrackingLinkInfo');

      return true;
    },
    setTrackingLinkFromModel() {
      const properties = [
        'name', 'description', 'domain', 'type', 'redirect_parameters',
        'goals', 'advanced_settings', 'use_tracking_ids', 'tracking_parameters'
      ];

      properties.forEach(prop => {
        this.trackingLink[prop] = this.model[prop];
      });
    },
    async saveAllInfo() {
      this.$loader.start('saveAllInfo');
      await this.saveTrackingLinkInfo();
      this.$loader.end('saveAllInfo');
      return true;
    },
    async saveTrackingLinkInfo() {
      this.$loader.start('saveTrackingLinkInfo');
      this.setTrackingLinkFromModel();

      const savedTrackingLinkData = await this.trackingLink.save();
      this.trackingLink = await TrackingLink.$find(savedTrackingLinkData.data.id);
      this.updateModelFromTrackingLink(false);
      this.$loader.end('saveTrackingLinkInfo');
    },

    async stepValidationSuccessful(name, isValid) {
      if (isValid) {
        name == 'info'
          ? await this.saveBasicTrackingLinkInfo()
          : await this.saveTrackingLinkInfo();

        this.currentTab = this.postValidationTab;
      }
    },
  },
};
</script>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
