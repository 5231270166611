<template>
  <div class="w-full flex flex-col text-grey-500">
    <!-- Trial Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.trialAmount | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_initial_sales') }}
        </span>
      </div>
    </div>

    <!-- Sales and Rebills Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-add-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.salesAmount | currency }} + {{ revenueViewData.rebillsAmount | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_new_sales') }}
        </span>
      </div>
    </div>

    <!-- Revenue Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <dollar-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.revenueAmount | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_revenue') }}
        </span>
      </div>
    </div>

    <!-- Commission Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <handshake-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.commissionAmount | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_commision') }}
        </span>
      </div>
    </div>

    <!-- Profit Amount -->
    <div v-if="!isAffiliateReportPage" class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <funnel-dollar-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.profit | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_profit') }}
        </span>
      </div>
    </div>

    <!-- Refunds Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-arrow-down-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.refundsAmount | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_refunds') }}
        </span>
      </div>
    </div>

    <!-- Net Profit Amount -->
    <div v-if="!isAffiliateReportPage" class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <piggy-bank-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.netProfit | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_net_profit') }}
        </span>
      </div>
    </div>

    <!-- EPC Earned Amount -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <credit-card-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ revenueViewData.epcEarned | currency }}
        </p>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_epc_earned') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import ShoppingCartAddIcon from '@/assets/images/icons/ShoppingCartAddIcon.svg';
import DollarIcon from '@/assets/images/icons/DollarIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import FunnelDollarIcon from '@/assets/images/icons/FunnelDollarIcon.svg';
import ShoppingCartArrowDownIcon from '@/assets/images/icons/ShoppingCartArrowDownIcon.svg';
import PiggyBankIcon from '@/assets/images/icons/PiggyBankIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';

export default {
  components: {
    ShoppingCartIcon,
    ShoppingCartAddIcon,
    DollarIcon,
    HandshakeIcon,
    FunnelDollarIcon,
    ShoppingCartArrowDownIcon,
    PiggyBankIcon,
    CreditCardIcon,
  },
  props: {
    revVolumeKey: {
      required: true,
    },
    revenueViewData: {
      type: Object,
      required: true,
    },
    isAffiliateReportPage: {
      default: false,
    },
  },
}
</script>
