<template>
  <div>
    <div class="w-full">
      <v-wait for="transactions">
        <div class="vendor-reports bg-white rounded border border-grey-200 mt-4">
          <filters
            :is-affiliate-report-page="true"
            :transactions-date-range="transactionsDateRange"
            :filtered-funnels="filteredFunnels"
            :funnels-dropdown="funnelsDropdown"
            @updateDateRange="updateDateRange"
            @updatedFilteredFunnels="filteredFunnels = $event"
            @clearFilters="clearFilters"
            @reloadData="reloadData"
            @applyFilters="applyFilters"
          />

          <div class="w-full p-4 text-grey-900">
            <div class="flex">
              <div class="w-1/4 mb-6">
                <div class="flex mb-4">
                  <div class="w-full">
                    <gd-tabs
                      :tabs="transactionTabs"
                      :current-tab="transactionsTab"
                      wrapper-class="px-0 gs-default-tabs"
                      tab-class="pb-2 mr-4 gs-default-tabs-item"
                      tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
                      line-class="gs-default-tabs-active-line"
                      @onClick="handleTransactionsTabChange"
                    />
                  </div>
                </div>
                <div class="mb-4 flex flex-col">
                  <revenue-view
                    v-if="revenueView"
                    :is-affiliate-report-page="true"
                    :rev-volume-key="revVolumeKey"
                    :revenue-view-data="revenueViewData"
                  />

                  <default-view
                    v-else
                    :rev-volume-key="revVolumeKey"
                    :default-view-data="defaultViewData"
                  />
                </div>
              </div>

              <chart
                ref="chart"
                :is-affiliate-report-page="true"
                :chart-data="chartData"
                :revenue-view="revenueView"
                :series="series"
                :filter-btn="filterBtn"
                @setRevenueView="setRevenueView"
                @updateFilterBtn="updateFilterBtn"
              />
            </div>
          </div>
        </div>
      </v-wait>
    </div>
    <portal to="headerContent">
      <gd-button variant="secondary" size="sm" class="w-full font-bold px-3" @click="goToPartnerProgram">
        {{ $t('groove_affiliate_program') }}
      </gd-button>
    </portal>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import RevenueView from './report_partials/RevenueView.vue';
import DefaultView from './report_partials/DefaultView.vue';
import Filters from './report_partials/Filters.vue';
import Chart from './report_partials/Chart.vue';

export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  metaInfo() {
    return { title: this.$t('affiliate_report') };
  },
  components: {
    AgGridVue,
    RevenueView,
    DefaultView,
    Filters,
    Chart,
  },
  data() {
    return {
      revVolumeKey: 1,
      revenueViewData: {
        trialAmount: 0,
        salesAmount: 0,
        rebillsAmount: 0,
        revenueAmount: 0,
        commissionAmount: 0,
        profit: 0,
        refundsAmount: 0,
        netProfit: 0,
        epcEarned: 0,
      },
      defaultViewData: {
        visitorsCount: 0,
        signupsCount: 0,
        trialCount: 0,
        salesCount: 0,
        rebillsCount: 0,
        refundsCount: 0,
        cancelCount: 0,
      },
      chartData: {
        activeSubscriptionsCount: 0,
        retentionLength: 0,
        projected: 0,
        projectedAfterAff: 0,
        projectedAfterAffRefunds: 0,
      },
      funnelsDropdown: [],
      filteredFunnels: [],
      revenueView: true,
      filterBtn: 'revenue',
      transactionsDateRange: {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
      },
      series: [
        {
          name: '',
          data: [],
        },
      ],
      transactionTabs: [
        { title: this.$t('frontend_analytics_vendor_total'), value: 'total' },
        { title: this.$t('frontend_analytics_vendor_sales'), value: 'sales' },
        { title: this.$t('frontend_analytics_vendor_rebills'), value: 'rebills' },
      ],
      transactionsTab: 'total',
    };
  },
  async created() {
    if (this.$route.query.fromDate && this.$route.query.toDate) {
      this.transactionsDateRange = {
        startDate: moment(this.$route.query.fromDate, 'MM/DD/YYYY'),
        endDate: moment(this.$route.query.toDate, 'MM/DD/YYYY'),
      };
    }

    if (this.$route.query.funnels) {
      this.filteredFunnels = JSON.parse(this.$route.query.funnels);
    }

    this.GetChartData();
  },
  methods: {
    goToPartnerProgram() {
      window.open('https://groovejv.com', '_blank');
    },
    updateDateRange(transactionsDateRange) {
      this.transactionsDateRange = transactionsDateRange;
      this.GetChartData();
    },
    updateFilterBtn(filterBtn) {
      this.filterBtn = filterBtn;
      this.GetChartData();
    },
    setRevenueView() {
      this.revVolumeKey++;
      this.revenueView = !this.revenueView;
    },
    clearFilters() {
      this.filteredFunnels = [];
      this.GetChartData();
    },
    reloadData() {
      this.GetChartData();
    },
    applyFilters() {
      this.GetChartData();
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },
    async GetChartData() {
      const { startDate, endDate } = this.transactionsDateRange;

      // this.$loader.start('GetChartData');

      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const transactionsData = await axios.post('/charts/get-affiliate-report', {
        fromDate: moment(startDate).format('MM/DD/YYYY'),
        toDate: moment(endDate).format('MM/DD/YYYY'),
        diffDays,
        chart_value: this.filterBtn,
        selected_tab: this.transactionsTab,
        funnels: this.filteredFunnels,
      });

      this.series[0].data = transactionsData.data.chart_values;
      this.updateSeriesLine();

      const selectedTabData  = transactionsData.data.selected_tab_data;
      this.revenueViewData = {
        refundsAmount: selectedTabData.refunded,
        revenueAmount: selectedTabData.revenue,
        commissionAmount: selectedTabData.commission,
        trialAmount: selectedTabData.trials,
        salesAmount: selectedTabData.sales,
        rebillsAmount: selectedTabData.rebills,
        profit: selectedTabData.profit,
        netProfit: selectedTabData.net_profit,
        epcEarned: selectedTabData.epc_earned,
      };

      this.defaultViewData = {
        visitorsCount: selectedTabData.visitors_count,
        refundsCount: selectedTabData.refunded_count,
        cancelCount: selectedTabData.cancelled,
        trialCount: selectedTabData.trials_count,
        salesCount: selectedTabData.sales_count,
        rebillsCount: selectedTabData.rebills_count,
        signupsCount: selectedTabData.signups,
      };

      this.chartData = {
        activeSubscriptionsCount: selectedTabData.active_subscriptions,
        retentionLength: selectedTabData.retention_length,
      };

      this.funnelsDropdown = transactionsData.data.funnels;
      // this.$loader.end('GetChartData');
    },
    updateSeriesLine() {
      this.$nextTick(() => {
        this.$refs.chart.$refs.realtimeChart.updateSeries(
          [
            {
              data: this.series[0].data,
            },
          ],
          false,
          true
        );
      });
    },
    async handleTransactionsTabChange(newTab) {
      this.transactionsTab = newTab;
      this.GetChartData();
    },
  },
};
</script>
