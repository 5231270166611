<template>
  <div v-if="assetsTab === 'links'">
    <links-tab
      :funnel-resource="funnelResource"
      :direct-affiliate-links="directAffiliateLinks"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'swipes'">
    <swipes-tab
      :funnel-resource="funnelResource"
      :swipes-tab="swipesTab"
      :current-shown-swipes-quill-editor-index="currentShownSwipesQuillEditorIndex"
      @updateCurrentShownSwipesQuillEditorIndex="updateCurrentShownSwipesQuillEditorIndex"
      @handleSwipesTabChange="handleTabsChange($event, 'swipesTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'banners'">
    <banners-tab
      :funnel-resource="funnelResource"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'thankyouPageAds'">
    <thankyou-page-ads-tab
      :funnel-resource="funnelResource"
      :thankyou-page-ads-tab="thankyouPageAdsTab"
      @handleThankyouPageAdsTabChange="handleTabsChange($event, 'thankyouPageAdsTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'signatures'">
    <signatures-tab
      :funnel-resource="funnelResource"
      :email-signatures-tab="emailSignaturesTab"
      :forum-signatures-tab="forumSignaturesTab"
      @handleEmailSignaturesTabChange="handleTabsChange($event, 'emailSignaturesTab')"
      @handleForumSignaturesTabChange="handleTabsChange($event, 'forumSignaturesTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'socialMediaPosts'">
    <social-media-posts-tab
      :funnel-resource="funnelResource"
      :facebook-posts-tab="facebookPostsTab"
      :twitter-tweets-tab="twitterTweetsTab"
      :linkedin-posts-tab="linkedinPostsTab"
      @handleFacebookPostsTabChange="handleTabsChange($event, 'facebookPostsTab')"
      @handleTwitterTweetsTabChange="handleTabsChange($event, 'twitterTweetsTab')"
      @handleLinkedinPostsTabChange="handleTabsChange($event, 'linkedinPostsTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'blogReviews'">
    <blog-reviews-tab
      :funnel-resource="funnelResource"
      :blog-reviews-tab="blogReviewsTab"
      :current-shown-blog-reviews-quill-editor-index="currentShownBlogReviewsQuillEditorIndex"
      @handleBlogReviewsTabChange="handleTabsChange($event, 'blogReviewsTab')"
      @updateCurrentShownBlogReviewsQuillEditorIndex="updateCurrentShownBlogReviewsQuillEditorIndex"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'videoReviews'">
    <video-reviews-tab
      :funnel-resource="funnelResource"
      :video-reviews-tab="videoReviewsTab"
      @handleVideoReviewsTabChange="handleTabsChange($event, 'videoReviewsTab')"
    />
  </div>
  <div v-else-if="assetsTab === 'facebookAds'">
    <facebook-ads-tab
      :funnel-resource="funnelResource"
      :facebook-ads-tab="facebookAdsTab"
      @handleFacebookAdsTabChange="handleTabsChange($event, 'facebookAdsTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'logoutPageAds'">
    <logout-page-ads-tab
      :funnel-resource="funnelResource"
      :logout-page-ads-tab="logoutPageAdsTab"
      @handleLogoutPageAdsTabChange="handleTabsChange($event, 'logoutPageAdsTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'allArticles'">
    <all-articles-tab
      :funnel-resource="funnelResource"
      :press-releases-tab="pressReleasesTab"
      :articles-tab="articlesTab"
      @handlePressReleasesTabChange="handleTabsChange($event, 'pressReleasesTab')"
      @handleArticlesTabChange="handleTabsChange($event, 'articlesTab')"
      @onCopy="onCopy"
      @onError="onError"
    />
  </div>
  <div v-else-if="assetsTab === 'stats'">
    <stats-tab
      :funnel-resource="funnelResource"
    />
  </div>
</template>

<script>
import LinksTab from './tabs/LinksTab.vue';
import SwipesTab from './tabs/SwipesTab.vue';
import BannersTab from './tabs/BannersTab.vue';
import ThankyouPageAdsTab from './tabs/ThankyouPageAdsTab.vue';
import SignaturesTab from './tabs/SignaturesTab.vue';
import SocialMediaPostsTab from './tabs/SocialMediaPostsTab.vue';
import BlogReviewsTab from './tabs/BlogReviewsTab.vue';
import VideoReviewsTab from './tabs/VideoReviewsTab.vue';
import FacebookAdsTab from './tabs/FacebookAdsTab.vue';
import LogoutPageAdsTab from './tabs/LogoutPageAdsTab.vue';
import AllArticlesTab from './tabs/AllArticlesTab.vue';
import StatsTab from './tabs/StatsTab.vue';

export default {
  components: {
    LinksTab,
    SwipesTab,
    BannersTab,
    ThankyouPageAdsTab,
    SignaturesTab,
    SocialMediaPostsTab,
    BlogReviewsTab,
    VideoReviewsTab,
    FacebookAdsTab,
    LogoutPageAdsTab,
    AllArticlesTab,
    StatsTab,
  },
  props: {
    assetsTab: {
      required: true,
    },
    funnelResource: {
      required: true,
    },
    directAffiliateLinks: {
      required: true,
    },
    swipesTab: {
      required: true,
    },
    currentShownSwipesQuillEditorIndex: {
      required: true,
    },
    thankyouPageAdsTab: {
      required: true,
    },
    emailSignaturesTab: {
      required: true,
    },
    forumSignaturesTab: {
      required: true,
    },
    facebookPostsTab: {
      required: true,
    },
    twitterTweetsTab: {
      required: true,
    },
    linkedinPostsTab: {
      required: true,
    },
    blogReviewsTab: {
      required: true,
    },
    currentShownBlogReviewsQuillEditorIndex: {
      required: true,
    },
    videoReviewsTab: {
      required: true,
    },
    facebookAdsTab: {
      required: true,
    },
    logoutPageAdsTab: {
      required: true,
    },
    pressReleasesTab: {
      required: true,
    },
    articlesTab: {
      required: true,
    },
  },
  methods: {
    handleTabsChange(index, tabName) {
      this.$emit('handleTabsChange', index, tabName);
    },
    updateCurrentShownSwipesQuillEditorIndex(index) {
      this.$emit('updateCurrentShownSwipesQuillEditorIndex', index);
    },
    updateCurrentShownBlogReviewsQuillEditorIndex(index) {
      this.$emit('updateCurrentShownBlogReviewsQuillEditorIndex', index);
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  }
};
</script>
