import MyTrackingLinks from '@/pages/tracking/index';
import MyAffiliateTrackingLinks from '@/pages/tracking/affiliate-tracking';
import MyPageTrackingLinks from '@/pages/page-tracking/index';
import CreateTrackingLink from '@/pages/tracking/create';
import TrackingLinkStats from '@/pages/tracking/stats';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/tracking',
    name: 'tracking',
    component: MyTrackingLinks,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_tracking')
    }
  },
  {
    path: '/affiliate-tracking',
    name: 'affiliate_tracking',
    component: MyAffiliateTrackingLinks,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_tracking')
    }
  },
  {
    path: '/tracking/:link_id/stats',
    name: 'tracking_link_stats',
    component: TrackingLinkStats,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_stats')
    }
  },
  {
    path: '/tracking/create',
    name: 'create_tracking_link',
    component: CreateTrackingLink,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_create_new_tracking_link')
    }
  },
  {
    path: '/tracking/create/:link_id',
    name: 'edit_tracking_link',
    component: CreateTrackingLink,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_tracking_link')
    }
  },
  {
    path: '/affiliate-tracking/create',
    name: 'create_affiliate_tracking_link',
    component: CreateTrackingLink,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_create_new_tracking_link')
    }
  },
  {
    path: '/affiliate-tracking/create/:link_id',
    name: 'edit_affiliate_tracking_link',
    component: CreateTrackingLink,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_tracking_link')
    }
  },
  {
    path: '/page-tracking',
    name: 'pageTracking',
    component: MyPageTrackingLinks,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_page_tracking')
    }
  }
];
