<template>
    <div v-if="funnel && funnels && funnels.length" class="flex flex-row justify-between">
        <div class="flex flex-col w-1/4">
            <div class="w-full flex flex-row">
                <gd-dropdown
                    v-model="localselectedFunnelId"
                    size="full"
                    :options="funnelsWithMainProductsOptions"
                    :placeholder="$t('frontend_resources_index_funnels')"
                    @change="changedFunnel"
                />
            </div>

            <div class="mt-2 w-full">
                <gd-dropdown
                    v-if="funnelProducts"
                    v-model="localselectedProductId"
                    size="full"
                    :options="funnelProductOptions"
                    :placeholder="$t('frontend_resources_index_funnels')"
                    @change="changedProduct"
                />
            </div>
        </div>
        <div>
            <gd-button variant="primary" @click="openCheckoutPagesModal">
                <plus-icon class="w-5 h-5 fill-current" />
                {{ $t('frontend_create') }}
            </gd-button>
        </div>
    </div>
</template>
<script>
import Funnel from '@/models/Funnel';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        PlusIcon,
    },
    props: {
        funnels: {
            type: Array,
            required: true
        },
        funnel: {
            type: Object,
            required: true
        },
        selectedFunnelId: {
            required: true
        },
        selectedProductId: {
            required: true
        },
        openCheckoutPagesModal: {
            type: Function,
            required: true
        },
    },
    data() {
        return {
            localselectedFunnelId: this.selectedFunnelId,
            localselectedProductId: this.selectedProductId,
        }

    },
    computed: {
        funnelProducts() {
            if (this.funnel && this.funnel.all_products) {
                return this.funnel.all_products.filter((product) => {
                    return product.is_wizard_complete && product.is_complete;
                });
            }
            return [];
        },
        funnelsWithMainProducts() {
            return this.funnels.filter((funnel) => {
                return (
                    funnel.main_product_id > 0 &&
                    funnel.is_live &&
                    funnel.main_product &&
                    funnel.main_product.is_wizard_complete &&
                    funnel.main_product.is_complete
                );
            });
        },
        funnelsWithMainProductsOptions() {
            return this.funnelsWithMainProducts.map((funnel) => {
                return {
                    value: funnel.id,
                    text: funnel.name,
                };
            });
        },
        funnelProductOptions() {
            return this.funnelProducts.map((product) => {
                return {
                    value: product.id,
                    text: product.name,
                };
            });
        },
    },
    methods: {
        async changedFunnel() {
            this.$loader.start('changedFunnel');
            const selectedFunnel = await Funnel.$find(this.localselectedFunnelId);
            const mainProductId = selectedFunnel.main_product_id;
            this.$router.push({
                name: 'productsCheckoutLinks',
                params: {
                    id: selectedFunnel.id,
                    product_id: mainProductId,
                },
            });
            this.$loader.end('changedFunnel');
        },
        changedProduct() {
            this.$loader.start('changedProduct');
            this.$router.push({
                name: 'productsCheckoutLinks',
                params: {
                    id: this.localselectedFunnelId,
                    product_id: this.localselectedProductId,
                },
            });
            this.$loader.end('changedProduct');
        },
    }

}
</script>