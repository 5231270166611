<template>
    <div>
        <div class="flex flex-col w-full">
            <div class="flex flex-col w-1/2 mb-6 bg-grey-100 p-1">
                <label class="font-semibold text-sm text-grey-700">
                    <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
                    {{ $t('frontend_product_step5_note') }}
                </label>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex flex-col w-1/2 mb-1">
                    <label class="font-semibold text-md text-grey-700 mb-2">{{
                        $t('frontend_product_step5_custom_css_track')
                    }}</label>
                </div>
                <div class="flex flex-row w-1/2 mb-2">
                    <text-area v-model="localModel.checkout_page_options.tracking_codes"
                        value="localModel.checkout_page_options.tracking_codes"
                        :placeholder="$t('frontend_product_step5_custom_css_track')" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
    components: {
        InfoIcon,
    },
    props: {
        model: {
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
        };
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },
};


</script>