<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step3_payment_gateways') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step3_you_can')
        }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs :tabs="gatewaysTabs" :current-tab="gatewaysTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
          :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleGatewaysTabChange" />
        <div class="pt-4 gs-default-tabs">
          <div class="pb-4 mr-4 gs-default-tabs-item cursor-pointer" @click="goToSetupGateways">
            {{ $t('frontend_product_step3_setup_gateways') }}
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div v-if="gatewaysTab === 'gateways'" class="-mt-4">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
            <gd-tabs :tabs="gatewaysTypes" :error-tabs="gatewayTypesErrorTabs" :current-tab="gatewaysType"
              :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'" @onClick="handleGatewaysTypeChange" />
          </div>
          <div class="mt-2">
            <div v-if="gatewaysType === 'paypal'">
              <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2">
                <gd-tabs :tabs="paypalTabs" :current-tab="paypalTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                  :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                  :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                  :line-class="'gs-default-tabs-active-line'" @onClick="handlePaypalTabChange" />
              </div>
              <div class="mt-2">
                <div v-if="paypalTab === 'rotation'">
                  <div v-if="paypalIntegrations.length == 0" class="flex flex-row w-full">
                    <label class="text-grey-dark">{{
                      $t('frontend_product_step3_no_paypal')
                    }}</label>
                  </div>
                  <div v-else class="flex flex-col w-full">
                    <div class="flex flex-row w-full">
                      <div class="w-4/5">
                        <div v-if="localModel.paypal_processing_accounts.length > 0
                            " class="flex flex-col w-1/2">
                          <div v-for="(
                              account, index
                            ) in localModel.paypal_processing_accounts" :key="index"
                            class="flex flex-row w-full mt-10">
                            <div class="flex flex-col w-full mb-6">
                              <label class="font-semibold text-sm text-grey-700 mb-2">{{ integrationName(account.id)
                              }}</label>
                              <div class="flex flex-row">
                                <div class="mr-2">
                                  <text-input v-model="account.weightage" :validator="$v.localModel.paypal_processing_accounts
                                      .$each[index].weightage
                                    " :attribute="$t('frontend_product_step3_percentage')" value="account.weightage"
                                    :placeholder="$t('frontend_product_step3_percentage')
                                      " />
                                </div>
                                <gd-button variant="outline" size="iconlg" :title="$t('frontend_delete')"
                                  @click="deletePaypalGateway(account.id)">
                                  <trash-icon class="h-5 w-5 fill-current"/>
                                </gd-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="flex flex-row w-full mt-4">
                          <label class="text-grey-dark">{{
                            $t('frontend_product_step3_add_paypal')
                          }}</label>
                        </div>
                      </div>
                      <div class="flex flex-row justify-end w-1/5 items-center space-x-2">
                        <simple-dropdown :title="$t('frontend_product_step3_Add_paypal')" :options="paypalIntegrations"
                          @selected="addPaypalGateway($event)" class="custom-dropdown-addnew"></simple-dropdown>
                        <div class="text-dark cursor-pointer" @click="reloadIntegrations"
                          :key="integrationsLoaderKey">
                          <reset-icon class="h-5 w-5 fill-current" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="flex flex-col w-full mb-6 bg-grey-100 p-1">
                    <label class="font-semibold text-sm text-grey-700">
                      <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
                      {{ $t('frontend_product_step3_paypal_rotation_note') }}
                    </label>
                  </div>

                  <div class="flex flex-row">
                    <div class="flex flex-col w-1/2 mr-2">
                      <div class="flex flex-col w-1/2 mb-6 mr-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_percentage') }}</label>
                        <gd-dropdown
                          v-model="localModel.paypal_risk_percentage"
                          size="full"
                          :options="paypalRiskPercentageOptions"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="gatewaysType === 'creditcard'">
              <div v-if="cardIntegrations.length == 0" class="flex flex-row w-full">
                <label class="text-grey-dark">{{
                  $t('frontend_product_step3_no_credit')
                }}</label>
              </div>
              <div v-else class="flex flex-col w-full">
                <div class="flex flex-row w-full">
                  <div class="w-4/5">
                    <div v-if="localModel.credit_card_processing_accounts.length > 0
                        " class="flex flex-col w-1/2">
                      <div v-for="(
                          account, index
                        ) in localModel.credit_card_processing_accounts" :key="index"
                        class="flex flex-col w-full mt-10">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ integrationName(account.id) }}</label>
                        <div class="flex flex-row">
                          <div class="mr-2">
                            <text-input v-model="account.weightage" :validator="$v.localModel.credit_card_processing_accounts
                                .$each[index].weightage
                              " :attribute="$t('frontend_percentage')" value="account.weightage"
                              :placeholder="$t('frontend_percentage')" />
                          </div>
                          <gd-button variant="outline" size="iconlg" :title="$t('frontend_delete')" @click="deleteCardGateway(account.id)">
                            <trash-icon class="h-5 w-5 fill-current"/>
                          </gd-button>
                        </div>
                      </div>
                    </div>
                    <div v-else class="flex flex-row w-full mt-4">
                      <label class="text-grey-dark">{{
                        $t('frontend_product_step3_add_credit_card')
                      }}</label>
                    </div>
                  </div>
                  <div class="flex flex-row justify-end w-1/5 items-center space-x-2">
                    <simple-dropdown :title="$t('frontend_product_step3_add_card_gateway')" :options="cardIntegrations"
                      @selected="addCardGateway($event)" class="custom-dropdown-addnew"></simple-dropdown>
                    <div class="text-dark cursor-pointer" @click="reloadIntegrations"
                      :key="integrationsLoaderKey">
                      <reset-icon class="h-5 w-5 fill-current" />
                    </div>
                  </div>
                </div>
                <div class="w-full flex">
                  <div class="flex flex-col w-1/2 mt-6 bg-grey-100 p-1">
                    <label class="font-semibold text-sm text-grey-700">
                      <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
                      {{ $t('frontend_product_step3_accept_currency_funnel_note') }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="gatewaysTab === 'applyForMerchantAccount'">
          <div class="flex flex-row">
            <div class="flex flex-col w-1/2 mr-2">
              <span class="text-lg font-bold mb-2">{{
                $t('frontend_product_step3_recommended_by_groovesell', {appTitle: upperCaseAppTitle})
              }}</span>
              <img src="@/assets/images/pages/groovepay_banner.png" alt="" />
            </div>
            <div class="flex flex-col ml-4">
              <p class="text-lg font-bold mb-2">
                {{ $t('frontend_product_step3_groovesell_is_best', {appTitle: whitelabelAppData('sell').title, payAppTitle: whitelabelAppData('pay').title}) }}
              </p>
              <p class="text-sm mb-2">
                <span class="font-bold">{{
                  $t('frontend_product_step3_super_low')
                }}</span>
                <span class="text-xs">+ {{ $t('frontend_product_step3_per_transaction') }}</span>
              </p>
              <p class="text-sm font-bold mb-2">
                {{ $t('frontend_product_step3_the_best') }}
              </p>
              <p class="text-sm mb-2">
                {{ $t('frontend_product_step3_groovepay_has_no', {appTitle: whitelabelAppData('pay').title}) }}
              </p>
              <div class="flex w-1/4 mb-4">
                <gd-button variant="primary" size="auto" @click="openGroovepay">{{
                  $t('frontend_product_step3_connect_to', {appTitle: whitelabelAppData('pay').title}) }}</gd-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';
import {
  required,
  numeric,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import createLinkMixin from '@/mixins/createLinkMixin';
import PaymentGateway from '@/components/PaymentGatewayModal';
import SimpleDropdown from '@/components/SimpleDropdown';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';

export default {
  name: 'Step3',
  components: {
    PaymentGateway,
    SimpleDropdown,
    TrashIcon,
    InfoIcon,
    ResetIcon,
  },
  mixins: [createLinkMixin],
  props: {
    model: {
      required: true,
    },
    gatewayIntegrations: {
      required: true,
    },
  },
  data() {
    return {
      fetchingIntegrations: false,
      integrationsLoaderKey: 1,
      localModel: this.model,
      thankyouPageIndex: 0,
      gatewaysTab: 'gateways',
      paypalTabs: [
        {
          title: this.$t('frontend_product_step3_account_rotation'),
          value: 'rotation',
        },
        {
          title: this.$t('frontend_product_step3_mitigation'),
          value: 'mitigation',
        },
      ],
      paypalTab: 'rotation',
      gatewaysType: 'paypal',
      selectedPaypalGatewayAccount: 0,
      selectedCardGatewayAccount: 0,
      formFieldsResponse: null,
    };
  },
  validations: {
    localModel: {
      paypal_processing_accounts: {
        $each: {
          weightage: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(100),
            totalHundredPercent() {
              const self = this;
              let totalWeightage = 0;

              for (
                let i = 0;
                i < self.localModel.paypal_processing_accounts.length;
                i++
              ) {
                totalWeightage += parseInt(
                  self.localModel.paypal_processing_accounts[i].weightage,
                  10
                );
              }

              if (totalWeightage == 100) {
                return true;
              }
              return false;
            },
          },
        },
      },
      credit_card_processing_accounts: {
        $each: {
          weightage: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(100),
            totalHundredPercent() {
              const self = this;
              let totalWeightage = 0;

              for (
                let i = 0;
                i < self.localModel.credit_card_processing_accounts.length;
                i++
              ) {
                totalWeightage += parseInt(
                  self.localModel.credit_card_processing_accounts[i].weightage,
                  10
                );
              }

              if (totalWeightage == 100) {
                return true;
              }
              return false;
            },
          },
        },
      },
    },
  },
  computed: {
    upperCaseAppTitle() {
      return this.whitelabelAppData('sell').title.toUpperCase();
    },
    paypalRiskPercentageOptions() {
      const options = [];

      for (let i = 10; i <= 100; i += 10) {
        options.push({ value: i, text: `${i}%` });
      }

      return options;
    },
    gatewaysTabs() {
      return [
        {
          title: this.$t('frontend_product_step3_payment_gateways'),
          value: 'gateways',
        },
        {
          title: this.$t('frontend_product_step3_apply_for_merchant'),
          value: 'applyForMerchantAccount',
        }
      ]
    },

    gatewaysTypes() {
      return [
        {
          title: this.$t('frontend_product_step3_paypal'),
          value: 'paypal'
        },
        {
          title: this.$t('frontend_product_step3_credit_card'),
          value: 'creditcard',
        }
      ]
    },
    paypalIntegrations() {
      const paypalIntegrations = [
        ...this.gatewayIntegrations,
        {
          id: 'addPaymentGateway',
          name: this.$t('frontend_groovesell_add_new_payment'),
          disabled: false,
          platform: 'paypal_commerce',
        },
      ];
      return paypalIntegrations
        .filter((integration) => {
          return (
            integration.platform == 'paypal' ||
            integration.platform == 'paypal_commerce'
          );
        })
        .map((v) => ({
          id: v.id,
          name: v.name,
          disabled: this.paypalAddedAccounts.includes(v.id),
        }));
    },

    cardIntegrations() {
      const cardIntegrations = [
        ...this.gatewayIntegrations,
        {
          id: 'addPaymentGateway',
          name: this.$t('frontend_groovesell_add_new_payment'),
          disabled: false,
          platform: 'stripe',
        },
      ];

      return cardIntegrations
        .filter((integration) => {
          return (
            integration.platform != 'paypal' &&
            integration.platform != 'paypal_commerce'
          );
        })
        .map((v) => ({
          id: v.id,
          name: v.name,
          disabled: this.cardAddedAccounts.includes(v.id),
        }));
    },

    paypalAddedAccounts() {
      return this.localModel.paypal_processing_accounts.map((a) => a.id);
    },

    cardAddedAccounts() {
      return this.localModel.credit_card_processing_accounts.map((a) => a.id);
    },

    gatewayTypesErrorTabs() {
      const gatewayTypeErrorTabs = [];

      if (this.$v.localModel.paypal_processing_accounts.$error) {
        gatewayTypeErrorTabs.push('paypal');
      }

      if (this.$v.localModel.credit_card_processing_accounts.$error) {
        gatewayTypeErrorTabs.push('creditcard');
      }

      return gatewayTypeErrorTabs;
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  created() {
    if (window.location.hash === '#step3') {
      this.gatewaysTab = 'setupGateways';
      window.location.hash = '';
    }
  },
  methods: {
    goToSetupGateways() {
      const routeData = this.$router.resolve({ name: 'vendor_settings' });
      window.open(routeData.href, '_blank');
    },

    openGroovepay() {
      const payAppTitle = this.whitelabelAppData('pay').title.toLowerCase();
      const sellAppTitle = this.whitelabelAppData('sell').title.toLowerCase();

      this.openLink(`https://app.${payAppTitle}.com/?refId=${sellAppTitle}`, '_blank');
    },

    async reloadIntegrations() {
      this.fetchingIntegrations = true;
      this.integrationsLoaderKey++;
      this.$emit('reloadIntegrations');
      setTimeout(() => {
        this.fetchingIntegrations = false;
        this.integrationsLoaderKey++;
      }, 1000);
    },

    validate() {
      this.$v.localModel.$touch();
      const isValid = !this.$v.localModel.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    handleGatewaysTabChange(newTab) {
      this.gatewaysTab = newTab;
    },

    handlePaypalTabChange(newTab) {
      this.paypalTab = newTab;
    },

    handleGatewaysTypeChange(newTab) {
      this.gatewaysType = newTab;
    },

    integrationName(id) {
      const integration = this.gatewayIntegrations.find((obj) => {
        return obj.id === id;
      });
      return integration ? integration.name : '';
    },
    addPaypalGateway(id) {
      if (this.paypalAddedAccounts.includes(id)) {
        return;
      }

      if (id === 'addPaymentGateway') {
        this.goToSetupGateways();
      }

      const totalCurrentWeightage = this.localModel.paypal_processing_accounts.reduce((cnt, o) => {
        return cnt + parseInt(o.weightage);
      }, 0);
      const remainingWeightage = 100 - totalCurrentWeightage;

      this.localModel.paypal_processing_accounts.push({
        id,
        weightage: remainingWeightage,
      });
    },

    deletePaypalGateway(id) {
      this.localModel.paypal_processing_accounts =
        this.localModel.paypal_processing_accounts.filter(
          (obj) => obj.id !== id
        );
    },
    addCardGateway(id) {
      if (this.cardAddedAccounts.includes(id)) {
        return;
      }

      if (id === 'addPaymentGateway') {
        this.goToSetupGateways();
        return;
      }

      const totalCurrentWeightage = this.localModel.credit_card_processing_accounts.reduce((cnt, o) => {
        return cnt + parseInt(o.weightage);
      }, 0);
      const remainingWeightage = 100 - totalCurrentWeightage;

      this.localModel.credit_card_processing_accounts.push({
        id,
        weightage: remainingWeightage,
      });
    },
    deleteCardGateway(id) {
      this.localModel.credit_card_processing_accounts =
        this.model.credit_card_processing_accounts.filter(
          (obj) => obj.id !== id
        );
    },
    openGatewayModal() {
      this.$root.$emit('modal-open', 'payment-gateway-modal');
    },
  },
};
</script>
<style lang="scss">
.custom-dropdown-addnew {
  &>.origin-top-right {
    padding-bottom: 50px;

    .py-1 {
      max-height: 250px;
      overflow: auto;

      .block:last-child {
        width: 100%;
        border-top: 1px solid #d5d8e5;
        text-align: center;
        padding: 12px 0;
        position: absolute;
        left: 0;
        bottom: 0;
        font-weight: 700;
        color: var(--brand-primary);

        &:before {
          content: '+ ';
        }
      }
    }
  }
}
</style>
