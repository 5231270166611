<template>
  <div
    class="
      w-full
      flex flex-col
      rounded
      border-dashed border-4 border-grey-300
      p-2
      pb-4
    "
  >
    <div class="flex flex-col w-full">
      <div
        class="
          flex flex-row
          justify-between
          w-full
          py-4
          px-2
          border-b border-grey-300
        "
      >
        <div v-if="heading && heading != null">
          {{ heading }}
        </div>
        <div v-else>{{ $t('frontend_heading') }}</div>
        <div class="flex flex-col">
          <div
            v-if="arrow"
            class="w-full flex flex-row justify-center text-center -mt-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 99.732 165.466"
              class="gs-animated-bounce text-red w-6 h-8"
            >
              <path
                d="M57.067,167.066,7.2,119.466H38.933V1.6H75.2V119.466h31.733l-49.867,47.6Z"
                transform="translate(-7.2 -1.6)"
                fill="#c51f1f"
                stroke="#c51f1f"
              />
            </svg>
          </div>
          <gd-toggle v-model="previewOrderBumps" :value="previewOrderBumps" />
        </div>
      </div>
      <div class="w-full flex flex-row px-2 py-2">
        <div
          class="flex flex-col text-md"
          :class="{
            'w-3/4':
              thumbnail &&
              actualSelectedProduct.checkout_page_options.hasOwnProperty(
                'product_image'
              ) &&
              actualSelectedProduct.checkout_page_options.product_image &&
              actualSelectedProduct.checkout_page_options.product_image != '',

            'w-full':
              !thumbnail ||
              !actualSelectedProduct.checkout_page_options.hasOwnProperty(
                'product_image'
              ) ||
              (!actualSelectedProduct.checkout_page_options.product_image &&
                actualSelectedProduct.checkout_page_options.product_image ==
                  ''),
          }"
        >
          <div class="flex justify-between">
            <div>
              <div class="flex flex-row w-full">
                <label class="font-semibold text-primary">
                  {{ actualSelectedProduct.name }}
                </label>
              </div>
              <div class="flex flex-row w-full font-semibold">
                <div v-if="actualSelectedPricepoint">
                  {{ actualSelectedPricepoint.pricing_string }}
                </div>
              </div>
            </div>
            <div
              v-if="actualSelectedPricepoint.quantity_enable == 1"
              style="width: 60px"
            >
              <gd-dropdown
                class="flex-row-reverse"
                style="width: 100%"
                v-model="actualSelectedPricepoint.minimum_quantity"
                :options="quantityValue"
              ></gd-dropdown>
            </div>
          </div>
          <div class="flex flex-row w-full text-grey-900 text-sm mt-2">
            <div class="flex flex-row">
              <div v-if="description != null" v-html="description"></div>
              <div v-else>{{ $t('frontend_desc') }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="
            thumbnail &&
            actualSelectedProduct.checkout_page_options.hasOwnProperty(
              'product_image'
            ) &&
            actualSelectedProduct.checkout_page_options.product_image &&
            actualSelectedProduct.checkout_page_options.product_image != ''
          "
          class="flex flex-row justify-end w-1/4"
        >
          <img
            class="rounded-full h-24 w-24 flex items-center justify-center"
            :src="actualSelectedProduct.checkout_page_options.product_image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@groovepages/gd-ui-kit/mixins';
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'Style3',
  props: {
    heading: String,
    description: String,
    arrow: {
      default: 0,
    },
    thumbnail: {
      default: 0,
    },
    selectedPricepoint: {
      default: null,
    },
    selectedProduct: {
      default: null,
    },
    allProducts: {
      default: null,
    },
  },
  data() {
    return {
      previewOrderBumps: false,
      quantityValue: [],
    };
  },
  created() {
    this.quantityValue = this.getQuantitiesArray();
  },
  computed: {
    actualSelectedProduct() {
      return this.allProducts.filter((product) => {
        return product.id == this.selectedProduct;
      })[0];
    },

    actualSelectedPricepoint() {
      return this.actualSelectedProduct.price_points.filter((pricepoint) => {
        return pricepoint.id == this.selectedPricepoint;
      })[0];
    },
  },

  methods: {
    getQuantitiesArray() {
      const arr = [];

      for (
        let i = this.actualSelectedPricepoint.minimum_quantity;
        i <= this.actualSelectedPricepoint.maximum_quantity;
        i++
      ) {
        arr.push({ text: i, value: i });
      }
      return arr;
    },
  },
};
</script>

<style>
.gs-animated-bounce {
  animation: gsbounce 2s infinite;
  -webkit-animation: gsbounce 2s infinite;
  -moz-animation: gsbounce 2s infinite;
  -o-animation: gsbounce 2s infinite;
}

@-webkit-keyframes gsbounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes gsbounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes gsbounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes gsbounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>