import axios from 'axios';

export default {
  data() {
    return {};
  },
  methods: {
    async createOpenLink(url, paramData) {
      const linkData = await axios.post(url, paramData);
      const a = document.createElement('a');
      a.href = linkData.data.download_link.replace(/^http:\/\//i, 'https://');
      a.click();
    },
    async openLink(link, linkTarget) {
      const a = document.createElement('a');
      a.target = linkTarget;
      a.href = link.replace(/^http:\/\//i, 'https://');
      a.click();
    }
  }
};
