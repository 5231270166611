<template>
    <div>
        <div class="flex flex-row">
            <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_product_name')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.product_name }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_price_point')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.price_point_name }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_price_type')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.transaction_type }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_date_and_time')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.formatted_date_time }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_refund_period')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.product.refund_period }}
                        {{ $t('frontend_affiliate_transactions_days') }}
                    </label>
                </div>

                <div v-if="selectedTransaction.recurring_billing" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_recurring_bill')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.recurring_billing }}
                    </label>
                </div>
                <div v-else class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_recurring_bill')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_na')
                    }}</label>
                </div>

                <div v-if="selectedTransaction.recurring_billing &&
                        selectedTransaction.payments_left &&
                        selectedTransaction.payments_left != 99999999999"
                    class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_payments_left')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.payments_left }}
                    </label>
                </div>
                <div v-else class="w-1/3 pt-2 pb-2 pr-2 flex flex-col mb-2"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTransaction: {
            required: true,
        },
    },
}
</script>