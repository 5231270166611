<template>
    <div>
        <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="dropPixelPricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="bg-white rounded p-2 mb-2">
            <div v-for="(pricePoint, index) in localDropPixel.price_points" :key="index">
                <div v-if="pricePointsTab === 'data_sharing_product_price_point_' + pricePoint.id"
                    class="flex flex-col w-full">
                    <div class="flex flex-col w-full bg-white mb-2">
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_tracking_code')
                            }}</label>

                            <text-area v-model="pricePoint.tracking_code" class="w-full" :select-all="true"
                                value="pricePoint.tracking_code" :placeholder="$t('frontend_tracking_code')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    props: {
        drop_pixel: {
            type: Object,
            required: true
        },
        pricePointsTabs: {
            type: Array,
            required: true
        },
        handlePricePointsTabChange: {
            type: Function,
            required: true
        },
        pricePointsTab: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            localDropPixel: this.drop_pixel
        }
    },
    watch: {
        localDropPixel: {
            handler: function (val, oldVal) {
                this.$emit('update-data', {
                    key: 'drop_pixel',
                    value: val
                });
            },
            deep: true
        }
    },
    computed: {
        dropPixelPricePointsErrorTabs() {
            return [];
        },
    }

}
</script>