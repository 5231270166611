<template>
    <div>
        <gd-modal name="change-transaction-affiliate-modal" :title="$t('frontend_groovesell_change_affiliate')" height="70%">
            <div class="px-4 mb-64">
                <div class="flex py-8 px-2">
                    <div v-if="localselectedTransaction && localselectedTransaction.funnel_id"
                        class="w-full flex flex-row pl-2">
                        <affiliates-auto-suggest :funnel="localselectedTransaction.funnel_id" :excluded="[]"
                            button="Change Affiliate" @onAffiliateAdded="openConfirmModal" />
                    </div>
                </div>
            </div>
        </gd-modal>
        <confirm-modal
            name="confirm-affiliate-modal"
            :message-content="changeAffiliateAlertText"
            :button-text="$t('frontend_change_affiliate_confirm')"
            button-size="auto"
            @confirm="changeAffiliate"
        />
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>

<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import axios from 'axios';
import Transaction from '@/models/Transaction';

export default {
    components: {
        AffiliatesAutoSuggest,
    },
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
        reloadData: {
            type: Function,
            default: () => { },
        },
    },
    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
            localselectedTransaction: this.selectedTransaction,
            changedAffiliateId: null,
            changeAffiliateAlertText: '',
        }
    },
    watch: {
        selectedTransaction: function (val) {
            this.localselectedTransaction = val;
        }
    },
    methods: {
        openConfirmModal(changedAffiliateId) {
            this.$root.$emit('modal-open', 'confirm-affiliate-modal');
            this.changedAffiliateId = changedAffiliateId;

            this.changeAffiliateAlertText = this.$t('frontend_change_affiliate_future_transactions', {
                affiliate: changedAffiliateId.id !== 0 ? 'this affiliate' : '"No Affiliate"',
            });

        },
        async changeAffiliate() {
            this.$loader.start('changeAffiliate');
            const self = this;
            const changeAffiliateData = await axios.post(
                '/transactions/change-affiliate',
                {
                    transaction_id: self.selectedTransaction.id,
                    affiliate_id: this.changedAffiliateId.id,
                }
            );

            await this.reloadData();
            this.localselectedTransaction = await Transaction.$find(self.selectedTransaction.id);

            const changedData = changeAffiliateData.data;
            const changeStatus = changedData.status;
            if (changeStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_change_successful_title'),
                    message: this.$t('frontend_change_successful_message'),
                };
                this.$root.$emit(
                    'modal-close',
                    'change-transaction-affiliate-modal'
                );
                let highLightTabs = [];

                if (this.localselectedTransaction.suspected_affiliate_fraud == 1) {
                    highLightTabs.push('affiliate');
                }
                this.$emit('filter-changed', {
                    key: 'highLightTabs',
                    value: highLightTabs,
                    startSearch: false,
                })
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_change_failed_title'),
                    message: this.$t('frontend_change_failed_message'),
                };
                this.$root.$emit(
                    'modal-close',
                    'change-transaction-affiliate-modal'
                );
            }

            this.$loader.end('changeAffiliate');
        },
    }
}

</script>

