import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  template: `
        <span v-tooltip="{ classes:'custom-double-tooltip', content: this.params?.data?.product?.internal_name + '<h6>' + this.params?.data?.product?.description + '</h6>', contentHtml: true  }">
            {{this.params.value}}
        </span>
    `
});
