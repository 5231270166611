import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openAffiliateDetail() {
      this.params.context.componentParent.$refs.vendorDetailModal.openVendorDetail(
        this.params.data.id
      );
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openAffiliateDetail">
            {{this.params.value}}
        </span>
    `
});
