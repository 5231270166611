<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="w-full flex">
          <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_groovesell_contracts_step3_share_type') }}</label>
            <gd-dropdown
              v-model="localModel.share_type"
              size="full"
              :options="shareTypeOptions"
              @change="changedShareType"
            />
          </div>
        </div>
        <div class="w-full flex">
          <div class="w-1/2">
            <text-input
              v-model="search"
              value="search"
              :placeholder="$t('frontend_groovesell_enter_username_or_email')"
              @keyup="changeInput"
            />
          </div>
          <gd-button
            v-if="enableButton"
            size="auto"
            variant="primary"
            class="ml-4"
            @click="addPartner"
          >
            {{ $t('frontend_groovesell_contracts_step3_add_partner') }}
          </gd-button>
          <gd-button
            v-if="!enableButton"
            size="auto"
            disabled
            class="ml-4"
          >
            {{ $t('frontend_groovesell_contracts_step3_add_partner') }}
          </gd-button>
        </div>

        <div
          v-if="userNotExist"
          class="w-full error text-danger mt-1 text-sm"
        >
          {{ errorMesssage }}
        </div>
        <div class="flex flex-col m-6 mb-4">
          <div v-if="localModel.partners && localModel.partners.length > 0">
            <div
              id="second-tier-affiliates-list-view"
              class="-mx-6 flex flex-row"
            >
              <div class="table-responsive w-full">
                <table
                  class="VueTables__table table table-striped table-bordered table-hover"
                >
                  <thead>
                    <tr>
                      <th>
                        <span class="VueTables__heading">
                          {{ $t('frontend_groovesell_contracts_step3_user_name') }}
                        </span>
                        <span class="pull-right"></span>
                        <div class="resize-handle"></div>
                      </th>

                      <th>
                        <span v-if="localModel.share_type == 1" class="VueTables__heading">
                          {{ $t( 'frontend_groovesell_contracts_step3_partner_percentage') }}
                        </span>
                        <span v-else class="VueTables__heading">
                          {{ $t( 'frontend_groovesell_contracts_step3_partner_fixed_amount' ) }}
                        </span>
                        <span class="pull-right"></span>
                        <div class="resize-handle"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(partner, id) in localModel.partners"
                      :key="id"
                      class="VueTables__row"
                    >
                      <td>{{ partner.username }}</td>
                      <td>
                        <span v-if="partner.partner_id == 0">
                          {{ partner.percentage }}
                        </span>
                        <input
                          v-else
                          type="text"
                          :value="partner.percentage"
                          class="h-10 outline-none rounded-lg px-4 border border-grey-200"
                          @keyup="calculateDistribution($event.target.value, partner)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="p-4">
            {{ $t('frontend_groovesell_contracts_step3_no_partner_percentage') }}
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Step3',
  components: {
  },
  props: {
    model: {
      required: true,
    },
  },

  data() {
    return {
      localModel: this.model,
      search: '',
      enableButton: false,
      userNotExist: false,
      errorMesssage: '',
    };
  },
  computed: {
    shareTypeOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step9_percentage') },
        { value: 2, text: this.$t('frontend_product_step9_fixed_amount') },
      ];
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  async created() {
    this.selectedFunnel = this.localModel.funnel_id;
    this.selectedProduct = this.localModel.product_id;
    this.selectedPricePoint = this.localModel.price_point_id;
  },
  methods: {
    changedShareType() {
      const me = this.localModel.partners.find((obj) => obj.partner_id === 0);

      if (this.localModel.share_type == 1 && me == undefined) {
          const newMe = {
            partner_id: 0,
            username: this.$t('me_vendor'),
            percentage: 0
          };

          this.localModel.partners.forEach((val) => {
            newMe.percentage += +val.percentage;
          });

          newMe.percentage = 100 - newMe.percentage;
          this.localModel.partners.unshift(newMe);
      } else {
        const deletedMeIndex = this.localModel.partners.findIndex((item) => item.id === me.id);

        if (deletedMeIndex !== -1) {
          this.localModel.partners.splice(deletedMeIndex, 1);
        }
      }
    },
    changeInput(value) {
      this.enableButton = value != '';
    },
    validate() {
      this.$emit('on-validate', true);
    },
    async addPartner() {
      this.$loader.start('addPartner');

      try {
        const userData = await axios.get('/contract/validate-user', {
          params: {
            username_or_email: this.search,
          },
        });

        if (userData.data.status !== 'success') {
          this.handleUserNotExist(userData.data.message);
          return;
        }

        const checkUser = this.localModel.partners.find((obj) => obj.partner_id === userData.data.data.id);

        if (typeof checkUser !== 'undefined') {
          this.handleUserExist();
          return;
        }

        const tempUser = {
          partner_id: userData.data.data.id,
          username: userData.data.data.name,
          percentage: 0,
        };
        this.localModel.partners.push(tempUser);
      } catch (error) {
        this.handleUserNotExist(this.$t('error_occured_user_data'));
      } finally {
        this.search = '';
        this.enableButton = false;
        this.$loader.end('addPartner');
      }
    },
    handleUserExist() {
      this.userNotExist = true;
      this.errorMesssage = this.$t('user_already_exists_contract');
      setTimeout(() => (this.userNotExist = false), 3000);
    },
    handleUserNotExist(errorMessage) {
      this.userNotExist = true;
      this.errorMesssage = errorMessage;
      setTimeout(() => (this.userNotExist = false), 3000);
    },
    async calculateDistribution(value, partner) {
      if(this.localModel.share_type == 1){
        const excludeSelf = this.calculateExcludeSelf();

        const me = this.localModel.partners.find((obj) => {
          return obj.partner_id === 0;
        });
        const remaining = 100 - excludeSelf;
    
        if (remaining - value > 0) {
          me.percentage = +me.percentage + (partner.percentage - value);
          partner.percentage = value;
        } else {
          this.handleInvalidError();
        }
      } else if(value > 0) {
           partner.percentage = value;
      } else {
        this.handleInvalidError();
        partner.percentage = 0;
      }
    },
    calculateExcludeSelf() {
      return this.localModel.partners.reduce((excludeSelf, val) => {
        return val.partner_id !== 0 ? +val.percentage + +excludeSelf : excludeSelf;
      }, 0);
    },
    handleInvalidError() {
      this.userNotExist = true;
      this.errorMesssage = this.$t('cant_set_negative_value');
      setTimeout(() => (this.userNotExist = false), 3000);
    },
  },
};
</script>
