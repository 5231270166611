<template>
  <div>
    <div class="w-full">
      <v-wait for="resources">
        <create-select-funnel
          :funnels="funnels"
          :funnel="funnel"
          :selectedFunnelId="selectedFunnelId"
          @updateSelectedFunnelId="updateSelectedFunnelId"
          @openCreateResourceModal="openCreateResourceModal"
        />

        <resources-list-view
          :model="model"
          :resources="resources"
          :funnels="funnels"
          :funnel="funnel"
          @updateModel="updateModel"
          @updateResources="updateResources"
          @updateResource="updateResource"
          @openEmbedCode="openEmbedCode"
        />

        <create-edit-promo-area-modal
          :model="model"
          :funnel="funnel"
          :funnels="funnels"
          :resource="resource"
          :resources="resources"
          @updateResources="updateResources"
          @updateResource="updateResource"
        />

        <embed-widget-code-modal
          :funnel="funnel"
          :selectedPermalink="selectedPermalink"
        />
      </v-wait>
    </div>

    <portal to="headerBreadCrumb">
      <div class="cursor-pointer flex items-center -ml-4" @click="sendToFunnels">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import FunnelResource from '@/models/FunnelResource';
import axios from 'axios';
import CreateSelectFunnel from './partials/CreateSelectFunnel.vue';
import ResourcesListView from './partials/ResourcesListView.vue';
import CreateEditPromoAreaModal from './partials/CreateEditPromoAreaModal.vue';
import EmbedWidgetCodeModal from './partials/EmbedWidgetCodeModal.vue';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('funnel_promo_tools') };
  },
  components: {
    CreateSelectFunnel,
    ResourcesListView,
    CreateEditPromoAreaModal,
    EmbedWidgetCodeModal,
    ChevronLeftIcon,
  },
  data() {
    return {
      promotoolStatusKey: 0,
      funnel: null,
      funnels: [],
      selectedFunnelId: 0,
      model: this.getDefaultModel(),
      defaultLandingPages: [],
      resources: [],
      resource: null,
      selectedPermalink: '',
    };
  },
  computed: {
    brandName() {
      return this.$store.state.brandData.brandName;
    },
  },
  async created() {
    this.$wait.start('resources');
    this.funnel = await Funnel.$find(this.$route.params.id);

    if (
      this.funnel.main_product &&
      this.funnel.main_product.affiliate_landing_pages &&
      this.funnel.main_product.affiliate_landing_pages.length
    ) {
      this.model.landing_page_id = this.funnel.main_product.affiliate_landing_pages[0].id;
    }

    this.resources = await this.funnel.promotional_areas().$get();
    this.selectedFunnelId = this.funnel.id;
    this.funnels = await Funnel.$get();

    const { data } = await axios.get(
      `/get-default-landing-pages/${this.funnel.id}`
    );

    this.defaultLandingPages = data.landing_pages;
    this.$wait.end('resources');
  },
  methods: {
    getDefaultModel() {
      return {
        name: '',
        use_custom_video: false,
        landing_page_id: 0,
        video_link: '',
        show_more_funnels: false,
        related_funnels: [],
        show_more_resources: 0,
        related_resources: [],
        landing_pages: [],
        header_text: this.$t('frontend_groovesell_tools_affiliate_modal_branding_placeholder_text_header', {brandName: this.brandName}),
        login_text: this.$t('frontend_groovesell_tools_affiliate_modal_branding_placeholder_text_login', {brandName: this.brandName}),
        signup_text: this.$t('frontend_groovesell_tools_affiliate_modal_branding_placeholder_text_signup', {brandName: this.brandName}),
        disable_branding: false,
      };
    },
    updateSelectedFunnelId(funnelId) {
      this.selectedFunnelId = funnelId;
    },
    updateModel(model) {
      this.model = model;
    },
    updateResources(resources) {
      this.resources = resources;
    },
    updateResource(resource) {
      this.resource = resource;
    },
    openEmbedCode(resourcePermalink) {
      this.selectedPermalink = resourcePermalink;
      this.$root.$emit('modal-open', 'embed-widget-code-modal');
    },
    sendToFunnels() {
      this.$router.push({ name: 'funnels' });
    },
    openCreateResourceModal() {
      this.resource = new FunnelResource({}).for(this.funnel);
      Object.assign(this.model, this.getDefaultModel());
      this.model.landing_page_id = this.funnel.main_product.affiliate_landing_pages[0].id;
      this.model.landing_pages = this.defaultLandingPages;
      this.$root.$emit('modal-open', 'create-promo-area-modal');
    },
  },
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#resources-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
