<template>
    <div>
        <div class="flex flex-row w-full mt-2">
            <div class="flex flex-row flex-wrap w-11/12 mt-8">
                <div v-for="(formField, index) in localModel.checkout_page_options.form_fields" :key="index"
                    class="w-1/4 flex flex-col mb-4">
                    <div class="w-full flex">
                        <div class="w-1/5 flex flex-row">
                            <gd-checkbox :variant="index < 4 ? 'base': 'primary'" v-model="formField.enabled" :disabled="index < 4 ? true : false" />
                        </div>
                        <div class="w-4/5 flex flex-row">
                            <span class="pl-2 pb-2 pt-1 text-sm mr-2">
                                {{ formField.label }}
                            </span>
                            <div v-if="formField.type == 'custom'" @click="openConfirmModal(index)"
                                class="text-red cursor-pointer">

                                <trash-icon class="h-5 w-5 fill-current"/>
                            </div>
                        </div>

                    </div>
                    <div :class="{
                        'invisible': formField.type == 'fname' ||
                            formField.type == 'lname' ||
                            formField.type == 'email' ||
                            formField.type == 'countryAndState' ||
                            formField.type == 'address' ||
                            formField.type == 'cityAndPostalCode' ||
                            formField.enabled == false
                    }" class="w-full flex">
                        <div class="w-1/5 flex flex-row">
                            <gd-toggle class="" v-model="formField.mandatory" :value="formField.mandatory"
                                :disable="true" />
                        </div>
                        <div class="w-4/5 flex flex-row">
                            <span class="pl-2 pb-2 pt-1 text-sm mr-2"> {{ $t('frontend_products_is_mandatory') }} </span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="w-2/12 flex flex-row-reverse">
                <gd-button variant="outline" size="auto" class="ml-4" @click="openCustomFieldModal">
                    <plus-icon class="h-5 w-5 fill-current"/>
                    {{ $t('frontend_product_step5_add_custom') }}</gd-button>
            </div>
        </div>
        <confirm-modal
            name="confirm-remove-form-field-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')"
            button-size="auto"
            @confirm="removeFormField"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon
    },
    props: {
        model: {
            required: true
        },
        openCustomFieldModal: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            localModel: this.model,
            formFieldsIndex: null,
        }
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('updateModel', this.localModel);
            },
            deep: true
        }
    },
    methods: {
        openConfirmModal(index) {
            this.$root.$emit('modal-open', 'confirm-remove-form-field-modal');
            this.formFieldsIndex = index;
        },
        async removeFormField() {
            this.localModel.checkout_page_options.form_fields.splice(this.formFieldsIndex, 1);
        },
    }
}
</script>
