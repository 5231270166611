var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full relative h-10 border border-solid rounded-lg",class:{
    'text-white border-grey-700 bg-grey-800': _vm.hasState('dark'),
    'text-grey-600 border-grey-200 bg-white': !_vm.hasState('dark'),
    'border-primary-dark error-form-group': _vm.hasErrors,
  }},[_c('input',{ref:"input",staticClass:"w-full h-full outline-none rounded-lg px-4",class:{
      'bg-grey-800': _vm.hasState('dark'),
      'bg-white': !_vm.hasState('dark'),
      'bg-grey-100': _vm.disabled,
    },attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.value},on:{"keyup":_vm.keyup,"keydown":_vm.keydown,"focus":_vm.inputFocussed,"input":_vm.input}}),(_vm.hasErrors)?_c('div',{staticClass:"w-full error text-primary-dark mt-1 text-sm"},[_vm._v(" "+_vm._s(_vm.firstErrorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }