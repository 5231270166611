<template>
    <div>
        <div>
            <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_username')
                }}</label>
                <text-input v-model="localForm.username" :validator="validator.form.username"
                    :attribute="$t('frontend_username')" value="form.username"
                    :placeholder="$t('frontend_affiliate_settings_username')"
                    @input="delayTouch(validator.form.username)" />
            </div>
            <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate') }} Id</label>
                <text-input v-model="userId" :attribute="$t('frontend_groovesell_affiliate_id')" value="userId"
                    :readonly="true" :disabled="true" placeholder="" />
            </div>
        </div>
        <hr class="border-grey-100 -mx-4" />
        <div class="flex flex-row-reverse pr-2 pb-4">
            <gd-button v-if="isValid" variant="primary" size="md" class="mt-6" @click="saveAffiliateSettings">{{
                $t('frontend_save') }}</gd-button>
            <gd-button v-else disabled size="md" class="mt-6">{{ $t('frontend_save')
            }}</gd-button>
        </div>
    </div>
</template>
<script>
const touchMap = new WeakMap();

export default {
    props: {
        form: {
            required: true
        },
        userId: {
            required: true
        },
        validator: {
            required: true
        },
        saveAffiliateSettings: {
            required: true,
            type: Function

        }
    },
    data() {
        return {
            localForm: this.form
        }
    },

    watch: {
        localForm: {
            handler: function (val, oldVal) {
                this.$emit('update-form', val);
            },
            deep: true
        }
    },
    computed: {
        isValid() {
            return !this.validator.form.username.$invalid;
        }
    },
    methods: {
        delayTouch($v) {
            $v.$reset();
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v));
            }
            touchMap.set($v, setTimeout($v.$touch, 1000));
        },
    }

}
</script>