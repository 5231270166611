import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openTransactionDetail() {
      this.params.context.componentParent.openTransactionDetail(
        this.params.data.id
      );
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openTransactionDetail">
          <span>{{this.params.value}} <span v-if="this.params?.data?.is_test_mode == 1">( Test Mode )</span> </span>
            <span class="ml-2"><i v-if="this.params?.data?.assisted_affiliate_id" class="fas fa-random"></i></span>
        </span>
    `
});
