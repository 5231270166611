<template>
  <div>
    <div class="flex flex-row justify-center">
        <gd-button size="auto" variant="primary" class="mr-4" @click="showTemplates">Email Builder</gd-button>
    </div>


    <gd-modal
            bgType="black"
            ref="modal"
            name="templates-modal"
            height="90%"
            width="95%"
            :no-overflow="true"
            title="Templates"
    >
        <template-selection-grid :templates="templates" @selectedTemplate="selectedTemplate">
            <div slot="sidebar">
                <gd-accordion>
                    <template #title="slotProps">
                        <p class="inline-flex items-center text-grey-700 font-semibold">
                            <span class="mr-2">Categories</span>
                            <arrow-down
                            v-show="!slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-down>
                            <arrow-up
                            v-show="slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-up>
                        </p>
                    </template>
                    <gd-checkbox
                        v-for="(category, idx) in categories"
                        :key="idx"
                        v-model="category.selected"
                        :checkboxText="category.name"
                        checkboxTextClass="font-semibold text-sm text-grey-700 ml-4"
                        checkboxTickClass="h-2 w-2"
                        checkboxIconBgClass="bg-primary"
                        class="mb-3"
                    ></gd-checkbox>
                </gd-accordion>

                <gd-accordion class="mt-8">
                    <template #title="slotProps">
                        <p class="inline-flex items-center text-grey-700 font-semibold">
                            <span class="mr-2">Usages</span>
                            <arrow-down
                            v-show="!slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-down>
                            <arrow-up
                            v-show="slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-up>
                        </p>
                    </template>
                    <gd-checkbox
                        v-for="(category, idx) in usages"
                        :key="idx"
                        v-model="category.selected"
                        :checkboxText="category.name"
                        checkboxTextClass="font-semibold text-sm text-grey-700 ml-4"
                        checkboxTickClass="h-2 w-2"
                        checkboxIconBgClass="bg-primary"
                        class="mb-3"
                    ></gd-checkbox>
                </gd-accordion>

                <gd-accordion class="mt-8">
                    <template #title="slotProps">
                        <p class="inline-flex items-center text-grey-700 font-semibold">
                            <span class="mr-2">Transactional</span>
                            <arrow-down
                            v-show="!slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-down>
                            <arrow-up
                            v-show="slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-up>
                        </p>
                    </template>
                    <gd-checkbox
                        v-for="(category, idx) in automated_types"
                        :key="idx"
                        v-model="category.selected"
                        :checkboxText="category.name"
                        checkboxTextClass="font-semibold text-sm text-grey-700 ml-4"
                        checkboxTickClass="h-2 w-2"
                        checkboxIconBgClass="bg-primary"
                        class="mb-3"
                    ></gd-checkbox>
                </gd-accordion>


                <gd-accordion class="mt-8">
                    <template #title="slotProps">
                        <p class="inline-flex items-center text-grey-700 font-semibold">
                            <span class="mr-2">Seasonal</span>
                            <arrow-down
                            v-show="!slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-down>
                            <arrow-up
                            v-show="slotProps.isOpen"
                            class="h-4 w-4 fill-current"
                            ></arrow-up>
                        </p>
                    </template>
                    <gd-checkbox
                        v-for="(category, idx) in seasons"
                        :key="idx"
                        v-model="category.selected"
                        :checkboxText="category.name"
                        checkboxTextClass="font-semibold text-sm text-grey-700 ml-4"
                        checkboxTickClass="h-2 w-2"
                        checkboxIconBgClass="bg-primary"
                        class="mb-3"
                    ></gd-checkbox>
                </gd-accordion>

            </div>
        </template-selection-grid>
    </gd-modal>
  </div>
</template>

<script>
import TemplateSelectionGrid from '@groovepages/gd-ui-kit/components/TemplateSelectionGrid'
import GdAccordion from '@groovepages/gd-ui-kit/components/Accordion'
import ArrowDown from '@fortawesome/fontawesome-free/svgs/solid/angle-down.svg';
import ArrowUp from '@fortawesome/fontawesome-free/svgs/solid/angle-up.svg';
import axios from 'axios';

export default {
  middleware: 'auth',
  components: {
      TemplateSelectionGrid,
      GdAccordion,
      ArrowDown,
      ArrowUp
  },
  data() {
    return {
        templates: [],
        categories: [],
        seasons: [],
        automated_types: [],
        usages: []
    };
  },
  metaInfo() {
    return { title: 'Sales Tax' };
  },
  async created() {
      const gdAPIsUrl = window.config.apiUrl.substr(0, window.config.apiUrl.lastIndexOf('/'));
      const mailAppUrl = this.whitelabelAppData('mail').url;
      const templatesData = await axios.get(`${gdAPIsUrl}/${mailAppUrl}/email-templates`);

      this.templates = templatesData.data.data;
      this.categories = templatesData.data.meta.categories;
      this.seasons = templatesData.data.meta.seasons;
      this.automated_types = templatesData.data.meta.automated_types;
      this.usages = templatesData.data.meta.usages;
  },
  async mounted() {

  },
  methods: {
      selectedTemplate(templateId)
      {
          console.log('This is the template id ' + templateId);
          this.$router.push({ name: 'builder_playground', params: { id: templateId } });
      },

      showTemplates()
      {
          this.$root.$emit('modal-open', 'templates-modal');
      }
  }
};
</script>
