import MyAffiliatePaymentHistory from '@/pages/affiliates/payment_history';
import MyPromos from '@/pages/affiliates/promos';
import MyAffiliates from '@/pages/affiliates/index';
import MySecondTierAffiliates from '@/pages/affiliates/second_tier_index';
import AffiliatePerformance from '@/pages/affiliates/performance';
import LeaderBoards from '@/pages/affiliates/leader_boards';
import MyAffiliateSettings from '@/pages/affiliates/settings';
import AffiliatesTransactions from '@/pages/affiliates/transactions';
import DataSharing from '@/pages/affiliates/datasharing';
import PromoDashboard from '@/pages/affiliates/promo_dashboard';
import AffiliateBonuses from '@/pages/affiliates/bonuses';
import AffiliatePayments from '@/pages/affiliates/payments';
import SecondTierAffiliates from '@/pages/affiliates/second_tier';
import SubAffiliates from '@/pages/sub-affiliates/index';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/payments',
    name: 'payments',
    component: AffiliatePayments,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_payments')
    }
  },
  {
    path: '/payment-history',
    name: 'payment_history',
    component: MyAffiliatePaymentHistory,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_payment_history')
    }
  },
  {
    path: '/promos',
    name: 'promos',
    component: MyPromos,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_promo_and_links')
    }
  },
  {
    path: '/affiliates',
    name: 'affiliates',
    component: MyAffiliates,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_affiliates')
    }
  },
  {
    path: '/affiliates-second-tier',
    name: 'affiliates_second_tier',
    component: MySecondTierAffiliates,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_second_tier_affiliates')
    }
  },
  {
    path: '/affiliates-performance',
    name: 'affiliates_performance',
    component: AffiliatePerformance,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_affiliate_perf')
    }
  },
  {
    path: '/leader-boards',
    name: 'leader_boards',
    component: LeaderBoards,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_leaderboards')
    }
  },
  {
    path: '/affiliate-settings',
    name: 'affiliate_settings',
    component: MyAffiliateSettings,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_my_affiliate_settings')
    }
  },
  {
    path: '/promos/transactions',
    name: 'affiliate_transactions',
    component: AffiliatesTransactions,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_affiliate_transactions')
    }
  },
  {
    path: '/promos/:funnel_id/data-sharing',
    name: 'data_sharing',
    component: DataSharing,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_data_sharing')
    }
  },
  {
    path: '/promos/:id/dashboard',
    name: 'promos_dashboard',
    component: PromoDashboard,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_promo_dash')
    }
  },
  {
    path: '/bonuses',
    name: 'bonuses',
    component: AffiliateBonuses,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_bonuses')
    }
  },
  {
    path: '/second-tier-affiliates',
    name: 'second_tier_affiliates',
    component: SecondTierAffiliates,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_second_tier_affiliates')
    }
  },
  {
    path: '/sub-affiliates',
    name: 'sub_affiliates',
    component: SubAffiliates,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_sub_affiliates')
    }
  },
];
