<template>
    <div class="flex flex-col w-1/2">
        <div class="flex flex-row">
            <div class="text-center w-full">
                <p class="font-semibold">{{ $t('frontend_preview') }}</p>
            </div>
        </div>
        <div class="flex flex-row" :style="[customColor]">
            <div class=" custom-font-family flex flex-row w-full p-2 custom-template-color-primary"
                v-if="!disableBrandingBar">
                <div class="mr-6">
                    <div class="flex justify-center">
                        <div class=" rounded-full h-16 w-16 bg-white flex items-center justify-center">
                            <img alt=""
                                v-if="prductDetails.checkout_page_options.hasOwnProperty('product_image') && prductDetails.checkout_page_options.product_image && prductDetails.checkout_page_options.product_image != ''"
                                class=" rounded-full h-16 w-24 flex items-center justify-center"
                                :src="prductDetails.checkout_page_options.product_image" />
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-12" viewBox="0 0 185 185">
                                <defs>
                                    <filter id="a" width="102%" height="106.4%" x="-1%" y="-3.2%"
                                        filterUnits="objectBoundingBox">
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1"
                                            stdDeviation="2" />
                                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                        <feMerge>
                                            <feMergeNode in="shadowMatrixOuter1" />
                                            <feMergeNode in="SourceGraphic" />
                                        </feMerge>
                                    </filter>
                                </defs>
                                <g fill="var(--brand-primary)" fill-rule="evenodd" filter="url(#a)" transform="translate(-770 -99)">
                                    <path
                                        d="M863.4 190.987H951c-3.755 40.686-22.4 67.525-55.933 80.517-33.533 12.992-65.212 6.496-95.038-19.488l31.303-30.036c11.38 8.342 22.07 12.514 32.068 12.514 9.998 0 19.344-3.828 28.037-11.482L863.4 190.987zM774 188.756h44.917c1.773-22.003 12.249-35.808 31.427-41.414 19.178-5.606 35.347.169 48.509 17.324l35.042-28.598c-29.51-33.005-63.616-42.712-102.32-29.123-35.752 12.553-54.944 39.823-57.575 81.811z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div class="flex">
                        <p class="text-xl custom-template-color-secondary-text">
                            {{ prductDetails.name }}
                        </p>
                    </div>
                    <div class="flex mt-2">
                        <p class="text-sm custom-template-color-secondary-text">
                            {{ prductDetails.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full" :style="[customColor]">
            <div class="custom-font-family w-full h-full flex flex-col">
                <div class="flex flex-col">
                    <div class="flex mt-4">
                        <div class="w-full">
                            <p class="font-semiboldcustom-heading-size custom-heading-color">
                                {{ $t('frontend_account_information') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-row mt-4 preview">
                        <div class="w-1/2 mr-2">
                            <label class=" custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">{{
                                $t('frontend_first_name') }}</label>
                            <text-input class="custom-input-height" :readonly="true"
                                :placeholder="$t('frontend_first_name')" />
                        </div>
                        <div class="w-1/2">
                            <label class=" custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">{{
                                $t('frontend_last_name') }}</label>
                            <text-input class="custom-input-height" :readonly="true"
                                :placeholder="$t('frontend_last_name')" />
                        </div>
                    </div>
                    <div class="flex flex-row mt-4 preview">
                        <div class="w-full">
                            <label
                                class=" custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">Email</label>
                            <text-input class="custom-input-height" :readonly="true" placeholder="Email" />
                        </div>
                    </div>
                    <div
                        class="py-4 flex justify-center" 
                        :class="!localModel.customization_details.customization_details.button_full_width ? 'px-4' : ''"
                    >
                        <gd-button
                            variant="primary"
                            size="expand"
                            class="custom-template-color-primary custom-template-color-secondary-text border-none"
                        >
                            {{ $t('frontend_complete_purchase') }}
                        </gd-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        localModel: {
            required: true
        },
        prductDetails: {
            required: true
        },
        customColor: {
            required: true
        },
        disableBrandingBar: {
            required: true

        }
    }
}
</script>