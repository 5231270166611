<template>
    <gd-modal name="clone-product-modal" :title="$t('frontend_product_clone')">
        <div class="flex flex-col p-4 m-10">
            <div class="flex flex-row w-full mb-6 items-center">
                <label class="font-semibold text-sm text-grey-700 w-1/3">{{ $t('frontend_product_step1_product_name')
                }}</label>
                <div class="w-2/3">
                    <text-input v-model="newCloneProductName" :attribute="$t('frontend_product_step1_product_name')"
                        value="newCloneProductName" :placeholder="$t('frontend_product_step1_product_name')" />
                </div>
            </div>
            <div class="flex flex-row w-full mb-4 items-center">
                <label class="font-semibold text-sm text-grey-700 w-1/3">
                    {{ $t('frontend_products_clone_into_funnel') }}</label>
                <div class="w-2/3">
                    <gd-dropdown
                        v-model="selectedFunnelId"
                        size="full"
                        :options="funnelsOptions"
                    />
                </div>
            </div>
            <div class="flex flex-row w-full mb-6">
                <gd-button size="md" variant="primary" @click="cloneProduct">{{
                    $t('frontend_product_clone') }}</gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import axios from 'axios';
export default {
    props: {
        selectedProduct: {
            type: Object,
            default: null
        },
        funnels: {
            type: Array,
            default: () => []
        },
        funnel_id: {
            type: Number,
            default: 0
        },
    },
    validations: {
        newCloneProductName: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
        },
    },
    data() {
        return {
            newCloneProductName: '',
            selectedFunnelId: ''
        }
    },
    computed: {
        funnelsOptions() {
            return this.funnels.map(funnel => ({
                value: funnel.id,
                text: funnel.name
            }));
        }
    },
    created() {
        this.newCloneProductName = this.selectedProduct?.name + ' - Clone';
        this.selectedFunnelId = this.funnel_id;
    },
    methods: {
        async cloneProduct() {
            this.$v.$touch();
            if (!this.$v.newCloneProductName.$invalid) {
                this.$loader.start('cloneProduct')
                const updateCloneProduct = await axios.post('clone-product', {
                    funnel_id: this.selectedFunnelId,
                    cloning_funnel_id: this.funnel_id,
                    product_id: this.selectedProduct.id,
                    name: this.newCloneProductName
                });
                this.$loader.end('cloneProduct')
                this.$emit('products', 'clone-product-modal');
            }
        },
    }
}
</script>