<template>
  <div>
    <div class="w-full">
      <v-wait for="transactions">
        <gd-tabs :tabs="transactionTabs" :current-tab="transactionsTab" :wrapper-class="'px-0 gs-default-tabs'"
          :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleTransactionsTabChange" />

        <div>
          <header-filters ref="headerFilters" :currentTab="transactionsTab" :filteredTransactions="filteredTransactions"
            :filteredFunnels="filteredFunnels" :filteredAdvancedTransactions="filteredAdvancedTransactions"
            :transactionsDateRange="transactionsDateRange" :reloadData="reloadData"
            :exportThisPageData="exportThisPageData" :exportData="exportData" :transactionList="transactionList"
            @filter-changed="handleFiltersChange" @apply-filters="applyFilters"/>
          <div v-if="transactionsTab === 'captured'" class="mt-4">
            <div class="mt-2">
              <div id="transactions-list-view" class="mt-4">
                <ag-grid-vue :key="agGridKey" class="ag-theme-material" style="width: 100%" :dom-layout="domLayout"
                  :grid-options="gridOptions" :column-defs="transactionsTableColumnDefs" :pagination="true"
                  :row-height="transactionsTableRowHeight" :row-buffer="rowBuffer" :row-model-type="rowModelType"
                  :datasource="dataSource" :pagination-page-size="paginationPageSize"
                  :cache-overflow-size="cacheOverflowSize" :infinite-initial-row-count="infiniteInitialRowCount"
                  :max-blocks-in-cache="maxBlocksInCache" :cache-block-size="cacheBlockSize"
                  :suppress-row-click-selection="true" :suppress-cell-selection="true" :row-class="rowClass"
                  :row-class-rules="rowClassRules" :context="context" :framework-components="frameworkComponents"
                  :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests" @grid-ready="onGridReady"
                  @column-moved="onCapturedTransactionsColumnsMoved"></ag-grid-vue>
              </div>
            </div>
          </div>
          <div v-if="transactionsTab === 'assists'" class="mt-4">
            <div class="mt-2">
              <div id="assist-transactions-list-view" class="mt-4">
                <ag-grid-vue :key="agGridKey" class="ag-theme-material" style="width: 100%" :dom-layout="domLayout"
                  :grid-options="assistsTransactionsGridOptions" :column-defs="assistsTransactionsTableColumnDefs"
                  :pagination="true" :row-height="transactionsTableRowHeight" :row-buffer="rowBuffer"
                  :row-model-type="rowModelType" :datasource="assistsTransactionsDataSource"
                  :pagination-page-size="paginationPageSize" :cache-overflow-size="cacheOverflowSize"
                  :infinite-initial-row-count="infiniteInitialRowCount" :max-blocks-in-cache="maxBlocksInCache"
                  :cache-block-size="cacheBlockSize" :suppress-row-click-selection="true" :suppress-cell-selection="true"
                  :row-class="rowClass" :row-class-rules="rowClassRules" :context="context"
                  :framework-components="frameworkComponents"
                  :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
                  @grid-ready="onAssistsTransactionsGridReady"></ag-grid-vue>
              </div>
            </div>
          </div>
        </div>
        <advanced-filters-modal :vendorsDropdown="vendorsDropdown" :filteredFunnels="filteredFunnels"
          :filteredAdvancedTransactions="filteredAdvancedTransactions"
          @filter-changed="handleFiltersChange" @apply-filters="applyAdvancedFilters" />
        <transaction-detail-modal :selectedTransaction="selectedTransaction" />
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>


<script>
import Transaction from '@/models/AffiliateTransaction';
import { required, decimal } from 'vuelidate/lib/validators';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import TransactionFullNameRenderer from '@/renderers/affiliatetransactionFullNameRenderer';
import UnderlyingTransactionRenderer from '@/renderers/underlyingTransactionRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';


import HeaderFilters from './Transactions_Partials/parts/HeaderFilters';
import AdvancedFiltersModal from './Transactions_Partials/modals/AdvancedFiltersModal';
import TransactionDetailModal from './Transactions_Partials/modals/TransactionDetailModal';
import '@/assets/scss/affiliates-transactions.scss'

export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  metaInfo() {
    return { title: this.$t('frontend_vendors_transactions') };
  },
  mixins: [createLinkMixin],
  components: {
    AgGridVue,
    HeaderFilters,
    AdvancedFiltersModal,
    TransactionDetailModal,
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      transactionsDateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      agGridKey: 1,
      transactionList: [],
      gridOptions: null,
      assistsTransactionsGridOptions: null,
      gridApi: null,
      assistsTransactionsGridApi: null,
      columnApi: null,
      assistsTransactionsColumnApi: null,
      dataSource: null,
      assistsTransactionsDataSource: null,
      assistsTransactions: [],
      paginationPageSize: 25,
      transactionsTableRowHeight: 40,
      rowBuffer: null,
      rowModelType: null,
      assistsTransactionsRowModelType: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      domLayout: 'autoHeight',
      transactionsTableColumnDefs: null,
      assistsTransactionsTableColumnDefs: null,
      vendorsDropdown: [],
      filteredFunnels: [],
      filteredAffiliates: [],
      filteredTransactions: [{ id: 0, name: this.$t('frontend_groovesell_all_transactions') }],
      filteredAssistsTransactions: [{ id: 0, name: this.$t('frontend_groovesell_all_transactions') }],
      filteredAdvancedTransactions: 0,
      searchPhrase: '',
      excludeFreeTransactions: false,
      affiliatesForFilter: [],
      rowClassRules: null,
      rowClass: 'transactions-row',
      selectedRowSize: 25,
      totalNumberOfAssistTransactions: 0,
      totalAssistsRevenueAmount: 0,
      totalAssistsCommissionAmount: 0,
      context: null,
      frameworkComponents: null,
      selectedTransaction: null,
      transactionDetailsModalTitle: null,
      transactionsTab: 'captured',
      selectedTransactionFunnelAffiliates: [],
      changedAffiliateId: 0,
      changedCommission: {
        commission: 0,
        commission_type: 'percent',
      },

      transactionTabs: [
        {
          title: this.$t('frontend_analytics_transactions_successful'),
          value: 'captured',
        },
        {
          title: this.$t('frontend_groovesell_assists'),
          value: 'assists',
        },
      ],
      transactionsTab: 'captured',
    };
  },
  validations: {
    changedCommission: {
      commission: {
        required,
        decimal,
        minValue: 0,
        isValidCommission(value, object) {
          const regexp = /^\d+(\.\d{1,2})?$/;

          if (object.commission_type == 'percent') {
            return regexp.test(value) && value <= 100;
          }
          return regexp.test(value);
        },
      },
    },
  },
  computed: {

    affiliatesForSelectedTransactionFunnel() {
      const affiliatesDataForTransactionFunnel = [];
      affiliatesDataForTransactionFunnel.push({ id: 0, name: this.$t('frontend_analytics_no_affiliate') });
      for (let i = 0; i < this.selectedTransactionFunnelAffiliates.length; i++) {
        affiliatesDataForTransactionFunnel.push(this.selectedTransactionFunnelAffiliates[i]);
      }

      return affiliatesDataForTransactionFunnel;
    },
    selectedTransactionFilters() {
      return this.filteredTransactions.map((a) => a.id);
    },
    selectedFailedTransactionFilters() {
      return this.filteredFailedTransactions.map((a) => a.id);
    },
  },
  async beforeMount() {
    this.gridOptions = {};
    this.assistsTransactionsGridOptions = {};
    this.context = { componentParent: this };
    this.frameworkComponents = {
      transactionFullNameRenderer: TransactionFullNameRenderer,
      underlyingTransactionRenderer: UnderlyingTransactionRenderer,
    };
    this.transactionsTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
            field: 'rendered_buyer_fullname',
            cellRenderer: 'transactionFullNameRenderer',
            colId: 'buyerFullName',
            width: 300,
            pinned: 'left',
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_product_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_product_name'),
            field: 'product_name',
            resizable: true,
          },
          { headerName: 'Price Point', field: 'price_point', resizable: true },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_price'),
            field: 'human_readable_price',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_date_and_time'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_date_time'),
            field: 'formatted_date_time',
            resizable: true,
            minWidth: 250,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_buyer_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Email',
            field: 'rendered_buyer_email',
            resizable: true,
          },
          { headerName: this.$t('frontend_groovesell_transaction_table_phone'), field: 'rendered_phone', resizable: true },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_company'),
            field: 'rendered_buyer_company',
            resizable: true,
          },
          {
            headerName: 'Address',
            field: 'rendered_buyer_address',
            resizable: true,
          },
          { headerName: 'Country', field: 'buyer_country', resizable: true },
          { headerName: 'State', field: 'buyer_state', resizable: true },
          { headerName: 'IP', field: 'buyer_ip', resizable: true },
        ],
      },
      {
        headerName: 'Commission',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Commission',
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_commission &&
                params.data.human_readable_commission != ''
              ) {
                return params.data.human_readable_commission;
              }
              return '';
            },
            resizable: true,
          },
        ],
      },
      {
        headerName: 'JV Broker Details',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'JV Broker Username',
            field: 'rendered_second_tier_affiliate_username',
            resizable: true,
          },
          {
            headerName: 'JV Broker Name',
            field: 'rendered_second_tier_affiliate_name',
            resizable: true,
          },
          {
            headerName: 'JV Broker Email',
            field: 'rendered_second_tier_affiliate_email',
            resizable: true,
          },
          {
            headerName: 'JV Broker Commission',
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_second_tier_commission &&
                params.data.human_readable_second_tier_commission != ''
              ) {
                return params.data.human_readable_second_tier_commission;
              }
              return '';
            },
            resizable: true,
          },
          {
            headerName: 'JV Broker Commission Type',
            field: 'second_tier_commission_type',
            resizable: true,
          },
        ],
      },
    ];
    this.assistsTransactionsTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
            field: 'rendered_buyer_fullname',
            cellRenderer: 'transactionFullNameRenderer',
            colId: 'buyerFullName',
            width: 300,
            pinned: 'left',
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_product_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_product_name'),
            field: 'product_name',
            resizable: true,
          },
          { headerName: 'Price Point', field: 'price_point', resizable: true },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_price'),
            field: 'human_readable_price',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_date_and_time'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_date_time'),
            field: 'formatted_date_time',
            resizable: true,
            minWidth: 250,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_buyer_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Email',
            field: 'rendered_buyer_email',
            resizable: true,
          },
          { headerName: this.$t('frontend_groovesell_transaction_table_phone'), field: 'rendered_phone', resizable: true },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_company'),
            field: 'rendered_buyer_company',
            resizable: true,
          },
          {
            headerName: 'Address',
            field: 'rendered_buyer_address',
            resizable: true,
          },
          { headerName: 'Country', field: 'buyer_country', resizable: true },
          { headerName: 'State', field: 'buyer_state', resizable: true },
          { headerName: 'IP', field: 'buyer_ip', resizable: true },
        ],
      },
      {
        headerName: 'Commission',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Commission',
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_commission &&
                params.data.human_readable_commission != ''
              ) {
                return params.data.human_readable_commission;
              }
              return '';
            },
            resizable: true,
          },
        ],
      },
    ];
    this.rowBuffer = 0;
    this.assistsTransactionsRowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;
    this.rowClassRules = {
      'refunded-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Refund'",
      'initial-payment-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Initial Payment'",
      'cancellation-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Cancellation'",
      'sale-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Sale'",
      'chargeback-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Chargeback'",
      'rebill-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Rebill'",
      'free-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Free Transaction'",
    };

  },
  methods: {
    handleFiltersChange(filters) {
      const { key, values, value } = filters;

      if (key === 'clear') {
        values.forEach(({ key, value }) => {
          if (this[key] !== undefined) this[key] = value;
        });

      } else {
        if (this[key] !== undefined) this[key] = value;
      }
    },
    showExportSuccessMessage() {
      this.alertProps = {
        show: true,
        variant: 'success',
        title: this.$t('frontend_groovesell_export_data'),
        message: this.$t('frontend_groovesell_transaction_message'),
      };
    },
    onCapturedTransactionsColumnsMoved() { },
    async handleTransactionsTabChange(newTab) {
      this.$refs.headerFilters.clearFilters();
      this.transactionsTab = newTab;
      this.agGridKey++;
    },

    async openTransactionDetail(transactionId) {
      this.$loader.start('openTransactionDetail');
      this.selectedTransaction = await Transaction.$find(transactionId);
      this.$root.$emit('modal-open', 'transaction-detail-modal');
      this.$loader.end('openTransactionDetail');
    },

    reloadData() {
      this.applyFilters();
    },

    async exportData() {
      const self = this;
      self.$loader.start('exportData');
      const apiUrl = this.transactionsTab == "captured" ? '/affiliate-generate-export-link' : '/affiliate-generate-assists-export-link';
      const exportLinkData = await axios.post(apiUrl,
        {
          funnels: self.filteredFunnels,
          transactions: self.selectedTransactionFilters,
          advancedTransactions: self.filteredAdvancedTransactions,
          search: self.searchPhrase,
          excludeFreeTransactions: self.excludeFreeTransactions,
          affiliates: self.filteredAffiliates,
          fromDate: moment(self.transactionsDateRange.startDate).format('MM/DD/YYYY'),
          toDate: moment(self.transactionsDateRange.endDate).format('MM/DD/YYYY'),
        }
      );
      if (this.currentTab = "assists") this.openLink(exportLinkData.data.download_link, '_self');
      if (this.currentTab = "captured") this.showExportSuccessMessage();
      self.$loader.end('exportData');

    },

    async exportThisPageData() {
      const self = this;
      self.$loader.start('exportThisPageData');
      const apiUrl = this.transactionsTab == "captured" ? '/affiliate-generate-export-link' : '/affiliate-generate-assists-export-link';
      const exportLinkData = await axios.post(apiUrl,
        {
          funnels: self.filteredFunnels,
          transactions: self.selectedTransactionFilters,
          advancedTransactions: self.filteredAdvancedTransactions,
          search: self.searchPhrase,
          excludeFreeTransactions: self.excludeFreeTransactions,
          affiliates: self.filteredAffiliates,
          fromDate: moment(self.transactionsDateRange.startDate).format('MM/DD/YYYY'),
          toDate: moment(self.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          page: self.gridApi.paginationGetCurrentPage() + 1,
          limit: self.gridApi.paginationGetPageSize(),
        }
      );
      if (this.currentTab = "assists") this.openLink(exportLinkData.data.download_link, '_self');
      if (this.currentTab = "captured") this.showExportSuccessMessage();
      self.$loader.end('exportThisPageData');

    },
    applyAdvancedFilters() {
      this.$refs.headerFilters.applyFilters();
    },
    applyFilters() {
      if (this.transactionsTab == 'captured') {
        this.gridApi.paginationGoToPage(0);
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
        this.gridApi.infiniteRowModel.resetCache();
        this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
      } else {
        this.assistsTransactionsGridApi.paginationGoToPage(0);
        this.assistsTransactionsGridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
        this.assistsTransactionsGridApi.infiniteRowModel.resetCache();
        this.assistsTransactionsGridApi.paginationSetPageSize(Number(this.selectedRowSize));
      }
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.checkRoutesParams();
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    checkRoutesParams() {
      if (this.$route.query.vendor_id && this.$route.query.vendor_id !== undefined) {
        this.filteredFunnels.push(`v_${this.$route.query.vendor_id}`);
      }

      if (this.$route.query.date_range && this.$route.query.date_range == 'all-time') {
        this.transactionsDateRange = {
          startDate: moment('20191201', 'YYYYMMDD'),
          endDate: moment(),
        };
      }

      if (this.$route.query.transaction_type && this.$route.query.transaction_type == 'all') {
        this.filteredTransactions = [{ id: 0, name: this.$t('frontend_groovesell_all_transactions') }];
      } else if (this.$route.query.transaction_type && this.$route.query.transaction_type == 'refund') {
        this.filteredTransactions = [{ id: 5, name: 'Refund' }];
      }
    },
    async onAssistsTransactionsGridReady() {
      this.assistsTransactionsGridApi = this.assistsTransactionsGridOptions.api;
      this.assistsTransactionsColumnApi = this.assistsTransactionsGridOptions.columnApi;
      this.assistsTransactionsDataSource = await this.getDataSource(Number(this.selectedRowSize), true);
    },

    getDataSource(rowCount, isAssistsTransactions = false) {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getDataSource');
        const gridApi = isAssistsTransactions ? self.assistsTransactionsGridApi : self.gridApi;
        const transactionsData = await Transaction.page(gridApi.paginationGetCurrentPage() + 1)
          .limit(gridApi.paginationGetPageSize())
          .params({
            funnels: self.filteredFunnels,
            transactions: self.selectedTransactionFilters,
            advancedTransactions: self.filteredAdvancedTransactions,
            search: self.searchPhrase,
            excludeFreeTransactions: self.excludeFreeTransactions,
            affiliates: self.filteredAffiliates,
            fromDate: moment(self.transactionsDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(self.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          })
          .custom(isAssistsTransactions ? 'affiliate-transactions-assists/assists' : '')
          .get();

        let rowsThisPage = [];
        rowsThisPage = transactionsData.data;
        const totalNumberOfRows = transactionsData.meta.total;
        self.vendorsDropdown = transactionsData.meta.vendors;
        self.affiliatesForFilter = [];
        self.affiliatesForFilter.push({ id: 0, name: self.$t('frontend_all') });
        self.affiliatesForFilter.push({ id: -2, name: self.$t('frontend_analytics_only_affiliates') });
        self.affiliatesForFilter.push({ id: -1, name: self.$t('frontend_analytics_only_non_affiliates') });
        self.affiliatesForFilter = self.affiliatesForFilter.concat(transactionsData.meta.affiliates);
        self.transactionList = transactionsData;
        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
  },
};
</script>
