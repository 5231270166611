<template>
    <div
      class="flex flex-col bg-white empty-state-wrapper p-10"
      :class="{ 'w-full mx-4 md:w-1/2': responsive, 'empty-wrapper-width': !responsive }"
    >
      <div v-if="empty_state_image" class="m-auto mb-5">
        <div
          class="w-auto relative"
          :class="{ 'opacity-50 cursor-pointer': has_video }"
          @click="has_video ? playVideo : null"
        >
          <img
            alt="empty_state_image"
            class="empty_state_image_view"
            :width="empty_state_image_width"
            :src="require('@/assets/images/' + empty_state_image)"
          />
          <div
            class="w-full h-full top-0 absolute bg-grey-700 rounded-lg opacity-50 flex justify-center items-center"
            v-if="has_video"
          >
            <div class="text-2xl text-white">
              <i class="fa fa-play" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="m-auto text-center"
        :class="{ 'empty-state-heading': !responsive }"
      >
        <p
          class="text-2xl text-grey-900 font-bold mb-4"
          v-if="empty_state_heading"
        >
          {{ this.$t(empty_state_heading) }}
        </p>
        <p class="text-sm text-grey-700 mb-4" v-if="empty_state_sub_heading">
          {{ this.$t(empty_state_sub_heading) }}
        </p>
      </div>
      <div class="flex w-full justify-center" v-if="empty_state_button_text">
        <gd-button
          variant="primary"
          class="empty-state-add-btn"
          @click="executeCreateAction"
          >{{ this.$t(empty_state_button_text) }}</gd-button
        >
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      responsive: {
        required: false,
      },
      has_video: {
        required: false,
      },
      empty_state_image: {
        required: false,
      },
      empty_state_heading: {
        required: false,
      },
      empty_state_sub_heading: {
        required: false,
      },
      empty_state_button_text: {
        required: false,
      },
      empty_state_image_width: {
        required: false,
      },
    },
  
    methods: {
      executeCreateAction() {
        this.$emit('executeDataCreation');
      },
  
      playVideo() {
        this.$emit('playVideoTutorial');
      },
    },
  };
  </script>
  
  <style lang="scss">
  .empty-wrapper-width {
    width: 620px;
  }
  
  .empty-state-wrapper {
    box-shadow: 20px 20px 40px rgba(22, 29, 37, 0.05);
    border-radius: 6px;
  }
  
  .empty-state-heading {
    width: auto;
  }
  
  .empty-state-add-btn {
    font-weight: 600 !important;
    border-radius: 6px !important;
  }
  </style>
  