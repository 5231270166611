var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4"},[_c('gd-tabs',{attrs:{"tabs":_vm.filesPricePointsTabs,"error-tabs":_vm.contentAccessPricePointsErrorTabs,"current-tab":_vm.filesPricePointsTab,"wrapper-class":'pt-4 px-0 gs-default-tabs',"tab-class":'pb-4 mr-4 gs-default-tabs-item',"tab-active-class":'border-primary border-b-2 text-primary gs-default-tabs-item-active',"line-class":'gs-default-tabs-active-line'},on:{"onClick":_vm.handleFilesPricePointsTabChange}})],1),_c('div',{staticClass:"mt-4"},_vm._l((_vm.localModel.pricing_points),function(pricePoint,index){return _c('div',{key:index},[(_vm.filesPricePointsTab === 'files_price_point_' + pricePoint.id
                )?_c('div',{staticClass:"flex flex-col w-full"},[(_vm.membership_files.length > 0)?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row-reverse w-full"},[_c('gd-button',{staticClass:"mr-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.emitShowFileMembershipBox(index)}}},[_c('plus-icon',{staticClass:"w-5 h-5 fill-current"}),_vm._v(" "+_vm._s(_vm.$t('frontend_product_step8_add_new_membership_file'))+" ")],1)],1),(pricePoint.file_memberships &&
                                pricePoint.file_memberships.length > 0
                                )?_c('div',_vm._l((pricePoint.file_memberships),function(file,fileIndex){return _c('div',{key:fileIndex,staticClass:"flex flex-col mt-4"},[_c('div',{staticClass:"flex flex-row",class:{
                                    'border-t border-grey-400 border-solid pt-4':
                                        fileIndex > 0,
                                }},[_c('div',{staticClass:"w-1/12 mr-8"},[_vm._v(" "+_vm._s(_vm.$t('frontend_send'))+" ")]),_c('div',{staticClass:"flex flex-row w-11/12"},[_c('div',{staticClass:"w-3/4 flex flex-col"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_name'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.fetchMemberFileName(file.file_id))+" ")])]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_file_name'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.fetchMemberOriginalFileName(file.file_id))+" ")])]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_size'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.fetchMemberFileSize(file.file_id))+" ")])])]),_c('div',{staticClass:"w-1/4 flex flex-row justify-end pr-4"},[_c('gd-button',{staticClass:"mr-2",attrs:{"size":"icon","variant":"primary","title":_vm.$t('frontend_groovesell_delete_file')},on:{"click":function($event){return _vm.openConfirmModal(index, fileIndex)}}},[_c('trash-icon',{staticClass:"h-5 w-5 fill-current"})],1)],1)])])])}),0):_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step8_no_memberships_files')))])])])]):_c('div',[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step8_no_memberships_files_added', {memberAppTitle: _vm.whitelabelAppData('member').title})))])])]):_vm._e()])}),0),_c('confirm-modal',{attrs:{"name":"confirm-delete-file-modal","message-content":_vm.$t('frontend_non_revertable'),"button-text":_vm.$t('frontend_delete_confirm'),"button-size":"auto"},on:{"confirm":_vm.deleteFile}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }