<template>
  <div>
    <div class="flex justify-between">
      <div class="w-1/4">
        <div class="flex space-x-2 items-center">
          <gd-dropdown
            v-model="localFiltersModel.filteredAffiliateTypes"
            :options="filteredAffiliateTypesOptions"
            :placeholder="$t('frontend_groovesell_affiliate_types_filter')"
            @change="changedSearchOrFilter"
          />
          <gd-dropdown
            v-model="localFiltersModel.selectedRowSize"
            size="small"
            :options="pageSizeOptions"
            @change="onPageSizeChanged()"
          />
        </div>
        <div class="flex mt-4">
          <gd-dropdown
            v-model="localFiltersModel.selectedFunnel"
            :options="funnelsOptions"
            :placeholder="$t('frontend_funnels')"
            @change="changedSearchOrFilter()"
          />
        </div>
      </div>

      <div class="flex flex-col w-1/4">
        <div class="flex">
          <text-input
            v-model="localFiltersModel.searchPhrase"
            v-debounce:1s="changedSearchOrFilter"
            value="localFiltersModel.searchPhrase"
            :placeholder="$t('frontend_search')"
            :debounce-events="['input', 'keydown']"
          />
        </div>
        <div class="flex flex-row mt-4 justify-between space-x-2">
          <gd-dropdown
            :disabled="!selectedRowLength"
            :placeholder="$t('frontend_affiliates_bulk_actions')"
            :actions="bulkActions"
          />
          <gd-dropdown
            :placeholder="$t('frontend_affiliates_select_affiliates')"
            :actions="selectAffiliatesActions"
          />
        </div>
        <div class="flex flex-row mt-4 justify-end">
          <gd-button
            variant="primary"
            size="auto"
            class="mr-4"
            @click="openAdvancedFilters"
          >
            <span class="mr-2">
              <filter-icon class="h-5 w-5 fill-current" />
            </span>
            {{ $t('frontend_filters') }}
          </gd-button>
          <gd-button
            variant="outline"
            size="auto"
            @click="clearFilters"
          >
            <span class="mr-2">
              <filter-icon class="h-5 w-5 fill-current" />
            </span>
            {{ $t('frontend_reset') }}
          </gd-button>
        </div>
      </div>
    </div>

    <advanced-filters-modal
      :filtered-model="localFilteredModel"
      @applyFilters="applyFilters"
    />
  </div>
</template>

<script>
import axios from 'axios';
import AdvancedFiltersModal from './modals/AdvancedFiltersModal.vue';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';

export default {
  components: {
    AdvancedFiltersModal,
    FilterIcon,
  },
  props: {
    filtersModel: {
      type: Object,
      required: true,
    },
    selectedRowLength: {
      type: Number,
      required: true,
    },
    gridApi: {
      type: Object,
      default: null,
    },
    unselectedAffiliateIds: {
      type: Array,
      required: true,
    },
    isAllSelected: {
      type: Boolean,
      required: true,
    },
    filteredModel: {
      type: Object,
      required: true,
    },
    funnels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFiltersModel: this.filtersModel,
      localFilteredModel: this.filteredModel,
    };
  },
  computed: {
    bulkActions() {
      return [
        {
          text: this.$t('frontend_affiliates_pay_selected_affiliates'),
          callback: () => this.openSecondTierExportModal()
        },
      ];
    },
    selectAffiliatesActions() {
      return [
        {
          text: this.$t('frontend_affiliates_select_all'),
          callback: () => this.selectAllAffiliates()
        },
        {
          text: this.$t('frontend_affiliates_Deselect_all'),
          callback: () => this.unselectAllAffiliates()
        },
      ];
    },
    filteredAffiliateTypesOptions() {
      return [
        {
          text: this.$t('frontend_affiliates_all_affiliates'),
          value: 0,
        },
        {
          text: this.$t('frontend_affiliates_all_paid_up'),
          value: 1,
        },
        {
          text: this.$t('frontend_affiliates_due_payments'),
          value: 2,
        },
      ];
    },
    pageSizeOptions() {
      return [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ];
    },
    funnelsOptions() {
      return this.funnels.map((funnel) => {
        return {
          text: funnel.name,
          value: funnel.id,
        };
      });
    },
  },
  methods: {
    changedSearchOrFilter() {
      this.$emit('updateFilters', this.localFiltersModel);
    },
    onPageSizeChanged() {
      this.gridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.localFiltersModel.selectedRowSize
      );
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.localFiltersModel.selectedRowSize));
    },
    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },
    async openSecondTierExportModal() {
      this.$loader.start('openSecondTierExportModal');
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-second-tier-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: this.unselectedAffiliateIds,
          isAllSelected: this.isAllSelected,
          affiliates: this.localFiltersModel.filteredAffiliateTypes,
          search: this.localFiltersModel.searchPhrase,
          funnels: this.localFiltersModel.selectedFunnel,
          commissionsAmount: this.localFilteredModel.secondTierCommissionsAmount,
          dueSecondTierCommissionsType: this.localFilteredModel.dueSecondTierCommissionsType,
          dueSecondTierAmount: this.localFilteredModel.secondTierDueAmount,
        }
      );

      this.$loader.end('openSecondTierExportModal');
      this.$emit('openSecondTierExportModal', exportLinkData);
    },
    selectAllAffiliates() {
      this.$emit('selectAllAffiliates');
    },
    unselectAllAffiliates() {
      this.$emit('unselectAllAffiliates');
    },
    clearFilters() {
      this.$emit('clearFilters');
    },
    applyFilters(model) {
      this.localFilteredModel = model;
      this.$emit('applyFilters', this.localFilteredModel);
    },
  },
};
</script>
