<template>
  <div>
    <portal to="headerBreadCrumb">
      <div class="flex h-full items-center">
        <div class="flex items-center" v-if="funnel.name">
          <div class="cursor-pointer text-grey-500" @click="sendToFunnels" v-tooltip="'Back to Funnels'">
            <arrow-bracket-icon class="h-5 w-5 fill-current"/>
          </div>
          <div class="text-sm ml-3">{{ funnel.name }}</div>
          <chevron-right-icon class="h-5 w-5 fill-current"/>
          <div class="text-sm mr-2">{{ $t('frontend_affiliate_promos_affiliate_dashboard') }}</div>
        </div>
      </div>
    </portal>
    <div class="w-full">
      <v-wait for="funnel_loading">
        <div v-if="funnel" class="flex flex-row flex-wrap w-full flex-col pl-5 sm:pl-4 xl:pl-5 py-8">
          <div class="flex flex-row">
            <div class="font-semibold text-2xl">
              <span>{{ $t('frontend_funnels_dash_heading') }}</span>
            </div>
          </div>
          <div class="flex w-full flex-row mt-4 flex-wrap justify-between">
            <div v-for="(option, index) in DashboardTiles" :key="index" class="dashboard-tile bg-white rounded-lg p-5 flex mb-5 shadow-lg">
              <div class="dashboard-tile-box flex items-center justify-center rounded-lg mr-4">
                <component :is="option.icon" class="w-13 h-13" />
              </div>
              <div>
                <div class="mb-3">
                  <span class="font-bold text-lg">{{ option.title }}</span><br />
                  <p class="text-grey-700 mt-1 text-sm">{{ option.description }}</p>
                </div>
                <div>
                  <gd-button @click="option.callback()" variant="primary">{{ option.button }}</gd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import ToolsIcon from '@/assets/images/icons/ToolsIcon.svg';
import TrophyIcon from '@/assets/images/icons/TrophyIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import DocumentIcon from '@/assets/images/icons/DocumentIcon.svg';
import FlagCheckeredIcon from '@/assets/images/icons/FlagCheckeredIcon.svg';
import TicketIcon from '@/assets/images/icons/TicketIcon.svg';
import ArrowBracketIcon from '@/assets/images/icons/ArrowBracketIcon.svg';
import ChevronRightIcon from '@/assets/images/icons/ChevronRightIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('funnel_dashboard') };
  },
  components: {
    ArrowBracketIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      funnel: '',
      DashboardTiles: [
        {
          icon: GiftsIcon,
          title: this.$t('frontend_funnels_custom_product_title'),
          description: this.$t('frontend_funnels_custom_product_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'products', params: { id: this.funnel.id } }),
        },
        {
          icon: ToolsIcon,
          title: this.$t('forntend_checkout_links'),
          description: this.$t('frontend_funnels_checkout_links_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'productsCheckoutLinks', params: { id: this.funnel.id, product_id: this.funnel.main_product_id } }),
        },
        {
          icon: DocumentIcon,
          title: this.$t('frontend_funnels_sales'),
          description: this.$t('frontend_funnels_sales_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'vendor_report', query: { funnel_id: this.funnel.id } }),
        },
        {
          icon: TicketIcon,
          title: this.$t('frontend_funnels_manage_coupons'),
          description: this.$t('frontend_funnels_manage_coupons_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'coupons' }),
        },
        {
          icon: FlagCheckeredIcon,
          title: this.$t('frontend_aff_perf'),
          description: this.$t('frontend_funnels_aff_perf_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'affiliates_performance', query: { funnel_id: this.funnel.id } }),
        },
        {
          icon: MegaphoneIcon,
          title: this.$t('frontend_funnels_affiliate_signup_promo_tools'),
          description: this.$t('frontend_funnels_affiliate_signup_promo_tools_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'funnel_resources', params: { id: this.funnel.id } }),
        },
        {
          icon: HandshakeIcon,
          title: this.$t('frontend_funnels_manage_affiliates_payouts'),
          description: this.$t('frontend_funnels_manage_affiliates_payouts_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'affiliates', query: { funnel_id: this.funnel.id } }),
        },
        {
          icon: TrophyIcon,
          title: this.$t('frontend_funnels_affiliate_leaderboards'),
          description: this.$t('frontend_funnels_affiliate_leaderboards_desc'),
          button: this.$t('frontend_manage'),
          callback: () => this.$router.push({ name: 'leader_boards' }),
        },
      ],
    };
  },
  async created() {
    this.$wait.start('funnel_loading');
    this.funnel = await Funnel.$find(this.$route.params.id);
    this.$wait.end('funnel_loading');
  },
  methods: {
    sendToFunnels() {
      this.$router.push({ name: 'funnels' });
    },
  },
};
</script>

<style lang="scss">
.dashboard-tile {
  width: calc(50% - 10px);
  .dashboard-tile-box {
    background-color: #f7f7fa;
    min-width: 102px;
    height: 102px;
    svg {
      fill: #9992b1;
    }
  }
}
</style>
