<template>
  <div>
    <!-- Payment Gateways List -->
    <div class="flex payment-gateway-list flex-wrap -mt-4 -mx-4" v-if="localGatewayIntegrations.length >= 0">
      <!-- Add Gateway Button -->
      <div class="px-4">
        <div
          class="w-80 h-80 my-4 border border-solid border-grey-200 rounded-lg flex flex-col bg-white justify-center items-center cursor-pointer"
          @click="openGatewayModal">
          <plus-icon class="w-20 h-20 text-primary fill-current" />
        </div>
        <payment-gateway :localGatewayList="localGatewayIntegrations"
          @updateIntegration="localGatewayIntegrations = $event"></payment-gateway>
      </div>

      <!-- Display Gateway Assets -->
      <div v-for="(gatewayIntegration, gatewayIntegrationIndex) in localGatewayIntegrations"
        :key="gatewayIntegrationIndex" class="px-4">
        <gateway-asset :gatewayIntegration="gatewayIntegration" @onProcessingFeeClick="openProcessingFeeModal"
          @onDeleteGatewayIntegration="openConfirmModal" @onEditGatewayIntegration="checkEditGateway" />
      </div>
    </div>
    <!-- No Gateway Message -->
    <div v-else>
      {{ $t('frontend_product_step3_add_payment_gateway') }}
    </div>

    <gd-modal name="add-processing-fees-modal" :title="$t('frontend_groovesell_estimated_merchant_fee')">
      <!-- Processing Fee Form -->
      <div class="px-8 py-6"
        v-if="!['groovepay_automated', 'paypal', 'paypal_commerce'].includes(editingIntegration.platform)">
        <div class="mb-5 text-grey-700">
          <info-icon class="w-4 h-4 fill-current inline" />
          <div class="font-semibold text-sm inline">
            {{ $t('average_merchant_processing_fee') }}
          </div>
        </div>
        <!-- Percentage Input -->
        <div class="flex flex-col mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_per_transaction_rates') }} (%)
          </label>
          <text-input v-model="editingIntegration.percentage" :attribute="$t('frontend_name')" class="text-dark text-sm"
            :placeholder="$t('frontend_percentage')" />
        </div>
        <!-- Per Transaction Fee Input -->
        <div class="flex flex-col mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_per_transaction_fee') }} ($)
          </label>
          <text-input v-model="editingIntegration.per_transaction_fee" :attribute="$t('frontend_name')"
            class="text-dark text-sm" :placeholder="$t('frontend_groovesell_transaction_fee')" />
        </div>
        <!-- Update Button -->
        <div class="flex justify-end">
          <gd-button class="ml-4" size="auto" variant="primary" @click="updateIntegration">{{ $t('frontend_update')
            }}</gd-button>
        </div>
      </div>
      <!-- PayPal/GroovePay Message -->
      <div class="px-8 py-6" v-else>
        {{
          editingIntegration.platform.startsWith('paypal')
            ? 'PayPal'
            : whitelabelAppData('pay').title
        }}
        {{ $t('frontend_groovesell_funnels_settings_fee_auto_calc_api') }}
      </div>
    </gd-modal>
    <!-- confirm modal-->
    <confirm-modal name="confirm-modal" :message-content="$t('frontend_bouses_delete_bonus_text')"
      :button-text="$t('frontend_bouses_delete_confirmation_text')" @confirm="deleteIntegration" />
    <!--  alert modal -->
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
    <!-- Edit payment gateway title -->
    <gd-modal name="payment-gateway-modal-edit" :title="$t('frontend_groovesell_edit_gateway')">
      <div class="px-8 py-6">
        <div class="flex flex-col mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_gateway_name') }}
          </label>
          <text-input v-model="selectedIntegrationName" :attribute="$t('frontend_name')" class="text-dark text-sm"
            :placeholder="$t('frontend_groovesell_gateway_name')" />
        </div>
        <div>
          <p class="text-sm mb-2">
            {{ $t('frontend_groovesell_cannot_edit_gateway_keys') }}
          </p>
        </div>
        <div class="flex justify-end">
          <gd-button class="ml-4" size="auto" variant="primary" @click="UpdateGateway">{{
            $t('frontend_update')}}</gd-button>
        </div>
      </div>

    </gd-modal>

  </div>
</template>

<script>
import Integration from '@/models/Integration';
import GatewayAsset from '@/components/GatewayAsset';
import PaymentGateway from '@/components/PaymentGatewayModal';
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import axios from 'axios';

export default {
  components: {
    PaymentGateway,
    GatewayAsset,
    InfoIcon,
    PlusIcon,
  },
  async created() {
    this.$loader.start('fetchIntegrations');
    this.localGatewayIntegrations = await Integration.$get();
    this.$loader.end('fetchIntegrations');
  },
  data() {
    return {
      selectedIntegrationName: '',
      showEditPayment: false,
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: ''
      },
      formfields: [],
      localGatewayIntegrations: {},
      selectedGatewayId: null,
      editingIntegration: {
        percentage: 0,
        per_transaction_fee: 0,
      },
      editGateway: {},
      selectedIntegrationTitle: '',
    };
  },
  methods: {
    async openProcessingFeeModal(gatewayId) {
      this.$loader.start('openProcessingFeeModal');
      this.editingIntegration = await Integration.$find(gatewayId);
      this.$root.$emit('modal-open', 'add-processing-fees-modal');
      this.$loader.end('openProcessingFeeModal');
    },
    openConfirmModal(gateway_id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.selectedGatewayId = gateway_id;
    },
    async checkEditGateway(gatewayId) {
      this.editGateway = await Integration.$find(gatewayId);
      this.selectedIntegrationName = this.editGateway.name;
      this.$root.$emit('modal-open', 'payment-gateway-modal-edit');
    },
    async UpdateGateway() {
      console.log('UpdateGateway', this.editGateway.id, this.formfields, this.selectedIntegrationName);
      const path = window.config.apiCoreUrl + '/integrations/update/' + this.editGateway.id;
      const data = {
        name: this.selectedIntegrationName,
      };
      try {
        this.$loader.start('UpdateGateway');
        const res = await axios.post(path, data);
        this.$loader.end('UpdateGateway');
        this.localGatewayIntegrations = this.localGatewayIntegrations.map((gateway) => {
          if (gateway.id === this.editGateway.id) {
            return {
              ...gateway,
              name: this.selectedIntegrationName,
            };
          }
          return gateway;
        });
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_groovesell_update_gateway'),
          message: this.$t('frontend_groovesell_gateway_updated_success'),
        };
        this.$root.$emit('modal-close', 'payment-gateway-modal-edit');
      } catch (e) {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_groovesell_update_gateway'),
          message: this.$t('frontend_groovesell_gateway_updated_error'),
        };
      }
    },



    async deleteIntegration() {
      try {
        this.$loader.start('deleteIntegration');
        const deleteGateway = await Integration.$find(this.selectedGatewayId);
        if (!deleteGateway) {
          this.$loader.end('deleteIntegration');
          return;
        }
        const res = await deleteGateway.delete();
        this.localGatewayIntegrations = this.localGatewayIntegrations.filter((gateway) => gateway.id !== this.selectedGatewayId);
        this.$loader.end('deleteIntegration');
        this.selectedGatewayId = null;
        this.alertProps = {
          show: true,
          variant: res.data.status,
          title: this.$t('frontend_groovesell_delete_gateway'),
          message: res.data.message || this.$t('frontend_groovesell_gateway_deleted_success'),
        };
      } catch (e) {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_groovesell_delete_gateway'),
          message: this.$t('frontend_groovesell_gateway_deleted_error'),
        };
      }

    },
    openGatewayModal() {
      this.$root.$emit('modal-open', 'payment-gateway-modal');
    },
    async updateIntegration() {
      this.$loader.start('updateIntegration');
      await this.editingIntegration.save();
      this.$root.$emit('modal-close', 'add-processing-fees-modal');
      this.$loader.end('updateIntegration');
    },
  },
}
</script>
