<template>
    <div>
        <div class="flex flex-row">
            <div class="flex flex-col w-1/3 mr-2">
                <div v-for="(checkoutType, index) in expressCheckoutTypes" :key="index" class="flex mr-4">
                    <gd-custom-radio v-model="localModel.checkout_page_options.express_checkout"
                        :option="checkoutType"></gd-custom-radio>
                </div>
            </div>
            <div class="flex flex-row-reverse w-2/3 pr-4">
                <CheckoutPage class="max-w-full" />
            </div>
        </div>
    </div>
</template>
<script>
import CheckoutPage from '@/assets/images/pages/checkout-page.svg';

export default {
    components: {
        CheckoutPage
    },
    props: {
        model: {
            required: true
        },

    },
    data() {
        return {
            localModel: this.model,
            expressCheckoutTypes: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step5_express_checkout_types0_title'),
                    description: this.$t('frontend_product_step5_express_checkout_types0_desc'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step5_express_checkout_types1_title'),
                    description: this.$t('frontend_product_step5_express_checkout_types1_desc'),
                },
                {
                    id: 2,
                    title: this.$t('frontend_product_step5_express_checkout_types2_title'),
                    description: this.$t('frontend_product_step5_express_checkout_types2_desc'),
                },
            ],
        }
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('updateModel', this.localModel);
            },
            deep: true
        }
    },
}
</script>