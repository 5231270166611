<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">{{ $t('frontend_product_step10_proof_widget') }}</h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{ $t('frontend_product_step10_config_proof_widget') }}.</span>
      </div>
      <hr class="border-grey-100 -mx-4" />
      <div class="flex flex-col w-full mb-6 mt-8">
        <div class="flex flex-col w-full mr-2">
          <div class="flex flex-row justify-end">
            <gd-button variant="primary" @click="createWidget">
              <plus-icon class="w-5 h-5 fill-current" />
              {{ $t('frontend_create') }}
            </gd-button>
          </div>
          <div class="flex flex-row w-full">
            <div v-if="proofWidgets.length" id="widgets-list-view" class="w-full">
              <v-client-table
                ref="widgetsTable"
                :data="proofWidgets"
                :columns="widgetsTableColumns"
                :options="widgetsTableOptions"
                class="bg-white text-center rounded border border-grey-300 mt-4"
              >
                <div slot="title" slot-scope="props">
                  {{ props.row.name }}
                </div>
                <div slot="actions" slot-scope="props">
                  <div class="flex flex-row">
                    <gd-button variant="primary" size="icon" class="mr-2" @click="openEditWidget(props.row.id)">
                      <pencil-icon class="h-5 w-5 fill-current"/>
                    </gd-button>
                    <gd-button variant="primary" size="icon" class="mr-2" @click="openConfirmModal(props.row.id)">
                      <trash-icon class="h-5 w-5 fill-current"/>
                    </gd-button>
                  </div>
                </div>
              </v-client-table>
            </div>
            <div v-else class="mt-4 text-center">
              <label class="text-grey-700">
                {{ $t('frontend_product_step10_dont_have_any_proof') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gd-modal name="add-widget-modal" :title="$t('frontend_groovesell_create_new_proff_widget')" width="80%">
      <div class="flex flex-col px-4">
        <div class="flex flex-row mb-2 -mx-4 px-4">
          <gd-tabs
            :tabs="widgetsTabs"
            :current-tab="widgetsTab"
            :wrapper-class="'pt-4 px-0 gs-default-tabs'"
            :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
            :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
            :line-class="'gs-default-tabs-active-line'"
            @onClick="handleWidgetsTabChange"
          />
        </div>
        <div class="mt-4">
          <div v-if="widgetsTab == 'info'">
            <div class="-mt-4">
              <div class="flex flex-col w-full mt-4">
                <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_name') }}</label>
                <text-input
                  v-model="proofModel.name"
                  :validator="$v.proofModel.name"
                  :attribute="$t('frontend_name')"
                  value="proofModel.name"
                  :placeholder="$t('frontend_name')"
                />
              </div>
              <div class="flex flex-col w-full mt-4">
                <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_notification_text') }}</label>
                <text-input
                  v-model="proofModel.notification_text"
                  :validator="$v.proofModel.notification_text"
                  :attribute="$t('frontend_notification_text')"
                  :placeholder="$t('frontend_notification_text')"
                />
              </div>
            </div>
          </div>
          <div v-else-if="widgetsTab == 'display'">
            <div class="-mt-4">
              <div class="flex flex-row w-full">
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_product_step10_delay_between_notification') }}</label>
                  <gd-dropdown
                    v-model="proofModel.delay"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_delay')"
                    :options="proofOptions"
                  />
                </div>

                <div class="w-1/2 flex flex-col w-full mt-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left"
                    >{{ $t('frontend_product_step10_notification_time_to_show') }})</label
                  >
                  <gd-dropdown
                    v-model="proofModel.duration"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_duration')"
                    :options="proofDurationOptions"
                  />
                </div>
              </div>

              <div class="flex flex-row w-full">
                <div class="flex flex-col w-1/2 mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_product_step10_n_contacts_to_display') }}</label>
                  <gd-dropdown
                    v-model="proofModel.no_of_conversions"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_product_step10_n_contacts_to_display')"
                    :options="proofNoOfNotificationsOptions"
                  />
                </div>
                <div class="flex flex-col w-1/2 mt-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_position') }}</label>
                  <gd-dropdown
                    v-model="proofModel.position"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_position')"
                    :options="positionOptions"
                  />
                </div>
              </div>

              <div class="flex flex-row w-full">
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_display_to_mobile') }}</label>
                  <gd-toggle v-model="proofModel.show_on_mobile" :value="proofModel.show_on_mobile" />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="widgetsTab == 'design'">
            <div class="-mt-4">
              <div class="flex flex-row w-full">
                <div class="flex flex-col w-1/3 mt-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_title_color') }}</label>
                  <v-swatches
                    v-model="proofModel.title_color"
                    show-fallback
                    fallback-input-type="color"
                    popover-x="right"
                    popover-y="bottom"
                    :trigger-style="swatchTriggerStyle"
                    swatches="text-basic"
                    swatch-size="24"
                  ></v-swatches>
                </div>
                <div class="flex flex-col w-1/3 mt-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_description_color') }}</label>
                  <v-swatches
                    v-model="proofModel.description_color"
                    show-fallback
                    fallback-input-type="color"
                    popover-x="right"
                    popover-y="bottom"
                    :trigger-style="swatchTriggerStyle"
                    swatches="text-basic"
                    swatch-size="24"
                  ></v-swatches>
                </div>
                <div class="flex flex-col w-1/3 mt-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_bg_color') }}</label>
                  <v-swatches
                    v-model="proofModel.background_color"
                    show-fallback
                    fallback-input-type="color"
                    popover-x="right"
                    popover-y="bottom"
                    :trigger-style="swatchTriggerStyle"
                    swatches="text-basic"
                    swatch-size="24"
                  ></v-swatches>
                </div>
              </div>
              <div class="flex flex-row w-full">
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">
                    {{ $t('frontend_location_format') }}
                  </label>
                  <gd-dropdown
                    v-model="proofModel.location_format"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_location_format')"
                    :options="locationFormatOptions"
                  />
                </div>
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_avatar_display') }}</label>
                  <gd-dropdown
                    v-model="proofModel.avatar_display"
                    size="full"
                    max-height="124px"
                    :placeholder="$t('frontend_avatar_display')"
                    :options="avatarDisplayOptions"
                  />
                </div>
              </div>
              <div class="flex flex-row w-full">
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_product_step10_show_timestamps') }}</label>
                  <gd-toggle v-model="proofModel.show_timestamps" :value="proofModel.show_timestamps" />
                </div>
                <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                  <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_product_step10_show_first_name') }}</label>
                  <gd-toggle v-model="proofModel.show_real_names" :value="proofModel.show_real_names" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button class="mt-4" size="md" variant="primary" @click="saveWidget">{{ isEditing ? $t('frontend_update') : $t('frontend_create') }}</gd-button>
        </div>
      </div>
    </gd-modal>
    <confirm-modal
      name="confirm-delete-widget-modal"
      :message-content="$t('frontend_non_revertable')"
      :confirm-button-text="$t('frontend_delete_confirm')"
      @confirm="deleteWidget"
    />
  </div>
</template>

<script>
import ProofWidget from '@/models/ProofWidget';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { Chrome, Photoshop } from 'vue-color';
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';
import VSwatches from 'vue-swatches';
// Import the styles too, globally
import 'vue-swatches/dist/vue-swatches.css';

export default {
  name: 'Step10',
  components: {
    'chrome-picker': Chrome,
    ColorPicker,
    VSwatches,
    TrashIcon,
    PencilIcon,
    PlusIcon,
  },
  props: {
    model: {
      required: true
    },
    product: {
      required: true
    }
  },
  data() {
    return {
      widgetId: null,
      localModel: this.model,
      title_colors: {
        hex: '#000000',
        source: 'hex'
      },
      description_colors: {
        hex: '#000000',
        source: 'hex'
      },
      backgroud_colors: {
        hex: '#000000',
        source: 'hex'
      },
      swatchTriggerStyle: {
        border: '1px solid #dcdcea'
      },
      isEditing: false,
      updateValue: '',
      hex: '',
      isOpen: false,
      description_color_isOpen: false,
      backgroud_color_isOpen: false,
      proofWidget: null,
      proofWidgets: [],
      proofModel: {
        product_id: this.$route.params.product_id || this.product.id,
        name: '',
        notification_text: 'Just Purchased ' + this.$store.state.brandData.brandAltName,
        delay: 5,
        duration: 3,
        no_of_conversions: 10,
        position: 'top left',
        title_color: '#000000',
        description_color: '#636363',
        background_color: '#FFFFFF',
        show_timestamps: false,
        show_real_names: false,
        location_format: 1,
        avatar_display: 1,
        show_on_mobile: false
      },
      position: 'top left',
      widgetsTabs: [
        { title: this.$t('frontend_info'), value: 'info' },
        { title: this.$t('frontend_display'), value: 'display' },
        { title: this.$t('frontend_design'), value: 'design' }
      ],
      widgetsTab: 'info',
      widgetsTableColumns: ['name', 'actions'],
      widgetsTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [],
        headings: {
          name: this.$t('frontend_name'),
          actions: this.$t('frontend_actions'),
          title: 'Name',
          expand: ''
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      }
    };
  },
  validations: {
    localModel: {},
    proofModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80)
      },
      notification_text: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80)
      }
    }
  },
  computed: {
    proofOptions() {
      return [
        { value: 5, text: `5 ${this.$t('frontend_seconds')}` },
        { value: 10, text: `10 ${this.$t('frontend_seconds')}` },
        { value: 15, text: `15 ${this.$t('frontend_seconds')}` },
        { value: 20, text: `20 ${this.$t('frontend_seconds')}` },
        { value: 60, text: `60 ${this.$t('frontend_seconds')}` },
      ];
    },
    proofDurationOptions() {
      return [
        { value: 3, text: `3 ${this.$t('frontend_seconds')}` },
        { value: 5, text: `5 ${this.$t('frontend_seconds')}` },
        { value: 10, text: `10 ${this.$t('frontend_seconds')}` }
      ];
    },
    proofNoOfNotificationsOptions() {
      return [5, 10, 25, 50, 100].map(item => {
        return { value: item, text: item };
      });
    },
    positionOptions() {
      return [
        { value: 'top left', text: this.$t('frontend_top_left') },
        { value: 'top right', text: this.$t('frontend_top_right') },
        { value: 'bottom left', text: this.$t('frontend_bottom_left') },
        { value: 'bottom right', text: this.$t('frontend_bottom_right') }
      ];
    },
    locationFormatOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step10_location_format_option1') },
        { value: 2, text: this.$t('frontend_product_step10_location_format_option2') },
        { value: 3, text: this.$t('frontend_product_step10_location_format_option3') },
        { value: 4, text: this.$t('frontend_product_step10_location_format_option4') }
      ];
    },
    avatarDisplayOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step10_avatar_display_option1') },
        { value: 2, text: this.$t('frontend_product_step10_avatar_display_option2') },
        { value: 3, text: this.$t('frontend_product_step10_avatar_display_option3') }
      ];
    },
  },
  async created() {
    this.$loader.start('fetchProofWidgets');
    this.proofWidgets = await this.product.proof_widgets().$get();
    this.proofModel = {
      product_id: this.$route.params.product_id || this.product.id,
      name: '',
      notification_text: this.$t('frontend_product_step10_notification_text', {brandAltName: this.$store.state.brandData.brandAltName}),
      delay: 5,
      duration: 3,
      no_of_conversions: 10,
      position: 'top left',
      title_color: '#000000',
      description_color: '#636363',
      background_color: '#FFFFFF',
      show_timestamps: false,
      show_real_names: false,
      location_format: 1,
      avatar_display: 1,
      show_on_mobile: false
    };
    this.$loader.end('fetchProofWidgets');
  },
  methods: {
    handleWidgetsTabChange(newTab) {
      this.widgetsTab = newTab;
    },

    validate() {
      this.$v.localModel.$touch();
      const isValid = !this.$v.localModel.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    async createWidget() {
      this.proofWidget = new ProofWidget({});

      this.proofModel = {
        product_id: this.$route.params.product_id || this.product.id,
        name: '',
        notification_text: this.$t('frontend_product_step10_notification_text', {brandAltName: this.$store.state.brandData.brandAltName}),
        delay: 5,
        duration: 3,
        no_of_conversions: 10,
        position: 'bottom left',
        title_color: '#000000',
        description_color: '#636363',
        background_color: '#FFFFFF',
        show_timestamps: false,
        show_real_names: false,
        location_format: 1,
        avatar_display: 1,
        show_on_mobile: false
      };

      this.$v.proofModel.$reset();
      this.widgetsTab = 'info';
      this.isEditing = false;
      this.$root.$emit('modal-open', 'add-widget-modal');
    },

    async saveWidget() {
      this.$v.proofModel.$touch();

      if (!this.$v.proofModel.$invalid) {
        this.$loader.start('saveWidget');
        this.proofWidget.product_id = this.proofModel.product_id;
        this.proofWidget.name = this.proofModel.name;
        this.proofWidget.notification_text = this.proofModel.notification_text;
        this.proofWidget.delay = this.proofModel.delay;
        this.proofWidget.duration = this.proofModel.duration;
        this.proofWidget.no_of_conversions = this.proofModel.no_of_conversions;
        this.proofWidget.position = this.proofModel.position;
        this.proofWidget.title_color = this.proofModel.title_color;
        this.proofWidget.description_color = this.proofModel.description_color;
        this.proofWidget.background_color = this.proofModel.background_color;
        this.proofWidget.show_timestamps = this.proofModel.show_timestamps;
        this.proofWidget.show_real_names = this.proofModel.show_real_names;
        this.proofWidget.location_format = this.proofModel.location_format;
        this.proofWidget.avatar_display = this.proofModel.avatar_display;
        this.proofWidget.show_on_mobile = this.proofModel.show_on_mobile;
        await this.proofWidget.save();
        this.$root.$emit('modal-close', 'add-widget-modal');
        this.proofWidgets = await this.product.proof_widgets().$get();
        this.$loader.end('saveWidget');
      }
    },

    async openEditWidget(id) {
      this.$loader.start('openEditWidget');
      this.$v.proofModel.$reset();
      this.proofWidget = await ProofWidget.$find(id);
      this.proofModel = {
        name: this.proofWidget.name,
        notification_text: this.proofWidget.notification_text,
        delay: this.proofWidget.delay,
        duration: this.proofWidget.duration,
        no_of_conversions: this.proofWidget.no_of_conversions || 10,
        position: this.proofWidget.position,
        title_color: this.proofWidget.title_color,
        description_color: this.proofWidget.description_color,
        background_color: this.proofWidget.background_color,
        show_timestamps: this.proofWidget.show_timestamps,
        show_real_names: this.proofWidget.show_real_names,
        location_format: this.proofWidget.location_format,
        avatar_display: this.proofWidget.avatar_display,
        show_on_mobile: this.proofWidget.show_on_mobile
      };

      this.widgetsTab = 'info';
      this.isEditing = true;
      this.$root.$emit('modal-open', 'add-widget-modal');
      this.$loader.end('openEditWidget');
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-delete-widget-modal');
      this.widgetId = id;
    },
    async deleteWidget() {
        this.$loader.start('deleteWidget');
        const Proof_Widget = await ProofWidget.$find(this.widgetId);
        await Proof_Widget.delete();
        const deleted = this.proofWidgets.map(item => item.id).indexOf(this.widgetId);
        this.proofWidgets.splice(deleted, 1);
        this.$loader.end('deleteWidget');
    },
  }
};
</script>

<style lang="scss">
#widgets-list-view,
#price-points-list-view, 
#filesTable {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
<style type="text/css">
.vue-color__chrome__alpha-wrap,
.vue-color__chrome__toggle-btn,
.vue-color__chrome__fields-wrap,
.vue-color__chrome__color-wrap {
  display: none !important;
}

.vue-color__chrome__controls {
  align-items: center;
}

.vue-color__chrome__active-color {
  margin-top: 0;
}

.fg-color {
  height: 35px;
  flex: 1;
  border: 1px solid;
  border-color: #9c9393;
}

.fg-color-sample {
  display: inline-block;
  width: 80px;
  border: none;
}

.picker {
  display: flex;
  justify-content: justify;
}

#colorpicker {
  max-width: 245px;
}
</style>
