<template>
  <div>
    <div v-if="hasLogoutPageAds" class="flex flex-col">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-tabs
          :tabs="logoutPageAdsTabs"
          :current-tab="localLogoutPageAdsTab"
          :error-tabs="logoutPageAdsErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleLogoutPageAdsTabChange"
          :enable-dragging="true"
          @updateTabOrder="handleLogoutPageAdsTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createLogoutPageAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(ad, index) in localFunnelResource.logout_page_ads" :key="index">
          <div v-if="isLogoutPageAdTabActive(ad)" class="pb-8">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_name') }}
                </label>
                <text-input
                  v-model="ad.name"
                  :validator="$v.localFunnelResource.logout_page_ads.$each[index].name"
                  :attribute="$t('frontend_groovesell_ad_name')"
                  value="ad.name"
                  :placeholder="$t('frontend_name')"
                />
              </div>

              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle class="h-6 mt-2" v-model="ad.enabled" :value="ad.enabled" />
                <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index)">
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex flex-row w-1/2">
                <div class="w-1/2">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_ad') }}
                  </label>
                </div>
                <div class="flex flex-row justify-end mb-2 w-1/2">
                  <div class="flex flex-row">
                    <div class="font-semibold text-sm text-grey-700 mr-2">
                      {{ $t('frontend_resources_view_html') }}
                    </div>
                    <gd-toggle v-model="isLogoutAdsPageHTMLView" :value="isLogoutAdsPageHTMLView" />
                  </div>
                </div>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-if="isLogoutAdsPageHTMLView"
                    v-model="ad.body"
                    class="swipesEditor w-full"
                    value="ad.body"
                    :placeholder="$t('frontend_body')"
                    variant="dark"
                  />
                  <quill-editor
                    v-else
                    :ref="'logoutPageAdEditor_' + index"
                    v-model="ad.body"
                    :options="adsEditorOptions"
                    class="swipesEditor w-full"
                  ></quill-editor>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToLogoutPageAd(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActions(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-button
          size="auto"
          variant="primary"
          class="mt-3 mb-3"
          @click="createLogoutPageAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_you_currently_dont_have_logout') }}
    </div>
    <confirm-modal
      name="confirm-facebook-ad-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteLogoutPageAd"
    />
  </div>
</template>

<script>
import Quill from 'quill';
import { quillEditor } from 'vue-quill-editor';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import Swal from 'sweetalert2';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    quillEditor,
    TrashIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    logoutPageAdsTab: {
      type: String,
      default: '',
    },
    adsEditorOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFunnelResource: this.funnelResource,
      localLogoutPageAdsTab: this.logoutPageAdsTab,
      isLogoutAdsPageHTMLView: false,
      logoutPageAdId: null,
    };
  },
  computed: {
    hasLogoutPageAds() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.logout_page_ads &&
        this.localFunnelResource.logout_page_ads.length
      );
    },
    logoutPageAdsTabs() {
      let logoutPageAdsTabs = [];

      if (this.hasLogoutPageAds) {
        for (let i = 0; i < this.localFunnelResource.logout_page_ads.length; i++) {
          const ad = this.localFunnelResource.logout_page_ads[i];
          logoutPageAdsTabs.push({
            title: ad.name,
            value: `logout_page_ad_tab_${ad.id}`,
          });
        }
      }

      return logoutPageAdsTabs;
    },
    logoutPageAdsErrorTabs() {
      let logoutPageAdsErrorTabs = [];

      for (const index in this.$v.localFunnelResource.logout_page_ads.$each.$iter) {
        if (this.$v.localFunnelResource.logout_page_ads.$each.$iter[index].$error) {
          logoutPageAdsErrorTabs.push(
            `logout_page_ad_tab_${this.$v.localFunnelResource.logout_page_ads.$each.$iter[index].$model.id}`
          );
        }
      }

      return logoutPageAdsErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActions(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToLogoutPageAd(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleLogoutPageAdsTabChange(newTab) {
      this.localLogoutPageAdsTab = newTab || this.localLogoutPageAdsTab;
      this.$emit('handleLogoutPageAdsTabChange', this.localLogoutPageAdsTab);
    },
    handleLogoutPageAdsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('logout_page_ad_tab_', ''))
      );

      this.localFunnelResource.logout_page_ads.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createLogoutPageAd() {
      const newAdId = this.generateNumber();
      const newAdCount = this.localFunnelResource.logout_page_ads.length + 1;

      this.localFunnelResource.logout_page_ads.push({
        id: newAdId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_ad')} ${newAdCount}`,
        body: '',
      });

      this.localLogoutPageAdsTab = `logout_page_ad_tab_${newAdId}`;

      this.updateFunnelResource();
      this.handleLogoutPageAdsTabChange();
    },
    openConfirmModal(logoutPageAdId) {
      this.logoutPageAdId = logoutPageAdId;
      this.$root.$emit('modal-open', 'confirm-facebook-ad-delete-modal');
    },
    async deleteLogoutPageAd() {
      this.localFunnelResource.logout_page_ads.splice(this.logoutPageAdId, 1);
      this.localLogoutPageAdsTab = '';

      if (this.localFunnelResource.logout_page_ads.length > 0) {
        let nextOpenIndex = 0;
        if (this.logoutPageAdId != 0) {
          nextOpenIndex = this.logoutPageAdId - 1;
        }

        const nextOpenId =
          this.localFunnelResource.logout_page_ads[nextOpenIndex].id;
        this.localLogoutPageAdsTab = `logout_page_ad_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleLogoutPageAdsTabChange();
    },
    addAffiliateNameToLogoutPageAd(index) {
      const range =
        this.$refs[`logoutPageAdEditor_${index}`][0].quill.getSelection(true);

      if (range) {
        this.$refs[`logoutPageAdEditor_${index}`][0].quill.insertText(
          range.index,
          '##affiliateName##'
        );
      }
    },
    addAffiliateLinkToLogoutPageAd(index, landingPageId) {
      const range =
        this.$refs[`logoutPageAdEditor_${index}`][0].quill.getSelection(true);
      if (range) {
        if (range.length == 0) {
          this.$refs[`logoutPageAdEditor_${index}`][0].quill.insertText(
            range.index,
            `##affiliateLink${landingPageId}##`
          );
        } else {
          this.$refs[`logoutPageAdEditor_${index}`][0].quill.format(
            'link',
            `##affiliateLink${landingPageId}##`,
            Quill.sources.USER
          );
        }
      }
    },
    isLogoutPageAdTabActive(ad) {
      return this.localLogoutPageAdsTab === `logout_page_ad_tab_${ad.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      logout_page_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
