<template>
  <div class="relative flex flex-col justify-start w-full bg-white px-5">
    <div class="flex flex-row w-full container mx-auto">
      <gd-tabs
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active'"
        line-class="gs-default-tabs-active-line"
        :tabs="tabs"
        :current-tab="currentTab"
        :disable-not-completed="true"
      />
      <div class="flex flex-row ml-8">
        <gd-button
          v-if="currentTab != 'step1'"
          size="auto"
          class="mr-4 mt-3"
          @click="moveBackOnWizard"
        >
          {{ $t('frontend_back') }}
        </gd-button>
        <gd-button
          v-if="currentTab != lastTab"
          variant="primary"
          size="auto"
          class="mr-4 mt-3"
          @click="moveNextOnWizard"
        >
          {{ $t('frontend_save_next') }}
        </gd-button>
        <gd-button
          v-else
          variant="primary"
          size="auto"
          class="mr-4 mt-3"
          @click="$emit('saveAndExitWizard')"
        >
          {{ $t('frontend_save_exit') }}
        </gd-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    currentTab: String,
    lastTab: String,
  },
  methods: {
    moveNextOnWizard() {
      let currentlySelectedTabIndex = this.tabs
        .map(item => item.value)
        .indexOf(this.currentTab);
      let nextTab = this.tabs[currentlySelectedTabIndex + 1].value;

      this.$emit('validateRef', nextTab, this.currentTab);
    },
    moveBackOnWizard() {
      let currentlySelectedTabIndex = this.tabs
        .map(item => item.value)
        .indexOf(this.currentTab);
      let nextTab = this.tabs[currentlySelectedTabIndex - 1].value;

      this.$emit('validateRef', nextTab, this.currentTab);
    },
  },
};
</script>
