<template>
  <gd-modal name="create-bonus-modal" :title="$t('frontend_groovesell_bonuses_create_new_bonus')">
    <div class="flex flex-col m-10">
      <div class="flex flex-row w-full mb-6">
        <label class="w-1/4 text-grey-dark">{{ $t('frontend_bonuses_name') }}</label>
        <text-input
          v-model="localModel.name"
          class="w-3/4"
          :validator="$v.localModel.name"
          :attribute="$t('frontend_name')"
          :placeholder="$t('frontend_name')"
        />
      </div>
      
      <div class="flex flex-row w-full mb-6">
        <label class="w-1/4 text-grey-dark">{{ $t('frontend_bouses_link') }}</label>
        <text-input
          v-model="localModel.link"
          class="w-3/4"
          :validator="$v.localModel.link"
          :attribute="'Link'"
          :placeholder="'Link'"
        />
      </div>
      
      <div class="flex flex-row w-full mb-6 justify-end">
        <gd-button size="md" variant="primary" @click="createBonus">
          {{ $t('frontend_bouses_create') }}
        </gd-button>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import { required, minLength, maxLength, url } from 'vuelidate/lib/validators';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  methods: {
    createBonus() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit('createBonus', this.localModel);
      }
    },
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80)
      },
      link: {
        required,
        url
      }
    }
  },
};
</script>
