<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col w-1/2">
      <!-- font color -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_total_codes_font_color') }}
        </label>
        <div class="pr-6 w-24">
          <color-picker
            v-model="localModel.customization_details.totals_code.font_color"
            class="border-2 border-grey-300 rounded"
            :colors="colors"
            :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
          ></color-picker>
        </div>
      </div>

      <!-- font size -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_total_codes_font_size') }}
        </label>
        <select
          v-model="localModel.customization_details.totals_code.font_size"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="1">{{ $t('frontend_groovesell_bumps_step4_small') }}</option>
          <option value="2">{{ $t('frontend_groovesell_bumps_step4_regular') }}</option>
          <option value="3">{{ $t('frontend_groovesell_bumps_step4_large') }}</option>
          <option value="4">{{ $t('frontend_groovesell_bumps_step4_xlarge') }}</option>
        </select>
      </div>

      <!-- font family -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_total_codes_font_family') }}
        </label>
        <select
          v-model="localModel.customization_details.totals_code.font_family"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="Arial">Arial</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Times">Times</option>
          <option value="Courier New">Courier New</option>
          <option value="Verdana">Verdana</option>
          <option value="Courier">Courier</option>
          <option value="Arial Narrow">Arial Narrow</option>
          <option value="Candara">Candara</option>
          <option value="Geneva">Geneva</option>
          <option value="Calibri">Calibri</option>
          <option value="Optima">Optima</option>
          <option value="Cambria">Cambria</option>
          <option value="Garamond">Garamond</option>
          <option value="Perpetua">Perpetua</option>
          <option value="Monaco">Monaco</option>
          <option value="Didot">Didot</option>
          <option value="Brush Script MT">Brush Script MT</option>
          <option value="Lucida Bright">Lucida Bright</option>
          <option value="Copperplate">Copperplate</option>
        </select>
      </div>

      <!-- font weight -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_total_codes_font_weight') }}
        </label>
        <select
          v-model="localModel.customization_details.totals_code.font_weight"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="normal">{{ $t('frontend_groovesell_bumps_step4_normal') }}</option>
          <option value="bold">{{ $t('frontend_groovesell_bumps_step4_bold') }}</option>
        </select>
      </div>
    </div>

    <div class="flex flex-col w-1/2 justify-center items-center">
      <p
        class="custom-totals-codes-color font-semibold"
        :class="{
          'text-sm': localModel.customization_details.totals_code.font_size == 1,
          'text-md': localModel.customization_details.totals_code.font_size == 2,
          'text-lg': localModel.customization_details.totals_code.font_size == 3,
          'text-xl': localModel.customization_details.totals_code.font_size == 4,
        }"
      >
        {{ $t('frontend_groovesell_bumps_step4_card_total') }} (X {{ $t('frontend_items') }}) : $X,XXX
      </p>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';

export default {
  components: {
    ColorPicker,
  },
  props: {
    model: {
      required: true,
    },
    colors: {
      required: true,
    },
    customColor: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
    }
  },
}
</script>
