<template>
  <div class="flex flex-col content-center justify-center text-center w-full bg-white rounded p-4">
    <div class="text-green-dark mt-4 mb-2">
      <em class="far fa-check-circle fa-5x"></em>
    </div>
    <div class="mb-2">
      <h3 class="text-xl font-bold">
        {{ $t('frontend_bumps_congratulations') }}
      </h3>
    </div>
    <div class="mb-8">
      <p class="text-sm font-semibold text-grey-700">
        {{ $t('frontend_bumps_ready') }}
      </p>
    </div>
    <div class="flex flex-col mb-8">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs
          :tabs="customizationTabs"
          :current-tab="customizationTab"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleCustomizationTabChange"
        />
      </div>
      <div class="mt-2">
        <div v-if="customizationTab === 'embed_button'">
          <div
            v-for="(otherProduct, otherProductIndex) in selectedProducts"
            :key="otherProductIndex"
            class="mb-4 pb-4 border-b border-grey-300 flex flex-col justify-left w-1/2"
          >
            <div class="text-sm font-semibold text-dark text-left">
              {{ resolveSelectedProductName(otherProduct.id) }}
            </div>
            <div class="flex flex-row w-full">
              <div class="w-11/12">
                <text-area
                  class="w-full mt-2"
                  :readonly="true"
                  :value="copyProductEmbedCode(otherProduct.id)"
                  :placeholder="$t('frontend_groovesell_upsell_button')"
                  :select-all="true"
                />
              </div>
              <div class="w-1/12 justify-center items-center items-center h-full">
                <gd-button
                  v-clipboard:copy="copyProductEmbedCode(otherProduct.id)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="primary"
                  size="icon"
                  class="ml-2 mt-2"
                >
                  <em class="fas fa-clone"></em>
                </gd-button>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="customizationTab === 'link_button'">
          <div class="flex flex-col w-1/2 justify-start mt-4">
            <div class="mb-4">
              <p class="text-sm font-semibold text-grey-700 text-left">
                {{ $t('frontend_groovesell_bumps_step5_copy_code_upsell_add_to_card') }}
              </p>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-11/12">
                <text-area
                  v-model="upsellEmbed"
                  class="w-full mt-2"
                  :readonly="true"
                  value="upsellEmbed"
                  :placeholder="$t('frontend_groovesell_upsell_button')"
                  :select-all="true"
                />
              </div>

              <div class="w-1/12 justify-center items-center items-center h-full">
                <gd-button
                  v-clipboard:copy="upsellEmbed"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="primary"
                  size="icon"
                  class="ml-2 mt-2"
                >
                  <em class="fas fa-clone"></em>
                </gd-button>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="customizationTab === 'totals_code'">
          <div class="flex flex-col w-1/2 justify-start mt-4">
            <div class="mb-4">
              <p class="text-sm font-semibold text-grey-700 text-left">
                <span class="text-bold">{{ $t('frontend_optional') }} : </span>
                {{ $t('frontend_groovesell_bumps_step5_copy_code_show_total_cart_value') }}
              </p>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-11/12">
                <text-area
                  v-model="totalsEmbed"
                  class="w-full mt-2"
                  :readonly="true"
                  value="totalsEmbed"
                  :placeholder="$t('frontend_groovesell_upsell_button')"
                  :select-all="true"
                />
              </div>

              <div class="w-1/12 justify-center items-center items-center h-full">
                <gd-button
                  v-clipboard:copy="totalsEmbed"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="primary"
                  size="icon"
                  class="ml-2 mt-2"
                >
                  <em class="fas fa-clone"></em>
                </gd-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
  name: 'FinishTab',
  props: {
    model: {
      required: true,
    },
    products: {
      required: true,
    },
    funnel: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      customizationTabs: [
        {title: this.$t('add_to_cart_button'), value: 'embed_button'},
        {title: this.$t('frontend_groovesell_upsell_button'), value: 'link_button'},
        {title: this.$t('totals_code'), value: 'totals_code'},
      ],
      customizationTab: 'embed_button',
    };
  },
  computed: {
    sellAppTitle() {
      return this.whitelabelAppData('sell').title.toLowerCase();
    },
    pagesAppTitle() {
      return this.whitelabelAppData('pages').title.toLowerCase();
    },
    upsellEmbed() {
      const site_appurl = window.config.appUrl.replace(/^https?:\/\//i, '');
      return `<div style="width: 100%;"><${this.sellAppTitle}-atc-upsell bump-id="${this.localModel.id}" funnel-id="${this.funnel.id}" bump-permalink="${this.localModel.permalink}"></${this.sellAppTitle}-atc-upsell><script src="https://${this.pagesAppTitle}.${site_appurl}/embed/js/app.js?n=1"><\/script></div>`;
    },
    totalsEmbed() {
      const site_appurl = window.config.appUrl.replace(/^https?:\/\//i, '');
      return `<div style="width: 100%;"><${this.sellAppTitle}-atc-totals bump-id="${this.localModel.id}" funnel-id="${this.funnel.id}"></${this.sellAppTitle}-atc-totals><script src="https://${this.pagesAppTitle}.${site_appurl}/embed/js/app.js?n=1"><\/script></div>`;
    },
    selectedProducts() {
      return this.localModel.funnel_details.filter((product) => {
        return product.enabled;
      });
    },
  },
  methods: {
    resolveSelectedProductName(productId) {
      const productObject = this.products.find((o) => o.id == productId);

      if (productObject == undefined) {
        return 'N/A';
      }

      return productObject.name;
    },
    onCopy() {
      Notify.success(this.$t('copied_successfully_to_clipboard'));
    },
    onError() {
      Notify.fail(this.$t('frontend_unable_copy_to_clipboard'));
    },
    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    copyProductEmbedCode(productId) {
      const siteAppUrl = window.config.appUrl.replace(/^https?:\/\//i, '');
      const pricePointObject = this.localModel.funnel_details.find(
        (item) => item.id === productId
      );

      let permalink = pricePointObject?.permalink || 0;

      return `<div style="width: 100%;"><${this.sellAppTitle}-atc-bump permalink="${permalink}" funnel-id="${this.funnel.id}" bump-id="${this.localModel.id}"></${this.sellAppTitle}-atc-bump><script src="https://${this.pagesAppTitle}.${siteAppUrl}/embed/js/app.js?n=1"><\/script></div>`;
    },
    handleCustomizationTabChange(newTab) {
      this.customizationTab = newTab;
    },
  },
};
</script>
