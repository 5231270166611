<template>
  <div class="flex flex-row w-full">
    <div class="flex flex-col w-1/4 mr-5">
      <div>
        <gd-dropdown
          v-model="localSortBy"
          :options="sortOptions"
          @change="$emit('changedSortBy', localSortBy)"
        />
      </div>
      <div class="flex flex-row mt-2">
        <gd-dropdown
          v-model="localSelectedFilter"
          :options="filterOptions"
          @change="$emit('onFilterChanged', localSelectedFilter)"
        />
      </div>
    </div>
    <div class="flex flex-col w-3/4">
      <div class="flex flex-row justify-end">
          <div class="flex flex-col">
            <text-input
              v-model="localQuery"
              v-debounce:1s="searchMarketPlaceData"
              value="localQuery"
              :placeholder="$t('frontend_analytics_transactions_search')"
              :debounce-events="['input', 'keydown']"
            />

            <div class="flex flex-row justify-end mt-2">
              <div
                class="flex flex-row justify-center items-center h-10 w-10 border border-grey-200 cursor-pointer rounded-sm"
                @click="updateViewTypeGrid(true)"
                :class="{
                  'bg-primary text-white': localViewTypeGrid,
                  'bg-white text-primary': !localViewTypeGrid,
                }"
              >
                <grid-icon class="w-5 h-5 fill-current" />
              </div>
              <div
                class="flex flex-row justify-center items-center h-10 w-10 border border-grey-200 cursor-pointer rounded-sm"
                @click="updateViewTypeGrid(false)"
                :class="{
                  'bg-primary text-white': !localViewTypeGrid,
                  'bg-white text-primary': localViewTypeGrid,
                }"
              >
                <list-icon class="w-5 h-5 fill-current" />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridIcon from '@/assets/images/icons/GridIcon.svg';
import ListIcon from '@/assets/images/icons/ListIcon.svg';

export default {
  components: {
    GridIcon,
    ListIcon,
  },
  props: {
    sortBy: {
      type: String,
      default: '1',
    },
    selectedFilter: {
      type: String,
      default: 'all',
    },
    query: {
      type: String,
      default: null,
    },
    viewTypeGrid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localSortBy: this.sortBy,
      localSelectedFilter: this.selectedFilter,
      localQuery: this.query,
      localViewTypeGrid: this.viewTypeGrid,
    };
  },
  computed: {
    sortOptions() {
      return [
        { value: '1', text: this.$t('frontend_marketplace_power_score') },
        { value: '2', text: this.$t('frontend_marketplace_conversation_rate') },
        { value: '3', text: this.$t('frontend_marketplace_funnel_epc_to_affiliate') },
        { value: '4', text: this.$t('frontend_marketplace_refund_rate') },
        { value: '5', text: this.$t('frontend_marketplace_newest') }
      ];
    },
    filterOptions() {
      return [
        { value: 'all', text: this.$t('frontend_marketplace_all_funnels') },
        { value: 'top_10', text: this.$t('frontend_top_10_funnels') },
      ];
    },
  },
  methods: {
    updateViewTypeGrid(value) {
      this.localViewTypeGrid = value;
      this.$emit('updateViewTypeGrid', this.localViewTypeGrid);
    },
    searchMarketPlaceData() {
      let hit = false;
      this.page = 1;
      if (this.localQuery.length != undefined && this.localQuery.trim() != '' && this.localQuery.length > 3) {
        hit = true;
      }
      if (this.localQuery.trim().length == 0) {
        hit = true;
      }
      if (hit) {
        this.$emit('searchMarketPlaceData', this.localQuery);
      }
    },
  },
}
</script>
