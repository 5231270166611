import Model from './Model';

export default class LeaderboardWidget extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'leaderboard-widgets';
  }

  get leaderboard_embed() {
    if (this.permalink && this.permalink !== '') {
      const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
      const groovesellWhiteLabelAppName = window.config.groovesellWhiteLabelAppName;
      return `<div style="min-height:220px; width: 100%;"><link href="https://${this.slug}.${siteAppurl}/lbwidget/css/app.css?n=1" rel="stylesheet"><${groovesellWhiteLabelAppName}-leaderboard permalink="${this.permalink}"></${groovesellWhiteLabelAppName}-leaderboard><script src="https://${this.slug}.${siteAppurl}/lbwidget/js/app.js?n=1"></script></div>`;
    }
      return '';
  }
}
