<template>
  <div class="min-h-full h-auto bg-secondary pb-6">
    <div class="relative flex flex-col justify-start w-full bg-white px-5">
      <div class="flex flex-row w-full container mx-auto">
        <gd-tabs
          :tabs="tabs"
          :current-tab="currentTab"
          :disable-not-completed="true"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
        />
        <div class="flex flex-row ml-8">
          <gd-button
            size="auto"
            class="mr-4 mt-3"
            :style="{visibility: currentTab != 'info' ? 'visible' : 'hidden'}"
            @click="moveBackOnWizard"
          >
            {{ $t('frontend_bumps_back') }}
          </gd-button>
          <gd-button
            v-if="currentTab != 'finish'"
            size="auto"
            class="mr-4 mt-3"
            @click="moveNextOnWizard"
          >
            {{ $t('frontend_bumps_save_next') }}
          </gd-button>
          <gd-button
            v-else
            variant="primary"
            size="auto"
            class="mr-4 mt-3"
            @click="saveAndExitWizard"
          >
            {{ $t('frontend_bumps_save_exit') }}
          </gd-button>
        </div>
      </div>
    </div>

    <v-wait for="create_order_bump">
      <div class="px-5">
        <div
          class="relative flex flex-col justify-start w-full h-full mt-8 container mx-auto"
        >
          <div v-if="currentTab === 'info'">
            <info-tab
              ref="info"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('info', $event)"
            ></info-tab>
          </div>

          <div v-else-if="currentTab === 'products'">
            <products-tab
              ref="products"
              :model="model"
              :products="products"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('products', $event)"
            ></products-tab>
          </div>

          <div v-if="currentTab === 'funnel'">
            <funnel-tab
              ref="funnel"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('funnel', $event)"
            ></funnel-tab>
          </div>

          <div v-else-if="currentTab === 'customize'">
            <customize-tab
              ref="customize"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('customize', $event)"
            ></customize-tab>
          </div>

          <div v-else-if="currentTab === 'finish'">
            <finish-tab
              ref="finish"
              :model="model"
              :products="products"
              :funnel="funnel"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('finish', $event)"
            ></finish-tab>
          </div>
        </div>
      </div>
    </v-wait>

    <portal to="headerBreadCrumb">
      <div class="mt-1 cursor-pointer" @click="sendToFunnelProducts">
        <em class="fas fa-chevron-left"></em>
      </div>
    </portal>
  </div>
</template>

<script>
import Bump from '@/models/Bump';
import Funnel from '@/models/Funnel';
import InfoTab from './partials/tabs/InfoTab';
import ProductsTab from './partials/tabs/ProductsTab';
import FunnelTab from './partials/tabs/FunnelTab';
import CustomizeTab from './partials/tabs/CustomizeTab';
import FinishTab from './partials/tabs/FinishTab';

export default {
  layout: 'defaultWithoutSidebar',
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('create_atc_order_bump') };
  },
  components: {
    InfoTab,
    ProductsTab,
    FunnelTab,
    CustomizeTab,
    FinishTab,
  },
  data() {
    return {
      tabs: [
        {
          title: '<span class="text-sm font-semibold">1. Info <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'info',
        },
        {
          title: '<span class="text-sm font-semibold">2. Products <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'products',
        },
        {
          title: '<span class="text-sm font-semibold">3. Funnel <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'funnel',
        },
        {
          title: '<span class="text-sm font-semibold">4. Customize <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'customize',
        },
        {
          title: '<span class="text-sm font-semibold">5. Finish<span>',
          value: 'finish',
        },
      ],
      postValidationTab: 'products',
      currentTab: 'info',
      edit_bump: false,
      funnel: null,
      orderBump: null,
      products: [],
      model: {
        name: '',
        internal_name: '',
        funnel_id: '',
        description: '',
        funnel_details: [],
        customization_details: [],
        thankyou_page_url: '',
      },
    };
  },
  async created() {
    this.$wait.start('create_order_bump');

    if (this.$route.params.product_id === undefined) {
      this.orderBump = new Bump({
        funnel_id: this.$route.params.id,
        name: '',
        internal_name: '',
        description: '',
        thankyou_page_url: 'https://thankyoupage.com',
        funnel_details: [],
        customization_details: {
          embed_button: {
            button_type: 1,
            button_size: 1,
            button_shape: 1,
            button_before_text: 'ADD',
            button_after_bg_color: '#AED1E7',
            button_after_font_color: '#266FA2',
            button_before_border_size: 1,
            button_after_border_color: '#266FA2',
            button_after_text: 'SELECTED',
            button_before_bg_color: '#A9E4B8',
            button_before_font_color: '#546457',
            button_after_border_size: 1,
            button_before_border_color: '#546457',
            button_shadow: 1,
          },
          thankyou_button: {
            button_text: this.$t('yes_upgrade_my_order_now'),
            button_subtext: this.$t('buy_now_with_one_click'),
            button_bg_color: '#0175B2',
            button_font_color: '#ffffff',
            button_subtext_font_color: '#ffffff',
            button_size: 1,
            button_border_size: 1,
            button_border_color: '#546457',
            button_shape: 1,
            button_shadow: 1,
          },
          totals_code: {
            font_color: '#191d3a',
            font_size: 1,
            font_family: 'Arial',
            font_weight: 'normal',
          },
        },
      });
    } else {
      this.orderBump = await Bump.$find(this.$route.params.product_id);
      this.edit_bump = true;

      const embedButton = this.orderBump.customization_details.embed_button;
      const thankyouButton = this.orderBump.customization_details.thankyou_button;
      const totalsCode = this.orderBump.customization_details.totals_code;

      embedButton.button_shadow = embedButton.button_shadow === undefined? 1 : embedButton.button_shadow;
      thankyouButton.button_border_size = thankyouButton.button_border_size === undefined ? 1 : thankyouButton.button_border_size;
      thankyouButton.button_shadow = thankyouButton.button_shadow === undefined ? 1 : thankyouButton.button_shadow;
      thankyouButton.button_border_color = thankyouButton.button_border_color === undefined ? '#266fa2' : thankyouButton.button_border_color;

      if (!totalsCode) {
        this.orderBump.customization_details.totals_code = {
          font_color: '#191d3a',
          font_size: 1,
          font_family: 'Arial',
          font_weight: 'normal',
        };
      } else if (totalsCode.font_weight === undefined) {
        totalsCode.font_weight = 'normal';
      }
    }

    const {
      id,
      name,
      internal_name,
      funnel_id,
      description,
      thankyou_page_url,
      permalink,
      customization_details,
    } = this.orderBump;

    this.model.id = id;
    this.model.name = name;
    this.model.internal_name = internal_name;
    this.model.funnel_id = funnel_id;
    this.model.description = description;
    this.model.thankyou_page_url = thankyou_page_url;
    this.model.permalink = permalink;
    this.model.customization_details = customization_details;

    this.funnel = await Funnel.$find(this.$route.params.id);
    this.products = await this.funnel.products().$get();
    const { products } = this;

    if (this.$route.params.product_id !== undefined) {
      this.orderBump.funnel_details.forEach((value, index) => {
        const selectedProductObject = products.find((o) => o.id == value.id);
        value.price_points = selectedProductObject.price_points.map((obj) => ({
          id: obj.id,
          name: obj.name,
        }));
      });
    }

    this.model.funnel_details = this.orderBump.funnel_details;

    const localFunnelDetails = [];

    for (let i = 0; i < this.products.length; i++) {
      const productId = this.products[i].id;
      const selectedPricePointObject = this.model.funnel_details.find((o) => o.id == productId);

      if (selectedPricePointObject === undefined) {
        const singleProductDetails = {
          id: productId,
          price_point: this.products[i].price_points.find((o) => o.is_default).id,
          price_points: this.products[i].price_points.map((obj) => ({
            id: obj.id,
            name: obj.name,
          })),
          enabled: true,
          permalink: Math.ceil(Math.random() * 100000),
        };
        localFunnelDetails.push(singleProductDetails);
      } else {
        const singleProductDetails = {
          id: productId,
          price_points: selectedPricePointObject.price_points,
          price_point: selectedPricePointObject.price_point,
          enabled: selectedPricePointObject.enabled,
          permalink: selectedPricePointObject.permalink,
        };
        localFunnelDetails.push(singleProductDetails);
      }
    }

    this.model.funnel_details = localFunnelDetails;
    this.$wait.end('create_order_bump');
  },
  methods: {
    sendToFunnelProducts() {
      this.$router.push({
        name: 'products',
        params: { id: this.$route.params.id },
      });
    },
    moveNextOnWizard() {
      const currentlySelectedTabIndex = this.tabs
        .map((item) => item.value)
        .indexOf(this.currentTab);
      const nextTab = this.tabs[currentlySelectedTabIndex + 1].value;
      this.postValidationTab = nextTab;
      const refToValidate = this.$refs[this.currentTab];
      return refToValidate.validate();
    },
    moveBackOnWizard() {
      const currentlySelectedTabIndex = this.tabs
        .map((item) => item.value)
        .indexOf(this.currentTab);
      const nextTab = this.tabs[currentlySelectedTabIndex - 1].value;
      this.postValidationTab = nextTab;
      const refToValidate = this.$refs[this.currentTab];
      return refToValidate.validate();
    },
    async saveAndExitWizard() {
      await this.saveAllInfo();
      this.$router.push({
        name: 'products',
        params: { id: this.$route.params.id },
      });
    },
    async saveBasicOrderBumpInfo() {
      this.$loader.start('saveBasicOrderBumpInfo');
      this.orderBump.name = this.model.name;
      this.orderBump.internal_name = this.model.internal_name;
      this.orderBump.description = this.model.description;
      this.orderBump.funnel_details = this.model.funnel_details;
      this.orderBump.customization_details = this.model.customization_details;
      this.orderBump.thankyou_page_url = this.model.thankyou_page_url;
      const savedOrderBumpData = await this.orderBump.save();

      if (!this.edit_bump) {
        this.orderBump = await Bump.$find(savedOrderBumpData.data.id);
      }

      this.model.id = this.orderBump.id;
      this.model.name = this.orderBump.name;
      this.model.internal_name = this.orderBump.internal_name;
      this.model.description = this.orderBump.description;
      this.model.thankyou_page_url = this.orderBump.thankyou_page_url;
      this.model.customization_details = this.orderBump.customization_details;
      this.model.permalink = this.orderBump.permalink;
      this.edit_bump = true;
      this.$loader.end('saveBasicOrderBumpInfo');
      return true;
    },
    async saveAllInfo() {
      this.$loader.start('saveAllInfo');
      await this.saveOrderBumpInfo();
      this.$loader.end('saveAllInfo');
      return true;
    },
    async saveOrderBumpInfo() {
      this.$loader.start('saveOrderBumpInfo');
      this.orderBump.name = this.model.name;
      this.orderBump.internal_name = this.model.internal_name;
      this.orderBump.description = this.model.description;
      this.orderBump.funnel_details = this.model.funnel_details;
      this.orderBump.customization_details = this.model.customization_details;
      this.orderBump.thankyou_page_url = this.model.thankyou_page_url;
      const savedOrderBumpData = await this.orderBump.save();

      this.orderBump = await Bump.$find(savedOrderBumpData.data.id);

      this.model.id = this.orderBump.id;
      this.model.name = this.orderBump.name;
      this.model.internal_name = this.orderBump.internal_name;
      this.model.description = this.orderBump.description;
      this.model.customization_details = this.orderBump.customization_details;
      this.model.thankyou_page_url = this.orderBump.thankyou_page_url;
      this.model.permalink = this.orderBump.permalink;
      this.$loader.end('saveOrderBumpInfo');
    },
    async stepValidationSuccessful(name, isValid) {
      if (isValid) {
        if (name == 'info') {
          await this.saveBasicOrderBumpInfo();
        } else {
          await this.saveOrderBumpInfo();
        }

        this.currentTab = this.postValidationTab;
      }
    },
  },
};
</script>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
