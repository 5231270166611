<template>
    <div class="mt-6">
        <div class="flex flex-row">
            <div class="flex flex-col w-full mr-20">
                <div class="flex flex-col w-full mb-4">
                    <p class="font-semibold">
                        {{ $t('frontend_checkout_links_step4_select_template') }}
                    </p>
                    <div class="flex flex-row w-full mt-2">
                        <div v-for="(templateType, index) in templateTypes" :key="index" class="w-1/3 mr-4">
                            <checkout-template-radio
                                v-model="prductDetails.checkout_page_options.look_and_feel.template_type"
                                :option="templateType"></checkout-template-radio>
                        </div>
                    </div>
                </div>
                <div class="w-full" v-if="prductDetails.checkout_page_options.look_and_feel.template_type == 4">
                    <div class="flex flex-row">
                        <p class="flex flex-col w-1/3 font-semibold mb-2">
                            {{ $t('frontend_primary_color') }}
                        </p>
                        <p class="flex flex-col w-1/3 font-semibold mb-2 ml-10">
                            {{ $t('frontend_secondary_color') }}
                        </p>
                    </div>

                    <!-- {{localModel.secondary_color}} -->
                    <div class="flex flex-row mb-8">
                        <div class="flex flex-col w-1/3" :class="{
                            'pointer-events-none': prductDetails.checkout_page_options.look_and_feel.template_type != 4,
                        }">
                            <color-picker v-model="localModel.primary_color" data-cy="localModel.primary_color"
                                class="border-2 w-24 border-grey-300 rounded" :colors="colors"
                                :cookies-origin="'groovesell_funnel_' + prductDetails.funnel_id"
                                @change="changedLogoBG(1)"></color-picker>
                        </div>
                        <div class="flex flex-col w-1/3 ml-10" :class="{
                            'pointer-events-none':
                                prductDetails.checkout_page_options.look_and_feel
                                    .template_type != 4,
                        }">
                            <color-picker v-model="localModel.secondary_color" data-cy="localModel.secondary_color"
                                class="border-2 w-24 border-grey-300 rounded" :colors="colors"
                                :cookies-origin="'groovesell_funnel_' + prductDetails.funnel_id"
                                @change="changedLogoBG(2)"></color-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BlueCheckoutPage from '@/assets/images/pages/blue-checkout-page-template.svg';
import GreyCheckoutPage from '@/assets/images/pages/grey-checkout-page-template.svg';
import PinkCheckoutPage from '@/assets/images/pages/default-checkout-page-template.svg';
import CustomCheckoutPage from '@/assets/images/pages/custom-checkout-page-template.svg';
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';

export default {
    components: {
        ColorPicker
    },
    props: {
        prductDetails: {
            required: true
        },
        model: {
            required: true
        },
        colors: {
            required: true
        }
    },
    watch: {
        localModel: {
            handler: function (val, oldVal) {
                this.$emit('update-model', this.localModel)
            },
            deep: true
        },
    },
    data() {
        return {
            localModel: this.model,
            templateTypes: [
                {
                    id: 1,
                    title: this.$t('frontend_groovesell_products_step5_templ_types_1'),
                    icon: PinkCheckoutPage,
                },
                {
                    id: 2,
                    title: this.$t('frontend_groovesell_products_step5_templ_types_2'),
                    icon: BlueCheckoutPage,
                },
                {
                    id: 3,
                    title: this.$t('frontend_groovesell_products_step5_templ_types_3'),
                    icon: GreyCheckoutPage,
                },
                {
                    id: 4,
                    title: this.$t('frontend_groovesell_products_step5_templ_types_4'),
                    icon: CustomCheckoutPage,
                },
            ],
        }
    },
    methods: {
        componentToHex(c) {
            const hex = c.toString(16);
            return hex.length == 1 ? `0${hex}` : hex;
        },
        rgbToHex(r, g, b) {
            return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
        },
        changedLogoBG(type) {
            console.log('type', type, this.localModel.primary_color, this.localModel.secondary_color);
            if (type == 1 && this.localModel.primary_color != '') {
                const values = this.localModel.primary_color
                    .replace(/rgba?\(/, '')
                    .replace(/\)/, '')
                    .replace(/[\s+]/g, '')
                    .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.primary_color = colorHex;
                this.$emit('attribute-changed', {
                    key: 'primary_color',
                    value: colorHex
                })
            }

            if (type == 2 && this.localModel.secondary_color != '') {
                const values = this.localModel.secondary_color
                    .replace(/rgba?\(/, '')
                    .replace(/\)/, '')
                    .replace(/[\s+]/g, '')
                    .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.secondary_color = colorHex;
                this.$emit('attribute-changed', {
                    key: 'secondary_color',
                    value: colorHex
                })
            }
        },
    }
}
</script>