<template>
    <div>
        <div class="flex flex-row">
            <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_customer')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.rendered_buyer_fullname }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_email')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.rendered_buyer_email }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_phone')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.rendered_phone &&
                            selectedTransaction.rendered_phone.trim() != ''
                            ? selectedTransaction.rendered_phone
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_company')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.rendered_buyer_company &&
                            selectedTransaction.rendered_buyer_company.trim() !=
                            ''
                            ? selectedTransaction.rendered_buyer_company
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_ip_address')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.buyer_ip }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_address')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.rendered_buyer_address &&
                            selectedTransaction.rendered_buyer_address.trim() !=
                            ''
                            ? selectedTransaction.rendered_buyer_address
                            : 'N/A'
                        }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_city')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.buyer_city &&
                            selectedTransaction.buyer_city.trim() != ''
                            ? selectedTransaction.buyer_city
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_State')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.buyer_state &&
                            selectedTransaction.buyer_state.trim() != ''
                            ? selectedTransaction.buyer_state
                            : 'N/A'
                        }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_country')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.buyer_country &&
                            selectedTransaction.buyer_country.trim() != ''
                            ? selectedTransaction.buyer_country
                            : 'N/A'
                        }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_affiliate_transactions_zip')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.buyer_postal_code &&
                            selectedTransaction.buyer_postal_code.trim() != ''
                            ? selectedTransaction.buyer_postal_code
                            : 'N/A'
                        }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTransaction: {
            required: true,
        },
    },
};
</script>