var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gd-modal',{attrs:{"name":"payment-gateway-modal","width":"900px","title":_vm.selectedIntegrationTitle
    ? _vm.selectedIntegrationTitle
    : _vm.$t('frontend_groovesell_paymeny_gateway_list')}},[_c('div',{staticClass:"overflow-y-auto custom-scrollbars scroll-modal"},[_c('div',{staticClass:"px-6 py-6"},[(!_vm.showIntegrationForm)?_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.availableIntegrations),function(integration,index){return _c('div',{key:index,staticClass:"p-2",class:integration.name === 'groove_pay' ||
            integration.name === 'groovepay_automated'
            ? 'w-full'
            : 'w-1/4'},[(
              integration.name === 'groove_pay' ||
              integration.name === 'groovepay_automated'
            )?_c('div',[_c('div',{staticClass:"flex border border-grey-300 rounded p-4 text-grey-darkest cursor-pointer relative",on:{"click":function($event){return _vm.handleIntegrationClick(
                      integration.fields,
                      integration.name,
                      integration.display_name
                    )}}},[_c('div',{staticClass:"w-32"},[_c('img',{attrs:{"src":require("@/assets/images/logo/groovepay-integration.png"),"alt":""}}),_c('div',{staticClass:"flex w-full justify-center mt-4"},[_c('gd-button',{attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.openGroovepay}},[_vm._v("Signup")])],1)]),_c('div',{staticClass:"flex flex-col flex-1 ml-4"},[_c('p',{staticClass:"text-lg font-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_product_step3_groovesell_is_best', { appTitle: _vm.whitelabelAppData('sell').title, payAppTitle: _vm.whitelabelAppData('pay').title }))+" ")]),_c('p',{staticClass:"text-sm mb-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("As Low As 2.85%")]),_c('span',{staticClass:"text-xs"},[_vm._v("+ $0.25 per transaction for USA")])]),_c('p',{staticClass:"text-sm font-bold mb-2"},[_vm._v(" The best and most affordable way to accept credit cards ")]),_c('p',{staticClass:"text-sm mb-2"},[_vm._v(" "+_vm._s(_vm.$t('groovepay_has_no', { appTitle: _vm.whitelabelAppData('pay').title }))+" ")])]),_c('div',{staticClass:"absolute top-0 right-0 -mt-6 -mr-6"},[_c('img',{attrs:{"src":require("@/assets/images/icons/recommended-icon.png"),"alt":""}})])])]):_vm._e(),(
              integration.name !== 'groove_pay' &&
              integration.name !== 'groovepay_automated'
            )?_c('div',{staticClass:"h-full relative"},[_c('div',{staticClass:"flex flex-col h-full items-center border border-grey-300 rounded overflow-hidden p-4 text-grey-darkest",class:_vm.isGroovester(integration.groove_only)
                  ? 'cursor-pointer'
                  : 'pointer-events-none',on:{"click":function($event){return _vm.handleIntegrationClick(
                      integration.fields,
                      integration.name,
                      integration.display_name
                    )}}},[_c('div',[(integration.name == 'paypal' || integration.name == 'paypal_commerce')?_c('paypal-logo-icon',{staticClass:"h-16"}):(integration.name == 'stripe')?_c('stripe-logo-icon',{staticClass:"h-16"}):_c('img',{staticClass:"h-16",attrs:{"src":'https://assets.grooveapps.com/payment-gateway/' + integration.name + '-integration.png',"alt":""}})],1),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(integration.display_name)+" ")])])]):_vm._e()])}),0):_vm._e(),(_vm.showIntegrationForm)?_c('div',{staticClass:"px-2"},[(
            _vm.selectedIntegrationType == 'braintree' ||
            _vm.selectedIntegrationType == 'authorize.net'
          )?_c('div',{staticClass:"mb-4 bg-grey-100 text-sm font-semibold text-grey-700 p-2"},[_c('info-icon',{staticClass:"w-4 h-4 fill-current inline"}),_c('div',{staticClass:"inline"},[_vm._v(" Note : For Braintree and Authorize.net payment gateway the currency of integration account should match with the funnel currency. ")])],1):_vm._e(),(
            _vm.selectedIntegrationType == 'nmi' ||
            _vm.selectedIntegrationType == 'paypal'
          )?_c('div',{staticClass:"mb-4 bg-grey-100 text-sm font-semibold text-grey-700 p-2"},[_c('info-icon',{staticClass:"w-4 h-4 fill-current inline"}),_c('div',{staticClass:"inline"},[_vm._v(" Note : For NMI and Paypal we only support USD make sure the funnel currency is in USD . ")])],1):_vm._e(),_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700 mb-2"},[_vm._v(_vm._s(_vm.$t('frontend_product_step3_name')))]),_c('text-input',{staticClass:"text-dark text-sm",attrs:{"validator":_vm.$v.selectedIntegrationName,"attribute":_vm.$t('frontend_product_step3_name'),"placeholder":_vm.$t('frontend_product_step3_name')},model:{value:(_vm.selectedIntegrationName),callback:function ($$v) {_vm.selectedIntegrationName=$$v},expression:"selectedIntegrationName"}})],1),_vm._l((_vm.formfields),function(field,index){return _c('div',{key:index,staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700 mb-2"},[_vm._v(" "+_vm._s(_vm.formfields[index].label)+" ")]),(_vm.formfields[index].info)?_c('span',{staticClass:"text-xs mb-1 text-grey"},[_vm._v(" "+_vm._s(_vm.formfields[index].info)+" ")]):_vm._e(),_c('text-input',{staticClass:"text-dark text-sm",attrs:{"validator":_vm.$v.formfields.$each[index].value,"attribute":_vm.formfields[index].label,"value":"formfields[index].value","placeholder":_vm.formfields[index].label},model:{value:(_vm.formfields[index].value),callback:function ($$v) {_vm.$set(_vm.formfields[index], "value", $$v)},expression:"formfields[index].value"}})],1)}),_c('div',{staticClass:"flex justify-end"},[_c('gd-button',{attrs:{"size":"auto"},on:{"click":_vm.backToGateway}},[_vm._v(_vm._s(_vm.$t('frontend_groovesell_back_to_list')))]),_c('gd-button',{staticClass:"ml-4",attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.registerIntegration}},[_vm._v(_vm._s(_vm.$t('frontend_product_step3_create')))])],1)],2):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }