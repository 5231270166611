<template>
  <div class="flex flex-col justify-center h-full">
    <div
      class="
        border
        w-full
        mr-6
        bg-white
        rounded-lg
        p-6
        hover:cursor-pointer
        border-solid border-grey-300
        h-full
      "
      :class="{
        'bg-primary-lightest shadow-outline-blue': activeOption === optionName,
      }"
      tabindex="0"
      @click="updateActiveOption"
      @keydown.space="updateActiveOption"
    >
      <div class="flex justify-center items-center mb-3">
        <component :is="optionIcon" class="checkoutRadioIcon"></component>
      </div>

      <div class="mb-1 font-semibold text-center">
        <p class="text-md">{{ optionName }}</p>
      </div>

      <div class="mb-1 font-semibold text-center" v-if="comingSoon">
        <p class="text-sm">({{ $t('frontend_coming_soon') }})</p>
      </div>
    </div>
  </div>
</template>

<script>
import FrontendProduct from '@fortawesome/fontawesome-pro/svgs/light/gifts.svg';
import ATCBumps from '@/assets/images/icons/ATCBumps.svg';
import Upsell from '@/assets/images/icons/Upsell.svg';
import LeadCapture from '@/assets/images/icons/LeadCapture.svg';
import OrderBumps from '@/assets/images/icons/OrderBumps.svg';

export default {
  name: 'ProductRadio',
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption',
  },
  components: {
    FrontendProduct,
    ATCBumps,
    Upsell,
    LeadCapture,
    OrderBumps,
  },
  props: ['optionName', 'optionIcon', 'activeOption', 'comingSoon'],
  methods: {
    updateActiveOption() {
      if (this.comingSoon) {
      } else {
        this.$emit('onUpdateOption', this.optionName);
      }
    },
  },
};
</script>

<style>
.checkoutRadioIcon {
  width: 80px;
  height: 80px;
}
</style>
