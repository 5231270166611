<template>
    <gd-modal name="edit-leaderboard-team-modal" :title="$t('frontend_groovesell_edit_leaderboard_team')" height="80%"
        width="80%" :no-overflow="true" v-if="leaderboard" allowOverflow>
        <div class="flex flex-col m-6 mb-4">
            <div class="flex flex-col w-full mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_team_name') }}</label>
                <text-input v-model="leaderBoardTeamEditModal.name" :attribute="$t('frontend_name')"
                    :value="leaderBoardTeamEditModal.name" :placeholder="$t('frontend_name')" />
            </div>
        </div>

        <div class="px-4">
            <div class="flex pt-2 pr-2">
                <div class="w-1/2 flex flex-row pl-2" v-if="leaderboard.funnel_id">
                    <affiliates-auto-suggest :funnel="leaderboard.funnel_id" :excluded="selectedAffiliateIdArr"
                        :button="$t('frontend_groovesell_button_add_affiliate')" @onAffiliateAdded="addedAffiliate" />
                </div>
            </div>
            <div class="flex mt-2 pt-2 pr-2">
                <div class="w-full flex flex-row" v-if="leaderboard.funnel_id">
                    <affiliate-list-view :funnel="leaderboard.funnel_id"
                        :affiliate-ids="suggestionSelectedAffiliates" @onAffiliateRemoved="removedAffiliate" />
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-end mr-4 mt-4 mb-4">
            <gd-button v-if="suggestionSelectedAffiliates &&
                suggestionSelectedAffiliates.length > 0 &&
                !validation.leaderBoardTeamEditModal.name.$error" variant="primary" size="auto" class="mr-4 mb-4"
                @click="updateLeaderBoardTeam">{{
                    $t('frontend_update_team')
                }}</gd-button>
            <gd-button v-else disabled size="auto" class="mr-4 mb-4">{{ $t('frontend_update_team') }}</gd-button>
        </div>
    </gd-modal>
</template>
<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';
export default {
    components: {
        AffiliatesAutoSuggest,
        AffiliateListView
    },
    props: {
        leaderboard: {
            required: true
        },
        leaderBoardTeamEditModal: {
            required: true
        },
        selectedAffiliateIdArr: {
            required: true
        },
        addedAffiliate: {
            required: true
        },
        removedAffiliate: {
            required: true
        },
        updateLeaderBoardTeam: {
            required: true
        },
        suggestionSelectedAffiliates: {
            required: true
        },
        validation: {
            required: true
        },
    },

}
</script>