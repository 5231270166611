<template>
  <div>
    <div>
      <v-wait for="settings">
        <div class="flex flex-col w-full bg-white rounded p-4">
          <div class="flex flex-col w-1/2 mb-2">
            <h4 class="font-semibold text-grey-700">
              {{ $t('frontend_affiliate_settings_affiliate_settings') }}
            </h4>
          </div>
          <div class="flex flex-col w-1/2 mb-2">
            <span class="text-sm text-grey-700">{{
              $t('frontend_affiliate_settings_you_can_configure')
            }}</span>
          </div>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
            <gd-tabs :tabs="affiliateSettingsTabs" :current-tab="affiliateSettingsTab"
              :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiiateSettingsTabChange" />
          </div>
          <div class="mt-2">
            <general v-if="affiliateSettingsTab === 'general'" @update-form="HandleUpdateForm" :userId="userId"
              :validator="$v" :form="form" :saveAffiliateSettings="saveAffiliateSettings" />
            <payment v-else-if="affiliateSettingsTab === 'payment'" :saveAffiliateSettings="saveAffiliateSettings"
              :form="form" @update-form="HandleUpdateForm" />

            <tax v-else-if="affiliateSettingsTab === 'tax'" @update-form="HandleUpdateForm" :form="form"
              :saveAffiliateSettings="saveAffiliateSettings" :validator="$v" />


          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Form from 'vform';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from 'vuelidate/lib/validators';
import Notify from '@groovepages/gd-ui-kit/notify';

/** new components */
import General from './Settings_partials/tabs/General';
import Payment from './Settings_partials/tabs/Payment';
import Tax from './Settings_partials/tabs/Tax';

export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  components: {
    General,
    Payment,
    Tax,
  },
  data() {
    return {
      form: new Form({
        username: '',
        affiliate_paypal_email: '',
        affiliate_tax_id: '',
        affiliate_legal_name: '',
        affiliate_legal_address: '',
        affiliate_legal_address_2: '',
        affiliate_legal_city: '',
        affiliate_legal_state: '',
        affiliate_legal_country: '',
        affiliate_legal_zip_code: '',
        affiliate_tax_resident_type: 0,
        affiliate_tax_form: '',
      }),

      userId: 0,
      affiliateSettingsTabs: [
        {
          title: this.$t('frontend_affiliate_settings_general'),
          value: 'general',
        },
        {
          title: this.$t('frontend_affiliate_settings_payment'),
          value: 'payment',
        },
        {
          title: this.$t('frontend_affiliate_settings_tax_info'),
          value: 'tax',
        },
      ],
      affiliateSettingsTab: 'general',
    };
  },

  metaInfo() {
    return { title: this.$t('frontend_affiliate_settings_affiliate_settings') };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(255),
        async isUnique(value) {
          if (value === '') {
            return true;
          }

          let userNameFlag = false;

          await axios
            .get(`/validate-username/${value}`)
            .then(() => {
              userNameFlag = true;
            })
            .catch(() => {
              userNameFlag = false;
            });

          return userNameFlag;
        },
      },
      affiliate_legal_name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(1000),
      },
      affiliate_legal_address: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(1000),
      },
      affiliate_legal_city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(1000),
      },
      affiliate_legal_state: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(1000),
      },
      affiliate_legal_country: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(1000),
      },
      affiliate_legal_zip_code: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(1000),
      },
      affiliate_tax_id: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(1000),
      },
      affiliate_tax_resident_type: {
        required,
      },
      affiliate_tax_form: {
        required,
      },
    },
  },
  async created() {
    this.$wait.start('settings');
    const { data } = await axios.get('/affiliate-settings');
    this.userId = data.affiliate_id;
    this.form.username = data.username;
    this.form.affiliate_paypal_email = data.affiliate_paypal_email;
    this.form.affiliate_wire_info = data.affiliate_wire_info;
    this.form.affiliate_tax_id = data.affiliate_tax_id;
    this.form.affiliate_legal_name = data.affiliate_legal_name;
    this.form.affiliate_legal_address = data.affiliate_legal_address;
    this.form.affiliate_legal_address_2 = data.affiliate_legal_address_2;
    this.form.affiliate_legal_city = data.affiliate_legal_city;
    this.form.affiliate_legal_state = data.affiliate_legal_state;
    this.form.affiliate_legal_country = data.affiliate_legal_country;
    this.form.affiliate_legal_zip_code = data.affiliate_legal_zip_code;
    this.form.affiliate_tax_resident_type = data.affiliate_tax_resident_type;
    this.form.affiliate_tax_form = data.affiliate_tax_form;
    // await this.$v.$touch();
    this.$wait.end('settings');
  },
  methods: {
    HandleUpdateForm(newFormValue) {
      this.form = newFormValue;
    },

    handleAffiiateSettingsTabChange(newTab) {
      this.affiliateSettingsTab = newTab;
    },
    async saveAffiliateSettings() {;
      let isFormValid = true;
      if (this.affiliateSettingsTab == 'general') {
        this.$v.$touch();
        isFormValid = !this.$v.form.username.$$invalid;
      } else if (this.affiliateSettingsTab == 'tax') {
        this.$v.$touch();
        isFormValid =
          !this.$v.form.affiliate_legal_name.$invalid &&
          !this.$v.form.affiliate_legal_address.$invalid &&
          !this.$v.form.affiliate_legal_city.$invalid &&
          !this.$v.form.affiliate_legal_state.$invalid &&
          !this.$v.form.affiliate_legal_country.$invalid &&
          !this.$v.form.affiliate_legal_zip_code.$invalid &&
          !this.$v.form.affiliate_tax_resident_type.$invalid &&
          !this.$v.form.affiliate_tax_id.$invalid &&
          !this.$v.form.affiliate_tax_form.$invalid;
      }
      if (isFormValid) {
        const self = this;
        self.$loader.start('saveAffiliateSettings');

        await self.form
          .post(`${window.config.apiUrl}/save-affiliate-settings`)
          .then(() => {
            self.$loader.end('saveAffiliateSettings');
            Notify.success(this.$t('frontend_affiliate_settings_settings_saved_successfully'));
          })
          .catch(() => {
            self.$loader.end('saveAffiliateSettings');
            Notify.fail(this.$t('frontend_affiliate_settings_something_went_wrong'));
          });
      }
    },
  },
};
</script>

<style>
.dropzone .dz-preview .dz-progress {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  height: 20px !important;
}
</style>
<style scoped>
#customdropzone {
  width: 100%;
}
</style>