var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selectedTransaction.transaction_retry_logs.length > 0)?_c('div',{staticClass:"flex flex-row w-full",attrs:{"id":"retries-list-view"}},[_c('v-client-table',{attrs:{"data":_vm.selectedTransaction.transaction_retry_logs,"columns":_vm.failedLogsColumns,"options":_vm.failedLogsTableOptions},scopedSlots:_vm._u([{key:"log",fn:function(props){return _c('div',{},[(props.row.description ==
                    'auto_retry_attempt_successful'
                    )?_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(_vm.$t( 'frontend_analytics_transactions_automatic_dunning' ))+" ")]):(props.row.description == 'auto_retry_attempt_failed'
                        )?_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(_vm.$t( 'frontend_analytics_transactions_automatic_dunning_retry' ))+" ")]):(props.row.description ==
                        'manual_retry_attempt_successful'
                        )?_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(_vm.$t('frontend_analytics_transactions_manual'))+" ")]):(props.row.description ==
                    'manual_retry_attempt_failed'
                    )?_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(_vm.$t( 'frontend_analytics_transactions_manual_dunnig_fail' ))+" ")]):_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(_vm.$t('frontend_analytics_initial_fail'))+" ")])])}},{key:"date",fn:function(props){return _c('div',{},[_c('div',{staticClass:"flex flex-row"},[_vm._v(" "+_vm._s(props.row.created_at_date)+" - "+_vm._s(props.row.created_at_time)+" ")])])}}],null,false,2100569874)})],1):_c('div',{staticClass:"flex flex-row w-full"},[_vm._v(" "+_vm._s(_vm.$t('frontend_analytics_no_dunning_attempts'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }