<template>
  <div>
    <div>
      <v-wait for="purchases">
        <div class="flex flex-col w-full bg-white rounded p-4">
          <div class="flex flex-col w-1/2 mb-2">
            <h4 class="font-semibold text-grey-700">
              {{ $t('frontend_groovesell_billing_setting') }}
            </h4>
          </div>
          <div class="flex flex-col w-1/2 mb-2">
            <span class="text-sm text-grey-700">
              {{ $t('frontend_groovesell_billing_sett_conf') }}
            </span>
          </div>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
            <gd-tabs :tabs="tabs" :current-tab="currentTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
              :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'" @onClick="handleTabChange" />
          </div>
          <div class="mt-2">
            <div v-if="currentTab === 'info'">
              <info ref="info" :access-level="accessLevel" :next-billing-date="nextBillingDate" />
            </div>

            <div v-else-if="currentTab === 'transactions'">
              <transactions ref="transactions" :purchases="purchases" @refresh="loadPurchases" />
            </div>
          </div>
        </div>
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import axios from 'axios';
import transactions from './Tabs/Transactions';
import info from './Tabs/Info';

const touchMap = new WeakMap();

export default {
  middleware: 'auth',
  layout: 'default',
  components: {
    info,
    transactions,
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      tabs: [
        {
          title: `<span class="text-sm">${this.$t('frontend_info')}<span>`,
          value: 'info'
        },
        {
          title: `<span class="text-sm">${this.$t('frontend_transactions')}<span>`,
          value: 'transactions'
        }
      ],
      currentTab: 'info',
      accessLevel: null,
      nextBillingDate: null,
      purchases: [],
      domLayout: 'autoHeight',
      complete: true,
    };
  },
  async created() {
    this.$wait.start('purchases');
    this.accessLevel = 'Free User';
    if (this.$store.getters.isPlatinumUser) {
      this.accessLevel = 'Platinum Lifetime User';
    } else if (this.$store.getters.isGroovePagesUser) {
      this.accessLevel = this.whitelabelAppData('pages').title + ' User';
    }
    await this.loadPurchases(false);
    this.$wait.end('purchases');
  },
  methods: {
    async loadPurchases(showLoader = true) {
      try {
        if (showLoader) this.$loader.start('loadPurchases');
        const { data } = await axios.get('/purchases');
        if (data.status == 'success') {
          this.purchases = data.purchases;
          this.$loader.end('loadPurchases');
        } else {
          this.alertProps = {
            show: true,
            variant: 'error',
            title: this.$t('frontend_error'),
            message: this.$t('frontend_analytics_billing_unable_load_pur'),
          };
        }
      } catch (e) {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_analytics_billing_unable_load_pur'),
        };
      }

      const nextBillingDateRequest = await axios.get('/get-next-transaction-date');
      const nextBillingData = nextBillingDateRequest.data;
      this.nextBillingDate = nextBillingData.status == 'success' ? nextBillingData.next_transaction_date : null;
      this.$loader.end('loadPurchases');
    },
    handleTabChange(newTab) {
      this.currentTab = newTab;
    },
  }
};
</script>
