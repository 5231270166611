<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-2">
          <h4 class="font-semibold text-grey-700">{{ $t('frontend_tracking_track_your_goals') }}</h4>
        </div>
        <div class="flex flex-col w-full mb-2">
          <span class="text-sm text-grey-700">{{ $t('frontend_tracking_groovesell_will', {appTitle: whitelabelAppData('sell').title}) }}</span>
        </div>
        <div class="flex flex-row w-full mb-6">
          <div class="flex flex-col w-1/5 mb-6 mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_tracking_type') }}</label>
            <gd-dropdown
              v-model="selectedGoalType"
              :options="goalTypesOptions"
            />
          </div>

          <div v-if="selectedGoalType == 1" class="flex flex-row w-3/5">
            <div class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }}</label>
              <text-input v-model="enteredGoalName" value="enteredGoalName" :placeholder="$t('frontend_name')" />
            </div>
            <div class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnel') }}</label>
              <gd-dropdown
                v-model="selectedFunnel"
                :options="funnelsOptions"
              />
            </div>
            <div v-if="selectedFunnel > 0" class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_product') }}</label>
              <gd-dropdown
                v-model="selectedProduct"
                :options="productsOptions"
              />
            </div>
            <div v-if="selectedFunnel > 0 && selectedProduct > 0" class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_tracking_pricepoint') }}</label>
              <gd-dropdown
                v-model="selectedPricePoint"
                :options="pricePointsOptions"
              />
            </div>
          </div>
          <div v-else-if="selectedGoalType == 2" class="flex flex-row w-3/5">
            <div class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }}</label>
              <text-input v-model="enteredGoalName" value="enteredGoalName" :placeholder="$t('frontend_name')" />
            </div>
            <div class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnel') }}</label>
              <gd-dropdown
                v-model="selectedAffiliateFunnel"
                :options="affiliateFunnelsOptions"
              />
            </div>
            <div v-if="selectedAffiliateFunnel > 0" class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_product') }}</label>
              <gd-dropdown
                v-model="selectedAffiliateProduct"
                :options="affiliateProductsOptions"
              />
            </div>
            <div v-if="selectedAffiliateFunnel > 0 && selectedAffiliateProduct > 0" class="flex flex-col w-1/4 mb-6 mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_price_point') }}</label>
              <gd-dropdown
                v-model="selectedAffiliatePricePoint"
                :options="affiliatePricePointsOptions"
              />
            </div>
          </div>
          <div v-else class="flex flex-row w-3/5"></div>

          <div class="flex flex-row justify-end w-1/5">
            <div v-if="editMode">
              <gd-button
                v-if="selectedGoalType > 0 && enteredGoalName != ''"
                size="auto"
                variant="primary"
                class="ml-4 mt-8"
                @click="updateGoal"
              >
                {{ $t('frontend_tracking_any_update') }}
              </gd-button>
              <gd-button v-else size="auto" disabled class="ml-4 mt-8">{{ $t('frontend_tracking_any_update') }}</gd-button>
            </div>
            <div v-else>
              <gd-button v-if="selectedGoalType > 0 && enteredGoalName != ''" size="auto" variant="primary" class="ml-4 mt-8" @click="addGoal">
                {{ $t('frontend_tracking_any_add') }}
              </gd-button>
              <gd-button v-else size="auto" disabled class="ml-4 mt-8">{{ $t('frontend_tracking_any_add') }}</gd-button>
            </div>
          </div>
        </div>
        <hr class="border-grey-100 -mx-4" />

        <div class="flex flex-col w-full mb-6">
          <div v-if="localModel.goals.length == 0" class="flex flex-row w-full mt-4">{{ $t('frontend_tracking_you_currently') }}</div>
          <div v-else class="flex flex-row w-full">
            <div id="goals-list-view">
              <v-client-table
                ref="goalsTable"
                :data="localModel.goals"
                :columns="goalsTableColumns"
                :options="goalsTableOptions"
                class="bg-white text-center"
              >
                <div v-if="props.row.type == 1" slot="event_type" slot-scope="props">{{ $t('frontend_tracking_purchase') }}</div>
                <div v-else-if="props.row.type == 2" slot="event_type" slot-scope="props">{{ $t('frontend_tracking_affiliate_sale') }}</div>
                <div slot="event_description" slot-scope="props">
                  <div v-if="props.row.type == 1">
                    <p v-if="props.row.funnel_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_funnel') }}: </span>
                      <span>{{ funnelName(props.row.funnel_id) }}</span>
                    </p>
                    <p v-else>
                      <span class="font-semibold">{{ $t('frontend_tracking_funnel') }}: </span>
                      <span>{{ $t('frontend_tracking_any_funnel') }}</span>
                    </p>

                    <p v-if="props.row.funnel_id && props.row.product_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_product') }}: </span>
                      <span>
                        {{ productName(props.row.funnel_id, props.row.product_id) }}
                      </span>
                    </p>
                    <p v-else-if="props.row.funnel_id && !props.row.product_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_product') }}: </span>
                      <span>{{ $t('frontend_tracking_any_product') }}</span>
                    </p>

                    <p v-if="props.row.funnel_id && props.row.product_id && props.row.price_point_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_pricepoint') }}: </span>
                      <span>
                        {{ pricepointName(props.row.funnel_id, props.row.product_id, props.row.price_point_id) }}
                      </span>
                    </p>
                    <p v-else-if="props.row.funnel_id && props.row.product_id && !props.row.price_point_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_pricepoint') }}: </span>
                      <span>{{ $t('frontend_tracking_any_pricepoint') }}</span>
                    </p>
                  </div>
                  <div v-else-if="props.row.type == 2">
                    <p v-if="props.row.funnel_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_funnel') }}: </span>
                      <span>
                        {{ affiliateFunnelName(props.row.funnel_id) }}
                      </span>
                    </p>
                    <p v-else>
                      <span class="font-semibold">{{ $t('frontend_tracking_funnel') }}: </span>
                      <span>{{ $t('frontend_tracking_any_funnel') }}</span>
                    </p>

                    <p v-if="props.row.funnel_id && props.row.product_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_product') }}: </span>
                      <span>
                        {{ affiliateProductName(props.row.funnel_id, props.row.product_id) }}
                      </span>
                    </p>
                    <p v-else-if="props.row.funnel_id && !props.row.product_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_product') }}: </span>
                      <span>{{ $t('frontend_tracking_any_product') }}</span>
                    </p>

                    <p v-if="props.row.funnel_id && props.row.product_id && props.row.price_point_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_pricepoint') }}: </span>
                      <span>
                        {{ affiliatePricepointName(props.row.funnel_id, props.row.product_id, props.row.price_point_id) }}
                      </span>
                    </p>
                    <p v-else-if="props.row.funnel_id && props.row.product_id && !props.row.price_point_id">
                      <span class="font-semibold">{{ $t('frontend_tracking_pricepoint') }}: </span>
                      <span>{{ $t('frontend_tracking_any_pricepoint') }}</span>
                    </p>
                  </div>
                </div>

                <div slot="actions" slot-scope="props">
                  <div class="flex flex-row">
                    <gd-button variant="primary" size="icon" class="mr-2" @click="editGoal(props.row.id)">
                      <pencil-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                    <gd-button variant="primary" size="icon" class="mr-2" @click="deleteGoal(props.row.id)">
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>
              </v-client-table>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import generateNumberMixin from '@/mixins/generateNumberMixin';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  name: 'GoalsTab',
  mixins: [generateNumberMixin],
  components: {
    PencilIcon,
    TrashIcon,
  },
  props: {
    model: {
      required: true
    },
    funnels: {
      required: true
    },
    affiliateFunnels: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
      products: null,
      affiliateProducts: null,
      pricePoints: null,
      affiliatePricePoints: null,
      selectedGoalType: 0,
      enteredGoalName: '',
      selectedFunnel: 0,
      selectedProduct: 0,
      selectedPricePoint: 0,
      selectedAffiliateFunnel: 0,
      selectedAffiliateProduct: 0,
      selectedAffiliatePricePoint: 0,
      selectedGoal: null,
      editMode: false,
      goalsTableColumns: ['name', 'event_type', 'event_description', 'actions'],
      goalsTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [],
         headings: {
          name: this.$t('frontend_name'),
          event_type: this.$t('frontend_groovesell_page_tracking_setp3_table_event_type'),
          event_description: this.$t('frontend_groovesell_page_tracking_setp3_table_event_description'),
          actions: this.$t('frontend_groovesell_page_tracking_table_actions')
         },
        filterByColumn: false,
        filterable: false,
        perPage: 10000
      }
    };
  },
  computed: {
    goalTypesOptions() {
      return [
        {value: 0, text: this.$t('frontend_tracking_select')},
        {value: 1, text: this.$t('frontend_tracking_purchase')},
        {value: 2, text: this.$t('frontend_tracking_affiliate_sale')},
      ];
    },
    funnelsOptions() {
      return [
        {value: 0, text: this.$t('frontend_tracking_any_funnel')},
        ...this.funnels.map(funnel => {
          return {
            value: funnel.id,
            text: funnel.name
          };
        })
      ];
    },
    productsOptions() {
      if (!this.products) {
        return [
          {value: 0, text: this.$t('frontend_tracking_any_product')}
        ];
      }

      return [
        {value: 0, text: this.$t('frontend_tracking_any_product')},
        ...this.products.map(product => {
          return {
            value: product.id,
            text: product.name
          };
        })
      ];
    },
    pricePointsOptions() {
      if (!this.pricePoints) {
        return [
          {value: 0, text: this.$t('frontend_tracking_any_pricepoint')}
        ];
      }

      return [
        {value: 0, text: this.$t('frontend_tracking_any_pricepoint')},
        ...this.pricePoints.map(pricePoint => {
          return {
            value: pricePoint.id,
            text: pricePoint.name
          };
        })
      ];
    },
    affiliateFunnelsOptions() {
      if (!this.affiliateFunnels) {
        return [
          {value: 0, text: this.$t('frontend_tracking_any_funnel')}
        ];
      }

      return [
        {value: 0, text: this.$t('frontend_tracking_any_funnel')},
        ...this.affiliateFunnels.map(funnel => {
          return {
            value: funnel.id,
            text: funnel.name
          };
        })
      ];
    },
    affiliateProductsOptions() {
      if (!this.affiliateProducts) {
        return [
          {value: 0, text: this.$t('frontend_tracking_any_product')}
        ];
      }

      return [
        {value: 0, text: this.$t('frontend_tracking_any_product')},
        ...this.affiliateProducts.map(product => {
          return {
            value: product.id,
            text: product.name
          };
        })
      ];
    },
    affiliatePricePointsOptions() {
      if (!this.affiliatePricePoints) {
        return [
          {value: 0, text: this.$t('frontend_tracking_any_pricepoint')}
        ];
      }

      return [
        {value: 0, text: this.$t('frontend_tracking_any_pricepoint')},
        ...this.affiliatePricePoints.map(pricePoint => {
          return {
            value: pricePoint.id,
            text: pricePoint.name
          };
        })
      ];
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
    selectedFunnel() {
      if (this.selectedFunnel == 0) {
        this.products == null;
      } else {
        this.$loader.start('selectedFunnel');
        const selectedFunnel = this.funnels.find(funnel => funnel.id === this.selectedFunnel);
        this.products = selectedFunnel.products;
        this.$loader.end('selectedFunnel');
      }
    },
    selectedAffiliateFunnel() {
      if (this.selectedAffiliateFunnel == 0) {
        this.affiliateProducts == null;
      } else {
        this.$loader.start('selectedAffiliateFunnel');

        const selectedFunnel = this.affiliateFunnels.find(funnel => funnel.id === this.selectedAffiliateFunnel);

        this.affiliateProducts = selectedFunnel.products;
        this.$loader.end('selectedAffiliateFunnel');
      }
    },
    selectedProduct() {
      if (this.selectedProduct == 0) {
        this.pricePoints == null;
      } else {
        this.$loader.start('selectedProduct');
        const selectedFunnel = this.funnels.find(funnel => funnel.id === this.selectedFunnel);
        const selectedProduct = selectedFunnel.products.find(product => product.id === this.selectedProduct);
        this.pricePoints = selectedProduct.price_points;
        this.$loader.end('selectedProduct');
      }
    },
    selectedAffiliateProduct() {
      if (this.selectedAffiliateProduct == 0) {
        this.affiliatePricePoints == null;
      } else {
        this.$loader.start('selectedAffiliateProduct');
        const selectedFunnel = this.affiliateFunnels.find(funnel => funnel.id === this.selectedAffiliateFunnel);
        const selectedProduct = selectedFunnel.products.find(product => product.id === this.selectedAffiliateProduct);
        this.affiliatePricePoints = selectedProduct.price_points;
        this.$loader.end('selectedAffiliateProduct');
      }
    }
  },
  methods: {
    validate() {
      this.$emit('on-validate', true);
    },
    funnelName(id) {
      const funnel = this.funnels.find(funnel => funnel.id === id);

      return funnel.name;
    },
    productName(funnelid, id) {
      const funnel = this.funnels.find(funnel => funnel.id === funnelid);
      const product = funnel.products.find(product => product.id === id);

      return product.name;
    },
    pricepointName(funnelid, productid, id) {
      const funnel = this.funnels.find(funnel => funnel.id === funnelid);
      const product = funnel.products.find(product => product.id === productid);
      const pricepoint = product.price_points.find(pricePoint => pricePoint.id === id);

      return pricepoint.name;
    },
    affiliateFunnelName(id) {
      const funnel = this.affiliateFunnels.find(funnel => funnel.id === id);

      return funnel.name;
    },
    affiliateProductName(funnelid, id) {
      const funnel = this.affiliateFunnels.find(funnel => funnel.id === funnelid);
      const product = funnel.products.find(product => product.id === id);

      return product.name;
    },
    affiliatePricepointName(funnelid, productid, id) {
      const funnel = this.affiliateFunnels.find(funnel => funnel.id === funnelid);
      const product = funnel.products.find(product => product.id === productid);
      const pricepoint = product.price_points.find(pricePoint => pricePoint.id === id);

      return pricepoint.name;
    },
    addGoal() {
      let newGoal = {
        id: this.generateNumber(),
        name: this.enteredGoalName,
        type: this.selectedGoalType
      };

      if (this.selectedGoalType == 1) {
        newGoal.funnel_id = this.selectedFunnel;
        newGoal.product_id = this.selectedProduct;
        newGoal.price_point_id = this.selectedPricePoint;

        this.resetStandardSelectedProperties();
      } else if (this.selectedGoalType == 2) {
        newGoal.funnel_id = this.selectedAffiliateFunnel;
        newGoal.product_id = this.selectedAffiliateProduct;
        newGoal.price_point_id = this.selectedAffiliatePricePoint;

        this.resetAffiliateSelectedProperties();
      }

      this.localModel.goals.push(newGoal);

      this.enteredGoalName = '';
      this.selectedGoalType = 0;
    },
    editGoal(goalId) {
      this.selectedGoal = this.localModel.goals.find(goal => goal.id === goalId);
      this.selectedGoalType = this.selectedGoal.type;
      this.enteredGoalName = this.selectedGoal.name;
      this.editMode = true;

      if (this.selectedGoalType == 1) {
        this.assignStandardGoalProperties();
      } else {
        this.assignAffiliateGoalProperties();
      }
    },
    assignStandardGoalProperties() {
      this.selectedFunnel = this.selectedGoal.funnel_id;
      this.selectedProduct = this.selectedGoal.product_id;
      this.selectedPricePoint = this.selectedGoal.price_point_id;
    },
    assignAffiliateGoalProperties() {
      this.selectedAffiliateFunnel = this.selectedGoal.funnel_id;
      this.selectedAffiliateProduct = this.selectedGoal.product_id;
      this.selectedAffiliatePricePoint = this.selectedGoal.price_point_id;
    },
    deleteGoal(goalId) {
      const selectedGoalIndex = this.localModel.goals.findIndex(goal => goal.id === goalId);
      this.localModel.goals.splice(selectedGoalIndex, 1);
    },
    updateGoal() {
      const selectedGoalIndex = this.localModel.goals.findIndex(goal => goal.id === this.selectedGoal.id);

      if (this.selectedGoalType == 1) {
        this.updateStandardGoalProperties(selectedGoalIndex);
        this.resetStandardSelectedProperties();
      } else if (this.selectedGoalType == 2) {
        this.updateAffiliateGoalProperties(selectedGoalIndex);
        this.resetAffiliateSelectedProperties();
      }

      // Update common properties for both types
      this.localModel.goals[selectedGoalIndex].name = this.enteredGoalName;
      this.localModel.goals[selectedGoalIndex].type = this.selectedGoalType;

      this.selectedGoalType = 0;
      this.editMode = false;
    },
    updateStandardGoalProperties(index) {
      this.localModel.goals[index].funnel_id = this.selectedFunnel;
      this.localModel.goals[index].product_id = this.selectedProduct;
      this.localModel.goals[index].price_point_id = this.selectedPricePoint;
    },
    updateAffiliateGoalProperties(index) {
      this.localModel.goals[index].funnel_id = this.selectedAffiliateFunnel;
      this.localModel.goals[index].product_id = this.selectedAffiliateProduct;
      this.localModel.goals[index].price_point_id = this.selectedAffiliatePricePoint;
    },
    resetStandardSelectedProperties() {
      this.selectedFunnel = 0;
      this.selectedProduct = 0;
      this.selectedPricePoint = 0;
    },
    resetAffiliateSelectedProperties() {
      this.selectedAffiliateFunnel = 0;
      this.selectedAffiliateProduct = 0;
      this.selectedAffiliatePricePoint = 0;
    }
  }
};
</script>

<style lang="scss">
#goals-list-view {
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>
