<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col flex-wrap w-full">
        <div class="flex flex-row w-full mb-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }} : </label>
          <label class="text-sm text-grey-700 ml-2">{{ $store.state.user.name }}</label>
        </div>
        <div class="flex flex-row w-full mb-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_billing_email') }} :
          </label>
          <label class="text-sm text-grey-700 ml-2">{{ $store.state.user.email }}</label>
        </div>
        <div class="flex flex-row w-full mb-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_billing_access_level') }} :
          </label>
          <label class="text-sm text-grey-700 ml-2">{{ accessLevel }}</label>
        </div>
        <div v-if="nextBillingDate" class="flex flex-row w-full mb-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_groovesell_billing_next_billing_date') }}:
          </label>
          <label class="text-sm text-grey-700 ml-2">{{ nextBillingDate }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Info',
  props: {
    accessLevel: {
      required: true
    },
    nextBillingDate: {
      required: true
    }
  },
};
</script>
