var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col w-full bg-white rounded px-2"},[_c('div',[_c('div',{staticClass:"flex flex-row w-full mt-2"},[_c('div',{staticClass:"flex flex-row flex-wrap w-11/12 mt-8"},_vm._l((_vm.localModel.form_fields),function(formField,index){return _c('div',{key:index,staticClass:"w-1/4 flex flex-col mb-4"},[_c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-1/5 flex flex-row"},[_c('gd-checkbox',{attrs:{"variant":index < 4 ? 'base': 'primary',"disabled":index < 4 ? true : false},model:{value:(formField.enabled),callback:function ($$v) {_vm.$set(formField, "enabled", $$v)},expression:"formField.enabled"}})],1),_c('div',{staticClass:"w-4/5 flex flex-row items-center mb-2"},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v(" "+_vm._s(formField.label)+" ")]),(formField.type == 'custom')?_c('div',{staticClass:"text-red cursor-pointer",on:{"click":function($event){return _vm.openConfirmModal(index)}}},[_c('trash-icon',{staticClass:"w-4 h-4 fill-current"})],1):_vm._e()])]),_c('div',{staticClass:"w-full flex",class:{
              'invisible': formField.type == 'fname' ||
                formField.type == 'lname' ||
                formField.type == 'email' ||
                formField.type == 'countryAndState' ||
                formField.type == 'address' ||
                formField.type == 'cityAndPostalCode' ||
                formField.enabled == false
            }},[_c('div',{staticClass:"w-1/5 flex flex-row"},[_c('gd-toggle',{attrs:{"value":formField.mandatory,"disable":true},model:{value:(formField.mandatory),callback:function ($$v) {_vm.$set(formField, "mandatory", $$v)},expression:"formField.mandatory"}})],1),_vm._m(0,true)])])}),0),_c('div',{staticClass:"w-2/12 flex flex-row-reverse"},[_c('gd-button',{staticClass:"ml-4",attrs:{"variant":"outline","size":"auto"},on:{"click":_vm.openCustomFieldModal}},[_vm._v("+ "+_vm._s(_vm.$t('frontend_product_step5_add_custom')))])],1)])])]),_c('gd-modal',{attrs:{"name":"create-custom-field-modal","title":_vm.$t('frontend_groovesell_add_new_custom_field')}},[_c('div',{staticClass:"flex flex-col m-10"},[_c('div',{staticClass:"flex flex-row w-full mb-6"},[_c('label',{staticClass:"w-1/4 text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step5_field_name')))]),_c('text-input',{staticClass:"w-3/4",attrs:{"value":"customFieldName","placeholder":_vm.$t('frontend_product_step5_field_name')},model:{value:(_vm.customFieldName),callback:function ($$v) {_vm.customFieldName=$$v},expression:"customFieldName"}})],1),_c('div',{staticClass:"flex flex-row w-full mb-6 justify-end"},[_c('gd-button',{attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.addCustomField}},[_vm._v(_vm._s(_vm.$t('frontend_save')))])],1)])]),_c('confirm-modal',{attrs:{"name":"confirm-remove-formfield-modal","message-content":_vm.$t('frontend_non_revertable'),"button-text":_vm.$t('frontend_delete_confirm'),"button-size":"auto"},on:{"confirm":_vm.removeFormField}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-4/5 flex flex-row"},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v(" Mandatory? ")])])
}]

export { render, staticRenderFns }