import AffiliatesRoutes from './routes/affiliates';
import AnalyticsRoutes from './routes/analytics';
import AuthRoutes from './routes/auth';
import CouponsRoutes from './routes/coupons';
import FunnelRoutes from './routes/funnels';
import MarketplaceRoutes from './routes/marketplace';
import OtherRoutes from './routes/others';
import PartnersRoutes from './routes/partners';
import ProductsRoutes from './routes/products';
import ResourcesRoutes from './routes/resources';
import TrackingRoutes from './routes/tracking';

export default [
  ...AffiliatesRoutes,
  ...AnalyticsRoutes,
  ...AuthRoutes,
  ...CouponsRoutes,
  ...FunnelRoutes,
  ...MarketplaceRoutes,
  ...OtherRoutes,
  ...PartnersRoutes,
  ...ProductsRoutes,
  ...ResourcesRoutes,
  ...TrackingRoutes
];
