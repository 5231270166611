<template>
    <div>
        <div class="flex flex-row flex-wrap w-full py-4 px-4">
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_analytics_product_name')
                }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.product_name }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_analytics_price_point')
                }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.price_point_name }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_analytics_gateway')
                }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.gateway }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_transaction_id_gateway') }})</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.gateway_transaction_id }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_date_and_time') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.formatted_date_time }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_refund_period') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.product_refund_period }} {{
                    $t('frontend_analytics_days') }}</label>
            </div>

            <div v-if="purchase.recurring_billing" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_recurring_bill') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.recurring_billing }}</label>
            </div>
            <div v-else class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_recurring_bill') }}</label>
                <label class="text-sm text-grey-700 mb-2">N/A</label>
            </div>

            <div v-if="purchase.recurring_billing && purchase.payments_left && purchase.payments_left != 99999999999"
                class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_payments_left') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.payments_left }}</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        purchase: {
            required: true
        },
    }
}
</script>