<template>
    <gd-modal name="advanced-filters-modal" :title="$t('frontend_groovesell_modal_advanced_filters')" width="1080px"
        bg-type="regular">
        <div class="flex flex-col m-6 mb-4" style="min-height: 410px;">
            <div class="flex flex-row w-1/2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mr-2">{{ $t('frontend_only_comm_due') }}</label>
                <gd-toggle v-model="filters.filteredDueCommissionsType" :value="filteredDueCommissionsType" />
            </div>
            <div v-if="filteredDueCommissionsType" class="flex flex-col w-full mt-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_due_comm_more_than') }}</label>
                <gd-currency-input v-model="filters.filteredDueAmount" currency-code="USD" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_earn_comm_more_than') }}</label>
                <gd-currency-input v-model="filters.filteredCommissionsAmount" currency-code="USD" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_no_of_sale_more_than') }}</label>
                <text-input v-model="filters.filteredNumberOfSales" :attribute="$t('frontend_sales')" value="filteredNumberOfSales"
                    :placeholder="$t('frontend_sales')" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_gen_rev_more_than') }}</label>
                <gd-currency-input v-model="filters.filteredSalesAmount" currency-code="USD" />
            </div>
            <div class="flex flex-row w-full mt-6 justify-end">
                <gd-button size="md" variant="primary" @click="applyAdvancedFilters">{{
                    $t('frontend_apply_filter') }}</gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        filteredDueCommissionsType: {
            type: Boolean,
            default: false
        },
        filteredDueAmount: {
            type: Number,
            default: 0
        },
        filteredCommissionsAmount: {
            type: Number,
            default: 0
        },
        filteredNumberOfSales: {
            default: 0
        },
        filteredSalesAmount: {
            type: Number,
            default: 0
        },
        applyAdvancedFilters: {
            type: Function,
            default: null
        }
    },
    methods: {
        clearFilters() {
            this.filters = {
                filteredDueCommissionsType: false,
                filteredDueAmount: 0,
                filteredCommissionsAmount: 0,
                filteredNumberOfSales: 0,
                filteredSalesAmount: 0
            }
        }
    },
    data() {
        return {
            filters: {
                filteredDueCommissionsType: this.filteredDueCommissionsType,
                filteredDueAmount: this.filteredDueAmount,
                filteredCommissionsAmount: this.filteredCommissionsAmount,
                filteredNumberOfSales: this.filteredNumberOfSales,
                filteredSalesAmount: this.filteredSalesAmount
            }
        }
    },
    watch: {
        filters: {
            handler: function (val, oldVal) {
                this.$emit('update-filters', val);
            },
            deep: true
        }
    }
}

</script>
