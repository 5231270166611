<template>
  <gd-modal
    name="affiliates-second-tier-export-modal"
    :title="$t('frontend_groovesell_export_affiliates_payment_info')"
    width="80%"
    bg-type="regular"
  >
    <div class="flex">
      <!-- Left Section -->
      <div class="flex flex-col w-2/3 px-4">
        <div class="mt-2">
          <!-- Radio Options -->
          <div class="flex flex-row w-full mb-6 p-4 justify-center">
            <div class="w-1/3 mr-4">
              <font-awesome-radio-with-count
                v-model="exportSecondTierAffiliates.gatewayType"
                option-name="Paypal"
                option-value="1"
                :option-enabled="exportSecondTierAffiliatesResponse.paypal.count > 0"
                :option-count="exportSecondTierAffiliatesResponse.paypal.count"
              >
                <template slot="icon">
                    <paypal-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio-with-count>
            </div>
            <div class="w-1/3 mr-4">
              <font-awesome-radio-with-count
                v-model="exportSecondTierAffiliates.gatewayType"
                option-name="Check"
                option-value="2"
                :option-enabled="exportSecondTierAffiliatesResponse.check.count > 0"
                :option-count="exportSecondTierAffiliatesResponse.check.count"
              >
                <template slot="icon">
                    <credit-card-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio-with-count>
            </div>
            <div class="w-1/3">
              <font-awesome-radio-with-count
                v-model="exportSecondTierAffiliates.gatewayType"
                option-name="Wire Info"
                option-value="3"
                :option-enabled="exportSecondTierAffiliatesResponse.wire.count > 0"
                :option-count="exportSecondTierAffiliatesResponse.wire.count"
              >
                <template slot="icon">
                    <bank-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio-with-count>
            </div>
          </div>

          <!-- Details for Selected Radio Option -->
          <div v-if="exportSecondTierAffiliates.gatewayType != ''" class="flex flex-row w-full mb-4">
            <div v-if="exportSecondTierAffiliates.gatewayType == '1'" class="w-full flex flex-col">
              <!-- PayPal Details -->
              <h4 class="font-semibold text-grey-700">{{ $t('frontend_affiliate_index_paypal') }}</h4>
              <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-4">
                  {{ $t('frontend_affiliate_index_no_of_affiliates') }} :
                  <span class="text-red">{{ exportSecondTierAffiliatesResponse.paypal.count }}</span>
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_total_amount_due') }}
                  {{ exportSecondTierAffiliatesResponse.total.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-8">
                  {{ exportSecondTierAffiliatesResponse.total.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_on_hold') }}
                  {{ exportSecondTierAffiliatesResponse.on_hold.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-8">
                  {{ exportSecondTierAffiliatesResponse.on_hold.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_total_eligible') }}
                  {{ exportSecondTierAffiliatesResponse.paypal.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-2">
                  {{ exportSecondTierAffiliatesResponse.paypal.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
              </div>
            </div>
            <div v-else-if="exportSecondTierAffiliates.gatewayType == '2'">
              <!-- Check Details -->
              <h4 class="font-semibold text-grey-700">
                {{ $t('frontend_affiliate_index_check') }}
              </h4>
              <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_no_of_affiliates_who') }}
                  <span class="text-red">
                    {{ exportSecondTierAffiliatesResponse.check.count }}
                  </span>
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_total_amount') }}
                  <span class="text-red">
                    {{ exportSecondTierAffiliatesResponse.check.amount }}
                  </span>
                </label>
              </div>
            </div>
            <div v-else-if="exportSecondTierAffiliates.gatewayType == '3'" class="w-full flex flex-col">
              <!-- Wire Details -->
              <h4 class="font-semibold text-grey-700">Wire</h4>
              <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-4">
                  {{ $t('frontend_affiliate_index_no_of_affiliates_who_added_wire') }}
                  <span class="text-red">
                    {{ exportSecondTierAffiliatesResponse.wire.count }}
                  </span>
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_total_amount_due') }}
                  {{ exportSecondTierAffiliatesResponse.total.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-8">
                  {{ exportSecondTierAffiliatesResponse.total.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_on_hold') }}
                  {{ exportSecondTierAffiliatesResponse.on_hold.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-8">
                  {{ exportSecondTierAffiliatesResponse.on_hold.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_total_eligible') }}
                  {{ exportSecondTierAffiliatesResponse.wire.amount }}
                </label>
                <label class="text-red font-semibold text-sm mb-2">
                  {{ exportSecondTierAffiliatesResponse.wire.count }}
                  {{ $t('frontend_affiliate_index_affiliates') }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Section -->
      <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
        <div class="flex flex-col">
          <div class="flex">
            <label class="font-semibold text-grey-700 mb-2">
              {{ $t('frontend_affiliate_index_mark_as') }}
            </label>
          </div>
          <!-- Payment Type Radio Options -->
          <div v-for="(exportAffiliatePaymentType, index) in exportSecondTierAffiliatePaymentTypes" :key="index" class="flex">
            <gd-custom-radio v-model="exportSecondTierAffiliates.typeOfPayment" :option="exportAffiliatePaymentType"></gd-custom-radio>
          </div>
          <!-- Buttons Section -->
          <div class="mt-2 flex flex-col justify-center">
            <gd-button v-if="exportSecondTierAffiliates.gatewayType != ''" size="auto" variant="primary" class="mb-4 mr-4" @click="exportSecondTierAffiliateDetails">
              {{ $t('frontend_affiliates_export_affiliates') }}
            </gd-button>
            <gd-button v-else size="auto" disabled class="mb-4 mr-4">
              {{ $t('frontend_affiliates_export_affiliates') }}
            </gd-button>
            <gd-button v-if="exportSecondTierAffiliates.gatewayType == '1'" size="auto" variant="primary" class="mb-4 mr-4" @click="exportSecondTierPaypalMasspay">
              {{ $t('frontend_affiliate_index_export_paypal_masspay') }}
            </gd-button>
          </div>
        </div>
      </div>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </gd-modal>
</template>

<script>
import axios from 'axios';
import createLinkMixin from '@/mixins/createLinkMixin';
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';

export default {
  components: {
    PaypalIcon,
    CreditCardIcon,
    BankIcon,
  },
  mixins: [createLinkMixin],
  props: {
    exportSecondTierAffiliatesResponse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alertProps: {
        variant: 'success',
        title: '',
        message: '',
        show: false,
      },
      exportSecondTierAffiliates: {
        gatewayType: '',
        typeOfPayment: 2,
      },
      exportSecondTierAffiliatePaymentTypes: [
        { id: 2, title: this.$t('frontend_paid'), description: '' },
        { id: 3, title: this.$t('frontend_groovesell_paid_after_24'), description: '' },
        { id: 4, title: this.$t('frontend_groovesell_paid_after_48'), description: '' },
        { id: 1, title: this.$t('frontend_in_process'), description: '' },
        { id: 5, title: this.$t('unchanged'), description: '' },
      ],
    };
  },
  methods: {
    async exportSecondTierDetails(exportType = '') {
      this.$loader.start('exportSecondTierDetails');

      const payload = {
        funnel: this.filtersModel.selectedFunnel,
        hash: this.exportSecondTierAffiliatesResponse.hash,
        type: this.exportSecondTierAffiliates.typeOfPayment,
        exportType,
      };

      const downloadLinkData = await axios.post('/process-exported-affiliate-second-tier-transactions', payload);

      if (downloadLinkData.data.status == 'processed') {
        this.openLink(downloadLinkData.data.download_link, '_self');
        this.unselectAllAffiliates();
        this.$loader.end('exportSecondTierDetails');
        this.$root.$emit('modal-close', 'affiliates-second-tier-export-modal');
      } else {
        this.getSecondTierStatus(downloadLinkData.data.payment_export_id);
        this.$loader.end('exportSecondTierDetails');
        this.alertProps = {
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc'),
          show: true,
        };
      }
    },
    exportSecondTierAffiliateDetails() {
      const gatewayType = this.exportSecondTierAffiliates.gatewayType;

      if (gatewayType == '1') {
        this.exportSecondTierDetails('paypal');
      } else if (gatewayType == '3') {
        this.exportSecondTierDetails('wire');
      }
    },
    exportSecondTierPaypalMasspay() {
      this.exportSecondTierDetails('paypal_masspay');
    },
    getSecondTierStatus(affiliateSecondTierPaymentExportId) {
      this.$emit('getSecondTierStatus', affiliateSecondTierPaymentExportId);
    },
    unselectAllAffiliates() {
      this.$emit('unselectAllAffiliates');
    },
  },
};
</script>
