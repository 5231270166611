<template>
  <div>
    <v-wait for="dashboard">
      <!-- ROW 1 -->
      <div class="flex mb-4 justify-end">
        <div class="flex justify-end">
            <date-range-picker
              ref="picker"
              v-model="dashboardDateRange"
              :opens="opens"
              :auto-apply="autoApply"
              :max-date="maxDateForFilter"
              :start-date="customStartDate"
              :end-date="customEndDate"
              :ranges="customDateRanges"
            >
              <div slot="input" slot-scope="picker">
                {{ picker.startDate | moment('MM/DD/YYYY') }} -
                {{ picker.endDate | moment('MM/DD/YYYY') }}
              </div>
            </date-range-picker>

          <gd-button variant="primary" @click="fetchDashboard">{{ $t('frontend_submit') }}</gd-button>
        </div>
      </div>

      <!-- ROW 2 -->
      <div class="flex flex-row space-x-5">
        <div class="w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
          <dashboard-card>
            <template slot="header">
              {{ $t('frontend_impressions') }}
            </template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ impressions | numFormat }}
              </div>
            </template>
            <template slot="footer"> </template>
          </dashboard-card>
        </div>
        <div class="w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
          <dashboard-card>
            <template slot="header">
              {{ $t('frontend_uniques') }}
            </template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ uniques | numFormat }}
              </div>
            </template>
            <template slot="footer"> </template>
          </dashboard-card>
        </div>
        <div class="w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
          <dashboard-card>
            <template slot="header">
              {{ $t('frontend_signups') }}
            </template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ optins | numFormat }}
              </div>
            </template>
            <template slot="footer"> </template>
          </dashboard-card>
        </div>
        <div class="w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
          <dashboard-card>
            <template slot="header">
              {{ $t('frontend_affiliate_index_sales') }}
            </template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ sales | numFormat }}
              </div>
            </template>
            <template slot="footer"> </template>
          </dashboard-card>
        </div>
        <div class="w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
          <dashboard-card>
            <template slot="header">
              {{ $t('frontend_revenue') }}
            </template>
            <template slot="content">
              <div class="w-full h-full relative text-4xl font-semibold">
                {{ revenue | currency }}
              </div>
            </template>
            <template slot="footer"> </template>
          </dashboard-card>
        </div>
      </div>

      <div class="flex flex-col bg-white mt-16 rounded">
        <div class="flex flex-row mb-2 p-4">
          <div class="w-full">
            <h4 class="font-semibold text-grey-700">{{ $t('frontend_goals') }}</h4>
          </div>
        </div>
        <div class="flex flex-row border-grey-100 border">
          <div id="stats-list-view" class="w-full">
            <v-client-table
              ref="goalsTable"
              :data="goals"
              :columns="goalsTableColumns"
              :options="goalsTableOptions"
              class="bg-white text-center rounded"
            >
              <div slot="optins" slot-scope="props">
                {{ props.row.optins | numFormat }}
              </div>
              <div slot="sales" slot-scope="props">
                {{ props.row.sales | numFormat }}
              </div>
              <div slot="sales_amount" slot-scope="props">
                {{ props.row.sales_amount | currency }}
              </div>
            </v-client-table>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  middleware: 'auth',
  data() {
    return {
      dashboardDateRange: {
        startDate: moment(),
        endDate: moment()
      },
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week')
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year')
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()]
      },
      maxDateForFilter: new Date(),
      impressions: 0,
      uniques: 0,
      optins: 0,
      sales: 0,
      revenue: 0,
      opens: 'left',
      autoApply: true,
      goals: [],
      link_id: this.$route.params.link_id,
      goalsTableColumns: ['name', 'optins', 'sales', 'sales_amount'],
      goalsTableOptions: {
        sortable: [],
        headings: {
          name: this.$t('frontend_name'),
          optins: this.$t('frontend_groovesell_page_tracking_stats_table_optins'),
          sales: this.$t('frontend_groovesell_page_tracking_stats_table_sales'),
          sales_amount: this.$t('frontend_groovesell_page_tracking_stats_table_revenue')
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      },
    };
  },
  metaInfo() {
    return { title: this.$t('frontend_groovesell_routes_stats') };
  },
  async created() {
    this.$wait.start('dashboard');
    const { data } = await axios.get(
      'tracking-links/' + this.link_id + '/stats'
    );

    this.updateStats(data.data);

    this.$wait.end('dashboard');
  },
  methods: {
    async fetchDashboard() {
      this.$wait.start('dashboard');
      let dashboardData = await axios.get(
        'tracking-links/' + this.link_id + '/stats',
        {
          params: {
            fromDate: moment(this.dashboardDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(this.dashboardDateRange.endDate).format('MM/DD/YYYY')
          }
        }
      );

      this.updateStats(dashboardData.data.data);

      this.$wait.end('dashboard');
    },
    updateStats(data) {
      this.impressions = data.impressions;
      this.uniques = data.uniques;
      this.optins = data.optins;
      this.sales = data.sales;
      this.revenue = data.sales_amount;
      this.goals = data.goals;
    }
  }
};
</script>

<style>
.reportrange-text {
  color: inherit;
  position: relative;
  padding: 0.4rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>

<style scoped>
.vue-daterange-picker {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
</style>

<style lang="scss">
#stats-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>
