<template>
    <div>
        <div class="flex flex-row">
            <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transactions_affiliate')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.affiliate_name &&
                            selectedTransaction.affiliate_name.trim() != ''
                            ? selectedTransaction.rendered_affiliate_name
                            : 'N/A'
                        }}
                        <span v-if="selectedTransaction.status == 'captured'" class="ml-2 cursor-pointer"
                            @click="openChangeAffiliateModal">
                            <pencil-icon class="h-5 w-5 fill-current" />
                        </span>
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t(
                            'frontend_analytics_transactions_affiliate_email'
                        )
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.affiliate_email &&
                            selectedTransaction.affiliate_email.trim() != ''
                            ? selectedTransaction.rendered_affiliate_email
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transactions_username')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.affiliate_username &&
                            selectedTransaction.affiliate_username.trim() != ''
                            ? selectedTransaction.rendered_affiliate_username
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transactions_commision')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.human_readable_commission &&
                            selectedTransaction.human_readable_commission.trim() !=
                            ''
                            ? selectedTransaction.human_readable_commission
                            : 'N/A'
                        }}
                        <span v-if="selectedTransaction.affiliate_username &&
                                selectedTransaction.affiliate_username.trim() != '' &&
                                selectedTransaction.status == 'captured'" class="ml-2 cursor-pointer"
                            @click="openChangeCommissionModal">
                            <pencil-icon class="h-5 w-5 fill-current" />
                        </span>
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transactions_commision_type')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.commission_type &&
                            selectedTransaction.commission_type.trim() != ''
                            ? selectedTransaction.commission_type
                            : 'N/A'
                        }}
                    </label>
                </div>

                <div v-if="selectedTransaction.assisted_affiliate_id" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">Assisted Affiliate</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.assisted_affiliate_name }}
                    </label>
                </div>

                <div v-if="selectedTransaction.assisted_affiliate_id" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_groovesell_transaction_assis_aff_comm')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.assisted_affiliate_commission
                        }}
                    </label>
                </div>
            </div>
        </div>
        <div v-if="selectedTransaction.suspected_affiliate_fraud == 1" class="mt-4 w-full text-md text-grey-700">
            <span class="font-bold text-red">{{
                $t('frontend_analytics_transactions_note')
            }}</span>
            {{ $t('frontend_analytics_transactions_note_text') }}
        </div>
    </div>
</template>
<script>
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';

export default {
    components: {
        PencilIcon,
    },
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
    },
    methods: {
        openChangeAffiliateModal() {
            this.$root.$emit('modal-open', 'change-transaction-affiliate-modal');
        },
        openChangeCommissionModal() {
            this.$root.$emit('modal-open', 'change-affiliate-commission-modal');
        },
    }

}
</script>