<template>
    <div>
        <div class="flex flex-row mt-2 w-full">
            <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col w-6/12">
                    <div class="flex w-96">
                        <div class="w-full transactionsFilterContainer">
                            <treeselect v-model="localFilters.filteredTransactions" :multiple="true"
                                :options="transactionFilters" :max-height="500"
                                :placeholder="$t('frontend_groovesell_transactions_filter')"
                                @select="onTransactionFilterChange" @deselect="onTransactionFilterChange">
                            </treeselect>
                        </div>
                    </div>

                    <div class="flex justify-start items-center space-x-4 mt-4">
                        <date-range-picker ref="picker" v-model="localFilters.transactionsDateRange"
                            :opens="opens" :auto-apply="autoApply" :start-date="customStartDate"
                            :end-date="customEndDate" :ranges="customDateRanges" @update="dateRangeChanged">
                            <div slot="input" slot-scope="picker">
                                {{ picker.startDate | moment('MM/DD/YYYY') }} -
                                {{ picker.endDate | moment('MM/DD/YYYY') }}
                            </div>
                        </date-range-picker>
                        <gd-dropdown
                            v-model="localFilters.selectedRowSize"
                            size="small"
                            :options="pageSizeOptions"
                            @change="onPageSizeChanged()"
                        />
                    </div>
                    <div class="flex justify-start mt-4">
                        <gd-button
                            :class="{'animated bounce': !defaultFilters || applyTriggered}"
                            :variant="defaultFilters && !applyTriggered ? 'base' : 'primary'"
                            :disabled="defaultFilters && !applyTriggered"
                            @click="applyFilters"
                        >
                            {{ $t('apply_filters') }}
                        </gd-button>
                        <gd-button v-if="!defaultFilters" size="auto" variant="outline" class="ml-4" @click="clearFilters">
                            <span class="mr-2">
                                <filter-icon class="h-5 w-5 fill-current" />
                            </span>
                            {{ $t('frontend_analytics_transactions_reset') }}
                        </gd-button>
                    </div>
                </div>
                <div class="flex flex-col justify-start">
                    <div class="flex flex-row space-x-2">
                        <text-input v-model="localFilters.searchPhrase"
                            value="searchPhrase" :placeholder="$t('frontend_analytics_transactions_search')"
                            @input="searchPhraseChanged" />
                        <gd-button variant="primary" size="expand" class="mr-4" @click="openAdvancedFilters">
                            <span class="mr-2">
                                <filter-icon class="h-5 w-5 fill-current" />
                            </span>
                            {{ $t('frontend_groovesell_modal_advanced_filters') }}
                        </gd-button>
                    </div>
                    <div class="flex flex-col mt-4 space-y-2">
                        <div class="flex items-center justify-end space-x-2">
                            <div>
                                {{ $t('frontend_analytics_exclude') }}
                            </div>
                            <gd-toggle v-model="localFilters.excludeFreeTransactions" dark
                                :value="localFilters.excludeFreeTransactions" @change="excludeFreeTransactionsChanged" />
                        </div>
                    </div>
                    <div class="flex justify-end" :class="this.currentTab === 'failed' ? 'mt-4' : 'mt-2'">
                        <gd-dropdown
                            :placeholder="$t('frontend_analytics_transactions_export')"
                            :actions="openPopUpActions"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row mt-2" v-if="currentTab == 'captured'">
           <div
                class="w-full flex flex-wrap xl:flex-nowrap text-xs statsTransactions mt-4 text-center border border-grey-200 bg-white rounded"
            >
                <stats-item
                    :title="$t('frontend_analytics_transactions_transactions')"
                    :value="transactionList?.meta?.total | numFormat"
                />
                <stats-item
                    :title="$t('frontend_analytics_transactions_revenue')"
                    :value="transactionList?.meta?.total_revenue | currency"
                />
                <stats-item
                    :title="$t('frontend_analytics_transactions_refunded')"
                    :value="transactionList?.meta?.total_refunded | currency"
                />
                <stats-item
                    :title="$t('frontend_analytics_transactions_processing_fee')"
                    :value="transactionList?.meta?.total_processing_fee | currency"
                />
                <stats-item
                    :title="$t('frontend_analytics_transactions_commisions')"
                    :value="transactionList?.meta?.total_commission | currency"
                />
                <stats-item
                    :title="$t('frontend_analytics_transactions_commisions_second_tier')"
                    :value="transactionList?.meta?.total_commission_second_tier | currency"
                />
                <stats-item
                        :title="$t('frontend_analytics_transactions_vendor_revenue')"
                        :value="transactionList?.meta?.total_vendor_revenue | currency"
                    />
                </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import StatsItem from '@/components/StatsItem.vue';

export default {
    components: {
        Treeselect,
        ResetIcon,
        FilterIcon,
        StatsItem,
    },
    props: {
        filteredFunnels: {
            type: Array,
        },
        filteredAffiliates: {
            type: Array,
        },
        filteredSecondTierAffiliates: {
            type: Array,
        },
        filteredAdvancedTransactions: {},
        currentTab: {
            type: String,
        },
        filteredTransactions: {
            type: Array,
        },
        transactionsDateRange: {
            type: Object,
        },
        reloadData: {
            type: Function,
        },
        openPopUp: {
            type: Function,
        },
        transactionList: {
            required: true,

        },
    },
    watch: {
        currentTab() {
            this.localFilters.filteredTransactions = this.filteredTransactions;
        },
    },
    data() {
        return {
            applyTriggered: false,
            localFilters: {
                filteredTransactions: this.filteredTransactions,
                transactionsDateRange: this.transactionsDateRange,
                selectedRowSize: 25,
                searchPhrase: '',
                excludeFreeTransactions: false,
            },
            opens: 'right',
            autoApply: true,
            customStartDate: moment(),
            customEndDate: moment(),
            customDateRanges: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                ],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [
                    moment().subtract(1, 'year').startOf('year'),
                    moment().subtract(1, 'year').endOf('year'),
                ],
                'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
            },

        }
    },
    computed: {
        defaultFilters() {
            return this.localFilters.filteredTransactions?.length == 1 &&
                this.localFilters.filteredTransactions[0] == 0 &&
                this.localFilters.searchPhrase == '' &&
                this.localFilters.selectedRowSize == 25 &&
                this.filteredFunnels?.length == 0 &&
                this.filteredAffiliates?.length == 1 &&
                this.filteredAffiliates[0].id == 0 &&
                this.filteredAffiliates[0].name === 'All' &&
                this.filteredSecondTierAffiliates?.length == 1 &&
                this.filteredSecondTierAffiliates[0].id == 0 &&
                this.filteredSecondTierAffiliates[0].name === 'All' &&
                this.filteredAdvancedTransactions == 0 &&
                !this.localFilters.excludeFreeTransactions &&
                this.isDefaultDateRange(this.localFilters.transactionsDateRange.startDate) &&
                this.isDefaultDateRange(this.localFilters.transactionsDateRange.endDate);
        },
        transactionFilters() {
            const TransactionFilters = [
                { id: 0, label: this.$t('frontend_analytics_transactions_all_transactions') },
                { id: 8, label: this.$t('frontend_analytics_transactions_only_the_good') },
                { id: 9, label: this.$t('frontend_analytics_transactions_only_the_bad') },
                { id: 1, label: this.$t('frontend_analytics_transactions_sale') },
                { id: 3, label: this.$t('frontend_analytics_transactions_initial_payment') },
                { id: 2, label: this.$t('frontend_analytics_transactions_initial_rebill') },
                { id: 7, label: this.$t('frontend_analytics_transactions_free_transactions') },
                { id: 4, label: this.$t('frontend_analytics_transactions_cancellations') },
                { id: 5, label: this.$t('frontend_analytics_transactions_refund') },
                { id: 6, label: this.$t('frontend_analytics_transactions_suspected_fraud') },
            ];
            const failedTransactionFilters = [
                { id: 0, label: this.$t('frontend_analytics_all_failed_rebills') },
                { id: 1, label: this.$t('frontend_analytics_successfully_captured') },
                { id: 2, label: this.$t('frontend_analytics_pending_dunning') },
                { id: 3, label: this.$t('frontend_analytics_unable_to_recover') },
            ];
            return this.currentTab === 'failed' ? failedTransactionFilters : TransactionFilters;
        },
        pageSizeOptions() {
            return [
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ];
        },
        openPopUpActions() {
            return [
                {
                    text: this.$t('frontend_analytics_transactions_export_all'),
                    callback: () => this.openPopUp(true),
                },
                {
                    text: this.$t('frontend_analytics_transactions_export_this_page'),
                    callback: () => this.openPopUp(),
                },
            ];
        },
    },
    methods: {
        isDefaultDateRange(dateString) {
            return moment(dateString).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
        },
        dateRangeChanged() {
            this.$emit('filter-changed', {
                key: 'transactionsDateRange',
                value: this.localFilters.transactionsDateRange
            });
        },
        searchPhraseChanged() {
            this.$emit('filter-changed', {
                key: 'searchPhrase',
                value: this.localFilters.searchPhrase
            });
        },
        onPageSizeChanged() {
            this.$emit('filter-changed', {
                key: 'selectedRowSize',
                value: this.localFilters.selectedRowSize
            });
        },
        excludeFreeTransactionsChanged() {
            this.$emit('filter-changed', {
                key: 'excludeFreeTransactions',
                value: this.localFilters.excludeFreeTransactions
            });

            this.$emit('apply-filters');
        },
        onTransactionFilterChange() {
            if (this.currentTab === 'failed') {
                this.$nextTick(() => {
                    if (this.localFilters.filteredTransactions && this.localFilters.filteredTransactions.length > 0) {
                        const lastAddedTransactionFilterId =
                            this.localFilters.filteredTransactions[this.localFilters.filteredTransactions.length - 1];

                        if (lastAddedTransactionFilterId == 0) {
                            this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.slice(-1);
                        } else {
                            this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.filter((obj) => { return obj !== 0; });
                        }
                    } else {
                        this.localFilters.filteredTransactions = [0];
                    }
                    this.emitData('filteredFailedTransactions', this.localFilters.filteredTransactions);
                });
            } else {
                this.$nextTick(() => {
                    if (this.localFilters.filteredTransactions && this.localFilters.filteredTransactions.length > 0) {
                        const lastAddedTransactionFilterId = this.localFilters.filteredTransactions[this.localFilters.filteredTransactions.length - 1];
                        if (lastAddedTransactionFilterId == 0) {
                            this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.slice(-1);
                        }
                        else if (lastAddedTransactionFilterId == 8) {
                            this.localFilters.filteredTransactions = [1, 2, 3, 6];
                        }
                        else if (lastAddedTransactionFilterId == 9) {
                            this.localFilters.filteredTransactions = [4, 5];
                        }
                        else {
                            this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.filter((obj) => { return obj !== 0; });
                        }
                        this.emitData('filteredTransactions', this.localFilters.filteredTransactions);

                    }

                });
            }

        },
        emitData(key, value) {
            this.$emit('filter-changed', {
                key: key,
                value: value,
                startSearch: false
            });
        },
        emitStartSearch() {
            this.$emit('start-search')
        },
        openAdvancedFilters() {
            const modalName = this.currentTab === 'failed' ? 'advanced-failed-filters-modal' : 'advanced-filters-modal';
            this.$root.$emit('modal-open', modalName);
        },
        clearFilters() {
            this.applyTriggered = false;
            this.localFilters.filteredTransactions = [0];
            this.localFilters.searchPhrase = '';
            this.localFilters.selectedRowSize = 25;
            this.localFilters.transactionsDateRange = {startDate: moment(), endDate: moment()};
            this.localFilters.excludeFreeTransactions = false;

            this.$emit('filter-changed', {
                key: 'clear',
                values: [
                    { key: 'searchPhrase', value: ''},
                    { key: 'selectedRowSize', value: 25 },
                    { key: 'transactionsDateRange', value: this.localFilters.transactionsDateRange},
                    { key: 'excludeFreeTransactions', value: false},
                    { key: 'filteredTransactions', value: [0] },
                    { key: 'filteredFailedTransactions', value: [0] },
                    { key: 'searchPhrase', value: this.localFilters.searchPhrase },
                    { key: 'filteredAffiliates', value: [{ id: 0, name: 'All' }] },
                    { key: 'filteredSecondTierAffiliates', value: [{ id: 0, name: 'All' }] },
                    { key: 'filteredFunnels', value: [] },
                    { key: 'filteredAdvancedTransactions', value: 0 }
                ]
            });
            this.$emit('apply-filters');
        },
        applyFilters() {
            this.applyTriggered = true;
            this.$emit('apply-filters');
        },
    },
    watch: {
        transactionsDateRange: {
            handler() {
                this.localFilters.transactionsDateRange = this.transactionsDateRange;
            },
            deep: true
        },
        filteredTransactions: {
            handler() {
                this.localFilters.filteredTransactions = this.filteredTransactions;
            },
            deep: true
        },
    }
}
</script>

<style>
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-5px);}
    60% {-webkit-transform: translateY(-5px);}
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-5px);}
    60% {transform: translateY(-5px);}
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
</style>
