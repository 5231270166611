<template>
    <gd-modal name="add-membership-modal"
        :title="isEditing === false ? $t('frontend_groovesell_add_membership') : $t('frontend_groovesell_edit_membership')"
        width="80%">
        <div class="flex flex-col px-4">
            <div class="mt-4">
                <div class="-mt-4">
                    <div class="flex flex-row w-full">
                        <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                            <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{
                                $t('frontend_groovesell_select_membership') }}</label>
                            <gd-dropdown
                                v-model="localpostMembershipId"
                                size="full"
                                :placeholder="$t('frontend_groovesell_select_membership')"
                                :options="postMembershipOptions"
                                @change="selectMembership($event)"
                            />
                        </div>

                        <div class="w-1/2 flex flex-col w-full mt-4">
                            <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{
                                $t('frontend_groovesell_select_access_level') }}</label>
                            <gd-dropdown
                                v-model="localpostMembershipAccessLevelId"
                                size="full"
                                :placeholder="$t('frontend_groovesell_select_access_level')"
                                :options="postAccessLevelOptions"
                                @change="postaccesslevelmemberid($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-full mb-6 justify-end">
                <gd-button
                    v-if="isEditing == false"
                    :variant="gdButtonVariant"
                    :disabled="!post_addbtn"
                    class="mt-4"
                    size="md"
                    @click="postdata != '' ? addMembershipField() : null"
                >
                    {{ $t('frontend_add') }}
                </gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        isEditing: {
            type: Boolean,
            required: true,
        },
        postMembershipId: {
            required: true,
        },
        post_memberships: {
            required: true,
        },
        postdata: {
            required: true,
        },
        post_access_levels: {
            required: true,
        },
        post_addbtn: {
            required: true,

        },
        postMembershipAccessLevelId: {
            required: true,
        },
        postaccesslevelmemberid: {
            type: Function,
            required: true
        },
        addMembershipField: {
            type: Function,
            required: true
        }


    },
    data() {
        return {
            localpostMembershipId: this.postMembershipId,
            localpost_access_levels: this.post_access_levels,
            localpostMembershipAccessLevelId: this.postMembershipAccessLevelId
        }
    },
    computed: {
        gdButtonVariant() {
            return this.post_addbtn ? 'primary' : 'base';
        },
        postMembershipOptions() {
            return this.post_memberships.map(membership => ({ value: membership.id, text: membership.name }));
        },
        postAccessLevelOptions() {
            return this.localpost_access_levels.map(accessLevel => ({ value: accessLevel.secret_key, text: accessLevel.name }));
        },
    },
    methods: {
        selectMembership(event) {

            const selectedPostMembership = this.post_memberships.find((ele) => {
                return ele.id === this.postMembershipId;
            });

            this.localpost_access_levels = selectedPostMembership ? selectedPostMembership.access_level : []
            this.emitData('post_addbtn', false);
            this.emitData('postdata', []);
            this.emitData('post_access_levels', this.localpost_access_levels);
        },
        emitData(key, value) {
            this.$emit('update-data', {
                key: key,
                value: value,
            })
        },

    }
}
</script>