<template>
  <div v-if="hasLogoutPageAds" class="flex flex-col">
    <!-- Tabs for Logout Page Ads -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="logoutPageAdsTabs"
        :current-tab="logoutPageAdsTab"
				wrapper-class="pt-4 px-0 gs-default-tabs"
				tab-class="pb-4 mr-4 gs-default-tabs-item"
				tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
				line-class="gs-default-tabs-active-line"
        @onClick="handleLogoutPageAdsTabChange"
      />
    </div>

    <!-- Displaying Ads based on selected tab -->
    <div class="mt-2">
      <div v-for="(ad, index) in funnelResource.logout_page_ads" :key="index">
        <div v-if="isCurrentTab(ad)" class="pb-8">
          <div class="flex flex-col justify-center items-center w-full mb-6">
            <div v-html="ad.body"></div>
          </div>
          <!-- Textarea for embed code and copy button -->
					<div class="w-full flex flex-row justify-center mt-2">
						<div class="w-1/3"></div>
						<div class="w-1/3 flex flex-row">
							<textarea
								v-model="ad.body"
								:placeholder="$t('frontend_affiliate_promo_embed_code')"
								class="h-64 w-full resize-y border rounded border-grey-200 p-2"
                @click="handleTextareaClick"
								readonly="readonly"
							></textarea>
							<div class="ml-2 flex items-center">
								<gd-button
									v-clipboard:copy="ad.body"
									v-clipboard:success="onCopy"
									v-clipboard:error="onError"
									size="icon"
									variant="primary"
									:title="$t('frontend_affiliate_promo_copy_embed_code')"
								>
									<copy-icon class="w-5 h-5 fill-current" />
								</gd-button>
							</div>
						</div>
						<div class="w-1/3"></div>
					</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_affiliate_no_logout_page') }}
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    logoutPageAdsTab: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasLogoutPageAds() {
      return (
        this.funnelResource &&
        this.funnelResource.logout_page_ads &&
        this.funnelResource.logout_page_ads.length
      );
    },
    logoutPageAdsTabs() {
      return this.funnelResource?.logout_page_ads?.map(ad => ({
        title: ad.name,
        value: `logout_page_ad_tab_${ad.id}`,
      })) || [];
    },
  },
  methods: {
    handleLogoutPageAdsTabChange(event) {
      this.$emit('handleLogoutPageAdsTabChange', event);
    },
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    isCurrentTab(ad) {
      return this.logoutPageAdsTab === `logout_page_ad_tab_${ad.id}`;
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
