<template>
  <div>
    <div v-if="hasSwipes" class="flex flex-col">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-tabs
          :tabs="swipesTabs"
          :current-tab="localSwipesTab"
          :error-tabs="swipesErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          :enable-dragging="true"
          @onClick="handleSwipesTabChange"
          @updateTabOrder="handleSwipesTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createAffiliateSwipe"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(swipe, index) in localFunnelResource.swipes" :key="index">
          <div v-if="isSwipesTabActive(swipe)" class="pb-8">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_name') }}
                </label>
                <text-input
                  v-model="swipe.name"
                  :validator="$v.localFunnelResource.swipes.$each[index].name"
                  :attribute="$t('frontend_groovesell_swipe_name')"
                  value="swipe.name"
                  :placeholder="$t('frontend_name')"
                />
              </div>
              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle class="h-6 mt-2" v-model="swipe.enabled" :value="swipe.enabled" />
                <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index)">
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div v-for="(subject, subjectIndex) in swipe.subjects" class="flex flex-col w-1/2 mb-6">
              <label v-if="subjectIndex === 0" class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_resources_subject') }}
              </label>
              <div class="flex flex-row">
                <text-input
                  v-model="swipe.subjects[subjectIndex]"
                  :validator="$v.localFunnelResource.swipes.$each[index].subjects.$each[subjectIndex]"
                  attribute="$t('frontend_resources_subject')"
                  value="swipe.subjects[subjectIndex]"
                  :placeholder="$t('frontend_resources_subject')"
                />
                <gd-button
                  variant="outline"
                  size="iconlg"
                  :title="$t('frontend_resources_add')"
                  class="ml-2"
                  @click="addSubject(index)"
                >
                  <plus-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button
                  v-if="swipe.subjects.length > 1"
                  variant="outline"
                  size="iconlg"
                  :title="$t('frontend_resources_add')"
                  class="ml-2"
                  @click="deleteSubject(index, subjectIndex)"
                >
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex flex-row w-1/2">
                <div class="w-1/2">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_body') }}
                  </label>
                </div>
                <div class="flex flex-row justify-end mb-2 w-1/2">
                  <div class="flex flex-row">
                    <div class="font-semibold text-sm text-grey-700 mr-2">
                      {{ $t('frontend_resources_view_html') }}
                    </div>
                    <gd-toggle v-model="isSwipesPageHTMLView" :value="isSwipesPageHTMLView" />
                  </div>
                </div>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-if="isSwipesPageHTMLView"
                    v-model="swipe.htmlBody"
                    class="swipesEditor w-full"
                    value="swipe.htmlBody"
                    :placeholder="$t('frontend_resources_body')"
                    variant="dark"
                  />
                  <quill-editor
                    v-else
                    :ref="'swipesEditor_' + index"
                    v-model="swipe.htmlBody"
                    :options="swipeEditorOptions"
                    class="swipesEditor w-full"
                  ></quill-editor>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToSwipes(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActionsSwipes(index)"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col w-full mt-32 mb-6">
              <div class="flex flex-row w-1/2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_text_view') }}
                </label>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <textarea
                    v-model="swipe.textBody"
                    value="swipe.textBody"
                    :placeholder="$t('frontend_resources_text_body')"
                    class="swipesEditor w-full resize-y border rounded border-grey-200 p-2"
                  ></textarea>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToSwipesTextView(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActionsTextView(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-button
          size="auto"
          variant="primary"
          class="mt-3 mb-3"
          @click="createAffiliateSwipe"
        >
          +{{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_you_currently') }}
    </div>
    <confirm-modal
      name="confirm-swipes-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteSwipe"
    />
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Quill from 'quill';
import {quillEditor} from 'vue-quill-editor';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    quillEditor,
    TrashIcon,
    PlusIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    swipesTab: {
      type: String,
      default: '',
    },
    swipeEditorOptions: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swpiteId: null,
      localFunnelResource: this.funnelResource,
      localSwipesTab: this.swipesTab,
      isSwipesPageHTMLView: false,
    }
  },
  computed: {
    hasSwipes() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.swipes &&
        this.localFunnelResource.swipes.length
      );
    },
    swipesTabs() {
      let swipesTabs = [];

      if (this.hasSwipes) {
        for (let i = 0; i < this.localFunnelResource.swipes.length; i++) {
          swipesTabs.push({
            title: this.localFunnelResource.swipes[i].name,
            value: `swipe_tab_${this.localFunnelResource.swipes[i].id}`,
          });
        }
      }

      return swipesTabs;
    },
    swipesErrorTabs() {
      let swipesErrorTabs = [];

      let swipesIndex;
      for (swipesIndex in this.$v.localFunnelResource.swipes.$each.$iter) {
        if (this.$v.localFunnelResource.swipes.$each.$iter[swipesIndex].$error) {
          swipesErrorTabs.push(
            `swipe_tab_${this.$v.localFunnelResource.swipes.$each.$iter[swipesIndex].$model.id}`
          );
        }
      }

      return swipesErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActionsSwipes(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToSwipes(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsTextView(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToSwipesTextView(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleSwipesTabChange(tab) {
      this.localSwipesTab = tab || this.localSwipesTab;
      this.$emit('handleSwipesTabChange', this.localSwipesTab);
    },
    handleSwipesTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('swipe_tab_', ''))
      );
      console.log(updatedOrder);

      this.localFunnelResource.swipes.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.handleSwipesTabChange();
      this.updateFunnelResource();
    },
    createAffiliateSwipe() {
      const newSwipeId = this.generateNumber();
      const newSwipeCount = this.localFunnelResource.swipes.length + 1;

      this.localFunnelResource.swipes.push({
        id: newSwipeId,
        name: `Swipe ${newSwipeCount}`,
        subjects: [ `${this.$t('frontend_groovesell_tools_affiliate_edit_swipe_subject')} #1`],
        htmlBody: '',
        textBody: '',
      });

      this.localSwipesTab = `swipe_tab_${newSwipeId}`;
      this.handleSwipesTabChange();
      this.updateFunnelResource();
    },
    openConfirmModal(swpiteId) {
      this.swpiteId = swpiteId;
      this.$root.$emit('modal-open', 'confirm-swipes-delete-modal');
    },
    async deleteSwipe() {
      this.localSwipesTab = '';

      this.localFunnelResource.swipes.splice(this.swpiteId, 1);
      if (this.localFunnelResource.swipes.length > 0) {
        let nextOpenIndex = 0;
        if (this.swpiteId == 0) {
          nextOpenIndex = 0;
        } else {
          nextOpenIndex = this.swpiteId - 1;
        }

        const nextOpenId = this.localFunnelResource.swipes[nextOpenIndex].id;
        this.localSwipesTab = `swipe_tab_${nextOpenId}`;
      }
      this.updateFunnelResource();
      this.handleSwipesTabChange();
    },
    addSubject(index) {
      const newSubjectCount =
        this.localFunnelResource.swipes[index].subjects.length + 1;

      this.localFunnelResource.swipes[index].subjects.push(
        `${this.$t('frontend_groovesell_tools_affiliate_edit_swipe_subject')} #${newSubjectCount}`
      );
      this.updateFunnelResource();
    },
    deleteSubject(index, subjectIndex) {
      this.localFunnelResource.swipes[index].subjects.splice(subjectIndex, 1);
      this.updateFunnelResource();
    },
    addAffiliateNameToSwipes(index) {
      const range =
        this.$refs[`swipesEditor_${index}`][0].quill.getSelection(true);

      if (range) {
        this.$refs[`swipesEditor_${index}`][0].quill.insertText(
          range.index,
          '##affiliateName##'
        );
      }
    },
    addAffiliateNameToSwipesTextView(index) {
      this.localFunnelResource.swipes[
        index
      ].textBody = `${this.localFunnelResource.swipes[index].textBody}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToSwipesTextView(index, landingPageId) {
      this.localFunnelResource.swipes[
        index
      ].textBody = `${this.localFunnelResource.swipes[index].textBody}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToSwipes(index, landingPageId) {
      const range =
        this.$refs[`swipesEditor_${index}`][0].quill.getSelection(true);

      if (range) {
        if (range.length == 0) {
          this.$refs[`swipesEditor_${index}`][0].quill.insertText(
            range.index,
            `##affiliateLink${landingPageId}##`
          );
        } else {
          this.$refs[`swipesEditor_${index}`][0].quill.format(
            'link',
            `##affiliateLink${landingPageId}##`,
            Quill.sources.USER
          );
        }
      }
    },
    isSwipesTabActive(swipe) {
      return this.localSwipesTab === `swipe_tab_${swipe.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      swipes: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          subjects: {
            $each: {
              required,
              minLength: minLength(1),
              maxLength: maxLength(300),
            },
          },
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
