<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_funnel') }}
          </label>
          <gd-dropdown
            v-model="selectedFunnel"
            size="full"
            :options="funnelOptions"
          />
        </div>
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_product') }}
          </label>
          <gd-dropdown
            v-model="selectedProduct"
            size="full"
            :options="productOptions"
          />
        </div>
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_price_point') }}
          </label>
          <gd-dropdown
            v-model="selectedPricePoint"
            size="full"
            :options="pricePointOptions"
          />
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Step3',
  props: {
    model: {
      required: true
    },
    funnels: {
      required: true
    },
  },
  data() {
    return {
      localModel: this.model,
      products: null,
      pricePoints: null,
      selectedFunnel: 0,
      selectedProduct: 0,
      selectedPricePoint: 0,
      isProductFirstCall: true,
      isPricePointFirstCall: true,
    };
  },
  computed: {
    funnelOptions() {
      if (!this.funnels) {
        return [
          { value: 0, text: this.$t('frontend_groovesell_any_funnel') }
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_groovesell_any_funnel') },
        ...this.funnels.map((funnel) => {
          return { value: funnel.id, text: funnel.name };
        }),
      ];
    },
    productOptions() {
      if (!this.products) {
        return [
          { value: 0, text: this.$t('frontend_groovesell_any_product') }
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_groovesell_any_product') },
        ...this.products.map((product) => {
          return { value: product.id, text: product.name };
        }),
      ];
    },
    pricePointOptions() {
      if (!this.pricePoints) {
        return [
          { value: 0, text: this.$t('frontend_groovesell_any_price_point') }
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_groovesell_any_price_point') },
        ...this.pricePoints.map((pricePoint) => {
          return { value: pricePoint.id, text: pricePoint.name };
        }),
      ];
    },
  },
  validations: {
    localModel: {
      funnel_id: { required },
      product_id: { required },
      price_point_id: { required }
    }
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
    selectedFunnel() {
      this.handleSelectedFunnel();
    },
    selectedProduct() {
      this.handleSelectedProduct();
    },
    selectedPricePoint() {
      this.localModel.price_point_id = this.selectedPricePoint;
    }
  },
  created() {
    this.initializeSelectedValues();
  },
  methods: {
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    handleSelectedFunnel() {
      if (this.selectedFunnel == 0) {
        this.resetSelections();
      } else {
        this.$loader.start('handleSelectedFunnel');
        const selectedFunnel = this.getSelectedFunnel();
        this.products = selectedFunnel.products;
        this.localModel.funnel_id = this.selectedFunnel;

        if (!this.isProductFirstCall) {
          this.resetProductSelections();
        }

        this.isProductFirstCall = false;
        this.$loader.end('handleSelectedFunnel');
      }
    },
    handleSelectedProduct() {
      if (this.selectedProduct == 0) {
        this.pricePoints = null;
        this.resetProductSelections();
      } else {
        this.$loader.start('handleSelectedProduct');
        const selectedFunnel = this.getSelectedFunnel();
        const selectedProduct = this.getSelectedProduct(selectedFunnel);
        this.localModel.product_id = this.selectedProduct;
        this.pricePoints = selectedProduct.price_points;

        if (!this.isPricePointFirstCall) {
          this.resetPricePointSelections();
        }

        this.isPricePointFirstCall = false;
        this.$loader.end('handleSelectedProduct');
      }
    },
    getSelectedFunnel() {
      return this.funnels.find(funnel => funnel.id === this.selectedFunnel);
    },
    getSelectedProduct(selectedFunnel) {
      return selectedFunnel.products.find(product => product.id === this.selectedProduct);
    },
    resetSelections() {
      this.products = null;
      this.pricePoints = null;
      this.localModel.funnel_id = 0;
      this.resetProductSelections();
    },
    resetProductSelections() {
      this.localModel.product_id = 0;
      this.selectedProduct = 0;
      this.resetPricePointSelections();
    },
    resetPricePointSelections() {
      this.localModel.price_point_id = 0;
      this.selectedPricePoint = 0;
    },
    initializeSelectedValues() {
      this.selectedFunnel = this.localModel.funnel_id;
      this.selectedProduct = this.localModel.product_id;
      this.selectedPricePoint = this.localModel.price_point_id;
    },
  }
};
</script>
