var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4"},[_c('gd-tabs',{attrs:{"tabs":_vm.landingPagesTabs,"error-tabs":_vm.landingPagesErrorTabs,"current-tab":_vm.landingPagesTab,"wrapper-class":'pt-4 px-0 gs-default-tabs flex-auto w-128',"tab-class":'pb-4 mr-4 gs-default-tabs-item',"tab-active-class":'border-primary border-b-2 text-primary gs-default-tabs-item-active',"line-class":'gs-default-tabs-active-line'},on:{"onClick":_vm.handleLandingPagesTabChange}}),_c('gd-button',{staticClass:"mt-3 w-64",attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.emitCreateAffiliateLandingPage}},[_c('plus-icon',{staticClass:"h-5 w-5 fill-current"}),_vm._v(" "+_vm._s(_vm.$t('frontend_product_step9_add_new_landing_page'))+" ")],1)],1),_c('div',{staticClass:"mt-2"},_vm._l((_vm.localModel.affiliate_landing_pages),function(affiliateLandingPage,index){return _c('div',{key:index},[(_vm.landingPagesTab === 'landing_page_' + affiliateLandingPage.id)?_c('div',[_c('div',{staticClass:"flex flex-col w-1/2 mb-6"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700 mb-2"},[_vm._v(_vm._s(_vm.$t('frontend_name')))]),_c('text-input',{attrs:{"validator":_vm.validation.localModel.affiliate_landing_pages.$each[index]
                        .description,"attribute":_vm.$t('frontend_groovesell_landing_page_name'),"value":"affiliateLandingPage.description","placeholder":_vm.$t('frontend_product_step9_name')},model:{value:(affiliateLandingPage.description),callback:function ($$v) {_vm.$set(affiliateLandingPage, "description", $$v)},expression:"affiliateLandingPage.description"}})],1),_c('div',{staticClass:"flex flex-col w-1/2 mb-6"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700 mb-2"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_landing_page')))]),(index == 0)?_c('label',{staticClass:"text-grey-dark"},[_vm._v(" "+_vm._s(affiliateLandingPage.landing_page)+" ")]):_vm._e(),(index == 0)?_c('label',{staticClass:"text-xs text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_this_cannotbe')))]):_c('text-input',{attrs:{"validator":_vm.validation.localModel.affiliate_landing_pages.$each[index]
                        .landing_page,"attribute":_vm.$t('frontend_product_step9_landing_page_url'),"value":"affiliateLandingPage.landing_page","placeholder":_vm.$t('frontend_product_step9_landing_page_url')},model:{value:(affiliateLandingPage.landing_page),callback:function ($$v) {_vm.$set(affiliateLandingPage, "landing_page", $$v)},expression:"affiliateLandingPage.landing_page"}})],1),_c('div',{staticClass:"flex flex-col w-full mb-6"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700 mb-2"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_available_to')))]),(index == 0)?_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_all_affiliates')))]):_c('div',[_c('div',{staticClass:"w-1/2 flex flex-row"},[_c('div',{staticClass:"flex w-1/2 mr-4"},[_c('div',{staticClass:"w-full flex flex-col items-left mr-4 mb-4 rounded border p-2",class:{
                                            'border-primary': _vm.landingPageAssignedType === 1,
                                            'border-grey-200': _vm.landingPageAssignedType !== 1,
                                        },on:{"click":function($event){return _vm.updateLandingPageAssignedType(1)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.updateLandingPageAssignedType(1)}}},[_c('label',{staticClass:"flex cursor-pointer"},[_c('span',{staticClass:"w-6 h-5 inline-block mr-1 mt-1 rounded-full border",class:{
                                            'border-grey bg-primary text-primary activeOptionRadio':
                                                _vm.landingPageAssignedType === 1,
                                            'border-grey': _vm.landingPageAssignedType !== 1
                                        }}),_c('div',{staticClass:"ml-2 flex flex-col w-full"},[_c('label',{staticClass:"font-semibold",class:{
                                                'text-primary': _vm.landingPageAssignedType === 1,
                                                'text-grey-700':
                                                    _vm.landingPageAssignedType !== 1
                                            }},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_all_affiliates')))]),_c('div',{staticClass:"text-grey-500"},[_vm._v(" "+_vm._s(_vm.$t('frontend_product_step9_this_landingpage'))+" ")])])])])]),_c('div',{staticClass:"flex w-1/2 mr-4"},[_c('div',{staticClass:"w-full flex flex-col items-left mr-4 mb-4 rounded border p-2",class:{
                                    'border-primary': _vm.landingPageAssignedType === 2,
                                    'border-grey-200': _vm.landingPageAssignedType !== 2,
                                },on:{"click":function($event){return _vm.updateLandingPageAssignedType(2)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.updateLandingPageAssignedType(2)}}},[_c('label',{staticClass:"flex cursor-pointer"},[_c('span',{staticClass:"w-6 h-5 inline-block mr-1 mt-1 rounded-full border",class:{
                                            'border-grey bg-primary text-primary activeOptionRadio':
                                                _vm.landingPageAssignedType === 2,
                                            'border-grey': _vm.landingPageAssignedType !== 2,
                                        }}),_c('div',{staticClass:"ml-2 flex flex-col w-full"},[_c('label',{staticClass:"font-semibold",class:{
                                                'text-primary': _vm.landingPageAssignedType === 2,
                                                'text-grey-700':
                                                    _vm.landingPageAssignedType !== 2,
                                            }},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_specific_affiliates')))]),_c('div',{staticClass:"text-grey-500"},[_vm._v(" "+_vm._s(_vm.$t('frontend_product_step9_this_landingpage_wouldbe'))+" ")])])])])])]),(_vm.landingPageAssignedType == 2)?_c('div',{staticClass:"w-1/2"},[_c('gd-button',{staticClass:"mt-3",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openCustomLandingPagesAffModal(index)}}},[_vm._v(" "+_vm._s(_vm.$t('frontend_groovesell_available_to'))+" "+_vm._s(affiliateLandingPage.assigned_to.length)+" "+_vm._s(_vm.$t('frontend_affiliates'))+" ")])],1):_vm._e()])]),(index > 0)?_c('div',{staticClass:"flex flex-row-reverse w-full mb-6"},[_c('gd-button',{staticClass:"mt-3",attrs:{"variant":"outline","size":"icon"},on:{"click":function($event){return _vm.emitDeleteAffiliateLandingPage(index)}}},[_c('trash-icon',{staticClass:"h-5 w-5 fill-current"})],1)],1):_vm._e()]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }