<template>
    <gd-modal name="mark-as-paid-modal" :title="$t('frontend_groovesell_process_commissions')" width="1080px"
        bg-type="regular">
        <div class="overflow-y-auto custom-scrollbars scroll-modal">
            <div v-if="selectedAffiliate" class="flex">
                <div class="flex flex-col w-2/3 px-4">
                    <div class="mt-2">
                        <div v-if="selectedAffiliate.previous_pending_amount > 0">
                            <div class="w-full flex flex-row mb-4">
                                <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                    $t('frontend_affiliate_index_amount')
                                }}</label>
                                <label class="w-full px-1 mt-1 font-semibold text-grey-700">{{
                                    selectedAffiliate.human_readable_processed_commission
                                }}</label>
                            </div>
                            <div class="w-full flex flex-row mb-4">
                                <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                    $t('frontend_affiliate_index_advance_clawbacks')
                                }}</label>
                                <label class="w-full px-1 mt-1 font-semibold text-grey-700">
                                    {{
                                        selectedAffiliate.human_readable_previous_pending_amount
                                    }}
                                </label>
                            </div>
                            <div class="w-full flex flex-row mb-4">
                                <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                    $t('frontend_affiliate_index_due_commisions')
                                }}</label>
                                <label class="w-full px-1 mt-1 font-semibold text-grey-700">
                                    {{
                                        selectedAffiliate.human_readable_processed_without_advances
                                    }}
                                </label>
                            </div>
                        </div>
                        <div v-else>
                            <div class="w-full flex flex-row mb-4">
                                <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                    $t('frontend_affiliate_index_amount')
                                }}</label>
                                <label class="w-full px-1 mt-1 font-semibold text-grey-700">{{
                                    selectedAffiliate.human_readable_processed_without_advances
                                }}</label>
                            </div>
                        </div>


                        <div class="w-full flex flex-row mb-4">
                            <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                $t('frontend_affiliate_index_transaction')
                            }}</label>
                            <text-input v-model="markAsPaid.transactionId" class="w-2/3" value="markAsPaid.transactionId"
                                :placeholder="$t('frontend_transaction_id')" />
                        </div>
                        <div class="w-full flex flex-row mb-4">
                            <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                                $t('frontend_affiliate_index_comment')
                            }}</label>
                            <text-input v-model="markAsPaid.comment" class="w-2/3" value="markAsPaid.comment"
                                :placeholder="$t('frontend_comment')" />
                        </div>
                        <div class="flex flex-row w-full mb-6 p-4 justify-center">
                            <div class="w-1/3 mr-2">
                                <font-awesome-radio v-model="markAsPaid.gatewayType" option-name="Paypal" option-value="1"
                                    :option-enabled="selectedAffiliate.affiliate_paypal">
                                    <template slot="icon">
                                        <paypal-icon class="w-10 h-10 fill-current" />
                                    </template>
                                </font-awesome-radio>
                            </div>
                            <div class="w-1/3 mr-2">
                                <font-awesome-radio v-model="markAsPaid.gatewayType" option-name="Check" option-value="2"
                                    :option-enabled="selectedAffiliate.affiliate_check_info">
                                    <template slot="icon">
                                        <credit-card-icon class="w-10 h-10 fill-current" />
                                    </template>
                                </font-awesome-radio>
                            </div>
                            <div class="w-1/3">
                                <font-awesome-radio v-model="markAsPaid.gatewayType" option-name="Wire Info"
                                    :option-enabled="selectedAffiliate.affiliate_wire_info" option-value="3">
                                    <template slot="icon">
                                        <bank-icon class="w-10 h-10 fill-current" />
                                    </template>
                                </font-awesome-radio>
                            </div>
                        </div>
                        <div v-if="markAsPaid.gatewayType != ''" class="flex flex-row w-full mb-4">
                            <div v-if="markAsPaid.gatewayType == '1'" class="w-full flex flex-col">
                                <h4 class="font-semibold text-grey-700">
                                    {{ $t('frontend_affiliate_index_payee_details') }}
                                </h4>
                                <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_paypal_email')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{ selectedAffiliate.rendered_affiliate_paypal }}
                                    </label>
                                </div>
                            </div>
                            <div v-else-if="markAsPaid.gatewayType == '2'">
                                <h4 class="font-semibold text-grey-700">
                                    {{ $t('frontend_affiliate_index_check_Settings') }}
                                </h4>
                            </div>
                            <div v-else-if="markAsPaid.gatewayType == '3'" class="w-full flex flex-col">
                                <h4 class="font-semibold text-grey-700">
                                    {{ $t('frontend_affiliate_index_payee_details') }}
                                </h4>
                                <div class="w-full flex flex-wrap">
                                    <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_name')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.account_name
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_address') }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{ selectedAffiliate.affiliate_wire_info.address }}
                                        </label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{ selectedAffiliate.affiliate_wire_info.city }}
                                        </label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{ selectedAffiliate.affiliate_wire_info.state }}
                                            {{
                                                selectedAffiliate.affiliate_wire_info.postal_code
                                            }}
                                        </label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{ selectedAffiliate.affiliate_wire_info.country }}
                                        </label>
                                    </div>
                                </div>

                                <h4 class="font-semibold text-grey-700">
                                    {{ $t('frontend_affiliate_index_benificiary_bank') }}
                                </h4>
                                <div class="w-full flex flex-wrap">
                                    <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_bank_name') }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_name
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_bank_address')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info
                                                    .main_bank_address
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_account_number')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_account_no
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_routing_number')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_routing_no
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_swift')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_swift_code
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_aba')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_aba
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/2 pt-2 pb-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                            $t('frontend_affiliate_index_iban')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-2">
                                            {{
                                                selectedAffiliate.affiliate_wire_info.main_bank_iban
                                            }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
                    <div v-if="markAsPaid.gatewayType != '' && selectedAffiliate.processed_without_advances > 0">
                        <div class="flex">
                            <label class="font-semibold text-grey-700 mb-2">{{
                                $t('frontend_affiliate_index_mark_as')
                            }}</label>
                        </div>
                        <div v-for="(markAsPaidType, index) in markAsPaidTypes" :key="index" class="flex mr-4">
                            <gd-custom-radio v-model="markAsPaid.typeOfPayment" :option="markAsPaidType"></gd-custom-radio>
                        </div>
                        <div class="mt-2 flex flex-row w-full justify-center">
                            <gd-button size="auto" variant="primary" class="mb-4" @click="processPayment">{{
                                $t('frontend_affiliate_process_payment') }}</gd-button>
                        </div>
                    </div>
                    <div v-else class="w-full h-full flex justify-center items-center">
                        <div class="text-sm text-deep-dark">
                            <span class="text-red font-bold">{{ $t('frontend_analytics_transactions_note') }}</span> {{
                                $t('frontend_affiliate_index_process_payment_disabled_info_note') }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';

export default {
    components: {
        PaypalIcon,
        CreditCardIcon,
        BankIcon,
    },
    props: {
        selectedAffiliate: {
            type: Object,
            default: () => { }
        },
        processPayment: {
            type: Function,
            default: () => { }
        }
    },
    methods: {

    },
    watch: {
        markAsPaid: {
            handler: function (val, oldVal) {
                this.$emit('mark-as-paid-update', val)
            },
            deep: true
        }
    },
    data() {
        return {
            markAsPaidTypes: [
                { id: 1, title: this.$t('frontend_in_process'), description: '' },
                { id: 2, title: this.$t('frontend_mark_as_paid'), description: '' },
                { id: 3, title: this.$t('frontend_mark_as_paid_after_24'), description: '' },
                { id: 4, title: this.$t('frontend_mark_as_paid_after_48'), description: '' },
            ],
            markAsPaid: {
                comment: '',
                transactionId: '',
                gatewayType: '',
                typeOfPayment: 1,
            },
        }
    }
}
</script>