<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4"> <gd-tabs
                :tabs="commissionOverridePricePointsTabs" :error-tabs="commissionOverridePricePointsErrorTabs"
                :current-tab="commissionOverridePricePointsTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleCommissionOverridePricePointsTabChange" />
        </div>
        <div class="mt-2">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="commissionOverridePricePointsTab === 'commission_override_price_point_' + pricePoint.id">
                    <div v-if="pricePoint.commission_details.filter((obj) => { return obj.type != 1; }).length > 0"
                        class="flex flex-col w-full">
                        <div v-for="(commissionTier, commissionTierIndex) in pricePoint.commission_details.filter((obj) => { return obj.type != 1; })"
                            :key="commissionTierIndex" class="flex flex-col w-full mb-6">
                            <hr v-if="commissionTierIndex > 0" class="border-grey-100 -mx-4" />
                            <div class="flex flex-row w-full mt-2">
                                <div class="w-1/2"> <gd-button v-if="commissionTierIndex == 0" size="auto" variant="primary"
                                        class="mt-3" @click="createCommissionOverride(index)">
                                        <plus-icon class="h-5 w-5 fill-current" />
                                         {{ $t('frontend_add_new_commission_override') }}</gd-button> </div>
                                <div class="w-1/2 flex flex-row-reverse"> <gd-button variant="outline" size="icon" class="mr-2"
                                        @click="openConfirmModal(index, commissionTierIndex)">
                                    <trash-icon class="h-5 w-5 fill-current"/>
                                </gd-button> </div>
                            </div>

                            <p class="text-lg w-full mb-4 mt-4"> {{ $t('frontend_add_commission_override') }}{{
                                commissionTierIndex + 1 }} </p>

                            <div class="w-full flex flex-col">
                                <div class="w-full flex flex-col mb-8">
                                    <div class="w-full flex flex-row mb-2"> <label class="w-full text-grey-dark text-sm"> {{
                                        $t('frontend_name') }} </label> </div>
                                    <div class="w-1/2 flex flex-col"> <text-input v-model="commissionTier.name"
                                            :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].name"
                                            value="commissionTier.name"
                                            :placeholder="$t('frontend_product_step9_commision_overrides')" /> </div>
                                </div>

                                <div v-if="pricePoint.is_trial == 1" class="w-full flex flex-col mb-8">
                                    <div class="w-full flex flex-row mb-2"> <label
                                            class="w-full text-grey-dark font-bold">{{
                                                $t('frontend_product_step9_initial_charge') }}</label> </div>
                                    <div class="w-1/2 flex flex-col">
                                        <div class="w-1/2 flex flex-col"> <label class="w-full text-grey-dark text-sm">{{
                                            $t('frontend_product_step9_affiliate_tier1') }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-1/3 mr-2"> <text-input
                                                        v-model="commissionTier.trial_commission.commission" class="w-1/3"
                                                        :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].trial_commission.commission"
                                                        :attribute="$t('frontend_product_step9_commision')"
                                                        value="commissionTier.trial_commission.commission"
                                                        :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                <gd-dropdown
                                                    v-model="commissionTier.trial_commission.commission_type"
                                                    :options="commissionTypeOptions"
                                                />
                                            </div>
                                        </div>
                                        <div class="w-1/2 flex flex-col mt-4"> <label
                                                class="w-full text-grey-dark text-sm">{{
                                                    $t('frontend_product_step9_jv_broker_tier2') }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-1/3 mr-2"> <text-input
                                                        v-model="commissionTier.trial_commission.jv_broker_commission"
                                                        :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].trial_commission.jv_broker_commission"
                                                        :attribute="$t('frontend_product_step9_commision')"
                                                        value="commissionTier.trial_commission.jv_broker_commission"
                                                        :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                <gd-dropdown
                                                    v-model="commissionTier.trial_commission.jv_broker_commission_type"
                                                    :options="commissionTypeOptions"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="pricePoint.type == 1" class="w-full flex flex-col mb-8">
                                    <div class="w-full flex flex-row mb-2"> <label
                                            class="w-full text-grey-dark font-bold">{{
                                                $t('frontend_product_step9_sales_commision') }}</label> </div>
                                    <div class="w-1/2 flex flex-col">
                                        <div class="w-1/2 flex flex-col"> <label class="w-full text-grey-dark text-sm">{{
                                            $t('frontend_product_step9_affiliate_tier1') }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-1/3 mr-2"> <text-input
                                                        v-model="commissionTier.sales_commission.commission"
                                                        :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].sales_commission.commission"
                                                        :attribute="$t('frontend_product_step9_commision')"
                                                        value="commissionTier.sales_commission.commission"
                                                        :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                <gd-dropdown
                                                    v-model="commissionTier.sales_commission.commission_type"
                                                    :options="commissionTypeOptions"
                                                />
                                            </div>
                                        </div>
                                        <div class="w-1/2 flex flex-col mt-4"> <label
                                                class="w-full text-grey-dark text-sm">{{
                                                    $t('frontend_product_step9_jv_broker_tier2') }}</label>
                                            <div class="w-full flex flex-row">
                                                <div class="w-1/3 mr-2"> <text-input
                                                        v-model="commissionTier.sales_commission.jv_broker_commission"
                                                        :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].sales_commission.jv_broker_commission"
                                                        :attribute="$t('frontend_product_step9_commision')"
                                                        value="commissionTier.sales_commission.jv_broker_commission"
                                                        :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                <gd-dropdown
                                                    v-model="commissionTier.sales_commission.jv_broker_commission_type"
                                                    :options="commissionTypeOptions"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="pricePoint.type != 1">
                                    <div class="w-full flex flex-row mb-2"> <label
                                            class="w-full text-grey-dark font-bold">{{
                                                $t('frontend_product_step9_recurring_transactios') }}</label> </div>
                                    <div v-for="(commissionDetail, commissionDetailIndex) in commissionTier.rebill_commissions"
                                        :key="commissionDetailIndex" class="w-full flex flex-col mb-4">
                                        <div class="w-full flex flex-row mb-2"> <label
                                                class="text-grey-dark text-sm mr-2 mt-2">{{ $t('frontend_product_step9_for')
                                                }}</label> <no-head-text-input v-model="commissionDetail.no_of_payments"
                                                class="mr-2 border-b w-24"
                                                :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].rebill_commissions.$each[commissionDetailIndex].no_of_payments"
                                                :attribute="$t('frontend_groovesell_routes_payments')"
                                                value="commissionDetail.no_of_payments" placeholder /> <label
                                                class="text-grey-dark text-sm mr-2 mt-2">{{
                                                    $t('frontend_product_step9_charges') }}</label> <gd-button variant="outline" size="icon"
                                                class="mr-2" :title="$t('frontend_add')"
                                                @click="addRecurringCommission(index, commissionTierIndex)"> 
                                                <plus-icon class="h-5 w-5 fill-current" />
                                             </gd-button> <gd-button variant="outline"
                                                v-if="commissionTier.rebill_commissions.length > 1" size="icon" class="mr-2"
                                                :title="$t('frontend_delete')"
                                                @click="deleteRecurringCommission(index, commissionTierIndex, commissionDetailIndex)">
                                                <minus-icon class="h-5 w-5 fill-current" /> </gd-button> </div>
                                        <div class="w-1/2 flex flex-col">
                                            <div class="w-1/2 flex flex-col"> <label
                                                    class="w-full text-grey-dark text-sm">{{
                                                        $t('frontend_product_step9_affiliate_tier_1') }}</label>
                                                <div class="w-full flex flex-row">
                                                    <div class="w-1/3 mr-2"> <text-input
                                                            v-model="commissionDetail.commission"
                                                            :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].rebill_commissions.$each[commissionDetailIndex].commission"
                                                            :attribute="$t('frontend_product_step9_commision')"
                                                            value="commissionDetail.commission"
                                                            :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                    <gd-dropdown
                                                        v-model="commissionDetail.commission_type"
                                                        :options="commissionTypeOptions"
                                                    />
                                                </div>
                                            </div>
                                            <div class="w-1/2 flex flex-col mt-4"> <label
                                                    class="w-full text-grey-dark text-sm">{{
                                                        $t('frontend_product_step9_jv_broker_tier2') }}</label>
                                                <div class="w-full flex flex-row">
                                                    <div class="w-1/3 mr-2"> <text-input
                                                            v-model="commissionDetail.jv_broker_commission"
                                                            :validator="validation.localModel.pricing_points.$each[index].commission_details.$each[commissionTierIndex].rebill_commissions.$each[commissionDetailIndex].jv_broker_commission"
                                                            :attribute="$t('frontend_product_step9_commision')"
                                                            value="commissionDetail.jv_broker_commission"
                                                            :placeholder="$t('frontend_product_step9_commision')" /> </div>
                                                    <gd-dropdown
                                                        v-model="commissionDetail.jv_broker_commission_type"
                                                        :options="commissionTypeOptions"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-col w-1/2 mb-6">
                                    <div class="w-1/2"> <gd-button v-if="commissionTier && commissionTier.assigned_to"
                                            size="auto" variant="primary" class="mt-3"
                                            @click="openCommissionOverridesAffModal(index, commissionTierIndex)"> {{
                                                $t('frontend_product_step9_available_to') }} {{
        commissionTier.assigned_to.length }} {{ $t('frontend_affiliates') }}
                                        </gd-button> <gd-button v-else size="auto" variant="primary" class="mt-3"
                                            @click="openCommissionOverridesAffModal(index, commissionTierIndex)">{{
                                                $t('frontend_product_step9_available_to') }}</gd-button> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex flex-col w-full mt-4">
                        <div class="flex flex-row w-full">
                            <div class="w-1/2">
                                <gd-button size="auto" variant="primary" class="mt-3" @click="createCommissionOverride(index)">
                                    <plus-icon class="h-5 w-5 fill-current"/>
                                    {{ $t('frontend_product_step9_add_to_new') }}
                                </gd-button> </div>
                        </div> <label class="mt-2 text-grey-dark">{{ $t('frontend_product_step9_no_commision_override')
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="confirm-delete-commission-modal"
            :message-content="$t('frontend_non_revertable')"
            :confirm-button-text="$t('frontend_delete_confirm')"
            @confirm="deleteCommissionTier"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import MinusIcon from '@/assets/images/icons/MinusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
        MinusIcon,
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        validation: {
            required: true
        },
        openCommissionOverridesAffModal: {
            type: Function,
            required: true
        },

    },
    mounted() {
        if (this.commissionOverridePricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.commissionOverridePricePointsTab = `commission_override_price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    computed: {
        commissionTypeOptions() {
            return [
                { value: 'percent', text: this.$t('frontend_product_step9_percentage') },
                { value: 'fixed', text: this.$t('frontend_product_step9_fixed_amount') },
            ];
        },
        commissionOverridePricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `commission_override_price_point_${this.localModel.pricing_points[i].id}`,
                });
            }

            return pricePointTabs;
        },
        commissionOverridePricePointsErrorTabs() {
            const pricePointErrorTabs = [];
            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (
                    this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error
                ) {
                    pricePointErrorTabs.push(
                        `commission_override_price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`
                    );
                }
            }

            return pricePointErrorTabs;
        },
    },
    watch: {
        model: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true
        }
    },
    data() {
        return {
            localModel: this.model,
            commissionOverridePricePointsTab: '',
            pricePointIndex: null,
            commissionTierIndex: null,
        }
    },
    methods: {
        handleCommissionOverridePricePointsTabChange(tab) {
            this.commissionOverridePricePointsTab = tab;
        },
        createCommissionOverride(index) {
            const commision_id = Math.ceil(Math.random() * 1000);
            const { length } = this.localModel.pricing_points[index].commission_details;
            const commission_name = `${this.$t('frontend_add_commission_override')} #${length}`;
            this.localModel.pricing_points[index].commission_details.push({
                id: commision_id,
                type: 2,
                name: commission_name,
                assigned_to: null,
                sales_commission: {
                    commission: 0,
                    commission_type: 'percent',
                    jv_broker_commission: 0,
                    jv_broker_commission_type: 'percent',
                },
                trial_commission: {
                    commission: 0,
                    commission_type: 'percent',
                    jv_broker_commission: 0,
                    jv_broker_commission_type: 'percent',
                },
                rebill_commissions: [
                    {
                        commission: 50,
                        no_of_payments: null,
                        commission_type: 'percent',
                        jv_broker_commission: 0,
                        jv_broker_commission_type: 'percent',
                    },
                ],
            });
        },
        openConfirmModal(index, commissionTierIndex) {
            this.$root.$emit('modal-open', 'confirm-delete-commission-modal');
            this.pricePointIndex = index;
            this.commissionTierIndex = commissionTierIndex;
        },
        async deleteCommissionTier() {
            this.localModel.pricing_points[this.pricePointIndex].commission_details.splice(
                this.commissionTierIndex + 1,
                1
            );
        },
        addRecurringCommission(index, commissionTierIndex) {
            this.localModel.pricing_points[index].commission_details[commissionTierIndex].rebill_commissions.push({
                commission: 50,
                no_of_payments: null,
                commission_type: 'percent',
            });
        },

        deleteRecurringCommission(index, commissionTierIndex, commissionDetailIndex) {
            this.localModel.pricing_points[index].commission_details[commissionTierIndex].rebill_commissions.splice(commissionDetailIndex, 1);
        },

    },

}
</script>