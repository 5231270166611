<template>
    <div>
        <div>
            <div class=" flex flex-row mb-2 border-grey-100 border -mt-4 -mx-4 px-4">
                <gd-tabs :tabs="affiliatePaymentSettingsTabs" :current-tab="affiliatePaymentSettingsTab"
                    :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                    :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                    :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiiatePaymentSettingsTabChange" />
            </div>
            <div class="mt-2">
                <div v-if="affiliatePaymentSettingsTab === 'paypal'">
                    <div class="flex flex-col w-1/2 mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_affiliate_settings_paypal_email_id')
                        }}</label>
                        <text-input v-model="localForm.affiliate_paypal_email" value="localForm.affiliate_paypal_email"
                            placeholder="email@paypal.com" />
                    </div>
                </div>
                <div v-else-if="affiliatePaymentSettingsTab === 'wire'">
                    <div class="flex flex-col w-full mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_affiliate_settings_account_name')
                        }}</label>
                        <text-input v-model="localForm.affiliate_wire_info.account_name"
                            value="localForm.affiliate_wire_info.account_name" :placeholder="$t('frontend_affiliate_settings_account_name')
                                " />
                    </div>
                    <div class="flex flex-col w-full mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_affiliate_settings_address')
                        }}</label>
                        <text-input v-model="localForm.affiliate_wire_info.address"
                            :value="localForm.affiliate_wire_info.address"
                            :placeholder="$t('frontend_affiliate_settings_address')" />
                    </div>
                    <div class="flex flex-row w-full mb-6">
                        <div class="flex flex-col mr-2 w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_city')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.city"
                                value="localForm.affiliate_wire_info.city"
                                :placeholder="$t('frontend_affiliate_settings_city')" />
                        </div>
                        <div class="flex flex-col w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_state')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.state"
                                value="localForm.affiliate_wire_info.state"
                                :placeholder="$t('frontend_affiliate_settings_state')" />
                        </div>
                    </div>

                    <div class="flex flex-row w-full mb-6">
                        <div class="flex flex-col mr-2 w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_bank_name')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_name"
                                value="localForm.affiliate_wire_info.main_bank_name" :placeholder="$t('frontend_affiliate_settings_bank_name')
                                    " />
                        </div>
                        <div class="flex flex-col w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_account_number')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_account_no"
                                value="localForm.affiliate_wire_info.main_bank_account_no" :placeholder="$t('frontend_affiliate_settings_account_number')
                                    " />
                        </div>
                    </div>
                    <div class="flex flex-row w-full mb-6">
                        <div class="flex flex-col mr-2 w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_postal_code')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.postal_code"
                                value="localForm.affiliate_wire_info.postal_code" :placeholder="$t('frontend_affiliate_settings_postal_code')
                                    " />
                        </div>
                        <div class="flex flex-col w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_country')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.country"
                                value="localForm.affiliate_wire_info.country"
                                :placeholder="$t('frontend_affiliate_settings_country')" />
                        </div>
                    </div>
                    <div class="flex flex-col w-full mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_affiliate_settings_bank_address')
                        }}</label>
                        <text-input v-model="localForm.affiliate_wire_info.main_bank_address"
                            value="localForm.affiliate_wire_info.main_bank_address" :placeholder="$t('frontend_affiliate_settings_bank_address')
                                " />
                    </div>
                    <div class="flex flex-row w-full mb-6">
                        <div class="flex flex-col mr-2 w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_routing_number')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_routing_no"
                                value="localForm.affiliate_wire_info.main_bank_routing_no" :placeholder="$t('frontend_affiliate_settings_routing_number')
                                    " />
                        </div>
                        <div class="flex flex-col w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_swift')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_swift_code"
                                value="localForm.affiliate_wire_info.main_bank_swift_code"
                                :placeholder="$t('frontend_affiliate_settings_swift')" />
                        </div>
                    </div>
                    <div class="flex flex-row w-full mb-6">
                        <div class="flex flex-col mr-2 w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_aba')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_aba"
                                value="localForm.affiliate_wire_info.main_bank_aba"
                                :placeholder="$t('frontend_affiliate_settings_aba')" />
                        </div>
                        <div class="flex flex-col w-1/2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_affiliate_settings_iban')
                            }}</label>
                            <text-input v-model="localForm.affiliate_wire_info.main_bank_iban"
                                value="localForm.affiliate_wire_info.main_bank_iban"
                                :placeholder="$t('frontend_affiliate_settings_iban')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="border-grey-100 -mx-4" />
        <div class="flex flex-row-reverse pr-2 pb-4">
            <gd-button variant="primary" size="md" class="mt-6" @click="saveAffiliateSettings">{{
                $t('frontend_save') }}</gd-button>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        form: {
            required: true
        },
        saveAffiliateSettings: {
            required: true,
            type: Function
        }
    },
    data() {
        return {
            localForm: this.form,
            affiliatePaymentSettingsTabs: [
                {
                    title: this.$t('frontend_affiliate_settings_paypal'),
                    value: 'paypal',
                },
                {
                    title: this.$t('frontend_affiliate_settings_wire'),
                    value: 'wire'
                },
            ],
            affiliatePaymentSettingsTab: 'paypal',
        }
    },
    watch: {
        localForm: {
            handler: function (val, oldVal) {
                console.log('Updating form:', val);
                this.$emit('update-form', val);
            },
            deep: true
        },
       
    },

    methods: {
        handleAffiiatePaymentSettingsTabChange(newTab) {
            this.affiliatePaymentSettingsTab = newTab;
        },
    }

}
</script>