<template>
  <div
    class="
      md:flex
      mx-6
      md:mx-auto
      mt-6
      w-full
      bg-white
      rounded rounded-lg
      border border-grey-200
      info-panel-wrapper-inner
    "
  >
  
    <div class="relative md:w-64 h-52 border-r border-grey-200">
      <gd-tag
        size="sm"
        class="absolute top-2 right-2"
        :variant="funnel.is_live == 1 ? 'success' : 'warning'"
      >
        {{ funnel.is_live == 1 ? 'Live' : 'Draft' }}
      </gd-tag>
      <div
        class="
          absolute
          w-full
          h-52
          rounded-l-lg
          bg-grey-transparent
          show_panel
          opacity-0
          flex
          items-center
          justify-center
        "
      >
     
        <gd-button variant="primary" size="auto" @click="sendToDashboard">
          {{ $t('frontend_groovesell_manage_product_funnel') }}
        </gd-button>
      </div>
      <div class="w-full h-full flex justify-center items-center text-primary py-6">
        <img
          class="h-full w-full object-contain"
          v-if="funnelImage"
          :src="funnelImage"
        />
        <gifts-icon v-else class="h-full w-full fill-current" />
      </div>
    </div>
    <div
      class="
        w-full
        h-52
        flex flex-col
        justify-between
        md:flex-1
        pl-4
        rounded-lg
        p-5
      "
    >
      <div>
        <div class="flex items-center w-full">
          <div class="w-3/4">
            <p class="text-lg text-dark font-bold mr-auto" v-line-clamp:27="1">
              {{ funnel.name }}
             
            </p>
          </div>
          <div class="w-1/4 flex flex-row justify-end">
            <drop-menu :menu-items="menuItems" small class="-mt-4"></drop-menu>
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-3/4">
            <p class="text-sm text-grey-700" v-line-clamp:21="2">
              <span class="font-bold"> Internal Name : </span>
              {{ funnel.internal_name }}
            </p>
          </div>
          <div class="w-1/4 flex flex-row justify-end">
            <p class="text-sm text-grey-700" v-line-clamp:21="2">
              {{ funnel.all_products.length }} {{ $t('frontend_products') }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-3/4">
            <p class="text-sm text-grey-700 mt-2" v-line-clamp:21="2">
              <span class="font-bold"> {{ $t('frontend_desc') }} : </span>
              {{ funnel.description }}
            </p>
          </div>
          <div class="w-1/4 flex flex-row justify-end">
            <p class="text-xs text-grey-500 mt-2">
              {{ $t('frontend_groovevideo_videos_index_updated') }}
              {{ funnel.last_updated_on }}
            </p>
          </div>
        </div>
      </div>
      <div class="show_panel opacity-0">
        <div class="flex items-center gap-x-4 text-grey-600">
          <span v-for="(option, index) in menuItemsIcons" :key="index">
            <span
              class="fill-current cursor-pointer"
              @click="option.callback()"
              v-tooltip="option.text"
            >
              <component :is="option.icon" class="w-5 h-5" />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import ToolsIcon from '@/assets/images/icons/ToolsIcon.svg';
import TrophyIcon from '@/assets/images/icons/TrophyIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import DocumentIcon from '@/assets/images/icons/DocumentIcon.svg';
import GdTag from '@groovepages/gd-ui-kit/components/Tag';

export default {
  name: 'FunnelListAsset',
  components: {
    GiftsIcon,
    GdTag,
  },
  props: {
    funnel: {},
  },
  computed: {
    funnelImage() {
      let funnelImage = false;

      if (
        this.funnel.main_product &&
        this.funnel.main_product.checkout_page_options.product_image &&
        this.funnel.main_product.checkout_page_options.product_image != ''
      )
        funnelImage =
          this.funnel.main_product.checkout_page_options.product_image;

      return funnelImage;
    },
    menuItemsIcons() {
      const menuItemsIcons = [];
      menuItemsIcons.push({
        icon: GiftsIcon,
        text: this.$t('frontend_funnels_manage_products'),
        callback: () => this.$emit('onManageProducts', this.funnel.id),
      });
      menuItemsIcons.push({
        icon: MegaphoneIcon,
        text: this.$t('frontend_funnels_affiliate_signup_promo_tools'),
        callback: () => this.$emit('onManagePromotools', this.funnel.id),
      });
      menuItemsIcons.push({
        icon: ToolsIcon,
        text: this.$t('forntend_checkout_links'),
        callback: () => this.$router.push({ name: 'productsCheckoutLinks', params: { id: this.funnel.id, product_id: this.funnel.main_product_id } }),
      });
      menuItemsIcons.push({
        icon: TrophyIcon,
        text: this.$t('frontend_leaderboards'),
        callback: () => this.$router.push({ name: 'leader_boards' }),
      });
      menuItemsIcons.push({
        icon: HandshakeIcon,
        text: this.$t('frontend_gduikit_sidebar_manage_affiliates'),
        callback: () => this.$router.push({ name: 'affiliates', query: { funnel_id: this.funnel.id} }),
      });
      menuItemsIcons.push({
        icon: DocumentIcon,
        text: this.$t('frontend_gduikit_sidebar_reports'),
        callback: () => this.$router.push({ name: 'vendor_report', query: { funnel_id: this.funnel.id} }),
      });
      return menuItemsIcons;
    },
    menuItems() {
      const menuItems = [];
      menuItems.push({
        text: this.$t('frontend_funnels_settings'),
        callback: () => this.$emit('onEditFunnel', this.funnel.id),
      });

  
      menuItems.push({
        text: this.$t('frontend_funnels_clone'),
        callback: () => this.$emit('onCloneFunnels', this.funnel.id),
      });
      menuItems.push({
        text: this.$t('frontend_funnels_delete'),
        callback: () => this.$emit('onDeleteFunnels', this.funnel.id),
      });

      return menuItems;
    },
  },
  methods: {
    backgroundImageUrl(url) {
      if (url) {
        return `url("${url}")`;
      }

      return '';
    },
    sendToManageProducts() {
      this.$emit('onManageProducts', this.funnel.id);
    },
    sendToDashboard() {
      this.$router.push({ name: 'funnelDashboard', params: { id: this.funnel.id } });
    },
  },
};
</script>
