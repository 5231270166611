<template>
    <gd-modal name="leaderboards-add-prize-modal" :title="$t('frontend_groovesell_add_prize')" height="80%" width="80%"
        :no-overflow="true" allowOverflow>
        <div class="flex flex-row m-3 mb-1">
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }}</label>
                <text-input v-model="localPricePoint.pricePointName" :attribute="$t('frontend_name')"
                    :value="localPricePoint.pricePointName" :validator="$v.localPricePoint.pricePointName"
                    :placeholder="$t('frontend_name')" />
            </div>
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_value') }}</label>
                <gd-currency-input v-model="localPricePoint.pricePointValue" :currency-code="localPricePoint.currency" />
            </div>
        </div>

        <div class="flex flex-row m-3 mb-1">
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_position') }}</label>
                <div>
                    <gd-dropdown
                        v-model="localPricePoint.pricePositionOptionsValue"
                        class="w-full"
                        max-height="165px"
                        :options="pricePositionOptions"
                    />
                    <div v-if="$v.localPricePoint.pricePositionOptionsValue.$error" class="text-red text-sm">
                        {{
                            $t('frontend_plugin_groovesell_pricePositionOptionsValue_error')
                        }}
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-1/2 mr-2 mb-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">Thumbnail</label>
                <image-picker v-model="localPricePoint.prize_image" :primary="true"></image-picker>
            </div>
        </div>

        <div class="flex justify-end mr-5 mb-5">
            <gd-button class="mb-5" variant="primary" @click="addPrize">
                <plus-icon class="w-5 h-5 fill-current" />
                {{ $t('frontend_groovesell_add_prizes') }}
            </gd-button>
        </div>
    </gd-modal>
</template>
<script>
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        ImagePicker,
        PlusIcon,
    },
    validations: {
        localPricePoint: {
            pricePointValue: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(255),
            },

            pricePointName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(255),
            },

            pricePositionOptionsValue: {
                required,
            },
        },
    },
    props: {
        pricePositionOptions: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            localPricePoint: {
                pricePointName: '',
                pricePointValue: 0,
                pricePositionOptionsValue: '1',
                prize_image: '',
                currency: 'USD'
            },
        }
    },
    methods: {

        addPrize() {
            this.$v.localPricePoint.pricePointValue.$touch();
            this.$v.localPricePoint.pricePointName.$touch();
            this.$v.localPricePoint.pricePositionOptionsValue.$touch();

            if (this.$v.localPricePoint.pricePointName.$error || this.$v.localPricePoint.pricePointValue.$error || this.$v.localPricePoint.pricePositionOptionsValue.$error) {
                return;
            }

            const data = {
                pricePointValue: this.localPricePoint.pricePointValue,
                pricePointName: this.localPricePoint.pricePointName,
                prize_image: this.localPricePoint.prize_image,
                pricePositionOptionsValue: this.localPricePoint.pricePositionOptionsValue,
            }
            this.$emit('update-attributes', {
                key: 'leaderBordPricingData',
                value: data,
                action: 'push'

            })

            // remove selected position from dropdown
            const pricePointOptionsArr = this.pricePositionOptions;
            const indexOfPricePosition = pricePointOptionsArr.indexOf(this.localPricePoint.pricePositionOptionsValue.toString());
            pricePointOptionsArr.splice(indexOfPricePosition, 1);
            this.$emit('update-attributes', {
                key: 'pricePositionOptions',
                value: pricePointOptionsArr,
            })

            this.$v.localPricePoint.pricePointValue.$reset();
            this.$v.localPricePoint.pricePointName.$reset();
            this.$v.localPricePoint.pricePositionOptionsValue.$reset();


            this.localPricePoint = {
                pricePointName: '',
                pricePointValue: 0,
                pricePositionOptionsValue: '',
                prize_image: '',
                currency: 'USD'
            }
            this.$root.$emit('modal-close', 'leaderboards-add-prize-modal');
        },
    }
}
</script>
