<template>
    <gd-modal name="block-leaderboard-affiliates" :title="$t('frontend_groovesell_disqualify_affiliates')" height="80%"
        width="80%" :no-overflow="true" allowOverflow>
        <div class="px-4">
            <div class="flex pt-2 pr-2">
                <div class="w-1/2 flex flex-row pl-2" v-if="leaderboard">
                    <affiliates-auto-suggest :funnel="leaderboard.funnel_id"
                        :button="$t('frontend_groovesell_add_affiliates')" :excluded="selectedAffiliateBlockedIdArr"
                        @onAffiliateAdded="addedAffiliate_block" />
                </div>
            </div>

            <div class="flex mt-2 pt-2 pr-2">
                <div class="w-full flex flex-row" v-if="leaderboard">
                    <affiliate-list-view :blockAffFinalArr="blockAffFinalArr" :funnel="leaderboard.funnel_id"
                        :disqualify-type="true" :affiliate-ids="suggestionSelectedAffiliates_block_aff"
                        :positions="leaderboard.max_affiliates" @onAffiliateRemoved="removedAffiliate_block"
                        @updateType="updateDisqualType" @updatePosition="updateDisqualPosition" />
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-end mr-4 mt-4">
            <gd-button variant="primary" size="auto" class="mr-4 mb-4" @click="saveLeaderBoardBlockedAff">{{
                $t('frontend_groovesell_button_save') }}</gd-button>
        </div>
    </gd-modal>
</template>
<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';
export default {
    components: {
        AffiliatesAutoSuggest,
        AffiliateListView
    },
    props: {
        leaderboard: {
            required: true
        },
        blockAffFinalArr: {
            required: true
        },
        selectedAffiliateBlockedIdArr: {
            required: true
        },
       
        suggestionSelectedAffiliates_block_aff: {
            required: true
        }

    },
    methods: {
        async saveLeaderBoardBlockedAff() {
            this.$loader.start('saveLeaderBoardBlockedAff');
            const blockAffFinalArr = this.blockAffFinalArr;
            blockAffFinalArr.map((value) => {
                if (value.type != 2) {
                    value.position = 1;
                }
            });
            this.$emit('update-attributes', {
                key: "blockAffFinalArr",
                value: blockAffFinalArr,
            })
            const leaderboard = this.leaderboard;
            leaderboard.blocked_affiliates = blockAffFinalArr;
            await leaderboard.save();
            this.$emit('update-attributes', {
                key: "leaderboard",
                value: leaderboard,
            })
            this.$root.$emit('modal-close', 'block-leaderboard-affiliates');
            this.$loader.end('saveLeaderBoardBlockedAff');
        },
        updateDisqualPosition(options) {
            const blockAffFinalArr = this.blockAffFinalArr;
            blockAffFinalArr[options.index].position = parseInt(options.value);
            this.$emit('update-attributes', {
                key: "blockAffFinalArr",
                value: blockAffFinalArr,
            })
        },
        updateDisqualType(options) {
            const blockAffFinalArr = this.blockAffFinalArr;
            blockAffFinalArr[options.index].type = parseInt(options.value);
            this.$emit('update-attributes', {
                key: "blockAffFinalArr",
                value: blockAffFinalArr,
            })
        },
        addedAffiliate_block(affiliate) {
            this.$emit('update-attributes', {
                key: "suggestionSelectedAffiliates_block_aff",
                value: affiliate.id,
                action: 'push'
            })
            const finalArr = {
                id: affiliate.id,
                type: 1,
                position: 1,
            }
            this.$emit('update-attributes', {
                key: "blockAffFinalArr",
                value: finalArr,
                action: 'push'
            })

        },
        removedAffiliate_block(affiliate) {
            const suggestionSelectedAffiliates_block_aff = this.suggestionSelectedAffiliates_block_aff.filter((value) => {
                return value !== affiliate;
            });
            this.$emit('update-attributes', {
                key: "suggestionSelectedAffiliates_block_aff",
                value: suggestionSelectedAffiliates_block_aff,
            })
            const blockAffFinalArr = this.blockAffFinalArr.filter((value) => {
                return value.id !== affiliate;
            });
            this.$emit('update-attributes', {
                key: "blockAffFinalArr",
                value: blockAffFinalArr,
            })
        },
    }

}
</script>
