<template>
    <gd-modal name="open-affiliates-export-modal" :title="$t('frontend_groovesell_export_affiliates_payment_info')"
        width="80%" bg-type="regular">
        <div class="flex">
            <div class="flex flex-col w-2/3 px-4">
                <div class="mt-2">
                    <div class="flex flex-row w-full mb-6 p-4 justify-center">
                        <div class="w-1/3 mr-4">
                            <font-awesome-radio-with-count v-model="exportAffiliates.gatewayType" option-name="Paypal"
                                option-value="1"
                                :option-enabled="exportAffiliatesResponse.paypal.count > 0"
                                :option-count="exportAffiliatesResponse.paypal.count"
                            >
                                <template slot="icon">
                                    <paypal-icon class="w-10 h-10 fill-current" />
                                </template>
                            </font-awesome-radio-with-count>
                        </div>
                        <div class="w-1/3 mr-4">
                            <font-awesome-radio-with-count v-model="exportAffiliates.gatewayType" option-name="Check"
                                option-value="2"
                                :option-enabled="exportAffiliatesResponse.check.count > 0"
                                :option-count="exportAffiliatesResponse.check.count"
                            >
                                <template slot="icon">
                                    <credit-card-icon class="w-10 h-10 fill-current" />
                                </template>
                            </font-awesome-radio-with-count>
                        </div>
                        <div class="w-1/3">
                            <font-awesome-radio-with-count v-model="exportAffiliates.gatewayType" option-name="Wire Info"
                                option-value="3"
                                :option-enabled="exportAffiliatesResponse.wire.count > 0"
                                :option-count="exportAffiliatesResponse.wire.count"
                            >
                                <template slot="icon">
                                    <bank-icon class="w-10 h-10 fill-current" />
                                </template>
                            </font-awesome-radio-with-count>
                        </div>
                    </div>
                    <div v-if="exportAffiliates.gatewayType != ''" class="flex flex-row w-full mb-4">
                        <div v-if="exportAffiliates.gatewayType == '1'" class="w-full flex flex-col">
                            <h4 class="font-semibold text-grey-700">
                                {{ $t('frontend_affiliate_index_paypal') }}
                            </h4>
                            <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                                <label class="font-semibold text-sm text-grey-700 mb-4">
                                    {{ $t('frontend_affiliate_index_no_of_affiliates') }} :
                                    <span class="text-red">
                                        {{ exportAffiliatesResponse.paypal.count }}
                                    </span>
                                </label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ $t('frontend_affiliate_index_total_amount_due') }}
                                    {{ exportAffiliatesResponse.total.amount }}
                                </label>
                                <label class="text-red font-semibold text-sm mb-8">{{ exportAffiliatesResponse.total.count }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                    $t('frontend_affiliate_index_on_hold') }}
                                    {{ exportAffiliatesResponse.on_hold.amount }}</label>
                                <label class="text-red font-semibold text-sm mb-8">{{ exportAffiliatesResponse.on_hold.count
                                }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ $t('frontend_affiliate_index_total_eligible') }}
                                    {{ exportAffiliatesResponse.paypal.amount }}
                                </label>
                                <label class="text-red font-semibold text-sm mb-2">{{ exportAffiliatesResponse.paypal.count
                                }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                            </div>
                        </div>
                        <div v-else-if="exportAffiliates.gatewayType == '2'">
                            <h4 class="font-semibold text-grey-700">
                                {{ $t('frontend_affiliate_index_check') }}
                            </h4>
                            <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{
                                        $t('frontend_affiliate_index_no_of_affiliates_who')
                                    }}
                                    <span class="text-red">
                                        {{ exportAffiliatesResponse.check.count }}
                                    </span>
                                </label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ $t('frontend_affiliate_index_total_amount') }}
                                    <span class="text-red">
                                        {{ exportAffiliatesResponse.check.amount }}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div v-else-if="exportAffiliates.gatewayType == '3'" class="w-full flex flex-col">
                            <h4 class="font-semibold text-grey-700">Wire</h4>
                            <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                                <label class="font-semibold text-sm text-grey-700 mb-4">
                                    {{
                                        $t(
                                            'frontend_affiliate_index_no_of_affiliates_who_added_wire'
                                        )
                                    }}
                                    <span class="text-red">
                                        {{ exportAffiliatesResponse.wire.count }}
                                    </span>
                                </label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ $t('frontend_affiliate_total_amount_due') }}
                                    {{ exportAffiliatesResponse.total.amount }}
                                </label>
                                <label class="text-red font-semibold text-sm mb-8">{{ exportAffiliatesResponse.total.count
                                }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                    $t('frontend_affiliate_index_on_hold') }}
                                    {{ exportAffiliatesResponse.on_hold.amount }}</label>
                                <label class="text-red font-semibold text-sm mb-8">{{ exportAffiliatesResponse.on_hold.count
                                }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                                <label class="font-semibold text-sm text-grey-700 mb-2">
                                    {{ $t('frontend_affiliate_index_total_eligible') }}
                                    {{ exportAffiliatesResponse.wire.amount }}
                                </label>
                                <label class="text-red font-semibold text-sm mb-2">{{ exportAffiliatesResponse.wire.count }}
                                    {{ $t('frontend_affiliate_index_affiliates') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
                <div class="flex flex-col">
                    <div class="flex">
                        <label class="font-semibold text-grey-700 mb-2">{{
                            $t('frontend_affiliate_index_mark_as')
                        }}</label>
                    </div>
                    <div v-for="(exportAffiliatePaymentType, index) in exportAffiliatePaymentTypes" :key="index"
                        class="flex mr-4">
                        <gd-custom-radio v-model="exportAffiliates.typeOfPayment"
                            :option="exportAffiliatePaymentType"></gd-custom-radio>
                    </div>
                    <div class="mt-2 flex flex-col w-full justify-center">
                        <gd-button v-if="exportAffiliates.gatewayType != ''" size="auto" variant="primary" class="mb-4"
                            @click="exportAffiliateDetails">
                            {{ $t('frontend_affiliates_export_affiliates') }}
                        </gd-button>
                        <gd-button v-else size="auto" disabled class="mb-4">{{
                            $t('frontend_affiliates_export_affiliates')
                        }}</gd-button>
                        <gd-button v-if="exportAffiliates.gatewayType == '1'" size="auto" variant="primary" class="mb-4"
                            @click="exportPaypalMasspay">{{
                                $t('frontend_affiliate_index_export_paypal_masspay')
                            }}</gd-button>
                    </div>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';

export default {
    components: {
        PaypalIcon,
        CreditCardIcon,
        BankIcon,
    },
    props: {
        exportAffiliatesResponse: {
            type: Object,
            default: null
        },
        exportAffiliateDetails: {
            type: Function,
            default: null
        },
        exportPaypalMasspay: {
            type: Function,
            default: null
        },
    },
    data() {
        return {
            exportAffiliates: {
                gatewayType: '',
                typeOfPayment: 2,
            },
            exportAffiliatePaymentTypes: [
                { id: 2, title: this.$t('frontend_paid'), description: '' },
                { id: 3, title: this.$t('frontend_groovesell_paid_after_24'), description: '' },
                { id: 4, title: this.$t('frontend_groovesell_paid_after_48'), description: '' },
                { id: 1, title: this.$t('frontend_in_process'), description: '' },
                { id: 5, title: this.$t('frontend_unchanged'), description: '' },
            ],
        }
    },
    watch: {
        exportAffiliates: {
            handler: function (val, oldVal) {
                this.$emit('update-export-affiliates', val);
            },
            deep: true
        }
    },
}
</script>