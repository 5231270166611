<template>
  <div class="flex flex-col">
    <div class="mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="allArticlesTabs"
        :current-tab="allArticlesTab"
        :error-tabs="allArticlesErrorTabs"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleAllArticlesTabChange"
      />
    </div>
    <div class="mt-2">
      <div v-if="allArticlesTab === 'pressReleases'">
        <div v-if="hasPressReleases" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="pressReleasesTabs"
              :current-tab="localPressReleasesTab"
              :error-tabs="pressReleasesErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handlePressReleasesTabChange"
              :enable-dragging="true"
              @updateTabOrder="handlePressReleaseTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createPressRelease"
            >{{ $t('frontend_resources_add_new') }}</gd-button>
          </div>
          <div class="mt-2">
            <div v-for="(release, index) in localFunnelResource.press_releases" :key="index">
              <div v-if="isPressReleaseTabActive(release)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="release.name"
                      :validator="$v.localFunnelResource.press_releases.$each[index].name"
                      :attribute="$t('frontend_groovesell_press_releases_name')"
                      value="release.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>
                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle
                      class="h-6 mt-2"
                      v-model="release.enabled"
                      :value="release.enabled"
                    />
                    <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index, 'deletePressRelease')">
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_press_release') }}
                    </label>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="release.press_release"
                        class="swipesEditor w-full"
                        value="release.press_release"
                        :placeholder="$t('frontend_resources_post')"
                        :validator="$v.localFunnelResource.press_releases.$each[index].press_release"
                        :attribute="$t('frontend_resources_press_release')"
                      />
                    </div>
                    <div class="flex flex-col">
                        <gd-button
                          size="auto"
                          variant="primary"
                          class="mb-4"
                          @click="addAffiliateNameToPressRelease(index)"
                        >
                          {{ $t('frontend_resources_add_affiliate_name') }}
                        </gd-button>
                        <gd-dropdown
                          :placeholder="$t('frontend_resources_add_affiliate_link')"
                          :actions="affiliateLinksActionsPressReleases(index)"
                        />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createPressRelease"
            >{{ $t('frontend_resources_add_new') }}</gd-button>
          </div>
          {{ $t('frontend_resources_you_dont_have_any_press_release') }}
        </div>
      </div>
      <div v-else-if="allArticlesTab === 'articles'">
        <div v-if="hasArticles" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="articlesTabs"
              :current-tab="localArticlesTab"
              :error-tabs="articlesErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleArticlesTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleArticlesTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createArticle"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          <div class="mt-2">
            <div v-for="(article, index) in localFunnelResource.articles" :key="index">
              <div v-if="isArticleTabActive(article)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="article.name"
                      :validator="$v.localFunnelResource.articles.$each[index].name"
                      :attribute="$t('frontend_groovesell_article_name')"
                      value="article.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>
                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle class="h-6 mt-2" v-model="article.enabled" :value="article.enabled" />
                    <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index, 'deleteArticle')">
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_article') }}
                    </label>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="article.article"
                        class="swipesEditor w-full"
                        value="article.article"
                        :placeholder="$t('frontend_resources_article')"
                        :validator="$v.localFunnelResource.articles.$each[index].article"
                        :attribute="$t('frontend_article')"
                      />
                    </div>
                    <div class="flex flex-col">
                        <gd-button
                          size="auto"
                          variant="primary"
                          class="mb-4"
                          @click="addAffiliateNameToArticle(index)"
                        >
                          {{ $t('frontend_resources_add_affiliate_name') }}
                        </gd-button>
                        <gd-dropdown
                          :placeholder="$t('frontend_resources_add_affiliate_link')"
                          :actions="affiliateLinksActionsArticles(index)"
                        />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createArticle"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_currently_dont_have_articles') }}
        </div>
      </div>
    </div>
    <confirm-modal
      name="all-articles-confirm-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="applyAction"
    />
  </div>
</template>

<script>
import generateNumberMixin from '@/mixins/generateNumberMixin';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  components: {
    TrashIcon,
  },
  mixins: [generateNumberMixin],
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    pressReleasesTab: {
      type: String,
      default: '',
    },
    articlesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      itemToDeleteIndex: null,
      actionType: '',
      localFunnelResource: this.funnelResource,
      localPressReleasesTab: this.pressReleasesTab,
      localArticlesTab: this.articlesTab,
      allArticlesTabs: [
        { title: this.$t('frontend_resources_articles'), value: 'articles' },
        {
          title: this.$t('frontend_resources_press_releases'),
          value: 'pressReleases',
        },
      ],
      allArticlesTab: 'articles',
    }
  },
  computed: {
    hasPressReleases() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.press_releases &&
        this.localFunnelResource.press_releases.length
      );
    },
    hasArticles() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.articles &&
        this.localFunnelResource.articles.length > 0
      );
    },
    allArticlesErrorTabs() {
      let allArticlesErrorTabs = [];

      if (this.$v.localFunnelResource.press_releases.$error) {
        allArticlesErrorTabs.push('pressReleases');
      }

      if (this.$v.localFunnelResource.articles.$error) {
        allArticlesErrorTabs.push('articles');
      }

      return allArticlesErrorTabs;
    },
    pressReleasesTabs() {
      let pressReleasesTabs = [];

      if (this.hasPressReleases) {
        for (let i = 0; i < this.localFunnelResource.press_releases.length; i++) {
          pressReleasesTabs.push({
            title: this.localFunnelResource.press_releases[i].name,
            value: `press_release_tab_${this.localFunnelResource.press_releases[i].id}`,
          });
        }
      }

      return pressReleasesTabs;
    },
    pressReleasesErrorTabs() {
      let pressReleasesErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.press_releases.$each.$iter) {
        if (this.$v.localFunnelResource.press_releases.$each.$iter[index].$error) {
          pressReleasesErrorTabs.push(
            `press_release_tab_${this.$v.localFunnelResource.press_releases.$each.$iter[index].$model.id}`
          );
        }
      }

      return pressReleasesErrorTabs;
    },
    articlesTabs() {
      let articlesTabs = [];

      if (this.hasArticles) {
        for (let i = 0; i < this.localFunnelResource.articles.length; i++) {
          articlesTabs.push({
            title: this.localFunnelResource.articles[i].name,
            value: `articles_tab_${this.localFunnelResource.articles[i].id}`,
          });
        }
      }

      return articlesTabs;
    },
    articlesErrorTabs() {
      let articlesErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.articles.$each.$iter) {
        if (this.$v.localFunnelResource.articles.$each.$iter[index].$error) {
          articlesErrorTabs.push(
            `articles_tab_${this.$v.localFunnelResource.articles.$each.$iter[index].$model.id}`
          );
        }
      }

      return articlesErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActionsPressReleases(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToPressRelease(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsArticles(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToArticle(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handlePressReleasesTabChange(newTab) {
      this.localPressReleasesTab = newTab || this.localPressReleasesTab;
      this.$emit('handlePressReleasesTabChange', this.localPressReleasesTab);
    },
    handleArticlesTabChange(newTab) {
      this.localArticlesTab = newTab || this.localArticlesTab;
      this.$emit('handleArticlesTabChange', this.localArticlesTab);
    },
    handleAllArticlesTabChange(newTab) {
      this.allArticlesTab = newTab;
    },
    handlePressReleaseTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('press_release_tab_', ''))
      );

      this.localFunnelResource.press_releases.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createPressRelease() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.press_releases.length + 1;

      this.localFunnelResource.press_releases.push({
        id: newPostId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_press_release')} ${newPostCount}`,
        press_release: '',
      });
      this.localPressReleasesTab = `press_release_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handlePressReleasesTabChange();
    },
    openConfirmModal(index, actionType) {
      this.itemToDeleteIndex = index;
      this.actionType = actionType;
      this.$root.$emit('modal-open', 'all-articles-confirm-delete-modal');
    },
    applyAction() {
      switch (this.actionType) {
        case 'deletePressRelease':
          this.deletePressRelease();
          break;
        case 'deleteArticle':
          this.deleteArticle();
          break;
      }
    },
    async deletePressRelease() {
      this.localFunnelResource.press_releases.splice(this.itemToDeleteIndex, 1);
      this.localPressReleasesTab = '';

      if (this.localFunnelResource.press_releases.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId = this.localFunnelResource.press_releases[nextOpenIndex].id;
        this.localPressReleasesTab = `press_release_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handlePressReleasesTabChange();
    },
    addAffiliateNameToPressRelease(index) {
      this.localFunnelResource.press_releases[
        index
      ].press_release = `${this.localFunnelResource.press_releases[index].press_release}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToPressRelease(index, landingPageId) {
      this.localFunnelResource.press_releases[
        index
      ].press_release = `${this.localFunnelResource.press_releases[index].press_release}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    handleArticlesTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('articles_tab_', ''))
      );

      this.localFunnelResource.articles.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createArticle() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.articles.length + 1;

      this.localFunnelResource.articles.push({
        id: newPostId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_article')} ${newPostCount}`,
        article: '',
      });

      this.localArticlesTab = `articles_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleArticlesTabChange();
    },
    async deleteArticle() {
      this.localFunnelResource.articles.splice(this.itemToDeleteIndex, 1);
      this.localArticlesTab = '';

      if (this.localFunnelResource.articles.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId = this.localFunnelResource.articles[nextOpenIndex].id;
        this.localArticlesTab = `articles_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleArticlesTabChange();
    },
    addAffiliateNameToArticle(index) {
      this.localFunnelResource.articles[
        index
      ].article = `${this.localFunnelResource.articles[index].article}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToArticle(index, landingPageId) {
      this.localFunnelResource.articles[
        index
      ].article = `${this.localFunnelResource.articles[index].article}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    isPressReleaseTabActive(release) {
      return this.localPressReleasesTab === `press_release_tab_${release.id}`;
    },
    isArticleTabActive(article) {
      return this.localArticlesTab === `articles_tab_${article.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      press_releases: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          press_release: {
            required,
            minLength: minLength(3),
          },
          $trackBy: 'id',
        },
      },
      articles: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          article: {
            required,
            minLength: minLength(3),
          },
          $trackBy: 'id',
        },
      },
    },
  }
};
</script>
