<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded px-2">
      <div class="mt-0">
        <div class="mt-4">
          <checkout-step-type ref="checkoutsteptype" :checkoutStepTypes="checkoutStepTypes"
            :selectedCheckoutStepType="selectedCheckoutStepType" :multiStepTypes="multiStepTypes"
            :singleStepTypes="singleStepTypes" :selectedMultiStepTypeProp="selectedMultiStepType"
            :isMultiStepRedirectProp="isMultiStepRedirectProp" :model="localModel" @updateModel="model = $event"
            @updateSelCheckoutStepType="selCheckoutStepType = $event"
            @updateselectedMultiStepType="selectedMultiStepType = $event"
            @updateMultiStepRedirect="updateMultiStepRedirectOptions($event)"
            @updateIsMultiStepRedirect="updateIsMultiStepRedirect($event)">
          </checkout-step-type>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CheckoutStepType from '@/components/CheckoutStepType';

export default {
  name: 'StepsStep',

  components: {
    CheckoutStepType,
  },

  props: {
    model: {
      required: true,
    },
    product: {
      required: true,
    },
    selectedCheckoutStepType: {
      required: true,
    },
    selectedMultiStepTypeProp: {
      required: true,
    },
    isMultiStepRedirectProp: {
      required: true,
    },
  },

  data() {
    return {
      selectedMultiStepType: this.selectedMultiStepTypeProp,
      localModel: this.model,
      selCheckoutStepType: this.selectedCheckoutStepType,

      multiStepTypes: [
        {
          id: 3,
          svg: ['Email', 'OrderForm_V2'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes3'),
        },
        {
          id: 2,
          svg: ['Email', 'NameAndBilling', 'Payment'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes2'),
        },
        {
          id: 1,
          svg: ['Contact', 'Billing', 'Payment'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes1'),
        },
      ],

      singleStepTypes: [
        {
          id: 1,
          svg: ['OrderForm_V2'],
          label: 'Order Form',
        },
      ],

      funnel_id: this.$route.params.id,
    };
  },

  validations: {},

  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },

    selCheckoutStepType() {
      this.$emit('updateSelCheckoutStepType', this.selCheckoutStepType);
    },

    selectedMultiStepType() {
      this.$emit('updateselectedMultiStepType', this.selectedMultiStepType);
    },
  },

  computed: {
    isAdmin() {
      return this.$store.state.user.admin;
    },

    checkoutStepTypes() {
      return [
        {
          id: 1,
          title: this.$t('forntend_checkout_link_step_1'),
          description: this.$t('forntend_checkout_link_step_1_desc'),
          coming_soon: false,
        },
        {
          id: 0,
          title: this.$t('forntend_checkout_link_multistep_checkout'),
          description: this.$t(
            'forntend_checkout_link_multistep_checkout_desc'
          ),
          coming_soon: false,
        }
      ];
    },
  },

  async created() {

    this.selectedMultiStepType = this.selectedMultiStepTypeProp;
    this.localModel.checkout_link_page_options = {
      ...this.localModel.checkout_link_page_options,
    };
    console.log('this.localModel', this.localModel);
  },

  methods: {
    validate() {
      this.$refs.checkoutsteptype.$v.$touch();
      const isValid = !this.$refs.checkoutsteptype.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    updateMultiStepRedirectOptions(options) {
      this.$emit('updateMultiStepRedirectOptions', options);
    },

    updateIsMultiStepRedirect(isMultistepRedirect) {
      this.$emit('updateIsMultiStepRedirect', isMultistepRedirect);
    },
  },
};
</script>