<template>
    <gd-modal name="affiliate-links-modal" :title="$t('frontend_affiliate_links')" width="60%">
        <div class="flex flex-col">
            <div v-if="affiliateLinks.length">
                <div class="flex flex-row">
                    <gd-tabs :tabs="affiliateLinksTabs" :current-tab="affiliateLinksTab"
                        :wrapper-class="'pt-4 px-2 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                        :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                        :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiliateLinksTabChange" />
                    <div class="pt-4 gs-default-tabs">
                        <div class="pb-4 mr-4 gs-default-tabs-item cursor-pointer" @click="goToPromoTools">
                            {{ $t('frontend_affiliate_promos_promo_tools') }}
                        </div>
                    </div>
                </div>

                <div>
                    <div v-if="affiliateLinksTab === 'aff_links'" style="height: calc(80vh - 15rem)">
                        <div class="mt-8 px-4 h-full max-h-full overflow-y-auto">
                            <div v-for="(affiliateLink, affiliateLinkIndexs) in directAffiliateLinks"
                                :key="affiliateLinkIndexs" class="flex flex-col mb-6">
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{ affiliateLink.name }}</label>
                                <div class="flex flex-row">
                                    <text-input v-model="affiliateLink.link" class="mr-2" :readonly="true"
                                        value="affiliateLink.link"
                                        :placeholder="$t('frontend_affiliate_promos_affiliate_link')" :select-all="true" />
                                    <gd-button v-clipboard:copy="affiliateLink.link" v-clipboard:success="onCopy"
                                        v-clipboard:error="onError" size="iconlg" variant="primary"
                                        :title="$t('frontend_affiliate_promos_copy_affiliate_link')">
                                        <copy-icon class="w-5 h-5 fill-current" />
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="affiliateLinksTab === 'jv_broker_links'" class="mt-8 px-4">
                        <div v-for="(affiliateLink, affiliateLinkIndex) in jvBrokerLinks" :key="affiliateLinkIndex"
                            class="flex flex-col mb-6">
                            <label class="font-semibold text-sm text-grey-700 mb-2">
                                {{ affiliateLink.name }}
                            </label>
                            <div class="flex flex-row">
                                <text-input v-model="affiliateLink.link" class="mr-2" :readonly="true"
                                    value="affiliateLink.link" :placeholder="$t('frontend_affiliate_promos_jv_broker_link')
                                        " :select-all="true" />
                                <gd-button v-clipboard:copy="affiliateLink.link" v-clipboard:success="onCopy"
                                    v-clipboard:error="onError" size="iconlg" variant="primary"
                                    :title="$t('frontend_affiliate_promos_copy_jv_broker_link')">
                                    <copy-icon class="w-5 h-5 fill-current" />
                                </gd-button>
                            </div>
                        </div>
                    </div>

                    <div v-if="affiliateLinksTab === 'custom_landing_pages'">
                        <div class="pt-4 pr-4 flex flex-row justify-end">
                            <gd-button size="auto" variant="primary"
                                :title="$t('frontend_groovesell_add_custom_landing_page_url')" @click="openCreate">
                                {{ $t('frontend_groovesell_add_new_landing_page') }}
                            </gd-button>
                        </div>

                        <div class="mt-4 px-4 h-full max-h-full overflow-y-auto" style="height: calc(80vh - 15rem)">
                            <div v-if="customLandingPageLinks.length">
                                <div v-for="(customLandingPageLink, customLandingPageLinkIndex) in customLandingPageLinks"
                                    :key="customLandingPageLinkIndex" class="flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">
                                        {{ customLandingPageLink.name }}
                                    </label>
                                    <div class="flex flex-row">
                                        <text-input v-model="customLandingPageLink.landing_page_url" class="mr-2"
                                            :readonly="true" value="customLandingPageLink.link"
                                            :placeholder="$t('frontend_groovesell_custom_landing_page_url')"
                                            :select-all="true" />
                                        <gd-button v-clipboard:copy="customLandingPageLink.landing_page_url"
                                            v-clipboard:success="onCopy" v-clipboard:error="onError" size="iconlg" variant="primary"
                                            :title="$t('frontend_affiliate_promos_copy_custom_landing_page_url')">
                                            <copy-icon class="w-5 h-5 fill-current" />
                                        </gd-button>

                                        <gd-button class="ml-1" size="iconlg" variant="primary" :title="$t('frontend_groovesell_edit')"
                                            @click="openEditCustomLandingPage(customLandingPageLink.id)">
                                            <pencil-icon class="w-5 h-5 fill-current" />
                                        </gd-button>

                                        <gd-button class="ml-1" size="iconlg" variant="primary"
                                            :title="$t('frontend_groovesell_delete')"
                                            @click="deleteLandingPageUrl(customLandingPageLink.id)">
                                            <trash-icon class="w-5 h-5 fill-current" />
                                        </gd-button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                {{ $t('frontend_affiliate_custom_landing_page_url_to_fetch') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                {{ $t('frontend_affiliate_promos_unable_to_fetch') }}
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Notify from '@groovepages/gd-ui-kit/notify';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
    components: {
        TrashIcon,
        PencilIcon,
        CopyIcon,
    },
    props: {
        affiliateLinks: {
            required: true,
        },
        openCreate: {
            required: true,
            type: Function,
        },
        openEditCustomLandingPage: {
            required: true,
            type: Function,
        },
        deleteLandingPageUrl: {
            required: true,
            type: Function,
        },
        selectedFunnelId: {

        },
    },
    data() {
        return {
            affiliateLinksTab: 'aff_links',

        }
    },

    computed: {
        affiliateLinksTabs() {
            const secondTierLinksLength = this.affiliateLinks.filter((link) => {
                return link.type === 'second_tier';
            }).length;

            if (secondTierLinksLength > 0) {
                return [
                    { title: this.$t('frontend_affiliate_link_tabs_afiliate_links'), value: 'aff_links' },
                    { title: this.$t('frontend_resource_my_jv_broker_links'), value: 'jv_broker_links' },
                    { title: this.$t('frontend_product_step9_custom_landing'), value: 'custom_landing_pages' },
                ];
            }

            return [
                { title: this.$t('frontend_affiliate_link_tabs_afiliate_links'), value: 'aff_links' },
                { title: this.$t('frontend_product_step9_custom_landing'), value: 'custom_landing_pages' },
            ];
        },
        directAffiliateLinks() {
            return this.affiliateLinks.filter((link) => {
                return link.type === 'affiliate';
            });
        },
        jvBrokerLinks() {
            return this.affiliateLinks.filter((link) => {
                return link.type === 'second_tier';
            });
        },
        customLandingPageLinks() {
            return this.affiliateLinks.filter((link) => {
                return link.type === 'custom_land_page';
            });
        },

    },
    methods: {

        handleAffiliateLinksTabChange(tab) {
            this.affiliateLinksTab = tab;
        },
        goToPromoTools() {
            this.$router.push({
                name: 'promos_dashboard',
                params: { id: this.selectedFunnelId },
            });
        },
        onCopy() {
            Notify.success(this.$t('frontend_aff_link_copied'));
        },

        onError() {
            Notify.fail(this.$t('frontend_aff_link_unable_copy'));
        },
    }
}

</script>