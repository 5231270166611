<template>
  <div class="max-h-full w-full h-full flex flex-col">
    <close-preview-and-spinner @closePreview="closePreview"/>

    <div class="w-full border border-solid border-grey-300 bg-white rounded-lg relative flex flex-col">
      <heading
        :heading="heading"
        :subheading="subheading"
        :thumbnail="thumbnail"
        :thumbnail-img="thumbnailImg"
        :thumbnail-is-square="thumbnailIsSquare"
        :thumbnail-size="thumbnailSize"
        :description="description"
      />

      <video-iframe
        :custom-size="true"
        :post-order-video-details="postOrderVideoDetails"
      />

      <payment-details
        :option="option"
        :currency-code="currencyCode"
        @getValueofQuantity="getValueofQuantity"
      />
    </div>

    <buy-and-no-thanks-buttons
      :buynow-text="buynowText"
      :is-processing="isProcessing"
      :product="product"
      @purchasePostSaleBump="purchasePostSaleBump"
      @clickedNoThanks="clickedNoThanks"
    />
  </div>
</template>

<script>
import Heading from './post-sale-bumps-partials/slider/Heading.vue';
import VideoIframe from './post-sale-bumps-partials/VideoIframe.vue';
import PaymentDetails from './post-sale-bumps-partials/slider/PaymentDetails.vue';
import BuyAndNoThanksButtons from './post-sale-bumps-partials/slider/BuyAndNoThanksButtons.vue';
import ClosePreviewAndSpinner from './post-sale-bumps-partials/slider/ClosePreviewAndSpinner.vue';

export default {
  name: 'SliderBumpStyle',
  components: {
    Heading,
    VideoIframe,
    PaymentDetails,
    BuyAndNoThanksButtons,
    ClosePreviewAndSpinner,
  },
  props: {
    heading: String,
    subheading: String,
    description: String,
    buynowText: String,
    option: {},
    postOrderVideoDetails: {},
    currencyCode: {
      default: '$',
    },
    thumbnail: {
      default: 0,
    },
    thumbnailImg: {},
    thumbnailIsSquare: {},
    thumbnailSize: {},
    product: {},
    isProcessing: {},
  },
  methods: {
    getValueofQuantity() {
      this.$emit('onChangedQuantity', this.option.selected_quantity);
    },
    purchasePostSaleBump() {
      this.$emit('onPurchasePostSaleBump');
    },
    clickedNoThanks() {
      this.$emit('onClickedNoThanks');
    },
    closePreview() {
      this.$emit('onClickedClosePreview');
    },
  },
};
</script>


<style>
.embed-container {
  margin-bottom: 1.1em;
  margin-top: 1.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}
.slim-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.slim-scroller::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.slim-scroller::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.bumpDescription ul {
  list-style-type: disc;
}

.bumpDescription ol {
  list-style-type: decimal;
}

.bumpDescription li {
  list-style-position: inside;
}

.bumpDescription {
  max-height: 15rem;
}

@media only screen and (max-width: 650px) {
  .bumpDescription {
    max-height: 10rem;
  }
}
</style>

<style lang="scss">
.tgl {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2em;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: sans-serif;
    font-size: 11px;
    perspective: 100px;
    &:after,
    &:before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 4px;
    }

    &:after {
      content: attr(data-tg-on);
      background: #02c66f;
      transform: rotateY(-180deg);
    }

    &:before {
      background: var(--brand-primary);
      content: attr(data-tg-off);
    }

    &:active:before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &:before {
      transform: rotateY(180deg);
    }

    &:after {
      transform: rotateY(0);
      left: 0;
      background: #15b85a;
    }

    &:active:after {
      transform: rotateY(20deg);
    }
  }
}
</style>

