<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step4_contents') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step4_you_can_add')
        }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs :tabs="contentsTabs" :current-tab="contentsTab" :error-tabs="contentsErrorTabs"
          :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleContentsTabChange" />
      </div>
      <div class="mt-2">
        <sales-page-tab v-if="contentsTab === 'salespage'" @update-model="handleUpdateModel"
          :sales_page="localModel.sales_page" :validation="$v" />

        <thankyou-page-tab v-else-if="contentsTab === 'thankyoupage'" @update-model="handleUpdateModel"
          :pricing_points="localModel.pricing_points" :validation="$v"/>

        <refund-page-tab v-else-if="contentsTab === 'refund'" @update-model="handleUpdateModel"
          :refund_period="localModel.refund_period" :waiting_period="localModel.waiting_period" :validation="$v" />

        <support-info-tab v-else-if="contentsTab === 'support_info'" :is_support_enabled="localModel.is_support_enabled"
          :support_info_type="localModel.support_info_type" :support_info_value="localModel.support_info_value"
          @update-model="handleUpdateModel" :validation="$v"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  numeric,
  url,
  minValue,
} from 'vuelidate/lib/validators';

import SalesPageTab from './tabs/contents/SalesPageTab';
import ThankyouPageTab from './tabs/contents/ThankyouPageTab';
import RefundPageTab from './tabs/contents/RefundPageTab';
import SupportInfoTab from './tabs/contents/SupportInfoTab';

export default {
  name: 'Step4',
  components: {
    SalesPageTab,
    ThankyouPageTab,
    RefundPageTab,
    SupportInfoTab,
  },
  props: {
    model: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      thankyouPageIndex: 0,
      contentsTabs: [
        {
          title: this.$t('frontend_product_step4_sales_page'),
          value: 'salespage',
        },
        {
          title: this.$t('frontend_product_step4_thankyou_page'),
          value: 'thankyoupage',
        },
        {
          title: this.$t('frontend_product_step4_refund_period'),
          value: 'refund',
        },
        {
          title: this.$t('frontend_product_step4_support_info'),
          value: 'support_info',
        },
      ],
      contentsTab: 'salespage',
    };
  },
  validations: {
    localModel: {
      sales_page: {
        required,
        url,
      },
      refund_period: {
        required,
        numeric,
        minValue: minValue(0),
      },
      waiting_period: {
        required,
        numeric,
        minValue: minValue(0),
      },
      support_info_value: {
        validSupportInfo(value, object) {
          let self = this;

          if (self.localModel.is_support_enabled) {
            if (value) {
              if (self.localModel.support_info_type == 1) {
                var pattern = new RegExp(
                  '^(https?:\\/\\/)?' + // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                  '(\\#[-a-z\\d_]*)?$',
                  'i'
                ); // fragment locator
                return !!pattern.test(value);
              } else {
                var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value.toLowerCase());
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        },
      },
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          post_checkout_details: {
            thankyou_page: {
              required,
              url,
            },
          },
        },
      },
    },
  },
  computed: {
    contentsErrorTabs() {
      let contentErrorTabs = [];

      if (this.$v.localModel.sales_page.$error) {
        contentErrorTabs.push('salespage');
      }

      if (this.$v.localModel.pricing_points.$error) {
        contentErrorTabs.push('thankyoupage');
      }

      if (
        this.$v.localModel.refund_period.$error ||
        this.$v.localModel.waiting_period.$error
      ) {
        contentErrorTabs.push('refund');
      }

      if (this.$v.localModel.support_info_value.$error) {
        contentErrorTabs.push('support_info');
      }

      return contentErrorTabs;
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  created() { },
  methods: {
    handleUpdateModel(data) {
      const key = Object.keys(data)[0];
      if(Object.keys(this.localModel).includes(key)) {
        this.localModel[key] = data[key];
      }
    },
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    handleContentsTabChange(newTab) {
      this.contentsTab = newTab;
    },
  },
};
</script>
