<template>
    <div>
        <div>
            <div class="flex flex-col w-full p-2">
                <div class="flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_legal_name') }}</label>
                    <text-input v-model="localForm.affiliate_legal_name" value="localForm.affiliate_legal_name"
                        :placeholder="$t('frontend_legal_name')" />
                </div>
                <div class="flex flex-row mt-4 w-full">
                    <div class="flex flex-col mr-2 w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_address') }}</label>
                        <text-input v-model="localForm.affiliate_legal_address" value="localForm.affiliate_legal_address"
                            :placeholder="$t('frontend_address')" />
                    </div>
                    <div class="flex flex-col w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_address') }} 2</label>
                        <text-input v-model="localForm.affiliate_legal_address_2"
                            value="localForm.affiliate_legal_address_2" :placeholder="$t('frontend_address')" />
                    </div>
                </div>
                <div class="flex flex-row mt-4 w-full">
                    <div class="flex flex-col mr-2 w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_city') }}</label>
                        <text-input v-model="localForm.affiliate_legal_city" value="localForm.affiliate_legal_city"
                            :placeholder="$t('frontend_city')" />
                    </div>
                    <div class="flex flex-col w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_state') }}</label>
                        <text-input v-model="localForm.affiliate_legal_state" value="localForm.affiliate_legal_state"
                            :placeholder="$t('frontend_state')" />
                    </div>
                </div>
                <div class="flex flex-row mt-4 w-full">
                    <div class="flex flex-col mr-2 w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_postal_code') }}</label>
                        <text-input v-model="localForm.affiliate_legal_zip_code" value="localForm.affiliate_legal_zip_code"
                            :placeholder="$t('frontend_postal_code')" />
                    </div>
                    <div class="flex flex-col w-1/2">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_country') }}</label>
                        <text-input v-model="localForm.affiliate_legal_country" value="localForm.affiliate_legal_country"
                            :placeholder="$t('frontend_country')" />
                    </div>
                </div>
                <div class="flex flex-col mt-4">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_settings_tax_id')
                    }}</label>
                    <text-input v-model="localForm.affiliate_tax_id" value="localForm.affiliate_tax_id"
                        :placeholder="$t('frontend_affiliate_settings_tax_id')" />
                </div>
                <div class="flex flex-col mt-4">
                    <div class="flex">
                        <label class="font-semibold text-grey-700 mb-2">
                            {{ $t('frontend_affiliate_settings_citizenship_status') }}
                        </label>
                    </div>
                    <fieldset class="mb-4">
                        <legend class="sr-only">{{ $t('frontend_affiliate_settings_citizenship_status') }}</legend>
                        <div class="flex flex-row w-full">
                            <label
                                class="border rounded py-3 px-3 flex items-center justify-center text-sm uppercase sm:flex-1 cursor-pointer focus:outline-none border-grey-300 mr-4 font-semibold text-center"
                                :class="{
                                    'bg-primary border-transparent text-white hover:bg-primary-dark':
                                        localForm.affiliate_tax_resident_type == citizenshipType.id,
                                    'bg-white border-grey-200 text-grey-900 hover:bg-grey-50':
                                        localForm.affiliate_tax_resident_type !=
                                        citizenshipType.id,
                                }" v-for="(citizenshipType, index) in citizenshipTypes" :key="index">
                                <input type="radio" name="citizenship-type" :value="citizenshipType.id" class="sr-only"
                                    v-model="localForm.affiliate_tax_resident_type" />
                                <p>{{ citizenshipType.title }}</p>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div class="mt-4" v-if="localForm.affiliate_tax_resident_type > 0">
                    <div class=" flex flex-row justify-between border border-grey-300 rounded p-4"
                        v-if="localForm.affiliate_tax_form">
                        <div class=" flex flex-col text-green-dark font-semibold text-lg w-1/2">
                            <span>
                                {{
                                    localForm.affiliate_tax_resident_type == 1 ||
                                    localForm.affiliate_tax_resident_type == 2
                                    ? 'W-9'
                                    : 'W-8BEN'
                                }}
                                {{ $t('frontend_form_filled_in') }}
                                <check-circle-icon class="w-5 h-5 fill-current" />
                            </span>
                        </div>
                        <div class="w-1/2 flex flex-row justify-end">
                            <gd-button size="auto" variant="primary" class="mr-2" @click="previewTaxForm">{{
                                $t('frontend_preview_file') }}</gd-button>
                            <gd-button size="auto" variant="primary" class="mr-2" @click="downloadTaxForm">{{
                                $t('frontend_download_file') }}</gd-button>
                            <gd-button size="auto" variant="primary" @click="removeTaxForm">{{ $t('frontend_remove_file')
                            }}</gd-button>
                        </div>
                    </div>
                    <div class="flex flex-col" v-else>
                        <div class="text-dark flex flex-row">
                            <div>
                                <span class="font-bold">{{ $t('frontend_step1') }}</span>
                                {{ $t('frontend_please_download') }}
                                {{
                                    localForm.affiliate_tax_resident_type == 1 ||
                                    localForm.affiliate_tax_resident_type == 2
                                    ? 'W-9'
                                    : 'W-8BEN'
                                }}
                                {{ $t('frontend_form_by') }}
                                <template
                                    v-if="localForm.affiliate_tax_resident_type == 1 || localForm.affiliate_tax_resident_type == 2">{{
                                        $t('frontend_clicking_download_w9_form') }}</template>
                                <template
                                    v-if="localForm.affiliate_tax_resident_type == 3 || localForm.affiliate_tax_resident_type == 4">{{
                                        $t('frontend_clicking_download_w8_form') }}</template>
                                <chevron-right-icon class="w-5 h-5 fill-current inline" />
                            </div>
                            <div class="-mt-1">
                                <gd-button v-if="localForm.affiliate_tax_resident_type == 1 ||
                                    localForm.affiliate_tax_resident_type == 2
                                    " size="auto" variant="primary" class="ml-2"
                                    @click="getAdminFileURL('Groove-Affiliate-W-9-localForm.pdf')">
                                {{ $t('frontend_download_w9_form') }}
                                </gd-button>
                                <gd-dropdown
                                    v-else-if="localForm.affiliate_tax_resident_type == 3 || localForm.affiliate_tax_resident_type == 4"
                                    placeholder="Download W-8BEN Form"
                                    size="full"
                                    :actions="w8TypesActions" class="ml-2">
                                </gd-dropdown>
                            </div>
                        </div>
                        <div class="text-dark mt-4">
                            <span class="font-bold">{{ $t('frontend_step2') }}</span>
                            {{ $t('frontend_fill_required_details') }}
                        </div>
                        <div class="flex flex-col w-full mt-4">
                            <div class="text-dark mb-4">
                                <span class="font-bold">  {{ $t('frontend_step3') }} </span>
                                {{ $t('frontend_upload_complete_pdf') }}
                            </div>
                            <vue-dropzone id="customdropzone" ref="myVueDropzone" :options="dropzoneOptions"
                                :use-custom-slot="true" @vdropzone-file-added="fileAdded" @vdropzone-error="() => { }"
                                @vdropzone-upload-progress="() => { }" @vdropzone-total-upload-progress="() => { }"
                                @vdropzone-success="() => { }">
                                <div class="dropzone-custom-content">
                                    <div class=" rounded-lg bgcolor flex flex-col h-40 justify-center items-center px-4">
                                        <div
                                            class=" w-full flex flex-row flex-nowrap items-center justify-center text-sm subtitle mb-1">
                                            {{
                                                $t('frontend_groovemember_files_drag_and_drop')
                                            }}
                                        </div>
                                        <div
                                            class=" w-full flex flex-row flex-nowrap items-center justify-center text-sm subtitle mb-3">
                                            - {{ $t('frontend_groovemember_files_or') }} -
                                        </div>
                                        <div class=" w-full flex flex-row flex-nowrap items-center justify-center">
                                            <gd-button variant="primary" class="mr-2">
                                                {{ $t('frontend_groovemember_select_file') }}
                                            </gd-button>
                                        </div>
                                        <div
                                            class=" text-sm subtitle mt-3 w-full flex flex-row flex-nowrap items-center justify-center">
                                           {{ $t('frontend_max_size')  }}
                                        </div>
                                    </div>
                                </div>
                            </vue-dropzone>
                            <div class="flex justify-center mt-2">
                                <gd-button v-if="this.upload_file.file" size="auto" variant="primary"
                                    @click="uploadFilesToServer">
                                    {{ $t('frontend_groovemember_files_upload_file') }}
                                </gd-button>
                                <gd-button v-else disabled size="auto">
                                    {{ $t('frontend_groovemember_files_upload_file') }}
                                </gd-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="border-grey-100 -mx-4" />
        <div class="flex flex-row-reverse pr-2 pb-4">
            <gd-button v-if="isValid" variant="primary" size="md" class="mt-6" @click="saveAffiliateSettings">{{
                $t('frontend_save')
            }}</gd-button>
            <gd-button v-else disabled size="md" class="mt-6">{{ $t('frontend_save')
            }}</gd-button>
        </div>
        <confirm-modal
            name="confirm-modal"
            :message-content="$t('frontend_swal_remove_tax_form_text')"
            :button-text="$t('frontend_swal_remove_tax_form_btn_confirm')"
            button-size="auto"
            @confirm="removeTaxForm"
        />
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import SimpleDropdown from '@/components/SimpleDropdown';
import axios from 'axios';
import CheckCircleIcon from '@/assets/images/icons/CheckCircleIcon.svg';
import ChevronRightIcon from '@/assets/images/icons/ChevronRightIcon.svg';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        SimpleDropdown,
        CheckCircleIcon,
        ChevronRightIcon,
    },
    props: {
        form: {
            required: true,
        },
        validator: {
            required: true,
        },
        saveAffiliateSettings: {
            required: true,
            type: Function,
        }

    },
    watch: {
        localForm: {
            handler() {
                this.$emit('update-form', this.localForm);
            },
            deep: true,
        },
        'localForm.affiliate_tax_resident_type': function (newVal, oldVal) {
            if (oldVal > 0 && newVal > 0) {
                if (newVal == 1 || newVal == 2) {
                    if (this.addedW9Form != '') {
                        this.localForm.affiliate_tax_form = this.addedW9Form;
                    } else {
                        this.localForm.affiliate_tax_form = '';
                    }
                } else if (newVal == 3 || newVal == 4) {
                    if (this.addedW8Form != '') {
                        this.localForm.affiliate_tax_form = this.addedW8Form;
                    } else {
                        this.localForm.affiliate_tax_form = '';
                    }
                }
            }
        },

        'localForm.affiliate_tax_form': function (newVal, oldVal) {
            if (newVal != '' && this.localForm.affiliate_tax_resident_type > 0) {
                if (this.localForm.affiliate_tax_resident_type == 1 || this.localForm.affiliate_tax_resident_type == 2) {
                    this.addedW9Form = newVal;
                } else {
                    this.addedW8Form = newVal;
                }
            }
        },
    },
    data() {
        return {
            localForm: this.form,
            addedW8Form: '',
            addedW9Form: '',
            upload_file: {
                name: '',
                file: null,
            },
            w8Types: [
                {
                    id: 'Groove-Affiliate-W-8-BEN-localForm.pdf',
                    name: 'Download W-8BEN For An Individual',
                    disabled: false,
                },
                {
                    id: 'Groove-Affiliate-W-8-BENE-localForm.pdf',
                    name: 'Download W-8BEN-E For An Entity',
                    disabled: false,
                },
            ],
            dropzoneOptions: {
                url: `${window.config.apiUrl}/upload`,
                timeout: 99999999,
                maxFiles: 1,
                maxFilesize: 2,
                autoProcessQueue: false,
                autoQueue: true,
                method: 'put',
                acceptedFiles: '.pdf',
            },
            citizenshipTypes: [
                {
                    id: 1,
                    title: this.$t('frontend_affiliate_us_citizen_in_us'),
                    description: '',
                },
                {
                    id: 2,
                    title: this.$t('frontend_affiliate_us_citizen_in_non_us'),
                    description: '',
                },
                {
                    id: 3,
                    title: this.$t('frontend_affiliate_non_us_citizen_in_us'),
                    description: '',
                },
                {
                    id: 4,
                    title: this.$t('frontend_affiliate_non_us_citizen_in_non_us'),
                    description: '',
                },
            ],
            currentUploadedFile: null,

        }
    },
    computed: {
        w8TypesActions() {
            return this.w8Types.map((type) => {
                return {
                    text: type.name,
                    callback: () => this.downloadW8Form(type.id)
                };
            });
        },
        isValid() {
            return !this.validator.form.affiliate_legal_name.$invalid &&
                !this.validator.form.affiliate_legal_address.$invalid &&
                !this.validator.form.affiliate_legal_city.$invalid &&
                !this.validator.form.affiliate_legal_state.$invalid &&
                !this.validator.form.affiliate_legal_country.$invalid &&
                !this.validator.form.affiliate_legal_zip_code.$invalid &&
                !this.validator.form.affiliate_tax_resident_type.$invalid &&
                !this.validator.form.affiliate_tax_id.$invalid &&
                !this.validator.form.affiliate_tax_form.$invalid

        }


    },
    methods: {
        previewTaxForm() {
            this.openLink(
                `${window.config.apiUrl}/download-user-files/${this.localForm.affiliate_tax_form}`,
                '_blank'
            );
        },
        downloadTaxForm() {
            this.openLink(
                `${window.config.apiUrl}/download-user-files/${this.localForm.affiliate_tax_form}?is_download=1`,
                '_blank'
            );
        },
        openConfirmModal() {
            this.$root.$emit('modal-open', 'confirm-modal');
        },
        async removeTaxForm() {
            this.localForm.affiliate_tax_form = '';
        },
        async getAdminFileURL(fileName) {
            const sellAppUrl = this.whitelabelAppData('sell').url;
            const url = `${window.gdEnv.VUE_APP_API_URL}${sellAppUrl}download-admin-files/${fileName}`;
            this.openLink(url, '_blank');
        },
        downloadW8Form(id) {
            this.getAdminFileURL(id);
        },
        fileAdded(addedFile) {
            if (this.currentUploadedFile) {
                this.$refs.myVueDropzone.removeFile(this.currentUploadedFile);
            }
            this.currentUploadedFile = addedFile;
            this.upload_file.file = addedFile;
        },
        async uploadFilesToServer() {
            if (this.upload_file) {
                const self = this;
                this.$loader.start('uploadFilesToServer');
                axios
                    .post(`${window.config.apiUrl}/get-pre-sign-url`, {
                        fileName: this.upload_file.file.name,
                    })
                    .then(async (res) => {
                        this.uploadedFileName = res.data.file;
                        // this.affiliate_tax_form = res.data.file;
                        const uninterceptedAxiosInstance = axios.create();
                        uninterceptedAxiosInstance
                            .put(res.data.postEndpoint, this.upload_file.file, {
                                onUploadProgress: (p) => {
                                    this.uploadProgressStatus = Math.round(
                                        (p.loaded / p.total) * 100
                                    );

                                    if (this.upload_file.file.previewElement) {
                                        const progressElement =
                                            this.upload_file.file.previewElement.querySelector(
                                                '[data-dz-uploadprogress]'
                                            );
                                        progressElement.style.width = `${this.uploadProgressStatus}%`;
                                        progressElement.textContent = `${this.uploadProgressStatus}%`;
                                    }
                                },
                            })
                            .then(async (uploadRes) => {
                                this.localForm.affiliate_tax_form = this.uploadedFileName;
                                this.$loader.end('uploadFilesToServer');

                            })
                            .catch((e) => {
                                this.$loader.end('uploadFilesToServer');
                                this.onUploaderError(
                                    this.upload_file.file,
                                    'Error occurred while uploading file'
                                );
                            });
                    });
            }
        },
        async openLink(link, linkTarget) {
            let a = document.createElement('a');
            a.target = linkTarget;
            a.href = link.replace(/^http:\/\//i, 'https://');
            a.click();
        }
    }
}
</script>