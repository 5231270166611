<template></template>
<script>
import publisher from 'publisher';
import axios from 'axios';
import qs from 'qs';

export default {
  layout: 'basic',
  async created() {
    const token = this.$route.params.token;
    const digitalAppTitle = this.whitelabelAppData('digital')?.title.toLowerCase() || 'groovedigital';

    new Promise((resolve, reject) => {
      axios.post(
        `${window.gdEnv.VUE_APP_API_URL}/${digitalAppTitle}/auth/sso-login/${token}`,
        qs.stringify({token:token})
      )
        .then(res => {
          this.setUserData(res.data)
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
  methods: {
    async setUserData(user){
       if (!user.auth_keycloak) {
          axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            'x-auth-provider': 'auth0',
          };
        } else {
          delete axios.defaults.headers.common['x-auth-provider'];
        }

        const self = this;
        store.commit('login', user);

        if (
          self.$route.query &&
          self.$route.query.redirect_funnel_id &&
          self.$route.query.redirect_funnel_id != ''
        ) {
          const funnelAffiliate = new FunnelAffiliate({});
          funnelAffiliate.funnel_id = self.$route.query.redirect_funnel_id;

          if (
            self.$route.query.gd_jv_cookie &&
            self.$route.query.gd_jv_cookie != ''
          ) {
            funnelAffiliate.gd_jv_cookie = self.$route.query.gd_jv_cookie;
          } else {
            funnelAffiliate.gd_jv_cookie = null;
          }

          if (
            self.$route.query.gs_jv_cookie &&
            self.$route.query.gs_jv_cookie != ''
          ) {
            funnelAffiliate.gs_jv_cookie = self.$route.query.gs_jv_cookie;
          } else {
            funnelAffiliate.gd_jv_cookie = null;
          }

          funnelAffiliate.save().then(() => {
            self.$router.push({
              name: 'promos_dashboard',
              params: { id: self.$route.query.redirect_funnel_id },
            });
          });
        } else {
          self.$router.push({ name: 'dashboard' });
        }
    }
  },
};
</script>