<template>
    <div>
        <div class="flex flex-row">
            <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_groovesell_transaction_table_funnel_name')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.funnel_name }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_product_name')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.product_name }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_price_point')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.price_point_name }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_analytics_type')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.transaction_type }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_gateway')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.gateway }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_account')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.gateway_name }}
                    </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transaction_id_gateway')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.processor_transaction_id }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_date_and_time')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.formatted_date_time }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_revenue')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.human_readable_price }}
                    </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_refund_period')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{
                            selectedTransaction.product &&
                            selectedTransaction.product != null
                            ? selectedTransaction.product.refund_period
                            : 'NA'
                        }}
                        {{ $t('frontend_analytics_days') }}
                    </label>
                </div>

                <div v-if="selectedTransaction.recurring_billing" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_recurring_bill')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.recurring_billing }}
                    </label>
                </div>
                <div v-else class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_recurring_bill')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">N/A</label>
                </div>

                <div v-if="selectedTransaction.recurring_billing &&
                        selectedTransaction.payments_left &&
                        selectedTransaction.payments_left != 99999999999
                        " class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_payments_left')
                    }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ selectedTransaction.payments_left }}
                    </label>
                </div>
                <div v-else class="w-1/3 pt-2 pb-2 pr-2 flex flex-col mb-2"></div>
                <div v-if="showDaysSinceFreeSignup" class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_groovesell_transaction_days_since_free_signup') }}</label>
                    <label class="text-sm text-grey-700 mb-2"> {{ selectedTransaction.days_since_free_signup }}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTransaction: {
            type: Object,
            required: true,
        },
        authenticatedUser: {
            default: null,
        },
    },
    computed: {
        showDaysSinceFreeSignup() {
            return this.authenticatedUser?.id === '5daeb623b256c50e25993cc6';
        },
    }
}
</script>