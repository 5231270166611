import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import store from '@/store';
import routes from './routes';
import scrollBehavior from './utils/scroll-behavior';
import { beforeEach, afterEach } from './utils/guards';

Vue.use(Meta);
Vue.use(Router);

const router = new Router({
  base: process.env.NODE_ENV === 'development' ? '/' : '/groovesell/',
  scrollBehavior,
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => beforeEach(to, from, next, router));
router.afterEach(async (to, from) => afterEach(to, from, router));

sync(store, router);

export default router;
