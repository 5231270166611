<template>
  <div class="w-full flex flex-row">
    <div class="w-1/3 border-r border-grey-300 p-4">
      <div class="embed-container">
        <iframe
          v-if="useCustomVideo && isYouTubeService"
          :src="'https://www.youtube.com/embed/' + funnelResource.video_details.id"
        ></iframe>
        <iframe
          v-else-if="useCustomVideo && isVimeoService"
          :src="'https://player.vimeo.com/video/' + funnelResource.video_details.id"
        ></iframe>
        <iframe v-else src="https://www.youtube.com/embed/syb231NPuMY?rel=0"></iframe>
      </div>
      <div class="flex flex-row justify-center">
        <gd-button
          variant="primary"
          size="auto"
          class="mt-2"
          @click="$emit('openPromotionalResourcesPage')"
        >
          {{ $t('frontend_resource_open_resources') }}
        </gd-button>
      </div>
    </div>
    <div class="w-2/3 container flex flex-row flex-wrap justify-center mx-auto">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center font-bold text-lg text-grey-700 mb-2 p-8 text-center">
          {{ $t('frontend_groovesell_resources_havent_signed_aff_funnel_desc') }}
        </div>
        <div class="flex flex-row w-full justify-center">
          <div class="w-full max-w-4xl px-4">
            <div>
              <div class="flex flex-row justify-center w-full mb-8">
                <gd-button
                  size="auto"
                  class="ml-4 mt-3"
                  variant="primary"
                  @click="signupToPromoteFunnel"
                >
                  {{ $t('frontend_resources_signup_to_funnel') }}
                </gd-button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import FunnelAffiliate from '@/models/FunnelAffiliate';

export default {
  props: {
    funnelResource: {
      type: Object,
      default: () => {},
    },
    selectedPromoLink: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    useCustomVideo() {
      return this.funnelResource && this.funnelResource.use_custom_video;
    },
    isYouTubeService() {
      return (
        this.useCustomVideo &&
        this.funnelResource.video_details &&
        this.funnelResource.video_details.id &&
        this.funnelResource.video_details.service === 'youtube'
      );
    },
    isVimeoService() {
      return (
        this.useCustomVideo &&
        this.funnelResource.video_details &&
        this.funnelResource.video_details.id &&
        this.funnelResource.video_details.service === 'vimeo'
      );
    },
  },
  methods: {
    async signupToPromoteFunnel() {
      this.$loader.start('signupToPromoteFunnel');

      const funnelAffiliate = new FunnelAffiliate({});
      funnelAffiliate.funnel_id = this.selectedPromoLink.funnel;

      const gdJvCookie = Cookies.get(`gd_jv_f${this.selectedPromoLink.funnel}`);

      if (typeof gdJvCookie !== 'undefined') {
        funnelAffiliate.gd_jv_cookie = gdJvCookie;
      }

      const gsJvCookie = Cookies.get(`gs_jv_f${this.selectedPromoLink.funnel}`);

      if (typeof gsJvCookie !== 'undefined') {
        funnelAffiliate.gs_jv_cookie = gsJvCookie;
      }

      await funnelAffiliate.save();
      this.$loader.end('signupToPromoteFunnel');
      this.$emit('changedFunnel');
      this.isUserSignedUpToAffiliateProgram = 1;
    },
  },
};
</script>
