<template>
  <div class="w-full min-h-full bg-white p-4">
    <v-wait for="loginChecking">
      <div slot="waiting">
        <div class="mt-4 text-lg text-grey-700 font-medium text-center w-full">
          {{ $t('frontend_resources_connecting', {appTitle: whitelabelAppData('sell').title}) }}
        </div>
      </div>
      <div v-if="isUserSignedUpToAffiliateProgram">
        <promo-tools-assets
          @userLoggedOut="userLoggedOut"
        />
      </div>
      <div v-else class="flex flex-col">
        <div class="flex flex-col w-full">
          <div
            class="flex flex-row justify-center font-bold text-lg text-grey-700 mb-2 py-4"
          >
            {{ $t('frontend_resources_this_widget', {appTitle: whitelabelAppData('sell').title}) }}
          </div>
          <div class="flex flex-row w-full justify-center">
            <div class="w-full max-w-4xl px-4">
              <div class="mt-2">
                <div class="flex flex-row justify-center w-full mb-8">
                  <gd-button
                    size="auto"
                    class="ml-4 mt-3"
                    variant="primary"
                    @click="signupToPromoteFunnel"
                  >
                    {{ $t('frontend_resources_signup_to_funnel') }}
                  </gd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';
import FunnelAffiliate from '@/models/FunnelAffiliate';
import Cookies from 'js-cookie';
import PromoToolsAssets from './promo_tools_assets';

export default {
  name: 'PromoToolsPostLogin',
  components: {
    PromoToolsAssets,
  },
  props: {
    funnelId: {
      required: true,
    },
  },
  data() {
    return {
      isUserSignedUpToAffiliateProgram: 0,
    };
  },
  async created() {
    await this.checkFunnelAffiliate();
  },
  methods: {
    async checkFunnelAffiliate() {
      this.$wait.start('loginChecking');
      const { data } = await axios.get(
          `check-if-funnel-affiliate/${this.funnelId}`
          );

      if (data.status == 'success') {
        this.isUserSignedUpToAffiliateProgram = 1;
      }
      this.$wait.end('loginChecking');
    },
    userLoggedOut() {
      this.$emit('userLoggedOut', 1);
    },
    async signupToPromoteFunnel() {
      this.$loader.start('signupToPromoteFunnel');

      const funnelAffiliate = new FunnelAffiliate({});
      funnelAffiliate.funnel_id = this.funnelId;

      const gdJvCookie = Cookies.get(`gd_jv_f${this.funnelId}`);

      if (typeof gdJvCookie !== 'undefined') {
        funnelAffiliate.gd_jv_cookie = gdJvCookie;
      }

      const gsJvCookie = Cookies.get(`gs_jv_f${this.funnelId}`);

      if (typeof gsJvCookie !== 'undefined') {
        funnelAffiliate.gs_jv_cookie = gsJvCookie;
      }

      await funnelAffiliate.save();
      this.$loader.end('signupToPromoteFunnel');
      this.isUserSignedUpToAffiliateProgram = 1;
    },
  },
};
</script>
