var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pluginSiteData.integrations.length > 0 &&_vm.availableIntegrations.length > 0 )?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row-reverse w-full"},[_c('gd-button',{staticClass:"mr-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.showAffiliateRegistrationIntegrationBox()}}},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_add_new_integration')))])],1),(_vm.localModel.affiliate_registration_integrations &&
                    _vm.localModel.affiliate_registration_integrations.length > 0
                    )?_c('div',{staticClass:"mt-6"},_vm._l((_vm.localModel.affiliate_registration_integrations),function(affiliateRegistrationIntegration,affiliateRegistrationIntegrationIndex){return _c('div',{key:affiliateRegistrationIntegrationIndex,staticClass:"flex flex-col mt-4"},[_c('div',{staticClass:"flex flex-row",class:{
                        'border-t border-grey-400 border-solid pt-4':
                            affiliateRegistrationIntegrationIndex > 0,
                    }},[_c('div',{staticClass:"w-1/12 mr-8"},[_vm._v(_vm._s(_vm.$t('frontend_groovesell_addTo')))]),_c('div',{staticClass:"flex flex-row w-11/12"},[_c('div',{staticClass:"w-3/4 flex flex-col"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(_vm._s(_vm.$t('frontend_type'))+" :")]),_c('div',[_vm._v(" "+_vm._s(affiliateRegistrationIntegration.platform .charAt(0) .toUpperCase() + affiliateRegistrationIntegration.platform.slice( 1 ))+" ")])]),_vm._l((affiliateRegistrationIntegration.config),function(affiliateRegistrationIntegrationConfig,affiliateRegistrationIntegrationConfigIndex){return _c('div',{key:affiliateRegistrationIntegrationConfigIndex,staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(affiliateRegistrationIntegrationConfig.label)+" : ")]),_c('div',[_vm._v(" "+_vm._s(affiliateRegistrationIntegrationConfig.name)+" ")])])})],2),_c('div',{staticClass:"w-1/4 flex flex-row justify-end pr-4"},[_c('gd-button',{staticClass:"mr-2",attrs:{"size":"icon","variant":"primary","title":_vm.$t('frontend_groovesell_delete_integration')},on:{"click":function($event){return _vm.deleteAffiliateRegistrationIntegration(
                                            affiliateRegistrationIntegrationIndex
                                        )}}},[_c('trash-icon',{staticClass:"h-5 w-5 fill-current"})],1)],1)])])])}),0):_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_no_integrations')))])])])]):_c('div',[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step9_no_integrations_added')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }