<template>
    <div>
        <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="bg-white rounded p-2 mb-2">
            <div v-for="(pricePoint, index) in integration.price_points" :key="index">
                <div v-if="pricePointsTab ===
                    'data_sharing_product_price_point_' + pricePoint.id
                    " class="flex flex-col w-full">
                    <div class="flex flex-col w-full bg-white mb-2">
                        <div v-if="pluginSiteData.integrations.length > 0 && availableIntegrations.length > 0">
                            <div class="flex flex-col">
                                <div class="flex flex-row-reverse w-full">
                                    <gd-button size="auto" variant="primary" class="mr-4"
                                        @click="showPostSaleIntegrationBox(index)">{{
                                            $t('frontend_product_step8_add_new')
                                        }}</gd-button>
                                </div>
                                <div v-if="pricePoint.affiliate_integration &&
                                        pricePoint.affiliate_integration.length > 0
                                        " class="mt-6">
                                    <div v-for="(
                                postSaleIntegration, postSaleIntegrationIndex
                              ) in pricePoint.affiliate_integration" :key="postSaleIntegrationIndex"
                                        class="flex flex-col mt-4">
                                        <div class="flex flex-row" :class="{
                                            'border-t border-grey-400 border-solid pt-4':
                                                postSaleIntegrationIndex > 0,
                                        }">
                                            <div class="w-1/12 mr-8">{{ $t('frontend_groovesell_addTo') }}</div>
                                            <div class="flex flex-row w-11/12">
                                                <div class="w-3/4 flex flex-col">
                                                    <div class="flex flex-row">
                                                        <div class="font-bold mr-2">
                                                            {{ $t('frontend_product_step8_type') }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                postSaleIntegration.platform
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                postSaleIntegration.platform.slice(1)
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div v-for="(
                                        postSaleIntegrationConfig,
                                                postSaleIntegrationConfigIndex
                                      ) in postSaleIntegration.config" :key="postSaleIntegrationConfigIndex"
                                                        class="flex flex-row">
                                                        <div class="font-bold mr-2">
                                                            {{ postSaleIntegrationConfig.label }} :
                                                        </div>
                                                        <div>
                                                            {{ postSaleIntegrationConfig.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-1/4 flex flex-row justify-end pr-4">
                                                    <gd-button size="icon" variant="primary"
                                                        :title="$t('frontend_groovesell_delete_integration')" class="mr-2"
                                                        @click="
                                                            deletePostSaleIntegration(
                                                                index,
                                                                postSaleIntegrationIndex
                                                            )
                                                            ">
                                                        <trash-icon class="w-5 h-5 fill-current" />
                                                    </gd-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="mt-6">
                                    <label class="text-grey-dark">{{
                                        $t('frontend_product_step8_no_integrations')
                                    }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <label class="text-grey-dark">{{
                                $t('frontend_product_step8_no_integrations_added')
                            }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import auth from '@groovepages/gd-lib/auth';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
    components: {
        TrashIcon,
    },
    props: {
        integration: {
            type: Object,
            required: true,
        },
        pricePointsTabs: {
            type: Array,
            required: true,
        },

        pricePointsTab: {
            type: String,
            required: true,
        },
        handlePricePointsTabChange: {
            type: Function,
            required: true,
        },

        showPostSaleIntegrationBox: {
            type: Function,
            required: true,
        },

    },
    computed: {
        pluginSiteData() {
            return this.$store.getters.pluginSiteSettings;
        },
        availableIntegrations() {
            const user = auth.authenticatedUser();
            if (user.metadata && user.metadata.integrations) {
                const integrations = [];
                user.metadata.integrations.map((value, key) => {
                    const integrationType = value.type.split('-')[0];
                    const integrationPlugin = `${integrationType}-component`;
                    if (
                        integrations.findIndex((x) => x.value == integrationPlugin) == -1
                    ) {
                        integrations.push({
                            text:
                                integrationType.charAt(0).toUpperCase() +
                                integrationType.slice(1),
                            value: integrationPlugin,
                        });
                    }
                });

                return integrations;
            }

            return [];
        },
        pricePointsErrorTabs() {
            return [];
        },
    },
    methods: {

        deletePostSaleIntegration(pricePointIndex, postSaleIntegrationIndex) {
            return;
        },

    }
}
</script>