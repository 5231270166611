<template>
  <div>
    <div class="w-full">
      <v-wait for="funnels">
        <affiliate-filters
          :model="model"
          :grid-api="gridApi"
          @changedSearchOrFilter="changedSearchOrFilter"
        />

        <div v-if="isFunnelAdded" id="affiliates-list-view" class="mt-4">
          <ag-grid-vue
            class="ag-theme-material"
            style="width: 100%;"
            :dom-layout="domLayout"
            :grid-options="gridOptions"
            :column-defs="vendorsTableColumnDefs"
            :pagination="true"
            :row-height="affiliatesTableRowHeight"
            :row-buffer="rowBuffer"
            :row-model-type="rowModelType"
            :datasource="dataSource"
            :pagination-page-size="paginationPageSize"
            :cache-overflow-size="cacheOverflowSize"
            :infinite-initial-row-count="infiniteInitialRowCount"
            :max-blocks-in-cache="maxBlocksInCache"
            :cache-block-size="cacheBlockSize"
            :suppress-row-click-selection="true"
            :suppress-cell-selection="true"
            :row-class="rowClass"
            :row-class-rules="rowClassRules"
            :context="context"
            :framework-components="frameworkComponents"
            :row-selection="rowSelection"
            :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
            @grid-ready="onGridReady"
            @model-updated="onModelUpdated"
            @row-selected="onRowSelected"
            @selection-changed="onSelectionChanged"
          ></ag-grid-vue>
        </div>
        <div v-else>
          <p>{{ $t('frontend_groovesell_vendors_currently_no_funnel') }}</p>
        </div>

        <vendor-detail-modal
          ref="vendorDetailModal"
          @fetchAffiliateLinks="fetchAffiliateLinks"
        />

        <affiliate-links-modal
          :affiliateLinks="affiliateLinks"
        />
      </v-wait>
    </div>
  </div>
</template>

<script>
import Vendor from '@/models/Vendor';
import VendorAffiliateFunnel from '@/models/VendorAffiliateFunnel';
import AffiliateLink from '@/models/AffiliateLink';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import VendorFullNameRenderer from '@/renderers/vendorFullNameRenderer';
import VendorSalesRenderer from '@/renderers/vendorSalesRenderer';
import VendorRefundsRenderer from '@/renderers/vendorRefundsRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';
import AffiliateFilters from './partials/AffiliateFilters.vue';
import VendorDetailModal from './partials/VendorDetailModal.vue';
import AffiliateLinksModal from './partials/AffiliateLinksModal.vue';

export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  metaInfo() {
    return { title: this.$t('frontend_affiliate_transactions_vendors') };
  },
  mixins: [createLinkMixin],
  validations: {},
  components: {
    AgGridVue,
    AffiliateFilters,
    VendorDetailModal,
    AffiliateLinksModal
},
  data() {
    return {
      model: {
        filteredAffiliateTypes: 0,
        selectedRowSize: 25,
        selectedFunnel: 0,
        searchPhrase: '',
        funnels: [],
      },
      affiliateLinks: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      dataSource: null,
      affiliates: [],
      paginationPageSize: 25,
      affiliatesTableRowHeight: 40,
      rowBuffer: null,
      rowModelType: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      domLayout: 'autoHeight',
      vendorsTableColumnDefs: null,
      rowClassRules: null,
      rowClass: 'affiliates-row',
      context: null,
      frameworkComponents: null,
      rowSelection: 'multiple',
      isAllSelected: false,
      unselectedVendorIds: [],
      selectedRowLength: 0,
      isFunnelAdded: false
    };
  },
  async beforeMount() {
    this.gridOptions = {};

    this.context = { componentParent: this };
    this.frameworkComponents = {
      vendorFullNameRenderer: VendorFullNameRenderer,
      vendorSalesRenderer: VendorSalesRenderer,
      vendorRefundsRenderer: VendorRefundsRenderer
    };

    this.vendorsTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_vendor_details'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'name',
            cellRenderer: 'vendorFullNameRenderer',
            colId: 'vendorFullName',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_clicks'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_impression'),
            field: 'impressions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_uniques'),
            field: 'uniques',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_conversions'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_signups'),
            field: 'total_optins',
            cellRenderer: 'vendorSalesRenderer',
            colId: 'vendorOptins',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_sales'),
            field: 'total_sales',
            cellRenderer: 'vendorSalesRenderer',
            colId: 'vendorSales',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_total_sales_amount'),
            field: 'human_readable_total_sales_amount',
            colId: 'vendorSalesAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_refunds'),
            field: 'total_refunds',
            cellRenderer: 'vendorRefundsRenderer',
            colId: 'vendorRefunds',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_total_refunds_amount'),
            field: 'human_readable_total_refunds_amount',
            cellRenderer: 'vendorRefundsRenderer',
            colId: 'vendorRefundsAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_groovesell_comissions'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned_comissions'),
            field: 'human_readable_total_commissions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid_comissions'),
            field: 'human_readable_pending_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_due_comissions'),
            field: 'human_readable_processed_without_advances',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_being_processed_comissions'),
            field: 'human_readable_in_process_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_amount_paid'),
            field: 'human_readable_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_comissions_on_hold'),
            field: 'human_readable_on_hold_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_all_time_earned_commision'),
            field: 'human_readable_alltime_earned_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_all_time_paid_commision'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_all_time_cash_advances'),
            field: 'human_readable_commission_advance',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          },
          {
            headerName: this.$t('frontend_groovesell_all_clawbacks'),
            field: 'human_readable_clawbacks',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc']
          }
        ]
      },
      {
        headerName: this.$t('frontend_email'),
        headerClass: 'vendor-details-header',
        children: [
          {
            headerName: this.$t('frontend_email'),
            field: 'email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc']
          }
        ]
      }
    ];

    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;

    this.rowClassRules = {};
  },
  async created() {
    this.$wait.start('funnels');
    const funnels = await VendorAffiliateFunnel.$get();
    if (funnels.length > 0) {
      this.isFunnelAdded = true;
      this.model.funnels = funnels;
      this.model.selectedFunnel = funnels[0].id;
    } else {
      this.isFunnelAdded = false;
    }
    this.$wait.end('funnels');
  },
  methods: {
    async fetchAffiliateLinks(id) {
      this.$loader.start('fetchAffiliateLinks');
      this.affiliateLinks = await AffiliateLink.params({
        funnelId: id
      })
        .custom('fetch-aff-links-from-affiliate')
        .$get();

      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },
    onModelUpdated() {
      if (this.isAllSelected) {
        const self = this;
        this.gridApi.forEachNode(rowNode => {
          if (!self.unselectedVendorIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },
    onRowSelected(event) {
      if (!event.node.selected && this.unselectedVendorIds.indexOf(event.node.data.id) === -1) {
        this.unselectedVendorIds.push(event.node.data.id);
      } else {
        this.unselectedVendorIds = this.unselectedVendorIds.filter(item => item !== event.node.data.id);
      }
    },
    async changedSearchOrFilter(updateModel) {
      this.model = updateModel;
      this.dataSource = await this.getDataSource(Number(this.model.selectedRowSize));
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.model.selectedRowSize));
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function(params) {
        self.$loader.start('getDataSource');

        const affiliatesData = await Vendor.page(self.gridApi.paginationGetCurrentPage() + 1)
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            affiliates: self.model.filteredAffiliateTypes,
            search: self.model.searchPhrase,
            funnels: self.model.selectedFunnel,
            sortBy: params.sortModel
          })
          .get();

        const rowsThisPage = affiliatesData.data;
        const totalNumberOfRows = affiliatesData.meta.total;
        self.model.funnels = affiliatesData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.model.selectedRowSize));
    },
  }
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>
<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .vendor-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .vendor-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .vendor-details-header .ag-header-group-cell-label span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
