<template> </template>

<script>
import auth from '@groovepages/gd-lib/auth';

export default {
  layout: 'basic',
  async created() {
    if (auth.authenticatedUser()) {
      auth.logout();
    }
  }
};
</script>
