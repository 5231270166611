<template>
  <div>
    <div class="flex flex-row w-full justify-between">
      <div>
        <slot name="header-left"> </slot>
      </div>
      <div>
        <div>
          <slot name="header-right"> </slot>
        </div>
        <div class="flex flex-row justify-end">
          <div
            class="
              flex flex-row
              justify-center
              items-center
              h-10
              w-10
              border border-grey-200
              cursor-pointer
              rounded-sm
            "
            @click="viewType = 'grid'"
            :class="{
              'bg-primary text-white': viewType == 'grid',
              'bg-white text-primary': viewType == 'list',
            }"
          >
            <grid-icon class="w-5 h-5 fill-current"/>
          </div>
          <div
            class="
              flex flex-row
              justify-center
              items-center
              h-10
              w-10
              border border-grey-200
              cursor-pointer
              rounded-sm
            "
            @click="viewType = 'list'"
            :class="{
              'bg-primary text-white': viewType == 'list',
              'bg-white text-primary': viewType == 'grid',
            }"
          >
            <list-icon class="w-5 h-5 fill-current"/>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slot v-if="viewType == 'grid'" name="gridContent"></slot>
      <slot v-else name="listContent"></slot>
    </div>
  </div>
</template>

<script>
import ListIcon from '@/assets/images/icons/ListIcon.svg';
import GridIcon from '@/assets/images/icons/GridIcon.svg';

export default {
  components: {
    ListIcon,
    GridIcon,
  },
  name: 'AssetsList',
  data() {
    return {
      viewType: 'grid',
    };
  },
};
</script>