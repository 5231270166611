<template>
  <div
    class="w-full flex flex-col items-left mr-4 mb-4 rounded p-4"
    :class="{
      'border-primary border-2': this.activeOption === option.id,
      'border-grey-200 border': this.activeOption !== option.id,
    }"
    @click="updateActiveOption"
    @keydown.space="updateActiveOption"
  >
    <label
      class="flex cursor-pointer"
      :class="{
        'cursor-not-allowed': option.coming_soon || option.is_disabled,
        'cursor-pointer': !option.coming_soon && !option.is_disabled,
      }"
    >
      <div class="flex flex-col w-full">
        <div class="flex flex-1 flex-row justify-center flex-wrap">
          <div
            v-for="img in option.svg"
            :key="img"
            class="my-2 px-2 w-24 flex flex-col justify-center items-center content-center"
          >
            <img
              class="h-full w-full object-scale-down"
              :class="{
                'border-2 border-red': img.includes('Password')
              }"
              :src="require(`@/assets/images/checkout/${img}.png`)"
            />
            <p
              v-if="option.label === undefined"
              class="mt-2"
              :class="{
                'text-primary': isActiveOption,
                'text-dark': !isActiveOption,
              }"
            >
              {{ camelCaseToSentence(img) }}
            </p>
          </div>
        </div>
          <div class="flex flex-1 flex-row justify-center flex-wrap"
            v-if="option.label !== undefined">
              <p
              class="mt-2"
              :class="{
                'text-primary': isActiveOption,
                'text-dark': !isActiveOption,
              }"
            >
              {{ option.label }}
            </p>
          </div>
      </div>
    </label>
  </div>
</template>

<script>
import ContactAndPassword from '@/assets/images/checkout/ContactAndPassword.svg';
import Email from '@/assets/images/checkout/Email.svg';
import EmailAndPassword from '@/assets/images/checkout/EmailAndPassword.svg';
import ContactInfo from '@/assets/images/checkout/ContactInfo.svg';
import PaymentInfo from '@/assets/images/checkout/PaymentInfo.svg';
import BillingInfo from '@/assets/images/checkout/BillingInfo.svg';
import OrderForm from '@/assets/images/checkout/OrderForm.svg';
import Password from '@/assets/images/checkout/Password.svg';

export default {
  name: 'CheckoutRadioButton',
  components: {
    ContactAndPassword,
    ContactInfo,
    BillingInfo,
    PaymentInfo,
    Email,
    EmailAndPassword,
    OrderForm,
    Password,
  },

  model: {
    prop: 'activeOption',
    event: 'onUpdateOption',
  },

  props: ['option', 'activeOption'],

  created() {
    // console.log('option', this.option);
    // console.log('activeOption', this.activeOption);
  },

  computed: {
    isActiveOption() {
      return this.activeOption == this.option.id;
    },
  },

  methods: {
    updateActiveOption() {
      if (!this.option.coming_soon && !this.option.is_disabled) {
        this.$emit('onUpdateOption', this.option.id);
      }
    },

    camelCaseToSentence(imgName) {
      const result = imgName.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },

    imagePath(imgName) {
      return `@/assets/images/checkout/${imgName}.png`;
    },
  },
};
</script>

<style>
.activeOptionRadio {
  box-shadow: 0px 0px 0px 2px white inset;
}
</style>
