<template>
  <div>
    <div class="w-full">
      <v-wait for="promos">
        <gd-dropdown
          v-model="selectedOrder"
          class="mb-1"
          :options="sortOrderOptions"
          @change="changedSortOrder"
        />
        <gd-dropdown
          v-model="filterOption"
          :options="filterOptions"
        />
        <div v-if="promos && promos.length" id="promos-list-view" class="mt-4">
          <client-table class="mt-4" :ref="'promosTable'" :tableData="filteredPromos" :tableColumns="promosTableColumns"
            :tableOptions="promosTableOptions" :slotData="slotData">
          </client-table>
        </div>
        <div v-else class="mt-8">
          <p>{{ $t('frontend_affiliate_promos_you_havent') }}</p>
        </div>


        <affiliate-links-modal :affiliateLinks="affiliateLinks" :openCreate="openCreate"
          :openEditCustomLandingPage="openEditCustomLandingPage"
          :deleteLandingPageUrl="openConfirmModal" :selectedFunnelId="selectedFunnelId" />
        <affiliate-bonuses-modal
          :affiliateBonuses="affiliateBonuses"
          :bonuses="bonuses"
          :affiliateBonusesTab="affiliateBonusesTab"
          @affiliate-bonuses-tab-change="handleAffiliateBonusTabChange"
        />
        <add-custom-landing-page-url :addLandingPageUrl="addLandingPageUrl" :model="model" :validator="$v"
          @update-model="HandleUpdateModel" />
        <edit-custom-landing-page-url :model="model" :validator="$v" :editCustomLandingPage="editCustomLandingPage"
          @update-model="HandleUpdateModel" />

      </v-wait>
    </div>
    <confirm-modal
      name="confirm-landing-page-url-delete-modal"
      :message-content="$t('frontend_delete_custom_landing_page_text')"
      :button-text="$t('frontend_delete_custom_landing_page_confirmation_text')"
      button-size="auto"
      @confirm="deleteLandingPageUrl"
    />
  </div>
</template>



<script>
import Promo from '@/models/Promo';
import AffiliateLink from '@/models/AffiliateLink';
import AffiliateBonus from '@/models/AffiliateBonus';
import Swal from 'sweetalert2';
import Bonus from '@/models/Bonus';
import { required, minLength, maxLength, url } from 'vuelidate/lib/validators';
import ClientTable from '@groovepages/gd-ui-kit/components/tables/ClientTable.vue';
import axios from 'axios';

/** new modals */
import AffiliateLinksModal from './Promo_partials/modals/AffiliateLinksModal';
import AffiliateBonusesModal from './Promo_partials/modals/AffiliateBonusesModal';
import AddCustomLandingPageUrl from './Promo_partials/modals/AddCustomLandingPageUrlModal';
import EditCustomLandingPageUrl from './Promo_partials/modals/EditCustomLandingPageUrlModal';


export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  metaInfo() {
    return { title: 'My Affiliate Promotions' };
  },
  components: {
    ClientTable,
    AffiliateLinksModal,
    AffiliateBonusesModal,
    AddCustomLandingPageUrl,
    EditCustomLandingPageUrl,
  },
  data() {
    return {
      landingPageUrlId: null,
      model: {
        name: '',
        type: 2,
        affiliate_id: '',
        funnel_id: '',
        product_id: '',
        landing_page_id: 0,
        link: '',
      },
      custom_landing_page: null,
      filterOption: 'active',
      promos: [],
      affiliateLinks: [],
      affiliateBonuses: [],
      affiliateBonusesTab: '',
      bonuses: [],
      selectedFunnelId: 0,
      selectedMainProductId: 0,
      promosTableColumns: [
        'name',
        'impressions',
        'uniques',
        'optins',
        'conversions',
        'commissions',
        'view',
      ],
      promosTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: '',
        },
        sortable: [
          'name',
          'impressions',
          'uniques',
          'optins',
          'conversions',
          'commissions',
        ],
        headings: {
          name: this.$t('frontend_affiliate_promos_name'),
          impressions: this.$t('frontend_affiliate_promos_impressions'),
          uniques: this.$t('frontend_affiliate_promos_uniques'),
          optins: this.$t('frontend_affiliate_promos_optins'),
          conversions: this.$t('frontend_affiliate_promos_conversions'),
          commissions: this.$t('frontend_affiliate_promos_commisions'),
          views: '',
        },
        filterByColumn: false,
        filterable: ['name'],
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
      selectedOrder: 'created_at',
      sortOrder: 'asc',
    };
  },

  validations: {
    model: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      link: {
        required,
        url,
      },
    },
  },

  computed: {
    sortOrderOptions() {
      return [
        { value: "created_at", text: this.$t('frontend_groovesell_created_oldest_to_newest') },
        { value: "-created_at", text: this.$t('frontend_groovesell_created_newest_to_oldest') },
        { value: "-updated_at", text: this.$t('frontend_groovesell_updated_oldest_to_newest') },
        { value: "updated_at", text: this.$t('frontend_groovesell_updated_newest_to_oldest') },
        { value: "name", text: `${this.$t('frontend_name')} (A-Z)` },
        { value: "-name", text: `${this.$t('frontend_name')} (Z-A)` }
      ];
    },
    filterOptions() {
      return [
        { value: "active", text: this.$t('frontend_active') },
        { value: "archive", text: this.$t('frontend_archive') }
      ];
    },
    filteredPromos() {
      if (this.filterOption === "active") {
        return this.promos.filter(promo => promo.archived_data === 0);
      } else if (this.filterOption === "archive") {
        return this.promos.filter(promo => promo.archived_data === 1);
      } else {
        return this.promos;
      }
    },

    customLandingPageLinks() {
      return this.affiliateLinks.filter((link) => {
        return link.type === 'custom_land_page';
      });
    },

    slotData() {
      return [

        {
          slotName: 'commissions',
          slotType: 'computed',
          classes: () => {
            return '';
          },
          value: (props) => {
            return `${props.row.currency_symbol}${props.row.commissions}`;
          },
        },
        {
          slotName: 'view',
          slotType: 'view',
          viewArray: [
            {
              id: 0,
              title: 'frontend_affiliate_promos_affiliate_links',
              conditions: () => {
                return true;
              },
              callback: (props) => {
                return this.fetchAffiliateLinks(props.row, props.row.id, props.row.main_product_id);
              },
              buttonClass: 'fas fa-link',
              classes: 'ml-2',
            },
            {
              id: 1,
              title: 'frontend_affiliate_promos_affiliate_bonuses',
              conditions: () => {
                return true;
              },
              callback: (props) => {
                return this.fetchAffiliateBonuses(props.row.id);
              },
              buttonClass: 'fas fa-gift',
              classes: 'ml-2',
            },
            {
              id: 2,
              title: 'frontend_affiliate_promos_affiliate_dashboard',
              conditions: () => {
                return true;
              },
              callback: (props) => {
                return this.dashboard(props.row.id);
              },
              buttonType: 'icon',
              buttonClass: 'fas fa-chart-bar',
              classes: 'ml-2',
            },
            {
              id: 3,
              title: 'frontend_affiliate_promos_data_sharing',
              disable: (props) => !props.row.has_data_sharing,
              conditions: () => {
                return true;
              },
              callback: (props) => {
                return this.dataSharing(props.row.id);
              },
              buttonType: 'icon',
              buttonClass: 'fas fa-plug',
              classes: 'ml-2',
            },
            {
              id: 4,
              title: 'Archive',
              conditions: (props) => {
                return this.filterOption === 'active';
              },

              callback: (props) => {
                this.archiveData(props.row.promo_id); // Call the backend API to archive data
              },

              buttonType: 'icon',
              buttonClass: 'fas fa-archive', // Use 'fas fa-toggle-on' for active and 'fas fa-toggle-off' for archive
              classes: 'ml-2',
            }

          ],
        },
      ];
    },
  },

  async created() {
    this.$wait.start('promos');
    this.promos = await Promo.$get();
    this.bonuses = await Bonus.$get();
    this.$wait.end('promos');
  },

  methods: {
    handleAffiliateBonusTabChange(newTab) {
      this.affiliateBonusesTab = newTab;
    },
    HandleUpdateModel(newModelValue) {
      this.model = newModelValue;
    },
    async archiveData(id) {
      try {
        this.$loader.start('archiveData');
        await axios.post(`/promos/${id}/archive`);

        const index = this.promos.findIndex(promo => promo.promo_id === id);

        if (index !== -1) {
          this.promos[index].archived_data = 1;
        }
        this.$loader.end('archiveData');

      } catch (error) {
        this.$loader.end('archiveData');
        if (error.response && error.response.status === 404) {
          console.error('Data not found');
        } else {
          console.error(error);
        }
      }
    },
    // sorting
    async changedSortOrder() {
      if (this.selectedOrder.startsWith('-')) {
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }
      // Call the method to update the table data based on the new sorting order
      this.updateTableData();
    },
    updateTableData() {
      // Fetch the table data or update the existing data
      // Sort the table data based on the selected order
      this.promos.sort((a, b) => {
        if (this.sortOrder === 'asc') {
          return a[this.selectedOrder] > b[this.selectedOrder] ? 1 : -1;
        } else {
          return a[this.selectedOrder] < b[this.selectedOrder] ? 1 : -1;
        }
      });
    },
    openCreate() {
      this.$v.$reset();
      this.custom_landing_page = new AffiliateLink({});
      this.model.name = '';
      this.model.link = '';
      this.$root.$emit('modal-open', 'add-custom-landing-page-url');
    },
    openConfirmModal(landingPageUrlId) {
      this.landingPageUrlId = landingPageUrlId;
      this.$root.$emit('modal-open', 'confirm-landing-page-url-delete-modal');
    },
    async deleteLandingPageUrl() {
      this.$loader.start('deleteLandingPageUrl');
      const affiliateLink = await AffiliateLink.$find(this.landingPageUrlId);
      await affiliateLink.delete();
      const deletedAffiliateLink = this.customLandingPageLinks
        .map((item) => item.id)
        .indexOf(this.landingPageUrlId);
      this.customLandingPageLinks.splice(deletedAffiliateLink, 1);
      const deletedAffiliateLink2 = this.affiliateLinks
        .map((item) => item.id)
        .indexOf(this.landingPageUrlId);
      this.affiliateLinks.splice(deletedAffiliateLink2, 1);
      this.$loader.end('deleteLandingPageUrl');
    },

    async addLandingPageUrl() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$loader.start('addLandingPageUrl');
        this.custom_landing_page.name = this.model.name;
        this.custom_landing_page.type = this.model.type;
        this.custom_landing_page.funnel_id = this.selectedFunnelId;
        this.custom_landing_page.product_id = this.selectedMainProductId;
        this.custom_landing_page.landing_page_id = this.model.landing_page_id;
        this.custom_landing_page.link = this.model.link;

        const savedCustomLandingPage = await this.custom_landing_page.save();
        this.customLandingPageLink = savedCustomLandingPage.data;
        this.customLandingPageLinks.unshift(this.customLandingPageLink);
        this.affiliateLinks.unshift(this.customLandingPageLink);
        this.$loader.end('addLandingPageUrl');
        this.$root.$emit('modal-close', 'add-custom-landing-page-url');
      }
    },

    async openEditCustomLandingPage(id) {
      this.$loader.start('openEditCustomLandingPage');
      this.custom_landing_page = await AffiliateLink.$find(id);
      this.model.name = this.custom_landing_page.name;
      this.model.link = this.custom_landing_page.landing_page_url;
      this.$loader.end('openEditCustomLandingPage');
      this.$root.$emit('modal-open', 'edit-custom-landing-page-url');
    },

    async editCustomLandingPage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$loader.start('editCustomLandingPage');
        this.custom_landing_page.name = this.model.name;
        this.custom_landing_page.link = this.model.link;
        const savedCustomLandingPage = await this.custom_landing_page.save();

        const editedCustomLandingPage = this.customLandingPageLinks
          .map((item) => item.id)
          .indexOf(savedCustomLandingPage.data.id);

        this.customLandingPageLinks[editedCustomLandingPage] =
          savedCustomLandingPage.data;

        this.$loader.end('editCustomLandingPage');
        this.$root.$emit('modal-close', 'edit-custom-landing-page-url');
      }
    },

    async editBonus() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$loader.start('editBonus');
        this.bonus.name = this.model.name;
        this.bonus.link = this.model.link;
        const savedBonus = await this.bonus.save();
        const editedBonus = this.bonuses
          .map((item) => item.id)
          .indexOf(savedBonus.data.id);
        this.bonuses[editedBonus] = savedBonus.data;
        this.$loader.end('editBonus');
        this.$root.$emit('modal-close', 'edit-bonus-modal');
      }
    },


    async fetchAffiliateLinks(row, id, mainProductId) {
      this.$loader.start('fetchAffiliateLinks');
      this.affiliateLinksTab = 'aff_links';
      this.affiliateLinks = await AffiliateLink.params({
        funnel_id: id,
      }).$get();
      this.selectedFunnelId = id;
      this.selectedMainProductId = mainProductId;
      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },

    async fetchAffiliateBonuses(id) {
      this.$loader.start('fetchAffiliateBonuses');
      this.affiliateBonuses = await AffiliateBonus.params({
        funnel_id: id,
      }).$get();

      if (this.affiliateBonuses.length > 0) {
        this.affiliateBonusesTab = `affiliate_bonus_${this.affiliateBonuses[0].product_id}`;
      }

      this.$loader.end('fetchAffiliateBonuses');
      this.$root.$emit('modal-open', 'affiliate-bonuses-modal');
    },
    dashboard(id) {
      this.$router.push({ name: 'promos_dashboard', params: { id } });
    },
    dataSharing(id) {
      this.$router.push({ name: 'data_sharing', params: { funnel_id: id } });
    },
  },
};
</script>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#promos-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>
