<template>
    <div>
        <div class="flex flex-row mt-2 w-full">
            <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col w-6/12">
                    <div class="flex w-96">
                        <div class="w-full transactionsFilterContainer">
                            <multi-select v-model="localFilters.filteredTransactions" track-by="id" label="name"
                                :placeholder="$t('frontend_affiliate_settings_select_one')" :options="transactionFilters"
                                :searchable="false" :multiple="true" :allow-empty="false"
                                @input="onTransactionFilterChange">
                            </multi-select>
                        </div>
                    </div>

                    <div class="flex justify-start items-center space-x-4 mt-4">
                        <date-range-picker ref="picker" v-model="localFilters.transactionsDateRange"
                            :opens="opens" :auto-apply="autoApply" :start-date="customStartDate"
                            :end-date="customEndDate" :ranges="customDateRanges" @update="dateRangeChanged">
                            <div slot="input" slot-scope="picker">
                                {{ picker.startDate | moment('MM/DD/YYYY') }} -
                                {{ picker.endDate | moment('MM/DD/YYYY') }}
                            </div>
                        </date-range-picker>
                        <gd-dropdown
                            v-model="localFilters.selectedRowSize"
                            size="small"
                            :options="pageSizeOptions"
                            @change="onPageSizeChanged()"
                        />
                    </div>
                    <div class="flex mt-4 justify-start">
                        <gd-button
                            :class="{'animated bounce': !defaultFilters || applyTriggered}"
                            :variant="defaultFilters && !applyTriggered ? 'base' : 'primary'"
                            :disabled="defaultFilters && !applyTriggered"
                            @click="applyFilters"
                        >
                            {{ $t('apply_filters') }}
                        </gd-button>
                        <gd-button v-if="!defaultFilters && applyTriggered" variant="outline" size="auto" class="ml-4" @click="clearFilters">
                            <span class="mr-2">
                                <filter-icon class="w-5 h-5 fill-current" />
                            </span>
                            {{ $t('frontend_affiliate_transactions_reset') }}
                        </gd-button>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-row space-x-2">
                        <text-input v-model="localFilters.searchPhrase"
                            value="localFilters.searchPhrase" :placeholder="$t('frontend_affiliate_transactions_search')"
                            @input="searchPhraseChanged" />
                            <gd-button variant="primary" size="expand" class="mr-4" @click="openAdvancedFilters">
                                <span class="mr-2">
                                    <filter-icon class="w-5 h-5 fill-current" />
                                </span>
                                {{ $t('frontend_groovesell_modal_advanced_filters') }}
                            </gd-button>
                    </div>
                    <div class="flex justify-end mt-4">
                        <div class="mr-2">
                            {{ $t('frontend_affiliate_transactions_exclude_free_transactions') }}
                        </div>
                        <gd-toggle v-model="localFilters.excludeFreeTransactions" dark :value="localFilters.excludeFreeTransactions"
                            @change="excludeFreeTransactionsChanged" />
                    </div>
                    <div class="flex justify-end mt-4">
                        <gd-dropdown
                            :placeholder="$t('frontend_affiliate_transactions_export')"
                            :actions="exportActions"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row mt-2">
            <div class="w-1/2">
                # {{ $t('frontend_affiliate_transactions_transactions') }} :
                {{ transactionList?.meta?.total | numFormat }} |
                {{ $t('frontend_affiliate_transactions_revenue') }} :
                {{ transactionList?.meta?.total_revenue | currency }} |
                {{ $t('frontend_affiliate_transactions_commisions') }}:
                {{ transactionList?.meta?.total_commission | currency }}
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';

export default {
    components: {
        FilterIcon,
        ResetIcon
    },
    props: {
        currentTab: {
            type: String,
            default: 'captured'
        },
        filteredTransactions: {
            required: true
        },
        transactionsDateRange: {
            requied: true
        },
        reloadData: {
            required: true,
            type: Function
        },

        exportThisPageData: {
            required: true,
            type: Function
        },
        exportData: {
            required: true,
            type: Function
        },
        transactionList: {
            required: true
        },
        filteredFunnels: {
            default: () => []
        },
        filteredAdvancedTransactions: {},
    },

    data() {
        return {
            applyTriggered: false,
            localFilters: {
                filteredTransactions: this.filteredTransactions,
                transactionsDateRange: this.transactionsDateRange,
                selectedRowSize: 25,
                searchPhrase: '',
                excludeFreeTransactions: false,

            },
            opens: 'right',
            autoApply: true,
            customStartDate: moment(),
            customEndDate: moment(),
            customDateRanges: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                ],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [
                    moment().subtract(1, 'year').startOf('year'),
                    moment().subtract(1, 'year').endOf('year'),
                ],
                'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
            },

        }
    },
    computed: {
        defaultFilters() {
            return this.localFilters.filteredTransactions?.length == 1 &&
                this.localFilters.filteredTransactions[0].id == 0 &&
                this.localFilters.searchPhrase == '' &&
                this.localFilters.selectedRowSize == 25 && 
                this.filteredAdvancedTransactions == 0 &&
                !this.localFilters.excludeFreeTransactions &&
                this.filteredFunnels.length == 0 &&
                this.isDefaultDateRange(this.localFilters.transactionsDateRange.startDate) &&
                this.isDefaultDateRange(this.localFilters.transactionsDateRange.endDate);
        },
        transactionFilters() {
            return [
                { id: 0, name: this.$t('frontend_groovesell_all_transactions') },
                { id: 1, name: this.$t('frontend_groovesell_affiliates_filters_sale') },
                { id: 3, name: this.$t('frontend_groovesell_affiliates_filters_initial_payment') },
                { id: 2, name: this.$t('frontend_groovesell_affiliates_filters_rebill') },
                { id: 7, name: this.$t('frontend_groovesell_affiliates_filters_free_transactions') },
                { id: 4, name: this.$t('frontend_groovesell_affiliates_filters_cancellation') },
                { id: 5, name: this.$t('frontend_groovesell_affiliates_filters_refund') }
            ]
        },
        exportActions() {
            return [
                {
                    text: this.$t('frontend_affiliate_transactions_export_all'),
                    callback: () => this.exportData()
                },
                {
                    text: this.$t('frontend_affiliate_transactions_export_this_page'),
                    callback: () => this.exportThisPageData()
                }
            ]
        },
        pageSizeOptions() {
            return [
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ];
        },
    },
    methods: {
        isDefaultDateRange(dateString) {
            return moment(dateString).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
        },
        dateRangeChanged() {
            this.$emit('filter-changed', {
                key: 'transactionsDateRange',
                value: this.localFilters.transactionsDateRange
            });
        },
        onTransactionFilterChange() {
            if (this.localFilters.filteredTransactions && this.localFilters.filteredTransactions.length > 0) {
                const lastAddedTransactionFilterId = this.localFilters.filteredTransactions[this.localFilters.filteredTransactions.length - 1].id;

                if (lastAddedTransactionFilterId == 0) {
                    this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.slice(-1);
                } else {
                    this.localFilters.filteredTransactions = this.localFilters.filteredTransactions.filter((obj) => { return obj.id !== 0; });
                }
                this.$emit('filter-changed', {
                    key: 'filteredTransactions',
                    value: this.localFilters.filteredTransactions
                });
            }
        },
        onPageSizeChanged() {
            this.$emit('filter-changed', {
                key: 'selectedRowSize',
                value: this.localFilters.selectedRowSize
            });
        },
        searchPhraseChanged() {
            this.$emit('filter-changed', {
                key: 'searchPhrase',
                value: this.localFilters.searchPhrase
            });
        },
        excludeFreeTransactionsChanged() {
            this.$emit('filter-changed', {
                key: 'excludeFreeTransactions',
                value: this.localFilters.excludeFreeTransactions
            });

            this.$emit('apply-filters');
        },
        clearFilters() {
            this.applyTriggered = false;
            this.localFilters.filteredTransactions = [{ id: 0, name: this.$t('frontend_groovesell_all_transactions') }];
            this.localFilters.searchPhrase = '';
            this.localFilters.selectedRowSize = 25;
            this.localFilters.transactionsDateRange = {startDate: moment(), endDate: moment()};
            this.localFilters.excludeFreeTransactions = false;

            this.$emit('filter-changed', {
                key: 'clear',
                values: [
                    { key: 'searchPhrase', value: ''},
                    { key: 'selectedRowSize', value: 25 },
                    { key: 'transactionsDateRange', value: this.localFilters.transactionsDateRange},
                    { key: 'excludeFreeTransactions', value: false},
                    { key: 'filteredTransactions', value: this.localFilters.filteredTransactions },
                    { key: 'filteredAffiliates', value: [] },
                    { key: 'filteredFunnels', value: [] },
                    { key: 'filteredAdvancedTransactions', value: 0 }
                ]
            });
            this.$emit('apply-filters');
        },
        openAdvancedFilters() {
            this.$root.$emit('modal-open', 'advanced-filters-modal');
        },
        applyFilters() {
            this.applyTriggered = true;
            this.$emit('apply-filters');
        },
    },
    watch: {
        transactionsDateRange: {
            handler() {
                this.localFilters.transactionsDateRange = this.transactionsDateRange;
            },
            deep: true
        },
        filteredTransactions: {
            handler() {
                this.localFilters.filteredTransactions = this.filteredTransactions;
            },
            deep: true
        },
    }
}
</script>

<style>
.reportrange-text {
    height: 2.5rem;
    border-radius: 0.5rem !important;
    display: flex;
    align-items: center;
}

.animated { 
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
}

@-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-5px);} 
    60% {-webkit-transform: translateY(-5px);} 
} 

@keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-5px);} 
    60% {transform: translateY(-5px);} 
}

.bounce { 
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
}
</style>
