import store from '@/store';
import Model from './Model';

const ISDEMOMODE = 'internal/isDemoMode';

export default class AffiliateTransaction extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'affiliate-transactions';
  }

  get product_name() {
    return this.product.name;
  }

  get rendered_phone() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxx-xxx-xxxx';
    }

    return this.buyer_phone;
  }

  get rendered_buyer_company() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx';
    }

    return this.buyer_company;
  }

  get rendered_buyer_address() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx';
    }

    return this.buyer_address;
  }

  get rendered_buyer_email() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx@xxxx.xxx';
    }

    return this.buyer_email;
  }

  get rendered_buyer_fullname() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxxxx';
    }

    return this.buyer_fullname;
  }

  get rendered_affiliate_name() {
    if (store.getters[ISDEMOMODE] && this.affiliate_name) {
      return 'xxxxxxxxx';
    }

    return this.affiliate_name;
  }

  get rendered_affiliate_email() {
    if (store.getters[ISDEMOMODE] && this.affiliate_email) {
      return 'xxxxxxxxx@xxxx.xxxx';
    }

    return this.affiliate_email;
  }

  get rendered_affiliate_username() {
    if (store.getters[ISDEMOMODE] && this.affiliate_username) {
      return 'xxxxxxxxxx';
    }

    return this.affiliate_username;
  }

  get rendered_second_tier_affiliate_username() {
    if (store.getters[ISDEMOMODE] && this.second_tier_affiliate_username) {
      return 'xxxxxxxxx';
    }

    return this.second_tier_affiliate_username;
  }

  get rendered_second_tier_affiliate_email() {
    if (store.getters[ISDEMOMODE] && this.second_tier_affiliate_email) {
      return 'xxxxxxxxx@xxxx.xxxx';
    }

    return this.second_tier_affiliate_email;
  }

  get rendered_second_tier_affiliate_name() {
    if (store.getters[ISDEMOMODE] && this.second_tier_affiliate_name) {
      return 'xxxxxxxxxx';
    }

    return this.second_tier_affiliate_name;
  }
}
