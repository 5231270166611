<template>
    <gd-modal name="tracking-modal" :title="$t('frontend_groovesell_tracking_and_proof_widgets')" width="80%">
        <div style="min-width: 40vw">
            <div v-if="selectedTrackingFlow == 0" class="flex flex-col m-10">
                <div class="w-full flex flex-col">
                    <div class="flex flex-row w-full mb-6 justify-start">
                        <div class="w-1/2 mr-4">
                            <checkout-radio v-model="trackingFlow" option-name="Tracking Link"
                                option-icon="LinkIcon"></checkout-radio>
                        </div>
                        <div class="w-1/2">
                            <checkout-radio v-model="trackingFlow" option-name="Proof Widgets"
                                option-icon="CodeIcon"></checkout-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedTrackingFlow == 'Tracking Link'" class="flex flex-col mx-4">
                <div class="w-full mt-12">
                    <p class="text-sm font-semibold text-grey-700">
                        {{ $t('frontend_product_step11_pastecode') }}
                    </p>
                    <text-input v-model="selectedProduct.tracking_link" class="w-full mt-2" :readonly="true"
                        value="localModel.tracking_link" :placeholder="$t('frontend_product_index_tracking_link')"
                        :select-all="true" />
                </div>
                <div class="w-full text-center mb-12">
                    <gd-button v-clipboard:copy="selectedProduct.tracking_link" v-clipboard:success="onCopy"
                        v-clipboard:error="onError" variant="primary" size="md" class="mt-6">
                        {{ $t('frontend_copy') }}
                    </gd-button>
                </div>
            </div>
            <div v-else-if="selectedTrackingFlow == 'Proof Widgets'" class="flex flex-col m-6">
                <div v-if="proofWidgets.length">
                    <gd-tabs :tabs="proofWidgetsTabs" :current-tab="proofWidgetsTab"
                        :wrapper-class="'pt-0 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                        :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                        :line-class="'gs-default-tabs-active-line'" @onClick="handleProofWidgetsTabChange" />
                    <div class="mt-2">
                        <div v-for="(proofWidget, index) in proofWidgets" :key="index"
                            :ref="'proof_widget_' + proofWidget.id">
                            <div v-if="proofWidgetsTab === 'proof_widget_' + proofWidget.id" class="flex flex-col mt-4">
                                <text-area v-model="proofWidget.embed_code" class="w-full mt-12" :readonly="true"
                                    value="proofWidget.embed_code" :placeholder="$t('frontend_widget_code')"
                                    :select-all="true" />
                                <div class="text-center">
                                    <gd-button v-clipboard:copy="proofWidget.embed_code"
                                        v-clipboard:success="onCopyProofWidget" v-clipboard:error="onErrorProofWidget"
                                        variant="primary" size="md" class="mt-6 mb-12">
                                        {{ $t('frontend_copy') }}
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    {{ $t('frontend_product_index_dont_have_any_proof') }}
                </div>
            </div>

            <div v-if="trackingFlow">
                <div class="flex flex-row items-end justify-end m-6">
                    <gd-button variant="primary" size="md" @click="showTrackingOptions">{{ $t('frontend_back') }}</gd-button>
                    <gd-button v-if="selectedTrackingFlow == 0" class="ml-2" variant="primary" size="md"
                        @click="showTrackingCode">{{ $t('frontend_next') }}</gd-button>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
    props: {
        selectedProduct: {
            type: Object,
            required: true
        },
        proofWidgets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedTrackingFlow: 0,
            trackingFlow: '',
            proofWidgetsTab: '',

        }
    },
    created() {
        this.trackingFlow = 'Tracking Link';
    },
    /** on modal close */
    beforeDestroy() {
        this.trackingFlow = '';
        this.selectedTrackingFlow = 0;
    },
    computed: {
        proofWidgetsTabs() {
            const proofWidgetsTabs = [];
            for (let i = 0; i < this.proofWidgets.length; i++) {
                proofWidgetsTabs.push({
                    title: this.proofWidgets[i].name,
                    value: `proof_widget_${this.proofWidgets[i].id}`,
                });
            }
            return proofWidgetsTabs;
        },
    },
    methods: {
        handleProofWidgetsTabChange(newTab) {
            this.proofWidgetsTab = newTab;
        },
        showTrackingOptions() {
            this.trackingFlow = 'Tracking Link';
            this.selectedTrackingFlow = 0;
        },
        showTrackingCode() {
            this.selectedTrackingFlow = this.trackingFlow;
        },
        onCopy() {
            Notify.success(this.$t('frontend_tracking_code_copied_to_clipboard'));
        },

        onError() {
            Notify.fail(this.$t('frontend_unable_copy_tracking_code'));
        },
        onCopyProofWidget() {
            Notify.success(this.$t('frontend_proof_widget_code_copy_to_clipboard'));
        },

        onErrorProofWidget() {
            Notify.fail(this.$t('frontend_unable_copy_proof_widget_code'));
        },
    }
}
</script>