<template>
    <div>
        <div v-if="localcheckoutLinks.length" id="products-list-view" class="mt-8">
            <v-client-table ref="checkoutLinks" :data="localcheckoutLinks" :columns="checkouLinkTableColumns"
                :options="checkoutLinksTableOptions" class="bg-white text-center rounded border border-grey-300">
                <div slot="revenue" slot-scope="props">
                    ${{ props.row.revenue }}
                </div>
                <div slot="commissions" slot-scope="props">
                    ${{ props.row.commissions }}
                </div>
                <div slot="revenue" slot-scope="props">
                    {{ props.row.currency_symbol }}{{ props.row.revenue }}
                </div>
                <div slot="commissions" slot-scope="props">
                    {{ props.row.currency_symbol }}{{ props.row.commissions }}
                </div>
                <div slot="expand" slot-scope="props">
                    <div class="expandIcon cursor-pointer" @click="showPricePointDetails(props.row.id)">
                        <Snapshot class="w-4" />
                    </div>
                </div>
                <div slot="actions" slot-scope="props">
                    <div class="flex flex-row">
                        <gd-button variant="primary" size="icon" class="mr-2"
                            @click="openCheckoutPageLink(props.row.id)">
                            <shopping-cart-icon class="w-5 h-5 fill-current" />
                        </gd-button>

                        <gd-button variant="primary" size="icon" class="mr-2"
                            @click="openEditCheckoutPage(props.row.id, false)">
                            <pencil-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                        <gd-button variant="primary" size="icon" class="mr-2" @click="cloneCheckout(props.row.id)">
                            <clone-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                        <gd-button variant="primary" size="icon" class="mr-2" @click="openConfirmModal(props.row.id)">
                            <trash-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                    </div>
                </div>
            </v-client-table>
        </div>
        <div v-else class="mt-8">
            {{ $t('frontend_no_checkout_link_added') }}
        </div>
        <confirm-modal name="confirm-checkoutlink-delete-modal" :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')" button-size="auto" @confirm="deleteProductCheckout" />
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>
<script>
import CheckoutLink from '@/models/CheckoutLink';
import axios from 'axios';
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import CloneIcon from '@/assets/images/icons/CloneIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import Snapshot from '@/assets/images/icons/Focus.svg';

export default {
    components: {
        ShoppingCartIcon,
        PencilIcon,
        CloneIcon,
        TrashIcon,
        PlusIcon,
        Snapshot,
    },
    props: {
        checkoutLinks: {
            type: Array,
            required: true
        },
        openCheckoutPageLink: {
            type: Function,
            required: true
        },
        openEditCheckoutPage: {
            type: Function,
            required: true
        },
        selectedProduct: {
            type: Object,
            required: true
        },
    },
    watch: {
        checkoutLinks: {
            handler() {
                this.localcheckoutLinks = this.checkoutLinks;
            },
            deep: true,
        },
        selectedProduct: {
            handler() {
                this.localselectedProduct = this.selectedProduct;
            },
            deep: true,
        },
    },
    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
            checkoutLinkId: null,
            localselectedProduct: this.selectedProduct,
            localcheckoutLinks: this.checkoutLinks,
            checkouLinkTableColumns: ['name', 'actions'],
            checkoutLinksTableOptions: {
                sortIcon: {
                    base: 'fa',
                    is: 'fa-sort',
                    up: 'fa-sort-asc',
                    down: 'fa-sort-desc',
                },
                sortable: ['name'],
                headings: {
                    name: this.$t('frontend_name'),
                    actions: this.$t('frontend_actions'),
                },
                filterByColumn: false,
                filterable: false,
                childRowTogglerFirst: false,
                perPage: 10000,
                rowClassCallback() {
                    return 'hover:bg-grey-50 funnelRow';
                },
            },
        }
    },
    methods: {
        async cloneCheckout(id) {
            this.$loader.start('cloneCheckout');
            await axios.post('clone-checkout-link', { id });
            this.localcheckoutLinks = await this.localselectedProduct.checkoutLinks().$get();
            this.$loader.end('cloneCheckout');
        },
        openConfirmModal(id) {
            this.$root.$emit('modal-open', 'confirm-checkoutlink-delete-modal');
            this.checkoutLinkId = id;
        },
        async deleteProductCheckout() {
            this.$loader.start('deleteProductCheckout');
            const checkoutData = await CheckoutLink.$find(this.checkoutLinkId);
            await checkoutData.delete();

            const deletedData = this.localcheckoutLinks
                .map((item) => item.id)
                .indexOf(this.checkoutLinkId);
            this.localcheckoutLinks.splice(deletedData, 1);
            this.$loader.end('deleteProductCheckout');
            this.alertProps = {
                show: true,
                variant: 'success',
                title: this.$t('frontend_groovesell_swal_checkout_link_deleted_title'),
                message: this.$t('frontend_groovesell_swal_checkout_link_deleted_text'),
            };
        },
    }
}
</script>