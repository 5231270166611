<template>
  <div>
    <v-wait for="dashboard">
      {{ $t('frontend_coming_soon') }}
    </v-wait>
  </div>
</template>

<script>
export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Self Billing' };
  },
};
</script>
