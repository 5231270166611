<template>
  <div
    class="stats__transactions__item w-full sm:w-1/3 md:w-1/5 xl:flex-grow min-w-32 py-2"
  >
    <div class="stats__transactions__value font-bold">
      {{ value }}
    </div>
    <div class="stats__transactions__title uppercase text-grey-500">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsItem',
  props: {
    title: String,
    value: String,
  }
};
</script>
