import store from '@/store';
import Model from './Model';

const ISDEMOMODE = 'internal/isDemoMode';
const DEMOMODEMASK = 'xxxx-xxxx-xxxx';

export default class CustomerReport extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'customer-report';
  }

  get rendered_buyer_company() {
    if (store.getters[ISDEMOMODE]) {
      return DEMOMODEMASK;
    }
    return this.buyer_company;
  }

  get rendered_buyer_address() {
    if (store.getters[ISDEMOMODE]) {
      return DEMOMODEMASK;
    }
    return this.buyer_address;
  }

  get rendered_buyer_email() {
    if (store.getters[ISDEMOMODE]) {
      return DEMOMODEMASK;
    }
    return this.email;
  }

  get rendered_buyer_fullname() {
    if (store.getters[ISDEMOMODE]) {
      return DEMOMODEMASK;
    }
    return this.buyer_name;
  }
}
