<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="filesPricePointsTabs" :error-tabs="contentAccessPricePointsErrorTabs"
                :current-tab="filesPricePointsTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleFilesPricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="filesPricePointsTab === 'files_price_point_' + pricePoint.id
                    " class="flex flex-col w-full">
                    <div v-if="membership_files.length > 0">
                        <div class="flex flex-col">
                            <div class="flex flex-row-reverse w-full">
                                <gd-button
                                    size="auto"
                                    variant="primary"
                                    class="mr-4"
                                    @click="emitShowFileMembershipBox(index)"
                                >
                                    <plus-icon class="w-5 h-5 fill-current" />
                                    {{ $t('frontend_product_step8_add_new_membership_file') }}
                                </gd-button>
                            </div>
                            <div v-if="pricePoint.file_memberships &&
                                    pricePoint.file_memberships.length > 0
                                    ">
                                <div v-for="(file, fileIndex) in pricePoint.file_memberships" :key="fileIndex"
                                    class="flex flex-col mt-4">
                                    <div class="flex flex-row" :class="{
                                        'border-t border-grey-400 border-solid pt-4':
                                            fileIndex > 0,
                                    }">
                                        <div class="w-1/12 mr-8">
                                            {{ $t('frontend_send') }}
                                        </div>
                                        <div class="flex flex-row w-11/12">
                                            <div class="w-3/4 flex flex-col">
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_name') }}
                                                    </div>
                                                    <div>
                                                        {{ fetchMemberFileName(file.file_id) }}
                                                    </div>
                                                </div>
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_file_name') }}
                                                    </div>
                                                    <div>
                                                        {{ fetchMemberOriginalFileName(file.file_id) }}
                                                    </div>
                                                </div>
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_size') }}
                                                    </div>
                                                    <div>
                                                        {{ fetchMemberFileSize(file.file_id) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-1/4 flex flex-row justify-end pr-4">
                                                <gd-button size="icon" variant="primary"
                                                    :title="$t('frontend_groovesell_delete_file')" class="mr-2"
                                                    @click="openConfirmModal(index, fileIndex)">
                                                    <trash-icon class="h-5 w-5 fill-current"/>
                                                </gd-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <label class="text-grey-dark">{{
                                    $t('frontend_product_step8_no_memberships_files')
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <label class="text-grey-dark">{{
                            $t('frontend_product_step8_no_memberships_files_added', {memberAppTitle: whitelabelAppData('member').title})
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="confirm-delete-file-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')"
            button-size="auto"
            @confirm="deleteFile"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        
        membership_files: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
            filesPricePointsTab: '',
            pricePointIndex: null,
            fileIndex: null,
        }
    },
    mounted() {
        if (this.filesPricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.filesPricePointsTab = `files_price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    computed: {
        filesPricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `files_price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        contentAccessPricePointsErrorTabs() {
            return [];
        },
    },
    methods: {
        handleFilesPricePointsTabChange(newTab) {
            this.filesPricePointsTab = newTab;
        },
        emitShowFileMembershipBox(index) {
            this.$emit('show-file-membership-box', index);
        },
        fetchMemberFileName(fileId) {
            const file = this.membership_files.find((obj) => {
                return obj.id == fileId;
            });
            if (file) {
                return file.name;
            }
            return 'N/A';
        },
        fetchMemberOriginalFileName(fileId) {
            const file = this.membership_files.find((obj) => {
                return obj.id == fileId;
            });
            if (file) {
                return file.original_name;
            }
            return 'N/A';
        },
        fetchMemberFileSize(fileId) {
            const file = this.membership_files.find((obj) => {
                return obj.id == fileId;
            });

            if (file) {
                return file.size;
            }
            return 'N/A';
        },
        openConfirmModal(index, fileIndex) {
            this.$root.$emit('modal-open', 'confirm-delete-file-modal');
            this.pricePointIndex = index;
            this.fileIndex = fileIndex;
        },
        async deleteFile() {
            this.localModel.pricing_points[this.pricePointIndex].file_memberships.splice(
                this.fileIndex,
                1
            );
        },
    }
}
</script>