<template>
  <div
    class="w-full relative h-10 border border-solid rounded-lg"
    :class="{
      'text-white border-grey-700 bg-grey-800': hasState('dark'),
      'text-grey-600 border-grey-200 bg-white': !hasState('dark'),
      'border-primary-dark error-form-group': hasErrors,
    }"
  >
    <input
      ref="input"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      class="w-full h-full outline-none rounded-lg px-4"
      :class="{
        'bg-grey-800': hasState('dark'),
        'bg-white': !hasState('dark'),
        'bg-grey-100': disabled,
      }"
      :min="min"
      :max="max"
      @keyup="keyup"
      @keydown="keydown"
      @focus="inputFocussed"
      @input="input"
    />
    <div v-if="hasErrors" class="w-full error text-primary-dark mt-1 text-sm">
      {{ firstErrorMessage }}
    </div>
  </div>
</template>

<script>
import mixins from '@groovepages/gd-ui-kit/mixins';
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'TextInput',
  mixins: [singleErrorExtractorMixin, mixins],
  props: {
    dark: {},
    value: {},
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
  },
  methods: {
    input() {
      this.$emit('input', this.$refs.input.value);
    },
    keyup() {
      this.$emit('input', this.$refs.input.value);
      this.$emit('keyup', this.$refs.input.value);
    },

    keydown(e) {
      this.$emit('keydown', e);
    },

    inputFocussed(e) {
      if (this.selectAll) {
        e.target.select();
      }
    },
  },
};
</script>

<style>
.error-form-group {
  border-color: var(--brand-primary-dark) !important;
}
</style>
