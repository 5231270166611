<template>
  <div class="flex flex-col content-center justify-center text-center w-full bg-white rounded p-4">
    <div class="flex justify-center text-green-dark mt-4 mb-2">
      <check-circle-icon class="w-20 h-20 fill-current" />
    </div>
    <div class="mb-2">
      <h3 class="text-xl font-bold">
        {{ $t('frontend_product_step11_congratulations') }}
      </h3>
    </div>
    <div class="mb-8">
      <p class="text-sm font-semibold text-grey-700">
        {{ $t('frontend_groovesell_contracts_step4_create_successfully') }}!
      </p>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@/assets/images/icons/CheckCircleIcon.svg';

export default {
  name: 'Step4',
  components: {
    CheckCircleIcon,
  },
  methods: {
    validate() {
      this.$emit('on-validate', true);
    },
  }
};
</script>
