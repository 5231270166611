<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                    <div v-if="pricePoint.post_sale_details &&
                        pricePoint.post_sale_details.length > 0
                        ">
                        <div class="flex flex-row-reverse w-full">
                            <gd-button size="auto" variant="primary" class="mr-4" @click="addIPNURL(index)">
                                <plus-icon class="h-5 w-5 fill-current"/>
                                {{ $t('frontend_product_step8_no_integrations_add_new_webhook') }}
                            </gd-button>
                        </div>
                        <div v-for="(ipnUrl, ipnIndex) in pricePoint.post_sale_details" :key="ipnIndex"
                            class="flex flex-col w-1/2 mb-6">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_product_step8_webhook_url')
                            }}</label>
                            <div class="flex flex-row w-full">
                                <div class="w-11/12">
                                    <text-input v-model="ipnUrl.link"
                                        :validator="validation.localModel.pricing_points.$each[index].post_sale_details.$each[ipnIndex].link"
                                        :attribute="$t('frontend_product_step8_ipn_url')" value="ipnUrl.link"
                                        :placeholder="$t('frontend_product_step8_ipn_url')" />
                                </div>
                                <gd-button variant="outline" size="iconlg" class="ml-2" :title="$t('frontend_groovesell_delete')"
                                    @click="deleteIPNURL(index, ipnIndex)">
                                    <trash-icon class="h-5 w-5 fill-current"/>
                                </gd-button>
                            </div>
                        </div>
                    </div>

                    <div v-else class="flex flex-col w-full">
                        <div class="flex flex-row-reverse w-full">
                            <gd-button size="auto" variant="primary" class="mr-4" @click="addIPNURL(index)">{{
                                $t('frontend_product_step8_add_new_webhook')
                            }}</gd-button>
                        </div>
                        <label class="text-grey-dark">{{
                            $t('frontend_product_step8_no_webhook')
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        validation: {
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: '',
        };
    },
    mounted() {
        if (this.pricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
        addIPNURL(index) {
            if (this.localModel.pricing_points[index].post_sale_details) {
                this.localModel.pricing_points[index].post_sale_details.push({ type: 1, link: '' });
            } else {
                this.localModel.pricing_points[index].post_sale_details = [];
                this.localModel.pricing_points[index].post_sale_details.push({ type: 1, link: '' });
            }
        },

        deleteIPNURL(index, ipnIndex) {
            this.localModel.pricing_points[index].post_sale_details.splice(ipnIndex, 1);
        },
    },
    computed: {
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];
            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error) {
                    pricePointErrorTabs.push(
                        `price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`
                    );
                }
            }
            return pricePointErrorTabs;
        },
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
    }
}
</script>