<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-3/5 bg-white rounded p-4">
        <div class="flex flex-col w-full mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{
            $t('frontend_product_step1_product_name')
          }}</label>
          <text-input v-model="localModel.name" :validator="$v.localModel.name"
            :attribute="$t('frontend_product_step1_product_name')" value="localModel.name"
            :placeholder="$t('frontend_product_step1_product_name')" />
        </div>
        <div class="flex flex-col w-full mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{
            $t('frontend_product_step1_internal_name')
          }}</label>
          <text-input v-model="localModel.internal_name" :validator="$v.localModel.internal_name"
            :attribute="$t('frontend_product_step1_internal_name')" value="localModel.internal_name"
            :placeholder="$t('frontend_product_step1_internal_name')" />
        </div>
        <div class="mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">{{
            $t('frontend_product_step1_description')
          }}</label>
          <text-area v-model="localModel.description" :validator="$v.localModel.description"
            :attribute="$t('frontend_product_step1_description')" value="localModel.description"
            :placeholder="$t('frontend_product_step1_description')" />
        </div>
      </div>
      <div class="flex flex-row justify-center w-2/5 bg-white rounded p-4">
        <div class="mt-6 text-center">
          <p class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_product_image') }}</p>
          <div :key="imagePickerKey" class="relative rounded-full overflow-hidden">
            <img v-if="localModel.checkout_page_options.product_image" class="relative rounded-full w-40 h-40"
              :src="localModel.checkout_page_options.product_image" alt="" />
            <gifts-icon v-else class="relative w-40 h-40 text-primary fill-current p-5" />
            <label for="desktop-user-photo" class="absolute inset-0 w-40 h-40 rounded-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100" @click="showImageSelectionModal">
              <span>{{ $t('frontend_change') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full bg-white rounded p-4">
      <div class="flex flex-col w-full mb-6">
        <div class="w-full flex flex-row">
          <div v-for="(productType, index) in productTypes" :key="index" class="flex w-1/2 mr-4">
            <gd-custom-radio v-model="localModel.type" :option="productType"></gd-custom-radio>
          </div>
        </div>
      </div>
    </div>
    <gd-modal
      ref="productImageModal"
      name="image-picker"
      width="100%"
      height="100%"
      :hideHeader="true"
    >
    <image-picker-without-modal
      :value="localModel.checkout_page_options.product_image"
      modal="true"
      remove="false"
      @close-modal="$refs.productImageModal.close()"
      @selected="setProductImage"
    ></image-picker-without-modal>
    </gd-modal>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import ImagePickerWithoutModal from '@groovepages/gd-ui-kit/components/ImagePicker';
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';

export default {
  name: 'Step1',
  props: {
    model: {
      required: true,
    },
    gatewayCheckBox: {
      required: true,
    },
    gatewayIntegrations: {
      required: true,
    },
  },
  components: {
    ImagePicker,
    ImagePickerWithoutModal,
    GiftsIcon,
  },
  data() {
    return {
      imagePickerKey: 1,
      localModel: this.model,
      localGatewayCheckBox: this.gatewayCheckBox,
      localGatewayIntegrations: this.gatewayIntegrations,
      selectedPaypalGatewayAccount: 0,
      selectedCardGatewayAccount: 0,
      productTypes: [
        {
          id: 1,
          title: this.$t('frontend_product_step1_frontend_product'),
          description: this.$t('frontend_product_step1_this_will_be_frontend'),
        },
        {
          id: 2,
          title: this.$t('frontend_product_step1_upsell_downsell'),
          description: this.$t('frontend_product_step1_this_will_be_backend'),
        },
        {
          id: 3,
          title: this.$t('frontend_product_step1_order_bump'),
          description: this.$t(
            'frontend_product_step1_this_will_be_order_bump'
          ),
        },
      ],
    };
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      internal_name: {
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
    },
  },
  computed: {
    paypalIntegrations() {
      return this.localGatewayIntegrations.filter((integration) => {
        return integration.platform == 'paypal';
      });
    },

    cardIntegrations() {
      return this.localGatewayIntegrations.filter((integration) => {
        return integration.platform != 'paypal';
      });
    },

    paypalAddedAccounts() {
      return this.localModel.paypal_processing_accounts.map((a) => a.id);
    },

    cardAddedAccounts() {
      return this.localModel.credit_card_processing_accounts.map((a) => a.id);
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },

    localGatewayCheckBox() {
      this.$emit('updateGatewayCheckbox', this.localGatewayCheckBox);
    },
  },
  created() { },
  methods: {
    showImageSelectionModal() {
      this.$refs.productImageModal.open();
    },

    setProductImage(value) {
      this.localModel.checkout_page_options.product_image = value;
      this.imagePickerKey = this.imagePickerKey + 1;
    },
    getHex() {
      let rgb = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(',');
      return `#${((1 << 24) +(Number(rgb[0]) << 16) +(Number(rgb[1]) << 8) +Number(rgb[2]))
        .toString(16)
        .slice(1)}`;
    },

    setGatewayCheckbox(gateway) {
      this.localGatewayCheckBox = gateway;
    },

    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },

    integrationName(id) {
      const integration = this.localGatewayIntegrations.find((obj) => {
        return obj.id === id;
      });

      return integration.name;
    },

    addPaypalGateway() {
      const self = this;
      this.localModel.paypal_processing_accounts.push({
        id: self.selectedPaypalGatewayAccount,
        weightage: 0,
      });

      self.selectedPaypalGatewayAccount = 0;
    },

    deletePaypalGateway(id) {
      this.localModel.paypal_processing_accounts =
        this.localModel.paypal_processing_accounts.filter(
          (obj) => obj.id !== id
        );
    },

    addCardGateway() {
      const self = this;
      this.localModel.credit_card_processing_accounts.push({
        id: self.selectedCardGatewayAccount,
        weightage: 0,
      });

      self.selectedCardGatewayAccount = 0;
    },

    deleteCardGateway(id) {
      this.localModel.credit_card_processing_accounts =
        this.model.credit_card_processing_accounts.filter(
          (obj) => obj.id !== id
        );
    },

    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
