import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openTransactionDetail() {
      if (this.params.data.underlying_transaction) {
        this.params.context.componentParent.openTransactionDetail(
          this.params.data.underlying_transaction.id
        );
      }
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openTransactionDetail">
            <span v-if="this.params.data.underlying_transaction">{{this.params.data.underlying_transaction.transaction_id}}</span>
            <span v-else>N/A</span>
        </span>
    `
});
