<template>
  <div class="flex flex-col w-1/4 mr-5">
    <div>
      <div class="rounded pink_checkboxes">
        <!--filters start here -->
        <div class="bg-white rounded rounded-lg p-4 mt-8 border border-grey-200">
          <div v-if="filters.categoriesFilterList">
            <div class="mb-4 pb-2 border-b border-grey-100 text-sm font-semibold text-grey-500">
              {{ $t('frontend_categories') }}
            </div>

            <div>
              <div
                class="flex flex-row ml-2 mb-2"
                v-for="(cat_val, index) in filters.categoriesFilterList"
                :key="index"
              >
                <gd-checkbox
                  variant="primary"
                  v-model="cat_val.enable"
                  @change="categoryChange(cat_val.id)"
                />
                <span class="ml-2 text-grey-700 text-sm">
                  {{ cat_val.name }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="filters.billingType" class="mt-8">
            <div class="mb-4 pb-2 border-b border-grey-100 text-sm font-semibold text-grey-500">
              {{ $t('frontend_billing_types') }}
            </div>
            <div>
              <div class="flex flex-row mb-2 ml-2">
                <gd-checkbox
                  variant="primary"
                  v-model="filters.billingType.all"
                  @change="billingTypeChange(0)"
                />
                <span class="ml-2 text-grey-700 text-sm">
                  {{ $t('frontend_all_type') }}
                </span>
              </div>

              <div class="flex flex-row mb-2 ml-2">
                <gd-checkbox
                  variant="primary"
                  v-model="filters.billingType.one_time"
                  @change="billingTypeChange(1)"
                />
                <span class="ml-2 text-grey-700 text-sm">{{
                  $t('frontend_one_time')
                }}</span>
              </div>

              <div class="flex flex-row mb-2 ml-2">
                <gd-checkbox
                  variant="primary"
                  v-model="filters.billingType.recurring"
                  @change="billingTypeChange(2)"
                />
                <span class="ml-2 text-grey-700 text-sm">{{
                  $t('frontend_recurring')
                }}</span>
              </div>

              <div class="flex flex-row mb-2 ml-2">
                <gd-checkbox
                  variant="primary"
                  v-model="filters.billingType.installments"
                  @change="billingTypeChange(3)"
                />
                <span class="ml-2 text-grey-700 text-sm">{{
                  $t('frontend_installments')
                }}</span>
              </div>

              <div class="flex flex-row mb-2 ml-2">
                <gd-checkbox
                  variant="primary"
                  v-model="filters.billingType.reccuring_installments"
                  @change="billingTypeChange(4)"
                />
                <span class="ml-2 text-grey-700 text-sm">{{
                  $t('frontend_recurring_installments')
                }}</span>
              </div>
            </div>
          </div>

          <div v-if="filters.billingType" class="mt-8">
            <div
              class="
                mb-4
                pb-2
                border-b border-grey-100
                text-sm
                font-semibold
                text-grey-500
              "
            >
              {{ $t('frontend_trial') }}
            </div>
            <div>
              <div class="flex flex-row mb-2 ml-2">
                <span class="text-grey-700 text-sm mr-3">{{
                  $t('frontend_is_trial')
                }}</span>
                <gd-toggle
                  v-model="filters.trialFilterList.trial"
                  :value="filters.trialFilterList.trial"
                  @change="trialSwitchValue(1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoriesFilterList: {
      type: Array,
      required: true,
    },
    billingType: {
      type: Object,
      required: true,
    },
    trialFilterList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        categoriesFilterList: this.categoriesFilterList,
        billingType: this.billingType,
        trialFilterList: this.trialFilterList,
      },
    };
  },
  methods: {
    categoryChange(id) {
      this.$emit('categoryChange', id, this.filters.categoriesFilterList);
    },
    billingTypeChange(id) {
      this.$emit('billingTypeChange', id, this.filters.billingType);
    },
    trialSwitchValue(id) {
      this.$emit('trialSwitchValue', this.filters.trialFilterList);
    },
  },
};
</script>
