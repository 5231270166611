<template>
  <div>
    <!-- Add New Pixel Button -->
    <div class="flex flex-row justify-end mb-6">
      <gd-button
        size="auto"
        variant="primary"
        @click="createPixel"
      >
        <plus-icon class="w-5 h-5 fill-current" />
        {{ $t('add_new_pixel') }}
      </gd-button>
    </div>
    <!-- Pixel List -->
    <div
      v-if="fb_conversion_detail_list.length"
      id="links-list-view"
    >
      <v-client-table
        ref="linksTable"
        :data="fb_conversion_detail_list"
        :columns="tableColumns"
        :options="tableOptions"
        class="bg-white text-center rounded border border-grey-300"
      >
        <div slot="actions" slot-scope="props">
          <!-- Edit Button -->
          <div class="flex flex-row">
            <gd-button
              variant="primary"
              size="icon"
              class="mr-2"
              @click="editDetail(props.row.id)"
            >
              <pencil-icon class="w-5 h-5 fill-current" />
            </gd-button>
            <!-- Send Test Event Button -->
            <gd-button
              variant="primary"
              size="icon"
              class="mr-2"
              @click="sendTestEvent(props.row.id)"
              :title="$t('frontend_groovesell_send_test_event')"
            >
              <paper-plane-icon class="w-5 h-5 fill-current" />
            </gd-button>
            <!-- Delete Button -->
            <gd-button
              variant="primary"
              size="icon"
              class="mr-2"
              @click="openConfirmModal(props.row.id)"
            >
              <trash-icon class="w-5 h-5 fill-current" />
            </gd-button>
          </div>
        </div>
      </v-client-table>
    </div>
    <!-- No Pixel Message -->
    <div v-else>
      {{ $t('frontend_no_pixel_added') }}
    </div>

    <gd-modal
      name="add-facebook-conversion-detail-modal"
      :title="$t('frontend_groovesell_facebook_conversion_details')"
    >
      <!-- Facebook Conversion Detail Form -->
      <div class="flex flex-col m-10 mt-6">
        <div class="flex flex-col w-full mb-6">
          <!-- Pixel Name Input -->
          <div class="flex flex-col w-1/1 mb-4">
            <label class="text-grey-700 mt-2 mr-2">
              {{ $t('frontend_pixel_name') }}
            </label>
            <text-input
              v-model="fb_conversion_detail.name"
              :validator="$v.fb_conversion_detail.name"
              class="mt-2"
              value="fb_conversion_detail.name"
              :placeholder="$t('frontend_pixel_name')"
              @input="triggerValidation('name')"
            />
          </div>

          <!-- Pixel ID Input -->
          <div class="flex flex-col w-1/1 mb-4">
            <label class="text-grey-700 mt-2 mr-2">
              {{ $t('frontend_pixel_id') }}
            </label>
            <text-input
              v-model="fb_conversion_detail.pixel_id"
              :validator="$v.fb_conversion_detail.pixel_id"
              class="mt-2"
              value="fb_conversion_detail.pixel_id"
              :placeholder="$t('frontend_pixel_id')"
              @input="triggerValidation('pixel_id')"
            />
          </div>

          <!-- Access Token Input -->
          <div class="flex flex-col w-1/1 mb-4">
            <label class="text-grey-700 mt-2 mr-2">
              {{ $t('frontend_access_token') }}
            </label>
            <text-area
              v-model="fb_conversion_detail.access_token"
              :validator="$v.fb_conversion_detail.access_token"
              class="mt-2"
              value="fb_conversion_detail.access_token"
              :placeholder="$t('frontend_access_token')"
              @input="triggerValidation('access_token')"
            />
          </div>

          <!-- Guide Link -->
          <div class="w-full flex flex-row mb-4">
            <label class="w-full mt-1 text-grey-700">
              <span class="text-red">{{ $t('frontend_analytics_transactions_note') }}</span> {{ $t('frontend_follow_guide') }}
              <a
                :href="facebookDevelopersUrl"
                class="text-blue underline underline-offset-1 font-semibold"
                target="_blank"
              >{{ $t('here') }}</a>
              {{ $t('get_your_pixel_id') }}
            </label>
          </div>

          <!-- Save Buttons -->
          <div class="flex justify-end pr-2 pb-4">
            <gd-button
              v-if="!$v.fb_conversion_detail.$invalid"
              variant="primary"
              size="md"
              class="mt-6"
              @click="saveConversionSettings"
            >
              {{ $t('frontend_save') }}
            </gd-button>
            <gd-button
              v-else
              disabled
              size="md"
              class="mt-6"
            >
              {{ $t('frontend_save') }}
            </gd-button>
          </div>
        </div>
      </div>
    </gd-modal>
    <confirm-modal
      name="confirm-conversion-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteDetail"
    />
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import Notify from '@groovepages/gd-ui-kit/notify';
import {
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PaperPlaneIcon from '@/assets/images/icons/PaperPlaneIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  components: {
    PencilIcon,
    TrashIcon,
    PaperPlaneIcon,
    PlusIcon,
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      fbConversionId: null,
      facebookDevelopersUrl: 'https://developers.facebook.com/docs/marketing-api/conversions-api/get-started',
      is_editing: false,
      editing_id: null,
      fb_conversion_detail_list: [],
      fb_conversion_detail: {},
      tableColumns: ['name', 'actions'],
      tableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        sortable: [],
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
    }
  },
  async created() {
    this.$loader.start('fetchList');

    await this.getList();

    this.$loader.end('fetchList');
  },
  methods: {
    triggerValidation(key) {
      this.$v.fb_conversion_detail[key].$touch();
    },
    async createPixel() {
      this.fb_conversion_detail = {};
      this.is_editing = false;
      this.$root.$emit('modal-open', 'add-facebook-conversion-detail-modal');
    },
    async editDetail(Id) {
      this.$loader.start('editDetail');
      this.editing_id = Id;
      this.is_editing = true;
      const fbSetting = await axios.post('/conversion-settings', {
        list: false,
        id: Id,
      });
      this.fb_conversion_detail = fbSetting.data;
      this.$root.$emit('modal-open', 'add-facebook-conversion-detail-modal');
      this.$loader.end('editDetail');
    },
    async sendTestEvent(Id) {
      Swal.fire({
        title: '',
        text: this.$t('enter_test_event_name'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return !value && this.$t('you_need_to_write_something');
        },
        preConfirm: (eventName) => {
          return axios
            .post(`/send-test-event`, {
              event_name: eventName,
              pixel_id: Id,
            })
            .then((response) => {
              if (!response.data.events_received) {
                throw new Error(response.statusText);
              }
            })
            .catch((error) => {
              Swal.showValidationMessage(`${this.$t('request_failed')} ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.value) {
          this.alertProps = {
            show: true,
            variant: 'success',
            title: '',
            message: this.$t('test_event_sent'),
          };
        }
      });
    },
    async getList() {
      const fbSetting = await axios.post('/conversion-settings', {
        list: true,
      });
      this.fb_conversion_detail_list = fbSetting.data.data;
    },
    async saveConversionSettings() {
      this.$loader.start('saveConversionSettings');
      this.fb_conversion_detail.is_editing = this.is_editing;
      this.fb_conversion_detail.id = this.editing_id;

      await axios
        .post(
          `${window.config.apiUrl}/save-conversion-settings`,
          this.fb_conversion_detail
        )
        .then(() => {
          this.getList();
          this.$root.$emit(
            'modal-close',
            'add-facebook-conversion-detail-modal'
          );

          this.$loader.end('saveConversionSettings');
          Notify.success(this.$t('saved_settings'));
        })
        .catch(() => {
          this.$loader.end('saveConversionSettings');
          Notify.fail(this.$t('unable_to_save_settings'));
        });
    },
    openConfirmModal(fbConversionId) {
      this.fbConversionId = fbConversionId;
      this.$root.$emit('modal-open', 'confirm-conversion-delete-modal');
    },
    async deleteDetail() {
      this.$loader.start('deleteDetail');
      axios
        .delete(`/delete-conversion-settings/${this.fbConversionId}`)
        .then(() => {
          this.getList();
          this.$loader.end('deleteDetail');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  validations: {
    fb_conversion_detail: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },

      pixel_id: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },

      access_token: {
        required,
        minLength: minLength(3),
      },
    },
  },
};
</script>
