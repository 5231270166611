<template>
  <div>
    <!-- Vendor Settings Tabs -->
    <div>
      <v-wait for="settings">
        <div class="bg-white rounded mt-3 mb-8">
          <div class="px-4 py-3 border-solid border-grey-200 border-b">
            <h4 class="font-semibold text-grey-700">
              {{ $t('frontend_funnels_vendor_settings') }}
            </h4>
            <div class="text-sm text-grey-700">
              {{$t('frontend_funnels_you_can_configure')}}
            </div>
          </div>

          <div class="flex">
            <vertical-tabs
              :tabs="vendorSettingsTabs"
              :currentTab="vendorSettingsTab"
              :wrapper-class="'pt-5 w-3/12 border-r border-grey-200 pb-10'"
              @onClick="handleVendorSettingsTabChange"
            />
            <div class="py-5 px-6 w-full">
              <payment-gateways v-if="vendorSettingsTab === 'setupGateways'" />
              <manage-domains  v-if="vendorSettingsTab === 'manageDomains'" />
              <facebook-conversion-pixels v-if="vendorSettingsTab === 'facebook_conversion'" />
              <self-billing  v-if="vendorSettingsTab === 'selfbilling'" />
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import VerticalTabs from '@/components/VerticalTabs';
import PaymentGateways from './index-partials/PaymentGateways.vue';
import ManageDomains from './index-partials/ManageDomains.vue';
import SelfBilling from './index-partials/SelfBilling.vue';
import FacebookConversionPixels from './index-partials/FacebookConversionPixels.vue';

export default {
  middleware: 'auth',
  components: {
    VerticalTabs,
    PaymentGateways,
    ManageDomains,
    SelfBilling,
    FacebookConversionPixels,
  },
  data() {
    return {
      vendorSettingsTab: 'setupGateways',
    };
  },
  metaInfo() {
    return { title: this.$t('vendor_settings') };
  },
  computed: {
    vendorSettingsTabs () {
      return [
        {
          title: this.$t('groovesell_vendor_settings_payment_gateways'),
          value: 'setupGateways',
        },
        {
          title: this.$t('groovesell_vendor_settings_manage_domains'),
          value: 'manageDomains',
        },
        {
          title: this.$t('frontend_facebook_conversion_api_detail'),
          value: 'facebook_conversion',
        },
        {
          title: this.$t('frontend_funnels_self_billing'),
          value: 'selfbilling',
        }
      ]
    },
  },
  methods: {
    handleVendorSettingsTabChange(newTab) {
      this.vendorSettingsTab = newTab;
    },
  },
};
</script>

<style lang="scss">
#funnels-list-view,
#products-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
.scroll-modal {
  max-height: calc(100vh - 80px);
}
</style>
