<template>
  <div>
    <div class="w-full">
      <v-wait for="contracts">
        <div class="flex flex-row justify-end">
          <gd-button
            size="auto"
            variant="primary"
            class="mb-4"
            @click="createContract"
          >
            <plus-icon class="w-5 h-5 fill-current" />
            {{ $t('frontend_groovesell_contracts_index_create_contract') }}
          </gd-button>
        </div>
        <div v-if="contracts.length" id="links-list-view" class="mt-8">
          <v-client-table
            ref="contractsTable"
            :data="contracts"
            :columns="contractTableColumns"
            :options="contractTableOptions"
            class="bg-white text-center rounded border border-grey-300"
          >
            <div slot="status" slot-scope="props">
              <gd-toggle
                dark
                :value="props.row.status"
                @change="changeContractStatus(props.row.id)"
              />
            </div>
            <div slot="actions" slot-scope="props">
              <div class="flex flex-row">
                <gd-button
                  variant="primary"
                  size="icon"
                  v-tooltip="$t('frontend_groovesell_edit')"
                  class="mr-2"
                  @click="editContractDetails(props.row.id)"
                >
                  <pencil-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button
                  variant="primary"
                  size="icon"
                  v-tooltip="$t('frontend_groovesell_delete')"
                  class="mr-2"
                  @click="openConfirmModal(props.row.id)"
                >
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="mt-8">
          {{ $t('frontend_groovesell_contracts_index_no_contract_added_desc') }}
        </div>
      </v-wait>
    </div>
    <confirm-modal
      name="confirm-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteContractDetails"
    />
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import Contract from '@/models/Contract';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('contracts') };
  },
  components: {
    PlusIcon,
    PencilIcon,
    TrashIcon,
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      contractId: null,
      contracts: [],
      coupon: null,
      contractTableColumns: [
        'name',
        'funnel',
        'product',
        'price_point',
        'no_of_partners',
        'status',
        'actions'
      ],
      contractTableOptions: {
        headings: {
          name: this.$t('frontend_name'),
          funnel: this.$t('frontend_groovesell_funnel'),
          product: this.$t('frontend_groovesell_product'),
          price_point: this.$t('frontend_groovesell_price_point'),
          no_of_partners: this.$t('frontend_groovesell_partners'),
          status: this.$t('frontend_groovesell_status'),
          actions: this.$t('frontend_groovesell_action'),
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: ['name', 'funnel', 'price_point', 'no_of_partners'],
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      }
    };
  },
  async created() {
    this.$wait.start('contracts');
    this.contracts = await Contract.$get();
    this.$wait.end('contracts');
  },
  methods: {
    createContract() {
      this.$router.push({ name: 'create_contract' });
    },
    editContractDetails(contractId) {
      this.$router.push({
        name: 'edit_contract',
        params: { contract_id: contractId }
      });
    },
    async changeContractStatus(contractId) {
      this.$loader.start('changeContractStatus');
      this.contract = await Contract.$find(contractId);

      this.contract.status = this.contract.status == 0 ? 1 : 0;

      let updateStatus = await this.contract.custom('toggleContractStatus').save();

      if (updateStatus.status == 'error') {
        this.$loader.end('changeContractStatus');
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: updateStatus.message,
        };

        return;
      }

      let item = this.contracts.find(post => post.id == contractId);

      if (item) {
        // Toggle button enable/disable
        item.status = this.contract.status;
      }

      this.$loader.end('changeContractStatus');
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.contractId = id;
    },
    async deleteContractDetails() {
        try {
          this.$loader.start('deleteContractDetails');
          await this.deleteContract();
          this.removeDeletedContractFromList();
        } catch (error) {
          this.handleDeletionError(error);
        } finally {
          this.$loader.end('deleteContractDetails');
        }
    },
    async deleteContract() {
      const contract = await Contract.$find(this.contractId);
      await contract.delete();
    },
    removeDeletedContractFromList() {
      const deletedIndex = this.contracts.findIndex(item => item.id == this.contractId);
      if (deletedIndex !== -1) {
        this.contracts.splice(deletedIndex, 1);
      }
    },
    handleDeletionError(error) {
      this.alertProps = {
        show: true,
        variant: 'error',
        title: this.$t('frontend_error'),
        message: error.message,
      };
    },
  }
};
</script>
<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#links-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
