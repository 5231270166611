<template>
  <div class="h-full">

    <div class="flex w-full h-full bg-white rounded border-solid border-t border-grey-200 -mx-4">
      <vertical-tabs :tabs="tabs" :currentTab="currentTab" :wrapper-class="'pt-5 w-1/3 border-r border-grey-200 pb-10'"
        @onClick="handleTabChange" />
      <div class="w-full py-5 px-6">
        <tab-link v-if="currentTab == 'link'" :checkout_link="checkout_link" :onCopy="onCopy" :onError="onError" />
        <tab-embed v-if="currentTab == 'embed'" :checkout_embed="checkout_embed" :onCopy="onCopy" :onError="onError" />
        <tab-popup v-if="currentTab == 'popup'" />
      </div>
    </div>
  </div>
</template>
<script>
import CheckoutLink from '@/models/CheckoutLink';
import Product from '@/models/Product';
import Notify from '@groovepages/gd-ui-kit/notify';
import VerticalTabs from '@/components/VerticalTabs';

import TabLink from './tabs/TabLink';
import TabEmbed from './tabs/TabEmbed';
import TabPopup from './tabs/TabPopup';


export default {
  name: 'LinksStep',

  components: {
    VerticalTabs,
    TabLink,
    TabEmbed,
    TabPopup
  },

  props: {
    model: {
      required: true,
    },
    selectPricePoint: {
      required: true,
    },
    prductDetails: {
      required: true,
    },
    selectCheckoutStepType: {
      required: true,
    },
    checkoutLinks: {
      required: true,
    },
  },

  watch: {
    checkoutLinksLocal() {
      this.$emit('updateCheckoutProp', this.checkoutLinksLocal);
    },
  },

  data() {
    return {
      currentTab: 'link',
      tabs: [
        {
          title: this.$t('frontend_product_hosted_checkout'),
          value: 'link',
        },
        {
          title: this.$t('frontend_product_embedded_into'),
          value: 'embed',
        },
        {
          title: this.$t('frontend_groovesell_link_checkout_popup_modal'),
          value: 'popup',
        },
      ],
      localModel: this.model,
      checkout_link: null,
      checkout_embed: null,
      selectedProduct: null,
      checkoutLinksLocal: [],
    };
  },

  methods: {
    onCopy() {
      Notify.success(this.$t('frontend_copy_link'));
    },

    onError() {
      Notify.fail(this.$t('frontend_copy_link_fail'));
    },

    handleTabChange(newTab) {
      this.currentTab = newTab;
    },
  },

  async created() {
    if (this.model.isEdit) {
      this.$loader.start('editFetch');
      const product = await Product.$find(this.$route.params.product_id);
      const links = await product.checkoutLinks().$find(this.model.id);
      this.checkout_link = links.checkout_link;
      this.checkout_embed = links.checkout_embed;
      this.$loader.end('editFetch');
      return;
    }

    this.$loader.start('createdFetch');
    const enabledPricePoints = this.selectPricePoint.filter((pricePoint) => {
      return pricePoint.enabled;
    });

    const enabledPricingOptionsArray = [];
    if (enabledPricePoints.length > 0) {
      for (let i = 0; i < enabledPricePoints.length; i++) {
        var singleEnabledPricingOption = {};
        singleEnabledPricingOption.id = enabledPricePoints[i].id;
        singleEnabledPricingOption.enabled = true;
        singleEnabledPricingOption.ask_username = this.localModel.skip_field_arr[i].ask_username;
        singleEnabledPricingOption.ask_for_password = this.localModel.skip_field_arr[i].ask_for_password;
        singleEnabledPricingOption.skip_billing_info = this.localModel.skip_field_arr[i].skip_billing_info;
        singleEnabledPricingOption.skip_payment_info = this.localModel.skip_field_arr[i].skip_payment_info;
        singleEnabledPricingOption.skip_terms_or_service = this.localModel.skip_field_arr[i].skip_terms_or_service;
        enabledPricingOptionsArray.push(singleEnabledPricingOption);
      }
    } else {
      for (let i = 0; i < this.selectPricePoint.length; i++) {
        var singleEnabledPricingOption = {};
        singleEnabledPricingOption.id = this.selectPricePoint[i].id;
        singleEnabledPricingOption.enabled = true;
        singleEnabledPricingOption.ask_for_password = false;
        singleEnabledPricingOption.ask_username = false;
        singleEnabledPricingOption.skip_billing_info = false;
        singleEnabledPricingOption.skip_payment_info = false;
        singleEnabledPricingOption.skip_terms_or_service = false;
        enabledPricingOptionsArray.push(singleEnabledPricingOption);
      }
    }

    let checkout_link_page_options = {};
    checkout_link_page_options = {
      look_and_feel: this.prductDetails.checkout_page_options.look_and_feel,
      product_image: this.prductDetails.checkout_page_options.product_image,
      tracking_codes: this.prductDetails.checkout_page_options.tracking_codes,
    };

    const newMultiPricePointLink = new CheckoutLink({
      product_id: this.prductDetails.id,
      price_point_id: 0,
      name: this.localModel.name,
      pricing_options: enabledPricingOptionsArray,
      is_onestep: this.selectCheckoutStepType,
      checkout_link_page_options,
      force_affiliate: this.localModel.force_affiliate,
      force_affiliate_id: this.localModel.force_affiliate_id,
    });

    const savedCheckoutLinkData = await newMultiPricePointLink.save();
    this.multiPricePointLink = await this.prductDetails.checkoutLinks().$find(savedCheckoutLinkData.data.id);
    this.checkout_link = this.multiPricePointLink.checkout_link;
    this.checkout_embed = this.multiPricePointLink.checkout_embed;

    this.selectedProduct = await Product.$find(this.$route.params.product_id);
    this.checkoutLinksLocal = await this.selectedProduct.checkoutLinks().$get();
    this.$loader.end('createdFetch');
  },
};
</script>