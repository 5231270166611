<template>
  <div v-on-clickaway="away" class="relative" @click="toggle">
    <slot name="toggler">
      <button>{{ $t('frontend_gduikit_checkout_toggle') }}</button>
    </slot>
    <slot />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'GdDropdownButton',
  mixins: [clickaway],
  provide() {
    return {
      sharedState: this.sharedState
    };
  },
  data() {
    return {
      sharedState: {
        active: false
      }
    };
  },
  methods: {
    toggle() {
      this.sharedState.active = !this.sharedState.active;
    },
    away() {
      this.sharedState.active = false;
    }
  }
};
</script>
