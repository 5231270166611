import Vue from 'vue';
import VueI18n from 'vue-i18n';

import pEn from '../../../packages-lang/en.json';
import pPt from '../../../packages-lang/pt.json';

import en from '../lang/en.json';
import pt from '../lang/pt.json';

Vue.use(VueI18n);

const messages = {
  en: { ...en, ...pEn },
  pt: { ...pt, ...pPt }
};

const i18n = new VueI18n({
  locale: 'en',
  messages
});

i18n.locale = 'en';

try {
  if ('localStorage' in window && window.localStorage) {
    i18n.locale = localStorage.getItem('lang') || 'en';
  }
} catch (exception) {
  //
}

export default i18n;
