<template>
    <div v-if="leaderBoardData.length > 0" id="leader-board-table-view" class="mt-4">
        <v-client-table ref="leaderBoardTable" :data="leaderBoardData" :columns="leaderBoardTableColumns"
            :options="leaderBoardTableOptions" class="bg-white text-center rounded border border-grey-300">
            <div slot="name" slot-scope="props">
                <span v-if="props.row.cloned">
                </span>
                {{ props.row.name }}
            </div>

            <div slot="start_timestamp" slot-scope="props">
                {{ props.row.start_timestamp }}
            </div>

            <div slot="end_timestamp" slot-scope="props">
                {{ props.row.end_timestamp }}
            </div>

            <div slot="actions" slot-scope="props">
                <gd-dropdown
                    :placeholder="$t('frontend_leaderboards_actions')"
                    :actions="leaderboardActions(props.row.id)"
                />
            </div>
        </v-client-table>
    </div>
    <div v-else id="leader-board-table-view" class="mt-4">
        <p>{{ $t('frontend_leaderboards_you_currently') }}</p>
    </div>
</template>
<script>
import axios from 'axios';
import LeaderboardWidget from '@/models/LeaderboardWidget';

export default {

    props: {
        leaderBoardData: {
            type: Array,
            required: true
        },
        EditLeaderboard: {
            type: Function,
            required: true
        },
        leaderBoardTeams: {
            type: Function,
            required: true
        },
       

    },
    data() {
        return {
            leaderBoardTableColumns: [
                'name',
                'start_timestamp',
                'end_timestamp',
                'actions',
            ],
            leaderBoardTableOptions: {
                sortIcon: {
                    base: 'fa',
                    is: 'fa-sort',
                    up: 'fa-sort-asc',
                    down: 'fa-sort-desc',
                },
                sortable: [
                    'name',
                    'sales',
                    'refunds',
                    'revenue',
                    'commissions',
                    'products',
                    'expand',
                ],
                headings: {
                    expand: '',
                    name: this.$t('frontend_name'),
                    actions: this.$t('frontend_groovesell_page_tracking_table_actions'),
                    start_timestamp: this.$t('frontend_groovesell_leaderboard_table_start_timestamp'),
                    end_timestamp: this.$t('frontend_groovesell_leaderboard_table_end_timestamp')

                },
                filterByColumn: false,
                filterable: false,
                perPage: 10000,
                rowClassCallback() {
                    return 'hover:bg-grey-50 funnelRow';
                },
            },
        }
    },
    methods: {
        leaderboardActions(id) {
            return [
                {
                    text: this.$t('frontend_leaderboards_edit_leader_board'),
                    callback: () => this.EditLeaderboard(id)
                },
                {
                    text: this.$t('frontend_clone'),
                    callback: () => this.CloneLeaderboard(id)
                },
                {
                    text: this.$t('frontend_leaderboards_copy_link'),
                    callback: () => this.copyLink(id)
                },
                {
                    text: this.$t('frontend_leaderboards_teams'),
                    callback: () => this.leaderBoardTeams(id)
                },
                {
                    text: this.$t('frontend_groovesell_disqualify_affiliates'),
                    callback: () => this.blockLleaderBoard(id)
                }
            ];
        },
        async CloneLeaderboard(leader_board_id) {
            try {
                this.$loader.start('CloneLeaderboard');
                await axios.post(`/leaderboard-widgets/${leader_board_id}`, { id: leader_board_id });
                this.$emit('refreshLeaderboardData');
            } catch (error) {
                console.error('Error cloning data:', error);
            }
            this.$loader.end('CloneLeaderboard');
        },
        // copy leader board link function
        async copyLink(id) {
            this.$loader.start('copyLink');
            this.leaderboard = await LeaderboardWidget.$find(id);
            this.$emit('update-attributes', {
                key: 'leaderboard',
                value: this.leaderboard
            });
            this.$emit('update-attributes', {
                key: 'modalLinkKey',
                value: id
            });
            this.$loader.end('copyLink');
            this.$nextTick(() => {
                this.$root.$emit('modal-open', 'copy-link-modal');
            });
        },
        async blockLleaderBoard(leaderBoardId) {
            this.$loader.start('blockLleaderBoard');
            const leaderboard = await LeaderboardWidget.$find(leaderBoardId);
            this.emitData('leaderboard', leaderboard);
            const leaderboardDateRange = {
                startDate: new Date(leaderboard.start_timestamp * 1000).toISOString(),
                endDate: new Date(leaderboard.end_timestamp * 1000).toISOString(),
            };
            this.emitData('leaderboardDateRange', leaderboardDateRange);
            const arr = [];
            leaderboard.blocked_affiliates.map((value) => {
                arr.push(value.id);
            });

            const blockAffFinalArr = leaderboard.blocked_affiliates;
            this.emitData('blockAffFinalArr', blockAffFinalArr);
            this.emitData('suggestionSelectedAffiliates_block_aff', arr);
            this.emitData('selectedAffiliateBlockedIdArr', arr);
            this.$root.$emit('modal-open', 'block-leaderboard-affiliates');
            this.$loader.end('blockLleaderBoard');
        },
        emitData(key, value) {
            this.$emit('update-attributes', {
                key: key,
                value: value
            });
        }

    }
}
</script>