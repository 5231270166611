import MySalesAnalytics from '@/pages/analytics/sales';
import MyAffiliateAnalytics from '@/pages/analytics/affiliates';
import MyTransactions from '@/pages/analytics/transactions';
import VendorReport from '@/pages/analytics/vendor_report';
import AffiliateReport from '@/pages/analytics/affiliate_report';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/sales',
    name: 'sales',
    component: MySalesAnalytics,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_sales')
    }
  },
  {
    path: '/affiliate-analytics',
    name: 'affiliate_analytics',
    component: MyAffiliateAnalytics,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_analytics')
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: MyTransactions,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_transactions')
    }
  },
  {
    path: '/vendor-report',
    name: 'vendor_report',
    component: VendorReport,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_vendor_report')
    }
  },
  {
    path: '/affiliates-dashboard',
    name: 'affiliates_dashboard',
    component: AffiliateReport,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_affiliate_dashboard')
    }
  },
];
