import Model from './Model';

export default class ProofWidget extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'proof-widgets';
  }

  get embed_code() {
    const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
    const groovesellWhiteLabelAppName = window.config.groovesellWhiteLabelAppName;
    return `<div><${groovesellWhiteLabelAppName}-proof-widget permalink="${this.permalink}"></${groovesellWhiteLabelAppName}-proof-widget><script src="https://proof.${siteAppurl}/proofembed/js/app.js?n=1"></script></div>`;
  }
}
