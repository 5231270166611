<template>
  <div>
    <div class="w-full">
      <v-wait for="dashboard">
        <div class="mt-4">
          <div class="mt-2">
            <div class="flex flex-row mt-2 w-full">
              <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col w-4/12 pr-6">
                  <div class="flex w-96">
                    <div class="transactionsFilterContainer">
                      <div class="flex flex-col">
                        <treeselect
                          v-model="filteredFunnels"
                          :multiple="true"
                          :options="funnelsDropdown"
                          :placeholder="$t('frontend_groovesell_funnels_filter')"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex space-x-4 justify-start items-center mt-4">
                    <date-range-picker
                      ref="picker"
                      v-model="transactionsDateRange"
                      :opens="opens"
                      :auto-apply="autoApply"
                      :start-date="customStartDate"
                      :end-date="customEndDate"
                      :ranges="customDateRanges"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | moment('MM/DD/YYYY') }} -
                        {{ picker.endDate | moment('MM/DD/YYYY') }}
                      </div>
                    </date-range-picker>
                    <gd-dropdown
                      v-model="selectedRowSize"
                      size="small"
                      :options="pageSizeOptions"
                    />
                  </div>
                  <div class="flex mt-4 justify-start">
                    <gd-button
                      size="auto"
                      class="mr-4"
                      :class="{'animated bounce': !defaultFilters || applyTriggered}"
                      :variant="defaultFilters && !applyTriggered ? 'base' : 'primary'"
                      :disabled="defaultFilters && !applyTriggered"
                      @click="applyFilters"
                    >
                      <span class="mr-2">
                        <filter-icon class="w-5 h-5 fill-current" />
                      </span>
                      {{ $t('frontend_apply_filter') }}
                    </gd-button>
                    <gd-button
                      v-if="!defaultFilters && applyTriggered"
                      variant="outline"
                      size="auto"
                      class="mr-4"
                      @click="clearFilters"
                    >
                      <span class="mr-2">
                        <filter-icon class="w-5 h-5 fill-current" />
                      </span>
                      {{ $t('frontend_reset') }}
                    </gd-button>
                  </div>
                </div>
                <div class="flex flex-col w-3/12">
                  <div class="flex flex-row">
                    <text-input
                      v-model="searchPhrase"
                      value="searchPhrase"
                      :placeholder="$t('frontend_search')"
                      :debounce-events="['input', 'keydown']"
                    />
                  </div>
                  <div class="flex mt-4 justify-end">
                    <gd-dropdown
                      :placeholder="$t('frontend_export')"
                      :actions="exportActions"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="transactions-list-view" class="mt-4">
              <ag-grid-vue
                class="ag-theme-material"
                style="width: 100%;"
                :dom-layout="domLayout"
                :grid-options="gridOptions"
                :column-defs="leadsTableColumnDefs"
                :pagination="true"
                :row-height="leadsTableRowHeight"
                :row-buffer="rowBuffer"
                :row-model-type="rowModelType"
                :datasource="dataSource"
                :pagination-page-size="paginationPageSize"
                :cache-overflow-size="cacheOverflowSize"
                :infinite-initial-row-count="infiniteInitialRowCount"
                :max-blocks-in-cache="maxBlocksInCache"
                :cache-block-size="cacheBlockSize"
                :suppress-row-click-selection="true"
                :suppress-cell-selection="true"
                :row-class="rowClass"
                :row-class-rules="rowClassRules"
                :context="context"
                :framework-components="frameworkComponents"
                :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
                @grid-ready="onGridReady"
              ></ag-grid-vue>
            </div>
          </div>
        </div>
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import moment from 'moment';
import LeadsReport from '@/models/LeadsReport';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import axios from 'axios';
import Treeselect from '@riophae/vue-treeselect';
import TransactionFullNameRenderer from '@/renderers/transactionFullNameRenderer';
import UnderlyingTransactionRenderer from '@/renderers/underlyingTransactionRenderer';
import FailedTransactionFullNameRenderer from '@/renderers/failedTransactionFullNameRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';

export default {
  middleware: 'auth',
  mixins: [createLinkMixin],
  components: {
    AgGridVue,
    Treeselect,
    ResetIcon,
    FilterIcon,
  },
  data() {
    return {
      applyTriggered: false,
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      transactionsDateRange: {
        startDate: moment(),
        endDate: moment()
      },
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment()
            .subtract(1, 'week')
            .startOf('week'),
          moment()
            .subtract(1, 'week')
            .endOf('week')
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment()
            .subtract(1, 'year')
            .startOf('year'),
          moment()
            .subtract(1, 'year')
            .endOf('year')
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()]
      },
      opens: 'right',
      autoApply: true,
      domLayout: 'autoHeight',
      gridOptions: null,
      leadsTableColumnDefs: null,
      leadsTableRowHeight: 40,
      dataSource: null,
      selectedRowSize: 25,
      rowClass: 'transactions-row',
      rowClassRules: null,
      funnelsDropdown: [],
      filteredFunnels: [],
      searchPhrase: ''
    };
  },
  computed: {
    defaultFilters() {
      return this.filteredFunnels.length === 0 &&
        this.searchPhrase == '' &&
        this.selectedRowSize == 25 &&
        this.isDefaultDateRange(this.transactionsDateRange.startDate) &&
        this.isDefaultDateRange(this.transactionsDateRange.endDate);
    },
    pageSizeOptions() {
      return [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ];
    },
    exportActions() {
      return [
        {
          text: this.$t('frontend_export_all'),
          callback: () => this.exportData()
      },
        {
          text: this.$t('frontend_groovesell_export_this_page'),
          callback: () => this.exportThisPageData()
        }
      ];
    },
  },
  metaInfo() {
    return { title: 'Leads' };
  },
  async beforeMount() {
    this.$loader.start('beforeMount');
    this.gridOptions = {};
    this.failedTransactionsGridOptions = {};

    this.context = { componentParent: this };
    this.failedTransactionsContext = { componentParent: this };

    this.frameworkComponents = {
      transactionFullNameRenderer: TransactionFullNameRenderer,
      underlyingTransactionRenderer: UnderlyingTransactionRenderer
    };
    this.failedTransactionsFrameworkComponents = {
      transactionFullNameRenderer: FailedTransactionFullNameRenderer,
      underlyingTransactionRenderer: UnderlyingTransactionRenderer
    };

    this.leadsTableColumnDefs = [
      {
        headerName: this.$t('frontend_customer_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'rendered_full_name',
            colId: 'fullName',
            width: 200,
            pinned: 'left'
          },
          {
            headerName: 'Email',
            field: 'rendered_email',
            colId: 'Email',
            width: 300,
            pinned: 'left'
          },

          {
            headerName: this.$t('frontend_phone'),
            field: 'buyer_phone',
            colId: 'Phone',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_company'),
            field: 'buyer_company',
            colId: 'Company',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_address'),
            field: 'buyer_address',
            colId: 'Address',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_city'),
            field: 'buyer_city',
            colId: 'City',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_postal_code'),
            field: 'buyer_postal_code',
            colId: 'Postal Code',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_state'),
            field: 'buyer_state',
            colId: 'State',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: this.$t('frontend_country'),
            field: 'buyer_country',
            colId: 'Country',
            width: 300,
            // pinned: 'left'
          },

          {
            headerName: 'IP',
            field: 'buyer_ip_address',
            colId: 'IP',
            width: 300,
            // pinned: 'left'
          },
        ]
      },
      {
        headerName: this.$t('frontend_funnel_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_funnel_name'),
            field: 'funnel_name',
            width: 200,
            resizable: true
          }
        ]
      },
      {
        headerName: this.$t('frontend_product_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_product_name'),
            field: 'product_name',
            width: 200,
            resizable: true
          },
          {
            headerName: this.$t('frontend_price_point'),
            field: 'price_point',
            width: 200,
            resizable: true
          },
          {
            headerName: this.$t('frontend_price'),
            field: 'total_amount',
            width: 200,
            resizable: true
          }
        ]
      },
      {
        headerName: this.$t('frontend_date_and_time'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_date_time'),
            field: 'date_time',
            resizable: true,
            minWidth: 250
          }
        ]
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_username'),
            field: 'affiliate_username',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_name'),
            field: 'affiliate_name',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_email'),
            field: 'affiliate_email',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_username'),
            field: 'second_tier_affiliate_username',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_name'),
            field: 'second_tier_affiliate_name',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_email'),
            field: 'second_tier_affiliate_email',
            resizable: true,
          },
        ],
      },
    ];

    this.rowBuffer = 0;
    this.failedTransactionsRowBuffer = 0;

    this.rowModelType = 'infinite';
    this.failedTransactionsRowModelType = 'infinite';

    this.paginationPageSize = 25;
    this.failedTransactionsPaginationPageSize = 25;

    this.cacheBlockSize = 25;
    this.failedTransactionsCacheBlockSize = 25;

    this.cacheOverflowSize = 2;
    this.failedTransactionsCacheOverflowSize = 2;

    this.maxConcurrentDatasourceRequests = 1;
    this.failedTransactionsMaxConcurrentDatasourceRequests = 1;

    this.infiniteInitialRowCount = 1;
    this.failedTransactionsInfiniteInitialRowCount = 1;

    this.maxBlocksInCache = 2;
    this.failedTransactionsMaxBlocksInCache = 2;

    this.$loader.end('beforeMount');
  },
  methods: {
    isDefaultDateRange(dateString) {
      return moment(dateString).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
    },
    showExportSuccessMessage() {
      this.alertProps = {
        show: true,
        variant: 'success',
        title: this.$t('frontend_groovesell_export_data'),
        message: this.$t('frontend_groovesell_transaction_message'),
      };
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      const { data } = await axios.get('abandoned-cart');

      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    async exportData() {
      const self = this;
      self.$loader.start('exportData');
      const exportLinkData = await axios.post(
        '/generate-export-link-abandoned-cart',
        {
          search: self.searchPhrase,
          funnels: self.filteredFunnels,
          fromDate: moment(self.transactionsDateRange.startDate).format(
            'MM/DD/YYYY'
          ),
          toDate: moment(self.transactionsDateRange.endDate).format(
            'MM/DD/YYYY'
          )
        }
      );

      self.$loader.end('exportData');
      this.showExportSuccessMessage();
      document.location.href = exportLinkData.data.download_link;
    },

    async exportThisPageData() {
      const self = this;
      self.$loader.start('exportThisPageData');
      const exportLinkData = await axios.post(
        '/generate-export-link-abandoned-cart',
        {
          search: self.searchPhrase,
          funnels: self.filteredFunnels,
          fromDate: moment(self.transactionsDateRange.startDate).format(
            'MM/DD/YYYY'
          ),
          toDate: moment(self.transactionsDateRange.endDate).format(
            'MM/DD/YYYY'
          ),
          page: self.gridApi.paginationGetCurrentPage() + 1,
          limit: self.gridApi.paginationGetPageSize()
        }
      );

      self.$loader.end('exportThisPageData');
      this.showExportSuccessMessage();
      document.location.href = exportLinkData.data.download_link;
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function(params) {
        self.$loader.start('getDataSource');

        const leadsData = await LeadsReport.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            search: self.searchPhrase,
            funnels: self.filteredFunnels,
            fromDate: moment(self.transactionsDateRange.startDate).format(
              'MM/DD/YYYY'
            ),
            toDate: moment(self.transactionsDateRange.endDate).format(
              'MM/DD/YYYY'
            )
          })
          .get();

        let rowsThisPage = [];
        rowsThisPage = leadsData.data;
        const totalNumberOfRows = leadsData.meta.total;
        self.funnelsDropdown = leadsData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
    applyFilters(isClearAction = false) {
      if (!isClearAction) {
        this.applyTriggered = true;
      }

      this.gridApi.paginationGoToPage(0);
      this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
    },
    clearFilters() {
      this.applyTriggered = false;
      this.filteredFunnels = [];
      this.searchPhrase = '';
      this.selectedRowSize = 25;
      this.transactionsDateRange = {
        startDate: moment(),
        endDate: moment()
      };

      this.applyFilters(true);
    }
  }
};
</script>
