<template>
  <gd-modal
    name="advanced-filters-modal"
    :title="$t('frontend_groovesell_modal_advanced_filters')"
    height="80%"
    width="80%"
    :no-overflow="true"
    bg-type="regular"
    allowOverflow
  >
    <div class="flex flex-col m-6 mb-4">
      <div class="flex flex-col w-full mr-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_analytics_vendor_funnels') }}
        </label>
        <treeselect
          v-model="localFilteredFunnels"
          :multiple="true"
          :options="funnelsDropdown"
          :placeholder="$t('frontend_groovesell_funnels_filter')"
          @input="updatedFilteredFunnels"
        />
      </div>
      <div v-if="!isAffiliateReportPage" class="flex flex-col w-full mr-2 mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_analytics_vendor_affiliates') }}
        </label>
        <!-- Affiliate Filtering Options -->
        <div class="w-full flex flex-row">
          <!-- Option 1 -->
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 1,
                'border-grey-200': filteredAffiliatesType !== 1,
              }"
              @click="updateFilteredAffiliatesType(1)"
              @keydown.space="updateFilteredAffiliatesType(1)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 1,
                    'border-grey': filteredAffiliatesType !== 1,
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 1,
                      'text-grey-700': filteredAffiliatesType !== 1,
                    }"
                  >
                    {{ $t('frontend_analytics_all') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_analytics_vendor_all_transactions') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <!-- Option 2 -->
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 2,
                'border-grey-200': filteredAffiliatesType !== 2,
              }"
              @click="updateFilteredAffiliatesType(2)"
              @keydown.space="updateFilteredAffiliatesType(2)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 2,
                    'border-grey': filteredAffiliatesType !== 2,
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 2,
                      'text-grey-700': filteredAffiliatesType !== 2,
                    }"
                  >
                    {{ $t('frontend_analytics_vendor_only_affiliates') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_analytics_vendor_only_the_sales') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <!-- Option 3 -->
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 3,
                'border-grey-200': filteredAffiliatesType !== 3,
              }"
              @click="updateFilteredAffiliatesType(3)"
              @keydown.space="updateFilteredAffiliatesType(3)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 3,
                    'border-grey': filteredAffiliatesType !== 3,
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 3,
                      'text-grey-700': filteredAffiliatesType !== 3,
                    }"
                  >
                    {{ $t('frontend_analytics_vendor_only_non_affiliates') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_analytics_vendor_only_the_sales_without') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <!-- Option 4 -->
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 4,
                'border-grey-200': filteredAffiliatesType !== 4,
              }"
              @click="updateFilteredAffiliatesType(4)"
              @keydown.space="updateFilteredAffiliatesType(4)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 4,
                    'border-grey': filteredAffiliatesType !== 4,
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 4,
                      'text-grey-700': filteredAffiliatesType !== 4,
                    }"
                  >
                    {{ $t('frontend_analytics_vendor_only_specific') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_analytics_vendor_only_the_sales_made') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <!-- Show button if specific affiliates option selected -->
        <div v-if="filteredAffiliatesType == 4" class="w-1/2 mb-4">
          <gd-button
            size="auto"
            variant="primary"
            class="mt-3"
            @click="openFilteredAffiliatesModal"
          >
            {{ localFilteredAffiliates.length }}
            {{ $t('frontend_analytics_vendor_affiliates') }}
            {{ $t('frontend_analytics_vendor_selected') }}
          </gd-button>
        </div>
      </div>
      <!-- Note for non-affiliate report page -->
      <div v-if="!isAffiliateReportPage" class="flex flex-col w-full mr-2 mt-2">
        <label class="font-semibold text-sm text-red mb-2">
          {{ $t('frontend_analytics_vendor_note') }}
        </label>
      </div>
      <!-- Apply filters button -->
      <div class="flex flex-row w-full my-6 justify-end">
        <gd-button
          size="md"
          variant="primary"
          @click="applyFilters"
        >
          {{ $t('frontend_analytics_apply_filters') }}
        </gd-button>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Treeselect,
  },
  props: {
    filteredFunnels: {
      type: Array,
      required: true,
    },
    funnelsDropdown: {
      type: Array,
      default: () => [],
    },
    filteredAffiliates: {
      type: Array,
      default: () => [{ id: 0, name: 'All' }],
    },
    isAffiliateReportPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localFilteredFunnels: this.filteredFunnels,
      localFilteredAffiliates: this.filteredAffiliates,
    };
  },
  computed: {
    filteredAffiliatesType() {
      const assignedTo = this.localFilteredAffiliates;

      if (assignedTo.length == 0) {
        return 4;
      }

      const firstAssignedToId = assignedTo[0].id;

      if (firstAssignedToId == 0) {
        return 1;
      } else if (firstAssignedToId == -1) {
        return 3;
      } else if (firstAssignedToId == -2) {
        return 2;
      }

      return 4;
    },
  },
  methods: {
    updatedFilteredFunnels() {
      this.$emit('updatedFilteredFunnels', this.localFilteredFunnels);
    },
    updateFilteredAffiliatesType(type) {
      if (this.filteredAffiliatesType != type) {
        if (type == 1) {
          this.localFilteredAffiliates = [{ id: 0, name: this.$t('frontend_all') }];
        } else if (type == 2) {
          this.localFilteredAffiliates = [{ id: -2, name: this.$t('frontend_analytics_only_affiliates') }];
        } else if (type == 3) {
          this.localFilteredAffiliates = [{ id: -1, name: this.$t('frontend_analytics_only_non_affiliates') }];
        } else {
          this.localFilteredAffiliates = [];
        }
      }

      this.$emit('updatedFilteredAffiliates', this.localFilteredAffiliates);
    },
    openFilteredAffiliatesModal() {
      this.$root.$emit('modal-open', 'filters-affiliates-modal');
    },
    applyFilters() {
      this.$emit('applyFilters');
    },
  }
};
</script>
