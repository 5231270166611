<template>
  <side-bar
    :key="$t('frontend_gduikit_sidebar_affiliate_home')"
    ref="sidebar"
    origin="GrooveAffiliate"
    logo="GrooveAffiliateLogo"
    :sections="sections"
    no-exit-button="true"
    @open="setSideOpen"
    @close="setSideClosed"
  >
    <template v-for="item in menuItems" :slot="item.name">
      <div class="text-sm">
        <ul class="cursor-pointer text-white list-reset p-4 blocks-container">
          <li
            class="mb-2 block-data relative focus:outline-none"
            v-for="(item, key) in item.items"
            :key="key"
            @click="sendTo(key)"
          >
            {{ $t(item) }}
          </li>
        </ul>
      </div>
    </template>
    <template slot="header">
      <div class="flex flex-col">
        <div>{{ whitelabelAppData('sell').title }}</div>
        <div>
          <div class="relative">
            <div
              class="
                inline-flex
                shadow-sm
                rounded-md
                divide-x divide-indigo-600
              "
            >
              <div
                class="
                  relative
                  z-0
                  inline-flex
                  shadow-sm
                  rounded-md
                  divide-x divide-indigo-600
                "
              >
                <div
                  class="
                    relative
                    inline-flex
                    items-center
                    bg-indigo-500
                    border border-transparent
                    rounded-l-md
                    shadow-sm
                    text-primary-invert
                    cursor-pointer
                  "
                  @click="openViewSwitcher = !openViewSwitcher"
                >
                  <p class="text-sm font-medium mr-1">
                    {{ $t('frontend_affiliate') }}
                  </p>
                  <angle-down-icon class="w-3 h-3 text-white fill-current " />
                </div>
                <div
                  v-if="openViewSwitcher"
                  v-on-click-outside="() => (this.openViewSwitcher = false)"
                  class="
                    p-6
                    bg-secondary
                    rounded-lg
                    shadow-md
                    absolute
                    left-0
                    top-full
                    z-30
                    w-80
                    border-solid border border-secondary-light
                    shadow
                    overflow-y-auto
                  "
                >
                  <div class="relative grid gap-2 bg-secondary">
                    <div class="">
                      <div class="text-secondary-lightest mb-2 text-xs">
                        {{ $t('frontend_groovesell_affiliate_menu_switch_to') }}
                      </div>
                      <a
                        @click="sendToVendorDashboard"
                        class="
                          -mx-3
                          my-2
                          p-3
                          flex
                          rounded-lg
                          transition
                          ease-in-out
                          duration-150
                          cursor-pointer
                        "
                      >
                        <div class="flex flex-col">
                          <p class="text-sm font-semibold text-secondary-invert">
                            {{ $t('frontend_vendor') }}
                          </p>
                          <p class="text-xs text-secondary-lightest">
                            {{
                              $t(
                                'frontend_groovesell_affiliate_menu_vendor_desc'
                              )
                            }}
                          </p>
                        </div>
                      </a>
                      <a
                        class="
                          -mx-3
                          my-2
                          p-3
                          flex
                          rounded-lg
                          transition
                          ease-in-out
                          duration-150
                          bg-secondary-dark
                        "
                      >
                        <div class="flex flex-col">
                          <p class="text-sm font-semibold text-secondary-invert">
                            {{ $t('frontend_affiliate') }}
                          </p>
                          <p class="text-xs text-secondary-lightest">
                            {{
                              $t(
                                'frontend_groovesell_affiliate_menu_affiliate_desc'
                              )
                            }}
                          </p>
                        </div>
                        <div class="flex flex-col items-center">
                          <check-icon />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </side-bar>
</template>

<script>
import SideBar from '@groovepages/gd-ui-kit/components/SideBarNew';
import DashboardIcon from '@/assets/images/icons/DashboardIcon.svg';
import ColumnChartIcon from '@/assets/images/icons/ColumnChartIcon.svg';
import MarketPlaceIcon from '@/assets/images/icons/MarketplaceIcon.svg';
import CheckIcon from '@/assets/images/icons/Check.svg';
import AngleDownIcon from '@/assets/images/icons/AngleDownIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import SettingsIcon from '@/assets/images/icons/SettingsIcon.svg';
import ToolsIcon from '@/assets/images/icons/ToolsIcon.svg';

export default {
  name: 'SidebarAffiliateMenu',
  components: {
    SideBar,
    CheckIcon,
    AngleDownIcon
  },
  data() {
    return {
      currentOpenMenuItem: '',
      openViewSwitcher: false
    };
  },
  computed: {
    menuItems() {
      return {
        dashboard: {
          name: this.$t('frontend_vendors_dashboard'),
          items: {
            affiliates_dashboard:
              'frontend_gduikit_sidebar_affiliate_home_afiiliate_dashboard',
            dashboard:
              'frontend_gduikit_sidebar_affiliate_home_vendor_dashboard'
          }
        },
        promos: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_promos'),
          items: {
            promos: 'frontend_gduikit_sidebar_affiliate_promos_links',
            bonuses: 'frontend_gduikit_sidebar_affiliate_bonuses'
          }
        },
        tools: {
          name: this.$t('frontend_gduikit_sidebar_groovesell_tools'),
          items: {
            affiliate_tracking: 'frontend_gduikit_sidebar_product_tracking'
          }
        },
        reporting: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_reporting'),
          items: {
            affiliate_transactions:
              'frontend_gduikit_sidebar_affiliate_transactions',
            sub_affiliates: 'frontend_sub_aff',
            vendors: 'frontend_gduikit_sidebar_affiliate_earning',
            payments: 'frontend_gduikit_sidebar_affiliate_payment'
          }
        }
      };
    },
    sections() {
      return [
        {
          name: this.$t('frontend_vendors_dashboard'),
          icon: DashboardIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_promos'),
          icon: MegaphoneIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_reporting'),
          icon: ColumnChartIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_groovesell_tools'),
          icon: ToolsIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_marketplace'),
          icon: MarketPlaceIcon,
          doNotOpen: 1
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_settings'),
          icon: SettingsIcon,
          doNotOpen: 1
        }
      ];
    },
  },
  methods: {
    sendTo(routeName) {
      this.$router.push({ name: routeName });
    },
    setSideOpen(side) {
      if (side === this.$t('frontend_gduikit_sidebar_affiliate_settings')) {
        this.sendToAffiliateSettings();
        return;
      }
      if (side === this.$t('frontend_gduikit_sidebar_affiliate_marketplace')) {
        this.sendToMarketplace();
        return;
      }
      this.currentOpenMenuItem = side;
    },
    setSideClosed() {
      this.currentOpenMenuItem = '';
    },
    sendToVendorDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
    sendToAffiliateSettings() {
      this.$router.push({ name: 'affiliate_settings' });
    },
    sendToMarketplace() {
      this.$router.push({ name: 'affiliate_marketplace' });
    },
  }
};
</script>
