<template>
  <div class="flex flex-col">
    <!-- Tabs for Signatures -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="signaturesTabs"
        :current-tab="signaturesTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleSignaturesTabChange"
      />
    </div>
    <!-- Displaying content based on selected tab -->
    <div class="mt-2">
      <div v-if="signaturesTab == 'email' || signaturesTab == 'forum'">
        <div v-if="hasSignatures(signaturesTab)">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="getSignaturesTabs(signaturesTab)"
              :current-tab="getCurrentSignaturesTab(signaturesTab)"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleSignaturesTabChangeByType(signaturesTab, $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in getSignaturesByType(signaturesTab)" :key="index">
              <div v-if="isCurrentTab(signaturesTab, post.id)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_affiliate_promo_signature') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.post"
                        class="h-32 w-full"
                        :placeholder="$t('frontend_affiliate_promo_signature')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center w-1/4">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="post.post"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="icon"
                          variant="primary"
                          :title="$t('frontend_affiliate_promo_copy_ad_body')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                    <div class="w-1/4 flex flex-col text-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ getNoSignaturesMessage(signaturesTab) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    emailSignaturesTab: {
      type: String,
      default: '',
    },
    forumSignaturesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      signaturesTabs: [
        { title: 'Email', value: 'email' },
        { title: 'Forum', value: 'forum' },
      ],
      signaturesTab: 'email',
    };
  },
  computed: {
    getNoSignaturesMessage() {
      return (type) => {
        const messages = {
          email: this.$t('frontend_affiliate_promo_no_email_signatures'),
          forum: this.$t('frontend_affiliate_promo_no_forum_signatures'),
        };
        return messages[type];
      };
    },
  },
  methods: {
    handleSignaturesTabChange(newTab) {
      this.signaturesTab = newTab;
    },
    handleSignaturesTabChangeByType(type, newTab) {
        const event = `handle${this.capitalizeFirstLetter(type)}SignaturesTabChange`;
        this.$emit(event, newTab);
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    hasSignatures(type) {
      return (
        this.funnelResource &&
        this.funnelResource.signatures &&
        this.funnelResource.signatures[type] &&
        this.funnelResource.signatures[type].length > 0
      );
    },
    getSignaturesTabs(type) {
      const tabs = [];
      if (this.funnelResource && this.funnelResource.signatures[type]) {
        for (let i = 0; i < this.funnelResource.signatures[type].length; i++) {
          tabs.push({
            title: this.funnelResource.signatures[type][i].name,
            value: `${type}_signature_tab_${this.funnelResource.signatures[type][i].id}`,
          });
        }
      }
      return tabs;
    },
    getCurrentSignaturesTab(type) {
      return type == 'forum' ? this.forumSignaturesTab : this.emailSignaturesTab;
    },
    getSignaturesByType(type) {
      return this.funnelResource.signatures[type] || [];
    },
    isCurrentTab(type, id) {
      return this[`${type}SignaturesTab`] === `${type}_signature_tab_${id}`;
    },
  },
};
</script>
