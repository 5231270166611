<template>
  <div class="flex flex-row w-full p-4">
    <div class="text-grey-600 w-1/2 text-sm flex flex-row items-end pb-1">
      <span v-if="localOption.is_trial">
        <span v-if="localOption.trial_period_type == 1">
          {{
            $t('initial_payment_days', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.localOption.trial_period,
            })
          }}
        </span>
        <span v-else-if="localOption.trial_period_type == 2">
          {{
            $t('initial_payment_weeks', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.localOption.trial_period,
            })
          }}
        </span>
        <span v-else-if="localOption.trial_period_type == 3">
          {{
            $t('initial_payment_months', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.localOption.trial_period,
            })
          }}
        </span>
        <span v-else-if="localOption.trial_period_type == 4">
          {{
            $t('initial_payment_years', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.localOption.trial_period,
            })
          }}
        </span>

        <span v-if="localOption.type == 1"
          >{{
            $t('additional_one_time_payment', {
              currencyCode: this.currencyCode,
              price: parseFloat(this.localOption.price).toFixed(2),
            })
          }}
        </span>
        <span v-else-if="localOption.type == 2">
          <span v-if="localOption.recurring_interval == 1" class="font-semibold">
            {{
              $t('additional_recurring_weekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 2"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 3"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 4"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 5"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 9"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 6"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 7"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="localOption.recurring_interval == 8"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
              })
            }}
          </span>
        </span>
        <span v-if="localOption.type == 3" class="font-semibold">
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t('additional_installments_weekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t('additional_installments_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t('additional_installments_monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t('additional_installments_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 5">
            {{
              $t('additional_installments_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 9">
            {{
              $t('additional_installments_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 6">
            {{
              $t('additional_installments_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 7">
            {{
              $t('additional_installments_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 8">
            {{
              $t('additional_installments_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.localOption.price).toFixed(2),
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
        </span>
        <span
          v-if="localOption.type == 4 && localOption.recurrence_interval == 1"
          class="font-semibold"
        >
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t(
                'additional_recurrent_installments_weekly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t(
                'additional_recurrent_installments_biweekly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t(
                'additional_recurrent_installments_monthly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                  recurrenceInterval: this.localOption.recurrence_interval,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t(
                'additional_recurrent_installments_bimonthly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                  recurrenceInterval: this.localOption.recurrence_interval,
                }
              )
            }}
          </span>
        </span>
        <span
          v-if="localOption.type == 4 && localOption.recurrence_interval == 2"
          class="font-semibold"
        >
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t(
                'additional_recurrent_installments_weekly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t(
                'additional_recurrent_installments_biweekly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t(
                'additional_recurrent_installments_monthly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t(
                'additional_recurrent_installments_bimonthly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.localOption.number_of_installments,
                }
              )
            }}
          </span>
        </span>
      </span>

      <span v-else>
        <span v-if="localOption.type == 1">
          {{
            $t('one_time_payment', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
            })
          }}
        </span>
        <span v-if="localOption.type == 2">
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t('recurring_weekly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t('recurring_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t('recurring_monthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t('recurring_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 5">
            {{
              $t('recurring_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 9">
            {{
              $t('recurring_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 6">
            {{
              $t('recurring_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 7">
            {{
              $t('recurring_yearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 8">
            {{
              $t('recurring_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
        </span>
        <span v-if="localOption.type == 3">
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t('installments_weekly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t('installments_biweekly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t('installments_monthly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t('installments_bimonthly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 5">
            {{
              $t('installments_quarterly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 9">
            {{
              $t('installments_4monthly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 6">
            {{
              $t('installments_half_yearly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 7">
            {{
              $t('installments_yearly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 8">
            {{
              $t('installments_biyearly_payments', {
                numberOfInstallments: this.localOption.number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
        </span>
        <span v-if="localOption.type == 4 && localOption.recurrence_interval == 1">
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t('recurrent_installments_weekly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t('recurrent_installments_biweekly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t('recurrent_installments_monthly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
                recurrenceInterval: this.localOption.recurrence_interval,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t('recurrent_installments_bimonthly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
                recurrenceInterval: this.localOption.recurrence_interval,
              })
            }}
          </span>
        </span>
        <span v-if="localOption.type == 4 && localOption.recurrence_interval == 2">
          <span v-if="localOption.recurring_interval == 1">
            {{
              $t('recurrent_installments_weekly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 2">
            {{
              $t('recurrent_installments_biweekly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 3">
            {{
              $t('recurrent_installments_monthly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
          <span v-else-if="localOption.recurring_interval == 4">
            {{
              $t('recurrent_installments_bimonthly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.localOption.number_of_installments,
              })
            }}
          </span>
        </span>
      </span>
    </div>

    <div class="w-1/2">
      <div v-if="isQuantityEnabled" class="flex flex-row justify-end mb-2">
        <div class="flex items-center mr-2">
          <span class="text-sm text-grey-700">
            {{ $t('frontend_product_step5_quantity') }}
          </span>
        </div>
        <gd-dropdown
          v-model="localOption.selected_quantity"
          size="x-small"
          :options="quantityOptions"
          @change="getValueofQuantity"
        />
      </div>
      <div class="text-3xl font-semibold flex flex-row-reverse text-dark">
        {{ currencyCode }}{{ calculatedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localOption: this.option,
    };
  },
  computed: {
    isQuantityEnabled() {
      return this.localOption.type == 1 && this.localOption.quantity_enable == 1;
    },
    quantityOptions() {
      const options = [];

      for (let i = this.localOption.minimum_quantity; i <= this.localOption.maximum_quantity; i++) {
        options.push(i);
      }

      return options;
    },
    calculatedPrice() {
      if (this.option.is_trial) {
        return this.formatPrice(this.option.trial_price);
      } else if (this.option.quantity_enable === 1) {
        return this.formatPrice(this.option.price * this.option.selected_quantity);
      } else {
        return this.formatPrice(this.option.price);
      }
    },
  },
  methods: {
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    getValueofQuantity() {
      this.$emit('getValueofQuantity', this.localOption.selected_quantity);
    },
  },
};
</script>
