<template>
  <div>
    <div class="w-full">
      <v-wait for="dashboard">
        <div class="mt-4">
          <div class="mt-2">
            <transactions-filter
              :model="model"
              :transaction-filters="transactionFilters"
              :selected-transaction-filters="selectedTransactionFilters"
              :funnels-dropdown="funnelsDropdown"
              :grid-api="gridApi"
              @updateModel="updateModel"
              @applyFilters="applyFilters"
            />

            <div id="transactions-list-view" class="mt-4">
              <ag-grid-vue
                class="ag-theme-material"
                style="width: 100%;"
                :dom-layout="domLayout"
                :grid-options="gridOptions"
                :column-defs="customerTableColumnDefs"
                :pagination="true"
                :row-height="transactionsTableRowHeight"
                :row-buffer="rowBuffer"
                :row-model-type="rowModelType"
                :datasource="dataSource"
                :pagination-page-size="paginationPageSize"
                :cache-overflow-size="cacheOverflowSize"
                :infinite-initial-row-count="infiniteInitialRowCount"
                :max-blocks-in-cache="maxBlocksInCache"
                :cache-block-size="cacheBlockSize"
                :suppress-row-click-selection="true"
                :suppress-cell-selection="true"
                :row-class="rowClass"
                :row-class-rules="rowClassRules"
                :context="context"
                :framework-components="frameworkComponents"
                :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
                @grid-ready="onGridReady"
              ></ag-grid-vue>
            </div>
          </div>
        </div>

        <transaction-detail-modal  ref="transactionDetailModal" />
      </v-wait>
    </div>
  </div>
</template>

<script>
import CustomerReport from '@/models/CustomerReport';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import moment from 'moment';
import createLinkMixin from '@/mixins/createLinkMixin';
import CustomerFullNameRenderer from '@/renderers/customerFullNameRenderer';
import TransactionsFilter from './partials/TransactionsFilter.vue';
import TransactionDetailModal from './partials/TransactionDetailModal.vue';

export default {
  middleware: 'auth',
  components: {
    AgGridVue,
    CustomerFullNameRenderer,
    TransactionsFilter,
    TransactionDetailModal
  },
  mixins: [createLinkMixin],
  data() {
    return {
      model: {
        filteredTransactions: [0],
        transactionsDateRange: {
          startDate: moment(),
          endDate: moment()
        },
        selectedRowSize: 25,
        searchPhrase: '',
        excludeFreeTransactions: false,
        filteredFunnels: [],
        filteredAdvancedTransactions: 0,
        filteredAffiliates: [{ id: 0, name: 'All' }],
      },
      domLayout: 'autoHeight',
      gridOptions: null,
      transactionsTableRowHeight: 40,
      dataSource: null,
      rowClass: 'transactions-row',
      rowClassRules: null,
      funnelsDropdown: [],
      frameworkComponents: null,
      gridApi: null,
    };
  },
  computed: {
    transactionFilters() {
      let transactionFilters = [];
      transactionFilters.push({ id: 0, label: this.$t('frontend_groovesell_customers_all_transactions') });
      transactionFilters.push({ id: 8, label: this.$t('frontend_groovesell_customers_only_the_good') });
      transactionFilters.push({ id: 9, label: this.$t('frontend_groovesell_customers_only_the_bad') });
      transactionFilters.push({ id: 1, label: this.$t('frontend_groovesell_customers_sale') });
      transactionFilters.push({ id: 3, label: this.$t('frontend_groovesell_customers_initial_payment') });
      transactionFilters.push({ id: 2, label: this.$t('frontend_groovesell_customers_initial_rebill') });
      transactionFilters.push({ id: 7, label: this.$t('frontend_groovesell_customers_free_transactions') });
      transactionFilters.push({ id: 4, label: this.$t('frontend_groovesell_customers_cancellations') });
      transactionFilters.push({ id: 5, label: this.$t('frontend_groovesell_customers_refund') });

      return transactionFilters;
    },
    selectedTransactionFilters() {
      return this.model.filteredTransactions;
    },
  },
  metaInfo() {
    return { title: this.$t('frontend_gduikit_sidebar_customers') };
  },
  async beforeMount() {
    this.gridOptions = {};
    this.context = { componentParent: this };
    this.customerTableColumnDefs = [
      {
        headerName: this.$t('frontend_buyer_name'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'buyer_name',
            colId: 'buyerFullName',
            cellRenderer: 'customerFullNameRenderer',
            width: 300,
            pinned: 'left'
          }
        ]
      },
      {
        headerName: this.$t('frontend_buyer_email'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Email',
            field: 'rendered_buyer_email',
            resizable: true
          }
        ]
      },
      {
        headerName: this.$t('frontend_contact_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_company_name'),
            field: 'rendered_buyer_company',
            resizable: true
          },
          {
            headerName: this.$t('frontend_address'),
            field: 'rendered_buyer_address',
            resizable: true
          },
          { 
            headerName: this.$t('frontend_city'), 
            field: 'buyer_city', 
            resizable: true 
          },
          { 
            headerName: this.$t('frontend_postal_code'), 
            field: 'buyer_postal_code', 
            resizable: true 
          },
          { 
            headerName: this.$t('frontend_state'), 
            field: 'buyer_state', 
            resizable: true 
          },
          { 
            headerName: this.$t('frontend_country'), 
            field: 'buyer_country', 
            resizable: true 
          }
        ]
      },
      {
        headerName: this.$t('frontend_total_revenue'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_total_revenue'),
            field: 'total_revenue',
            resizable: true
          },
          {
            headerName: this.$t('frontend_total_refund'),
            field: 'total_refunds',
            resizable: true
          },
          {
            headerName: this.$t('frontend_groovesell_total_revenue_excluding_refund'),
            field: 'total_revenue_excluding_refund',
            resizable: true
          }
        ]
      }
    ];

    this.rowBuffer = 0;
    this.failedTransactionsRowBuffer = 0;

    this.rowModelType = 'infinite';
    this.failedTransactionsRowModelType = 'infinite';
    this.paginationPageSize = 25;

    this.cacheBlockSize = 25;

    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;

    this.frameworkComponents = {
      customerFullNameRenderer: CustomerFullNameRenderer
    };

    this.maxBlocksInCache = 2;
  },
  methods: {
    updateModel(model) {
      this.model = model;
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      this.handleDateRange();
      this.handleTransactionType();

      this.dataSource = await this.getDataSource(Number(this.model.selectedRowSize));
    },
    handleDateRange() {
      if (this.$route.query.date_range === 'all-time') {
        this.model.transactionsDateRange = {
          startDate: moment('20191201', 'YYYYMMDD'),
          endDate: moment()
        };
      }
    },
    handleTransactionType() {
      const transactionType = this.$route.query.transaction_type;

      if (transactionType == 'all') {
        this.model.filteredTransactions = [0];
      }

      if (transactionType == 'refund') {
        this.model.filteredTransactions = [5];
      }
    },
    applyFilters() {
      this.gridApi.paginationGoToPage(0);
      this.gridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.model.selectedRowSize
      );
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.model.selectedRowSize));
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function(params) {
        self.$loader.start('getDataSource');

        let cutomerData = await CustomerReport.page(self.gridApi.paginationGetCurrentPage() + 1)
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            funnels: self.model.filteredFunnels,
            transactions: self.selectedTransactionFilters,
            search: self.model.searchPhrase,
            excludeFreeTransactions: self.model.excludeFreeTransactions,
            advancedTransactions: self.model.filteredAdvancedTransactions,
            affiliates: self.model.filteredAffiliates,
            fromDate: moment(self.model.transactionsDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(self.model.transactionsDateRange.endDate).format('MM/DD/YYYY')
          })
          .get();

        const rowsThisPage = cutomerData.data;
        let totalNumberOfRows = 0;

        if (cutomerData.hasOwnProperty('meta')) {
          totalNumberOfRows = cutomerData.meta.total;
          self.funnelsDropdown = cutomerData.meta.funnels;
        }

        self.affiliatesForFilter = [];
        self.affiliatesForFilter.push({ id: 0, name: 'All' });
        self.affiliatesForFilter.push({ id: -2, name: 'Only Affiliates' });
        self.affiliatesForFilter.push({ id: -1, name: 'Only Non-Affiliates' });

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.model.selectedRowSize));
    },
  }
};
</script>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#transactions-list-view,
#failed-transactions-list-view {
  .fraud-transaction-row {
    background-color: #f5ab35;
    color: #fff;
  }

  .refunded-transaction-row {
    background-color: #fff3f7;
  }

  .initial-payment-transaction-row {
    background-color: #e0e2ff;
  }

  .cancellation-transaction-row {
    background-color: #ffffe5;
  }

  .sale-transaction-row {
    background-color: #fff;
  }

  .rebill-transaction-row {
    background-color: #e4fff5;
  }

  .free-transaction-row {
    background-color: #b5e2ff;
  }

  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .transaction-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .transaction-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .transaction-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.transactionsFilterContainer .multiselect__content-wrapper {
  max-height: 550px !important;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}

a.page-link.active {
  color: var(--brand-primary) !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>

<style lang="scss">
#retries-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }

  .VueTables__search {
    display: none;
  }
}
</style>
