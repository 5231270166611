<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded px-2">
      <div>
        <div class="flex flex-row w-full mt-2">
          <div class="flex flex-row flex-wrap w-11/12 mt-8">
            <div v-for="(formField, index) in localModel.form_fields" :key="index" class="w-1/4 flex flex-col mb-4">
              <div class="w-full flex">
                <div class="w-1/5 flex flex-row">
                  <gd-checkbox :variant="index < 4 ? 'base': 'primary'" v-model="formField.enabled" :disabled="index < 4 ? true : false" />
                </div>
                <div class="w-4/5 flex flex-row items-center mb-2">
                  <span class="text-sm mr-2">
                    {{ formField.label }}
                  </span>
                  <div v-if="formField.type == 'custom'" @click="openConfirmModal(index)" class="text-red cursor-pointer">
                    <trash-icon class="w-4 h-4 fill-current" />
                  </div>
                </div>

              </div>
              <div :class="{
                'invisible': formField.type == 'fname' ||
                  formField.type == 'lname' ||
                  formField.type == 'email' ||
                  formField.type == 'countryAndState' ||
                  formField.type == 'address' ||
                  formField.type == 'cityAndPostalCode' ||
                  formField.enabled == false
              }" class="w-full flex">
                <div class="w-1/5 flex flex-row">
                  <gd-toggle class="" v-model="formField.mandatory" :value="formField.mandatory" :disable="true" />
                </div>
                <div class="w-4/5 flex flex-row">
                  <span class="text-sm mr-2"> Mandatory? </span>
                </div>
              </div>


            </div>
          </div>

          <div class="w-2/12 flex flex-row-reverse">
            <gd-button variant="outline" size="auto" class="ml-4" @click="openCustomFieldModal">+ {{
              $t('frontend_product_step5_add_custom') }}</gd-button>
          </div>
        </div>
      </div>
    </div>

    <gd-modal name="create-custom-field-modal" :title="$t('frontend_groovesell_add_new_custom_field')">
      <div class="flex flex-col m-10">
        <div class="flex flex-row w-full mb-6">
          <label class="w-1/4 text-grey-dark">{{
            $t('frontend_product_step5_field_name')
          }}</label>
          <text-input v-model="customFieldName" class="w-3/4" value="customFieldName"
            :placeholder="$t('frontend_product_step5_field_name')" />
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button size="auto" variant="primary" @click="addCustomField">{{
            $t('frontend_save')
          }}</gd-button>
        </div>
      </div>
    </gd-modal>
    <confirm-modal
      name="confirm-remove-formfield-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="removeFormField"
    />
  </div>
</template>

<script>
import AutoSuggestDropDown from '@/components/AutoSuggestDropDown';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  name: 'Step5',
  components: {
    AutoSuggestDropDown,
    TrashIcon
  },
  props: {
    model: {
      required: true,
    },
    product: {
      required: true,
    },
  },
  data() {
    return {
      customFieldName: '',
      localModel: this.model,
      productData: this.product,
      funnel_id: this.$route.params.id,
    };
  },
  validations: {},
  watch: {
    localModel: {
      deep: true,
      handler() {
        this.$emit('updateModel', this.localModel);
      },
    },
  },
  methods: {
    openCustomFieldModal() {
      this.customFieldName = '';
      this.$root.$emit('modal-open', 'create-custom-field-modal');
    },

    addCustomField() {
      if (!this.customFieldName.length) {
        return false;
      }
      const lastFormFieldOrder = this.localModel.form_fields[this.localModel.form_fields.length - 1].order;
      this.localModel.form_fields.push({
        order: lastFormFieldOrder + 1,
        type: 'custom',
        label: this.customFieldName,
        enabled: 1,
      });

      this.customFieldName = '';
      this.$root.$emit('modal-close', 'create-custom-field-modal');
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-remove-formfield-modal');
      this.formFieldId = id;
    },
    async removeFormField() {
        this.localModel.form_fields.splice(this.formFieldId, 1);
    },
    validate() {
      this.$v.$touch();
      let isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>