<template>
  <div class="flex flex-col content-center justify-center text-center w-full bg-white rounded p-4">
    <div class="flex justify-center text-green-dark mt-4 mb-2">
      <check-circle-icon class="w-20 h-20 fill-current" />
    </div>
    <div class="mb-2">
      <h3 class="text-xl font-bold">{{ $t('frontend_tracking_congratilations') }}</h3>
    </div>
    <div class="mb-8">
      <p class="text-sm font-semibold text-grey-700">{{ $t('frontend_tracking_your_tracking') }}</p>
    </div>
    <div class="flex flex-col content-center justify-center text-center mb-8">
      <div class="w-full px-32">
        <text-input
          v-model="localModel.tracking_link"
          class="w-full mt-2"
          :readonly="true"
          :placeholder="$t('frontend_tracking_tracking_link')"
          :select-all="true"
        />
      </div>
      <div class="flex flex-row justify-center">
        <gd-button
          v-clipboard:copy="localModel.tracking_link"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          variant="primary"
          size="md"
          class="mt-6"
        >{{ $t('frontend_tracking_copy') }}</gd-button>
      </div>
    </div>
  </div>
</template>

<script>
import Notify from '@groovepages/gd-ui-kit/notify';
import CheckCircleIcon from '@/assets/images/icons/CheckCircleIcon.svg';

export default {
  name: 'FinishTab',
  components: {
    CheckCircleIcon
  },
  props: {
    model: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model
    };
  },
  methods: {
    onCopy() {
      Notify.success(this.$t('frontend_aff_link_copied'));
    },
    onError() {
      Notify.fail(this.$t('frontend_aff_link_unable_copy'));
    },
    validate() {
      this.$emit('on-validate', true);
    },
  }
};
</script>
