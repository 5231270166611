<template>
  <div class="flex flex-row w-full p-4">
    <div class="text-grey-600 w-1/2 text-sm flex flex-row items-center pb-1">
      <span v-if="option.is_trial">
        <span v-if="option.trial_period_type == 1">
          {{
            $t('initial_payment_days', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.option.trial_period,
            })
          }}
        </span>
        <span v-else-if="option.trial_period_type == 2">
          {{
            $t('initial_payment_weeks', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.option.trial_period,
            })
          }}
        </span>
        <span v-else-if="option.trial_period_type == 3">
          {{
            $t('initial_payment_months', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.option.trial_period,
            })
          }}
        </span>
        <span v-else-if="option.trial_period_type == 4">
          {{
            $t('initial_payment_years', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
              trialPeriod: this.option.trial_period,
            })
          }}
        </span>

        <span v-if="option.type == 1"
          >{{
            $t('additional_one_time_payment', {
              currencyCode: this.currencyCode,
              price: parseFloat(this.option.price).toFixed(2),
            })
          }}
        </span>
        <span v-else-if="option.type == 2">
          <span
            v-if="option.recurring_interval == 1"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_weekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 2"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 3"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 4"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 5"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 9"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 6"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 7"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
          <span
            v-else-if="option.recurring_interval == 8"
            class="font-semibold"
          >
            {{
              $t('additional_recurring_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
              })
            }}
          </span>
        </span>
        <span v-if="option.type == 3" class="font-semibold">
          <span v-if="option.recurring_interval == 1">
            {{
              $t('additional_installments_weekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t('additional_installments_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t('additional_installments_monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t('additional_installments_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 5">
            {{
              $t('additional_installments_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 9">
            {{
              $t('additional_installments_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 6">
            {{
              $t('additional_installments_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 7">
            {{
              $t('additional_installments_yearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 8">
            {{
              $t('additional_installments_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: parseFloat(this.option.price).toFixed(2),
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
        </span>
        <span
          v-if="option.type == 4 && option.recurrence_interval == 1"
          class="font-semibold"
        >
          <span v-if="option.recurring_interval == 1">
            {{
              $t(
                'additional_recurrent_installments_weekly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t(
                'additional_recurrent_installments_biweekly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t(
                'additional_recurrent_installments_monthly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                  recurrenceInterval: this.option.recurrence_interval,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t(
                'additional_recurrent_installments_bimonthly_payments_yearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                  recurrenceInterval: this.option.recurrence_interval,
                }
              )
            }}
          </span>
        </span>
        <span
          v-if="option.type == 4 && option.recurrence_interval == 2"
          class="font-semibold"
        >
          <span v-if="option.recurring_interval == 1">
            {{
              $t(
                'additional_recurrent_installments_weekly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t(
                'additional_recurrent_installments_biweekly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t(
                'additional_recurrent_installments_monthly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t(
                'additional_recurrent_installments_bimonthly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
        </span>
      </span>

      <span v-else>
        <span v-if="option.type == 1">
          {{
            $t('one_time_payment', {
              currencyCode: this.currencyCode,
              price: calculatedPrice,
            })
          }}
        </span>
        <span v-if="option.type == 2">
          <span v-if="option.recurring_interval == 1">
            {{
              $t('recurring_weekly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t('recurring_biweekly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t('recurring_monthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t('recurring_bimonthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 5">
            {{
              $t('recurring_quarterly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 9">
            {{
              $t('recurring_4monthly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 6">
            {{
              $t('recurring_half_yearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 7">
            {{
              $t('recurring_yearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 8">
            {{
              $t('recurring_biyearly_payments', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
        </span>
        <span v-if="option.type == 3">
          <span v-if="option.recurring_interval == 1">
            {{
              $t('installments_weekly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t('installments_biweekly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t('installments_monthly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t('installments_bimonthly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 5">
            {{
              $t('installments_quarterly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 9">
            {{
              $t('installments_4monthly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 6">
            {{
              $t('installments_half_yearly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 7">
            {{
              $t('installments_yearly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 8">
            {{
              $t('installments_biyearly_payments', {
                numberOfInstallments: this.option
                  .number_of_installments,
                currencyCode: this.currencyCode,
                price: calculatedPrice,
              })
            }}
          </span>
        </span>
        <span
          v-if="option.type == 4 && option.recurrence_interval == 1"
        >
          <span v-if="option.recurring_interval == 1">
            {{
              $t('recurrent_installments_weekly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t('recurrent_installments_biweekly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t('recurrent_installments_monthly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
                recurrenceInterval: this.option.recurrence_interval,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t('recurrent_installments_bimonthly_payments_yearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
                recurrenceInterval: this.option.recurrence_interval,
              })
            }}
          </span>
        </span>
        <span
          v-if="option.type == 4 && option.recurrence_interval == 2"
        >
          <span v-if="option.recurring_interval == 1">
            {{
              $t('recurrent_installments_weekly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 2">
            {{
              $t(
                'recurrent_installments_biweekly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
          <span v-else-if="option.recurring_interval == 3">
            {{
              $t('recurrent_installments_monthly_payments_biyearly', {
                currencyCode: this.currencyCode,
                price: calculatedPrice,
                numberOfInstallments: this.option
                  .number_of_installments,
              })
            }}
          </span>
          <span v-else-if="option.recurring_interval == 4">
            {{
              $t(
                'recurrent_installments_bimonthly_payments_biyearly',
                {
                  currencyCode: this.currencyCode,
                  price: calculatedPrice,
                  numberOfInstallments: this.option
                    .number_of_installments,
                }
              )
            }}
          </span>
        </span>
      </span>
    </div>

    <div class="w-1/2">
      <div
        :class="{
          'text-xl md:text-3xl': option.is_trial,
          'text-3xl': !option.is_trial,
        }"
        class="font-semibold flex flex-row-reverse text-dark"
      >
        {{ currencyCode }}{{ calculatedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    calculatedPrice() {
      if (this.option.is_trial) {
        return this.formatPrice(this.option.trial_price);
      } else if (this.option.quantity_enable === 1) {
        return this.formatPrice(this.option.price * this.option.selected_quantity);
      } else {
        return this.formatPrice(this.option.price);
      }
    },
  },
  methods: {
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
  },
};
</script>
