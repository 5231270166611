<template>
  <div>
    <div class="w-full">
      <v-wait for="checkout_links">
        <div v-if="liveFunnels.length" id="checkout-links-list-view" class="mt-8"></div>
        <div v-else class="mt-8">
          {{ $t('frontend_resources_nofunnels') }}
        </div>
      </v-wait>
    </div>

    <portal to="headerBreadCrumb">
      <div class="mt-1 cursor-pointer" @click="sendToFunnels">
        <i class="fas fa-chevron-left"></i>
      </div>
    </portal>
  </div>
</template>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<script>
import Funnel from '@/models/Funnel';
import router from '@/router';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_groovesell_funnel_promo_tools') };
  },
  components: {
  },
  validations: {},
  data() {
    return {
      liveFunnels: [],
    };
  },
  computed: {},
  async created() {
    this.$wait.start('checkout_links');
    const funnels = await Funnel.$get();
    this.liveFunnels = funnels.filter((funnel) => {
      return (
        funnel.main_product_id > 0 &&
        funnel.is_live &&
        funnel.main_product &&
        funnel.main_product.is_wizard_complete &&
        funnel.main_product.is_complete
      );
    });

    if (this.liveFunnels.length) {
      this.$router.push({
        name: 'productsCheckoutLinks',
        params: {
          id: this.liveFunnels[0].id,
          product_id: this.liveFunnels[0].main_product_id,
        },
      });
    }
    this.$wait.end('checkout_links');
  },
  methods: {
    sendToFunnels() {
      this.$router.push({ name: 'funnels' });
    },
  },
};
</script>