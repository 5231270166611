import Model from './Model';
import PricePoint from './PricePoint';
import CheckoutLink from './CheckoutLink';
import ProofWidget from './ProofWidget';

export default class Product extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'products';
  }

  pricepoints() {
    return this.hasMany(PricePoint);
  }

  checkoutLinks() {
    return this.hasMany(CheckoutLink);
  }

  proof_widgets() {
    return this.hasMany(ProofWidget);
  }
}
