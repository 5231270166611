<template>
  <div class="flex flex-row space-x-5">
    <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
      <dashboard-card>
        <template slot="header">
          {{ $t('frontend_affiliate_promo_impressions') }}
        </template>
        <template slot="content">
          <div class="w-full h-full relative text-4xl font-semibold">
            {{ impressions | numFormat }}
          </div>
        </template>
        <template slot="footer"></template>
      </dashboard-card>
    </div>

    <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
      <dashboard-card>
        <template slot="header">
          {{ $t('frontend_affiliate_promo_uniques') }}
        </template>
        <template slot="content">
          <div class="w-full h-full relative text-4xl font-semibold">
            {{ uniques | numFormat }}
          </div>
        </template>
        <template slot="footer"></template>
      </dashboard-card>
    </div>

    <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
      <dashboard-card>
        <template slot="header">
          {{ $t('frontend_affiliate_promo_signups') }}
        </template>
        <template slot="content">
          <div class="w-full h-full relative text-4xl font-semibold">
            {{ optins | numFormat }}
          </div>
        </template>
        <template slot="footer"></template>
      </dashboard-card>
    </div>

    <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
      <dashboard-card>
        <template slot="header">
          {{ $t('frontend_affiliate_promo_sales') }}
        </template>
        <template slot="content">
          <div class="w-full h-full relative text-4xl font-semibold">
            {{ sales | numFormat }}
          </div>
        </template>
        <template slot="footer"></template>
      </dashboard-card>
    </div>

    <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
      <dashboard-card>
        <template slot="header">
          {{ $t('frontend_affiliate_promo_commisions') }}
        </template>
        <template slot="content">
          <div class="w-full h-full relative text-4xl font-semibold truncate">
            {{ commissions | currency }}
          </div>
        </template>
        <template slot="footer"></template>
      </dashboard-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    impressions: {
      default: 0,
    },
    uniques: {
      default: 0,
    },
    optins: {
      default: 0,
    },
    sales: {
      default: 0,
    },
    commissions: {
      default: '$0',
    },
  },
};
</script>
