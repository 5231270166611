<template>
    <gd-modal name="advanced-filters-modal" :title="$t('frontend_groovesell_modal_advanced_filters')" height="80%"
        width="80%" :no-overflow="true" bg-type="regular" allowOverflow>
        <template slot="actions">
            <div class="justify-end">
                <gd-button
                    size="md"
                    :variant="defaultFilters ? 'base' : 'primary'"
                    :disabled="defaultFilters"
                    @click="apply"
                >
                    {{ $t('frontend_affiliate_transactions_apply_filters') }}
                </gd-button>
            </div>
        </template>
        <div class="flex flex-col m-6 mb-4">
            <div class="flex flex-col w-full mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_affiliate_transactions_vendors')
                }}</label>

                <treeselect v-model="localfilteredFunnels" :multiple="true" :options="vendorsDropdown"
                    :placeholder="$t('frontend_affiliate_transactions_vendors_filter')" @input="filteredFunnelsChanged" />
            </div>
            <div class="flex flex-col w-full mr-2 mt-2 mb-8">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_affiliate_transactions_advance_transaction_filter')
                }}</label>
                <gd-dropdown
                    v-model="localFilteredAdvancedTransactions"
                    size="full"
                    :placeholder="$t('frontend_affiliate_transactions_advance_transaction_filter')"
                    :options="filteredTransactionOptions"
                    @change="filteredAdvancedTransactionsChanged"
                />
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
    components: {
        Treeselect
    },
    props: {
        vendorsDropdown: {
            type: Array,
            default: () => []
        },
        filteredFunnels: {
            required: true,
        },
        filteredAdvancedTransactions: {
            required: true,
        }
    },
    methods: {
        filteredFunnelsChanged() {
            this.$emit('filter-changed', {
                key: 'filteredFunnels',
                value: this.localfilteredFunnels,
            });
        },
        filteredAdvancedTransactionsChanged() {
            this.$emit('filter-changed', {
                key: 'filteredAdvancedTransactions',
                value: this.localFilteredAdvancedTransactions,
            });
        },
        apply() {
            this.$emit('apply-filters');

            this.$root.$emit('modal-close', 'advanced-filters-modal');
        },
    },
    data() {
        return {
            localfilteredFunnels: this.filteredFunnels,
            localFilteredAdvancedTransactions: this.filteredAdvancedTransactions,
        }
    },
    watch: {
        filteredFunnels: {
            handler(val) {
                this.localfilteredFunnels = val;
            },
            deep: true
        },
        filteredAdvancedTransactions: {
            handler(val) {
                this.localFilteredAdvancedTransactions = val;
            },
            deep: true
        }
    },
    computed: {
        defaultFilters() {
            return this.localfilteredFunnels?.length == 0 &&
                this.localFilteredAdvancedTransactions == 0;
        },
        filteredTransactionOptions() {
            return [
                { value: 0, text: this.$t('frontend_affiliate_transactions_advance_transaction_all') },
                { value: 1, text: this.$t('frontend_affiliate_transactions_first_sale') },
                { value: 2, text: this.$t('frontend_affiliate_transactions_first_sale_after') },
                { value: 3, text: this.$t('frontend_affiliate_transactions_rebills_only') }
            ];
        }
    },
}
</script>