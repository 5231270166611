<template>
  <div>
    <v-wait for="funnels">
      <!-- Main content -->
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-2">
          <h4 class="font-semibold text-grey-700">
            {{ $t('frontend_bumps_configure_products') }}
          </h4>
        </div>

        <div class="flex flex-col w-1/2 mb-2">
          <span class="text-sm text-grey-700">
            {{ $t('frontend_bumps_configure_product_settings') }}
          </span>
        </div>

        <div class="w-1/2 border-grey-100 border-t pt-8">
          <div
            v-for="(otherProduct, otherProductIndex) in localModel.funnel_details"
            :key="otherProductIndex"
            class="mb-4 pb-4 border-b border-grey-300 flex flex-row justify-between"
          >
            <div class="flex flex-row items-center">
              <!-- Toggle for enabling/disabling -->
              <gd-toggle
                class="mr-2"
                v-model="otherProduct.enabled"
                :value="otherProduct.enabled"
              >
              </gd-toggle>

              <!-- Product details -->
              <div class="text-sm text-dark">
                <div class="font-semibold">
                  {{ resolveSelectedProductName(otherProduct.id) }}
                </div>

                <!-- Internal name -->
                <div class="w-full flex flex-row">
                  <div class="mr-2">
                    <strong>{{ $t('frontend_internal_name') }} :</strong>
                  </div>
                  <div>
                    {{ resolveSelectedProductInternalName(otherProduct.id) }}
                  </div>
                </div>

                <!-- Description -->
                <div class="w-full flex flex-row">
                  <div class="mr-2">
                    <strong>{{ $t('frontend_desc') }} :</strong>
                  </div>
                  <div>
                    {{ resolveSelectedProductDescription(otherProduct.id) }}
                  </div>
                </div>

                <!-- Price point if enabled -->
                <div class="w-full flex flex-row" v-if="otherProduct.enabled">
                  <div class="mr-2 pt-2">
                    <strong>{{ $t('frontend_price_point') }} :</strong>
                  </div>
                  <div>
                    <select
                      v-model="otherProduct.price_point"
                      class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
                      :value="otherProduct.price_point"
                    >
                      <option
                        v-for="(pricepoint, pricepointindex) in otherProduct.price_points"
                        :key="pricepointindex"
                        :value="pricepoint.id"
                      >
                        {{ pricepoint.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>

export default {
  name: 'ProductsTab',
  components: {
  },
  props: {
    model: {
      required: true,
    },
    products: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      selectedProductId: 0,
      selectedProductPricePoints: [],
      selectedPricePointId: 0,
      selectedPricePointIdArr: [],
    };
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  methods: {
    resolveSelectedProductName(productId) {
      const productObject = this.products.find((o) => o.id == productId);

      if (productObject == undefined) {
        return 'N/A';
      }

      return productObject.name;
    },
    resolveSelectedProductInternalName(productId) {
      const productObject = this.products.find((o) => o.id == productId);

      if (productObject == undefined) {
        return 'N/A';
      }

      return productObject.internal_name;
    },
    resolveSelectedProductDescription(productId) {
      const productObject = this.products.find((o) => o.id == productId);

      if (productObject == undefined) {
        return 'N/A';
      }

      return productObject.description;
    },
    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
