import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=06b40d29&scoped=true"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"
import style0 from "./Loading.vue?vue&type=style&index=0&id=06b40d29&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b40d29",
  null
  
)

export default component.exports