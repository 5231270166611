<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded">
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2">
          <div class="relative w-full flex flex-col justify-start">
            <gd-tabs :tabs="lookAndFeelTabs" :current-tab="currentLookAndFeelTab" :disable-not-completed="true"
              :wrapper-class="'px-0 gs-default-tabs'" :tab-class="mainTabClass"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'" @onClick="handleLookFeelTabChange" />
          </div>

          <tab-template v-if="currentLookAndFeelTab == 'template'" @update-model="localModel = $event"
            :prductDetails="prductDetails" :model="localModel" :colors="colors"
            @attribute-changed="handleAttributeChange" />
          <tab-product-image v-if="currentLookAndFeelTab == 'product_image'" @update-model="localModel = $event"
            @attribute-changed="handleAttributeChange" @disableBrandingBarChange="emitdisableBrandingBarChange"
            :prductDetails="prductDetails" :disableBrandingBar="disableBrandingBar" />
          <tab-customization v-if="currentLookAndFeelTab == 'customization'" :model="localModel"
            @update-model="localModel = $event" :colors="colors" :prductDetails="prductDetails"
            @attribute-changed="handleAttributeChange" />
          <tab-button v-if="currentLookAndFeelTab == 'button_text'" :model="localModel"
            @update-model="localModel = $event" />
        </div>
        <checkout-preview :localModel="localModel" :prductDetails="prductDetails" :customColor="customColor"
          :disableBrandingBar="disableBrandingBar" />
      </div>
    </div>
  </div>
</template>
<script>


/** Tabs */
import TabButton from './tabs/TabButtonText';
import TabCustomization from './tabs/TabCustomization';
import TabProductImage from './tabs/TabProductImage';
import TabTemplate from './tabs/TabTemplate';

import CheckoutPreview from '../partials/CheckoutPreview';

export default {
  name: 'LookFeelStep',

  components: {
    TabButton,
    TabCustomization,
    TabProductImage,
    TabTemplate,
    CheckoutPreview
  },

  props: {
    prductDetails: {
      required: true,
    },
    model: {
      required: true,
    },
    disableBrandingBarProp: {
      required: true,
    },
    pricePoints: {
      required: true,
    },
  },
  data() {
    return {
      currentLookAndFeelTab: 'template',


      lookAndFeelTabs: [
        {
          title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t(
            'frontend_template'
          )}<span>`,
          value: 'template',
        },
        {
          title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t(
            'frontend_product_image'
          )}<span>`,
          value: 'product_image',
        },
        {
          title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t(
            'frontend_customization'
          )}<span>`,
          value: 'customization',
        },
        {
          title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t(
            'frontend_button_text'
          )}<span>`,
          value: 'button_text',
        },
      ],
      colors: {
        'primary-darkest': 'hsla(213, 90%, 10%, 1.00)',
        'primary-darker': 'hsla(213, 95%, 25%, 1.00)',
        'primary-dark': 'hsla(213, 95%, 35%, 1.00)',
        primary: 'hsla(213, 100%, 59%, 1.00)',
        'primary-light': 'hsla(213, 100%, 70%, 1.00)',
        'primary-lighter': 'hsla(213, 100%, 83%, 1.00)',
        'primary-lightest': 'hsla(213, 100%, 93%, 1.00)',

        'secondary-darkest': 'hsla(342, 55%, 10%, 1.00)',
        'secondary-darker': 'hsla(342, 65%, 25%, 1.00)',
        'secondary-dark': 'hsla(342, 65%, 38%, 1.00)',
        secondary: 'hsla(342, 75%, 57%, 1.00)',
        'secondary-light': 'hsla(342, 75%, 65%, 1.00)',
        'secondary-lighter': 'hsla(342, 75%, 80%, 1.00)',
        'secondary-lightest': 'hsla(342, 85%, 93%, 1.00)',

        'grey-darkest': 'hsla(213, 40%, 10%, 1.00)',
        'grey-darker': 'hsla(213, 30%, 18%, 1.00)',
        'grey-dark': 'hsla(202, 20%, 28%, 1)',
        grey: 'hsla(213, 15%, 54%, 1.00)',
        'grey-light': 'hsla(213, 15%, 72%, 1.00)',
        'grey-lighter': 'hsla(213, 15%, 85%, 1.00)',
        'grey-lightest': 'hsla(213, 15%, 95%, 1.00)',

        'red-darkest': 'hsla(0, 90%, 12%, 1.00)',
        'red-darker': 'hsla(0, 80%, 25%, 1.00)',
        'red-dark': 'hsla(0, 80%, 48%, 1.00)',
        red: 'hsla(0, 99%, 64%, 1.00)',
        'red-light': 'hsla(0, 99%, 71%, 1.00)',
        'red-lighter': 'hsla(0, 99%, 83%, 1.00)',
        'red-lightest': 'hsla(0, 99%, 93%, 1.00)',

        'green-darkest': 'hsla(143, 69%, 10%, 1.00)',
        'green-darker': 'hsla(143, 79%, 20%, 1.00)',
        'green-dark': 'hsla(143, 79%, 30%, 1.00)',
        green: 'hsla(143, 79%, 45%, 1.00)',
        'green-light': 'hsla(143, 75%, 55%, 1.00)',
        'green-lighter': 'hsla(143, 75%, 70%, 1.00)',
        'green-lightest': 'hsla(143, 80%, 80%, 1.00)',

        'yellow-darkest': 'hsla(20, 90%, 20%, 1.00)',
        'yellow-darker': 'hsla(30, 100%, 30%, 1.00)',
        'yellow-dark': 'hsla(40, 100%, 40%, 1.00)',
        yellow: 'hsla(46, 100%, 57%, 1.00)',
        'yellow-light': 'hsla(46, 95%, 69%, 1.00)',
        'yellow-lighter': 'hsla(46, 95%, 79%, 1.00)',
        'yellow-lightest': 'hsla(46, 90%, 92%, 1.00)',

        purple: 'hsla(227, 49%, 55%, 1.00)',
        indigo: 'hsla(225, 63%, 49%, 1.00)',
        blue: 'hsla(209, 63%, 43%, 1.00)',
      },
      disableBrandingBar: this.disableBrandingBarProp,
      localModel: this.model,
      designTab: 'lookandfeel',
      designTabs: [
        {
          title: this.$t('frontend_product_step5_look_feel'),
          value: 'lookandfeel',
        },
        {
          title: this.$t('frontend_product_step5_custom_css'),
          value: 'customcss',
        },
      ],

      legalTypes: [
        {
          id: 0,
          title: this.$t('frontend_checkout_links_step4_legal_types0_title'),
          description: this.$t(
            'frontend_checkout_links_step4_legal_types0_desc'
          ),
        },
        {
          id: 1,
          title: this.$t('frontend_checkout_links_step4_legal_types1_title'),
          description: this.$t(
            'frontend_checkout_links_step4_legal_types1_desc'
          ),
        },
      ],
      legalEditorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        },
      },
      prductDetailsProp: this.prductDetails,
      primary_color: 'var(--brand-primary)',
      inputSize: 'regular',
      headingSize: 'regular',
      full_width: true,
      buttonSize: 'regular',
      lable_color: '#545671',
      heading_color: '#280b14',
      secondary_color: '#fff',
      font_family: 'Nunito Sans',
      input_size: '2.5rem',
      button_size: '2rem',
      lable_size: '0.875rem',
      button_text: '14px',
      heading_size: '16px',
    };
  },
  computed: {
    mainTabClass() {
      return 'pb-4 gs-default-tabs-item';
    },
    customColor() {
      return {
        '--primary-color': this.primary_color,
        '--hover-color': this.hover_color,
        '--disable-color': this.disable_color,
        '--secondary-color': this.secondary_color,
        '--font-family':
          this.localModel.customization_details.customization_details
            .font_family,
        '--lable-color': this.lable_color,
        '--heading-color': this.heading_color,
        '--input-size': this.input_size,
        '--heading-size': this.heading_size,
        '--button-size': this.button_size,
        '--button-text': this.button_text,
        '--button-padding-top':
          this.localModel.customization_details.customization_details
            .button_padding_top,
        '--button-padding-bottom':
          this.localModel.customization_details.customization_details
            .button_padding_bottom,
        '--lable-size':
          this.localModel.customization_details.customization_details
            .lable_size,
      };
    },
  },

  watch: {
    'prductDetails.checkout_page_options.look_and_feel.template_type':
      function (newVal) {
        if (newVal === 1) {
          this.primary_color = 'var(--brand-primary)';
          this.localModel.primary_color = 'var(--brand-primary)';
          this.secondary_color = '#fff';
          this.localModel.secondary_color = '#fff';
        } else if (newVal === 2) {
          this.primary_color = '#308be5';
          this.localModel.primary_color = '#308be5';
          this.secondary_color = '#fff';
          this.localModel.secondary_color = '#fff';
        } else if (newVal === 3) {
          this.primary_color = '#8689a4';
          this.localModel.primary_color = '#8689a4';
          this.secondary_color = '#fff';
          this.localModel.secondary_color = '#fff';
        } else if (newVal === 4) {
          if (
            'primary_color' in this.localModel &&
            this.localModel.primary_color
          ) {
            this.primary_color = this.localModel.primary_color;
          } else {
            this.primary_color = '#ffcc24';
          }

          if (
            'secondary_color' in this.localModel &&
            this.localModel.secondary_color
          ) {
            this.secondary_color = this.localModel.secondary_color;
          } else {
            this.secondary_color = '#033a7c';
          }
        }
      },

    prductDetailsProp() {
      this.$emit('updatePrductDetailsProp', this.prductDetails);
    },
  },

  async created() {
    if (
      typeof this.prductDetails.checkout_page_options.look_and_feel ===
      'string' ||
      this.prductDetails.checkout_page_options.look_and_feel == null ||
      this.prductDetails.checkout_page_options.look_and_feel.length !=
      undefined ||
      this.prductDetails.checkout_page_options.look_and_feel.length == 0
    ) {
      this.prductDetails.checkout_page_options.look_and_feel = {
        template_type: 1,
        product_image: '',
        tracking_codes: '',
      };
    }

    if (this.prductDetails.checkout_page_options.look_and_feel.template_type == 1) {
      this.primary_color = 'var(--brand-primary)';
      this.secondary_color = '#fff';
    }
    if (this.prductDetails.checkout_page_options.look_and_feel.template_type == 2) {
      this.primary_color = '#308be5';
      this.secondary_color = '#fff';
    }
    if (this.prductDetails.checkout_page_options.look_and_feel.template_type == 3) {
      this.primary_color = '#8689a4';
      this.secondary_color = '#fff';
    }
    if (this.prductDetails.checkout_page_options.look_and_feel.template_type == 4) {
      this.primary_color = this.localModel.primary_color;
      this.secondary_color = this.localModel.secondary_color;
    }

    this.lable_color = this.localModel.customization_details.customization_details.lable_color;
    this.heading_color = this.localModel.customization_details.customization_details.heading_color;
    this.lable_size = this.localModel.customization_details.customization_details.lable_size;
    this.inputSize = this.localModel.customization_details.customization_details.input_size;
    this.headingSize = this.localModel.customization_details.customization_details.heading_size;
    this.button_padding_bottom = this.localModel.customization_details.customization_details.button_padding_bottom;
    this.button_padding_top = this.localModel.customization_details.customization_details.button_padding_top;
    if (this.inputSize == 'small') {
      this.input_size = '2rem';
    } else if (this.inputSize == 'regular') {
      this.input_size = '2.5rem';
    } else if (this.inputSize == 'large') {
      this.input_size = '3rem';
    } else {
      this.input_size = '2rem';
    }
    if (this.headingSize == 'small') {
      this.heading_size = '14px';
    } else if (this.headingSize == 'regular') {
      this.heading_size = '16px';
    } else if (this.headingSize == 'large') {
      this.heading_size = '20px';
    } else {
      this.heading_size = '16px';
    }
  },

  methods: {

    emitdisableBrandingBarChange(value) {
      this.$emit('disableBrandingBarChange', value);
    },
    handleLookFeelTabChange(newTab) {
      this.currentLookAndFeelTab = newTab;
    },

    handleDesignTabChange(newTab) {
      this.designTab = newTab;
    },

    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    handleAttributeChange(v) {
      const { key, value } = v;
      console.log('handleAttributeChange', key, value)
      if (this[key] !== undefined) {
        this[key] = value;
      }
    },
  },
};
</script>

<style>
.custom-font-family {
  font-family: var(--font-family) !important;
}

.custom-lable-color {
  color: var(--lable-color) !important;
}

.custom-heading-color {
  color: var(--heading-color) !important;
}

.custom-input-height {
  height: var(--input-size) !important;
}

.preview input::-webkit-input-placeholder {
  font-size: var(--lable-size) !important;
}

.custom-label-size {
  font-size: var(--lable-size) !important;
}

.custom-heading-size {
  font-size: var(--heading-size) !important;
}

.custom-button {
  padding-top: var(--button-padding-top) !important;
  padding-bottom: var(--button-padding-bottom) !important;
  height: var(--button-size) !important;
  font-size: var(--button-text) !important;
}

.disabled-button-custom-template-color-primary {
  background-color: var(--disable-color) !important;
}

.custom-template-color-primary {
  background-color: var(--primary-color) !important;
}

.custom-template-color-primary-border {
  color: var(--primary-color) !important;
}

.custom-template-color-primary-text {
  color: var(--primary-color) !important;
}

.custom-template-color-primary:hover {
  background-color: var(--primary-color) !important;
}

.custom-template-color-secondary {
  background-color: var(--secondary-color) !important;
}

.custom-template-color-secondary-text {
  color: var(--secondary-color) !important;
}

.hover-custom-template-color-primary:hover {
  background-color: var(--hover-color) !important;
}

.custom-template-color-secondary {
  color: var(--secondary-color) !important;
}

.custom-border {
  border-color: var(--primary-color) !important;
}
</style>
