<template>
  <gd-modal
    name="advanced-filters-modal"
    :title="$t('frontend_groovesell_modal_advanced_filters')"
    height="80%"
    width="80%"
    :no-overflow="true"
    bg-type="regular"
  >
    <div class="flex flex-col m-6 mb-4">

      <!-- Only Second Tier Commission Due -->
      <div class="flex flex-row w-1/2 mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2 mr-2">{{ $t('second_tier_commission_due') }}</label>
        <gd-toggle v-model="localFilteredModel.dueSecondTierCommissionsType" :value="localFilteredModel.dueSecondTierCommissionsType" />
      </div>

      <!-- Due Second Tier Commissions More Than -->
      <div v-if="localFilteredModel.dueSecondTierCommissionsType" class="flex flex-col w-full mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('due_second_tier_more_than') }}</label>
        <gd-currency-input v-model="localFilteredModel.secondTierDueAmount" currency-code="USD" />
      </div>

      <!-- Earned Second Tier Commissions More Than -->
      <div class="flex flex-col w-full mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('earned_second_tier_more_than') }}</label>
        <gd-currency-input v-model="localFilteredModel.secondTierCommissionsAmount" currency-code="USD" />
      </div>

      <!-- Apply Filters Button -->
      <div class="flex flex-row w-full my-6 justify-end">
        <gd-button size="md" variant="primary" @click="applyFilters">{{ $t('apply_filters') }}</gd-button>
      </div>
    </div>
  </gd-modal>
</template>

<script>
export default {
  name: "AdvancedFiltersModal",
  props: {
    filteredModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFilteredModel: this.filteredModel,
    };
  },
  methods: {
    applyFilters() {
      this.$emit("applyFilters", this.localFilteredModel);
    },
  },
}
</script>
