<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4 pb-32">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_bumps_customize') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">
          {{ $t('frontend_bumps_configure_customize_settings') }}
        </span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs
          :tabs="customizationTabs"
          :current-tab="customizationTab"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleCustomizationTabChange"
        />

        <div class="flex flex-row ml-8">
          <gd-button
            size="auto"
            class="mr-4 mt-3"
            :style="{visibility: customizationTab != 'addToCartButtonTab' ? 'visible' : 'hidden'}"
            @click="moveBackOnWizard"
          >
            {{ $t('frontend_bumps_back') }}
          </gd-button>
          <gd-button
            v-if="customizationTab != 'totals_code'"
            size="auto"
            class="mr-4 mt-3"
            @click="moveNextOnWizard"
          >
            {{ $t('frontend_next') }}
          </gd-button>
        </div>
      </div>
      <div class="mt-2">
        <div v-if="customizationTab === 'addToCartButtonTab'">
          <add-to-cart-button-tab
            :model="localModel"
            :colors="colors"
            :custom-color="customColor"
            :embed-button-size="embedButtonSize"
            :button-size-key="buttonSizeKey"
          />
        </div>

        <div v-else-if="customizationTab === 'removeFromCartButtonTab'">
          <remove-from-cart-button-tab
            :model="localModel"
            :colors="colors"
            :custom-color="customColor"
            :embed-button-size="embedButtonSize"
            :button-size-key="buttonSizeKey"
          />
        </div>

        <div v-else-if="customizationTab === 'link_button'">
          <upsell-button-tab
            :model="localModel"
            :colors="colors"
            :custom-color="customColor"
            :button-size-key="buttonSizeKey"
            @thankyou_button_bg_color="thankyou_button_bg_color"
          />
        </div>

        <div v-else-if="customizationTab === 'totals_code'">
          <totals-code-tab
            :model="localModel"
            :colors="colors"
            :custom-color="customColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddToCartButtonTab from './AddToCartButtonTab.vue';
import RemoveFromCartButtonTab from './RemoveFromCartButtonTab.vue';
import UpsellButtonTab from './UpsellButtonTab.vue';
import TotalsCodeTab from './TotalsCodeTab.vue';

export default {
  name: 'CustomizeTab',
  components: {
    AddToCartButtonTab,
    RemoveFromCartButtonTab,
    UpsellButtonTab,
    TotalsCodeTab,
},
  props: {
    model: {
      required: true,
    },
  },
  watch: {
    embedButtonSize() {
      this.buttonSizeKey = this.buttonSizeKey + 1;
    },
  },
  data() {
    return {
      embedButtonSize: null,
      buttonSizeKey: 1,
      colors: {
        'primary-darkest': 'hsla(213, 90%, 10%, 1.00)',
        'primary-darker': 'hsla(213, 95%, 25%, 1.00)',
        'primary-dark': 'hsla(213, 95%, 35%, 1.00)',
        'primary': 'hsla(213, 100%, 59%, 1.00)',
        'primary-light': 'hsla(213, 100%, 70%, 1.00)',
        'primary-lighter': 'hsla(213, 100%, 83%, 1.00)',
        'primary-lightest': 'hsla(213, 100%, 93%, 1.00)',

        'secondary-darkest': 'hsla(342, 55%, 10%, 1.00)',
        'secondary-darker': 'hsla(342, 65%, 25%, 1.00)',
        'secondary-dark': 'hsla(342, 65%, 38%, 1.00)',
        'secondary': 'hsla(342, 75%, 57%, 1.00)',
        'secondary-light': 'hsla(342, 75%, 65%, 1.00)',
        'secondary-lighter': 'hsla(342, 75%, 80%, 1.00)',
        'secondary-lightest': 'hsla(342, 85%, 93%, 1.00)',

        'grey-darkest': 'hsla(213, 40%, 10%, 1.00)',
        'grey-darker': 'hsla(213, 30%, 18%, 1.00)',
        'grey-dark': 'hsla(202, 20%, 28%, 1)',
        'grey': 'hsla(213, 15%, 54%, 1.00)',
        'grey-light': 'hsla(213, 15%, 72%, 1.00)',
        'grey-lighter': 'hsla(213, 15%, 85%, 1.00)',
        'grey-lightest': 'hsla(213, 15%, 95%, 1.00)',

        'red-darkest': 'hsla(0, 90%, 12%, 1.00)',
        'red-darker': 'hsla(0, 80%, 25%, 1.00)',
        'red-dark': 'hsla(0, 80%, 48%, 1.00)',
        'red': 'hsla(0, 99%, 64%, 1.00)',
        'red-light': 'hsla(0, 99%, 71%, 1.00)',
        'red-lighter': 'hsla(0, 99%, 83%, 1.00)',
        'red-lightest': 'hsla(0, 99%, 93%, 1.00)',

        'green-darkest': 'hsla(143, 69%, 10%, 1.00)',
        'green-darker': 'hsla(143, 79%, 20%, 1.00)',
        'green-dark': 'hsla(143, 79%, 30%, 1.00)',
        'green': 'hsla(143, 79%, 45%, 1.00)',
        'green-light': 'hsla(143, 75%, 55%, 1.00)',
        'green-lighter': 'hsla(143, 75%, 70%, 1.00)',
        'green-lightest': 'hsla(143, 80%, 80%, 1.00)',

        'yellow-darkest': 'hsla(20, 90%, 20%, 1.00)',
        'yellow-darker': 'hsla(30, 100%, 30%, 1.00)',
        'yellow-dark': 'hsla(40, 100%, 40%, 1.00)',
        'yellow': 'hsla(46, 100%, 57%, 1.00)',
        'yellow-light': 'hsla(46, 95%, 69%, 1.00)',
        'yellow-lighter': 'hsla(46, 95%, 79%, 1.00)',
        'yellow-lightest': 'hsla(46, 90%, 92%, 1.00)',

        'purple': 'hsla(227, 49%, 55%, 1.00)',
        'indigo': 'hsla(225, 63%, 49%, 1.00)',
        'blue': 'hsla(209, 63%, 43%, 1.00)',
      },
      localModel: this.model,
      customizationTabs: [
        {
          title: '<span class="text-sm font-semibold">1. Add To Cart Button <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'addToCartButtonTab',
        },
        {
          title: '<span class="text-sm font-semibold">2. Remove From Cart Button <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'removeFromCartButtonTab',
        },
        { 
          title: '<span class="text-sm font-semibold">3. Upsell Button <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'link_button' 
        },
        { 
          title: '<span class="text-sm font-semibold">4. Totals Code <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>',
          value: 'totals_code' 
        },
      ],
      customizationTab: 'addToCartButtonTab',
      hoverColor: "#ffffff",
    };
  },
  computed: {
    customColor() {
      const {
        embed_button,
        thankyou_button,
        totals_code
      } = this.localModel.customization_details;

      return {
        '--embed-button-before-bg-color': embed_button.button_before_bg_color,
        '--embed-button-before-font-color': embed_button.button_before_font_color,
        '--embed-button-before-border-color': embed_button.button_before_border_color,

        '--embed-button-after-bg-color': embed_button.button_after_bg_color,
        '--embed-button-after-font-color': embed_button.button_after_font_color,
        '--embed-button-after-border-color': embed_button.button_after_border_color,

        '--thankyou-button-bg-color': thankyou_button.button_bg_color,
        '--thankyou-button-font-color': thankyou_button.button_font_color,
        '--thankyou-button-border-color': thankyou_button.button_border_color,
        '--thankyou-button-hover-color': this.hoverColor,

        '--second-line-font-color': thankyou_button.button_subtext_font_color,

        '--button-font-color': totals_code.font_color,
        '--button-font-family': totals_code.font_family,
        '--button-font-size': `${totals_code.font_size}px`,
        '--button-font-weight': totals_code.font_weight,
      };
    },
  },
  async created() {
    this.hoverColor = await this.pSBC(-0.4, this.localModel.customization_details.thankyou_button.button_bg_color);
  },
  methods: {
    moveNextOnWizard() {
      const currentIndex = this.getTabIndex(this.customizationTab);
      const nextTabIndex = currentIndex + 1;
      this.customizationTab = this.getTabValue(nextTabIndex);
    },
    moveBackOnWizard() {
      const currentIndex = this.getTabIndex(this.customizationTab);
      const previousTabIndex = currentIndex - 1;
      this.customizationTab = this.getTabValue(previousTabIndex);
    },
    getTabIndex(tabValue) {
      return this.customizationTabs.findIndex((item) => item.value === tabValue);
    },
    getTabValue(index) {
      return this.customizationTabs[index]?.value || this.customizationTab;
    },
    validate() {
      const isValid = true;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    handleCustomizationTabChange(newTab) {
      this.customizationTab = newTab;
      this.buttonSizeKey = this.buttonSizeKey + 1;
    },
    async pSBC(p, c0, c1, l) {
      let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof c1 == "string";

      if (
        typeof p != "number" ||
        p < -1 ||
        p > 1 ||
        typeof c0 != "string" ||
        (c0[0] != "r" && c0[0] != "#") ||
        (c1 && !a)
      ) {
        return null;
      }

      if (!this.pSBCr) {
        this.pSBCr = (d) => {
          let n = d.length, x = {};

          if (n > 9) {
            [r, g, b, a] = d = d.split(",");
            n = d.length;

            if (n < 3 || n > 4) {
              return null;
            }

            x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4));
            x.g = i(g);
            x.b = i(b);
            x.a = a ? parseFloat(a) : -1;
          } else {
            if (n == 8 || n == 6 || n < 4) {
              return null;
            }

            if (n < 6) {
              d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
            }

            d = i(d.slice(1), 16);

            if (n == 9 || n == 5) {
              x.r = (d >> 24) & 255;
              x.g = (d >> 16) & 255;
              x.b = (d >> 8) & 255;
              x.a = m((d & 255) / 0.255) / 1000;
            } else {
              x.r = d >> 16;
              x.g = (d >> 8) & 255;
              x.b = d & 255;
              x.a = -1;
            }
          }
          return x;
        };
      }

      h = c0.length > 9;
      h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h;

      f = this.pSBCr(c0);
      P = p < 0;
      t = c1 && c1 !== "c" ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 };
      p = P ? p * -1 : p;
      P = 1 - p;

      if (!f || !t) {
        return null;
      }

      if (l) {
        r = m(P * f.r + p * t.r);
        g = m(P * f.g + p * t.g);
        b = m(P * f.b + p * t.b);
      } else {
        r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
        g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
        b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
      }

      a = f.a;
      t = t.a;
      f = a >= 0 || t >= 0;
      a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0;

      if (h) {
        return `rgb${f ? "a(" : "("}${r},${g},${b}${f ? "," + m(a * 1000) / 1000 : ""})`;
      } else {
        return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)}`;
      }
    },
    async thankyou_button_bg_color(val){
      this.hoverColor = await this.pSBC(-0.4, this.localModel.customization_details.thankyou_button.button_bg_color);
    }
  },
};
</script>


<style>
.custom-embed-before-button-color {
  background-color: var(--embed-button-before-bg-color) !important;
  color: var(--embed-button-before-font-color) !important;
  border-color: var(--embed-button-before-border-color) !important;
}

.custom-embed-toggle-color span {
  background-color: var(--embed-button-before-bg-color) !important;
}

.custom-embed-after-button-color {
  background-color: var(--embed-button-after-bg-color) !important;
  color: var(--embed-button-after-font-color) !important;
  border-color: var(--embed-button-after-border-color) !important;
}

.custom-thankyou-button-color {
  background-color: var(--thankyou-button-bg-color) !important;
  color: var(--thankyou-button-font-color) !important;
  border-color: var(--thankyou-button-border-color) !important;
}

.custom-thankyou-button-subtext-color {
  /* color: var(--second-line-font-color) !important; */
  color: var(--thankyou-button-font-color) !important;
}

.custom-totals-codes-color {
  color: var(--button-font-color) !important;
  font-family: var(--button-font-family) !important;
  font-weight: var(--button-font-weight) !important;
}

.hover-custom-thankyou-button:hover {
  background-color: var(--thankyou-button-hover-color) !important;
}
</style>