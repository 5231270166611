<template>
  <div class="VuePagination" :class="props.theme.wrapper">
    <nav :class="props.theme.nav">
      <ul
        v-show="props.showPagination"
        :class="props.theme.list"
        class="flex pl-0 list-none rounded my-2"
      >
        <li
          :class="props.theme.prev"
          @click="props.setPrevPage"
          class="text-sm relative block p-2 leading-tight bg-white border border-grey-300 text-primary rounded-sm hover:bg-gray-200 cursor-pointer"
        >
          <a v-bind="{ ...props.aProps, ...props.prevProps }">{{
            props.texts.prevPage
          }}</a>
        </li>

        <li
          v-for="page in props.pages"
          :key="page"
          class="text-sm relative block p-2 leading-tight bg-white border border-grey-300 text-primary rounded-sm hover:bg-gray-200 cursor-pointer"
          :class="props.pageClasses(page)"
          v-on="props.pageEvents(page)"
        >
          <a v-bind="props.aProps" :class="props.theme.link">{{ page }}</a>
        </li>

        <li
          class="text-sm relative block p-2 leading-tight bg-white border border-grey-300 text-primary rounded-sm hover:bg-gray-200 cursor-pointer"
          :class="props.theme.next"
          @click="props.setNextPage"
        >
          <a v-bind="{ ...props.aProps, ...props.nextProps }">{{
            props.texts.nextPage
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'MyPagination',
  props: ['props'],
};
</script>


<style scoped>
.active {
  background-color: var(--brand-primary) !important;
}

.active .page-link {
  color: #fff !important;
}
</style>