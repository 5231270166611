<template>
  <div v-if="hasEnabledThankyouPageAds" class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="thankyouPageAdsTabs"
        :current-tab="thankyouPageAdsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleThankyouPageAdsTabChange', $event)"
      />
    </div>
    <div class="mt-2">
      <div v-for="(ad, index) in funnelResource.thankyou_page_ads" :key="index">
        <div v-if="isThankyouPageAdTabActive(ad)" class="pb-8">
          <div class="flex flex-col justify-center align-center text-center items-center w-full mb-6">
            <div v-html="ad.body"></div>
          </div>

          <div class="w-full flex flex-row justify-center mt-2">
            <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/3 xl:w-1/3"></div>
            <div class="w-4/6 sm:w-4/6 md:w-4/6 lg:w-1/3 xl:w-1/3 flex flex-row">
              <textarea
                v-model="ad.body"
                :placeholder="$t('frontend_affiliate_promo_embed_code')"
                class="h-64 w-full resize-y border rounded border-grey-200 p-2"
                @click="handleTextareaClick"
                readonly="readonly"
              ></textarea>
              <div class="ml-2 flex items-center">
                <gd-button
                  v-clipboard:copy="ad.body"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  size="iconlg"
                  variant="primary"
                  :title="$t('frontend_resource_copy_embed')"
                >
                  <copy-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
            <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/3 xl:w-1/3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>{{ $t('frontend_resource_no_thankyou') }}</div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    thankyouPageAdsTab: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasEnabledThankyouPageAds() {
      return (
        this.funnelResource &&
        this.funnelResource.thankyou_page_ads &&
        this.funnelResource.thankyou_page_ads.filter(el => el.enabled).length
      );
    },
    thankyouPageAdsTabs() {
      return this.funnelResource.thankyou_page_ads.filter(el => el.enabled).map(ad => ({
        title: ad.name,
        value: `thankyou_page_ad_tab_${ad.id}`,
      }));
    },
  },
  methods: {
    isThankyouPageAdTabActive(ad) {
      return this.thankyouPageAdsTab === `thankyou_page_ad_tab_${ad.id}`;
    },
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
