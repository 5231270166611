<template>
  <div>
    <div>
      <v-wait for="settings">
        <top-bar-filter
          :sort-by="sortBy"
          :selected-filter="selectedFilter"
          :query="query"
          :view-type-grid="viewTypeGrid"
          @changedSortBy="changedSortBy"
          @onFilterChanged="onFilterChanged"
          @searchMarketPlaceData="searchMarketPlaceData"
          @updateViewTypeGrid="updateViewTypeGrid"
        />

        <div class="flex flex-row w-full">
          <side-bar-filter
            :categories-filter-list="categoriesFilterList"
            :billing-type="billingType"
            :trial-filter-list="trialFilterList"
            @categoryChange="categoryChange"
            @billingTypeChange="billingTypeChange"
            @trialSwitchValue="trialSwitchValue"
          />

          <div class="flex flex-col w-3/4">
            <div v-if="viewTypeGrid" class="container w-full mx-auto flex flex-row flex-wrap mt-2">
              <div v-for="(funnel, index) in marketPlaceData" :key="index" class="w-1/3 flex flex-col pl-4">
                <product-card-grid
                  :funnel="funnel"
                  @signUpForAffiliate="openConfirmModal"
                  @openUrlInNewTab="openUrlInNewTab"
                  @fetchAffiliateLinks="fetchAffiliateLinks"
                  @openFunnelResource="openFunnelResource"
                />
              </div>
            </div>
            <div v-else class="container w-full mx-auto flex flex-col mt-6 pl-4">
              <div v-for="(funnel, index) in marketPlaceData" :key="index">
                <product-card-list
                  :funnel="funnel"
                  @signUpForAffiliate="openConfirmModal"
                  @openUrlInNewTab="openUrlInNewTab"
                  @fetchAffiliateLinks="fetchAffiliateLinks"
                  @openFunnelResource="openFunnelResource"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-end">
          <pagination
            v-if="recordCount > perPage"
            v-model="page"
            :records="recordCount"
            :per-page="perPage"
            :options="paginationOptions"
            @paginate="paginationCallback"
          />
        </div>
      </v-wait>
    </div>

    <gd-modal
      name="open-funnel-resource-modal"
      width="80%"
      :title="$t('frontend_groovesell_funnel_resource')"
    >
      <div class="flex flex-col">
        <div class="flex flex-col bg-white rounded">
          <promotools
            :show-elements="false"
            :permalink="permalink"
          />
        </div>
      </div>
    </gd-modal>

    <affiliate-links-modal :affiliate-links="affiliateLinks" />

    <confirm-modal
      name="confirm-modal"
      :message-content="$t('frontend_groovemember_swal_signup_affiliate_program')"
      @confirm="signUpForAffiliate"
    />
  </div>
</template>

<script>
import axios from 'axios';
import FunnelAffiliate from '@/models/FunnelAffiliate';
import AffiliateLink from '@/models/AffiliateLink';
import Pagination from 'vue-pagination-2';
import MyPagination from '@/components/MyPagination';
import ProductCardGrid from './partials/ProductCardGrid.vue';
import ProductCardList from './partials/ProductCardList.vue';
import SideBarFilter from './partials/SideBarFilter.vue';
import TopBarFilter from './partials/TopBarFilter.vue';
import Promotools from '@/components/promotools/Promotools.vue';
import AffiliateLinksModal from './partials/AffiliateLinksModal.vue';

export default {
  name: 'MarketPlace',
  components: {
    Pagination,
    MyPagination,
    ProductCardGrid,
    ProductCardList,
    SideBarFilter,
    TopBarFilter,
    Promotools,
    AffiliateLinksModal
  },
  data() {
    return {
      affiliateId: null,
      viewTypeGrid: true,
      isRegistered: 1,
      sortBy: '1',
      affiliateLinks: [],
      query: null,
      trialFilterList: {
        trial: false,
      },
      categoriesFilterList: [],
      billingType: {
        all: true,
        one_time: false,
        recurring: false,
        installments: false,
        reccuring_installments: false,
      },
      marketPlaceData: [],
      selectedFilter: 'all',
      categoriesList: [],
      page: 1,
      recordCount: 0,
      paginationOptions: {
        template: MyPagination,
        texts: {
          prevPage: 'Prev.',
          nextPage: 'Next',
        },
      },
      perPage: 15,
      permalink: null,
      funnelResource: null,
      assetsTabs: [],
      assetsTab: '',
      isBlogReviewsPageHTMLView: false,
      socialMediaPostsTabs: [
        { title: 'Facebook', value: 'facebook' },
        { title: 'Twitter', value: 'twitter' },
        { title: 'Linkedin', value: 'linkedin' },
      ],
      socialMediaPostsTab: 'facebook',
      allArticlesTabs: [
        { title: 'Articles', value: 'articles' },
        { title: 'Press Releases', value: 'pressReleases' },
      ],
      allArticlesTab: 'articles',
      signaturesTabs: [
        { title: 'Email', value: 'email' },
        { title: 'Forum', value: 'forum' },
      ],
      signaturesTab: 'email',
      swipesTab: '',
      facebookPostsTab: '',
      videoReviewsTab: '',
      twitterTweetsTab: '',
      linkedinPostsTab: '',
      emailSignaturesTab: '',
      forumSignaturesTab: '',
      pressReleasesTab: '',
      articlesTab: '',
      blogReviewsTab: '',
      thankyouPageAdsTab: '',
      logoutPageAdsTab: '',
      facebookAdsTab: '',
      selectedPromoLinkPermalink: '',
      one_time: '',
      recurring: '',
      installments: '',
      reccuring_installments: '',
      all: '',
      reviewsEditorOptions: {
        modules: {
          toolbar: false,
        },
      },
      currentShownSwipesQuillEditorIndex: -1,
      currentShownThankYouQuillEditorIndex: -1,
      currentShownBlogReviewsQuillEditorIndex: -1,
      currentShownLogoutPageAdQuillEditorIndex: -1,
    };
  },
  async created() {
    this.$loader.start('fetchMarketplaceData');

    const marketplace_categories = await axios.get(`marketplace-categories`);
    this.categoriesList = marketplace_categories.data;
    this.categoriesList.splice(0, 0, {
      id: 0,
      name: this.$t('frontend_groovesell_all_categories'),
      enable: true,
    });

    for (let i = 0; i < this.categoriesList.length; i++) {
      let enable = false;
      if (this.categoriesList[i].id == 0) {
        enable = true;
      }
      this.categoriesFilterList.push({
        id: this.categoriesList[i].id,
        name: this.$t(`frontend_groovesell_funnels_category_${this.categoriesList[i].id}`),
        enable,
      });
    }

    this.getMarketplaceData().then(() => {
      this.$loader.end('fetchMarketplaceData');
    });
  },

  methods: {
    updateViewTypeGrid(value) {
      this.viewTypeGrid = value;
    },
    openUrlInNewTab(link) {
      const win = window.open(link, '_blank');
      win.focus();
    },
    async fetchAffiliateLinks(id) {
      this.$loader.start('fetchAffiliateLinks');

      this.affiliateLinks = await AffiliateLink.params({
        funnel_id: id,
      }).$get();

      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },
    changedSortBy(sortBy) {
      this.sortBy = sortBy;
      this.$loader.start('changedSortBy');
      this.getMarketplaceData().then(() => {
        this.$loader.end('changedSortBy');
      });
    },
    paginationCallback(page) {
      this.$loader.start('paginationCallback');
      this.page = page;
      this.getMarketplaceData().then(() => {
        this.$loader.end('paginationCallback');
      });
    },
    searchMarketPlaceData(query) {
      this.query = query;
      this.$loader.start('searchMarketPlaceData');
      this.getMarketplaceData().then(() => {
        this.$loader.end('searchMarketPlaceData');
      });
    },
    categoryChange(value, categoriesFilterList) {
      this.$loader.start('categoryChange');
      this.categoriesFilterList = categoriesFilterList;
      this.page = 1;
      for (let i = 0; i < this.categoriesFilterList.length; i++) {
        if (value == 0) {
          if (this.categoriesFilterList[i].id == 0) {
            continue;
          } else {
            this.categoriesFilterList[i].enable = false;
          }
        } else {
          this.categoriesFilterList[0].enable = false;
        }
      }
      this.getMarketplaceData().then(() => {
        this.$loader.end('categoryChange');
      });
    },
    billingTypeChange(value, billingType) {
      this.$loader.start('billingTypeChange');
      this.billingType = billingType;
      this.page = 1;

      if (value == 0) {
        if (this.billingType.all) {
          this.billingType = {
            all: true,
            one_time: false,
            recurring: false,
            installments: false,
            reccuring_installments: false,
          };
        }
      } else {
        this.billingType.all = false;
      }

      this.getMarketplaceData().then(() => {
        this.$loader.end('billingTypeChange');
      });
    },
    onFilterChanged(selectedFilter) {
      this.selectedFilter = selectedFilter
      this.page = 1;

      if (this.selectedFilter == 'top_10') {
        this.perPage = 10;
      } else {
        this.perPage = this.perPage;
      }

      this.$loader.start('onFilterChanged');
      this.getMarketplaceData().then(() => {
        this.$loader.end('onFilterChanged');
      });
    },
    trialSwitchValue(trialFilterList) {
      this.trialFilterList = trialFilterList;
      this.page = 1;
      this.$loader.start('trialSwitchValue');
      this.getMarketplaceData().then(() => {
        this.$loader.end('trialSwitchValue');
      });
    },
    async getMarketplaceData() {
      const params = {
        category_filter: this.categoriesFilterList,
        query: this.query,
        limit: this.perPage,
        page: this.page,
        orderBy: this.sortBy,
        selectedTab: this.selectedFilter,
        billing_type: this.billingType,
        trial_filter: this.trialFilterList,
        ascending: '',
        byColumn: '',
      };
      const request = await axios.get(`/marketplace-funnels`, { params });

      this.marketPlaceData = request.data.data;
      this.recordCount = request.data.count;
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.affiliateId = id;
    },
    async signUpForAffiliate() {
      this.$loader.start('signUpForAffiliate');

      const funnelAffiliate = new FunnelAffiliate({});
      funnelAffiliate.funnel_id = this.affiliateId;
      const saved = await funnelAffiliate.save();

      if (saved) {
        this.getMarketplaceData().then(() => {
          this.$loader.end('signUpForAffiliate');
        });
      }

      this.$loader.end('signUpForAffiliate');
    },
    async openFunnelResource(funnel_id) {
      this.$loader.start('openFunnelResource');

      axios.get(`affiliate/get-funnel-resource-id/${funnel_id}`)
        .then((response) => {
          this.permalink = response.data.id;
          this.$loader.end('openFunnelResource');
          this.$root.$emit('modal-open', 'open-funnel-resource-modal');
        });
    },
  },
};
</script>
