<template>
    <gd-modal name="add-custom-landing-page-url" :title="$t('frontend_groovesell_add_custom_landing_page_url')">
        <div class="flex flex-col m-10">
            <div class="flex flex-row w-full mb-6">
                <label class="w-1/4 text-grey-dark">{{
                    $t('frontend_bonuses_name')
                }}</label>
                <text-input v-model="localModel.name" class="w-3/4" :validator="validator.model.name"
                    :attribute="$t('frontend_name')" value="model.name" :placeholder="$t('frontend_name')" />
            </div>
            <div class="flex flex-row w-full mb-6">
                <label class="w-1/4 text-grey-dark">{{
                    $t('frontend_bouses_link')
                }}</label>
                <text-input v-model="localModel.link" class="w-3/4" :validator="validator.model.link" attribute="Link"
                    value="model.link" placeholder="Link" />
            </div>
            <div class="flex flex-row w-full mb-6 justify-end">
                <gd-button size="md" variant="primary" @click="addLandingPageUrl">{{
                    $t('frontend_bouses_create') }}</gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        validator: {
            required: true
        },
        model: {
            required: true
        },
        addLandingPageUrl: {
            required: true,
            type: Function
        }
    },
    data() {
        return {
            localModel: this.model
        }
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel)
            },
            deep: true
        }
    },
}
</script>