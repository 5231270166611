<template>
  <div class="flex flex-row">
    <div class="w-1/2">
      <gd-dropdown
        v-if="funnels && funnels && funnels.length"
        v-model="localSelectedFunnelId"
        :options="funnelsOptions"
        :placeholder="$t('frontend_resources_index_funnels')"
        @change="changedPromoResources"
      />
    </div>
    <div class="w-1/2 flex flex-row justify-end">
      <div v-if="funnel" class="flex flex-row justify-end">
        <gd-button
          variant="primary"
          size="auto"
          @click="$emit('openCreateResourceModal')"
        >
          <plus-icon class="w-5 h-5 fill-current" />
          {{ $t('frontend_resources_index_create_new_funnel') }}
        </gd-button>
      </div>
    </div>
  </div>
</template>

<script>
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  components: {
    PlusIcon,
  },
  props: {
    funnels: {
      type: Array,
      default: () => [],
    },
    funnel: {
      type: Object,
      default: () => {},
    },
    selectedFunnelId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localSelectedFunnelId: this.selectedFunnelId,
    };
  },
  computed: {
    funnelsOptions() {
      return this.funnels.map((funnel) => {
        return {
          value: funnel.id,
          text: funnel.internal_name,
        };
      });
    },
  },
  methods: {
    changedPromoResources() {
      this.$emit('updateSelectedFunnelId', this.localSelectedFunnelId);

      this.$router.push({
        name: 'funnel_resources',
        params: {
          id: this.localSelectedFunnelId,
        },
      });
    },
  },
}
</script>
