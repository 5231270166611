import Vue from 'vue';
import GdButton from '@groovepages/gd-ui-kit/components/Button';
import GdCheckbox from '@groovepages/gd-ui-kit/components/Checkbox';
import ConfirmModal from '@groovepages/gd-ui-kit/components/ConfirmModal';
import GdDropdown from '@groovepages/gd-ui-kit/components/Dropdown';
import ExpansionPanel from '@groovepages/gd-ui-kit/components/ExpansionPanel';
import InfoPanel from '@groovepages/gd-ui-kit/components/InfoPanel';
import GdModal from '@groovepages/gd-ui-kit/components/Modal';
import GdSlider from '@groovepages/gd-ui-kit/components/Slider';
import DropMenu from '@groovepages/gd-ui-kit/components/DropMenu';
import GdToggle from '@groovepages/gd-ui-kit/components/Toggle';
import GdCustomRadio from '@groovepages/gd-ui-kit/components/GdCustomRadio';
import TextArea from '@groovepages/gd-ui-kit/components/TextArea';
import TextInput from '@groovepages/gd-ui-kit/components/TextInput';
import MultiSelect from '@groovepages/gd-ui-kit/components/MultiSelect';
import NoHeadTextInput from './NoHeadTextInput';
import FeatherIcon from './FeatherIcon';
import Child from './Child';
import SidebarMenu from './SidebarMenu';
import SidebarAffiliateMenu from './SidebarAffiliateMenu';
import DashboardCard from './DashboardCard';
import CheckoutRadio from './CheckoutRadio';
import ProductRadio from './ProductRadio';
import FontAwesomeRadio from './FontAwesomeRadio';
import FontAwesomeRadioWithCount from './FontAwesomeRadioWithCount';
import GdDropdownButton from './GdDropdownButton';
import HeaderBar from './HeaderBar';
import DropdownButton from './DropdownButton';
import CheckoutTemplateRadio from './CheckoutTemplateRadio';
import GdCurrencyInput from './GdCurrencyInput';
import GdTabs from './GdTabs';
import Style1 from './Style1';
import Style2 from './Style2';
import Style3 from './Style3';
import Style4 from './Style4';
import CheckoutStepType from './CheckoutStepType';
import CheckoutRadioButton from './CheckoutRadioButton'; //
import PopupAlert from '@groovepages/gd-ui-kit/components/PopupAlert';

// Components that are registered globaly.
[
  Child,
  GdButton,
  GdCheckbox,
  GdDropdownButton,
  ConfirmModal,
  FeatherIcon,
  GdDropdown,
  ExpansionPanel,
  InfoPanel,
  GdModal,
  GdSlider,
  HeaderBar,
  TextArea,
  TextInput,
  NoHeadTextInput,
  GdToggle,
  SidebarMenu,
  SidebarAffiliateMenu,
  DashboardCard,
  CheckoutRadio,
  ProductRadio,
  DropMenu,
  DropdownButton,
  GdCustomRadio,
  GdTabs,
  CheckoutTemplateRadio,
  FontAwesomeRadio,
  FontAwesomeRadioWithCount,
  Style1,
  Style2,
  Style3,
  Style4,
  GdCurrencyInput,
  CheckoutStepType,
  CheckoutRadioButton,
  MultiSelect,
  PopupAlert,
].forEach(Component => {
  Vue.component(Component.name, Component);
});
