<template>
  <div class="w-full">
    <info-panel class="my-4 relative">
      <template slot="content">
        <div class="w-full h-full rounded-t-lg relative">
          <stripe-logo-icon v-if="gatewayIntegration.platform == 'stripe'" class="w-full h-52 object-contain" />
          <paypal-logo-icon
            v-else-if="gatewayIntegration.platform == 'paypal' || gatewayIntegration.platform == 'paypal_commerce'"
            class="w-full h-52 object-contain" />
          <div v-else class="bg-cover bg-no-repeat bg-center h-full w-full" :style="{
            backgroundImage:
              'url(' +
              require('@/assets/images/logo/' +
                gatewayIntegration.platform +
                '-integration.png') +
              ')',
          }"></div>
        </div>
      </template>
      <template slot="footer">
        <div class="flex w-full justify-between items-center">
          <p class="text-sm short-text mx-4">{{ gatewayIntegration.name }}</p>
          <drop-menu :menu-items="menuItems" small></drop-menu>
        </div>
      </template>
    </info-panel>
  </div>
</template>
<style>
.short-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
<script>
import StripeLogoIcon from '@/assets/images/logo/StripeLogoIcon.svg';
import PaypalLogoIcon from '@/assets/images/logo/PaypalLogoIcon.svg';

export default {
  name: 'FunnelGridAsset',
  components: {
    StripeLogoIcon,
    PaypalLogoIcon,
  },
  props: {
    gatewayIntegration: {},
  },
  computed: {
    menuItems() {
      const menuItems = [];

      menuItems.push(
        {
          text: this.$t('frontend_groovesell_reporting_overide'),
          callback: () =>
            this.$emit('onProcessingFeeClick', this.gatewayIntegration.id),
        },
        {
          text: this.$t('frontend_groovesell_edit'),
          callback: () =>
            this.$emit('onEditGatewayIntegration', this.gatewayIntegration.id),

        },
        {
          text: this.$t('frontend_groovesell_delete'),
          callback: () =>
            this.$emit('onDeleteGatewayIntegration', this.gatewayIntegration.id),
        }
      );

      return menuItems;
    },
  },
};
</script>