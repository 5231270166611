import VendorDashboard from '@/pages/members/dashboard';
import SalesTax from '@/pages/sales-tax/index';
import SelfBilling from '@/pages/self-billing/index';
import MyVendors from '@/pages/vendors/index';
import MyVendorSettings from '@/pages/settings/index';
import Customers from '@/pages/customers/index';
import Leads from '@/pages/leads/index';
import Billing from '@/pages/billings/billing';
import Test from '@/pages/sales-tax/test';
import EmailBuilderTest from '@/pages/sales-tax/builder-test';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/',
    name: 'welcome',
    component: VendorDashboard,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_vendor_dashboard')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: VendorDashboard,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_vendor_dashboard')
    }
  },
  {
    path: '/test-playground',
    name: 'playground',
    component: Test,
    meta: {
      pageTitle: 'Templates'
    }
  },
  {
    path: '/email-builder-test-playground/:id',
    name: 'builder_playground',
    component: EmailBuilderTest,
    meta: {
      pageTitle: 'Email Builder'
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_customers')
    }
  },
  {
    path: '/leads',
    name: 'leads',
    component: Leads,
    meta: {
      pageTitle: 'Leads'
    }
  },
  {
    path: '/sales-tax',
    name: 'salesTax',
    component: SalesTax,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_sales_tax')
    }
  },
  {
    path: '/self-billing',
    name: 'selfBilling',
    component: SelfBilling,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_self_billing')
    }
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: MyVendors,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_vendors')
    }
  },
  {
    path: '/vendor-settings',
    name: 'vendor_settings',
    component: MyVendorSettings,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_my_vendor_settings')
    }
  },
  {
    path: '/billing',
    name: 'billing',
    component: Billing,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_billing')
    }
  }
];
