<template>
  <div>
    <div class="w-full">
      <v-wait for="coupons">
        <div class="flex flex-row justify-end">
          <gd-button size="auto" variant="primary" class="mb-4" @click="createCoupon">
            <plus-icon class="w-5 h-5 fill-current" />
            {{ $t('frontend_groovesell_coupons_index_create_coupon') }}
          </gd-button>
        </div>
        <div v-if="coupons.length" id="links-list-view" class="mt-8">
          <v-client-table
            ref="couponsTable"
            :data="coupons"
            :columns="couponsTableColumns"
            :options="couponsTableOptions"
            class="bg-white text-center rounded border border-grey-300"
          >
            <div slot="discount_type" slot-scope="props">
              <div v-if="props.row.discount_type == 1" class="flex flex-row">{{ $t('frontend_groovesell_coupons_index_amount') }}</div>
              <div v-else-if="props.row.discount_type == 2" class="flex flex-row">{{ $t('frontend_percentage') }}(%)</div>
              <div v-else class="flex flex-row"></div>
            </div>

            <div slot="status" slot-scope="props">
              <gd-toggle dark :value="props.row.status" @change="changeCouponStatus(props.row.id)" />
            </div>
            <div slot="actions" slot-scope="props">
              <div class="flex flex-row">
                <gd-button variant="primary" size="icon" class="mr-2" @click="editCoupon(props.row.id)">
                  <pencil-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button variant="primary" size="icon" class="mr-2" @click="openConfirmModal(props.row.id)">
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="mt-8">{{ $t('frontend_groovesell_coupons_index_no_coupon_added_desc') }}</div>
      </v-wait>
    </div>
    <confirm-modal
      name="confirm-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteCoupon"
    />
  </div>
</template>

<script>
import Coupon from '@/models/Coupon';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Coupons' };
  },
  components: {
    PencilIcon,
    TrashIcon,
    PlusIcon,
  },
  data() {
    return {
      couponId: null,
      coupons: [],
      coupon: null,
      couponsTableColumns: ['name', 'coupon_code', 'discount_type', 'discount', 'status', 'actions'],
      couponsTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: ['name', 'coupon_code', 'discount_type', 'discount'],
        headings: {
          name: this.$t('frontend_name'),
          actions: this.$t('frontend_groovesell_page_tracking_table_actions'),
          coupon_code: this.$t('frontend_groovesell_cupons_table_code'),
          discount_type: this.$t('frontend_groovesell_cupons_table_discount_type'),
          discount: this.$t('frontend_groovesell_cupons_table_discount'),
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      }
    };
  },
  async created() {
    this.$wait.start('coupons');
    this.coupons = await Coupon.$get();
    this.$wait.end('coupons');
  },
  methods: {
    createCoupon() {
      this.$router.push({ name: 'create_coupon' });
    },
    async changeCouponStatus(couponId) {
      try {
        this.$loader.start('changeCouponStatus');
        const coupon = await Coupon.$find(couponId);

        // Toggle the coupon status (0 to 1, 1 to 0)
        coupon.status = coupon.status === 0 ? 1 : 0;

        // Copy properties from discount_details and expiration_details
        const { apply_discount, no_of_payments } = coupon.discount_details;
        const { expiration_rule, expiration } = coupon.expiration_details;
        coupon.apply_discount = apply_discount;
        coupon.no_of_payments = no_of_payments;
        coupon.expiration_rule = expiration_rule;
        coupon.expiration = expiration;

        // Save the updated coupon
        await coupon.save();

        // Update the status in the coupons array
        const itemIndex = this.coupons.findIndex((post) => post.id === couponId);
        if (itemIndex !== -1) {
          this.coupons[itemIndex].status = coupon.status;
        }

        this.$loader.end('changeCouponStatus');
      } catch (error) {

        this.$loader.end('changeCouponStatus');
      }
    },
    editCoupon(couponId) {
      this.$router.push({
        name: 'edit_coupon',
        params: { coupon_id: couponId }
      });
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.couponId = id;
    },
    async deleteCoupon() {
      try {
        this.$loader.start('deleteCoupon');
        const couponToDelete = await Coupon.$find(this.couponId);
        
        // Delete the coupon
        await couponToDelete.delete();
        
        // Remove the deleted coupon from the array
        const deletedCouponIndex = this.coupons.findIndex(item => item.id === this.couponId);
        if (deletedCouponIndex !== -1) {
          this.coupons.splice(deletedCouponIndex, 1);
        }

        this.$loader.end('deleteCoupon');
      } catch (error) {
        this.$loader.end('deleteCoupon');
      }
    },

  }
};
</script>

<style lang="scss">
#links-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
