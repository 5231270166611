// TODO: Translations
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vuelidateErrorExtractor from 'vuelidate-error-extractor';
import config from '../../../load-config';

config.then(configValues => {
  Vue.use(Vuelidate);
  Vue.use(vuelidateErrorExtractor, {
    messages: {
      required: '{attribute} field is required',
      minLength: '{attribute} has to be atleast {min} characters',
      maxLength: '{attribute} cannot be more than {max} characters',
      numeric: '{attribute} needs to be a positive integer',
      decimal: '{attribute} needs to be a valid positive number',
      between: '{attribute} needs to be between {min} and {max}',
      minValue: '{attribute} needs to be more than or equal to {min}',
      maxValue: '{attribute} needs to be less than or equal to {max}',
      url: '{attribute} needs to be a valid URL',
      isValidCommission: 'Not a valid commission rate',
      isValidNumberOfInstallments:
        'No. of installments needs to be atleast more than 1',
      isValidNumberOfRecurringInstallments:
        'No. of installments needs to be atleast more than 1',
      isValidNumberOfRicurringInterval: 'Please select Recurring Interval',
      isValidNumberOfRecurrenceInterval: 'Please select Recurrence Interval',
      alpha: '{attribute} only accepts alphabets',
      email: '{attribute} needs to be a valid email',
      sameAsPassword: '{attribute} needs to be same as password',
      goodPassword: 'Password strength is not enough',
      isUnique: '{attribute} already taken',
      alphaNum: '{attribute} accepts only alphanumeric',
      totalHundredPercent: 'Sum of percentages should add upto 100',
      isValidVideoURL: 'Please enter a valid youtube or vimeo URL',
      isValidPostSaleVideoUrl:
        `Please enter a valid youtube or vimeo or wistia or ${configValues.GROOVEVIDEO_WHITELABEL_APP_NAME} URL`,
      isValidPricePointPrice:
        'No trials, rebills or installments allowed on $0 price',
      // isValidQuantityType: 'test data',
      couponCodeShouldbeUnique: 'Coupon code should be unique',
      domainShouldbeUnique: 'Domain name already taken',
      domainShouldbeValid: 'Please enter a valid domain name without http(s)',
      notDuplicateSlug: 'Subdomain already taken',
      isValidNumberOfPayments: 'Payments needs to be a positive integer',
      isValidDiscount: 'Please enter a discount value between 0 and 100',
      contractShouldbeUnique: 'Contract already exist',
      validSupportInfo: 'Please enter valid support information',
      minimumNoOfFunnelResources: 'Please select atleast one funnel resource to block',
      validVideoLink: 'Please enter a valid video link',
      isValidExpiration: 'Please enter a valid value',
    }
  });
});
