<template>
  <div>
    <div class="w-full">
      <v-wait for="payment-histories">
        <div v-if="paymentHistories.length" id="payment-histories-list-view" class="mt-4">
          <v-client-table ref="paymentHistoriesTable" :data="paymentHistories" :columns="historiesTableColumns"
            :options="historiesTableOptions" class="bg-white text-center rounded border border-grey-300">
            <div slot="actions" slot-scope="props">
              <div class="flex flex-row">
                <gd-button v-if="!props.row.is_processed" variant="outline" v-tooltip="'Process Payment'" size="icon" class="mr-2"
                  @click="openConfirmModal(props.row.id)">
                  <check-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button v-else v-tooltip="'Payment Already Processed'" size="icon"
                  disabled
                  class="mr-2">
                  <check-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button v-tooltip="'Affiliates'" variant="primary" size="icon" class="mr-2"
                  @click="openAffiliatesModal(props.row.id)">
                  <users-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button v-if="props.row.export_id" v-tooltip="'Re-download Export File'" variant="primary" size="icon"
                  class="mr-2" @click="redownloadExport(props.row.id)">
                  <download-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button v-else v-tooltip="'Download Not Available'" disabled size="icon" class="mr-2">
                  <download-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="mt-8">
          <p>{{ $t('frontend_affiliate_you_currently_dont_have') }}</p>
        </div>
        <payment-affiliates-modal :selectedPayment="selectedPayment" />
      </v-wait>
    </div>
    <confirm-modal
      name="confirm-modal"
      :message-content="$t('frontend_groovesell_swal_all_transaction_would_be_marker_as_paid')"
      :button-text="$t('frontend_groovesell_swal_btn_text_mark_as_processed')"
      button-size="auto"
      @confirm="markAffiliateHistoryAsProcessed"
    />
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import AffiliatePaymentHistory from '@/models/AffiliatePaymentHistory';
import axios from 'axios';
import PaymentAffiliatesModal from './PaymentsHistory_partials/modals/PaymentAffiliatesModal';  
import CheckIcon from '@/assets/images/icons/CheckIcon.svg';
import UsersIcon from '@/assets/images/icons/UsersIcon.svg';
import DownloadIcon from '@/assets/images/icons/DownloadIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Affiliate Payment History' };
  },
  components: {
    PaymentAffiliatesModal,
    CheckIcon,
    UsersIcon,
    DownloadIcon,
  },
  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: ''
      },
      historyId: null,
      paymentHistories: [],
      itemsPerPage: 5,
      selectedPayment: null,
      historiesTableColumns: ['name', 'human_readable_amount', 'paid_on', 'processed_at', 'actions'],
      historiesTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [],
        headings: {
          human_readable_amount: 'Amount',
          paid_on: 'Added On',
          processed_at: 'Processed At'
        },
        filterByColumn: false,
        filterable: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      },

    };
  },
  computed: {},
  async created() {
    this.$wait.start('payment-histories');
    this.paymentHistories = await AffiliatePaymentHistory.$get();
    this.$wait.end('payment-histories');
  },
  methods: {
    async redownloadExport(history_id) {
      this.$loader.start('redownloadExport');
      const redownloadExportData = await axios.post('/generate-affiliate-export-redownload-link', {
        id: history_id
      });

      if (redownloadExportData.data.status == 'success') {
        let a = document.createElement('a');
        a.href = redownloadExportData.data.download_link.replace(/^http:\/\//i, 'https://');
        a.click();
        this.$loader.end('redownloadExport');
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_groovesell_swal_unable_generate_download_link')
        };
      }
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.historyId = id;
    },
    async markAffiliateHistoryAsProcessed() {
      this.$loader.start('markAffiliateHistoryAsProcessed');
      const markHistoryAsPaidData = await axios.post('/mark-payment-history-paid', {
        id: this.historyId
      });

      if (markHistoryAsPaidData.data.status == 'success') {
        this.paymentHistories = await AffiliatePaymentHistory.$get();
      }
      this.$loader.end('markAffiliateHistoryAsProcessed');
    },

    async openAffiliatesModal(history_id) {
      this.$loader.start('openAffiliatesModal');
      this.selectedPayment = await AffiliatePaymentHistory.$find(history_id);
      this.$loader.end('openAffiliatesModal');
      this.$root.$emit('modal-open', 'payment-affiliates-modal');
    },
  }
};
</script>

<style lang="scss">
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#payment-affiliates-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
#payment-histories-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
