<template>
  <div class="w-3/4 mb-6">
    <div class="flex mb-4">
      <div v-if="!isAffiliateReportPage" class="w-1/3 flex flex-col justify-center items-center bg-gray-400 mb-6">
        <label class="block text-grey-900 text-sm font-bold mb-1">
          {{ chartData.activeSubscriptionsCount | numFormat }}
        </label>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_active') }}
        </span>
      </div>
      <div v-if="!isAffiliateReportPage" class="w-1/3 flex flex-col justify-center items-center bg-gray-400 mb-6">
        <label class="block text-grey-900 text-sm font-bold mb-1">
          {{ chartData.retentionLength | numFormat }} {{ $t('frontend_analytics_vendor_days') }}
        </label>
        <span class="block text-sm uppercase text-grey-500">
          {{ $t('frontend_analytics_vendor_avg_retention') }}
        </span>
      </div>
      <div :class="isAffiliateReportPage ? 'w-full' : 'w-1/3'" class="flex flex-row justify-end bg-gray-400 mb-6 pt-4">
        <label class="font-semibold text-grey-900 mb-2 mr-2">
          {{ $t('frontend_analytics_vendor_revenue_view') }}
        </label>
        <gd-toggle :value="revenueView" @change="setRevenueView" />
      </div>
    </div>
    <div class="flex mb-4">
      <div class="w-full">
        <vue-apex-charts
          ref="realtimeChart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></vue-apex-charts>
        <div class="radio_btns">
          <label>
            <input v-model="localFilterBtn" class="ml-4" type="radio" value="revenue" @change="updateFilterBtn" />
            {{ $t('frontend_analytics_vendor_revenue') }}
          </label>
          <label>
            <input v-model="localFilterBtn" class="ml-4" type="radio" value="visitors" @change="updateFilterBtn" />
            {{ $t('frontend_analytics_vendor_visitors') }}
          </label>
          <label>
            <input v-model="localFilterBtn" class="ml-4" type="radio" value="uniques" @change="updateFilterBtn" />
            {{ $t('frontend_analytics_vendor_uniques') }}
          </label>
        </div>
      </div>
    </div>
    <div v-if="!isAffiliateReportPage" class="flex mb-4 pt-2">
      <div class="w-1/12">
        <div class="w-full flex flex-col justify-center items-center bg-gray-400 mb-6">
          <calculator-icon class="w-12 h-12 text-grey-500 fill-current"/>
        </div>
      </div>
      <div class="w-11/12 flex flex-row">
        <div class="w-1/3 flex flex-col justify-center items-center bg-gray-400 mb-6">
          <label class="block text-grey-900 text-sm font-bold mb-1">
            {{ chartData.projected | currency }}
          </label>
          <span class="block text-sm uppercase text-grey-500">
            {{ $t('frontend_analytics_vendor_projected') }}
          </span>
        </div>
        <div class="w-1/3 flex flex-col justify-center items-center bg-gray-400 mb-6">
          <label class="block text-grey-900 text-sm font-bold mb-1">
            {{ chartData.projectedAfterAff | currency }}
          </label>
          <div class="block text-sm uppercase text-grey-500">
            {{ $t('frontend_projected') }}
          </div>
          <div class="block text-sm uppercase text-grey-500">
            {{ $t('frontend_analytics_after_paid') }}
          </div>
        </div>
        <div class="w-1/3 flex flex-col justify-center items-center bg-gray-400 mb-6">
          <label class="block text-grey-900 text-sm font-bold mb-1">
            {{ chartData.projectedAfterAffRefunds | currency }}
          </label>
          <div class="block text-sm uppercase text-grey-500">
            {{ $t('frontend_projected') }}
          </div>
          <div class="block text-sm uppercase text-grey-500">
            {{ $t('frontend_analytics_after_aff_com_and_refunds') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import CalculatorIcon from '@/assets/images/icons/CalculatorIcon.svg';

export default {
  components: {
    CalculatorIcon,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    revenueView: {
      required: true,
    },
    series: {
      required: true,
    },
    filterBtn: {
      required: true,
    },
    isAffiliateReportPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localFilterBtn: this.filterBtn,
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: '',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    setRevenueView() {
      this.$emit('setRevenueView')
    },
    updateFilterBtn() {
      this.$emit('updateFilterBtn', this.localFilterBtn)
    },
  },
};
</script>
