<template>
    <div>
        <div class="flex flex-row">
            <div class="flex flex-row w-full">
                <div>
                    <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
                        <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                            :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                            :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                            :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
                    </div>
                    <div class="mt-2 w-full">
                        <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                            <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                                <div class="flex flex-col w-full mb-6 mt-8">
                                    <div v-if="pricePoint.type == 1 &&
                                        pricePoint.is_trial == 0 &&
                                        pricePoint.price > 0
                                        " class="flex flex-col w-2/3">
                                        <div class="flex flex-row">
                                            <div v-for="(quantityType, index) in quantityTypes" :key="index"
                                                class="flex w-1/2 mr-4">
                                                <gd-custom-radio v-model="pricePoint.quantity_enable"
                                                    :option="quantityType"></gd-custom-radio>
                                            </div>
                                        </div>
                                        <div v-if="pricePoint.quantity_enable == 1" class="flex flex-col w-1/2 mb-6">
                                            <div class="flex flex-row">
                                                <div class="flex flex-col w-1/2 mb-6 mr-6">
                                                    <label class=" font-semibold text-sm text-grey-700 mb-2">{{
                                                        $t('frontend_product_step5_min_value') }}</label>
                                                    <gd-dropdown
                                                        v-model="pricePoint.minimum_quantity"
                                                        size="full"
                                                        :options="quantityOptions"
                                                    />
                                                </div>
                                                <div class="flex flex-col w-1/2 mb-6 mr-6">
                                                    <label class=" font-semibold text-sm text-grey-700 mb-2">{{
                                                        $t('frontend_product_step5_max_value') }}</label>
                                                    <gd-dropdown
                                                        v-model="pricePoint.maximum_quantity"
                                                        size="full"
                                                        :options="quantityOptions"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $t('frontend_product_step5_multiple_quantities_not_available') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    props: {
        model: {
            required: true,
        },
        validation: {
            required: true,
        },
    },
    data() {
        return {
            pricePointsTab: '',
            localModel: this.model,
            quantityTypes: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step5_quantity_types0_title'),
                    description: this.$t('frontend_product_step5_quantity_types0_desc'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step5_quantity_types1_title'),
                    description: this.$t('frontend_product_step5_quantity_types1_desc'),
                },
            ],
        }
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },
    mounted() {
        if (this.pricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    computed: {
        quantityOptions() {
            const quantityOptions = [];
            for (let i = 1; i <= 100; i++) {
                quantityOptions.push({
                    value: i,
                    text: i,
                });
            }
            return quantityOptions;
        },
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];
            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error) {
                    pricePointErrorTabs.push(`price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`);
                }
            }

            return pricePointErrorTabs;
        },
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
    },
}
</script>