<template>
  <div>
    <div class="flex flex-row w-full p-4">
      <div v-if="hasThumbnail" class="flex flex-row mr-4">
        <img :class="getThumbnailClasses" :src="thumbnailImg" />
      </div>
      <div class="flex flex-col justify-center">
        <div class="w-full flex flex-row text-dark">
          <span class="font-bold">{{ heading }}</span>
        </div>
        <div class="w-full text-grey-600 mt-1 text-sm">
          {{ subheading }}
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full mt-2 block px-4">
      <div
        class="text-grey-700 slim-scroller overflow-auto bumpDescription text-sm w-full"
        v-html="description"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      default: '',
    },
    subheading: {
      default: '',
    },
    thumbnail: {
      default: false,
    },
    thumbnailImg: {
      default: '',
    },
    thumbnailIsSquare: {
      default: false,
    },
    thumbnailSize: {
      default: 'md',
    },
    description: {
      default: '',
    },
  },
  computed: {
    hasThumbnail() {
      return this.thumbnail && this.thumbnailImg && this.thumbnailImg != '';
    },
    getThumbnailClasses() {
      return {
        'h-12 w-12 flex items-center justify-center max-h-full max-w-full': true,
        'md:h-24 md:w-24': this.thumbnailSize === 'lg',
        'md:h-16 md:w-16': this.thumbnailSize === 'md',
        'md:h-12 md:w-12': this.thumbnailSize === 'sm',
        'rounded-full': !this.thumbnailIsSquare,
      };
    },
  },
};
</script>
