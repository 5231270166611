<template>
  <div class="w-full">
    <div class="w-full">
      <div v-if="affiliateIds.length > 0" class="w-full">
        <div id="affiliates-data-list-view" class="flex flex-row">
          <v-server-table
            :key="componentKey"
            ref="affiliatesListTable"
            :columns="columns"
            :options="options"
            @loading="onLoading"
            @loaded="onLoaded"
          >
            <div slot="type" slot-scope="props" v-if="disqualifyTypeProp">
              <div class="flex flex-row">
                <gd-dropdown
                  v-model="blockAffFinalArrProp[affiliateIds.length - props.index].type"
                  :options="typeOptions"
                  @change="changedDisqualifyType(affiliateIds.length - props.index)"
                />
                <div
                  class="flex flex-row w-full ml-2"
                  v-if="
                    blockAffFinalArrProp[affiliateIds.length - props.index]
                      .type == 2
                  "
                >
                  <label class="font-semibold text-sm text-grey-700 mt-2 mr-2"
                    >{{ $t('frontend_groovesell_max_position') }}</label
                  >
                  <gd-dropdown
                    v-model="blockAffFinalArrProp[affiliateIds.length - props.index].position"
                    :options="positionsOptions"
                    @change="changedDisqualifyPosition(affiliateIds.length - props.index)"
                  />
                </div>
              </div>
            </div>
            <div slot="actions" slot-scope="props">
              <gd-button
                size="icon"
                variant="primary"
                :title="$t('frontend_gduikit_affiliateview_delete_affiliate')"
                @click="removeAffiliate(props.row.id)"
              >
                <trash-icon class="w-5 h-5 fill-current" />
              </gd-button>
            </div>
          </v-server-table>
        </div>
      </div>
      <div v-else class="pl-2 mb-10">
        {{ $t('frontend_gduikit_affiliateview_delete_no_affiliates') }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  name: 'AffiliatesListView',
  components: {
    TrashIcon,
  },
  props: {
    blockAffFinalArr: {
      
    },

    affiliateIds: {
      default: [],
    },
    funnel: {
      default: 0,
    },
    disqualifyType: {
      default: false,
    },
    positions: {
      default: 0,
    },
  },
  data() {
    return {
      selectedDisqualifyId: 1,
      blockAffFinalArrProp: this.blockAffFinalArr,
      disqualifyTypeProp: this.disqualifyType,
      componentKey: 0,
      options: {
        headings: {},
        perPage: 5,
        texts: {
          filterPlaceholder: 'Search',
          filter: '',
        },
        params: {
          funnel_id: this.funnel,
          user_ids: this.affiliateIds,
        },
        requestFunction(data) {
          return axios
            .post('funnel-affiliates/get-details', {
              params: data,
            })
            .catch(function (e) {
              this.dispatch('error', e);
            });
        },
        responseAdapter({ data }) {
          return {
            data: data.data,
            count: data.count,
          };
        },
      },
    };
  },
  computed: {
    typeOptions() {
      return [
        { value: 1, text: this.$t('frontend_groovesell_disqualify') },
        { value: 3, text: this.$t('frontend_groovesell_disqualify_show_position') },
        { value: 2, text: this.$t('frontend_groovesell_disqualify_top_position') }
      ];
    },
    positionsOptions() {
      return Array.from({ length: this.positions }, (_, index) => index + 1);
    },
    columns () {
      const nameKey = 'frontend_groovesell_affiliate_list_view_columns_name'
      const actionsKey = 'frontend_groovesell_affiliate_list_view_columns_actions'

      if (this.disqualifyType) {
        return [
          this.$t(nameKey),
          this.$t('frontend_groovesell_affiliate_list_view_columns_type'),
          this.$t(actionsKey)
        ]
      }

      return [
        this.$t(nameKey),
        this.$t(actionsKey)
      ]
    }
  },
  watch: {
    affiliateIds(ids) {
      this.options.params.user_ids = ids;
      this.componentKey++;
      this.$nextTick(() => {
        this.fetchAffiliateNames();
      });
    },
  },
  created() {
    this.computedFunnelId = this.funnel;
    this.computedAffiliateIds = this.affiliateIds;
  },
  mounted() {
    if (this.affiliateIds.length > 0) {
      // this.fetchAffiliateNames();
    }
  },
  methods: {
    changedDisqualifyPosition(index) {
      this.$emit('updatePosition', {
        index,
        value: this.blockAffFinalArrProp[index].position,
      });
    },

    changedDisqualifyType(index) {
      this.$emit('updateType', {
        index,
        value: this.blockAffFinalArrProp[index].type,
      });
    },

    async fetchAffiliateNames() {
      this.$refs.affiliatesListTable.refresh();
    },
    onLoading() {
      this.$loader.start('onLoading');
    },
    onLoaded() {
      this.$loader.end('onLoading');
    },
    removeAffiliate(affiliateId) {
      this.$emit('onAffiliateRemoved', affiliateId);
    },
  },
};
</script>

<style lang="scss">
#affiliates-data-list-view {
  width: 100%;
  .VueTables--server {
    width: 100%;
  }

  .VueTables--server table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>
