<template>
  <div
    class="
      md:flex
      mx-6
      md:mx-auto
      mt-6
      w-full
      bg-white
      rounded rounded-lg
      border border-grey-200
      info-panel-wrapper-inner
    "
  >
    <div class="relative md:w-64 h-52 border-r border-grey-200">
      <div
        class="
          absolute
          w-full
          h-full
          rounded-l-lg
          bg-grey-transparent
          show_panel
          opacity-0
          flex
          items-center
          justify-center
        "
      >
        <gd-button variant="primary" size="md" @click="sendToEditProduct">
          {{ $t('frontend_product_edit') }}
        </gd-button>
      </div>
      <div class="w-full h-44 flex justify-center items-center text-primary py-2">
        <img
          class="h-full w-full object-contain"
          v-if="productImage"
          :src="productImage"
        />
        <gifts-icon v-else class="w-full h-full fill-current" />
      </div>
      <div class="flex justify-end bottom-0 right-0 absolute mb-1">
        <gd-tag
          v-if="product.is_test_mode_enabled"
          class="mr-1"
          variant="warning"
          size="sm"
        >
          {{ $t('frontend_groove_sell_span_test_mode') }}
        </gd-tag>
        <gd-tag class="mr-1" variant="primary" size="sm">
          {{ productTypeName }}
        </gd-tag>
      </div>

    </div>
    <div
      class="
        w-full
        h-52
        flex flex-col
        justify-between
        md:flex-1
        pl-4
        rounded-lg
        p-5
      "
    >
      <div>
        <div class="flex items-center w-full">
          <div class="w-3/4">
            <p class="text-lg text-dark font-bold mr-auto" v-line-clamp:27="1">
              {{ product.name }}
              <span v-if="product.is_test_mode_enabled">(Test Mode)</span>
            </p>
          </div>
          <div class="w-1/4 flex flex-row justify-end">
            <drop-menu :menu-items="menuItems" small class="-mt-4"></drop-menu>
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-full">
            <p class="text-sm text-grey-700" v-line-clamp:21="2">
              <span class="font-bold">
                {{ $t('frontend_internal_name') }} :
              </span>
              {{ product.internal_name ? product.internal_name : 'N/A' }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-full">
            <p class="text-sm text-grey-700 mt-2" v-line-clamp:21="2">
              <span class="font-bold"> {{ $t('frontend_desc') }} : </span>
              {{ product.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="show_panel opacity-0">
        <div class="flex items-center gap-x-4 text-grey-600">
          <span v-for="(option, index) in menuItemsIcons" :key="index">
            <span
              class="fill-current cursor-pointer"
              @click="option.callback()"
              v-tooltip="option.text"
            >
              <component :is="option.icon" class="w-5 h-5" />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartBarIcon from '@/assets/images/icons/ChartBarIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import CloneIcon from '@/assets/images/icons/CloneIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';
import GdTag from '@groovepages/gd-ui-kit/components/Tag';

export default {
  name: 'ProductListAsset',
  components: {
    GiftsIcon,
    GdTag,
  },
  props: {
    product: {},
    productType: {},
    admin: {
      default: false,
    },
  },
  computed: {
    productTypeName() {
      let productTypeName = '';

      if (this.productType != 'atc_order_bumps') {
        if (this.product.type == 1) productTypeName = this.$t('frontend_groovesell_tag_frontend');
        if (this.product.type == 2) productTypeName = this.$t('frontend_product_index_tab_upsell_downsell');
        if (this.product.type == 3) productTypeName = this.$t('frontend_product_step1_order_bump');
      } else {
        productTypeName = this.$t('upsell_add_to_cart_order_bumps');
      }

      return productTypeName;
    },
    productImage() {
      let productImage = false;

      if (
        this.product.checkout_page_options &&
        this.product.checkout_page_options != null &&
        this.product.checkout_page_options.product_image &&
        this.product.checkout_page_options.product_image != ''
      )
        productImage = this.product.checkout_page_options.product_image;

      return productImage;
    },
    isProductComplete() {
      let productComplete = true;
      if (this.product.is_wizard_complete && this.product.is_complete) {
        productComplete = false;
      }
      return productComplete;
    },
    menuItemsIcons() {
      const menuItemsIcons = [];
      menuItemsIcons.push({
        icon: PencilIcon,
        text: this.$t('frontend_product_edit'),
        callback: () =>
          this.$emit('onProductEdit', this.product.id, this.isProductComplete),
      });
      if (this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: ShoppingCartIcon,
          text: this.$t('forntend_checkout_links'),
          callback: () => this.$emit('onProductCheckOutLinks', this.product.id),
        });
      }
      if (this.admin && this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: ChartBarIcon,
          text: this.$t('frontend_groovesell_menu_items_projections'),
          callback: () => this.$emit('onProductProjection', this.product.id),
        });
      }
      if (this.productType != 'atc_order_bumps') {
        menuItemsIcons.push({
          icon: CloneIcon,
          text: this.$t('frontend_product_clone'),
          callback: () => this.$emit('onProductClone', this.product.id),
        });
      }
      menuItemsIcons.push({
        icon: MegaphoneIcon,
        text: this.$t('frontend_funnels_affiliate_signup_promo_tools'),
        callback: () => this.$emit('onManagePromotools', this.funnel.id),
      });
      return menuItemsIcons;
    },
    menuItems() {
      const menuItems = [];
      if (this.productType != 'atc_order_bumps') {
        if (!this.product.is_test_mode_enabled) {
          menuItems.push({
            text: this.$t('frontend_groovesell_menu_items_enable_test_mode'),
            callback: () =>
              this.$emit('onProductEnableTestMode', this.product.id, 1),
          });
        } else {
          menuItems.push({
            text: this.$t('frontend_groovesell_menu_items_disable_test_mode'),
            callback: () =>
              this.$emit('onProductEnableTestMode', this.product.id, 0),
          });
        }
      }
      if (this.productType != 'atc_order_bumps') {
        menuItems.push({
          text: this.$t('frontend_groovesell_menu_items_tracking_links'),
          callback: () => this.$emit('onProductTracking', this.product.id),
        });
      }
      if (this.product.is_main != true) {
        menuItems.push({
          text: this.$t('frontend_groovesell_menu_items_delete_product'),
          callback: () => this.$emit('onProductDelete', this.product.id),
        });
      }
      if (
        this.productType == 'all' &&
        !this.product.hasOwnProperty('is_main')
      ) {
        const Trackingindex = menuItems.findIndex(
          (x) => x.text == this.$t('frontend_groovesell_menu_items_tracking_links')
        );
        menuItems.splice(Trackingindex, 1);
        const checkOutLinkindex = menuItems.findIndex(
          (x) => x.text == this.$t('forntend_checkout_links')
        );
        menuItems.splice(checkOutLinkindex, 1);
        const cloneindex = menuItems.findIndex(
          (x) => x.text == this.$t('frontend_product_clone')
        );
        menuItems.splice(cloneindex, 1);
      }

      return menuItems;
    },
  },
  methods: {
    backgroundImageUrl(url) {
      if (url) {
        return `url("${url}")`;
      }

      return '';
    },
    sendToEditProduct() {
      this.$emit('onProductEdit', this.product.id, this.isProductComplete);
    },
  },
};
</script>
