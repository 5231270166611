<template>
  <div>
    <div class="w-full">
      <v-wait for="links">
        <div class="flex flex-row justify-end">
          <gd-button size="auto" variant="primary" class="mb-4" @click="createPageTrackingLink">
            <plus-icon class="w-5 h-5 fill-current" />
            {{ $t('frontend_page_tracking_code') }}
          </gd-button>
        </div>
        <div v-if="links.length" id="links-list-view" class="mt-8">
          <v-client-table
            ref="linksTable"
            :data="links"
            :columns="linksTableColumns"
            :options="linksTableOptions"
            class="bg-white text-center rounded border border-grey-300"
          >
            <div slot="actions" slot-scope="props">
              <div class="flex flex-row">
                <gd-button
                  v-clipboard:copy="props.row.tracking_link"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="primary"
                  size="icon"
                  class="mr-2"
                >
                  <copy-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button variant="primary" size="icon" class="mr-2" @click="editPageTrackingLink(props.row.id)">
                  <pencil-icon class="w-5 h-5 fill-current" />
                </gd-button>
                <gd-button variant="primary" size="icon" class="mr-2" @click="openConfirmModal(props.row.id)">
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="mt-8">
          {{ $t('frontend_groovesell_no_page_tracking_codes') }}
        </div>
      </v-wait>
    </div>

    <gd-modal name="add-page-tracking-modal" :title="$t('frontend_groovesell_add_page_tracking_code')">
      <div class="flex flex-col m-10">
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_name') }}
          </label>
          <text-input
            v-model="model.name"
            :validator="$v.model.name"
            attribute="Name"
            :placeholder="$t('frontend_name')"
          />
        </div>
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_desc') }}
          </label>
          <text-input
            v-model="model.description"
            :validator="$v.model.description"
            :attribute="$t('frontend_desc')"
            :placeholder="$t('frontend_desc')"
          />
        </div>
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_goal_val') }}
          </label>
          <gd-currency-input v-model="model.value" currency-code="USD" />
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button size="md" variant="primary" @click="submitPageTrackingLink">
            {{ $t('frontend_add') }}
          </gd-button>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="edit-page-tracking-modal" :title="$t('frontend_groovesell_edit_page_tracking_code')">
      <div class="flex flex-col m-10">
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_name') }}
          </label>
          <text-input
            v-model="model.name"
            :validator="$v.model.name"
            :attribute="$t('frontend_name')"
            :placeholder="$t('frontend_name')"
          />
        </div>
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_desc') }}
          </label>
          <text-input
            v-model="model.description"
            :validator="$v.model.description"
            :attribute="$t('frontend_desc')"
            :placeholder="$t('frontend_desc')"
          />
        </div>
        <div class="flex flex-col w-full mb-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_goal_val') }}
          </label>
          <gd-currency-input v-model="model.value" currency-code="USD" />
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button size="md" variant="primary" @click="updatePageTracking">
            {{ $t('frontend_update') }}
          </gd-button>
        </div>
      </div>
    </gd-modal>
    <confirm-modal
      name="confirm-tracking-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deletePageTrackingLink"
    />
  </div>
</template>

<script>
import PageTrackingLink from '@/models/PageTrackingLink';
import Notify from '@groovepages/gd-ui-kit/notify';
import { required, decimal, minValue, minLength, maxLength } from 'vuelidate/lib/validators';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Page Tracking' };
  },
  components: {
    CopyIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon,
  },
  data() {
    return {
      trackingLinklId: null,
      model: {
        name: '',
        description: '',
        value: 0
      },
      links: [],
      linksTableColumns: ['name', 'human_readable_value', 'clicks', 'uniques', 'human_readable_revenue', 'actions'],
      linksTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: ['name', 'human_readable_value', 'clicks', 'human_readable_revenue'],
        headings: {
          name: this.$t('frontend_groovesell_page_tracking_table_name'),
          actions: this.$t('frontend_groovesell_page_tracking_table_actions'),
          clicks: this.$t('frontend_impressions'),
          uniques: this.$t('frontend_uniques'),
          human_readable_revenue: this.$t('frontend_revenue'),
          human_readable_value: this.$t('frontend_value')
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      }
    };
  },
  validations: {
    model: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80)
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160)
      },
      value: {
        required,
        decimal,
        minValue: minValue(0)
      }
    }
  },
  async created() {
    this.$wait.start('links');
    this.links = await PageTrackingLink.$get();
    this.$wait.end('links');
  },
  methods: {
    createPageTrackingLink() {
      this.pageTrackingLink = new PageTrackingLink({});
      this.model.name = '';
      this.model.description = '';
      this.model.value = 0;
      this.$root.$emit('modal-open', 'add-page-tracking-modal');
    },
    async submitPageTrackingLink() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$loader.start('submitPageTrackingLink');
        this.pageTrackingLink.name = this.model.name;
        this.pageTrackingLink.description = this.model.description;
        this.pageTrackingLink.value = this.model.value;
        await this.pageTrackingLink.save();
        this.$loader.end('submitPageTrackingLink');

        if (this.pageTrackingLink.data.id) {
          this.$root.$emit('modal-close', 'add-page-tracking-modal');
          this.$wait.start('links');
          this.links = await PageTrackingLink.$get();
          this.$wait.end('links');
        }
      }
    },
    async editPageTrackingLink(trackerId) {
      this.$loader.start('editPageTrackingLink');
      this.pageTrackingLink = await PageTrackingLink.$find(trackerId);
      this.model.name = this.pageTrackingLink.name;
      this.model.description = this.pageTrackingLink.description;
      this.model.value = this.pageTrackingLink.value;
      this.$root.$emit('modal-open', 'edit-page-tracking-modal');
      this.$loader.end('editPageTrackingLink');
    },
    async updatePageTracking() {
      this.$v.model.name.$touch();
      this.$v.model.description.$touch();
      this.$v.model.value.$touch();

      if (!this.$v.model.name.$invalid && !this.$v.model.description.$invalid && !this.$v.model.value.$invalid) {
        this.$loader.start('updatePageTracking');
        this.pageTrackingLink.name = this.model.name;
        this.pageTrackingLink.description = this.model.description;
        this.pageTrackingLink.value = this.model.value;
        await this.pageTrackingLink.save();
        this.links = await PageTrackingLink.$get();

        this.$root.$emit('modal-close', 'edit-page-tracking-modal');
        this.$loader.end('updatePageTracking');
      }

      this.$v.model.name.$reset();
      this.$v.model.description.$reset();
      this.$v.model.value.$reset();
    },
    onCopy() {
      Notify.success(this.$t('frontend_page_track_copy_success'));
    },
    onError() {
      Notify.fail(this.$t('frontend_page_track_copy_unsuccess'));
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-tracking-delete-modal');
      this.trackingLinklId = id;
    },
    async deletePageTrackingLink() {
        this.$loader.start('deletePageTrackingLink');
        let pageTrackingLink = await PageTrackingLink.$find(this.trackingLinklId);
        await pageTrackingLink.delete();
        let deleted_link = this.links.map(item => item.id).indexOf(this.trackingLinklId);
        this.links.splice(deleted_link, 1);
        this.$loader.end('deletePageTrackingLink');
    }
  }
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#links-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
