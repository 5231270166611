<template>
    <gd-modal name="advanced-failed-filters-modal" :title="$t('frontend_groovesell_modal_advanced_filters')" height="80%"
        width="80%" bg-type="regular" allowOverflow>
        <template slot="actions">
            <div class="justify-end">
                <gd-button
                    size="md"
                    :variant="defaultFilters ? 'base' : 'primary'"
                    :disabled="defaultFilters"
                    @click="emitStartSearch"
                >
                    {{ $t('frontend_analytics_apply_filters') }}
                </gd-button>
            </div>
        </template>
        <div class="flex flex-col m-6 mb-4">
            <div class="flex flex-col w-full mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_funnels')
                }}</label>
                <treeselect v-model="LocalfilteredFunnels" :multiple="true" :options="funnelsDropdown"
                    :placeholder="$t('frontend_analytics_funnels_filter')" @input="filteredFunnelsChanged" />
            </div>
            <div class="flex flex-col w-full mr-2 mt-2 mb-8">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_analytics_affiliates')
                }}</label>
                <multi-select v-model="LocalfilteredAffiliates" track-by="id" label="name"
                    :placeholder="$t('frontend_analytics_select_one')" :options="affiliatesForFilter" :searchable="true"
                    :multiple="true" :allow-empty="false" @input="onAffiliateChange">
                </multi-select>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';

export default {
    components: {
        Treeselect,
    },
    props: {
        filteredFunnels: {
            type: Array,
            default: () => [],
        },
        funnelsDropdown: {
            type: Array,
            default: () => [],
        },
        filteredAffiliates: {
            type: Array,
            default: () => [],
        },
        affiliatesForFilter: {
            default: () => [],
        },

    },
    data() {
        return {
            LocalfilteredFunnels: this.filteredFunnels,
            LocalfilteredAffiliates: this.filteredAffiliates,
        }
    },
    computed: {
        defaultFilters() {
            return this.LocalfilteredFunnels?.length == 0 &&
            this.LocalfilteredAffiliates?.length == 1 && 
            this.LocalfilteredAffiliates[0].id == 0 &&
            this.LocalfilteredAffiliates[0].name === 'All';
        },
    },
    watch: {
        filteredFunnels: {
            handler: function (val) {
                this.LocalfilteredFunnels = val;
            },
            deep: true,
        },
        filteredAffiliates: {
            handler: function (val) {
                this.LocalfilteredAffiliates = val;
            },
            deep: true,
        },
    },
    methods: {
        onAffiliateChange() {
            if (this.LocalfilteredAffiliates && this.LocalfilteredAffiliates.length > 0) {
                const lastAddedAffiliateId = this.LocalfilteredAffiliates[this.LocalfilteredAffiliates.length - 1].id;

                if (lastAddedAffiliateId == 0 || lastAddedAffiliateId == -1 || lastAddedAffiliateId == -2) {
                    this.LocalfilteredAffiliates = this.LocalfilteredAffiliates.slice(-1);
                } else {
                    this.LocalfilteredAffiliates = this.LocalfilteredAffiliates.filter((obj) => {
                        return obj.id !== 0 && obj.id !== -1 && obj.id !== -2;
                    });
                }
                this.$emit('filter-changed', {
                    key: 'filteredAffiliates',
                    value: this.LocalfilteredAffiliates,
                    startSearch: false,
                })
            }
        },
        filteredFunnelsChanged() {
            this.$emit('filter-changed', {
                key: 'filteredFunnels',
                value: this.LocalfilteredFunnels,
            })
        },
        emitStartSearch() {
            this.$emit('apply-filters');
            this.$root.$emit('modal-close', 'advanced-failed-filters-modal');
        },
    }
}
</script>