<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliates">
        <div class="flex justify-between">
          <div class="w-1/4">
            <div class="flex space-x-2 items-center">
              <gd-dropdown
                v-model="filteredAffiliateTypes"
                :options="filteredAffiliateTypesOptions"
                :placeholder="$t('frontend_groovesell_affiliate_types_filter')"
              />
              <gd-dropdown
                v-model="selectedRowSize"
                size="small"
                :options="pageSizeOptions"
              />
            </div>
            <div class="flex mt-4">
              <gd-dropdown
                v-model="selectedFunnel"
                :options="funnelsOptions"
                :placeholder="$t('frontend_funnels')"
              />
            </div>
            <div class="flex mt-4 justify-start">
              <gd-button
                :class="{'animated bounce': !defaultFilters || applyTriggered}"
                :variant="defaultFilters && !applyTriggered ? 'base' : 'primary'"
                :disabled="defaultFilters && !applyTriggered"
                @click="applyFilters"
              >
                {{ $t('apply_filters') }}
              </gd-button>
              <gd-button v-if="!defaultFilters && applyTriggered" variant="outline" size="auto" class="ml-4" @click="clearFilters">
                <span class="mr-2">
                  <filter-icon class="w-5 h-5 fill-current" />
                </span>
                {{ $t('frontend_affiliate_transactions_reset') }}
              </gd-button>
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex">
              <text-input
                v-model="searchPhrase"
                value="searchPhrase"
                :placeholder="$t('frontend_search')"
              />
            </div>
            <div class="flex flex-row mt-4 justify-between space-x-2">
              <gd-dropdown
                :disabled="!selectedRowLength"
                :placeholder="$t('frontend_affiliates_bulk_actions')"
                :actions="bulkActions"
              />
              <gd-dropdown
                :placeholder="$t('frontend_affiliates_select_affiliates')"
                :actions="selectAffiliatesActions"
              />
            </div>
            <div class="flex flex-row mt-4 justify-end">
              <gd-button variant="primary" size="auto" @click="openAdvancedFilters">
                <filter-icon class="w-5 h-5 fill-current" />
                {{ $t('frontend_groovesell_modal_advanced_filters') }}
              </gd-button>
            </div>
          </div>
        </div>

        <div v-if="isFunnelAdded" id="affiliates-list-view" class="mt-4">
          <ag-grid-vue class="ag-theme-material" style="width: 100%" :dom-layout="domLayout" :grid-options="gridOptions"
            :column-defs="affiliatesTableColumnDefs" :pagination="true" :row-height="affiliatesTableRowHeight"
            :row-buffer="rowBuffer" :row-model-type="rowModelType" :datasource="dataSource"
            :pagination-page-size="paginationPageSize" :cache-overflow-size="cacheOverflowSize"
            :infinite-initial-row-count="infiniteInitialRowCount" :max-blocks-in-cache="maxBlocksInCache"
            :cache-block-size="cacheBlockSize" :suppress-row-click-selection="true" :suppress-cell-selection="true"
            :row-class="rowClass" :row-class-rules="rowClassRules" :context="context"
            :framework-components="frameworkComponents" :row-selection="rowSelection" :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests
              " @grid-ready="onGridReady" @model-updated="onModelUpdated" @row-selected="onRowSelected"
            @selection-changed="onSelectionChanged"></ag-grid-vue>
        </div>
        <div v-else>
          <p>{{ $t('frontend_groovesell_no_funnels_added_your_account') }}</p>
        </div>

        <affiliate-detail-modal :selectedAffiliate="selectedAffiliate" :openMarkAsPaidModal="openMarkAsPaidModal"
          :openClawbackModal="openClawbackModal" :openCommissionAdvanceModal="openCommissionAdvanceModal"
          :openCommissionBonusModal="openCommissionBonusModal" :fetchAffiliateLinks="fetchAffiliateLinks"
          :downloadTaxForm="downloadTaxForm" :previewTaxForm="previewTaxForm" />
        <affiliate-links-modal :affiliateLinks="affiliateLinks" />

        <mark-as-paid-modal :selectedAffiliate="selectedAffiliate" :processPayment="processPayment"
          @mark-as-paid-update="updateMarkAsPaid" />

        <add-commission-bonus-modal :selectedAffiliate="selectedAffiliate" :validation="$v"
          :addAffiliatePaymentBonus="addAffiliatePaymentBonus" @bonus-update="updateBonus" />
        <add-commission-advance-modal :selectedAffiliate="selectedAffiliate" :validation="$v"
          :addCommissionAdvance="addCommissionAdvance" @update-commission-advance="updateCommissionAdvance" />

        <add-clawback-modal @update-clawback="updateClawback" :selectedAffiliate="selectedAffiliate" :validation="$v"
          :addClawback="addClawback" />

        <open-affiliates-export-modal :exportAffiliatesResponse="exportAffiliatesResponse"
          :exportAffiliateDetails="exportAffiliateDetails" :exportPaypalMasspay="exportPaypalMasspay"
          @update-export-affiliates="updateExportAffiliates" />
        <advanced-filters-modal ref="advancedFiltersModal" @update-filters="UpdateFilters" :filteredDueCommissionsType="filteredDueCommissionsType"
          :filteredDueAmount="filteredDueAmount" :filteredCommissionsAmount="filteredCommissionsAmount"
          :filteredNumberOfSales="filteredNumberOfSales" :filteredSalesAmount="filteredSalesAmount"
          :applyAdvancedFilters="applyAdvancedFilters" />
        <secondtier-affiliates-modal :affiliateAffiliates="affiliateAffiliates" />
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  required,
  maxLength,
  decimal,
  minValue,
} from 'vuelidate/lib/validators';
import { AgGridVue } from 'ag-grid-vue';
import _ from 'lodash';
import axios from 'axios';
import Funnel from '@/models/Funnel';
import Affiliate from '@/models/Affiliate';
import AffiliatePayment from '@/models/AffiliatePayment';
import AffiliatePaymentBonus from '@/models/AffiliatePaymentBonus';
import AffiliateLink from '@/models/AffiliateLink';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AffiliateFullNameRenderer from '@/renderers/affiliateFullNameRenderer';
import AffiliateSalesRenderer from '@/renderers/affiliateSalesRenderer';
import AffiliateRefundsRenderer from '@/renderers/affiliateRefundsRenderer';

/*** new modals */
import AffiliateDetailModal from './index_partials/modals/AffiliateDetailModal';
import AffiliateLinksModal from './index_partials/modals/AffiliateLinksModal';
import MarkAsPaidModal from './index_partials/modals/MarkAsPaidModal';
import AddCommissionBonusModal from './index_partials/modals/AddCommissionBonusModal';
import AddCommissionAdvanceModal from './index_partials/modals/AddCommissionAdvanceModal';
import AddClawbackModal from './index_partials/modals/AddClawbackModal';
import OpenAffiliatesExportModal from './index_partials/modals/OpenAffiliatesExportModal';
import AdvancedFiltersModal from './index_partials/modals/AdvancedFiltersModal';
import SecondtierAffiliatesModal from './index_partials/modals/SecondtierAffiliatesModal';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Affiliates' };
  },
  validations: {
    markAsPaid: {
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
    commissionAdvance: {
      amount: {
        required,
        decimal,
        minValue: minValue(1),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
    clawback: {
      amount: {
        required,
        decimal,
        minValue: minValue(0),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
    bonus: {
      amount: {
        required,
        decimal,
        minValue: minValue(0),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
  },
  components: {
    AgGridVue,
    AffiliateDetailModal,
    AffiliateLinksModal,
    MarkAsPaidModal,
    AddCommissionBonusModal,
    AddCommissionAdvanceModal,
    AddClawbackModal,
    OpenAffiliatesExportModal,
    AdvancedFiltersModal,
    SecondtierAffiliatesModal,
    FilterIcon,
  },
  data() {
    return {
      alertProps: {
        variant: 'success',
        title: '',
        message: '',
        show: false,
      },
      applyTriggered: false,
      isFunnelAdded: false,
      genericUrl: `${window.config.apiUrl.split('/api')[0]}/api`,
      filteredNumberOfSales: 0,
      filteredSalesAmount: 0,
      filteredCommissionsAmount: 0,
      filteredDueCommissionsType: false,
      filteredDueAmount: 0,
      affiliatePaymentExportId: 0,
      affiliateLinks: [],
      affiliateAffiliates: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      dataSource: null,
      affiliates: [],
      paginationPageSize: 25,
      affiliatesTableRowHeight: 40,
      rowBuffer: null,
      rowModelType: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      domLayout: 'autoHeight',
      affiliatesTableColumnDefs: null,
      filteredAffiliateTypes: 0,
      searchPhrase: '',
      rowClassRules: null,
      rowClass: 'affiliates-row',
      selectedRowSize: 25,
      context: null,
      frameworkComponents: null,
      selectedAffiliate: null,
      newAffiliatePayment: null,
      rowSelection: 'multiple',
      isAllSelected: false,
      unselectedAffiliateIds: [],
      selectedRowLength: 0,
      selectedAffiliatesAmount: 0,
      markAsPaid: {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      },
      commissionAdvance: {
        amount: 0,
        comment: '',
        transactionId: '',
      },
      clawback: {
        amount: 0,
        comment: '',
        transactionId: '',
      },
      bonus: {
        amount: 0,
        comment: '',
        transactionId: '',
      },
      funnels: [],
      selectedFunnel: 0,

      exportAffiliates: {
        gatewayType: '',
        typeOfPayment: 1,
      },
      exportAffiliatesResponse: {
        paypal: {
          count: 0,
          amount: '$0.00',
        },
        check: {
          count: 0,
          amount: '$0.00',
        },
        wire: {
          count: 0,
          amount: '$0.00',
        },
        on_hold: {
          count: 0,
          amount: '$0.00',
        },
        total: {
          count: 0,
          amount: '$0.00',
        },
        hash: '',
      },
    };
  },
  computed: {
    defaultFilters() {
      return this.filteredAffiliateTypes == 0 &&
        this.selectedRowSize == 25 &&
        (this.selectedFunnel == 0 || this.selectedFunnel == this.funnels[0]?.id) &&
        this.searchPhrase == '' &&
        this.filteredNumberOfSales == 0 &&
        this.filteredSalesAmount == 0 &&
        this.filteredCommissionsAmount == 0 &&
        this.filteredDueCommissionsType == false &&
        this.filteredDueAmount == 0;
    },
    sellAppUrl() {
      return this.whitelabelAppData('sell').url;
    },
    bulkActions() {
      return [
        {
          text: this.$t('frontend_affiliates_export_affiliates'),
          callback: () => this.downloadAffiliateExportFile()
        },
        {
          text: this.$t('frontend_affiliates_pay_selected_affiliates'),
          callback: () => this.openExportModal()
        },
      ];
    },
    selectAffiliatesActions() {
      return [
        {
          text: this.$t('frontend_affiliates_select_all'),
          callback: () => this.selectAllAffiliates()
        },
        {
          text: this.$t('frontend_affiliates_Deselect_all'),
          callback: () => this.unselectAllAffiliates()
        },
      ];
    },
    filteredAffiliateTypesOptions() {
      return [
        {
          text: this.$t('frontend_affiliates_all_affiliates'),
          value: 0,
        },
        {
          text: this.$t('frontend_affiliates_all_paid_up'),
          value: 1,
        },
        {
          text: this.$t('frontend_affiliates_due_payments'),
          value: 2,
        },
      ];
    },
    pageSizeOptions() {
      return [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ];
    },
    funnelsOptions() {
      return this.funnels.map((funnel) => {
        return {
          text: funnel.name,
          value: funnel.id,
        };
      });
    },
  },
  async beforeMount() {
    this.gridOptions = {};

    this.context = { componentParent: this };
    this.frameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer,
    };

    this.affiliatesTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_affiliate_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'rendered_name',
            cellRenderer: 'affiliateFullNameRenderer',
            colId: 'affiliateFullName',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'rendered_username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_clicks'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_impressions'),
            field: 'impressions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_uniques'),
            field: 'uniques',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_conversions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_signups'),
            field: 'total_optins',
            cellRenderer: 'affiliateSalesRenderer',
            colId: 'affiliateOptins',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_sales'),
            field: 'total_sales',
            cellRenderer: 'affiliateSalesRenderer',
            colId: 'affiliateSales',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_total_sales_amount'),
            field: 'human_readable_total_sales_amount',
            cellRenderer: 'affiliateSalesAmountRenderer',
            colId: 'affiliateSalesAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_refunds'),
            field: 'total_refunds',
            cellRenderer: 'affiliateRefundsRenderer',
            colId: 'affiliateRefunds',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_total_refunds_amount'),
            field: 'human_readable_total_refunds_amount',
            cellRenderer: 'affiliateRefundsRenderer',
            colId: 'affiliateRefundsAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_comissions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned_comissions'),
            field: 'human_readable_total_commissions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid_comissions'),
            field: 'human_readable_pending_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_due_comissions'),
            field: 'human_readable_processed_without_advances',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_being_processed_comissions'),
            field: 'human_readable_in_process_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_amount_paid'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_comissions_on_hold'),
            field: 'human_readable_on_hold_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_all_earned_comissions'),
            field: 'human_readable_alltime_earned_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_all_paid_comissions'),
            field: 'human_readable_alltime_paid_commission',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_all_cash_advances'),
            field: 'human_readable_commission_advance',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_all_clawbacks'),
            field: 'human_readable_clawbacks',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_email'),
            field: 'rendered_email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
    ];
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;

    this.rowClassRules = {};
  },
  async created() {
    this.$wait.start('affiliates');
    const funnels = await Funnel.params({'resource_type': 'basic'}).$get();

    if (funnels.length > 0) {
      this.isFunnelAdded = true;
      this.funnels = funnels;
      if (this.$route.query.funnel_id) {
        this.selectedFunnel = this.$route.query.funnel_id;
      } else {
        this.selectedFunnel = funnels[0].id;
      }
    } else {
      this.isFunnelAdded = false;
    }

    this.$wait.end('affiliates');
  },
  methods: {
    UpdateFilters(val) {
      this.filteredNumberOfSales = val.filteredNumberOfSales;
      this.filteredSalesAmount = val.filteredSalesAmount;
      this.filteredCommissionsAmount = val.filteredCommissionsAmount;
      this.filteredDueCommissionsType = val.filteredDueCommissionsType;
      this.filteredDueAmount = val.filteredDueAmount;
    },
    updateExportAffiliates(val) {
      this.exportAffiliates = val;
    },
    updateClawback(val) {
      this.clawback = val;
    },
    updateCommissionAdvance(val) {
      this.commissionAdvance = val;
    },
    updateBonus(val) {
      this.bonus = val;
    },
    updateMarkAsPaid(val) {
      this.markAsPaid = val;
    },
    previewTaxForm(pdf) {
      this.openLink(`${this.genericUrl}${this.sellAppUrl}download-user-files/${pdf}`,'_blank');
    },

    downloadTaxForm(pdf) {
      this.openLink(`${this.genericUrl}${this.sellAppUrl}download-user-files/${pdf}?is_download=1`,'_blank');
    },


    applyAdvancedFilters() {
      this.applyFilters();
      this.isAllSelected = false;
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },

    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },

    clearFilters() {
      this.applyTriggered = false;

      this.filteredAffiliateTypes = 0;
      this.selectedRowSize = 25;
      this.selectedFunnel = this.funnels[0]?.id || 0;
      this.searchPhrase = '';
      this.$refs.advancedFiltersModal.clearFilters();

      this.applyFilters(true);
    },


    async openLink(link, linkTarget) {
      let a = document.createElement('a');
      a.target = linkTarget;
      a.href = link.replace(/^http:\/\//i, 'https://');
      a.click();
    },
    async getAdminFileURL(fileName) {
      const url = `${this.genericUrl}${this.sellAppUrl}download-admin-files/${fileName}`;
      this.openLink(url, '_blank');
    },

    async fetchAffiliateLinks(id) {
      this.$loader.start('fetchAffiliateLinks');
      this.affiliateLinks = await AffiliateLink.params({
        funnelId: id,
        affiliateId: this.selectedAffiliate.id,
      })
        .custom('fetch-aff-links-from-vendor')
        .$get();

      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },

    async fetchAffiliateAffiliates(id) {
      this.$loader.start('fetchAffiliateAffiliates');
      const { data } = await axios.get(
        `second-tier-affiliates/${id}/${this.selectedAffiliate.id}`
      );

      this.affiliateAffiliates = data.affiliates;
      this.$loader.end('fetchAffiliateAffiliates');
      this.$root.$emit('modal-open', 'secondtier-affiliates-modal');
    },

    async exportAffiliateDetails() {
      const self = this;
      self.$loader.start('exportAffiliateDetails');

      let exportType = '';

      if (self.exportAffiliates.gatewayType == '1') {
        exportType = 'paypal';
      } else if (self.exportAffiliates.gatewayType == '3') {
        exportType = 'wire';
      }

      const downloadLinkData = await axios.post(
        '/process-exported-affiliate-transactions',
        {
          funnel: self.selectedFunnel,
          hash: self.exportAffiliatesResponse.hash,
          type: self.exportAffiliates.typeOfPayment,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        const a = document.createElement('a');
        a.href = downloadLinkData.data.download_link.replace(/^http:\/\//i,'https://');
        a.click();

        self.unselectAllAffiliates();
        self.$loader.end('exportAffiliateDetails');
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.affiliatePaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportAffiliateDetails');
        this.alertProps = {
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc'),
          show: true,
        };
      }
    },

    async exportPaypalMasspay() {
      const self = this;
      self.$loader.start('exportPaypalMasspay');

      const exportType = 'paypal_masspay';
      const downloadLinkData = await axios.post(
        '/process-exported-affiliate-transactions',
        {
          funnel: self.selectedFunnel,
          hash: self.exportAffiliatesResponse.hash,
          type: self.exportAffiliates.typeOfPayment,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        const a = document.createElement('a');
        a.href = downloadLinkData.data.download_link.replace(
          /^http:\/\//i,
          'https://'
        );
        a.click();

        self.unselectAllAffiliates();
        self.$loader.end('exportPaypalMasspay');
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.affiliatePaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportPaypalMasspay');
        this.alertProps = {
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc'),
          show: true,
        };
      }
    },

    async getStatus() {
      const { data } = await axios.get(
        `affiliate-payment-export-status/${this.affiliatePaymentExportId}`
      );

      const processedStatus = data.status;

      if (processedStatus == 'processing') {
        setTimeout(this.getStatus(), 5000);
      } else if (processedStatus == 'processed') {
        Swal.close();

        const a = document.createElement('a');
        a.href = downloadLinkData.data.download_link.replace(
          /^http:\/\//i,
          'https://'
        );
        a.click();

        this.unselectAllAffiliates();

        this.alertProps = {
          variant: 'success',
          title: this.$t('frontend_processed_successfully'),
          message: this.$t('frontend_groovesell_swal_export_file_successfully'),
          show: true,
        };
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.alertProps = {
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_backend_error'),
          show: true,
        };
      }
    },

    async openExportModal() {
      const self = this;
      self.$loader.start('openExportModal');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
          affiliates: self.filteredAffiliateTypes,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
          noOfSales: self.filteredNumberOfSales,
          salesAmount: self.filteredSalesAmount,
          commissionsAmount: self.filteredCommissionsAmount,
          dueCommissionsType: self.filteredDueCommissionsType,
          dueAmount: self.filteredDueAmount,
        }
      );

      this.exportAffiliatesResponse.paypal = exportLinkData.data.paypal;
      this.exportAffiliatesResponse.wire = exportLinkData.data.wire;
      this.exportAffiliatesResponse.hash = exportLinkData.data.hash;
      this.exportAffiliatesResponse.on_hold = exportLinkData.data.on_hold;
      this.exportAffiliatesResponse.total = exportLinkData.data.total;
      self.$loader.end('openExportModal');

      this.exportAffiliates = {
        gatewayType: '',
        typeOfPayment: 2,
      };
      this.$root.$emit('modal-open', 'open-affiliates-export-modal');
    },

    async downloadAffiliateExportFile() {
      const self = this;
      self.$loader.start('downloadAffiliateExportFile');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
          affiliates: self.filteredAffiliateTypes,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
          noOfSales: self.filteredNumberOfSales,
          salesAmount: self.filteredSalesAmount,
          commissionsAmount: self.filteredCommissionsAmount,
          dueCommissionsType: self.filteredDueCommissionsType,
          dueAmount: self.filteredDueAmount,
          direct_download: 1,
        }
      );

      const a = document.createElement('a');
      a.href = exportLinkData.data.download_link.replace(
        /^http:\/\//i,
        'https://'
      );
      a.click();
      self.$loader.end('downloadAffiliateExportFile');
    },



    sendToSecondTierAffiliatesPage() {
      const routeData = this.$router.resolve({
        name: 'second_tier_affiliates',
        query: { referred_by: this.selectedAffiliate.id },
      });
      this.openLink(routeData.href, '_blank');
    },

    async processPayment() {
      this.$v.markAsPaid.$touch();
      const isValid = !this.$v.markAsPaid.$invalid;

      if (isValid) {
        this.$loader.start('processPayment');
        const self = this;

        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          amount: self.selectedAffiliate.processed_without_advances / 100,
          payment_gateway: self.markAsPaid.gatewayType,
          note: self.markAsPaid.comment,
          transaction_id: self.markAsPaid.transactionId,
          type: 1,
          markTransactionAs: self.markAsPaid.typeOfPayment,
          funnel: this.selectedFunnel,
          processedTransactionIds:
            self.selectedAffiliate.processed_transaction_ids,
          processedAssistIds:
            self.selectedAffiliate.processed_affiliate_assist_ids,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'mark-as-paid-modal');

        this.selectedAffiliate = await Affiliate.params({
          funnels: this.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('processPayment');
        this.applyFilters();
      }
    },

    openMarkAsPaidModal() {
      this.$v.markAsPaid.$reset();
      this.markAsPaid = {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      };
      this.$root.$emit('modal-open', 'mark-as-paid-modal');
    },

    openCommissionAdvanceModal() {
      this.commissionAdvance = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-commission-advance-modal');
    },

    openClawbackModal() {
      this.clawback = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-clawback-modal');
    },

    openCommissionBonusModal() {
      this.bonus = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-commission-bonus-modal');
    },

    async addCommissionAdvance() {
      this.$v.commissionAdvance.$touch();
      const isValid = !this.$v.commissionAdvance.$invalid;

      if (isValid) {
        this.$loader.start('addCommissionAdvance');
        const self = this;
        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          funnel: this.selectedFunnel,
          amount: self.commissionAdvance.amount,
          payment_gateway: 0,
          note: self.commissionAdvance.comment,
          transaction_id: self.commissionAdvance.transactionId,
          type: 2,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'add-commission-advance-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: this.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addCommissionAdvance');
        this.applyFilters();
      }
    },

    async addClawback() {
      this.$v.clawback.$touch();
      const isValid = !this.$v.clawback.$invalid;

      if (isValid) {
        this.$loader.start('addClawback');
        const self = this;
        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          funnel: this.selectedFunnel,
          amount: self.clawback.amount,
          payment_gateway: 0,
          note: self.clawback.comment,
          transaction_id: self.clawback.transactionId,
          type: 3,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'add-clawback-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: this.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addClawback');
        this.applyFilters();
      }
    },

    async addAffiliatePaymentBonus() {
      this.$v.bonus.$touch();
      const isValid = !this.$v.bonus.$invalid;

      if (isValid) {
        this.$loader.start('addAffiliatePaymentBonus');
        const self = this;
        const affiliatePaymentBonus = new AffiliatePaymentBonus({
          affiliate_id: self.selectedAffiliate.id,
          funnel: this.selectedFunnel,
          amount: self.bonus.amount,
          payment_gateway: 0,
          note: self.bonus.comment,
          transaction_id: self.bonus.transactionId,
        });

        await affiliatePaymentBonus.save();
        this.$root.$emit('modal-close', 'add-commission-bonus-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: this.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addAffiliatePaymentBonus');
        this.applyFilters();
      }
    },

    onModelUpdated() {
      if (this.isAllSelected) {
        const self = this;
        this.gridApi.forEachNode((rowNode) => {
          if (!self.unselectedAffiliateIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },

    async exportSelectedAffiliates() {
      const self = this;
      self.$loader.start('exportSelectedAffiliates');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
        }
      );

      const a = document.createElement('a');
      a.href = exportLinkData.data.download_link.replace(
        /^http:\/\//i,
        'https://'
      );
      a.click();

      self.$loader.end('exportSelectedAffiliates');
    },

    onRowSelected(event) {
      if (
        !event.node.selected &&
        this.unselectedAffiliateIds.indexOf(event.node.data.id) === -1
      ) {
        this.unselectedAffiliateIds.push(event.node.data.id);
      } else {
        this.unselectedAffiliateIds = this.unselectedAffiliateIds.filter(
          (item) => item !== event.node.data.id
        );
      }
    },

    selectAllAffiliates() {
      this.isAllSelected = true;
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
      });

      this.unselectedAffiliateIds = [];
    },

    unselectAllAffiliates() {
      this.isAllSelected = false;
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });

      this.unselectedAffiliateIds = [];
    },

    async openAffiliateDetail(affiliateId) {
      this.$loader.start('openAffiliateDetail');
      this.selectedAffiliate = await Affiliate.params({
        funnels: this.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'info';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openAffiliateDetail');
      // console.log(transactionId);
    },

    async openSalesDetail(affiliateId) {
      this.$loader.start('openSalesDetail');
      this.selectedAffiliate = await Affiliate.params({
        funnels: this.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'transactions';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openSalesDetail');
      // console.log(transactionId);
    },

    async openRefundsDetail(affiliateId) {
      this.$loader.start('openRefundsDetail');
      this.selectedAffiliate = await Affiliate.params({
        funnels: this.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'refunds';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openRefundsDetail');
    },

    applyFilters(isClearFilters = false) {
      if (!isClearFilters) {
        this.applyTriggered = true;
      }

      this.gridApi.paginationGoToPage(0);
      this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
    },

    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },

    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getDataSource');

        const affiliatesData = await Affiliate.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            affiliates: self.filteredAffiliateTypes,
            search: self.searchPhrase,
            funnels: self.selectedFunnel,
            sortBy: params.sortModel,
            noOfSales: self.filteredNumberOfSales,
            salesAmount: self.filteredSalesAmount,
            commissionsAmount: self.filteredCommissionsAmount,
            dueCommissionsType: self.filteredDueCommissionsType,
            dueAmount: self.filteredDueAmount,
          })
          .get();

        let rowsThisPage = [];
        rowsThisPage = affiliatesData.data;
        const totalNumberOfRows = affiliatesData.meta.total;
        self.funnels = affiliatesData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
  },
};
</script>
<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>
<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#second-tier-affiliates-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header .ag-header-group-cell-label span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
.ag-icon-checkbox-checked {
  color: var(--brand-primary) !important;
}
</style>
