<template>
  <div>
    <div class="w-full">
      <v-wait for="products">
        <navigation-header :funnels="funnels" :funnel="funnel" :selectedFunnelId="selectedFunnelId"
          :selectedProductId="selectedProductId" :openCheckoutPagesModal="openCheckoutPagesModal"
        />

        <checkout-links-list :checkoutLinks="checkoutLinks" :openCheckoutPageLink="openCheckoutPageLink"
          :openEditCheckoutPage="openEditCheckoutPage" :selectedProduct="selectedProduct" />

      </v-wait>
    </div>

    <gd-modal name="checkout-pages-modal" :title="checkoutModalTitle" height="100%" width="80%">
      <steper-header :selectedCheckoutFlow="selectedCheckoutFlow" :currentTab="currentTab" :errorTabs="errorTabs"
        :tabs="tabs" :moveBackOnWizard="moveBackOnWizard" :moveNextOnWizard="moveNextOnWizard" />
      <v-wait for="create_coupon">
      </v-wait>
      <div class=" relative w-full h-full flex flex-col justify-start px-4 overflow-auto">

        <div :class="{ 'h-full': currentTab === 'LinksStep' }">
          <component v-if="currentTab" :is="currentTab" :ref="currentTab" :model="model" :product="selectedProduct"
            :selectCheckoutStepType="selectedCheckoutStepType" :selectedCheckoutStepType="selectedCheckoutStepType"
            :selectedMultiStepTypeProp="selectedMultiStepType"
            :selectedCheckoutTypeFreePaid="selectedCheckoutTypeFreePaid" :isMultiStepRedirectProp="isMultiStepRedirect"
            :pricePoints="selectedProductPricePoints" :prductDetails="selectedProduct"
            :pricepointTabs="projectionPricePointsTabs" :projectionsPricePointsTab="projectionPricePointsTab"
            :handleProjectionPricePointTabChange="handleProjectionPricePointsTabChange"
            :pricepointsForProjections="pricepointsForProjections" :selectPricePoint="selectedProductPricePoints"
            :disableBrandingBarProp="disableBrandingBar" :selectedMultiStepType="selectedMultiStepType"
            :checkoutLinks="checkoutLinks" @updateModel="model = $event"
            @updateSelCheckoutStepType="selectedCheckoutStepType = $event"
            @updateselectedMultiStepType="selectedMultiStepType = $event"
            @updateselectedCheckoutTypeFreePaid="selectedCheckoutTypeFreePaid = $event"
            @updatePrductDetailsProp="selectedProduct = $event" @updateCheckoutProp="checkoutLinks = $event"
            @disableBrandingBarChange="disableBrandingBar = $event"
            @updateIsMultiStepRedirect="isMultiStepRedirect = $event"
            @updateMultiStepRedirectOptions="model.multi_step_redirect_options = $event"
            @on-validate="stepValidationSuccessful(currentTab, $event)" />

        </div>

      </div>
    </gd-modal>
    <checkout-link-modal :checkout_link="checkout_link" :checkout_embed="checkout_embed" />
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  requiredIf,
  url,
} from 'vuelidate/lib/validators';
import Funnel from '@/models/Funnel';
import Product from '@/models/Product';
import CheckoutLink from '@/models/CheckoutLink';
import Notify from '@groovepages/gd-ui-kit/notify';
import ConfigStep from './steps/ConfigStep';
import StepsStep from './steps/StepsStep';
import PricePointsStep from './steps/PricePointsStep';
import LookFeelStep from './steps/LookFeelStep';
import FormFieldsStep from './steps/FormFieldsStep';
import PasswordsStep from './steps/PasswordsStep';
import BillingAdressStep from './steps/BillingAdressStep';
import CustomCssStep from './steps/CustomCssStep';
import LinksStep from './steps/LinksStep';

import NavigationHeader from './partials/NavigationHeader';
import CheckoutLinksList from './partials/CheckoutLinksList';
import CheckoutLinkModal from './modals/CheckoutLinkModal';
import SteperHeader from './partials/SteperHeader';

import '@/assets/scss/products/checkout_links.scss';


export default {
  middleware: 'auth',
  scrollToTop: true,
  metaInfo() {
    return { title: 'Checkout Links' };
  },

  components: {
    ConfigStep,
    StepsStep,
    PricePointsStep,
    LookFeelStep,
    FormFieldsStep,
    PasswordsStep,
    BillingAdressStep,
    CustomCssStep,
    LinksStep,
    NavigationHeader,
    CheckoutLinksList,
    CheckoutLinkModal,
    SteperHeader
  },

  validations: {
    model: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },

      multi_step_redirect_options: {
        order_form_redirects: {
          order_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (self.isMultiStepRedirect && self.selectedMultiStepType == 3) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
        email_form_redirects: {
          billing_form_url: {
            required: requiredIf(function (value) {
              const self = this;

              if (self.isMultiStepRedirect && self.selectedMultiStepType == 2) {
                return true;
              }
              return false;
            }),
            url,
          },
          payment_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (self.isMultiStepRedirect && self.selectedMultiStepType == 2) {
                return true;
              }
              return false;
            }),
            url,
          },
          password_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.isMultiStepRedirect &&
                self.selectedMultiStepType == 2 &&
                self.model.checkout_link_page_options.move_password_to_last_step
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
        contact_form_redirects: {
          billing_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (self.isMultiStepRedirect && self.selectedMultiStepType == 1) {
                return true;
              }
              return false;
            }),
            url,
          },
          payment_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (self.isMultiStepRedirect && self.selectedMultiStepType == 1) {
                return true;
              }
              return false;
            }),
            url,
          },
          password_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.isMultiStepRedirect &&
                self.selectedMultiStepType == 1 &&
                self.model.checkout_link_page_options.move_password_to_last_step
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
      },
    },
  },

  watch: {
    selectedProduct() { },
  },

  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      isStepsValid: false,
      errorTabs: [],
      disableBrandingBar: 0,
      showSkipBillingAddress: true,
      currentTab: 'ConfigStep',
      funnel: null,
      selectedProduct: null,
      funnels: [],
      products: [],
      selectedFunnelId: 0,
      selectedProductId: 0,
      object: {
        name: 'Options',
      },
      checkoutFlow: 0,
      selectedCheckoutFlow: 0,
      trackingFlow: 0,
      selectedTrackingFlow: 0,
      proofWidgets: [],
      checkoutLinksIndex: 0,
      model: {
        isEdit: false,
        checkout_links: [],
        name: '',
        skip_field_arr: [],
        force_affiliate: 0,
        customization_details: [],
        post_transaction_url_details: [],
        is_multi_step_redirect: 0,
        multi_step_redirect_options: [],
        // force_affiliate : false,
        force_affiliate_id: null,
        primary_color: '#ffcc24',
        secondary_color: '#033a7c',
        step_type: this.selectedMultiStepType,
        checkout_link_type: this.selectedCheckoutTypeFreePaid,
      },
      multiPricePointLink: null,
      selectedCheckoutStepType: 1,
      selectedCheckoutTypeFreePaid: 1,
      selectedMultiStepType: 3,
      isMultiStepRedirect: 0,
      checkoutStepTypes: [
        {
          id: 1,
          title: '1-Step',
          description: '',
        },
        {
          id: 0,
          title: 'Multi-step',
          description: '',
        },
      ],


      selectedProductPricePoints: [],
      pricePointsTab: '',
      proofWidgetsTab: '',
      checkoutEmbedTypes: [
        { title: this.$t('frontend_product_hosted_checkout'), value: 'link' },
        { title: this.$t('frontend_product_embedded_into'), value: 'embed' },
      ],
      checkoutEmbedType: 'link',
      pricepointsForProjections: [],
      projectionPricePointsTab: '',
      postValidationTab: 'StepsStep',
      changeTabsAfterValidation: 1,
      checkoutLinks: [],
      selectedCheckoutData: [],
      checkout_link: null,
      checkout_embed: null,
      move_password_to_last_step: false,
      checkoutModalTitle: '',
      productHasOnlyFreePricePoints: true,
    };
  },

  computed: {
    isAdmin() {
      return this.$store.state.user.admin;
    },

    tabs() {
      const createTab = (titleKey, value) => ({
        title: `<span class="text-sm font-semibold">${this.$t(titleKey)} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i></span>`,
        value,
      });

      const baseTabs = [
        createTab('frontend_config', 'ConfigStep'),
        createTab('frontend_steps', 'StepsStep'),
        createTab('frontend_price_points', 'PricePointsStep'),
        createTab('frontend_product_step5_look_feel', 'LookFeelStep'),
        createTab('frontend_product_step5_form_fields', 'FormFieldsStep'),
        createTab('frontend_passwords', 'PasswordsStep'),
      ];

      if (this.selectedFreePricePoints) {
        baseTabs.push(createTab('frontend_product_skip_billing_address', 'BillingAdressStep'));
      }

      baseTabs.push(createTab('frontend_product_step5_custom_css', 'CustomCssStep'));
      baseTabs.push({ title: '<span class="text-sm font-semibold">Links<span>', value: 'LinksStep' });

      return baseTabs;
    },

    freePricePoints() {
      if (this.selectedProduct && this.selectedProduct.price_points) {
        return this.selectedProduct.price_points.filter((pricePoint) => {
          return pricePoint.price == 0;
        }).map((obj) => {
          return obj.id;
        });
      }
      return [];
    },

    selectedFreePricePoints() {
      if (this.model.hasOwnProperty('pricing_options') && this.model.pricing_options.length > 0) {
        const selectedPricePointIds = this.model.pricing_options
          .filter((pricePoint) => {
            return (
              pricePoint.enabled &&
              (pricePoint.enabled == true || pricePoint.enabled == 'true')
            );
          })
          .map((obj) => {
            return obj.id;
          });

        return this.freePricePoints.some((item) =>
          selectedPricePointIds.includes(item)
        );
      }

      return false;
    },



    pricePointsTabs() {
      const pricePointTabs = [];

      for (let i = 0; i < this.selectedProductPricePoints.length; i += 1) {
        pricePointTabs.push({
          title: this.selectedProductPricePoints[i].name,
          value: `price_point_${this.selectedProductPricePoints[i].id}`,
          price: this.selectedProductPricePoints[i].price,
        });
      }

      return pricePointTabs;
    },

    proofWidgetsTabs() {
      const proofWidgetsTabs = [];

      for (let i = 0; i < this.proofWidgets.length; i += 1) {
        proofWidgetsTabs.push({
          title: this.proofWidgets[i].name,
          value: `proof_widget_${this.proofWidgets[i].id}`,
        });
      }

      return proofWidgetsTabs;
    },

    projectionPricePointsTabs() {
      const pricePointTabs = [];

      for (let i = 0; i < this.pricepointsForProjections.length; i += 1) {
        pricePointTabs.push({
          title: this.pricepointsForProjections[i].name,
          value: `price_point_${this.pricepointsForProjections[i].id}`,
        });
      }
      return pricePointTabs;
    },

  },

  async created() {
    console.log(' {{$store}}', this.$store);
    this.$wait.start('products');
    this.funnel = await Funnel.$find(this.$route.params.id);
    this.selectedProduct = await Product.$find(this.$route.params.product_id);
    this.checkoutLinks = await this.selectedProduct.checkoutLinks().$get();
    this.funnels = await Funnel.$get();
    this.selectedFunnelId = this.$route.params.id;
    this.selectedProductId = this.$route.params.product_id;
    this.$wait.end('products');
  },

  methods: {

    handleTabChange() {
      //  this.currentTab = newTab;
    },


    getSiblingTab(position = 1) {
      const currentlySelectedTabIndex = this.tabs
        .map((item) => item.value)
        .indexOf(this.currentTab);

      const tab = this.tabs[currentlySelectedTabIndex + position];
      return tab ? tab.value : this.currentTab;
    },

    moveNextOnWizard() {
      const nextTab = this.getSiblingTab(1);
      this.postValidationTab = nextTab;
      const refToValidate = this.$refs[this.currentTab];
      this.errorTabs = [];
      const validTabs = ['ConfigStep', 'StepsStep', 'PricePointsStep', 'LookFeelStep', 'FormFieldsStep', 'PasswordsStep', 'BillingAdressStep', 'CustomCssStep'];

      if (validTabs.includes(this.currentTab)) {

        if (this.currentTab === 'PasswordsStep') {
          if (!this.isStepsValid) {
            this.errorTabs.push('StepsStep');
            return false;
          }
          return refToValidate.validate();
        }
        return refToValidate.validate();
      }

      // if its create checkout functionality
      if (!this.model.isEdit) {
        this.currentTab = nextTab;
      }

      return true;
    },

    moveBackOnWizard() {
      const prevTab = this.getSiblingTab(-1);
      this.postValidationTab = prevTab;
      this.currentTab = prevTab;
    },

    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async openEditCheckoutPage(id) {
      this.$loader.start('openEditCheckoutPage');
      this.currentTab = 'ConfigStep';
      this.selectedCheckoutData = await CheckoutLink.$find(id);

      if (this.selectedCheckoutData.checkout_link_page_options.hasOwnProperty('move_field_to_next_step')) {
        this.move_field_to_next_step = this.selectedCheckoutData.checkout_link_page_options.move_field_to_next_step;
      } else {
        this.selectedCheckoutData.checkout_link_page_options.move_field_to_next_step = false;
      }

      let formFields = this.selectedCheckoutData.form_fields;
      if (this.selectedCheckoutData.hasOwnProperty('form_fields') && (formFields == null || formFields == 'null' || formFields == undefined)) {
        if (this.selectedProduct.checkout_page_options.hasOwnProperty('form_fields')) {
          formFields = this.selectedProduct.checkout_page_options.form_fields;
        }
      }

      if (this.selectedCheckoutData.checkout_link_page_options.hasOwnProperty('move_password_to_last_step')) {
        this.move_password_to_last_step = this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step;
      } else {
        this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step = false;
      }
      this.selectedCheckoutStepType = this.selectedCheckoutData.is_onestep;
      this.selectedCheckoutTypeFreePaid = this.selectedCheckoutData.checkout_link_type;
      this.selectedMultiStepType = this.selectedCheckoutData.step_type;
      this.isMultiStepRedirect = this.selectedCheckoutData.is_multi_step_redirect;
      const productId = this.$route.params.product_id;
      this.selectedProduct = await Product.$find(productId);
      const currentProductPricePoints = await this.selectedProduct.pricepoints().$get();
      const selectedPricePointData = this.setupModel(currentProductPricePoints, formFields);

      this.selectedProductPricePoints = [];
      const self = this;
      currentProductPricePoints.forEach((val, index) => {
        if (selectedPricePointData[index] != undefined) {
          if (selectedPricePointData.length != 0 && selectedPricePointData[index].id == val.id) {
            val.enabled = selectedPricePointData[index].enabled;
          }

          if (this.selectedCheckoutData.checkout_link_type == 1 && val.price > 0) {
            self.selectedProductPricePoints.push(val);
          }

          if (this.selectedCheckoutData.checkout_link_type == 2 && val.price == 0) {
            self.selectedProductPricePoints.push(val);
          }
        } else {
          if (this.selectedCheckoutData.pricing_options == null) {
            this.selectedCheckoutData.pricing_options = [];
          }

          if (val.id != undefined || val.id != null) {
            this.selectedCheckoutData.pricing_options.push({
              ask_for_password: false,
              ask_username: false,
              enabled: false,
              id: val.id,
              skip_billing_info: false,
              skip_payment_info: false,
              skip_terms_or_service: false,
            });
            self.selectedProductPricePoints.push(val);
          }
        }
      });


      this.pricePointsTab = `price_point_${self.selectedProductPricePoints[0].id}`;

      this.selectedProduct.checkout_page_options.product_image =
        this.selectedCheckoutData.checkout_link_page_options.product_image !== undefined
          ? this.selectedCheckoutData.checkout_link_page_options.product_image
          : '';

      this.selectedProduct.checkout_page_options.tracking_codes =
        this.selectedCheckoutData.checkout_link_page_options.tracking_codes !== undefined
          ? this.selectedCheckoutData.checkout_link_page_options.tracking_codes
          : '';

      this.selectedProduct.checkout_page_options.look_and_feel =
        this.selectedCheckoutData.checkout_link_page_options.look_and_feel !== undefined
          ? this.selectedCheckoutData.checkout_link_page_options.look_and_feel
          : '';


      if (this.selectedProduct.checkout_page_options.look_and_feel.template_type != undefined && this.selectedProduct.checkout_page_options.look_and_feel.template_type == 4) {
        if (this.selectedProduct.checkout_page_options.look_and_feel.custom_color != undefined) {
          this.model.primary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.primary_color;
        }
        if (this.selectedProduct.checkout_page_options.look_and_feel.custom_color != undefined) {
          this.model.secondary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.secondary_color;
        }
      }

      if (this.selectedCheckoutData.hasOwnProperty('pricing_options') && this.selectedCheckoutData.pricing_options != null) {
        this.model.skip_field_arr = currentProductPricePoints.map(
          (point, index) => {
            let skip_payment =
              this.selectedCheckoutData.pricing_options[index].skip_payment_info;
            if (point.price == 0) {
              skip_payment = true;
            }

            let returnData = {
              id: point.id,
              ask_username: this.selectedCheckoutData.pricing_options[index].ask_username,
              ask_for_password: this.selectedCheckoutData.pricing_options[index].ask_for_password,
              ask_for_password_end: this.selectedCheckoutData.pricing_options[index].ask_for_password_end,
              skip_billing_info: this.selectedCheckoutData.pricing_options[index].skip_billing_info,
              skip_payment_info: skip_payment,
              skip_terms_or_service: this.selectedCheckoutData.pricing_options[index].skip_terms_or_service,

            }
            if (this.selectedCheckoutData.pricing_options[index].hasOwnProperty('skip_phone') && this.selectedCheckoutData.pricing_options[index].skip_phone != null)
              returnData['skip_phone'] = this.selectedCheckoutData.pricing_options[index].skip_phone;
            else
              returnData['skip_phone'] = false;

            return returnData;
          }
        );
      }
      this.model = { ...this.model };
      this.pricepointsForProjections = await this.selectedProduct.pricepoints().$get();
      this.projectionPricePointsTab = `price_point_${this.pricepointsForProjections[0].id}`;
      this.checkoutModalTitle = `${this.funnel.name} <i class="fas fa-chevron-right text-sm mx-1"></i> ${this.funnel.main_product.name} <i class="fas fa-chevron-right text-sm mx-1"></i> ${this.selectedCheckoutData.name}<i class="fas fa-chevron-right text-sm mx-1"></i>Edit Checkout`;
      this.$root.$emit('modal-open', 'checkout-pages-modal');
      this.$loader.end('openEditCheckoutPage');
    },
    setupModel(currentProductPricePoints, formFields) {
      this.model = this.selectedCheckoutData;
      this.model.isEdit = true;
      this.model.primary_color = this.selectedCheckoutData.primary_color;
      this.model.secondary_color = this.selectedCheckoutData.secondary_color;
      this.model.ask_for_password_select = 0;
      this.model.form_fields = formFields;
      this.model.step_type = this.selectedCheckoutData.step_type;
      this.model.customization_details = this.selectedCheckoutData.customization_details;
      this.model.multi_step_redirect_options = this.selectedCheckoutData.multi_step_redirect_options;
      this.model.is_multi_step_redirect = this.selectedCheckoutData.is_multi_step_redirect;
      this.model.post_transaction_url_details = this.selectedCheckoutData.post_transaction_url_details;
      if (this.model.post_transaction_url_details == null) {
        const postTransactionDetail = [];
        currentProductPricePoints.forEach((val, index) => {
          postTransactionDetail.push({
            id: val.id,
            name: val.name,
            post_transaction_type: val.post_transaction_details.type,
            post_transaction_url:
              val.post_transaction_details.post_transaction_url,
          });
        });
        this.model.post_transaction_url_details = postTransactionDetail;
      }
      const selectedPricePointData = [];
      if (this.selectedCheckoutData.pricing_options != undefined) {
        for (let i = 0; i < this.selectedCheckoutData.pricing_options.length; i++) {
          if (this.model.ask_for_password_select == 0) {
            if (this.selectedCheckoutData.pricing_options[i].ask_for_password) {
              this.model.ask_for_password_select = 1;
            }

            if (this.selectedCheckoutData.pricing_options[i].ask_for_password_end) {
              this.model.ask_for_password_select = 2;
            }
          }

          selectedPricePointData.push({
            id: this.selectedCheckoutData.pricing_options[i].id,
            enabled: this.selectedCheckoutData.pricing_options[i].enabled,
          });
        }
      }
      return selectedPricePointData;
    },

    async openCheckoutPagesModal() {
      this.$loader.start('openCheckoutPagesModal');
      this.setLocalProperties();
      const productId = this.$route.params.product_id;

      this.selectedProduct = await this.funnel.products().$find(productId);
      const selectedCheckoutLinks = await this.selectedProduct.checkoutLinks().$get();
      const currentProductPricePoints = await this.selectedProduct.pricepoints().$get();

      for (let i = 0; i < currentProductPricePoints.length; i++) {
        if (currentProductPricePoints[i].price !== 0) {
          this.productHasOnlyFreePricePoints = false;
        }

        this.model.skip_field_arr.splice(i, 0, {
          id: currentProductPricePoints[i].id,
          enabled: false,
          ask_username: false,
          ask_for_password: false,
          ask_for_password_end: false,
          skip_billing_info: false,
          skip_payment_info: false,
          skip_terms_or_service: false,
          skip_phone: false,
        });
      }

      this.pricepointsForProjections = await this.selectedProduct.pricepoints().$get();
      this.projectionPricePointsTab = `price_point_${this.pricepointsForProjections[0].id}`;
      this.selectedProductPricePoints = []; // this prop passes in step 2
      const self = this;
      currentProductPricePoints.forEach((val) => {
        val.enabled = true;
        self.selectedProductPricePoints.push(val);
      });

      this.pricePointsTab = `price_point_${self.selectedProductPricePoints[0].id}`;
      this.model.checkout_links = selectedCheckoutLinks;
      this.model.checkout_link_page_options = {
        move_field_to_next_step: false,
        move_password_to_last_step: false,
      };
      this.checkoutFlow = 'Single Price Checkout';
      this.selectedCheckoutFlow = 0;
      this.selectedCheckoutTypeFreePaid = this.productHasOnlyFreePricePoints ? 2 : 1;
      this.model.step_type = 1;
      this.multiPricePointLink = null;
      this.model.ask_for_password_select = 0;

      if (this.selectedProduct.checkout_page_options.look_and_feel.template_type == 4) {
        this.model.primary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.primary_color;
        this.model.secondary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.secondary_color;
      }
      this.model.multi_step_redirect_options = {
        order_form_redirects: {
          order_form_url: '',
        },
        email_form_redirects: {
          billing_form_url: '',
          password_form_url: '',
          payment_form_url: '',
        },
        contact_form_redirects: {
          billing_form_url: '',
          password_form_url: '',
          payment_form_url: '',
        },
      };

      this.model = { ...this.model };
      this.checkoutModalTitle = `${this.funnel.name} <i class="fas fa-chevron-right text-sm mx-1"></i> ${this.funnel.main_product.name} <i class="fas fa-chevron-right text-sm mx-1"></i> Add Checkout`;
      this.$root.$emit('modal-open', 'checkout-pages-modal');
      this.$loader.end('openCheckoutPagesModal');
    },

    async stepValidationSuccessful(name, isValid) {
      if (name == 'StepsStep') {
        this.isStepsValid = isValid;
      }
      if (isValid && this.model.isEdit) this.updateStepData(name);
      if (isValid && !this.model.isEdit && this.changeTabsAfterValidation) this.saveStepData(name);
    },
    async saveStepData(name) {
      switch (name) {
        case 'ConfigStep':
          this.$loader.start('saveStepData');
          this.selectedCheckoutData.checkout_link_type = this.selectedCheckoutTypeFreePaid;
          const self = this;
          this.model.skip_field_arr.forEach((element, index) => {
            if (self.freePricePoints.includes(element.id)) {
              this.model.skip_field_arr[index].enabled = this.selectedCheckoutTypeFreePaid == 1 ? false : true
            } else {
              this.model.skip_field_arr[index].enabled = this.selectedCheckoutTypeFreePaid == 1 ? true : false
            }
          });

          const newProductCheckoutData = {
            product_id: this.selectedProduct.id,
            checkout_link_type: this.selectedCheckoutTypeFreePaid,
            price_point_id: 0,
            name: this.model.name,
            pricing_options: this.model.skip_field_arr,
            is_onestep: this.selectedCheckoutStepType,
            step_type: this.selectedMultiStepType,
            is_multi_step_redirect: 0,
            is_manual: 1,
            checkout_link_page_options: {
              look_and_feel: this.selectedProduct.checkout_page_options.look_and_feel,
              product_image: this.selectedProduct.checkout_page_options.product_image,
              tracking_codes: this.selectedProduct.checkout_page_options.tracking_codes,
              move_field_to_next_step: this.model.checkout_link_page_options.move_field_to_next_step,
              move_password_to_last_step: this.model.checkout_link_page_options.move_password_to_last_step,
            },
            force_affiliate: this.model.force_affiliate,
            force_affiliate_id: this.model.force_affiliate_id,
            form_fields: this.selectedProduct.checkout_page_options.form_fields,
          };


          const newMultiPricePointLink = new CheckoutLink(newProductCheckoutData);
          const savedCheckoutLinkData = await newMultiPricePointLink.save();
          this.selectedCheckoutData = await CheckoutLink.$find(savedCheckoutLinkData.data.id);
          const selectedProduct = await Product.$find(this.$route.params.product_id);
          const currentProductPricePoints = await selectedProduct.pricepoints().$get();


          const selectedPricePointData = [];
          if (this.selectedCheckoutData.pricing_options != undefined) {
            for (let i = 0; i < this.selectedCheckoutData.pricing_options.length; i++) {
              selectedPricePointData.push({
                id: this.selectedCheckoutData.pricing_options[i].id,
                enabled: this.selectedCheckoutData.pricing_options[i].enabled,
              });
            }
          }
          this.selectedProductPricePoints = [];
          currentProductPricePoints.forEach((val, index) => {
            if (selectedPricePointData[index] != undefined) {
              if (selectedPricePointData.length != 0 && selectedPricePointData[index].id == val.id) {
                val.enabled = selectedPricePointData[index].enabled;
              }
              if (this.selectedCheckoutData.checkout_link_type == 1 && val.price > 0) {
                self.selectedProductPricePoints.push(val);
              }
              if (this.selectedCheckoutData.checkout_link_type == 2 && val.price == 0) {
                self.selectedProductPricePoints.push(val);
              }
            }
          });

          this.checkoutLinks = await selectedProduct.checkoutLinks().$get();
          this.currentTab = 'StepsStep';
          this.model = this.selectedCheckoutData;
          this.model.isEdit = true;

          if (this.selectedProduct.checkout_page_options.look_and_feel.template_type != undefined && this.selectedProduct.checkout_page_options.look_and_feel.template_type == 4) {
            if (this.selectedProduct.checkout_page_options.look_and_feel.custom_color != undefined) {
              this.model.primary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.primary_color;
            }
            if (this.selectedProduct.checkout_page_options.look_and_feel.custom_color != undefined) {
              this.model.secondary_color = this.selectedProduct.checkout_page_options.look_and_feel.custom_color.secondary_color;
            }
          }

          this.model.ask_for_password_select = 0;
          this.model.skip_field_arr = currentProductPricePoints.map(
            (point, index) => {
              return {
                id: point.id,
                ask_username: this.selectedCheckoutData.pricing_options[index].ask_username,
                ask_for_password: this.selectedCheckoutData.pricing_options[index].ask_for_password,
                ask_for_password_end: this.selectedCheckoutData.pricing_options[index].ask_for_password_end,
                skip_billing_info: this.selectedCheckoutData.pricing_options[index].skip_billing_info,
                skip_payment_info: this.selectedCheckoutData.pricing_options[index].skip_payment_info,
                skip_terms_or_service: this.selectedCheckoutData.pricing_options[index].skip_terms_or_service,
              };
            }
          );
          this.model = { ...this.model };
          this.$loader.end('saveStepData');
          break;
      }
      this.currentTab = this.postValidationTab;
    },
    async updateStepData(name) {

      this.$loader.start('updateStepData');
      switch (name) {
        case 'ConfigStep':
          this.selectedCheckoutData.name = this.model.name;
          this.selectedCheckoutData.checkout_link_type = this.selectedCheckoutTypeFreePaid;
          const self = this;
          if (this.selectedCheckoutTypeFreePaid == 1) {
            this.selectedCheckoutData.pricing_options.forEach(
              (element, index) => {
                if (self.freePricePoints.includes(element.id)) {
                  this.selectedCheckoutData.pricing_options[index].enabled = false;
                }
              }
            );
          } else {
            const self = this;
            this.selectedCheckoutData.pricing_options.forEach(
              (element, index) => {
                if (!self.freePricePoints.includes(element.id)) {
                  this.selectedCheckoutData.pricing_options[index].enabled = false;
                }
              }
            );
          }


          this.selectedCheckoutData.checkout_link_page_options.move_field_to_next_step = this.model.checkout_link_page_options.move_field_to_next_step;
          this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step = this.model.checkout_link_page_options.move_password_to_last_step;

          this.selectedCheckoutData.force_affiliate = this.model.force_affiliate;
          this.selectedCheckoutData.force_affiliate_id = this.model.force_affiliate_id;
          if (!this.model.force_affiliate) {
            this.selectedCheckoutData.force_affiliate_id = null;
          }
          this.selectedCheckoutData.save();
          const selectedProduct = await Product.$find(this.$route.params.product_id);
          const currentProductPricePoints = await selectedProduct.pricepoints().$get();
          const selectedPricePointData = [];
          if (this.selectedCheckoutData.pricing_options != undefined) {
            for (let i = 0; i < this.selectedCheckoutData.pricing_options.length; i++) {
              selectedPricePointData.push({
                id: this.selectedCheckoutData.pricing_options[i].id,
                enabled: this.selectedCheckoutData.pricing_options[i].enabled,
              });
            }
          }

          this.selectedProductPricePoints = []; // this prop passes in step 2 as pricePoints
          currentProductPricePoints.forEach((val, index) => {
            if (selectedPricePointData[index] != undefined) {
              if (selectedPricePointData.length != 0 && selectedPricePointData[index].id == val.id) {
                val.enabled = selectedPricePointData[index].enabled;
              }
              if (this.selectedCheckoutData.checkout_link_type == 1 && val.price > 0) {
                self.selectedProductPricePoints.push(val);
              }
              if (this.selectedCheckoutData.checkout_link_type == 2 && val.price == 0) {
                self.selectedProductPricePoints.push(val);
              }
            }
          });

          this.checkoutLinks = await selectedProduct.checkoutLinks().$get();
          break;

        case 'StepsStep':

          this.selectedCheckoutData.is_onestep = this.selectedCheckoutStepType;
          this.model.is_onestep = this.selectedCheckoutStepType;
          this.selectedCheckoutData.step_type = this.selectedMultiStepType;

          if (this.selectedCheckoutStepType == 1) {
            this.selectedCheckoutData.step_type = 1;
          }
          this.selectedCheckoutData.is_multi_step_redirect = this.isMultiStepRedirect ? 1 : 0;
          this.selectedCheckoutData.multi_step_redirect_options = this.model.multi_step_redirect_options;
          this.selectedCheckoutData.save();
          break;

        case 'PricePointsStep':

          let shwSkpBllngAddrss = true;
          for (let i = 0; i < this.selectedProductPricePoints.length; i++) {
            if (this.selectedProductPricePoints[i].price > 0 && this.selectedProductPricePoints[i].enabled == true) {
              shwSkpBllngAddrss = false;
              break;
            }
          }

          for (let j = 0; j < this.selectedCheckoutData.pricing_options.length; j++) {
            this.selectedCheckoutData.pricing_options[j].enabled = false;
          }

          for (let i = 0; i < this.selectedProductPricePoints.length; i++) {
            for (let j = 0; j < this.selectedCheckoutData.pricing_options.length; j++) {
              if (this.selectedProductPricePoints[i].id == this.selectedCheckoutData.pricing_options[j].id) {
                this.selectedCheckoutData.pricing_options[j].enabled = this.selectedProductPricePoints[i].enabled;
              }
            }
          }


          this.selectedCheckoutData.save();
          break;

        case 'LookFeelStep':
          this.selectedCheckoutData.customization_details = this.model.customization_details;
          this.selectedCheckoutData.disable_branding_bar = this.disableBrandingBar;
          this.selectedCheckoutData.checkout_link_page_options = {
            look_and_feel: this.selectedProduct.checkout_page_options.look_and_feel,
            product_image: this.selectedProduct.checkout_page_options.product_image,
            tracking_codes: this.selectedProduct.checkout_page_options.tracking_codes,
            move_field_to_next_step: this.selectedCheckoutData.checkout_link_page_options.move_field_to_next_step,
            move_password_to_last_step: this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step,
          };

          var { template_type } = this.selectedCheckoutData.checkout_link_page_options.look_and_feel;

          if (template_type != undefined && template_type == 4) {
            this.selectedCheckoutData.checkout_link_page_options.look_and_feel =
            {
              template_type: this.selectedCheckoutData.checkout_link_page_options.look_and_feel.template_type,
              custom_color: {
                primary_color: this.model.primary_color,
                secondary_color: this.model.secondary_color,
              },
            };
          } else {
            this.selectedCheckoutData.checkout_link_page_options.look_and_feel =
            {
              template_type: this.selectedCheckoutData.checkout_link_page_options.look_and_feel.template_type,
            };
          }

          this.selectedCheckoutData.save();
          break;

        case 'FormFieldsStep':
          this.selectedCheckoutData.form_fields = this.model.form_fields;
          this.selectedCheckoutData.save();
          break;
        case 'PasswordsStep':
          this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step = this.model.checkout_link_page_options.move_password_to_last_step;

          const enabledPricePoints = this.selectedCheckoutData.pricing_options.filter((pricePoint) => { return pricePoint.enabled; });
          const enabledPricePointIdArr = enabledPricePoints.map((point) => { return point.id; });

          for (let i = 0; i < this.selectedCheckoutData.pricing_options.length; i++) {
            const pricePointSkipFieldsArray =
              this.model.skip_field_arr.filter((skipFieldsArray) => {
                return (
                  skipFieldsArray.id ==
                  this.selectedCheckoutData.pricing_options[i].id
                );
              });

            if (pricePointSkipFieldsArray.length === 0) {
              continue;
            }

            let { ask_for_password } = pricePointSkipFieldsArray[0];
            let { ask_for_password_end } = pricePointSkipFieldsArray[0];
            const { ask_username } = pricePointSkipFieldsArray[0];
            let { skip_phone } = pricePointSkipFieldsArray[0];

            if (this.model.has_password_fields == 0) {
              ask_for_password = false;
              ask_for_password_end = false;
            } else if (this.model.checkout_link_page_options.move_password_to_last_step) {
              ask_for_password = false;
              if (this.model.skip_field_arr[i].ask_for_password) {
                ask_for_password = true;
                ask_for_password_end = true;
              } else {
                ask_for_password_end = false;
              }
            } else {
              if (this.model.skip_field_arr[i].ask_for_password) {
                ask_for_password = true;
              } else {
                ask_for_password = false;
              }
              ask_for_password_end = false;
            }

            this.selectedCheckoutData.pricing_options[i] = {
              id: this.selectedCheckoutData.pricing_options[i].id,
              enabled: this.selectedCheckoutData.pricing_options[i].enabled,
              ask_username,
              ask_for_password,
              ask_for_password_start: ask_for_password,
              ask_for_password_end,
              skip_billing_info: pricePointSkipFieldsArray[0].skip_billing_info,
              skip_payment_info: pricePointSkipFieldsArray[0].skip_payment_info,
              skip_terms_or_service: pricePointSkipFieldsArray[0].skip_terms_or_service,
              skip_phone: pricePointSkipFieldsArray[0].skip_phone,
            };
          }

          this.selectedCheckoutData.has_password_fields = this.model.has_password_fields;
          this.selectedCheckoutData.save();
          break;

        case 'BillingAdressStep':
          for (let i = 0; i < this.selectedCheckoutData.pricing_options.length; i++) {
            const pricePointSkipFieldsArray =
              this.model.skip_field_arr.find((skipFieldsArray) => {
                return (
                  skipFieldsArray.id ==
                  this.selectedCheckoutData.pricing_options[i].id
                );
              });
            const { ask_for_password } = pricePointSkipFieldsArray;
            const { ask_for_password_end } = pricePointSkipFieldsArray;
            const { ask_username } = pricePointSkipFieldsArray;
            let { skip_billing_info } = pricePointSkipFieldsArray;
            let { skip_terms_or_service } = pricePointSkipFieldsArray;
            let { skip_phone } = pricePointSkipFieldsArray;

            if (this.model.has_skip_fields == 0) {
              skip_billing_info = false;
              skip_terms_or_service = false;
            }

            this.selectedCheckoutData.pricing_options[i] = {
              id: this.selectedCheckoutData.pricing_options[i].id,
              enabled: this.selectedCheckoutData.pricing_options[i].enabled,
              ask_username,
              ask_for_password,
              ask_for_password_start: ask_for_password,
              ask_for_password_end,
              skip_billing_info,
              skip_payment_info: pricePointSkipFieldsArray.skip_payment_info,
              skip_terms_or_service,
              skip_phone: skip_phone
            };
          }

          this.selectedCheckoutData.has_password_fields = this.model.has_password_fields;
          this.selectedCheckoutData.has_skip_fields = this.model.has_skip_fields;
          this.selectedCheckoutData.save();
          break;

        case 'CustomCssStep':
          this.selectedCheckoutData.checkout_link_page_options = {
            look_and_feel: this.selectedProduct.checkout_page_options.look_and_feel,
            product_image: this.selectedProduct.checkout_page_options.product_image,
            tracking_codes: this.selectedProduct.checkout_page_options.tracking_codes,
            move_field_to_next_step: this.selectedCheckoutData.checkout_link_page_options.move_field_to_next_step,
            move_password_to_last_step: this.selectedCheckoutData.checkout_link_page_options.move_password_to_last_step,
          };

          var { template_type } = this.selectedCheckoutData.checkout_link_page_options.look_and_feel;
          if (template_type != undefined && template_type == 4) {
            this.selectedCheckoutData.checkout_link_page_options.look_and_feel =
            {
              template_type: this.selectedCheckoutData.checkout_link_page_options.look_and_feel.template_type,
              custom_color: {
                primary_color: this.model.primary_color,
                secondary_color: this.model.secondary_color,
              },
            };
          } else {
            this.selectedCheckoutData.checkout_link_page_options.look_and_feel =
            {
              template_type: this.selectedCheckoutData.checkout_link_page_options.look_and_feel.template_type,
            };
          }

          this.selectedCheckoutData.save();
          break;
      }

      await this.sleep(1000);


      if (name === 'PasswordsStep') {
        if (!this.selectedFreePricePoints) {
          this.currentTab = 'CustomCssStep';
        }
        else {
          this.currentTab = 'BillingAdressStep';
        }
      } else {
        this.currentTab = this.getSiblingTab(1);
      }

      this.$loader.end('updateStepData');

    },

    handlePricePointsTabChange(newTab) {
      this.pricePointsTab = newTab;
    },

    handleProjectionPricePointsTabChange(newTab) {
      this.projectionPricePointsTab = newTab;
    },

    handleCheckoutEmbedTypesTabChange(newTab) {
      this.checkoutEmbedType = newTab;
    },

    showPricePointDetails(productId) {
      const selectedProduct = this.products
        .map((item) => item.id)
        .indexOf(productId);
      this.selectedProductPricePoints =
        this.products[selectedProduct].price_points;
      this.$root.$emit('modal-open', 'price-points-modal');
    },

    sendToFunnels() {
      this.$router.push({ name: 'funnels' });
    },

    showCheckoutLinks() {
      this.selectedCheckoutFlow = this.checkoutFlow;
    },

    showTrackingCode() {
      this.selectedTrackingFlow = this.trackingFlow;
    },

    showCheckoutOptions() {
      this.checkoutFlow = 0;
      this.selectedCheckoutFlow = 0;
    },

    showTrackingOptions() {
      this.trackingFlow = 'Tracking Link';
      this.selectedTrackingFlow = 0;
    },

    async openProjectionsModal(productId) {
      this.$loader.start('openProjectionsModal');
      this.selectedProduct = await this.funnel.products().$find(productId);
      this.pricepointsForProjections = await this.selectedProduct
        .pricepoints()
        .$get();

      this.projectionPricePointsTab = `price_point_${this.pricepointsForProjections[0].id}`;
      this.$root.$emit('modal-open', 'projections-modal');
      this.$loader.end('openProjectionsModal');
    },

    async savePricePointProjections() {
      this.$loader.start('savePricePointProjections');

      const promises = [];
      for (let i = 0; i < this.pricepointsForProjections.length; i++) {
        promises.push(this.pricepointsForProjections[i].save());
      }

      const self = this;
      await Promise.all(promises).then((values) => {
        self.$root.$emit('modal-close', 'projections-modal');
        this.$loader.end('savePricePointProjections');
      });
    },

    async openTrackingModal(productId) {
      this.$loader.start('openTrackingModal');
      this.selectedProduct = await this.funnel.products().$find(productId);
      this.proofWidgets = await this.selectedProduct.proof_widgets().$get();
      if (this.proofWidgets.length > 0) {
        this.proofWidgetsTab = `proof_widget_${this.proofWidgets[0].id}`;
      } else {
        this.proofWidgetsTab = '';
      }

      this.$loader.end('openTrackingModal');

      this.trackingFlow = 'Tracking Link';
      this.selectedCheckoutFlow = 0;
      this.$root.$emit('modal-open', 'tracking-modal');
    },

    setLocalProperties() {
      this.model = {
        isEdit: false,
        checkout_links: [],
        name: '',
        skip_field_arr: [],
        force_affiliate: false,
        force_affiliate_id: null,
        ask_for_password_select: 0,
      };
      this.currentTab = 'ConfigStep';
      this.selectedCheckoutStepType = 1;
    },

    async openCheckoutPageLink(id) {
      this.$loader.start('openCheckoutPageLink');
      this.selectedProduct = await Product.$find(this.$route.params.product_id);
      this.multiPricePointLink = await this.selectedProduct.checkoutLinks().$find(id);
      this.checkout_link = this.multiPricePointLink.checkout_link;
      this.checkout_embed = this.multiPricePointLink.checkout_embed;
      this.$root.$emit('modal-open', 'checkout-link-modal');
      this.$loader.end('openCheckoutPageLink');
    },



    async setPricePointEnabledOnMultiCheckout(value, linkid, pricingPointId) {
      this.$loader.start('setPricePointEnabledOnMultiCheckout');
      const selectedCheckoutLink = await this.selectedProduct.checkoutLinks().$find(linkid);
      const objIndex = selectedCheckoutLink.pricing_options.findIndex(
        (obj) => obj.id === pricingPointId
      );
      selectedCheckoutLink.pricing_options[objIndex].enabled = value;
      await selectedCheckoutLink.save();
      this.$loader.end('setPricePointEnabledOnMultiCheckout');
    },

    async generateMultiCheckoutLink() {
      const enabledPricePoints = this.selectedProductPricePoints.filter(
        (pricePoint) => {
          return pricePoint.enabled;
        }
      );

      if (enabledPricePoints.length > 0) {
        this.$loader.start('generateMultiCheckoutLink');
        const enabledPricingOptionsArray = [];

        for (let i = 0; i < enabledPricePoints.length; i += 1) {
          const singleEnabledPricingOption = {
            id: enabledPricePoints[i].id,
            enabled: true,
          };
          enabledPricingOptionsArray.push(singleEnabledPricingOption);
        }

        const newMultiPricePointLink = new CheckoutLink({
          product_id: this.selectedProduct.id,
          price_point_id: 0,
          pricing_options: enabledPricingOptionsArray,
          is_onestep: this.selectedCheckoutStepType,
        });

        const savedCheckoutLinkData = await newMultiPricePointLink.save();
        this.multiPricePointLink = await this.selectedProduct.checkoutLinks().$find(savedCheckoutLinkData.data.id);
        this.$loader.end('generateMultiCheckoutLink');
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_groovesell_swal_select_atleast_one_payment_option'),
        };
      }
    },

    goToMultiCheckoutLinks() {
      this.multiPricePointLink = null;
    },

    checkoutlinksNavigate(navigateMethod, index) {
      this.$refs.checkoutLinksWizard.changeTab(this.checkoutLinksIndex, index);
    },



    onCopyProofWidget() {
      Notify.success(this.$t('frontend_proof_widget_code_copy_to_clipboard'));
    },

    onErrorProofWidget() {
      Notify.fail(this.$t('frontend_unable_copy_proof_widget_code'));
    },
  },
};
</script>
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Amatic+SC:wght@400;700&family=Architects+Daughter&family=Asap:wght@400;700&family=Balsamiq+Sans:wght@400;700&family=Barlow:wght@400;700;900&family=Bebas+Neue&family=Bitter:wght@400;700;900&family=Cabin:wght@400;700&family=Cairo:wght@400;700&family=Cormorant+Garamond:wght@400;700&family=Crimson+Text:wght@400;700&family=Dancing+Script:wght@400;700&family=Fira+Sans:wght@400;700;900&family=Fjalla+One&family=Indie+Flower&family=Josefin+Sans:wght@400;700&family=Lato:wght@400;700;900&family=Libre+Baskerville:wght@400;700&family=Libre+Franklin:wght@400;700;900&family=Lobster&family=Lora:wght@400;700&family=Martel:wght@400;700;900&family=Merriweather:wght@400;700;900&family=Montserrat:wght@400;700;900&family=Mukta:wght@400;700&family=Noto+Sans+JP:wght@400;700&family=Noto+Sans+KR:wght@400;700;900&family=Noto+Sans:wght@400;700&family=Noto+Serif:wght@400;700&family=Nunito+Sans:wght@200;300;400;700;900&family=Nunito:wght@300;400;700;900&family=Old+Standard+TT:wght@400;700&family=Open+Sans+Condensed:wght@300;700&family=Open+Sans:wght@300;400;700&family=Oswald:wght@400;700&family=Overpass:wght@400;700;900&family=Oxygen:wght@300;400;700&family=PT+Sans+Narrow:wght@400;700&family=PT+Sans:wght@400;700&family=PT+Serif:wght@400;700&family=Pacifico&family=Playfair+Display:wght@400;700;900&family=Poppins:ital,wght@0,400;0,700;1,900&family=Raleway:wght@400;700;900&family=Roboto+Condensed:wght@400;700&family=Roboto+Slab:wght@400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,400;0,700;1,900&family=Shadows+Into+Light&family=Signika:wght@400;700&family=Slabo+27px&family=Source+Code+Pro:wght@400;700;900&family=Source+Sans+Pro:wght@400;700;900&family=Source+Serif+Pro:wght@400;700;900&family=Tajawal:wght@400;700;900&family=Titillium+Web:wght@400;700;900&family=Ubuntu:wght@400;700&family=Work+Sans:wght@400;700;900&display=swap');
