<template>
  <div>
    <div class="flex flex-row">
      <div class="flex flex-col w-1/2 bg-white rounded max-h-160">
        <div v-if="pricepoint_err" class="text-red text-sm">
          {{ $t('frontend_price_poit_must_enable') }}
        </div>
        <div v-if="pricePoints.length" class="flex flex-row justify-start pr-2 mb-4">
          <gd-toggle class="mr-2" v-model="enable_all_price_point" :value="enable_all_price_point"
            @change="changeEnableAllPricePoint($event)" />
          <label class="mr-4">{{
            $t('frontend_checkout_links_step3_enable_all_price_points')
          }}</label>
        </div>

        <ul class="w-full mb-2 max-h-full flex flex-col overflow-y-auto" v-if="!enable_all_price_point">
          <li v-for="(pricePoint, index) in pricePoints" :key="index" class="border-grey-300 py-2"
            :class="{ 'border-b': index != pricePoints.length - 1 }">
            <div class="flex flex-row w-full">
              <div class="mr-3 flex flex-row">
                <gd-toggle @change="pricePointChange" v-model="pricePoint.enabled" :value="pricePoint.enabled" />
              </div>
              <div>
                <h6 class="font-semibold text-md">
                  <span v-if="pricePoint.internal_name">
                    {{ pricePoint.internal_name }}
                    <br />
                    {{ '(' + pricePoint.name + ')' }}
                  </span>
                  <span v-else>
                    {{ pricePoint.name }}
                  </span>
                </h6>
                <p class="font-semibold text-sm">
                  {{ pricePoint.description }}
                </p>
                <span class="text-sm">
                  <span v-if="pricePoint.number_of_installments > 0">
                    {{ pricePoint.number_of_installments }}
                    <span class="relative" style="top: -1px;">x</span>
                  </span>
                  {{ localModel.currency_symbol }}{{ pricePoint.price }}</span>
                <span v-if="pricePoint.recurring_interval == 1" class="ml-1 text-sm">{{ $t('frontend_product_weekly')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 2" class="ml-1 text-sm">{{ $t('frontend_every_2_weeks')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 3" class="ml-1 text-sm">{{
                  $t('frontend_product_monthly') }}</span>
                <span v-else-if="pricePoint.recurring_interval == 4" class="ml-1 text-sm">{{ $t('frontend_every_2_months')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 5" class="ml-1 text-sm">{{ $t('frontend_quarterly')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 9" class="ml-1 text-sm">{{ $t('frontend_every_4_months')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 6" class="ml-1 text-sm">{{ $t('frontend_half_yearly')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 7" class="ml-1 text-sm">{{ $t('frontend_product_yearly')
                }}</span>
                <span v-else-if="pricePoint.recurring_interval == 8" class="ml-1 text-sm">{{
                  $t('frontend_product_every_2_years') }}</span>
              </div>
            </div>
          </li>
        </ul>

        <p class="font-semi-bold text-dark mb-5" v-if="showNote">
          <span class="text-red">{{ $t('frontend_note') }}: </span>
          {{ $t('frontend_checkout_links_step3_click_upsell_note') }}
        </p>
      </div>
      <div class="flex flex-col w-1/2 justify-center align-middle items-center" v-if="showRemoveCart">
        <div class="text-center font-bold text-lg">
          {{ $t('frontend_single_price_display') }}
        </div>
        <div class="text-base text-center mb-4">(Example Image)</div>
        <div class="h-128">
          <img v-if="localModel.customization_details.customization_details.show_price_confirmation"
            class="max-w-full h-full" src="@/assets/images/checkout/PriceSelectOn.png" />
          <img v-else class="max-w-full h-full" src="@/assets/images/checkout/PriceSelectOff.png" />
        </div>
        <div class="flex flex-row w-full justify-center my-4">
          <label class="font-semibold text-sm text-grey-700">{{
            $t('frontend_show_price_confirmation')
          }}</label>
          <gd-toggle class="ml-2"
            v-model="localModel.customization_details.customization_details.show_price_confirmation" />
        </div>
      </div>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>

export default {
  name: 'PricePointsStep',
  components: {
  },
  props: {
    pricePoints: {
      required: true,
    },
    product: {
      required: true,
    },
    model: {
      required: true,
    },
  },

  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      localModel: this.model,
      pricepoint_err: false,
      enable_all_price_point: true,
    };
  },

  async created() {

    this.pricePoints.map((value, index) => {
      if (!value.enabled) {
        this.enable_all_price_point = false;
      }
    });
  },

  computed: {
    showNote() {
      if (this.product.checkout_page_options.express_checkout > 0) {
        const enabled = this.pricePoints.filter((value, index) => {
          return value.enabled == true;
        });
        return enabled.length > 1
      }
      return false;

    },

    showRemoveCart() {
      const allEnabled = this.pricePoints.filter((value) => {
        return value.enabled == true;
      });
      return allEnabled.length === 1;
    },
  },

  methods: {
    pricePointChange(value) {
      const allEnabled = this.pricePoints.filter((value, index) => {
        return value.enabled == false;
      });
      this.enable_all_price_point = allEnabled.length > 0 ? false : true;
    },

    changeEnableAllPricePoint(event) {
      this.pricePoints.map((value, index) => {
        return (value.enabled = event);
      });
    },

    handleDesignTabChange(newTab) {
      this.designTab = newTab;
    },

    validate() {
      const isValid = true;
      const enabledArr = [];
      this.pricePoints.map((value, index) => {
        if (value.enabled) {
          enabledArr.push(value.id);
        }
      });

      if (enabledArr.length == 0) {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: 'Oops...',
          message: this.$t('frontend_price_poit_must_enable'),
        };
        return;
      }

      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
