<template>
  <div>
    <gd-button variant="outline" size="md" class="my-2" @click="editDomains">
      {{ $t('groovesell_vendor_settings_goto_domains') }}
    </gd-button>
  </div>
</template>

<script>
export default {
  methods: {
    editDomains() {
      this.$root.$emit('open-account-modal', this.$t('frontend_sites_account_modal_domains'));
    },
  },
}
</script>
