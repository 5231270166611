<template>
  <div class="flex flex-col justify-center">
    <div
      class="border w-full mr-6 bg-white rounded-lg p-6 hover:cursor-pointer border-solid border-grey-300"
      :class="{
        'bg-primary-lightest shadow-outline-blue': activeOption === optionName
      }"
      tabindex="0"
      @click="updateActiveOption"
      @keydown.space="updateActiveOption"
    >
      <div class="flex justify-center items-center mb-3">
        <feather-icon
          :icon="optionIcon"
          class="icon"
          svg-classes="checkoutRadioIcon"
        />
      </div>

      <div class="mb-1 font-semibold text-center">
        <p class="text-md">{{ optionName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutRadio',
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption'
  },
  props: ['optionName', 'optionIcon', 'activeOption'],
  methods: {
    updateActiveOption() {
      this.$emit('onUpdateOption', this.optionName);
    }
  }
};
</script>

<style>
.checkoutRadioIcon {
  width: 80px;
  height: 80px;
}
</style>
