<template>
  <div v-if="hasBlogReviews" class="flex flex-col">
    <!-- Blog Reviews Tabs -->
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="blogReviewsTabs"
        :current-tab="blogReviewsTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="$emit('handleBlogReviewsTabChange', $event)"
      />
    </div>

    <!-- Blog Reviews Content -->
    <div class="mt-2">
      <div v-for="(review, index) in funnelResource.blog_reviews" :key="index">
        <div v-if="isCurrentBlogReviewTab(review)" class="pb-8">
          <!-- Title -->
          <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_title') }}</label>
            <text-input
              v-model="review.title"
              :readonly="true"
              value="review.title"
              :placeholder="$t('frontend_title')"
            />
          </div>

          <!-- HTML Body -->
          <div class="flex flex-col w-full mb-6">
            <div class="flex flex-row w-1/2">
              <div class="w-1/2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_promo_post') }}
                </label>
              </div>
              <div class="flex flex-row justify-end mb-2 w-1/2">
                <div class="flex flex-row">
                  <div class="font-semibold text-sm text-grey-700 mr-2">
                    {{ $t('frontend_affiliate_promo_view_html') }}
                  </div>
                  <gd-toggle
                    v-model="isBlogReviewsPageHTMLView"
                    :value="isBlogReviewsPageHTMLView"
                    @change="updateCurrentShownBlogReviewsQuillEditorIndex(-1)"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-1/2">
                <text-area
                  v-if="isBlogReviewsPageHTMLView"
                  v-model="review.htmlBody"
                  class="swipesEditor w-full"
                  :placeholder="$t('frontend_affiliate_promo_post')"
                  variant="dark"
                  :readonly="true"
                />
                <quill-editor
                  v-else
                  :ref="'reviewsEditor_' + index"
                  v-model="review.htmlBody"
                  :options="reviewsEditorOptions"
                  class="swipesEditor w-full"
                  :disabled="true"
                  @ready="updateCurrentShownBlogReviewsQuillEditorIndex(index)"
                ></quill-editor>
              </div>
              <div class="flex items-center content-center w-1/4">
                <div class="ml-2">
                  <gd-button
                    v-if="isBlogReviewsPageHTMLView"
                    v-clipboard:copy="review.htmlBody"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    size="icon"
                    variant="primary"
                    :title="$t('frontend_affiliate_promo_copy_swipe')"
                  >
                    <copy-icon class="w-5 h-5 fill-current" />
                  </gd-button>
                  <div v-else-if="shouldShowQuillCopyButton(index)">
                    <gd-button
                      v-clipboard:copy="getRichTextFromBlogReviewsEditor(index)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      size="icon"
                      variant="primary"
                      :title="$t('frontend_affiliate_promo_copy_swipe')"
                    >
                      <copy-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>
              </div>
              <div class="w-1/4 flex flex-col text-right"></div>
            </div>
          </div>

          <!-- Text Body -->
          <div class="flex flex-col w-full mt-32 mb-6">
            <div class="flex flex-row w-1/2">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_affiliate_promo_text_view') }}
              </label>
            </div>
            <div class="flex flex-row w-full">
              <div class="w-1/2">
                <textarea
                  v-model="review.textBody"
                  value="review.textBody"
                  :placeholder="$t('frontend_affiliate_promo_text_body')"
                  class="swipesEditor w-full resize-y border rounded border-grey-200 p-2"
                  @click="handleTextareaClick"
                  readonly="readonly"
                ></textarea>
              </div>
              <div class="flex items-center content-center w-1/4">
                <div class="ml-2">
                  <gd-button
                    v-clipboard:copy="review.textBody"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    size="icon"
                    variant="primary"
                    :title="$t('frontend_affiliate_promo_copy_blog_review')"
                  >
                    <copy-icon class="w-5 h-5 fill-current" />
                  </gd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('frontend_affiliate_promo_no_blog_reviews') }}
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    quillEditor,
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      default: () => {},
    },
    blogReviewsTab: {
      type: String,
      default: '',
    },
    currentShownBlogReviewsQuillEditorIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isBlogReviewsPageHTMLView: false,
      reviewsEditorOptions: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    hasBlogReviews() {
      return this.funnelResource &&
        this.funnelResource.blog_reviews &&
        this.funnelResource.blog_reviews.length;
    },
    blogReviewsTabs() {
      return this.funnelResource.blog_reviews?.map(review => ({
        title: review.name,
        value: `blog_review_tab_${review.id}`,
      })) || [];
    },
  },
  methods: {
    updateCurrentShownBlogReviewsQuillEditorIndex(index) {
      this.$emit('updateCurrentShownBlogReviewsQuillEditorIndex', index);
    },
    getRichTextFromBlogReviewsEditor(index) {
      return this.$refs[`reviewsEditor_${index}`]?.[0]?.quill?.getText() || '';
    },
    isCurrentBlogReviewTab(review) {
      return this.blogReviewsTab === `blog_review_tab_${review.id}`;
    },
    shouldShowQuillCopyButton(index) {
      return this.currentShownBlogReviewsQuillEditorIndex == index &&
        !this.isBlogReviewsPageHTMLView;
    },
    handleTextareaClick(event) {
      event.target.focus();
      event.target.select();
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
