<template>
  <base-layout :menu-items="menuItems">
    <template slot="content">
        <div class="w-full h-full" :class="{ 'pb-5 pt-3 pl-6 pr-4': hasPadding }">
          <div
            :class="{ 'container mx-auto': !fullWidth, 'h-full': fullHeight }"
          >
            <transition :name="routerTransition">
              <router-view :key="$route.fullPath"></router-view>
            </transition>
          </div>
        </div>
    </template>
    <template slot="header-bar">
      <header-bar
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        :route-title="routeTitle"
      ></header-bar>
    </template>
    <template v-if="hasSidebar" slot="sidebar">
      <sidebar-menu v-if="sidebar == 'default'"></sidebar-menu>
      <sidebar-affiliate-menu
        v-if="sidebar == 'affiliates'"
      ></sidebar-affiliate-menu>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@groovepages/gd-ui-kit/components/BaseLayout';

export default {
  components: {
    BaseLayout
  },
  props: {
    sidebar: {
      type: String,
      default: 'default'
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExternal: true,
      showSlider: false,
      routerTransition: 'zoom-fade',
      routeTitle: this.$route.meta.pageTitle,
      isAdmin: null
    };
  },
  computed: {
    menuItems() {
      return [];
    },
    hasSidebar() {
      return this.sidebar !== 'none';
    }
  },

  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    }
  },
  async created() {
    this.isAdmin = this.$store.state.user.admin;
  },

  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    }
  }
};
</script>

<style scoped>
.modified-scrollbars {
  height: calc(100vh - 4rem) !important;
  scrollbar-color: #999 #eee;
  scrollbar-width: thin;
}
</style>
