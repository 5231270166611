<template>
  <div>
    <MarketPlace />
  </div>
</template>

<script>
import MarketPlace from '@/components/marketplace/MarketPlace.vue';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Marketplace' };
  },
  components: {
    MarketPlace,
  },
  data() {
    return {
    };
  },
};
</script>
