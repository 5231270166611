<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step7_funnel') }}&nbsp;{{
            $t('frontend_product_step7_post_transactions')
          }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step7_configure')
        }}</span>
      </div>
      <hr class="border-grey-100 -mx-4" />
      <div class="mt-6">
        <div class="flex flex-col w-1/2 mb-6 bg-grey-100 p-1">
          <label class="font-semibold text-sm text-grey-700">
            <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
            {{ $t('frontend_product_step7_note') }}
          </label>
        </div>
        <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index" class="mb-12">
          <div class="flex flex-col w-1/2 mb-1">
            <label class="font-semibold text-md text-grey-700 mb-2">{{
              $t('frontend_product_step7_price_point')
            }}</label>
            <label class="text-sm text-grey-700 mb-2">
              {{ pricePoint.name }}
            </label>
          </div>
          <div class="flex flex-row w-1/2 mb-4">
            <div class="flex flex-col w-1/2 mr-2">
              <label class="text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step7_type')
              }}</label>
              <gd-dropdown
                v-model="pricePoint.post_transaction_details.type"
                size="full"
                :options="typeOptions"
              />
            </div>
            <div v-if="pricePoint.post_transaction_details.type == 1" class="flex flex-col w-full ml-4">
              <label class="text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step7_thankyou_page')
              }}</label>
              <label class="text-md text-grey-700 mb-2 mt-2">
                {{ pricePoint.post_checkout_details.thankyou_page }}
              </label>
            </div>
            <div v-if="pricePoint.post_transaction_details.type == 2" class="flex flex-col w-1/2">
              <label class="text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step7_upsell_url')
              }}</label>
              <text-input v-model="pricePoint.post_transaction_details.post_transaction_url
                  " :validator="$v.localModel.pricing_points.$each[index]
        .post_transaction_details.post_transaction_url
      " :attribute="$t('frontend_product_step7_post_transaction_url')"
                value="pricePoint.post_transaction_details.post_transaction_url"
                :placeholder="$t('frontend_product_step7_post_transaction_url')" />
            </div>
          </div>

          <div class="flex flex-row w-1/2 mb-2 items-center">
            <label class="font-semibold text-sm text-grey-700 mr-2">{{
              $t('frontend_product_step7_post_enable_advance')
            }}</label>
            <gd-toggle :value="pricePoint.advanced_tracking_code_enabled" @change="setIsAdvancedTrackingCode(index)" />
          </div>

          <div v-if="pricePoint.advanced_tracking_code_enabled == 1" class="flex flex-col w-full mb-2">
            <div class="flex flex-row w-1/2 mb-2">
              <text-area v-model="pricePoint.advanced_tracking_code_details.tracking_script" value="pricePoint.advanced_tracking_code_details.tracking_script" :placeholder="$t('frontend_product_step7_post_advance_tracking')" />
            </div>
          </div>

          <div class="flex flex-row w-1/2 mb-2 items-center">
            <label class="font-semibold text-sm text-grey-700 mr-2">{{
              $t('frontend_product_step7_post_enable_facebook_conversion_api')
            }}</label>
            <!-- {{pricePoint.facebook_conversion_api_enabled}} -->
            <gd-toggle :value="pricePoint.facebook_conversion_api_enabled"
              @change="setIsFacebookConversionApiEnabled(index)" />
          </div>

          <div v-if="pricePoint.facebook_conversion_api_enabled" class="flex flex-row w-1/2 mb-4">
            <div class="flex flex-col w-1/2 mr-2">
              <label class="text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step7_type')
              }}</label>
                <gd-dropdown
                  v-model="pricePoint.pixel_id"
                  size="full"
                  :options="facebookConversionListOptions"
                />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, requiredIf, url } from 'vuelidate/lib/validators';
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
  name: 'Step7',
  components: {
    InfoIcon,
  },
  props: {
    model: {
      required: true,
    },
    facebookCoversionList: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
      facebook_conversion_list: this.facebookCoversionList,
      postSaleTabs: [
        { title: this.$t('frontend_product_step7_post_transactions'), value: 'postTransactionURL' },
        { title: this.$t('frontend_product_step8_content_access'), value: 'contentAccess' },
      ],
      postSaleTab: 'postTransactionURL',
      pricePointsTab: '',
    };
  },
  validations: {
    localModel: {
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          post_transaction_details: {
            post_transaction_url: {
              required: requiredIf((object) => {
                return object.type == 2;
              }),
              url,
            },
          },
          post_sale_details: {
            $each: {
              link: {
                url,
                required,
              },
            },
          },
        },
      },
    },
  },
  computed: {
    pricePointsTabs() {
      const pricePointTabs = [];

      for (let i = 0; i < this.localModel.pricing_points.length; i++) {
        pricePointTabs.push({
          title: this.localModel.pricing_points[i].name,
          value: `price_point_${this.localModel.pricing_points[i].id}`,
        });
      }

      return pricePointTabs;
    },
    typeOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step7_thankyou_page') },
        { value: 2, text: this.$t('frontend_product_step7_upsell_page') },
      ];
    },
    facebookConversionListOptions() {
      return [
        { value: 0, text: this.$t('frontend_groovesell_edit_product_step7_select_pixel') },
        ...this.facebook_conversion_list.map((x) => ({
          value: x.id,
          text: x.name,
        })),
      ];
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },

  created() {
    console.log(`facebook_conversion_list ${JSON.stringify(this.facebook_conversion_list)}`);
  },

  mounted() {
    if (
      this.pricePointsTab == '' &&
      this.localModel.pricing_points.length > 0
    ) {
      this.pricePointsTab =
        `price_point_${this.localModel.pricing_points[0].id}`;
    }
  },
  methods: {
    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(',');
      return (
        `#${(
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)}`
      );
    },

    setIsAdvancedTrackingCode(index) {
      this.localModel.pricing_points[
        index
      ].advanced_tracking_code_enabled = !this.localModel.pricing_points[index]
        .advanced_tracking_code_enabled;
    },

    setIsFacebookConversionApiEnabled(index) {
      // console.log('setIsFacebookConversionApiEnabled', this.localModel.pricing_points );
      this.localModel.pricing_points[index].facebook_conversion_api_enabled = !this.localModel.pricing_points[index].facebook_conversion_api_enabled;
    },

    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    handlePostSaleTabChange(newTab) {
      this.postSaleTab = newTab;
    },

    handlePricePointsTabChange(newTab) {
      this.pricePointsTab = newTab;
    },
  },
};
</script>
