<template>
  <gd-modal name="vendor-detail-modal" :title="$t('frontend_groovesell_vendor_details')" width="80%" bg-type="regular">
    <div v-if="selectedVendor" style="min-height: 60vh;">
      <gd-tabs
        :tabs="vendorDetailTabs"
        :current-tab="vendorDetailsTab"
        :wrapper-class="'pt-4 px-4 gs-default-tabs'"
        :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
        :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
        :line-class="'gs-default-tabs-active-line'"
        @onClick="handleVendorsDetailTabChange"
      />
      <div v-if="vendorDetailsTab === 'info'">
        <div class="flex flex-row">
          <div class="flex flex-col w-3/4 px-4">
            <div class="mt-2">
              <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_name') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.name }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_email') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.email }}
                  </label>
                </div>
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_paypal_email') }}</label>
                  <label v-if="selectedVendor.affiliate_paypal" class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.affiliate_paypal}}
                  </label>
                  <label v-else class="text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_na') }}</label>
                </div>

                <div class="w-full flex flex-row">
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_username') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.username }}
                    </label>
                  </div>
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_impressions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.impressions }}
                    </label>
                  </div>
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_uniques') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.uniques }}
                    </label>
                  </div>
                </div>

                <div class="w-full flex flex-row">
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_sales') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.total_sales }}
                    </label>
                  </div>
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_refunds') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.total_refunds }}
                    </label>
                  </div>
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_earned_commisions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                      {{ selectedVendor.human_readable_total_commissions }}
                    </label>
                  </div>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_unpaid_commsions') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_pending_commission }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_advance_clawback') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_advances }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_duecommsions') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_processed_without_advances }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_commision_onhold') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_on_hold_commission }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_being_processed') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_in_process_commission }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_amount_paid') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_paid_commission }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
            <div class="my-auto flex flex-col"></div>
          </div>
        </div>
      </div>
      <div v-else-if="vendorDetailsTab === 'lifetime'">
        <div class="flex flex-row">
          <div class="flex flex-col w-3/4 px-4">
            <div class="mt-2">
              <div class="w-full flex flex-wrap">
                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_alltime_earned') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_alltime_earned_commission }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_alltime_paid') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_paid_commission }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_alltime_cash') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_commission_advance }}
                  </label>
                </div>

                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_vendors_alltime_clawbacks') }}</label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedVendor.human_readable_clawbacks }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="vendorDetailsTab === 'transactions'">
        <div v-if="selectedVendor.transactions && selectedVendor.transactions.length > 0">
          <div id="affiliate-transactions-list-view" class="flex flex-row">
            <v-client-table
              :data="selectedVendor.transactions"
              :columns="affiliatesTransactionsColumns"
              :options="affiliatesTransactionsTableOptions"
            />
          </div>
          <div class="mt-4 px-6 flex justify-end text-blue">
            <router-link
              :to="{
                name: 'affiliate_transactions',
                query: {
                  vendor_id: selectedVendor.id,
                  date_range: 'all-time',
                  transaction_type: 'all'
                }
              }"
              target="_blank"
            >
              {{ $t('frontend_vendors_seefull') }}
            </router-link>
          </div>
        </div>
        <div v-else class="p-4">
          {{ $t('frontend_vendors_no_transaction_recorded') }}
        </div>
      </div>
      <div v-else-if="vendorDetailsTab === 'refunds'">
        <div v-if="selectedVendor.refunds && selectedVendor.refunds.length > 0">
          <div id="affiliate-refunds-list-view" class="flex flex-row">
            <v-client-table
              :data="selectedVendor.refunds"
              :columns="affiliatesRefundsColumns"
              :options="affiliatesRefundsTableOptions"
            />
          </div>
          <div class="mt-4 px-6 flex justify-end text-blue">
            <router-link
              :to="{
                name: 'affiliate_transactions',
                query: {
                  vendor_id: selectedVendor.id,
                  date_range: 'all-time',
                  transaction_type: 'refund'
                }
              }"
              target="_blank"
            >
              {{ $t('frontend_vendors_see_full_refund') }}
            </router-link>
          </div>
        </div>
        <div v-else class="p-4">
          {{ $t('frontend_vendors_no_refund_recorded') }}
        </div>
      </div>

      <div v-else-if="vendorDetailsTab === 'links'">
        <div v-if="selectedVendor.registered_funnels && selectedVendor.registered_funnels.length > 0">
          <div id="affiliate-links-list-view" class="flex flex-row">
            <v-client-table
              :data="selectedVendor.registered_funnels"
              :columns="affiliatesLinksColumns"
              :options="affiliatesLinksTableOptions"
            >
              <div slot="links" slot-scope="props">
                <gd-button
                  variant="outline"
                  size="icon"
                  class="mr-2"
                  :title="$t('frontend_vendors_links')"
                  @click="$emit('fetchAffiliateLinks', props.row.id)"
                >
                  <link-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </v-client-table>
          </div>
        </div>
        <div v-else class="p-4">
          {{ $t('frontend_vendors_no_funnels_werefound') }}
        </div>
      </div>

      <div v-else-if="vendorDetailsTab === 'history'">
        <div
          v-if="selectedVendor.payment_bonus_history && selectedVendor.payment_bonus_history.length > 0"
          id="affiliate-payments-list-view"
          class="flex flex-row"
        >
          <v-client-table
            :data="selectedVendor.payment_bonus_history"
            :columns="affiliatesPaymentsColumns"
            :options="affiliatesPaymentsTableOptions"
          >
            <div slot="note" slot-scope="props" class="inline-block">
              <div v-if="props.row.note && props.row.note != ''" v-tooltip="props.row.note">
                <comment-icon class="w-5 h-5 fill-current" />
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="p-4">
          {{ $t('frontend_vendors_no_payments') }}
        </div>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import Vendor from '@/models/Vendor';
import LinkIcon from '@/assets/images/icons/LinkIcon.svg';
import CommentIcon from '@/assets/images/icons/CommentIcon.svg';

export default {
  components: {
    LinkIcon,
    CommentIcon
  },
  data() {
    return {
      selectedVendor: null,
      vendorDetailsTab: 'info',
      vendorDetailTabs: [
        {title: this.$t('frontend_vendors_affiliate'), value: 'info'},
        {title: this.$t('frontend_vendors_lifetime_commisions'), value: 'lifetime'},
        {title: this.$t('frontend_vendors_transactions'), value: 'transactions'},
        {title: this.$t('frontend_vendors_refund'), value: 'refunds'},
        {title: this.$t('frontend_vendors_links'), value: 'links'},
        {title: this.$t('frontend_vendors_payment_history'), value: 'history'}
      ],
      affiliatesTransactionsColumns: [
        'buyer_fullname',
        'buyer_email',
        'buyer_ip',
        'transaction_type',
        'commission_status',
        'formatted_date_time'
      ],
      affiliatesTransactionsTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          buyer_fullname: this.$t('frontend_name'),
          buyer_email: this.$t('frontend_groovesell_email'),
          buyer_ip: this.$t('frontend_ip_address'),
          transaction_type: this.$t('frontend_type'),
          commission_status: this.$t('frontend_marketplace_commission')
        },
        perPage: 5,
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: ''
        }
      },
      affiliatesRefundsColumns: [
        'buyer_fullname',
        'buyer_email',
        'buyer_ip',
        'transaction_type',
        'commission_status',
        'formatted_date_time'
      ],
      affiliatesRefundsTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          buyer_fullname: this.$t('frontend_name'),
          buyer_email: this.$t('frontend_groovesell_email'),
          buyer_ip: this.$t('frontend_ip_address'),
          transaction_type: this.$t('frontend_type'),
          commission_status: this.$t('frontend_marketplace_commission')
        },
        perPage: 5,
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: ''
        }
      },
      affiliatesLinksColumns: ['name', 'links'],
      affiliatesLinksTableOptions: {
        headings: {},
        perPage: 5,
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: ''
        }
      },
      affiliatesPaymentsColumns: ['readable_type', 'human_readable_amount', 'formatted_date_time', 'note'],
      affiliatesPaymentsTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          readable_type: this.$t('frontend_type'),
          human_readable_amount: this.$t('frontend_amount')
        },
        perPage: 5,
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: ''
        }
      },
    }
  },
  methods: {
    handleVendorsDetailTabChange(newTab) {
      this.vendorDetailsTab = newTab;
    },
    async openVendorDetail(vendorId) {
      this.$loader.start('openVendorDetail');
      this.selectedVendor = await Vendor.params({
        funnels: this.selectedFunnel
      }).$find(vendorId);
      this.vendorDetailsTab = 'info';
      this.$root.$emit('modal-open', 'vendor-detail-modal');
      this.$loader.end('openVendorDetail');
    },
    async openSalesDetail(affiliateId) {
      this.$loader.start('openSalesDetail');
      this.selectedVendor = await Vendor.params({
        funnels: this.selectedFunnel
      }).$find(affiliateId);
      this.vendorDetailsTab = 'transactions';
      this.$root.$emit('modal-open', 'vendor-detail-modal');
      this.$loader.end('openSalesDetail');
    },
    async openRefundsDetail(affiliateId) {
      this.$loader.start('openRefundsDetail');
      this.selectedVendor = await Vendor.params({
        funnels: this.selectedFunnel
      }).$find(affiliateId);
      this.vendorDetailsTab = 'refunds';
      this.$root.$emit('modal-open', 'vendor-detail-modal');
      this.$loader.end('openRefundsDetail');
    },
  },
}
</script>
