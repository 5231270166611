<template>
  <div>
    <div class="flex flex-row mt-2 w-full">
      <div class="flex flex-row w-full justify-between">
        <div class="flex flex-col w-4/12 pr-6">
          <div class="flex w-96">
            <div class="w-full transactionsFilterContainer">
              <treeselect
                v-model="localModel.filteredTransactions"
                :multiple="true"
                :options="transactionFilters"
                :max-height="500"
                :placeholder="$t('frontend_groovesell_transactions_filter')"
                @select="onTransactionFilterChange"
                @deselect="onTransactionFilterChange"
              />
            </div>
          </div>

          <div class="flex space-x-4 items-center mt-4">
            <date-range-picker
              ref="picker"
              v-model="localModel.transactionsDateRange"
              :opens="opens"
              :auto-apply="autoApply"
              :start-date="customStartDate"
              :end-date="customEndDate"
              :ranges="customDateRanges"
              @update="updateModel"
            >
              <div slot="input" slot-scope="picker">
                {{ picker.startDate | moment('MM/DD/YYYY') }} -
                {{ picker.endDate | moment('MM/DD/YYYY') }}
              </div>
            </date-range-picker>
            <gd-dropdown
              v-model="localModel.selectedRowSize"
              size="small"
              :options="pageSizeOptions"
              @change="updateModel"
            />
          </div>
          <div class="flex mt-4 justify-start">
            <gd-button
              size="auto"
              class="mr-4"
              :class="{'animated bounce': !defaultFilters || applyTriggered}"
              :variant="defaultFilters && !applyTriggered ? 'base' : 'primary'"
              :disabled="defaultFilters && !applyTriggered"
              @click="applyFilters"
            >
              <span class="mr-2">
                <filter-icon class="w-5 h-5 fill-current" />
              </span>
              {{ $t('frontend_apply_filter') }}
            </gd-button>
            <gd-button
              v-if="!defaultFilters && applyTriggered"
              variant="outline"
              size="auto"
              class="mr-4"
              @click="clearFilters"
            >
              <span class="mr-2">
                <filter-icon class="w-5 h-5 fill-current" />
              </span>
              {{ $t('frontend_reset') }}
            </gd-button>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex flex-row space-x-2">
            <text-input
              v-model="localModel.searchPhrase"
              value="searchPhrase"
              :placeholder="$t('frontend_search')"
              :debounce-events="['input', 'keydown']"
              @input="updateModel"
            />
            <gd-button
              variant="primary"
              size="expand"
              class="mr-4"
              @click="openAdvancedFilters"
            >
              <span class="mr-2">
                <filter-icon class="w-5 h-5 fill-current" />
              </span>
              {{ $t('frontend_groovesell_modal_advanced_filters') }}
            </gd-button>
          </div>
          <div class="flex mt-4 justify-end">
            <gd-dropdown
              :placeholder="$t('frontend_affiliate_transactions_export')"
              :actions="exportActions"
            />
          </div>
          <div class="mt-4 flex justify-end">
            <div class="mr-2">{{ $t('frontend_groovesell_exclude_free_transactions') }}</div>
            <gd-toggle
              v-model="localModel.excludeFreeTransactions"
              dark
              :value="localModel.excludeFreeTransactions"
              @change="updateModel"
            />
          </div>
        </div>
      </div>
    </div>

    <advanced-filters-modal
      :model="localModel"
      :funnelsDropdown="funnelsDropdown"
      @updateModel="localModel = $event"
      @applyFilters="applyFilters"
    />
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';
import AdvancedFiltersModal from './AdvancedFiltersModal.vue';

export default {
  components: {
    Treeselect,
    FilterIcon,
    ResetIcon,
    AdvancedFiltersModal,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    transactionFilters: {
      type: Array,
      default: () => []
    },
    selectedTransactionFilters: {
      type: Array,
      default: () => [0]
    },
    funnelsDropdown: {
      type: Array,
      default: () => []
    },
    gridApi: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      applyTriggered: false,
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      localModel: this.model,
      opens: 'right',
      autoApply: true,
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()]
      },
    };
  },
  computed: {
    defaultFilters() {
      return this.localModel.filteredTransactions.length == 1 &&
        this.localModel.filteredTransactions[0] == 0 &&
        this.localModel.searchPhrase == '' &&
        this.localModel.filteredFunnels.length == 0 &&
        this.localModel.filteredAdvancedTransactions == 0 &&
        this.localModel.filteredAffiliates.length == 1 &&
        this.localModel.filteredAffiliates[0].id == 0 &&
        !this.localModel.excludeFreeTransactions &&
        this.localModel.selectedRowSize == 25 &&
        this.isDefaultDateRange(this.localModel.transactionsDateRange.startDate) &&
        this.isDefaultDateRange(this.localModel.transactionsDateRange.endDate);
    },
    exportActions() {
      return [
        {
          text: this.$t('frontend_affiliate_transactions_export_all'),
          callback: () => this.exportData()
      },
        {
          text: this.$t('frontend_affiliate_transactions_export_this_page'),
          callback: () => this.exportThisPageData()
        }
      ];
    },
    pageSizeOptions() {
      return [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ];
    },
  },
  methods: {
    isDefaultDateRange(dateString) {
      return moment(dateString).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
    },
    onTransactionFilterChange() {
      this.$nextTick(() => {
        const { filteredTransactions } = this.localModel;

        if (filteredTransactions && filteredTransactions.length > 0) {
          const lastAddedTransactionFilterId = filteredTransactions[filteredTransactions.length - 1];

          switch (lastAddedTransactionFilterId) {
            case 0:
              this.localModel.filteredTransactions = [lastAddedTransactionFilterId];
              break;
            case 8:
              this.localModel.filteredTransactions = [1, 2, 3];
              break;
            case 9:
              this.localModel.filteredTransactions = [4, 5];
              break;
            default:
              this.localModel.filteredTransactions = filteredTransactions.filter(obj => obj !== 0);
              break;
          }
        } else {
          this.localModel.filteredTransactions = [0];
        }
      });

      this.updateModel();
    },
    clearFilters() {
      this.applyTriggered = false;
      this.localModel.filteredTransactions = [0];
      this.localModel.searchPhrase = '';
      this.localModel.filteredFunnels = [];
      this.localModel.filteredAdvancedTransactions = 0;
      this.localModel.filteredAffiliates = [{ id: 0, name: 'All' }];
      this.localModel.excludeFreeTransactions = false;
      this.localModel.selectedRowSize = 25;
      this.localModel.transactionsDateRange = {
        startDate: moment(),
        endDate: moment()
      };

      this.updateModel();
      this.$emit('applyFilters');
    },
    updateModel() {
      this.$emit('updateModel', this.localModel);
    },
    applyFilters() {
      this.applyTriggered = true;
      this.$emit('applyFilters');
    },
    async exportData() {
      this.$loader.start('exportData');

      await axios.post('/generate-export-link-customer-report',
        {
          funnels: this.localModel.filteredFunnels,
          transactions: this.selectedTransactionFilters,
          advancedTransactions: this.localModel.filteredAdvancedTransactions,
          search: this.localModel.searchPhrase,
          excludeFreeTransactions: this.localModel.excludeFreeTransactions,
          affiliates: this.localModel.filteredAffiliates,
          fromDate: moment(this.localModel.transactionsDateRange.startDate).format('MM/DD/YYYY'),
          toDate: moment(this.localModel.transactionsDateRange.endDate).format('MM/DD/YYYY')
        }
      );

      this.$loader.end('exportData');
      this.showExportSuccessMessage();
    },
    async exportThisPageData() {
      this.$loader.start('exportThisPageData');

      await axios.post('/generate-export-link-customer-report',
        {
          funnels: this.localModel.filteredFunnels,
          transactions: this.selectedTransactionFilters,
          advancedTransactions: this.localModel.filteredAdvancedTransactions,
          search: this.localModel.searchPhrase,
          excludeFreeTransactions: this.localModel.excludeFreeTransactions,
          affiliates: this.localModel.filteredAffiliates,
          fromDate: moment(this.localModel.transactionsDateRange.startDate).format('MM/DD/YYYY'),
          toDate: moment(this.localModel.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          page: this.gridApi.paginationGetCurrentPage() + 1,
          limit: this.gridApi.paginationGetPageSize()
        }
      );

      this.$loader.end('exportThisPageData');
      this.showExportSuccessMessage();
    },
    showExportSuccessMessage() {
      this.alertProps = {
        show: true,
        variant: 'success',
        title: this.$t('frontend_groovesell_export_data'),
        message: this.$t('frontend_groovesell_transaction_message'),
      };
    },
    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value-item {
  background-color: var(--brand-primary) !important;
  color: #fff !important;
}

.vue-treeselect__value-remove {
  border: none !important;
}

.vue-treeselect__multi-value-item .vue-treeselect__value-remove {
  color: #266d4d !important;
}

.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
  color: #fff !important;
}

.vue-treeselect__multi-value-label {
  font-size: 14px !important;
}

.vue-treeselect__multi-value-item {
  border-radius: 5px !important;
}
.transactionsFilterContainer .multiselect__content-wrapper {
  max-height: 550px !important;
}
</style>
