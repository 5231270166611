<template>
  <div class="w-full flex flex-row ml-4">
    <portal-target name="headerBreadCrumb" class="flex"> </portal-target>
    <div class="flex flex-grow justify-between">
      <div class="flex text-sm font-semibold">
        <span class="pr-4 flex items-center">{{ routeTitle }}</span>
      </div>
    </div>
    <div class="flex items-center text-sm px-4 font-semibold">
      <label class="mr-1">{{ $t('frontend_groovesell_headerbar_demo_mode') }}</label>
      <info-icon class="mr-4 cursor-pointer w-4 h-4 text-grey-500 fill-current" 
        v-tooltip="{
          classes: 'w-96',
          content: this.$t('frontend_groovesell_headerbar_demo_mode_tooltip'),
        }" />
      <gd-toggle v-model="toggle" :value="toggle" @change="changeDemoMode" />
    </div>
    <portal-target name="headerContent"> </portal-target>
  </div>
</template>
<script>
import Vuex, { mapState } from 'vuex';
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
  data() {
    return {
      toggle: this.$store.getters['internal/isDemoMode'],
    };
  },
  components: {
    InfoIcon,
  },
  methods: {
    async changeDemoMode() {
      await this.$store.dispatch('internal/changeDemoMode', this.toggle);
      window.location.reload();
    },
  },
  name: 'HeaderBar',
  props: ['routeTitle'],
};
</script>
