const globalMiddleware = [];

function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {}
    );
}

const routeMiddleware = resolveMiddleware(
  require.context('@/middleware', false, /.*\.js$/)
);

function getMiddleware(components) {
  const middleware = [...globalMiddleware];

  components
    .filter(c => c.middleware)
    .forEach(component => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware);
      } else {
        middleware.push(component.middleware);
      }
    });

  return middleware;
}

function callMiddleware(middleware, to, from, router, next) {
  const stack = middleware.reverse();
  const nextMiddleware = (...args) => {
    // Stop if "nextMiddleware" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      if (args.length > 0) {
        router.app.$loading.finish();
      }
      return next(...args);
    }

    const currentMiddleware = stack.pop();

    if (typeof currentMiddleware === 'function') {
      return middleware(to, from, nextMiddleware);
    }
    if (routeMiddleware[currentMiddleware]) {
      return routeMiddleware[currentMiddleware](to, from, nextMiddleware);
    }
    throw Error(`Undefined middleware [${currentMiddleware}]`);
  };
  nextMiddleware();
}

export { getMiddleware, callMiddleware };
