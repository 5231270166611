<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <!-- Name Input -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_name') }}
        </label>
        <text-input 
          v-model="localModel.name" 
          :validator="$v.localModel.name" 
          :attribute="$t('frontend_bumps_name')" 
          value="localModel.name" 
          :placeholder="$t('frontend_bumps_name')"
        />
      </div>

      <!-- Internal Name Input -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_internal_name') }}
        </label>
        <text-input 
          v-model="localModel.internal_name" 
          :validator="$v.localModel.internal_name" 
          :attribute="$t('frontend_bumps_internal_name')" 
          value="localModel.internal_name" 
          :placeholder="$t('frontend_bumps_internal_name')"
        />
      </div>

      <!-- Description Text Area -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_description') }}
        </label>
        <text-area 
          v-model="localModel.description" 
          :validator="$v.localModel.description" 
          :attribute="$t('frontend_desc')" 
          value="localModel.description" 
          :placeholder="$t('frontend_desc')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'InfoTab',
  components: {
  },
  props: {
    model: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
