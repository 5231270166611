<template>
  <div class="flex flex-row w-full">
    <div class="flex flex-col w-full mb-6">
      <div class="flex flex-row mb-3" v-if="!showSearch">
        <label class="mr-4 mt-2 text-bold">{{ this.labelName }}</label>
        <vue-autosuggest
          ref="autosuggest"
          v-model="query"
          :suggestions="suggestions"
          :input-props="this.inputProps"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          @selected="selectHandler"
          @input="fetchResults"
        >
          <template slot-scope="{ suggestion }">
            <div>
              <b>{{ suggestion.item.name }}</b>
            </div>
          </template>
        </vue-autosuggest>
      </div>

      <div class="flex flex-row mb-6" v-if="showSearch">
        <div v-if="affiliate_ids.length != 0">
          <span class="text-bold"
            >{{ $t('frontend_analytics_affiliate') }} :
          </span>
          {{ this.affiliate_name }}
        </div>
        <gd-button variant="primary" size="icon" class="ml-2" @click="openEdit()">
          <pencil-icon class="w-5 h-5 fill-current" />
        </gd-button>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="ml-2 flex flex-col items-center align-center justify-center"
    >
      <half-circle-spinner
        slot="waiting"
        style="display: block; margin: 0 auto"
        :animation-duration="1000"
        :size="20"
        color="var(--brand-primary)"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';

export default {
  name: 'AutoSuggestDropDown',
  components: {
    PencilIcon,
  },

  props: {
    funnel: {
      default: 0,
    },
    excluded: {
      default: [],
    },
    button: {
      default: 'Add Affiliate',
    },
    affiliate_ids: {},
    title: {},
  },

  data() {
    return {
      isLoading: false,
      query: '',
      suggestions: [],
      selected: null,
      debounceMilliseconds: 500,
      timeout: null,
      labelName: this.title,
      affiliate_name: '',
      inputProps: {
        id: 'autosuggest__input',
        placeholder: this.$t('frontend_groovesell_affiliate_dropdown_select'),
        disabled: false,
        initialValue: this.affiliate_name,
        class:
          'h-10 border border-solid text-grey-600 border-grey-200 w-full h-full outline-none rounded-lg px-4 bg-white py-2',
      },
      showSearch: this.affiliate_name,
    };
  },

  async created() {
    const data = {
      funnel_id: this.$route.params.id,
      user_ids: this.affiliate_ids,
      query: '',
      limit: 1,
      page: 1,
    };

    this.$loader.start('created');

    const response = await axios
      .post('funnel-affiliates/get-details', {
        params: data,
      })
      .catch((e) => {
        console.log(`Error${e}`);
      });

    if (response.data.count) {
      this.affiliate_name = this.showSearch = response.data.data[0].name;
    }

    this.$loader.end('created');
  },
  methods: {
    addedAffiliate() {
      this.$emit('onAffiliateAdded', this.selected);
      this.selected = null;
      this.query = '';
      this.suggestions = [];
    },

    openEdit() {
      this.showSearch = '';
    },

    deleteAff() {
      this.$emit('removedAffiliate_add_team', this.selected);
      this.showSearch = this.affiliate_name = this.selected = '';
    },

    async fetchResults() {
      const { query } = this;

      if (query.trim() != '' && query.length > 3) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          this.suggestions = [];
          this.selected = null;
          this.isLoading = true;

          const affiliatesData = await axios.post('funnel-affiliates/search', {
            funnel_id: this.funnel,
            query: this.query,
            excluded: this.excluded,
          });

          this.suggestions = [
            {
              data: affiliatesData.data,
            },
          ];
          this.isLoading = false;
        }, this.debounceMilliseconds);
      } else {
        this.suggestions = [];
        this.selected = null;
      }
    },

    renderSuggestion(suggestion) {
      return suggestion.item.name;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    selectHandler(item) {
      if (item) {
        this.selected = item.item;
        this.affiliate_name = this.showSearch = this.selected.name;
        this.$emit('onAffiliateAdded', this.selected);
      }
    },
  },
};
</script>

<style>
#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results {
  overflow: auto;
}

.autosuggest__results {
  max-height: 300px;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>