// TODO: Clean up this file after refactor complete
// Some of these plugins are not used in the app
import Vue from 'vue';
import { VueHammer } from 'vue2-hammer';
import VueApexCharts from 'vue-apexcharts';
import { HalfCircleSpinner } from 'epic-spinners';
import VueFormWizard, { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import vSelect from 'vselect-component';
import VueNumeric from 'vue-numeric';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Vuetable from 'vuetable-2';
import { ClientTable, ServerTable } from 'vue-tables-2';
import PortalVue from 'portal-vue';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import VueCurrencyInput from 'vue-currency-input';
import wysiwyg from 'vue-wysiwyg';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import vueDebounce from 'vue-debounce';
import VueCurrencyFilter from 'vue-currency-filter';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VTooltip from 'v-tooltip';
import VueAutosuggest from 'vue-autosuggest';
import vueCountryRegionSelect from 'vue-country-region-select';
import lineClamp from 'vue-line-clamp';

Vue.use(VueHammer);
Vue.use(VueApexCharts);
Vue.component('vue-apex-charts', VueApexCharts);
Vue.component('half-circle-spinner', HalfCircleSpinner);
Vue.use(FormWizard);
Vue.use(TabContent);
Vue.use(DateRangePicker);
Vue.component('date-range-picker', DateRangePicker);
Vue.use(VueFormWizard);
Vue.use(vSelect);
Vue.component('v-select', vSelect);
Vue.use(VueNumeric);
Vue.component('multiselect', Multiselect);
Vue.use(Vuetable);
Vue.component('vuetable', Vuetable);
Vue.use(ClientTable);

Vue.use(ServerTable);
Vue.use(PortalVue);
Vue.use(VueCurrencyInput);
Vue.use(wysiwyg, {});
Vue.use(vueDebounce);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
});
Vue.filter('numFormat', numFormat(numeral));
Vue.use(VTooltip);
Vue.use(VueAutosuggest);
Vue.use(vueCountryRegionSelect);
Vue.use(lineClamp);
