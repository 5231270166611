import store from '@/store';
import Model from './Model';

const ISDEMOMODE = 'internal/isDemoMode';

export default class Affiliate extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'affiliates';
  }

  get rendered_name() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxxx';
    }

    return this.name;
  }

  get rendered_username() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxxx';
    }

    return this.username;
  }

  get rendered_email() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxxx@xxxxxx.xxx';
    }

    return this.email;
  }

  get rendered_affiliate_paypal() {
    if (store.getters[ISDEMOMODE] && this.affiliate_paypal) {
      return 'xxxxxxx';
    }

    return this.affiliate_paypal;
  }
}
