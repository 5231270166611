<template>
    <div>
        <div v-if="selectedTransaction.transaction_retry_logs.length > 0" id="retries-list-view"
            class="flex flex-row w-full">
            <v-client-table :data="selectedTransaction.transaction_retry_logs" :columns="failedLogsColumns"
                :options="failedLogsTableOptions">
                <div slot="log" slot-scope="props">
                    <div v-if="props.row.description ==
                        'auto_retry_attempt_successful'
                        " class="flex flex-row">
                        {{
                            $t(
                                'frontend_analytics_transactions_automatic_dunning'
                            )
                        }}
                    </div>
                    <div v-else-if="props.row.description == 'auto_retry_attempt_failed'
                            " class="flex flex-row">
                        {{
                            $t(
                                'frontend_analytics_transactions_automatic_dunning_retry'
                            )
                        }}
                    </div>
                    <div v-else-if="props.row.description ==
                            'manual_retry_attempt_successful'
                            " class="flex flex-row">
                        {{ $t('frontend_analytics_transactions_manual') }}
                    </div>
                    <div v-else-if="props.row.description ==
                        'manual_retry_attempt_failed'
                        " class="flex flex-row">
                        {{
                            $t(
                                'frontend_analytics_transactions_manual_dunnig_fail'
                            )
                        }}
                    </div>
                    <div v-else class="flex flex-row">
                        {{ $t('frontend_analytics_initial_fail') }}
                    </div>
                </div>
                <div slot="date" slot-scope="props">
                    <div class="flex flex-row">
                        {{ props.row.created_at_date }} -
                        {{ props.row.created_at_time }}
                    </div>
                </div>
            </v-client-table>
        </div>
        <div v-else class="flex flex-row w-full">
            {{ $t('frontend_analytics_no_dunning_attempts') }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            failedLogsColumns: ['log', 'date'],
            failedLogsTableOptions: {
                headings: {
                    log: 'Activity Log',
                    date: 'Date Time',
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
        }
    }
}
</script>