<template>
    <div
    class="w-full relative h-10 text-grey-600 border border-solid border-grey-200 rounded-lg bg-white"
  >
    <input
      ref="input"
      type="text"
      :placeholder="$t('frontend_amount')"
      class="w-full h-full outline-none rounded-lg px-4"
      v-model="formattedValue"
      v-currency="{ currency: currencyCode, locale: 'en' }"
      @keyup="keyup"
      @keydown="keydown"
    />
  </div>
</template>

<script>
import TextInput from './TextInput';
import { setValue, getValue } from "vue-currency-input";
export default {
  name: 'GdCurrencyInput',
  components: {
    TextInput
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    currencyCode: {
      default: 'usd'
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formattedValue: 0
    };
  },
  watch: {
      formatted(value)
      {
        setValue(this.$refs.input, value);
      }
  },
  mounted() {
    this.formattedValue = this.value;
    setValue(this.$refs.input, this.value);
  },
  methods: {

    keyup() {
      this.$emit('input', getValue(this.$refs.input));
      this.$emit('keyup', getValue(this.$refs.input));
    },

    keydown(e) {
      this.$emit('keydown', e);
    },
  }
};
</script>