<template>
  <div class="flex flex-row w-full">
    <vue-autosuggest
      ref="autosuggest"
      v-model="query"
      :suggestions="suggestions"
      :input-props="inputProps"
      :render-suggestion="renderSuggestion"
      :get-suggestion-value="getSuggestionValue"
      @selected="selectHandler"
      @input="fetchResults"
    >
      <template slot-scope="{ suggestion }">
        <div>
          <b>{{ suggestion.item.name }}</b>
        </div>
      </template>
    </vue-autosuggest>
    <gd-button
      v-if="selected && !isLoading"
      size="auto"
      variant="primary"
      class="ml-4"
      @click="addedAffiliate"
      >{{ button }}</gd-button
    >
    <gd-button
      v-else
      size="auto"
      class="ml-4"
      disabled
      >{{ button }}</gd-button
    >
    <div
      v-if="isLoading"
      class="ml-2 flex flex-col items-center align-center justify-center"
    >
      <half-circle-spinner
        slot="waiting"
        style="display: block; margin: 0 auto"
        :animation-duration="1000"
        :size="20"
        color="var(--brand-primary)"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AffiliatesAutoSuggest',
  props: {
    funnel: {
      default: 0,
    },
    excluded: {
      default: [],
    },
    button: {
      default: 'Add Affiliate',
    },
  },
  data() {
    return {
      query: '',
      suggestions: [],
      selected: null,
      debounceMilliseconds: 500,
      timeout: null,
      isLoading: false,
    };
  },
  computed: {
    inputProps() {
      let inputProps = {
        id: 'autosuggest__input',
        placeholder: this.$t(
          'frontend_gduikit_affiliate_suggest_add_affiliate'
        ),
        disabled: false,
        class:
          'h-10 border border-solid text-grey-600 border-grey-200 w-full h-full outline-none rounded-lg px-4 bg-white py-2',
      };

      if (this.isLoading) {
        inputProps = {
          id: 'autosuggest__input',
          placeholder: this.$t(
            'frontend_gduikit_affiliate_suggest_add_affiliate'
          ),
          class:
            'h-10 border border-solid text-grey-600 border-grey-200 w-full h-full outline-none rounded-lg px-4 bg-white py-2',
        };
      }

      return inputProps;
    },
  },
  methods: {
    addedAffiliate() {
      this.$emit('onAffiliateAdded', this.selected);
      this.selected = null;
      this.query = '';
      this.suggestions = [];
    },

    async fetchResults() {
      const {query} = this;

      if (query.trim() != '' && query.length > 3) {
        const self = this;
 
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          this.suggestions = [];
          this.selected = null;

          this.isLoading = true;

          const affiliatesData = await axios.post('funnel-affiliates/search', {
            funnel_id: this.funnel,
            query: this.query,
            excluded: this.excluded,
          });
          this.suggestions = [
            {
              data: affiliatesData.data,
            },
          ];

          this.isLoading = false;
        }, this.debounceMilliseconds);
      } else {
        this.suggestions = [];
        this.selected = null;
      }
    },

    renderSuggestion(suggestion) {
      return suggestion.item.name;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    selectHandler(item) {
      if (item) {
        this.selected = item.item;
      }
    },
  },
};
</script>

<style>
#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  background-color: rgb(41, 43, 73);
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results {
  overflow: auto;
}

.autosuggest__results {
  max-height: 300px;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: rgb(41, 43, 73);
  color: #292b49;
}
</style>