<template>
  <div v-on-clickaway="away" class="relative inline-block text-left">
    <div>
      <button
        type="button"
        class="
          inline-flex
          justify-center
          w-full
          rounded
          border border-grey-300
          shadow-sm
          px-4
          py-2
          bg-primary
          text-white text-sm
          font-medium
        "
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click="toggle"
      >
        {{ title }}
        <!-- Heroicon name: solid/chevron-down -->
        <svg
          class="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
    <div
      class="
        origin-top-right
        absolute
        right-0
        mt-2
        w-56
        rounded
        shadow-lg
        bg-white
        ring-1 ring-grey-500 ring-opacity-5
        focus:outline-none
      "
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-if="active"
    >
      <div class="py-1" role="none">
        <!-- Active: "bg-grey-100 text-grey-900", Not Active: "text-grey-700" -->
        <div
          v-for="(option, index) in options"
          :key="index"
          class="block px-4 py-2 text-sm"
          @click="clicked(option)"
          :class="{
            'text-grey-700 cursor-pointer':
              !option.hasOwnProperty('disabled') || !option.disabled,
            'text-grey-300 cursor-not-allowed':
              option.hasOwnProperty('disabled') && option.disabled,
          }"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'SimpleDropdown',
  mixins: [clickaway],
  props: {
    title: {
      required: true,
    },
    options: {
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    away() {
      this.active = false;
    },
    clicked(option) {
      if (!option.hasOwnProperty('disabled') || !option.disabled) {
        this.$emit('selected', option.id);
        this.active = false;
      }
    },
  },
};
</script>
