<template>
  <div class="flex flex-col video-vertical-tabs" :class="wrapperClass">
    <button
      type="button"
      v-for="tab in tabs"
      :key="tab.value"
      :ref="tab.value"
      :class="[
        'px-6 py-3 w-full text-left focus:outline-none text-sm',
        { 'border-l-4 border-primary text-primary font-bold': tab.value === currentTab },
        { 'text-grey-800 font-semibold': tab.value != currentTab },
        tab.value === currentTab && tabActiveClass ? tabActiveClass : '',
        tabClass,
      ]"
      :disabled="tab.disabled || false"
      @click="handleClick(tab.value)"
      v-html="tab.title"
    />
  </div>
</template>
<script>
export default {
  name: 'VerticalTabs',
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    tabClass: {
      type: String,
      required: false,
    },
    tabActiveClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleClick(value) {
      this.$emit('onClick', value);
    },
  },
};
</script>
<style>
.video-vertical-tabs {
  max-width: 300px;
}
</style>