<template>
  <div class="w-full flex flex-col text-grey-500">
    <!-- Visitors Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <user-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.visitorsCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_visitors') }}
        </span>
      </div>
    </div>
    <!-- Signups Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <envelop-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.signupsCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_signups') }}
        </span>
      </div>
    </div>
    <!-- Trial Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.trialCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_initial_sales') }}
        </span>
      </div>
    </div>
    <!-- Sales and Rebills Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-add-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.salesCount | numFormat }} + {{ defaultViewData.rebillsCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_new_sales_rebills') }}
        </span>
      </div>
    </div>
    <!-- Refunds Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <shopping-cart-arrow-down-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.refundsCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_refunds') }}
        </span>
      </div>
    </div>
    <!-- Cancellations Count -->
    <div class="w-full flex flex-row mb-4">
      <div :key="revVolumeKey" class="flex flex-col">
        <cross-icon class="w-12 h-12 fill-current"/>
      </div>
      <div class="flex flex-col w-full justify-end content-end pl-6">
        <p class="block text-grey-900 text-sm font-bold mb-1">
          {{ defaultViewData.cancelCount | numFormat }}
        </p>
        <span class="block text-sm uppercase">
          {{ $t('frontend_analytics_vendor_cancellations') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import UserIcon from '@/assets/images/icons/UserIcon.svg';
import EnvelopIcon from '@/assets/images/icons/EnvelopIcon.svg';
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import ShoppingCartAddIcon from '@/assets/images/icons/ShoppingCartAddIcon.svg';
import ShoppingCartArrowDownIcon from '@/assets/images/icons/ShoppingCartArrowDownIcon.svg';
import CrossIcon from '@/assets/images/icons/CrossIcon.svg';

export default {
  components: {
    UserIcon,
    EnvelopIcon,
    ShoppingCartIcon,
    ShoppingCartAddIcon,
    ShoppingCartArrowDownIcon,
    CrossIcon,
  },
  props: {
    revVolumeKey: {
      required: true,
    },
    defaultViewData: {
      type: Object,
      required: true,
    },
  }
}
</script>
