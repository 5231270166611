<template>
    <gd-modal name="add-commission-bonus-modal" :title="$t('frontend_groovesell_add_bonus')" bg-type="regular">
        <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-full px-4">
                <div class="mt-2">
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_amount')
                        }}</label>
                        <div class="w-2/3">
                            <gd-currency-input v-model="bonus.amount" currency-code="USD" />
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_transaction')
                        }}</label>
                        <text-input v-model="bonus.transactionId" :validator="validation.bonus.transactionId"
                            :attribute="$t('frontend_affiliate_index_transaction_id')" class="w-2/3"
                            value="bonus.transactionId" :placeholder="$t('frontend_affiliate_index_transaction')" />
                    </div>
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_comment')
                        }}</label>
                        <text-input v-model="bonus.comment" :validator="validation.bonus.comment"
                            :attribute="$t('frontend_affiliate_index_comment')" class="w-2/3" value="bonus.comment"
                            :placeholder="$t('frontend_affiliate_index_comment')" />
                    </div>
                    <div class="flex flex-row w-full mb-6 justify-end">
                        <gd-button size="auto" class="mb-4" variant="primary" @click="addAffiliatePaymentBonus">{{
                            $t('frontend_affiliate_index_add_affiliate_bonus')
                        }}</gd-button>
                    </div>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        selectedAffiliate: {
            type: Object,
            default: null
        },
        addAffiliatePaymentBonus: {
            type: Function,
            default: null
        },
        validation: {
            required: true,
        }
    },
    watch: {
        bonus: {
            handler: function (val, oldVal) {
                this.validation.bonus.$touch();
                this.$emit('update-bonus', val);
            },
            deep: true

        }
    },
    data() {
            return {
                bonus: {
                    amount: 0,
                    comment: '',
                    transactionId: '',
                },

            }
        },

    }
</script>