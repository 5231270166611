<template>
    <div>
        <gd-modal name="leaderboards-modal"
            :title="LeaderBoardId ? $t('frontend_leaderboards_edit_leader_board') : $t('frontend_groovesell_create_leaderboard')"
            height="80%" width="80%" :no-overflow="true" allowOverflow>
            <div v-if="leaderboard" class="flex flex-col">
                <!-- step 1 -->
                <div v-if="leaderBoardStep == 1">
                    <div class="flex flex-col m-6 mb-4">
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_name') }}</label>
                            <text-input v-model="leaderboard.name" :attribute="$t('frontend_name')" value="leaderboard.name"
                                :placeholder="$t('frontend_name')" />
                        </div>
                        <div class="flex flex-row w-full mr-2 mb-2">
                            <div class="flex flex-col w-1/2 mr-2">
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_starting_date')
                                }}</label>
                                <date-time-picker
                                    v-model="leaderboardDateRange.startDate"
                                    variant="base"
                                    :value="leaderboardDateRange.startDate"
                                />
                            </div>
                            <div class="flex flex-col w-1/2">
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_ending_date') }}</label>
                                <date-time-picker
                                    v-model="leaderboardDateRange.endDate"
                                    variant="base"
                                    :value="leaderboardDateRange.endDate"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnel') }}</label>
                            <gd-dropdown
                                v-model="leaderboard.funnel_id"
                                size="full"
                                max-height="124px"
                                :placeholder="$t('frontend_funnels')"
                                :options="leaderboardLiveFunnelsOptions"
                            />
                        </div>
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_no_of_aff') }}</label>
                            <gd-dropdown
                                v-model="leaderboard.max_affiliates"
                                size="full"
                                max-height="124px"
                                :options="rowSizeOptions"
                            />
                        </div>
                        <div class="flex flex-col w-full mr-2 mb-16">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_groovesell_ranking_based_on')
                            }}</label>
                            <gd-dropdown
                                v-model="leaderboard.type"
                                size="full"
                                max-height="124px"
                                :options="leaderboardTypeOptions"
                            />
                        </div>
                    </div>
                </div>
                <!-- step2  -->
                <div v-else-if="leaderBoardStep == 2">
                    <div class="mx-4">
                        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
                            <gd-tabs :tabs="leaderboardProductsTabs" :current-tab="leaderboardProductsTab"
                                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                                :line-class="'gs-default-tabs-active-line'" @onClick="handleLeaderboardProductsTabChange" />
                        </div>
                        <div class="mt-2 flex flex-col" style="height: calc(80vh - 15rem)">
                            <div v-for="(pricingPoint, index) in leaderboard.pricepoints" :key="index"
                                class="max-h-full flex flex-col">
                                <div v-if="leaderboardProductsTab === 'leaderboard_product_' + pricingPoint.id"
                                    :ref="'leaderboard_product_' + pricingPoint.id" class="h-full max-h-full flex flex-col">
                                    <ul class=" w-full h-full max-h-full flex flex-col overflow-y-auto mb-8">
                                        <li v-for="(pricePoint, pricePointIndex) in pricingPoint.price_points"
                                            :key="pricePointIndex" class="border-b border-grey-300 px-2 py-4">
                                            <div class="flex flex-row w-full">
                                                <div class="w-3/4">
                                                    <div class="text-semibold text-sm">
                                                        {{ pricePoint.name }}
                                                    </div>
                                                </div>
                                                <div class="w-1/4 flex flex-row justify-end">
                                                    <gd-toggle v-model="pricePoint.enabled" :value="pricePoint.enabled" />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- step 3 -->
                <div v-else-if="leaderBoardStep == 3">
                    <div class="mx-4">
                        <div class="flex flex-col" style="height: calc(80vh - 10rem)">
                            <ul class=" w-full h-full max-h-full flex flex-col overflow-y-auto mb-8">
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">{{ $t('frontend_impressions') }}</div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.impressions"
                                                :value="leaderboard.enabled_stats.impressions" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">{{ $t('frontend_uniques') }}</div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.uniques"
                                                :value="leaderboard.enabled_stats.uniques" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">{{ $t('frontend_signups') }}</div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.optins"
                                                :value="leaderboard.enabled_stats.optins" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">{{ $t('frontend_sales') }}</div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.sales"
                                                :value="leaderboard.enabled_stats.sales" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_groovesell_total_sales_amount') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.total_sales_amount"
                                                :value="leaderboard.enabled_stats.total_sales_amount" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_groovesell_lb_projected_sales_amount') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.projected_revenue"
                                                :value="leaderboard.enabled_stats.projected_revenue" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">{{ $t('frontend_refunds') }}</div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.refunds"
                                                :value="leaderboard.enabled_stats.refunds" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_refunds_amount') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.total_refunds_amount"
                                                :value="leaderboard.enabled_stats.total_refunds_amount" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_groovesell_earned_commissions') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.earned_commissions"
                                                :value="leaderboard.enabled_stats.earned_commissions" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_groovesell_lb_refund_commissions') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.refunded_commissions"
                                                :value="leaderboard.enabled_stats.refunded_commissions" />
                                        </div>
                                    </div>
                                </li>
                                <li class="border-b border-grey-300 px-2 py-4">
                                    <div class="flex flex-row w-full">
                                        <div class="w-3/4">
                                            <div class="text-semibold text-sm">
                                                {{ $t('frontend_groovesell_lb_commissions_without_refunds') }}
                                            </div>
                                        </div>
                                        <div class="w-1/4 text-right">
                                            <gd-toggle v-model="leaderboard.enabled_stats.commissions_without_refunds"
                                                :value="leaderboard.enabled_stats.commissions_without_refunds" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- step 4 -->
                <div v-if="leaderBoardStep == 4">
                    <!-- Add button -->
                    <gd-button variant="primary" size="auto" class="float-right mt-3 mr-6"
                        @click="openModal('leaderboards-add-prize-modal', 'add')">
                        <plus-icon class="w-5 h-5 fill-current" />
                        {{ $t('frontend_groovesell_add_prizes') }}
                    </gd-button>

                    <!-- Table listing -->
                    <div class="p-6 flex" v-if="leaderBordPricingData && leaderBordPricingData.length > 0"
                        id="affiliate-transactions-list-view">
                        <v-client-table :data="leaderBordPricingData" :columns="leaderBordPricingColumns"
                            :options="leaderBordPrizingOptions">
                            <div slot="prize_image" slot-scope="props">
                                <img :src="props.row.prize_image" height="50" width="50" />
                            </div>

                            <div slot="actions" slot-scope="props">
                                <div class="flex flex-row">
                                    <gd-button variant="primary" size="icon" class="mr-2" @click="editPrize(props.index, props.row)">
                                        <pencil-icon class="w-5 h-5 fill-current" />
                                    </gd-button>

                                    <gd-button variant="primary" size="icon" class="mr-2"
                                        @click="deletePrize(props.index, props.row, LeaderBoardId ? 'edit' : 'add')">
                                        <trash-icon class="w-5 h-5 fill-current" />
                                    </gd-button>
                                </div>
                            </div>
                        </v-client-table>
                    </div>
                    <div class="p-6 flex" v-else>
                        {{ $t('frontend_groovesell_no_prizes_desc') }}
                    </div>
                </div>
                <!-- step 5 -->
                <div v-else-if="leaderBoardStep == 5">
                    <div class="flex flex-col m-6 mb-4">
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">Link</label>
                            <text-input v-model="leaderboardLink" class="w-full" :readonly="true" :select-all="true"
                                value="leaderboardLink" placeholder="Link" />
                        </div>
                        <div class="flex flex-col w-full mr-2 mb-2">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_promo_embed_code')
                            }}</label>
                            <text-area v-model="leaderboard.leaderboard_embed" class="w-full" :readonly="true"
                                :select-all="true" value="leaderboard.leaderboard_embed"
                                :placeholder="$t('frontend_affiliate_promo_embed_code')" />
                        </div>
                    </div>
                </div>

                <div class="flex flex-row w-full mb-6 justify-end">
                    <gd-button v-if="leaderBoardStep != 5" size="auto" class="mr-6" variant="primary"
                        @click="saveAndNextOnLeaderboard">{{ $t('frontend_save_and_next') }}</gd-button>
                    <gd-button v-else size="auto" class="mr-6" variant="primary" @click="closeLeaderboardWizard">{{
                        $t('frontend_close') }}</gd-button>
                </div>
            </div>
        </gd-modal>
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>
<script>
import LeaderboardWidget from '@/models/LeaderboardWidget';
import Funnel from '@/models/Funnel';
import moment from 'moment-timezone';
import DateTimePicker from '@groovepages/gd-ui-kit/components/DateTimePicker';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    name: "SaveLeaderBoardModal",
    props: {
        openModal: {
            type: Function,
            required: true
        },
        editPrize: {
            type: Function,
            required: true
        },
        deletePrize: {
            type: Function,
            required: true
        },
        leaderBordPricingData: {
            type: Array,
            required: true
        },
        LeaderBoardId: {
            required: true,
            default: null
        },
    },
    components: {
        DateTimePicker,
        PencilIcon,
        TrashIcon,
        PlusIcon,
    },

    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
            leaderBoardStep: 1,
            leaderboardFunnels: [],
            leaderboard: {},
            leaderboardDateRange: {
                startDate: moment().startOf('hour').toISOString(),
                endDate: moment().startOf('hour').add(30, 'days').toISOString(),
            },
            leaderboardLiveFunnels: [],
            leaderboardProductsTab: '',
            leaderBordPricingColumns: [
                'pricePointName',
                'pricePointValue',
                'pricePositionOptionsValue',
                'actions',
            ],
            leaderBordPrizingOptions: {
                headings: {
                    pricePointName: 'Name',
                    pricePointValue: 'Value',
                    pricePositionOptionsValue: 'Position',
                    actions: 'Actions',
                },
                perPage: 5,
                filterable: false,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
        }
    },
    async created() {
        this.$loader.start('fetchLeaderboardData');
        this.leaderboardFunnels = await Funnel.$get();
        this.leaderboardLiveFunnels = this.leaderboardFunnels.filter((x) => x.is_live);

        if (!this.LeaderBoardId) {
            this.leaderboard = new LeaderboardWidget({
                type: '1',
                name: '',
                funnel_id: this.leaderboardLiveFunnels[0].id, // Setting funnel_id from the first element of leaderboardFunnels array
                max_affiliates: 10,
                pricepoints: [],
                enabled_stats: [],
            });
        } else {
            this.leaderboard = await LeaderboardWidget.$find(this.LeaderBoardId);
            this.$emit('update-attributes', {
                key: 'leaderBordPricingData',
                value: this.leaderboard.leaderboard_config.prices
            })
            this.leaderboardDateRange = {
                startDate: new Date(this.leaderboard.start_timestamp * 1000).toISOString(),
                endDate: new Date(this.leaderboard.end_timestamp * 1000).toISOString(),
            };
            this.$emit('updatePricePositionOptions', this.leaderboard);
        }
        this.$emit('update-attributes', {
            key: 'leaderboard',
            value: this.leaderboard
        });
        this.$loader.end('fetchLeaderboardData');

    },
    computed: {
        leaderboardLiveFunnelsOptions() {
            return this.leaderboardLiveFunnels.map(funnel => ({ value: funnel.id, text: funnel.name }));
        },
        rowSizeOptions() {
            return [5, 10, 25, 50, 100];
        },
        leaderboardTypeOptions() {
            return [
                { value: '1', text: this.$t('frontend_groovesell_lb_type_revenue_collected') },
                { value: '9', text: this.$t('frontend_groovesell_lb_type_revenue_projected') },
                { value: '2', text: this.$t('frontend_groovesell_lb_type_commissions_with_refunds') },
                { value: '3', text: this.$t('frontend_groovesell_lb_type_commissions_without_refunds') },
                { value: '4', text: this.$t('frontend_groovesell_lb_type_sales_with_refunds') },
                { value: '5', text: this.$t('frontend_groovesell_lb_type_sales_without_refunds') },
                { value: '6', text: this.$t('frontend_signups') },
                { value: '7', text: this.$t('frontend_impressions') },
                { value: '8', text: this.$t('frontend_uniques') }
            ];
        },
        leaderboardLink() {
            if (this.leaderboard && this.leaderboard.permalink) {
                const site_appurl = window.config.appUrl.replace(/^https?:\/\//i, '');
                return `https://leaderboards.${site_appurl}/leaderboard/${this.leaderboard.permalink}`;
            }
            return '';
        },
        leaderboardProductsTabs() {
            const leaderboardProductsTabs = [];
            for (let i = 0; i < this.leaderboard.pricepoints.length; i++) {
                leaderboardProductsTabs.push({
                    title: this.leaderboard.pricepoints[i].name,
                    value: `leaderboard_product_${this.leaderboard.pricepoints[i].id}`,
                });
            }
            return leaderboardProductsTabs;
        },
    },
    methods: {
        async saveAndNextOnLeaderboard() {
            if (!this.leaderboard.name || this.leaderboard.name == ''){
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_error'),
                    message: this.$t('frontend_groovesell_swal_valid_name_for_leaderboard'),
                };
                return;
            }
            if (moment(this.leaderboardDateRange.endDate).diff(moment(this.leaderboardDateRange.startDate)) <= 0) {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_error'),
                    message: this.$t('frontend_groovesell_swal_select_valid_date_range'),
                };
                return;
            }
            if (moment(this.leaderboardDateRange.endDate).diff(moment(this.leaderboardDateRange.startDate), 'days') > 90) {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_error'),
                    message: this.$t('frontend_groovesell_swal_leaderboard_date_range'),
                };
                return;
            }

            this.leaderboard.start_at = moment(this.leaderboardDateRange.startDate).tz('America/New_York').format('MM/DD/YYYY hh:mm A');
            this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate).tz('America/New_York').format('MM/DD/YYYY hh:mm A');
            this.$loader.start('saveAndNextOnLeaderboard');
            if (this.leaderBoardStep == 1) {
                const savedLeaderboard = await this.leaderboard.save();
                this.$emit('refreshLeaderboardData')
                this.leaderboard = await LeaderboardWidget.$find(savedLeaderboard.data.id);
                this.leaderboardProductsTab = `leaderboard_product_${this.leaderboard.pricepoints[0].id}`;
                this.leaderBoardStep = 2;
                this.$emit('updatePricePositionOptions', this.leaderboard);
            } else if (this.leaderBoardStep == 2) {
                const savedLeaderboard = await this.leaderboard.save();
                this.leaderboard = await LeaderboardWidget.$find(savedLeaderboard.data.id);
                this.leaderBoardStep = 3;
            } else if (this.leaderBoardStep == 3) {
                const savedLeaderboard = await this.leaderboard.save();
                this.leaderboard = await LeaderboardWidget.$find(savedLeaderboard.data.id);
                this.leaderBoardStep = 4;
            } else if (this.leaderBoardStep == 4) {
                this.leaderboard.leaderboard_config = {
                    prices: this.leaderBordPricingData.length
                        ? this.leaderBordPricingData
                        : [],
                    teams: this.leaderboard.leaderboard_config.teams.length
                        ? this.leaderboard.leaderboard_config.teams
                        : [],
                };
                const savedLeaderboard = await this.leaderboard.save(); // update data
                this.leaderboard = await LeaderboardWidget.$find(savedLeaderboard.data.id);
                this.leaderBoardStep = 5;
            }
            this.$emit('update-attributes', {
                key: 'leaderboard',
                value: this.leaderboard
            });
            this.$loader.end('saveAndNextOnLeaderboard');
        },
        async closeLeaderboardWizard() {
            if (!this.LeaderBoardId) this.$emit('refreshLeaderboardData');
            this.$root.$emit('modal-close', 'leaderboards-modal');
        },
        handleLeaderboardProductsTabChange(newTab) {
            this.leaderboardProductsTab = newTab;
        },
        generateDynamicOptionArr(leaderBoardData, ignore_position = null) {

            const mynumber = leaderBoardData.max_affiliates;
            const leaderBaordPriceData = leaderBoardData.leaderboard_config.prices;

            const ignoreArr = [];
            for (let i = 0; i < leaderBaordPriceData.length; i++) {
                if (ignore_position != null && ignore_position == leaderBaordPriceData[i].pricePositionOptionsValue) {
                    continue;
                }
                ignoreArr.push(leaderBaordPriceData[i].pricePositionOptionsValue);
            }

            const arr = [];
            for (let i = 1; i <= mynumber; i++) {
                if (!ignoreArr.includes(i.toString())) {
                    arr.push(i.toString());
                }
            }

            arr.sort((a, b) => a - b);

            return arr;
        }
    }

}
</script>