<template>
    <gd-modal name="payment-affiliates-modal" :title="$t('frontend_affiliates')" bg-type="regular">
        <div v-if="selectedPayment">
            <div v-if="selectedPayment.affiliates && selectedPayment.affiliates.length > 0">
                <div id="payment-affiliates-list-view" class="flex flex-row mt-4 mb-4">
                    <v-client-table :data="selectedPayment.affiliates" :columns="paymentAffiliatesColumns"
                        :options="paymentAffiliatesTableOptions" />
                </div>
            </div>
            <div v-else class="p-4">
                {{ $t('frontend_affiliate_index_no_affiliates_for_this_payment') }}
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        selectedPayment: {
            type: Object,
            default: null
        }

    },
    data() {
        return {
            paymentAffiliatesColumns: ['name', 'email'],
            paymentAffiliatesTableOptions: {
                headings: {
                    name: 'Name',
                    email: 'Email'
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: this.$t('frontend_search'),
                    filter: ''
                }
            }
        }
    }
}
</script>