<template>
  <div>
    <div class="w-full">
      <v-wait for="bonuses">
        <!-- Bonuses List -->
        <div v-if="bonuses.length" id="bonuses-list-view" class="mt-4">
          <v-client-table 
            ref="bonusesTable"
            :data="bonuses"
            :columns="bonusesTableColumns"
            :options="bonusesTableOptions"
            class="bg-white text-center rounded border border-grey-300">
            <!-- Actions Column -->
            <div slot="actions" slot-scope="props">
              <gd-button
                variant="outline"
                size="icon"
                class="mr-1"
                :title="$t('frontend_groovesell_bonuses_edit_bonus')" 
                @click="openEditBonus(props.row.id)"
              >
                <pencil-icon class="w-5 h-5 fill-current" />
              </gd-button>
              <gd-button 
                size="icon" 
                variant="primary" 
                :title="$t('frontend_groovesell_delete_bonus')" 
                @click="openConfirmModal(props.row.id)"
              >
                <trash-icon class="w-5 h-5 fill-current" />
              </gd-button>
            </div>
          </v-client-table>
        </div>

        <!-- No Bonuses Message -->
        <div v-else class="mt-8">
          <p>{{ $t('frontend_no_bonuses') }}</p>
        </div>

        <!-- Modals and Button -->
        <create-bonus-modal :model="model" @createBonus="createBonus"/>
        <edit-bonus-modal :model="model" @editBonus="editBonus"/>
        <portal to="headerContent">
          <gd-button 
            variant="primary" 
            class="mr-4" 
            @click="openCreateBonus">
            <plus-icon class="w-5 h-5 fill-current" />
            {{ $t('frontend_bouses_new_bonus') }}
          </gd-button>
        </portal>
      </v-wait>
    </div>
    <confirm-modal
      name="confirm-modal"
      :message-content="$t('frontend_bouses_delete_bonus_text')"
      :button-text="$t('frontend_bouses_delete_confirmation_text')"
      @confirm="deleteBonus"
    />
  </div>
</template>

<script>
import Bonus from '@/models/Bonus';
import CreateBonusModal from './bonuses-partials/modals/CreateBonusModal.vue';
import EditBonusModal from './bonuses-partials/modals/EditBonusModal.vue';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  layout: 'defaultForAffiliates',
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('my_bonuses') };
  },
  components: {
    CreateBonusModal,
    EditBonusModal,
    PencilIcon,
    TrashIcon,
    PlusIcon,
  },
  data() {
    return {
      bonusToDeleteId: null,
      bonus: null,
      bonuses: [],
      model: { name: '', link: '' },
      bonusesTableColumns: ['name', 'actions'],
      bonusesTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: ['name'],
        headings: { name: 'Bonus', actions: '' },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback: () => 'hover:bg-grey-50 funnelRow'
      }
    };
  },

  async created() {
    this.loadBonuses();
  },

  methods: {
    async loadBonuses() {
      this.$wait.start('bonuses');
      this.bonuses = await Bonus.$get();
      this.$wait.end('bonuses');
    },

    openCreateBonus() {
      this.resetBonusModel();
      this.$root.$emit('modal-open', 'create-bonus-modal');
    },

    async openEditBonus(bonus_id) {
      this.$loader.start('openEditBonus');
      this.bonus = await Bonus.$find(bonus_id);
      this.updateModelFromBonus();
      this.$loader.end('openEditBonus');
      this.$root.$emit('modal-open', 'edit-bonus-modal');
    },

    async createOrUpdateBonus(model, isCreate = true) {
      this.$loader.start('createOrUpdateBonus');
      this.model = model;
      this.bonus.name = this.model.name;
      this.bonus.link = this.model.link;

      const savedBonus = await this.bonus.save();

      if (isCreate) {
        this.bonuses.push(savedBonus.data);
      } else {
        this.bonuses = await Bonus.$get();
      }

      this.$loader.end('createOrUpdateBonus');
      this.$root.$emit('modal-close', isCreate ? 'create-bonus-modal' : 'edit-bonus-modal');
    },

    createBonus(model) {
      this.createOrUpdateBonus(model);
    },

    editBonus(model) {
      this.createOrUpdateBonus(model, false);
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-modal');
      this.bonusToDeleteId = id;
    },
    async deleteBonus() {
      this.$loader.start('deleteBonus');
      const bonus = await Bonus.$find(this.bonusToDeleteId);
      await bonus.delete();
      this.bonuses = this.bonuses.filter(item => item.id !== this.bonusToDeleteId);
      this.$loader.end('deleteBonus');
    },

    resetBonusModel() {
      this.bonus = new Bonus({});
      this.model.name = '';
      this.model.link = '';
    },

    updateModelFromBonus() {
      this.model.name = this.bonus.name;
      this.model.link = this.bonus.link;
    },
  }
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#bonuses-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>
