<template>
  <div>
    <div v-if="hasFacebookAds" class="flex flex-col">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-tabs
          :tabs="facebookAdsTabs"
          :current-tab="localFacebookAdsTab"
          :error-tabs="facebookAdsErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleFacebookAdsTabChange"
          :enable-dragging="true"
          @updateTabOrder="handleFacebookAdsTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createFacebookAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(ad, index) in localFunnelResource.facebook_ads" :key="index">
          <div v-if="isFacebookAdTabActive(ad)" class="pb-8">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resources_name') }}
                </label>
                <text-input
                  v-model="ad.name"
                  :validator="$v.localFunnelResource.facebook_ads.$each[index].name"
                  :attribute="$t('frontend_groovesell_facebook_ad_name')"
                  value="ad.name"
                  :placeholder="$t('frontend_resources_name')"
                />
              </div>
              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle class="h-6 mt-2" v-model="ad.enabled" :value="ad.enabled" />
                <gd-button
                  variant="outline"
                  size="iconlg"
                  class="ml-4"
                  @click="openConfirmModal(index)"
                >
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-col w-full mb-6">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_resources_ad_copy') }}
              </label>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-model="ad.copy"
                    class="h-32 w-full"
                    value="ad.copy"
                    :placeholder="$t('frontend_resources_copy')"
                    :validator="$v.localFunnelResource.facebook_ads.$each[index].copy"
                    :attribute="$t('frontend_resources_ad_copy')"
                  />
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToAdCopy(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActions(index)"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col w-1/2 mb-6">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_resources_suggested_interests') }}
              </label>
              <text-area
                v-model="ad.interests"
                class="h-16 w-full"
                value="ad.interests"
                :placeholder="$t('frontend_resources_suggested_interests')"
                :validator="$v.localFunnelResource.facebook_ads.$each[index].interests"
                :attribute="$t('frontend_resources_suggested_interests')"
              />
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex items-center mb-2">
                <label class="font-semibold text-sm text-grey-700">
                  {{ $t('frontend_resources_ad_images') }}
                </label>
                <gd-button
                  variant="outline"
                  size="iconlg"
                  :title="$t('frontend_add')"
                  class="ml-2"
                  @click="addNewFacebookAdImage(index)"
                >
                  <plus-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
              <div v-if="ad.images && ad.images.length" class="flex flex-row flex-wrap">
                <div v-for="(image, imageIndex) in ad.images" :key="imageIndex" class="w-1/5 px-2">
                  <div class="flex flex-col w-full">
                    <div class="w-full text-center mt-2">
                      <img :src="image" />
                    </div>
                    <div
                      class="w-full justify-center flex flex-row font-semibold text-md text-grey-700 mt-2"
                    >
                      <gd-button
                        variant="outline"
                        size="auto"
                        class="mb-3 mt-3"
                        @click="deleteAdImage(index, imageIndex)"
                      >
                        {{ $t('frontend_resources_delete_image') }}
                      </gd-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                {{ $t('frontend_resources_you_currently_dont_have_ad_images') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-button
          size="auto"
          variant="primary"
          class="mb-3 mt-3"
          @click="createFacebookAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_you_currently_dont_have_fb_ads') }}
    </div>

    <gd-modal ref="adImagesModal" name="image-picker" width="100%" height="100%">
      <image-picker-without-modal
        :value="imagePickerVal"
        modal="true"
        remove="false"
        @close-modal="$refs.adImagesModal.close()"
        @selected="addFacebookAdImageToArray"
      ></image-picker-without-modal>
    </gd-modal>
    <confirm-modal
      name="confirm-facebook-ad-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteFacebookAd"
    />
  </div>
</template>

<script>
import ImagePickerWithoutModal from '@groovepages/gd-ui-kit/components/ImagePicker';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    ImagePickerWithoutModal,
    TrashIcon,
    PlusIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    facebookAdsTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      facebookAdId: null,
      localFunnelResource: this.funnelResource,
      localFacebookAdsTab: this.facebookAdsTab,
      imagePickerVal: '',
      selectedFacebookAdsImageIndex: '',
    }
  },
  computed: {
    hasFacebookAds() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.facebook_ads &&
        this.localFunnelResource.facebook_ads.length
      );
    },
    facebookAdsTabs() {
      let facebookAdsTabs = [];

      if (this.hasFacebookAds) {
        for (let i = 0; i < this.localFunnelResource.facebook_ads.length; i++) {
          const ad = this.localFunnelResource.facebook_ads[i];
          facebookAdsTabs.push({
            title: ad.name,
            value: `facebook_ad_tab_${ad.id}`,
          });
        }
      }

      return facebookAdsTabs;
    },
    facebookAdsErrorTabs() {
      let facebookAdsErrorTabs = [];

      for (const index in this.$v.localFunnelResource.facebook_ads.$each.$iter) {
        if (this.$v.localFunnelResource.facebook_ads.$each.$iter[index].$error) {
          facebookAdsErrorTabs.push(
            `facebook_ad_tab_${this.$v.localFunnelResource.facebook_ads.$each.$iter[index].$model.id}`
          );
        }
      }

      return facebookAdsErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActions(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToAdCopy(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleFacebookAdsTabChange(newTab) {
      this.localFacebookAdsTab = newTab || this.localFacebookAdsTab;
      this.$emit('handleFacebookAdsTabChange', this.localFacebookAdsTab);
    },
    handleFacebookAdsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('facebook_ad_tab_', ''))
      );

      this.localFunnelResource.facebook_ads.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createFacebookAd() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.facebook_ads.length + 1;

      this.localFunnelResource.facebook_ads.push({
        id: newPostId,
        name: `${this.$t('facebook_ad')} ${newPostCount}`,
        copy: '',
        interests: '',
        images: [],
      });

      this.localFacebookAdsTab = `facebook_ad_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleFacebookAdsTabChange();
    },
    openConfirmModal(facebookAdId) {
      this.facebookAdId = facebookAdId;
      this.$root.$emit('modal-open', 'confirm-facebook-ad-delete-modal');
    },
    async deleteFacebookAd() {
      this.localFunnelResource.facebook_ads.splice(this.facebookAdId, 1);
      this.localFacebookAdsTab = '';

      if (this.localFunnelResource.facebook_ads.length > 0) {
        let nextOpenIndex = 0;
        if (this.facebookAdId != 0) {
          nextOpenIndex = this.facebookAdId - 1;
        }

        const nextOpenId = this.localFunnelResource.facebook_ads[nextOpenIndex].id;
        this.localFacebookAdsTab = `facebook_ad_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleFacebookAdsTabChange();
    },
    addAffiliateNameToAdCopy(index) {
      this.localFunnelResource.facebook_ads[
        index
      ].copy = `${this.localFunnelResource.facebook_ads[index].copy}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToAdCopy(index, landingPageId) {
      this.localFunnelResource.facebook_ads[
        index
      ].copy = `${this.localFunnelResource.facebook_ads[index].copy}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    addNewFacebookAdImage(index) {
      this.selectedFacebookAdsImageIndex = index;
      this.$refs.adImagesModal.open();
    },
    deleteAdImage(index, imageIndex) {
      this.localFunnelResource.facebook_ads[index].images.splice(imageIndex, 1);

      this.updateFunnelResource();
    },
    addFacebookAdImageToArray(value) {
      this.funnelResource.facebook_ads[
        this.selectedFacebookAdsImageIndex
      ].images.push(value);

      this.updateFunnelResource();
    },
    isFacebookAdTabActive(ad) {
      return this.localFacebookAdsTab === `facebook_ad_tab_${ad.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      facebook_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          copy: {
            required,
            minLength: minLength(3),
          },
          interests: {},
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
