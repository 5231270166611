<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                    <div v-if="pluginSiteData.integrations.length > 0 && availableIntegrations.length > 0">
                        <div class="flex flex-col">
                            <div class="flex flex-row-reverse w-full">
                                <gd-button size="auto" variant="primary" class="mr-4"
                                    @click="emitShowPostSaleIntegrationBox(index)">{{
                                        $t('frontend_product_step8_add_new') }}</gd-button>
                            </div>
                            <div v-if="pricePoint.post_sale_integrations && pricePoint.post_sale_integrations.length > 0"
                                class="mt-6">
                                <div v-for="(postSaleIntegration, postSaleIntegrationIndex) in pricePoint.post_sale_integrations"
                                    :key="postSaleIntegrationIndex" class="flex flex-col mt-4">
                                    <div class="flex flex-row" :class="{
                                        'border-t border-grey-400 border-solid pt-4':
                                            postSaleIntegrationIndex > 0,
                                    }">
                                        <div class="w-1/12 mr-8">
                                            {{ $t('frontend_add') }} {{ $t('frontend_to') }}
                                        </div>
                                        <div class="flex flex-row w-11/12">
                                            <div class="w-3/4 flex flex-col">
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_product_step8_type') }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            postSaleIntegration.platform
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            postSaleIntegration.platform.slice(1)
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-for="(postSaleIntegrationConfig, postSaleIntegrationConfigIndex) in postSaleIntegration.config"
                                                    :key="postSaleIntegrationConfigIndex" class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ postSaleIntegrationConfig.label }} :
                                                    </div>
                                                    <div>{{ postSaleIntegrationConfig.name }}</div>
                                                </div>
                                            </div>
                                            <div class="w-1/4 flex flex-row justify-end pr-4">
                                                <gd-button size="icon" variant="primary"
                                                    :title="$t('frontend_groovesell_delete_integration')" class="mr-2"
                                                    @click="openConfirmModal(index, postSaleIntegrationIndex)">
                                                    <trash-icon class="h-5 w-5 fill-current"/>
                                                </gd-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <label class="text-grey-dark">{{
                                    $t('frontend_product_step8_no_integrations')
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <label class="text-grey-dark">{{
                            $t('frontend_product_step8_no_integrations_added')
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="confirm-delete-post-sale-integration-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')"
            button-size="auto"
            @confirm="deletePostSaleIntegration"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
    components: {
        TrashIcon,
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        validation: {
            required: true,
        },
        pluginSiteData: {
            required: true,
        },
        availableIntegrations: {
            required: true,
        },

    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: '',
            pricePointIndex: null,
            postSaleIntegrationIndex: null,
        }
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },
    mounted() {
        if (this.pricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    computed: {
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];
            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error) {
                    pricePointErrorTabs.push(`price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`);
                }
            }
            return pricePointErrorTabs;
        },
    },
    methods: {
        handlePricePointsTabChange(newTab) {
            this.pricePointsTab = newTab;
        },
        emitShowPostSaleIntegrationBox(index) {
            this.$emit('show-post-sale-integration-box', index);
        },
        openConfirmModal(index, postSaleIntegrationIndex) {
            this.$root.$emit('modal-open', 'confirm-delete-post-sale-integration-modal');
            this.pricePointIndex = index;
            this.postSaleIntegrationIndex = postSaleIntegrationIndex;
        },
        async deletePostSaleIntegration() {
            this.localModel.pricing_points[this.pricePointIndex].post_sale_integrations.splice(
                this.postSaleIntegrationIndex,
                1
            );
        },
    }
}
</script>