<template>
  <div class="fixed z-50 flex justify-center items-center inset-0">
    <div class="fixed inset-0 w-full h-full opacity-75 bg-secondary"></div>
    <div class="max-h-full w-full p-8 flex flex-col items-center justify-center">
      <div
        class="bg-white rounded-lg shadow-xl relative flex flex-col overflow-hidden w-full max-w-4xl m-auto"
        style="min-height: 70%; max-height: 90%"
      >
        <div class="border-solid border-b border-grey-200 w-full flex flex-row items-center justify-between px-6 py-6 text-dark">
          <span class="text-lg font-bold">
            {{ $t('frontend_add_to_your_order') }}
          </span>
        </div>
        <div class="w-full flex flex-row p-4 overflow-auto">
          <div class="w-full md:w-2/3 flex flex-col">
            <heading
              :option="option"
              :heading="heading"
              :subheading="subheading"
              :thumbnail="thumbnail"
              :thumbnail-img="thumbnailImg"
              :thumbnail-is-square="thumbnailIsSquare"
              :thumbnail-size="thumbnailSize"
              @getValueofQuantity="getValueofQuantity"
            />

            <hr class="border border-grey-300 my-4" />

            <payment-details :option="option" :currency-code="currencyCode"/>

            <video-iframe :post-order-video-details="postOrderVideoDetails"/>

            <div
              class="mt-4 text-grey-700 text-sm overflow-auto slim-scroller bumpDescription"
              v-html="description"
            ></div>

            <div class="flex flex-row w-full">
              <half-circle-spinner
                class="mr-4"
                :animation-duration="1000"
                :size="24"
                color="var(--brand-primary)"
              />
              <span class="mt-2 text-center font-semibold text-grey-700">
                {{ $t('frontend_donot_close_page') }}
              </span>
            </div>
          </div>
          <div class="hidden md:block w-1/3 p-4">
            <div class="border border-grey-300 flex flex-col p-3 rounded">
              <buy-and-no-thanks-buttons
                :buynow-text="buynowText"
                :is-processing="isProcessing"
                :product="product"
                @purchasePostSaleBump="purchasePostSaleBump"
                @clickedNoThanks="clickedNoThanks"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-center mt-2 mb-4">
          <gd-button
            size="auto"
            class="mr-4 mt-3"
            @click="closePreview"
          >
            {{ $t('frontend_donot_close_preview') }}
          </gd-button>
        </div>
        <div class="flex flex-col z-50 w-full block md:hidden p-3">
          <buy-and-no-thanks-buttons
            :buynow-text="buynowText"
            :is-processing="isProcessing"
            :product="product"
            @purchasePostSaleBump="purchasePostSaleBump"
            @clickedNoThanks="clickedNoThanks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from './post-sale-bumps-partials/light-box/Heading.vue';
import PaymentDetails from './post-sale-bumps-partials/light-box/PaymentDetails.vue';
import VideoIframe from './post-sale-bumps-partials/VideoIframe.vue';
import BuyAndNoThanksButtons from './post-sale-bumps-partials/light-box/BuyAndNoThanksButtons.vue';

export default {
  name: 'LightBoxBumpStyle',
  components: {
    Heading,
    PaymentDetails,
    VideoIframe,
    BuyAndNoThanksButtons,
  },
  props: {
    heading: String,
    subheading: String,
    description: String,
    buynowText: String,
    option: {},
    currencyCode: {
      default: '$',
    },
    thankYouPage: {},
    thumbnail: {
      default: 0,
    },
    postOrderVideoDetails: {},
    thumbnailImg: {},
    thumbnailIsSquare: {},
    thumbnailSize: {},
    product: {},
    isProcessing: {},
    width: { default: '50%' },
    height: { default: '' },
  },
  methods: {
    getValueofQuantity(selectedQuantity) {
      this.option.selected_quantity = selectedQuantity;
      this.$emit('onChangedQuantity', this.option.selected_quantity);
    },
    purchasePostSaleBump() {
      this.$emit('onPurchasePostSaleBump');
    },
    clickedNoThanks() {
      this.$emit('onClickedNoThanks');
    },
    closePreview() {
      this.$emit('onClickedClosePreview');
    },
  },
};
</script>

<style>
.embed-container {
  margin-bottom: 1.1em;
  margin-top: 1.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}
.slim-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.slim-scroller::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.slim-scroller::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.bumpDescription ul {
  list-style-type: disc;
}

.bumpDescription ol {
  list-style-type: decimal;
}

.bumpDescription li {
  list-style-position: inside;
}
</style>
