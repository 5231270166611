<template>
    <div>
        <div class="flex flex-col w-full mb-6 mt-4">
            <div class="flex flex-row w-2/3">
                <div v-for="(supportType, index) in supportTypes" :key="index" class="flex flex-1 mr-4">
                    <gd-custom-radio v-model="local_is_support_enabled" :option="supportType"></gd-custom-radio>
                </div>
            </div>

            <div v-if="local_is_support_enabled" class="w-full flex flex-col">
                <div class="flex flex-row w-full">
                    <div class="flex flex-row w-2/3 pr-4 mb-6">
                        <div class="w-1/3 flex flex-col">
                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                $t('frontend_type')
                            }}</label>
                            <div class="w-full flex flex-row">
                                <div class="w-full mr-4">
                                    <gd-dropdown
                                        v-model="local_support_info_type"
                                        size="full"
                                        :options="supportInfoTypeOptions"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="w-2/3 flex flex-col">
                            <label class="font-semibold text-sm text-grey-700 mb-2" v-if="local_support_info_type == 1">{{
                                $t('frontend_helpdesk_URL') }}</label>
                            <label class="font-semibold text-sm text-grey-700 mb-2" v-if="local_support_info_type == 2">{{
                                $t('frontend_support_email') }}</label>
                            <div class="w-full flex flex-row">
                                <text-input v-if="local_support_info_type == 1" v-model="local_support_info_value"
                                    :validator="validation.localModel.support_info_value" attribute="URL"
                                    value="local_support_info_value" placeholder="URL" />
                                <text-input v-else-if="local_support_info_type == 2" v-model="local_support_info_value"
                                    :validator="validation.localModel.support_info_value" attribute="Email"
                                    value="local_support_info_value" placeholder="Email" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        is_support_enabled: {
            default: false,
        },
        support_info_type: {
            default: 1,
        },
        support_info_value: {
            default: '',
        },
        validation: {
            required: true,
        },

    },

    watch: {
        local_is_support_enabled() {
            this.$emit('update-model', {
                is_support_enabled: this.local_is_support_enabled
            });
        },
        local_support_info_type() {
            this.$emit('update-model', {
                support_info_type: this.local_support_info_type
            });
        },
        local_support_info_value() {
            this.validation.localModel.support_info_value.$touch();
            this.$emit('update-model', {
                support_info_value: this.local_support_info_value
            });
        },
    },
    data() {
        return {
            supportTypes: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step4_dont_add_support_info'),
                    description: this.$t('frontend_product_step4_no_support_info_to_receipts'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step4_add_support_info'),
                    description: this.$t('frontend_product_step4_support_info_to_receipts'),
                },
            ],
            local_is_support_enabled: this.is_support_enabled,
            local_support_info_type: this.support_info_type,
            local_support_info_value: this.support_info_value,
        }
    },
    computed: {
        supportInfoTypeOptions() {
            return [
                { value: 1, text: this.$t('frontend_url') },
                { value: 2, text: this.$t('frontend_email') },
            ];
        },
    },
}
</script>
