<template>
  <div class="min-h-full h-auto bg-secondary pb-6">
    <wizard
      :tabs="tabs"
      :current-tab="currentTab"
      last-tab="step4"
      @saveAndExitWizard="saveAndExitWizard"
      @validateRef="validateRef"
    />

    <v-wait for="create_contract">
      <div class="px-5">
        <div class="relative flex flex-col justify-start w-full h-full mt-8 container mx-auto">
            <step1
              v-if="currentTab === 'step1'"
              ref="step1"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('step1', $event)"
            ></step1>

            <step2
              v-if="currentTab === 'step2'"
              ref="step2"
              :model="model"
              :funnels="funnels"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('step2', $event)"
            ></step2>

            <step3
              v-if="currentTab === 'step3'"
              ref="step3"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('step3', $event)"
            ></step3>

            <step4
              v-if="currentTab === 'step4'"
              ref="step4"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful('step4', $event)"
            ></step4>
        </div>
      </div>
    </v-wait>

    <portal to="headerBreadCrumb">
      <div class="cursor-pointer flex items-center -ml-4" @click="sendToContracts">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import Contract from '@/models/Contract';
import axios from 'axios';
import step1 from './step1';
import step2 from './step2';
import step3 from './step3';
import step4 from './step4';
import Wizard from '@/components/Wizard';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

export default {
  layout: 'defaultWithoutSidebar',
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('create_contract') };
  },
  components: {
    step1,
    step2,
    step3,
    step4,
    Wizard,
    ChevronLeftIcon,
  },
  data() {
    return {
      tabs: [
        {
          title: `<span class="text-sm font-semibold">1. ${this.$t('frontend_groovesell_contracts_create_tab1')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step1'
        },
        {
          title: `<span class="text-sm font-semibold">2. ${this.$t('frontend_groovesell_contracts_create_tab2')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step2'
        },
        {
          title: `<span class="text-sm font-semibold">3. ${this.$t('frontend_groovesell_contracts_create_tab3')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step3'
        },
        {
          title: `<span class="text-sm font-semibold">4. ${this.$t('frontend_groovesell_contracts_create_tab4')}<span>`,
          value: 'step4'
        }
      ],
      postValidationTab: 'step1',
      currentTab: 'step1',
      contract: null,
      model: {
        name: '',
        description: '',
        funnel_id: null,
        product_id: null,
        price_point_id: null,
        partners: [
          {
            partner_id: 0,
            username: this.$t('me_vendor'),
            percentage: 100
          }
        ]
      },
      funnels: null,
      share_type: 1,
      contractError: '',
      contractExist: false
    };
  },
  async created() {
    this.$wait.start('create_contract');

    if (this.$route.params.contract_id == undefined) {
      this.contract = new Contract({});
    } else {
      await this.loadContractDetails(this.$route.params.contract_id);
    }

    await this.loadFunnelsData();

    this.$wait.end('create_contract');
  },
  methods: {
    async loadContractDetails(contractId) {
      this.contract = await Contract.$find(contractId);

      this.model = {
        id: this.contract.id,
        name: this.contract.name,
        description: this.contract.description,
        funnel_id: this.contract.funnel_id,
        product_id: this.contract.product_id,
        price_point_id: this.contract.price_point_id,
        share_type: this.contract.share_type,
        partners: []
      };

      if (this.contract.partners.length > 0) {
        const me = {
          partner_id: 0,
          username: this.$t('me_vendor'),
          percentage: 0
        };

        this.contract.partners.forEach(element => {
          this.model.partners.push({
            partner_id: element.partner_id,
            username: element.user.name,
            percentage: element.percentage
          });
        });

        if (this.contract.share_type == 1) {
          this.contract.partners.forEach(val => {
            me.percentage += +val.percentage;
          });

          me.percentage = 100 - me.percentage;
          this.model.partners.unshift(me);
        }
      }
    },
    async loadFunnelsData() {
      const { data } = await axios.get('funnels-breakdown');
      this.funnels = data.funnels;
      this.affiliate_funnels = data.affiliate_funnels;
    },
    validateRef(nextTab, currentTab) {
      this.postValidationTab = nextTab;

      return this.$refs[currentTab].validate();
    },
    sendToContracts() {
      this.$router.push({ name: 'contracts' });
    },
    async saveAndExitWizard() {
      this.$router.push({ name: 'contracts' });
    },
    async saveContractInfo() {
      this.$loader.start('saveContractInfo');
      this.updateContractInfo();
      await this.saveContract();
      this.updateModelFromContract();
      this.$loader.end('saveContractInfo');
    },
    async saveContractInfoStep2() {
      this.$loader.start('saveContractInfoStep2');
      this.updateContractInfo(true);

      let savedContractData = await this.contract.custom('validateStep').save();

      if (savedContractData.status === 'error') {
        savedContractData.status = null;
        this.$refs.step2.showContractExistError();
        this.$loader.end('saveContractInfoStep2');
        return false;
      }

      await this.updateModelFromContract(true);
      this.$loader.end('saveContractInfoStep2');
      return true;
    },
    updateContractInfo(isStep2 = false) {
      this.contract.name = this.model.name;
      this.contract.description = this.model.description;
      this.contract.funnel_id = this.model.funnel_id;
      this.contract.product_id = this.model.product_id;
      this.contract.price_point_id = this.model.price_point_id;
      this.contract.partners = this.model.partners;

      if (!isStep2) {
        this.contract.share_type = this.model.share_type;
      }
    },
    async saveContract() {
      const savedContractData = await this.contract.save();
      this.contract = await Contract.$find(savedContractData.data.id);
    },
    updateModelFromContract(isStep2 = false) {
      this.model.id = this.contract.id;
      this.model.name = this.contract.name;
      this.model.funnel_id = this.contract.funnel_id;
      this.model.product_id = this.contract.product_id;
      this.model.price_point_id = this.contract.price_point_id;

      if (!isStep2) {
        this.model.share_type = this.contract.share_type;
      }
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    async stepValidationSuccessful(name, isValid) {
      if (!isValid) {
        return;
      }

      if (name == 'step2') {
        if (await this.saveContractInfoStep2()) {
          this.currentTab = this.postValidationTab;
        }
      } else {
        await this.saveContractInfo();
        this.currentTab = this.postValidationTab;
      }
    },
  }
};
</script>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
