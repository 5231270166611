<template>
  <default
    sidebar="none"
    :has-padding="false"
    :full-width="true"
    :full-height="true"
  />
</template>
<script>
import Default from '@/layouts/default';

export default {
  components: {
    Default
  }
};
</script>
