<template>
  <gd-modal name="clone-funnel-modal" :title="$t('frontend_groovesell_modal_clone_product_funnel')">
    <VuePerfectScrollbar>
      <div class="scroll-modal-height">
        <div class="flex flex-col p-10">

          <!-- Funnel Name -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnels_name') }}</label>
            <text-input
              v-model="cloneFunnelModel.name"
              :validator="$v.cloneFunnelModel.name"
              :attribute="$t('frontend_funnels_name')"
              value="cloneFunnelModel.name"
              :placeholder="$t('frontend_funnels_name')"
            />
          </div>

          <!-- Internal Name -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnels_internal_name') }}</label>
            <text-input
              v-model="cloneFunnelModel.internal_name"
              :validator="$v.cloneFunnelModel.internal_name"
              :attribute="$t('frontend_funnels_internal_name')"
              value="cloneFunnelModel.internal_name"
              :placeholder="$t('frontend_funnels_internal_name')"
            />
          </div>

          <!-- Description -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnels_description') }}</label>
            <text-input
              v-model="cloneFunnelModel.description"
              :validator="$v.cloneFunnelModel.description"
              :attribute="$t('frontend_funnels_description')"
              value="cloneFunnelModel.description"
              :placeholder="$t('frontend_funnels_description')"
            />
          </div>

          <!-- Currency -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_funnels_currency') }}</label>
            <gd-dropdown
              v-model="cloneFunnelModel.currency_code"
              size="full"
              :options="currencyOptions"
            />
          </div>

          <!-- Show in Marketplace -->
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2 mr-2 w-1/2">
              {{ $t('frontend_funnels_show_in_marketplace') }}
            </label>
            <div class="w-1/2 flex flex-row justify-end">
              <gd-toggle v-model="cloneFunnelModel.show_in_marketplace" :value="cloneFunnelModel.show_in_marketplace" />
            </div>
          </div>

          <!-- Copy Affiliates -->
          <div class="flex flex-row w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2 mr-2 w-1/2">
              {{ $t('frontend_funnels_copy_affiliates') }}
            </label>
            <div class="w-1/2 flex flex-row justify-end">
              <gd-toggle v-model="cloneFunnelModel.copy_affiliates" :value="cloneFunnelModel.copy_affiliates" />
            </div>
          </div>

          <!-- Clone Button -->
          <div class="flex flex-row w-full mb-6 justify-end">
            <gd-button size="md" variant="primary" @click="updateCloneFunnel">
              {{ $t('frontend_funnels_clone') }}
            </gd-button>
          </div>

        </div>
      </div>
    </VuePerfectScrollbar>
  </gd-modal>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import themeConfig from '@/theme/themeConfig.js';

export default {
  components: {
    VuePerfectScrollbar,
  },
  mounted() {
      this.cloneFunnelModel.internal_name = this.originalFunnel.internal_name;
      this.cloneFunnelModel.name = this.originalFunnel.name;
      this.cloneFunnelModel.description = this.originalFunnel.description;
      this.cloneFunnelModel.show_in_marketplace = this.originalFunnel.show_in_marketplace;
      this.cloneFunnelModel.currency_code = this.originalFunnel.currency_code;
      this.cloneFunnelModel.is_live = 0;

      this.$root.$emit('modal-open', 'clone-funnel-modal');
    },
  data() {
    return {
      cloneFunnelModel: {
        id: 0,
        internal_name: '',
        name: '',
        description: '',
        slug: '',
        is_live: '',
        color: themeConfig.appPrimaryColor,
        currency_code: 'usd',
        show_in_marketplace: 1,
        domain: 0,
        sender_id: null,
        copy_affiliates: 0,
      },
    };
  },
  computed: {
    currencyOptions() {
      return [
        { value: 'usd', text: 'USD ($)' },
        { value: 'gbp', text: 'GBP (£)' },
        { value: 'cad', text: 'CAD ($)' },
        { value: 'aud', text: 'AUD ($)' },
        { value: 'nzd', text: 'NZD ($)' },
        { value: 'eur', text: 'EUR (€)' },
        { value: 'sek', text: 'SEK (kr)' },
        { value: 'sgd', text: 'SGD ($)' },
        { value: 'brl', text: 'BRL (R$)' },
        { value: 'mxn', text: 'MXN ($)' },
        { value: 'php', text: 'PHP (₱)' },
        { value: 'inr', text: 'INR (₹)' },
        { value: 'myr', text: 'MYR (RM)' },
        { value: 'pln', text: 'PLN (zł)' },
        { value: 'bgn', text: 'BGN (лв)' },
        { value: 'dkk', text: 'DKK (kr)' },
        { value: 'zar', text: 'ZAR (R)' },
        { value: 'czk', text: 'CZK (Kč)' },
        { value: 'thb', text: 'THB (฿)' },
        { value: 'vnd', text: 'VND (฿₫)' },
        { value: 'hkd', text: 'HKD (HK$)' },
        { value: 'idr', text: 'IDR (Rp)' },
        { value: 'jpy', text: 'JPY (¥)' },
        { value: 'rub', text: 'RUB (₽)' },
        { value: 'chf', text: 'CHF (CHf)' },
      ];
    },
  },
  validations: {
    cloneFunnelModel: {
      internal_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
    },
  },
  props: {
    originalFunnel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async updateCloneFunnel() {
      if (
        !this.$v.cloneFunnelModel.internal_name.$invalid &&
        !this.$v.cloneFunnelModel.name.$invalid &&
        !this.$v.cloneFunnelModel.description.$invalid
      ) {
        this.$loader.start('clone-funnel-modal');
        const updateFunnel = await axios.post('update-clone-funnel', {
          internal_name: this.cloneFunnelModel.internal_name,
          name: this.cloneFunnelModel.name,
          description: this.cloneFunnelModel.description,
          currency_code: this.cloneFunnelModel.currency_code,
          show_in_marketplace: this.cloneFunnelModel.show_in_marketplace,
          is_live: this.cloneFunnelModel.is_live,
          clone_funnel_id: this.originalFunnel.id,
          copy_affiliates: this.cloneFunnelModel.copy_affiliates,
        });
        this.$loader.end('clone-funnel-modal');
        const funnelId = updateFunnel.data.data.id;
        router.push({ name: 'products', params: { id: funnelId } });
      }
    },
  }
}
</script>
