export default function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    return { selector: to.hash };
  }

  const [component] = this.getMatchedComponents({ ...to }).slice(-1);

  if (component && component.scrollToTop === false) {
     return {};
   }

  return { x: 0, y: 0 };
}
