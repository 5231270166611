var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-row w-full justify-between"},[_c('div',[_vm._t("header-left")],2),_c('div',[_c('div',[_vm._t("header-right")],2),_c('div',{staticClass:"flex flex-row justify-end"},[_c('div',{staticClass:"flex flex-row justify-center items-center h-10 w-10 border border-grey-200 cursor-pointer rounded-sm",class:{
            'bg-primary text-white': _vm.viewType == 'grid',
            'bg-white text-primary': _vm.viewType == 'list',
          },on:{"click":function($event){_vm.viewType = 'grid'}}},[_c('grid-icon',{staticClass:"w-5 h-5 fill-current"})],1),_c('div',{staticClass:"flex flex-row justify-center items-center h-10 w-10 border border-grey-200 cursor-pointer rounded-sm",class:{
            'bg-primary text-white': _vm.viewType == 'list',
            'bg-white text-primary': _vm.viewType == 'grid',
          },on:{"click":function($event){_vm.viewType = 'list'}}},[_c('list-icon',{staticClass:"w-5 h-5 fill-current"})],1)])])]),_c('div',[(_vm.viewType == 'grid')?_vm._t("gridContent"):_vm._t("listContent")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }