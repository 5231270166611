<template>
    <gd-modal name="new-product-modal" :title="$t('frontend_groovesell_create_new_product')" width="auto">
        <div style="min-width: 50vw">
            <div class="flex flex-col m-10 verma">
                <div class="w-full flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_new_product_type')
                    }}</label>
                    <div class="flex flex-row w-full mb-6 justify-start">
                        <div class="flex flex-col w-1/4 mr-4">
                            <product-radio v-model="newproductFlow" option-name="Frontend Product"
                                option-icon="FrontendProduct" :coming-soon="false"></product-radio>
                        </div>
                        <div class="flex flex-col w-1/4 mr-4">
                            <product-radio v-model="newproductFlow" option-name="Upsell/Downsell" option-icon="Upsell"
                                :coming-soon="false"></product-radio>
                        </div>
                        <div class="flex flex-col w-1/4 mr-4">
                            <product-radio v-model="newproductFlow" option-name="Order Bumps" option-icon="OrderBumps"
                                :coming-soon="false"></product-radio>
                        </div>
                        <div class="flex flex-col w-1/4 mr-4">
                            <product-radio v-model="newproductFlow" option-name="Upsell / Add-to-Cart Order Bumps"
                                option-icon="ATCBumps" :coming-soon="isAdmin ? false : true"></product-radio>
                        </div>

                    </div>
                </div>
                <div class="flex flex-row items-end justify-end m-6">
                    <gd-button variant="primary" size="md" @click="gotoProductCreationWizard">{{
                        $t('frontend_create') }}</gd-button>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },
        funnel: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            newproductFlow: ''
        }
    },

    methods: {
        gotoProductCreationWizard() {
            if (this.newproductFlow === 'Upsell / Add-to-Cart Order Bumps') {
                this.$router.push({
                    name: 'create_bump',
                    params: { id: this.funnel.id },
                });
            } else {
                let productType = 3;
                if (this.newproductFlow === 'Frontend Product') {
                    productType = 1;
                } else if (this.newproductFlow === 'Upsell/Downsell') {
                    productType = 2;
                }
                this.$router.push({
                    name: 'create_product',
                    params: { id: this.funnel.id },
                    query: { product_type: productType },
                });
            }
        },
    }
}
</script>