<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step9_affiliates') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step9_configure')
        }}</span>
      </div>
      <hr class="border-grey-100 -mx-4" />
      <div class="flex flex-col w-full mb-6 mt-8">
        <div class="w-1/2 flex flex-row">
          <div v-for="(
              affiliateSettingsType, affiliateSettingsIndex
            ) in affiliateSettingsTypes" :key="affiliateSettingsIndex" class="flex w-1/2 mr-4">
            <gd-custom-radio v-model="localModel.affiliates_enabled" :option="affiliateSettingsType"></gd-custom-radio>
          </div>
        </div>
      </div>

      <!-- {{localModel.commission_after_processing}} -->
      <div v-if="localModel.affiliates_enabled" class="mt-2">
        <!-- flex-col w-1/2 -->
        <div class="flex flex-col mb-2">
          <div class="flex flex-row">
            {{ $t('frontend_groovesell_products_step9_calc_commission_question') }}
            <gd-toggle class="ml-2" v-model="localModel.commission_after_processing" />
          </div>
          <div class="mt-2">
            <p class="font-semi-bold text-dark mt-2" v-if="localModel.commission_after_processing">
              <span class="text-red">{{ $t('frontend_note') }}: </span>
              {{ $t('frontend_groovesell_products_step9_commission_after_processing') }}
              <span class="cursor-pointer underline" @click="goToSetupGateways">{{
                $t('frontend_groovesell_products_step9_here') }}</span>.
            </p>
          </div>
        </div>

        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
          <gd-tabs :tabs="affiliateSettingsTabs" :error-tabs="affiliateSettingsErrorTabs"
            :current-tab="affiliateSettingsTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
            :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
            :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
            :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiliateSettingsTabChange" />
        </div>
        <div class="mt-4">
          <jv-blog v-if="affiliateSettingsTab === 'jvblog'" :model="localModel" :validation="$v"
            @update-model="updateModel" />
          <integrations v-else-if="affiliateSettingsTab === 'integrations'" :model="localModel" :validation="$v"
            @update-model="updateModel" :pluginSiteData="pluginSiteData" :availableIntegrations="availableIntegrations"
            :showAffiliateRegistrationIntegrationBox="showAffiliateRegistrationIntegrationBox"
            :deleteAffiliateRegistrationIntegration="openDeleteAffiliateRegistrationIntegrationConfirmModal" />
          <commissions v-else-if="affiliateSettingsTab === 'commissions'" @update-model="updateModel" :validation="$v"
            :model="localModel" />
          <commission-overrides v-else-if="affiliateSettingsTab === 'commissionOverrides'" @update-model="updateModel"
            :validation="$v" :model="localModel" :openCommissionOverridesAffModal="openCommissionOverridesAffModal" />

          <affiliates-landing-pages v-else-if="affiliateSettingsTab === 'affiliatesLandingPages'"
            @update-model="updateModel" :validation="$v" :model="localModel"
            :openCustomLandingPagesAffModal="openCustomLandingPagesAffModal"
            @create-affiliate-landing-page="handleCreateAffiliateLandingPage"
            @delete-affiliate-landing-page="openDeleteAffiliateLandingPageConfirmModal" />

          <data-sharing v-else-if="affiliateSettingsTab === 'data_sharing'" @update-model="updateModel"
            :model="localModel" />

        </div>
      </div>
    </div>

    <gd-modal name="add-new-affiliate-registration-integration-modal"
      :title="$t('frontend_groovemember_step9_add_new_integration')" :no-overflow="true">
      <div class="flex flex-col m-8">
        <div class="flex flex-col w-full mb-6">
          <div class="w-full flex flex-col mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
              $t('frontend_type')
            }}</label>
            <gd-dropdown :v-bind="integrationType" :value="integrationType" :options="availableIntegrations" size="full"
              @change="setValue($event)" @keyup="setValue($event)" />
          </div>
          <div v-if="integrationType && integrationType != ''" class="w-full">
            <config-menu :key="configMenuKey" :initial-values="componentConfigValues" :plugin-id="pluginId"
              :resources-path="resourcesPath" :server-path="serverPath" :plugin="integrationType"
              :app-data="pluginSiteData" layout="vertical" :is-dark="false" :show-button-borders="false"
              :show-notifications-on-save="false" :btn-label="$t('frontend_sites_integration_modal_add_integration')"
              :opened-from="'groovesell'" @update="addAffiliateRegistrationIntegration"
              @menu-updated="configMenuItemsUpdated">
            </config-menu>
          </div>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="commission-overrides-affiliates-modal" :title="$t('frontend_affiliates')" width="70%" height="80%"
      bg-type="dark">
      <div class="px-4">
        <div class="flex pt-2 pr-2">
          <div class="w-1/2 flex flex-row pl-2">
            <affiliates-auto-suggest :funnel="localModel.funnel_id" :excluded="excludedCommissionTierAffiliates"
              @onAffiliateAdded="addedAffiliateToCommissionTier" />
          </div>
        </div>
        <div class="flex mt-2 pt-2 pr-2">
          <div class="w-full flex flex-row">
            <affiliate-list-view :funnel="localModel.funnel_id" :affiliate-ids="selectedCommissionTierAffiliates"
              @onAffiliateRemoved="removedAffiliateFromCommissionTier" />
          </div>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="custom-landing-pages-affiliates-modal" :title="$t('frontend_affiliates')" width="70%" height="80%"
      bg-type="dark">
      <div class="px-4">
        <div class="flex pt-2 pr-2">
          <div class="w-1/2 flex flex-row pl-2">
            <affiliates-auto-suggest :funnel="localModel.funnel_id" :excluded="excludedLandingPageAffiliates"
              @onAffiliateAdded="addedAffiliateToCustomLandingPage" />
          </div>
        </div>
        <div class="flex mt-2 pt-2 pr-2">
          <div class="w-full flex flex-row">
            <affiliate-list-view :funnel="localModel.funnel_id" :affiliate-ids="selectedLandingPageAffiliates"
              @onAffiliateRemoved="removedAffiliateFromCustomLandingPage" />
          </div>
        </div>
      </div>
    </gd-modal>
    <confirm-modal
      name="confirm-delete-affiliate-landing-page-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteAffiliateLandingPage"
    />
    <confirm-modal
      name="confirm-delete-affiliate-registration-integration-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteAffiliateRegistrationIntegration"
    />
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric,
  decimal,
  url,
  minValue,
} from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';

import Commissions from './tabs/affiliates/Commissions';
import CommissionOverrides from './tabs/affiliates/CommissionOverrides';
import AffiliatesLandingPages from './tabs/affiliates/AffiliatesLandingPages';
import DataSharing from './tabs/affiliates/DataSharing';
import JvBlog from './tabs/affiliates/JvBlog';
import Integrations from './tabs/affiliates/Integrations';


export default {
  name: 'Step9',
  components: {
    ModelSelect,
    ConfigMenu,
    AffiliatesAutoSuggest,
    AffiliateListView,
    Commissions,
    CommissionOverrides,
    AffiliatesLandingPages,
    DataSharing,
    JvBlog,
    Integrations,
  },
  props: {
    model: {
      required: true,
    },
    affiliates: {
      required: true,
    },
    resourcesPath: {
      required: true,
    },
    serverPath: {
      required: true,
    },
    pluginSiteData: {
      required: true,
    },
    availableIntegrations: {
      required: true,
    },
  },
  data() {
    return {
      affiliateLandingPageToDeleteIndex: null,
      affiliateRegistrationIndex: null,
      localModel: this.model,
      commissionsIndex: 0,
      affiliateLandingPageIndex: 0,
      affiliateSettingsTypes: [
        {
          id: 0,
          title: this.$t('frontend_product_step9_no_affiliate_program'),
          description: this.$t('frontend_product_step9_no_affiliate_program_desc'),
        },
        {
          id: 1,
          title: this.$t('frontend_product_step9_activate_affiliate_program'),
          description: this.$t('frontend_product_step9_activate_affiliate_program_desc'),
        },
      ],
      affiliateSettingsTab: 'commissions',

      checkoutTab: 'lookfeel',
      pricePointsTab: '',
      commissionOverridePricePointsTab: '',
      landingPagesTab: '',
      commissionOverrideSelectedAffiliate: '',
      integrationType: '',
      configMenuKey: 1,
      componentConfigValues: [],
      pluginId: '',
      integrationType: '',
      pluginMenuItems: [],
      selectedPricePointIndex: 0,
      selectedCommissionTierIndex: 0,
      selectedLandingPageIndex: 0,
      test: true,
    };
  },
  validations: {
    localModel: {
      jv_page: {
        url,
      },
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          commission_details: {
            $each: {
              name: {
                required: requiredIf(function () {
                  return this.affiliateSettingsTab == 'commissionOverrides';
                }),
              },
              trial_commission: {
                commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      
                      return regexp.test(value) && value <= 100;
                    }
                  
                    return regexp.test(value);
                  },
                },
                jv_broker_commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      
                      return regexp.test(value) && value <= 100;
                    }
                   
                    return regexp.test(value);
                  },
                },
              },
              sales_commission: {
                commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
                jv_broker_commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
              },
              rebill_commissions: {
                $each: {
                  commission: {
                    required,
                    decimal,
                    minValue: minValue(0),
                    isValidCommission(value, object) {
                      const regexp = /^\d+(\.\d{1,2})?$/;

                      if (object.commission_type == 'percent') {
                        return regexp.test(value) && value <= 100;
                      }
                      return regexp.test(value);
                    },
                  },
                  no_of_payments: {
                    numeric,
                    minValue: minValue(0),
                  },
                  jv_broker_commission: {
                    required,
                    decimal,
                    minValue: minValue(0),
                    isValidCommission(value, object) {
                      const regexp = /^\d+(\.\d{1,2})?$/;

                      if (object.commission_type == 'percent') {
                        return regexp.test(value) && value <= 100;
                      }
                      return regexp.test(value);
                    },
                  },
                },
              },
            },
          },
          post_sale_details: {
            ipn_url: {
              url,
            },
          },
        },
      },
      waiting_period: {
        required,
        numeric,
        minValue: minValue(0),
      },
      affiliate_landing_pages: {
        required,
        minLength: minLength(1),
        $each: {
          landing_page: {
            url,
          },
          description: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
        },
      },
    },
  },
  computed: {
    landingPageAssignedType() {
      const currentlyActiveLandingPageId = parseInt(
        this.landingPagesTab.split('_')[2]
      );

      const selectedLandingPageIndex = this.localModel.affiliate_landing_pages
        .map((item) => item.id)
        .indexOf(currentlyActiveLandingPageId);

      if (selectedLandingPageIndex != -1) {
        const assignedTo =
          this.localModel.affiliate_landing_pages[selectedLandingPageIndex]
            .assigned_to;

        if (assignedTo.length > 0 && assignedTo[0].id == 0) {
          return 1;
        }
        return 2;
      }

      return 2;
    },

    selectedLandingPageAffiliates() {
      return this.localModel.affiliate_landing_pages[
        this.selectedLandingPageIndex
      ].assigned_to.map((a) => a.id);
    },

    excludedLandingPageAffiliates() {
      return this.selectedLandingPageAffiliates;
    },

    selectedCommissionTierAffiliates() {
      if (
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        }).length > 0 &&
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        })[this.selectedCommissionTierIndex] &&
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        })[this.selectedCommissionTierIndex].assigned_to
      ) {
        return this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        })[this.selectedCommissionTierIndex].assigned_to;
      }
    },

    excludedCommissionTierAffiliates() {
      const allCommissionOverrides = this.localModel.pricing_points[
        this.selectedPricePointIndex
      ].commission_details.filter((obj) => {
        return obj.type != 1;
      });

      let excludedCommissionTierAffiliates = [];

      for (let i = 0; i < allCommissionOverrides.length; i++) {
        excludedCommissionTierAffiliates =
          excludedCommissionTierAffiliates.concat(
            allCommissionOverrides[i].assigned_to
          );
      }

      return excludedCommissionTierAffiliates;
    },

    affiliatesForCommissionOverrides() {
      const affiliatesDataForCommissionOverrides = [];
      for (let i = 0; i < this.affiliates.length; i++) {
        affiliatesDataForCommissionOverrides.push({
          value: this.affiliates[i].id,
          text: this.affiliates[i].name,
        });
      }

      return affiliatesDataForCommissionOverrides;
    },

    affiliatesForLandingPages() {
      const affiliatesDataForLandingPages = [];
      affiliatesDataForLandingPages.push({
        id: 0,
        name: this.$t('frontend_affiliates_all_affiliates'),
      });
      for (let i = 0; i < this.affiliates.length; i++) {
        affiliatesDataForLandingPages.push(this.affiliates[i]);
      }

      return affiliatesDataForLandingPages;
    },

    affiliateSettingsTabs() {
      if (this.localModel.is_main) {
        return [
          {
            title: this.$t('frontend_product_step9_commisions'),
            value: 'commissions',
          },
          {
            title: this.$t('frontend_product_step9_commision_overrides'),
            value: 'commissionOverrides',
          },
          {
            title: this.$t('frontend_product_step9_custom_landing'),
            value: 'affiliatesLandingPages',
          },
          {
            title: this.$t('frontend_product_step9_jv'),
            value: 'jvblog',
          },
          {
            title: this.$t('frontend_product_step9_integrations'),
            value: 'integrations',
          },
          {
            title: this.$t('frontend_product_step9_data_share'),
            value: 'data_sharing',
          },
        ];
      }
      return [
        {
          title: this.$t('frontend_product_step9_commisions'),
          value: 'commissions',
        },
        {
          title: this.$t('frontend_product_step9_commision_overrides'),
          value: 'commissionOverrides',
        },
      ];
    },
    affiliateSettingsErrorTabs() {
      const affiliateSettingErrorTabs = [];

      if (this.$v.localModel.jv_page.$error) {
        affiliateSettingErrorTabs.push('jvblog');
      }

      if (this.$v.localModel.pricing_points.$error && this.affiliateSettingsTab == 'commissions') {
        affiliateSettingErrorTabs.push('commissions');
      }

      if (this.$v.localModel.pricing_points.$error && this.affiliateSettingsTab == 'commissionOverrides') {
        affiliateSettingErrorTabs.push('commissionOverrides');
      }

      if (this.$v.localModel.affiliate_landing_pages.$error) {
        affiliateSettingErrorTabs.push('affiliatesLandingPages');
      }
      return affiliateSettingErrorTabs;
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  created() { },
  mounted() {
    if (
      this.pricePointsTab == '' &&
      this.localModel.pricing_points.length > 0
    ) {
      this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
    }

    if (
      this.commissionOverridePricePointsTab == '' &&
      this.localModel.pricing_points.length > 0
    ) {
      this.commissionOverridePricePointsTab = `commission_override_price_point_${this.localModel.pricing_points[0].id}`;
    }

    if (
      this.landingPagesTab == '' &&
      this.localModel.affiliate_landing_pages.length > 0
    ) {
      this.landingPagesTab = `landing_page_${this.localModel.affiliate_landing_pages[0].id}`;
    }
  },
  methods: {
    updateModel(value) {
      this.localModel = value;
    },
    openCustomLandingPagesAffModal(index) {
      this.selectedLandingPageIndex = index;
      this.$nextTick(() => {
        this.$root.$emit('modal-open', 'custom-landing-pages-affiliates-modal');
      });
    },

    openCommissionOverridesAffModal(index, commissionTierIndex) {
      this.selectedPricePointIndex = index;
      this.selectedCommissionTierIndex = commissionTierIndex;

      this.$nextTick(() => {
        this.$root.$emit('modal-open', 'commission-overrides-affiliates-modal');
      });
    },

    addedAffiliateToCustomLandingPage(affiliate) {
      if (
        this.localModel.affiliate_landing_pages[
          this.selectedLandingPageIndex
        ].assigned_to
          .map((a) => a.id)
          .indexOf(affiliate.id) === -1
      ) {
        this.localModel.affiliate_landing_pages[
          this.selectedLandingPageIndex
        ].assigned_to.push(affiliate);
      }
    },

    removedAffiliateFromCustomLandingPage(affiliateId) {
      const removedAffiliate = this.localModel.affiliate_landing_pages[
        this.selectedLandingPageIndex
      ].assigned_to
        .map((item) => item.id)
        .indexOf(affiliateId);

      this.localModel.affiliate_landing_pages[
        this.selectedLandingPageIndex
      ].assigned_to.splice(removedAffiliate, 1);
    },

    addedAffiliateToCommissionTier(affiliate) {
  
      if (
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        })[this.selectedCommissionTierIndex].assigned_to == null
      ) {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details.filter((obj) => {
          return obj.type != 1;
        })[this.selectedCommissionTierIndex].assigned_to = [];

        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details
          .filter((obj) => {
            return obj.type != 1;
          })
        [this.selectedCommissionTierIndex].assigned_to.push(affiliate.id);
      } else if (
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details
          .filter((obj) => {
            return obj.type != 1;
          })
        [this.selectedCommissionTierIndex].assigned_to.indexOf(
          affiliate.id
        ) === -1
      ) {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].commission_details
          .filter((obj) => {
            return obj.type != 1;
          })
        [this.selectedCommissionTierIndex].assigned_to.push(affiliate.id);
      }
    },

    removedAffiliateFromCommissionTier(affiliateId) {
      const removedAffiliate = this.localModel.pricing_points[
        this.selectedPricePointIndex
      ].commission_details
        .filter((obj) => {
          return obj.type != 1;
        })
      [this.selectedCommissionTierIndex].assigned_to.indexOf(affiliateId);

      this.localModel.pricing_points[
        this.selectedPricePointIndex
      ].commission_details
        .filter((obj) => {
          return obj.type != 1;
        })
      [this.selectedCommissionTierIndex].assigned_to.splice(
        removedAffiliate,
        1
      );
    },

    showAffiliateRegistrationIntegrationBox(index) {
      this.integrationType = '';
      this.$root.$emit(
        'modal-open',
        'add-new-affiliate-registration-integration-modal'
      );
    },

    setValue(value) {
      this.integrationType = value;
      this.pluginId = '';
      this.componentConfigValues = [];
      this.configMenuKey = this.configMenuKey + 1;
    },

    addAffiliateRegistrationIntegration(data) {
      const integrationConfig = data.config;
      const self = this;

      const integrationInfo = [];

      for (const index in integrationConfig) {
        const value = integrationConfig[index];

        if (value) {
          self.pluginMenuItems.forEach((menuValue, menuIndex) => {
            if (menuValue.key == index) {
              const { options } = menuValue;

              const objChecker = options.some((val) => {
                return typeof val === 'object';
              });

              let correspondingOption = value;
              if (objChecker) {
                correspondingOption = options.find((o) => o.value === value);
              }

              const singleIntegrationField = {};
              singleIntegrationField.index = index;
              singleIntegrationField.value = value;
              singleIntegrationField.label = menuValue.label;

              if (objChecker) {
                singleIntegrationField.name = correspondingOption.text;
              } else {
                singleIntegrationField.name = value;
              }

              integrationInfo.push(singleIntegrationField);
            }
          });
        }
      }

      const completeIntegrationInfo = {};
      completeIntegrationInfo.platform = this.integrationType.split('-')[0];
      completeIntegrationInfo.config = integrationInfo;

      if (this.localModel.affiliate_registration_integrations) {
        this.localModel.affiliate_registration_integrations.push(
          completeIntegrationInfo
        );
      } else {
        this.localModel.affiliate_registration_integrations = [];
        this.localModel.affiliate_registration_integrations.push(
          completeIntegrationInfo
        );
      }

      this.$root.$emit(
        'modal-close',
        'add-new-affiliate-registration-integration-modal'
      );
    },

    configMenuItemsUpdated(data) {
      this.pluginMenuItems = data.menuItems;
    },

    onAffiliateChange(affiliateLandingPageIndex) {
      if (
        this.localModel.affiliate_landing_pages[affiliateLandingPageIndex]
          .assigned_to &&
        this.localModel.affiliate_landing_pages[affiliateLandingPageIndex]
          .assigned_to.length > 0
      ) {
        const lastAddedAffiliateId =
          this.localModel.affiliate_landing_pages[affiliateLandingPageIndex]
            .assigned_to[
            this.localModel.affiliate_landing_pages[affiliateLandingPageIndex]
              .assigned_to.length - 1
          ].id;

        if (lastAddedAffiliateId == 0) {
          this.localModel.affiliate_landing_pages[
            affiliateLandingPageIndex
          ].assigned_to =
            this.localModel.affiliate_landing_pages[
              affiliateLandingPageIndex
            ].assigned_to.slice(-1);
        } else {
          this.localModel.affiliate_landing_pages[
            affiliateLandingPageIndex
          ].assigned_to = this.localModel.affiliate_landing_pages[
            affiliateLandingPageIndex
          ].assigned_to.filter((obj) => {
            return obj.id !== 0;
          });
        }
      }
    },

    deleteAffiliateFromCommissionTier(
      index,
      commissionTierIndex,
      commissionTierAffiliate
    ) {
      const commissionOverrideTier = this.localModel.pricing_points[
        index
      ].commission_details.filter((obj) => {
        return obj.type != 1;
      })[commissionTierIndex];

      if (commissionOverrideTier.assigned_to) {
        commissionOverrideTier.assigned_to =
          commissionOverrideTier.assigned_to.filter(
            (item) => item !== commissionTierAffiliate
          );
      }
    },

    affiliatesForThisPricePoint(index) {
      let assignedAffiliatesArray = [];
      this.localModel.pricing_points[index].commission_details
        .filter((obj) => {
          return obj.type != 1 && obj.assigned_to != null;
        })
        .forEach((arrayItem) => {
          assignedAffiliatesArray = assignedAffiliatesArray.concat(
            arrayItem.assigned_to
          );
        });

      return this.affiliatesForCommissionOverrides.filter((obj) => {
        return !assignedAffiliatesArray.includes(obj.value);
      });
    },

    addAffiliatesToCommissionOverride(index, commissionTierIndex) {
      const commissionOverrideTier = this.localModel.pricing_points[
        index
      ].commission_details.filter((obj) => {
        return obj.type != 1;
      })[commissionTierIndex];

      if (commissionOverrideTier.assigned_to) {
        commissionOverrideTier.assigned_to.push(
          this.commissionOverrideSelectedAffiliate
        );
      } else {
        commissionOverrideTier.assigned_to = [];
        commissionOverrideTier.assigned_to.push(
          this.commissionOverrideSelectedAffiliate
        );
      }

      // this.localModel.pricing_points[index].commission_details[commissionTierIndex].assigned_to.push(this.commissionOverrideSelectedAffiliate);
    },

    handleAffiliateSettingsTabChange(newTab) {
      this.affiliateSettingsTab = newTab;
    },

    getTrialCommissionDetails(details) {
      const trialCommissionObj = details.filter((elem) => {
        if (elem.type == '0' || elem.type == 0) {
          return elem;
        }
      });

      if (trialCommissionObj.length > 0) {
        return trialCommissionObj[0];
      }
      return false;
    },
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    commissionsNavigate(navigateMethod, index) {
      this.$refs.commissionsWizard.changeTab(this.commissionsIndex, index);
    },

    affiliateLandingPageNavigate(navigateMethod, index) {
      this.$refs.affiliateLandingPagesWizard.changeTab(
        this.affiliateLandingPageIndex,
        index
      );
    },
    handleCreateAffiliateLandingPage() {
      this.createAffiliateLandingPage();
    },
    async createAffiliateLandingPage() {
      const newLandingPageId = this.generateNumber();
      this.localModel.affiliate_landing_pages.push({
        id: newLandingPageId,
        landing_page: this.localModel.sales_page,
        description: this.$t('frontend_groovesell_affiliate_landing_page'),
        assigned_to: [
          {
            id: 0,
            name: this.$t('frontend_affiliates_all_affiliates'),
          },
        ],
      });

      this.landingPagesTab = `landing_page_${newLandingPageId}`;
      this.$emit('saveProdInfo');
    },

    affiliateName(affiliateId) {
      if (affiliateId) {
        const affiliate = this.affiliatesForCommissionOverrides.find((obj) => {
          return obj.value === affiliateId;
        });

        return affiliate.text;
      }
      return this.$t('frontend_affiliates_all_affiliates');
    },
    openDeleteAffiliateLandingPageConfirmModal(index) {
      this.$root.$emit('modal-open', 'confirm-delete-affiliate-landing-page-modal');
      this.affiliateLandingPageToDeleteIndex = index;
    },
    async deleteAffiliateLandingPage() {
      this.localModel.affiliate_landing_pages.splice(this.affiliateLandingPageToDeleteIndex, 1);

      const nextOpenIndex = this.affiliateLandingPageToDeleteIndex - 1;
      const nextOpenLandingPageId =
        this.localModel.affiliate_landing_pages[nextOpenIndex].id;
      this.landingPagesTab = `landing_page_${nextOpenLandingPageId}`;
        this.$emit('saveProdInfo');
    },

    onCommissionOverrideAffiliateChange() { },

    onLandingPageAffiliateChange() {
      if (
        this.localModel.affiliate_landing_pages[this.affiliateLandingPageIndex]
          .assigned_to &&
        this.localModel.affiliate_landing_pages[this.affiliateLandingPageIndex]
          .assigned_to.length > 0
      ) {
        const lastAddedAffiliateId =
          this.localModel.affiliate_landing_pages[
            this.affiliateLandingPageIndex
          ].assigned_to[
            this.localModel.affiliate_landing_pages[
              this.affiliateLandingPageIndex
            ].assigned_to.length - 1
          ].id;

        if (lastAddedAffiliateId == 0) {
          this.localModel.affiliate_landing_pages[
            this.affiliateLandingPageIndex
          ].assigned_to =
            this.localModel.affiliate_landing_pages[
              this.affiliateLandingPageIndex
            ].assigned_to.slice(-1);
        } else {
          this.localModel.affiliate_landing_pages[
            this.affiliateLandingPageIndex
          ].assigned_to = this.localModel.affiliate_landing_pages[
            this.affiliateLandingPageIndex
          ].assigned_to.filter((obj) => {
            return obj.id !== 0;
          });
        }
      }
    },

    generateNumber() {
      const min = 1;
      const max = 10000;
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    openDeleteAffiliateRegistrationIntegrationConfirmModal(affiliateRegistrationIndex) {
      this.$root.$emit('modal-open', 'confirm-delete-affiliate-registration-integration-modal');
      this.affiliateRegistrationIndex = affiliateRegistrationIndex;
    },
    async deleteAffiliateRegistrationIntegration() {
        this.localModel.affiliate_registration_integrations.splice(
          this.affiliateRegistrationIndex,
          1
        );
    },
    goToSetupGateways() {
      const routeData = this.$router.resolve({ name: 'vendor_settings' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style>
.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>
