import MyCoupons from '@/pages/coupons/index';
import CreateCoupon from '@/pages/coupons/create';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/coupons',
    name: 'coupons',
    component: MyCoupons,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_coupons')
    }
  },
  {
    path: '/coupons/create',
    name: 'create_coupon',
    component: CreateCoupon,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_create_new_coupon')
    }
  },
  {
    path: '/coupons/create/:coupon_id',
    name: 'edit_coupon',
    component: CreateCoupon,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_coupon')
    }
  }
];
