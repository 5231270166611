<template>
  <gd-modal
    name="mark-as-paid-second-tier-modal"
    :title="$t('frontend_groovesell_process_second_tier_commissions')"
    width="80%"
    bg-type="regular"
    @closed="onClose"
  >
    <div v-if="selectedAffiliate" class="flex">
      <div class="flex flex-col w-2/3 px-4">
        <div class="mt-2">
          <div class="w-full flex flex-row mb-4">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">
              {{ $t('frontend_affiliate_index_amount') }}
            </label>
            <label class="w-full px-1 mt-1 font-semibold text-grey-700">
              {{ selectedAffiliate.human_readable_processed_without_advances }}
            </label>
          </div>

          <div class="w-full flex flex-row mb-4">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">
              {{ $t('frontend_affiliate_index_transaction') }}
            </label>
            <text-input
              v-model="markAsSecondTierPaid.transactionId"
              class="w-2/3"
              value="markAsSecondTierPaid.transactionId"
              :placeholder="$t('frontend_transaction_id')"
            />
          </div>
          <div class="w-full flex flex-row mb-4">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">
              {{ $t('frontend_affiliate_index_comment') }}
            </label>
            <text-input
              v-model="markAsSecondTierPaid.comment"
              class="w-2/3"
              value="markAsSecondTierPaid.comment"
              :placeholder="$t('frontend_comment')"
            />
          </div>
          <div class="flex flex-row w-full mb-6 p-4 justify-center">
            <div class="w-1/3 mr-2">
              <font-awesome-radio
                v-model="markAsSecondTierPaid.gatewayType"
                option-name="Paypal"
                option-value="1"
                :option-enabled="selectedAffiliate.affiliate_paypal"
              >
                <template slot="icon">
                    <paypal-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio>
            </div>
            <div class="w-1/3 mr-2">
              <font-awesome-radio
                v-model="markAsSecondTierPaid.gatewayType"
                option-name="Check"
                option-value="2"
                :option-enabled="selectedAffiliate.affiliate_check_info"
              >
                <template slot="icon">
                    <credit-card-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio>
            </div>
            <div class="w-1/3">
              <font-awesome-radio
                v-model="markAsSecondTierPaid.gatewayType"
                option-name="Wire Info"
                option-value="3"
                :option-enabled="selectedAffiliate.affiliate_wire_info"
              >
                <template slot="icon">
                    <bank-icon class="w-10 h-10 fill-current" />
                </template>
              </font-awesome-radio>
            </div>
          </div>
          <div
            v-if="markAsSecondTierPaid.gatewayType != ''"
            class="flex flex-row w-full mb-4"
          >
            <div
              v-if="markAsSecondTierPaid.gatewayType == '1'"
              class="w-full flex flex-col"
            >
              <h4 class="font-semibold text-grey-700">
                {{ $t('frontend_affiliate_index_payee_details') }}
              </h4>
              <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_affiliate_index_paypal_email') }}
                </label>
                <label class="text-sm text-grey-700 mb-2">
                  {{ selectedAffiliate.rendered_affiliate_paypal }}
                </label>
              </div>
            </div>
            <div v-else-if="markAsSecondTierPaid.gatewayType == '2'">
              <h4 class="font-semibold text-grey-700">
                {{ $t('frontend_affiliate_index_check_Settings') }}
              </h4>
            </div>
            <div
              v-else-if="markAsSecondTierPaid.gatewayType == '3'"
              class="w-full flex flex-col"
            >
              <h4 class="font-semibold text-grey-700">
                {{ $t('frontend_affiliate_index_payee_details') }}
              </h4>
              <div class="w-full flex flex-wrap">
                <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_name') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.account_name }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_address') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.address }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.city }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.state }}
                    {{ selectedAffiliate.affiliate_wire_info.postal_code }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.country }}
                  </label>
                </div>
              </div>

              <h4 class="font-semibold text-grey-700">
                {{ $t('frontend_affiliate_index_benificiary_bank') }}
              </h4>
              <div class="w-full flex flex-wrap">
                <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_bank_name') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_name }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_bank_address') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_address }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_account_number') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_account_no }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_routing_number') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_routing_no }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_swift') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_swift_code }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_aba') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_aba }}
                  </label>
                </div>
                <div class="w-1/2 pt-2 pb-2 flex flex-col">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_affiliate_index_iban') }}
                  </label>
                  <label class="text-sm text-grey-700 mb-2">
                    {{ selectedAffiliate.affiliate_wire_info.main_bank_iban }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
        <div class="flex flex-col">
          <div class="flex">
            <label class="font-semibold text-grey-700 mb-2">
              {{ $t('frontend_affiliate_index_mark_as') }}
            </label>
          </div>
          <div
            v-for="(markAsPaidType, index) in markAsPaidTypes"
            :key="index"
            class="flex mr-4"
          >
            <gd-custom-radio
              v-model="markAsSecondTierPaid.typeOfPayment"
              :option="markAsPaidType"
            ></gd-custom-radio>
          </div>
          <div class="mt-2 flex flex-row w-full justify-center">
            <gd-button
              v-if="
                markAsSecondTierPaid.gatewayType != '' &&
                selectedAffiliate.processed_without_advances > 0
              "
              size="auto"
              variant="primary"
              class="mb-4"
              @click="processSecondTierPayment"
            >
              {{ $t('frontend_affiliate_process_payment') }}
            </gd-button>
            <gd-button v-else size="auto" disabled class="mb-4">
              {{ $t('frontend_affiliate_process_payment') }}
            </gd-button>
          </div>
        </div>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import {maxLength} from 'vuelidate/lib/validators';
import AffiliatePayment from '@/models/AffiliatePayment';
import SecondTierAffiliate from '@/models/SecondTierAffiliate';
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';

export default {
  components: {
    PaypalIcon,
    CreditCardIcon,
    BankIcon,
  },
  props: {
    selectedAffiliate: {
      type: Object,
      default: null,
    },
    filtersModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      markAsSecondTierPaid: {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      },
      markAsPaidTypes: [
        { id: 1, title: this.$t('frontend_in_process'), description: '' },
        { id: 2, title: this.$t('frontend_paid'), description: '' },
        { id: 3, title: this.$t('frontend_groovesell_paid_after_24'), description: '' },
        { id: 4, title: this.$t('frontend_groovesell_paid_after_48'), description: '' },
      ],
    };
  },
  methods: {
    async processSecondTierPayment() {
      this.$v.markAsSecondTierPaid.$touch();
      const isValid = !this.$v.markAsSecondTierPaid.$invalid;

      if (isValid) {
        this.$loader.start('processSecondTierPayment');

        const affiliatePayment = new AffiliatePayment({
          affiliate_id: this.selectedAffiliate.id,
          amount: this.selectedAffiliate.processed_without_advances / 100,
          payment_gateway: this.markAsSecondTierPaid.gatewayType,
          note: this.markAsSecondTierPaid.comment,
          transaction_id: this.markAsSecondTierPaid.transactionId,
          type: 5,
          markTransactionAs: this.markAsSecondTierPaid.typeOfPayment,
          funnel: this.filtersModel.selectedFunnel,
          processedTransactionIds: this.selectedAffiliate.processed_second_tier_transaction_ids,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'mark-as-paid-second-tier-modal');

        const newSelectedAffiliate = await SecondTierAffiliate.params({
          funnels: this.filtersModel.selectedFunnel,
        }).$find(this.selectedAffiliate.id);
        this.$emit('updateSelectedAffiliate', newSelectedAffiliate);

        this.$loader.end('processSecondTierPayment');
        this.$emit('changedSearchOrFilter');
      }
    },
    onClose() {
      this.$v.markAsSecondTierPaid.$reset();
      this.markAsSecondTierPaid = {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      };
    },
  },
  validations: {
    markAsSecondTierPaid: {
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
