<template>
    <div>
        <div class="flex flex-row">
            <div class="w-full flex flex-wrap">
                <div v-for="(field, index) in selectedTransaction.buyer_custom_fields" :key="index"
                    class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ field.label }}</label>
                    <label class="text-sm text-grey-700 mb-2">
                        {{ field.value }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
    },
    created() {
        console.log(this.selectedTransaction);
    },

}
</script>