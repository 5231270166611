<template>
    <div>
        <div class="flex flex-row">
            <div class="flex flex-col w-full bg-white rounded mt-4">
                <div class="w-full">
                    <div class="flex flex-row p-2 mb-2">
                        <div class="w-1/3"></div>

                        <div class="w-1/3">
                            {{ $t('frontend_ask_user_name') }}
                        </div>
                        <div class="w-1/3">
                            {{ $t('frontend_ask_for_pass') }}
                        </div>
                    </div>

                    <div style="height: calc(52vh - 15rem)">
                        <div class="w-full mb-2 max-h-full flex flex-col overflow-y-auto">
                            <div class="flex flex-row p-2" v-for="(pricePoint, index) in localModel.password_options"
                                :key="index">
                                <div class="w-1/3">
                                    {{ localModelPricePointName(pricePoint.id) }}
                                </div>

                                <!-- ask_user_name -->
                                <div class="w-1/3 text-center text-center">
                                    <gd-toggle v-model="localModel.password_options[index].ask_username"
                                        :value="localModel.password_options[index].ask_username" />
                                </div>

                                <div class="w-1/3 text-center text-center">
                                    <gd-toggle v-model="localModel.password_options[index].ask_for_password"
                                        :value="localModel.password_options[index].ask_for_password" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localModel: this.model
        }
    },
    watch: {
        localModel: {
            handler: function (val, oldVal) {
                this.$emit('update-model', val)
            },
            deep: true
        }
    },
    methods: {
        localModelPricePointName(pricePointId) {
            const pp = this.localModel.pricing_points.find((obj) => {
                return obj.id == pricePointId;
            });

            if (pp) {
                return pp.name;
            }
        },
    }

}
</script>