<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                    <div class="flex flex-col w-full mb-6 mt-8">
                        <div v-if="pricePoint.price > 0" class="flex flex-row w-2/3">
                            <div v-for="(bumpType, index) in orderBumpStatus" :key="index" class="flex flex-1 mr-4">
                                <gd-custom-radio v-model="pricePoint.post_sale_order_bump_options
                                    .post_order_bump_enable
                                    " :option="bumpType"></gd-custom-radio>
                            </div>
                        </div>
                        <div v-else class="flex flex-row w-2/3">
                            <div v-for="(
                        bumpType, index
                      ) in orderBumpTypesForFreePricePoint" :key="index" class="flex flex-1 mr-4">
                                <gd-custom-radio v-model="pricePoint.post_sale_order_bump_options
                                    .post_order_bump_enable
                                    " :option="bumpType"></gd-custom-radio>
                            </div>
                        </div>
                    </div>

                    <div v-if="pricePoint.post_sale_order_bump_options
                        .post_order_bump_enable
                        " class="w-full flex flex-col">
                        <div class="flex flex-row w-full">
                            <div class="flex flex-col w-2/3 pr-4">
                                <div class="w-full flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_heading')
                                    }}</label>
                                    <div class="w-full flex flex-row">
                                        <div class="w-full mr-2">
                                            <text-input
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_heading_line"
                                                :validator="$v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_heading_line"
                                                :attribute="$t('frontend_heading')"
                                                value="pricePoint.post_sale_order_bump_options.post_order_heading_line"
                                                :placeholder="$t('frontend_heading')" />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_sub_heading')
                                    }}</label>
                                    <div class="w-full flex flex-row">
                                        <div class="w-full mr-2">
                                            <text-input
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_subheading"
                                                :validator="$v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_subheading"
                                                :attribute="$t('frontend_subheading')"
                                                value="pricePoint.post_sale_order_bump_options.post_order_subheading"
                                                :placeholder="$t('frontend_subheading')" />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_desc')
                                    }}</label>
                                    <div class="w-full flex flex-row">
                                        <div class="w-full mr-2">
                                            <quill-editor
                                                ref="postSellOrderBumpEditor"
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_description"
                                                class="min-h-32"
                                                :class="{'invalid': $v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_description.$error}"
                                                :options="postSellOrderBumpEditorOptions"
                                            >
                                            </quill-editor>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full flex flex-col mb-8">
                                    <div class="w-full flex">
                                        <div class="flex flex-col w-1/2">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                $t('frontend_show_video') }}?</label>
                                            <gd-toggle v-model="pricePoint.post_sale_order_bump_options
                                                .post_order_video_details
                                                .post_order_show_video
                                                " />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="pricePoint.post_sale_order_bump_options
                                    .post_order_video_details.post_order_show_video
                                    " class="w-full flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_video_url')
                                    }}</label>

                                    <div class="w-full flex flex-row">
                                        <div class="w-full mr-2">
                                            <text-input
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_video_details.url"
                                                attribute="Video URL"
                                                value="pricePoint.post_sale_order_bump_options.post_order_video_details.url"
                                                :validator="$v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_video_details.url"
                                                :placeholder="$t('frontend_product_step6_video_url_ph', {videoAppTitle: whitelabelAppData('video').title})"
                                                @input="updateUrl(index, $event)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-col w-full mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_product')
                                    }}</label>
                                    <gd-dropdown
                                        v-model="pricePoint.post_sale_order_bump_options.post_order_product_id"
                                        size="full"
                                        :placeholder="$t('frontend_select_product')"
                                        :class="{'invalid': $v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_product_id.$error}"
                                        :options="pricePointFunnelProductsOptions(pricePoint)"
                                        @change="changedPostSellProduct(pricePoint, $event)"
                                    />
                                    <span
                                        v-if="$v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_product_id.$error"
                                        class="text-danger text-sm"
                                    >
                                        {{ $t('frontend_product_required') }}
                                    </span>
                                </div>
                                <div class="flex flex-col w-full mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_price_point')
                                    }}</label>
                                    <gd-dropdown
                                        v-model="pricePoint.post_sale_order_bump_options.post_order_price_point_id"
                                        size="full"
                                        :placeholder="$t('frontend_groovesell_select_price_point')"
                                        :class="{'invalid': $v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_price_point_id.$error}"
                                        :options="selectedProductPricePointsOptions(pricePoint, pricePoint.post_sale_order_bump_options.post_order_product_id)"
                                    />
                                    <span
                                        v-if="$v.localModel.pricing_points.$each[index].post_sale_order_bump_options.post_order_price_point_id.$error"
                                        class="text-danger text-sm"
                                    >
                                        {{ $t('frontend_price_point_required') }}
                                    </span>
                                </div>
                                <div class="w-full flex flex-col mb-6">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_style')
                                    }}</label>
                                    <div class="w-full flex flex-row">
                                        <div class="w-full">
                                            <gd-dropdown
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_style_id"
                                                size="full"
                                                :options="postSellOrderBumpstyleOptionsList"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full flex flex-col mb-8">
                                    <div class="w-full flex">
                                        <div class="flex flex-col w-1/2">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                $t('frontend_display_thumbnail') }}?</label>
                                            <gd-toggle
                                                v-model="pricePoint.post_sale_order_bump_options.post_order_display_thumbnail"
                                                @change="enabledThumbnail(index, $event)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full"
                                    v-if="pricePoint.post_sale_order_bump_options.post_order_display_thumbnail">
                                    <div class="w-full flex flex-col mb-8">
                                        <div class="w-full flex">
                                            <div class="flex flex-col w-1/2">
                                                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                                    $t('frontend_thumbnail_image') }}</label>
                                                <image-picker
                                                    v-model="pricePoint.post_sale_order_bump_options.post_order_thumbnail_image"
                                                    :primary="true"></image-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w-full flex flex-row mb-8">
                                        <div class="w-1/2 flex flex-col mr-2">
                                            <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_style')
                                            }}</label>
                                            <div class="w-full flex flex-row">
                                                <gd-dropdown
                                                    v-model="pricePoint.post_sale_order_bump_options.post_order_thumbnail_size"
                                                    size="full"
                                                    class="mr-2"
                                                    :options="thumbnailSizeOptions"
                                                />
                                            </div>
                                        </div>
                                        <div class="w-1/2 flex flex-col">
                                            <div class="w-full flex">
                                                <div class="flex flex-col w-1/2">
                                                    <label class="
                                    font-semibold
                                    text-sm text-grey-700
                                    mb-2
                                  ">{{ $t('frontend_square_thumbnail') }}?</label>
                                                    <gd-toggle class="mt-3" v-model="pricePoint.post_sale_order_bump_options
                                                        .post_order_is_square
                                                        " />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col w-1/3 px-4 border-l border-grey-100">
                                <div class="w-full flex flex-col mb-6">
                                    <label class="
                            w-full
                            text-grey-dark text-sm
                            font-semibold
                            mb-32
                          ">{{ $t('frontend_preview') }}</label>
                                    <gd-button v-if="pricePoint.post_sale_order_bump_options
                                        .post_order_product_id &&
                                        pricePoint.post_sale_order_bump_options
                                            .post_order_price_point_id
                                        " size="auto" variant="primary" class="ml-4 mt-3" @click="
        emitPreviewPostSaleBump(
            pricePoint.post_sale_order_bump_options,
            pricePoint.funnelProducts,
            pricePoint.postSaleProductPricePoint
        )
        ">{{ $t('frontend_click_to_preview') }}</gd-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import urlParser from 'js-video-url-parser/lib/base';
import Swal from 'sweetalert2';
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import { quillEditor } from 'vue-quill-editor';
import {
  required,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';

export default {
    components: {
        ImagePicker,
        quillEditor,
    },
    props: {
        model: {
            required: true
        },
    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: '',
            orderBumpStatus: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step6_no_dont'),
                    description: this.$t('frontend_product_step6_the_checkout'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step6_yes_activate'),
                    description: this.$t('frontend_product_step6_the_checkout_page'),
                },
            ],
            orderBumpTypesForFreePricePoint: [
                {
                    id: 0,
                    title: this.$t('frontend_product_step6_no_dont'),
                    description: this.$t('frontend_product_step6_the_checkout'),
                },
                {
                    id: 1,
                    title: this.$t('frontend_product_step6_yes_activate'),
                    description: this.$t('frontend_product_step6_the_checkout_page'),
                    is_disabled: true,
                },
            ],
            postSellOrderBumpEditorOptions: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ],
                },
            },
            offerBumpComponentKey: 1,
            postSellOrderBumpstyleOptionsList: [
                { text: 'Light Box', value: 1 },
                { text: 'Slider', value: 2 },
            ]
        }
    },
    computed: {
        thumbnailSizeOptions() {
            return [
                { text: this.$t('frontend_small'), value: 'sm' },
                { text: this.$t('frontend_normal'), value: 'md' },
                { text: this.$t('frontend_large'), value: 'lg' },
            ];
        },
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];

            let pricePointIndex;
            for (pricePointIndex in this.$v.localModel.pricing_points.$each.$iter) {
                if (
                this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$error
                ) {
                pricePointErrorTabs.push(
                    `price_point_${this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`
                );
                }
            }

            return pricePointErrorTabs;
        },
    },
    mounted() {
        if (this.pricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    watch: {
        localModel: {
            handler: function (val, oldVal) {
                this.$emit('update-model', val);
            },
            deep: true
        },
    },
    methods: {
        pricePointFunnelProductsOptions(pricePoint) {
            return pricePoint.funnelProducts.map((product) => {
                return {
                    text: product.name,
                    value: product.id,
                };
            });
        },
        selectedProductPricePointsOptions(pricePoint) {
            if (!pricePoint.postSaleProductPricePoint) {
                return [];
            }

            return pricePoint.postSaleProductPricePoint.map((pricePoint) => {
                return {
                    text: pricePoint.name,
                    value: pricePoint.id,
                };
            });
        },
        changedPostSellProduct(pricePoint, event) {
            pricePoint.post_sale_order_bump_options.post_order_price_point_id = 0;
            pricePoint.funnelProducts.find((product) => {
                if (product.id == event) {
                    pricePoint.postSaleProductPricePoint = product.price_points;
                }
            });
        },
        emitPreviewPostSaleBump(postSaleOrderBumpOptions, funnelProducts, postSaleProductPricePoint) {
            this.$emit('preview-post-sale-bump', postSaleOrderBumpOptions, funnelProducts, postSaleProductPricePoint);
        },
        enabledThumbnail(index, event) {
            if (event) {
                if (this.localModel.pricing_points[index].post_sale_order_bump_options.post_order_thumbnail_image == '' && this.localModel.checkout_page_options.product_image != '') {
                    this.localModel.pricing_points[index].post_sale_order_bump_options.post_order_thumbnail_image = this.localModel.checkout_page_options.product_image;
                }
            }
        },
        updateUrl(index, event) {
            const videoInfo = urlParser.parse(
                this.localModel.pricing_points[index].post_sale_order_bump_options
                    .post_order_video_details.url
            );
            // urlParser.parse(value) === undefined
            if (videoInfo !== undefined && videoInfo.provider == 'youtube') {
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.provider =
                    'youtube';
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.id =
                    videoInfo.id;
            } else if (videoInfo !== undefined && videoInfo.provider == 'vimeo') {
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.provider =
                    'vimeo';
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.id =
                    videoInfo.id;
            } else if (videoInfo !== undefined && videoInfo.provider == 'wistia') {
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.provider =
                    'wistia';
                this.localModel.pricing_points[
                    index
                ].post_sale_order_bump_options.post_order_video_details.id =
                    videoInfo.id;
            } else {
                const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
                const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();
                const expression = new RegExp(`(http:|https:|)\/\/?(app\\.${brandAltName}\\.com)\/${videoAppTitle}\/videopage\/[A-Za-z0-9]*\/[A-Za-z0-9]*`, 'g');

                const regex = new RegExp(expression);
                const tempUrl =
                    this.localModel.pricing_points[index].post_sale_order_bump_options
                        .post_order_video_details.url;

                if (tempUrl.match(regex)) {
                    const splitUrl =
                        this.localModel.pricing_points[
                            index
                        ].post_sale_order_bump_options.post_order_video_details.url.split(
                            '/videopage/'
                        );
                    const splitId = splitUrl[1].split('/');
                    const videoId = splitId[0];
                    const videoPermalink = splitId[1];
                    const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();

                    this.localModel.pricing_points[
                        index
                    ].post_sale_order_bump_options.post_order_video_details.provider = videoAppTitle;
                    this.localModel.pricing_points[
                        index
                    ].post_sale_order_bump_options.post_order_video_details.id = videoId;
                    this.localModel.pricing_points[
                        index
                    ].post_sale_order_bump_options.post_order_video_details.permalink = videoPermalink;
                }
            }
        },
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
        createOfferBump(index) {
            this.localModel.pricing_points[index].offer_bump_options.push({
                display_thumbnail: 0,
                display_bouncing: 0,
                heading_line: '',
                description: '',
                style_id: 1,
                product_id: 0,
                price_point_id: 0,
            });
        },
        async deleteOfferBump(index, offerBumpIndex) {
            console.log(`this is index ${index}`);
            console.log(`this is offe bump index ${offerBumpIndex}`);

            const { value: accept } = await Swal.fire({
                title: this.$t('frontend_sure'),
                text: this.$t('frontend_non_revertable'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('frontend_delete_confirm'),
            });
            if (accept) {
                this.localModel.pricing_points[index].offer_bump_options.splice(
                    offerBumpIndex,
                    1
                );
            }

           
        },
        selectedProductPricePoints(pricePoint, productId) {
            const funnelProduct = pricePoint.funnelProducts.find(
                (product) => product.id == productId
            );

            if (funnelProduct) {
                return funnelProduct.price_points;
            }
            return [];
        },
    },
    validations: {
        localModel: {
            pricing_points: {
                required,
                minLength: minLength(1),
                $each: {
                    post_sale_order_bump_options: {
                        post_order_heading_line: {
                            required: requiredIf((object) => {
                                if (
                                    object.post_order_bump_enable &&
                                    object.post_order_bump_enable == 1
                                ) {
                                    return true;
                                }

                                return false;
                            }),
                        },
                        post_order_subheading: {
                            required: requiredIf((object) => {
                                if (
                                    object.post_order_bump_enable &&
                                    object.post_order_bump_enable == 1
                                ) {
                                    return true;
                                }

                                return false;
                            }),
                        },
                        post_order_description: {
                            required: requiredIf((object) => {
                                if (
                                    object.post_order_bump_enable &&
                                    object.post_order_bump_enable == 1
                                ) {
                                    console.log('head_line error9')
                                    return true;
                                }
                                console.log('head_line error10')
                                return false;
                            }),
                        },
                        post_order_product_id: {
                            isValidProductId(value, object) {
                                if (
                                    object.post_order_bump_enable &&
                                    object.post_order_bump_enable == 1 &&
                                    (!value || value == 0)
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        post_order_price_point_id: {
                            isValidPricePointtId(value, object) {
                                if (
                                    object.post_order_bump_enable &&
                                    object.post_order_bump_enable == 1 &&
                                    (!value || value == 0)
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        post_order_video_details: {
                            url: {
                                required: requiredIf((object) => {
                                    if (
                                        object.post_order_show_video &&
                                        object.post_order_show_video == true
                                    ) {
                                        return true;
                                    }
                                    return false;
                                }),
                                isValidPostSaleVideoUrl(value, object) {
                                    if (
                                        object.post_order_show_video &&
                                        object.post_order_show_video == true
                                    ) {
                                        value = value || '';
                                        const videoInfo = urlParser.parse(value);

                                        if (videoInfo != undefined) {
                                            return true;
                                        }
                                        const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
                                        const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();
                                        const expression = new RegExp(`(http:|https:|)\/\/?(app\\.${brandAltName}\\.com)\/${videoAppTitle}\/videopage\/[A-Za-z0-9]*\/[A-Za-z0-9]*`, 'g');
                                        const regex = new RegExp(expression);
                                        if (value.match(regex)) {
                                            return true;
                                        }
                                        return false;
                                    }
                                    return true;
                                },
                            },
                        },
                    },
                },
            },
        },
    },
}
</script>

<style>
.invalid .border-grey-200  {
    border-color: var(--brand-danger);
}
</style>
