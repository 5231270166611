<template>
    <div>
        <div class="flex flex-col">
            <div class="flex flex-row w-1/2 mr-2">
                <div v-for="(languageType, index) in languageTypes" :key="index" class="flex w-1/2 mr-4">
                    <gd-custom-radio v-model="localModel.checkout_page_options.language.type"
                        :option="languageType"></gd-custom-radio>
                </div>
            </div>
            <div v-if="localModel.checkout_page_options.language.type == 1" class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                    $t('frontend_product_step5_select_language')
                }}</label>
                <gd-dropdown
                    v-model="localModel.checkout_page_options.language.languages[0]"
                    size="full"
                    :options="languagesAvailableOptions"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },

    },
    data() {
        return {
            localModel: this.model,
            languageTypes: [
                {
                    id: 1,
                    title: this.$t('frontend_product_step5_language_types0_title'),
                    description: this.$t('frontend_product_step5_language_types0_desc'),
                },
                {
                    id: 2,
                    title: this.$t('frontend_product_step5_language_types1_title'),
                    description: this.$t('frontend_product_step5_language_types1_desc'),
                    coming_soon: true,
                },
            ],
        };
    },
    computed: {
        languagesAvailable() {
            return [
                { code: 'en', name: this.$t('frontend_product_step5_english') },
                { code: 'pt', name: this.$t('frontend_product_step5_portuguese') }
            ];
        },
        languagesAvailableOptions() {
            return this.languagesAvailable.map((language) => {
                return {
                    value: language.code,
                    text: language.name,
                };
            });
        },
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },

}
</script>
