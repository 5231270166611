var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col items-left mr-4 mb-4 rounded p-4",class:{
    'border-primary border-2': this.activeOption === _vm.option.id,
    'border-grey-200 border': this.activeOption !== _vm.option.id,
  },on:{"click":_vm.updateActiveOption,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.updateActiveOption.apply(null, arguments)}}},[_c('label',{staticClass:"flex cursor-pointer",class:{
      'cursor-not-allowed': _vm.option.coming_soon || _vm.option.is_disabled,
      'cursor-pointer': !_vm.option.coming_soon && !_vm.option.is_disabled,
    }},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-1 flex-row justify-center flex-wrap"},_vm._l((_vm.option.svg),function(img){return _c('div',{key:img,staticClass:"my-2 px-2 w-24 flex flex-col justify-center items-center content-center"},[_c('img',{staticClass:"h-full w-full object-scale-down",class:{
              'border-2 border-red': img.includes('Password')
            },attrs:{"src":require(`@/assets/images/checkout/${img}.png`)}}),(_vm.option.label === undefined)?_c('p',{staticClass:"mt-2",class:{
              'text-primary': _vm.isActiveOption,
              'text-dark': !_vm.isActiveOption,
            }},[_vm._v(" "+_vm._s(_vm.camelCaseToSentence(img))+" ")]):_vm._e()])}),0),(_vm.option.label !== undefined)?_c('div',{staticClass:"flex flex-1 flex-row justify-center flex-wrap"},[_c('p',{staticClass:"mt-2",class:{
              'text-primary': _vm.isActiveOption,
              'text-dark': !_vm.isActiveOption,
            }},[_vm._v(" "+_vm._s(_vm.option.label)+" ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }