<template>
    <gd-modal name="projections-modal" :title="$t('frontend_groovesell_menu_items_projections')" width="auto">
        <div class="mx-4" style="min-width: 40vw">
            <gd-tabs :tabs="projectionPricePointsTabs" :current-tab="projectionPricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleProjectionPricePointsTabChange" />
            <div class="mt-2">
                <div v-for="(pricePoint, index) in pricepointsForProjections" :key="index"
                    :ref="'price_point_tab_' + pricePoint.id">
                    <div v-if="projectionPricePointsTab === 'price_point_' + pricePoint.id"
                        class="flex flex-row pl-4 checkoutLinksTabContent mt-4">
                        <div class="flex flex-col w-full">
                            <div class="flex flex-row w-full justify-center mb-8">
                                <span class="text-3xl font-semibold text-center mr-4">
                                    <span v-if="pricePoint.number_of_installments > 0">{{ pricePoint.number_of_installments
                                    }}
                                        <span class="relative" style="top: -3px;">x</span></span>
                                    {{ funnel.currency_symbol }}{{ pricePoint.price }}</span>
                                <span v-if="recurringIntervals.hasOwnProperty(pricePoint.recurring_interval)"
                                    class="text-sm border border-grey-200 pt-2 px-6 h-10">
                                    {{ recurringIntervals[pricePoint.recurring_interval] }}
                                </span>
                            </div>

                            <div class="w-full flex flex-row mb-8">
                                <div class="px-4 w-1/3 flex flex-col justify-end checkoutLinksTabContent mt-4">
                                    <label class="w-full text-grey-dark mb-4">{{
                                        $t('frontend_product_projected_after')
                                    }}</label>
                                    <gd-currency-input v-model="pricePoint.projected"
                                        :currency-code="funnel.currency_code.toUpperCase()" />
                                </div>
                                <div class="px-4 w-1/3 flex flex-col justify-end checkoutLinksTabContent mt-4">
                                    <label class="w-full text-grey-dark mb-4">{{
                                        $t('frontend_product_projected_after_paid')
                                    }}</label>
                                    <gd-currency-input v-model="pricePoint.projected_after_aff"
                                        :currency-code="funnel.currency_code.toUpperCase()" />
                                </div>
                                <div class="px-4 w-1/3 flex flex-col justify-end checkoutLinksTabContent mt-4">
                                    <label class="w-full text-grey-dark mb-4">{{
                                        $t('frontend_product_projected_after_paid_affiliate')
                                    }}</label>
                                    <gd-currency-input v-model="pricePoint.projected_after_aff_refunds"
                                        :currency-code="funnel.currency_code.toUpperCase()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-8 px-4 flex flex-row justify-end">
            <gd-button variant="primary" size="md" @click="savePricePointProjections">{{ $t('frontend_save') }}</gd-button>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        pricepointsForProjections: {
            type: Array,
            required: true
        },
        funnel: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            projectionPricePointsTab: '',
            recurringIntervals: {
                1: this.funnel.currency_code.toUpperCase(),
                2: this.$t('frontend_product_every_2_weeks'),
                3: this.$t('frontend_product_monthly'),
                4: this.$t('frontend_product_every_2_months'),
                5: this.$t('frontend_product_quarterly'),
                6: this.$t('frontend_product_half_yearly'),
                7: this.$t('frontend_product_yearly'),
                8: this.$t('frontend_product_every_2_years'),
                9: this.$t('frontend_product_every_4_months'),
            },
        }
    },
    created() {
        this.projectionPricePointsTab = `price_point_${this.pricepointsForProjections[0].id}`;

    },
    computed: {
        projectionPricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.pricepointsForProjections.length; i++) {
                pricePointTabs.push({
                    title: this.pricepointsForProjections[i].name,
                    value: `price_point_${this.pricepointsForProjections[i].id}`,
                });
            }

            return pricePointTabs;
        },
    },
    methods: {
        handleProjectionPricePointsTabChange(newTab) {
            this.projectionPricePointsTab = newTab;
        },
        async savePricePointProjections() {
            this.$loader.start('savePricePointProjections');
            const promises = [];
            for (let i = 0; i < this.pricepointsForProjections.length; i++) {
                promises.push(this.pricepointsForProjections[i].save());
            }

            const self = this;
            await Promise.all(promises).then((values) => {
                self.$root.$emit('modal-close', 'projections-modal');
                self.$loader.end('savePricePointProjections');
            });
        },
    }
}
</script>