<template>
    <div>
        <div v-if="selectedCheckoutFlow == 0" class="flex flex-row mx-4 mb-4">
            <gd-tabs :tabs="tabs" :current-tab="currentTab" :disable-not-completed="true" :disable-exclamatory-marks="true"
                :error-tabs="errorTabs" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                :tab-class="'pb-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleTabChange" />
            <div class="flex flex-row ml-8">
                <gd-button v-if="currentTab != 'ConfigStep'" size="auto" class="mr-4 mt-3"
                    @click="moveBackOnWizard">{{ $t('frontend_back') }}</gd-button>
                <gd-button v-if="currentTab != 'LinksStep'" size="auto" class="mr-4 mt-3" variant="primary"
                    @click="moveNextOnWizard">{{ $t('frontend_save_next') }}</gd-button>

                <gd-button v-if="currentTab == 'LinksStep'" size="auto" class="mr-4 mt-3" variant="primary"
                    @click="closeModal('checkout-pages-modal')">{{ $t('frontend_close') }}</gd-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedCheckoutFlow: {
            type: Number,
            default: 0
        },
        currentTab: {
            type: String,
            default: 'step1'
        },
        errorTabs: {
            type: Array,
            default: () => []
        },
        tabs: {
            type: Array,
            default: () => []
        },
        moveBackOnWizard: {
            type: Function,
            default: () => { }
        },
        moveNextOnWizard: {
            type: Function,
            default: () => { }
        },



    },
    methods: {
        handleTabChange(tab) {
            this.$emit('tabChange', tab);
        },
        async closeModal(name) {
            this.$loader.start('closeModal');
            this.$root.$emit('modal-close', name);
            this.$loader.end('closeModal');
        },

    },
}
</script>