<template>
  <div>
    <v-wait for="funnels">
      <!-- Main content area with flex layout -->
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-1/2 mb-2">
          <h4 class="font-semibold text-grey-700">
            {{ $t('frontend_bumps_funnel') }}
          </h4>
        </div>

        <div class="flex flex-col w-1/2 mb-2">
          <span class="text-sm text-grey-700">
            {{ $t('frontend_bumps_configure_funnel') }}
          </span>
        </div>

        <hr class="border-grey-100 -mx-4" />

        <!-- Section for successful transaction URL configuration -->
        <div class="flex flex-col w-1/2 mb-6 mt-4">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_successfull_transaction_url') }}
          </label>

          <text-input
            v-model="localModel.thankyou_page_url"
            :validator="$v.localModel.thankyou_page_url"
            :attribute="$t('frontend_successfull_transaction_url')"
            value="localModel.thankyou_page_url"
            :placeholder="$t('frontend_successfull_transaction_url')"
          />
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';

export default {
  name: 'FunnelTab',
  components: {
  },
  props: {
    model: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  validations: {
    localModel: {
      thankyou_page_url: {
        required,
        url,
      },
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
