<template>
  <div>
    <div class="w-full">
      <v-wait for="links">
        <div class="flex flex-row justify-end">
          <gd-button
            size="auto"
            variant="primary"
            class="mb-4"
            @click="createTrackingLink"
          >
            <plus-icon class="h-5 w-5 fill-current" />
            {{ $t('frontend_tracking_link') }}
          </gd-button>
        </div>
        <div v-if="links.length" id="links-list-view" class="mt-8">
          <v-client-table
            ref="linksTable"
            :data="links"
            :columns="linksTableColumns"
            :options="linksTableOptions"
            class="bg-white text-center rounded border border-grey-300"
          >
            <div slot="actions" slot-scope="props">
              <div class="flex flex-row">
                <gd-button
                  v-clipboard:copy="props.row.tracking_link"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="primary"
                  size="icon"
                  class="mr-2"
                >
                  <copy-icon class="h-5 w-5 fill-current" />
                </gd-button>
                <gd-button
                  variant="primary"
                  size="icon"
                  class="mr-2"
                  @click="sendToTrackingLinkStats(props.row.id)"
                >
                  <pie-chart-icon class="h-5 w-5 fill-current" />
                </gd-button>
                <gd-button
                  variant="primary"
                  size="icon"
                  class="mr-2"
                  @click="editTrackingLink(props.row.id)"
                >
                  <pencil-icon class="h-5 w-5 fill-current" />
                </gd-button>
                <gd-button
                  variant="primary"
                  size="icon"
                  class="mr-2"
                  @click="openConfirmModal(props.row.id)"
                >
                  <trash-icon class="h-5 w-5 fill-current" />
                </gd-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div v-else class="mt-8">{{ $t('frontend_tracking_no_tracking') }}</div>
      </v-wait>
    </div>
    <confirm-modal
      name="confirm-tracking-link-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteTrackingLink"
    />
  </div>
</template>

<script>
import TrackingLink from '@/models/TrackingLink';
import Notify from '@groovepages/gd-ui-kit/notify';
import { required } from 'vuelidate/lib/validators';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PieChartIcon from '@/assets/images/icons/PieChartIcon.svg';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_groovesell_menu_items_tracking_links') };
  },
  components: {
    PencilIcon,
    TrashIcon,
    PieChartIcon,
    CopyIcon,
    PlusIcon,
  },
  data() {
    return {
      trackingLinkId: null,
      model: {
        domain: null,
        tracking_link_id: null,
      },
      links: [],
      linksTableColumns: [
        'name',
        'clicks',
        'completed_goals',
        'cost',
        'actions',
      ],
      linksTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        sortable: ['name', 'clicks', 'completed_goals', 'cost'],
         headings: {
          name: this.$t('frontend_name'),
          actions: this.$t('frontend_groovesell_page_tracking_table_actions'),
          completed_goals: this.$t('frontend_groovesell_page_tracking_table_completed_goals'),
          cost: this.$t('frontend_groovesell_page_tracking_table_cost'),          
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
    };
  },
  validations: {
    model: {
      domain: {
        required,
      },
    },
  },
  async created() {
    this.$wait.start('links');
    this.links = await TrackingLink.$get();
    this.$wait.end('links');
  },
  methods: {
    sendToTrackingLinkStats(linkId) {
      this.$router.push({
        name: 'tracking_link_stats',
        params: { link_id: linkId },
      });
    },
    createTrackingLink() {
      const routeName = window.location.href.indexOf('affiliate-tracking') > -1 
        ? 'create_affiliate_tracking_link' 
        : 'create_tracking_link';
      
      this.$router.push({ name: routeName });
    },
    onCopy() {
      Notify.success(this.$t('tracking_url_copied_to_clipboard'));
    },
    onError() {
      Notify.fail(this.$t('unable_to_copy_tracking_url'));
    },
    editTrackingLink(linkId) {
      if (window.location.href.indexOf('affiliate-tracking') > -1) {
        this.$router.push({
          name: 'edit_affiliate_tracking_link',
          params: { link_id: linkId },
        });
      }

      this.$router.push({
        name: 'edit_tracking_link',
        params: { link_id: linkId },
      });
    },
    openConfirmModal(trackingLinkId) {
      this.trackingLinkId = trackingLinkId;
      this.$root.$emit('modal-open', 'confirm-tracking-link-delete-modal');
    },
    async deleteTrackingLink() {
      this.$loader.start('deleteTrackingLink');
      const trackingLink = await TrackingLink.$find(this.trackingLinkId);
      await trackingLink.delete();
      const deleted_link = this.links.map((item) => item.id).indexOf(this.trackingLinkId);
      this.links.splice(deleted_link, 1);
      this.$loader.end('deleteTrackingLink');
    },
  },
};
</script>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#links-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}
</style>
