<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">{{ $t('frontend_tracking_advance_settings') }}</h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{ $t('frontend_tracking_configure_advance_settings') }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs
          :tabs="advancedTabs"
          :current-tab="advancedTab"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleAdvancedTabChange"
        />
      </div>
      <div class="mt-2">
        <div v-if="advancedTab === 'tracking'">
          <div class="flex flex-row">
            <div class="flex flex-col w-1/2 mb-6">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_tracking_tracking_codes') }}
              </label>
              <text-area
                v-model="localModel.advanced_settings.tracking_codes"
                :placeholder="$t('frontend_groovesell_tracking_codes')"
              />
            </div>
          </div>
        </div>

        <div v-else-if="advancedTab === 'cpc'">
          <div class="flex flex-row">
            <div class="flex flex-col w-1/2 mb-6">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_tracking_cost_per_click') }}
              </label>
              <gd-currency-input v-model="localModel.advanced_settings.cpc" currency-code="USD" />
            </div>
          </div>
        </div>

        <div v-else-if="advancedTab === 'user_tacking_ids'">
          <div class="flex mb-4">
            <div class="w-3/4 bg-gray-500 h-12">
              <span v-if="this.tracking_parameters.length == 0">
                {{ $t('frontend_tracking_you_dont_have_tracking') }}
              </span>
            </div>
            <div class="w-1/4 bg-gray-400 h-12">
              <gd-button
                variant="primary"
                size="auto"
                class="float-right"
                @click="addIdRow"
              >
                {{ $t('frontend_tracking_add_tracking_param') }}
              </gd-button>
            </div>
          </div>
          <div v-for="(trackingParam, index) in tracking_parameters" :key="index">
            <div class="flex mb-4 items-end">
              <div class="w-1/3 bg-gray-400">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_tracking_name') }}
                </label>
                <text-input
                  v-model="trackingParam.name"
                  :attribute="$t('frontend_tracking_name')"
                  :placeholder="$t('frontend_tracking_name')"
                />
              </div>
              <div class="w-1/3 bg-gray-500 ml-5">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_tracking_tracking_parameter') }}
                </label>
                <text-input
                  v-model="trackingParam.tracking_param"
                  :attribute="$t('frontend_tracking_tracking_parameter')"
                  :placeholder="$t('frontend_tracking_tracking_parameter')"
                />
              </div>
              <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index)">
                <trash-icon class="h-5 w-5 fill-current" />
              </gd-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      name="confirm-tracking-parameter-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteIdRow"
    />
  </div>
</template>

<script>
import {
  required,
  decimal,
  minValue,
} from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  name: 'AdvancedSettingsTab',
  components: {
    TrashIcon,
  },
  props: {
    model: {
      required: true
    }
  },
  data() {
    return {
      trackingParameterId: null,
      index: 0,
      rowCount: 0,
      tracking_parameters: [],
      localModel: this.model,
      advancedTabs: [
        {
          title: this.$t('frontend_tracking_tracking_codes'),
          value: 'tracking'
        },
        { title: this.$t('frontend_tracking_cost_per_click'), value: 'cpc' },
        {
          title: this.$t('frontend_tracking_tracking_ids'),
          value: 'user_tacking_ids'
        }
      ],
      advancedTab: 'tracking',
      trackingParam: []
    };
  },
  validations: {
    localModel: {
      advanced_settings: {
        cpc: {
          required,
          decimal,
          minValue: minValue(0)
        }
      }
    }
  },
  created() {
    this.tracking_parameters = this.localModel.tracking_parameters;
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    handleAdvancedTabChange(newTab) {
      this.advancedTab = newTab;
    },
    addIdRow() {
      this.rowCount = this.rowCount + 1;
      this.tracking_parameters.push({ name: '', tracking_param: '' });
    },
    openConfirmModal(trackingParameterId) {
      this.trackingParameterId = trackingParameterId;
      this.$root.$emit('modal-open', 'confirm-tracking-parameter-delete-modal');
    },
    async deleteIdRow() {
      this.tracking_parameters.splice(this.trackingParameterId, 1);
    },
  },
};
</script>
