<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step5_checkout') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step5_configure')
        }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs :tabs="getCheckoutTabs" :current-tab="checkoutTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
          :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleCheckoutTabChange" />
      </div>
      <div class="mt-2">

        <look-feel v-if="checkoutTab === 'lookfeel'" :model="localModel" :customColor="customColor"
          @update-attributes="HandleAttributesUpdate" @updateModel="HandleModelUpdate" :validation="$v" />
        <form-fields v-else-if="checkoutTab === 'formfields'" :model="localModel" @updateModel="HandleModelUpdate"
          :openCustomFieldModal="openCustomFieldModal" />
        <express-checkout v-else-if="checkoutTab === 'expressCheckout'" :model="localModel"
          @updateModel="HandleModelUpdate" />
        <cart-abandonment v-else-if="checkoutTab === 'cartAbandonment'" :model="localModel"
          :openIntegrationModal="openIntegrationModal" :pluginSiteData="pluginSiteData"
          :availableIntegrations="availableIntegrations" :showAbandonCartIntegrationBox="showAbandonCartIntegrationBox"
          :validation="$v" @updateModel="HandleModelUpdate" />

        <language v-else-if="checkoutTab === 'language'" :model="localModel" @updateModel="HandleModelUpdate" />
        <tracking v-else-if="checkoutTab === 'tracking'" :model="localModel" @updateModel="HandleModelUpdate" />
        <legal v-else-if="checkoutTab === 'legal'" :model="localModel" @updateModel="HandleModelUpdate"
          :validation="$v" />
        <quantity v-else-if="checkoutTab === 'quantity'" :model="localModel" @updateModel="HandleModelUpdate"
          :validation="$v" />
        <password v-else-if="checkoutTab === 'password'" :model="localModel" @updateModel="HandleModelUpdate" />
      </div>
    </div>

    <gd-modal name="create-custom-field-modal" :title="$t('frontend_groovesell_add_new_custom_field')">
      <div class="flex flex-col m-10">
        <div class="flex flex-row w-full mb-6">
          <label class="w-1/4 text-grey-dark">{{
            $t('frontend_product_step5_field_name')
          }}</label>
          <text-input v-model="customFieldName" class="w-3/4" value="customFieldName"
            :placeholder="$t('frontend_product_step5_field_name')" />
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button size="auto" variant="primary" @click="addCustomField">{{
            $t('frontend_save')
          }}</gd-button>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="add-new-abandon-cart-integration-modal" :title="$t('frontend_product_step5_add_new_integration')"
      :no-overflow="true">
      <div class="flex flex-col m-8">
        <div class="flex flex-col w-full mb-6">
          <div class="w-full flex flex-col mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
              $t('frontend_product_step5_type')
            }}</label>
            <gd-dropdown :v-bind="integrationType" :value="integrationType" :options="availableIntegrations" size="full"
              @change="setValue($event)" @keyup="setValue($event)" />
          </div>
          <div v-if="integrationType && integrationType != ''" class="w-full">
            <config-menu :key="configMenuKey" :initial-values="componentConfigValues" :plugin-id="pluginId"
              :resources-path="resourcesPath" :server-path="serverPath" :plugin="integrationType"
              :app-data="pluginSiteData" layout="vertical" :is-dark="false" :show-button-borders="false"
              :show-notifications-on-save="false" :opened-from="'groovesell'"
              :btn-label="$t('frontend_groovesell_integration_add_new')" @update="addAbandonCartIntegration"
              @menu-updated="configMenuItemsUpdated"></config-menu>
          </div>
        </div>
      </div>
    </gd-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import Auth from '@groovepages/gd-lib/auth';
import Axios from 'axios';
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';
import { required, minLength, url } from 'vuelidate/lib/validators';



import FormFields from './tabs/checkout/FormFields';
import ExpressCheckout from './tabs/checkout/ExpressCheckout';
import CartAbandonment from './tabs/checkout/CartAbandonment';
import Language from './tabs/checkout/Language';
import Tracking from './tabs/checkout/Tracking';
import Legal from './tabs/checkout/Legal';
import Quantity from './tabs/checkout/Quantity';
import Password from './tabs/checkout/Password';
import LookFeel from './tabs/checkout/LookFeel';

export default {
  name: 'Step5',
  components: {
    ConfigMenu,
    FormFields,
    ExpressCheckout,
    CartAbandonment,
    Language,
    Tracking,
    Legal,
    Quantity,
    Password,
    LookFeel,
  },
  props: {
    model: {
      required: true,
    },
    resourcesPath: {
      required: true,
    },
    serverPath: {
      required: true,
    },
    pluginSiteData: {
      required: true,
    },
    availableIntegrations: {
      required: true,
    },
  },
  data() {
    return {
      inputSize: 'regular',
      headingSize: 'regular',
      full_width: true,
      buttonSize: 'regular',
      lable_color: '#545671',
      heading_color: '#280b14',
      secondary_color: '#fff',
      font_family: 'Nunito Sans',
      input_size: '2.5rem',
      button_size: '2rem',
      lable_size: '0.875rem',
      button_text: '14px',
      heading_size: '16px',
      localModel: this.model,
      pricePointsTab: '',
      legalPricePointsTab: '',
      customFieldName: '',
      checkoutTabs: [
        {
          title: this.$t('frontend_product_step5_look_feel'),
          value: 'lookfeel',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_form_fields'),
          value: 'formfields',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_express_checkout'),
          value: 'expressCheckout',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_cart_abondonment'),
          value: 'cartAbandonment',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_custom_css'),
          value: 'tracking',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_legal'),
          value: 'legal',
          show: true
        },
        {
          title: this.$t('frontend_product_step5_language'),
          value: 'language',
          show: true
        },
        /* {
          title: this.$t('frontend_product_step5_compilance'),
          value: 'compliance',
          show: true
        }, */

        {
          title: this.$t('frontend_product_step5_quantity'),
          value: 'quantity',
          show: true
        },
        {
          title: this.$t('frontend_resource_promo_password'),
          value: 'password',
          show: true
        },
      ],
      checkoutTab: 'lookfeel',
      billingAgreementTypes: [
        {
          id: 0,
          title: this.$t('frontend_product_step5_billing_types0_title'),
          description: this.$t('frontend_product_step5_billing_types0_desc'),
        },
        {
          id: 1,
          title: this.$t('frontend_product_step5_billing_types1_title'),
          description: this.$t('frontend_product_step5_billing_types1_desc'),
          coming_soon: true,
        },
      ],

      productImage: null,
      integrationType: '',
      configMenuKey: 1,
      componentConfigValues: [],
      pluginId: '',
      integrationType: '',
      pluginMenuItems: [],
      selectedPricePointIndex: 0,
      primary_color: 'var(--brand-primary)',
      secondary_color: '#fff',
    };
  },

  validations: {
    localModel: {
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          minimumQuantity: {
            isValidQuantityType(value, object) {
              if (object.quantity_enable == 1) {
                if (object.minimum_quantity <= object.maximum_quantity) {
                  return true;
                }
                return false;
              }

              return true;
            },
          },
        },
      },
    },
  },

  computed: {
    getCheckoutTabs() {
      return this.checkoutTabs.filter(tab => tab.show)
    },
    customColor() {
      return {
        '--primary-color': this.primary_color,
        '--hover-color': this.hover_color,
        '--disable-color': this.disable_color,
        '--secondary-color': this.secondary_color,
        '--font-family': this.localModel.product_customization_details.customization_details.font_family,
        '--lable-color': this.localModel.product_customization_details.customization_details.lable_color,
        '--heading-color': this.localModel.product_customization_details.customization_details.heading_color,
        '--lable-color': this.lable_color,
        '--heading-color': this.heading_color,
        '--input-size': this.input_size,
        '--heading-size': this.heading_size,
        '--button-size': this.button_size,
        '--button-text': this.button_text,
        '--button-padding-top': this.localModel.product_customization_details.customization_details.button_padding_top,
        '--button-padding-bottom': this.localModel.product_customization_details.customization_details.button_padding_bottom,
        '--lable-size': this.localModel.product_customization_details.customization_details.lable_size,
      };
    },

  },

  watch: {
    'localModel.checkout_page_options.look_and_feel.template_type': function (newVal) {
 

      if (newVal == 1) {
        this.primary_color = 'var(--brand-primary)';
        this.secondary_color = '#fff';
      } else if (newVal == 2) {
        this.primary_color = '#308be5';
        this.secondary_color = '#fff';
      } else if (newVal == 3) {
        this.primary_color = '#8689a4';
        this.secondary_color = '#fff';
      } else if (newVal == 4) {
        this.primary_color = '#ffcc24';
        this.secondary_color = '#033a7c';
        this.localModel.primary_color = '#ffcc24';
        this.localModel.secondary_color = '#033a7c';
      }
    },

    

    localModel: {
      deep: true,
      handler() {
        this.$emit('updateModel', this.localModel);
      },
    },
  },

  created() {
    if (
      this.localModel.checkout_page_options.look_and_feel.template_type == 1
    ) {
      this.primary_color = 'var(--brand-primary)';
      this.secondary_color = '#fff';
    }
    if (
      this.localModel.checkout_page_options.look_and_feel.template_type == 2
    ) {
      this.primary_color = '#308be5';
      this.secondary_color = '#fff';
    }
    if (
      this.localModel.checkout_page_options.look_and_feel.template_type == 3
    ) {
      this.primary_color = '#8689a4';
      this.secondary_color = '#fff';
    }
    if (
      this.localModel.checkout_page_options.look_and_feel.template_type == 4
    ) {
      this.primary_color = this.localModel.primary_color;
      this.secondary_color = this.localModel.secondary_color;
    }

    this.lable_color =
      this.localModel.product_customization_details.customization_details.lable_color;
    this.heading_color =
      this.localModel.product_customization_details.customization_details.heading_color;
    this.lable_size =
      this.localModel.product_customization_details.customization_details.lable_size;
    this.inputSize =
      this.localModel.product_customization_details.customization_details.input_size;
    this.headingSize =
      this.localModel.product_customization_details.customization_details.heading_size;
    this.button_padding_bottom =
      this.localModel.product_customization_details.customization_details.button_padding_bottom;
    this.button_padding_top =
      this.localModel.product_customization_details.customization_details.button_padding_top;
    this.buttonSize = this.localModel.product_customization_details.customization_details.button_size;

    if (this.inputSize == 'small') {
      this.input_size = '2rem';
    } else if (this.inputSize == 'regular') {
      this.input_size = '2.5rem';
    } else if (this.inputSize == 'large') {
      this.input_size = '3rem';
    } else {
      this.input_size = '2rem';
    }
    if (this.headingSize == 'small') {
      this.heading_size = '14px';
    } else if (this.headingSize == 'regular') {
      this.heading_size = '16px';
    } else if (this.headingSize == 'large') {
      this.heading_size = '20px';
    } else {
      this.heading_size = '16px';
    }

    if (this.buttonSize == 'small') {
      this.button_size = '1rem';
      this.button_text = '12px';
    } else if (this.buttonSize == 'regular') {
      this.button_size = '2rem';
      this.button_text = '12px';
    } else if (this.buttonSize == 'large') {
      this.button_size = '4rem';
      this.button_text = '18px';
    } else {
      this.button_size = '2rem';
      this.button_text = '14px';
    }
  },


  methods: {
    HandleModelUpdate(newVal) {
      this.localModel = newVal;
    },

    HandleAttributesUpdate({ key, value }) {
      if (key == 'button_size') this.changedButtonSize(value);
      if (key == 'input_size') this.changedInputSize(value);
      if (key == 'heading_size') this.changedHeadingSize(value);
      if (this[key] !== undefined) this[key] = value;
    },
    openIntegrationModal() {
      const e = new CustomEvent('openIntegrations', { details: {} });
      document.dispatchEvent(e)
    },

    changedButtonSize(size) {
      if (size == 'small') {
        this.buttonSize = 'small';
        this.button_size = '1rem';
        this.button_padding_bottom = '1rem';
        this.button_padding_top = '1rem';
        this.button_text = '12px';
        this.localModel.product_customization_details.customization_details.button_padding_bottom = '1rem';
        this.localModel.product_customization_details.customization_details.button_padding_top = '1rem';
      } else if (size == 'regular') {
        this.buttonSize = 'regular';
        this.button_size = '2rem';
        this.button_padding_bottom = '1.5rem';
        this.button_text = '12px';
        this.localModel.product_customization_details.customization_details.button_padding_bottom = '1.5rem';
        this.button_padding_top = '1.5rem';
        this.localModel.product_customization_details.customization_details.button_padding_top = '1.5rem';
      } else if (size == 'large') {
        this.buttonSize = 'large';
        this.button_size = '4rem';
        this.button_text = '18px';
      } else {
        this.buttonSize = 'regular';
        this.button_size = '2rem';
        this.localModel.product_customization_details.customization_details.button_padding_bottom = '1rem';
        this.localModel.product_customization_details.customization_details.button_padding_top = '1rem';
        this.button_text = '14px';
      }
    },
    changedInputSize(size) {
      if (size == 'small') {
        this.inputSize = 'small';
        this.input_size = '2rem';
        this.localModel.product_customization_details.customization_details.lable_size =
          '0.700rem';
        this.lable_size = '0.700rem';
      } else if (size == 'regular') {
        this.inputSize = 'regular';
        this.lable_size = '0.875rem';
        this.localModel.product_customization_details.customization_details.lable_size =
          '0.875rem';
        this.input_size = '2.5rem';
      } else if (size == 'large') {
        this.inputSize = 'large';
        this.input_size = '3rem';
        this.lable_size = '1rem';
        this.localModel.product_customization_details.customization_details.lable_size =
          '1rem';
      } else {
        this.lable_size = '0.875rem';
        this.inputSize = 'regular';
        this.input_size = '2rem';
        this.localModel.product_customization_details.customization_details.lable_size =
          '0.700rem';
      }
    },
    changedHeadingSize(size) {
      if (size == 'small') {
        this.hedingSize = 'small';
        this.heading_size = '14px';
      } else if (size == 'regular') {
        this.hedingSize = 'regular';
        this.heading_size = '16px';
      } else if (size == 'large') {
        this.hedingSize = 'large';
        this.heading_size = '20px';
      } else {
        this.heading_size = '16px';
      }
    },



    showAbandonCartIntegrationBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-new-abandon-cart-integration-modal');
    },

    setValue(value) {
      this.integrationType = value;
      this.pluginId = '';
      this.componentConfigValues = [];
      this.configMenuKey = this.configMenuKey + 1;
    },

    addAbandonCartIntegration(data) {
      const integrationConfig = data.config;
      const self = this;

      const integrationInfo = [];

      for (const index in integrationConfig) {
        const value = integrationConfig[index];

        if (value) {
          self.pluginMenuItems.forEach((menuValue, menuIndex) => {
            if (menuValue.key == index) {
              const { options } = menuValue;

              const objChecker = options.some((val) => {
                return typeof val === 'object';
              });

              let correspondingOption = value;
              if (objChecker) {
                correspondingOption = options.find((o) => o.value === value);
              }

              const singleIntegrationField = {};
              singleIntegrationField.index = index;
              singleIntegrationField.value = value;
              singleIntegrationField.label = menuValue.label;

              if (objChecker) {
                singleIntegrationField.name = correspondingOption.text;
              } else {
                singleIntegrationField.name = value;
              }

              integrationInfo.push(singleIntegrationField);
            }
          });
        }
      }

      const completeIntegrationInfo = {};
      completeIntegrationInfo.platform = this.integrationType.split('-')[0];
      completeIntegrationInfo.config = integrationInfo;

      if (
        this.localModel.pricing_points[this.selectedPricePointIndex]
          .abandon_cart_integrations
      ) {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].abandon_cart_integrations.push(completeIntegrationInfo);
      } else {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].abandon_cart_integrations = [];
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].abandon_cart_integrations.push(completeIntegrationInfo);
      }

      this.$root.$emit('modal-close', 'add-new-abandon-cart-integration-modal');
    },

    configMenuItemsUpdated(data) {
      this.pluginMenuItems = data.menuItems;
    },
    openCustomFieldModal() {
      this.customFieldName = '';
      this.$root.$emit('modal-open', 'create-custom-field-modal');
    },

    addCustomField() {
      if (!this.customFieldName.length) {
        return false;
      }
      const lastFormFieldOrder =
        this.localModel.checkout_page_options.form_fields[
          this.localModel.checkout_page_options.form_fields.length - 1
        ].order;
      this.localModel.checkout_page_options.form_fields.push({
        order: lastFormFieldOrder + 1,
        type: 'custom',
        label: this.customFieldName,
        enabled: 1,
      });

      this.customFieldName = '';
      this.$root.$emit('modal-close', 'create-custom-field-modal');
    },

    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    handleCheckoutTabChange(newTab) {
      this.checkoutTab = newTab;
    },

  },
};
</script>

<style>
.custom-font-family {
  font-family: var(--font-family) !important;
}

.custom-lable-color {
  color: var(--lable-color) !important;
}

.custom-heading-color {
  color: var(--heading-color) !important;
}

.custom-input-height {
  height: var(--input-size) !important;
}

.preview input::-webkit-input-placeholder {
  font-size: var(--lable-size) !important;
}

.custom-label-size {
  font-size: var(--lable-size) !important;
}

.custom-heading-size {
  font-size: var(--heading-size) !important;
}

.custom-button {
  padding-top: var(--button-padding-top) !important;
  padding-bottom: var(--button-padding-bottom) !important;
  height: var(--button-size) !important;
  font-size: var(--button-text) !important;
}

.disabled-button-custom-template-color-primary {
  background-color: var(--disable-color) !important;
}

.custom-template-color-primary {
  background-color: var(--primary-color) !important;
}

.custom-template-color-primary-border {
  color: var(--primary-color) !important;
}

.custom-template-color-primary-text {
  color: var(--primary-color) !important;
}

.custom-template-color-primary:hover {
  background-color: var(--primary-color) !important;
}

.custom-template-color-secondary {
  background-color: var(--secondary-color) !important;
}

.custom-template-color-secondary-text {
  color: var(--secondary-color) !important;
}

.hover-custom-template-color-primary:hover {
  background-color: var(--hover-color) !important;
}

.custom-template-color-secondary {
  color: var(--secondary-color) !important;
}

.custom-border {
  border-color: var(--primary-color) !important;
}
</style>
