import { getMiddleware, callMiddleware } from './middleware-handler';

function resolveComponents(components) {
  return Promise.all(
    components.map(component =>
      typeof component === 'function' ? component() : component
    )
  );
}

async function beforeEach(to, from, next, router) {

  let components = [];
  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(
      router.getMatchedComponents({ ...to })
    );
  } catch (error) {
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload(true);
      return;
    }
  }

  if (components.length === 0) {
    next();
  }

  // Start the loading bar.
  if (components[components.length - 1].loading !== false) {
    router.app.$nextTick(() => router.app.$loading.start());
  }

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components);

  // Call each middleware.
  callMiddleware(middleware, to, from, router, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      router.app.setLayout(components[0].layout || '');
      if (to.meta.layout) {
        router.app.setLayout(to.meta.layout);
      }
    }
    next(...args);
  });
}

async function afterEach(to, from, router) {
  await router.app.$nextTick();
  router.app.$loading.finish();
}

export { beforeEach, afterEach };
