<template>
  <div class="border-b border-grey-100 flex flex-row py-2" :class="{'px-8': !showElements}">
    <div class="w-1/4 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/4">
      <div v-if="hasAssetsTab" class="mb-2">
        <gd-button
          size="auto"
          variant="primary"
          @click="$emit('goBackToPromoToolsNavigation')"
        >
          <chevron-left-icon class="h-3 fill-current" />
          {{ $t('frontend_resource_promo_back_to_groovesell') }}
        </gd-button>
      </div>

      <div v-if="showElements" class="flex flex-row w-full">
        <div class="mr-2 mt-2 w-1/4">{{ $t('frontend_funnel') }}</div>
        <div class="w-3/4" v-if="hasRelatedFunnels">
          <gd-dropdown
            v-model="localSelectedPromoLink.funnel"
            size="full"
            :placeholder="$t('frontend_funnel')"
            :options="funnelOptions"
            @change="$emit('changedFunnel', localSelectedPromoLink.funnel)"
          />
        </div>
        <div v-else-if="localFunnelResource" class="w-3/4 text-md max-w-full mt-2">
          {{ localFunnelResource.funnel_name }}
        </div>
      </div>

      <div class="mt-2 flex flex-row w-full">
        <div v-if="showElements" class="mr-2 mt-2 w-1/4">{{ $t('frontend_portal') }}</div>
        <div class="w-3/4" v-if="hasOtherResources">
          <gd-dropdown
            v-model="localSelectedPromoLink.permalink"
            size="full"
            :placeholder="$t('frontend_groovesell_promotional_tool')"
            :options="promoToolOptions"
            @change="$emit('changedPromoTool', localSelectedPromoLink.permalink)"
          />
        </div>
        <div
          v-else-if="hasValidOtherResources"
          class="mt-2 text-md max-w-full w-3/4"
        >
          {{ otherResources[0].name }}
        </div>
        <div v-else-if="localFunnelResource" class="mt-2 text-md max-w-full w-3/4">
          {{ localFunnelResource.name }}
        </div>
      </div>
    </div>

    <div class="w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/2 px-6">
      <div v-if="isRegistered && hasAssetsTab" class="w-full flex flex-row justify-center mt-2">
        <div v-if="shouldShowSwitches" class="ml-16 flex flex-row">
          <div
            class="
              font-semibold
              text-sm text-grey-700
              mr-2
              hidden
              sm:hidden
              md:hidden
              lg:block
              xl:block
            "
          >
            {{ $t('frontend_resource_promo_mark_as_completed') }}
          </div>
          <gd-toggle
            v-if="assetsTab == 'swipes'"
            v-model="localFunnelResource.affiliate_resource.swipes_completed"
            :value="localFunnelResource.affiliate_resource.swipes_completed"
            @change="toggleSwitchValue(1)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'blogReviews'"
            v-model="localFunnelResource.affiliate_resource.blog_reviews_completed"
            :value="localFunnelResource.affiliate_resource.blog_reviews_completed"
            @change="toggleSwitchValue(2)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'banners'"
            v-model="localFunnelResource.affiliate_resource.banners_completed"
            :value="localFunnelResource.affiliate_resource.banners_completed"
            @change="toggleSwitchValue(3)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'thankyouPageAds'"
            v-model="localFunnelResource.affiliate_resource.thankyou_page_ads_completed"
            :value="localFunnelResource.affiliate_resource.thankyou_page_ads_completed"
            @change="toggleSwitchValue(4)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'logoutPageAds'"
            v-model="localFunnelResource.affiliate_resource.logout_page_ads_completed"
            :value="localFunnelResource.affiliate_resource.logout_page_ads_completed"
            @change="toggleSwitchValue(5)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'facebookAds'"
            v-model="localFunnelResource.affiliate_resource.facebook_ads_completed"
            :value="localFunnelResource.affiliate_resource.facebook_ads_completed"
            @change="toggleSwitchValue(6)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'socialMediaPosts'"
            v-model="localFunnelResource.affiliate_resource.social_media_posts_completed"
            :value="localFunnelResource.affiliate_resource.social_media_posts_completed"
            @change="toggleSwitchValue(7)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'allArticles'"
            v-model="localFunnelResource.affiliate_resource.press_releases_completed"
            :value="localFunnelResource.affiliate_resource.press_releases_completed"
            @change="toggleSwitchValue(8)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'videoReviews'"
            v-model="localFunnelResource.affiliate_resource.video_reviews_completed"
            :value="localFunnelResource.affiliate_resource.video_reviews_completed"
            @change="toggleSwitchValue(9)"
          />
          <gd-toggle
            v-else-if="assetsTab == 'signatures'"
            v-model="localFunnelResource.affiliate_resource.signatures_completed"
            :value="localFunnelResource.affiliate_resource.signatures_completed"
            @change="toggleSwitchValue(10)"
          />
        </div>
      </div>
      <div
        class="w-full flex flex-row justify-center"
        v-else-if="localFunnelResource && directAffiliateLinks && homePageLink && showElements"
      >
        <div class="w-full flex flex-row space-x-2">
          <text-input
            v-model="homePageLink"
            :readonly="true"
            :placeholder="$t('frontend_resource_affiliate_link')"
            :select-all="true"
          />
          <gd-button
            v-clipboard:copy="homePageLink"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            size="iconlg"
            variant="primary"
            :title="$t('frontend_resource_copy_affiliate_link')"
          >
            <copy-icon class="w-5 h-5 fill-current" />
          </gd-button>
          <gd-button
            size="iconlg"
            variant="primary"
            @click="$emit('openPromotoolsSection', 'links')"
          >
            <link-icon class="w-5 h-5 fill-current" />
          </gd-button>
          <gd-button
            size="iconlg"
            variant="primary"
            @click="$emit('openPromotoolsSection', 'links')"
          >
            <angle-down-icon class="w-5 h-5 fill-current" />
          </gd-button>
        </div>
      </div>
    </div>

    <div v-if="showElements" class="w-1/4 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/4 flex flex-row justify-end pt-2">
      <div class="hidden sm:hidden md:hidden lg:block xl:block">
        {{ $t('frontend_resource_promo_not') }} {{ userName }}?
      </div>
      <a class="ml-2 cursor-pointer" @click="$emit('logoutAffiliate')">
        {{ $t('frontend_logout') }}
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';
import LinkIcon from '@/assets/images/icons/LinkIcon.svg';
import AngleDownIcon from '@/assets/images/icons/AngleDownIcon.svg';

export default {
  components: {
    CopyIcon,
    ChevronLeftIcon,
    LinkIcon,
    AngleDownIcon,
  },
  props: {
    assetsTab: {
      type: String,
      default: '',
    },
    funnelResource: {
      type: Object,
      default: () => {},
    },
    relatedFunnels: {
      type: Array,
      default: () => [],
    },
    selectedPromoLink: {
      type: Object,
      default: () => {},
    },
    otherResources: {
      type: Array,
      default: () => [],
    },
    isRegistered: {
      type: Number,
      default: 1,
    },
    directAffiliateLinks: {
      type: Array,
      default: () => [],
    },
    assetsTabs: {
      type: Array,
      default: () => [],
    },
    userName: {
      type: String,
      default: '',
    },
    showElements: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localAssetsTabs: this.assetsTabs,
      localFunnelResource: this.funnelResource,
      localSelectedPromoLink: this.selectedPromoLink,
    };
  },
  computed: {
    funnelOptions() {
      return this.relatedFunnels.map(funnel => ({ value: funnel.id, text: funnel.name }));
    },
    promoToolOptions() {
      return this.otherResources.map(promoTool => ({ value: promoTool.permalink, text: promoTool.name }));
    },
    homePageLink() {
      if (
        this.localFunnelResource &&
        this.localFunnelResource.home_page_link &&
        this.localFunnelResource.home_page_link != ''
      ) {
        return this.localFunnelResource.home_page_link;
      }
      if (
        this.localFunnelResource &&
        this.localFunnelResource.display_links &&
        this.directAffiliateLinks
      ) {
        return this.directAffiliateLinks[0].link;
      }

      return '';
    },
    hasAssetsTab() {
      return this.assetsTab != '';
    },
    hasRelatedFunnels() {
      return (
        this.localFunnelResource &&
        this.relatedFunnels &&
        this.relatedFunnels.length > 0
      );
    },
    hasOtherResources() {
      return (
        this.localFunnelResource &&
        this.otherResources &&
        this.otherResources.length > 1
      );
    },
    hasValidOtherResources() {
      return (
        this.localFunnelResource &&
        this.otherResources &&
        Array.isArray(this.otherResources) &&
        typeof this.otherResources[0] != 'undefined'
      );
    },
    shouldShowSwitches() {
      return (
        this.assetsTab != 'links' &&
        this.assetsTab != 'stats'
      );
    },
  },
  methods: {
    async toggleSwitchValue(type) {
      let updatedValue = 1;

      if (type == 1) {
        updatedValue = this.localFunnelResource.affiliate_resource.swipes_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'swipes') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 2) {
        updatedValue = this.localFunnelResource.affiliate_resource.blog_reviews_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'blogReviews') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 3) {
        updatedValue = this.localFunnelResource.affiliate_resource.banners_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'banners') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 4) {
        updatedValue = this.localFunnelResource.affiliate_resource.thankyou_page_ads_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'thankyouPageAds') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 5) {
        updatedValue = this.localFunnelResource.affiliate_resource.logout_page_ads_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'logoutPageAds') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 6) {
        updatedValue = this.localFunnelResource.affiliate_resource.facebook_ads_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'facebookAds') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 7) {
        updatedValue = this.localFunnelResource.affiliate_resource.social_media_posts_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'socialMediaPosts') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 8) {
        updatedValue = this.localFunnelResource.affiliate_resource.press_releases_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'pressReleases') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 9) {
        updatedValue = this.localFunnelResource.affiliate_resource.video_reviews_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'videoReviews') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      } else if (type == 10) {
        updatedValue = this.localFunnelResource.affiliate_resource.signatures_completed;

        var foundIndex = this.localAssetsTabs.findIndex((asset, index) => {
          if (asset.value == 'signatures') return true;
        });

        if (foundIndex != -1) {
          this.localAssetsTabs[foundIndex].completed = updatedValue;
        }
      }

      this.$emit('onToggleSwitchValue', {
        assetsTabs: this.localAssetsTabs,
        funnelResource: this.localFunnelResource,
      });

      await axios.post('/toggle-affiliate-funnel-resource-switch', {
        type,
        id: this.localFunnelResource.affiliate_resource.id,
        value: updatedValue,
      });
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
  watch: {
    funnelResource(newVal) {
      this.localFunnelResource = newVal;
    },
  },
};
</script>
