import MyFunnels from '@/pages/funnels/index';
import FunnelDashboard from '@/pages/funnels/dashboard';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/funnels',
    name: 'funnels',
    component: MyFunnels,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_manage_product_funnel')
    }
  },
  {
    path: '/funnels/:id/dashboard',
    name: 'funnelDashboard',
    component: FunnelDashboard,
    meta: {
      pageTitle: ' '
    }
  }
];
