<template>
  <div>
    <div v-if="isPostSaleBumpOpen">
      <post-sale-bumps :heading="postSaleBumpDetails.title" :subheading="postSaleBumpDetails.subheading"
        :description="postSaleBumpDetails.description"
        :post-order-video-details="postSaleBumpDetails.post_order_video_details"
        :selected-pricepoint="postSaleBumpDetails.pricing_option" :selected-product="postSaleBumpDetails.product"
        :currency-code="postSaleBumpDetails.currency_code"
        :checkout-page-options="postSaleBumpDetails.checkout_page_options"
        :buynow-text="postSaleBumpDetails.buynow_button_text" :thumbnail="postSaleBumpDetails.thumbnail"
        :thumbnail-img="postSaleBumpDetails.thumbnail_img" :thumbnail-is-square="postSaleBumpDetails.thumbnail_is_square"
        :thumbnail-size="postSaleBumpDetails.thumbnail_size" :is-processing="postSaleBumpDetails.is_processing"
        :bump-type="postSaleBumpDetails.style" :all-products="postSaleBumpDetails.funnelProducts"
        :all-pricepoints="postSaleBumpDetails.productPricePoint" @onClickedClosePreview="clickedClosePreview" />
    </div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step6_order_bumps') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step6_configure')
        }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs :tabs="orderBumpTypeTabs" :current-tab="orderBumpTypeTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
          :error-tabs="errorTabs"
          :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleOrderBumpTypeTabChange" />
      </div>
      <div class="mt-2">
        <presell v-show="orderBumpTypeTab === 'presell'" ref="presell" :model="localModel" @update-model="HandleModelUpdate" />
        <postsell v-show="orderBumpTypeTab === 'postsell'" ref="postsell" :model="localModel" @preview-post-sale-bump="HandlepreviewPostSaleBump" @update-model="HandleModelUpdate" />
      </div>
    </div>
  </div>
</template>

<script>
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';
import {
  required,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';
import urlParser from 'js-video-url-parser/lib/base';
import PostSaleBumps from '@/components/PostSaleBumps';

import CheckoutPage from '@/assets/images/pages/checkout-page.svg';
import 'js-video-url-parser/lib/provider/wistia';

import Presell from './tabs/bumps/Presell'
import Postsell from './tabs/bumps/Postsell'

export default {
  name: 'Step6',
  components: {
    CheckoutPage,
    ConfigMenu,
    ImagePicker,
    PostSaleBumps,
    Presell,
    Postsell
  },
  props: {
    model: {
      required: true,
    },
    funnelProducts: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      isPostSaleBumpOpen: false,
      postSaleBumpDetails: {
        title: '',
        description: '',
        post_order_video_details: [],
        style: 1,
        pricing_option: null,
        product: null,
        thumbnail: 0,
        is_processing: false,
        currency_code: '$',
        buynow_button_text: this.$t('frontend_add_to_your_order'),
      },
      previewOrderBumps: false,
      offerBumpComponentKey: 1,
      orderBumpTypeTabs: [
        {
          title: this.$t('frontend_product_step6_tab_presell'),
          value: 'presell',
        },
        {
          title: this.$t('frontend_product_step6_tab_postsell'),
          value: 'postsell',
        },
      ],
      orderBumpTypeTab: 'presell',
      products: null,
      pricePoints: null,
      errorTabs: [],
    };
  },
  validations: {
    localModel: {
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          offer_bump_options: {
            $each: {
              heading_line: {
                required: requiredIf((object, value) => {
                  if (object) {
                    return true;
                  }

                  return false;
                }),
              },
              description: {
                required: requiredIf((object, value) => {
                  if (object) {
                    return true;
                  }

                  return false;
                }),
              },
            },
          },
          post_sale_order_bump_options: {
            post_order_heading_line: {
              required: requiredIf((object) => {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1
                ) {

                  return true;
                }

                return false;
              }),
            },
            post_order_subheading: {
              required: requiredIf((object) => {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1
                ) {
                  return true;
                }
                return false;
              }),
            },
            post_order_description: {
              required: requiredIf((object) => {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1
                ) {
                  console.log('head_line error9')
                  return true;
                }
                console.log('head_line error10')
                return false;
              }),
            },
            post_order_product_id: {
              isValidProductId(value, object) {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1 &&
                  (!value || value == 0)
                ) {
                  return false;
                }
                return true;
              },
            },
            post_order_price_point_id: {
              isValidPricePointtId(value, object) {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1 &&
                  (!value || value == 0)
                ) {
                  return false;
                }
                return true;
              },
            },
            post_order_video_details: {
              url: {
                required: requiredIf((object) => {
                  if (
                    object.post_order_show_video &&
                    object.post_order_show_video == true
                  ) {
                    return true;
                  }
                  return false;
                }),
                isValidPostSaleVideoUrl(value, object) {
                  if (
                    object.post_order_show_video &&
                    object.post_order_show_video == true
                  ) {
                    const videoInfo = urlParser.parse(value);

                    if (videoInfo != undefined) {
                      return true;
                    }
                    const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
                    const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();
                    const expression = new RegExp(`(http:|https:|)\/\/?(app\\.${brandAltName}\\.com)\/${videoAppTitle}\/videopage\/[A-Za-z0-9]*\/[A-Za-z0-9]*`, 'g');
                    const regex = new RegExp(expression);
                    if (value.match(regex)) {
                      return true;
                    }
                    return false;
                  }
                  return true;
                },
              },
            },
          },
        },
      },
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  methods: {
    HandleModelUpdate(newVal) {
      this.localModel = newVal;
    },
    clickedClosePreview() {
      this.isPostSaleBumpOpen = false;
    },
    HandlepreviewPostSaleBump(postSaleOrderBumpOptions, funnelProducts, postSaleProductPricePoint) {
      this.previewPostSaleBump(postSaleOrderBumpOptions, funnelProducts, postSaleProductPricePoint);
    },
    previewPostSaleBump(post_sale_order_bump_options, products, pricePoints) {
      this.postSaleBumpDetails = {
        title: post_sale_order_bump_options.post_order_heading_line,
        subheading: post_sale_order_bump_options.post_order_subheading,
        description: post_sale_order_bump_options.post_order_description,
        post_order_video_details:
          post_sale_order_bump_options.post_order_video_details,
        style: post_sale_order_bump_options.post_order_style_id,
        product: post_sale_order_bump_options.post_order_product_id,
        pricing_option: post_sale_order_bump_options.post_order_price_point_id,
        thumbnail: post_sale_order_bump_options.post_order_display_thumbnail,
        thumbnail_img: post_sale_order_bump_options.post_order_thumbnail_image,
        thumbnail_is_square: post_sale_order_bump_options.post_order_is_square,
        thumbnail_size: post_sale_order_bump_options.post_order_thumbnail_size,
        currency_code: '$',
        buynow_button_text: this.$t('frontend_yes_add_to_my_order'),
        funnelProducts: products,
        productPricePoint: pricePoints,
        checkout_page_options: this.localModel.checkout_page_options,
      };

      this.isPostSaleBumpOpen = true;
    },
  
    funnelName(id) {
      const funnel = this.funnels.find((funnel) => funnel.id === id);
      return funnel.name;
    },

    productName(funnelid, id) {
      const funnel = this.funnels.find((funnel) => funnel.id === funnelid);
      const product = funnel.products.find((product) => product.id === id);
      return product.name;
    },

    pricepointName(funnelid, productid, id) {
      const funnel = this.funnels.find((funnel) => funnel.id === funnelid);
      const product = funnel.products.find(
        (product) => product.id === productid
      );
      const pricepoint = product.price_points.find(
        (pricePoint) => pricePoint.id === id
      );
      return pricepoint.name;
    },

    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(',');
      return `#${(
        (1 << 24) +
        (Number(rgb[0]) << 16) +
        (Number(rgb[1]) << 8) +
        Number(rgb[2])
      )
        .toString(16)
        .slice(1)}`;
    },

    setValue(value, index) {
      this.localModel.pricing_points[index].style_id = value;
    },

  

    handleOrderBumpTypeTabChange(newTab) {
      this.orderBumpTypeTab = newTab;
    },

    validate() {
      this.$refs.presell?.$v?.$touch();
      this.$refs.postsell?.$v?.$touch();
      this.errorTabs = this.getErrorTabs();
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      console.log(this.$v);
      if (isValid) {
        console.log('is valid');
      } else {
        console.log('is not valid');
      }

      this.$emit('on-validate', isValid);
      return isValid;
    },
    getErrorTabs() {
      const invalidTabs = [];

      if (this.$refs.presell?.$v?.$invalid) {
        invalidTabs.push('presell');
      }

      if (this.$refs.postsell?.$v?.$invalid) {
        invalidTabs.push('postsell');
      }

      return invalidTabs;
    },
  },
};
</script>
