<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliates">
        <!-- Table Upper Section -->
        <div class="flex flex-row">
          <gd-button variant="primary" size="auto" class="mt-3 ml-auto" @click="createLeaderboard">
            <plus-icon class="h-5 w-5 fill-current" />
            {{ $t('leaderboard') }}
          </gd-button>
        </div>

        <!-- Leader Board List Table -->
        <leader-boards-list :leaderBoardData="leaderBoardData" :EditLeaderboard="EditLeaderboard"
          :leaderBoardTeams="leaderBoardTeams"
          @refreshLeaderboardData="refreshLeaderboardData" @update-attributes="updateAttributes" />

        <!-- Create Leader Board modal -->
        <save-leader-board-modal v-if="showSaveLeaderBoardModal" :openModal="openModal"
          :leaderBordPricingData="leaderBordPricingData" @refreshLeaderboardData="refreshLeaderboardData"
          :LeaderBoardId="LeaderBoardId" @update-attributes="updateAttributes"
          @updatePricePositionOptions="updatePricePositionOptions" :editPrize="editPrize" :deletePrize="deletePrize"
          :key="modalKey" />

        <!-- Add prize modal -->
        <add-prize-modal :pricePositionOptions="pricePositionOptions" :validation="$v" v-if="showSaveLeaderBoardModal"
          @update-attributes="updateAttributes" />

        <!-- edit single prize modal -->
        <edit-prize-modal :editSinglePriceModel="editSinglePriceModel" :validation="$v" :pricePointCurrency="pricePointCurrency"
          :updatePrize="updatePrize" @update-attributes="updateAttributes" />

        <!-- copy leader board link modal -->
        <copy-leader-board-link-modal :leaderboard="leaderboard" :key="modalLinkKey" />

        <!-- LeaderBoard Teams -->
        <leader-board-team :leaderBordTeamData="leaderBordTeamData" :openModal="openModal"
          :editLeaderBoardTeam="editLeaderBoardTeam" :deleteLeaderBoardTeam="deleteLeaderBoardTeam"
          :saveLeaderBoardTeam="saveLeaderBoardTeam" @update-attributes="updateAttributes"
        />

        <!-- LeaderBoard Teams Add-->
        <add-leader-board-team :leaderboard="leaderboard" :leaderBoardTeamModal="leaderBoardTeamModal"
          :selectedAffiliateIdArr="selectedAffiliateIdArr" :addedAffiliate_add_team="addedAffiliate_add_team"
          :suggestionSelectedAffiliates_add_team="suggestionSelectedAffiliates_add_team"
          :removedAffiliate_add_team="removedAffiliate_add_team" :addEditLeaderBoardTeam="addEditLeaderBoardTeam"
          :validation="$v" @update-attributes="updateAttributes" />


        <!-- LeaderBoard Teams Edit-->
        <edit-leader-board-team :leaderboard="leaderboard" :leaderBoardTeamEditModal="leaderBoardTeamEditModal"
          :selectedAffiliateIdArr="selectedAffiliateIdArr" :addedAffiliate="addedAffiliate"
          :suggestionSelectedAffiliates="suggestionSelectedAffiliates" :removedAffiliate="removedAffiliate"
          :updateLeaderBoardTeam="updateLeaderBoardTeam" :validation="$v" @update-attributes="updateAttributes"
        />


        <!-- Add affiliates modal -->


        <!-- Edit affiliates modal -->


        <!-- Block leaderboard affiliate-->
        <block-leader-board-affiliates :leaderboard="leaderboard" :blockAffFinalArr="blockAffFinalArr"
          :selectedAffiliateBlockedIdArr="selectedAffiliateBlockedIdArr"
          :suggestionSelectedAffiliates_block_aff="suggestionSelectedAffiliates_block_aff"
          @update-attributes="updateAttributes" />

      </v-wait>
    </div>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import LeaderboardWidget from '@/models/LeaderboardWidget';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Multiselect from 'vue-multiselect';
import createLinkMixin from '@/mixins/createLinkMixin';
import moment from 'moment-timezone';



/** new components */
import LeaderBoardsList from './leader_board_partials/parts/LeaderBoardsList';
import SaveLeaderBoardModal from './leader_board_partials/modals/SaveLeaderBoardModal';
import CopyLeaderBoardLinkModal from './leader_board_partials/modals/CopyLeaderBoardLinkModal';
import AddPrizeModal from './leader_board_partials/modals/AddPrizeModal';
import EditPrizeModal from './leader_board_partials/modals/EditPrizeModal';
import LeaderBoardTeam from './leader_board_partials/modals/LeaderBoardTeam';
import AddLeaderBoardTeam from './leader_board_partials/modals/AddLeaderBoardTeam';
import EditLeaderBoardTeam from './leader_board_partials/modals/EditLeaderBoardTeam';
import BlockLeaderBoardAffiliates from './leader_board_partials/modals/BlockLeaderBoardAffiliates';
import '@/assets/scss/leader-boards.scss';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_products_create_tabs_affiliates') };
  },
  mixins: [createLinkMixin],
  validations: {
    leaderBoardTeamModal: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
    },
    leaderBoardTeamEditModal: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
    },
    editPriceModel: {
      pricePointName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      pricePointValue: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      pricePositionOptionsValue: {
        required,
      },
    },
    editSinglePriceModel: {
      pricePointName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      pricePointValue: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      pricePositionOptionsValue: {
        required,
      },
    },
  },

  components: {
    AgGridVue,
    Multiselect,
    LeaderBoardsList,
    SaveLeaderBoardModal,
    CopyLeaderBoardLinkModal,
    AddPrizeModal,
    EditPrizeModal,
    LeaderBoardTeam,
    AddLeaderBoardTeam,
    EditLeaderBoardTeam,
    BlockLeaderBoardAffiliates,
    PlusIcon,
  },

  data() {
    return {
      modalKey: 0,
      modalLinkKey: 0,
      showSaveLeaderBoardModal: false,
      LeaderBoardId: null,
      blockAffFinalArr: [],
      pricePointCurrency: 'USD',
      editTeamIndex: null,
      selectedAffiliateIdArr: [],
      leaderBoardTeamModal: {
        name: null,
        affiliates: [],
      },
      leaderBoardTeamEditModal: {
        name: null,
        affiliates: [],
      },
      leaderBordTeamData: [],
      leaderboard_team_funnel_id: null,
      leaderboard_team_affiliatesOptions: [],
      editPrizeIndex: null,
      oldPricePositionOptionsValue: null,
      editPriceModel: {
        pricePointName: null,
        pricePointValue: 0,
        pricePositionOptionsValue: null,
        prize_image: null,
        pricePositionOptions: [], // dynamic price postion array
      },
      editSinglePriceModel: {
        pricePointName: null,
        pricePointValue: 0,
        prize_image: null,
        pricePositionOptionsValue: null,
        pricePositionOptions: [], // dynamic price postion array
      },
      pricePositionOptions: [], // dynamic price postion array
      leaderBordPricingData: [],
      leaderBoardData: [],
      leaderboardProductsTab: '',
      leaderboardTimePicker: true,
      leaderboardTimePicker24Hour: true,
      leaderboardTimePickerIncrement: 30,
      leaderBoardStep: 1,
      leaderboardFunnels: [],
      leaderboardStartingDate: moment().toISOString(),
      leaderboardEndingDate: moment().add(30, 'days').toISOString(),
      leaderboardDateRange: {
        startDate: moment().toISOString(),
        endDate: moment().add(30, 'days').toISOString(),
      },
      leaderboard: null,
      domLayout: 'autoHeight',
      selectedFunnel: [],
      isFunnelAdded: true,
      suggestionSelectedAffiliates_add_team: [],
      suggestionSelectedAffiliates_block_aff: [],
      selectedAffiliateBlockedIdArr: [],
      slected_aff_count_add_team: 0,
      slected_aff_count_block_aff: 0,
      affiliate_empty_err_add_team: false,
      affiliate_empty_err_block_aff: false,
      suggestionSelectedAffiliates: [],
      slected_aff_count: 0,
      affiliate_empty_err: false,
    };
  },

  computed: {
    pricePosition() {
      if (this.leaderboard) {
        const arr = [];
        for (let i = 1; i <= mynumber; i++) {
          arr.push(i.toString());
        }
      }
    },
  },
  async created() {
    this.$wait.start('affiliates');
    const funnels = await Funnel.$get();
    if (funnels.length > 0) {
      this.funnels = funnels;
      this.selectedFunnel = funnels[0].id;
      this.isFunnelAdded = true;
    } else {
      this.isFunnelAdded = false;
    }
    this.onLeaderBoardGridReady();
    this.$wait.end('affiliates');
  },

  methods: {
    updateAttributes(v) {
      const { key, value, action } = v;
      if (this[key] !== undefined) {
        if (action === 'push') {
          return this[key].push(value);
        }
        this[key] = value;

      }
    },
    async refreshLeaderboardData() {
      this.leaderBoardData = await LeaderboardWidget.$get();
    },
    updatePricePositionOptions(leaderboard) {
      this.editPriceModel.pricePositionOptions = this.generateDynamicOptionArr(leaderboard);
      this.pricePositionOptions = this.generateDynamicOptionArr(leaderboard);
    },

    openModal(modal_name, action) {
      this.$root.$emit('modal-open', modal_name);
    },
    async leaderBoardTeams(leaderBoardId) {
      this.$loader.start('leaderBoardTeams');
      this.suggestionSelectedAffiliates = [];
      this.leaderBoardTeamModal.name = null;
      this.affiliate_empty_err = false;
      this.slected_aff_count = 0;
      this.selectedAffiliateIdArr = []; // reset already selected affiliates array
      this.$v.leaderBoardTeamModal.$reset();
      this.leaderboard = await LeaderboardWidget.$find(leaderBoardId);
      this.leaderboardDateRange = {
        startDate: new Date(this.leaderboard.start_timestamp * 1000).toISOString(),
        endDate: new Date(this.leaderboard.end_timestamp * 1000).toISOString(),
      };


      if (this.leaderboard.leaderboard_config.length != 0 && this.leaderboard.leaderboard_config.teams != undefined) {
        this.leaderBordTeamData = this.leaderboard.leaderboard_config.teams;
      }

      if (this.leaderBordTeamData.length) {
        for (let i = 0; i < this.leaderBordTeamData.length; i++) {
          for (let j = 0; j < this.leaderBordTeamData[i].affiliates_id.length; j++) {
            this.selectedAffiliateIdArr.push(this.leaderBordTeamData[i].affiliates_id[j]);
          }
        }
      }
      this.$root.$emit('modal-open', 'leaderboards-team-modal');
      this.$loader.end('leaderBoardTeams');
    },
    addedAffiliate_add_team(affiliate) {
      this.suggestionSelectedAffiliates_add_team.push(affiliate.id);
      this.selectedAffiliateIdArr.push(affiliate.id);
      this.affiliate_empty_err_add_team = false;
      if (this.suggestionSelectedAffiliates_add_team.length == 0) {
        this.affiliate_empty_err_add_team = true;
        return;
      }
      this.slected_aff_count_add_team = this.suggestionSelectedAffiliates_add_team.length;
    },

    removedAffiliate_add_team(affiliate) {
      this.suggestionSelectedAffiliates_add_team.splice(this.suggestionSelectedAffiliates_add_team.indexOf(affiliate), 1);
      this.selectedAffiliateIdArr.splice(this.selectedAffiliateIdArr.indexOf(affiliate), 1);
      this.affiliate_empty_err_add_team = false;
      this.slected_aff_count_add_team = this.selectedAffiliateIdArr.length;
    },

    addedAffiliate(affiliate) {
      this.suggestionSelectedAffiliates.push(affiliate.id); // for affiliate list view
      this.selectedAffiliateIdArr.push(affiliate.id); // for affiliate list search
      this.affiliate_empty_err = false;
      if (this.suggestionSelectedAffiliates.length == 0) {
        this.affiliate_empty_err = true;
        return;
      }
      this.slected_aff_count = this.suggestionSelectedAffiliates.length;
    },

    removedAffiliate(affiliate) {
      this.suggestionSelectedAffiliates.splice(this.suggestionSelectedAffiliates.indexOf(affiliate), 1);
      this.selectedAffiliateIdArr.splice(this.selectedAffiliateIdArr.indexOf(affiliate), 1);
      this.affiliate_empty_err = false;
      if (this.suggestionSelectedAffiliates.length == 0) {
        this.affiliate_empty_err = true;
      }
      this.slected_aff_count = this.suggestionSelectedAffiliates.length;
    },


    // edit leader board team
    async addEditLeaderBoardTeam() {
      this.$v.leaderBoardTeamModal.$touch();
      if (this.$v.leaderBoardTeamModal.$error) {
        return;
      }

      if (this.suggestionSelectedAffiliates_add_team.length == 0) {
        this.affiliate_empty_err_add_team = true;
        return;
      }

      // create array of affliliate and push id into array
      const affiliateId = [];
      for (let i = 0; i < this.suggestionSelectedAffiliates_add_team.length; i++) {
        affiliateId.push(this.suggestionSelectedAffiliates_add_team[i]);
      }

      this.leaderBordTeamData.push({
        name: this.leaderBoardTeamModal.name,
        affiliates: this.suggestionSelectedAffiliates_add_team.length,
        affiliates_id: affiliateId,
      });

      this.$v.leaderBoardTeamModal.$reset();
      this.leaderBoardTeamModal.name = null;
      this.leaderBoardTeamModal.affiliates = null;
      this.suggestionSelectedAffiliates_add_team = [];
      this.affiliate_empty_err_add_team = false;
      this.slected_aff_count_add_team = 0;
      this.$root.$emit('modal-close', 'add-leaderboard-team-modal');

    },

    // update leader board team
    async updateLeaderBoardTeam() {

      this.$loader.start('updateLeaderBoardTeam');
      this.$v.leaderBoardTeamEditModal.$touch();
      if (this.$v.leaderBoardTeamEditModal.$error) {
        this.$loader.end('updateLeaderBoardTeam');
        return;
      }

      if (this.suggestionSelectedAffiliates.length == 0) {
        this.affiliate_empty_err = true;
        this.$loader.end('updateLeaderBoardTeam');
        return;
      }

      // create array of affliliate and push id into array
      const affiliateId = [];
      for (let i = 0; i < this.suggestionSelectedAffiliates.length; i++) {
        affiliateId.push(this.suggestionSelectedAffiliates[i]);
      }

      // first remove element from index
      this.leaderBordTeamData.splice(this.editTeamIndex, 1);
      // push upadted data on index
      this.leaderBordTeamData.splice(this.editTeamIndex, 0, {
        name: this.leaderBoardTeamEditModal.name,
        affiliates: this.suggestionSelectedAffiliates.length,
        affiliates_id: affiliateId,
      });
      this.$root.$emit('modal-close', 'edit-leaderboard-team-modal');
      this.suggestionSelectedAffiliates = [];
      this.slected_aff_count = 0;
      this.$loader.end('updateLeaderBoardTeam');
    },

    // save leader board team data
    async saveLeaderBoardTeam() {
      this.$loader.start('saveLeaderBoardTeam');
      this.leaderboard.leaderboard_config = {
        prices: this.leaderboard.leaderboard_config.prices,
        teams: this.leaderBordTeamData.length ? this.leaderBordTeamData : [],
      };
      const savedLeaderboard = await this.leaderboard.save();
      this.$root.$emit('modal-close', 'leaderboards-team-modal');
      this.$loader.end('saveLeaderBoardTeam');
    },

    // delete leaderboard team
    deleteLeaderBoardTeam(index, data) {
      this.leaderBordTeamData.splice(index - 1, 1);

      for (let i = 0; i < data.affiliates_id.length; i++) {
        const index = this.selectedAffiliateIdArr.indexOf(
          data.affiliates_id[i]
        );
        this.selectedAffiliateIdArr.splice(index, 1);
      }
    },

    // edit leader board team modal
    async editLeaderBoardTeam(index, data) {

      this.suggestionSelectedAffiliates = [];
      this.$loader.start('saveLeaderBoardTeam');
      this.editTeamIndex = index - 1;
      this.leaderBoardTeamEditModal.affiliates = [];
      this.leaderBoardTeamEditModal.name = '';

      this.leaderBoardTeamEditModal.name = data.name;
      this.suggestionSelectedAffiliates = data.affiliates_id;
      this.slected_aff_count = data.affiliates_id.length;
      this.$root.$emit('modal-open', 'edit-leaderboard-team-modal');
      this.$loader.end('saveLeaderBoardTeam');
    },

    // delete data function from v client table of price points
    deletePrize(index, data, event_type) {
      this.leaderBordPricingData.splice(index - 1, 1);
      let pricePositionOption = event_type == 'edit' ? this.editPriceModel.pricePositionOptions : this.pricePositionOptions;
      pricePositionOption.splice(data.pricePositionOptionsValue, 0, data.pricePositionOptionsValue);
      pricePositionOption.sort((a, b) => a - b);
      this.pricePositionOptions = pricePositionOption;
    },

    // edit prize
    async editPrize(index, data) {
      this.$loader.start('editPrize');
      this.editPrizeIndex = index - 1;
      this.oldPricePositionOptionsValue = data.pricePositionOptionsValue;

      this.editSinglePriceModel.pricePointName = data.pricePointName;
      this.editSinglePriceModel.pricePointValue = data.pricePointValue;
      this.editSinglePriceModel.pricePositionOptionsValue = data.pricePositionOptionsValue;
      this.editSinglePriceModel.prize_image = data.prize_image;


      // enter prizes data
      this.leaderboard.leaderboard_config.prices = this.leaderBordPricingData;
      // generate prizes options by ignoring prev value
      this.editSinglePriceModel.pricePositionOptions =
        this.generateDynamicOptionArr(this.leaderboard, data.pricePositionOptionsValue);
      this.$root.$emit('modal-open', 'leaderboards-edit-prize-modal');
      this.$loader.end('editPrize');
    },

    async updatePrize() {

      this.$loader.start('updatePrize');
      this.$v.editSinglePriceModel.$touch();
      console.log('this.$v.editSinglePriceModel', this.$v.editSinglePriceModel)
      if (this.$v.editSinglePriceModel.$error) {
        this.$loader.end('updatePrize');
        return;
      }

      this.editSinglePriceModel.pricePositionOptions = this.generateDynamicOptionArr(this.leaderboard);
      this.editPriceModel.pricePositionOptions.splice(this.editPriceModel.pricePositionOptions.indexOf(this.editSinglePriceModel.pricePositionOptionsValue), 1);

      // if old selected position and new selected position not same
      if (this.editSinglePriceModel.pricePositionOptionsValue != this.oldPricePositionOptionsValue) {
        this.editPriceModel.pricePositionOptions.push(this.oldPricePositionOptionsValue);
        this.editPriceModel.pricePositionOptions.sort((a, b) => a - b);
      }

      if (this.editSinglePriceModel.pricePositionOptionsValue != this.oldPricePositionOptionsValue) {
        this.pricePositionOptions.push(this.oldPricePositionOptionsValue);
        this.pricePositionOptions.splice(
          this.pricePositionOptions.indexOf(
            this.editSinglePriceModel.pricePositionOptionsValue
          ),
          1
        );
        this.pricePositionOptions.sort((a, b) => a - b);
      }

      this.leaderBordPricingData.splice(this.editPrizeIndex, 1);
      this.leaderBordPricingData.splice(this.editPrizeIndex, 0, {
        pricePointName: this.editSinglePriceModel.pricePointName,
        pricePointValue: this.editSinglePriceModel.pricePointValue,
        pricePositionOptionsValue:
          this.editSinglePriceModel.pricePositionOptionsValue,
        prize_image: this.editSinglePriceModel.prize_image,
      });

      this.$root.$emit('modal-close', 'leaderboards-edit-prize-modal');
      this.$loader.end('updatePrize');
      this.$v.editSinglePriceModel.$reset();
      this.editSinglePriceModel.pricePointName = null;
      this.editSinglePriceModel.pricePointValue = null;
      this.editSinglePriceModel.pricePositionOptions = [];
    },

    generateDynamicOptionArr(leaderBoardData, ignore_position = null) {

      const mynumber = leaderBoardData.max_affiliates;
      const leaderBaordPriceData = leaderBoardData.leaderboard_config.prices;

      const ignoreArr = [];
      for (let i = 0; i < leaderBaordPriceData.length; i++) {
        if (ignore_position != null && ignore_position == leaderBaordPriceData[i].pricePositionOptionsValue) {
          continue;
        }
        ignoreArr.push(leaderBaordPriceData[i].pricePositionOptionsValue);
      }

      const arr = [];
      for (let i = 1; i <= mynumber; i++) {
        if (!ignoreArr.includes(i.toString())) {
          arr.push(i.toString());
        }
      }

      arr.sort((a, b) => a - b); // sort array

      return arr;
    },

    async createLeaderboard() {
      this.modalKey++;
      this.LeaderBoardId = null;
      this.showSaveLeaderBoardModal = true;
      this.$nextTick(function () {
        this.$root.$emit('modal-open', 'leaderboards-modal');
      });
    },

    async EditLeaderboard(leader_board_id) {
      this.modalKey++;
      this.$v.editPriceModel.$reset();
      this.LeaderBoardId = leader_board_id;
      this.showSaveLeaderBoardModal = true;
      this.$nextTick(function () {
        this.$root.$emit('modal-open', 'leaderboards-modal');
      });
    },

    async onLeaderBoardGridReady() {
      this.leaderBoardData = await LeaderboardWidget.$get();
    },

    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  },
};
</script>

