<template>
  <div>
    <div class="w-full">
      <v-wait for="transactions">
        <div class="relative plus-quick-links">
          <quick-links :links="quickLinks" />
          <div
            class="absolute right-0 top-0 mr-5 mt-4 cursor-pointer"
            v-tooltip="$t('frontend_sites_side_bar_groove_affiliate_market')"
            @click="goToMarketplace"
          >
            <market-place-icon class="h-8 w-8 text-grey-500 fill-current" />
          </div>

        </div>
        <div class="mt-8">
          <vendor-report :hide-loader="true"/>
        </div>
        <div class="flex flex-row my-8">
          <div class="w-1/2">
            <dashboard-chart
              :title="$t('frontend_revenue')"
              :data="revenueData"
              @refresh="getGroovesellRevenue"
              @redirect="$router.push({ name: 'funnels' })"
            >
            </dashboard-chart>
          </div>
          <div class="w-1/2 ml-4">
            <dashboard-chart
              :title="$t('frontend_visitors')"
              :data="visitorsData"
              @refresh="getGroovesellVisitors"
              @redirect="$router.push({ name: 'funnels' })"
            >
            </dashboard-chart>
          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import QuickLinks from '@groovepages/gd-ui-kit/components/QuickLinks';
import DashboardChart from '@groovepages/gd-ui-kit/components/DashboardChart';
import moment from 'moment';
import axios from 'axios';
import FunnelIcon from '@/assets/images/icons/FunnelIcon.svg';
import ColumnChartIcon from '@/assets/images/icons/ColumnChartIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import PieChartIcon from '@/assets/images/icons/PieChartIcon.svg';
import CommentUserIcon from '@/assets/images/icons/CommentUserIcon.svg';
import ShoppingCartIcon from '@/assets/images/icons/ShoppingCartIcon.svg';
import MarketPlaceIcon from '@/assets/images/icons/MarketplaceIcon.svg';
import AddIcon from '@/assets/images/icons/AddIcon.svg';
import VendorReport from '@/pages/analytics/vendor_report.vue';

const ONE_WEEK = 7;
const TWO_WEEKS = 14;
const ONE_MONTH = 30;

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_groovesell_routes_vendor_report') };
  },
  components: {
    QuickLinks,
    FunnelIcon,
    DashboardChart,
    VendorReport,
    MarketPlaceIcon,
    AddIcon,
  },
  data() {
    return {
      opens: 'right',
      quickLinks: [
        {
          name: this.$t('frontend_resources_funnels_and_products'),
          callback: () => this.$router.push({ name: 'funnels' }),
          icon: FunnelIcon,
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_reporting'),
          callback: () => this.$router.push({ name: 'transactions' }),
          icon: ColumnChartIcon,
        },
        {
          name: this.$t('frontend_gduikit_sidebar_manage_affiliates'),
          callback: () => this.$router.push({ name: 'affiliates' }),
          icon: HandshakeIcon,
        },
        {
          name: this.$t('frontend_aff_perf'),
          callback: () => this.$router.push({ name: 'affiliates_performance' }),
          icon: PieChartIcon,
        },
        {
          name: this.$t('frontend_gduikit_sidebar_customers'),
          callback: () => this.$router.push({ name: 'customers' }),
          icon: CommentUserIcon,
        },
        {
          name: this.$t('frontend_gduikit_sidebar_checkout_forms'),
          callback: () => this.$router.push({ name: 'default_checkout_links' }),
          icon: ShoppingCartIcon,
        },
        {
          name: this.$t('frontend_resources_new_funnel'),
          callback: () => this.$router.push({ name: 'funnels' }),
          icon: AddIcon,
        },
      ],
      revenueData: {
        response: null,
        stats: [],
        data: [
          {
            name: '',
            data: [],
          },
        ],
        noOfDays: 0,
        hasUsed: 0,
        hasData: 0,
        isLoaded: 0,
      },
      visitorsData: {
        response: null,
        stats: [],
        data: [
          {
            name: '',
            data: [],
          },
        ],
        noOfDays: 0,
        hasUsed: 0,
        hasData: 0,
        isLoaded: 0,
      },
    };
  },
  created() {
    this.getGroovesellRevenue(this.revenueData.noOfDays);
    this.getGroovesellVisitors(this.visitorsData.noOfDays);
  },
  methods: {
    async getGroovesellRevenue(days) {
      this.updateRevenueDataDays(days);
      const { fromDate, toDate } = this.calculateDatesBasedOnDays(this.revenueData.noOfDays);

      await this.fetchVendorReport(fromDate, toDate);
    },
    updateRevenueDataDays(days) {
      this.revenueData.noOfDays = days;
      this.revenueData.isLoaded = 0;
    },
    async fetchVendorReport(fromDate, toDate) {
      const response = await axios.post('charts/get-vendor-report', {
        chart_value: 'revenue',
        affiliates: [{ id: 0, name: 'All' }],
        diffDays: this.getRevenueDays,
        fromDate,
        toDate,
        funnels: [],
        selected_tab: 'total',
      });

      this.processResponseData(response.data);
    },
    processResponseData(data) {
      this.revenueData.response = data;
      this.revenueData.data[0].data = data.chart_values;
      this.revenueData.hasUsed = data.has_used;

      const { selected_tab_data } = data;
      this.revenueData.hasData = this.checkIfDataExists(selected_tab_data);

      this.populateStats(selected_tab_data);
      this.revenueData.isLoaded = 1;
    },
    checkIfDataExists(tabData) {
      return (
        tabData.sales_count > 0 ||
        tabData.rebills_count > 0 ||
        tabData.refunded_count > 0 ||
        tabData.revenue > 0 ||
        tabData.profit > 0
      ) ? 1 : 0;
    },
    populateStats(data) {
      this.revenueData.stats = [
        {
          name: this.$t('sales_plus_rebills'),
          stat: `${this.formatNumber(data.sales_count)} + ${this.formatNumber(data.rebills_count)}`,
        },
        {
          name: this.$t('frontend_groovesell_refunds'),
          stat: this.formatNumber(data.refunded_count),
        },
        {
          name: this.$t('frontend_revenue'),
          stat: `$${this.formatNumber(data.revenue, 2)}`,
        },
        {
          name: this.$t('profits'),
          stat: `$${this.formatNumber(data.profit, 2)}`,
        },
      ];
    },
    numberSystem(num, decimals) {
      if (decimals == 0) {
        return parseInt(num).toLocaleString();
      }

      return parseInt(num)
        .toFixed(decimals)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    async getGroovesellVisitors(days) {
      this.updateVisitorsData(days);
      const { fromDate, toDate } = this.calculateDatesBasedOnDays(this.visitorsData.noOfDays);

      await this.fetchVendorReportForVisitors(fromDate, toDate);
    },

    updateVisitorsData(days) {
      this.visitorsData.noOfDays = days;
      this.visitorsData.isLoaded = 0;
    },
    calculateDatesBasedOnDays(noOfDays) {
      let fromDate = moment();
      const toDate = moment();

      switch (noOfDays) {
        case ONE_WEEK:
          fromDate = moment().subtract(ONE_WEEK - 1, 'days');
          break;
        case TWO_WEEKS:
          fromDate = moment().subtract(TWO_WEEKS - 1, 'days');
          break;
        case ONE_MONTH:
          fromDate = moment().startOf('month');
          break;
        default:
          break;
      }

      return { fromDate: fromDate.format('MM/DD/YYYY'), toDate: toDate.format('MM/DD/YYYY') };
    },
    async fetchVendorReportForVisitors(fromDate, toDate) {
      const response = await axios.post('charts/get-vendor-report', {
        chart_value: 'visitors',
        affiliates: [{ id: 0, name: 'All' }],
        diffDays: this.getRevenueDays,
        fromDate,
        toDate,
        funnels: [],
        selected_tab: 'total',
      });

      this.processVisitorsResponse(response.data);
    },
    processVisitorsResponse(data) {
      this.visitorsData.response = data;
      this.visitorsData.data[0].data = data.chart_values;
      this.visitorsData.hasUsed = data.has_used;

      const { selected_tab_data } = data;
      this.visitorsData.hasData = selected_tab_data.visitors_count && selected_tab_data.visitors_count > 0 ? 1 : 0;

      this.populateVisitorsStats(selected_tab_data);
      this.visitorsData.isLoaded = 1;
    },
    populateVisitorsStats(data) {
      this.visitorsData.stats = [
        {
          name: this.$t('frontend_visitors'),
          stat: this.formatNumber(data.visitors_count),
        },
        {
          name: this.$t('frontend_uniques'),
          stat: this.formatNumber(data.uniques_count),
        },
        {
          name: this.$t('frontend_analytics_vendor_epc_earned'),
          stat: `$${this.formatNumber(data.epc_earned, 2)}`,
        },
      ];
    },
    formatNumber(value, decimals = 0) {
      return value == null ? '0' : this.numberSystem(value, decimals);
    },
    goToMarketplace() {
      this.$router.push({ name: 'marketplace' });
    },
  },
};
</script>