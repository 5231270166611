<template>
  <div>
    <v-wait for="dashboard">
      <!-- Date range picker -->
      <div class="flex mb-4 justify-end">
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 flex justify-end">
          <div>
            <date-range-picker
              ref="picker"
              v-model="dashboardDateRange"
              :opens="opens"
              :auto-apply="autoApply"
              :max-date="maxDateForFilter"
              :start-date="customStartDate"
              :end-date="customEndDate"
              :ranges="customDateRanges"
            >
              <div slot="input" slot-scope="picker">
                {{ picker.startDate | moment('MM/DD/YYYY') }} -
                {{ picker.endDate | moment('MM/DD/YYYY') }}
              </div>
            </date-range-picker>
          </div>
          <div>
            <gd-button variant="primary" @click="fetchDashboard">
              {{ $t('frontend_affiliate_promo_submit') }}
            </gd-button>
          </div>
        </div>
      </div>

      <stats-cards
        :impressions="impressions"
        :uniques="uniques"
        :optins="optins"
        :sales="sales"
        :commissions="commissions"
      />

      <div class="flex flex-col bg-white mt-16 rounded">
        <!-- Mark as completed -->
        <div class="flex flex-row mb-2 p-4">
          <div class="w-1/4 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/4">
            <div v-if="hasMultipleResources">
              <gd-dropdown
                v-model="selectedPromoLinkPermalink"
                size="full"
                :placeholder="$t('frontend_groovesell_promotional_tool')"
                :options="promoToolOptions"
                @change="changedPromoTool"
              />
            </div>
            <div v-else-if="funnelResource" class="pt-3 text-lg font-semibold max-w-full">
              {{ funnelResource.name }}
            </div>
          </div>

          <div class="w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/2 pt-2">
            <div v-if="assetsTab != ''" class="w-full flex flex-row justify-center">
              <a
                class="cursor-pointer flex flex-row font-semibold text-sm text-grey-700 items-center"
                @click="goBackToPromoToolsNavigation"
              >
                <chevron-left-icon class="h-3 fill-current" />
                <div class="hidden sm:hidden md:hidden lg:block xl:block ml-1">
                  {{ $t('frontend_affiliate_promo_back_to_promo_tools') }}
                </div>
              </a>

              <div v-if="assetsTab != 'links' && assetsTab != 'stats'" class="ml-16 flex flex-row">
                <div class="font-semibold text-sm text-grey-700 mr-2 hidden sm:hidden md:hidden lg:block xl:block">
                  {{ $t('frontend_affiliate_promo_mark_as_completed') }}
                </div>
                <gd-toggle
                  v-for="(asset, index) in assetTabs"
                  v-if="assetsTab === asset.tab"
                  :key="index"
                  v-model="funnelResource.affiliate_resource[asset.property]"
                  :value="funnelResource.affiliate_resource[asset.property]"
                  @change="toggleSwitchValue(index + 1)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row mb-2 px-4 border-grey-100 border-t">
          <div v-if="assetsTab === ''" class="mt-2 w-full">
            <main-tab
              :assets-tabs="assetsTabs"
              @openPromotoolsSection="openPromotoolsSection"
            />
          </div>
          <div v-else class="mt-2 w-full">
            <div v-if="assetsTab === 'links'">
              <links-tab
                :funnel-resource="funnelResource"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'swipes'">
              <swipes-tab
                :funnel-resource="funnelResource"
                :swipes-tab="swipesTab"
                :current-shown-swipes-quill-editor-index="currentShownSwipesQuillEditorIndex"
                @handleSwipesTabChange="handleSwipesTabChange"
                @updateCurrentShownSwipesQuillEditorIndex="updateCurrentShownSwipesQuillEditorIndex"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'banners'">
              <banners-tab
                :funnel-resource="funnelResource"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'thankyouPageAds'">
              <thankyou-page-ads-tab
                :funnel-resource="funnelResource"
                :thankyou-page-ads-tab="thankyouPageAdsTab"
                @handleThankyouPageAdsTabChange="handleThankyouPageAdsTabChange"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'signatures'">
              <signatures-tab
                :funnel-resource="funnelResource"
                :email-signatures-tab="emailSignaturesTab"
                :forum-signatures-tab="forumSignaturesTab"
                @handleEmailSignaturesTabChange="handleEmailSignaturesTabChange"
                @handleForumSignaturesTabChange="handleForumSignaturesTabChange"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'socialMediaPosts'">
              <social-media-posts-tab
                :funnel-resource="funnelResource"
                :facebook-posts-tab="facebookPostsTab"
                :twitter-tweets-tab="twitterTweetsTab"
                :linkedin-posts-tab="linkedinPostsTab"
                @handleFacebookPostsTabChange="handleFacebookPostsTabChange"
                @handleTwitterTweetsTabChange="handleTwitterTweetsTabChange"
                @handleLinkedinPostsTabChange="handleLinkedinPostsTabChange"
              />
            </div>
            <div v-else-if="assetsTab === 'blogReviews'">
              <blog-reviews-tab
                :funnel-resource="funnelResource"
                :blog-reviews-tab="blogReviewsTab"
                :current-shown-blog-reviews-quill-editor-index="currentShownBlogReviewsQuillEditorIndex"
                @handleBlogReviewsTabChange="handleBlogReviewsTabChange"
                @updateCurrentShownBlogReviewsQuillEditorIndex="updateCurrentShownBlogReviewsQuillEditorIndex"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'videoReviews'">
              <video-reviews-tab
                :funnel-resource="funnelResource"
                :video-reviews-tab="videoReviewsTab"
                @handleVideoReviewsTabChange="handleVideoReviewsTabChange"
              />
            </div>
            <div v-else-if="assetsTab === 'facebookAds'">
              <facebook-ads-tab
                :funnel-resource="funnelResource"
                :facebook-ads-tab="facebookAdsTab"
                @handleFacebookAdsTabChange="handleFacebookAdsTabChange"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'logoutPageAds'">
              <logout-page-ads-tab
                :funnel-resource="funnelResource"
                :logout-page-ads-tab="logoutPageAdsTab"
                @handleLogoutPageAdsTabChange="handleLogoutPageAdsTabChange"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
            <div v-else-if="assetsTab === 'allArticles'">
              <all-articles-tab
                :funnel-resource="funnelResource"
                :press-releases-tab="pressReleasesTab"
                :articles-tab="articlesTab"
                @handlePressReleasesTabChange="handlePressReleasesTabChange"
                @handleArticlesTabChange="handleArticlesTabChange"
                @onCopy="onCopy"
                @onError="onError"
              />
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'js-video-url-parser/lib/provider/youtube';
import 'js-video-url-parser/lib/provider/vimeo';
import LinksIcon from '@/assets/images/icons/LinkIcon.svg';
import SwipesIcon from '@/assets/images/icons/EnvelopIcon.svg';
import BlogIcon from '@/assets/images/icons/BlogIcon.svg';
import BannerIcon from '@/assets/images/icons/MapIcon.svg';
import LogoutPageAdIcon from '@/assets/images/icons/LogoutIcon.svg';
import ThankYouPageAdIcon from '@/assets/images/icons/ReceiptIcon.svg';
import FacebookAdIcon from '@/assets/images/icons/AdIcon.svg';
import SocialMediaPostsIcon from '@/assets/images/icons/FacebookIcon.svg';
import ArticlesIcon from '@/assets/images/icons/NewspaperIcon.svg';
import VideoReviewsIcon from '@/assets/images/icons/VideoCameraIcon.svg';
import SignaturesIcon from '@/assets/images/icons/SignatureIcon.svg';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';
import Notify from '@groovepages/gd-ui-kit/notify';
import MainTab from './promo_dashboard_partials/MainTab.vue';
import LinksTab from './promo_dashboard_partials/LinksTab.vue';
import SwipesTab from './promo_dashboard_partials/SwipesTab.vue';
import BannersTab from './promo_dashboard_partials/BannersTab.vue';
import ThankyouPageAdsTab from './promo_dashboard_partials/ThankyouPageAdsTab.vue';
import SignaturesTab from './promo_dashboard_partials/SignaturesTab.vue';
import SocialMediaPostsTab from './promo_dashboard_partials/SocialMediaPostsTab.vue';
import BlogReviewsTab from './promo_dashboard_partials/BlogReviewsTab.vue';
import VideoReviewsTab from './promo_dashboard_partials/VideoReviewsTab.vue';
import FacebookAdsTab from './promo_dashboard_partials/FacebookAdsTab.vue';
import LogoutPageAdsTab from './promo_dashboard_partials/LogoutPageAdsTab.vue';
import AllArticlesTab from './promo_dashboard_partials/AllArticlesTab.vue';
import StatsCards from './promo_dashboard_partials/StatsCards.vue';

export default {
  layout: 'defaultForAffiliates',
  middleware: 'auth',
  components: {
    MainTab,
    LinksTab,
    SwipesTab,
    BannersTab,
    ThankyouPageAdsTab,
    SignaturesTab,
    SocialMediaPostsTab,
    BlogReviewsTab,
    VideoReviewsTab,
    FacebookAdsTab,
    LogoutPageAdsTab,
    AllArticlesTab,
    StatsCards,
    ChevronLeftIcon,
},
  data() {
    return {
      isRegistered: 1,
      dashboardDateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
      },
      maxDateForFilter: new Date(),
      user: null,
      impressions: 0,
      uniques: 0,
      optins: 0,
      sales: 0,
      opens: 'left',
      autoApply: true,
      commissions: '$0',
      funnel_id: this.$route.params.id,
      funnel: null,
      funnelResource: null,
      assetsTabs: [],
      assetsTab: '',
      swipesTab: '',
      facebookPostsTab: '',
      videoReviewsTab: '',
      twitterTweetsTab: '',
      linkedinPostsTab: '',
      emailSignaturesTab: '',
      forumSignaturesTab: '',
      pressReleasesTab: '',
      articlesTab: '',
      blogReviewsTab: '',
      thankyouPageAdsTab: '',
      logoutPageAdsTab: '',
      facebookAdsTab: '',
      selectedPromoLinkPermalink: '',
      currentShownSwipesQuillEditorIndex: -1,
      currentShownThankYouQuillEditorIndex: -1,
      currentShownBlogReviewsQuillEditorIndex: -1,
      currentShownLogoutPageAdQuillEditorIndex: -1,
    };
  },
  computed: {
    promoToolOptions() {
      return this.funnelResource.remaining_resources.map(promoTool => ({ value: promoTool.permalink, text: promoTool.name }));
    },
    hasMultipleResources() {
      return this.funnelResource &&
        this.funnelResource.remaining_resources &&
        this.funnelResource.remaining_resources.length > 1;
    },
    assetTabs() {
      return [
        { tab: 'swipes', property: 'swipes_completed' },
        { tab: 'blogReviews', property: 'blog_reviews_completed' },
        { tab: 'banners', property: 'banners_completed' },
        { tab: 'thankyouPageAds', property: 'thankyou_page_ads_completed' },
        { tab: 'logoutPageAds', property: 'logout_page_ads_completed' },
        { tab: 'facebookAds', property: 'facebook_ads_completed' },
        { tab: 'socialMediaPosts', property: 'social_media_posts_completed' },
        { tab: 'allArticles', property: 'press_releases_completed' },
        { tab: 'videoReviews', property: 'video_reviews_completed' },
        { tab: 'signatures', property: 'signatures_completed' },
      ];
    },
  },
  metaInfo() {
    return { title: this.$t('promo_dashboard') };
  },
  async created() {
    this.$wait.start('dashboard');

    window.toggleSwitchValue = this.toggleSwitchValue;
    const { data } = await axios.get(`affiliates/${this.funnel_id}/dashboard`);

    this.impressions = data.impressions;
    this.uniques = data.uniques;
    this.optins = data.optins;
    this.sales = data.sales;
    this.commissions = data.commissions;

    await this.fetchFunnelResource(data.resource_permalink);

    this.$wait.end('dashboard');
  },
  methods: {
    updateCurrentShownSwipesQuillEditorIndex(index) {
      this.currentShownSwipesQuillEditorIndex = index;
    },
    updateCurrentShownBlogReviewsQuillEditorIndex(index) {
      this.currentShownBlogReviewsQuillEditorIndex = index;
    },
    getRichTextFromThankYouPageEditor(index) {
      if (this.$refs[`thankyouPageAdEditor_${index}`]) {
        return this.$refs[`thankyouPageAdEditor_${index}`][0].quill.getText();
      }
      return '';
    },
    getRichTextFromLogoutPageAdEditor(index) {
      if (this.$refs[`logoutPageAdEditor_${index}`]) {
        return this.$refs[`logoutPageAdEditor_${index}`][0].quill.getText();
      }
      return '';
    },
    goBackToPromoToolsNavigation() {
      this.currentShownSwipesQuillEditorIndex = -1;
      this.currentShownThankYouQuillEditorIndex = -1;
      this.currentShownBlogReviewsQuillEditorIndex = -1;
      this.currentShownLogoutPageAdQuillEditorIndex = -1;
      this.assetsTab = '';
    },
    openPromotoolsSection(sectionName) {
      this.assetsTab = sectionName;
    },
    async fetchFunnelResource(resourceId) {
      const funnelResourceData = await axios.get('fetch-funnel-resource', {
          params: { permalink: resourceId },
      });

      if (funnelResourceData.data.status !== 'success') {
          this.isRegistered = 0;
          return;
      }

      this.processFunnelResourceData(funnelResourceData.data.funnel_resource, resourceId);
    },
    processFunnelResourceData(resource, resourceId) {
      this.funnelResource = resource;
      this.selectedPromoLinkPermalink = resourceId;
      
      this.setupAssetsTabs();
      this.setupTabsFromResource('swipes', 'swipe_tab_', this.funnelResource.swipes);
      this.setupTabsFromResource('thankyouPageAds', 'thankyou_page_ad_tab_', this.funnelResource.thankyou_page_ads);
      this.setupTabsFromResource('emailSignatures', 'email_signature_tab_', this.funnelResource.signatures.email);
      this.setupTabsFromResource('forumSignatures', 'forum_signature_tab_', this.funnelResource.signatures.forum);
      this.setupTabsFromResource('facebookPosts', 'fb_post_tab_', this.funnelResource.social_media_posts.facebook);
      this.setupTabsFromResource('twitterTweets', 'twitter_tweet_tab_', this.funnelResource.social_media_posts.twitter);
      this.setupTabsFromResource('linkedinPosts', 'linkedin_post_tab_', this.funnelResource.social_media_posts.linkedin);
      this.setupTabsFromResource('blogReviews', 'blog_review_tab_', this.funnelResource.blog_reviews);
      this.setupTabsFromResource('facebookAds', 'facebook_ad_tab_', this.funnelResource.facebook_ads);
      this.setupTabsFromResource('logoutPageAds', 'logout_page_ad_tab_', this.funnelResource.logout_page_ads);
      this.setupTabsFromResource('pressReleases', 'press_release_tab_', this.funnelResource.press_releases);
      this.setupTabsFromResource('articles', 'articles_tab_', this.funnelResource.articles);
      this.setupTabsFromResource('videoReviews', 'video_review_tab_', this.funnelResource.video_reviews);
    },
    setupAssetsTabs() {
      this.assetsTabs = [
        {
            title: 'Links',
            value: 'links',
            icon: LinksIcon,
            completed: 0,
            id: 0,
        },
        {
            title: 'Swipes',
            value: 'swipes',
            icon: SwipesIcon,
            completed: this.funnelResource.affiliate_resource.swipes_completed,
            id: 1,
        },
        {
            title: 'Blog Reviews',
            value: 'blogReviews',
            icon: BlogIcon,
            completed: this.funnelResource.affiliate_resource.blog_reviews_completed,
            id: 2,
        },
        {
            title: 'Articles',
            value: 'allArticles',
            icon: ArticlesIcon,
            completed: this.funnelResource.affiliate_resource.press_releases_completed,
            id: 8,
        },
        {
            title: 'Banners',
            value: 'banners',
            icon: BannerIcon,
            completed: this.funnelResource.affiliate_resource.banners_completed,
            id: 3,
        },
        {
            title: 'Thank You Page Ads',
            value: 'thankyouPageAds',
            icon: ThankYouPageAdIcon,
            completed: this.funnelResource.affiliate_resource.thankyou_page_ads_completed,
            id: 4,
        },
        {
            title: 'Logout Page Ads',
            value: 'logoutPageAds',
            icon: LogoutPageAdIcon,
            completed: this.funnelResource.affiliate_resource.logout_page_ads_completed,
            id: 5,
        },
        {
            title: 'Social Media',
            value: 'socialMediaPosts',
            icon: SocialMediaPostsIcon,
            completed: this.funnelResource.affiliate_resource.social_media_posts_completed,
            id: 7,
        },
        {
            title: 'Video Reviews',
            value: 'videoReviews',
            icon: VideoReviewsIcon,
            completed: this.funnelResource.affiliate_resource.video_reviews_completed,
            id: 9,
        },
        {
            title: 'Facebook Ads',
            value: 'facebookAds',
            icon: FacebookAdIcon,
            completed: this.funnelResource.affiliate_resource.facebook_ads_completed,
            id: 6,
        },
        {
            title: 'Signatures',
            value: 'signatures',
            icon: SignaturesIcon,
            completed: this.funnelResource.affiliate_resource.signatures_completed,
            id: 10,
        },
      ];
    },
    setupTabsFromResource(tabProperty, tabPrefix, resourceArray) {
        if (this[`${tabProperty}Tab`] == '' && resourceArray && resourceArray.length > 0) {
            this[`${tabProperty}Tab`] = `${tabPrefix}${resourceArray[0].id}`;
        }
    },
    async fetchDashboard() {
      this.$wait.start('dashboard');
      const dashboardData = await axios.get(
        `affiliates/${this.funnel_id}/dashboard`,
        {
          params: {
            fromDate: moment(this.dashboardDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(this.dashboardDateRange.endDate).format('MM/DD/YYYY'),
          },
        }
      );
      this.impressions = dashboardData.data.impressions;
      this.uniques = dashboardData.data.uniques;
      this.optins = dashboardData.data.optins;
      this.sales = dashboardData.data.sales;
      this.commissions = dashboardData.data.commissions;
      this.$wait.end('dashboard');
    },
    async changedPromoTool() {
      this.$loader.start('changedPromoTool');
      this.resourceId = this.selectedPromoLinkPermalink;
      await this.fetchFunnelResource(this.selectedPromoLinkPermalink);
      this.$loader.end('changedPromoTool');
    },
    async toggleSwitchValue(type) {
      const propertiesMap = {
        1: 'swipes_completed',
        2: 'blog_reviews_completed',
        3: 'banners_completed',
        4: 'thankyou_page_ads_completed',
        5: 'logout_page_ads_completed',
        6: 'facebook_ads_completed',
        7: 'social_media_posts_completed',
        8: 'press_releases_completed',
        9: 'video_reviews_completed',
        10: 'signatures_completed'
      };

      const property = propertiesMap[type];
      if (!property) {
        return;
      }

      const foundIndex = this.assetsTabs.findIndex(asset => asset.value === this.getValueForType(type));
      if (foundIndex !== -1) {
        this.assetsTabs[foundIndex].completed = this.funnelResource.affiliate_resource[property];
      }

      await axios.post('/toggle-affiliate-funnel-resource-switch', {
        type,
        id: this.funnelResource.affiliate_resource.id,
        value: this.funnelResource.affiliate_resource[property],
      });
    },
    getValueForType(type) {
        const valueMap = {
            1: 'swipes',
            2: 'blogReviews',
            3: 'banners',
            4: 'thankyouPageAds',
            5: 'logoutPageAds',
            6: 'facebookAds',
            7: 'socialMediaPosts',
            8: 'pressReleases',
            9: 'videoReviews',
            10: 'signatures'
        };
        return valueMap[type] || '';
    },
    handleVideoReviewsTabChange(newTab) {
      this.videoReviewsTab = newTab;
    },
    onCopy() {
      Notify.success(this.$t('copy_success'));
    },
    onError() {
      Notify.fail(this.$t('frontend_unable_copy_to_clipboard'));
    },
    handleAssetsTabChange(newTab) {
      this.assetsTab = newTab;
    },
    handleSwipesTabChange(newTab) {
      this.swipesTab = newTab;
    },
    handleBlogReviewsTabChange(newTab) {
      this.blogReviewsTab = newTab;
    },
    handleFacebookAdsTabChange(newTab) {
      this.facebookAdsTab = newTab;
    },
    handleThankyouPageAdsTabChange(newTab) {
      this.thankyouPageAdsTab = newTab;
    },
    handleLogoutPageAdsTabChange(newTab) {
      this.logoutPageAdsTab = newTab;
    },
    handlePressReleasesTabChange(newTab) {
      this.pressReleasesTab = newTab;
    },
    handleArticlesTabChange(newTab) {
      this.articlesTab = newTab;
    },
    handleFacebookPostsTabChange(newTab) {
      this.facebookPostsTab = newTab;
    },
    handleTwitterTweetsTabChange(newTab) {
      this.twitterTweetsTab = newTab;
    },
    handleLinkedinPostsTabChange(newTab) {
      this.linkedinPostsTab = newTab;
    },
    handleEmailSignaturesTabChange(newTab) {
      this.emailSignaturesTab = newTab;
    },
    handleForumSignaturesTabChange(newTab) {
      this.forumSignaturesTab = newTab;
    },
  },
};
</script>

<style>
.reportrange-text {
  color: inherit;
  position: relative;
  padding: 0.4rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>

<style scoped>
.vue-daterange-picker {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
</style>

<style lang="scss">
#affiliate-links-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>

<style>
.swipesEditor {
  height: 32rem;
}

.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}

.embed-container {
  margin-bottom: 1.1em;
  margin-top: 1.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

</style>

<style lang="scss">
.tgl {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2em;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: sans-serif;
    font-size: 11px;
    perspective: 100px;
    &:after,
    &:before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 4px;
    }

    &:after {
      content: attr(data-tg-on);
      background: #02c66f;
      transform: rotateY(-180deg);
    }

    &:before {
      background: var(--brand-primary);
      content: attr(data-tg-off);
    }

    &:active:before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &:before {
      transform: rotateY(180deg);
    }

    &:after {
      transform: rotateY(0);
      left: 0;
      background: #15b85a;
    }

    &:active:after {
      transform: rotateY(20deg);
    }
  }
}
</style>
