<template>
  <gd-modal
    name="create-edit-funnel-modal"
    width="690px"
    :title="modalTitle"
    allowOverflow
    @closed="$emit('onFunnelUpdated', funnel)"
  >
        <div class="flex flex-col px-10 py-5">

          <!-- Live Toggle -->
          <div class="flex flex-row w-full justify-end mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_funnels_make_funnel') }}
            </label>
            <gd-toggle v-model="model.is_live" :value="model.is_live" />
          </div>

          <!-- Name Input -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_name') }}
            </label>
            <text-input
              v-model="model.name"
              :validator="$v.model.name"
              :attribute="$t('frontend_funnels_name')"
              value="model.name"
              :placeholder="$t('frontend_funnels_name')"
            />
          </div>

          <!-- Internal Name Input -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_internal_name') }}
            </label>
            <text-input
              v-model="model.internal_name"
              :validator="$v.model.internal_name"
              :attribute="$t('frontend_funnels_internal_name')"
              value="model.internal_name"
              :placeholder="$t('frontend_funnels_internal_name')"
            />
          </div>

          <!-- Description Input -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_description') }}
            </label>
            <text-input
              v-model="model.description"
              :validator="$v.model.description"
              :attribute="$t('frontend_funnels_description')"
              value="model.description"
              :placeholder="$t('frontend_funnels_description')"
            />
          </div>

          <!-- Sub Domain Input -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_sub_domain') }}
            </label>
            <text-input
              @input="debounceUsername"
              :validator="$v.model.slug"
              :attribute="$t('frontend_funnels_sub_domain')"
              :value="model.slug"
              :placeholder="$t('frontend_funnels_sub_domain')"
            />
            <div class="text-grey-500 mt-4">
              {{ subDomainUrl }}
            </div>
          </div>

          <!-- Category Select -->
          <div class="flex flex-row w-full">
            <div class="flex flex-col mb-4" :class="isEdit ? 'w-full' : 'w-1/2 mr-2'">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_funnels_category') }}
              </label>
              <gd-dropdown
                v-model="model.category"
                size="full"
                maxHeight="150px"
                :options="categoriesListOptions"
              />
            </div>

            <!-- Currency Select (Only for Create) -->
            <div v-if="!isEdit" class="flex flex-col w-1/2 mb-4">
              <label class="font-semibold text-sm text-grey-700 mb-2">
                {{ $t('frontend_funnels_currency') }}
              </label>
              <gd-dropdown
                v-model="model.currency_code"
                size="full"
                maxHeight="150px"
                :options="currencyOptions"
              />
            </div>
          </div>

          <!-- Transaction Email Sender -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_transaction_email_sender') }}
            </label>
            <div class="flex custom-radio -mx-2">
              <div class="w-1/2 px-2"
                v-for="(senderTypeOption, index) in senderTypeOptions"
                :key="index"
              >
                <gd-custom-radio
                  v-model="senderTypeValue"
                  :option="senderTypeOption"
                ></gd-custom-radio>
              </div>
            </div>
            <!-- Additional input for sender type 2 -->
            <div v-if="senderTypeValue == 2" class="mt-4">
              <gd-dropdown
                v-model="model.sender_id"
                size="full"
                maxHeight="150px"
                :options="sendersDataOptions"
              />
            </div>
          </div>

          <!-- Domain Select -->
          <div class="flex flex-col w-full mb-4">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_funnels_domain') }}
            </label>
            <gd-dropdown
              v-model="model.domain"
              size="full"
              maxHeight="150px"
              :options="domainOptions"
            />
          </div>

          <!-- Show in Marketplace Toggle -->
          <div class="flex flex-row w-1/2 items-center mb-4">
            <label class="font-semibold text-sm text-grey-700 mr-2">
              {{ $t('frontend_funnels_show_in_marketplace') }}
            </label>
            <gd-toggle
              v-model="model.show_in_marketplace"
              :value="model.show_in_marketplace"
            />
          </div>

          <!-- Note for Marketplace -->
          <div class="w-full text-sm font-semibold mb-4" v-if="model.show_in_marketplace">
            <span class="text-red">{{ $t('frontend_groovesell_funnels_index_only_live_note_text') }}</span>
            {{ $t('frontend_groovesell_funnels_index_only_live_note') }}
          </div>

          <!-- Save Button -->
          <div class="flex flex-row w-full justify-end">
            <gd-button
              size="auto"
              variant="primary"
              @click="editFunnel"
            >
              {{ gdButtonText }}
            </gd-button>
          </div>
        </div>
  </gd-modal>
</template>

<script>
import axios from 'axios';
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from 'vuelidate/lib/validators';
import router from '@/router';
import themeConfig from '@/theme/themeConfig.js';
import _ from 'lodash';

export default {
  mounted() {
    if(this.funnel == null)
      return;

    this.model.internal_name = '';
    this.model.name = '';
    this.model.description = '';
    this.model.category = 1;
    this.model.show_in_marketplace = 1;
    this.model.domain = 0;

    if (this.funnel?.id) {
      this.model.internal_name = this.funnel.internal_name;
      this.model.name = this.funnel.name;
      this.model.description = this.funnel.description;
      this.model.category = this.funnel.category;
      this.model.slug = this.funnel.slug;
      this.model.show_in_marketplace = this.funnel.show_in_marketplace;
      this.model.is_live = this.funnel.is_live;
      this.model.domain = this.funnel.domain;
      this.model.sender_id = this.funnel.sender_id;
      this.funnel.sender_id != 0
        ? this.senderTypeValue = 2
        : this.senderTypeValue = 1;
      this.modalTitle = this.$t('edit_product_funnel');
      this.$v.model.slug.$touch();
    }

    this.$root.$emit('modal-open', 'create-edit-funnel-modal');
  },
  props: {
    senders: {
      type: Object,
      required: true,
    },
    categoriesList: {
      type: Array,
      required: true,
    },
    domains: {
      required: true,
    },
    funnel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      senderTypeOptions: [
        {
            id: 1,
            title: this.$t('default_sender'),
            description: this.$t('sender_type_options_description', {
              sellAppTitle: this.whitelabelAppData('sell').title,
              brandEmail: this.$store.state.brandData.brandEmail
            }),
        },
        {
            id: 2,
            title: this.$t('custom_sender'),
        }
      ],
      model: {
        internal_name: '',
        name: '',
        description: '',
        slug: '',
        is_live: '',
        color: themeConfig.appPrimaryColor,
        currency_code: 'usd',
        show_in_marketplace: 1,
        domain: 0,
        sender_id: null,
        category: 1,
      },
      senderTypeValue: 1,
      modalTitle: this.$t('create_new_product_funnel'),
    };
  },
  computed: {
    subDomainUrl() {
      const sellAppTitle = this.whitelabelAppData('sell').title.toLowerCase();
      return `${this.model.slug}.${sellAppTitle}.com`
    },
    gdButtonText() {
      return this.isEdit
        ? this.$t('frontend_funnels_update')
        : this.$t('frontend_funnels_create_product_funnel');
    },
    isEdit() {
      return this.funnel && this.funnel.id;
    },
    categoriesListOptions() {
      return this.categoriesList.map((category) => ({
        value: category.id,
        text: this.$t(`frontend_groovesell_funnels_category_${category.id}`),
      }));
    },
    currencyOptions() {
      return [
        { value: 'usd', text: 'USD ($)' },
        { value: 'gbp', text: 'GBP (£)' },
        { value: 'cad', text: 'CAD ($)' },
        { value: 'aud', text: 'AUD ($)' },
        { value: 'nzd', text: 'NZD ($)' },
        { value: 'eur', text: 'EUR (€)' },
        { value: 'sek', text: 'SEK (kr)' },
        { value: 'sgd', text: 'SGD ($)' },
        { value: 'brl', text: 'BRL (R$)' },
        { value: 'mxn', text: 'MXN ($)' },
        { value: 'php', text: 'PHP (₱)' },
        { value: 'inr', text: 'INR (₹)' },
        { value: 'myr', text: 'MYR (RM)' },
        { value: 'pln', text: 'PLN (zł)' },
        { value: 'bgn', text: 'BGN (лв)' },
        { value: 'dkk', text: 'DKK (kr)' },
        { value: 'zar', text: 'ZAR (R)' },
        { value: 'czk', text: 'CZK (Kč)' },
        { value: 'thb', text: 'THB (฿)' },
        { value: 'vnd', text: 'VND (฿₫)' },
        { value: 'hkd', text: 'HKD (HK$)' },
        { value: 'idr', text: 'IDR (Rp)' },
        { value: 'jpy', text: 'JPY (¥)' },
        { value: 'rub', text: 'RUB (₽)' },
        { value: 'aed', text: 'AED (د.إ)' },
        { value: 'chf', text: 'CHF (CHf)' },
      ];
    },
    sendersDataOptions() {
      return this.senders.data.map((sender) => ({
        value: sender.id,
        text: `${sender.name} (${sender.sender_name} <${sender.sender_email}>)`,
      }));
    },
    domainOptions() {
      if (!this.domains.length) {
        return [
          { value: 0, text: this.$t('frontend_funnels_no_domain_selected') },
        ];
      }

      return [
        { value: 0, text: this.$t('frontend_funnels_no_domain_selected') },
        ...this.domains.map((domain) => ({
          value: domain.id,
          text: domain.name,
        })),
      ];
    },
  },
  validations: {
    model: {
      internal_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
      slug: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40),
        alphaNum,
        async notDuplicateSlug(value) {
          if (!value) {
            return false;
          }

          const url = this.funnel && this.funnel.id !== undefined
            ? `/validate-funnel-slug/${value}/${this.funnel.id}`
            : `/validate-funnel-slug/${value}`;

          try {
            await axios.get(url);

            return true;
          } catch (error) {
            return false;
          }
        },
      },
    },
  },
  methods: {
    debounceUsername: _.debounce(function (e) {
      this.model.slug = e;
      this.setSlugTouch();
      }, 160, {leading: false, trailing: true}
    ),
    setSlugTouch() {
      if (!this.$v.model.slug.$dirty) {
        this.$v.model.slug.$touch();
      }
    },
    async editFunnel() {
      this.$v.model.internal_name.$touch();
      this.$v.model.name.$touch();
      this.$v.model.description.$touch();
      this.$v.model.slug.$touch();

      if (
        !this.$v.model.internal_name.$invalid &&
        !this.$v.model.name.$invalid &&
        !this.$v.model.slug.$invalid &&
        !this.$v.model.description.$invalid
      ) {
        this.$loader.start('create-edit-funnel-modal');
        if(this.senderTypeValue == 1) {
          this.model.sender_id = 0;
        }
        this.funnel.internal_name = this.model.internal_name;
        this.funnel.name = this.model.name;
        this.funnel.description = this.model.description;
        this.funnel.category = this.model.category;
        this.funnel.slug = this.model.slug;
        this.funnel.is_live = this.model.is_live;
        this.funnel.sender_id = this.model.sender_id;
        this.funnel.domain = this.model.domain;
        this.funnel.show_in_marketplace = this.model.show_in_marketplace;
        if(!this.funnel?.id) {
          this.funnel.color = this.model.color;
          this.funnel.currency_code = this.model.currency_code;
        }

        await this.funnel.save();

        this.$root.$emit('modal-close', 'create-edit-funnel-modal');
        this.$loader.end('create-edit-funnel-modal');

        if(this.isEdit) {
          this.$emit('onFunnelUpdated', this.funnel);
          return;
        }

        router.push({ name: 'products', params: { id: this.funnel.data.id } });
      }
    },
  }
};
</script>
