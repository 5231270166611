<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_tracking_name') }}
        </label>
        <text-input
          v-model="localModel.name"
          :validator="$v.localModel.name"
          :attribute="$t('frontend_tracking_link_name')"
          value="localModel.name"
          :placeholder="$t('frontend_tracking_link_name')"
        />
      </div>
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_tracking_description') }}
        </label>
        <text-area
          v-model="localModel.description"
          :validator="$v.localModel.description"
          :attribute="$t('frontend_groovesell_tracking_link_description')"
          value="localModel.description"
          :placeholder="$t('frontend_groovesell_tracking_link_description')"
        />
      </div>
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_funnels_domain') }}
        </label>
        <gd-dropdown
          v-model="localModel.domain"
          size="full"
          :options="domainsOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'InfoTab',
  components: {
  },
  props: {
    model: {
      required: true,
    },
    domains:{
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  computed: {
    domainsOptions() {
      if (!this.domains.length) {
        return [{ value: 0, text: this.$t('frontend_funnels_no_domain_selected')}];
      }

      return this.domains.map((domain) => {
        return {
          value: domain.id,
          text: domain.name,
        };
      });
    },
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
  },
};
</script>
