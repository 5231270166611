import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null
    };
  },
  methods: {
    openSalesDetail() {
      if (this.params.context.componentParent.$refs.affiliateDetailModal) {
        this.params.context.componentParent.$refs.affiliateDetailModal.openSalesDetail(this.params.data.id);
      } else {
        this.params.context.componentParent.openSalesDetail(this.params.data.id);
      }
    }
  },
  template: `
        <span class="no-underline hover:underline cursor-pointer" v-on:click="openSalesDetail">
            {{this.params.value}}
        </span>
    `
});
