<template>
  <div>
    <div class="flex items-center justify-between mt-2 mb-2">
      <div class="text-xl font-bold">
        {{ $t('frontend_do_not_close_page') }}
      </div>
      <gd-button
        size="auto"
        @click="closePreview"
      >
        {{ $t('frontend_donot_close_preview') }}
      </gd-button>
    </div>
    <div class="flex flex-row justify-center items-center w-full mt-6 mb-8">
      <half-circle-spinner
        class="mr-4"
        :animation-duration="1000"
        :size="24"
        color="var(--brand-primary)"
      />
      <span class="mt-2 text-center font-semibold text-grey-700">
        {{ $t('frontend_please_select_option') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePreview() {
      this.$emit('closePreview');
    },
  },
}
</script>
