<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliates">
        <gd-tabs
          :tabs="distributionTabs"
          :current-tab="distributionTab"
          :wrapper-class="'px-0 gs-default-tabs'"
          :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'"
          @onClick="handleDistributionTabChange"
        />
        <div class>
          <div v-if="distributionTab === 'owed_by_me'" class="mt-4">
            <div class="mt-2">
              <div class="flex flex-row justify-end">
                <div class="flex flex-col w-1/4">
                  <div class="flex">
                    <text-input
                      v-model="searchPhrase"
                      v-debounce:1s="changedSearchOrFilter"
                      value="searchPhrase"
                      :placeholder="$t('frontend_search')"
                      :debounce-events="['input', 'keydown']"
                    />
                  </div>
                  <div class="flex flex-row mt-4 justify-end">
                    <div class="w-1/2 flex">
                      <gd-dropdown
                        :disabled="!selectedRowLength"
                        :placeholder="$t('frontend_affiliates_bulk_actions')"
                        :actions="bulkActions"
                      />
                    </div>
                    <div class="w-1/2 flex justify-end">
                      <gd-dropdown
                        :placeholder="$t('frontend_groovesell_select_partners')"
                        :actions="selectPartnersActions"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="affiliates-list-view" class="mt-4">
                <ag-grid-vue
                  class="ag-theme-material"
                  style="width: 100%"
                  :dom-layout="domLayout"
                  :grid-options="gridOptions"
                  :column-defs="partnersTableColumnDefs"
                  :pagination="true"
                  :row-height="partnersTableRowHeight"
                  :row-buffer="rowBuffer"
                  :row-model-type="rowModelType"
                  :datasource="dataSource"
                  :pagination-page-size="paginationPageSize"
                  :cache-overflow-size="cacheOverflowSize"
                  :infinite-initial-row-count="infiniteInitialRowCount"
                  :max-blocks-in-cache="maxBlocksInCache"
                  :cache-block-size="cacheBlockSize"
                  :suppress-row-click-selection="true"
                  :suppress-cell-selection="true"
                  :row-class="rowClass"
                  :row-class-rules="rowClassRules"
                  :context="context"
                  :framework-components="frameworkComponents"
                  :row-selection="rowSelection"
                  :max-concurrent-datasource-requests="
                    maxConcurrentDatasourceRequests
                  "
                  @grid-ready="onGridReady"
                  @model-updated="onModelUpdated"
                  @row-selected="onRowSelected"
                  @selection-changed="onSelectionChanged"
                ></ag-grid-vue>
              </div>
            </div>
          </div>

          <div v-if="distributionTab === 'owed_to_me'" class="mt-4">
            <div class="mt-2">
              <div id="owned-list-view" class="mt-4">
                <ag-grid-vue
                  class="ag-theme-material"
                  style="width: 100%"
                  :dom-layout="ownedByMeDomLayout"
                  :grid-options="ownedByMeGridOptions"
                  :column-defs="ownedByMePartnersTableColumnDefs"
                  :pagination="true"
                  :row-height="ownedByMePartnersTableRowHeight"
                  :row-buffer="ownedByMeRowBuffer"
                  :row-model-type="ownedByMeRowModelType"
                  :datasource="ownedByMeDataSource"
                  :pagination-page-size="ownedByMePaginationPageSize"
                  :cache-overflow-size="ownedByMeCacheOverflowSize"
                  :infinite-initial-row-count="ownedByMeInfiniteInitialRowCount"
                  :max-blocks-in-cache="ownedByMeMaxBlocksInCache"
                  :cache-block-size="ownedByMeCacheBlockSize"
                  :suppress-row-click-selection="true"
                  :suppress-cell-selection="true"
                  :row-class="ownedByMeRowClass"
                  :row-class-rules="ownedByMeRowClassRules"
                  :context="ownedByMeContext"
                  :framework-components="ownedByMeFrameworkComponents"
                  :row-selection="ownedByMeRowSelection"
                  :max-concurrent-datasource-requests="
                    ownedByMeMaxConcurrentDatasourceRequests
                  "
                  @grid-ready="ownedByMeGridReady"
                ></ag-grid-vue>
              </div>
            </div>
          </div>
        </div>

        <gd-modal
          name="affiliate-detail-modal"
          :title="$t('frontend_groovesell_partner_details')"
          width="80%"
          bg-type="regular"
        >
          <div v-if="selectedPartner" style="min-height: 60vh">
            <gd-tabs
              :tabs="affiliateDetailTabs"
              :current-tab="affiliateDetailsTab"
              :wrapper-class="'pt-4 px-4 gs-default-tabs'"
              :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'"
              @onClick="handlePartnerDetailTabChange"
            />
            <div v-if="affiliateDetailsTab === 'info'">
              <div class="flex flex-row">
                <div class="flex flex-col w-3/4 px-4">
                  <div class="mt-2">
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.name }}
                        </label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >Email</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.email }}
                        </label>
                      </div>
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >Paypal Email</label
                        >
                        <label
                          v-if="selectedPartner.affiliate_paypal"
                          class="text-sm text-grey-700 mb-2"
                          >{{ selectedPartner.affiliate_paypal }}</label
                        >
                        <label v-else class="text-sm text-grey-700 mb-2"
                          >N/A</label
                        >
                      </div>

                      <div class="w-full flex flex-row">
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_username') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{ selectedPartner.username }}
                          </label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_groovesell_earned_distribution') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{
                              selectedPartner.human_readable_earned_distribution
                            }}
                          </label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_groovesell_un_paid_distribution') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{
                              selectedPartner.human_readable_un_paid_distribution
                            }}
                          </label>
                        </div>
                      </div>

                      <div class="w-full flex flex-row">
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_groovesell_due_distribution') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{
                              selectedPartner.human_readable_due_distribution
                            }}
                          </label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_groovesell_being_processed_distribution') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{
                              selectedPartner.human_readable_being_processes_distribution
                            }}
                          </label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_groovesell_on_hold_distribution') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">
                            {{
                              selectedPartner.human_readable_on_hold_distribution
                            }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
                  <div class="my-auto flex flex-col">
                    <gd-button
                      size="auto"
                      variant="primary"
                      class="mb-4"
                      @click="openMarkAsPaidModal"
                      >{{ $t('frontend_groovesell_process_payment') }}</gd-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'lifetime'">
              <div class="flex flex-row">
                <div class="flex flex-col w-3/4 px-4">
                  <div class="mt-2">
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_all_time_distribution') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.human_readable_all_time_earned_distribution
                          }}
                        </label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_all_time_paid_distribution') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.human_readable_paid_distribution }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </gd-modal>
        <gd-modal
          name="mark-as-paid-modal"
          :title="$t('frontend_groovesell_process_distribution')"
          width="80%"
          bg-type="regular"
        >
          <div v-if="selectedPartner" class="flex">
            <div class="flex flex-col w-2/3 px-4">
              <div class="mt-2">
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_groovesell_amount') }}</label
                  >
                  <label class="w-full px-1 mt-1 font-semibold text-grey-700">{{
                    selectedPartner.human_readable_due_distribution
                  }}</label>
                </div>

                <!--
                  <div v-if="selectedPartner.processed_without_advances != selectedPartner.processed_commission">
                      <div class="w-full flex flex-row mb-4" v-if="selectedPartner.processed_without_advances > 0">
                          <label class="w-full mt-1 font-bold text-red text-sm">Note: You only have to pay {{selectedPartner.human_readable_processed_without_advances}} after removing the cash advances and clawbacks</label>
                      </div>
                      <div class="w-full flex flex-row mb-4" v-else-if="selectedPartner.processed_without_advances <= 0">
                          <label class="w-full mt-1 font-bold text-red text-sm">Note: You don't have any commission dues after removing the cash advances and clawbacks. Please select "Mark As Paid" and click on "Process Payment" to mark the due commissions as paid</label>
                      </div>
                  </div>
                -->
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_transaction') }} #</label
                  >
                  <text-input
                    v-model="markAsPaid.transactionId"
                    class="w-2/3"
                    value="markAsPaid.transactionId"
                    :placeholder="$t('frontend_transaction_id')"
                  />
                </div>
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_comment') }}</label
                  >
                  <text-input
                    v-model="markAsPaid.comment"
                    class="w-2/3"
                    value="markAsPaid.comment"
                    :placeholder="$t('frontend_comment')"
                  />
                </div>
                <div class="flex flex-row w-full mb-6 p-4 justify-center">
                  <div class="w-1/3 mr-2">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Paypal"
                      option-value="1"
                      :option-enabled="selectedPartner.affiliate_paypal"
                    >
                      <template slot="icon">
                        <paypal-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                  <div class="w-1/3 mr-2">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Check"
                      option-value="2"
                      :option-enabled="selectedPartner.affiliate_check_info"
                    >
                      <template slot="icon">
                        <credit-card-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                  <div class="w-1/3">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Wire Info"
                      option-value="3"
                      :option-enabled="selectedPartner.affiliate_wire_info"
                    >
                      <template slot="icon">
                        <bank-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                </div>
                <div
                  v-if="markAsPaid.gatewayType != ''"
                  class="flex flex-row w-full mb-4"
                >
                  <div
                    v-if="markAsPaid.gatewayType == '1'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">{{ $t('frontend_groovesell_payee_details') }}</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-2"
                        >{{ $t('frontend_groovesell_paypal_email') }}</label
                      >
                      <label class="text-sm text-grey-700 mb-2">
                        {{ selectedPartner.affiliate_paypal }}
                      </label>
                    </div>
                  </div>
                  <div v-else-if="markAsPaid.gatewayType == '2'">
                    <h4 class="font-semibold text-grey-700">{{ $t('frontend_groovesell_check_Settings') }}</h4>
                  </div>
                  <div
                    v-else-if="markAsPaid.gatewayType == '3'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">{{ $t('frontend_groovesell_payee_details') }}</h4>
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.affiliate_wire_info.account_name }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_address') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.affiliate_wire_info.address }}
                        </label>
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.affiliate_wire_info.city }}
                        </label>
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.affiliate_wire_info.state }}
                          {{ selectedPartner.affiliate_wire_info.postal_code }}
                        </label>
                        <label class="text-sm text-grey-700 mb-2">
                          {{ selectedPartner.affiliate_wire_info.country }}
                        </label>
                      </div>
                    </div>

                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_benificiary_bank') }}
                    </h4>
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_bank_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info.main_bank_name
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_bank_address') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info
                              .main_bank_address
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_account_number') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info
                              .main_bank_account_no
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_routing_number') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info
                              .main_bank_routing_no
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_swift') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info
                              .main_bank_swift_code
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_aba') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info.main_bank_aba
                          }}
                        </label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_iban') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">
                          {{
                            selectedPartner.affiliate_wire_info.main_bank_iban
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
              <div class="flex flex-col">
                <div class="flex">
                  <label class="font-semibold text-grey-700 mb-2"
                    >{{ $t('frontend_groovesell_mark_as') }}:</label
                  >
                </div>
                <div
                  v-for="(markAsPaidType, index) in markAsPaidTypes"
                  :key="index"
                  class="flex mr-4"
                >
                  <gd-custom-radio
                    v-model="markAsPaid.typeOfPayment"
                    :option="markAsPaidType"
                  ></gd-custom-radio>
                </div>
                <div class="mt-2 flex flex-row w-full justify-center">
                  <gd-button
                    v-if="markAsPaid.gatewayType != ''"
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="processPayment"
                    >{{ $t('frontend_groovesell_process_payment') }}</gd-button
                  >
                  <gd-button v-else size="auto" disabled class="mb-4"
                    >{{ $t('frontend_groovesell_process_payment') }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>
        <gd-modal
          name="open-partners-export-modal"
          :title="$t('frontend_groovesell_partners_payment_info')"
          width="80%"
          bg-type="regular"
        >
          <div class="flex">
            <div class="flex flex-col w-2/3 px-4">
              <div class="mt-2">
                <div class="flex flex-row w-full mb-6 p-4 justify-center">
                  <div class="w-1/3 mr-4">
                    <font-awesome-radio-with-count
                      v-model="exportPartner.gatewayType"
                      option-name="Paypal"
                      option-value="1"
                      :option-enabled="exportPartnerResponse.paypal.count > 0"
                      :option-count="exportPartnerResponse.paypal.count"
                    >
                      <template slot="icon">
                        <paypal-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                  <div class="w-1/3 mr-4">
                    <font-awesome-radio-with-count
                      v-model="exportPartner.gatewayType"
                      option-name="Check"
                      option-value="2"
                      :option-enabled="exportPartnerResponse.check.count > 0"
                      :option-count="exportPartnerResponse.check.count"
                    >
                      <template slot="icon">
                        <credit-card-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                  <div class="w-1/3">
                    <font-awesome-radio-with-count
                      v-model="exportPartner.gatewayType"
                      option-name="Wire Info"
                      option-value="3"
                      :option-enabled="exportPartnerResponse.wire.count > 0"
                      :option-count="exportPartnerResponse.wire.count"
                    >
                      <template slot="icon">
                        <bank-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                </div>
                <div
                  v-if="exportPartner.gatewayType != ''"
                  class="flex flex-row w-full mb-4"
                >
                  <div
                    v-if="exportPartner.gatewayType == '1'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">Paypal</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-4">
                        {{ $t('frontend_groovesell_no_of_partners') }} :
                        <span class="text-red">
                          {{ exportPartnerResponse.paypal.count }}
                        </span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount_due') }} :
                        {{ exportPartnerResponse.total.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8"
                        >{{ exportPartnerResponse.total.count }} {{ $t('frontend_partners') }}</label
                      >
                      <label class="font-semibold text-sm text-grey-700 mb-2"
                        >{{ $t('frontend_groovesell_on_hold') }}:  
                        {{ exportPartnerResponse.on_hold.amount }}</label
                      >
                      <label class="text-red font-semibold text-sm mb-8"
                        >{{
                          exportPartnerResponse.on_hold.count
                        }}
                        {{ $t('frontend_partners') }}</label
                      >
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_eligible') }}: 
                        {{ exportPartnerResponse.paypal.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-2"
                        >{{
                          exportPartnerResponse.paypal.count
                        }}
                        {{ $t('frontend_partners') }}</label
                      >
                    </div>
                  </div>
                  <div v-else-if="exportPartner.gatewayType == '2'">
                    <h4 class="font-semibold text-grey-700">{{ $t('frontend_groovesell_check') }}</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_no_of_check_partners') }} :
                        <span class="text-red">
                          {{ exportPartnerResponse.check.count }}
                        </span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount') }}: 
                        <span class="text-red">
                          {{ exportPartnerResponse.check.amount }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-else-if="exportPartner.gatewayType == '3'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">Wire</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-4">
                        {{ $t('frontend_groovesell_no_of_wire_partners') }} :
                        <span class="text-red">
                          {{ exportPartnerResponse.wire.count }}
                        </span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount_due') }} :
                        {{ exportPartnerResponse.total.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8"
                        >{{ exportPartnerResponse.total.count }} {{ $t('frontend_partners') }}</label
                      >
                      <label class="font-semibold text-sm text-grey-700 mb-2"
                        >{{ $t('frontend_groovesell_on_hold') }}: 
                        {{ exportPartnerResponse.on_hold.amount }}</label
                      >
                      <label class="text-red font-semibold text-sm mb-8"
                        >{{
                          exportPartnerResponse.on_hold.count
                        }}
                        {{ $t('frontend_partners') }}</label
                      >
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_eligible') }}: 
                        {{ exportPartnerResponse.wire.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-2"
                        >{{ exportPartnerResponse.wire.count }} {{ $t('frontend_partners') }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
              <div class="flex flex-col">
                <div class="flex">
                  <label class="font-semibold text-grey-700 mb-2"
                    >{{ $t('frontend_groovesell_mark_as') }}:</label
                  >
                </div>
                <div
                  v-for="(
                    exportAffiliatePaymentType, index
                  ) in exportAffiliatePaymentTypes"
                  :key="index"
                  class="flex mr-4"
                >
                  <gd-custom-radio
                    v-model="exportPartner.typeOfPayment"
                    :option="exportAffiliatePaymentType"
                  ></gd-custom-radio>
                </div>
                <div class="mt-2 flex flex-col w-full justify-center">
                  <gd-button
                    v-if="exportPartner.gatewayType != ''"
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="exportPartnerDetails"
                    >{{ $t('frontend_groovesell_export_partners') }}</gd-button
                  >
                  <gd-button v-else size="auto" disabled class="mb-4"
                    >{{ $t('frontend_groovesell_export_partners') }}</gd-button
                  >
                  <gd-button
                    v-if="exportPartner.gatewayType == '1'"
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="exportPaypalMasspay"
                    >{{ $t('frontend_groovesell_export_paypal_masspay') }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';
import Funnel from '@/models/Funnel';
import Distribution from '@/models/Distribution';
import PartnerPayment from '@/models/PartnerPayment';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AffiliateFullNameRenderer from '@/renderers/affiliateFullNameRenderer';
import AffiliateSalesRenderer from '@/renderers/affiliateSalesRenderer';
import AffiliateRefundsRenderer from '@/renderers/affiliateRefundsRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Distribution' };
  },
  mixins: [createLinkMixin],
  components: {
    AgGridVue,
    PaypalIcon,
    CreditCardIcon,
    BankIcon,
  },
  data() {
    return {
      isFirstLoad: true,
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      columnApi: null,
      ownedByMeGridColumnApi: null,
      ownedByMeDataSource: null,
      dataSource: null,
      affiliatePaymentExportId: 0,
      contracts: [],
      coupon: null,
      domLayout: 'autoHeight',
      ownedByMeDomLayout: 'autoHeight',
      searchPhrase: '',
      ownedByMeSearchPhrase: '',
      gridApi: null,
      ownedByMeGridApi: null,
      gridOptions: null,
      ownedByMeGridOptions: null,
      funnels: [],
      contracts: [],
      selectedFunnel: 0,
      selectedContract: 0,
      ownedByMeSelectedContract: 0,
      partnersTableColumnDefs: null,
      ownedByMePartnersTableColumnDefs: null,
      infiniteInitialRowCount: null,
      ownedByMeInfiniteInitialRowCount: null,
      partnerPaymentExportId: 0,
      cacheOverflowSize: null,
      ownedByMeCacheOverflowSize: null,
      rowClassRules: null,
      ownedByMeRowClassRules: null,
      rowClass: 'affiliates-row',
      ownedByMeRowClass: 'affiliates-row',
      partnersTableRowHeight: 40,
      ownedByMePartnersTableRowHeight: 40,
      paginationPageSize: 25,
      ownedByMePaginationPageSize: 25,
      selectedRowSize: 25,
      ownedByMeSelectedRowSize: 25,
      context: null,
      ownedByMeContext: null,
      maxBlocksInCache: null,
      ownedByMeMaxBlocksInCache: null,
      rowBuffer: null,
      ownedByMeRowBuffer: null,
      rowModelType: null,
      ownedByMeRowModelType: null,
      frameworkComponents: null,
      ownedByMeFrameworkComponents: null,
      maxConcurrentDatasourceRequests: null,
      ownedByMeMaxConcurrentDatasourceRequests: null,
      rowSelection: 'multiple',
      ownedByMeRowSelection: 'multiple',
      selectedRowLength: 0,
      dataSource: null,
      selectedPartner: null,
      cacheBlockSize: 25,
      ownedByMeCacheBlockSize: 25,

      affiliateDetailTabs: [
        { title: 'Partner Details', value: 'info' },
        { title: 'Lifetime Distribution', value: 'lifetime' },
      ],
      affiliateDetailsTab: 'info',
      markAsPaid: {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      },
      markAsPaidTypes: [
        { id: 1, title: this.$t('frontend_in_process'), description: '' },
        { id: 2, title: this.$t('frontend_paid'), description: '' },
        { id: 3, title: this.$t('frontend_groovesell_paid_after_24'), description: '' },
        { id: 4, title: this.$t('frontend_groovesell_paid_after_48'), description: '' },
      ],
      exportAffiliatePaymentTypes: [
        { id: 2, title: this.$t('frontend_paid'), description: '' },
        { id: 3, title: this.$t('frontend_groovesell_paid_after_24'), description: '' },
        { id: 4, title: this.$t('frontend_groovesell_paid_after_48'), description: '' },
        { id: 1, title: this.$t('frontend_in_process'), description: '' },
      ],
      exportPartner: {
        gatewayType: '',
        typeOfPayment: 1,
      },
      exportPartnerResponse: {
        paypal: {
          count: 0,
          amount: '$0.00',
        },
        check: {
          count: 0,
          amount: '$0.00',
        },
        wire: {
          count: 0,
          amount: '$0.00',
        },
        on_hold: {
          count: 0,
          amount: '$0.00',
        },
        total: {
          count: 0,
          amount: '$0.00',
        },
        hash: '',
      },
      distributionTabs: [
        {
          title: this.$t('frontend_groovesell_distribution_tab1'),
          value: 'owed_by_me',
        },
        {
          title: this.$t('frontend_groovesell_distribution_tab2'),
          value: 'owed_to_me',
        },
      ],
      distributionTab: 'owed_by_me',
    };
  },
  validations: {
    markAsPaid: {
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
  },
  async created() {
    this.$wait.start('affiliates');
    const funnels = await Funnel.$get();
    if (funnels.length > 0) {
      this.funnels = funnels;
      this.isFunnelAdded = true;
    } else {
      this.isFunnelAdded = false;
    }
    this.$wait.end('affiliates');
  },
  computed: {
    bulkActions() {
      return [
        {
          text: this.$t('frontend_groovesell_export_partners'),
          callback: () => this.downloadPartnerExportFile()
        },
        {
          text: this.$t('pay_selected_partners'),
          callback: () => this.openExportModal()
        },
      ];
    },
    selectPartnersActions() {
      return [
        {
          text: this.$t('frontend_groovesell_select_all'),
          callback: () => this.selectAllPartners()
        },
        {
          text: this.$t('frontend_groovesell_deselect_all'),
          callback: () => this.unSelectAllPartners()
        },
      ];
    },
  },
  async beforeMount() {
    this.gridOptions = {};
    this.ownedByMeGridOptions = {};

    this.context = { componentParent: this };
    this.ownedByMeContext = { componentParent: this };

    this.frameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer,
    };
    this.ownedByMeFrameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer,
    };

    this.partnersTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_user_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'name',
            cellRenderer: 'affiliateFullNameRenderer',
            colId: 'affiliateFullName',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_distribution'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned'),
            field: 'human_readable_earned_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid'),
            field: 'human_readable_un_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_due'),
            field: 'human_readable_due_distribution',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_being_processed'),
            field: 'human_readable_being_processes_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_amount_paid'),
            field: 'human_readable_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_distribution_on_hold'),
            field: 'human_readable_on_hold_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_all_earned_distribution'),
            field: 'human_readable_all_time_earned_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_all_paid_distribution'),
            field: 'human_readable_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_email'),
            field: 'email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
    ];

    this.ownedByMePartnersTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_user_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'name',
            width: 300,
            pinned: 'left',
            checkboxSelection: true,
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_distribution'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned'),
            field: 'human_readable_earned_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_unpaid'),
            field: 'human_readable_un_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_due'),
            field: 'human_readable_due_distribution',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_being_processed'),
            field: 'human_readable_being_processes_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_amount_paid'),
            field: 'human_readable_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_distribution_on_hold'),
            field: 'human_readable_on_hold_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_payments'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_all_earned_distribution'),
            field: 'human_readable_all_time_earned_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_all_paid_distribution'),
            field: 'human_readable_paid_distribution',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_email'),
            field: 'email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
    ];
    this.rowBuffer = 0;
    this.ownedByMeRowBuffer = 0;

    this.rowModelType = 'infinite';
    this.ownedByMeRowModelType = 'infinite';

    this.paginationPageSize = 25;
    this.ownedByMePaginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.ownedByMeCacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.ownedByMeCacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.ownedByMeMaxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.ownedByMeInfiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;
    this.ownedByMeMaxBlocksInCache = 2;

    this.rowClassRules = {};
    this.ownedByMeRowClassRules = {};
  },
  methods: {
    applyFilters() {
      this.changedSearchOrFilter();
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },
    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },

    clearFilters() {
      this.changedSearchOrFilter();
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    async ownedByMeGridReady() {
      this.ownedByMeGridApi = this.ownedByMeGridOptions.api;
      this.ownedByMeGridColumnApi = this.ownedByMeGridOptions.columnApi;
      this.ownedByMeDataSource = await this.getOwnedByMeDataSource(
        Number(this.ownedByMeSelectedRowSize)
      );
    },

    async openAffiliateDetail(affiliateId) {
      this.$loader.start('openAffiliateDetail');
      const partner = await Distribution.params({
        funnels: this.selectedFunnel,
      }).$find(affiliateId);
      this.selectedPartner = partner[0];
      this.affiliateDetailsTab = 'info';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openAffiliateDetail');
    },

    async processPayment() {
      this.$v.markAsPaid.$touch();
      const isValid = !this.$v.markAsPaid.$invalid;
      if (isValid) {
        this.$loader.start('processPayment');
        const self = this;

        const partnerPayment = new PartnerPayment({
          partner_id: self.selectedPartner.id,
          amount: self.selectedPartner.dueDistribution / 100,
          payment_gateway: self.markAsPaid.gatewayType,
          note: self.markAsPaid.comment,
          transaction_id: self.markAsPaid.transactionId,
          type: 1,
          markTransactionAs: self.markAsPaid.typeOfPayment,
          processedTransactionIds:
            self.selectedPartner.processed_transaction_ids,
        });

        await partnerPayment.save();
        this.$root.$emit('modal-close', 'mark-as-paid-modal');

        const partner = await Distribution.params({
          funnels: this.selectedFunnel,
        }).$find(self.selectedPartner.id);
        this.selectedPartner = partner[0];
        this.$loader.end('processPayment');
        this.changedSearchOrFilter();
      }
    },

    async exportPartnerDetails() {
      const self = this;
      self.$loader.start('exportPartnerDetails');

      let exportType = '';

      if (self.exportPartner.gatewayType == '1') {
        exportType = 'paypal';
      } else if (self.exportPartners.gatewayType == '3') {
        exportType = 'wire';
      }

      const downloadLinkData = await axios.post(
        '/process-exported-partner-transactions',
        {
          hash: self.exportPartnerResponse.hash,
          type: self.exportPartner.typeOfPayment,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        this.openLink(downloadLinkData.data.download_link, '_self');
        self.unSelectAllPartners();
        self.$loader.end('exportPartnerDetails');
        this.$root.$emit('modal-close', 'open-partners-export-modal');
      } else {
        this.partnerPaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportPartnerDetails');
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc_your_partner_request'),
        };
      }
    },

    async getStatus() {
      const { data } = await axios.get(
        `partner-payment-export-status/${this.partnerPaymentExportId}`
      );

      const processedStatus = data.status;

      if (processedStatus == 'processing') {
        setTimeout(this.getStatus(), 5000);
      } else if (processedStatus == 'processed') {
        this.openLink(data.download_link, '_self');
        this.unSelectAllPartners();
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_processed_successfully'),
          message: this.$t('frontend_groovesell_swal_export_file_successfully'),
        };
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_backend_error'),
        };
      }
    },

    async exportPaypalMasspay() {
      const self = this;
      self.$loader.start('exportPaypalMasspay');

      const exportType = 'paypal_masspay';
      const downloadLinkData = await axios.post(
        '/process-exported-partner-transactions',
        {
          hash: self.exportPartnerResponse.hash,
          type: self.exportPartnerResponse.typeOfPayment,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        this.openLink(downloadLinkData.data.download_link, '_self');
        self.unSelectAllPartners();
        self.$loader.end('exportPaypalMasspay');
        this.$root.$emit('modal-close', 'open-partners-export-modal');
      } else {
        this.partnerPaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportPaypalMasspay');
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc_your_partner_request'),
        };
      }
    },

    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        if (!self.isFirstLoad) self.$loader.start('getDataSource');

        const distributionData = await Distribution.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            search: self.searchPhrase,
            funnels: self.selectedFunnel,
          })
          .get();

        let rowsThisPage = [];
        rowsThisPage = distributionData.data;
        const totalNumberOfRows = distributionData.meta.total;
        self.funnels = distributionData.meta.funnels;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          if (!self.isFirstLoad) self.$loader.end('getDataSource');
          self.isFirstLoad = false;
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },

    getOwnedByMeDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getOwnedByMeDataSource');

        const ownedDistributionData = await Distribution.page(
          self.ownedByMeGridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.ownedByMeGridApi.paginationGetPageSize())
          .params({
            search: self.ownedByMeSearchPhrase,
            contracts: self.ownedByMeSelectedContract,
          })
          .custom('owed-to-me')
          .get();

        let rowsThisPage = [];
        rowsThisPage = ownedDistributionData.data;
        const totalNumberOfRows = ownedDistributionData.meta.total;
        self.contracts = ownedDistributionData.meta.contracts;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getOwnedByMeDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.ownedByMeSelectedRowSize));
    },

    async openExportModal() {
      const self = this;
      self.$loader.start('openExportModal');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedPartnerIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedPartnerIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-partners-export-link',
        {
          selected: selectedPartnerIds,
          unselected: self.unselectedPartnerIds,
          isAllSelected: self.isAllSelected,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
        }
      );

      this.exportPartnerResponse.paypal = exportLinkData.data.paypal;
      this.exportPartnerResponse.wire = exportLinkData.data.wire;
      this.exportPartnerResponse.hash = exportLinkData.data.hash;
      this.exportPartnerResponse.on_hold = exportLinkData.data.on_hold;
      this.exportPartnerResponse.total = exportLinkData.data.total;
      self.$loader.end('openExportModal');

      this.exportPartner = {
        gatewayType: '',
        typeOfPayment: 2,
      };
      this.$root.$emit('modal-open', 'open-partners-export-modal');
    },

    async downloadPartnerExportFile() {
      const self = this;
      self.$loader.start('downloadPartnerExportFile');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedPartnerIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedPartnerIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-partners-export-link',
        {
          selected: selectedPartnerIds,
          unselected: self.unselectedPartnerIds,
          isAllSelected: self.isAllSelected,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
          direct_download: 1,
        }
      );

      this.openLink(exportLinkData.data.download_link, '_self');
      self.$loader.end('downloadPartnerExportFile');
    },

    handleDistributionTabChange(newTab) {
      this.distributionTab = newTab;
    },
    onPageSizeChanged() {
      this.gridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.selectedRowSize
      );
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
    },
    ownedByMeOnPageSizeChanged() {
      this.ownedByMeGridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.ownedByMeSelectedRowSize
      );
      this.ownedByMeGridApi.infiniteRowModel.resetCache();
      this.ownedByMeGridApi.paginationSetPageSize(
        Number(this.ownedByMeSelectedRowSize)
      );
    },
    debouncedSearchOrFilterCaller: _.debounce(function () {
      this.changedSearchOrFilter();
    }, 1000),

    async changedSearchOrFilter() {
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },
    async ownedByMeChangedSearchOrFilter() {
      this.ownedByMeDataSource = await this.getOwnedByMeDataSource(
        Number(this.ownedByMeSelectedRowSize)
      );
    },
    onModelUpdated() {
      if (this.isAllSelected) {
        const self = this;
        this.gridApi.forEachNode((rowNode) => {
          if (!self.unselectedPartnerIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },

    openMarkAsPaidModal() {
      this.$v.markAsPaid.$reset();
      this.markAsPaid = {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      };
      this.$root.$emit('modal-open', 'mark-as-paid-modal');
    },
    handlePartnerDetailTabChange(newTab) {
      this.affiliateDetailsTab = newTab;
    },

    selectAllPartners() {
      this.isAllSelected = true;
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
      });

      this.unselectedPartnerIds = [];
    },

    unSelectAllPartners() {
      this.isAllSelected = false;
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });

      this.unselectedPartnerIds = [];
    },

    onRowSelected(event) {
      if (
        !event.node.selected &&
        this.unselectedPartnerIds.indexOf(event.node.data.id) === -1
      ) {
        this.unselectedPartnerIds.push(event.node.data.id);
      } else {
        this.unselectedPartnerIds = this.unselectedPartnerIds.filter(
          (item) => item !== event.node.data.id
        );
      }
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#second-tier-affiliates-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}

#owned-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>