<template>
  <div class="w-full min-h-full">
    <div class="w-full min-h-full h-auto pb-6">
      <v-wait for="purchases">
        <div class="p-4">
          <div v-if="purchases.length" id="purchases-list-view" class="mt-4">
            <v-client-table ref="purchasesTable" :data="purchases" :columns="purchasesTableColumns"
              :options="purchasesTableOptions" class="bg-white text-center rounded border border-grey-300">
              <template slot="current_status" slot-scope="props">
                <div>{{ getCurrentStatus(props.row) }}</div>
              </template>
              <template slot="child_row" slot-scope="props">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col w-3/4 px-4">
                    <gd-tabs :tabs="purchaseDetailTabs" :current-tab="purchaseDetailsTab"
                      :wrapper-class="'pt-4 px-4 gs-default-tabs'" :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
                      :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                      :line-class="'gs-default-tabs-active-line'" @onClick="handlePurchaseDetailTabChange" />
                    <transaction-details v-if="purchaseDetailsTab === 'transaction'" :purchase="props.row" />
                    <customer v-else-if="purchaseDetailsTab === 'customer'" :purchase="props.row" />
                    <affiliate v-else-if="purchaseDetailsTab === 'affiliate'" :purchase="props.row" />
                  </div>
                  <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
                    <div class="my-auto flex flex-col">
                      <gd-button v-if="props.row.gateway_type == 'card' && props.row.is_transaction_cancelled == 0"
                        size="auto" variant="primary" class="mb-4" @click="downloadTransactionInvoice(props.row.id)">
                        {{ $t('frontend_analytics_download_invoice') }}
                      </gd-button>

                      <gd-button v-if="isUpdateInvoiceButtonActive(props.row)" size="auto" variant="primary" class="mb-4"
                        @click="updateProfile(props.row.id)">
                        {{ $t('frontend_groovesell_billing_edit_invoice') }}
                      </gd-button>
                    </div>
                  </div>
                </div>
              </template>
            </v-client-table>
          </div>
          <div v-else class="mt-8">
            <p>
              {{ $t('frontend_groovesell_billing_not_purchased_using_email', {brandSupportEmail: this.brandSupportEmail}) }}
            </p>
          </div>
        </div>

        <user-profile-modal :purchaseDetail="purchaseDetail"
          @refresh="emitRefreshRequest" />
      </v-wait>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import TransactionDetails from './TransactionDetails';
import Customer from './Customer';
import Affiliate from './Affiliate';
import UserProfileModal from '../Modals/UserProfileModal';

export default {
  name: 'Transactions',
  components: {
    TransactionDetails,
    Customer,
    Affiliate,
    UserProfileModal
  },
  props: {
    purchases: {
      required: true
    }
  },
  data() {
    return {
      purchasesTableColumns: ['product_name', 'human_readable_price', 'formatted_date_time', 'current_status'],
      purchasesTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: ['product_name'],
        headings: {
          product_name: 'Product',
          human_readable_price: 'Price',
          current_status: 'Status',
          formatted_date_time: 'Date And Time'
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      },
      purchaseDetailTabs: [
        { title: this.$t('frontend_transaction'), value: 'transaction' },
        { title: this.$t('frontend_customer'), value: 'customer' },
        { title: this.$t('frontend_affiliate'), value: 'affiliate' }
      ],
      purchaseDetailsTab: 'transaction',
      domLayout: 'autoHeight',
      complete: true,
      purchaseDetail: {
        id: null,
        buyer_fname: null,
        buyer_lname: null,
        buyer_phone: null,
        buyer_company: null,
        buyer_address: null,
        buyer_city: null,
        buyer_country: null,
        buyer_state: null,
        buyer_postal_code: null
      }
    };
  },
  computed: {
    brandSupportEmail() {
      return this.$store.state.brandData.brandSupportEmail || 'support@groovedigital.com';
    }
  },
  methods: {
    emitRefreshRequest() {
      this.$emit('refresh');
    },
    isUpdateInvoiceButtonActive(row) {
      return row.recurring_billing && row.payments_left != 'Subscription ended' &&
        row.gateway_type == 'card' &&
        !row.is_refunded &&
        row.is_active &&
        row.is_transaction_cancelled == 0
    },
    getCurrentStatus(row) {
      let status = this.$t('frontend_paid');
      if (row.is_refunded) {
        status = this.$t('frontend_groovesell_billing_step2_refunded');
      } else if (row.is_transaction_cancelled) {
        status = this.$t('frontend_groovesell_billing_step2_cancelled');
      } else if (row.is_recurring_transaction && row.payments_left != 'Subscription ended') {
        status = this.$t('frontend_groovesell_billing_step2_in_sub');
      } else if (row.is_recurring_transaction && row.payments_left && row.payments_left == 'Subscription ended') {
        status = this.$t('frontend_groovesell_billing_step2_sub_ended');
      }
      return status;

    },
    handlePurchaseDetailTabChange(newTab) {
      this.purchaseDetailsTab = newTab;
    },
    async downloadTransactionInvoice(transactionId) {
      const self = this;
      self.$loader.start('downloadTransactionInvoice');
      const invoiceLinkData = await axios.post('/generate-invoice-download-link', {
        transaction_id: transactionId
      });


      let a = document.createElement('a');
      a.href = invoiceLinkData.data.download_link.replace(/^http:\/\//i, 'https://');
      a.click();

      self.$loader.end('downloadTransactionInvoice');
    },
    async updateProfile(transactionId) {
      const self = this;
      self.$loader.start('updateProfile');
      const purchaseDeatai = await axios.post('/purchase-detail', {
        transaction_id: transactionId
      });
      self.purchaseDetail = purchaseDeatai.data.purchase[0];
      self.$loader.end('updateProfile');
      this.$root.$emit('modal-open', 'user-profile-modal');
    },
  }
};
</script>

<style lang="scss">
.disabled-button {
  background-color: #fea7b8 !important;
}

#purchases-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>
