<template>
    <gd-modal name="leaderboards-team-modal" :title="$t('frontend_groovesell_leaderboard_team')" height="80%" width="80%"
        :no-overflow="true" @closed="leaderboards_team_modal_closed" allowOverflow>
        <div class="flex flex-row">
            <gd-button variant="primary" size="auto" class="m-3 mr-4 ml-auto" @click="openModal('add-leaderboard-team-modal')">
                <plus-icon class="w-5 h-5 fill-current" />
                {{ $t('frontend_add_team') }}
            </gd-button>
        </div>
        <div class="flex flex-col">
            <!-- Leader Board Team Table View -->
            <div v-if="leaderBordTeamData && leaderBordTeamData.length > 0" class="p-6 flex"
                id="affiliate-transactions-list-view">
                <v-client-table :data="leaderBordTeamData" :columns="leaderBordTeamColumns" :options="leaderBordTeamOptions"
                    ref="leaderBordTeamTable">
                    <div slot="actions" slot-scope="props">
                        <div class="flex flex-row">
                            <gd-button variant="primary" size="icon" class="mr-2"
                                @click="editLeaderBoardTeam(props.index, props.row)">
                                <pencil-icon class="w-5 h-5 fill-current" />
                            </gd-button>

                            <gd-button variant="primary" size="icon" class="mr-2"
                                @click="deleteLeaderBoardTeam(props.index, props.row)">
                                <trash-icon class="w-5 h-5 fill-current" />
                            </gd-button>
                        </div>
                    </div>
                </v-client-table>
            </div>
            <div v-else class="p-6 flex">
                {{ $t('frontend_groovesell_no_teams_desc') }}
            </div>

            <div class="flex flex-row w-full mb-6 justify-end">
                <gd-button size="auto" class="mr-4" variant="primary" @click="saveLeaderBoardTeam">{{
                    $t('frontend_save_close') }}</gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        PencilIcon,
        TrashIcon,
        PlusIcon,
    },
    props: {
        openModal: {
            type: Function,
            required: true
        },
        leaderBordTeamData: {
            type: Array,
            required: true
        },
        editLeaderBoardTeam: {
            type: Function,
            required: true
        },
        deleteLeaderBoardTeam: {
            type: Function,
            required: true
        },
        saveLeaderBoardTeam: {
            type: Function,
            required: true
        },

    },
    data() {
        return {
            leaderBordTeamColumns: ['name', 'affiliates', 'actions'],
            leaderBordTeamOptions: {
                headings: {
                    name: this.$t('frontend_name'),
                    actions: this.$t('frontend_groovesell_page_tracking_table_actions'),
                    affiliates: this.$t('frontend_groovesell_leaderboard_table_affiliate')
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
        }
    },
    methods: {
        leaderboards_team_modal_closed() {
            this.$emit('update-attributes', {
                key: 'affiliate_empty_err_add_team',
                value: false
            });

        },


    }

}
</script>