<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs :tabs="landingPagesTabs" :error-tabs="landingPagesErrorTabs" :current-tab="landingPagesTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs flex-auto w-128'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleLandingPagesTabChange" />
            <gd-button size="auto" variant="primary" class="mt-3 w-64" @click="emitCreateAffiliateLandingPage">
                <plus-icon class="h-5 w-5 fill-current"/>
                {{ $t('frontend_product_step9_add_new_landing_page') }}
            </gd-button>
        </div>
        <div class="mt-2">
            <div v-for="(affiliateLandingPage, index) in localModel.affiliate_landing_pages" :key="index">
                <div v-if="landingPagesTab === 'landing_page_' + affiliateLandingPage.id">
                    <div class="flex flex-col w-1/2 mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_name')
                        }}</label>
                        <text-input v-model="affiliateLandingPage.description" :validator="validation.localModel.affiliate_landing_pages.$each[index]
                            .description
                            " :attribute="$t('frontend_groovesell_landing_page_name')"
                            value="affiliateLandingPage.description" :placeholder="$t('frontend_product_step9_name')" />
                    </div>
                    <div class="flex flex-col w-1/2 mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_product_step9_landing_page')
                        }}</label>
                        <label v-if="index == 0" class="text-grey-dark">
                            {{ affiliateLandingPage.landing_page }}
                        </label>
                        <label v-if="index == 0" class="text-xs text-grey-dark">{{
                            $t('frontend_product_step9_this_cannotbe')
                        }}</label>
                        <text-input v-else v-model="affiliateLandingPage.landing_page" :validator="validation.localModel.affiliate_landing_pages.$each[index]
                            .landing_page
                            " :attribute="$t('frontend_product_step9_landing_page_url')"
                            value="affiliateLandingPage.landing_page"
                            :placeholder="$t('frontend_product_step9_landing_page_url')" />
                    </div>
                    <div class="flex flex-col w-full mb-6">
                        <label class="font-semibold text-sm text-grey-700 mb-2">{{
                            $t('frontend_product_step9_available_to')
                        }}</label>
                        <label v-if="index == 0" class="text-grey-dark">{{
                            $t('frontend_product_step9_all_affiliates')
                        }}</label>
                        <div v-else>
                            <div class="w-1/2 flex flex-row">
                                <div class="flex w-1/2 mr-4">
                                    <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                                                'border-primary': landingPageAssignedType === 1,
                                                'border-grey-200': landingPageAssignedType !== 1,
                                            }" @click="updateLandingPageAssignedType(1)"
                                        @keydown.space="updateLandingPageAssignedType(1)">
                                        <label class="flex cursor-pointer">
                                            <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                                'border-grey bg-primary text-primary activeOptionRadio':
                                                    landingPageAssignedType === 1,
                                                'border-grey': landingPageAssignedType !== 1
                                            }"></span>
                                            <div class="ml-2 flex flex-col w-full">
                                                <label class="font-semibold" :class="{
                                                    'text-primary': landingPageAssignedType === 1,
                                                    'text-grey-700':
                                                        landingPageAssignedType !== 1
                                                }">{{ $t('frontend_product_step9_all_affiliates') }}</label>
                                                <div class="text-grey-500">
                                                    {{ $t('frontend_product_step9_this_landingpage') }}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="flex w-1/2 mr-4">
                                    <div class=" w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{
                                        'border-primary': landingPageAssignedType === 2,
                                        'border-grey-200': landingPageAssignedType !== 2,
                                    }" @click="updateLandingPageAssignedType(2)"
                                        @keydown.space="updateLandingPageAssignedType(2)">
                                        <label class="flex cursor-pointer">
                                            <span class=" w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{
                                                'border-grey bg-primary text-primary activeOptionRadio':
                                                    landingPageAssignedType === 2,
                                                'border-grey': landingPageAssignedType !== 2,
                                            }"></span>
                                            <div class="ml-2 flex flex-col w-full">
                                                <label class="font-semibold" :class="{
                                                    'text-primary': landingPageAssignedType === 2,
                                                    'text-grey-700':
                                                        landingPageAssignedType !== 2,
                                                }">{{ $t('frontend_product_step9_specific_affiliates') }}</label>
                                                <div class="text-grey-500">
                                                    {{ $t('frontend_product_step9_this_landingpage_wouldbe') }}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="landingPageAssignedType == 2" class="w-1/2">
                                <gd-button size="auto" variant="primary" class="mt-3"
                                    @click="openCustomLandingPagesAffModal(index)">
                                    {{ $t('frontend_groovesell_available_to') }}
                                    {{ affiliateLandingPage.assigned_to.length }}
                                    {{ $t('frontend_affiliates') }}
                                </gd-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="index > 0" class="flex flex-row-reverse w-full mb-6">
                        <gd-button variant="outline" size="icon" class="mt-3" @click="emitDeleteAffiliateLandingPage(index)">
                            <trash-icon class="h-5 w-5 fill-current"/>
                        </gd-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        validation: {
            required: true
        },
        openCustomLandingPagesAffModal: {
            type: Function,
            required: true
        },

    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true
        }
    },
    computed: {
        landingPageAssignedType() {
            const currentlyActiveLandingPageId = parseInt(
                this.landingPagesTab.split('_')[2]
            );

            const selectedLandingPageIndex = this.localModel.affiliate_landing_pages
                .map((item) => item.id)
                .indexOf(currentlyActiveLandingPageId);

            if (selectedLandingPageIndex != -1) {
                const assignedTo =
                this.localModel.affiliate_landing_pages[selectedLandingPageIndex]
                    .assigned_to;

                if (assignedTo.length > 0 && assignedTo[0].id == 0) {
                return 1;
                }
                return 2;
            }

            return 2;
        },
        landingPagesTabs() {
            const landingPageTabs = [];
            for (let i = 0; i < this.localModel.affiliate_landing_pages.length; i++) {
                landingPageTabs.push({
                    title: this.localModel.affiliate_landing_pages[i].description,
                    value: `landing_page_${this.localModel.affiliate_landing_pages[i].id}`,
                });
            }

            return landingPageTabs;
        },
        landingPagesErrorTabs() {
            const landingPageErrorTabs = [];
            let landingPageIndex;
            for (landingPageIndex in this.validation.localModel.affiliate_landing_pages.$each.$iter) {
                if (this.validation.localModel.affiliate_landing_pages.$each.$iter[landingPageIndex].$error) {
                    landingPageErrorTabs.push(
                        `landing_page_${this.validation.localModel.affiliate_landing_pages.$each.$iter[landingPageIndex].$model.id}`
                    );
                }
            }

            return landingPageErrorTabs;
        },
    },
    data() {
        return {
            localModel: this.model,
            landingPagesTab: ''
        }
    },
    methods: {
        handleLandingPagesTabChange(tab) {
            this.landingPagesTab = tab;
        },
        emitCreateAffiliateLandingPage() {
            this.$emit('create-affiliate-landing-page');
        },
        updateLandingPageAssignedType(type) {
            if (this.landingPageAssignedType != type) {
                const currentlyActiveLandingPageId = parseInt(
                this.landingPagesTab.split('_')[2]
                );

                const selectedLandingPageIndex = this.localModel.affiliate_landing_pages
                .map((item) => item.id)
                .indexOf(currentlyActiveLandingPageId);

                if (selectedLandingPageIndex != -1) {
                const assignedTo =
                    this.localModel.affiliate_landing_pages[selectedLandingPageIndex]
                    .assigned_to;

                if (type == 1) {
                    this.localModel.affiliate_landing_pages[
                    selectedLandingPageIndex
                    ].assigned_to = [
                        {
                        id: 0,
                        name: this.$t('frontend_affiliates_all_affiliates'),
                        },
                    ];
                } else {
                    this.localModel.affiliate_landing_pages[
                    selectedLandingPageIndex
                    ].assigned_to = [];
                }
                }
            }
        },
        emitDeleteAffiliateLandingPage(index) {
            this.$emit('delete-affiliate-landing-page', index);
        },

    },

}
</script>