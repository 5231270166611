<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="socialMediaPostsTabs"
        :current-tab="socialMediaPostsTab"
        :error-tabs="socialMediaPostsErrorTabs"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleSocialMediaPostsTabChange"
      />
    </div>
    <div class="mt-2">
      <div v-if="socialMediaPostsTab == 'facebook'">
        <div v-if="hasFacebookPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="facebookPostsTabs"
              :current-tab="localFacebookPostsTab"
              :error-tabs="facebookPostsErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleFacebookPostsTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleFacebookPostsTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createFacebookPost"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in localFunnelResource.social_media_posts.facebook" :key="index">
              <div v-if="isCurrentFacebookPostsTab(post)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="post.name"
                      :validator="$v.localFunnelResource.social_media_posts.facebook.$each[index].name"
                      :attribute="$t('frontend_groovemember_filters_post_name')"
                      value="post.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>

                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle
                      class="h-6 mt-2"
                      v-model="post.enabled"
                      :value="post.enabled"
                    />
                    <gd-button
                      variant="outline"
                      size="iconlg"
                      class="ml-4"
                      @click="openConfirmModal(index, 'deleteFacebookPost')"
                    >
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-1/2 mb-6">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_link_to') }}
                  </label>
                  <gd-dropdown
                    v-model="post.landingPage"
                    size="full"
                    :options="landingPagesOptions"
                  />
                </div>

                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_post') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.post"
                        class="swipesEditor w-full"
                        value="post.post"
                        :placeholder="$t('frontend_resources_post')"
                        :validator="$v.localFunnelResource.social_media_posts.facebook.$each[index].post"
                        :attribute="$t('frontend_groovesell_post_description')"
                      />
                    </div>
                    <div class="flex flex-col">
                      <gd-button
                        size="auto"
                        variant="primary"
                        class="mb-4"
                        @click="addAffiliateNameToFacebookPost(index)"
                      >
                        {{ $t('frontend_resources_add_affiliate_name') }}
                      </gd-button>
                      <gd-dropdown
                        :placeholder="$t('frontend_resources_add_affiliate_link')"
                        :actions="affiliateLinksActionsFacebookPost(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createFacebookPost"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_donthave_any_facebook_posts') }}
        </div>
      </div>
      <div v-else-if="socialMediaPostsTab == 'twitter'">
        <div v-if="hasTwitterPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="twitterTweetsTabs"
              :current-tab="localTwitterTweetsTab"
              :error-tabs="twitterTweetsErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleTwitterTweetsTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleTwitterTweetsTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createTwitterTweet"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in localFunnelResource.social_media_posts.twitter" :key="index">
              <div v-if="isCurrentTwitterTweetsTab(post)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="post.name"
                      :validator="$v.localFunnelResource.social_media_posts.twitter.$each[index].name"
                      :attribute="$t('frontend_groovemember_filters_post_name')"
                      value="post.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>

                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle
                      class="h-6 mt-2"
                      v-model="post.enabled"
                      :value="post.enabled"
                    />
                    <gd-button
                      variant="outline"
                      size="iconlg"
                      class="ml-4"
                      @click="openConfirmModal(index, 'deleteTwitterTweet')"
                    >
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-1/2 mb-6">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_link_to') }}
                  </label>
                  <gd-dropdown
                    v-model="post.landingPage"
                    size="full"
                    :options="landingPagesOptions"
                  />
                </div>

                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_tweet') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.tweet"
                        class="swipesEditor w-full"
                        value="post.tweet"
                        :placeholder="$t('frontend_resources_tweet')"
                        :validator="$v.localFunnelResource.social_media_posts.twitter.$each[index].tweet"
                        :attribute="$t('frontend_resources_tweet')"
                      />
                    </div>
                    <div class="flex flex-col">
                      <gd-button
                        size="auto"
                        variant="primary"
                        class="mb-4"
                        @click="addAffiliateNameToTwitterTweet(index)"
                      >
                        {{ $t('frontend_resources_add_affiliate_name') }}
                      </gd-button>
                      <gd-dropdown
                        :placeholder="$t('frontend_resources_add_affiliate_link')"
                        :actions="affiliateLinksActionsTwitterTweet(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createTwitterTweet"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_currently_dont_have_any_tweets') }}
        </div>
      </div>

      <div v-else-if="socialMediaPostsTab == 'linkedin'">
        <div v-if="hasLinkedinPosts" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="linkedinPostsTabs"
              :current-tab="localLinkedinPostsTab"
              :error-tabs="linkedinPostsErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleLinkedinPostsTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleLinkedinPostsTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createLinkedinPost"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in localFunnelResource.social_media_posts.linkedin" :key="index">
              <div v-if="isCurrentLinkedinPostsTab(post)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="post.name"
                      :validator="$v.localFunnelResource.social_media_posts.linkedin.$each[index].name"
                      :attribute="$t('frontend_groovemember_filters_post_name')"
                      value="post.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>

                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle
                      class="h-6 mt-2"
                      v-model="post.enabled"
                      :value="post.enabled"
                    />
                    <gd-button
                      variant="outline"
                      size="iconlg"
                      class="ml-4"
                      @click="openConfirmModal(index, 'deleteLinkedinPost')"
                    >
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-1/2 mb-6">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_link_to') }}
                  </label>
                  <gd-dropdown
                    v-model="post.landingPage"
                    size="full"
                    :options="landingPagesOptions"
                  />
                </div>

                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_post') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.post"
                        class="swipesEditor w-full"
                        value="post.post"
                        :placeholder="$t('frontend_resources_post')"
                        :validator="$v.localFunnelResource.social_media_posts.linkedin.$each[index].post"
                        attribute="Post"
                      />
                    </div>
                    <div class="flex flex-col">
                      <gd-button
                        size="auto"
                        variant="primary"
                        class="mb-4"
                        @click="addAffiliateNameToLinkedinPost(index)"
                      >
                        {{ $t('frontend_resources_add_affiliate_name') }}
                      </gd-button>
                      <gd-dropdown
                        :placeholder="$t('frontend_resources_add_affiliate_link')"
                        :actions="affiliateLinksActionsLinkedinPost(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createLinkedinPost"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_currently_donthave_linkedin_posts') }}
        </div>
      </div>
    </div>
    <confirm-modal
      name="social-media-confirm-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="applyAction"
    />
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  components: {
    TrashIcon,
  },
  mixins: [generateNumberMixin],
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    facebookPostsTab: {
      type: String,
      default: '',
    },
    twitterTweetsTab: {
      type: String,
      default: '',
    },
    linkedinPostsTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      itemToDeleteIndex: null,
      actionType: '',
      localFunnelResource: this.funnelResource,
      localFacebookPostsTab: this.facebookPostsTab,
      localTwitterTweetsTab: this.twitterTweetsTab,
      localLinkedinPostsTab: this.linkedinPostsTab,
      socialMediaPostsTabs: [
        { title: this.$t('frontend_resources_facebook'), value: 'facebook' },
        { title: this.$t('frontend_resources_twitter'), value: 'twitter' },
        { title: this.$t('frontend_resources_linkedin'), value: 'linkedin' },
      ],
      socialMediaPostsTab: 'facebook',
    }
  },
  computed: {
    landingPagesOptions() {
      return this.landingPages.map((landingPage) => {
        return {
          value: landingPage.id,
          text: landingPage.description,
        };
      });
    },
    hasFacebookPosts() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.social_media_posts &&
        this.localFunnelResource.social_media_posts.facebook.length > 0
      );
    },
    hasTwitterPosts() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.social_media_posts &&
        this.localFunnelResource.social_media_posts.twitter.length > 0
      );
    },
    hasLinkedinPosts() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.social_media_posts &&
        this.localFunnelResource.social_media_posts.linkedin.length > 0
      );
    },
    socialMediaPostsErrorTabs() {
      let socialMediaPostsErrorTabs = [];

      if (this.$v.localFunnelResource.social_media_posts.facebook.$error) {
        socialMediaPostsErrorTabs.push('facebook');
      }

      if (this.$v.localFunnelResource.social_media_posts.twitter.$error) {
        socialMediaPostsErrorTabs.push('twitter');
      }

      if (this.$v.localFunnelResource.social_media_posts.linkedin.$error) {
        socialMediaPostsErrorTabs.push('linkedin');
      }

      return socialMediaPostsErrorTabs;
    },
    facebookPostsTabs() {
      let facebookPostsTabs = [];

      if (this.hasFacebookPosts) {
        for (let i = 0; i < this.localFunnelResource.social_media_posts.facebook.length; i++) {
          facebookPostsTabs.push({
            title: this.localFunnelResource.social_media_posts.facebook[i].name,
            value: `fb_post_tab_${this.localFunnelResource.social_media_posts.facebook[i].id}`,
          });
        }
      }

      return facebookPostsTabs;
    },
    facebookPostsErrorTabs() {
      let facebookPostsErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.social_media_posts.facebook.$each.$iter) {
        if (this.$v.localFunnelResource.social_media_posts.facebook.$each.$iter[index].$error) {
          facebookPostsErrorTabs.push(
            `fb_post_tab_${this.$v.localFunnelResource.social_media_posts.facebook.$each.$iter[index].$model.id}`
          );
        }
      }

      return facebookPostsErrorTabs;
    },
    twitterTweetsTabs() {
      let twitterTweetsTabs = [];

      if (this.hasTwitterPosts) {
        for (let i = 0; i < this.localFunnelResource.social_media_posts.twitter.length;i++) {
          twitterTweetsTabs.push({
            title: this.localFunnelResource.social_media_posts.twitter[i].name,
            value: `twitter_tweet_tab_${this.localFunnelResource.social_media_posts.twitter[i].id}`,
          });
        }
      }

      return twitterTweetsTabs;
    },
    twitterTweetsErrorTabs() {
      let twitterTweetsErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.social_media_posts.twitter.$each.$iter) {
        if (this.$v.localFunnelResource.social_media_posts.twitter.$each.$iter[index].$error) {
          twitterTweetsErrorTabs.push(
            `twitter_tweet_tab_${this.$v.localFunnelResource.social_media_posts.twitter.$each.$iter[index].$model.id}`
          );
        }
      }

      return twitterTweetsErrorTabs;
    },
    linkedinPostsTabs() {
      let linkedinPostsTabs = [];

      if (this.hasLinkedinPosts) {
        for (let i = 0; i < this.localFunnelResource.social_media_posts.linkedin.length; i++) {
          linkedinPostsTabs.push({
            title: this.localFunnelResource.social_media_posts.linkedin[i].name,
            value: `linkedin_post_tab_${this.localFunnelResource.social_media_posts.linkedin[i].id}`,
          });
        }
      }

      return linkedinPostsTabs;
    },
    linkedinPostsErrorTabs() {
      let linkedinPostsErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.social_media_posts.linkedin.$each.$iter) {
        if (this.$v.localFunnelResource.social_media_posts.linkedin.$each.$iter[index].$error) {
          linkedinPostsErrorTabs.push(
            `linkedin_post_tab_${this.$v.localFunnelResource.social_media_posts.linkedin.$each.$iter[index].$model.id}`
          );
        }
      }

      return linkedinPostsErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActionsFacebookPost(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToFacebookPost(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsTwitterTweet(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToTwitterTweet(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsLinkedinPost(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToLinkedinPost(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleFacebookPostsTabChange(newTab) {
      this.localFacebookPostsTab = newTab || this.localFacebookPostsTab;
      this.$emit('handleFacebookPostsTabChange', this.localFacebookPostsTab);
    },
    handleTwitterTweetsTabChange(newTab) {
      this.localTwitterTweetsTab = newTab || this.localTwitterTweetsTab;
      this.$emit('handleTwitterTweetsTabChange', this.localTwitterTweetsTab);
    },
    handleLinkedinPostsTabChange(newTab) {
      this.localLinkedinPostsTab = newTab || this.localLinkedinPostsTab;
      this.$emit('handleLinkedinPostsTabChange', this.localLinkedinPostsTab);
    },
    handleSocialMediaPostsTabChange(newTab) {
      this.socialMediaPostsTab = newTab;
    },
    handleFacebookPostsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('fb_post_tab_', ''))
      );

      this.localFunnelResource.social_media_posts.facebook.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });
    },
    createFacebookPost() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.social_media_posts.facebook.length + 1;

      this.localFunnelResource.social_media_posts.facebook.push({
        id: newPostId,
        name: `Post ${newPostCount}`,
        landingPage: this.landingPages[0].id,
        post: '',
      });
      this.localFacebookPostsTab = `fb_post_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleFacebookPostsTabChange();
    },
    openConfirmModal(index, actionType) {
      this.itemToDeleteIndex = index;
      this.actionType = actionType;
      this.$root.$emit('modal-open', 'social-media-confirm-delete-modal');
    },
    applyAction() {
      switch (this.actionType) {
        case 'deleteFacebookPost':
          this.deleteFacebookPost();
          break;
        case 'deleteTwitterTweet':
          this.deleteTwitterTweet();
          break;
        case 'deleteLinkedinPost':
          this.deleteLinkedinPost();
          break;
      }
    },
    async deleteFacebookPost() {
      this.localFunnelResource.social_media_posts.facebook.splice(this.itemToDeleteIndex, 1);
      this.localFacebookPostsTab = '';

      if (this.localFunnelResource.social_media_posts.facebook.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId =
          this.localFunnelResource.social_media_posts.facebook[nextOpenIndex].id;
        this.localFacebookPostsTab = `fb_post_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleFacebookPostsTabChange();
    },
    addAffiliateNameToFacebookPost(index) {
      this.localFunnelResource.social_media_posts.facebook[
        index
      ].post = `${this.localFunnelResource.social_media_posts.facebook[index].post}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToFacebookPost(index, landingPageId) {
      this.localFunnelResource.social_media_posts.facebook[
        index
      ].post = `${this.localFunnelResource.social_media_posts.facebook[index].post}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    handleTwitterTweetsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('twitter_tweet_tab_', ''))
      );

      this.localFunnelResource.social_media_posts.twitter.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createTwitterTweet() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.social_media_posts.twitter.length + 1;

      this.localFunnelResource.social_media_posts.twitter.push({
        id: newPostId,
        name: `Tweet ${newPostCount}`,
        landingPage: this.landingPages[0].id,
        tweet: '',
      });
      this.localTwitterTweetsTab = `twitter_tweet_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleTwitterTweetsTabChange();
    },
    async deleteTwitterTweet() {
      this.localFunnelResource.social_media_posts.twitter.splice(this.itemToDeleteIndex, 1);
      this.localTwitterTweetsTab = '';

      if (this.localFunnelResource.social_media_posts.twitter.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId = this.localFunnelResource.social_media_posts.twitter[nextOpenIndex].id;
        this.localTwitterTweetsTab = `twitter_tweet_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleTwitterTweetsTabChange();
    },
    addAffiliateNameToTwitterTweet(index) {
      this.localFunnelResource.social_media_posts.twitter[
        index
      ].tweet = `${this.localFunnelResource.social_media_posts.twitter[index].tweet}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToTwitterTweet(index, landingPageId) {
      this.localFunnelResource.social_media_posts.twitter[
        index
      ].tweet = `${this.localFunnelResource.social_media_posts.twitter[index].tweet}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    handleLinkedinPostsTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('linkedin_post_tab_', ''))
      );

      this.localFunnelResource.social_media_posts.linkedin.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createLinkedinPost() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.social_media_posts.linkedin.length + 1;

      this.localFunnelResource.social_media_posts.linkedin.push({
        id: newPostId,
        name: `Post ${newPostCount}`,
        landingPage: this.landingPages[0].id,
        post: '',
      });
      this.localLinkedinPostsTab = `linkedin_post_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleLinkedinPostsTabChange();
    },
    async deleteLinkedinPost() {
      this.localFunnelResource.social_media_posts.linkedin.splice(this.itemToDeleteIndex, 1);
      this.localLinkedinPostsTab = '';

      if (this.localFunnelResource.social_media_posts.linkedin.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId = this.localFunnelResource.social_media_posts.linkedin[nextOpenIndex].id;
        this.localLinkedinPostsTab = `linkedin_post_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleLinkedinPostsTabChange();
    },
    addAffiliateNameToLinkedinPost(index) {
      this.localFunnelResource.social_media_posts.linkedin[
        index
      ].post = `${this.localFunnelResource.social_media_posts.linkedin[index].post}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToLinkedinPost(index, landingPageId) {
      this.localFunnelResource.social_media_posts.linkedin[
        index
      ].post = `${this.localFunnelResource.social_media_posts.linkedin[index].post}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    isCurrentFacebookPostsTab(post) {
      return this.localFacebookPostsTab === 'fb_post_tab_' + post.id;
    },
    isCurrentTwitterTweetsTab(post) {
      return this.localTwitterTweetsTab === 'twitter_tweet_tab_' + post.id;
    },
    isCurrentLinkedinPostsTab(post) {
      return this.localLinkedinPostsTab === 'linkedin_post_tab_' + post.id;
    },
  },
  validations: {
    localFunnelResource: {
      social_media_posts: {
        facebook: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        twitter: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            tweet: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        linkedin: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
      },
    },
  },
};
</script>
