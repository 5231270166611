<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-col w-1/4">
      <div class="flex flex-row space-x-2">
        <gd-dropdown
          v-model="localModel.filteredAffiliateTypes"
          :options="affiliateTypesOptions"
          :placeholder="$t('frontend_groovesell_affiliate_types_filter')"
          @change="changedSearchOrFilter"
        />
        <gd-dropdown
          v-model="localModel.selectedRowSize"
          size="small"
          :options="rowSizeOptions"
          @change="onPageSizeChanged"
        />
      </div>
      <div class="flex mt-4">
        <div class="w-2/3 mr-4">
          <gd-dropdown
            v-model="localModel.selectedFunnel"
            :options="funnelsOptions"
            :placeholder="$t('frontend_vendors_funnels')"
            @change="changedSearchOrFilter"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/4">
      <div class="flex">
        <text-input
          v-model="localModel.searchPhrase"
          v-debounce:1s="changedSearchOrFilter"
          value="searchPhrase"
          :placeholder="$t('frontend_vendors_search')"
          :debounce-events="['input', 'keydown']"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    gridApi: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  computed: {
    affiliateTypesOptions() {
      return [
        { value: 0, text: this.$t('frontend_vendors_all') },
        { value: 1, text: this.$t('frontend_vendors_all_paid') },
        { value: 2, text: this.$t('frontend_vendors_due_payment') },
      ];
    },
    rowSizeOptions() {
      return [
        { value: 5, text: this.$t('frontend_affiliates_5') },
        { value: 10, text: this.$t('frontend_affiliates_10') },
        { value: 25, text: this.$t('frontend_affiliates_25') },
        { value: 50, text: this.$t('frontend_affiliates_50') },
        { value: 100, text: this.$t('frontend_affiliates_100') },
      ];
    },
    funnelsOptions() {
      return Object.values(this.localModel.funnels).map(funnel => ({
        value: funnel.id,
        text: funnel.name,
      }));
    },
  },
  methods: {
    changedSearchOrFilter() {
      this.$emit('changedSearchOrFilter', this.localModel);
    },
    onPageSizeChanged() {
      this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.localModel.selectedRowSize);
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.localModel.selectedRowSize));
    },
  }
};
</script>
