<template>
  <transition :name="routerTransition" mode="out-in">
    <slot>
      <router-view />
    </slot>
  </transition>
</template>

<script>
export default {
  name: 'Child',
  props: ['routerTransition']
};
</script>
