import axios from 'axios';
import Vue from 'vue';
import Cookies from 'js-cookie';
import store from '@/store';
import App from './App';
import router from './router';
import wait from './plugins/wait';
import loader from './plugins/loader';
import './components';
import './plugins';
import '@groovepages/gd-ui-kit/styles.css';
import config from '../../load-config';
import i18n from './plugins/i18n';

config.then(configValues => {
  /**
   * User configs
   */
  let user = JSON.parse('{}');

  // TODO: check why the hardcoded values for cookies
  try {
    if (Cookies.get('db71ab6b0872a8afd28afe1adf7de4b5')) {
      user = JSON.parse(
        Cookies.get('db71ab6b0872a8afd28afe1adf7de4b5') || '{}'
      );
    } else if ('localStorage' in window && window.localStorage) {
      user = JSON.parse(window.localStorage.getItem('user') || '{}');
    }
  } catch (exception) {
    //
  }

  /**
   * Axios configs
   */
  axios.defaults.baseURL = `${configValues.VUE_APP_API_URL}/groovesell`;
  // TODO: check if we still use auth0
  if (!user.auth_keycloak) {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      'x-auth-provider': 'auth0'
    };
  } else {
    delete axios.defaults.headers.common['x-auth-provider'];
  }

  /**
   * Window config default variables
   */
  let appFrontendURL = configValues.VUE_APP_FRONTEND_URL;
  // TODO: Check to remove this condition
  if (configValues.VUE_APP_FRONTEND_URL.includes(`/groovepages`)) {
    appFrontendURL = configValues.VUE_APP_FRONTEND_URL.replace(
      `/groovepages`,
      ''
    );
  }
  const localConfig = {
    apiUrl: `${configValues.VUE_APP_API_URL}/groovesell`,
    appUrl: `${configValues.VUE_APP_GROOVESELL_URL}`,
    grooveappsurl: appFrontendURL,
    apiCoreUrl: `${configValues.VUE_APP_API_URL}/groovedigital`,
    groovesellWhiteLabelAppName: `groovesell`,
    groovepagesWhiteLabelAppName: `groovepages`
  };
  window.config = localConfig;
  window.gdEnv = configValues;

  /**
   * Vue configs and initialization
   */
  Vue.use(loader);
  Vue.config.productionTip = false;

  // eslint-disable-next-line no-new
  store.dispatch('initCore').then(() => {
    new Vue({
      el: '#app',
      i18n,
      store,
      router,
      wait,
      ...App
    });
  });
});
