<template>
  <div>
    <div class="flex flex-row mt-2 w-full p-4 pb-2">
      <div class="flex flex-row w-full justify-between">
        <div class="flex flex-col w-1/4">
          <div class="flex">
            <div class="mr-4">
              <date-range-picker
                ref="picker"
                v-model="localTransactionsDateRange"
                class="w-full"
                :opens="opens"
                :auto-apply="autoApply"
                :start-date="customStartDate"
                :end-date="customEndDate"
                :ranges="customDateRanges"
                @update="updateDateRange"
              >
                <div slot="input" slot-scope="picker">
                  {{ picker.startDate | moment('MM/DD/YYYY') }} -
                  {{ picker.endDate | moment('MM/DD/YYYY') }}
                </div>
              </date-range-picker>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex justify-end space-x-2">
            <gd-button
              variant="primary"
              size="auto"
              @click="openAdvancedFilters"
            >
              <filter-icon class="h-5 w-5 fill-current" />
              {{ $t('frontend_analytics_vendor_filters') }}
            </gd-button>
            <gd-button
              variant="outline"
              size="auto"
              @click="clearFilters"
            >
              <filter-icon class="h-5 w-5 fill-current" />
              {{ $t('frontend_analytics_vendor_reset') }}
            </gd-button>
            <gd-button
              variant="primary"
              size="iconlg" 
              @click="reloadData"
            >
              <reset-icon class="h-5 w-5 fill-current" />
            </gd-button>
          </div>
        </div>
      </div>
    </div>

    <advanced-filters-modal
      :is-affiliate-report-page="isAffiliateReportPage"
      :filtered-funnels="filteredFunnels"
      :funnels-dropdown="funnelsDropdown"
      :filtered-affiliates="localFilteredAffiliates"
      @updatedFilteredFunnels="updatedFilteredFunnels"
      @updatedFilteredAffiliates="updatedFilteredAffiliates"
      @applyFilters="applyFilters"
    />

    <filters-affiliates-modal
      v-if="!isAffiliateReportPage"
      :filtered-affiliates="localFilteredAffiliates"
      @updatedFilteredAffiliates="updatedFilteredAffiliates"
    />
  </div>
</template>

<script>
import moment from 'moment';
import AdvancedFiltersModal from './modals/AdvancedFiltersModal.vue';
import FiltersAffiliatesModal from './modals/FiltersAffiliatesModal.vue';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import ResetIcon from '@/assets/images/icons/ResetIcon.svg';

export default {
  components: {
    AdvancedFiltersModal,
    FiltersAffiliatesModal,
    FilterIcon,
    ResetIcon,
  },
  props: {
    transactionsDateRange: {
      type: Object,
      required: true,
    },
    filteredFunnels: {
      type: Array,
      required: true,
    },
    funnelsDropdown: {
      type: Array,
      default: () => [],
    },
    filteredAffiliates: {
      type: Array,
      default: () => [{ id: 0, name: 'All' }],
    },
    isAffiliateReportPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localTransactionsDateRange: this.transactionsDateRange,
      localFilteredAffiliates: this.filteredAffiliates,
      opens: 'right',
      autoApply: true,
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
      },
    };
  },
  methods: {
    updateDateRange() {
      this.$emit('updateDateRange', this.localTransactionsDateRange);
    },
    updatedFilteredFunnels(filteredFunnels) {
      this.$emit('updatedFilteredFunnels', filteredFunnels);
    },
    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },
    clearFilters() {
      this.$emit('clearFilters');
    },
    reloadData() {
      this.$emit('reloadData');
    },
    applyFilters() {
      this.$emit('applyFilters');
    },
    updatedFilteredAffiliates(filteredAffiliates = null) {
      this.localFilteredAffiliates = filteredAffiliates || this.localFilteredAffiliates;
      this.$emit('updatedFilteredAffiliates', this.localFilteredAffiliates);
    },
  },
};
</script>
