<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="membershipPricePointsTabs" :error-tabs="contentAccessPricePointsErrorTabs"
                :current-tab="membershipPricePointsTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
                :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleMembershipPricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="membershipPricePointsTab ===
                    'membership_price_point_' + pricePoint.id
                    " class="flex flex-col w-full">
                    <div v-if="post_memberships.length > 0">
                        <div class="flex flex-col">
                            <div class="flex flex-row-reverse w-full">
                                <gd-button
                                    size="auto"
                                    variant="primary"
                                    class="mr-4"
                                    @click="emitShowPostSaleMembershipBox(index)"
                                >
                                    <plus-icon class="w-5 h-5 fill-current" />
                                    {{ $t('frontend_product_step8_add_new_membership') }}
                                </gd-button>
                            </div>
                            <div v-if="pricePoint.memberships && pricePoint.memberships.length > 0" class="mt-6">
                                <div v-for="(membership, membershipIndex) in pricePoint.memberships" :key="membershipIndex"
                                    class="flex flex-col mt-4">
                                    <div class="flex flex-row" :class="{
                                        'border-t border-grey-400 border-solid pt-4':
                                            membershipIndex > 0,
                                    }">
                                        <div class="w-1/12 mr-8">{{ $t('frontend_add_to') }}</div>
                                        <div class="flex flex-row w-11/12">
                                            <div class="w-3/4 flex flex-col">
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_membership') }}
                                                    </div>
                                                    <div>
                                                        {{ fetchMembershipName(membership.membership_id) }}
                                                    </div>
                                                </div>
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_access_level') }}
                                                    </div>
                                                    <div>
                                                        {{ fetchAccessLevelName(membership.membership_id,
                                                            membership.secret_key) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-1/4 flex flex-row justify-end pr-4">
                                                <gd-button size="icon" variant="primary"
                                                    :title="$t('frontend_groovesell_delete_membership')" class="mr-2"
                                                    @click="openConfirmModal(index, membershipIndex)">
                                                    <trash-icon class="h-5 w-5 fill-current"/>
                                                </gd-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <label class="text-grey-dark">{{
                                    $t('frontend_product_step8_no_memberships')
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <label class="text-grey-dark">{{
                            $t('frontend_product_step8_no_memberships_added', {memberAppTitle: whitelabelAppData('member').title})
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="confirm-delete-post-sale-membership-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')"
            button-size="auto"
            @confirm="deletePostSaleMembership"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        post_memberships: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
            membershipPricePointsTab: '',
            pricePointIndex: null,
            postSaleMembershipIndex: null,
        };
    },
    watch: {
        model: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
    },
    mounted() {
        if (this.membershipPricePointsTab == '' && this.localModel.pricing_points.length > 0) {
            this.membershipPricePointsTab = `membership_price_point_${this.localModel.pricing_points[0].id}`;
        }
    },
    computed: {
        membershipPricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `membership_price_point_${this.localModel.pricing_points[i].id}`,
                });
            }
            return pricePointTabs;
        },
        contentAccessPricePointsErrorTabs() {
            return [];
        },
    },
    methods: {
        handleMembershipPricePointsTabChange(newTab) {
            this.membershipPricePointsTab = newTab;
        },
        emitShowPostSaleMembershipBox(index) {
            this.$emit('show-post-sale-membership-box', index);
        },
        fetchMembershipName(membershipId) {
            const membership = this.post_memberships.find((obj) => {
                return obj.id == membershipId;
            });
            if (membership) {
                return membership.name;
            }
            return 'N/A';
        },

        fetchAccessLevelName(membershipId, accessLevelSecretKey) {
            const membership = this.post_memberships.find((obj) => {
                return obj.id == membershipId;
            });

            if (membership) {
                const accessLevels = membership.access_level;

                if (accessLevels) {
                    const accessLevel = accessLevels.find((obj) => {
                        return obj.secret_key == accessLevelSecretKey;
                    });

                    if (accessLevel) {
                        return accessLevel.name;
                    }
                    return 'N/A';
                }
                return 'N/A';
            }
            return 'N/A';
        },
        openConfirmModal(index, postSaleMembershipIndex) {
            this.$root.$emit('modal-open', 'confirm-delete-post-sale-membership-modal');
            this.pricePointIndex = index;
            this.postSaleMembershipIndex = postSaleMembershipIndex;
        },
        async deletePostSaleMembership() {
            this.localModel.pricing_points[this.pricePointIndex].memberships.splice(
                this.postSaleMembershipIndex,
                1
            );
        },
    }
}
</script>