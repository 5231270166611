<template>
    <div class="mt-6">
        <div class="flex flex-col w-4/5 pr-2">
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_continue_to_billing')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.continue_to_billing"
                        :placeholder="$t('frontend_continue_to_billing')" />
                </div>
            </div>
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_continue_to_password')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.continue_to_password"
                        :placeholder="$t('frontend_continue_to_password')" />
                </div>
            </div>
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_continue_to_payment')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.continue_to_payment"
                        :placeholder="$t('frontend_continue_to_payment')" />
                </div>
            </div>
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_continue_to_order')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.continue_to_order"
                        :placeholder="$t('frontend_continue_to_order')" />
                </div>
            </div>
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_complete_purchase')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.complete_purchase"
                        :placeholder="$t('frontend_complete_purchase')" />
                </div>
            </div>
            <div class="flex flex-row justify-start pr-2 mb-4">
                <div class="w-1/3">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_get_free_access')
                    }}</label>
                </div>

                <div class="w-2/3">
                    <text-input v-model="localModel.customization_details.button_text_details.get_free_access"
                        :placeholder="$t('frontend_get_free_access')" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
        };
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true,
        },
        
    },
};

</script>