import Model from './Model';

export default class Integration extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'integrations';
  }

  // eslint-disable-next-line class-methods-use-this
  baseURL() {
    return window.config.apiCoreUrl;
  }
}
