var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gd-modal',{attrs:{"name":"transaction-detail-modal","title":_vm.transactionDetailsModalTitle,"width":"80%","bg-type":"regular"}},[(_vm.selectedTransaction)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex-col w-3/4 px-4",staticStyle:{"min-height":"60vh"}},[_c('gd-tabs',{attrs:{"tabs":_vm.transactionDetailTabs,"current-tab":_vm.transactionDetailsTab,"highlight-tabs":_vm.highLightTabs,"highlight-text":"Fraud Alert","wrapper-class":'pt-4 px-0 gs-default-tabs',"tab-class":'pb-2 mr-4 gs-default-tabs-item',"tab-active-class":'border-primary border-b-2 text-primary gs-default-tabs-item-active',"line-class":'gs-default-tabs-active-line'},on:{"onClick":_vm.handleTransactionsDetailTabChange}}),_c('div',{staticClass:"mt-2"},[(_vm.transactionDetailsTab === 'transaction')?_c('transaction',{attrs:{"selectedTransaction":_vm.selectedTransaction,"authenticatedUser":_vm.authenticatedUser}}):(_vm.transactionDetailsTab === 'customer')?_c('customer',{attrs:{"selectedTransaction":_vm.selectedTransaction}}):(_vm.transactionDetailsTab === 'custom_fields')?_c('custom-fields',{attrs:{"selectedTransaction":_vm.selectedTransaction}}):(_vm.transactionDetailsTab === 'affiliate')?_c('affiliate',{attrs:{"selectedTransaction":_vm.selectedTransaction}}):(_vm.transactionDetailsTab === 'retries')?_c('retries',{attrs:{"selectedTransaction":_vm.selectedTransaction}}):_vm._e()],1)],1),_c('div',{staticClass:"flex flex-col w-1/4 border-l border-grey-300 p-4"},[_c('div',{staticClass:"my-auto flex flex-col"},[(!_vm.selectedTransaction.is_partially_refunded &&
                        _vm.selectedTransaction.gateway.toLowerCase() == 'paypal' &&
                        _vm.selectedTransaction.total_amount > 0 &&
                        _vm.selectedTransaction.status == 'captured' &&
                        !_vm.selectedTransaction.is_refunded &&
                        !_vm.selectedTransaction.gateway_transaction_id.startsWith('I-'))?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.refundTransaction}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_refund_transaction')))]):(_vm.selectedTransaction.total_amount > 0 &&
                        _vm.selectedTransaction.status == 'captured' &&
                        !_vm.selectedTransaction.is_refunded &&
                        !_vm.selectedTransaction.is_partially_refunded)?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.refundTransaction}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_refund_transaction')))]):_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","disabled":""}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_refunded_transaction')))]),(
                            _vm.selectedTransaction.total_amount > 0 &&
                            _vm.selectedTransaction.status == 'captured' &&
                            !_vm.selectedTransaction.is_refunded &&
                            !_vm.selectedTransaction.is_partially_refunded &&
                            !_vm.selectedTransaction.gateway_transaction_id.startsWith('I-'))?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.refundTransaction('partial_refund')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_refund_transaction_partially')))]):_vm._e(),(_vm.selectedTransaction.status == 'captured' &&
                        _vm.selectedTransaction.is_active &&
                        _vm.selectedTransaction.payments_left &&
                        _vm.selectedTransaction.payments_left != 'Subscription ended')?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openConfirmModal('cancelTransaction')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_cancel_future_payments')))]):_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","disabled":""}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_cancel_future_payments')))]),(_vm.selectedTransaction.suspected_affiliate_fraud == 1)?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openConfirmModal('unmarkAffiliateFraudTransaction')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_mark_as_not_affiliate')))]):_vm._e(),(_vm.selectedTransaction.affiliate_id)?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openConfirmModal('removeAffiliateFromTransaction')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_remove_affiliate')))]):_vm._e(),(_vm.selectedTransaction.total_amount > 0 &&
                        _vm.selectedTransaction.status == 'failed' &&
                        !_vm.selectedTransaction.is_refunded &&
                        _vm.selectedTransaction.is_active)?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openConfirmModal('retryTransaction')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_retry_transaction')))]):_vm._e(),(_vm.selectedTransaction.status == 'captured')?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":_vm.downloadTransactionInvoice}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_download_invoice')))]):_vm._e(),(_vm.selectedTransaction.assisted_affiliate_id)?_c('gd-button',{staticClass:"mb-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.openConfirmModal('removeAssist')}}},[_vm._v(_vm._s(_vm.$t('frontend_analytics_remove_assist')))]):_vm._e(),(_vm.selectedTransaction.refunded_note)?_c('div',{staticClass:"flex flex-col w-full mt-8 bg-grey-100 p-1"},[_c('label',{staticClass:"font-semibold text-sm text-grey-700"},[_c('info-icon',{staticClass:"h-4 w-4 fill-current"}),_vm._v(" "+_vm._s(_vm.selectedTransaction.refunded_note)+" ")],1)]):_vm._e()],1)])]):_vm._e()]),_c('confirm-modal',{attrs:{"name":"confirm-transaction-detail-modal","message-content":_vm.messageContent,"button-text":_vm.buttonText,"button-size":"auto"},on:{"confirm":_vm.applyAction}}),(_vm.alertProps.show)?_c('popup-alert',{attrs:{"variant":_vm.alertProps.variant},on:{"close":function($event){_vm.alertProps.show = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.alertProps.title)+" ")]},proxy:true}],null,false,1387945214)},[_c('p',[_vm._v(_vm._s(_vm.alertProps.message))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }