<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step8_fullfillment') }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step8_configure')
        }}</span>
      </div>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
        <gd-tabs :tabs="fulfillmentTabs" :current-tab="fulfillmentTab" :wrapper-class="'pt-4 px-0 gs-default-tabs'"
          :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleFulfillmentTabChange" />
      </div>
      <div class="mt-2">
        <memberships :model="localModel" v-if="fulfillmentTab === 'memberships'"
          @show-post-sale-membership-box="handleShowPostSaleMembershipBox" @update-model="updateModel"
          :post_memberships="post_memberships" />

        <integrations :model="localModel" v-else-if="fulfillmentTab === 'integrations'"
          :pluginSiteData="pluginSiteData" :availableIntegrations="availableIntegrations"
          @show-post-sale-integration-box="showPostSaleIntegrationBox" @update-model="updateModel" :validation="$v" />

        <web-hook :model="localModel" v-else-if="fulfillmentTab === 'webHook'" @update-model="updateModel"
          :validation="$v" />

        <files v-else-if="fulfillmentTab === 'files'" :model="localModel" @update-model="updateModel"
          :membership_files="membership_files" @show-file-membership-box="showFileMembershipBox" />
      </div>
    </div>

    <gd-modal name="add-new-post-sale-integration-modal" :title="$t('frontend_product_step5_add_new_integration')" allowOverflow>
      <div class="flex flex-col m-8">
        <div class="flex flex-col w-full mb-6">
          <div class="w-full flex flex-col mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
              $t('frontend_product_step8_type')
            }}</label>
            <gd-dropdown :v-bind="integrationType" :value="integrationType" :options="availableIntegrations" size="full"
              maxHeight="150px" @change="setValue($event)" @keyup="setValue($event)" />
          </div>
          <div v-if="integrationType && integrationType != ''" class="w-full">
            <config-menu :key="configMenuKey" :initial-values="componentConfigValues" :plugin-id="pluginId"
              :resources-path="resourcesPath" :server-path="serverPath" :plugin="integrationType"
              :app-data="pluginSiteData" layout="vertical" :is-dark="false" :show-button-borders="false"
              :show-notifications-on-save="false" :opened-from="'groovesell'"
              :btn-label="$t('frontend_sites_integration_modal_add_integration')" @update="addPostSaleIntegration"
              @menu-updated="configMenuItemsUpdated"></config-menu>
          </div>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="add-membership-modal"
      :title="isEditing === false ? $t('frontend_groovesell_add_membership') : $t('frontend_groovesell_edit_membership')"
      width="80%">
      <div class="flex flex-col px-4 h-96 justify-between">
        <div class="mt-4">
          <div class="-mt-4">
            <div class="flex flex-row w-full">
              <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_select') }}
                  {{ $t('frontend_membership') }}</label>
                <gd-dropdown
                  v-model="postMembershipId"
                  maxHeight="150px"
                  size="full"
                  :options="postMembershipsOptions"
                  :placeholder="`${$t('frontend_select')} ${$t('frontend_membership')}`"
                  @change="selectMembership($event)"
                />
              </div>

              <div class="w-1/2 flex flex-col w-full mt-4">
                <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_select') }}
                  {{ $t('frontend_access_level') }}</label>
                <gd-dropdown
                  v-model="postMembershipAccessLevelId"
                  maxHeight="150px"
                  size="full"
                  :options="postAccessLevelsOptions"
                  :placeholder="`${$t('frontend_select')} ${$t('frontend_access_level')}`"
                  @change="postaccesslevelid($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button
            v-if="isEditing == false"
            :variant="gdButtonVariant"
            :disabled="!post_addbtn"
            class="mt-4"
            size="md"
            @click="postdata != '' ? addMembershipField() : null"
          >
            {{ $t('frontend_add') }}
          </gd-button>
        </div>
      </div>
    </gd-modal>

    <gd-modal name="add-membership-file-modal" allowOverflow
      :title="isEditing === false ? $t('frontend_groovesell_add_new_file') : $t('frontend_groovesell_edit_file')">
      <div class="flex flex-col px-4 h-96 justify-between">
        <div class="mt-4">
          <div class="-mt-4">
            <div class="flex flex-row w-full">
              <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{ $t('frontend_select') }}
                  {{ $t('frontend_membership') }}
                </label>
                <gd-dropdown
                  v-model="postMembershipFileId"
                  maxHeight="150px"
                  size="full"
                  :options="membershipFilesOptions"
                  :placeholder="`${$t('frontend_select')} ${$t('frontend_file')}`"
                  @change="post_addbtn = true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row w-full mb-6 justify-end">
          <gd-button
            v-if="isEditing == false"
            :variant="gdButtonVariant"
            :disabled="!post_addbtn"
            class="mt-4"
            size="md"
            @click="addMembershipFile()"
          >
            {{ $t('frontend_add') }}
          </gd-button>
        </div>
      </div>
    </gd-modal>
  </div>
</template>

<script>
import { required, minLength, url } from 'vuelidate/lib/validators';
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';
import axios from 'axios';

import Memberships from './tabs/fullfillment/Memberships';
import Integrations from './tabs/fullfillment/Integrations';
import WebHook from './tabs/fullfillment/WebHook';
import Files from './tabs/fullfillment/Files';

export default {
  name: 'Step8',
  components: {
    ConfigMenu,
    Memberships,
    Integrations,
    WebHook,
    Files,
  },
  props: {
    model: {
      required: true,
    },
    resourcesPath: {
      required: true,
    },
    serverPath: {
      required: true,
    },
    pluginSiteData: {
      required: true,
    },
    availableIntegrations: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
      fulfillmentTabs: [
        {
          title: 'Memberships',
          value: 'memberships',
        },
        {
          title: this.$t('frontend_product_step8_integrations'),
          value: 'integrations',
        },
        {
          title: this.$t('frontend_product_step8_webhook'),
          value: 'webHook',
        },
        {
          title: this.$t('frontend_product_step8_files'),
          value: 'files',
        },
      ],
      fulfillmentTab: 'memberships',
      pricePointsTab: '',
      contentAccessPricePointsTab: '',
      membershipPricePointsTab: '',
      filesPricePointsTab: '',
      integrationType: '',
      configMenuKey: 1,
      componentConfigValues: [],
      pluginId: '',
      integrationType: '',
      get_price_point_info: [],
      pluginMenuItems: [],
      selectedPricePointIndex: 0,
      post_memberships: [],
      membership_files: [],
      post_access_levels: [],
      pricingMemberships: [],
      post_addbtn: false,
      postdata: [],
      updating_index: '',
      membershipdata: '',
      postdataname: '',
      isEditing: false,
      widgetsTableColumns: ['membership', 'access_level', 'actions'],
      widgetsTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        sortable: [],
        headings: {
          title: 'Membership',
          access_level: 'Access Level',
          expand: '',
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
      postMembershipId: '',
      postMembershipFileId: '',
      postMembershipAccessLevelId: '',
      /* model: {
        postMembership: '',
        postaccesslevelid: ''
      } */
      filesTableColumns: ['name', 'actions'],
      filesTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        sortable: [],
        headings: {
          title: 'Name',
          expand: '',
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
    };
  },
  validations: {
    localModel: {
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          post_sale_details: {
            $each: {
              link: {
                url,
                required,
              },
            },
          },
        },
      },
    },
  },
  computed: {
    contentAccessTypes() {
      return [
        {
          id: 0,
          title: this.$t('frontend_product_step8_no_access'),
          description: this.$t('frontend_product_step8_theres_no'),
        },
        {
          id: 1,
          title: this.$t('frontend_product_step8_a_downloadable'),
          description: this.$t('frontend_product_step8_a_groovesell', {appTitle: this.whitelabelAppData('sell').title}),
          coming_soon: true,
        },
        {
          id: 2,
          title: this.$t('frontend_product_step8_integrated'),
          description: this.$t('frontend_product_step8_the_actual'),
          coming_soon: true,
        },
      ];
    },
    contentAccessPricePointsTabs() {
      const pricePointTabs = [];
      for (let i = 0; i < this.localModel.pricing_points.length; i++) {
        pricePointTabs.push({
          title: this.localModel.pricing_points[i].name,
          value: `content_price_point_${this.localModel.pricing_points[i].id}`,
        });
      }

      return pricePointTabs;
    },
    gdButtonVariant() {
        return this.post_addbtn ? 'primary' : 'base';
    },
    postMembershipsOptions() {
      return this.post_memberships.map((membership) => {
        return {
          value: membership.id,
          text: membership.name,
        };
      });
    },
    postAccessLevelsOptions() {
      return this.post_access_levels.map((accessLevel) => {
        return {
          value: accessLevel.secret_key,
          text: accessLevel.name,
        };
      });
    },
    membershipFilesOptions() {
      return this.membership_files.map((file) => {
        return {
          value: file.id,
          text: file.name,
        };
      });
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
  },
  async created() {
    const memberAppTitle = this.whitelabelAppData('member').url;
    const groovememberUrl = window.config.apiUrl.substr(
      0,
      window.config.apiUrl.lastIndexOf('/')
    );
    const result = await axios.get(
      `${groovememberUrl}${memberAppTitle}memberships`
    );
    this.post_memberships = result.data;
    const fileResult = await axios.get(
      `${groovememberUrl}${memberAppTitle}membership/file`
    );
    this.membership_files = fileResult.data.data;
  },
  async beforeMount() { },
  mounted() {
    if (this.contentAccessPricePointsTab == '' && this.localModel.pricing_points.length > 0) {
      this.contentAccessPricePointsTab = `content_price_point_${this.localModel.pricing_points[0].id}`;
    }
  },
  methods: {
    updateModel(val) {
      this.localModel = val
    },
    selectMembership(event) {
      this.model.postaccesslevelid = '';
      this.post_addbtn = false;
      this.postdata = [];
      const selectedPostMembership = this.post_memberships.find((ele) => {
        return ele.id === this.postMembershipId;
      });
      // console.log(selectedPostMembership);
      if (selectedPostMembership) {
        this.post_access_levels = selectedPostMembership.access_level;
      } else {
        this.post_access_levels = [];
      }
    },

    postaccesslevelid() {
      const selectedPostMembership = this.post_memberships.find((ele) => {
        return ele.id === this.postMembershipId;
      });
      const selectedAccessLevel = this.post_access_levels.find((ele) => {
        return ele.secret_key === this.postMembershipAccessLevelId;
      });
      // console.log(selectedAccessLevel);
      if (selectedAccessLevel) {
        this.membershipdata = {
          membership_id: this.postMembershipId,
          secret_key: this.postMembershipAccessLevelId,
        };
        if (this.isEditing == false) {
          this.postdata.push(this.membershipdata);
        }

        this.post_addbtn = true;
      } else {
        this.postdataname = '';
        this.post_addbtn = false;
      }
    },

    showPostSaleIntegrationBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-new-post-sale-integration-modal');
    },
    handleShowPostSaleMembershipBox(index) {
      this.showPostSaleMembershipBox(index)
    },
    showPostSaleMembershipBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-membership-modal');
    },
    showFileMembershipBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-membership-file-modal');
    },

    setValue(value) {
      this.integrationType = value;
      this.pluginId = '';
      this.componentConfigValues = [];
      this.configMenuKey = this.configMenuKey + 1;
    },

    addMembershipField() {
      const completeMembershipInfo = {};
      completeMembershipInfo.membership_id = this.postMembershipId;
      completeMembershipInfo.secret_key = this.postMembershipAccessLevelId;

      if (
        this.localModel.pricing_points[this.selectedPricePointIndex].memberships
      ) {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].memberships.push(completeMembershipInfo);
      } else {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].memberships = [];
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].memberships.push(completeMembershipInfo);
      }

      this.$root.$emit('modal-close', 'add-membership-modal');
    },

    addMembershipFile() {
      if (this.postMembershipFileId) {
        const completeMemberFileInfo = {};
        completeMemberFileInfo.file_id = this.postMembershipFileId;
        if (
          this.localModel.pricing_points[this.selectedPricePointIndex]
            .file_memberships
        ) {
          this.localModel.pricing_points[
            this.selectedPricePointIndex
          ].file_memberships.push(completeMemberFileInfo);
        } else {
          this.localModel.pricing_points[
            this.selectedPricePointIndex
          ].file_memberships = [];
          this.localModel.pricing_points[
            this.selectedPricePointIndex
          ].file_memberships.push(completeMemberFileInfo);
        }
        this.$root.$emit('modal-close', 'add-membership-file-modal');
      }
    },
    addPostSaleIntegration(data) {
      const integrationConfig = data.config;
      const self = this;

      const integrationInfo = [];

      for (const index in integrationConfig) {
        const value = integrationConfig[index];

        if (value) {
          self.pluginMenuItems.forEach((menuValue, menuIndex) => {
            if (menuValue.key == index) {
              const { options } = menuValue;

              const objChecker = options.some((val) => {
                return typeof val === 'object';
              });

              let correspondingOption = value;
              if (objChecker) {
                correspondingOption = options.find((o) => o.value === value);
              }

              const singleIntegrationField = {};
              singleIntegrationField.index = index;
              singleIntegrationField.value = value;
              singleIntegrationField.label = menuValue.label;

              if (objChecker) {
                singleIntegrationField.name = correspondingOption.text;
              } else {
                singleIntegrationField.name = value;
              }

              integrationInfo.push(singleIntegrationField);
            }
          });
        }
      }

      const completeIntegrationInfo = {};
      completeIntegrationInfo.platform = this.integrationType.split('-')[0];
      completeIntegrationInfo.config = integrationInfo;

      if (
        this.localModel.pricing_points[this.selectedPricePointIndex]
          .post_sale_integrations
      ) {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].post_sale_integrations.push(completeIntegrationInfo);
      } else {
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].post_sale_integrations = [];
        this.localModel.pricing_points[
          this.selectedPricePointIndex
        ].post_sale_integrations.push(completeIntegrationInfo);
      }

      this.$root.$emit('modal-close', 'add-new-post-sale-integration-modal');
    },

    configMenuItemsUpdated(data) {
      this.pluginMenuItems = data.menuItems;
    },

    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(',');
      return `#${(
        (1 << 24) +
        (Number(rgb[0]) << 16) +
        (Number(rgb[1]) << 8) +
        Number(rgb[2])
      )
        .toString(16)
        .slice(1)}`;
    },

    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    handleFulfillmentTabChange(newTab) {
      this.fulfillmentTab = newTab;
    },

    handleContentAccessPricePointsTabChange(newTab) {
      this.contentAccessPricePointsTab = newTab;
    },

  },
};
</script>
