import FunnelResources from '@/pages/resources/index';
import FunnelResourceAssets from '@/pages/resources/assets';
import DefaultFunnelResource from '@/pages/resources/default_funnel_resource';
import PromoTools from '@/pages/resources/promo_tools';
import AffiliateCookieSetter from '@/pages/resources/affiliate_cookie_setter';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/funnels/:id/funnel-resources',
    name: 'funnel_resources',
    component: FunnelResources,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_affiliate_portals_tools')
    }
  },
  {
    path: '/funnels/:id/funnel-resources/:resource_id/assets',
    name: 'funnel_resource_assets',
    component: FunnelResourceAssets,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_add_assets')
    }
  },
  {
    path: '/funnel-resources',
    name: 'default_funnel_resources',
    component: DefaultFunnelResource,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_funnel_resources')
    }
  },
  {
    path: '/promo-tools/:resource_id',
    name: 'promo_tools',
    component: PromoTools,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_promo_tools')
    }
  },
  {
    path: '/cookie-setter',
    name: 'cookie_setter',
    component: AffiliateCookieSetter,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_set_cookies')
    }
  }
];
