<template>
  <div>
    <div class="flex flex-col content-center justify-center text-center w-full bg-white rounded p-4">
      <div class="flex justify-center text-green-dark mt-4 mb-2">
        <check-circle-icon class="w-20 h-20 fill-current" />
      </div>
      <div class="mb-2"><h3 class="text-xl font-bold">{{$t('frontend_product_step11_congratulations')}}</h3></div>
      <div class="mb-8">
        <p class="text-sm font-semibold text-grey-700">
          {{ $t('frontend_product_step11_configured') }}
        </p>
      </div>
      <div class="mb-4"><h4 class="text-xl font-semibold">{{$t('frontend_what_next')}}</h4></div>
      <div class="mb-2">
        <p v-if="localModel.type == 1" class="text-sm font-semibold text-grey-700">
          {{ $t('frontend_product_step11_pastecode') }}
        </p>
        <p v-else class="text-sm font-semibold text-grey-700">
          {{ $t('frontend_product_step11_paste_this_code') }}
        </p>
      </div>
      <div class="flex flex-col content-center justify-center text-center mb-8">
        <h6 class="text-lg font-semibold mt-4">
          {{$t('frontend_tracking')}} {{$t('frontend_link')}}
        </h6>
        <div class="w-full px-32">
          <text-input
            v-model="localModel.tracking_link"
            class="w-full mt-2"
            :readonly="true"
            value="localModel.tracking_link"
            :placeholder="$t('frontend_tracking_tracking_link')"
            :select-all="true"
          />
        </div>
        <div class="flex flex-row justify-center">
          <gd-button
            v-clipboard:copy="model.tracking_link"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            variant="primary"
            size="md"
            class="mt-6"
          >
            {{$t('frontend_copy')}}
          </gd-button>
        </div>
        <div v-if="proofWidgets.length > 0" class="w-full text-center px-32 mt-4">
          <h6 class="text-lg font-semibold">
            {{ $t('frontend_product_step10_proof_widget') }}
          </h6>
          <div v-for="(proofWidget, index) in proofWidgets" :key="index" class="flex flex-col w-full justify-start mt-4">
            <div class="mb-4">
              <p class="text-sm font-semibold text-grey-700">
                {{ proofWidget.name }}
              </p>
            </div>
            <text-area
              v-model="proofWidget.embed_code"
              class="w-full mt-2"
              :readonly="true"
              value="proofWidget.embed_code"
              :placeholder="$t('frontend_widget_code')"
              :select-all="true"
            />
            <div class="flex flex-row justify-center">
              <gd-button
                v-clipboard:copy="proofWidget.embed_code"
                v-clipboard:success="onCopyProofWidget"
                v-clipboard:error="onErrorProofWidget"
                variant="primary"
                size="md"
                class="mt-6"
              >
                {{$t('frontend_copy')}}
              </gd-button>
            </div>
          </div>
        </div>

        <div class="text-center mt-4">
          <span class="text-primary font-semibold cursor-pointer" @click="goToPromotionTools">
            {{$t('frontend_create_promotion_tools')}}
          </span>
        </div>

        <div class="text-center mt-4">
          <span class="text-primary font-semibold cursor-pointer" @click="goToDashboard">
            {{$t('frontend_back_to_dashboard')}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notify from '@groovepages/gd-ui-kit/notify';
import CheckCircleIcon from '@/assets/images/icons/CheckCircleIcon.svg';

export default {
  name: 'Step11',
  components: {
    CheckCircleIcon,
  },
  props: {
    model: {
      required: true
    },
    product: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
      proofWidgets: [],
    };
  },
  validations: {
    localModel: {}
  },
  computed: {},
  async created() {
    this.$loader.start('fetchProofWidgets');
    this.proofWidgets = await this.product.proof_widgets().$get();
    this.$loader.end('fetchProofWidgets');
  },
  methods: {
    goToPromotionTools() {
      this.$router.push({
        name: 'funnel_resources',
        params: { id: this.localModel.funnel_id }
      });
    },

    goToDashboard() {
      this.$router.push({
        name: 'dashboard'
      });
    },

    onCopy() {
      Notify.success(this.$t('frontend_product_step11_success'));
    },

    onError() {
      Notify.fail(this.$t('frontend_unable_copy_to_clipboard'));
    },

    onCopyProofWidget() {
      Notify.success(this.$t('frontend_proof_widget_code_copy_to_clipboard'));
    },

    onErrorProofWidget() {
      Notify.fail(this.$t('frontend_unable_copy_proof_widget_code'));
    },

    getHex() {
      let rgb = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(',');
      return `#${((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`;
    },

    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    }
  }
};
</script>
