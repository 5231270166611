<template>
  <div>
    <email-builder :template-id="templateId" ref="emailBuilder">
    </email-builder>
    <portal to="headerContent">
        <div class="flex flex-row">
            <gd-button size="auto" variant="primary" class="mr-4" @click="$refs.emailBuilder.saveEmail()">Save</gd-button>
            <gd-button size="auto" class="mr-4" @click="$refs.emailBuilder.saveTemplate()">Save As Template</gd-button>
        </div>
    </portal>
    <portal to="headerBreadCrumb">
      <div class="mt-1 cursor-pointer" @click="sendToTest">
        <i class="fas fa-chevron-left"></i>
      </div>
    </portal>
  </div>
</template>

<script>
import EmailBuilder from '@groovepages/gd-ui-kit/components/EmailBuilder'
import axios from 'axios';

export default {
  middleware: 'auth',
  components: {
      EmailBuilder
  },
  data() {
    return {
        templateId: this.$route.params.id,
    };
  },
  metaInfo() {
    return { title: 'Sales Tax' };
  },
  async created()
  {
      
  },
  async mounted() {

  },
  methods: {
      sendToTest()
      {
          this.$router.push({ name: 'playground'});
      }
  }
};
</script>
