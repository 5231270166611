<template>
  <div class="flex flex-row w-full pb-4">
    <div :class="getContainerClasses">
      <div v-if="hasThumbnail" class="flex flex-row mr-2">
        <img :class="getThumbnailClasses" :src="thumbnailImg" />
      </div>
      <div class="flex flex-col items-center justify-center">
        <div class="w-full flex flex-row text-dark">
          <span class="font-bold text-lg md:text-xl">{{ heading }}</span>
        </div>
        <div class="mt-1 text-grey-600 text-sm">{{ subheading }}</div>
      </div>
    </div>
    <div v-if="showQuantitySection" class="w-1/4">
      <div class="w-full h-full flex flex-row justify-end items-center">
        <div class="flex items-center mr-2">
          <span class="text-sm text-grey-700">Quantity</span>
        </div>
        <gd-dropdown
          v-model="localOption.selected_quantity"
          size="x-small"
          :options="quantityOptions"
          @change="getValueofQuantity"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    heading: {
      default: '',
    },
    subheading: {
      default: '',
    },
    thumbnail: {
      default: false,
    },
    thumbnailImg: {
      default: '',
    },
    thumbnailIsSquare: {
      default: false,
    },
    thumbnailSize: {
      default: 'md',
    },
  },
  data() {
    return {
      localOption: this.option,
    };
  },
  computed: {
    hasThumbnail() {
      return this.thumbnail && this.thumbnailImg && this.thumbnailImg != '';
    },
    getContainerClasses() {
      return {
        'flex flex-row': true,
        'w-3/4': this.localOption.type == 1 && this.localOption.quantity_enable == 1,
        'w-full': !(this.localOption.type == 1 && this.localOption.quantity_enable == 1),
      };
    },
    getThumbnailClasses() {
      return {
        'h-12 w-12 flex items-center justify-center max-h-full max-w-full': true,
        'md:h-24 md:w-24': this.thumbnailSize == 'lg',
        'md:h-16 md:w-16': this.thumbnailSize == 'md',
        'md:h-12 md:w-12': this.thumbnailSize == 'sm',
        'rounded-full': !this.thumbnailIsSquare,
      };
    },
    showQuantitySection() {
      return this.localOption.type == 1 && this.localOption.quantity_enable == 1;
    },
    quantityOptions() {
      const options = [];

      for (let i = this.localOption.minimum_quantity; i <= this.localOption.maximum_quantity; i++) {
        options.push(i);
      }

      return options;
    },
  },
  methods: {
    getValueofQuantity() {
      this.$emit('getValueofQuantity', this.localOption.selected_quantity);
    },
  },
};
</script>
