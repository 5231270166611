<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliate-products">
        <div class="flex flex-col w-full bg-white rounded p-4">
          <div class="flex flex-col w-1/2 mb-2">
            <h4 class="font-semibold text-grey-700">
              {{ $t('frontend_groovesell_bonus_integration_post_sale_pixels') }}
            </h4>
          </div>
          <div class="flex flex-col w-1/2 mb-2">
            <span class="text-sm text-grey-700">{{ $t('frontend_groovesell_configure_integration_desc') }}</span>
          </div>

          <div class="flex flex-row justify-end mb-4">
            <div class="flex flex-col w-1/4">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_product') }}</label>
              <gd-dropdown
                v-model="selectedProduct"
                size="full"
                :options="productOptions"
              />
            </div>
          </div>

          <div v-if="product">
            <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4">
              <gd-tabs :tabs="dataSharingTabs" :error-tabs="dataSharingErrorTabs" :current-tab="dataSharingTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleDataSharingTabChange" />
            </div>

            <drop-pixel v-if="dataSharingTab == 'drop_pixel'" :pricePointsTabs="pricePointsTabs" :drop_pixel="drop_pixel"
              :handlePricePointsTabChange="handlePricePointsTabChange" :pricePointsTab="pricePointsTab"
              @update-data="HandleUpdateData" />

            <integrations v-if="dataSharingTab == 'integrations'" :pricePointsTabs="pricePointsTabs"
              :handlePricePointsTabChange="handlePricePointsTabChange" :pricePointsTab="pricePointsTab"
              :integration="integration" :showPostSaleIntegrationBox="showPostSaleIntegrationBox" />

            <web-hook v-if="dataSharingTab == 'webhook'" @update-data="HandleUpdateData"
              :pricePointsTabs="pricePointsTabs" :handlePricePointsTabChange="handlePricePointsTabChange"
              :pricePointsTab="pricePointsTab" :webhook="webhook"
              :webhookPricePointsErrorTabs="webhookPricePointsErrorTabs" :validator="$v" />


            <memberships v-if="dataSharingTab == 'memberships'" :pricePointsTabs="pricePointsTabs"
              :handlePricePointsTabChange="handlePricePointsTabChange" :pricePointsTab="pricePointsTab"
              :membership="membership" :showPostSaleMembershipBox="showPostSaleMembershipBox"
              :post_memberships="post_memberships" />

            <file v-if="dataSharingTab == 'file'" :pricePointsTabs="pricePointsTabs"
              :handlePricePointsTabChange="handlePricePointsTabChange" :pricePointsTab="pricePointsTab" :file="file"
              :membership_files="membership_files" :showFileMembershipBox="showFileMembershipBox" />

            <automations v-if="dataSharingTab == 'automations'" :pricePointsTabs="pricePointsTabs"
              :handlePricePointsTabChange="handlePricePointsTabChange" :pricePointsTab="pricePointsTab"
              :automations="automations" />


            <div class="flex flex-row-reverse w-full">
              <gd-button size="auto" variant="primary" class="mr-2" @click="savePromoOptions()">{{
                $t('frontend_save') }}</gd-button>
            </div>
          </div>
        </div>
        <add-membership-modal :isEditing="isEditing" :postMembershipId="postMembershipId"
          :post_memberships="post_memberships" :postdata="postdata" :post_access_levels="post_access_levels"
          :post_addbtn="post_addbtn" :postMembershipAccessLevelId="postMembershipAccessLevelId"
          :postaccesslevelmemberid="postaccesslevelmemberid" :addMembershipField="addMembershipField"
          @update-data="HandleUpdateData" />
        <add-membership-file-modal :isEditing="isEditing" :postMembershipFileId="postMembershipFileId"
          :membership_files="membership_files" :post_addbtn="post_addbtn" :addMembershipFile="addMembershipFile" />
        <add-new-post-sale-integration-modal :integrationType="integrationType"
          :addPostSaleIntegration="addPostSaleIntegration" :configMenuItemsUpdated="configMenuItemsUpdated" />


      </v-wait>
    </div>
  </div>
</template>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}
</style>

<script>
import Promo from '@/models/Promo';
import AffiliateLink from '@/models/AffiliateLink';
import Notify from '@groovepages/gd-ui-kit/notify';
import axios from 'axios';
import { required, minLength, url } from 'vuelidate/lib/validators';
import ConfigMenu from '@groovepages/gd-ui-kit/components/PluginMenu';
import AffiliateBonus from '@/models/AffiliateBonus';


import DropPixel from './DataSharing_partials/tabs/DropPixel';
import Integrations from './DataSharing_partials/tabs/Integrations';
import WebHook from './DataSharing_partials/tabs/WebHook';
import Memberships from './DataSharing_partials/tabs/MemberShips';
import File from './DataSharing_partials/tabs/File';
import Automations from './DataSharing_partials/tabs/Automations';
import AddMembershipModal from './DataSharing_partials/modals/AddMembershipModal';
import AddMembershipFileModal from './DataSharing_partials/modals/AddMembershipFileModal';
import AddNewPostSaleIntegrationModal from './DataSharing_partials/modals/AddNewPostSaleIntegrationModal';


export default {
  middleware: 'auth',
  layout: 'defaultForAffiliates',
  metaInfo() {
    return { title: 'Integrations For Bonus Delivery |  Post Sale Pixels' };
  },
  components: {
    ConfigMenu,
    DropPixel,
    Integrations,
    WebHook,
    Memberships,
    File,
    Automations,
    AddMembershipModal,
    AddMembershipFileModal,
    AddNewPostSaleIntegrationModal,

  },
  data() {
    return {
      funnelAffiliate: null,
      products: [],
      pricePointsTabs: [],
      pricePointsTab: '',
      pricePoints: [],
      selectedProduct: 0,
      main_product: null,
      product: null,
      drop_pixel: {
        price_points: [],
      },
      integration: {
        price_points: [],
      },
      webhook: {
        price_points: [],
      },
      membership: {
        price_points: [],
      },
      file: {
        price_points: [],
      },
      automations: {
        price_points: [],
      },
      post_memberships: [],
      selectedPricePointIndex: 0,
      integrationType: '',
      dataSharingTabs: [],
      dataSharingTab: '',
      post_addbtn: false,
      postdata: [],
      affiliateLinks: [],
      affiliateBonuses: [],
      affiliateBonusesTab: '',
      affiliateLinksTab: 'aff_links',
      bonuses: null,
      selectedFunnelId: 0,
      isEditing: false,
      postMembershipId: '',
      postMembershipAccessLevelId: '',
      post_access_levels: [],
      membershipdata: '',
      membership_files: [],
      postMembershipFileId: '',
      affiliateFunnelPromoOptions: null,
      affiliateFunnel: null,
      pluginMenuItems: [],
    };
  },
  validations: {
    webhook: {
      price_points: {
        required,
        minLength: minLength(1),
        $each: {
          ipnUrl: {
            $each: {
              link: {
                url,
                required,
              },
            },
          },
        },
      },
    },
  },
  computed: {
    productOptions() {
      return this.products.map(product => ({ value: product.id, text: product.name }));
    },
    webhookPricePointsErrorTabs() {
      const webhookPricePointsErrorTabs = [];
      let pricePointIndex;
      for (pricePointIndex in this.$v.webhook.price_points.$each.$iter) {
        if (this.$v.webhook.price_points.$each.$iter[pricePointIndex].$error) {
          webhookPricePointsErrorTabs.push(
            `data_sharing_product_price_point_${this.$v.webhook.price_points.$each.$iter[pricePointIndex].$model.id}`
          );
        }
      }

      return webhookPricePointsErrorTabs;
    },

    pricePointsErrorTabs() {
      return [];
    },



    dataSharingErrorTabs() {
      const dataSharingErrorTabs = [];

      if (this.$v.webhook.price_points.$error) {
        dataSharingErrorTabs.push('webhook');
      }

      return dataSharingErrorTabs;
    },
  },
  watch: {
    selectedProduct() {
      this.$loader.start('selectedProduct');
      if (this.selectedProduct > 0) {
        const pro = this.products.find(
          (product) => product.id == this.selectedProduct
        );
        this.product = pro;
        if (pro.price_points.length > 0) {
          this.pricePointsTabs = [];
          this.pricePointsTab = '';
          this.pricePoints = [];
          this.pricePoints = pro.price_points;
          this.drop_pixel.price_points = [];
          this.integration.price_points = [];
          this.webhook.price_points = [];
          this.file.price_points = [];
          this.membership.price_points = [];
          this.updatePricePointTabs(pro);
          if (this.pricePointsTab == '' && this.pricePointsTabs.length > 0) {
            this.pricePointsTab = `data_sharing_product_price_point_${this.pricePoints[0].id}`;
          }
        }
      }
      this.$loader.end('selectedProduct');
    },
  },
  async created() {
    this.$wait.start('affiliate-products');

    if (this.$route.params.funnel_id != undefined) {
      const product = await axios.post('/funnel-affiliate-products', {
        funnel_id: this.$route.params.funnel_id,
      });

      this.products = product.data.data;
      this.main_product = this.products.find((product) => product.is_main === 1);

      if (this.main_product != null) {
        const affiliateFunnel = await axios.post('user-funnel-affiliate', {
          funnel_id: this.$route.params.funnel_id,
        });


        this.affiliateFunnel = affiliateFunnel.data.data;
        this.affiliateFunnelPromoOptions = affiliateFunnel.data.data.promo_options;
        this.product = this.main_product;
        this.selectedProduct = this.main_product.id;

        const dataShareDetails = this.main_product.affiliate_data_share_details;

        const sharingTabsConfig = [
          { condition: dataShareDetails.affiliate_can_drop_pixel, title: this.$t('frontend_tracking_code'), value: 'drop_pixel' },
          { condition: dataShareDetails.affiliate_integration, title: this.$t('frontend_affiliate_promos_data_sharing'), value: 'integrations' },
          { condition: dataShareDetails.affiliate_bonus_webhook, title: this.$t('frontend_product_step8_webhook'), value: 'webhook' },
          { condition: dataShareDetails.affiliate_bonus_membership, title: this.$t('frontend_memeberships'), value: 'memberships' },
          { condition: dataShareDetails.affiliate_bonus_file, title: this.$t('frontend_file'), value: 'file' },
        ];

        this.dataSharingTabs = sharingTabsConfig
          .filter(tabConfig => tabConfig.condition)
          .map(({ title, value }) => ({ title, value }));


        if (this.dataSharingTab == '' && this.dataSharingTabs.length > 0) {
          this.dataSharingTab = this.dataSharingTabs[0].value;
        }
      }

      const memberAppTitle = this.whitelabelAppData('member').title.toLowerCase();
      const groovememberUrl = window.config.apiUrl.substr(0, window.config.apiUrl.lastIndexOf('/'));
      const result = await axios.get(`${groovememberUrl}/${memberAppTitle}/memberships`);
      this.post_memberships = result.data;
      const fileResult = await axios.get(`${groovememberUrl}/${memberAppTitle}/membership/file`);
      this.membership_files = fileResult.data.data;
    }
    this.$wait.end('affiliate-products');
  },
  async mounted() { },
  methods: {
    HandleUpdateData(val) {
      if (this[val.key]) this[val.key] = val.value;
    },
    async updatePricePointTabs(product) {
      product.price_points.forEach((element) => {
        this.pricePointsTabs.push({
          title: element.name,
          value: `data_sharing_product_price_point_${element.id}`,
        });
      });

      if (this.affiliateFunnelPromoOptions != null && this.affiliateFunnelPromoOptions.product_id) {
        const keys = Object.keys(this.affiliateFunnelPromoOptions.product_id);
        const product_promo_option = keys.find((key) => key == product.id);

        if (product_promo_option != undefined) {
          this.updateEmptyPricePointsTabs();



          for (let i = 0; i < this.affiliateFunnelPromoOptions.product_id[product_promo_option].drop_pixel.price_points.length; i++) {
            this.drop_pixel.price_points[i].tracking_code = this.affiliateFunnelPromoOptions.product_id[product_promo_option].drop_pixel.price_points[i].tracking_code;
          }

          for (let i = 0; i < this.affiliateFunnelPromoOptions.product_id[product_promo_option].webhook.price_points.length; i++) {
            for (let j = 0; j < this.affiliateFunnelPromoOptions.product_id[product_promo_option].webhook.price_points[i].ipnUrl.length; j++) {
              const ipnURLArray = this.affiliateFunnelPromoOptions.product_id[product_promo_option].webhook.price_points[i].ipnUrl[j];
              const ipn = {
                link: ipnURLArray.link,
                type: ipnURLArray.type,
              };
              this.webhook.price_points[i].ipnUrl.push(ipn);
            }
          }

          for (let i = 0; i < this.affiliateFunnelPromoOptions.product_id[product_promo_option].integration.price_points.length; i++) {
            for (let j = 0; j < this.affiliateFunnelPromoOptions.product_id[product_promo_option].integration.price_points[i].affiliate_integration.length; j++) {
              const int = {
                platform: this.affiliateFunnelPromoOptions.product_id[product_promo_option].integration.price_points[i].affiliate_integration[j].platform,
                config: this.affiliateFunnelPromoOptions.product_id[product_promo_option].integration.price_points[i].affiliate_integration[j].config,
                id: this.affiliateFunnelPromoOptions.product_id[product_promo_option].integration.price_points[i].affiliate_integration[j].id,
              };
              this.integration.price_points[i].affiliate_integration.push(int);
            }
          }

          //
          for (let i = 0; i < this.affiliateFunnelPromoOptions.product_id[product_promo_option].membership.price_points.length; i++) {
            for (let j = 0; j < this.affiliateFunnelPromoOptions.product_id[product_promo_option].membership.price_points[i].memberships.length; j++) {
              const mem = {
                secret_key: this.affiliateFunnelPromoOptions.product_id[product_promo_option].membership.price_points[i].memberships[j].secret_key,
                membership_id: this.affiliateFunnelPromoOptions.product_id[product_promo_option].membership.price_points[i].memberships[j].membership_id,
                id: this.affiliateFunnelPromoOptions.product_id[product_promo_option].membership.price_points[i].memberships[j].id,
              };
              this.membership.price_points[i].memberships.push(mem);
            }
          }

          for (let i = 0; i < this.affiliateFunnelPromoOptions.product_id[product_promo_option].file.price_points.length; i++) {
            for (let j = 0; j < this.affiliateFunnelPromoOptions.product_id[product_promo_option].file.price_points[i].file_memberships.length; j++) {
              const fl = {
                file_id: this.affiliateFunnelPromoOptions.product_id[product_promo_option].file.price_points[i].file_memberships[j].file_id,
                id: this.affiliateFunnelPromoOptions.product_id[product_promo_option].file.price_points[i].file_memberships[j].id,
              };
              this.file.price_points[i].file_memberships.push(fl);
            }
          }


        } else {
          this.updateEmptyPricePointsTabs();
        }
      } else {
        this.updateEmptyPricePointsTabs();
      }
    },

    addPostSaleIntegration(data) {
      const integrationConfig = data.config;
      const self = this;

      const integrationInfo = [];

      for (const index in integrationConfig) {
        const value = integrationConfig[index];

        if (value) {
          self.pluginMenuItems.forEach((menuValue, menuIndex) => {
            if (menuValue.key == index) {
              const { options } = menuValue;

              const objChecker = options.some((val) => {
                return typeof val === 'object';
              });

              let correspondingOption = value;
              if (objChecker) {
                correspondingOption = options.find((o) => o.value === value);
              }
              integrationInfo.push({
                label: menuValue.label,
                name: objChecker ? correspondingOption.text : value,
                index: index,
                value: value

              });
            }
          });
        }
      }

      const completeIntegrationInfo = {};
      completeIntegrationInfo.platform = this.integrationType.split('-')[0];
      completeIntegrationInfo.config = integrationInfo;

      if (this.integration.price_points[this.selectedPricePointIndex].affiliate_integration) {
        this.integration.price_points[this.selectedPricePointIndex].affiliate_integration.push(completeIntegrationInfo);
      } else {
        this.integration.price_points[this.selectedPricePointIndex].affiliate_integration = [];
        this.integration.price_points[this.selectedPricePointIndex].affiliate_integration.push(completeIntegrationInfo);
      }

      this.$root.$emit('modal-close', 'add-new-post-sale-integration-modal');
    },

    configMenuItemsUpdated(data) {
      this.pluginMenuItems = data.menuItems;
    },



    showPostSaleIntegrationBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-new-post-sale-integration-modal');
    },

    updateEmptyPricePointsTabs() {
      this.pricePoints.forEach((priceP) => {
        this.drop_pixel.price_points.push({
          id: priceP.id,
          name: priceP.name,
          tracking_code: '',
        });
        this.integration.price_points.push({
          id: priceP.id,
          name: priceP.name,
          affiliate_integration: [],
        });
        this.webhook.price_points.push({
          id: priceP.id,
          name: priceP.name,
          ipnUrl: [],
        });
        this.membership.price_points.push({
          id: priceP.id,
          name: priceP.name,
          memberships: [],
        });
        this.file.price_points.push({
          id: priceP.id,
          name: priceP.name,
          file_memberships: [],
        });
      });
    },

    showPostSaleMembershipBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-membership-modal');
    },


    postaccesslevelmemberid() {
      const selectedPostMembership = this.post_memberships.find((ele) => {
        return ele.id === this.postMembershipId;
      });
      const selectedAccessLevel = this.post_access_levels.find((ele) => {
        return ele.secret_key === this.postMembershipAccessLevelId;
      });
      if (selectedAccessLevel) {
        this.membershipdata = {
          membership_id: this.postMembershipId,
          secret_key: this.postMembershipAccessLevelId,
        };
        if (this.isEditing == false) {
          this.postdata.push(this.membershipdata);
        }

        this.post_addbtn = true;
      } else {
        this.postdataname = '';
        this.post_addbtn = false;
      }
    },



    handlePricePointsTabChange(newTab) {
      this.pricePointsTab = newTab;
    },

    handleDataSharingTabChange(newTab) {
      this.dataSharingTab = newTab;
    },

    async fetchAffiliateLinks(id) {
      this.$loader.start('fetchAffiliateLinks');
      this.affiliateLinks = await AffiliateLink.params({
        funnel_id: id,
      }).$get();
      this.selectedFunnelId = id;
      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },

    showFileMembershipBox(index) {
      this.selectedPricePointIndex = index;
      this.integrationType = '';
      this.$root.$emit('modal-open', 'add-membership-file-modal');
    },

    addMembershipField() {
      const completeMembershipInfo = {};
      completeMembershipInfo.membership_id = this.postMembershipId;
      completeMembershipInfo.secret_key = this.postMembershipAccessLevelId;
      completeMembershipInfo.id =
        this.membership.price_points[this.selectedPricePointIndex].id;

      if (this.membership.price_points[this.selectedPricePointIndex].memberships) {
        this.membership.price_points[this.selectedPricePointIndex].memberships.push(completeMembershipInfo);
      } else {
        this.membership.price_points[this.selectedPricePointIndex].memberships = [];
        this.membership.price_points[this.selectedPricePointIndex].memberships.push(completeMembershipInfo);
      }

      this.$root.$emit('modal-close', 'add-membership-modal');
    },
    addMembershipFile() {
      if (this.postMembershipFileId) {
        const completeMemberFileInfo = {};
        completeMemberFileInfo.file_id = this.postMembershipFileId;
        completeMemberFileInfo.id = this.file.price_points[this.selectedPricePointIndex].id;
        if (this.file.price_points[this.selectedPricePointIndex].file_memberships) {
          this.file.price_points[this.selectedPricePointIndex].file_memberships.push(completeMemberFileInfo);
        } else {
          this.file.price_points[this.selectedPricePointIndex].file_memberships = [];
          this.file.price_points[this.selectedPricePointIndex].file_memberships.push(completeMemberFileInfo);
        }
        this.$root.$emit('modal-close', 'add-membership-file-modal');
      }
    },

    async fetchAffiliateBonuses(id) {
      this.$loader.start('fetchAffiliateBonuses');
      this.affiliateBonuses = await AffiliateBonus.params({
        funnel_id: id,
      }).$get();

      if (this.affiliateBonuses.length > 0) {
        this.affiliateBonusesTab = `affiliate_bonus_${this.affiliateBonuses[0].product_id}`;
      }

      this.$loader.end('fetchAffiliateBonuses');
      this.$root.$emit('modal-open', 'affiliate-bonuses-modal');
    },


    async savePromoOptions() {
      this.$v.$touch();
      if (!this.$v.webhook.$invalid && this.product != null) {
        this.$loader.start('savePromoOptions');
        const promoOptions = {
          drop_pixel: this.drop_pixel,
          integration: this.integration,
          webhook: this.webhook,
          membership: this.membership,
          file: this.file,
        };
        if (this.affiliateFunnelPromoOptions != null && this.affiliateFunnelPromoOptions.product_id) {
          this.affiliateFunnelPromoOptions.product_id[this.product.id] = promoOptions;
        } else {
          const tempProduct = {};
          tempProduct.product_id = {};
          tempProduct.product_id[this.product.id] = promoOptions;
          this.affiliateFunnelPromoOptions = tempProduct;
        }

        const affiliateFunnel = await axios.post('create-promo-options', {
          promo_options: this.affiliateFunnelPromoOptions,
          affiliate_funnel_id: this.affiliateFunnel.id,
        });
        if (affiliateFunnel.data.status == 'success') {
          this.affiliateFunnelPromoOptions =
            affiliateFunnel.data.data.promo_options;
        }
        this.$loader.end('savePromoOptions');

      }
    },

    onCopy() {
      Notify.success(this.$t('frontend_aff_link_copied'));
    },

    onError() {
      Notify.fail(this.$t('frontend_aff_link_unable_copy'));
    },

    dashboard(id) {
      this.$router.push({ name: 'promos_dashboard', params: { id } });
    },
    dataSharing(id) {
      this.$router.push({ name: 'promos_dashboard', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#promos-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>

<style>
.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>
