<template>
  <datetime
    :type="picker"
    :value="pickerValue"
    :format="format"
    value-zone="America/New_York"
    zone="America/New_York"
    :minute-step="30"
    use12-hour
    class="w-full relative h-10 border border-solid rounded-lg gp-datetime-picker"
    :input-class="inputClass"
    :class="{
      'text-white border-grey-700 bg-grey-800': hasState('dark'),
      'text-grey-600 border-grey-200 bg-white': !hasState('dark'),
      'text-sm': size === 'small'
    }"
    @input="update"
  >
    <template slot="button-cancel">
      <gd-button variant="outline">{{ $t('frontend_cancel') }}</gd-button>
    </template>
    <template slot="button-confirm" slot-scope="scope">
      <gd-button variant="primary">{{ scope.step !== 'time' ? 'Next' : 'OK' }}</gd-button>
    </template>
  </datetime>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import mixins from '@groovepages/gd-ui-kit/mixins';
import GdButton from '@groovepages/gd-ui-kit/components/Button';
export default {
  name: 'DateTimePicker',
  components: {
    Datetime,
    GdButton
  },
  mixins: [mixins],
  props: {
    size: {},
    value: {},
    dark: {},
    picker: {
      default: 'datetime'
    }
  },
  computed: {
    format() {
      if (this.picker === 'datetime') return 'yyyy-MM-dd HH:mm';
      if (this.picker === 'time') return 'HH:mm';
      else return 'yyyy-MM-dd';
    },
    inputClass() {
      let classes = 'w-full h-full outline-none rounded-lg px-4 ';
      if (this.hasState('dark')) classes += 'bg-grey-800';
      else classes += 'bg-white';
      return classes;
    },
    pickerValue() {
      return this.value;
    }
  },
  methods: {
    update(e) {
      this.$emit('input', e);
      this.$emit('change', e);
    }
  }
};
</script>
