<template>
    <div>
        <div class="flex flex-col w-full bg-white rounded">
            <div class="flex flex-row">
                <div class="flex flex-col w-1/2">
                    <div class="relative w-full flex flex-col justify-start">
                        <gd-tabs :tabs="getLookAndFeelTabs" :current-tab="currentLookAndFeelTab"
                            :disable-not-completed="false" :wrapper-class="'px-0 gs-default-tabs'" :tab-class="mainTabClass"
                            :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                            :line-class="'gs-default-tabs-active-line'" @onClick="handleLookFeelTabChange" />
                    </div>

                    <div v-if="currentLookAndFeelTab == 'template'" class="mt-6">
                        <div class="flex flex-row">
                            <div class="flex flex-col w-full mr-20">
                                <div class="flex flex-col w-full mb-4">
                                    <p class="font-semibold">
                                        {{ $t('frontend_select_template') }}
                                    </p>
                                    <div class="flex flex-row w-full mt-2">
                                        <div v-for="(templateType, index) in templateTypes" :key="index" class="w-1/3 mr-4">
                                            <checkout-template-radio
                                                v-model="localModel.checkout_page_options.look_and_feel.template_type"
                                                :option="templateType"></checkout-template-radio>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full"
                                    v-if="localModel.checkout_page_options.look_and_feel.template_type == 4">
                                    <div class="flex flex-row">
                                        <p class="flex flex-col w-1/3 font-semibold mb-2">
                                            {{ $t('frontend_primary_color') }}
                                        </p>
                                        <p class="flex flex-col w-1/3 font-semibold mb-2 ml-10">
                                            {{ $t('frontend_secondary_color') }}
                                        </p>
                                    </div>

                                    <!-- {{localModel.secondary_color}} -->
                                    <div class="flex flex-row mb-8">
                                        <div class="flex flex-col w-1/3" :class="{
                                            'pointer-events-none': localModel.checkout_page_options.look_and_feel.template_type != 4
                                        }">
                                            <color-picker v-model="localModel.primary_color"
                                                data-cy="localModel.primary_color"
                                                class="border-2 w-24 border-grey-300 rounded" :colors="colors"
                                                :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
                                                @change="changedLogoBG(1)"></color-picker>
                                        </div>
                                        <div class="flex flex-col w-1/3 ml-10" :class="{
                                            'pointer-events-none':
                                                localModel.checkout_page_options.look_and_feel.template_type != 4
                                        }">
                                            <color-picker data-cy="localModel.secondary_color"
                                                v-model="localModel.secondary_color"
                                                class="border-2 w-24 border-grey-300 rounded" :colors="colors"
                                                :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
                                                @change="changedLogoBG(2)"></color-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="currentLookAndFeelTab == 'product_image'" class="mt-6">
                        <div class="flex flex-row">
                            <div class="flex flex-col w-full mr-20">
                                <div class="flex flex-row justify-start pr-2 mb-4">
                                    <gd-toggle class="mr-2" v-model="localModel.disable_branding_bar"
                                        :value="localModel.disable_branding_bar" />
                                    <label class="mr-4">{{
                                        $t('frontend_product_disable_branding_bar')
                                    }}</label>
                                </div>

                                <div class="flex flex-col mb-1" v-if="!localModel.disable_branding_bar">
                                    <p class="font-semibold mb-1">
                                        {{ $t('frontend_product_step5_upload') }}
                                    </p>
                                    <div class="w-1/2">
                                        <image-picker v-model="localModel.checkout_page_options.product_image"
                                            :primary="true"></image-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentLookAndFeelTab == 'product_banner'" class="mt-6">
                        <div class="flex flex-row">
                            <div class="flex flex-col w-full mr-20">
                                <div class="flex flex-col mb-1">
                                    <p class="font-semibold mb-1">
                                        {{ $t('frontend_product_step5_upload_banner') }}
                                    </p>
                                    <div class="w-1/2">
                                        <image-picker v-model="localModel.checkout_page_options.product_banner"
                                            :primary="true"></image-picker>
                                    </div>
                                    <span class="text-sm text-grey-600">Tamanho Recomendado: 1440px x 400px</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentLookAndFeelTab == 'customization'" class="mt-6">
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_font_family') }}
                                    </label>
                                </div>
                                <div class="w-1/3">
                                    <gd-dropdown
                                        v-model="localModel.product_customization_details.customization_details.font_family"
                                        size="full"
                                        :options="fontFamiliesOptions" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_heading_color') }}
                                    </label>
                                </div>
                                <div class="w-1/3">
                                    <color-picker
                                        data-cy="localModel.product_customization_details.customization_details.heading_color"
                                        v-model="localModel.product_customization_details.customization_details.heading_color"
                                        class="w-24 border-2 border-grey-300 rounded" :colors="colors"
                                        :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
                                        @change="changedHeadingColor()"></color-picker>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_heading_size')
                                    }}</label>
                                </div>
                                <div class="w-1/3">
                                    <gd-dropdown
                                        v-model="localModel.product_customization_details.customization_details.heading_size"
                                        size="full"
                                        :options="sizesOptions"
                                        @change="emitChangedHeadingSize(localModel.product_customization_details.customization_details.heading_size)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_font_color') }}</label>
                                </div>
                                <div class="w-1/3">
                                    <color-picker
                                        data-cy="localModel.product_customization_details.customization_details.lable_color"
                                        v-model="localModel.product_customization_details.customization_details.lable_color"
                                        class="w-24 border-2 border-grey-300 rounded" :colors="colors"
                                        :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
                                        @change="changedLabelColor()"></color-picker>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_form_field_size')
                                    }}</label>
                                </div>
                                <div class="w-1/3">
                                    <gd-dropdown
                                        v-model="localModel.product_customization_details.customization_details.input_size"
                                        size="full"
                                        :options="sizesOptions"
                                        @change="emitChangedInputSize(localModel.product_customization_details.customization_details.input_size)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_button_size')
                                    }}</label>
                                </div>
                                <div class="w-1/3">
                                    <gd-dropdown
                                        v-model="localModel.product_customization_details.customization_details.button_size"
                                        size="full"
                                        :options="sizesOptions"
                                        @change="emitChangedButtonSize(localModel.product_customization_details.customization_details.button_size)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex flex-row w-full justify-start mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_full_width_button')
                                    }}</label>
                                </div>
                                <div class="w-1/3">
                                    <gd-toggle
                                        v-model="localModel.product_customization_details.customization_details.button_full_width" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentLookAndFeelTab == 'button_text'" class="mt-6">
                        <div class="flex flex-col w-4/5 pr-2">
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_continue_to_billing')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.continue_to_billing" />
                                </div>
                            </div>
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_continue_to_password')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.continue_to_password" />
                                </div>
                            </div>
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_continue_to_payment')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.continue_to_payment" />
                                </div>
                            </div>
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_continue_to_order')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.continue_to_order" />
                                </div>
                            </div>
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_complete_purchase')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.complete_purchase" />
                                </div>
                            </div>
                            <div class="flex flex-row justify-start pr-2 mb-4">
                                <div class="w-1/3">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_get_free_access')
                                    }}</label>
                                </div>

                                <div class="w-2/3">
                                    <text-input :placeholder="''"
                                        v-model="localModel.product_customization_details.button_text_details.get_free_access" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-1/2">
                    <div class="flex flex-row">
                        <div class="text-center w-full">
                            <p class="font-semibold">{{ $t('frontend_preview') }}</p>
                        </div>
                    </div>
                    <div class="flex flex-row" :style="[customColor]">
                        <div class=" custom-font-family flex flex-row w-full p-2 custom-template-color-primary"
                            v-if="!localModel.disable_branding_bar">
                            <div class="mr-6">
                                <div class="flex justify-center">
                                    <div class=" rounded-full h-16 w-16 bg-white flex items-center justify-center">
                                        <img alt="" v-if="ProductHasImage"
                                            class=" rounded-full h-16 w-24 flex items-center justify-center"
                                            :src="localModel.checkout_page_options.product_image" />
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-12" viewBox="0 0 185 185">
                                            <defs>
                                                <filter id="a" width="102%" height="106.4%" x="-1%" y="-3.2%"
                                                    filterUnits="objectBoundingBox">
                                                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1"
                                                        stdDeviation="2" />
                                                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                                    <feMerge>
                                                        <feMergeNode in="shadowMatrixOuter1" />
                                                        <feMergeNode in="SourceGraphic" />
                                                    </feMerge>
                                                </filter>
                                            </defs>
                                            <g fill="var(--brand-primary)" fill-rule="evenodd" filter="url(#a)"
                                                transform="translate(-770 -99)">
                                                <path
                                                    d="M863.4 190.987H951c-3.755 40.686-22.4 67.525-55.933 80.517-33.533 12.992-65.212 6.496-95.038-19.488l31.303-30.036c11.38 8.342 22.07 12.514 32.068 12.514 9.998 0 19.344-3.828 28.037-11.482L863.4 190.987zM774 188.756h44.917c1.773-22.003 12.249-35.808 31.427-41.414 19.178-5.606 35.347.169 48.509 17.324l35.042-28.598c-29.51-33.005-63.616-42.712-102.32-29.123-35.752 12.553-54.944 39.823-57.575 81.811z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full">
                                <div class="flex">
                                    <p class="text-xl custom-template-color-secondary-text">
                                        {{ localModel.name }}
                                    </p>
                                </div>
                                <div class="flex mt-2">
                                    <p class="text-sm custom-template-color-secondary-text">
                                        {{ localModel.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row w-full" :style="[customColor]">
                        <div class="custom-font-family w-full h-full flex flex-col">
                            <div class="flex flex-col">
                                <div class="flex mt-4">
                                    <div class="w-full">
                                        <p class="font-semibold custom-heading-size custom-heading-color">
                                            {{ $t('frontend_account_information') }}
                                        </p>
                                    </div>
                                </div>
                                <div class="flex flex-row mt-4 preview">
                                    <div class="w-1/2 mr-2">
                                        <label class=" custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">
                                            {{ $t('frontend_first_name') }}
                                        </label>
                                        <text-input class="custom-input-height" :readonly="true" :placeholder="$t('frontend_first_name')" />
                                    </div>
                                    <div class="w-1/2">
                                        <label class="custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">
                                            {{ $t('frontend_last_name') }}
                                        </label>
                                        <text-input class="custom-input-height" :readonly="true" :placeholder="$t('frontend_last_name')" />
                                    </div>
                                </div>
                                <div class="flex flex-row mt-4 preview">
                                    <div class="w-full">
                                        <label class="custom-label-size font-semibold custom-lable-color text-sm text-grey-700 mb-2">
                                            Email
                                        </label>
                                        <text-input class="custom-input-height" :readonly="true" placeholder="Email" />
                                    </div>
                                </div>
                                <div
                                    class="py-4 flex justify-center"
                                    :class="{ 'px-4': !localModel.product_customization_details.customization_details.button_full_width }"
                                >
                                    <gd-button
                                        variant="primary"
                                        size="expand"
                                        class="custom-template-color-primary custom-template-color-secondary-text border-none"
                                    >
                                        {{ $t('frontend_complete_purchase') }}
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import CustomCheckoutPage from '@/assets/images/pages/custom-checkout-page-template.svg';
import PinkCheckoutPage from '@/assets/images/pages/default-checkout-page-template.svg';
import GreyCheckoutPage from '@/assets/images/pages/grey-checkout-page-template.svg';
import BlueCheckoutPage from '@/assets/images/pages/blue-checkout-page-template.svg';
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';
import ImagePicker from '@groovepages/gd-ui-kit/components/ImagePickerModal';

export default {

    components: {
        ColorPicker,
        ImagePicker,
    },
    props: {
        model: {
            required: true
        },
        customColor: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            localModel: this.model,
            fontFamilies: [
                {
                    id: 1,
                    value: 'Abril Fatface',
                },
                {
                    id: 2,
                    value: 'Amatic SC',
                },
                {
                    id: 3,
                    value: 'Architects Daughter',
                },
                {
                    id: 4,
                    value: 'Asap',
                },
                {
                    id: 5,
                    value: 'Balsamiq Sans',
                },
                {
                    id: 6,
                    value: 'Barlow',
                },
                {
                    id: 7,
                    value: 'Bebas Neue',
                },
                {
                    id: 8,
                    value: 'Bitter',
                },
                {
                    id: 9,
                    value: 'Cabin',
                },
                {
                    id: 10,
                    value: 'Cairo',
                },
                {
                    id: 11,
                    value: 'Cormorant Garamond',
                },
                {
                    id: 12,
                    value: 'Crimson Text',
                },
                {
                    id: 13,
                    value: 'Dancing Script',
                },
                {
                    id: 14,
                    value: 'Fira Sans',
                },
                {
                    id: 15,
                    value: 'Fjalla One',
                },
                {
                    id: 16,
                    value: 'Indie Flower',
                },
                {
                    id: 17,
                    value: 'Josefin Sans',
                },
                {
                    id: 18,
                    value: 'Lato',
                },
                {
                    id: 19,
                    value: 'Libre',
                },
                {
                    id: 20,
                    value: 'Libre Franklin',
                },
                {
                    id: 21,
                    value: 'Lobster',
                },
                {
                    id: 22,
                    value: 'Lora',
                },
                {
                    id: 23,
                    value: 'Martel',
                },
                {
                    id: 24,
                    value: 'Merriweather',
                },
                {
                    id: 25,
                    value: 'Montserrat',
                },
                {
                    id: 26,
                    value: 'Mukta',
                },
                {
                    id: 27,
                    value: 'Noto+Sans',
                },
                {
                    id: 28,
                    value: 'Noto+Sans+KR',
                },
                {
                    id: 29,
                    value: 'Noto Sans',
                },
                {
                    id: 30,
                    value: 'Noto Serif',
                },
                {
                    id: 31,
                    value: 'Nunito Sans',
                },
                {
                    id: 32,
                    value: 'Nunito',
                },
                {
                    id: 33,
                    value: 'Old Standard TT',
                },
                {
                    id: 34,
                    value: 'Open Sans Condensed',
                },
                {
                    id: 35,
                    value: 'Open Sans',
                },
                {
                    id: 36,
                    value: 'Oswald',
                },
                {
                    id: 37,
                    value: 'Overpass',
                },
                {
                    id: 38,
                    value: 'Oxygen',
                },
                {
                    id: 39,
                    value: 'PT Sans Narrow',
                },
                {
                    id: 40,
                    value: 'PT Sans',
                },
                {
                    id: 41,
                    value: 'PT Serif',
                },
                {
                    id: 42,
                    value: 'Pacifico',
                },
                {
                    id: 43,
                    value: 'Playfair Display',
                },
                {
                    id: 44,
                    value: 'Poppins',
                },
                {
                    id: 45,
                    value: 'Raleway',
                },
                {
                    id: 46,
                    value: 'Roboto Condensed',
                },
                {
                    id: 47,
                    value: 'Roboto Slab',
                },
                {
                    id: 48,
                    value: 'Roboto',
                },
                {
                    id: 49,
                    value: 'Rubik',
                },
                {
                    id: 50,
                    value: 'Shadows Into Light',
                },
                {
                    id: 51,
                    value: 'Signika',
                },
                {
                    id: 52,
                    value: 'Slabo 27px',
                },
                {
                    id: 53,
                    value: 'Source Code Pro',
                },
                {
                    id: 54,
                    value: 'Source Sans',
                },
                {
                    id: 55,
                    value: 'Source Serif Pro',
                },
                {
                    id: 56,
                    value: 'Tajawal',
                },
                {
                    id: 57,
                    value: 'Titillium Web',
                },
                {
                    id: 58,
                    value: 'Ubuntu',
                },
                {
                    id: 59,
                    value: 'Work Sans',
                },
            ],
            sizes: [
                {
                    id: 1,
                    title: 'Small',
                    value: 'small',
                },
                {
                    id: 2,
                    title: 'Regular',
                    value: 'regular',
                },
                {
                    id: 3,
                    title: 'Large',
                    value: 'large',
                },
            ],
            colors: {
                'primary-darkest': 'hsla(213, 90%, 10%, 1.00)',
                'primary-darker': 'hsla(213, 95%, 25%, 1.00)',
                'primary-dark': 'hsla(213, 95%, 35%, 1.00)',
                primary: 'hsla(213, 100%, 59%, 1.00)',
                'primary-light': 'hsla(213, 100%, 70%, 1.00)',
                'primary-lighter': 'hsla(213, 100%, 83%, 1.00)',
                'primary-lightest': 'hsla(213, 100%, 93%, 1.00)',

                'secondary-darkest': 'hsla(342, 55%, 10%, 1.00)',
                'secondary-darker': 'hsla(342, 65%, 25%, 1.00)',
                'secondary-dark': 'hsla(342, 65%, 38%, 1.00)',
                secondary: 'hsla(342, 75%, 57%, 1.00)',
                'secondary-light': 'hsla(342, 75%, 65%, 1.00)',
                'secondary-lighter': 'hsla(342, 75%, 80%, 1.00)',
                'secondary-lightest': 'hsla(342, 85%, 93%, 1.00)',

                'grey-darkest': 'hsla(213, 40%, 10%, 1.00)',
                'grey-darker': 'hsla(213, 30%, 18%, 1.00)',
                'grey-dark': 'hsla(202, 20%, 28%, 1)',
                grey: 'hsla(213, 15%, 54%, 1.00)',
                'grey-light': 'hsla(213, 15%, 72%, 1.00)',
                'grey-lighter': 'hsla(213, 15%, 85%, 1.00)',
                'grey-lightest': 'hsla(213, 15%, 95%, 1.00)',

                'red-darkest': 'hsla(0, 90%, 12%, 1.00)',
                'red-darker': 'hsla(0, 80%, 25%, 1.00)',
                'red-dark': 'hsla(0, 80%, 48%, 1.00)',
                red: 'hsla(0, 99%, 64%, 1.00)',
                'red-light': 'hsla(0, 99%, 71%, 1.00)',
                'red-lighter': 'hsla(0, 99%, 83%, 1.00)',
                'red-lightest': 'hsla(0, 99%, 93%, 1.00)',

                'green-darkest': 'hsla(143, 69%, 10%, 1.00)',
                'green-darker': 'hsla(143, 79%, 20%, 1.00)',
                'green-dark': 'hsla(143, 79%, 30%, 1.00)',
                green: 'hsla(143, 79%, 45%, 1.00)',
                'green-light': 'hsla(143, 75%, 55%, 1.00)',
                'green-lighter': 'hsla(143, 75%, 70%, 1.00)',
                'green-lightest': 'hsla(143, 80%, 80%, 1.00)',

                'yellow-darkest': 'hsla(20, 90%, 20%, 1.00)',
                'yellow-darker': 'hsla(30, 100%, 30%, 1.00)',
                'yellow-dark': 'hsla(40, 100%, 40%, 1.00)',
                yellow: 'hsla(46, 100%, 57%, 1.00)',
                'yellow-light': 'hsla(46, 95%, 69%, 1.00)',
                'yellow-lighter': 'hsla(46, 95%, 79%, 1.00)',
                'yellow-lightest': 'hsla(46, 90%, 92%, 1.00)',

                purple: 'hsla(227, 49%, 55%, 1.00)',
                indigo: 'hsla(225, 63%, 49%, 1.00)',
                blue: 'hsla(209, 63%, 43%, 1.00)',
            },
            currentLookAndFeelTab: 'template',
            templateTypes: [
                { id: 1, title: this.$t('frontend_groovesell_products_step5_templ_types_1'), icon: PinkCheckoutPage },
                { id: 2, title: this.$t('frontend_groovesell_products_step5_templ_types_2'), icon: BlueCheckoutPage },
                { id: 3, title: this.$t('frontend_groovesell_products_step5_templ_types_3'), icon: GreyCheckoutPage },
                { id: 4, title: this.$t('frontend_groovesell_products_step5_templ_types_4'), icon: CustomCheckoutPage },
            ],
            lookAndFeelTabs: [
                {
                    title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t('frontend_template')}<span>`,
                    value: 'template',
                    show: true
                },
                {
                    title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t('frontend_product_image')}<span>`,
                    value: 'product_image',
                    show: true
                },
                {
                    title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t('frontend_product_banner_image')}<span>`,
                    value: 'product_banner',
                    show: false
                },
                {
                    title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t('frontend_customization')} <span>`,
                    value: 'customization',
                    show: true
                },
                {
                    title: `<span class="text-sm mr-2 ml-1 text-xs font-semibold">${this.$t('frontend_button_text')} <span>`,
                    value: 'button_text',
                    show: true
                },
            ],
        }
    },

    computed: {
        ProductHasImage() {
            return this.localModel.checkout_page_options.hasOwnProperty('product_image') &&
                this.localModel.checkout_page_options.product_image &&
                this.localModel.checkout_page_options.product_image != ''
        },
        mainTabClass() {
            return 'pb-4 gs-default-tabs-item';
        },
        getLookAndFeelTabs() {
            return this.lookAndFeelTabs.filter(tab => tab.show)
        },
        fontFamiliesOptions() {
            return this.fontFamilies.map(family => {
                return {
                    value: family.value,
                    text: family.value
                }
            })
        },
        sizesOptions() {
            return this.sizes.map(size => {
                return {
                    value: size.value,
                    text: size.title
                }
            })
        }
    },
    watch: {
        localModel: {
            handler: function (val, oldVal) {
                this.$emit('update-model', val);
            },
            deep: true
        },
    },
    methods: {
        handleLookFeelTabChange(newTab) {
            this.currentLookAndFeelTab = newTab;
        },
        changedLogoBG(type) {
            if (type == 1 && this.localModel.primary_color != '') {
                const values = this.localModel.primary_color
                    .replace(/rgba?\(/, '')
                    .replace(/\)/, '')
                    .replace(/[\s+]/g, '')
                    .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.primary_color = colorHex;
                this.$emit('update-attributes', {
                    key: 'primary_color',
                    value: colorHex,
                });
            }

            if (type == 2 && this.localModel.secondary_color != '') {
                const values = this.localModel.secondary_color
                    .replace(/rgba?\(/, '')
                    .replace(/\)/, '')
                    .replace(/[\s+]/g, '')
                    .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.secondary_color = colorHex;
                this.$emit('update-attributes', {
                    key: 'secondary_color',
                    value: colorHex,
                });
            }
        },
        changedHeadingColor() {
            if (this.localModel.product_customization_details.customization_details.heading_color != '') {
                const values =
                    this.localModel.product_customization_details.customization_details.heading_color
                        .replace(/rgba?\(/, '')
                        .replace(/\)/, '')
                        .replace(/[\s+]/g, '')
                        .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.product_customization_details.customization_details.heading_color = colorHex;
                this.$emit('update-attributes', {
                    key: 'heading_color',
                    value: colorHex,
                });
            }
        },
        changedLabelColor() {
            if (this.localModel.product_customization_details.customization_details.lable_color != '') {
                const values =
                    this.localModel.product_customization_details.customization_details.lable_color
                        .replace(/rgba?\(/, '')
                        .replace(/\)/, '')
                        .replace(/[\s+]/g, '')
                        .split(',');
                const colorHex = this.rgbToHex(
                    parseInt(values[0]),
                    parseInt(values[1]),
                    parseInt(values[2])
                );
                this.localModel.product_customization_details.customization_details.lable_color = colorHex;
                this.$emit('update-attributes', {
                    key: 'lable_color',
                    value: colorHex,
                });
            }
        },
        rgbToHex(r, g, b) {
            return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
        },
        componentToHex(c) {
            const hex = c.toString(16);
            return hex.length == 1 ? `0${hex}` : hex;
        },
        emitChangedButtonSize(val) {
            this.$emit('update-attributes', {
                key: 'button_size',
                value: val,
            });
        },
        emitChangedHeadingSize(val) {
            this.$emit('update-attributes', {
                key: 'heading_size',
                value: val,
            });
        },
        emitChangedInputSize(val) {
            this.$emit('update-attributes', {
                key: 'input_size',
                value: val,
            });
        },
    }
}

</script>