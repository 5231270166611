<template>
  <side-bar
    :key="$t('frontend_gduikit_sidebar_affiliate_home')"
    ref="sidebar"
    :origin="whitelabelAppData('sell').title"
    :logo="whitelabelAppData('sell').logo"
    :sections="sections"
    no-exit-button="true"
    @open="setSideOpen"
    @close="setSideClosed"
  >
    <template v-for="item in menuItems" :slot="item.name">
      <div class="text-sm">
        <ul class="cursor-pointer text-white list-reset p-4 blocks-container">
          <li
            class="mb-2 block-data relative focus:outline-none"
            v-for="(item, key) in item.items"
            :key="key"
            @click="sendTo(key)"
          >
            {{ $t(item) }}
          </li>
        </ul>
      </div>
    </template>
    <template slot="header">
      <div class="flex flex-col">
        <div>{{ whitelabelAppData('sell').title }}</div>
        <div>
          <div class="relative">
            <div
              class="
                inline-flex
                shadow-sm
                rounded-md
                divide-x divide-indigo-600
              "
            >
              <div
                class="
                  relative
                  z-0
                  inline-flex
                  shadow-sm
                  rounded-md
                  divide-x divide-indigo-600
                "
              >
                <div
                  class="
                    relative
                    inline-flex
                    items-center
                    bg-indigo-500
                    border border-transparent
                    rounded-l-md
                    shadow-sm
                    text-green
                    cursor-pointer
                  "
                  @click="openViewSwitcher = !openViewSwitcher"
                >
                  <p class="text-sm font-medium mr-1">{{ $t('frontend_vendor') }}</p>
                  <angle-down-icon class="w-3 h-3 text-white fill-current " />
                </div>
                <div
                  v-if="openViewSwitcher"
                  v-on-click-outside="() => (this.openViewSwitcher = false)"
                  class="
                    p-6
                    bg-secondary
                    rounded-lg
                    shadow-md
                    absolute
                    left-0
                    top-full
                    z-30
                    w-80
                    border-solid border border-grey-700
                    shadow
                    overflow-y-auto
                  "
                >
                  <div class="relative grid gap-2 bg-secondary">
                    <div class="">
                      <div class="text-secondary-lightest mb-2 text-xs">
                        {{ $t('frontend_groovesell_affiliate_menu_switch_to') }}
                      </div>
                      <a
                        class="
                          -mx-3
                          my-2
                          p-3
                          flex
                          rounded-lg
                          transition
                          ease-in-out
                          duration-150
                          bg-secondary-dark
                        "
                      >
                        <div class="flex flex-col">
                          <p class="text-sm font-semibold text-secondary-invert">
                            {{ $t('frontend_vendor') }}
                          </p>
                          <p class="text-xs text-secondary-lightest">
                            {{
                              $t(
                                'frontend_groovesell_affiliate_menu_vendor_desc'
                              )
                            }}
                          </p>
                        </div>
                        <div class="flex flex-col items-center">
                          <check-icon />
                        </div>
                      </a>
                      <a
                        class="
                          -mx-3
                          my-2
                          p-3
                          flex
                          rounded-lg
                          transition
                          ease-in-out
                          duration-150
                          cursor-pointer
                        "
                        @click="sendToAffiliatesDashboard"
                      >
                        <div class="flex flex-col">
                          <p class="text-sm font-semibold text-secondary-invert">
                            {{ $t('frontend_affiliate') }}
                          </p>
                          <p class="text-xs text-secondary-lightest">
                            {{
                              $t(
                                'frontend_groovesell_affiliate_menu_affiliate_desc'
                              )
                            }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </side-bar>
</template>

<script>
import SideBar from '@groovepages/gd-ui-kit/components/SideBarNew';
import DashboardIcon from '@/assets/images/icons/DashboardIcon.svg';
import CheckIcon from '@/assets/images/icons/Check.svg';
import AngleDownIcon from '@/assets/images/icons/AngleDownIcon.svg';
import FunnelIcon from '@/assets/images/icons/FunnelIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import ColumnChartIcon from '@/assets/images/icons/ColumnChartIcon.svg';
import CommentUserIcon from '@/assets/images/icons/CommentUserIcon.svg';
import SettingsIcon from '@/assets/images/icons/SettingsIcon.svg';
import ToolsIcon from '@/assets/images/icons/ToolsIcon.svg';
import MarketPlaceIcon from '@/assets/images/icons/MarketplaceIcon.svg';

export default {
  name: 'SidebarMenu',
  components: {
    SideBar,
    CheckIcon,
    AngleDownIcon
  },
  data() {
    return {
      currentOpenMenuItem: '',
      openViewSwitcher: false
    };
  },
  computed: {
    // eslint-disable-next-line
    menuItems() {
      return {
        dashboard: {
          name: this.$t('frontend_vendors_dashboard'),
          items: {
            dashboard:
              'frontend_gduikit_sidebar_affiliate_home_vendor_dashboard',
            affiliates_dashboard:
              'frontend_gduikit_sidebar_affiliate_home_afiiliate_dashboard'
          }
        },
        funnels: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_funnels'),
          items: {
            funnels: 'frontend_gduikit_sidebar_product_funnels',
            contracts: 'frontend_groovesell_sidebar_partner_contracts',
            distribution:
              'frontend_groovesell_sidebar_partner_contracts_distributions'
          }
        },
        affiliates: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_affiliates'),
          items: {
            affiliates: 'frontend_gduikit_sidebar_manage',
            affiliates_second_tier: 'frontend_second_tier_aff',
            affiliates_performance: 'frontend_aff_perf',
            payment_history: 'frontend_gduikit_sidebar_payment_history',
            leader_boards: 'frontend_leaderboards'
          }
        },
        customers: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_Customers'),
          items: {
            customers: 'frontend_gduikit_sidebar_customers',
            leads: 'frontend_gduikit_sidebar_cart'
          }
        },
        reports: {
          name: this.$t('frontend_gduikit_sidebar_affiliate_reporting'),
          items: {
            transactions: 'frontend_gduikit_sidebar_transaction',
            vendor_report: 'frontend_gduikit_sidebar_reports'
          }
        },
        tools: {
          name: this.$t('frontend_gduikit_sidebar_groovesell_tools'),
          items: {
            default_checkout_links: 'frontend_gduikit_sidebar_checkout_links',
            default_funnel_resources:
              'frontend_gduikit_sidebar_affiliate_portal',
            leader_boards: 'frontend_leaderboards',
            coupons: 'frontend_gduikit_sidebar_product_coupons',
            tracking: 'frontend_gduikit_sidebar_product_tracking',
            pageTracking: 'frontend_gduikit_sidebar_page_tracking'
          }
        }
      };
    },
    sections() {
      return [
        {
          name: this.$t('frontend_vendors_dashboard'),
          icon: DashboardIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_funnels'),
          icon: FunnelIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_affiliates'),
          icon: HandshakeIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_Customers'),
          icon: CommentUserIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_reporting'),
          icon: ColumnChartIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_groovesell_tools'),
          icon: ToolsIcon
        },
        {
          name: this.$t('frontend_gduikit_sidebar_affiliate_marketplace'),
          icon: MarketPlaceIcon,
          doNotOpen: 1
        },
        {
          name: this.$t('frontend_gduikit_sidebar_vendor_setting'),
          icon: SettingsIcon,
          doNotOpen: 1
        }
      ];
    }
  },
  methods: {
    sendTo(routeName) {
      this.$router.push({ name: routeName });
    },
    setSideOpen(side) {
      if (side === this.$t('frontend_gduikit_sidebar_affiliate_marketplace')) {
        this.sendToMarketplace();
        return;
      }
      if (side === this.$t('frontend_gduikit_sidebar_vendor_setting')) {
        this.sendToVendorSettings();
        return;
      }
      this.currentOpenMenuItem = side;
    },
    setSideClosed() {
      this.currentOpenMenuItem = '';
    },
    sendToAffiliatesDashboard() {
      this.$router.push({ name: 'affiliates_dashboard' });
    },
    sendToVendorSettings() {
      this.$router.push({ name: 'vendor_settings' });
    },
    sendToMarketplace() {
      this.$router.push({ name: 'marketplace' });
    }
  }
};
</script>
<style lang="scss">
#left_sidebar {
  .dashboard,
  .paineldecontrole {
    &.activeSideBar svg path {
      stroke: var(--brand-primary) !important;
    }
  }
  .activeSideBar svg path {
    fill: var(--brand-primary);
    stroke: var(--brand-primary);
  }
}
</style>
