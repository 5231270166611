<template>
  <gd-modal
    name="add-commission-advance-modal"
    :title="$t('frontend_groovesell_add_commision_advance')"
    bg-type="regular"
    @closed="onClose"
  >
    <div v-if="selectedAffiliate" class="flex">
      <div class="flex flex-col w-full px-4">
        <div class="mt-2">
          <!-- Amount Input -->
          <div class="w-full flex flex-row mb-6">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">{{ $t('frontend_affiliate_index_amount') }}</label>
            <div class="w-2/3">
              <gd-currency-input v-model="commissionAdvance.amount" currency-code="USD" />
            </div>
          </div>

          <!-- Transaction ID Input -->
          <div class="w-full flex flex-row mb-6">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">{{ $t('frontend_affiliate_index_transaction') }}</label>
            <text-input
              v-model="commissionAdvance.transactionId"
              :validator="$v.commissionAdvance.transactionId"
              :attribute="$t('frontend_affiliate_index_transaction_id')"
              class="w-2/3"
              :placeholder="$t('frontend_affiliate_index_transaction_id')"
            />
          </div>

          <!-- Comment Input -->
          <div class="w-full flex flex-row mb-6">
            <label class="w-1/3 mt-1 font-semibold text-grey-700">{{ $t('frontend_affiliate_index_comment') }}</label>
            <text-input
              v-model="commissionAdvance.comment"
              :validator="$v.commissionAdvance.comment"
              :attribute="$t('frontend_affiliate_index_comment')"
              class="w-2/3"
              :placeholder="$t('frontend_affiliate_index_comment')"
            />
          </div>

          <!-- Add Commission Advance Button -->
          <div class="flex flex-row w-full mb-6 justify-end">
            <gd-button size="auto" class="mb-4" variant="primary" @click="addCommissionAdvance">
              {{ $t('frontend_affiliate_index_add_commision_advance') }}
            </gd-button>
          </div>
        </div>
      </div>
    </div>
  </gd-modal>
</template>

<script>
import { required, maxLength, decimal, minValue } from 'vuelidate/lib/validators';
import AffiliatePayment from '@/models/AffiliatePayment';
import SecondTierAffiliate from '@/models/SecondTierAffiliate';

export default {
  props: {
    selectedAffiliate: {
      type: Object,
      default: null,
    },
    filtersModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      commissionAdvance: {
        amount: 0,
        comment: '',
        transactionId: '',
      },
    };
  },
  methods: {
    async addCommissionAdvance() {
      this.$v.commissionAdvance.$touch();
      if (this.$v.commissionAdvance.$invalid) return;

      this.$loader.start('addCommissionAdvance');
      const affiliatePayment = new AffiliatePayment({
        affiliate_id: this.selectedAffiliate.id,
        amount: this.commissionAdvance.amount,
        payment_gateway: 0,
        note: this.commissionAdvance.comment,
        transaction_id: this.commissionAdvance.transactionId,
        type: 6,
        funnel: this.filtersModel.selectedFunnel,
      });

      await affiliatePayment.save();
      this.$root.$emit('modal-close', 'add-commission-advance-modal');
      
      const newSelectedAffiliate = await SecondTierAffiliate.params({
        funnels: this.filtersModel.selectedFunnel,
      }).$find(this.selectedAffiliate.id);
      this.$emit('updateSelectedAffiliate', newSelectedAffiliate);

      this.$loader.end('addCommissionAdvance');
      this.$emit('changedSearchOrFilter');
    },
    onClose() {
      this.commissionAdvance = {
        amount: 0,
        comment: '',
        transactionId: '',
      };
    },
  },
  validations: {
    commissionAdvance: {
      amount: {
        required,
        decimal,
        minValue: minValue(1),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
