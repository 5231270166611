<template>
    <div>
        <div class="flex w-1/2 mb-6 bg-grey-100 p-1">
            <label class="font-semibold text-sm text-grey-700">
                <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
                {{ $t('frontend_product_step4_note') }}
            </label>
        </div>
        <div v-for="(pricePoint, index) in Localpricing_points" :key="index" class="mb-12">
            <div class="flex flex-col w-1/2 mb-1">
                <label class="font-semibold text-md text-grey-700 mb-2">
                    {{ pricePoint.name }}
                </label>
            </div>
            <div class="flex flex-col w-1/2">
                <label class="text-sm text-grey-700 mb-2">{{
                    $t('frontend_product_step4_thank_you')
                }}</label>
                <text-input v-model="pricePoint.post_checkout_details.thankyou_page"
                    :validator="validation.localModel.pricing_points.$each[index].post_checkout_details.thankyou_page"
                    :attribute="$t('frontend_product_step4_thankyou_page')"
                    value="pricePoint.post_checkout_details.thankyou_page"
                    :placeholder="$t('frontend_product_step4_thank_you')" />
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
    components: {
        InfoIcon,
    },
    props: {
        pricing_points: {
            required: true,
        },
        validation: {
            required: true,
        },
    },
    data() {
        return {
            Localpricing_points: this.pricing_points,
        };
    },
    watch: {
        Localpricing_points: {
            handler() {
                this.validation.localModel.pricing_points.$touch();
                this.$emit('update-model', {
                    pricing_points: this.Localpricing_points
                });
            },
            deep: true,
        },
    },

}
</script>