<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="signaturesTabs"
        :current-tab="signaturesTab"
        :error-tabs="signaturesErrorTabs"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleSignaturesTabChange"
      />
    </div>

    <div class="mt-2">
      <div v-if="signaturesTab === 'email'">
        <div v-if="hasEmailSignatures" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="emailSignaturesTabs"
              :current-tab="localEmailSignaturesTab"
              :error-tabs="emailSignaturesErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleEmailSignaturesTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleEmailSignaturesTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createEmailSignature"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>

          <div class="mt-2">
            <div v-for="(post, index) in localFunnelResource.signatures.email" :key="index">
              <div v-if="isEmailSignatureTabActive(post)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="post.name"
                      :validator="$v.localFunnelResource.signatures.email.$each[index].name"
                      :attribute="$t('frontend_groovesell_signature_name')"
                      value="post.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>
                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle class="h-6 mt-2" v-model="post.enabled" :value="post.enabled" />
                    <gd-button variant="outline" size="iconlg" class="ml-4" @click="openConfirmModal(index, 'deleteEmailSignature')">
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-full mb-6">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_signature') }}
                  </label>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.post"
                        class="h-32 w-full"
                        value="post.post"
                        :placeholder="$t('frontend_resources_signature')"
                        :validator="$v.localFunnelResource.signatures.email.$each[index].post"
                        :attribute="$t('frontend_signature')"
                      />
                    </div>
                    <div class="flex flex-col">
                      <gd-button
                        size="auto"
                        variant="primary"
                        class="mb-4"
                        @click="addAffiliateNameToEmailSignature(index)"
                      >
                        {{ $t('frontend_resources_add_affiliate_name') }}
                      </gd-button>
                      <gd-dropdown
                        :placeholder="$t('frontend_resources_add_affiliate_link')"
                        :actions="affiliateLinksActionsEmailSignature(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3 mb-3"
              @click="createEmailSignature"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_currently_dont_have_any_email_signatures') }}
        </div>
      </div>

      <div v-else-if="signaturesTab === 'forum'">
        <div v-if="hasForumSignatures" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="forumSignaturesTabs"
              :current-tab="localForumSignaturesTab"
              :error-tabs="forumSignaturesErrorTabs"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="handleForumSignaturesTabChange"
              :enable-dragging="true"
              @updateTabOrder="handleForumSignaturesTabOrderUpdate"
            />
            <gd-button
              size="auto"
              variant="primary"
              class="ml-4 mt-3"
              @click="createForumSignature"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>

          <div class="mt-2">
            <div v-for="(post, index) in localFunnelResource.signatures.forum" :key="index">
              <div v-if="isForumSignatureTabActive(post)" class="pb-8">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/2 mb-6">
                    <label class="font-semibold text-sm text-grey-700 mb-2">
                      {{ $t('frontend_resources_name') }}
                    </label>
                    <text-input
                      v-model="post.name"
                      :validator="$v.localFunnelResource.signatures.forum.$each[index].name"
                      :attribute="$t('frontend_groovesell_signature_name')"
                      value="post.name"
                      :placeholder="$t('frontend_resources_name')"
                    />
                  </div>
                  <div class="flex flex-row justify-end w-1/2">
                    <gd-toggle class="h-6 mt-2" v-model="post.enabled" :value="post.enabled" />
                    <gd-button
                      variant="outline"
                      size="iconlg"
                      class="ml-4"
                      @click="openConfirmModal(index, 'deleteForumSignature')"
                    >
                      <trash-icon class="w-5 h-5 fill-current" />
                    </gd-button>
                  </div>
                </div>

                <div class="flex flex-col w-full mb-6">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_signature') }}
                  </label>
                  <div class="flex flex-row w-full justify-between">
                    <div class="w-1/2">
                      <text-area
                        v-model="post.post"
                        class="h-32 w-full"
                        value="post.post"
                        :placeholder="$t('frontend_resources_signature')"
                        :validator="$v.localFunnelResource.signatures.forum.$each[index].post"
                        :attribute="$t('frontend_signature')"
                      />
                    </div>
                    <div class="flex flex-col">
                      <gd-button
                        size="auto"
                        variant="primary"
                        class="mb-4"
                        @click="addAffiliateNameToForumSignature(index)"
                      >
                        {{ $t('frontend_resources_add_affiliate_name') }}
                      </gd-button>
                      <gd-dropdown
                        :placeholder="$t('frontend_resources_add_affiliate_link')"
                        :actions="affiliateLinksActionsForumSignature(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
            <gd-button
              size="auto"
              variant="primary"
              class="mt-3"
              @click="createForumSignature"
            >
              {{ $t('frontend_resources_add_new') }}
            </gd-button>
          </div>
          {{ $t('frontend_resources_you_currently_dont_have_any_forum') }}
        </div>
      </div>
    </div>
    <confirm-modal
      name="signatures-confirm-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="applyAction"
    />
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  components: {
    TrashIcon,
  },
  mixins: [generateNumberMixin],
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
    emailSignaturesTab: {
      type: String,
      default: '',
    },
    forumSignaturesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      itemToDeleteIndex: null,
      actionType: '',
      localFunnelResource: this.funnelResource,
      localEmailSignaturesTab: this.emailSignaturesTab,
      localForumSignaturesTab: this.forumSignaturesTab,
      signaturesTabs: [
        { title: this.$t('frontend_resources_email'), value: 'email' },
        { title: this.$t('frontend_resources_forum'), value: 'forum' },
      ],
      signaturesTab: 'email',
    };
  },
  computed: {
    hasEmailSignatures() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.signatures &&
        this.localFunnelResource.signatures.email.length > 0
      );
    },
    hasForumSignatures() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.signatures &&
        this.localFunnelResource.signatures.forum.length > 0
      );
    },
    signaturesErrorTabs() {
      let signaturesErrorTabs = [];

      if (this.$v.localFunnelResource.signatures.email.$error) {
        signaturesErrorTabs.push('email');
      }

      if (this.$v.localFunnelResource.signatures.forum.$error) {
        signaturesErrorTabs.push('forum');
      }

      return signaturesErrorTabs;
    },
    emailSignaturesTabs() {
      let emailSignaturesTabs = [];

      if (this.hasEmailSignatures) {
        for (let i = 0; i < this.localFunnelResource.signatures.email.length; i++) {
          emailSignaturesTabs.push({
            title: this.localFunnelResource.signatures.email[i].name,
            value: `email_signature_tab_${this.localFunnelResource.signatures.email[i].id}`,
          });
        }
      }

      return emailSignaturesTabs;
    },
    emailSignaturesErrorTabs() {
      let emailSignaturesErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.signatures.email.$each.$iter) {
        if (this.$v.localFunnelResource.signatures.email.$each.$iter[index].$error) {
          emailSignaturesErrorTabs.push(
            `email_signature_tab_${this.$v.localFunnelResource.signatures.email.$each.$iter[index].$model.id}`
          );
        }
      }

      return emailSignaturesErrorTabs;
    },
    forumSignaturesTabs() {
      let forumSignaturesTabs = [];

      if (this.hasForumSignatures) {
        for (let i = 0; i < this.localFunnelResource.signatures.forum.length; i++) {
          forumSignaturesTabs.push({
            title: this.localFunnelResource.signatures.forum[i].name,
            value: `forum_signature_tab_${this.localFunnelResource.signatures.forum[i].id}`,
          });
        }
      }

      return forumSignaturesTabs;
    },
    forumSignaturesErrorTabs() {
      let forumSignaturesErrorTabs = [];

      let index;
      for (index in this.$v.localFunnelResource.signatures.forum.$each.$iter) {
        if (this.$v.localFunnelResource.signatures.forum.$each.$iter[index].$error) {
          forumSignaturesErrorTabs.push(
            `forum_signature_tab_${this.$v.localFunnelResource.signatures.forum.$each.$iter[index].$model.id}`
          );
        }
      }

      return forumSignaturesErrorTabs;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  methods: {
    affiliateLinksActionsEmailSignature(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToEmailSignature(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    affiliateLinksActionsForumSignature(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToForumSignature(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleEmailSignaturesTabChange(newTab) {
      this.localEmailSignaturesTab = newTab || this.localEmailSignaturesTab;
      this.$emit('handleEmailSignaturesTabChange', this.localEmailSignaturesTab);
    },
    handleForumSignaturesTabChange(newTab) {
      this.localForumSignaturesTab = newTab || this.localForumSignaturesTab;
      this.$emit('handleForumSignaturesTabChange', this.localForumSignaturesTab);
    },
    handleSignaturesTabChange(newTab) {
      this.signaturesTab = newTab;
    },
    handleEmailSignaturesTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('email_signature_tab_', ''))
      );

      this.localFunnelResource.signatures.email.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createEmailSignature() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.signatures.email.length + 1;

      this.localFunnelResource.signatures.email.push({
        id: newPostId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_signature')} ${newPostCount}`,
        post: '',
      });

      this.localEmailSignaturesTab = `email_signature_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleEmailSignaturesTabChange();
    },
    openConfirmModal(index, actionType) {
      this.itemToDeleteIndex = index;
      this.actionType = actionType;
      this.$root.$emit('modal-open', 'signatures-confirm-delete-modal');
    },
    applyAction() {
      switch (this.actionType) {
        case 'deleteEmailSignature':
          this.deleteEmailSignature();
          break;
        case 'deleteForumSignature':
          this.deleteForumSignature();
          break;
      }
    },
    async deleteEmailSignature() {
      this.localFunnelResource.signatures.email.splice(this.itemToDeleteIndex, 1);
      this.localEmailSignaturesTab = '';

      if (this.localFunnelResource.signatures.email.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId =
          this.localFunnelResource.signatures.email[nextOpenIndex].id;
        this.localEmailSignaturesTab = `email_signature_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleEmailSignaturesTabChange();
    },
    addAffiliateNameToEmailSignature(index) {
      this.localFunnelResource.signatures.email[
        index
      ].post = `${this.localFunnelResource.signatures.email[index].post}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToEmailSignature(index, landingPageId) {
      this.localFunnelResource.signatures.email[
        index
      ].post = `${this.localFunnelResource.signatures.email[index].post}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    handleForumSignaturesTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('forum_signature_tab_', ''))
      );

      this.localFunnelResource.signatures.forum.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });

      this.updateFunnelResource();
    },
    createForumSignature() {
      const newPostId = this.generateNumber();
      const newPostCount = this.localFunnelResource.signatures.forum.length + 1;

      this.localFunnelResource.signatures.forum.push({
        id: newPostId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_signature')} ${newPostCount}`,
        post: '',
      });

      this.localForumSignaturesTab = `forum_signature_tab_${newPostId}`;

      this.updateFunnelResource();
      this.handleForumSignaturesTabChange();
    },
    async deleteForumSignature() {
      this.localFunnelResource.signatures.forum.splice(this.itemToDeleteIndex, 1);
      this.localForumSignaturesTab = '';

      if (this.localFunnelResource.signatures.forum.length > 0) {
        let nextOpenIndex = 0;
        if (this.itemToDeleteIndex != 0) {
          nextOpenIndex = this.itemToDeleteIndex - 1;
        }

        const nextOpenId = this.localFunnelResource.signatures.forum[nextOpenIndex].id;
        this.localForumSignaturesTab = `forum_signature_tab_${nextOpenId}`;
      }

      this.updateFunnelResource();
      this.handleForumSignaturesTabChange();
    },
    addAffiliateNameToForumSignature(index) {
      this.localFunnelResource.signatures.forum[
        index
      ].post = `${this.localFunnelResource.signatures.forum[index].post}##affiliateName##`;

      this.updateFunnelResource();
    },
    addAffiliateLinkToForumSignature(index, landingPageId) {
      this.localFunnelResource.signatures.forum[
        index
      ].post = `${this.localFunnelResource.signatures.forum[index].post}##affiliateLink${landingPageId}##`;

      this.updateFunnelResource();
    },
    isEmailSignatureTabActive(post) {
      return this.localEmailSignaturesTab === `email_signature_tab_${post.id}`;
    },
    isForumSignatureTabActive(post) {
      return this.localForumSignaturesTab === `forum_signature_tab_${post.id}`;
    },
  },
  validations: {
    localFunnelResource: {
      signatures: {
        email: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
        forum: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(80),
            },
            post: {
              required,
              minLength: minLength(2),
            },
            $trackBy: 'id',
          },
        },
      },
    },
  },
};
</script>
