<template>
  <div>
    <div v-if="option">
      <div v-if="bumpType == 1">
        <light-box-bump-style
          :key="priceBumpKey"
          :heading="heading"
          :subheading="subheading"
          :description="description"
          :post-order-video-details="postOrderVideoDetails"
          :option="option"
          :product="product"
          :currency-code="currencyCode"
          :buynow-text="buynowText"
          :thumbnail="thumbnail"
          :thumbnail-img="thumbnailImg"
          :thumbnail-is-square="thumbnailIsSquare"
          :thumbnail-size="thumbnailSize"
          :is-processing="isProcessing"
          @onPurchasePostSaleBump="purchasedPostSaleBump"
          @onClickedNoThanks="clickedNoThanks"
          @onClickedClosePreview="clickedClosePreview"
          @onChangedQuantity="changedQuantity"
        />
      </div>
      <div v-else-if="bumpType == 2">
        <transition name="fade">
          <div class="fixed z-40 flex justify-center items-center inset-0">
            <div class="fixed inset-0 w-full h-full opacity-75 bg-white" />
          </div>
        </transition>
        <transition name="slide">
          <div
            class="slidein fixed z-50 top-0 right-0 h-full p-4 border-l border-grey-300 bg-white max-w-xl overflow-auto"
          >
            <slider-bump-style
              :key="priceBumpKey"
              :heading="heading"
              :subheading="subheading"
              :description="description"
              :post-order-video-details="postOrderVideoDetails"
              :option="option"
              :product="product"
              :currency-code="currencyCode"
              :buynow-text="buynowText"
              :thumbnail="thumbnail"
              :thumbnail-img="thumbnailImg"
              :thumbnail-is-square="thumbnailIsSquare"
              :thumbnail-size="thumbnailSize"
              :is-processing="isProcessing"
              @onPurchasePostSaleBump="purchasedPostSaleBump"
              @onClickedNoThanks="clickedNoThanks"
              @onClickedClosePreview="clickedClosePreview"
              @onChangedQuantity="changedQuantity"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LightBoxBumpStyle from './LightBoxBumpStyle';
import SliderBumpStyle from './SliderBumpStyle';

export default {
  name: 'PostSaleBumps',
  props: {
    heading: String,
    subheading: String,
    description: String,
    buynowText: String,
    currencyCode: {
      default: '$',
    },
    thumbnail: {
      default: 0,
    },
    postOrderVideoDetails:{},
    thumbnailImg: {},
    thumbnailIsSquare: {},
    thumbnailSize: {},
    isProcessing: {},
    bumpType: {},
    allProducts: {
      default: null,
    },
    allPricepoints: {
      default: null,
    },
    checkoutPageOptions: {
      default: null,
    },
    selectedPricepoint: {
      default: null,
    },
    selectedProduct: {
      default: null,
    },
  },
  data() {
    return {
      priceBumpKey: 1,
      option: null,
    };
  },
  computed: {
    product() {
      const product = this.allProducts.filter((product) => {
        return product.id == this.selectedProduct;
      })[0];

      if (product) {
        product.checkout_page_options = this.checkoutPageOptions;
        return product;
      }

      return null;
    },
  },
  created() {
    const pricepPoint = this.allPricepoints.filter((pricepoint) => {
      return pricepoint.id == this.selectedPricepoint;
    })[0];

    if (pricepPoint.hasOwnProperty('selected_quantity')) {
    } else if (pricepPoint.quantity_enable) {
      pricepPoint.selected_quantity = pricepPoint.minimum_quantity;
    } else {
      pricepPoint.selected_quantity = 1;
    }

    this.option = pricepPoint;
  },
  components: {
    LightBoxBumpStyle,
    SliderBumpStyle,
  },
  methods: {
    changedQuantity(quantity) {
      this.option.selected_quantity = quantity;
      this.priceBumpKey++;
    },
    purchasedPostSaleBump() {
      this.$emit('onPurchasePostSaleBump');
    },
    clickedNoThanks() {
      this.$emit('onClickedNoThanks');
    },
    clickedClosePreview() {
      this.$emit('onClickedClosePreview');
    },
  },
};
</script>

<style>
.slidein {
  transition: all 0.5s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  right: -100%;
}
</style>