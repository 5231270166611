<template>
  <div id="app">
    <div v-if="$loader.loading" class="absolute bg-opacity-50 bg-white h-screen w-screen z-99">
      <gd-loader />
    </div>
    <loading ref="loading" :blur="''" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import auth from '@groovepages/gd-lib/auth';
import GdLoader from '@groovepages/gd-ui-kit/components/Loader';
import Loading from '@/components/Loading';

// Load layout components dynamically.
const requireContext = require.context('@/layouts', false, /.*\.vue$/);
const layouts = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
  .reduce((components, [name, component]) => {
    const finalComponents = components;
    finalComponents[name] = component.default || component;
    return finalComponents;
  }, {});

export default {
  components: {
    Loading,
    GdLoader
  },
  data: () => ({
    layout: null,
    defaultLayout: 'default'
  }),
  metaInfo() {
    const appName = this.whitelabelAppData('sell').title;

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    };
  },
  created() {
    if (!auth.authenticatedUser()) {
      this.$store.commit('login', auth.authenticatedUser());
    }
  },
  mounted() {
    this.$loading = this.$refs.loading;
  },
  methods: {
    setLayout(layout) {
      let finalLayout = layout;
      if (!layout || !layouts[layout]) {
        finalLayout = this.defaultLayout;
      }

      this.layout = layouts[finalLayout];
    }
  }
};
</script>
<style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
