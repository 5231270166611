<template>
    <div>
        <div class="flex flex-col w-1/2 mb-6 bg-grey-100 p-1">
            <label class="font-semibold text-sm text-grey-700">
                <info-icon class="h-4 w-4 fill-current inline mr-1 -mt-1"/>
                {{ $t('frontend_product_step4_note2') }}
            </label>
        </div>
        <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step4_refund_period')
            }}</label>
            <text-input v-model="local_refund_period" :validator="validation.localModel.refund_period"
                :attribute="$t('frontend_product_step4_refund_period')" value="refund_period"
                :placeholder="$t('frontend_product_step4_refund_period')" />
        </div>

        <div class="flex flex-col w-1/2 mb-6">
            <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_product_step4_Buffer_period')
            }}</label>
            <text-input v-model="local_waiting_period" :validator="validation.localModel.waiting_period"
                :attribute="$t('frontend_product_step4_Buffer_period')" value="waiting_period"
                :placeholder="$t('frontend_product_step4_waiting_period')" />
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/assets/images/icons/InfoIcon.svg';

export default {
    components: {
        InfoIcon,
    },
    props: {
        refund_period: {
            required: true,
        },
        waiting_period: {
            required: true,
        },
        validation: {
            required: true,
        },
    },
    data() {
        return {
            local_refund_period: this.refund_period,
            local_waiting_period: this.waiting_period,
        };
    },
    watch: {
        local_refund_period() {
            this.validation.localModel.refund_period.$touch();
            this.$emit('update-model', {
                refund_period: this.local_refund_period
            });
        },
        local_waiting_period() {
            this.validation.localModel.waiting_period.$touch();
            this.$emit('update-model', {
                waiting_period: this.local_waiting_period
            });
        },
    },
}
</script>