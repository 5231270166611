<template>
    <div>
        <div class="flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-4">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="mt-4">
            <div v-for="(pricePoint, index) in localModel.pricing_points" :key="index">
                <div v-if="pricePointsTab === 'price_point_' + pricePoint.id" class="flex flex-col w-full">
                    <div v-if="pluginSiteData.integrations.length > 0 && availableIntegrations.length > 0">
                        <div class="flex flex-col">
                            <div class="flex flex-row-reverse w-full">
                                <gd-button size="auto" variant="primary" class="mr-4"
                                    @click="showAbandonCartIntegrationBox(index)">{{
                                        $t('frontend_product_step5_add_new_integration') }}</gd-button>
                            </div>
                            <div v-if="pricePoint.abandon_cart_integrations && pricePoint.abandon_cart_integrations.length > 0"
                                class="mt-6">
                                <div v-for="(abandonCartIntegration, abandonCartIntegrationIndex) in pricePoint.abandon_cart_integrations"
                                    :key="abandonCartIntegrationIndex" class="flex flex-col mt-4">
                                    <div class="flex flex-row" :class="{
                                        'border-t border-grey-400 border-solid pt-4': abandonCartIntegrationIndex > 0
                                    }">
                                        <div class="w-1/12 mr-8">{{ $t('frontend_products_add_to') }}</div>
                                        <div class="flex flex-row w-11/12">
                                            <div class="w-3/4 flex flex-col">
                                                <div class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ $t('frontend_product_step5_type') }}
                                                    </div>
                                                    <div>
                                                        {{
                                                            abandonCartIntegration.platform
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            abandonCartIntegration.platform.slice(1)
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-for="(abandonCartIntegrationConfig, abandonCartIntegrationConfigIndex) in abandonCartIntegration.config"
                                                    :key="abandonCartIntegrationConfigIndex" class="flex flex-row">
                                                    <div class="font-bold mr-2">
                                                        {{ abandonCartIntegrationConfig.label }} :
                                                    </div>
                                                    <div>
                                                        {{ abandonCartIntegrationConfig.name }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-1/4 flex flex-row justify-end pr-4">
                                                <gd-button size="icon" variant="primary"
                                                    :title="$t('frontend_groovesell_delete_integration')" class="mr-2"
                                                    @click="openConfirmModal(index, abandonCartIntegrationIndex)">
                                                    <trash-icon class="h-5 w-5 fill-current"/>
                                                </gd-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-6">
                                <label class="text-grey-dark">{{
                                    $t('frontend_product_step5_no_integrations')
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex justify-between">
                        <label class="text-grey-dark">{{
                            $t('frontend_product_step5_no_integrations_added')
                        }}</label>

                        <gd-button variant="primary" size="auto" @click="openIntegrationModal">
                            <plus-icon class="h-5 w-5 fill-current"/>
                            {{ $t('frontend_groovesell_products_step5_btn_integrations_modal') }}
                        </gd-button>
                    </div>
                </div>
            </div>
        </div>
        <confirm-modal
            name="confirm-delete-cart-integration-modal"
            :message-content="$t('frontend_non_revertable')"
            :button-text="$t('frontend_delete_confirm')"
            button-size="auto"
            @confirm="deleteAbandonCartIntegration"
        />
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';

export default {
    components: {
        TrashIcon,
        PlusIcon,
    },
    props: {
        model: {
            required: true
        },
        validation: {
            required: true
        },
        openIntegrationModal: {
            type: Function,
            required: true
        },
        pluginSiteData: {
            type: Object,
            required: true
        },
        availableIntegrations: {
            type: Array,
            required: true
        },
        showAbandonCartIntegrationBox: {
            type: Function,
            required: true
        },

    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: 'price_point_' + this.model.pricing_points[0].id,
            pricePointIndex: null,
            abandonCartIntegrationIndex: null,
        }
    },
  

    computed: {
        pricePointsTabs() {
            const pricePointTabs = [];
            for (let i = 0; i < this.localModel.pricing_points.length; i++) {
                pricePointTabs.push({
                    title: this.localModel.pricing_points[i].name,
                    value: `price_point_${this.localModel.pricing_points[i].id}`,
                });
            }

            return pricePointTabs;
        },
        pricePointsErrorTabs() {
            const pricePointErrorTabs = [];
            let pricePointIndex;
            for (pricePointIndex in this.validation.localModel.pricing_points.$each.$iter) {
                if (this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$error) {
                    pricePointErrorTabs.push(`price_point_${this.validation.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`);
                }
            }
            return pricePointErrorTabs;
        },
    },
    watch: {
        localModel: {
            handler() {
                this.$emit('updateModel', this.localModel);
            },
            deep: true
        }
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        },
        openConfirmModal(index, abandonCartIntegrationIndex) {
            this.$root.$emit('confirm-delete-cart-integration-modal');
            this.pricePointIndex = index;
            this.abandonCartIntegrationIndex = abandonCartIntegrationIndex;
        },
        async deleteAbandonCartIntegration() {
            this.localModel.pricing_points[this.pricePointIndex]
                .abandon_cart_integrations.splice(this.abandonCartIntegrationIndex, 1);
        }
    },
}
</script>