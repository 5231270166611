var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col rounded border-dashed border-4 border-black p-2 pb-4"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row justify-center w-full py-4 px-2",staticStyle:{"background-color":"#ffff99","color":"#1a9b15"}},[_c('div',{staticClass:"flex flex-row w-full justify-center",class:{ '-ml-2': _vm.arrow }},[(_vm.arrow)?_c('div',{staticClass:"rotate-270 text-center mr-2 -mt-1"},[_c('svg',{staticClass:"gs-animated-bounce text-red w-6 h-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 99.732 165.466"}},[_c('path',{attrs:{"d":"M57.067,167.066,7.2,119.466H38.933V1.6H75.2V119.466h31.733l-49.867,47.6Z","transform":"translate(-7.2 -1.6)","fill":"#c51f1f","stroke":"#c51f1f"}})])]):_vm._e(),_c('gd-checkbox',{attrs:{"variant":"primary"},model:{value:(_vm.previewOrderBumps),callback:function ($$v) {_vm.previewOrderBumps=$$v},expression:"previewOrderBumps"}}),(_vm.heading && _vm.heading != null)?_c('div',{staticClass:"text-lg ml-2 font-semibold"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_c('div',{staticClass:"text-lg ml-2 font-semibold"},[_vm._v(_vm._s(_vm.$t('frontend_heading')))])],1)]),_c('div',{staticClass:"w-full flex flex-row px-2 py-2"},[_c('div',{staticClass:"flex flex-col text-md",class:{
          'w-3/4':
            _vm.thumbnail &&
            _vm.actualSelectedProduct.checkout_page_options.hasOwnProperty(
              'product_image'
            ) &&
            _vm.actualSelectedProduct.checkout_page_options.product_image &&
            _vm.actualSelectedProduct.checkout_page_options.product_image != '',

          'w-full':
            !_vm.thumbnail ||
            !_vm.actualSelectedProduct.checkout_page_options.hasOwnProperty(
              'product_image'
            ) ||
            (!_vm.actualSelectedProduct.checkout_page_options.product_image &&
              _vm.actualSelectedProduct.checkout_page_options.product_image ==
                ''),
        }},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_c('div',{staticClass:"flex flex-row w-full"},[_c('label',{staticClass:"font-semibold text-primary"},[_vm._v(" "+_vm._s(_vm.actualSelectedProduct.name)+" ")])]),_c('div',{staticClass:"flex flex-row w-full font-semibold"},[(_vm.actualSelectedPricepoint)?_c('div',[_vm._v(" "+_vm._s(_vm.actualSelectedPricepoint.pricing_string)+" ")]):_vm._e()])]),(_vm.actualSelectedPricepoint.quantity_enable == 1)?_c('div',{staticStyle:{"width":"60px"}},[_c('gd-dropdown',{staticClass:"flex-row-reverse",staticStyle:{"width":"100%"},attrs:{"options":_vm.quantityValue},model:{value:(_vm.actualSelectedPricepoint.minimum_quantity),callback:function ($$v) {_vm.$set(_vm.actualSelectedPricepoint, "minimum_quantity", $$v)},expression:"actualSelectedPricepoint.minimum_quantity"}})],1):_vm._e()]),_c('div',{staticClass:"flex flex-row w-full text-grey-900 text-sm mt-2"},[_c('div',{staticClass:"flex flex-row"},[(_vm.description != null)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}}):_c('div',[_vm._v(_vm._s(_vm.$t('frontend_desc')))])])])]),(
          _vm.thumbnail &&
          _vm.actualSelectedProduct.checkout_page_options.hasOwnProperty(
            'product_image'
          ) &&
          _vm.actualSelectedProduct.checkout_page_options.product_image &&
          _vm.actualSelectedProduct.checkout_page_options.product_image != ''
        )?_c('div',{staticClass:"flex flex-row justify-end w-1/4"},[_c('img',{staticClass:"rounded-full h-24 w-24 flex items-center justify-center",attrs:{"src":_vm.actualSelectedProduct.checkout_page_options.product_image}})]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }