<template>
  <div>
    <!-- Selfbilling Form -->
    <div class="flex flex-col w-full mb-6">
      <!-- Customer Portal Link -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="text-grey-700 mt-2 mr-2">
          {{ $t('frontend_funnels_your_customers') }}
        </label>
        <text-input
          v-model="selfbillingLink"
          class="mt-2"
          :readonly="true"
          value="selfbillingLink"
          :placeholder="$t('frontend_funnels_customer_portal')"
          :select-all="true"
        />
      </div>
      <!-- Toggle Switches -->
      <div class="flex flex-wrap w-full">
        <!-- Invoice -->
        <div class="flex w-1/3 mt-4">
          <div class="mr-2">
            {{ $t('frontend_funnels_allow_invoice') }}
          </div>
          <gd-toggle
            v-model="form.selfbilling_info.invoice_download"
            :value="form.selfbilling_info.invoice_download"
          />
        </div>
        <!-- Credit Card -->
        <div class="flex w-1/3 mt-4">
          <div class="mr-2">
            {{ $t('frontend_funnels_allow_credit_card') }}
          </div>
          <gd-toggle
            v-model="form.selfbilling_info.update_payment_info"
            :value="form.selfbilling_info.update_payment_info"
          />
        </div>
        <!-- Refund -->
        <div class="flex w-1/3 mt-4">
          <div class="mr-2">
            {{ $t('frontend_funnels_allow_refund') }}
          </div>
          <gd-toggle
            v-model="form.selfbilling_info.refund"
            :value="form.selfbilling_info.refund"
          />
        </div>
        <!-- Cancel -->
        <div class="flex w-1/3 mt-4">
          <div class="mr-2">
            {{ $t('frontend_funnels_allow_cancel') }}
          </div>
          <gd-toggle
            v-model="form.selfbilling_info.cancel"
            :value="form.selfbilling_info.cancel"
          />
        </div>
      </div>
    </div>
    <!-- Save Buttons -->
    <hr class="border-grey-100 -mx-4" />
    <div class="flex flex-row-reverse pr-2 pb-4">
      <gd-button
        v-if="!$v.form.$invalid"
        variant="primary"
        size="md"
        class="mt-6"
        @click="saveVendorSettings"
      >{{ $t('frontend_save') }}</gd-button>
      <gd-button
        v-else
        disabled
        size="md"
        class="mt-6"
      >{{ $t('frontend_save') }}</gd-button>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import axios from 'axios';
import Notify from '@groovepages/gd-ui-kit/notify';

export default {
  data() {
    return {
      selfbillingLink: window.gdEnv.VUE_APP_SELFBILLING_URL,
      form: new Form({
        selfbilling_info: {},
      }),
    };
  },
  async created() {
    this.$loader.start('fetchVendorSettings');

    const { data } = await axios.get('/vendor-settings');
    this.form.selfbilling_info = data.selfbilling_info;

    this.$loader.end('fetchVendorSettings');
  },
  methods: {
    async saveVendorSettings() {
      this.$loader.start('saveVendorSettings');

      await this.form
        .post(`${window.config.apiUrl}/save-vendor-settings`)
        .then(() => {
          this.$loader.end('saveVendorSettings');
          Notify.success(this.$t('saved_settings'));
        })
        .catch(() => {
          this.$loader.end('saveVendorSettings');
          Notify.fail(this.$t('unable_to_save_settings'));
        });
    },
  },
  validations: {
    form: {},
  },
};
</script>
