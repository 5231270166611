<template>
  <div>
    <v-wait for="payment-list">
      <div v-if="paymentList.length" id="payment-list-view" class="mt-4">
        <v-client-table ref="paymentListTable" :data="paymentList" :columns="paymentListTableColumns"
          :options="paymentListTableOptions" class="bg-white text-center rounded border border-grey-300">
        </v-client-table>
      </div>
      <div v-else class="mt-8">
        <p>{{ $t('frontend_affiliate_no_vendor_payments') }}</p>
      </div>
    </v-wait>
  </div>
</template>

<script>
import AffiliatePayment from '@/models/AffiliatePayment';

export default {
  layout: 'defaultForAffiliates',
  middleware: 'auth',
  data() {
    return {
      paymentList: [],
      paymentListTableColumns: ['type', 'human_readable_amount', 'processed_at'],
      paymentListTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [],
        headings: {
          type: 'Name',
          human_readable_amount: 'Amount',
          processed_at: this.$t('frontend_processed_at')
        },
        filterByColumn: false,
        filterable: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        }
      }
    };
  },
  metaInfo() {
    return { title: this.$t('frontend_payments') };
  },
  async created() {
    this.$wait.start('payment-list');
    this.paymentList = await AffiliatePayment.$get();
    this.$wait.end('payment-list');
  },
};
</script>
<style lang="scss">
#payment-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>
