import PartnerContracts from '@/pages/contracts/index';
import CreateContracts from '@/pages/contracts/create';
import Distribution from '@/pages/distribution/index';
import i18n from '@/plugins/i18n';

export default [
  {
    path: '/contracts',
    name: 'contracts',
    component: PartnerContracts,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_contracts')
    }
  },
  {
    path: '/contracts/create',
    name: 'create_contract',
    component: CreateContracts,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_contracts')
    }
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: Distribution,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_distribution')
    }
  },
  {
    path: '/contracts/create/:contract_id',
    name: 'edit_contract',
    component: CreateContracts,
    meta: {
      pageTitle: i18n.t('frontend_groovesell_routes_edit_contracts')
    }
  }
];
