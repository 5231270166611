<template>
    <div>
        <div class=" flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2">
            <gd-tabs :tabs="pricePointsTabs" :error-tabs="webhookPricePointsErrorTabs" :current-tab="pricePointsTab"
                :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        </div>
        <div class="bg-white rounded p-2 mb-2">
            <div v-for="(pricePoint, index) in localWebHook.price_points" :key="index">
                <div v-if="pricePointsTab ===
                    'data_sharing_product_price_point_' + pricePoint.id
                    " class="flex flex-col w-full">
                    <div class="flex flex-row-reverse w-full">
                        <gd-button size="auto" variant="primary" @click="addIPNURL(index)">
                            {{
                                $t(
                                    'frontend_product_step8_no_integrations_add_new_webhook'
                                )
                            }}
                        </gd-button>
                    </div>

                    <div class="flex flex-col w-full bg-white mb-2">
                        <div v-if="pricePoint.ipnUrl && pricePoint.ipnUrl.length > 0" class="mt-6">
                            <div v-for="(ipn_Url, ipnIndex) in pricePoint.ipnUrl" :key="ipnIndex"
                                class="flex flex-col w-1/2 mb-6">
                                <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                    $t('frontend_product_step8_webhook_url')
                                }}</label>
                                <div class="flex flex-row w-11/12 items-center">
                                    <div class="w-full">
                                        <text-input v-model="ipn_Url.link"
                                            :validator="validator.webhook.price_points.$each[index].ipnUrl.$each[ipnIndex].link"
                                            attribute="IPN URL" value="ipn_Url.link"
                                            :placeholder="$t('frontend_product_step8_ipn_url')" />
                                    </div>
                                    <gd-button variant="outline" size="iconlg" class="ml-2" :title="$t('frontend_delete')"
                                        @click="deleteIPNURL(index, ipnIndex)">
                                        <trash-icon class="w-5 h-5 fill-current" />
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mt-6">
                            <label class="text-grey-dark">{{
                                $t('frontend_product_step8_no_webhook')
                            }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
    components: {
        TrashIcon,
    },
    props: {
        webhook: {
            type: Object,
            required: true,
        },
        pricePointsTabs: {
            type: Array,
            required: true,
        },
        pricePointsTab: {
            type: String,
            required: true,
        },
        webhookPricePointsErrorTabs: {
            type: Array,
            required: true,
        },
        validator: {
            type: Object,
            required: true,
        },
        handlePricePointsTabChange: {
            type: Function,
            required: true,
        },

    },
    data() {
        return {
            localWebHook: this.webhook
        }
    },
    watch: {
        localWebHook: {
            handler: function (val, oldVal) {
                this.$emit('update-data', {
                    key: 'webhook',
                    value: val,
                });
            },
            deep: true,
        },
    },
    methods: {
        addIPNURL(index) {
            this.localWebHook.price_points[index].ipnUrl.push({ link: '', type: 1 });
        },
        deleteIPNURL(index, ipnIndex) {
            this.localWebHook.price_points[index].ipnUrl.splice(ipnIndex, 1);
        },
    }
}
</script>