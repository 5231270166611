<template>
  <div>
    <div class="w-full flex flex-row mb-1">
      <div
        v-for="(checkoutStepType, index) in checkoutStepTypes"
        :key="index"
        class="flex w-1/4 mr-4"
      >
        <gd-custom-radio
          v-model="selCheckoutStepType"
          :option="checkoutStepType"
          @onUpdateOption="changeStep"
        ></gd-custom-radio>
      </div>
    </div>

    <div
      class="w-full flex flex-row mb-1"
      v-if="selCheckoutStepType == 0 || selCheckoutStepType == 2"
    >
      <div
        v-for="(multiStepType, index) in multiStepTypes"
        :key="index"
        class="flex w-1/3 flex-1"
      >
        <checkout-radio-button
          v-model="selectedMultiStepType"
          :option="multiStepType"
          @onUpdateOption="multiStepTypeChange"
        ></checkout-radio-button>
      </div>
    </div>

    <div class="flex flex-row mb-1" v-else>
      <div
        v-for="(singleStepType, index) in singleStepTypes"
        :key="index"
        class="flex flex-row w-1/3"
      >
        <checkout-radio-button
          v-model="selectedSingleStepType"
          :option="singleStepType"
          @onUpdateOption="singleStepTypeChange"
        ></checkout-radio-button>
      </div>
    </div>

    <div v-if="selCheckoutStepType == 0" class="mb-5">
      <div class="flex flex-row w-full mb-1">
        <label class="font-semibold text-sm text-grey-700 mr-2">{{
          $t('forntend_checkout_link_advance_multistep_checkout')
        }}</label>
        <gd-toggle
          v-model="isMultiStepRedirect"
          :value="isMultiStepRedirect"
          @change="multiStepRedirectChange"
        />
      </div>

      <div v-if="isMultiStepRedirect" class="mt-4">
        <div
          class="w-full flex flex-row mb-1"
          v-if="selectedMultiStepType == 3"
        >
          <div class="mt-0 w-1/2 flex flex-row mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mr-2 w-1/4 mt-2"
              >{{
                $t('forntend_checkout_link_advance_multistep_order_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.order_form_redirects
                    .order_form_url
                "
                :placeholder="
                  $t('forntend_checkout_link_advance_multistep_order_form_url')
                "
                :validator="
                  $v.localModel.multi_step_redirect_options.order_form_redirects
                    .order_form_url
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-1" v-else-if="selectedMultiStepType == 2">
          <div class="mt-0 w-1/2 flex flex-row mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_billing_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.email_form_redirects
                    .billing_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_billing_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options.email_form_redirects
                    .billing_form_url
                "
              />
            </div>
          </div>
          <div
            class="mt-0 w-1/2 flex flex-row mb-6"
            v-if="
              localModel.checkout_link_page_options.move_password_to_last_step
            "
          >
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_password_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.email_form_redirects
                    .password_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_password_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options.email_form_redirects
                    .password_form_url
                "
              />
            </div>
          </div>
          <div class="mt-0 w-1/2 flex flex-row mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_payment_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.email_form_redirects
                    .payment_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_payment_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options.email_form_redirects
                    .payment_form_url
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-1" v-else-if="selectedMultiStepType == 1">
          <div class="mt-0 w-1/2 flex flex-row mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_billing_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.contact_form_redirects
                    .billing_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_billing_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options
                    .contact_form_redirects.billing_form_url
                "
              />
            </div>
          </div>
          <div
            class="mt-0 w-1/2 flex flex-row mb-2"
            v-if="
              localModel.checkout_link_page_options.move_password_to_last_step
            "
          >
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_password_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.contact_form_redirects
                    .password_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_password_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options
                    .contact_form_redirects.password_form_url
                "
              />
            </div>
          </div>
          <div class="mt-0 w-1/2 flex flex-row mb-2">
            <label
              class="font-semibold text-sm text-grey-700 mr-2 mt-2 w-1/4"
              >{{
                $t('forntend_checkout_link_advance_multistep_payment_form_url')
              }}</label
            >
            <div class="w-3/4 flex flex-row">
              <text-input
                class="w-full"
                v-model="
                  localModel.multi_step_redirect_options.contact_form_redirects
                    .payment_form_url
                "
                :placeholder="
                  $t(
                    'forntend_checkout_link_advance_multistep_payment_form_url'
                  )
                "
                :validator="
                  $v.localModel.multi_step_redirect_options
                    .contact_form_redirects.payment_form_url
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf, url } from 'vuelidate/lib/validators';
import CheckoutRadioButton from './CheckoutRadioButton';

export default {
  name: 'CheckoutStepTypes',

  components: {
    CheckoutRadioButton,
  },

  props: {
    checkoutStepTypes: {
      required: true,
    },
    selectedCheckoutStepType: {
      required: true,
    },
    multiStepTypes: {
      required: true,
    },
    singleStepTypes: {
      required: true,
    },
    selectedMultiStepTypeProp: {
      required: true,
    },
    isMultiStepRedirectProp: {
      required: true,
    },
    model: {
      required: true,
    },
  },
  validations: {
    localModel: {
      multi_step_redirect_options: {
        order_form_redirects: {
          order_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 3
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
        email_form_redirects: {
          billing_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType === 2 &&
                self.selectedMultiStepType === 2
              ) {
                return true;
              }

              return false;
            }),
            url,
          },
          payment_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 2
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
          password_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 2 &&
                self.localModel.checkout_link_page_options
                  .move_password_to_last_step
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
        contact_form_redirects: {
          billing_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 1
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
          payment_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 1
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
          password_form_url: {
            required: requiredIf(function (value) {
              const self = this;
              if (
                self.selCheckoutStepType == 2 &&
                self.selectedMultiStepType == 1 &&
                self.localModel.checkout_link_page_options
                  .move_password_to_last_step
              ) {
                return true;
              }
              return false;
            }),
            url,
          },
        },
      },
    },
  },

  data() {
    return {
      localModel: this.model,
      selectedMultiStepType: this.selectedMultiStepTypeProp,
      isMultiStepRedirect: this.isMultiStepRedirectProp,
      selCheckoutStepType: this.selectedCheckoutStepType,
      selectedSingleStepType: 1,
    };
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },
    'localModel.multi_step_redirect_options': {
      handler(after, before) {
        this.$emit(
          'updateMultiStepRedirect',
          this.localModel.multi_step_redirect_options
        );
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    changeStep(value) {
      this.$emit('updateSelCheckoutStepType', value);
    },

    multiStepTypeChange(value) {
      this.$emit('updateselectedMultiStepType', value);
    },

    singleStepTypeChange(value) {
      this.$emit('updateselectedSingleStepType', value);
    },

    multiStepRedirectChange() {
      this.$emit('updateIsMultiStepRedirect', this.isMultiStepRedirect);
    },
  },
};
</script>