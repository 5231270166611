<template>
  <div class="app-tiles-container">
    <div class="w-full">
      <v-wait for="products">
        <div class="mt-4">
          <div class="relative inline-flex">
            <angle-down-icon class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" />
            <gd-dropdown
              v-model="selectedOrder"
              size="full"
              :options="sortOrderOptions"
              @change="changedSortOrder"
            />
          </div>
          <div class="mt-4">
            <gd-tabs :tabs="getProductTabs" :current-tab="productsTab" :wrapper-class="'px-0 gs-default-tabs'"
              :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'" @onClick="handleProductsTabChange" />
            <product-tabs :products="products" :bumps="bumps" :isAdmin="isAdmin" :funnel="funnel"
              :productsTab="productsTab"  :deleteProduct="openDeleteProductConfirmModal"
              :openProjectionsModal="openProjectionsModal" :openTrackingModal="openTrackingModal"
              :deleteBump="openDeleteBumpConfirmModal" :openCloneProduct="openCloneProduct" />
          </div>
        </div>

        <projections-modal v-if="pricepointsForProjections.length > 0"
          :pricepointsForProjections="pricepointsForProjections" :funnel="funnel" />
        <tracking-modal v-if="selectedProduct" :selectedProduct="selectedProduct" :proofWidgets="proofWidgets" />
        <clone-product-modal v-if="selectedProductForCloning" :selectedProduct="selectedProductForCloning"
          :funnel_id="selectedFunnelId" :funnels="funnels" @products="updateProducts" />
        <new-product-modal :isAdmin="isAdmin" :funnel="funnel" />


      </v-wait>
    </div>

    <portal to="headerBreadCrumb">
      <div v-if="funnel?.name" class="flex items-center items-center -ml-4">
        <div class="cursor-pointer text-grey-500" @click="sendToDashboard" v-tooltip="$t('frontend_back_to_dashboard')">
          <arrow-bracket-icon class="h-5 w-5 fill-current"/>
        </div>
        <div class="text-sm font-semibold ml-3">{{ funnel?.name }}</div>
        <chevron-right-icon class="h-5 w-5 fill-current"/>
        <div class="text-sm font-semibold">{{ $t('frontend_dashboard') }}</div>
        <chevron-right-icon class="h-5 w-5 fill-current"/>
      </div>
    </portal>

    <portal to="headerContent">
      <gd-button variant="primary" @click="createProduct">
        <plus-icon class="h-5 w-5 fill-current"/>
        {{ $t('frontend_add') }}
      </gd-button>
    </portal>
    <confirm-modal
      name="confirm-delete-bump-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteBump"
    />
    <confirm-modal
      name="confirm-delete-product-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteProduct"
    />
  </div>
</template>


<script>
import axios from 'axios';
import Funnel from '@/models/Funnel';
import Product from '@/models/Product';
import Bump from '@/models/Bump';
import ArrowBracketIcon from '@/assets/images/icons/ArrowBracketIcon.svg';
import ChevronRightIcon from '@/assets/images/icons/ChevronRightIcon.svg';
import AngleDownIcon from '@/assets/images/icons/AngleDownIcon.svg';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import ProductTabs from './partials/ProductTabs';
import CloneProductModal from './partials/modals/CloneProductModal';
import NewProductModal from './partials/modals/NewProductModal';
import TrackingModal from './partials/modals/TrackingModal';
import ProjectionsModal from './partials/modals/ProjectionsModal';


export default {
  middleware: 'auth',
  scrollToTop: true,
  metaInfo() {
    return { title: 'Products' };
  },
  components: {
    ArrowBracketIcon,
    ChevronRightIcon,
    PlusIcon,
    ProductTabs,
    AngleDownIcon,
    CloneProductModal,
    NewProductModal,
    TrackingModal,
    ProjectionsModal
  },
  data() {
    return {
      bumpIp: null,
      productId: null,
      funnels: [],
      showCloningModal: false,
      selectedProductForCloning: null,
      selectedOrder: 'created_at',
      funnel: null,
      selectedProduct: null,
      products: [],
      bumps: [],
      object: {
        name: 'Options',
      },
      checkoutFlow: 0,
      selectedCheckoutFlow: 0,
      trackingFlow: 0,
      selectedTrackingFlow: 0,
      proofWidgets: [],
      checkoutLinksIndex: 0,
      model: {
        checkout_links: [],
      },
      multiPricePointLink: null,
      selectedCheckoutStepType: 1,
      bumpsTableColumns: ['name', 'created_at', 'actions'],
      
      pricepointsForProjections: [],
      selectedProductId: 0,
      selectedFunnelId: 0,
      funnels: [],
      productTabs: [
        {
          title: this.$t('frontend_product_index_tab_all_products'),
          value: 'all',
          show: true
        },
        {
          title: this.$t('frontend_product_index_tab_frontend'),
          value: 'frontend',
          show: true
        },
        {
          title: this.$t('frontend_product_index_tab_upsell_downsell'),
          value: 'upsell_downsell',
          show: true
        },
        {
          title: this.$t('frontend_product_index_tab_order_bumps'),
          value: 'order_bumps',
          show: true
        },
        {
          title: this.$t('frontend_product_index_tab_atc_order_bumps'),
          value: 'atc_order_bumps',
          show: true
        }
      ],
      productsTab: 'all',
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.admin;
    },
    getProductTabs() {
      return this.productTabs.filter(tab => tab.show)
    },
    sortOrderOptions() {
      return [
        { value: 'created_at', text: this.$t('frontend_groovesell_created_oldest_to_newest') },
        { value: '-created_at', text: this.$t('frontend_groovesell_created_newest_to_oldest') },
        { value: '-updated_at', text: this.$t('frontend_groovesell_updated_oldest_to_newest') },
        { value: 'updated_at', text: this.$t('frontend_groovesell_updated_newest_to_oldest') },
        { value: 'name', text: this.$t('frontend_name') + ' (A-Z)' },
        { value: '-name', text: this.$t('frontend_name') + ' (Z-A)' },
      ];
    },
  },
  async created() {
    this.$wait.start('products');
    this.funnel = await Funnel.$find(this.$route.params.id);
    this.products = await this.funnel.products().$get();
    this.bumps = await this.funnel.bumps().$get();
    this.$wait.end('products');
  },
  methods: {
    async updateProducts(modal) {
      this.$loader.start('updateProducts');
      this.products = await this.funnel.products().$get();
      this.$root.$emit('modal-close', modal);
      this.$loader.end('updateProducts');
    },

    async changedSortOrder() {
      this.$loader.start('changedSortOrder');
      this.products = await this.funnel
        .products()
        .orderBy(this.selectedOrder)
        .$get();
      this.bumps = await this.funnel.bumps().orderBy(this.selectedOrder).$get();
      this.$loader.end('changedSortOrder');
    },

    handleProductsTabChange(newTab) {
      this.productsTab = newTab;
    },

    sendToDashboard() {
      this.$router.push({ name: 'funnelDashboard' });
    },
    async openProjectionsModal(productId) {
      this.$loader.start('openProjectionsModal');
      this.selectedProduct = await this.funnel.products().$find(productId);
      this.pricepointsForProjections = await this.selectedProduct
        .pricepoints()
        .$get();

      this.$nextTick(() => {
        this.$root.$emit('modal-open', 'projections-modal');
      });
      this.$loader.end('openProjectionsModal');
    },

    async openTrackingModal(productId) {
      this.$loader.start('openTrackingModal');
      this.selectedProduct = await this.funnel.products().$find(productId);
      this.proofWidgets = await this.selectedProduct.proof_widgets().$get();
      if (this.proofWidgets.length > 0) {
        this.proofWidgetsTab = `proof_widget_${this.proofWidgets[0].id}`;
      } else {
        this.proofWidgetsTab = '';
      }

      this.$loader.end('openTrackingModal');

      this.trackingFlow = 'Tracking Link';
      this.selectedCheckoutFlow = 0;
      this.$root.$emit('modal-open', 'tracking-modal');
    },
    async openCloneProduct(productId) {
      this.$loader.start('openCloneProduct');
      this.selectedFunnelId = this.funnel.id;
      this.funnels = await Funnel.$get();
      this.selectedProductForCloning = this.products.find((product) => { return product.id == productId });
      this.$loader.end('openCloneProduct');
      this.$nextTick(() => {
        this.$root.$emit('modal-open', 'clone-product-modal');
      })

    },

    createProduct() {
      this.$root.$emit('modal-open', 'new-product-modal');
    },
    openDeleteBumpConfirmModal(id) {
      this.bumpIp = id;
      this.$root.$emit('modal-open', 'confirm-delete-bump-modal');
    },
    openDeleteProductConfirmModal(id) {
      this.productId = id;
      this.$root.$emit('modal-open', 'confirm-delete-product-modal');
    },
   async deleteBump() {
      const bump = await Bump.$find(this.bumpIp);
      await bump.delete();
      const deleted_product = this.bumps.map((item) => item.id).indexOf(this.bumpIp);
      this.bumps.splice(deleted_product, 1);
    },

    async makeMainProduct(productId) {
      const { data } = await axios.post('/main-product', {
        id: productId,
      });
      if (data == 1) {
        this.products = await this.funnel.products().$get();
      }
    },

    async deleteProduct() {
      const product = await Product.$find(this.productId);
      await product.delete();
      const deleted_product = this.products
        .map((item) => item.id)
        .indexOf(this.productId);
      this.products.splice(deleted_product, 1);
    },
  },
};
</script>



<style lang="scss">
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.incompleteProduct {
  color: #fff !important;
}

#checkoutLinksWizard {
  width: 100%;
}

#checkoutLinksWizard i.wizard-icon {
  font-style: inherit;
}

#checkoutLinksWizard .wizard-nav-pills li {
  margin-bottom: 20px;
}

#checkoutLinksWizard .wizard-tab-content {
  padding-top: 10px;
  min-width: 90%;
}

#checkoutLinksWizard .wizard-header,
#checkoutLinksWizard .wizard-card-footer {
  padding-top: 0px !important;
}

#products-list-view,
#upsells-list-view,
#order-bumps-list-view,
#bumps-list-view,
#price-points-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row>td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count {
    display: none;
  }

  .VueTables__limit {
    display: none;
  }
}

.back-arrow {
  transform: rotate(180deg);

  path {
    fill: #a4a8c0;
  }
}
</style>
