<template>
    <div>
        <div v-if="pluginSiteData.integrations.length > 0 &&availableIntegrations.length > 0 ">
            <div class="flex flex-col">
                <div class="flex flex-row-reverse w-full">
                    <gd-button size="auto" variant="primary" class="mr-4" @click="showAffiliateRegistrationIntegrationBox()">{{
                        $t('frontend_product_step9_add_new_integration')
                    }}</gd-button>
                </div>
                <div v-if="localModel.affiliate_registration_integrations &&
                        localModel.affiliate_registration_integrations.length > 0
                        " class="mt-6">
                    <div v-for="(affiliateRegistrationIntegration,affiliateRegistrationIntegrationIndex) in localModel.affiliate_registration_integrations" :key="affiliateRegistrationIntegrationIndex"
                        class="flex flex-col mt-4">
                        <div class="flex flex-row" :class="{
                            'border-t border-grey-400 border-solid pt-4':
                                affiliateRegistrationIntegrationIndex > 0,
                        }">
                            <div class="w-1/12 mr-8">{{ $t('frontend_groovesell_addTo') }}</div>
                            <div class="flex flex-row w-11/12">
                                <div class="w-3/4 flex flex-col">
                                    <div class="flex flex-row">
                                        <div class="font-bold mr-2">{{ $t('frontend_type') }} :</div>
                                        <div>
                                            {{
                                                affiliateRegistrationIntegration.platform
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                affiliateRegistrationIntegration.platform.slice(
                                                    1
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div v-for="(
                              affiliateRegistrationIntegrationConfig,
                                      affiliateRegistrationIntegrationConfigIndex
                            ) in affiliateRegistrationIntegration.config"
                                        :key="affiliateRegistrationIntegrationConfigIndex" class="flex flex-row">
                                        <div class="font-bold mr-2">
                                            {{ affiliateRegistrationIntegrationConfig.label }}
                                            :
                                        </div>
                                        <div>
                                            {{ affiliateRegistrationIntegrationConfig.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="w-1/4 flex flex-row justify-end pr-4">
                                    <gd-button size="icon" variant="primary" :title="$t('frontend_groovesell_delete_integration')"
                                        class="mr-2" @click="
                                            deleteAffiliateRegistrationIntegration(
                                                affiliateRegistrationIntegrationIndex
                                            )
                                            ">
                                        <trash-icon class="h-5 w-5 fill-current"/>
                                    </gd-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="mt-6">
                    <label class="text-grey-dark">{{
                        $t('frontend_product_step9_no_integrations')
                    }}</label>
                </div>
            </div>
        </div>
        <div v-else>
            <label class="text-grey-dark">{{
                $t('frontend_product_step9_no_integrations_added')
            }}</label>
        </div>
    </div>
</template>
<script>
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
    components: {
        TrashIcon
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        validation: {
            required: true
        },
        showAffiliateRegistrationIntegrationBox: {
            type: Function,
            required: true
        },
        deleteAffiliateRegistrationIntegration: {
            type: Function,
            required: true
        },
        pluginSiteData: {
            required: true
        },
        availableIntegrations: {
            required: true
        }

    },
    watch: {
        model: {
            handler() {
                this.$emit('update-model', this.localModel);
            },
            deep: true
        }
    },
    data() {
        return {
            localModel: this.model,
            pricePointsTab: ''
        }
    },
    methods: {
        handlePricePointsTabChange(tab) {
            this.pricePointsTab = tab;
        }

    },

}
</script>