<template>
    <gd-modal name="add-commission-advance-modal" :title="$t('frontend_groovesell_add_commision_advance')"
        bg-type="regular">
        <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-full px-4">
                <div class="mt-2">
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_amount')
                        }}</label>
                        <div class="w-2/3">
                            <gd-currency-input v-model="commissionAdvance.amount" currency-code="USD" />
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_transaction')
                        }}</label>
                        <text-input v-model="commissionAdvance.transactionId"
                            :validator="validation.commissionAdvance.transactionId"
                            :attribute="$t('frontend_affiliate_index_transaction_id')" class="w-2/3"
                            value="commissionAdvance.transactionId"
                            :placeholder="$t('frontend_affiliate_index_transaction_id')" />
                    </div>
                    <div class="w-full flex flex-row mb-6">
                        <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                            $t('frontend_affiliate_index_comment')
                        }}</label>
                        <text-input v-model="commissionAdvance.comment" :validator="validation.commissionAdvance.comment"
                            :attribute="$t('frontend_affiliate_index_comment')" class="w-2/3"
                            value="commissionAdvance.comment" :placeholder="$t('frontend_affiliate_index_comment')" />
                    </div>
                    <div class="flex flex-row w-full mb-6 justify-end">
                        <gd-button size="auto" class="mb-4" variant="primary" @click="addCommissionAdvance">{{
                            $t('frontend_affiliate_index_add_commision_advance')
                        }}</gd-button>
                    </div>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        selectedAffiliate: {
            type: Object,
            default: null
        },
        addCommissionAdvance: {
            type: Function,
            default: null
        },
        validation: {
            required: true,
        }
    },
    data() {
        return {
            commissionAdvance: {
                amount: 0,
                transactionId: '',
                comment: ''
            }
        }
    },
    watch: {
        commissionAdvance: {
            handler: function (val, oldVal) {
                this.validation.commissionAdvance.$touch();
                this.$emit('update-commission-advance', val);
            },
            deep: true

        }
    },
}
</script>