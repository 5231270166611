var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-row mb-2 border-grey-100 border -mx-4 px-4 -mt-2"},[_c('gd-tabs',{attrs:{"tabs":_vm.pricePointsTabs,"error-tabs":_vm.pricePointsErrorTabs,"current-tab":_vm.pricePointsTab,"wrapper-class":'pt-4 px-0 gs-default-tabs',"tab-class":'pb-4 mr-4 gs-default-tabs-item',"tab-active-class":'border-primary border-b-2 text-primary gs-default-tabs-item-active',"line-class":'gs-default-tabs-active-line'},on:{"onClick":_vm.handlePricePointsTabChange}})],1),_c('div',{staticClass:"bg-white rounded p-2 mb-2"},_vm._l((_vm.integration.price_points),function(pricePoint,index){return _c('div',{key:index},[(_vm.pricePointsTab ===
                'data_sharing_product_price_point_' + pricePoint.id
                )?_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-col w-full bg-white mb-2"},[(_vm.pluginSiteData.integrations.length > 0 && _vm.availableIntegrations.length > 0)?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row-reverse w-full"},[_c('gd-button',{staticClass:"mr-4",attrs:{"size":"auto","variant":"primary"},on:{"click":function($event){return _vm.showPostSaleIntegrationBox(index)}}},[_vm._v(_vm._s(_vm.$t('frontend_product_step8_add_new')))])],1),(pricePoint.affiliate_integration &&
                                    pricePoint.affiliate_integration.length > 0
                                    )?_c('div',{staticClass:"mt-6"},_vm._l((pricePoint.affiliate_integration),function(postSaleIntegration,postSaleIntegrationIndex){return _c('div',{key:postSaleIntegrationIndex,staticClass:"flex flex-col mt-4"},[_c('div',{staticClass:"flex flex-row",class:{
                                        'border-t border-grey-400 border-solid pt-4':
                                            postSaleIntegrationIndex > 0,
                                    }},[_c('div',{staticClass:"w-1/12 mr-8"},[_vm._v(_vm._s(_vm.$t('frontend_groovesell_addTo')))]),_c('div',{staticClass:"flex flex-row w-11/12"},[_c('div',{staticClass:"w-3/4 flex flex-col"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(_vm.$t('frontend_product_step8_type'))+" ")]),_c('div',[_vm._v(" "+_vm._s(postSaleIntegration.platform .charAt(0) .toUpperCase() + postSaleIntegration.platform.slice(1))+" ")])]),_vm._l((postSaleIntegration.config),function(postSaleIntegrationConfig,postSaleIntegrationConfigIndex){return _c('div',{key:postSaleIntegrationConfigIndex,staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" "+_vm._s(postSaleIntegrationConfig.label)+" : ")]),_c('div',[_vm._v(" "+_vm._s(postSaleIntegrationConfig.name)+" ")])])})],2),_c('div',{staticClass:"w-1/4 flex flex-row justify-end pr-4"},[_c('gd-button',{staticClass:"mr-2",attrs:{"size":"icon","variant":"primary","title":_vm.$t('frontend_groovesell_delete_integration')},on:{"click":function($event){return _vm.deletePostSaleIntegration(
                                                            index,
                                                            postSaleIntegrationIndex
                                                        )}}},[_c('trash-icon',{staticClass:"w-5 h-5 fill-current"})],1)],1)])])])}),0):_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step8_no_integrations')))])])])]):_c('div',[_c('label',{staticClass:"text-grey-dark"},[_vm._v(_vm._s(_vm.$t('frontend_product_step8_no_integrations_added')))])])])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }