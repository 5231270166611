import store from '@/store';
import Model from './Model';

const ISDEMOMODE = 'internal/isDemoMode';

export default class Transaction extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'transactions';
  }

  get product_name() {
    if (this.product && this.product != null) return this.product.name;
  }

  get rendered_phone() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxx-xxx-xxxx';
    }

    return this.buyer_phone;
  }

  get funnel_name() {
    if (this.funnel && this.funnel != null) return this.funnel.name;
  }

  get rendered_buyer_company() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx';
    }

    return this.buyer_company;
  }

  get rendered_buyer_address() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx';
    }

    return this.buyer_address;
  }

  get rendered_buyer_email() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxx@xxxx.xxx';
    }

    return this.buyer_email;
  }

  get rendered_buyer_fullname() {
    if (store.getters[ISDEMOMODE]) {
      return 'xxxxxxxx';
    }

    return this.buyer_fullname;
  }

  get rendered_affiliate_name() {
    if (store.getters[ISDEMOMODE] && this.affiliate_name) {
      return 'xxxxxxxxx';
    }

    return this.affiliate_name;
  }

  get rendered_affiliate_email() {
    if (store.getters[ISDEMOMODE] && this.affiliate_email) {
      return 'xxxxxxxxx@xxxx.xxxx';
    }

    return this.affiliate_email;
  }

  get rendered_affiliate_username() {
    if (store.getters[ISDEMOMODE] && this.affiliate_username) {
      return 'xxxxxxxxxx';
    }

    return this.affiliate_username;
  }

  get rendered_second_tier_affiliate_username() {
    if (
      store.getters[ISDEMOMODE] &&
      this.second_tier_affiliate_username
    ) {
      return 'xxxxxxxxx';
    }

    return this.second_tier_affiliate_username;
  }

  get rendered_second_tier_affiliate_email() {
    if (
      store.getters[ISDEMOMODE] &&
      this.second_tier_affiliate_email
    ) {
      return 'xxxxxxxxx@xxxx.xxxx';
    }

    return this.second_tier_affiliate_email;
  }

  get rendered_second_tier_affiliate_name() {
    if (
      store.getters[ISDEMOMODE] &&
      this.second_tier_affiliate_name
    ) {
      return 'xxxxxxxxxx';
    }

    return this.second_tier_affiliate_name;
  }

  get groovecm_render_phone() {
    if (
      this.groovecm_customer &&
      this.groovecm_customer != null &&
      this.groovecm_customer.phone &&
      this.groovecm_customer.phone != null
    )
      return this.groovecm_customer.phone;
  }

  get groovecm_render_address() {
    if (
      this.groovecm_customer &&
      this.groovecm_customer != null &&
      this.groovecm_customer.address &&
      this.groovecm_customer.address != null
    )
      return this.groovecm_customer.address;
  }

  get groovecm_render_country() {
    if (
      this.groovecm_customer &&
      this.groovecm_customer != null &&
      this.groovecm_customer.country &&
      this.groovecm_customer.country != null
    )
      return this.groovecm_customer.country;
  }

  get groovecm_render_state() {
    if (
      this.groovecm_customer &&
      this.groovecm_customer != null &&
      this.groovecm_customer.state &&
      this.groovecm_customer.state != null
    )
      return this.groovecm_customer.state;
  }
}
