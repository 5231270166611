<template>
  <v-wait for="assets">
    <div slot="waiting">
      <div class="mt-4 text-lg text-grey-700 font-medium text-center w-full">
        {{ $t('frontend_resource_promo_connecting_to_groovesell', {appTitle: whitelabelAppData('sell').title}) }}
      </div>
    </div>

    <promo-tools-header
      :assets-tab="assetsTab"
      :funnel-resource="funnelResource"
      :related-funnels="relatedFunnels"
      :other-resources="otherResources"
      :selected-promo-link="selectedPromoLink"
      :is-registered="isRegistered"
      :direct-affiliate-links="directAffiliateLinks"
      :assets-tabs="assetsTabs"
      :user-name="userName"
      :show-elements="showElements"
      @goBackToPromoToolsNavigation="goBackToPromoToolsNavigation"
      @changedPromoTool="changedPromoTool"
      @onCopy="onCopy"
      @onError="onError"
      @openPromotoolsSection="openPromotoolsSection"
      @logoutAffiliate="logoutAffiliate"
      @onToggleSwitchValue="onToggleSwitchValue"
      @changedFunnel="changedFunnel"
    />

    <transition name="fade">
      <div
        v-if="isRegistered"
        :class="{'flex flex-row mb-2 px-4 border-grey-100 border-t' : !showElements}"
        :style="!showElements ? 'height: calc(80vh - 15rem)' : ''"
      >
        <div v-if="assetsTab === ''" class="mt-2 w-full" :class="{'overflow-y-auto overflow-x-hidden': !showElements}">
          <main-tab
            :assets-tabs="assetsTabs"
            :funnel-resource="funnelResource"
            :show-elements="showElements"
            @openPromotionalResourcesPage="openPromotionalResourcesPage"
            @openPromotoolsSection="openPromotoolsSection"
          />
        </div>
        <div v-else class="mt-2 w-full" :class="{'overflow-y-auto overflow-x-hidden': !showElements}">
          <promotools-tabs
            :assets-tab="assetsTab"
            :funnel-resource="funnelResource"
            :direct-affiliate-links="directAffiliateLinks"
            :swipes-tab="swipesTab"
            :current-shown-swipes-quill-editor-index="currentShownSwipesQuillEditorIndex"
            :blog-reviews-tab="blogReviewsTab"
            :current-shown-blog-reviews-quill-editor-index="currentShownBlogReviewsQuillEditorIndex"
            :thankyou-page-ads-tab="thankyouPageAdsTab"
            :logout-page-ads-tab="logoutPageAdsTab"
            :press-releases-tab="pressReleasesTab"
            :facebook-ads-tab="facebookAdsTab"
            :articles-tab="articlesTab"
            :video-reviews-tab="videoReviewsTab"
            :email-signatures-tab="emailSignaturesTab"
            :forum-signatures-tab="forumSignaturesTab"
            :facebook-posts-tab="facebookPostsTab"
            :twitter-tweets-tab="twitterTweetsTab"
            :linkedin-posts-tab="linkedinPostsTab"
            @handleTabsChange="handleTabsChange"
            @updateCurrentShownSwipesQuillEditorIndex="updateCurrentShownSwipesQuillEditorIndex"
            @updateCurrentShownBlogReviewsQuillEditorIndex="updateCurrentShownBlogReviewsQuillEditorIndex"
            @onCopy="onCopy"
            @onError="onError"
          />
        </div>
      </div>
      <div v-else>
        <default-tab
          :funnel-resource="funnelResource"
          :selected-promo-link="selectedPromoLink"
          @changedFunnel="changedFunnel"
        />
      </div>
    </transition>
  </v-wait>
</template>

<script>
import axios from 'axios';
import 'js-video-url-parser/lib/provider/youtube';
import 'js-video-url-parser/lib/provider/vimeo';
import LinksIcon from '@/assets/images/icons/LinkIcon.svg';
import SwipesIcon from '@/assets/images/icons/EnvelopIcon.svg';
import BlogIcon from '@/assets/images/icons/BlogIcon.svg';
import BannerIcon from '@/assets/images/icons/MapIcon.svg';
import LogoutPageAdIcon from '@/assets/images/icons/LogoutIcon.svg';
import ThankYouPageAdIcon from '@/assets/images/icons/ReceiptIcon.svg';
import FacebookAdIcon from '@/assets/images/icons/AdIcon.svg';
import SocialMediaPostsIcon from '@/assets/images/icons/FacebookIcon.svg';
import ArticlesIcon from '@/assets/images/icons/NewspaperIcon.svg';
import VideoReviewsIcon from '@/assets/images/icons/VideoCameraIcon.svg';
import SignaturesIcon from '@/assets/images/icons/SignatureIcon.svg';
import ChartBarIcon from '@/assets/images/icons/ChartBarIcon.svg';
import Notify from '@groovepages/gd-ui-kit/notify';
import auth from '@groovepages/gd-lib/auth';
import FunnelResource from '@/models/FunnelResource';
import PromoToolsHeader from './partials/PromoToolsHeader.vue';
import MainTab from './partials/tabs/MainTab.vue';
import PromotoolsTabs from './partials/PromotoolsTabs.vue';
import DefaultTab from './partials/tabs/DefaultTab.vue';

export default {
  components: {
    PromoToolsHeader,
    MainTab,
    PromotoolsTabs,
    DefaultTab,
  },
  props: {
    showElements: {
      type: Boolean,
      default: true,
    },
    permalink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      resourceId: this.permalink || this.$route.params.resource_id,
      isRegistered: 1,
      relatedFunnels: [],
      otherResources: [],
      iframeHeight: '200px',
      userName: '',
      funnel: null,
      funnelResource: null,
      assetsTabs: [],
      assetsTab: '',
      swipesTab: '',
      facebookPostsTab: '',
      videoReviewsTab: '',
      twitterTweetsTab: '',
      linkedinPostsTab: '',
      emailSignaturesTab: '',
      forumSignaturesTab: '',
      pressReleasesTab: '',
      articlesTab: '',
      blogReviewsTab: '',
      thankyouPageAdsTab: '',
      logoutPageAdsTab: '',
      facebookAdsTab: '',
      selectedPromoLink: {
        funnel: '',
        permalink: '',
      },
      currentShownSwipesQuillEditorIndex: -1,
      currentShownThankYouQuillEditorIndex: -1,
      currentShownBlogReviewsQuillEditorIndex: -1,
      currentShownLogoutPageAdQuillEditorIndex: -1,
    };
  },
  computed: {
    directAffiliateLinks() {
      if (this.funnelResource && this.funnelResource.display_links) {
        return this.funnelResource.display_links.filter((link) => {
          return link.type == 'affiliate';
        });
      }
    },
  },
  async created() {
    this.$wait.start('assets');
    const loggedInUser = auth.authenticatedUser();
    this.userName = loggedInUser.name;
    await this.fetchFunnelResource(this.resourceId);
    if (
      this.funnelResource &&
      this.funnelResource.show_more_funnels &&
      this.funnelResource.related_funnels.length &&
      this.funnelResource.related_funnels.length > 0
    ) {
      this.relatedFunnels = this.funnelResource.related_funnels;
    } else {
      this.relatedFunnels = [];
    }

    if (
      this.funnelResource &&
      this.funnelResource.remaining_resources &&
      this.funnelResource.remaining_resources.length &&
      this.funnelResource.remaining_resources.length > 0
    ) {
      this.otherResources = this.funnelResource.remaining_resources;
    } else {
      this.otherResources = [];
    }

    this.$wait.end('assets');
  },
  methods: {
    updateCurrentShownSwipesQuillEditorIndex(index) {
      this.currentShownSwipesQuillEditorIndex = index;
    },
    updateCurrentShownBlogReviewsQuillEditorIndex(index) {
      this.currentShownBlogReviewsQuillEditorIndex = index;
    },
    onToggleSwitchValue(data) {
      this.assetsTabs = data.assetsTabs;
      this.funnelResource = data.funnelResource;
    },
    openPromotionalResourcesPage() {
      const pagesAppTitle = this.whitelabelAppData('pages').title.toLowerCase();
      window.open(
        `https://hrc.${pagesAppTitle}.com/gpiframes/page-28`,
        '_blank'
      );
    },
    load() {
      this.iframeHeight = `${
        window.innerHeight -
        this.$refs.iframeContainer.getBoundingClientRect().top
      }px`;
    },
    logoutAffiliate() {
      auth.logout();
      this.$emit('logoutAffiliate');
    },
    goBackToPromoToolsNavigation() {
      this.currentShownSwipesQuillEditorIndex = -1;
      this.currentShownThankYouQuillEditorIndex = -1;
      this.currentShownBlogReviewsQuillEditorIndex = -1;
      this.currentShownLogoutPageAdQuillEditorIndex = -1;
      this.assetsTab = '';
    },
    openPromotoolsSection(sectionName) {
      this.assetsTab = sectionName;
    },
    async changedFunnel(selectedPromoLinkFunnel = null) {
      const selectedFunnelId = selectedPromoLinkFunnel || this.selectedPromoLink.funnel;
      const selectedfunnelResourcePermalink = await FunnelResource.params({
        funnel: selectedFunnelId,
      })
        .custom('fetch-default-funnel-resource')
        .$first();

      this.resourceId = selectedfunnelResourcePermalink.funnel_resource.permalink;
      this.otherResources = selectedfunnelResourcePermalink.funnel_resource.remaining_resources;

      this.selectedPromoLink.permalink = this.resourceId;
      this.fetchFunnelResource(this.selectedPromoLink.permalink);
    },
    async fetchFunnelResource(resourceId) {
      const funnelResourceData = await axios.get(`fetch-funnel-resource`, {
        params: {
          permalink: resourceId,
        },
      });
      if (funnelResourceData.data.status != 'success') {
        this.isRegistered = 0;
      } else {
        this.funnelResource = funnelResourceData.data.funnel_resource;
        this.selectedPromoLink.permalink = resourceId;

        this.selectedPromoLink.funnel = this.funnelResource.funnel_id;
        this.assetsTabs = [];
        this.assetsTabs.push({
          title: 'Links',
          value: 'links',
          icon: LinksIcon,
          completed: 0,
          id: 0,
        });

        this.assetsTabs.push({
          title: 'Stats',
          value: 'stats',
          icon: ChartBarIcon,
          completed: 0,
          id: -1,
        });

        this.assetsTabs.push({
          title: 'Swipes',
          value: 'swipes',
          icon: SwipesIcon,
          completed: this.funnelResource.affiliate_resource.swipes_completed,
          id: 1,
        });

        this.assetsTabs.push({
          title: `Blog Reviews`,
          value: 'blogReviews',
          icon: BlogIcon,
          completed:
            this.funnelResource.affiliate_resource.blog_reviews_completed,
          id: 2,
        });

        this.assetsTabs.push({
          title: `Articles`,
          value: 'allArticles',
          icon: ArticlesIcon,
          completed:
            this.funnelResource.affiliate_resource.press_releases_completed,
          id: 8,
        });

        this.assetsTabs.push({
          title: `Banners`,
          value: 'banners',
          icon: BannerIcon,
          completed: this.funnelResource.affiliate_resource.banners_completed,
          id: 3,
        });

        this.assetsTabs.push({
          title: `Thank You Page Ads`,
          value: 'thankyouPageAds',
          icon: ThankYouPageAdIcon,
          completed:
            this.funnelResource.affiliate_resource.thankyou_page_ads_completed,
          id: 4,
        });

        this.assetsTabs.push({
          title: `Logout Page Ads`,
          value: 'logoutPageAds',
          icon: LogoutPageAdIcon,
          completed:
            this.funnelResource.affiliate_resource.logout_page_ads_completed,
          id: 5,
        });

        this.assetsTabs.push({
          title: `Social Media`,
          value: 'socialMediaPosts',
          icon: SocialMediaPostsIcon,
          completed:
            this.funnelResource.affiliate_resource.social_media_posts_completed,
          id: 7,
        });

        this.assetsTabs.push({
          title: `Video Reviews`,
          value: 'videoReviews',
          icon: VideoReviewsIcon,
          completed:
            this.funnelResource.affiliate_resource.video_reviews_completed,
          id: 9,
        });

        this.assetsTabs.push({
          title: `Facebook Ads`,
          value: 'facebookAds',
          icon: FacebookAdIcon,
          completed:
            this.funnelResource.affiliate_resource.facebook_ads_completed,
          id: 6,
        });

        this.assetsTabs.push({
          title: `Signatures`,
          value: 'signatures',
          icon: SignaturesIcon,
          completed:
            this.funnelResource.affiliate_resource.signatures_completed,
          id: 10,
        });

        if (
          this.funnelResource &&
          this.funnelResource.swipes &&
          this.funnelResource.swipes.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.swipesTab = `swipe_tab_${
            this.funnelResource.swipes.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.thankyou_page_ads &&
          this.funnelResource.thankyou_page_ads.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.thankyouPageAdsTab = `thankyou_page_ad_tab_${
            this.funnelResource.thankyou_page_ads.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.signatures.email &&
          this.funnelResource.signatures.email.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.emailSignaturesTab = `email_signature_tab_${
            this.funnelResource.signatures.email.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.signatures.forum &&
          this.funnelResource.signatures.forum.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.forumSignaturesTab = `forum_signature_tab_${
            this.funnelResource.signatures.forum.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.social_media_posts.facebook &&
          this.funnelResource.social_media_posts.facebook.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.facebookPostsTab = `fb_post_tab_${
            this.funnelResource.social_media_posts.facebook.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.social_media_posts.twitter &&
          this.funnelResource.social_media_posts.twitter.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.twitterTweetsTab = `twitter_tweet_tab_${
            this.funnelResource.social_media_posts.twitter.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.social_media_posts.linkedin &&
          this.funnelResource.social_media_posts.linkedin.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.linkedinPostsTab = `linkedin_post_tab_${
            this.funnelResource.social_media_posts.linkedin.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.blog_reviews &&
          this.funnelResource.blog_reviews.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.blogReviewsTab = `blog_review_tab_${
            this.funnelResource.blog_reviews.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.facebook_ads &&
          this.funnelResource.facebook_ads.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.facebookAdsTab = `facebook_ad_tab_${
            this.funnelResource.facebook_ads.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.logout_page_ads &&
          this.funnelResource.logout_page_ads.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.logoutPageAdsTab = `logout_page_ad_tab_${
            this.funnelResource.logout_page_ads.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.press_releases &&
          this.funnelResource.press_releases.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.pressReleasesTab = `press_release_tab_${
            this.funnelResource.press_releases.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.articles &&
          this.funnelResource.articles.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.articlesTab = `articles_tab_${
            this.funnelResource.articles.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        if (
          this.funnelResource &&
          this.funnelResource.video_reviews &&
          this.funnelResource.video_reviews.filter((el) => {
            return el.enabled;
          }).length > 0
        ) {
          this.videoReviewsTab = `video_review_tab_${
            this.funnelResource.video_reviews.filter((el) => {
              return el.enabled;
            })[0].id
          }`;
        }

        this.isRegistered = 1;
      }
    },
    changedPromoTool(selectedPromoLinkPermalink) {
      this.resourceId = selectedPromoLinkPermalink;
      this.fetchFunnelResource(selectedPromoLinkPermalink);
    },
    handleTabsChange(newTab, tabName) {
      this[tabName] = newTab;
    },
    onCopy() {
      Notify.success(this.$t('successfully_copied'));
    },
    onError() {
      Notify.fail(this.$t('frontend_unable_copy_to_clipboard'));
    },
  },
  watch: {
    permalink() {
      this.resourceId = this.permalink;
    },
  },
};
</script>
