<template>
  <div class="app-tiles-container">
    <div class="w-full">
      <v-wait for="funnels">
        <funnels-assets-list
          :funnels="funnels"
          :selected-order="selectedOrder"
          :products="products"
          @updateFunnels="funnels = $event"
          @updateSelectedOrder="selectedOrder = $event"
          @onManageProducts="products"
          @onCloneFunnels="openCloneFunnelModel"
          @onEditFunnel="openEditFunnelModel"
        />

        <create-edit-funnel-modal
          v-if="funnel"
          :senders="senders"
          :categories-list="categoriesList"
          :domains="domains"
          :funnel="funnel"
          @onFunnelUpdated="funnelUpdated"
        />

        <clone-funnel-modal
          v-if="originalFunnel"
          :original-funnel="originalFunnel"
        />

        <portal to="headerContent">
          <gd-button variant="primary" class="mr-4" @click="openCreateFunnel">{{
            $t('frontend_funnels_new_product')
          }}</gd-button>
        </portal>
      </v-wait>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Funnel from '@/models/Funnel';
import FunnelsAssetsList from './index-partials/FunnelsAssetsList';
import CloneFunnelModal from './index-partials/CloneFunnelModal.vue';
import CreateEditFunnelModal from './index-partials/CreateEditFunnelModal.vue';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('my_product_funnels') };
  },
  components: {
    FunnelsAssetsList,
    CloneFunnelModal,
    CreateEditFunnelModal,
  },
  data() {
    return {
      selectedOrder: 'created_at',
      categoriesList: [],
      funnel: null,
      originalFunnel: null,
      funnels: [],
      domains: [],
      senders: [],
    };
  },
  computed: {
    admin() {
      return this.$store.state.user.admin;
    },
  },
  async created() {
    this.$wait.start('funnels');
    this.funnels = await Funnel.orderBy(this.selectedOrder).$get();
    const userDomains = await axios.get(`get-user-domains/1`);
    this.domains = userDomains?.data?.data;    
    const mailAppUrl = this.whitelabelAppData('mail').url;
    const url = `${window.gdEnv.VUE_APP_API_URL}${mailAppUrl}sender?verify=1`;
    this.senders = await axios.get(url,{
      user : this.$store.state.user
    });

    const marketplaceCategories = await axios.get(`marketplace-categories`);
    this.categoriesList = marketplaceCategories.data;
    this.$wait.end('funnels');

    if (this.$route.query.new && this.$route.query.new == 'modal') {
      this.openCreateFunnel();
    }
  },
  methods: {
    products(funnel_id) {
      this.$router.push({ name: 'products', params: { id: funnel_id } });
    },
    openCreateFunnel() {
      this.funnel = new Funnel({});
    },
    openEditFunnelModel(funnel_id) {
      const funnelIndex = this.funnels.map((item) => item.id).indexOf(funnel_id);

      this.funnel = this.funnels[funnelIndex];
    },
    openCloneFunnelModel(funnel_id) {
      const cloneFunnelId = this.funnels.map((item) => item.id).indexOf(funnel_id);

      this.originalFunnel = this.funnels[cloneFunnelId];
    },
    funnelUpdated(funnel) {
      if(!funnel.id) {
        this.funnel = null;
        return;
      }

      const edited_funnel = this.funnels.map((item) => item.id).indexOf(funnel.id);

      this.funnels[edited_funnel].internal_name = funnel.internal_name;
      this.funnels[edited_funnel].name = funnel.name;
      this.funnels[edited_funnel].description = funnel.description;
      this.funnels[edited_funnel].category = funnel.category;
      this.funnels[edited_funnel].slug = funnel.slug;
      this.funnels[edited_funnel].is_live = funnel.is_live;
      this.funnels[edited_funnel].sender_id = funnel.sender_id;
      this.funnels[edited_funnel].show_in_marketplace = funnel.show_in_marketplace;

      this.funnel = null;
    },
  },
};
</script>
<style lang="scss">
.bg-c-red {
  background-color: #fbe8d7;
  color: #ee9b5d;
}
.bg-c-green {
  background-color: #cfecdb;
  color: #43b06f;
}
.scroll-modal-height {
  max-height: calc(100vh - 100px);
}
.custom-radio {
  .border.p-2 {
    min-height: 65px;
    margin: 0;
    cursor: pointer;
  }
  .w-6 {
    height: 22px;
    margin: 0;
  }
  label {
    cursor: pointer;
  }
  .text-grey-500 {
    font-size: 14px;
  }
}
</style>
