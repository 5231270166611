<template>
    <div>
        <div class="flex flex-row flex-wrap w-full py-4 px-4">
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.affiliate_name &&
                    purchase.affiliate_name.trim() != '' ? purchase.affiliate_name : 'N/A' }}
                </label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate') }}
                    Email</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.affiliate_email && purchase.affiliate_email.trim() != '' ?
                    purchase.affiliate_email : 'N/A'
                }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_username') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.affiliate_username && purchase.affiliate_username.trim() != '' ?
                    purchase.affiliate_username : 'N/A'
                }}</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        purchase: {
            required: true
        }
    }
}
</script>