<template>
    <gd-modal name="add-membership-file-modal" :title="isEditing === false ? 'Add New File' : 'Edit File'">
        <div class="flex flex-col px-4">
            <div class="mt-4">
                <div class="-mt-4">
                    <div class="flex flex-row w-full">
                        <div class="w-1/2 flex flex-col w-full mt-4 mr-4">
                            <label class="font-semibold text-sm text-grey-700 mb-2 text-left">{{
                                $t('frontend_groovesell_select_membership') }}</label>
                            <gd-dropdown
                                v-model="localpostMembershipFileId"
                                size="full"
                                :placeholder="$t('frontend_groovesell_select_file')"
                                :options="membershipFileOptions"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-full mb-6 justify-end">
                <gd-button v-if="isEditing == false" :variant="gdButtonVariant" class="mt-4" size="md"
                    @click="addMembershipFile()">{{ $t('frontend_add') }}</gd-button>
            </div>
        </div>
    </gd-modal>
</template>
<script>
export default {
    props: {
        isEditing: {
            type: Boolean,
            required: true,
        },
        postMembershipFileId: {
            required: true,
        },
        membership_files: {
            required: true,
        },
        post_addbtn: {
            required: true,
        },
        addMembershipFile: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            localpostMembershipFileId: this.postMembershipFileId,
        };
    },
    computed: {
        gdButtonVariant() {
            return this.post_addbtn ? 'primary' : 'base';
        },
        membershipFileOptions() {
            return this.membership_files.map(file => ({ value: file.id, text: file.name }));
        },
    },
    watch: {
        localpostMembershipFileId() {
            this.$emit('update-data', {
                key: 'postMembershipFileId',
                value: this.localpostMembershipFileId,
            });
        },
    },
};

</script>