<template>
  <div>
    <div class="w-full">
      <v-wait for="affiliates">
        <div v-if="dataSource" class="flex flex-row justify-between">
          <div class="flex flex-col w-2/8">
            <div class="flex">
              <div class="w-full">
                <gd-dropdown
                  v-model="selectedFunnel"
                  size="full"
                  :placeholder="$t('frontend_funnels')"
                  :options="funnelOptions"
                  @change="changeFunnel"
                />
              </div>
            </div>
            <div class="flex flex-row mt-4 justify-between space-x-2">
              <date-range-picker
                ref="picker"
                v-model="affiliatesDateRange"
                class="w-full"
                :opens="opens"
                :auto-apply="autoApply"
                :start-date="customStartDate"
                :end-date="customEndDate"
                :ranges="customDateRanges"
                @update="changedSearchOrFilter"
              >
                <div slot="input" slot-scope="picker">
                  {{ picker.startDate | moment('MM/DD/YYYY') }} -
                  {{ picker.endDate | moment('MM/DD/YYYY') }}
                </div>
              </date-range-picker>
              <gd-dropdown
                v-model="selectedRowSize"
                size="small"
                :options="rowSizeOptions"
                @change="onPageSizeChanged()"
              />
            </div>
          </div>
          <div class="flex flex-col w-4/8">
            <div class="flex">
              <text-input
                v-model="searchPhrase"
                v-debounce:1s="changedSearchOrFilter"
                value="searchPhrase"
                :placeholder="$t('frontend_search')"
                :debounce-events="['input', 'keydown']"
              />
            </div>
            <div class="flex flex-row justify-between">
              <div class="w-5/8 flex mr-5 pt-4 items-center">
                <div class="mr-2">
                  {{ $t('frontend_groovesell_show_click_tracking') }}
                </div>
                <gd-toggle
                  v-model="showAnalytics"
                  dark
                  :value="showAnalytics"
                  @change="changedAnalyticsToggle"
                />
              </div>
              <div class="w-3/8 flex pt-4">
                <gd-button
                  variant="primary"
                  size="auto"
                  @click="openAdvancedFilters"
                >
                  <span class="mr-2">
                    <filter-icon class="w-5 h-5 fill-current" />
                  </span>
                  {{ $t('frontend_analytics_transactions_filters') }}
                </gd-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isFunnelAdded && selectedFunnel != 0" id="affiliates-list-view" class="mt-4">
          <ag-grid-vue
            class="ag-theme-material"
            style="width: 100%"
            :dom-layout="domLayout"
            :grid-options="gridOptions"
            :column-defs="affiliatesTableColumnDefs"
            :pagination="true"
            :row-height="affiliatesTableRowHeight"
            :row-buffer="rowBuffer"
            :row-model-type="rowModelType"
            :datasource="dataSource"
            :pagination-page-size="paginationPageSize"
            :cache-overflow-size="cacheOverflowSize"
            :infinite-initial-row-count="infiniteInitialRowCount"
            :max-blocks-in-cache="maxBlocksInCache"
            :cache-block-size="cacheBlockSize"
            :suppress-row-click-selection="true"
            :suppress-cell-selection="true"
            :row-class="rowClass"
            :row-class-rules="rowClassRules"
            :context="context"
            :framework-components="frameworkComponents"
            :row-selection="rowSelection"
            :max-concurrent-datasource-requests="
              maxConcurrentDatasourceRequests
            "
            @grid-ready="onGridReady"
            @model-updated="onModelUpdated"
            @row-selected="onRowSelected"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </div>
        <div v-else>
          <p>{{ $t('frontend_affiliate_no_funnel') }}</p>
        </div>

        <gd-modal
          name="affiliate-detail-modal"
          :title="$t('frontend_groovesell_sub_aff_details')"
          width="80%"
          bg-type="regular"

        >
          <div v-if="selectedAffiliate" style="min-height: 60vh">
            <gd-tabs
              :tabs="affiliateDetailTabs"
              :current-tab="affiliateDetailsTab"
              :wrapper-class="'pt-4 px-4 gs-default-tabs'"
              :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
              :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
              :line-class="'gs-default-tabs-active-line'"
              @onClick="handleAffiliatesDetailTabChange"
            />
            <div v-if="affiliateDetailsTab === 'info'">
              <div class="flex flex-row">
                <div class="flex flex-col w-3/4 px-4">
                  <div class="mt-2">
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.rendered_name
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >Email</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.rendered_email
                        }}</label>
                      </div>
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label class="font-semibold text-sm text-grey-700 mb-2"
                          >Paypal Email</label
                        >
                        <label
                          v-if="selectedAffiliate.affiliate_paypal"
                          class="text-sm text-grey-700 mb-2"
                          >{{
                            selectedAffiliate.rendered_affiliate_paypal
                          }}</label
                        >
                        <label v-else class="text-sm text-grey-700 mb-2"
                          >N/A</label
                        >
                      </div>

                      <div class="w-full flex flex-row">
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_username') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.rendered_username
                          }}</label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_impressions') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.impressions
                          }}</label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_uniques') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.uniques
                          }}</label>
                        </div>
                      </div>

                      <div class="w-full flex flex-row">
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_sales') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.total_sales
                          }}</label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{ $t('frontend_refunds') }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.total_refunds
                          }}</label>
                        </div>
                        <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                          <label
                            class="font-semibold text-sm text-grey-700 mb-2"
                            >{{
                              $t('frontend_groovesell_earned_commissions')
                            }}</label
                          >
                          <label class="text-sm text-grey-700 mb-2">{{
                            selectedAffiliate.human_readable_total_commissions
                          }}</label>
                        </div>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_unpaid_commisions')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_pending_commission
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_advance_clawbacks')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_advances
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_due_commisions') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_processed_without_advances
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_commisions_on_hold')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_on_hold_commission
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_being_processed_commisions')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_in_process_commission
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_amount_paid') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_paid_commission
                        }}</label>
                      </div>
                    </div>
                    <div class="w-full text-red mb-2">
                      {{
                        $t(
                          'frontend_groovesell_affiliate_for_selected_funnel_desc'
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
                  <div class="my-auto flex flex-col">
                    <gd-button
                      size="auto"
                      variant="primary"
                      class="mb-4"
                      @click="openMarkAsPaidModal"
                      >{{
                        $t('frontend_groovesell_process_payment')
                      }}</gd-button
                    >

                    <gd-button
                      size="auto"
                      variant="primary"
                      class="mb-4"
                      @click="openClawbackModal"
                      >{{ $t('frontend_groovesell_add_clawback') }}</gd-button
                    >
                    <gd-button
                      size="auto"
                      variant="primary"
                      class="mb-4"
                      @click="openCommissionAdvanceModal"
                      >{{
                        $t('frontend_groovesell_add_commision_advance')
                      }}</gd-button
                    >
                    <gd-button
                      size="auto"
                      variant="primary"
                      class="mb-4"
                      @click="openCommissionBonusModal"
                      >{{
                        $t('frontend_groovesell_tax_ledger_adjustment')
                      }}</gd-button
                    >
                    <div class="-mt-2 text-center leading-none">
                      <span class="text-xs text-grey-500 italic">
                        {{ $t('frontend_groovesell_this_is_for_tax') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'lifetime'">
              <div class="flex flex-row">
                <div class="flex flex-col w-3/4 px-4">
                  <div class="mt-2">
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_all_time_earned_commision')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_alltime_earned_commission
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_all_time_paid_commision')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_paid_commission
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_all_time_cash_advances')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_commission_advance
                        }}</label>
                      </div>

                      <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{
                            $t('frontend_groovesell_all_time_claw_backs')
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.human_readable_clawbacks
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'transactions'">
              <div
                v-if="
                  selectedAffiliate.transactions &&
                  selectedAffiliate.transactions.length > 0
                "
              >
                <div
                  id="affiliate-transactions-list-view"
                  class="flex flex-row"
                >
                  <v-client-table
                    :data="selectedAffiliate.transactions"
                    :columns="affiliatesTransactionsColumns"
                    :options="affiliatesTransactionsTableOptions"
                  />
                </div>
                <div class="mt-4 px-6 flex justify-end text-blue">
                  <router-link
                    :to="{
                      name: 'transactions',
                      query: {
                        affiliate_id: selectedAffiliate.id,
                        date_range: 'all-time',
                        transaction_type: 'all',
                      },
                    }"
                    target="_blank"
                    >{{
                      $t('frontend_groovesell_see_full_transaction')
                    }}</router-link
                  >
                </div>
              </div>
              <div v-else class="p-4">
                {{ $t('frontend_groovesell_no_transactions_recorded') }}
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'refunds'">
              <div
                v-if="
                  selectedAffiliate.refunds &&
                  selectedAffiliate.refunds.length > 0
                "
              >
                <div id="affiliate-refunds-list-view" class="flex flex-row">
                  <v-client-table
                    :data="selectedAffiliate.refunds"
                    :columns="affiliatesRefundsColumns"
                    :options="affiliatesRefundsTableOptions"
                  />
                </div>
                <div class="mt-4 px-6 flex justify-end text-blue">
                  <router-link
                    :to="{
                      name: 'transactions',
                      query: {
                        affiliate_id: selectedAffiliate.id,
                        date_range: 'all-time',
                        transaction_type: 'refund',
                      },
                    }"
                    target="_blank"
                    >{{ $t('frontend_groovesell_see_full') }}</router-link
                  >
                </div>
              </div>
              <div v-else class="p-4">
                {{ $t('frontend_groovesell_no_refunds') }}
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'links'">
              <div
                v-if="
                  selectedAffiliate.registered_funnels &&
                  selectedAffiliate.registered_funnels.length > 0
                "
              >
                <div id="affiliate-links-list-view" class="flex flex-row">
                  <v-client-table
                    :data="selectedAffiliate.registered_funnels"
                    :columns="affiliatesLinksColumns"
                    :options="affiliatesLinksTableOptions"
                  >
                    <div slot="links" slot-scope="props">
                      <gd-button
                        variant="outline"
                        size="icon"
                        title="Links"
                        class="mr-2"
                        @click="fetchAffiliateLinks(props.row.id)"
                      >
                       <link-icon class="w-5 h-5 fill-current" />
                      </gd-button>
                    </div>
                  </v-client-table>
                </div>
              </div>
              <div v-else class="p-4">
                {{ $t('frontend_groovesell_no_funnels_found') }}
              </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'history'">
              <div
                v-if="
                  selectedAffiliate.payment_bonus_history &&
                  selectedAffiliate.payment_bonus_history.length > 0
                "
                id="affiliate-payments-list-view"
                class="flex flex-row"
              >
                <v-client-table
                  :data="selectedAffiliate.payment_bonus_history"
                  :columns="affiliatesPaymentsColumns"
                  :options="affiliatesPaymentsTableOptions"
                >
                  <div slot="note" slot-scope="props" class="inline-block">
                    <div
                      v-if="props.row.note && props.row.note != ''"
                      v-tooltip="props.row.note"
                    >
                      <comment-icon class="w-5 h-5 fill-current" />
                    </div>
                  </div>
                </v-client-table>
              </div>
              <div v-else class="p-4">
                {{ $t('frontend_groovesell_no_payments_done') }}
              </div>
            </div>
          </div>
        </gd-modal>

        <gd-modal name="affiliate-links-modal" :title="$t('frontend_affiliate_links')">
          <div class="flex flex-col">
            <div v-if="affiliateLinks.length">
              <gd-tabs
                :tabs="affiliateLinksTabs"
                :current-tab="affiliateLinksTab"
                :wrapper-class="'pt-4 px-2 gs-default-tabs'"
                :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'"
                @onClick="handleAffiliateLinksTabChange"
              />

              <div>
                <div
                  v-if="affiliateLinksTab === 'aff_links'"
                  style="height: calc(80vh - 15rem)"
                >
                  <div class="mt-8 px-4 h-full max-h-full overflow-y-auto">
                    <div
                      v-for="(
                        affiliateLink, affiliateLinkIndex
                      ) in directAffiliateLinks"
                      :key="affiliateLinkIndex"
                      class="flex flex-col mb-6"
                    >
                      <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        affiliateLink.name
                      }}</label>
                      <div class="flex flex-row">
                        <text-input
                          v-model="affiliateLink.link"
                          class="mr-2"
                          :readonly="true"
                          value="affiliateLink.link"
                          :placeholder="$t('frontend_affiliate_promos_affiliate_link')"
                          :select-all="true"
                        />
                        <gd-button
                          v-clipboard:copy="affiliateLink.link"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="iconlg"
                          variant="primary"
                          :title="$t('frontend_affiliate_promos_copy_affiliate_link')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="affiliateLinksTab === 'jv_broker_links'"
                  class="mt-8 px-4"
                >
                  <div
                    v-for="(affiliateLink, affiliateLinkIndex) in jvBrokerLinks"
                    :key="affiliateLinkIndex"
                    class="flex flex-col mb-6"
                  >
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                      affiliateLink.name
                    }}</label>
                    <div class="flex flex-row">
                      <text-input
                        v-model="affiliateLink.link"
                        class="mr-2"
                        :readonly="true"
                        value="affiliateLink.link"
                        placeholder="JV Broker Link"
                        :select-all="true"
                      />
                      <gd-button
                        v-clipboard:copy="affiliateLink.link"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        size="iconlg"
                        variant="primary"
                        :title="$t('frontend_groovesell_copy_jv_broker_link')"
                      >
                        <copy-icon class="w-5 h-5 fill-current" />
                      </gd-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ $t('frontend_groovesell_unable_to_fetch') }}
            </div>
          </div>
        </gd-modal>

        <gd-modal
          name="mark-as-paid-modal"
          :title="$t('frontend_groovesell_process_commissions')"
          width="80%"
          bg-type="regular"
        >
          <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-2/3 px-4">
              <div class="mt-2">
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_groovesell_amount')
                  }}</label>
                  <label class="w-full px-1 mt-1 font-semibold text-grey-700">
                    {{ selectedAffiliate.human_readable_processed_commission }}
                  </label>
                </div>

                <div v-if="selectedAffiliate.previous_pending_amount > 0">
                  <div class="w-full flex flex-row mb-4">
                    <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                      $t('frontend_groovesell_advance_clawbacks')
                    }}</label>
                    <label class="w-full px-1 mt-1 font-semibold text-grey-700">
                      {{
                        selectedAffiliate.human_readable_previous_pending_amount
                      }}
                    </label>
                  </div>
                  <div class="w-full flex flex-row mb-4">
                    <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                      $t('frontend_groovesell_amount_due_now')
                    }}</label>
                    <label class="w-full px-1 mt-1 font-semibold text-grey-700">
                      {{
                        selectedAffiliate.human_readable_processed_without_advances
                      }}
                    </label>
                  </div>
                </div>

                <!--
                  <div v-if="selectedAffiliate.processed_without_advances != selectedAffiliate.processed_commission">
                      <div class="w-full flex flex-row mb-4" v-if="selectedAffiliate.processed_without_advances > 0">
                          <label class="w-full mt-1 font-bold text-red text-sm">Note: You only have to pay {{selectedAffiliate.human_readable_processed_without_advances}} after removing the cash advances and clawbacks</label>
                      </div>
                      <div class="w-full flex flex-row mb-4" v-else-if="selectedAffiliate.processed_without_advances <= 0">
                          <label class="w-full mt-1 font-bold text-red text-sm">Note: You don't have any commission dues after removing the cash advances and clawbacks. Please select "Mark As Paid" and click on "Process Payment" to mark the due commissions as paid</label>
                      </div>
                  </div>
                -->
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_transaction') }} #</label
                  >
                  <text-input
                    v-model="markAsPaid.transactionId"
                    class="w-2/3"
                    value="markAsPaid.transactionId"
                    :placeholder="`${$t('frontend_transaction')} Id`"
                  />
                </div>
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_comment')
                  }}</label>
                  <text-input
                    v-model="markAsPaid.comment"
                    class="w-2/3"
                    value="markAsPaid.comment"
                    :placeholder="$t('frontend_comment')"
                  />
                </div>
                <div class="flex flex-row w-full mb-6 p-4 justify-center">
                  <div class="w-1/3 mr-2">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Paypal"
                      option-value="1"
                      :option-enabled="selectedAffiliate.affiliate_paypal"
                    >
                      <template slot="icon">
                          <paypal-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                  <div class="w-1/3 mr-2">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Check"
                      option-value="2"
                      :option-enabled="selectedAffiliate.affiliate_check_info"
                    >
                      <template slot="icon">
                        <credit-card-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                  <div class="w-1/3">
                    <font-awesome-radio
                      v-model="markAsPaid.gatewayType"
                      option-name="Wire Info"
                      option-value="3"
                      :option-enabled="selectedAffiliate.affiliate_wire_info"
                    >
                      <template slot="icon">
                        <bank-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio>
                  </div>
                </div>
                <div
                  v-if="markAsPaid.gatewayType != ''"
                  class="flex flex-row w-full mb-4"
                >
                  <div
                    v-if="markAsPaid.gatewayType == '1'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_payee_details') }}
                    </h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-2"
                        >Paypal Email</label
                      >
                      <label class="text-sm text-grey-700 mb-2">{{
                        selectedAffiliate.rendered_affiliate_paypal
                      }}</label>
                    </div>
                  </div>
                  <div v-else-if="markAsPaid.gatewayType == '2'">
                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_check_Settings') }}
                    </h4>
                  </div>
                  <div
                    v-else-if="markAsPaid.gatewayType == '3'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_payee_details') }}
                    </h4>
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.account_name
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_address') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.address
                        }}</label>
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.city
                        }}</label>
                        <label class="text-sm text-grey-700 mb-2"
                          >{{ selectedAffiliate.affiliate_wire_info.state }}
                          {{
                            selectedAffiliate.affiliate_wire_info.postal_code
                          }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.country
                        }}</label>
                      </div>
                    </div>

                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_benificiary_bank') }}
                    </h4>
                    <div class="w-full flex flex-wrap">
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_bank_name') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.main_bank_name
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_bank_address') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info
                            .main_bank_address
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_account_number') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info
                            .main_bank_account_no
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_routing_number') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info
                            .main_bank_routing_no
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 pr-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_swift') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info
                            .main_bank_swift_code
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_aba') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.main_bank_aba
                        }}</label>
                      </div>
                      <div class="w-1/2 pt-2 pb-2 flex flex-col">
                        <label
                          class="font-semibold text-sm text-grey-700 mb-2"
                          >{{ $t('frontend_groovesell_iban') }}</label
                        >
                        <label class="text-sm text-grey-700 mb-2">{{
                          selectedAffiliate.affiliate_wire_info.main_bank_iban
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
              <div class="flex flex-col">
                <div class="flex">
                  <label class="font-semibold text-grey-700 mb-2"
                    >{{ $t('frontend_groovesell_mark_as') }}:</label
                  >
                </div>
                <div
                  v-for="(markAsPaidType, index) in markAsPaidTypes"
                  :key="index"
                  class="flex mr-4"
                >
                  <gd-custom-radio
                    v-model="markAsPaid.typeOfPayment"
                    :option="markAsPaidType"
                  ></gd-custom-radio>
                </div>
                <div class="mt-2 flex flex-row w-full justify-center">
                  <gd-button
                    v-if="
                      markAsPaid.gatewayType != '' &&
                      selectedAffiliate.processed_without_advances > 0
                    "
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="processPayment"
                    >{{ $t('frontend_groovesell_process_payment') }}</gd-button
                  >
                  <gd-button v-else size="auto" disabled class="mb-4">{{
                    $t('frontend_groovesell_process_payment')
                  }}</gd-button>
                </div>
              </div>
            </div>
          </div>
        </gd-modal>

        <gd-modal
          name="add-commission-bonus-modal"
          :title="$t('frontend_groovesell_add_bonus')"
          bg-type="regular"
        >
          <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-full px-4">
              <div class="mt-2">
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_groovesell_amount')
                  }}</label>
                  <div class="w-2/3">
                    <gd-currency-input
                      v-model="bonus.amount"
                      currency-code="USD"
                    />
                  </div>
                </div>
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_transaction') }} #</label
                  >
                  <text-input
                    v-model="bonus.transactionId"
                    :validator="$v.bonus.transactionId"
                    :attribute="$t('frontend_transaction_id')"
                    class="w-2/3"
                    value="bonus.transactionId"
                    :placeholder="$t('frontend_transaction_id')"
                  />
                </div>
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_comment')
                  }}</label>
                  <text-input
                    v-model="bonus.comment"
                    :validator="$v.bonus.comment"
                    :attribute="$t('frontend_comment')"
                    class="w-2/3"
                    value="bonus.comment"
                    :placeholder="$t('frontend_comment')"
                  />
                </div>
                <div class="flex flex-row w-full mb-6 justify-end">
                  <gd-button
                    size="auto"
                    class="mb-4"
                    variant="primary"
                    @click="addAffiliatePaymentBonus"
                    >{{
                      $t('frontend_groovesell_add_affiliate_bonus')
                    }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>

        <gd-modal
          name="add-commission-advance-modal"
          :title="$t('frontend_groovesell_add_commision_advance')"
          bg-type="regular"
        >
          <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-full px-4">
              <div class="mt-2">
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_groovesell_amount')
                  }}</label>
                  <div class="w-2/3">
                    <gd-currency-input
                      v-model="commissionAdvance.amount"
                      currency-code="USD"
                    />
                  </div>
                </div>
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_transaction') }} #</label
                  >
                  <text-input
                    v-model="commissionAdvance.transactionId"
                    :validator="$v.commissionAdvance.transactionId"
                    :attribute="$t('frontend_transaction_id')"
                    class="w-2/3"
                    value="commissionAdvance.transactionId"
                    :placeholder="$t('frontend_transaction_id')"
                  />
                </div>
                <div class="w-full flex flex-row mb-6">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_comment')
                  }}</label>
                  <text-input
                    v-model="commissionAdvance.comment"
                    :validator="$v.commissionAdvance.comment"
                    :attribute="$t('frontend_comment')"
                    class="w-2/3"
                    value="commissionAdvance.comment"
                    :placeholder="$t('frontend_comment')"
                  />
                </div>
                <div class="flex flex-row w-full mb-6 justify-end">
                  <gd-button
                    size="auto"
                    class="mb-4"
                    variant="primary"
                    @click="addCommissionAdvance"
                    >{{
                      $t('frontend_groovesell_add_commission_advance')
                    }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>

        <gd-modal
          name="add-clawback-modal"
          :title="$t('frontend_groovesell_add_clawback')"
          bg-type="regular"
        >
          <div v-if="selectedAffiliate" class="flex">
            <div class="flex flex-col w-full px-4">
              <div class="mt-2">
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_groovesell_amount')
                  }}</label>
                  <div class="w-2/3">
                    <gd-currency-input
                      v-model="clawback.amount"
                      currency-code="USD"
                    />
                  </div>
                </div>
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700"
                    >{{ $t('frontend_transaction') }} #</label
                  >
                  <text-input
                    v-model="clawback.transactionId"
                    :validator="$v.clawback.transactionId"
                    :attribute="$t('frontend_transaction_id')"
                    class="w-2/3"
                    value="clawback.transactionId"
                    :placeholder="$t('frontend_transaction_id')"
                  />
                </div>
                <div class="w-full flex flex-row mb-4">
                  <label class="w-1/3 mt-1 font-semibold text-grey-700">{{
                    $t('frontend_comment')
                  }}</label>
                  <text-input
                    v-model="clawback.comment"
                    :validator="$v.clawback.comment"
                    :attribute="$t('frontend_comment')"
                    class="w-2/3"
                    value="clawback.comment"
                    :placeholder="$t('frontend_comment')"
                  />
                </div>
                <div class="flex flex-row w-full mb-6 justify-end">
                  <gd-button
                    size="auto"
                    class="mb-4"
                    variant="primary"
                    @click="addClawback"
                    >{{ $t('frontend_groovesell_add_clawback') }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>

        <gd-modal
          name="open-affiliates-export-modal"
          :title="$t('frontend_groovesell_export_affiliates_payment_info')"
          width="80%"
          bg-type="regular"
        >
          <div class="flex">
            <div class="flex flex-col w-2/3 px-4">
              <div class="mt-2">
                <div class="flex flex-row w-full mb-6 p-4 justify-center">
                  <div class="w-1/3 mr-4">
                    <font-awesome-radio-with-count
                      v-model="exportAffiliates.gatewayType"
                      option-name="Paypal"
                      option-value="1"
                      :option-enabled="exportAffiliatesResponse.paypal.count > 0"
                      :option-count="exportAffiliatesResponse.paypal.count"
                    >
                      <template slot="icon">
                        <paypal-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                  <div class="w-1/3 mr-4">
                    <font-awesome-radio-with-count
                      v-model="exportAffiliates.gatewayType"
                      option-name="Check"
                      option-value="2"
                      :option-enabled="exportAffiliatesResponse.check.count > 0"
                      :option-count="exportAffiliatesResponse.check.count"
                    >
                      <template slot="icon">
                        <credit-card-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                  <div class="w-1/3">
                    <font-awesome-radio-with-count
                      v-model="exportAffiliates.gatewayType"
                      option-name="Wire Info"
                      option-value="3"
                      :option-enabled="exportAffiliatesResponse.wire.count > 0"
                      :option-count="exportAffiliatesResponse.wire.count"
                    >
                      <template slot="icon">
                        <bank-icon class="w-10 h-10 fill-current" />
                      </template>
                    </font-awesome-radio-with-count>
                  </div>
                </div>
                <div
                  v-if="exportAffiliates.gatewayType != ''"
                  class="flex flex-row w-full mb-4"
                >
                  <div
                    v-if="exportAffiliates.gatewayType == '1'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">Paypal</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-4">
                        {{ $t('frontend_groovesell_no_of_affiliates') }} :
                        <span class="text-red">{{
                          exportAffiliatesResponse.paypal.count
                        }}</span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount_due') }} :
                        {{ exportAffiliatesResponse.total.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8">
                        {{ exportAffiliatesResponse.total.count }}
                        {{ $t('frontend_affiliates') }}
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_on_hold') }}:
                        {{ exportAffiliatesResponse.on_hold.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8">
                        {{ exportAffiliatesResponse.on_hold.count }}
                        {{ $t('frontend_affiliates') }}
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_eligible') }}:
                        {{ exportAffiliatesResponse.paypal.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-2">
                        {{ exportAffiliatesResponse.paypal.count }}
                        {{ $t('frontend_affiliates') }}
                      </label>
                    </div>
                  </div>
                  <div v-else-if="exportAffiliates.gatewayType == '2'">
                    <h4 class="font-semibold text-grey-700">
                      {{ $t('frontend_groovesell_check') }}
                    </h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_no_of_affiliates_who') }}
                        <span class="text-red">{{
                          exportAffiliatesResponse.check.count
                        }}</span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount') }}:
                        <span class="text-red">{{
                          exportAffiliatesResponse.check.amount
                        }}</span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-else-if="exportAffiliates.gatewayType == '3'"
                    class="w-full flex flex-col"
                  >
                    <h4 class="font-semibold text-grey-700">Wire</h4>
                    <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                      <label class="font-semibold text-sm text-grey-700 mb-4">
                        {{
                          $t(
                            'frontend_groovesell_no_of_affiliates_who_added_wire'
                          )
                        }}
                        <span class="text-red">{{
                          exportAffiliatesResponse.wire.count
                        }}</span>
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_amount_due') }} :
                        {{ exportAffiliatesResponse.total.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8">
                        {{ exportAffiliatesResponse.total.count }}
                        {{ $t('frontend_groovesell_affiliates') }}
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_on_hold') }}:
                        {{ exportAffiliatesResponse.on_hold.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-8">
                        {{ exportAffiliatesResponse.on_hold.count }}
                        {{ $t('frontend_groovesell_affiliates') }}
                      </label>
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_groovesell_total_eligible') }}:
                        {{ exportAffiliatesResponse.wire.amount }}
                      </label>
                      <label class="text-red font-semibold text-sm mb-2">
                        {{ exportAffiliatesResponse.wire.count }}
                        {{ $t('frontend_groovesell_affiliates') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/3 border-l border-grey-300 p-4">
              <div class="flex flex-col">
                <div class="flex">
                  <label class="font-semibold text-grey-700 mb-2"
                    >{{ $t('frontend_groovesell_mark_as') }}:
                  </label>
                </div>
                <div
                  v-for="(
                    exportAffiliatePaymentType, index
                  ) in exportAffiliatePaymentTypes"
                  :key="index"
                  class="flex mr-4"
                >
                  <gd-custom-radio
                    v-model="exportAffiliates.typeOfPayment"
                    :option="exportAffiliatePaymentType"
                  ></gd-custom-radio>
                </div>
                <div class="mt-2 flex flex-col w-full justify-center">
                  <gd-button
                    v-if="exportAffiliates.gatewayType != ''"
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="exportAffiliateDetails"
                    >{{
                      $t('frontend_groovesell_export_affiliates')
                    }}</gd-button
                  >
                  <gd-button v-else size="auto" disabled class="mb-4">{{
                    $t('frontend_groovesell_export_affiliates')
                  }}</gd-button>
                  <gd-button
                    v-if="exportAffiliates.gatewayType == '1'"
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="exportPaypalMasspay"
                    >{{
                      $t('frontend_groovesell_export_paypal_masspay')
                    }}</gd-button
                  >
                </div>
              </div>
            </div>
          </div>
        </gd-modal>
        <gd-modal
          name="advanced-filters-modal"
          title="Advanced Filters"
          height="80%"
          width="80%"
          :no-overflow="true"
          bg-type="regular"
          allowOverflow
        >
          <div class="flex flex-col m-6 mb-4">
            <div class="flex flex-col w-full mr-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_products')
              }}</label>
              <treeselect
                v-model="filteredFunnels"
                :multiple="true"
                :options="funnelsDropdown"
                placeholder="Products"
              />
            </div>
              <div class="flex flex-col w-full mr-2 mt-2">
              <label class="font-semibold text-sm text-grey-700 mb-2">{{
                $t('frontend_affiliate_transactions_advance_transaction_filter')
              }}</label>
                <gd-dropdown
                  v-model="filteredAdvancedTransactions"
                  size="full"
                  :placeholder="$t('frontend_affiliate_transactions_advance_transaction_filter')"
                  :options="filteredTransactionOptions"
                />
            </div>

            <div class="flex flex-row w-full my-6 justify-end">
              <gd-button
                size="md"
                variant="primary"
                @click="applyFilters"
                >{{ $t('frontend_analytics_apply_filters') }}</gd-button
              >
            </div>
          </div>
        </gd-modal>
      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<style>
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.daterangepicker .ranges li.active {
  background-color: var(--brand-primary);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--brand-primary);
}
</style>

<script>
import Funnel from '@/models/Funnel';
import LeaderboardWidget from '@/models/LeaderboardWidget';
import Affiliate from '@/models/Affiliate';
import AffiliatePayment from '@/models/AffiliatePayment';
import AffiliatePaymentBonus from '@/models/AffiliatePaymentBonus';
import AffiliateLink from '@/models/AffiliateLink';
import {
  required,
  maxLength,
  minLength,
  decimal,
  minValue,
  number,
} from 'vuelidate/lib/validators';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import axios from 'axios';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import AffiliateFullNameRenderer from '@/renderers/affiliateFullNameRenderer';
import AffiliateSalesRenderer from '@/renderers/affiliateSalesRenderer';
import AffiliateRefundsRenderer from '@/renderers/affiliateRefundsRenderer';
import Notify from '@groovepages/gd-ui-kit/notify';
import moment from 'moment-timezone';
import DateTimePicker from '@/components/DateTimePicker';
import Swal from 'sweetalert2';
import createLinkMixin from '@/mixins/createLinkMixin';
import PaypalIcon from '@/assets/images/icons/PaypalIcon.svg';
import CreditCardIcon from '@/assets/images/icons/CreditCardIcon.svg';
import BankIcon from '@/assets/images/icons/BankIcon.svg';
import FilterIcon from '@/assets/images/icons/FilterIcon.svg';
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';
import CommentIcon from '@/assets/images/icons/CommentIcon.svg';
import LinkIcon from '@/assets/images/icons/LinkIcon.svg';

export default {
  middleware: 'auth',

  metaInfo() {
    return { title: 'Affiliates' };
  },
  mixins: [createLinkMixin],
  validations: {
    markAsPaid: {
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },

    commissionAdvance: {
      amount: {
        required,
        decimal,
        minValue: minValue(1),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },

    clawback: {
      amount: {
        required,
        decimal,
        minValue: minValue(0),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },

    bonus: {
      amount: {
        required,
        decimal,
        minValue: minValue(0),
      },
      comment: {
        maxLength: maxLength(255),
      },
      transactionId: {
        maxLength: maxLength(255),
      },
    },
  },

  components: {
    AgGridVue,
    Treeselect,
    DateTimePicker,
    PaypalIcon,
    CreditCardIcon,
    BankIcon,
    CommentIcon,
    FilterIcon,
    CopyIcon,
    LinkIcon,
  },

  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      affiliatesRefundsColumns: [
        'buyer_fullname',
        'buyer_email',
        'buyer_ip',
        'transaction_type',
        'commission_status',
        'formatted_date_time',
      ],
      affiliatePaymentExportId: 0,
      leaderboardProductsTab: '',
      leaderboardTimePicker: true,
      leaderboardTimePicker24Hour: true,
      leaderboardTimePickerIncrement: 30,
      leaderBoardStep: 1,
      leaderboardFunnels: [],
      leaderboardStartingDate: moment().toISOString(),
      leaderboardEndingDate: moment().add(30, 'days').toISOString(),
      leaderboardDateRange: {
        startDate: moment().toISOString(),
        endDate: moment().add(30, 'days').toISOString(),
      },
      leaderboard: null,
      affiliatesDateRange: {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
      },
      customStartDate: moment(),
      customEndDate: moment(),
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
        'All Time': [moment('20191201', 'YYYYMMDD'), moment()],
      },
      opens: 'right',
      autoApply: true,
      affiliateLinks: [],
      affiliateLinksTab: 'aff_links',
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      dataSource: null,
      affiliates: [],
      funnelsDropdown: [],
      filteredFunnels: [],
      paginationPageSize: 25,
      affiliatesTableRowHeight: 40,
      rowBuffer: null,
      rowModelType: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      domLayout: 'autoHeight',
      affiliatesTableColumnDefs: null,
      filteredAffiliateTypes: 0,
      searchPhrase: '',
      rowClassRules: null,
      rowClass: 'affiliates-row',
      selectedRowSize: 25,
      context: null,
      frameworkComponents: null,
      selectedAffiliate: null,
      newAffiliatePayment: null,
      rowSelection: 'multiple',
      isAllSelected: false,
      unselectedAffiliateIds: [],
      selectedRowLength: 0,
      selectedAffiliatesAmount: 0,
      markAsPaid: {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      },

      markAsPaidTypes: [
        { id: 1, title: 'In Process', description: '' },
        { id: 2, title: 'Paid', description: '' },
        { id: 3, title: 'Paid After 24 Hrs.', description: '' },
        { id: 4, title: 'Paid After 48 Hrs.', description: '' },
      ],

      exportAffiliatePaymentTypes: [
        { id: 2, title: 'Paid', description: '' },
        { id: 3, title: 'Paid After 24 Hrs.', description: '' },
        { id: 4, title: 'Paid After 48 Hrs.', description: '' },
        { id: 1, title: 'In Process', description: '' },
        { id: 5, title: 'Unchanged', description: '' },
      ],

      showAnalytics: false,

      commissionAdvance: {
        amount: 0,
        comment: '',
        transactionId: '',
      },

      clawback: {
        amount: 0,
        comment: '',
        transactionId: '',
      },

      bonus: {
        amount: 0,
        comment: '',
        transactionId: '',
      },

      affiliateDetailTabs: [
        { title: 'Affiliiate', value: 'info' },
        { title: 'Lifetime Commissions', value: 'lifetime' },
        { title: 'Transactions', value: 'transactions' },
        { title: 'Refunds', value: 'refunds' },
        { title: 'Links', value: 'links' },
        { title: 'Payment History', value: 'history' },
      ],

      affiliateDetailsTab: 'info',

      affiliatesTransactionsColumns: [
        'buyer_fullname',
        'buyer_email',
        'buyer_ip',
        'transaction_type',
        'commission_status',
        'formatted_date_time',
      ],

      affiliatesTransactionsTableOptions: {
        headings: {
          formatted_date_time: 'Date Time',
          buyer_fullname: 'Name',
          buyer_email: 'Email',
          buyer_ip: 'IP Address',
          transaction_type: 'Type',
          commission_status: 'Commission',
        },
        perPage: 5,
        texts: {
          filterPlaceholder: 'Search',
          filter: '',
        },
      },

      affiliatefilterPlaceholdersRefundsColumns: [
        'buyer_fullname',
        'buyer_email',
        'buyer_ip',
        'transaction_type',
        'commission_status',
        'formatted_date_time',
      ],

      affiliatesRefundsTableOptions: {
        headings: {
          formatted_date_time: 'Date Time',
          buyer_fullname: 'Name',
          buyer_email: 'Email',
          buyer_ip: 'IP Address',
          transaction_type: 'Type',
          commission_status: 'Commission',
        },
        perPage: 5,
        texts: {
          filterPlaceholder: 'Search',
          filter: '',
        },
      },

      affiliatesLinksColumns: ['name', 'links'],

      affiliatesLinksTableOptions: {
        headings: {},
        perPage: 5,
        texts: {
          filterPlaceholder: 'Search',
          filter: '',
        },
      },

      funnelFilters: [],
      selectedFunnel: 0,

      affiliatesPaymentsColumns: [
        'readable_type',
        'human_readable_amount',
        'formatted_date_time',
        'note',
      ],

      affiliatesPaymentsTableOptions: {
        headings: {
          formatted_date_time: 'Date Time',
          readable_type: 'Type',
          human_readable_amount: 'Amount',
        },
        perPage: 5,
        texts: {
          filterPlaceholder: 'Search',
          filter: '',
        },
      },

      exportAffiliates: {
        gatewayType: '',
        typeOfPayment: 1,
      },

      exportAffiliatesResponse: {
        paypal: {
          count: 0,
          amount: '$0.00',
        },
        check: {
          count: 0,
          amount: '$0.00',
        },
        wire: {
          count: 0,
          amount: '$0.00',
        },
        on_hold: {
          count: 0,
          amount: '$0.00',
        },
        total: {
          count: 0,
          amount: '$0.00',
        },
        hash: '',
      },

      isFunnelAdded: true,
      filteredAdvancedTransactions: 0,

      affiliationMainTabs: [
        {
          title: 'Performance',
          value: 'performance_tab',
        },
      ],

      affiliationDefaultTab: 'performance_tab',
    };
  },

  computed: {
    funnelOptions() {
      return this.funnels.map(funnel => ({ value: funnel.id, text: funnel.name }));
    },
    rowSizeOptions() {
      return [5, 10, 25, 50, 100];
    },
    filteredTransactionOptions() {
      return [
        { value: "0", text: this.$t('frontend_affiliate_transactions_advance_transaction_all') },
        { value: "1", text: this.$t('frontend_affiliate_transactions_first_sale') },
        { value: "2", text: this.$t('frontend_affiliate_transactions_first_sale_after') },
        { value: "3", text: this.$t('frontend_affiliate_transactions_rebills_only') }
      ];
    },
    pricePosition() {
      if (this.leaderboard) {
        const arr = [];
        for (let i = 1; i <= mynumber; i++) {
          arr.push(i.toString());
        }
      }
    },

    leaderboardLiveFunnels() {
      return this.leaderboardFunnels.filter((x) => x.is_live);
    },

    leaderboardLink() {
      if (this.leaderboard && this.leaderboard.permalink) {
        const site_appurl = window.config.appUrl.replace(/^https?:\/\//i, '');
        return `https://leaderboards.${site_appurl}/leaderboard/${this.leaderboard.permalink}`;
      }
      return '';
    },

    leaderboard_embed() {
      if (this.leaderboard) {
        return this.leaderboard.leaderboard_embed;
      }
      return '';
    },

    leaderboardProductsTabs() {
      const leaderboardProductsTabs = [];

      for (let i = 0; i < this.leaderboard.pricepoints.length; i++) {
        leaderboardProductsTabs.push({
          title: this.leaderboard.pricepoints[i].name,
          value: `leaderboard_product_${this.leaderboard.pricepoints[i].id}`,
        });
      }

      return leaderboardProductsTabs;
    },

    affiliateLinksTabs() {
      const secondTierLinksLength = this.affiliateLinks.filter((link) => {
        return link.type == 'second_tier';
      }).length;

      if (secondTierLinksLength > 0) {
        return [
          { title: 'My Affiliate Links', value: 'aff_links' },
          { title: 'My JV Broker Links', value: 'jv_broker_links' },
        ];
      }
      return [{ title: 'My Affiliate Links', value: 'aff_links' }];
    },

    directAffiliateLinks() {
      return this.affiliateLinks.filter((link) => {
        return link.type == 'affiliate';
      });
    },

    jvBrokerLinks() {
      return this.affiliateLinks.filter((link) => {
        return link.type == 'second_tier';
      });
    },
  },
  async beforeMount() {;
    this.gridOptions = {};

    this.context = { componentParent: this };
    this.frameworkComponents = {
      affiliateFullNameRenderer: AffiliateFullNameRenderer,
      affiliateSalesRenderer: AffiliateSalesRenderer,
      affiliateRefundsRenderer: AffiliateRefundsRenderer,
    };

    // table column of leader board
    this.affiliatesTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_affiliate_details'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_name'),
            field: 'rendered_name',
            cellRenderer: 'affiliateFullNameRenderer',
            colId: 'affiliateFullName',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
          {
            headerName: this.$t('frontend_username'),
            field: 'rendered_username',
            width: 300,
            pinned: 'left',
            sortable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_clicks'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_impressions'),
            field: 'impressions',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_uniques'),
            field: 'uniques',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_conversions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_signups'),
            field: 'total_optins',
            cellRenderer: 'affiliateSalesRenderer',
            colId: 'affiliateOptins',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_sales'),
            field: 'total_sales',
            cellRenderer: 'affiliateSalesRenderer',
            colId: 'affiliateSales',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_total_sales_amount'),
            field: 'human_readable_total_sales_amount',
            cellRenderer: 'affiliateSalesAmountRenderer',
            colId: 'affiliateSalesAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_refunds'),
            field: 'total_refunds',
            cellRenderer: 'affiliateRefundsRenderer',
            colId: 'affiliateRefunds',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_total_refunds_amount'),
            field: 'human_readable_total_refunds_amount',
            cellRenderer: 'affiliateRefundsRenderer',
            colId: 'affiliateRefundsAmount',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_comissions'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_earned_comissions'),
            field: 'human_readable_commissions_including_refunds',
            colId: 'affiliateCommissionsIncludingRefunds',
            sortable: true,
            resizable: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_refunded_comissions'),
            field: 'human_readable_total_refunded_commissions',
            colId: 'affiliateRefundedCommissions',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_comissions_without_refunds'),
            field: 'human_readable_commissions_without_refunds',
            colId: 'affiliateCommissionsWithoutRefunds',
            sortable: true,
            resizable: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_earnings_per_click'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_gross_epc_earned'),
            field: 'human_readable_gross_epc_earned',
            colId: 'grossEpcEarned',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_affiliate_epc_earned'),
            field: 'human_readable_affiliate_epc_earned',
            colId: 'affiliateEpcEarned',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_gross_epc_paid'),
            field: 'human_readable_gross_epc_paid',
            colId: 'grossEpcPaid',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
          {
            headerName: this.$t('frontend_groovesell_affiliate_epc_paid'),
            field: 'human_readable_affiliate_epc_paid',
            colId: 'affiliateEpcPaid',
            sortable: true,
            resizable: true,
            hide: true,
            sortingOrder: ['desc', 'asc'],
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_email'),
        headerClass: 'affiliate-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_email'),
            field: 'rendered_email',
            sortable: true,
            resizable: true,
            sortingOrder: ['asc', 'desc'],
          },
        ],
      },
    ];
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;

    this.rowClassRules = {};
  },

  async created() {
    this.$wait.start('affiliates');
    const funnels = await Funnel.$get();
    if (funnels.length > 0) {
      this.funnels = funnels;
      this.isFunnelAdded = true;
      if(this.$route.query.funnel_id) {
        this.selectedFunnel = this.$route.query.funnel_id;
      } else {
        this.selectedFunnel = funnels[0].id;
      }
    } else {
      this.isFunnelAdded = false;
    }
    this.$wait.end('affiliates');
  },

  methods: {
    openAdvancedFilters() {
      this.$root.$emit('modal-open', 'advanced-filters-modal');
    },

    applyFilters() {
      this.changedSearchOrFilter();
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },
    // copy leader board link function
    async copyLink(id) {
      // get leader board data
      this.leaderboard = await LeaderboardWidget.$find(id);
      this.$root.$emit('modal-open', 'copy-link-modal');
    },

    changeFunnel() {
      this.filteredFunnels = [];
      this.changedSearchOrFilter();
    },
    changedAnalyticsToggle() {
      this.gridColumnApi.setColumnsVisible(
        [
          'impressions',
          'uniques',
          'grossEpcEarned',
          'affiliateEpcEarned',
          'grossEpcPaid',
          'affiliateEpcPaid',
        ],
        this.showAnalytics
      );

      this.changedSearchOrFilter();
    },

    handleLeaderboardProductsTabChange(newTab) {
      this.leaderboardProductsTab = newTab;
    },

    // close leader board create time modal
    async closeLeaderboardWizard() {
      // on close load leader board list data
      this.leaderBoardData = await LeaderboardWidget.$get();
      this.$root.$emit('modal-close', 'leaderboards-modal');
    },

    // close edit leader board
    async closeEditLeaderboardWizard() {
      this.$root.$emit('modal-close', 'leaderboards-edit-modal');
    },

    // function to generate array based on number passed
    generateDynamicOptionArr(leaderBoardData, ignore_position = null) {
      const mynumber = leaderBoardData.max_affiliates;
      const leaderBaordPriceData = leaderBoardData.leaderboard_config.prices;

      const ignoreArr = [];
      for (let i = 0; i < leaderBaordPriceData.length; i++) {
        if (
          ignore_position != null &&
          ignore_position == leaderBaordPriceData[i].pricePositionOptionsValue
        ) {
          continue;
        }

        ignoreArr.push(leaderBaordPriceData[i].pricePositionOptionsValue);
      }

      const arr = [];
      for (let i = 1; i <= mynumber; i++) {
        if (!ignoreArr.includes(i.toString())) {
          arr.push(i.toString());
        }
      }

      arr.sort((a, b) => a - b); // sort array

      return arr;
    },

    // save leader boad data functionality
    async saveAndNextOnLeaderboard() {
      if (this.leaderboard.name && this.leaderboard.name != '') {
        if (this.leaderBoardStep == 1) {
          this.$loader.start('saveAndNextOnLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          const savedLeaderboard = await this.leaderboard.save();
          // refresh table data
          this.leaderBoardData = await LeaderboardWidget.$get();
          // get data for particular leaderboard
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );
          this.leaderboardProductsTab = `leaderboard_product_${this.leaderboard.pricepoints[0].id}`;
          this.leaderBoardStep = 2;
          this.$loader.end('saveAndNextOnLeaderboard');
          this.pricePositionOptions = this.generateDynamicOptionArr(
            this.leaderboard
          );
        } else if (this.leaderBoardStep == 2) {
          this.$loader.start('saveAndNextOnLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          const savedLeaderboard = await this.leaderboard.save();
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );
          this.leaderBoardStep = 3;
          this.$loader.end('saveAndNextOnLeaderboard');
        } else if (this.leaderBoardStep == 3) {
          this.$loader.start('saveAndNextOnLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          const savedLeaderboard = await this.leaderboard.save();
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );
          this.leaderBoardStep = 4;
          this.$loader.end('saveAndNextOnLeaderboard');
        } else if (this.leaderBoardStep == 4) {
          this.leaderboard.leaderboard_config = {
            prices: this.leaderBordPricingData.length
              ? this.leaderBordPricingData
              : [],
            teams: this.leaderboard.leaderboard_config.teams.length
              ? this.leaderboard.leaderboard_config.teams
              : [],
          };

          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          this.$loader.start('saveAndNextOnLeaderboard');

          const savedLeaderboard = await this.leaderboard.save(); // update data

          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );

          this.leaderBoardStep = 5; // set leaderborad step property 5

          this.$loader.end('saveAndNextOnLeaderboard');
        }
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_groovesell_swal_valid_name_for_leaderboard'),
        };
      }
    },

    // update leader board data
    async updateLeaderboard() {
      if (this.leaderboard.name && this.leaderboard.name != '') {
        if (this.leaderBoardStep == 1) {
          this.$loader.start('updateLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          // update leader board data
          const savedLeaderboard = await this.leaderboard.save();
          // refresh table data
          this.leaderBoardData = await LeaderboardWidget.$get();
          // get updated data for particular leaderboard
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );

          this.leaderboardProductsTab = `leaderboard_product_${this.leaderboard.pricepoints[0].id}`;

          this.pricePositionOptions = this.generateDynamicOptionArr(
            this.leaderboard
          );

          this.leaderBoardStep = 2;
          this.$loader.end('updateLeaderboard');
        } else if (this.leaderBoardStep == 2) {
          this.$loader.start('updateLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          const savedLeaderboard = await this.leaderboard.save();
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );
          this.leaderBoardStep = 3;
          this.$loader.end('updateLeaderboard');
        } else if (this.leaderBoardStep == 3) {
          this.$loader.start('updateLeaderboard');
          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          const savedLeaderboard = await this.leaderboard.save();
          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );
          this.leaderBoardStep = 4;
          this.$loader.end('updateLeaderboard');
        } else if (this.leaderBoardStep == 4) {
          this.leaderboard.leaderboard_config = {
            prices: this.leaderBordPricingData.length
              ? this.leaderBordPricingData
              : [],
            teams: this.leaderboard.leaderboard_config.teams.length
              ? this.leaderboard.leaderboard_config.teams
              : [],
          };

          this.leaderboard.start_at = moment(
            this.leaderboardDateRange.startDate
          )
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');
          this.leaderboard.end_at = moment(this.leaderboardDateRange.endDate)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mm A');

          this.$loader.start('updateLeaderboard');

          const savedLeaderboard = await this.leaderboard.save(); // update data

          this.leaderboard = await LeaderboardWidget.$find(
            savedLeaderboard.data.id
          );

          this.leaderBoardStep = 5; // set leaderborad step property 5

          this.$loader.end('updateLeaderboard');
        }
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_groovesell_swal_valid_name_for_leaderboard'),
        };
      }
    },

    // create leader board functionality
    async createLeaderboard() {
      this.$loader.start('createLeaderboard');
      this.leaderBordPricingData = [];
      this.leaderBoardStep = 1;
      this.leaderboardFunnels = await Funnel.$get();
      this.leaderboard = new LeaderboardWidget({});
      this.leaderboard.name = '';
      this.leaderboard.funnel_id = this.leaderboardFunnels[0].id;
      this.leaderboardDateRange = {
        startDate: moment().startOf('hour').toISOString(),
        endDate: moment().startOf('hour').add(30, 'days').toISOString(),
      };
      this.leaderboard.max_affiliates = 10;
      this.leaderboard.type = 1;
      this.leaderboard.pricepoints = [];
      this.leaderboard.enabled_stats = [];
      this.$root.$emit('modal-open', 'leaderboards-modal');
      this.$loader.end('createLeaderboard');
    },

    // edit leader board function
    async EditLeaderboard(leader_board_id) {
      this.$v.editPriceModel.$reset(); // reset validation error messages
      this.$loader.start('EditLeaderboard'); // show loader
      this.leaderBoardStep = 1; // setting default step 1
      this.leaderboardFunnels = await Funnel.$get(); // get funnel data
      this.leaderboard = await LeaderboardWidget.$find(leader_board_id); // getting specific leader bord data
      this.leaderboardDateRange = {
        startDate: new Date(
          this.leaderboard.start_timestamp * 1000
        ).toISOString(),
        endDate: new Date(this.leaderboard.end_timestamp * 1000).toISOString(),
      };
      this.leaderBordPricingData = this.leaderboard.leaderboard_config.prices;
      this.$root.$emit('modal-open', 'leaderboards-edit-modal');
      this.$loader.end('EditLeaderboard');

      // set prize position option for dropdown
      this.editPriceModel.pricePositionOptions = this.generateDynamicOptionArr(
        this.leaderboard
      );
    },

    saveFunnelFilters() {
      this.changedSearchOrFilter();
    },

    onCopy() {
      Notify.success('Link Copied To Your Clipboard');
    },

    onError() {
      Notify.fail('Unable To Copy Link');
    },

    handleAffiliateLinksTabChange(newTab) {
      this.affiliateLinksTab = newTab;
    },

    handleAffiliateTabChange(newTab) {
      this.affiliationDefaultTab = newTab;

      if (newTab == 'leader_board_tab') {
        this.onLeaderBoardGridReady();
      }

      if (newTab == 'performance_tab') {
        this.onGridReady();
      }
    },

    async fetchAffiliateLinks(id) {
      this.$loader.start('fetchAffiliateLinks');
      this.affiliateLinks = await AffiliateLink.params({
        funnelId: id,
        affiliateId: this.selectedAffiliate.id,
      })
        .custom('fetch-aff-links-from-vendor')
        .$get();

      this.$loader.end('fetchAffiliateLinks');
      this.$root.$emit('modal-open', 'affiliate-links-modal');
    },

    async exportAffiliateDetails() {
      const self = this;
      self.$loader.start('exportAffiliateDetails');

      let exportType = '';

      if (self.exportAffiliates.gatewayType == '1') {
        exportType = 'paypal';
      } else if (self.exportAffiliates.gatewayType == '3') {
        exportType = 'wire';
      }

      const downloadLinkData = await axios.post(
        '/process-exported-affiliate-transactions',
        {
          hash: self.exportAffiliatesResponse.hash,
          type: self.exportAffiliates.typeOfPayment,
          funnel: self.selectedFunnel,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        this.openLink(downloadLinkData.data.download_link, '_self');

        self.unselectAllAffiliates();
        self.$loader.end('exportAffiliateDetails');
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.affiliatePaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportAffiliateDetails');
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc'),
        };
      }
    },

    async exportPaypalMasspay() {
      const self = this;
      self.$loader.start('exportPaypalMasspay');

      const exportType = 'paypal_masspay';
      const downloadLinkData = await axios.post(
        '/process-exported-affiliate-transactions',
        {
          hash: self.exportAffiliatesResponse.hash,
          type: self.exportAffiliates.typeOfPayment,
          funnel: self.selectedFunnel,
          exportType,
        }
      );

      if (downloadLinkData.data.status == 'processed') {
        this.openLink(downloadLinkData.data.download_link, '_self');
        self.unselectAllAffiliates();
        self.$loader.end('exportPaypalMasspay');
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.affiliatePaymentExportId = downloadLinkData.data.payment_export_id;
        this.getStatus();
        self.$loader.end('exportPaypalMasspay');
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_job_queued'),
          message: this.$t('frontend_groovesell_swal_export_file_desc'),
        };
      }
    },

    async getStatus() {
      const { data } = await axios.get(
        `affiliate-payment-export-status/${this.affiliatePaymentExportId}`
      );

      const processedStatus = data.status;

      if (processedStatus == 'processing') {
        setTimeout(this.getStatus(), 5000);
      } else if (processedStatus == 'processed') {
        Swal.close();
        this.openLink(data.download_link, '_self');
        this.unselectAllAffiliates();
        this.alertProps = {
          show: true,
          variant: 'success',
          title: this.$t('frontend_processed_successfully'),
          message: this.$t('frontend_groovesell_swal_export_file_successfully'),
        };
        this.$root.$emit('modal-close', 'open-affiliates-export-modal');
      } else {
        this.alertProps = {
          show: true,
          variant: 'error',
          title: this.$t('frontend_error'),
          message: this.$t('frontend_groovemail_forms_view_unknown_backend_error'),
        };
      }
    },

    async openExportModal() {
      const self = this;
      self.$loader.start('openExportModal');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
          affiliates: self.filteredAffiliateTypes,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
        }
      );

      this.exportAffiliatesResponse.paypal = exportLinkData.data.paypal;
      this.exportAffiliatesResponse.wire = exportLinkData.data.wire;
      this.exportAffiliatesResponse.hash = exportLinkData.data.hash;
      this.exportAffiliatesResponse.on_hold = exportLinkData.data.on_hold;
      this.exportAffiliatesResponse.total = exportLinkData.data.total;
      self.$loader.end('openExportModal');

      this.exportAffiliates = {
        gatewayType: '',
        typeOfPayment: 2,
      };
      this.$root.$emit('modal-open', 'open-affiliates-export-modal');
    },

    async downloadAffiliateExportFile() {
      const self = this;
      self.$loader.start('downloadAffiliateExportFile');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
          affiliates: self.filteredAffiliateTypes,
          search: self.searchPhrase,
          funnels: self.selectedFunnel,
          direct_download: 1,
        }
      );
      this.openLink(exportLinkData.data.download_link, '_self');
      self.$loader.end('downloadAffiliateExportFile');
    },

    handleAffiliatesDetailTabChange(newTab) {
      this.affiliateDetailsTab = newTab;
    },

    async processPayment() {
      this.$v.markAsPaid.$touch();
      const isValid = !this.$v.markAsPaid.$invalid;

      if (isValid) {
        this.$loader.start('processPayment');
        const self = this;

        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          amount: self.selectedAffiliate.processed_commission / 100,
          payment_gateway: self.markAsPaid.gatewayType,
          note: self.markAsPaid.comment,
          transaction_id: self.markAsPaid.transactionId,
          type: 1,
          markTransactionAs: self.markAsPaid.typeOfPayment,
          funnel: self.selectedFunnel,
          processedTransactionIds:
            self.selectedAffiliate.processed_transaction_ids,
          processedAssistIds:
            self.selectedAffiliate.processed_affiliate_assist_ids,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'mark-as-paid-modal');

        this.selectedAffiliate = await Affiliate.params({
          funnels: self.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('processPayment');
        this.changedSearchOrFilter();
      }
    },

    openMarkAsPaidModal() {
      this.$v.markAsPaid.$reset();
      this.markAsPaid = {
        comment: '',
        transactionId: '',
        gatewayType: '',
        typeOfPayment: 1,
      };
      this.$root.$emit('modal-open', 'mark-as-paid-modal');
    },

    openCommissionAdvanceModal() {
      this.commissionAdvance = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-commission-advance-modal');
    },

    openClawbackModal() {
      this.clawback = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-clawback-modal');
    },

    openCommissionBonusModal() {
      this.bonus = {
        amount: 0,
        comment: '',
        transactionId: '',
      };

      this.$root.$emit('modal-open', 'add-commission-bonus-modal');
    },

    async addCommissionAdvance() {
      this.$v.commissionAdvance.$touch();
      const isValid = !this.$v.commissionAdvance.$invalid;

      if (isValid) {
        this.$loader.start('addCommissionAdvance');
        const self = this;
        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          amount: self.commissionAdvance.amount,
          payment_gateway: 0,
          note: self.commissionAdvance.comment,
          transaction_id: self.commissionAdvance.transactionId,
          type: 2,
          funnel: self.selectedFunnel,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'add-commission-advance-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: self.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addCommissionAdvance');
        this.changedSearchOrFilter();
      }
    },

    async addClawback() {
      this.$v.clawback.$touch();
      const isValid = !this.$v.clawback.$invalid;

      if (isValid) {
        this.$loader.start('addClawback');
        const self = this;
        const affiliatePayment = new AffiliatePayment({
          affiliate_id: self.selectedAffiliate.id,
          amount: self.clawback.amount,
          payment_gateway: 0,
          note: self.clawback.comment,
          transaction_id: self.clawback.transactionId,
          type: 3,
          funnel: self.selectedFunnel,
        });

        await affiliatePayment.save();
        this.$root.$emit('modal-close', 'add-clawback-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: self.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addClawback');
        this.changedSearchOrFilter();
      }
    },

    async addAffiliatePaymentBonus() {
      this.$v.bonus.$touch();
      const isValid = !this.$v.bonus.$invalid;

      if (isValid) {
        this.$loader.start('addAffiliatePaymentBonus');
        const self = this;
        const affiliatePaymentBonus = new AffiliatePaymentBonus({
          affiliate_id: self.selectedAffiliate.id,
          amount: self.bonus.amount,
          payment_gateway: 0,
          note: self.bonus.comment,
          transaction_id: self.bonus.transactionId,
          funnel: self.selectedFunnel,
        });

        await affiliatePaymentBonus.save();
        this.$root.$emit('modal-close', 'add-commission-bonus-modal');
        this.selectedAffiliate = await Affiliate.params({
          funnels: self.selectedFunnel,
        }).$find(self.selectedAffiliate.id);
        this.$loader.end('addAffiliatePaymentBonus');
        this.changedSearchOrFilter();
      }
    },

    onModelUpdated() {
      if (this.isAllSelected) {
        const self = this;
        this.gridApi.forEachNode((rowNode) => {
          if (!self.unselectedAffiliateIds.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.selectedRowLength = selectedNodes.length;
    },

    async exportSelectedAffiliates() {
      const self = this;
      self.$loader.start('exportSelectedAffiliates');
      const selectedNodes = self.gridApi.getSelectedNodes();
      const selectedAffiliateIds = [];

      for (let i = 0; i < selectedNodes.length; i++) {
        selectedAffiliateIds.push(selectedNodes[i].data.id);
      }

      const exportLinkData = await axios.post(
        '/generate-affiliates-export-link',
        {
          selected: selectedAffiliateIds,
          unselected: self.unselectedAffiliateIds,
          isAllSelected: self.isAllSelected,
          funnel: self.selectedFunnel,
        }
      );
      this.openLink(exportLinkData.data.download_link, '_self');
      self.$loader.end('exportSelectedAffiliates');
    },

    onRowSelected(event) {
      if (
        !event.node.selected &&
        this.unselectedAffiliateIds.indexOf(event.node.data.id) === -1
      ) {
        this.unselectedAffiliateIds.push(event.node.data.id);
      } else {
        this.unselectedAffiliateIds = this.unselectedAffiliateIds.filter(
          (item) => item !== event.node.data.id
        );
      }
    },

    selectAllAffiliates() {
      this.isAllSelected = true;
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
      });

      this.unselectedAffiliateIds = [];
    },

    unselectAllAffiliates() {
      this.isAllSelected = false;
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });

      this.unselectedAffiliateIds = [];
    },

    async openAffiliateDetail(affiliateId) {
      this.$loader.start('openAffiliateDetail');
      const self = this;
      this.selectedAffiliate = await Affiliate.params({
        funnels: self.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'info';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openAffiliateDetail');
    },

    async openSalesDetail(affiliateId) {
      this.$loader.start('openSalesDetail');
      const self = this;
      this.selectedAffiliate = await Affiliate.params({
        funnels: self.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'transactions';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openSalesDetail');
    },

    async openRefundsDetail(affiliateId) {
      this.$loader.start('openRefundsDetail');
      const self = this;
      this.selectedAffiliate = await Affiliate.params({
        funnels: self.selectedFunnel,
      }).$find(affiliateId);
      this.affiliateDetailsTab = 'refunds';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openRefundsDetail');
    },

    reloadData() {
      this.changedSearchOrFilter();
    },

    onPageSizeChanged() {
      this.gridApi.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        this.selectedRowSize
      );
      this.gridApi.infiniteRowModel.resetCache();
      this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
    },

    debouncedSearchOrFilterCaller: _.debounce(function () {
      this.changedSearchOrFilter();
    }, 1000),

    async changedSearchOrFilter() {
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },

    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
    },

    async onLeaderBoardGridReady() {
      this.$loader.start('onLeaderBoardGridReady');
      this.leaderBoardData = await LeaderboardWidget.$get();
      this.$loader.end('onLeaderBoardGridReady');
    },

    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getDataSource');

        const affiliatesData = await Affiliate.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            fromDate: moment(self.affiliatesDateRange.startDate).format(
              'MM/DD/YYYY'
            ),
            toDate: moment(self.affiliatesDateRange.endDate).format(
              'MM/DD/YYYY'
            ),
            showAnalytics: self.showAnalytics,
            affiliates: self.filteredAffiliateTypes,
            advancedTransactions: self.filteredAdvancedTransactions,
            search: self.searchPhrase,
            funnel: self.selectedFunnel,
            funnels: self.filteredFunnels,
            sortBy: params.sortModel,
          })
          .custom('affiliates/performance-report')
          .get();

        let rowsThisPage = [];
        rowsThisPage = affiliatesData.data;
        const totalNumberOfRows = affiliatesData.meta.total;
        self.funnelsDropdown = affiliatesData.meta.funnels;

        self.$nextTick(() => {
          self.funnelFilters = affiliatesData.meta.funnels;
        });

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

<style lang="scss">
#affiliate-transactions-list-view,
#affiliate-refunds-list-view,
#affiliate-payments-list-view,
#affiliate-links-list-view {
  .VueTables--client {
    width: 100%;
  }

  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search__input {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    padding: 6px !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 10px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .VuePagination__pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .VuePagination__pagination-item {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<style lang="scss">
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#affiliates-list-view {
  height: calc(100vh - 9rem);

  .ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
  }

  .affiliate-details-header {
    background-color: var(--brand-primary);
    text-align: center !important;
    color: #fff;
    border: 1px solid #fff;
  }

  .affiliate-details-header .ag-header-group-cell-label {
    text-align: center;
  }

  .affiliate-details-header
    .ag-header-group-cell-label
    span.ag-header-group-text {
    margin: auto;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }
}
</style>

<style>
.daterangepicker.show-ranges .drp-calendar.left {
  border: none !important;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
</style>

<style>
.multiselect__placeholder {
  font-size: 16px;
}

.multiselect__option--highlight {
  background-color: var(--brand-primary) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--highlight:after {
  background-color: var(--brand-primary) !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #ff765b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #ff765b !important;
}
</style>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>

<style lang="scss">
#leader-board-table-view,
#products-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 1044.3622l-.707031-.707 6.125-6.125 1.875001-1.875 1.875 1.875 6.125 6.125-.707032.707-6.125-6.125-1.167968-1.168-1.167969 1.168-6.125 6.125' transform='translate(0-1030.3622)'/></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit {
    display: none;
  }

  .VuePagination__count {
    display: none;
  }

  .expandIcon {
    display: none;
  }

  .funnelRow:hover .expandIcon {
    display: block !important;
  }
}
</style>