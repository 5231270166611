import Vue from 'vue';
import VueWait from 'vue-wait';
import VueWaitDefaultLoader from '@/components/VWaitDefaultLoader';

Vue.use(VueWait);

const wait = new VueWait({
  useVuex: false,
  vuexModuleName: 'wait',
  registerComponent: true,
  componentName: 'v-wait-component',
  registerDirective: true,
  directiveName: 'wait'
});

Vue.component('v-wait', VueWaitDefaultLoader);

export default wait;
