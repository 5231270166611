<template>
  <div
    class="w-full flex flex-col items-center mr-4 mb-4 rounded border p-2"
    :class="{
      'border-primary': activeOption === option.id,
      'border-grey-200': activeOption !== option.id
    }"
    @click="updateActiveOption"
    @keydown.space="updateActiveOption"
  >
    <div class="h-8">
      <component :is="option.icon" class="w-full"></component>
    </div>
    <label
      class="flex flex-col w-full text-center cursor-pointer font-semibold text-grey-700 mt-4"
    >{{ option.title }}</label>
    <gd-button
      size="sm"
      class="mt-4"
    >{{ $t('frontend_gduikit_checkout_preview') }}</gd-button>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTemplateRadio',
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption'
  },
  props: ['option', 'activeOption'],
  methods: {
    updateActiveOption() {
      this.$emit('onUpdateOption', this.option.id);
    }
  }
};
</script>

<style>
.activeOptionRadio {
  box-shadow: 0px 0px 0px 2px white inset;
}
</style>
