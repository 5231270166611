<template>
    <div>
        <div class="w-full">
            <label class="text-base text-grey-800 mb-2">
                {{ $t('frontend_groovesell_link_checkout_popup_modal_text', {pagesAppTitle: whitelabelAppData('pages').title}) }}
            </label>
        </div>
    </div>
</template>
<script>
export default {

}
</script>