<template>
    <gd-modal
      name="filters-affiliates-modal"
      :title="$t('frontend_affiliates')"
      width="70%"
      height="80%"
      bg-type="dark"
      allowOverflow
    >
      <div class="px-4">
        <div class="flex pt-2 pr-2">
          <div class="w-1/2 flex flex-row pl-2">
            <affiliates-auto-suggest
              funnel="0"
              :excluded="excludedFilteredAffiliates"
              @onAffiliateAdded="addedAffiliateToFilter"
            />
          </div>
        </div>
        <div class="flex mt-2 pt-2 pr-2">
          <div class="w-full flex flex-row">
            <affiliate-list-view
              funnel="0"
              :affiliate-ids="selectedFilteredAffiliates"
              @onAffiliateRemoved="removedAffiliateFromFilter"
            />
          </div>
        </div>
      </div>
    </gd-modal>
</template>

<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';

export default {
  components: {
    AffiliatesAutoSuggest,
    AffiliateListView,
  },
  props: {
    filteredAffiliates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedFilteredAffiliates() {
      return this.filteredAffiliates.map((a) => a.id);
    },
    excludedFilteredAffiliates() {
      return this.selectedFilteredAffiliates;
    },
  },
  methods: {
    addedAffiliateToFilter(affiliate) {
      let filteredAffiliates = this.filteredAffiliates;
      if (this.filteredAffiliates.map((a) => a.id).indexOf(affiliate.id) === -1) {
        filteredAffiliates.push(affiliate);
      }

      this.updatedFilteredAffiliates(filteredAffiliates);
    },
    removedAffiliateFromFilter(affiliateId) {
      let filteredAffiliates = this.filteredAffiliates;
      const removedAffiliate = this.filteredAffiliates
        .map((item) => item.id)
        .indexOf(affiliateId);

      filteredAffiliates.splice(removedAffiliate, 1);
      this.updatedFilteredAffiliates(filteredAffiliates);
    },
    updatedFilteredAffiliates(filteredAffiliates) {
      this.$emit('updatedFilteredAffiliates', filteredAffiliates);
    },
  },
};
</script>
