const Loader = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$loader = new Vue({
      data: {
        waitingFor: []
      },
      computed: {
        loading() {
          return this.waitingFor.length > 0;
        }
      },
      methods: {
        start(name) {
          this.waitingFor.push(name);
        },
        end(name) {
          this.waitingFor = this.waitingFor.filter(item => item !== name);
        }
      }
    });
  }
};

export default Loader;
