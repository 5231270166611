<template>
  <div v-if="isValidVideo" class="flex flex-col w-full mt-2 block px-4">
    <div class="embed-container" v-if="isExternalVideoProvider">
      <iframe
        v-if="customSize"
        :width="iframeWidth"
        :height="iframeHeight"
        :src="generateVideoIframe(postOrderVideoDetails)"
      ></iframe>
      <iframe v-else :src="generateVideoIframe(postOrderVideoDetails)"></iframe>
    </div>
    <div class="embed-container" v-else-if="isGrooveVideoProvider">
      <link :href="stylesUrl" rel="stylesheet" />
      <groovevideo-widget
        :id="postOrderVideoDetails.id"
        :permalink="postOrderVideoDetails.permalink"
      ></groovevideo-widget>
    </div>
  </div>
</template>

<script>
import urlParser from 'js-video-url-parser/lib/base';
import 'js-video-url-parser/lib/provider/wistia';

export default {
  props: {
    postOrderVideoDetails: {
      type: Object,
      required: true,
    },
    customSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      externalVideoProviders: ['youtube', 'wistia', 'vimeo'],
      iframeWidth: 420,
      iframeHeight: 315,
    };
  },
  computed: {
    isValidVideo() {
      const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
      const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();

      if (this.postOrderVideoDetails.post_order_show_video == true) {
        const videoInfo = urlParser.parse(this.postOrderVideoDetails.url);
        if (videoInfo != undefined) {
          return true;
        } else {
          const grooveVideoRegex = new RegExp(`(http:|https:|)\/\/?(app\\.${brandAltName}\\.com)\/${videoAppTitle}\/videopage\/[A-Za-z0-9]*\/[A-Za-z0-9]*`, 'g');
          return this.postOrderVideoDetails.url.match(grooveVideoRegex) !== null;
        }
      }
      return false;
    },
    isExternalVideoProvider() {
      return this.externalVideoProviders.includes(this.postOrderVideoDetails.provider);
    },
    isGrooveVideoProvider() {
      const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();

      return this.postOrderVideoDetails.provider == videoAppTitle;
    },
    stylesUrl() {
      const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();

      return `https://widget.${videoAppTitle}.com/widget/app.css`;
    },
  },
  methods: {
    generateVideoIframe(videoDetails) {
      const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
      const videoAppUrl = this.whitelabelAppData('video').url;

      if (this.isExternalVideoProvider) {
        if (videoDetails.provider === 'youtube') {
          return `https://www.youtube.com/embed/${videoDetails.id}?autoplay=1&mute=1`;
        } else if (videoDetails.provider === 'vimeo') {
          return `https://player.vimeo.com/video/${videoDetails.id}`;
        } else if (videoDetails.provider === 'wistia') {
          return `https://fast.wistia.net/embed/iframe/${videoDetails.id}`;
        }
      } else if (this.isGrooveVideoProvider) {
        return `https://app.${brandAltName}.com${videoAppUrl}video/`;
      }
    },
  },
};
</script>
