<template>
  <div class="w-full flex flex-row">
    <div v-if="showElements" class="w-1/3 -my-2 border-r border-grey-300 p-4">
      <div class="embed-container">
        <iframe :src="videoSource"></iframe>
      </div>
      <div class="flex flex-row justify-center">
        <gd-button
          variant="primary"
          size="auto"
          class="mt-2"
          @click="$emit('openPromotionalResourcesPage')"
        >
          {{ $t('frontend_resource_open_resources') }}
        </gd-button>
      </div>
    </div>
    <div
      class="container flex flex-row flex-wrap justify-center grid grid-flow-row grid-cols-4 grid-rows-3 mx-auto"
      :class="{'w-2/3' : showElements}"
    >
      <div
        v-for="buttonDetail in assetsTabs"
        class="flex flex-col items-center my-2 sm:my-2 md:my-2 lg:my-4 lx:my-4 mx-2 sm:mx-2 md:mx-2 lg:mx-4 xl:mx-4"
      >
        <div
          class="
            border border-grey-200
            rounded
            flex flex-row
            justify-center
            py-8
            hover:bg-primary-lightest
            cursor-pointer
            w-full sm:w-full md:w-w-4/5 lg:w-3/5 xl:w-1/2
          "
          @click="$emit('openPromotoolsSection', buttonDetail.value)"
        >
          <div class="flex flex-col justify-center items-center w-full">
            <div v-if="buttonDetail.completed" class="flex flex-row w-full justify-end -mt-8 mr-1 mb-2">
              <div class="text-green-dark">
                <check-square-icon class="mt-1 w-4 h-4 fill-current" />
              </div>
            </div>
            <component :is="buttonDetail.icon" class="h-8 w-8" />
          </div>
        </div>
        <div class="mt-2 flex flex-row w-full justify-center">
          {{ buttonDetail.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckSquareIcon from '@/assets/images/icons/CheckSquareIcon.svg';

export default {
  components: {
    CheckSquareIcon,
  },
  props: {
    assetsTabs: {
      type: Array,
      default: () => [],
    },
    funnelResource: {
      type: Object,
      default: () => {},
    },
    showElements: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    videoSource() {
      if (
        this.funnelResource &&
        this.funnelResource.use_custom_video &&
        this.funnelResource.video_details &&
        this.funnelResource.video_details.id
      ) {
        return this.getVideoSource(this.funnelResource.video_details.service, this.funnelResource.video_details.id);
      }
      return 'https://www.youtube.com/embed/syb231NPuMY?rel=0';
    },
  },
  methods: {
    getVideoSource(service, id) {
      switch (service) {
        case 'youtube':
          return `https://www.youtube.com/embed/${id}`;
        case 'vimeo':
          return `https://player.vimeo.com/video/${id}`;
        default:
          return '';
      }
    },
  },
};
</script>
