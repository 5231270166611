<template>
  <div>
    <div v-if="resources.length" id="resources-list-view" class="mt-8">
      <v-client-table ref="resourcesTable" :data="resources" :columns="resourcesTableColumns"
        :options="resourcesTableOptions" class="bg-white text-center rounded border border-grey-300">
        <div slot="name" slot-scope="props">
          <div class="flex flex-row">
            <div class="mr-2">
              <gd-button v-if="props.row.is_default" variant="primary" size="icon" class="mr-2">
                <star-icon class="w-5 h-5 fill-current" />
              </gd-button>
              <gd-button v-else  size="icon" class="mr-2"
                @click="openConfirmModal(props.row.id, 'markAsDefault')">
                <star-icon class="w-5 h-5 fill-current" />
              </gd-button>
            </div>
            {{ props.row.name }}
          </div>
        </div>
        <div slot="actions" slot-scope="props">
          <div class="flex flex-row">
            <gd-button variant="primary" size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_edit')"
              @click="openResourceAssets(props.row.id)">
              <pencil-icon class="w-5 h-5 fill-current" />
            </gd-button>

            <gd-button v-if="funnel && funnel.is_live && funnel.slug != ''" variant="primary" size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_promotion_tools')"
              @click="$emit('openEmbedCode', props.row.permalink)">
              <code-icon class="w-5 h-5 fill-current" />
            </gd-button>

            <gd-button v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_promotion_tools')" v-else disabled
              size="icon" class="mr-2">
              <code-icon class="w-5 h-5 fill-current" />
            </gd-button>

            <gd-button v-if="funnel && funnel.is_live && funnel.slug != ''"
              v-clipboard:copy="getFunnelAffiliatePortal(props.row.permalink)" v-clipboard:success="onCopyAffiliateLink"
              v-clipboard:error="onErrorAffiliateLinkCopy" variant="primary" size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_affiliate_login')">
              <link-icon class="w-5 h-5 fill-current" />
            </gd-button>
            <gd-button v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_affiliate_login')" v-else disabled
              size="icon" class="mr-2">
              <link-icon class="w-5 h-5 fill-current" />
            </gd-button>

            <gd-button variant="primary" size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_settings')"
              @click="openEditPromoAreaModal(props.row.id)">
              <settings-icon class="w-5 h-5 fill-current" />
            </gd-button>

            <gd-button v-if="props.row.is_default" disabled size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_delete')">
              <trash-icon class="w-5 h-5 fill-current" />
            </gd-button>
            <gd-button v-else variant="primary" size="icon" class="mr-2"
              v-tooltip="$t('frontend_groovesell_tools_affiliate_tooltip_delete')"
              @click="openConfirmModal(props.row.id, 'deletePromoArea')">
              <trash-icon class="w-5 h-5 fill-current" />
            </gd-button>
            <gd-toggle v-model="props.row.is_active" dark :value="props.row.is_active"
              @change="openConfirmModal(props.row.id, 'changePromoToolStatus', props.row.is_active)" />
          </div>
        </div>
      </v-client-table>
    </div>
    <div v-else class="mt-8">{{ $t('frontend_resources_no_funnel') }}</div>
    <confirm-modal name="resources-list-confirm-modal" :message-content="messageContent" :button-text="buttonText"
      button-size="auto" @confirm="applyAction" />
  </div>
</template>

<script>
import Notify from '@groovepages/gd-ui-kit/notify';
import router from '@/router';
import axios from 'axios';
import FunnelResource from '@/models/FunnelResource';
import StarIcon from '@/assets/images/icons/StarIcon.svg';
import PencilIcon from '@/assets/images/icons/PencilIcon.svg';
import CodeIcon from '@/assets/images/icons/CodeIcon.svg';
import LinkIcon from '@/assets/images/icons/LinkIcon.svg';
import SettingsIcon from '@/assets/images/icons/SettingsIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  components: {
    StarIcon,
    PencilIcon,
    CodeIcon,
    LinkIcon,
    SettingsIcon,
    TrashIcon,
  },
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
    funnel: {
      type: Object,
      default: () => { },
    },
    model: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      resourceId: null,
      newStatus: '',
      actionType: '',
      messageContent: '',
      buttonText: '',
      localModel: this.model,
      resourcesTableColumns: ['name', 'created_at', 'actions'],
      resourcesTableOptions: {
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
        },
        headings: {
          name: this.$t('frontend_name'),
          actions: this.$t('frontend_actions'),
          created_at: this.$t('frontend_date'),
        },
        filterByColumn: false,
        filterable: false,
        childRowTogglerFirst: false,
        perPage: 10000,
        rowClassCallback() {
          return 'hover:bg-grey-50 funnelRow';
        },
      },
    }
  },
  methods: {
    openResourceAssets(resourceId) {
      router.push({
        name: 'funnel_resource_assets',
        params: {
          id: this.funnel.id,
          resource_id: resourceId,
        },
      });
    },
    openConfirmModal(resourceId, actionType, newStatus = '') {
      this.resourceId = resourceId;
      this.newStatus = newStatus;
      this.actionType = actionType;
      switch (actionType) {
        case 'markAsDefault':
          this.messageContent = this.$t('frontend_groovesell_resource_swal_title');
          this.buttonText = this.$t('frontend_groovesell_resource_swal_confirm_text');
          break;
        case 'deletePromoArea':
          this.messageContent = this.$t('frontend_non_revertable');
          this.buttonText = this.$t('frontend_delete_confirm');
          break;
        case 'changePromoToolStatus':
          this.messageContent = `${this.$t('frontend_groovesell_tools_affiliate_want_to')} ${newStatus
            ? this.$t('frontend_groovesell_tools_affiliate_enable')
            : this.$t('frontend_groovesell_tools_affiliate_disable')} ${this.$t('frontend_groovesell_tools_affiliate_this_promo_tool')}`;
          this.buttonText = `${this.$t('frontend_groovesell_tools_affiliate_yes')}, ${this.newStatus
            ? this.$t('frontend_groovesell_tools_affiliate_enable_it')
            : this.$t('frontend_groovesell_tools_affiliate_disable_it')}`;
          break;
      }

      this.$root.$emit('modal-open', 'resources-list-confirm-modal');
    },
    applyAction() {
      switch (this.actionType) {
        case 'markAsDefault':
          this.markAsDefault();
          break;
        case 'deletePromoArea':
          this.deletePromoArea();
          break;
        case 'changePromoToolStatus':
          this.changePromoToolStatus();
          break;
      }
    },
    async markAsDefault() {
      this.$loader.start('markAsDefault');
      await axios.post('/funnel-resources/make-default', {
        id: this.resourceId,
      });

      const resources = await this.funnel.promotional_areas().$get();

      this.$emit('updateResources', resources);
      this.$loader.end('markAsDefault');
    },
    getFunnelAffiliatePortal(resourcePermalink) {
      if (this.funnel && this.funnel.slug) {
        const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');

        return `https://${this.funnel.slug}.${siteAppurl}/${this.funnel.id}/affiliate-signup/${resourcePermalink}`;
      }

      return '';
    },
    onCopyAffiliateLink() {
      Notify.success('Affiliate Portal Copied To Your Clipboard');
    },
    onErrorAffiliateLinkCopy() {
      Notify.fail('Unable To Copy Affiliate Link');
    },
    async openEditPromoAreaModal(resourceId) {
      this.$loader.start('openEditPromoAreaModal');
      const resource = await FunnelResource.$find(resourceId);

      this.localModel.name = resource.name;
      this.localModel.landing_page_id = null;

      if (
        resource.landing_page_id &&
        this.funnel.main_product.affiliate_landing_pages.some(
          (el) => el.id == resource.landing_page_id
        )
      ) {
        this.localModel.landing_page_id = resource.landing_page_id;
      } else if (this.funnel.main_product.affiliate_landing_pages.length) {
        this.localModel.landing_page_id = this.funnel.main_product.affiliate_landing_pages[0].id;
      }

      this.localModel.use_custom_video = resource.use_custom_video;
      this.localModel.video_link = resource.video_link;
      this.localModel.show_more_funnels = resource.show_more_funnels;
      this.localModel.related_funnels = resource.related_funnels;
      this.localModel.show_more_resources = resource.show_more_resources;
      this.localModel.related_resources = resource.related_resources || [];
      this.localModel.landing_pages = resource.landing_pages;
      this.localModel.header_text = resource.header_text;
      this.localModel.login_text = resource.login_text;
      this.localModel.signup_text = resource.signup_text;
      this.localModel.disable_branding = resource.disable_branding;

      this.$emit('updateModel', this.localModel);
      this.$emit('updateResource', resource);
      this.$root.$emit('modal-open', 'edit-promo-area-modal');
      this.$loader.end('openEditPromoAreaModal');
    },
    async deletePromoArea() {
      this.$loader.start('deletePromoArea');

      const resources = this.resources;
      const resource = await FunnelResource.$find(this.resourceId);
      await resource.delete();

      const deleted_resource = resources
        .map((item) => item.id)
        .indexOf(this.resourceId);
      resources.splice(deleted_resource, 1);

      this.$emit('updateResources', resources);
      this.$loader.end('deletePromoArea');
    },
    async changePromoToolStatus() {
      this.$loader.start('changePromoToolStatus');

      await axios.post('/funnel-resources/update-status', {
        id: this.resourceId,
        status: this.newStatus,
      });

      const resources = await this.funnel.promotional_areas().$get();

      this.$emit('updateResources', resources);
      this.$loader.end('changePromoToolStatus');
    },
  }
}
</script>
