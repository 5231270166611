// TODO: Translations and show error code from backend
import axios from 'axios';
import Auth from '@groovepages/gd-lib/auth';
import storage from 'local-storage-fallback';
import store from '@/store';
import Vue from 'vue';

// Request interceptor
axios.interceptors.request.use(request => {
  const { token } = Auth.authenticatedUser();
  const finalRequest = request;

  if (token) {
    finalRequest.headers.common.Authorization = `Bearer ${token}`;
  }

  return finalRequest;
});

// Response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;


    if (status === 401) {
      if (window.location.href.indexOf('promo-tools') > -1) {
        // Logout user for promo tools
        Auth.logout();
        window.location.reload();
        return Promise.reject(error);
      }
      store.commit('logout');
      window.location.replace(`${window.config.grooveappsurl}/#/login`);
    }

    return Promise.reject(error);
  }
);
