<template>
    <gd-modal name="filters-second-tier-affiliates-modal" :title="$t('frontend_analytics_second_tier_affiliates')" width="70%" height="80%" bg-type="dark" allowOverflow>
        <div class="px-4">
            <div class="flex pt-2 pr-2">
                <div class="w-1/2 flex flex-row pl-2">
                    <affiliates-auto-suggest funnel="0" :excluded="excludedFilteredSecondTierAffiliates"
                        @onAffiliateAdded="addedAffiliateToFilter" />
                </div>
            </div>
            <div class="flex mt-2 pt-2 pr-2">
                <div class="w-full flex flex-row">
                    <affiliate-list-view funnel="0" :affiliate-ids="selectedFilteredSecondTierAffiliates"
                        @onAffiliateRemoved="removedAffiliateFromFilter" />
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import AffiliatesAutoSuggest from '@/components/AffiliatesAutoSuggest';
import AffiliateListView from '@/components/AffiliateListView';
export default {
    components: {
        AffiliatesAutoSuggest,
        AffiliateListView,
    },
    computed: {
        excludedFilteredSecondTierAffiliates() {
            return this.selectedFilteredSecondTierAffiliates;
        },
        selectedFilteredSecondTierAffiliates() {
            return this.filteredSecondTierAffiliates.map((a) => a.id);
        },
    },
    props: {
        filteredSecondTierAffiliates: {
            required: true,
        },
    },

    methods: {
        addedAffiliateToFilter(affiliate) {
            if (this.filteredSecondTierAffiliates.map((a) => a.id).indexOf(affiliate.id) === -1) {
                const newFilteredSecondTierAffiliates = [...this.filteredSecondTierAffiliates, affiliate];
                this.$emit('filter-changed', {
                    key: 'filteredSecondTierAffiliates',
                    value: newFilteredSecondTierAffiliates,
                    startSearch: false,
                })
            }
        },

        removedAffiliateFromFilter(affiliateId) {
            const removedAffiliate = this.filteredSecondTierAffiliates
                .map((item) => item.id)
                .indexOf(affiliateId);
            const newFilteredSecondTierAffiliates = [...this.filteredSecondTierAffiliates];
            newFilteredSecondTierAffiliates.splice(removedAffiliate, 1);
            this.$emit('filter-changed', {
                key: 'filteredSecondTierAffiliates',
                value: newFilteredSecondTierAffiliates,
                startSearch: false,
            });
        },
    }

}

</script>