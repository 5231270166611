<template>
  <div>
    <div class="flex flex-col w-full p-4 bg-white rounded">
      <div class="flex flex-col w-1/2 mb-2">
        <h4 class="font-semibold text-grey-700">
          {{ $t('frontend_product_step2_add_pricing_plan') }}
          {{ localModel.name }}
        </h4>
      </div>
      <div class="flex flex-col w-1/2 mb-2">
        <span class="text-sm text-grey-700">{{
          $t('frontend_product_step2_add_pricing_plans_determine')
        }}</span>
      </div>
      <div class="flex flex-row px-4 mb-2 -mx-4 border border-grey-100">
        <gd-tabs :tabs="pricePointsTabs" :error-tabs="pricePointsErrorTabs" :current-tab="pricePointsTab"
          :wrapper-class="'pt-4 px-0 gs-default-tabs flex-1'" :tab-class="'pb-4 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handlePricePointsTabChange" />
        <gd-button size="auto" variant="primary" class="mt-3 ml-4" @click="createPricePoint">
          <plus-icon class="h-5 w-5 fill-current"/>
          {{ $t('frontend_product_step2_add_new_pricepoint') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(pricingPoint, index) in localModel.pricing_points" :key="index">
          <div v-if="pricePointsTab === 'price_point_' + pricingPoint.id" :ref="'price_point_tab' + pricingPoint.id">
            <div class="flex flex-row w-full mb-6">
              <div class="flex flex-col w-1/2">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{ $t('frontend_price_point_name') }}</label>
                <text-input v-model="pricingPoint.name" :validator="$v.localModel.pricing_points.$each[index].name"
                  :attribute="$t('frontend_groovesell_name')" value="pricingPoint.name"
                  :placeholder="$t('frontend_product_step2_name')" />
              </div>
              <div class="flex flex-row-reverse w-1/2">
                <gd-button variant="outline" size="icon" class="ml-4" @click="openClonePricePointConfirmModal(index)">
                  <clone-icon class="h-5 w-5 fill-current"/>
                </gd-button>
              </div>
              <div v-if="isDeletablePricingOption(index)" class="flex flex-row-reverse">
                <gd-button variant="outline" size="icon" class="ml-4" @click="openConfirmModal(index)">
                  <trash-icon class="h-5 w-5 fill-current"/>
                </gd-button>
              </div>
            </div>
            <div class="flex flex-col w-1/2 mb-6">
              <label class="mb-2 text-sm font-semibold text-grey-700">{{ $t('frontend_internal_name') }}</label>
              <text-input v-model="pricingPoint.internal_name" :validator="$v.localModel.pricing_points.$each[index].internal_name
                " :attribute="$t('frontend_internal_name')" value="pricingPoint.internal_name"
                :placeholder="$t('frontend_internal_name')" />
            </div>
            <div class="flex flex-col w-1/2 mb-6">
              <label class="mb-2 text-sm font-semibold text-grey-700">{{
                $t('frontend_product_step2_price_point_description')
              }}</label>
              <text-area v-model="pricingPoint.description" :validator="$v.localModel.pricing_points.$each[index].description
                " value="pricingPoint.description" :attribute="$t('frontend_desc')"
                :placeholder="$t('frontend_product_step2_price_description')" />
            </div>
            <div class="flex flex-row w-1/2 mb-6">
              <label class="mb-2 mr-2 text-sm font-semibold text-grey-700">{{
                $t('frontend_product_step2_price_is_default')
              }}</label>
              <gd-toggle :value="pricingPoint.is_default" @change="setIsDefaultPricePoint(index)" />
            </div>
            <div class="flex flex-col w-full mb-6">
              <label class="mb-2 text-sm font-semibold text-grey-700">{{
                $t('frontend_product_step2_price_type')
              }}</label>
              <div class="flex flex-wrap w-full -mx-2">
                <div v-for="(
                    pricePointType, pricePointTypeIndex
                  ) in getPricePointTypes" :key="pricePointTypeIndex" class="flex w-1/3 px-2 py-1">
                  <gd-custom-radio v-model="pricingPoint.type" :option="pricePointType"></gd-custom-radio>
                </div>
              </div>
            </div>
            <div class="flex flex-row w-1/2 mb-6" v-if="pricingPoint.type != 5">
              <label class="mb-2 mr-2 text-sm font-semibold text-grey-700">
                {{ $t('frontend_product_step2_enable_trail') }}
              </label>
              <gd-toggle :value="pricingPoint.is_trial" @change="setIsTrialPricePoint(index)" />
            </div>
            <div v-if="pricingPoint.is_trial && pricingPoint.type != 5" class="flex flex-row w-1/2 mb-6">
              <div class="flex flex-col w-1/2 mb-6 mr-6">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{
                  $t('frontend_product_step2_initial_price')
                }}</label>
                <gd-currency-input v-model="pricingPoint.trial_price" :currency-code="pricingPoint.currency_code" />
              </div>
              <div class="flex flex-col w-1/2 mb-6 mr-6">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{
                  $t('frontend_product_step2_initial_period')
                }}</label>
                <div class="flex flex-row">
                  <text-input v-model="pricingPoint.trial_period" class="w-1/2 mr-1" :validator="$v.localModel.pricing_points.$each[index].trial_period
                    " :attribute="$t('frontend_product_step2_period')" value="pricingPoint.trial_period"
                    :placeholder="$t('frontend_product_step2_period')" />
                  <gd-dropdown
                    v-model="pricingPoint.trial_period_type"
                    size="full"
                    :options="trialPeriodOptions"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-row w-1/2">
              <div class="flex flex-col w-1/2 mb-6 mr-6">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{
                  pricingPoint.type != 5
                  ? $t('frontend_product_step2_price')
                  : $t('frontend_product_step2_price_minimum')
                }}</label>
                <gd-currency-input v-model="pricingPoint.price" :currency-code="pricingPoint.currency_code" />
              </div>
              <div v-if="pricingPoint.type == 2 ||
                pricingPoint.type == 3 ||
                pricingPoint.type == 4
                " class="flex flex-col w-1/2 mb-6">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{
                  $t('frontend_product_step2_interval')
                }}</label>
                <gd-dropdown
                  v-model="pricingPoint.recurring_interval"
                  size="full"
                  :options="recurringIntervalOptions(pricingPoint.type)"
                />
              </div>
            </div>
            <div v-if="pricingPoint.type == 3 || pricingPoint.type == 4" class="flex flex-col w-1/2 mb-6">
              <label class="mb-2 text-sm font-semibold text-grey-700">{{
                $t('frontend_product_step2_no_of_installments')
              }}</label>

              <text-input v-if="pricingPoint.type == 3 || pricingPoint.type == 4"
                v-model="pricingPoint.number_of_installments" :validator="$v.localModel.pricing_points.$each[index].number_of_installments" :attribute="$t('frontend_installments')" value="pricingPoint.number_of_installments"
                :placeholder="$t('frontend_product_step2_no_of_installments')" />

            </div>
            <div v-if="pricingPoint.type == 4" class="flex flex-row w-1/2 mb-6">
              <div class="flex flex-col w-1/2 mb-6 mr-6">
                <label class="mb-2 text-sm font-semibold text-grey-700">{{
                  $t('frontend_product_step2_recurrence_interval')
                }}</label>
                <gd-dropdown
                  v-model="pricingPoint.recurrence_interval"
                  size="full"
                  :options="yearlyRecurringIntervalOptions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      name="confirm-delete-pricepoint-modal"
      :message-content="$t('frontend_non_revertable')"
      :confirm-button-text="$t('frontend_delete_confirm')"
      @confirm="deletePricePoint"
    />
    <confirm-modal
      name="confirm-clone-price-point-modal"
      :confirm-message="$t('frontend_groovesell_swal_copy_commision_overrides')"
      :message-content="$t('frontend_groovesell_swal_copy_commision_overrides_desc')"
      :confirm-button-text="$t('frontend_groovesell_swal_copy_commision_overrides_btn_confirm')"
      @confirm="clonePricePoint"
    />
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
  decimal,
  requiredIf,
  minValue,
} from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import PricePoint from '@/models/PricePoint';
import Funnel from '@/models/Funnel';
import PlusIcon from '@/assets/images/icons/PlusIcon.svg';
import CloneIcon from '@/assets/images/icons/CloneIcon.svg';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  name: 'Step2',
  components: {
    PlusIcon,
    CloneIcon,
    TrashIcon,
  },
  props: {
    model: {
      required: true,
    },
    product: {
      required: true,
    },
    pricePoints: {
      required: true,
    },
  },
  data() {
    return {
      pricePointId: null,
      funnel: null,
      pricePointsTab: '',
      localModel: this.model,
      localPricePoints: this.pricePoints,
      pricePointIndex: 0,
      pricePoint: null,
    };
  },
  validations: {
    localModel: {
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          internal_name: {
            minLength: minLength(3),
            maxLength: maxLength(500),
          },
          description: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(160),
          },
          price: {
            required,
            decimal,
            minValue: minValue(0),
            isValidPricePointPrice(value, object) {
              if (value > 0) {
                return true;
              }
              if (object.is_trial == 1) {
                return false;
              }
              if (object.type == 2 || object.type == 3) {
                return false;
              }
              return true;
            },
          },
          number_of_installments: {
            required: requiredIf((object) => {
              return object.type == 3 || object.type == 4;
            }),
            numeric,
            isValidNumberOfInstallments(value, object) {
              if (object.type == 3 || object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },

          recurring_interval: {
            required: requiredIf((object) => {
              return object.type == 3 || object.type == 4;
            }),
            numeric,
            isValidNumberOfRicurringInterval(value, object) {
              if (object.type == 3 || object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },
          recurrence_interval: {
            required: requiredIf((object) => {
              return object.type == 4;
            }),
            numeric,
            isValidNumberOfRecurrenceInterval(value, object) {
              if (object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },
          trial_price: {
            required: requiredIf((object) => {
              return object.is_trial == 1;
            }),
            decimal,
            minValue: minValue(0),
          },
          trial_period: {
            required: requiredIf((object) => {
              return object.is_trial == 1;
            }),
            numeric,
            minValue: minValue(0),
          },
          $trackBy: 'id',
        },
      },
    },
  },
  computed: {
    trialPeriodOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step2_days') },
        { value: 2, text: this.$t('frontend_product_step2_weeks') },
        { value: 3, text: this.$t('frontend_product_step2_months') },
        { value: 4, text: this.$t('frontend_product_step2_years') },
      ];
    },
    yearlyRecurringIntervalOptions() {
      return [
        { value: 1, text: this.$t('frontend_product_step2_every_year') },
        { value: 2, text: this.$t('frontend_product_step2_every_2_years') },
      ];
    },
    getPricePointTypes() {
      return [
        {
          id: 1,
          title: this.$t('frontend_product_step_2_pp_types_tab1_title'),
          description: this.$t('frontend_product_step_2_pp_types_tab1_desc'),
        },
        {
          id: 2,
          title: this.$t('frontend_product_step_2_pp_types_tab2_title'),
          description: this.$t('frontend_product_step_2_pp_types_tab2_desc'),
        },
        {
          id: 3,
          title: this.$t('frontend_product_step_2_pp_types_tab3_title'),
          description: this.$t('frontend_product_step_2_pp_types_tab3_desc'),
        },
        {
          id: 4,
          title: this.$t('frontend_product_step_2_pp_types_tab4_title'),
          description: this.$t('frontend_product_step_2_pp_types_tab4_desc'),
          // coming_soon: true
        },
        {
          id: 5,
          title: this.$t('frontend_product_step_2_pp_types_tab5_title'),
          description: this.$t('frontend_product_step_2_pp_types_tab5_desc'),
          // coming_soon: true
        }
      ]
    },
    pricePointsTabs() {
      const pricePointTabs = [];

      for (let i = 0; i < this.localModel.pricing_points.length; i++) {
        pricePointTabs.push({
          title: this.localModel.pricing_points[i].name,
          value: `price_point_${this.localModel.pricing_points[i].id}`,
        });
      }

      return pricePointTabs;
    },

    pricePointsErrorTabs() {
      const pricePointErrorTabs = [];

      let pricePointIndex;
      for (pricePointIndex in this.$v.localModel.pricing_points.$each.$iter) {
        if (
          this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$error
        ) {
          pricePointErrorTabs.push(
            `price_point_${this.$v.localModel.pricing_points.$each.$iter[pricePointIndex].$model.id}`
          );
        }
      }

      return pricePointErrorTabs;
    },
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },

    localPricePoints() {
      this.$emit('updatePricePoints', this.localPricePoints);
    },
  },
  mounted() {
    if (
      this.pricePointsTab == '' &&
      this.localModel.pricing_points.length > 0
    ) {
      this.pricePointsTab = `price_point_${this.localModel.pricing_points[0].id}`;
    }
  },
  methods: {
    recurringIntervalOptions(type) {
      const options = [
        { value: 1, text: this.$t('frontend_product_step2_weekly') },
        { value: 2, text: this.$t('frontend_product_step2_every_2_weeks') },
        { value: 3, text: this.$t('frontend_product_step2_monthly') },
        { value: 4, text: this.$t('frontend_product_step2_every_2_months') },
      ];

      if (type != 4) {
        options.push(
          { value: 5, text: this.$t('frontend_product_step2_quarterly') },
          { value: 9, text: this.$t('frontend_product_step2_every_4_months') },
          { value: 6, text: this.$t('frontend_product_step2_half_yearly') },
          { value: 7, text: this.$t('frontend_product_step2_yearly') },
          { value: 8, text: this.$t('frontend_product_step2_every_2_years') }
        );
      }

      return options;
    },
    validate() {
      this.$v.$touch();
      const isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },

    async createPricePoint() {
      this.$loader.start('createPricePoint');

      const newPricePointCount = this.localModel.pricing_points.length + 1;

      const commision_id = Math.ceil(Math.random() * 10000);

      this.pricePoint = new PricePoint({
        type: 1,
        name: `${this.product.name} #${newPricePointCount}`,
        internal_name: '',
        description: '',
        price: 1,
        recurring_interval: 1,
        number_of_installments: 1,
        recurrence_interval: 1,
        is_trial: 0,
        trial_price: 0.0,
        trial_period: 0,
        trial_period_type: 1,
        salespage_type: 1,
        salespage_details: {
          sales_page: '',
        },
        quantity_enable: 0,
        minimum_quantity: 1,
        maximum_quantity: 5,
        offer_bump_enable: 0,
        offer_bump_options: [],
        post_checkout_type: 1,
        post_checkout_details: {
          thankyou_page: '',
        },
        post_transaction_details: {
          type: 1,
          post_transaction_url: '',
        },
        post_sale_notification_type: 1,
        post_sale_details: null,
        post_sale_integrations: null,
        memberships: null,
        file_memberships: null,
        legal_options: {
          enabled: 0,
          html: '',
        },
        abandon_cart_integrations: null,
        affiliate_registration_integrations: null,
        customer_portal_details: null,
        content_access_type: 0,
        content_access_options: {},
        facebook_conversion_api_enabled: 0,
        pixel_id: 0,
        advanced_tracking_code_enabled: 0,
        advanced_tracking_code_details: {
          tracking_script: '',
        },
        pricing_string: this.$t('frontend_product_step_2_pricing_string'),
        commission_type: 1,
        commission_details: [
          {
            id: commision_id,
            type: 1,
            name: 'Default',
            assigned_to: null,
            sales_commission: {
              commission: 0,
              commission_type: 'percent',
              jv_broker_commission: 0,
              jv_broker_commission_type: 'percent',
            },
            trial_commission: {
              commission: 0,
              commission_type: 'percent',
              jv_broker_commission: 0,
              jv_broker_commission_type: 'percent',
            },
            rebill_commissions: [
              {
                commission: 0,
                no_of_payments: null,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
            ],
          },
        ],
        is_default: 0,
        post_sale_order_bump_options: {
          post_order_heading_line: null,
          post_order_subheading: null,
          post_order_description: null,
          post_order_video_details: {
            post_order_show_video: 0,
            url: '',
            id: null,
            provider: '',
            permalink: null,
          },
          post_order_bump_enable: 0,
          post_order_display_thumbnail: 0,
          post_order_thumbnail_image: '',
          post_order_is_square: 0,
          post_order_thumbnail_size: 'md',
          post_order_style_id: 1,
          post_order_product_id: null,
          post_order_price_point_id: null,
        },
      }).for(this.product);

      const savedProductData = await this.pricePoint.save();

      this.pricePoint = await this.product
        .pricepoints()
        .$find(savedProductData.data.id);
      this.product.price_points.push(this.pricePoint);
      this.localPricePoints.push(this.pricePoint);
      this.funnel = await Funnel.$find(this.$route.params.id);
      const funnelProducts = await this.funnel.products().$get();
      const productPricePoints = funnelProducts[0].price_points;

      this.localModel.pricing_points.push({
        id: this.pricePoint.id,
        type: 1,
        name: `${this.product.name} #${newPricePointCount}`,
        internal_name: '',
        description: '',
        price: 1,
        recurring_interval: 1,
        number_of_installments: 1,
        recurrence_interval: 1,
        is_trial: 0,
        trial_price: 0.0,
        trial_period: 0,
        trial_period_type: 1,
        salespage_type: 1,
        salespage_details: {
          sales_page: '',
        },
        funnelProducts,
        productPricePoint: productPricePoints,
        minimum_quantity: 1,
        maximum_quantity: 5,
        quantity_enable: 0,
        offer_bump_enable: 0,
        offer_bump_options: [],
        post_checkout_type: 1,
        post_checkout_details: {
          thankyou_page: '',
        },
        post_transaction_details: {
          type: 1,
          post_transaction_url: '',
        },
        post_sale_notification_type: 1,
        post_sale_details: null,
        post_sale_integrations: null,
        memberships: null,
        file_memberships: null,
        legal_options: {
          enabled: 0,
          html: '',
        },
        abandon_cart_integrations: null,
        affiliate_registration_integrations: null,
        customer_portal_details: null,
        content_access_type: 0,
        content_access_options: {},
        facebook_conversion_api_enabled: 0,
        pixel_id: 0,
        advanced_tracking_code_enabled: 0,
        advanced_tracking_code_details: {
          tracking_script: '',
        },
        pricing_string: this.$t('frontend_product_step_2_pricing_string'),
        currency_code: this.funnel.currency_code.toUpperCase(),
        commission_type: 1,
        commission_details: [
          {
            type: 1,
            id: commision_id,
            name: 'Default',
            assigned_to: null,
            sales_commission: {
              commission: 0,
              commission_type: 'percent',
              jv_broker_commission: 0,
              jv_broker_commission_type: 'percent',
            },
            trial_commission: {
              commission: 0,
              commission_type: 'percent',
              jv_broker_commission: 0,
              jv_broker_commission_type: 'percent',
            },
            rebill_commissions: [
              {
                commission: 0,
                no_of_payments: null,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
            ],
          },
        ],
        is_default: 0,
        post_sale_order_bump_options: {
          post_order_heading_line: null,
          post_order_subheading: null,
          post_order_description: null,
          post_order_video_details: {
            post_order_show_video: 0,
            url: '',
            id: null,
            provider: '',
            permalink: null,
          },
          post_order_bump_enable: 0,
          post_order_display_thumbnail: 0,
          post_order_thumbnail_image: '',
          post_order_is_square: 0,
          post_order_thumbnail_size: 'md',
          post_order_style_id: 1,
          post_order_product_id: null,
          post_order_price_point_id: null,
        },
      });

      this.pricePointsTab = `price_point_${this.pricePoint.id}`;
      this.$loader.end('createPricePoint');
    },
    openConfirmModal(id) {
      this.$root.$emit('modal-open', 'confirm-delete-pricepoint-modal');
      this.pricePointId = id;
    },
    async deletePricePoint() {
      this.$loader.start('deletePricePoint');

      const currentlySelectedPricePoint = this.localPricePoints[this.pricePointId];

      const pricePoint = await this.product
        .pricepoints()
        .$find(currentlySelectedPricePoint.id);
      await pricePoint.delete();
      this.localPricePoints.splice(this.pricePointId, 1);
      this.localModel.pricing_points.splice(this.pricePointId, 1);

      let nextOpenIndex = 0;
      if (this.pricePointId == 0) {
        nextOpenIndex = 0;
      } else {
        nextOpenIndex = this.pricePointId - 1;
      }

      const nextOpenPricePointId =
        this.localModel.pricing_points[nextOpenIndex].id;
      this.pricePointsTab = `price_point_${nextOpenPricePointId}`;
      this.$loader.end('deletePricePoint');
    },
    openClonePricePointConfirmModal(index) {
      this.pricePointId = index;
      this.$root.$emit('modal-open', 'confirm-clone-price-point-modal');
    },
    async clonePricePoint() {
      this.$loader.start('clonePricePoint');
      const currentSelectedPricePointIndex = this.localPricePoints[this.pricePointId];
      const currentSelectedPricePoint = await this.product
        .pricepoints()
        .$find(currentSelectedPricePointIndex.id);

      const commissionDetails = [];

      currentSelectedPricePoint.commission_details.forEach((element) => {
        const commision_id = Math.ceil(Math.random() * 10000);
        const tempCommission = {
          id: commision_id,
          type: element.type,
          name: element.name,
          assigned_to: element.assigned_to,
          sales_commission: element.sales_commission,
          trial_commission: element.trial_commission,
          rebill_commissions: element.rebill_commissions,
        };
        commissionDetails.push(tempCommission);
      });

        const newClonedPricePoint = JSON.parse(
          JSON.stringify(currentSelectedPricePoint)
        );
        delete newClonedPricePoint.id;
        newClonedPricePoint.name += ' Clone';
        newClonedPricePoint.commission_details = commissionDetails;
        newClonedPricePoint.is_default = 0;

        this.pricePoint = new PricePoint(newClonedPricePoint).for(this.product);

        const savedProductData = await this.pricePoint.save();

        this.pricePoint = await this.product
          .pricepoints()
          .$find(savedProductData.data.id);

        this.product.price_points.push(this.pricePoint);
        this.localPricePoints.push(this.pricePoint);
        this.funnel = await Funnel.$find(this.$route.params.id);
        const funnelProducts = await this.funnel.products().$get();
        const productPricePoints = funnelProducts[0].price_points;

        this.localModel.pricing_points.push({
          id: this.pricePoint.id,
          type: this.pricePoint.type,
          name: this.pricePoint.name,
          internal_name: this.pricePoint.internal_name,
          description: this.pricePoint.description,
          price: this.pricePoint.price,
          recurring_interval: this.pricePoint.recurring_interval,
          number_of_installments: this.pricePoint.number_of_installments,
          recurrence_interval: this.pricePoint.recurrence_interval,
          is_trial: this.pricePoint.is_trial,
          trial_price: this.pricePoint.trial_price,
          trial_period: this.pricePoint.trial_period,
          trial_period_type: this.pricePoint.trial_period_type,
          salespage_type: this.pricePoint.salespage_type,
          salespage_details: this.pricePoint.salespage_details,
          funnelProducts,
          productPricePoint: productPricePoints,
          minimum_quantity: this.pricePoint.minimum_quantity,
          maximum_quantity: this.pricePoint.maximum_quantity,
          quantity_enable: this.pricePoint.quantity_enable,
          offer_bump_enable: this.pricePoint.offer_bump_enable,
          offer_bump_options: this.pricePoint.offer_bump_options,
          post_checkout_type: this.pricePoint.post_checkout_type,
          post_checkout_details: this.pricePoint.post_checkout_details,
          post_transaction_details: this.pricePoint.post_transaction_details,
          post_sale_notification_type:
            this.pricePoint.post_sale_notification_type,
          post_sale_details: this.pricePoint.post_sale_details,
          post_sale_integrations: this.pricePoint.post_sale_integrations,
          memberships: this.pricePoint.memberships,
          file_memberships: this.pricePoint.file_memberships,
          legal_options: this.pricePoint.legal_options,
          abandon_cart_integrations: this.pricePoint.abandon_cart_integrations,
          affiliate_registration_integrations:
            this.pricePoint.affiliate_registration_integrations,
          customer_portal_details: this.pricePoint.customer_portal_details,
          content_access_type: this.pricePoint.content_access_type,
          content_access_options: this.pricePoint.content_access_options,
          advanced_tracking_code_enabled:
            this.pricePoint.advanced_tracking_code_enabled,
          facebook_conversion_api_enabled:
            this.pricePoint.facebook_conversion_api_enabled,
          pixel_id:
            this.pricePoint.pixel_id,
          advanced_tracking_code_details:
            this.pricePoint.advanced_tracking_code_details,
          pricing_string: this.pricePoint.pricing_string,
          currency_code: this.pricePoint.currency_code,
          commission_type: this.pricePoint.commission_type,
          commission_details: commissionDetails,
          is_default: this.pricePoint.is_default,
          post_sale_order_bump_options:
            this.pricePoint.post_sale_order_bump_options,
        });

        this.pricePointsTab = `price_point_${this.pricePoint.id}`;
        this.$loader.end('clonePricePoint');
    },

    setIsDefaultPricePoint(index) {
      const pricePoint = this.localModel.pricing_points[index];

      if (!pricePoint.is_trial) {
        this.localModel.pricing_points.forEach((pricingPoint) => {
          pricingPoint.is_default = false;
        });

        pricePoint.is_default = !pricePoint.is_default;
      } else {
        return false;
      }
    },

    setIsTrialPricePoint(index) {
      this.localModel.pricing_points[index].is_trial =
        !this.localModel.pricing_points[index].is_trial;
      this.localPricePoints[index].is_trial =
        !this.localPricePoints[index].is_trial;
    },

    handlePricePointsTabChange(newTab) {
      this.pricePointsTab = newTab;
    },

    isDeletablePricingOption(index) {
      return (
        this.localModel.pricing_points.length > 1 &&
        !this.localModel.pricing_points[index].is_default
      );
    },
  },
};
</script>
