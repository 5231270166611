<template>
  <div class="min-h-full h-auto bg-secondary pb-6">
    <wizard
      :tabs="tabs"
      :current-tab="currentTab"
      @saveAllInfo="saveAllInfo"
      @validateRef="validateRef"
    />

    <v-wait for="create_coupon">
      <div class="px-5">
        <div class="relative flex flex-col justify-start w-full h-full mt-8 container mx-auto">
          <div v-if="currentTab === 'step1'">
            <step1
              ref="step1"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful($event)"
            ></step1>
          </div>

          <div v-else-if="currentTab === 'step2'">
            <step2
              ref="step2"
              :model="model"
              :funnels="funnels"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful($event)"
            ></step2>
          </div>

          <div v-if="currentTab === 'step3'">
            <step3
              ref="step3"
              :model="model"
              :funnels="funnels"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful($event)"
            ></step3>
          </div>

          <div v-else-if="currentTab === 'step4'">
            <step4
              ref="step4"
              :model="model"
              @updateModel="model = $event"
              @on-validate="stepValidationSuccessful($event)"
            ></step4>
          </div>

          <div v-else-if="currentTab === 'step5'">
            <step5
              ref="step5"
              :model="model"
              @on-validate="stepValidationSuccessful('step5', $event)"
            ></step5>
          </div>
        </div>
      </div>
    </v-wait>

    <portal to="headerBreadCrumb">
      <div class="cursor-pointer flex items-center -ml-4" @click="sendToCoupons">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import Coupon from '@/models/Coupon';
import axios from 'axios';
import step1 from './step1';
import step2 from './step2';
import step3 from './step3';
import step4 from './step4';
import step5 from './step5';
import Wizard from './partials/Wizard.vue';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

export default {
  layout: 'defaultWithoutSidebar',
  middleware: 'auth',
  metaInfo() {
    return { title: 'Create Coupon' };
  },
  components: {
    step1,
    step2,
    step3,
    step4,
    step5,
    Wizard,
    ChevronLeftIcon,
},
  data() {
    return {
      tabs: [
        {
          title: `<span class="text-sm font-semibold">1. ${this.$t('frontend_groovesell_coupons_create_tabs1')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step1'
        },
        {
          title: `<span class="text-sm font-semibold">2. ${this.$t('frontend_groovesell_coupons_create_tabs2')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step2'
        },
        {
          title: `<span class="text-sm font-semibold">3. ${this.$t('frontend_groovesell_coupons_create_tabs3')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step3'
        },
        {
          title: `<span class="text-sm font-semibold">4. ${this.$t('frontend_groovesell_coupons_create_tabs4')} <i class="mr-2 ml-1 text-xs fas fa-chevron-right" aria-hidden="true"></i><span>`,
          value: 'step4'
        },
        {
          title: `<span class="text-sm font-semibold">5. ${this.$t('frontend_groovesell_coupons_create_tabs5')} <span>`,
          value: 'step5'
        }
      ],
      postValidationTab: 'step2',
      currentTab: 'step1',
      edit_link: false,
      coupon: null,
      model: {
        name: '',
        description: '',
        coupon_code: '',
        discount_type: 1,
        discount: 0,
        funnel_id: 0,
        product_id: 0,
        price_point_id: 0,
        apply_discount: 1,
        no_of_payments: '',
        expiration_rule: 1,
        expiration: ''
      },
      funnels: null
    };
  },
  async created() {
    this.$wait.start('create_coupon');

    if (this.$route.params.coupon_id == undefined) {
      this.coupon = new Coupon({});
    } else {
      this.coupon = await Coupon.$find(this.$route.params.coupon_id);
      this.model.id = this.coupon.id;
      this.model.name = this.coupon.name;
      this.model.description = this.coupon.description;
      this.model.coupon_code = this.coupon.coupon_code;
      this.model.discount_type = this.coupon.discount_type;
      this.model.discount = this.coupon.discount;
      this.model.funnel_id = this.coupon.funnel_id;
      this.model.product_id = this.coupon.product_id;
      this.model.price_point_id = this.coupon.price_point_id;
      this.model.apply_discount = this.coupon.discount_details.apply_discount;
      this.model.no_of_payments = this.coupon.discount_details.no_of_payments;
      this.model.expiration_rule = parseInt(this.coupon.expiration_details.expiration_rule);
      this.model.expiration = this.coupon.expiration_details.expiration;
      this.edit_link = true;
    }

    const { data } = await axios.get('funnels-breakdown');
    this.funnels = data.funnels;
    this.affiliate_funnels = data.affiliate_funnels;

    this.$wait.end('create_coupon');
  },
  methods: {
    sendToCoupons() {
      this.$router.push({ name: 'coupons' });
    },
    async saveAllInfo() {
      this.$loader.start('saveAllInfo');
      await this.saveCouponInfo();
      this.$loader.end('saveAllInfo');
      return true;
    },
    async saveCouponInfo() {
      this.$loader.start('saveCouponInfo');
      this.coupon.name = this.model.name;
      this.coupon.description = this.model.description;
      this.coupon.coupon_code = this.model.coupon_code;
      this.coupon.discount_type = this.model.discount_type;
      this.coupon.discount = this.model.discount;
      this.coupon.funnel_id = this.model.funnel_id;
      this.coupon.product_id = this.model.product_id;
      this.coupon.price_point_id = this.model.price_point_id;
      this.coupon.apply_discount = this.model.apply_discount;
      this.coupon.no_of_payments = this.model.no_of_payments;
      this.coupon.expiration_rule = this.model.expiration_rule;
      this.coupon.expiration = this.model.expiration;
      let savedCouponData = await this.coupon.save();

      this.coupon = await Coupon.$find(savedCouponData.data.id);

      this.model.id = this.coupon.id;
      this.model.name = this.coupon.name;
      this.model.description = this.coupon.description;
      this.model.coupon_code = this.coupon.coupon_code;
      this.model.discount_type = this.coupon.discount_type;
      this.model.discount = this.coupon.discount;
      this.model.funnel_id = this.coupon.funnel_id;
      this.model.product_id = this.coupon.product_id;
      this.model.price_point_id = this.coupon.price_point_id;
      this.model.apply_discount = this.coupon.discount_details.apply_discount;
      this.model.no_of_payments = this.coupon.discount_details.no_of_payments;
      this.model.expiration_rule = this.coupon.expiration_details.expiration_rule;
      this.model.expiration = this.coupon.expiration_details.expiration;
      this.$loader.end('saveCouponInfo');
    },    
    async stepValidationSuccessful(isValid) {
      if (isValid) {
        await this.saveCouponInfo();
        this.currentTab = this.postValidationTab;
      }
    },
    validateRef(nextTab, currentTab) {
      this.postValidationTab = nextTab;

      return this.$refs[currentTab].validate();
    },
  }
};
</script>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}
</style>
