import { Model as BaseModel } from 'vue-api-query';

export default class Model extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  baseURL() {
    return window.config.apiUrl;
  }

  request(config) {
    return this.$http.request(config);
  }
}
