<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded px-2">
      <div class="mt-0">
        <label class="w-full text-grey-dark">{{ $t('frontend_name') }}</label>
        <div class="w-full flex flex-row">
          <text-input class="w-full" v-model="localModel.name" :placeholder="$t('frontend_name')"
            :validator="$v.localModel.name" />
        </div>

        <!-- force affiliate -->
        <div class="flex flex-col w-full bg-white rounded">
          <div class="flex flex-row w-full mb-5 mt-4">
            <div class="mr-2 flex flex-row">
              <gd-toggle v-model="localModel.force_affiliate" :value="localModel.force_affiliate" />
            </div>
            <div>
              <label class="w-full text-grey-dark">{{ $t('frontend_product_step1_for_specific_aff') }}</label>
            </div>
          </div>

          <div v-if="localModel.force_affiliate">
            <div class="flex pt-2 pr-2">
              <div class="w-1/2 flex flex-row" v-if="funnel_id">
                <auto-suggest-drop-down :funnel="funnel_id" :excluded="excludedAffIdArr" :affiliate_ids="selectedAffIdArr"
                  @onAffiliateAdded="addedAffiliate_add_team" @removedAffiliate_add_team="removedAffiliate_add_team"
                  :title="$t('frontend_affiliate')" />
              </div>
            </div>
            <div v-if="affiliate_err" class="text-red text-sm">
              {{ $t('frontend_aff_required') }}
            </div>
          </div>

          <div class="w-full flex flex-row mb-1" v-if="hasFreePricePoints">
            <div v-for="(checkoutType, index) in checkoutTypeFreePaid" :key="index" class="flex w-1/4 mr-4">
              <gd-custom-radio v-model="selCheckoutType" :option="checkoutType"
                @onUpdateOption="changeType"></gd-custom-radio>
            </div>
          </div>
        </div>


      </div>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>

<script>
import AutoSuggestDropDown from '@/components/AutoSuggestDropDown';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import CheckoutStepType from '@/components/CheckoutStepType';

import TestSvg from '@/assets/images/ag-grid/dark-asc.svg';

export default {
  name: 'ConfigStep',

  components: {
    AutoSuggestDropDown,
    CheckoutStepType,
    TestSvg,
  },

  props: {
    model: {
      required: true,
    },
    product: {
      required: true,
    },
    selectedCheckoutStepType: {
      required: true,
    },
    selectedMultiStepTypeProp: {
      required: true,
    },
    selectedCheckoutTypeFreePaid: {
      required: true,
    },
  },

  data() {
    return {
      alertProps: {
        show: false,
        variant: 'success',
        title: '',
        message: '',
      },
      selectedMultiStepType: this.selectedMultiStepTypeProp,
      localModel: this.model,
      checkoutFlow: 1,
      selectedCheckoutFlow: 0,
      selCheckoutStepType: this.selectedCheckoutStepType,
      checkoutTypeFreePaid: [
        {
          id: 1,
          title: this.$t('frontend_product_step1_type_free_paid1_title'),
          description: this.$t('frontend_product_step1_type_free_paid1_desc'),
        },
        {
          id: 2,
          title: this.$t('frontend_product_step1_type_free_paid2_title'),
          description: this.$t('frontend_product_step1_type_free_paid2_desc'),
        },
      ],
      checkoutStepTypes: [
        {
          id: 1,
          title: this.$t('forntend_checkout_link_step_1'),
          description: this.$t('forntend_checkout_link_step_1_desc'),
          // coming_soon: true,
        },
        {
          id: 0,
          title: this.$t('forntend_checkout_link_multistep_checkout'),
          description: this.$t('forntend_checkout_link_multistep_checkout_desc'),
        },
      ],

      selCheckoutType: this.selectedCheckoutTypeFreePaid,

      multiStepTypes: [
        {
          id: 3,
          svg: ['Email', 'OrderForm_V2'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes3'),
        },
        {
          id: 2,
          svg: ['Email', 'NameAndBilling', 'Payment'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes2'),
        },
        {
          id: 1,
          svg: ['Contact', 'Billing', 'Payment'],
          label: this.$t('frontend_checkout_links_step2_multiStepTypes1'),
        },
      ],
      singleStepTypes: [
        {
          id: 1,
          svg: ['OrderForm_V2'],
          label: 'Order Form',
        },
      ],
      affiliateSettingsTypes: [
        {
          id: 0,
          title: this.$t('frontend_for_all_aff'),
          description: this.$t('frontend_for_all_aff_desc'),
        },
        {
          id: 1,
          title: this.$t('frontend_for_single_aff'),
          description: this.$t('frontend_for_single_desc'),
        },
      ],
      funnel_id: this.$route.params.id,
      selectedAffIdArr: [],
      excludedAffIdArr: [],
      affiliate_err: false,
    };
  },

  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
    },
  },

  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    },

    selCheckoutStepType() {
      this.$emit('updateSelCheckoutStepType', this.selCheckoutStepType);
    },

    selectedMultiStepType() {
      this.$emit('updateselectedMultiStepType', this.selectedMultiStepType);
    },
  },

  computed: {
    hasFreePricePoints() {
      if (
        this.product &&
        this.product.price_points &&
        this.product.price_points.length > 0
      ) {
        return this.product.price_points.some((el) => {
          return el.price == 0;
        });
      }
      return false;
    },
    productHasOnlyFreePricePoints() {
      if (
        this.product &&
        this.product.price_points &&
        this.product.price_points.length > 0
      ) {
        return this.product.price_points.every((el) => {
          return el.price == 0;
        });
      }
      return false;
    },
  },

  async created() {
    this.selectedMultiStepType = this.selectedMultiStepTypeProp;
    this.localModel.checkout_link_page_options = { ...this.localModel.checkout_link_page_options };
    this.checkoutFlow = 'Single Price Checkout';
    this.selectedCheckoutFlow = 0;

    if (!this.localModel.force_affiliate) {
      this.localModel.force_affiliate = 0;
    }
    if (this.model.force_affiliate_id) {
      this.selectedAffIdArr.push(this.model.force_affiliate_id);
      this.excludedAffIdArr.push(this.model.force_affiliate_id);
    }
    if (this.productHasOnlyFreePricePoints) {
      this.checkoutTypeFreePaid = [
        {
          id: 1,
          title: this.$t('frontend_product_step1_type_free_paid1_title'),
          description: this.$t('frontend_product_step1_type_free_paid1_desc'),
          coming_soon: true,
          disabled_message: this.$t('frontend_product_step1_type_free_paid1_disabled_message'),
        },
        {
          id: 2,
          title: this.$t('frontend_product_step1_type_free_paid2_title'),
          description: this.$t('frontend_product_step1_type_free_paid2_desc'),
        }
      ];
    }
  },

  methods: {
    changeType(value) {
      this.$emit('updateselectedCheckoutTypeFreePaid', value);
    },

    validate() {
      this.$v.$touch();
      let isValid = !this.$v.$invalid;
      this.affiliate_err = false;

      if (this.localModel.force_affiliate && this.localModel.force_affiliate_id == null) {
        isValid = false;
        this.alertProps = {
          show: true,
          variant: 'error',
          title: 'Oops...',
          message: this.$t('frontend_aff_required'),
        };
      }

      this.$emit('on-validate', isValid);
      return isValid;
    },

    addedAffiliate_add_team(affiliate) {
      this.localModel.force_affiliate_id = affiliate.id;
      this.localModel.force_affiliate = 1;
      this.selectedAffIdArr = [];
      this.excludedAffIdArr = [];
      this.selectedAffIdArr.push(affiliate.id);
      this.excludedAffIdArr.push(affiliate.id);
      this.affiliate_err = false;
    },

    removedAffiliate_add_team(affiliate) {
      this.localModel.force_affiliate_id = null;
      this.selectedAffIdArr.splice(this.selectedAffIdArr.indexOf(affiliate), 1);
      this.excludedAffIdArr.splice(this.excludedAffIdArr.indexOf(affiliate), 1);
    },
  },
};
</script>

