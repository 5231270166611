<template>
  <div class="flex flex-col w-full">
    <!-- Header -->
    <div class="flex flex-row justify-center font-bold text-lg text-grey-700 mb-2 py-4">
      {{ headerText }}
    </div>

    <!-- Tabs -->
    <div class="flex flex-row w-full justify-center">
      <div class="w-full max-w-4xl border-grey-100 border px-4">
        <div class="flex flex-row mb-2 -mx-4 px-4">
          <gd-tabs
            wrapper-class="pt-4 px-0 gs-default-tabs"
            tab-class="pb-4 mr-4 gs-default-tabs-item"
            tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
            line-class="gs-default-tabs-active-line"
            :tabs="loginTabs"
            :current-tab="loginTab"
            @onClick="handleLoginTabChange"
          />
        </div>

        <!-- Login Form -->
        <div class="mt-2">
          <div v-if="loginTab === 'login'" class="p-4">
            <div class="flex flex-col w-1/2">
              <div class="flex flex-col w-full mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_email') }}
                </label>
                <text-input
                  v-model="loginForm.email"
                  :validator="$v.loginForm.email"
                  :attribute="$t('frontend_email')"
                  value="loginForm.email"
                  :placeholder="$t('frontend_resource_promo_email')"
                />
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="flex flex-col w-full mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_password') }}
                </label>
                <text-input
                  v-model="loginForm.password"
                  type="password"
                  :validator="$v.loginForm.password"
                  :attribute="$t('frontend_resource_promo_password')"
                  value="loginForm.password"
                  :placeholder="$t('frontend_resource_promo_password')"
                />
              </div>
            </div>
            <div class="flex flex-col items-center text-center justify-center w-1/2 mb-4">
              <gd-button size="md" variant="primary" @click="login">
                {{ $t('frontend_resource_promo_login') }}
              </gd-button>
              <span class="text-primary float-right cursor-pointer heading-sm sm:font-normal mt-4">
                <a :href="forgotPasswordUrl" target="_blank">
                  {{ $t('frontend_resource_promo_forgot_password') }}
                </a>
              </span>
            </div>
            <div class="flex flex-row justify-center w-1/2 mb-8 text-danger">
              {{ loginError }}
            </div>
          </div>

          <!-- Signup Form -->
          <div v-else-if="loginTab === 'signup'">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6 mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_first_name') }}
                </label>
                <text-input
                  v-model="localSignupForm.first_name"
                  :validator="$v.localSignupForm.first_name"
                  :attribute="$t('frontend_resource_promo_first_name')"
                  value="localSignupForm.first_name"
                  :placeholder="$t('frontend_resource_promo_first_name')"
                />
              </div>
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_last_name') }}
                </label>
                <text-input
                  v-model="localSignupForm.last_name"
                  :validator="$v.localSignupForm.last_name"
                  :attribute="$t('frontend_resource_promo_first_name')"
                  value="localSignupForm.last_name"
                  :placeholder="$t('frontend_resource_promo_last_name')"
                />
              </div>
            </div>

            <!-- Email -->
            <div class="flex flex-row">
              <div class="flex flex-col w-full mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_email') }}
                </label>
                <text-input
                  v-model="localSignupForm.email"
                  :validator="$v.localSignupForm.email"
                  :attribute="$t('frontend_email')"
                  value="localSignupForm.email"
                  :placeholder="$t('frontend_resource_promo_email')"
                />
              </div>
            </div>

            <!-- Company Name and Username -->
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6 mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_company_name') }}
                </label>
                <text-input
                  v-model="localSignupForm.company_name"
                  :validator="$v.localSignupForm.company_name"
                  :attribute="$t('frontend_resource_promo_company_name')"
                  value="localSignupForm.company_name"
                  :placeholder="$t('frontend_resource_promo_company_name')"
                />
              </div>
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_username') }}
                </label>
                <text-input
                  v-model="localSignupForm.username"
                  :validator="$v.localSignupForm.username"
                  :attribute="$t('frontend_resource_promo_username')"
                  value="localSignupForm.username"
                  :placeholder="$t('frontend_resource_promo_username')"
                  @input="delayTouch($v.localSignupForm.username)"
                />
              </div>
            </div>

            <!-- Password and Confirm Password -->
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6 mr-2">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_password') }}
                </label>
                <text-input
                  v-model="localSignupForm.password"
                  :validator="$v.localSignupForm.password"
                  :attribute="$t('frontend_resource_promo_password')"
                  value="localSignupForm.password"
                  :placeholder="$t('frontend_resource_promo_password')"
                  type="password"
                />
              </div>
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_resource_promo_confirm_password') }}
                </label>
                <text-input
                  v-model="localSignupForm.password_confirmation"
                  :validator="$v.localSignupForm.password_confirmation"
                  :attribute="$t('frontend_resource_promo_password_confirmation')"
                  value="localSignupForm.password_confirmation"
                  :placeholder="$t('frontend_resource_promo_password_confirmation')"
                  type="password"
                />
              </div>
            </div>

            <!-- Signup Button -->
            <div class="flex flex-row justify-center w-full mb-8">
              <gd-button
                size="auto"
                class="ml-4 mt-3"
                variant="primary"
                @click="registerUser"
              >
                {{ $t('frontend_resource_promo_signup_for_A_free') }}
              </gd-button>
            </div>

            <!-- Registration Error -->
            <div class="flex flex-row justify-center w-1/2 mb-8">
              {{ registrationError }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  required,
  minLength,
  maxLength,
  alphaNum,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import auth from '@groovepages/gd-lib/auth';
import store from '@/store';

const touchMap = new WeakMap();

export default {
  props: {
    funnelId: {
      type: Number,
      required: true,
    },
    signupForm: {
      type: Object,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    loginTabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localSignupForm: this.signupForm,
      loginTab: 'login',
      loginForm: {
        email: '',
        password: '',
      },
      loginError: '',
      registrationError: '',
    };
  },
  computed: {
    forgotPasswordUrl() {
      const brandName = this.$store.state.brandData.brandName.toLowerCase();

      return `https://app.${brandName}/#/forgotPassword`;
    },
  },
  methods: {
    handleLoginTabChange(newTab) {
      this.loginTab = newTab;
    },
    login() {
      this.$v.loginForm.$touch();

      if (this.$v.loginForm.$invalid) {
        return;
      }

      this.loginError = '';
      this.$loader.start('login');
      auth
        .login(this.loginForm.email, this.loginForm.password)
        .then(async (user) => {
          this.$loader.end('login');

          if (!user.auth_keycloak) {
            axios.defaults.headers.common = {
              ...axios.defaults.headers.common,
              'x-auth-provider': 'auth0',
            };
          } else {
            delete axios.defaults.headers.common['x-auth-provider'];
          }

          store.commit('login', user);

          const funnelAffiliateParams = {
            funnel_id: this.funnelId,
            testing: 'testing',
          };

          const gdJvCookie = Cookies.get(
            `gd_jv_f${this.localSignupForm.funnel_id}`
          );

          if (typeof gdJvCookie !== 'undefined') {
            funnelAffiliateParams.gd_jv_cookie = gdJvCookie;
          }

          const gsJvCookie = Cookies.get(
            `gs_jv_f${this.localSignupForm.funnel_id}`
          );

          if (typeof gsJvCookie !== 'undefined') {
            funnelAffiliateParams.gs_jv_cookie = gsJvCookie;
          }

          await axios.post('/funnel-affiliates', funnelAffiliateParams);
          this.$emit('userLoggedIn');
        })
        .catch((err) => {
          this.$loader.end('login');
          this.loginError = err.data.message;
        });
    },
    async registerUser() {
      this.$v.localSignupForm.$reset();

      this.$v.localSignupForm.$touch();

      if (this.$v.localSignupForm.$invalid) {
        return;
      }

      this.registrationError = '';
      this.$loader.start('registerUser');

      const postParams = {
        first_name: this.localSignupForm.first_name,
        last_name: this.localSignupForm.last_name,
        company_name: this.localSignupForm.company_name,
        email: this.localSignupForm.email,
        username: this.localSignupForm.username,
        password: this.localSignupForm.password,
        password_confirmation: this.localSignupForm.password_confirmation,
        funnel_id: this.localSignupForm.funnel_id,
      };

      const gdJvCookie = Cookies.get(`gd_jv_f${this.localSignupForm.funnel_id}`);

      if (typeof gdJvCookie !== 'undefined') {
        postParams.gd_jv_cookie = gdJvCookie;
      }

      const gsJvCookie = Cookies.get(`gs_jv_f${this.localSignupForm.funnel_id}`);

      if (typeof gsJvCookie !== 'undefined') {
        postParams.gs_jv_cookie = gsJvCookie;
      }

      await axios
        .post(`/affiliate/${this.funnelId}/auth/register`, postParams)
        .then(() => {
          this.$loader.end('registerUser');
          this.loginForm.email = this.localSignupForm.email;
          this.loginForm.password = this.localSignupForm.password;
          this.login();
        })
        .catch((error) => {
          this.$loader.end('registerUser');
          this.registrationError = error.response.data.message;
        });
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
  },
  validations: {
    loginForm: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },

    localSignupForm: {
      first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      company_name: {
        maxLength: maxLength(30),
      },
      email: {
        required,
        email,
      },
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
        alphaNum,
        async isUnique(value) {
          if (value === '') {
            return true;
          }

          try {
            await axios.get(`/auth/username/${value}`);
            return true;
          } catch (error) {
            return false;
          }
        },
      },
      password: {
        required,
        minLength: minLength(8),
        goodPassword: (password) => {
          // a custom validator!
          return (
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password)
          );
        },
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password'),
      },
    },
  },
}
</script>
