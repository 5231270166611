<template>
  <div>
    <div class="w-full">
      <v-wait for="resources">
        <div v-if="funnels.length" id="resources-list-view" class="mt-8"></div>
        <div v-else class="mt-8">
          {{ $t('frontend_resources_nofunnels') }}
        </div>
      </v-wait>
    </div>

    <portal to="headerBreadCrumb">
      <div class="flex items-center cursor-pointer -ml-4" @click="sendToFunnels">
        <chevron-left-icon class="h-3 fill-current" />
      </div>
    </portal>
  </div>
</template>

<script>
import Funnel from '@/models/Funnel';
import ChevronLeftIcon from '@/assets/images/icons/ChevronLeftIcon.svg';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('funnel_promo_tools') };
  },
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      funnels: [],
    };
  },
  async created() {
    this.$wait.start('resources');
    await this.loadFunnels();
    this.$wait.end('resources');
  },
  methods: {
    async loadFunnels() {
      this.funnels = await Funnel.$get();
      if (this.funnels.length) {
        this.$router.push({
          name: 'funnel_resources',
          params: {
            id: this.funnels[0].id
          }
        });
      }
    },
    sendToFunnels() {
      this.$router.push({ name: 'funnels' });
    }
  }
};
</script>

<style lang="scss">
.dropdown-toggle {
  background-image: none !important;
  border: 1px solid dodgerblue !important;
  border-radius: 30px;
}

.dropdown-toggle:hover {
  background: transparent;
}

.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
</style>

<style lang="scss">
#resources-list-view {
  .VueTables--client table {
    overflow: auto !important;
  }

  .VueTables__search-field {
    margin-bottom: 10px;
  }

  .VueTables__heading {
    color: #8f92ab;
  }

  .VueTables__sort-icon {
    color: #8f92ab;
    margin-left: 8px;
  }

  td {
    font-weight: 400;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .VueTables__child-row-toggler--closed::before,
  .VueTables__child-row-toggler--open::before {
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125' /></svg>");
    padding-right: 3px;
    vertical-align: middle;
  }

  .VueTables__child-row {
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-left: 5px solid var(--brand-primary);
  }

  .VueTables__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .VueTables__row {
    border-bottom: 1px solid #ddd;
  }

  .VueTables__table th,
  td {
    text-align: left;
    padding: 16px;
  }

  tr.VueTables__child-row > td {
    padding: 0px !important;
  }

  thead {
    border-bottom: 1px solid #ddd;
  }

  .vue-pagination-ad {
    text-align: center;
  }

  .VueTables__limit-field {
    margin-bottom: 12px;
  }

  .VuePagination__count,
  .VueTables__limit {
    display: none;
  }
}
</style>
