<template>
  <div class="max-w-sm w-full py-6 h-full">
    <div
      class="
        bg-white
        rounded-lg
        overflow-hidden
        border border-grey-200
        h-full
        flex flex-col
        justify-between
        content-between
      "
    >
      <div>
        <div
          class="
            bg-contain bg-no-repeat bg-center
            h-56
            p-4
            border-b-2 border-grey-100
          "
          v-if="
            funnel.main_product.checkout_page_options
              .product_image
          "
          :style="{
            'background-image': `url('${funnel.main_product.checkout_page_options.product_image}')`,
          }"
        ></div>
        <div
          v-else
          class="px-24 py-16 border-b-2 border-grey-100 flex justify-center text-primary"
        >
          <gifts-icon class="w-24 h-24 fill-current" />
        </div>
        <div class="p-4">
          <p
            class="text-dark font-bold text-lg"
            v-line-clamp:27="1"
          >
            {{ funnel.main_product.name }}
          </p>
          <div class="flex flex-row justify-between mt-2">
            <div class="flex flex-row">
              <p>
                <span
                  class="text-dark font-bold"
                  v-if="
                    funnel.price_point_type == 3 ||
                    funnel.price_point_type == 4
                  "
                >
                  {{ funnel.number_of_installments }} X
                </span>
                <span class="text-dark font-bold">
                  {{ funnel.price | currency }}
                </span>
                <span
                  class="text-grey-500 font-normal text-sm"
                  v-if="funnel.price_point_type != 1"
                >
                  {{
                    resolveRecurringInterval(
                      funnel.recurring_interval
                    )
                  }}
                </span>
              </p>
            </div>
            <div>
              <p
                class="text-dark font-bold"
                v-if="funnel.commission_type == 'percent'"
              >
                {{ funnel.commission_value }}%
                <span class="text-grey-500 font-normal text-sm"
                  >{{ $t('frontend_marketplace_commission') }}
                </span>
              </p>
              <p class="text-dark font-bold" v-else>
                {{ funnel.commission_value | currency }}
                <span class="text-grey-500 font-normal text-sm"
                  >{{ $t('frontend_marketplace_commission') }}
                </span>
              </p>
            </div>
          </div>
          <div
            v-if="funnel.business"
            class="
              flex flex-col
              justify-between
              mt-2
              font-normal
              text-sm
            "
          >
            <div>By {{ funnel.business }}</div>
            <div>
              {{ funnel.author }}
            </div>
          </div>
          <div
            v-else
            class="
              flex flex-row
              justify-between
              mt-2
              font-normal
              text-sm
            "
          >
            By {{ funnel.author }}
          </div>
        </div>
      </div>
      <div class="px-4">
        <div
          class="
            flex flex-row
            justify-between
            mt-2
            border-t-2 border-grey-200
            py-4
          "
        >
          <div
            class="
              flex flex-col
              justify-end
              items-center
              text-center text-sm
              w-1/4
            "
          >
            <div class="text-center text-grey-500">
              {{ $t('frontend_marketplace_aps') }}
            </div>
            <div class="text-center mt-1 font-bold">
              {{ funnel.gravity }}
            </div>
          </div>

          <div
            class="
              flex flex-col
              justify-end
              items-center
              text-center text-sm
              w-1/4
            "
          >
            <div class="text-center text-grey-500">
              {{ $t('frontend_marketplace_funnel_epc_to_aff') }}
            </div>
            <div class="text-center mt-1 font-bold">
              {{ funnel.aff_epc | currency }}
            </div>
          </div>

          <div
            class="
              flex flex-col
              justify-end
              items-center
              text-center text-sm
              w-1/4
            "
          >
            <div class="text-center text-grey-500">
              {{ $t('frontend_marketplace_conversion_rate') }}
            </div>
            <div class="text-center mt-1 font-bold">
              {{ funnel.conversion_rate }}%
            </div>
          </div>

          <div
            class="
              flex flex-col
              justify-end
              items-center
              text-center text-sm
              w-1/4
            "
          >
            <div class="text-center text-grey-500">
              {{ $t('frontend_marketplace_refund_rate') }}
            </div>
            <div class="text-center mt-1 font-bold">
              {{ funnel.refund_rate }}%
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            justify-center
            mt-2
            border-t-2 border-grey-200
            py-4
          "
        >
          <div class="w-1/5 px-2">
            <div
              v-if="funnel.is_funnel_affiliate_user == false"
              class="
                w-8
                h-8
                text-dark
                hover:text-primary
                cursor-pointer
              "
              @click="$emit('signUpForAffiliate', funnel.funnel_id)"
              v-tooltip="$t('frontend_join_program')"
            >
              <smile-icon class="w-7 h-7 fill-current" />
            </div>
            <div
              v-else
              class="w-7 h-7 text-primary cursor-pointer"
              v-tooltip="$t('frontend_marketplace_already_aff')"
            >
              <smile-icon class="w-7 h-7 fill-current" />
            </div>
          </div>

          <div class="w-1/5 px-2">
            <div
              v-if="funnel.sales_page"
              class="
                w-7
                h-7
                text-dark
                hover:text-primary
                cursor-pointer
              "
              @click="$emit('openUrlInNewTab', funnel.sales_page)"
              v-tooltip="$t('frontend_see_offer')"
            >
              <arrow-up-right-square-icon class="w-7 h-7 fill-current"/>
            </div>
            <div
              v-else
              class="w-7 h-7 text-grey-200 cursor-pointer"
              v-tooltip="$t('frontend_see_offer')"
            >
              <arrow-up-right-square-icon class="w-7 h-7 fill-current"/>
            </div>
          </div>

          <div class="w-1/5 px-2">
            <div
              v-if="funnel.is_funnel_affiliate_user == true"
              class="
                w-7
                h-7
                text-dark
                hover:text-primary
                cursor-pointer
              "
              @click="$emit('fetchAffiliateLinks', funnel.funnel_id)"
              v-tooltip="$t('affiliatesLinksColumns_links')"
            >
              <link-icon class="w-7 h-7 fill-current"/>
            </div>
            <div
              v-else
              class="w-7 h-7 text-grey-200 cursor-pointer"
              v-tooltip="$t('affiliatesLinksColumns_links')"
            >
              <link-icon class="w-7 h-7 fill-current"/>
            </div>
          </div>

          <div class="w-1/5 px-2">
            <div
              v-if="funnel.is_funnel_affiliate_user == true"
              class="
                w-7
                h-7
                text-dark
                hover:text-primary
                cursor-pointer
              "
              @click="$emit('openFunnelResource', funnel.funnel_id)"
              v-tooltip="
                $t('frontend_affiliate_promos_promo_tools')
              "
            >
              <megaphone-icon class="w-7 h-7 fill-current"/>
            </div>
            <div
              v-else
              class="w-7 h-7 text-grey-200 cursor-pointer"
              v-tooltip="
                $t('frontend_affiliate_promos_promo_tools')
              "
            >
              <megaphone-icon class="w-7 h-7 fill-current"/>
            </div>
          </div>

          <div class="w-1/5 px-2">
            <div
              v-if="funnel.main_product.jv_page"
              class="
                w-8
                h-8
                text-dark
                hover:text-primary
                cursor-pointer
              "
              @click="$emit('openUrlInNewTab', funnel.main_product.jv_page)"
              v-tooltip="$t('frontend_jv_signup_page')"
            >
              <handshake-icon class="w-7 h-7 fill-current"/>
            </div>
            <div
              v-else
              class="w-8 h-8 text-grey-200 cursor-pointer"
              v-tooltip="$t('frontend_jv_signup_page')"
            >
              <handshake-icon class="w-7 h-7 fill-current"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmileIcon from '@/assets/images/icons/SmileIcon.svg';
import ArrowUpRightSquareIcon from '@/assets/images/icons/ArrowUpRightSquareIcon.svg';
import LinkIcon from '@/assets/images/icons/LinkIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';

export default {
  components: {
    SmileIcon,
    ArrowUpRightSquareIcon,
    LinkIcon,
    MegaphoneIcon,
    HandshakeIcon,
    GiftsIcon,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resolveRecurringInterval(recurringInterval) {
      let recurringString = 'Weekly';

      switch (recurringInterval) {
        case 1:
          recurringString = 'Weekly';
          break;
        case 2:
          recurringString = 'Every 2 Weeks';
          break;
        case 3:
          recurringString = 'Monthly';
          break;
        case 4:
          recurringString = 'Every 2 Months';
          break;
        case 5:
          recurringString = 'Quarterly';
          break;
        case 9:
          recurringString = 'Every 4 Months';
          break;
        case 6:
          recurringString = 'Half-Yearly';
          break;
        case 7:
          recurringString = 'Yearly';
          break;
        case 8:
          recurringString = 'Every 2 Years';
          break;
        default:
          recurringString = 'Weekly';
          break;
      }

      return recurringString;
    },
  },
};
</script>
