<template>
  <div>
    <gd-modal
      name="affiliate-detail-modal"
      :title="$t('frontend_groovesell_sub_aff_details')"
      width="80%"
      bg-type="regular"
    >
      <div v-if="localSelectedAffiliate" style="min-height: 60vh">
        <gd-tabs
          :tabs="affiliateDetailTabs"
          :current-tab="affiliateDetailsTab"
          :wrapper-class="'pt-4 px-4 gs-default-tabs'"
          :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'"
          @onClick="handleAffiliatesDetailTabChange"
        />
        <div v-if="affiliateDetailsTab === 'info'">
          <div class="flex flex-row">
            <div class="flex flex-col w-3/4 px-4">
              <div class="mt-2">
                <div class="w-full flex flex-wrap">
                  
                  <!-- Name -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_name') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.rendered_name }}</label>
                  </div>
                  
                  <!-- Email -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_email') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.rendered_email }}</label>
                  </div>
                  
                  <!-- PayPal Email -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_paypal_email') }}</label>
                    <label v-if="localSelectedAffiliate.affiliate_paypal" class="text-sm text-grey-700 mb-2">
                      {{ localSelectedAffiliate.rendered_affiliate_paypal }}
                    </label>
                    <label v-else class="text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_NA') }}</label>
                  </div>

                  <!-- Username -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_username') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.rendered_username }}</label>
                  </div>

                  <!-- Earned Commissions -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_earned_commisions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_total_second_tier_commissions }}</label>
                  </div>

                  <!-- Unpaid Commissions -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_unpaid_commisions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_pending_second_tier_commission }}</label>
                  </div>

                  <!-- Due Commissions -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_due_commisions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_processed_without_advances }}</label>
                  </div>

                  <!-- Advance Clawbacks -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_advance_clawbacks') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_advances }}</label>
                  </div>

                  <!-- Commissions on Hold -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_commisions_on_hold') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_on_hold_second_tier_commission }}</label>
                  </div>

                  <!-- Being Processed Commissions -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_being_processed_commisions') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_in_process_second_tier_commission }}</label>
                  </div>

                  <!-- Amount Paid -->
                  <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_affiliate_index_amount_paid') }}</label>
                    <label class="text-sm text-grey-700 mb-2">{{ localSelectedAffiliate.human_readable_alltime_paid_commission }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
              <div class="my-auto flex flex-col">
                <gd-button 
                  size="auto" 
                  variant="primary" 
                  class="mb-4" 
                  @click="openSecondTierMarkAsPaidModal"
                >
                  {{ $t('process_second_tier_commission') }}
                </gd-button>

                <gd-button 
                  size="auto" 
                  variant="primary" 
                  class="mb-4" 
                  @click="sendToSecondTierAffiliatesPage"
                >
                  {{ $t('view_second_tier_affiliates') }}
                </gd-button>

                <gd-button 
                  size="auto" 
                  variant="primary" 
                  class="mb-4" 
                  @click="openClawbackModal"
                >
                  {{ $t('frontend_affiliate_index_add_clawback') }}
                </gd-button>

                <gd-button 
                  size="auto" 
                  variant="primary" 
                  class="mb-4" 
                  @click="openCommissionAdvanceModal"
                >
                  {{ $t('frontend_affiliate_index_add_commision_advance') }}
                </gd-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="affiliateDetailsTab === 'history'">
          <div v-if="localSelectedAffiliate.payment_bonus_history && localSelectedAffiliate.payment_bonus_history.length > 0" id="affiliate-payments-list-view" class="flex flex-row">
            <v-client-table 
              :data="localSelectedAffiliate.payment_bonus_history" 
              :columns="affiliatesPaymentsColumns" 
              :options="affiliatesPaymentsTableOptions"
            >
              <div slot="note" slot-scope="props" class="inline-block">
                <div v-if="props.row.note && props.row.note !== ''" v-tooltip="props.row.note">
                  <comment-icon class="h-5 w-5 fill-current" />
                </div>
              </div>
            </v-client-table>
          </div>

          <div v-else class="p-4">
            {{ $t('frontend_affiliate_index_no_payments_done') }}
          </div>
        </div>
      </div>
    </gd-modal>

    <mark-as-paid-second-tier-modal
      :selected-affiliate="localSelectedAffiliate"
      :filters-model="filtersModel"
      @updateSelectedAffiliate="updateSelectedAffiliate"
      @changedSearchOrFilter="changedSearchOrFilter"
    />

    <add-clawback-modal
      :selected-affiliate="localSelectedAffiliate"
      :filters-model="filtersModel"
      @updateSelectedAffiliate="updateSelectedAffiliate"
      @changedSearchOrFilter="changedSearchOrFilter"
    />

    <add-commission-advance-modal
      :selected-affiliate="localSelectedAffiliate"
      :filters-model="filtersModel"
      @updateSelectedAffiliate="updateSelectedAffiliate"
      @changedSearchOrFilter="changedSearchOrFilter"
    />
  </div>
</template>

<script>
import createLinkMixin from '@/mixins/createLinkMixin';
import SecondTierAffiliate from '@/models/SecondTierAffiliate';
import MarkAsPaidSecondTierModal from './MarkAsPaidSecondTierModal.vue';
import AddClawbackModal from './AddClawbackModal.vue';
import AddCommissionAdvanceModal from './AddCommissionAdvanceModal.vue';
import CommentIcon from '@/assets/images/icons/CommentIcon.svg';

export default {
  components: {
    MarkAsPaidSecondTierModal,
    AddClawbackModal,
    AddCommissionAdvanceModal,
    CommentIcon,
  },
  mixins: [createLinkMixin],
  props: {
    selectedAffiliate: {
      type: Object,
      default: null,
    },
    filtersModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSelectedAffiliate: this.selectedAffiliate,
      affiliateDetailTabs: [
        { title: this.$t('frontend_affiliate'), value: 'info' },
        { title: this.$t('frontend_vendors_payment_history'), value: 'history' },
      ],
      affiliateDetailsTab: 'info',
      affiliatesPaymentsColumns: [
        'readable_type',
        'human_readable_amount',
        'formatted_date_time',
        'note',
      ],
      affiliatesPaymentsTableOptions: {
        headings: {
          formatted_date_time: this.$t('frontend_date_time'),
          readable_type: this.$t('frontend_type'),
          human_readable_amount: this.$t('frontend_amount'),
        },
        perPage: 5,
        texts: {
          filterPlaceholder: this.$t('frontend_search'),
          filter: '',
        },
      },
    };
  },
  methods: {
    handleAffiliatesDetailTabChange(newTab) {
      this.affiliateDetailsTab = newTab;
    },
    updateSelectedAffiliate(selectedAffiliate = null) {
      this.localSelectedAffiliate = selectedAffiliate || this.localSelectedAffiliate;
      this.$emit('updateSelectedAffiliate', this.localSelectedAffiliate);
    },
    changedSearchOrFilter() {
      this.$emit('changedSearchOrFilter');
    },
    async openAffiliateDetail(affiliateId) {
      this.$loader.start('openAffiliateDetail');

      this.localSelectedAffiliate = await SecondTierAffiliate.params({
        funnels: this.filtersModel.selectedFunnel,
      }).$find(affiliateId);
      this.updateSelectedAffiliate();

      this.affiliateDetailsTab = 'info';

      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openAffiliateDetail');
    },
    async openSalesDetail(affiliateId) {
      this.$loader.start('openSalesDetail');

      this.localSelectedAffiliate = await SecondTierAffiliate.params({
        funnels: this.filtersModel.selectedFunnel,
      }).$find(affiliateId);
      this.updateSelectedAffiliate();

      this.affiliateDetailsTab = 'transactions';

      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openSalesDetail');
    },
    async openRefundsDetail(affiliateId) {
      this.$loader.start('openRefundsDetail');

      this.localSelectedAffiliate = await SecondTierAffiliate.params({
        funnels: this.filtersModel.selectedFunnel,
      }).$find(affiliateId);
      this.updateSelectedAffiliate();

      this.affiliateDetailsTab = 'refunds';
      this.$root.$emit('modal-open', 'affiliate-detail-modal');
      this.$loader.end('openRefundsDetail');
    },
    openSecondTierMarkAsPaidModal() {
      this.$root.$emit('modal-open', 'mark-as-paid-second-tier-modal');
    },
    sendToSecondTierAffiliatesPage() {
      const routeData = this.$router.resolve({
        name: 'second_tier_affiliates',
        query: { referred_by: this.localSelectedAffiliate.id },
      });
      this.openLink(routeData.href, '_self');
    },
    openClawbackModal() {
      this.$root.$emit('modal-open', 'add-clawback-modal');
    },
    openCommissionAdvanceModal() {
      this.$root.$emit('modal-open', 'add-commission-advance-modal');
    },
  },
};
</script>
