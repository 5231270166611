<template>
  <div>
    <v-wait for="funnels">
      <div class="flex flex-col w-full bg-white rounded p-4">
        <div class="flex flex-col w-full mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_tracking_type') }}
          </label>
          <div class="w-full flex flex-row">
            <div
              v-for="(linkType, linkIndex) in linkTypes"
              :key="linkIndex"
              class="flex w-1/3 mr-4"
            >
              <gd-custom-radio v-model="localModel.type" :option="linkType"></gd-custom-radio>
            </div>
          </div>
        </div>
        <div v-if="localModel.type == 1" class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_tracking_url') }}
          </label>
          <text-input
            v-model="localModel.redirect_parameters.url"
            :validator="$v.localModel.redirect_parameters.url"
            attribute="URL"
            value="localModel.redirect_parameters.url"
            :placeholder="$t('frontend_tracking_url')"
          />
        </div>
        <div v-if="localModel.type == 2" class="flex flex-row w-1/2 mb-6">
          <div class="flex flex-col w-1/2 mb-6 mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_tracking_funnel') }}
            </label>
            <gd-dropdown
              v-model="localModel.redirect_parameters.funnel_id"
              size="full"
              :options="funnelsOptions"
              @change="changedFunnel"
            />
            <div
              v-if="$v.localModel.redirect_parameters.funnel_id.$error"
              class="w-full error text-primary-dark mt-1 text-sm"
            >
              {{ $t('frontend_tracking_please_select') }}
            </div>
          </div>
          <div v-if="localModel.redirect_parameters.funnel_id > 0" class="flex flex-col w-1/2 mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mb-2"
            >
              {{ $t('frontend_tracking_product') }}
            </label>
            <gd-dropdown
              v-model="localModel.redirect_parameters.product_id"
              size="full"
              :options="productsOptions"
            />
            <div
              v-if="$v.localModel.redirect_parameters.product_id.$error"
              class="w-full error text-primary-dark mt-1 text-sm"
            >
              {{ $t('frontend_tracking_select_a_valid') }}
            </div>
          </div>
        </div>

        <div v-if="localModel.type == 4" class="flex flex-row w-1/2 mb-6">
          <div class="flex flex-col w-1/2 mb-6 mr-2">
            <label class="font-semibold text-sm text-grey-700 mb-2">
              {{ $t('frontend_tracking_funnel') }}
            </label>
            <gd-dropdown
              v-model="localModel.redirect_parameters.funnel_id"
              size="full"
              :options="affiliatesOptions"
              @change="changedAffiliateFunnel"
            />
            <div
              v-if="$v.localModel.redirect_parameters.funnel_id.$error"
              class="w-full error text-primary-dark mt-1 text-sm"
            >
              {{ $t('frontend_tracking_please_select') }}
            </div>
          </div>
          <div v-if="localModel.redirect_parameters.funnel_id > 0" class="flex flex-col w-1/2 mb-6">
            <label
              class="font-semibold text-sm text-grey-700 mb-2"
            >
              {{ $t('frontend_tracking_affiliate_link') }}
            </label>
            <gd-dropdown
              v-model="localModel.redirect_parameters.link_id"
              size="full"
              :options="affiliateLinksOptions"
            />
            <div
              v-if="$v.localModel.redirect_parameters.link_id.$error"
              class="w-full error text-primary-dark mt-1 text-sm"
            >
              {{ $t('frontend_tracking_please_select_valid_link') }}
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import { required, requiredIf, url } from 'vuelidate/lib/validators';
import AffiliateLink from '@/models/AffiliateLink';

export default {
  name: 'TypeTab',
  props: {
    model: {
      required: true
    },
    funnels: {
      required: true
    },
    affiliates: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
      products: null,
      affiliateLinks: []
    };
  },
  computed: {
    funnelsOptions() {
      return this.funnels.map(funnel => {
        return {
          value: funnel.id,
          text: funnel.name
        };
      });
    },
    productsOptions() {
      if (!this.products) {
        return [];
      }

      return this.products.map(product => {
        return {
          value: product.id,
          text: product.name
        };
      });
    },
    affiliatesOptions() {
      return this.affiliates.map(affiliate => {
        return {
          value: affiliate.id,
          text: affiliate.name
        };
      });
    },
    affiliateLinksOptions() {
      return this.affiliateLinks.map(link => {
        return {
          value: link.id,
          text: link.name
        };
      });
    },
    linkTypes() {
      return [
        {
          id: 1,
          title: this.$t('frontend_groovesell_tracking_step2_linktypes1_title'),
          description: this.$t('frontend_groovesell_tracking_step2_linktypes1_desc')
        },
        {
          id: 2,
          title: this.$t('frontend_groovesell_tracking_step2_linktypes2_title'),
          description: this.$t('frontend_groovesell_tracking_step2_linktypes2_desc', {appTitle: this.whitelabelAppData('sell').title})
        },
        {
          id: 4,
          title: this.$t('frontend_groovesell_tracking_step2_linktypes4_title'),
          description: this.$t('frontend_groovesell_tracking_step2_linktypes4_desc', {appTitle: this.whitelabelAppData('sell').title})
        },
        {
          id: 3,
          title: this.$t('frontend_groovesell_tracking_step2_linktypes3_title', {pagesAppTitle: this.whitelabelAppData('pages').title}),
          description: this.$t('frontend_groovesell_tracking_step2_linktypes3_desc', {pagesAppTitle: this.whitelabelAppData('pages').title}),
          coming_soon: true
        }
      ];
    }
  },
  validations: {
    localModel: {
      type: {
        required
      },
      redirect_parameters: {
        url: {
          required: requiredIf(function() {
            return this.localModel.type == 1;
          }),
          url
        },
        funnel_id: {
          required: requiredIf(function() {
            return this.localModel.type == 2;
          }),
          funnelIdSelected(value) {
            if (this.localModel.type == 2) {
              return value && value !== '' && value > 0;
            }
            
            return true;
          }
        },
        product_id: {
          required: requiredIf(function() {
            return this.localModel.type == 2;
          }),
          productIdSelected(value) {
            if (this.localModel.type == 2) {
              return value && value !== '' && value > 0;
            }
            
            return true;
          },
        },
        link_id: {
          required: requiredIf(function() {
            return this.localModel.type == 4;
          }),
          linkIdSelected(value) {
            if (this.localModel.type === 4) {
              return value && value !== '' && value > 0;
            }
            
            return true;
          },
        }
      }
    }
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    }
  },
  async created() {
    this.$loader.start('fetchAffiliateLinks');

    if (
      this.localModel.redirect_parameters.product_id &&
      this.localModel.redirect_parameters.product_id > 0
    ) {
      let selectedFunnel = this.funnels.find(funnel => funnel['id'] === this.localModel.redirect_parameters.funnel_id);
      this.products = selectedFunnel['products'];

      if (this.localModel.type == 4) {
        this.affiliateLinks = await AffiliateLink.params({
          funnel_id: this.localModel.redirect_parameters.funnel_id
        }).$get();
      }
    } else if (
      this.localModel.redirect_parameters.link_id &&
      this.localModel.redirect_parameters.link_id > 0
    ) {
      this.affiliateLinks = await AffiliateLink.params({
        funnel_id: this.localModel.redirect_parameters.funnel_id
      }).$get();
    }

    this.$loader.end('fetchAffiliateLinks');
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    },
    async changedFunnel() {
      if (this.localModel.redirect_parameters.funnel_id == 0) {
        this.products == null;
      } else {
        this.$loader.start('changedFunnel');
        let selectedFunnel = this.funnels.find(funnel => funnel['id'] === this.localModel.redirect_parameters.funnel_id);
        this.products = selectedFunnel['products'];
        this.$loader.end('changedFunnel');
      }
    },
    async changedAffiliateFunnel() {
      if (this.localModel.redirect_parameters.funnel_id == 0) {
        this.affiliateLinks == null;
      } else {
        this.$loader.start('changedAffiliateFunnel');
        this.affiliateLinks = await AffiliateLink.params({
          funnel_id: this.localModel.redirect_parameters.funnel_id
        }).$get();
        this.$loader.end('changedAffiliateFunnel');
      }
    },
  }
};
</script>
