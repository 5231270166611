<template>
    <div>
        <div class="flex flex-row flex-wrap w-full py-4 px-4">
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_analytics_customer')
                }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.buyer_fullname }}</label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">Email</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.buyer_email }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_phone') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_phone && purchase.buyer_phone.trim() != '' ? purchase.buyer_phone : 'N/A'
                }}</label>
            </div>

            <div class="w-full pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_company') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_company && purchase.buyer_company.trim() != '' ? purchase.buyer_company :
                    'N/A'
                }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_ip_address') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{ purchase.buyer_ip }}</label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_address') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_address && purchase.buyer_address.trim() != '' ? purchase.buyer_address :
                    'N/A'
                }}</label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_city') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_city && purchase.buyer_city.trim() != '' ? purchase.buyer_city : 'N/A'
                }}</label>
            </div>

            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_state') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_state && purchase.buyer_state.trim() != '' ? purchase.buyer_state : 'N/A'
                }}</label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_country') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_country && purchase.buyer_country.trim() != '' ? purchase.buyer_country :
                    'N/A'
                }}</label>
            </div>
            <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                <label class="font-semibold text-sm text-grey-700 mb-2">{{ $t('frontend_zipcode') }}</label>
                <label class="text-sm text-grey-700 mb-2">{{
                    purchase.buyer_postal_code && purchase.buyer_postal_code.trim() != '' ?
                    purchase.buyer_postal_code : 'N/A'
                }}</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    props: {
        purchase: {
            required: true
        }
    }
}
</script>