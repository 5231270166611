<template>
  <div>
    <div class="flex flex-col w-full bg-white rounded p-4">
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_name') }}
        </label>
        <text-input
          v-model="localModel.name"
          value="localModel.name"
          :validator="$v.localModel.name"
          :attribute="$t('frontend_groovesell_contracts_step1_contract_name')"
          :placeholder="$t('frontend_groovesell_contracts_step1_contract_name')"
        />
      </div>
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_desc') }}
        </label>
        <text-area
          v-model="localModel.description"
          value="localModel.description"
          :validator="$v.localModel.description"
          :attribute="$t('frontend_groovesell_contracts_step1_contract_desc')"
          :placeholder="$t('frontend_groovesell_contracts_step1_contract_desc')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'Step1',
  props: {
    model: {
      required: true
    }
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  validations: {
    localModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80)
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160)
      }
    }
  },
  watch: {
    localModel() {
      this.$emit('updateModel', this.localModel);
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit('on-validate', isValid);
      return isValid;
    }
  }
};
</script>
