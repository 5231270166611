<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
      <gd-tabs
        :tabs="signaturesTabs"
        :current-tab="signaturesTab"
        wrapper-class="pt-4 px-0 gs-default-tabs"
        tab-class="pb-4 mr-4 gs-default-tabs-item"
        tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
        line-class="gs-default-tabs-active-line"
        @onClick="handleSignaturesTabChange"
      />
    </div>
    <div class="mt-2">
      <div v-if="signaturesTab == 'email'">
        <div v-if="hasEnabledEmailSignatures" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="emailSignaturesTabs"
              :current-tab="emailSignaturesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleEmailSignaturesTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in funnelResource.signatures.email" :key="index">
              <div v-if="isSelectedEmailSignature(post)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_signature') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="post.post"
                        class="h-32 w-full"
                        :placeholder="$t('frontend_resources_signature')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="post.post"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="iconlg"
                          variant="primary"
                          :title="$t('frontend_resources_copy_ad_body')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_email_sinatures') }}
        </div>
      </div>
      <div v-else-if="signaturesTab == 'forum'">
        <div v-if="hasEnabledForumSignatures" class="flex flex-col">
          <div class="flex flex-row mb-2 border-grey-100 border-b -mx-4 -mt-4 px-4">
            <gd-tabs
              :tabs="forumSignaturesTabs"
              :current-tab="forumSignaturesTab"
              wrapper-class="pt-4 px-0 gs-default-tabs"
              tab-class="pb-4 mr-4 gs-default-tabs-item"
              tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
              line-class="gs-default-tabs-active-line"
              @onClick="$emit('handleForumSignaturesTabChange', $event)"
            />
          </div>
          <div class="mt-2">
            <div v-for="(post, index) in funnelResource.signatures.forum" :key="index">
              <div v-if="isSelectedForumSignature(post)" class="pb-8">
                <div class="flex flex-col w-full mb-6">
                  <div class="flex flex-row w-1/2">
                    <div class="w-1/2">
                      <label class="font-semibold text-sm text-grey-700 mb-2">
                        {{ $t('frontend_resources_signature') }}
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
                      <text-area
                        v-model="post.post"
                        class="h-32 w-full"
                        :placeholder="$t('frontend_resources_signature')"
                        :readonly="true"
                      />
                    </div>
                    <div class="flex items-center content-center">
                      <div class="ml-2">
                        <gd-button
                          v-clipboard:copy="post.post"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          size="iconlg"
                          variant="primary"
                          :title="$t('frontend_resources_copy_ad_body')"
                        >
                          <copy-icon class="w-5 h-5 fill-current" />
                        </gd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('frontend_resources_no_forum_signatures') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/images/icons/CopyIcon.svg';

export default {
  components: {
    CopyIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    emailSignaturesTab: {
      type: String,
      default: '',
    },
    forumSignaturesTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      signaturesTabs: [
        { title: this.$t('frontend_resource_email'), value: 'email' },
        { title: this.$t('frontend_resource_forum'), value: 'forum' },
      ],
      signaturesTab: 'email',
    };
  },
  computed: {
    hasEnabledEmailSignatures() {
      return (
        this.funnelResource &&
        this.funnelResource.signatures &&
        this.funnelResource.signatures.email &&
        this.funnelResource.signatures.email.some((el) => el.enabled)
      );
    },
    hasEnabledForumSignatures() {
      return (
        this.funnelResource &&
        this.funnelResource.signatures &&
        this.funnelResource.signatures.forum &&
        this.funnelResource.signatures.forum.some((el) => el.enabled)
      );
    },
    emailSignaturesTabs() {
      return this.getSignatureTabs(this.funnelResource.signatures.email);
    },
    forumSignaturesTabs() {
      return this.getSignatureTabs(this.funnelResource.signatures.forum);
    },
  },
  methods: {
    handleSignaturesTabChange(newTab) {
      this.signaturesTab = newTab;
    },
    isSelectedEmailSignature(post) {
      return this.emailSignaturesTab === `email_signature_tab_${post.id}`;
    },
    isSelectedForumSignature(post) {
      return this.forumSignaturesTab === `forum_signature_tab_${post.id}`;
    },
    getSignatureTabs(signatures) {
      const signatureTabs = [];
      if (signatures) {
        signatures.forEach((signature) => {
          if (signature.enabled) {
            signatureTabs.push({
              title: signature.name,
              value: `${this.signaturesTab}_signature_tab_${signature.id}`,
            });
          }
        });
      }
      return signatureTabs;
    },
    onCopy() {
      this.$emit('onCopy');
    },
    onError() {
      this.$emit('onError');
    },
  },
};
</script>
