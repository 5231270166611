<template>
  <div class="min-h-full h-auto pb-6 -my-8 -mx-4">
    <promotools @logoutAffiliate="logoutAffiliate" />
  </div>
</template>

<script>
import Promotools from '@/components/promotools/Promotools.vue';

export default {
  name: 'PromoToolsAssets',
  components: {
    Promotools,
  },
  data() {
    return {
    };
  },
  methods: {
    logoutAffiliate() {
      this.$emit('userLoggedOut', 1);
    },
  }
};
</script>

<style>
.swipesEditor {
  height: 32rem;
}

.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}

.embed-container {
  margin-bottom: 1.1em;
  margin-top: 1.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

.vue-tags-input {
  max-width: 100% !important;
}

.vue-tags-input .ti-tags .ti-tag {
  background-color: var(--brand-primary) !important;
}
</style>

<style lang="scss">
.tgl {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2em;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    font-family: sans-serif;
    font-size: 11px;
    perspective: 100px;
    &:after,
    &:before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 4px;
    }

    &:after {
      content: attr(data-tg-on);
      background: #02c66f;
      transform: rotateY(-180deg);
    }

    &:before {
      background: var(--brand-primary);
      content: attr(data-tg-off);
    }

    &:active:before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &:before {
      transform: rotateY(180deg);
    }

    &:after {
      transform: rotateY(0);
      left: 0;
      background: #15b85a;
    }

    &:active:after {
      transform: rotateY(20deg);
    }
  }
}
</style>

<style scoped>
.fade-enter-active {
  transition: opacity 1.5s;
}
.fade-leave-active {
  opacity: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
