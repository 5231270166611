import RefreshScopes from '@/pages/auth/refresh_scopes';
import Login from '@/pages/auth/login';
import SSOHash from '@/pages/auth/sso_hash';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {}
  },
  {
    path: '/sso-hash/:token',
    name: 'sso_hash',
    component: SSOHash,
    meta: {
      pageTitle: 'SSO-Hash'
    }
  },
  {
    path: '/upgrade-user-logout',
    name: 'upgradedUserLogout',
    component: RefreshScopes,
    meta: {}
  }
];
