<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col w-1/2">
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_button_text') }}
        </label>
        <text-input
          v-model="localModel.customization_details.thankyou_button.button_text"
          :attribute="$t('frontend_button_text')"
          :placeholder="$t('frontend_bumps_button_text')"
        />
      </div>

      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_second_line_button_text') }}
        </label>
        <text-input
          v-model="localModel.customization_details.thankyou_button.button_subtext"
          :attribute="$t('frontend_button_text')"
          :placeholder="$t('frontend_bumps_second_line_button_text')"
        />
      </div>

      <div class="flex flex-row w-1/2 mb-6">
        <!-- frontend_bumps_button_bg_color -->
        <div class="flex flex-col w-1/2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_bg_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.thankyou_button.button_bg_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
              @change="$emit('thankyou_button_bg_color')"
            ></color-picker>
          </div>
        </div>

        <div class="flex flex-col w-1/2 ml-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_font_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.thankyou_button.button_font_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
            ></color-picker>
          </div>
        </div>
      </div>

      <div class="flex flex-row w-1/2">
        <div class="flex flex-col w-1/2 mb-6">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_border_size') }}
          </label>
          <select
            v-model="localModel.customization_details.thankyou_button.button_border_size"
            class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
          >
            <option value="2">{{ $t('frontend_groovesell_bumps_step4_none') }}</option>
            <option value="3">{{ $t('frontend_groovesell_bumps_step4_small') }}</option>
            <option value="1">{{ $t('frontend_groovesell_bumps_step4_regular') }}</option>
            <option value="4">{{ $t('frontend_groovesell_bumps_step4_large') }}</option>
          </select>
        </div>

        <!-- button border color -->
        <div class="flex flex-col w-1/2 ml-2">
          <label class="font-semibold text-sm text-grey-700 mb-2">
            {{ $t('frontend_bumps_button_border_color') }}
          </label>
          <div class="pr-6 w-24">
            <color-picker
              v-model="localModel.customization_details.thankyou_button.button_border_color"
              class="border-2 border-grey-300 rounded"
              :colors="colors"
              :cookies-origin="'groovesell_funnel_' + localModel.funnel_id"
            ></color-picker>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_button_size') }}
        </label>
        <select
          v-model="localModel.customization_details.thankyou_button.button_size"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="1">{{ $t('frontend_groovesell_bumps_step4_regular') }}</option>
          <option value="2">{{ $t('frontend_groovesell_bumps_step4_large') }}</option>
          <option value="3">{{ $t('frontend_groovesell_bumps_step4_xlarge') }}</option>
          <option value="4">{{ $t('frontend_groovesell_bumps_step4_full_width') }}</option>
        </select>
      </div>

      <!-- thankyou button shape -->
      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_button_shape') }}
        </label>
        <select
          v-model="localModel.customization_details.thankyou_button.button_shape"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="1">{{ $t('frontend_groovesell_bumps_step4_square') }}</option>
          <option value="2">{{ $t('frontend_groovesell_bumps_step4_rounded') }}</option>
        </select>
      </div>

      <div class="flex flex-col w-1/2 mb-6">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_bumps_button_shadow') }}
        </label>
        <select
          v-model="localModel.customization_details.thankyou_button.button_shadow"
          class="w-full h-10 outline-none rounded-lg px-4 border border-grey-200"
        >
          <option value="1">{{ $t('frontend_groovesell_bumps_step4_small') }}</option>
          <option value="2">{{ $t('frontend_groovesell_bumps_step4_medium') }}</option>
          <option value="3">{{ $t('frontend_groovesell_bumps_step4_large') }}</option>
          <option value="4">{{ $t('frontend_groovesell_bumps_step4_xlarge') }}</option>
        </select>
      </div>
    </div>

    <div class="w-1/2 flex flex-col justify-center items-center px-8" :style="[customColor]">
      <div
        class="custom-thankyou-button-color flex flex-col items-center justify-center focus:outline-none shadow hover:shadow-lg focus:shadow-lg rounded-lg p-2 cursor-pointer font-bold hover-custom-thankyou-button"
        :class="{
          'rounded-full': localModel.customization_details.thankyou_button.button_shape == 2,
          'w-80': localModel.customization_details.thankyou_button.button_size == 1,
          'w-120': localModel.customization_details.thankyou_button.button_size == 2,
          'w-160': localModel.customization_details.thankyou_button.button_size == 3,
          'w-full': localModel.customization_details.thankyou_button.button_size == 4,
          'shadow-sm': localModel.customization_details.thankyou_button.button_shadow == 1,
          'shadow': localModel.customization_details.thankyou_button.button_shadow == 2,
          'shadow-lg': localModel.customization_details.thankyou_button.button_shadow == 3,
          'shadow-xl': localModel.customization_details.thankyou_button.button_shadow == 4,
          'border-2': localModel.customization_details.thankyou_button.button_border_size == 1,
          'border-none': localModel.customization_details.thankyou_button.button_border_size == 2,
          'border': localModel.customization_details.thankyou_button.button_border_size == 3,
          'border-4': localModel.customization_details.thankyou_button.button_border_size == 4,
        }"
        :key="buttonSizeKey"
      >
        <div
          class="font-semibold font-sans -mt-1 heading-div"
          :class="{
            'text-lg': localModel.customization_details.thankyou_button.button_size == 1,
            'text-xl': localModel.customization_details.thankyou_button.button_size == 2,
            'text-2xl': localModel.customization_details.thankyou_button.button_size == 3,
            'text-3xl': localModel.customization_details.thankyou_button.button_size == 4,
          }"
        >
          {{ localModel.customization_details.thankyou_button.button_text }}
        </div>
        <div class="text-xs custom-thankyou-button-subtext-color">
          {{ localModel.customization_details.thankyou_button.button_subtext }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@groovepages/gd-ui-kit/components/ColorPicker';

export default {
  components: {
    ColorPicker,
  },
  props: {
    model: {
      required: true,
    },
    colors: {
      required: true,
    },
    customColor: {
      required: true,
    },
    buttonSizeKey: {
      required: true,
    },
  },
  data() {
    return {
      localModel: this.model,
    }
  },
}
</script>
