import Model from './Model';

export default class Bump extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'bumps';
  }

  get upsell_embed() {
    const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
    const groovesellWhiteLabelAppName = window.config.groovesellWhiteLabelAppName;
    const groovepagesWhiteLabelAppName = window.config.groovepagesWhiteLabelAppName;

    return `<div style="width: 100%;"><${groovesellWhiteLabelAppName}-atc-upsell bump-id="${this.id}" button-text="${this.localModel.customization_details.thankyou_button.button_text}" button-bg-color="${this.localModel.customization_details.thankyou_button.button_bg_color}" button-font-color="${this.localModel.customization_details.thankyou_button.button_font_color}" button-size="${this.localModel.customization_details.thankyou_button.button_size}" button-shape="${this.localModel.customization_details.thankyou_button.button_shape}"></${groovesellWhiteLabelAppName}-atc-upsell><script src="https://${groovepagesWhiteLabelAppName}.${siteAppurl}/atc/js/app.js?n=1"></script></div>`;
  }
}
