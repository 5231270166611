var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col video-vertical-tabs",class:_vm.wrapperClass},_vm._l((_vm.tabs),function(tab){return _c('button',{key:tab.value,ref:tab.value,refInFor:true,class:[
      'px-6 py-3 w-full text-left focus:outline-none text-sm',
      { 'border-l-4 border-primary text-primary font-bold': tab.value === _vm.currentTab },
      { 'text-grey-800 font-semibold': tab.value != _vm.currentTab },
      tab.value === _vm.currentTab && _vm.tabActiveClass ? _vm.tabActiveClass : '',
      _vm.tabClass,
    ],attrs:{"type":"button","disabled":tab.disabled || false},domProps:{"innerHTML":_vm._s(tab.title)},on:{"click":function($event){return _vm.handleClick(tab.value)}}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }