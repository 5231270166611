<template>
    <div>
        <gd-modal name="change-affiliate-commission-modal" :title="$t('frontend_analytics_change_commision')">
            <div class="flex flex-col m-10" style="min-height: 30vh">
                <div class="flex flex-col w-full mb-4">
                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                        $t('frontend_analytics_transactions_commision')
                    }}</label>
                    <div class="w-full flex flex-row">
                        <div class="w-1/3 mr-2">
                            <text-input v-model="changedCommission.commission" class="w-1/3"
                                :validator="$v.changedCommission.commission" :attribute="$t('frontend_analytics_commission')"
                                value="changedCommission.commission"
                                :placeholder="$t('frontend_analytics_transactions_commision')" />
                        </div>
                        <gd-dropdown
                            v-model="changedCommission.commission_type"
                            :options="typeOptions"
                            size="full"
                        />
                    </div>
                </div>
                <div class="flex flex-row w-full mb-6 justify-end mt-6">
                    <gd-button size="auto" variant="primary" @click="openConfirmModal">{{
                        $t('frontend_analytics_change_commision') }}</gd-button>
                </div>
            </div>
        </gd-modal>
        <confirm-modal
            name="confirm-commission-modal"
            :message-content="$t('frontend_change_commission_warning')"
            :button-text="$t('frontend_change_commission_confirm')"
            button-size="auto"
            @confirm="changeCommission"
        />
        <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
            <template v-slot:title>
                {{ alertProps.title }}
            </template>
            <p>{{ alertProps.message }}</p>
        </popup-alert>
    </div>
</template>
<script>
import Transaction from '@/models/Transaction';
import axios from 'axios';
import { required, decimal, minValue } from 'vuelidate/lib/validators';

export default {
    validations: {
        changedCommission: {
            commission: {
                required,
                decimal,
                minValue: minValue(0),
                isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                        return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                },
            },
        },
    },
    data() {
        return {
            alertProps: {
                show: false,
                variant: 'success',
                title: '',
                message: '',
            },
            changedCommission: {
                commission: this.selectedTransaction?.commission_breakdown_amount,
                commission_type: this.selectedTransaction?.commission_breakdown_value,
            },
            localselectedTransaction: this.selectedTransaction,
        }
    },
    computed: {
        typeOptions() {
            return [
                { value: 'percent', text: this.$t('frontend_analytics_percentage') },
                { value: 'fixed', text: this.$t('frontend_analytics_fixed_amount') },
            ];
        },
    },
    watch: {
        selectedTransaction() {
            this.changedCommission = {
                commission: this.selectedTransaction?.commission_breakdown_amount,
                commission_type: this.selectedTransaction?.commission_breakdown_value,
            };
            this.localselectedTransaction = this.selectedTransaction;
        },
    },
    props: {
        selectedTransaction: {
            type: Object,
            default: () => { },
        },
        reloadData: {
            type: Function,
            default: () => { },
        },
    },
    methods: {
        openConfirmModal() {
            this.$root.$emit('modal-open', 'confirm-commission-modal');
        },
        async changeCommission() {
            this.$loader.start('changeCommission');
            const self = this;
            const changeCommissionData = await axios.post(
                '/transactions/change-commission',
                {
                    transaction_id: self.selectedTransaction.id,
                    changed_commission_amount: self.changedCommission.commission,
                    changed_commission_type: self.changedCommission.commission_type,
                }
            );

            await this.reloadData();
            this.localselectedTransaction = await Transaction.$find(self.selectedTransaction.id);

            const changedData = changeCommissionData.data;
            const changeStatus = changedData.status;
            if (changeStatus == 'success') {
                this.alertProps = {
                    show: true,
                    variant: 'success',
                    title: this.$t('frontend_change_successful_title'),
                    message: this.$t('frontend_change_successful_message'),
                };
                this.$root.$emit(
                    'modal-close',
                    'change-affiliate-commission-modal'
                );
                let highLightTabs = [];

                if (this.selectedTransaction.suspected_affiliate_fraud == 1) {
                    highLightTabs.push('affiliate');
                }
                this.$emit('filter-changed', {
                    key: 'highLightTabs',
                    value: highLightTabs,
                    startSearch: false,
                })
            } else {
                this.alertProps = {
                    show: true,
                    variant: 'error',
                    title: this.$t('frontend_change_failed_title'),
                    message: this.$t('frontend_change_failed_message'),
                };
                this.$root.$emit(
                    'modal-close',
                    'change-affiliate-commission-modal'
                );
            }

            this.$loader.end('changeCommission');
        },
    }
}
</script>