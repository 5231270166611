import Model from './Model';
import Product from './Product';
import FunnelResource from './FunnelResource';
import Transaction from './Transaction';
import Signup from './Signup';
import FunnelAffiliate from './FunnelAffiliate';
import Bump from './Bump';

export default class Funnel extends Model {
  // eslint-disable-next-line class-methods-use-this
  resource() {
    return 'funnels';
  }

  products() {
    return this.hasMany(Product);
  }

  bumps() {
    return this.hasMany(Bump);
  }

  promotional_areas() {
    return this.hasMany(FunnelResource);
  }

  transactions() {
    return this.hasMany(Transaction);
  }

  signups() {
    return this.hasMany(Signup);
  }

  affiliates() {
    return this.hasMany(FunnelAffiliate);
  }

  get affiliate_portal() {
    const siteAppurl = window.config.appUrl.replace(/^https?:\/\//i, '');
    return `https://${this.slug}.${siteAppurl}/${this.id}/affiliate-signup`;
  }

  get products_count() {
    return this.funnel_products.length;
  }
}
