/* eslint-disable complexity */
<template>
  <div class="h-auto min-h-full pb-6 bg-secondary">
    <div class="relative flex flex-col justify-start w-full bg-white px-5">
      <div class="flex flex-row w-full container mx-auto">
        <gd-tabs :tabs="tabs" :current-tab="currentTab" :error-tabs="errorTabs"
          :disable-not-completed="isPreviouslyCompletedProduct == 0" :disable-exclamatory-marks="true"
          :wrapper-class="'pt-4 px-0 gs-default-tabs'" :tab-class="mainTabClass"
          :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
          :line-class="'gs-default-tabs-active-line'" @onClick="handleTabChange" />
        <div v-if="isPreviouslyCompletedProduct" class="flex flex-row ml-8">
          <gd-button v-if="currentTab != 'finish-step'" size="auto" class="mt-3 mr-4" variant="primary"
            @click="exitWizardForCompletedProducts(true)">{{ $t('frontend_save') }}</gd-button>
          <gd-button v-else variant="primary" size="auto" class="mt-3 mr-4" @click="saveAndExitWizard">{{
            $t('frontend_products_create_save_exit') }}</gd-button>
        </div>
        <div v-else class="flex flex-row ml-8">
          <gd-button v-if="currentTab != 'info-step'" size="auto" class="mt-3 mr-4" 
          @click="moveOnWizard(false)">{{ $t('frontend_products_create_tabs_back') }}</gd-button>
          <gd-button v-if="currentTab != 'finish-step'" variant="primary" size="auto" class="mt-3 mr-4"
            @click="moveOnWizard(true)">{{ $t('frontend_products_create_save_next') }}</gd-button>
          <gd-button v-else variant="primary" size="auto" class="mt-3 mr-4" @click="saveAndExitWizard">{{
            $t('frontend_products_create_save_exit') }}</gd-button>
        </div>
      </div>
    </div>

    <v-wait for="create_product">
      <div class="px-5">
        <div class="relative flex flex-col justify-start w-full h-full mt-8 container mx-auto">
          <div>
            <component :is="currentTab" :ref="currentTab" :model="model" :gateway-check-box="gatewayCheckBox"
              :gateway-integrations="gatewayIntegrations" :product="product" :price-points="price_points"
              :resources-path="resourcesPath" :server-path="serverPath" :plugin-site-data="pluginSiteData"
              :available-integrations="availableIntegrations" :funnel-products="funnelProducts"
              :facebookCoversionList="this.facebook_coversion_list" :affiliates="affiliates"
              @saveProdInfo="saveProductInfo" @updateModel="model = $event"
              @updateGatewayCheckbox="gatewayCheckBox = $event"
              @on-validate="stepValidationSuccessful(currentTab, $event)" @updatePricePoints="price_points = $event"
              @updateIntegration="gatewayIntegrations = $event" @reloadIntegrations="fetchIntegrations" />
          </div>
        </div>
      </div>
    </v-wait>

    <portal to="headerContent">
      <gd-button v-if="isPreviouslyCompletedProduct" variant="primary" size="auto" class="mr-4"
        @click="exitWizardForCompletedProducts">{{ $t('frontend_exit') }}</gd-button>
    </portal>

    <portal to="headerBreadCrumb">
      <div v-if="funnel?.name" class="flex items-center items-center -ml-5">
        <div class="cursor-pointer text-grey-500" @click="sendToFunnelProducts" v-tooltip="'Back to Products'">
          <arrow-bracket-icon class="h-5 w-5 fill-current"/>
        </div>
        <div class="text-sm font-semibold ml-3">{{ funnel?.name }}</div>
        <chevron-right-icon class="h-5 w-5 fill-current"/>
        <div v-if="product && product.name" class="text-sm font-semibold">{{ product.name }}</div>
        <div v-if="product && product.name">
          <chevron-right-icon class="h-5 w-5 fill-current"/>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import axios from 'axios';
import ArrowBracketIcon from '@/assets/images/icons/ArrowBracketIcon.svg';
import ChevronRightIcon from '@/assets/images/icons/ChevronRightIcon.svg';
import {
  required,
  minLength,
  maxLength,
  numeric,
  decimal,
  requiredIf,
  url,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';
import auth from '@groovepages/gd-lib/auth';
import urlParser from 'js-video-url-parser/lib/base';
import Funnel from '@/models/Funnel';
import Product from '@/models/Product';
import PricePoint from '@/models/PricePoint';
import Integration from '@/models/Integration';

import AffiliatesStep from './steps/AffiliatesStep';
import ContentsStep from './steps/ContentsStep';
import FinishStep from './steps/FinishStep';
import FunnelStep from './steps/FunnelStep';
import GatewaysStep from './steps/GatewaysStep';
import InfoStep from './steps/InfoStep';
import PricingStep from './steps/PricingStep';
import BumpsStep from './steps/BumpsStep';
import CheckoutStep from './steps/CheckoutStep';
import FullfillmentStep from './steps/FullfillmentStep';
import ProofStep from './steps/ProofStep';


import 'js-video-url-parser/lib/provider/wistia';

import { userRegion } from '@/utils';

const defaultLanguage = (userRegion == 'brazil' ? 'pt' : 'en');

export default {
  layout: 'defaultWithoutSidebar',
  scrollToTop: true,
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_products_create_title') };
  },
  validations: {
    model: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      internal_name: {
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160),
      },
      sales_page: {
        required,
        url,
      },
      refund_period: {
        required,
        numeric,
        minValue: minValue(0),
      },
      support_info_value: {
        validSupportInfo(value, object) {
          const self = this;

          if (self.model.is_support_enabled) {
            if (value) {
              if (self.model.support_info_type == 1) {
                var pattern = new RegExp(
                  '^(https?:\\/\\/)?' + // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                  '(\\#[-a-z\\d_]*)?$',
                  'i'
                ); // fragment locator
                return !!pattern.test(value);
              }
              var pattern =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value.toLowerCase());
            }
            return false;
          }
          return true;
        },
      },
      waiting_period: {
        required,
        numeric,
        minValue: minValue(0),
      },
      jv_page: {
        url,
      },
      affiliate_landing_pages: {
        required,
        minLength: minLength(1),
        $each: {
          landing_page: {
            url,
          },
          description: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
        },
      },
      paypal_processing_accounts: {
        $each: {
          weightage: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(100),
            totalHundredPercent() {
              const self = this;
              let totalWeightage = 0;

              for (
                let i = 0;
                i < self.model.paypal_processing_accounts.length;
                i++
              ) {
                totalWeightage += parseInt(
                  self.model.paypal_processing_accounts[i].weightage,
                  10
                );
              }

              if (totalWeightage == 100) {
                return true;
              }
              return false;
            },
          },
        },
      },
      credit_card_processing_accounts: {
        $each: {
          weightage: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(100),
            totalHundredPercent() {
              const self = this;
              let totalWeightage = 0;

              for (
                let i = 0;
                i < self.model.credit_card_processing_accounts.length;
                i++
              ) {
                totalWeightage += parseInt(
                  self.model.credit_card_processing_accounts[i].weightage,
                  10
                );
              }

              if (totalWeightage == 100) {
                return true;
              }
              return false;
            },
          },
        },
      },
      pricing_points: {
        required,
        minLength: minLength(1),
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          internal_name: {
            minLength: minLength(3),
            maxLength: maxLength(500),
          },
          description: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(160),
          },
          minimumQuantity: {
            isValidQuantityType(value, object) {
              if (object.quantity_enable == 1) {
                if (object.minimum_quantity <= object.maximum_quantity) {
                  return true;
                }
                return false;
              }
              return true;
            },
          },
          price: {
            required,
            decimal,
            minValue: minValue(0),
            isValidPricePointPrice(value, object) {
              if (value > 0) {
                return true;
              }
              if (object.is_trial == 1) {
                return false;
              }
              if (object.type == 2 || object.type == 3) {
                return false;
              }
              return true;
            },
          },
          number_of_installments: {
            required: requiredIf((object) => {
              return object.type == 3 || object.type == 4;
            }),
            numeric,
            isValidNumberOfInstallments(value, object) {
              if (object.type == 3 || object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },
          recurring_interval: {
            required: requiredIf((object) => {
              return object.type == 3 || object.type == 4;
            }),
            isValidNumberOfRicurringInterval(value, object) {
              if (object.type == 3 || object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },
          recurrence_interval: {
            required: requiredIf((object) => {
              return object.type == 4;
            }),
            numeric,
            isValidNumberOfRecurrenceInterval(value, object) {
              if (object.type == 4) {
                if (value < 1) {
                  return false;
                }
                return true;
              }
              return true;
            },
          },
          trial_price: {
            required: requiredIf((object) => {
              return object.is_trial == 1;
            }),
            decimal,
            minValue: minValue(0),
          },
          trial_period: {
            required: requiredIf((object) => {
              return object.is_trial == 1;
            }),
            numeric,
            minValue: minValue(0),
          },
          post_checkout_details: {
            thankyou_page: {
              required,
              url,
            },
          },
          post_transaction_details: {
            post_transaction_url: {
              required: requiredIf((object) => {
                return object.type == 2;
              }),
              url,
            },
          },
          post_sale_details: {
            $each: {
              link: {
                url,
                required,
              },
            },
          },
          offer_bump_options: {
            $each: {
              heading_line: {
                required: requiredIf((object, value) => {
                  if (object) {
                    return true;
                  }
                  return false;
                }),
              },
              description: {
                required: requiredIf((object, value) => {
                  if (object) {
                    return true;
                  }
                  return false;
                }),
              },
            },
          },
          post_sale_order_bump_options: {
            post_order_heading_line: {
              required: requiredIf((object) => {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1
                ) {
                  return true;
                }
                return false;
              }),
            },
            post_order_description: {
              required: requiredIf((object) => {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1
                ) {
                  return true;
                }
                return false;
              }),
            },
            post_order_video_details: {
              url: {
                required: requiredIf((object) => {
                  if (
                    object.post_order_show_video &&
                    object.post_order_show_video == true
                  ) {
                    return true;
                  }
                  return false;
                }),
                isValidPostSaleVideoUrl(value, object) {
                  if (
                    object.post_order_show_video &&
                    object.post_order_show_video == true
                  ) {
                    const videoInfo = urlParser.parse(value);
                    if (videoInfo != undefined) {
                      return true;
                    }
                    const brandAltName = this.$store.state.brandData.brandAltName.toLowerCase();
                    const videoAppTitle = this.whitelabelAppData('video').title.toLowerCase();
                    const expression = new RegExp(`(http:|https:|)\/\/?(app\\.${brandAltName}\\.com)\/${videoAppTitle}\/videopage\/[A-Za-z0-9]*\/[A-Za-z0-9]*`, 'g');

                    const regex = new RegExp(expression);
                    if (value.match(regex)) {
                      return true;
                    }

                    return false;
                  }
                  return true;
                },
              },
            },
            post_order_product_id: {
              isValidProductId(value, object) {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1 &&
                  (!value || value == 0)
                ) {
                  return false;
                }
                return true;
              },
            },
            post_order_price_point_id: {
              isValidPricePointtId(value, object) {
                if (
                  object.post_order_bump_enable &&
                  object.post_order_bump_enable == 1 &&
                  (!value || value == 0)
                ) {
                  return false;
                }
                return true;
              },
            },
          },
          commission_details: {
            $each: {
              // name:{
              //   required
              // },
              trial_commission: {
                commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
                jv_broker_commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
              },
              sales_commission: {
                commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
                jv_broker_commission: {
                  required,
                  decimal,
                  minValue: minValue(0),
                  isValidCommission(value, object) {
                    const regexp = /^\d+(\.\d{1,2})?$/;

                    if (object.commission_type == 'percent') {
                      return regexp.test(value) && value <= 100;
                    }
                    return regexp.test(value);
                  },
                },
              },
              rebill_commissions: {
                $each: {
                  commission: {
                    required,
                    decimal,
                    minValue: minValue(0),
                    isValidCommission(value, object) {
                      const regexp = /^\d+(\.\d{1,2})?$/;

                      if (object.commission_type == 'percent') {
                        return regexp.test(value) && value <= 100;
                      }
                      return regexp.test(value);
                    },
                  },
                  no_of_payments: {
                    numeric,
                    minValue: minValue(0),
                  },
                  jv_broker_commission: {
                    required,
                    decimal,
                    minValue: minValue(0),
                    isValidCommission(value, object) {
                      const regexp = /^\d+(\.\d{1,2})?$/;

                      if (object.commission_type == 'percent') {
                        return regexp.test(value) && value <= 100;
                      }
                      return regexp.test(value);
                    },
                  },
                },
              },
            },
          },
          legal_options: {
            enabled: 0,
            html: '',
          },
          $trackBy: 'id',
        },
      },
    },
  },
  components: {
    ChevronRightIcon,
    ArrowBracketIcon,
    AffiliatesStep,
    ContentsStep,
    FinishStep,
    FunnelStep,
    GatewaysStep,
    InfoStep,
    PricingStep,
    BumpsStep,
    CheckoutStep,
    FullfillmentStep,
    ProofStep,
  },
  data() {
    return {
      facebook_coversion_list: [],
      initialPricePoints: [],
      errorTabs: [],
      funnels: null,
      postValidationTab: 'pricing-step',
      currentTab: 'info-step',
      edit_product: false,
      funnel: null,
      product: null,
      pricePoint: null,
      price_points: [],
      is_main_product: 0,
      gatewayCheckBox: '',
      model: {
        commission_after_processing: false,
        primary_color: '',
        secondary_color: '',
        name: '',
        internal_name: '',
        description: '',
        funnel_id: '',
        type: 1,
        refund_period: 0,
        is_support_enabled: 0,
        support_info_type: 1,
        support_info_value: null,
        waiting_period: 0,
        sales_page: '',
        product_customization_details: null,
        password_options: null,
        disable_branding_bar: 0,
        jv_page: '',
        tracking_link: null,
        minimum_quantity: 2,
        maximum_quantity: 2,
        paypal_risk_percentage: 100,
        paypal_processing_accounts: [],
        credit_card_processing_accounts: [],
        pricing_points: [],
        checkout_page_options: {
          type: 1,
          look_and_feel: {
            template_type: 1,
          },
          form_fields: [
            { order: 1, type: 'fname', label: this.$t('frontend_first_name'), enabled: 1 },
            { order: 2, type: 'lname', label: this.$t('frontend_last_name'), enabled: 1 },
            { order: 3, type: 'email', label: 'Email', enabled: 1 },
            {
              order: 4,
              type: 'countryAndState',
              label: this.$t('frontend_product_create_country_state'),
              enabled: 1,
            },
            { order: 5, type: 'address', label: this.$t('frontend_Address'), enabled: 1 },
            {
              order: 6,
              type: 'cityAndPostalCode',
              label: this.$t('frontend_product_create_city_postalcode'),
              enabled: 1,
            },
            { order: 7, type: 'phone', label: this.$t('frontend_phone'), enabled: 1 },
            { order: 8, type: 'company', label: this.$t('frontend_company'), enabled: 1 },
          ],
          express_checkout: 0,
          cart_abandonment: {
            enabled: 0,
            options: [],
          },
          language: {
            type: 1,
            languages: [defaultLanguage],
          },
          rosca_required: 0,
          legal: {
            enabled: 0,
            html: '',
          },
          tracking_codes: '',
          exit_popup: {
            enabled: 0,
            html: '',
          },
          testimonials: 0,
        },
        affiliate_registration_integrations: {},
        affiliates_enabled: 0,
        affiliate_landing_pages: [],
        is_main: 0,
        is_wizard_complete: 0,
      },
      assigned_to: null,
      mainWizardIndex: 0,
      gatewayIntegrations: [],
      affiliates: [],
      changeTabsAfterValidation: 1,
      saveTabsAfterValidation: 1,
      funnelProducts: null,
      isPreviouslyCompletedProduct: 0,
    };
  },

  computed: {
    tabs() {
      const tabTitles = [
        'frontend_products_create_tabs_info',
        'frontend_products_create_tabs_pricing',
        'frontend_products_create_tabs_gateways',
        'frontend_products_create_tabs_contents',
        'frontend_products_create_tabs_checkout',
        'frontend_products_create_tabs_bumps',
        'frontend_products_create_tabs_funnel',
        'frontend_products_create_tabs_fullfillment',
        'frontend_products_create_tabs_affiliates',
        'frontend_products_create_tabs_proof',
        'frontend_products_create_tabs_finish',
      ]
      const tabs = []
      tabTitles.forEach((title, index) => {
        let t = `<span class="text-sm font-semibold">${index + 1}. ${this.$t(title)}`
        if (index !== tabTitles.length - 1) {
          t += '<i class="ml-1 mr-2 text-xs fas fa-chevron-right" aria-hidden="true"></i>'
        }
        t += '</span>'
        /** tabName what after last _ in title */
        const tabName = title.split('_').pop() + '-step'
        tabs.push({
          title: t,
          value: tabName,
        })
      })
      return tabs
    },

    paypalIntegrations() {
      return this.gatewayIntegrations.filter((integration) => {
        return integration.type == 1 && integration.platform == 'paypal';
      });
    },

    cardIntegrations() {
      return this.gatewayIntegrations.filter((integration) => {
        return integration.type == 1 && integration.platform != 'paypal';
      });
    },

    salesPage() {
      return this.model.sales_page;
    },

    paypalAddedAccounts() {
      return this.model.paypal_processing_accounts.map((a) => a.id);
    },

    cardAddedAccounts() {
      return this.model.credit_card_processing_accounts.map((a) => a.id);
    },

    mainTabClass() {
      return "pb-4 gs-default-tabs-item"
    },

    resourcesPath() {
      return `${window.gdEnv.VUE_APP_STORAGE_URL}/plugins/`;
    },

    serverPath() {
      const digitalAppTitle = this.whitelabelAppData('digital')?.title.toLowerCase() || 'groovedigital';
      return `${window.gdEnv.VUE_APP_API_URL}/${digitalAppTitle}/plugins/call/`;
    },

    pluginSiteData() {
      return this.$store.getters.pluginSiteSettings;
    },

    availableIntegrations() {
      const user = auth.authenticatedUser();
      if (user.metadata && user.metadata.integrations) {
        const integrations = [];
        user.metadata.integrations.map((value, key) => {
          const integrationType = value.type.split('-')[0];
          const integrationPlugin = `${integrationType}-component`;
          if (integrations.findIndex((x) => x.value == integrationPlugin) == -1) {
            integrations.push({
              text: integrationType.charAt(0).toUpperCase() + integrationType.slice(1),
              value: integrationPlugin,
            });
          }
        });

        return integrations;
      }
      return [];
    },
  },

  watch: {
    salesPage(newVal) {
      if (
        this.model.affiliate_landing_pages &&
        this.model.affiliate_landing_pages[0]
      ) {
        this.model.affiliate_landing_pages[0].landing_page = newVal;
      }
    },
  },

  async created() {
    this.$wait.start('create_product');
    this.funnel = await Funnel.$find(this.$route.params.id);



    const FbSettings = await axios.post('/conversion-settings', {
      list: true,
    });

    this.facebook_coversion_list = FbSettings.data.data;

    if (this.$route.params.product_id == undefined) {
      this.product = new Product({}).for(this.funnel);

      if (
        this.$route.query.product_type &&
        this.$route.query.product_type !== undefined
      ) {
        
        this.model.type = parseInt(this.$route.query.product_type);
      }
    } else {
      this.product = await this.funnel
        .products()
        .$find(this.$route.params.product_id);


      this.model.commission_after_processing = this.product.commission_after_processing;
      this.model.funnel_id = this.product.funnel_id;
      this.model.name = this.product.name;
      this.model.internal_name = this.product.internal_name;
      this.model.description = this.product.description;
      this.model.type = this.product.type;
      this.model.refund_period = this.product.refund_period;
      this.model.is_support_enabled = this.product.is_support_enabled;
      this.model.support_info_type = this.product.support_info_type;
      this.model.product_customization_details = this.product.product_customization_details;
      this.model.password_options = this.product.password_options;
      this.model.disable_branding_bar = this.product.disable_branding_bar;
      this.model.support_info_value = this.product.support_info_value;
      this.model.waiting_period = this.product.waiting_period;
      this.model.tracking_link = this.product.tracking_link;
      this.model.sales_page = this.product.sales_page;
      this.model.jv_page = this.product.jv_page;
      this.model.checkout_page_options = this.product.checkout_page_options;

      this.model.primary_color = '#ffcc24';
      this.model.secondary_color = '#033a7c';

      if (
        this.product.checkout_page_options.look_and_feel.template_type !=
        undefined &&
        this.product.checkout_page_options.look_and_feel.template_type == 4
      ) {
        this.model.primary_color = this.product.checkout_page_options
          .look_and_feel.custom_color.primary_color
          ? this.product.checkout_page_options.look_and_feel.custom_color
            .primary_color
          : '#ffcc24';
        this.model.secondary_color = this.product.checkout_page_options
          .look_and_feel.custom_color.secondary_color
          ? this.product.checkout_page_options.look_and_feel.custom_color
            .secondary_color
          : '#033a7c';
      }

      this.model.affiliate_registration_integrations =
        this.product.affiliate_registration_integrations;
      this.model.affiliate_data_share_details =
        this.product.affiliate_data_share_details;

      this.model.paypal_risk_percentage = this.product.paypal_risk_percentage;

      if (this.product.paypal_processing_accounts) {
        this.model.paypal_processing_accounts =
          this.product.paypal_processing_accounts;
      }

      if (this.product.credit_card_processing_accounts) {
        this.model.credit_card_processing_accounts =
          this.product.credit_card_processing_accounts;
      }

      if (this.product.paypal_processing_accounts) {
        this.gatewayCheckBox = 'paypal';
      } else if (this.product.credit_card_processing_accounts) {
        this.gatewayCheckBox = 'card';
      }

      const productPricingPoints = await this.product.pricepoints().$get();


      for (let i = 0; i < productPricingPoints.length; i++) {
        const singlePricingPoint = productPricingPoints[i];
        this.price_points.push(singlePricingPoint);

        let pixelId = singlePricingPoint.pixel_id;
        if (!singlePricingPoint.facebook_conversion_api_enabled) {
          pixelId = 0;
        }


        this.model.pricing_points.push({
          id: singlePricingPoint.id,
          type: singlePricingPoint.type,
          name: singlePricingPoint.name,
          internal_name: singlePricingPoint.internal_name,
          description: singlePricingPoint.description,
          price: singlePricingPoint.price,
          recurring_interval: singlePricingPoint.recurring_interval,
          recurrence_interval: singlePricingPoint.recurrence_interval,
          number_of_installments: singlePricingPoint.number_of_installments,
          is_trial: singlePricingPoint.is_trial,
          trial_price: singlePricingPoint.trial_price,
          trial_period: singlePricingPoint.trial_period,
          trial_period_type: singlePricingPoint.trial_period_type,
          salespage_type: singlePricingPoint.salespage_type,
          salespage_details: singlePricingPoint.salespage_details,
          maximum_quantity: singlePricingPoint.maximum_quantity,
          minimum_quantity: singlePricingPoint.minimum_quantity,
          quantity_enable: singlePricingPoint.quantity_enable,
          offer_bump_enable: singlePricingPoint.offer_bump_enable,
          offer_bump_options: singlePricingPoint.offer_bump_options,
          post_sale_order_bump_options:
            singlePricingPoint.post_sale_order_bump_options,
          post_checkout_type: singlePricingPoint.post_checkout_type,
          post_checkout_details: singlePricingPoint.post_checkout_details,
          post_transaction_details: singlePricingPoint.post_transaction_details,
          post_sale_notification_type:
            singlePricingPoint.post_sale_notification_type,
          post_sale_details: singlePricingPoint.post_sale_details,
          post_sale_integrations: singlePricingPoint.post_sale_integrations,
          memberships: singlePricingPoint.memberships,
          file_memberships: singlePricingPoint.file_memberships,
          legal_options: singlePricingPoint.legal_options,
          abandon_cart_integrations:
            singlePricingPoint.abandon_cart_integrations,
          affiliate_registration_integrations:
            singlePricingPoint.affiliate_registration_integrations,
          customer_portal_details: singlePricingPoint.customer_portal_details,
          content_access_type: singlePricingPoint.content_access_type,
          content_access_options: singlePricingPoint.content_access_options,
          advanced_tracking_code_enabled:
            singlePricingPoint.advanced_tracking_code_enabled,

          facebook_conversion_api_enabled:
            singlePricingPoint.facebook_conversion_api_enabled,

          pixel_id: pixelId,

          advanced_tracking_code_details:
            singlePricingPoint.advanced_tracking_code_details,
          pricing_string: singlePricingPoint.pricing_string,
          currency_symbol: singlePricingPoint.currency_symbol,
          currency_code: singlePricingPoint.currency_code,
          commission_type: singlePricingPoint.commission_type,
          commission_details: singlePricingPoint.commission_details,
          is_default: singlePricingPoint.is_default,
        });
      }

      this.model.affiliates_enabled = this.product.affiliates_enabled;

      if (
        this.product.affiliate_landing_pages &&
        this.product.affiliate_landing_pages.length > 0
      ) {
        this.model.affiliate_landing_pages =
          this.product.affiliate_landing_pages;
      }

      this.model.is_main = this.product.is_main;
      this.model.is_wizard_complete = this.product.is_wizard_complete;
      // this.affiliates = await this.funnel.affiliates().$get();
      this.edit_product = true;

      this.isPreviouslyCompletedProduct = this.product.is_wizard_complete;
    }

    this.gatewayIntegrations = await Integration.$get();
    const funnelProducts = await this.funnel.products().$get();

    const tempFunnel = [];
    funnelProducts.find((product) => {
      if (product.id != this.$route.params.product_id) {
        tempFunnel.push(product);
      }
    });

    for (let j = 0; j < this.model.pricing_points.length; j++) {
      this.model.pricing_points[j].funnelProducts = tempFunnel;
      if (this.model.pricing_points[j].offer_bump_enable) {
      } else {
        this.model.pricing_points[j].productPricePoint = null;
        this.model.pricing_points[j].offer_bump_options.product_id = 0;
        this.model.pricing_points[j].offer_bump_options.price_point_id = 0;
      }

      if (
        this.model.pricing_points[j].post_sale_order_bump_options
          .post_order_bump_enable &&
        this.model.pricing_points[j].post_sale_order_bump_options
          .post_order_product_id != null &&
        this.model.pricing_points[j].post_sale_order_bump_options
          .post_order_product_id > 0
      ) {

        const pro = tempFunnel.find(
          (product) =>
            product.id ==
            this.model.pricing_points[j].post_sale_order_bump_options
              .post_order_product_id
        );
        this.model.pricing_points[j].postSaleProductPricePoint = pro?.price_points;
      } else {
        this.model.pricing_points[j].postSaleProductPricePoint = null;
        this.model.pricing_points[
          j
        ].post_sale_order_bump_options.post_order_product_id = 0;
        this.model.pricing_points[
          j
        ].post_sale_order_bump_options.post_order_price_point_id = 0;
      }
    }

    if (this.$route.query.validate && this.$route.query.validate == 1) {
      await this.validateAllTabs();
    }

    this.$wait.end('create_product');
    this.initialPricePoints = [...this.price_points];
  },

  methods: {
    sendToFunnelProducts() {
      this.$router.push({ name: 'products', params: { id: this.funnel.id } });
    },

    async fetchIntegrations() {
      this.gatewayIntegrations = await Integration.$get();
    },
    handleTabChange(newTab) {
      let refToValidate;

      this.changeTabsAfterValidation = 0;
      refToValidate = this.$refs[this.currentTab];
      const validationFlag = refToValidate.validate();

      if (validationFlag) {
        this.currentTab = newTab;
        this.$nextTick(function () {
          this.changeTabsAfterValidation = 0;
          this.saveTabsAfterValidation = 0;
        });
      }
    },

    moveOnWizard(next) {
      const currentlySelectedTabIndex = this.tabs
        .map((item) => item.value)
        .indexOf(this.currentTab);
      const tabIndex = next ? currentlySelectedTabIndex + 1 : currentlySelectedTabIndex - 1;
      const nextTab = this.tabs[tabIndex].value;
      this.postValidationTab = nextTab;
      this.changeTabsAfterValidation = 1;
      const refToValidate = this.$refs[this.currentTab];
      return refToValidate.validate();
      // this.currentTab = nextTab;
    },



    async validateAllTabs() {
      this.errorTabs = [];
      this.$v.$touch();
      if (this.$v.model.$invalid) {
        if (
          this.$v.model.name.$invalid ||
          this.$v.model.internal_name.$invalid ||
          this.$v.model.description.$invalid
        ) {
          this.errorTabs.push('info-step');
        }

        for (let i = 0; i < this.model.pricing_points.length; i++) {
          if (
            this.$v.model.pricing_points.$each[i].name.$invalid ||
            this.$v.model.pricing_points.$each[i].internal_name.$invalid ||
            this.$v.model.pricing_points.$each[i].description.$invalid ||
            this.$v.model.pricing_points.$each[i].price.$invalid ||
            this.$v.model.pricing_points.$each[i].number_of_installments
              .$invalid ||
            this.$v.model.pricing_points.$each[i].trial_price.$invalid ||
            this.$v.model.pricing_points.$each[i].trial_period.$invalid
          ) {
            if (!this.errorTabs.includes('pricing-step')) {
              this.errorTabs.push('pricing-step');
            }
          }
        }

        for (let i = 0; i < this.model.paypal_processing_accounts.length; i++) {
          if (this.$v.model.paypal_processing_accounts.$each[i].$invalid) {
            if (!this.errorTabs.includes('gateways-step')) {
              this.errorTabs.push('gateways-step');
            }
          }
        }

        if (!this.errorTabs.includes('gateways-step')) {
          for (
            let i = 0;
            i < this.model.credit_card_processing_accounts.length;
            i++
          ) {
            if (
              this.$v.model.credit_card_processing_accounts.$each[i].$invalid
            ) {
              if (!this.errorTabs.includes('gateways-step')) {
                this.errorTabs.push('gateways-step');
              }
            }
          }
        }

        if (
          this.$v.model.sales_page.$invalid ||
          this.$v.model.refund_period.$invalid ||
          this.$v.model.waiting_period.$invalid ||
          this.$v.model.support_info_value.$invalid
        ) {
          this.errorTabs.push('contents-step');
        }

        if (!this.errorTabs.includes('contents-step')) {
          for (let i = 0; i < this.model.pricing_points.length; i++) {
            if (
              this.$v.model.pricing_points.$each[i].post_checkout_details
                .$invalid
            ) {
              if (!this.errorTabs.includes('contents-step')) {
                this.errorTabs.push('contents-step');
              }
            }
          }
        }

        for (let i = 0; i < this.model.pricing_points.length; i++) {
          if (
            this.$v.model.pricing_points.$each[i].offer_bump_options.$invalid
          ) {
            if (!this.errorTabs.includes('bumps-step')) {
              this.errorTabs.push('bumps-step');
            }
          }
          if (
            this.$v.model.pricing_points.$each[i].post_sale_order_bump_options
              .$invalid
          ) {
            if (!this.errorTabs.includes('bumps-step')) {
              this.errorTabs.push('bumps-step');
            }
          }
        }

        for (let i = 0; i < this.model.pricing_points.length; i++) {
          if (
            this.$v.model.pricing_points.$each[i].post_transaction_details
              .$invalid
          ) {
            if (!this.errorTabs.includes('funnel-step')) {
              this.errorTabs.push('funnel-step');
            }
          }
        }

        for (let i = 0; i < this.model.pricing_points.length; i++) {
          if (
            this.$v.model.pricing_points.$each[i].post_sale_details.$invalid
          ) {
            if (!this.errorTabs.includes('fullfillment-step')) {
              this.errorTabs.push('fullfillment-step');
            }
          }
        }

        if (this.$v.model.jv_page.$invalid) {
          this.errorTabs.push('affiliates-step');
        }

        if (!this.errorTabs.includes('affiliates-step')) {
          for (let i = 0; i < this.model.pricing_points.length; i++) {
            if (
              this.$v.model.pricing_points.$each[i].commission_details.$invalid
            ) {
              if (!this.errorTabs.includes('affiliates-step')) {
                this.errorTabs.push('affiliates-step');
              }
            }
          }
        }

        if (!this.errorTabs.includes('affiliates-step')) {
          for (let i = 0; i < this.model.pricing_points.length; i++) {
            for (
              let j = 0;
              j < this.model.pricing_points[i].commission_details;
              j++
            ) {
              if (
                this.$v.model.pricing_points.$each[i].commission_details.$each[
                  j
                ].name.$invalid
              ) {
                if (!this.errorTabs.includes('affiliates-step')) {
                  this.errorTabs.push('affiliates-step');
                }
              }
            }
          }
        }

        if (!this.errorTabs.includes('affiliates-step')) {
          for (let i = 0; i < this.model.affiliate_landing_pages.length; i++) {
            if (this.$v.model.affiliate_landing_pages.$each[i].$invalid) {
              if (!this.errorTabs.includes('affiliates-step')) {
                this.errorTabs.push('affiliates-step');
              }
            }
          }
        }
        return false;
      }
      return true;
    },

    async exitWizardForCompletedProducts(justSave = false) {
      this.$refs[this.currentTab].validate();
      const validationFlag = await this.validateAllTabs();


      if (validationFlag) {
        await this.saveAllInfo();

        if (!justSave) {
          this.$router.push({
            name: 'products',
            params: { id: this.funnel.id },
          });
        } 
      }
    },

    async saveAndExitWizard() {
      await this.saveAllInfo();
      this.$router.push({ name: 'products', params: { id: this.funnel.id } });
    },

    async saveBasicProductInfo() {

      this.$loader.start('saveBasicProductInfo');
      this.product.funnel_id = this.funnel.id;
      this.product.name = this.model.name;
      this.product.commission_after_processing =
        this.model.commission_after_processing;
      this.product.internal_name = this.model.internal_name;
      this.product.description = this.model.description;
      this.product.type = this.model.type;
      this.product.refund_period = this.model.refund_period;
      this.product.product_customization_details =
        this.model.product_customization_details;
      this.product.password_options = this.model.password_options;
      this.product.disable_branding_bar = this.model.disable_branding_bar;
      this.product.is_support_enabled = this.model.is_support_enabled;
      this.product.support_info_type = this.model.support_info_type;
      this.product.support_info_value = this.model.support_info_value;
      this.product.waiting_period = this.model.waiting_period;
      this.product.sales_page = this.model.sales_page;
      this.product.jv_page = this.model.jv_page;
      this.product.credit_card_processing_accounts =
        this.model.credit_card_processing_accounts;
      this.product.paypal_risk_percentage = this.model.paypal_risk_percentage;
      this.product.paypal_processing_accounts =
        this.model.paypal_processing_accounts;
      this.product.affiliate_landing_pages = this.model.affiliate_landing_pages;
      this.product.affiliate_registration_integrations =
        this.model.affiliate_registration_integrations;
      this.product.checkout_page_options = this.model.checkout_page_options;
      this.product.affiliates_enabled = this.model.affiliates_enabled;
      this.product.is_wizard_complete = this.model.is_wizard_complete;
      this.product.affiliate_data_share_details =
        this.model.affiliate_data_share_details;
      const savedProductData = await this.product.save();

      if (!this.edit_product) {
        this.product = await this.funnel
          .products()
          .$find(savedProductData.data.id);
      }

      this.model.funnel_id = this.product.funnel_id;
      this.model.name = this.product.name;
      this.model.internal_name = this.product.internal_name;
      this.model.description = this.product.description;
      this.model.type = this.product.type;
      this.model.refund_period = this.product.refund_period;
      this.model.is_support_enabled = this.product.is_support_enabled;
      this.model.support_info_type = this.product.support_info_type;
      this.model.support_info_value = this.product.support_info_value;
      this.model.waiting_period = this.product.waiting_period;
      this.model.tracking_link = this.product.tracking_link;
      this.model.sales_page = this.product.sales_page;
      this.model.jv_page = this.product.jv_page;
      this.model.product_customization_details =
        this.product.product_customization_details;
      this.model.password_options = this.product.password_options;
      this.model.disable_branding_bar = this.product.disable_branding_bar;
      this.model.affiliate_data_share_details =
        this.product.affiliate_data_share_details;

      if (!this.edit_product || this.price_points.length == 0) {
        const funnelProducts = await this.funnel.products().$get();
        const productPricePoints = funnelProducts[0].price_points;
        if (funnelProducts[0].price_points.length > 0) {
          var seletectPricePoint = productPricePoints[0].id;
        } else {
          var seletectPricePoint = null;
          const productPricePoints = null;
        }
        const commision_id = Math.ceil(Math.random() * 10000);

        this.pricePoint = new PricePoint({
          type: 1,
          name: `${this.product.name} #1`,
          internal_name: '',
          description: '',
          price: 1,
          recurring_interval: 1,
          number_of_installments: 1,
          recurrence_interval: 1,
          is_trial: 0,
          trial_price: 0.0,
          trial_period: 0,
          trial_period_type: 1,
          salespage_type: 1,
          salespage_details: {
            sales_page: '',
          },
          funnelProducts,
          productPricePoint: productPricePoints,
          postSaleProductPricePoint: productPricePoints,
          maximum_quantity: 5,
          minimum_quantity: 1,
          quantity_enable: 0,
          offer_bump_enable: 0,
          offer_bump_options: [],
          post_sale_order_bump_options: {
            post_order_heading_line: null,
            post_order_subheading: null,
            post_order_description: null,
            post_order_video_details: {
              post_order_show_video: 0,
              url: '',
              id: null,
              provider: '',
              permalink: null,
            },
            post_order_bump_enable: 0,
            post_order_display_thumbnail: 0,
            post_order_thumbnail_image: '',
            post_order_is_square: 0,
            post_order_thumbnail_size: 'md',
            post_order_style_id: 1,
            post_order_product_id: null,
            post_order_price_point_id: null,
          },
          post_checkout_type: 1,
          post_checkout_details: {
            thankyou_page: '',
          },
          post_transaction_details: {
            type: 1,
            post_transaction_url: '',
          },
          post_sale_notification_type: 1,
          post_sale_details: null,
          post_sale_integrations: null,
          memberships: null,
          file_memberships: null,
          legal_options: {
            enabled: 0,
            html: '',
          },
          abandon_cart_integrations: null,
          affiliate_registration_integrations: null,
          customer_portal_details: null,
          content_access_type: 0,
          content_access_options: {},
          advanced_tracking_code_enabled: 0,
          advanced_tracking_code_details: {
            tracking_script: '',
          },
          pricing_string: 'One-time payment of $1.00',
          commission_type: 1,
          commission_details: [
            {
              id: commision_id,
              type: 1,
              name: 'Default',
              assigned_to: null,
              sales_commission: {
                commission: 0,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
              trial_commission: {
                commission: 0,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
              rebill_commissions: [
                {
                  commission: 0,
                  no_of_payments: null,
                  commission_type: 'percent',
                  jv_broker_commission: 0,
                  jv_broker_commission_type: 'percent',
                },
              ],
            },
          ],
          is_default: 1,
        }).for(this.product);

        const savedProductData = await this.pricePoint.save();
        this.pricePoint = await this.product
          .pricepoints()
          .$find(savedProductData.data.id);
        this.product.price_points.push(this.pricePoint);
        const postVideoDetails = {
          post_order_show_video: 0,
          url: '',
          id: null,
          provider: '',
          permalink: null,
        };
        this.model.pricing_points.push({
          id: this.pricePoint.id,
          type: 1,
          name: `${this.product.name} #1`,
          internal_name: '',
          description: '',
          price: 1,
          recurring_interval: 1,
          number_of_installments: 1,
          recurrence_interval: 1,
          is_trial: 0,
          trial_price: 0.0,
          trial_period: 0,
          trial_period_type: 1,
          salespage_type: 1,
          salespage_details: {
            sales_page: '',
          },
          funnelProducts,
          productPricePoint: productPricePoints,
          postSaleProductPricePoint: productPricePoints,
          maximum_quantity: this.pricePoint.maximum_quantity
            ? this.pricePoint.maximum_quantity
            : 1,
          minimum_quantity: this.pricePoint.minimum_quantity
            ? this.pricePoint.minimum_quantity
            : 5,
          quantity_enable: this.pricePoint.quantity_enable,
          offer_bump_enable: this.pricePoint.offer_bump_enable,
          offer_bump_options: this.pricePoint.offer_bump_options,
          post_sale_order_bump_options: {
            post_order_heading_line:
              this.pricePoint.post_sale_order_bump_options
                .post_order_heading_line,
            post_order_subheading:
              this.pricePoint.post_sale_order_bump_options
                .post_order_subheading,
            post_order_description:
              this.pricePoint.post_sale_order_bump_options
                .post_order_description,

            post_order_video_details:
              this.pricePoint.post_sale_order_bump_options.hasOwnProperty(
                'post_order_video_details'
              )
                ? this.pricePoint.post_sale_order_bump_options
                  .post_order_video_details
                : postVideoDetails,

            post_order_bump_enable:
              this.pricePoint.post_sale_order_bump_options
                .post_order_bump_enable,
            post_order_display_thumbnail:
              this.pricePoint.post_sale_order_bump_options
                .post_order_display_thumbnail,
            post_order_thumbnail_image:
              this.pricePoint.post_sale_order_bump_options
                .post_order_thumbnail_image,
            post_order_is_square:
              this.pricePoint.post_sale_order_bump_options.post_order_is_square,
            post_order_thumbnail_size:
              this.pricePoint.post_sale_order_bump_options
                .post_order_thumbnail_size,
            post_order_style_id:
              this.pricePoint.post_sale_order_bump_options.post_order_style_id,
            post_order_product_id:
              this.pricePoint.post_sale_order_bump_options
                .post_order_product_id,
            post_order_price_point_id:
              this.pricePoint.post_sale_order_bump_options
                .post_order_price_point_id,
          },
          post_checkout_type: 1,
          post_checkout_details: {
            thankyou_page: '',
          },
          post_transaction_details: {
            type: 1,
            post_transaction_url: '',
          },
          post_sale_notification_type: 1,
          post_sale_details: null,
          post_sale_integrations: null,
          memberships: null,
          file_memberships: null,
          legal_options: {
            enabled: 0,
            html: '',
          },
          abandon_cart_integrations: null,
          affiliate_registration_integrations: null,
          customer_portal_details: null,
          content_access_type: 0,
          content_access_options: {},
          advanced_tracking_code_enabled: 0,
          facebook_conversion_api_enabled: 0,
          pixel_id: 0,
          advanced_tracking_code_details: {
            tracking_script: '',
          },
          pricing_string: 'One-time payment of $1.00',
          currency_symbol: this.pricePoint.currency_symbol,
          currency_code: this.pricePoint.currency_code,
          commission_type: 1,
          commission_details: [
            {
              id: commision_id,
              type: 1,
              name: 'Default',
              assigned_to: null,
              sales_commission: {
                commission: 0,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
              trial_commission: {
                commission: 0,
                commission_type: 'percent',
                jv_broker_commission: 0,
                jv_broker_commission_type: 'percent',
              },
              rebill_commissions: [
                {
                  commission: 0,
                  no_of_payments: null,
                  commission_type: 'percent',
                  jv_broker_commission: 0,
                  jv_broker_commission_type: 'percent',
                },
              ],
            },
          ],
          is_default: 1,
        });

        this.price_points.push(this.pricePoint);
      }

      this.edit_product = true;

      this.model.affiliates_enabled = this.product.affiliates_enabled;
      this.model.is_wizard_complete = this.product.is_wizard_complete;

      if (
        this.model.affiliate_landing_pages.length == 0 &&
        this.model.sales_page != ''
      ) {
        this.model.affiliate_landing_pages.push({
          id: this.generateNumber(),
          landing_page: this.model.sales_page,
          description: this.$t('frontend_groovesell_product_index_page'),
          assigned_to: [{ id: 0, name: this.$t('frontend_product_step9_all_affiliates') }],
        });
      }

      this.model.is_main = this.product.is_main;
      this.$loader.end('saveBasicProductInfo');
      return true;
    },

    async saveAllInfo() {
      this.$loader.start('saveAllInfo');
      await this.saveProductInfo();
      await this.savePricePointsInfo();
      if (
        this.model.affiliate_landing_pages.length == 0 &&
        this.model.sales_page != ''
      ) {
        this.model.affiliate_landing_pages.push({
          id: this.generateNumber(),
          landing_page: this.model.sales_page,
          description: this.$t('frontend_groovesell_product_index_page'),
          assigned_to: [{ id: 0, name: this.$t('frontend_product_step9_all_affiliates') }],
        });
      }

      this.$loader.end('saveAllInfo');
      return true;
    },

    generateNumber() {
      const min = 1;
      const max = 10000;
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    async saveProductInfo() {
      this.$loader.start('saveProductInfo');
      this.product.funnel_id = this.funnel.id;
      this.product.name = this.model.name;
      this.product.commission_after_processing =
        this.model.commission_after_processing;
      this.product.internal_name = this.model.internal_name;
      this.product.description = this.model.description;
      this.product.type = this.model.type;
      this.product.refund_period = this.model.refund_period;
      this.product.is_support_enabled = this.model.is_support_enabled;
      this.product.support_info_type = this.model.support_info_type;
      this.product.support_info_value = this.model.support_info_value;
      this.product.product_customization_details =
        this.model.product_customization_details;
      this.product.password_options = this.model.password_options;
      this.product.disable_branding_bar = this.model.disable_branding_bar;
      this.product.waiting_period = this.model.waiting_period;
      this.product.sales_page = this.model.sales_page;
      this.product.jv_page = this.model.jv_page;
      this.product.paypal_risk_percentage = this.model.paypal_risk_percentage;
      this.product.credit_card_processing_accounts =
        this.model.credit_card_processing_accounts;
      this.product.paypal_processing_accounts =
        this.model.paypal_processing_accounts;
      this.product.checkout_page_options = this.model.checkout_page_options;

      if (
        this.product.checkout_page_options.look_and_feel.template_type !=
        undefined &&
        this.product.checkout_page_options.look_and_feel.template_type == 4
      ) {
        this.product.checkout_page_options.look_and_feel = {
          template_type:
            this.product.checkout_page_options.look_and_feel.template_type,
          custom_color: {
            primary_color: this.model.primary_color
              ? this.model.primary_color
              : '#ffcc24',
            secondary_color: this.model.secondary_color
              ? this.model.secondary_color
              : '#033a7c',
          },
        };
      } else {
        this.product.checkout_page_options.look_and_feel = {
          template_type:
            this.product.checkout_page_options.look_and_feel.template_type,
        };
      }

      this.product.affiliate_registration_integrations =
        this.model.affiliate_registration_integrations;
      this.product.affiliate_landing_pages = this.model.affiliate_landing_pages;
      this.product.affiliates_enabled = this.model.affiliates_enabled;
      this.product.is_wizard_complete = this.model.is_wizard_complete;
      this.product.affiliate_data_share_details =
        this.model.affiliate_data_share_details;

      if (this.product.price_points.length > 0) {
        for (let i = 0; i < this.model.pricing_points.length; i++) {
          this.product.price_points[i].minimum_quantity = this.model
            .pricing_points[i].minimum_quantity
            ? this.model.pricing_points[i].minimum_quantity
            : 2;
          this.product.price_points[i].maximum_quantity = this.model
            .pricing_points[i].maximum_quantity
            ? this.model.pricing_points[i].maximum_quantity
            : 2;
          this.product.price_points[i].quantity_enable =
            this.model.pricing_points[i].quantity_enable;
          this.product.price_points[i].offer_bump_enable =
            this.model.pricing_points[i].offer_bump_enable;

          this.product.price_points[i].offer_bump_options =
            this.model.pricing_points[i].offer_bump_options;

          //
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_heading_line =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_heading_line;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_subheading =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_subheading;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_description =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_description;

          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_video_details =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_video_details;

          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_bump_enable =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_bump_enable;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_display_thumbnail =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_display_thumbnail;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_thumbnail_image =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_thumbnail_image;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_is_square =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_is_square;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_thumbnail_size =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_thumbnail_size;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_style_id =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_style_id;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_product_id =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_product_id;
          this.product.price_points[
            i
          ].post_sale_order_bump_options.post_order_price_point_id =
            this.model.pricing_points[
              i
            ].post_sale_order_bump_options.post_order_price_point_id;
        }
      }

      await this.product.save();

      this.model.funnel_id = this.product.funnel_id;
      this.model.name = this.product.name;
      this.model.internal_name = this.product.internal_name;
      this.model.description = this.product.description;
      this.model.type = this.product.type;
      this.model.refund_period = this.product.refund_period;
      this.model.is_support_enabled = this.product.is_support_enabled;
      this.model.support_info_type = this.product.support_info_type;
      this.model.support_info_value = this.product.support_info_value;
      this.model.waiting_period = this.product.waiting_period;
      this.model.sales_page = this.product.sales_page;
      this.model.jv_page = this.product.jv_page;
      this.model.product_customization_details =
        this.product.product_customization_details;
      this.model.password_options = this.product.password_options;
      this.model.disable_branding_bar = this.product.disable_branding_bar;
      this.model.affiliates_enabled = this.product.affiliates_enabled;
      this.model.is_wizard_complete = this.product.is_wizard_complete;
      this.model.affiliate_landing_pages = this.product.affiliate_landing_pages;
      this.model.affiliate_data_share_details =
        this.product.affiliate_data_share_details;

      if (
        this.model.affiliate_landing_pages.length == 0 &&
        this.model.sales_page != ''
      ) {
        this.model.affiliate_landing_pages.push({
          id: this.generateNumber(),
          landing_page: this.model.sales_page,
          description: this.$t('frontend_groovesell_product_index_page'),
          assigned_to: [{ id: 0, name: this.$t('frontend_product_step9_all_affiliates') }],
        });
      }
      this.model.is_main = this.product.is_main;
      this.$loader.end('saveProductInfo');
    },

    async savePricePointsInfo() {
      this.$loader.start('savePricePointsInfo');
      const promises = [];

      for (let i = 0; i < this.price_points.length; i++) {

        let pixelId = this.model.pricing_points[i].pixel_id;
        if (this.model.pricing_points[i].facebook_conversion_api_enabled == 0) {
          pixelId = 0;
        }

        this.price_points[i].offer_bump_enable =
          this.model.pricing_points[i].offer_bump_enable;
        this.price_points[i].offer_bump_options =
          this.model.pricing_points[i].offer_bump_options;
        this.price_points[i].quantity_enable =
          this.model.pricing_points[i].quantity_enable;
        this.price_points[i].maximum_quantity =
          this.model.pricing_points[i].maximum_quantity;
        this.price_points[i].minimum_quantity =
          this.model.pricing_points[i].minimum_quantity;
        this.price_points[i].type = this.model.pricing_points[i].type;
        this.price_points[i].name = this.model.pricing_points[i].name;
        this.price_points[i].internal_name =
          this.model.pricing_points[i].internal_name;
        this.price_points[i].description =
          this.model.pricing_points[i].description;
        this.price_points[i].price = this.model.pricing_points[i].price;
        this.price_points[i].recurring_interval =
          this.model.pricing_points[i].recurring_interval;
        this.price_points[i].recurrence_interval =
          this.model.pricing_points[i].recurrence_interval;
        this.price_points[i].number_of_installments =
          this.model.pricing_points[i].number_of_installments;
        this.price_points[i].is_trial = this.model.pricing_points[i].is_trial;
        this.price_points[i].trial_price =
          this.model.pricing_points[i].trial_price;
        this.price_points[i].trial_period =
          this.model.pricing_points[i].trial_period;
        this.price_points[i].trial_period_type =
          this.model.pricing_points[i].trial_period_type;
        this.price_points[i].salespage_type =
          this.model.pricing_points[i].salespage_type;
        this.price_points[i].salespage_details =
          this.model.pricing_points[i].salespage_details;
        this.price_points[i].post_checkout_type =
          this.model.pricing_points[i].post_checkout_type;
        this.price_points[i].post_checkout_details =
          this.model.pricing_points[i].post_checkout_details;
        this.price_points[i].post_transaction_details =
          this.model.pricing_points[i].post_transaction_details;
        this.price_points[i].post_sale_notification_type =
          this.model.pricing_points[i].post_sale_notification_type;
        this.price_points[i].post_sale_details =
          this.model.pricing_points[i].post_sale_details;

        this.price_points[i].post_sale_integrations =
          this.model.pricing_points[i].post_sale_integrations;
        this.price_points[i].memberships =
          this.model.pricing_points[i].memberships;
        this.price_points[i].file_memberships =
          this.model.pricing_points[i].file_memberships;
        this.price_points[i].legal_options =
          this.model.pricing_points[i].legal_options;
        this.price_points[i].abandon_cart_integrations =
          this.model.pricing_points[i].abandon_cart_integrations;
        this.price_points[i].affiliate_registration_integrations =
          this.model.pricing_points[i].affiliate_registration_integrations;
        this.price_points[i].customer_portal_details =
          this.model.pricing_points[i].customer_portal_details;

        this.price_points[i].content_access_type =
          this.model.pricing_points[i].content_access_type;
        this.price_points[i].content_access_options =
          this.model.pricing_points[i].content_access_options;
        this.price_points[i].advanced_tracking_code_enabled =
          this.model.pricing_points[i].advanced_tracking_code_enabled;


        this.price_points[i].facebook_conversion_api_enabled =
          this.model.pricing_points[i].facebook_conversion_api_enabled;

        this.price_points[i].pixel_id = pixelId;

        this.price_points[i].advanced_tracking_code_details =
          this.model.pricing_points[i].advanced_tracking_code_details;

        this.price_points[i].pricing_string =
          this.model.pricing_points[i].pricing_string;

        this.price_points[i].commission_type =
          this.model.pricing_points[i].commission_type;
        this.price_points[i].commission_details =
          this.model.pricing_points[i].commission_details;
        this.price_points[i].is_default =
          this.model.pricing_points[i].is_default;
        promises.push(this.price_points[i].save());
      }

      if (
        this.model.affiliate_landing_pages.length == 0 &&
        this.model.sales_page != ''
      ) {
        this.model.affiliate_landing_pages.push({
          id: this.generateNumber(),
          landing_page: this.model.sales_page,
          description: this.$t('frontend_groovesell_product_index_page'),
          assigned_to: [{ id: 0, name: this.$t('frontend_product_step9_all_affiliates') }],
        });
      }

      const self = this;

      await Promise.all(promises).then((values) => {
        self.$loader.end('savePricePointsInfo');
      });

      return true;
    },
    validateStep(name) {
      const refToValidate = this.$refs[name];
      return refToValidate.validate();
    },

    async stepValidationSuccessful(name, isValid) {
    
      if (isValid && this.saveTabsAfterValidation) {
        if (name == 'info-step') {
          await this.saveBasicProductInfo();
        } else {
          if (name == 'proof-step' && this.model.is_wizard_complete == 0) {
            this.model.is_wizard_complete = 1;
          }
          await this.saveAllInfo();
        }

        if (this.changeTabsAfterValidation) {
          this.currentTab = this.postValidationTab;
        }
      }

      this.saveTabsAfterValidation = 1;
    },
  },
};
</script>

<style>
.gs-default-tabs-item:focus {
  outline: none !important;
  outline-style: none !important;
}

.tabHeadingWithError {
  color: var(--brand-warning) !important;
  border-color: var(--brand-warning) !important;
}
.invalid .ql-toolbar.ql-snow {
  border-bottom-color: var(--brand-danger);
}
.invalid .ql-container {
  border-color: var(--brand-danger) !important;
}
</style>
