<template>
  <gd-modal
    name="advanced-filters-modal"
    :title="$t('frontend_groovesell_modal_advanced_filters')"
    height="80%"
    width="80%"
    allowOverflow
    bg-type="regular"
  >
    <template slot="actions">
      <div class="justify-end">
        <gd-button
          size="md"
          variant="primary"
          @click="applyAdvancedFilters"
        >
          {{ $t('frontend_apply_filter') }}
        </gd-button>
      </div>
    </template>
    <div class="flex flex-col m-6 mb-4">
      <div class="flex flex-col w-full mr-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_funnels') }}
        </label>
        <treeselect
          v-model="localModel.filteredFunnels"
          :multiple="true"
          :options="funnelsDropdown"
          :placeholder="$t('frontend_groovesell_funnels_filter')"
          @select="updateModel"
          @deselect="updateModel"
        />
      </div>
      <div class="flex flex-col w-full mr-2 mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_affiliates') }}
        </label>
        <div class="w-full flex flex-row">
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 1,
                'border-grey-200': filteredAffiliatesType !== 1
              }"
              @click="updateFilteredAffiliatesType(1)"
              @keydown.space="updateFilteredAffiliatesType(1)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio':filteredAffiliatesType === 1,
                    'border-grey': filteredAffiliatesType !== 1
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 1,
                      'text-grey-700': filteredAffiliatesType !== 1
                    }"
                  >
                    {{ $t('frontend_all') }}
                  </label>
                  <div class="text-grey-500">{{ $t('frontend_groovesell_customers_all_transactions') }}</div>
                </div>
              </label>
            </div>
          </div>
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 2,
                'border-grey-200': filteredAffiliatesType !== 2
              }"
              @click="updateFilteredAffiliatesType(2)"
              @keydown.space="updateFilteredAffiliatesType(2)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 2,
                    'border-grey': filteredAffiliatesType !== 2
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 2,
                      'text-grey-700': filteredAffiliatesType !== 2
                    }"
                  >
                    {{ $t('frontend_groovesell_customers_only_affiliates') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_groovesell_customers_only_the_sales') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 3,
                'border-grey-200': filteredAffiliatesType !== 3
              }"
              @click="updateFilteredAffiliatesType(3)"
              @keydown.space="updateFilteredAffiliatesType(3)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 3,
                    'border-grey': filteredAffiliatesType !== 3
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 3,
                      'text-grey-700': filteredAffiliatesType !== 3
                    }"
                  >
                    {{ $t('frontend_groovesell_customers_only_non_affiliates') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_groovesell_customers_only_sales_made_by') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="flex w-1/4 mr-4">
            <div
              class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2"
              :class="{
                'border-primary': filteredAffiliatesType === 4,
                'border-grey-200': filteredAffiliatesType !== 4
              }"
              @click="updateFilteredAffiliatesType(4)"
              @keydown.space="updateFilteredAffiliatesType(4)"
            >
              <label class="flex cursor-pointer">
                <span
                  class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border"
                  :class="{
                    'border-grey bg-primary text-primary activeOptionRadio': filteredAffiliatesType === 4,
                    'border-grey': filteredAffiliatesType !== 4
                  }"
                ></span>
                <div class="ml-2 flex flex-col w-full">
                  <label
                    class="font-semibold"
                    :class="{
                      'text-primary': filteredAffiliatesType === 4,
                      'text-grey-700': filteredAffiliatesType !== 4
                    }"
                  >
                    {{ $t('frontend_groovesell_customers_specific_aff') }}
                  </label>
                  <div class="text-grey-500">
                    {{ $t('frontend_groovesell_customers_only_the_sales') }}
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div v-if="filteredAffiliatesType == 4" class="w-1/2 mb-4">
          <gd-button
            size="auto"
            variant="primary"
            class="mt-3"
            @click="openFilteredAffiliatesModal"
          >
            {{ localModel.filteredAffiliates.length }} {{ $t('frontend_groovesell_customers_affiliates_selected') }}
          </gd-button>
        </div>
      </div>
      <div class="flex flex-col w-full mr-2 mt-2">
        <label class="font-semibold text-sm text-grey-700 mb-2">
          {{ $t('frontend_groovesell_customers_advance_transaction') }}
        </label>
        <gd-dropdown
          v-model="localModel.filteredAdvancedTransactions"
          size="full"
          class="mb-10"
          :placeholder="$t('frontend_groovesell_customers_advance_transaction')"
          :options="filteredTransactionOptions"
          @change="updateModel"
        />
      </div>
    </div>
  </gd-modal>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Treeselect,
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    funnelsDropdown: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  computed: {
    filteredAffiliatesType() {
      const assignedTo = this.localModel.filteredAffiliates;

      if (assignedTo.length == 0) {
        return 4;
      }

      const firstAffiliateId = assignedTo[0].id;

      if (firstAffiliateId == 0) {
        return 1;
      } else if (firstAffiliateId == -1) {
        return 3;
      } else if (firstAffiliateId == -2) {
        return 2;
      }

      return 4;
    },
    filteredTransactionOptions() {
      return [
        { value: 0, text: this.$t('frontend_all') },
        { value: 1, text: this.$t('frontend_groovesell_customers_first_sale_or') },
        { value: 2, text: this.$t('frontend_groovesell_customers_first_sale_after_trail') },
        { value: 3, text: this.$t('frontend_groovesell_customers_rebills_only') }
      ];
    },
  },
  methods: {
    updateModel() {
      this.$emit('updateModel', this.localModel);
    },
    updateFilteredAffiliatesType(type) {
      const affiliations = {
        1: [{ id: 0, name: 'All' }],
        2: [{ id: -2, name: 'Only Affiliates' }],
        3: [{ id: -1, name: 'Only Non-Affiliates' }],
        default: [],
      };

      if (this.filteredAffiliatesType != type) {
        this.localModel.filteredAffiliates = affiliations[type] || affiliations.default;
      }

      this.updateModel();
    },
    applyAdvancedFilters() {
      this.$root.$emit('modal-close', 'advanced-filters-modal');
      this.$emit('applyFilters');
    },
  },
};
</script>
