<template>
  <div class="w-full min-h-full border bg-white border-grey-200 px-4 py-8">
    <v-wait for="tools">
      <div slot="waiting">
        <div class="mt-4 text-lg text-grey-700 font-medium text-center w-full">
          {{ $t('frontend_resource_promo_connecting', {appTitle: whitelabelAppData('sell').title}) }}
        </div>
      </div>

      <div v-if="storeUser || isUserLoggedinChecker">
        <promo-tools-post-login
          :funnel-id="funnelId"
          @userLoggedOut="userLoggedOut"
        />
      </div>

      <div v-else class="flex flex-col">
        <login-signup-tabs
          :funnel-id="funnelId"
          :signup-form="signupForm"
          :header-text="headerText"
          :login-tabs="loginTabs"
          @userLoggedIn="userLoggedIn"
        />
      </div>

      <div
        v-if="!disableBranding"
        class="flex flex-row justify-end text-xs mt-2"
      >
        {{ $t('frontend_resource_promo_poweredby') }}
        <span class="ml-1 font-bold">{{ this.brandName }}</span>
      </div>
    </v-wait>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '@groovepages/gd-lib/auth';
import Cookies from 'js-cookie';
import PromoToolsPostLogin from './promo_tools_post_login';
import LoginSignupTabs from './promo_tools_partials/LoginSignupTabs.vue';

export default {
  layout: 'iframe',
  metaInfo() {
    return { title: this.$t('frontend_promo_tools') };
  },
  components: {
    PromoToolsPostLogin,
    LoginSignupTabs
  },
  data() {
    return {
      funnelId: 0,
      disableBranding: false,
      signupForm: {
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        funnel_id: 0,
      },
      isUserLoggedinChecker: 0,
      isLoggedOut: 0,
    };
  },
  computed: {
    brandName() {
      return this.$store.state.brandData.brandName;
    },
    headerText: {
      get() {
        return this.$t('frontend_resource_promo_login_to', {appTitle: this.whitelabelAppData('sell').title});
      },
      set() {},
    },
    loginTabs: {
      get() {
        return [
          {
            title: this.$t('frontend_resource_promo_login_to', {appTitle: this.whitelabelAppData('sell').title}),
            value: 'login'
          },
          {
            title: this.$t('frontend_resource_promo_create_your', {appTitle: this.whitelabelAppData('sell').title}),
            value: 'signup',
          },
        ];
      },
      set() {},
    },
    storeUser() {
      if (this.isLoggedOut) {
        return null;
      }
      return auth.authenticatedUser('promo_tools');
    },
  },
  watch: {
    storeUser(newStoreUser) {
      if (newStoreUser) {
      } else {
        this.isUserLoggedinChecker = 0;
      }
    },
  },
  async created() {
    this.$wait.start('tools');

    const { data } = await axios.get(
      `get-promo-tools-funnel-id/${this.$route.params.resource_id}`
    );

    if (data.status == 'success') {
      this.funnelId = data.funnel_id;
      this.signupForm.funnel_id = data.funnel_id;
      this.headerText = data.header_text;
      this.loginTabs[0].title = data.login_text;
      this.loginTabs[1].title = data.signup_text;
      this.disableBranding = data.disable_branding;
    }

    if (auth.authenticatedUser()) {
      this.userLoggedIn();
    }

    this.$wait.end('tools');
  },
  methods: {
    userLoggedOut() {
      this.isUserLoggedinChecker = 0;
      this.isLoggedOut = 1;
      auth.logout();
    },
    userLoggedIn() {
      this.isUserLoggedinChecker = 1;
      this.isLoggedOut = 0;
    },
  },
};
</script>
