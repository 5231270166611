<template>
  <div class="flex flex-col mt-4">
    <div class="w-full flex flex-col mr-4 mb-4 bg-white">
      <label class="flex flex-col cursor-pointer leading-5 text-black">
        <div class="flex justify-between bg-primary text-white">
          <div class="flex items-center px-4 py-2">
            <gd-checkbox variant="primary" v-model="previewOrderBumps" />
            <div v-if="heading != null" class="text-base ml-2">
              {{ heading }}
            </div>
            <div v-else class="text-base ml-2">{{ $t('frontend_heading') }}</div>
          </div>
          <div
            v-if="price != null"
            class="
              w-40
              flex
              items-center
              justify-center
              text-lg
              font-bold
              bg-primary-dark
              px-4
              py-2
            "
          >
            {{ price }}
          </div>
          <div
            v-else
            class="
              w-40
              flex
              items-center
              justify-center
              text-lg
              font-bold
              bg-primary-dark
              px-4
              py-2
            "
          >
            $00.00
          </div>
        </div>
        <div
          v-if="description != null"
          v-html="description"
          class="text-black text-base border border-primary p-4"
        ></div>
        <div v-else class="text-black text-base border border-primary p-4">
          {{ $t('frontend_desc') }}
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import mixins from '@groovepages/gd-ui-kit/mixins';
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'Style4',
  props: {
    heading: String,
    description: String,
    arrow: {
      default: 0,
    },
    thumbnail: {
      default: 0,
    },
    selectedPricepoint: {
      default: null,
    },
    selectedProduct: {
      default: null,
    },
    allProducts: {
      default: null,
    },
    allPricepoints: {
      default: null,
    },
  },
  data() {
    return {
      previewOrderBumps: false,
    };
  },

  methods: {},
};
</script>

<style>
.error-form-group {
  border-color: var(--brand-primary-dark) !important;
}
</style>