<template>
  <div>
    <div v-if="hasThankYouPageAds" class="flex flex-col">
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-tabs
          :tabs="thankyouPageAdsTabs"
          :current-tab="localThankyouPageAdsTab"
          :error-tabs="thankyouPageAdsErrorTabs"
          wrapper-class="pt-4 px-0 gs-default-tabs"
          tab-class="pb-4 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line"
          @onClick="handleThankyouPageAdsTabChange"
          :enable-dragging="true"
          @updateTabOrder="handleThankyouPageTabOrderUpdate"
        />
        <gd-button
          size="auto"
          variant="primary"
          class="ml-4 mt-3"
          @click="createThankYouPageAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      <div class="mt-2">
        <div v-for="(ad, index) in localFunnelResource.thankyou_page_ads" :key="index">
          <div v-if="isThankYouPageAdTabActive(ad)" class="pb-8">
            <div class="flex flex-row">
              <div class="flex flex-col w-1/2 mb-6">
                <label class="font-semibold text-sm text-grey-700 mb-2">
                  {{ $t('frontend_groovesell_ad_name') }}
                </label>
                <text-input
                  v-model="ad.name"
                  :validator="$v.localFunnelResource.thankyou_page_ads.$each[index].name"
                  :attribute="$t('frontend_groovesell_ad_name')"
                  :placeholder="$t('frontend_resources_name')"
                />
              </div>
              <div class="flex flex-row justify-end w-1/2">
                <gd-toggle class="h-6 mt-2" v-model="ad.enabled" :value="ad.enabled" />
                <gd-button
                  variant="outline"
                  size="iconlg"
                  class="ml-4"
                  @click="openConfirmModal(index)"
                >
                  <trash-icon class="w-5 h-5 fill-current" />
                </gd-button>
              </div>
            </div>

            <div class="flex flex-col w-full mb-6">
              <div class="flex flex-row w-1/2">
                <div class="w-1/2">
                  <label class="font-semibold text-sm text-grey-700 mb-2">
                    {{ $t('frontend_resources_ad') }}
                  </label>
                </div>
                <div class="flex flex-row justify-end mb-2 w-1/2">
                  <div class="flex flex-row">
                    <div class="font-semibold text-sm text-grey-700 mr-2">
                      {{ $t('frontend_resources_view_html') }}
                    </div>
                    <gd-toggle v-model="isThankYouPageHTMLView" :value="isThankYouPageHTMLView" />
                  </div>
                </div>
              </div>
              <div class="flex flex-row w-full justify-between">
                <div class="w-1/2">
                  <text-area
                    v-if="isThankYouPageHTMLView"
                    v-model="ad.body"
                    class="swipesEditor w-full"
                    :placeholder="$('frontend_resources_ad')"
                    variant="dark"
                  />
                  <quill-editor
                    v-else
                    :ref="'thankyouPageAdEditor_' + index"
                    v-model="ad.body"
                    :options="adsEditorOptions"
                    class="swipesEditor w-full"
                  ></quill-editor>
                </div>
                <div class="flex flex-col">
                  <gd-button
                    size="auto"
                    variant="primary"
                    class="mb-4"
                    @click="addAffiliateNameToThankyouPageAd(index)"
                  >
                    {{ $t('frontend_resources_add_affiliate_name') }}
                  </gd-button>
                  <gd-dropdown
                    :placeholder="$t('frontend_resources_add_affiliate_link')"
                    :actions="affiliateLinksActions(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-row mb-2 border-grey-100 border -mx-4 -mt-4 px-4">
        <gd-button
          size="auto"
          variant="primary"
          class="mt-3 mb-3"
          @click="createThankYouPageAd"
        >
          {{ $t('frontend_resources_add_new') }}
        </gd-button>
      </div>
      {{ $t('frontend_resources_you_dont_have_thankyou_page') }}
    </div>
    <confirm-modal
      name="confirm-thankyou-page-delete-modal"
      :message-content="$t('frontend_non_revertable')"
      :button-text="$t('frontend_delete_confirm')"
      button-size="auto"
      @confirm="deleteThankYouPageAd"
    />
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { quillEditor } from 'vue-quill-editor';
import generateNumberMixin from '@/mixins/generateNumberMixin';
import Quill from 'quill';
import TrashIcon from '@/assets/images/icons/TrashIcon.svg';

export default {
  mixins: [generateNumberMixin],
  components: {
    quillEditor,
    TrashIcon,
  },
  props: {
    funnelResource: {
      type: Object,
      required: true,
    },
    thankyouPageAdsTab: {
      type: String,
      default: '',
    },
    adsEditorOptions: {
      type: Object,
      required: true,
    },
    landingPages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      thankyouPageAdId: null,
      localFunnelResource: this.funnelResource,
      localThankyouPageAdsTab: this.thankyouPageAdsTab,
      isThankYouPageHTMLView: false,
    };
  },
  computed: {
    hasThankYouPageAds() {
      return (
        this.localFunnelResource &&
        this.localFunnelResource.thankyou_page_ads &&
        this.localFunnelResource.thankyou_page_ads.length
      );
    },
    thankyouPageAdsTabs() {
      let thankyouPageAdsTabs = [];
      if (this.hasThankYouPageAds) {
        for (let i = 0; i < this.localFunnelResource.thankyou_page_ads.length; i++) {
          thankyouPageAdsTabs.push({
            title: this.localFunnelResource.thankyou_page_ads[i].name,
            value: `thankyou_page_ad_tab_${this.localFunnelResource.thankyou_page_ads[i].id}`,
          });
        }
      }
      return thankyouPageAdsTabs;
    },
    thankyouPageAdsErrorTabs() {
      let thankyouPageAdsErrorTabs = [];
      let thankyouPageAdsIndex;
      for (thankyouPageAdsIndex in this.$v.localFunnelResource.thankyou_page_ads.$each.$iter) {
        if (this.$v.localFunnelResource.thankyou_page_ads.$each.$iter[thankyouPageAdsIndex].$error) {
          thankyouPageAdsErrorTabs.push(
            `thankyou_page_ad_tab_${this.$v.localFunnelResource.thankyou_page_ads.$each.$iter[thankyouPageAdsIndex].$model.id}`
          );
        }
      }
      return thankyouPageAdsErrorTabs;
    },
  },
  methods: {
    affiliateLinksActions(index) {
      let affiliateLinksActions = [];

      if (this.landingPages.length > 0) {
        for (let i = 0; i < this.landingPages.length; i++) {
          affiliateLinksActions.push({
            text: this.landingPages[i].description,
            callback: () => this.addAffiliateLinkToThankyouPageAd(index, this.landingPages[i].id),
          });
        }
      }

      return affiliateLinksActions;
    },
    updateFunnelResource() {
      this.$emit('updateFunnelResource', this.localFunnelResource);
    },
    handleThankyouPageAdsTabChange(newTab) {
      this.localThankyouPageAdsTab = newTab || this.localThankyouPageAdsTab;
      this.$emit('handleThankyouPageAdsTabChange', this.localThankyouPageAdsTab);
    },
    handleThankyouPageTabOrderUpdate(updatedTabs) {
      const updatedOrder = updatedTabs.map((v) =>
        parseInt(v.value.replace('thankyou_page_ad_tab_', ''))
      );
      this.localFunnelResource.thankyou_page_ads.sort((a, b) => {
        return updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id);
      });
      this.updateFunnelResource();
    },
    createThankYouPageAd() {
      const newAdId = this.generateNumber();
      const newAdCount = this.localFunnelResource.thankyou_page_ads.length + 1;

      this.localFunnelResource.thankyou_page_ads.push({
        id: newAdId,
        name: `${this.$t('frontend_groovesell_tools_affiliate_edit_ad')} ${newAdCount}`,
        body: '',
      });

      this.localThankyouPageAdsTab = `thankyou_page_ad_tab_${newAdId}`;

      this.updateFunnelResource();
      this.handleThankyouPageAdsTabChange();
    },
    openConfirmModal(thankyouPageAdId) {
      this.thankyouPageAdId = thankyouPageAdId;
      this.$root.$emit('modal-open', 'confirm-thankyou-page-delete-modal');
    },
    async deleteThankYouPageAd() {
      this.localFunnelResource.thankyou_page_ads.splice(this.thankyouPageAdId, 1);

      if (this.localFunnelResource.thankyou_page_ads.length > 0) {
        let nextOpenIndex = 0;
        if (this.thankyouPageAdId == 0) {
          nextOpenIndex = 0;
        } else {
          nextOpenIndex = this.thankyouPageAdId - 1;
        }

        const nextOpenId =
          this.localFunnelResource.thankyou_page_ads[nextOpenIndex].id;
        this.localThankyouPageAdsTab = `thankyou_page_ad_tab_${nextOpenId}`;
      } else {
        this.localThankyouPageAdsTab = '';
      }

      this.updateFunnelResource();
      this.handleThankyouPageAdsTabChange();
    },
    addAffiliateNameToThankyouPageAd(index) {
      const range =
        this.$refs[`thankyouPageAdEditor_${index}`][0].quill.getSelection(true);

      if (range) {
        this.$refs[`thankyouPageAdEditor_${index}`][0].quill.insertText(
          range.index,
          '##affiliateName##'
        );
      }
    },
    addAffiliateLinkToThankyouPageAd(index, landingPageId) {
      const range =
        this.$refs[`thankyouPageAdEditor_${index}`][0].quill.getSelection(true);
      if (range) {
        if (range.length == 0) {
          this.$refs[`thankyouPageAdEditor_${index}`][0].quill.insertText(
            range.index,
            `##affiliateLink${landingPageId}##`
          );
        } else {
          this.$refs[`thankyouPageAdEditor_${index}`][0].quill.format(
            'link',
            `##affiliateLink${landingPageId}##`,
            Quill.sources.USER
          );
        }
      }
    },
    isThankYouPageAdTabActive(ad) {
      return this.localThankyouPageAdsTab === `thankyou_page_ad_tab_${ad.id}`;
    },
  },
  mounted() {
    this.$root.$on('trigger-validation', () => {
      this.$v.$touch();
    });
  },
  validations: {
    localFunnelResource: {
      thankyou_page_ads: {
        $each: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          $trackBy: 'id',
        },
      },
    },
  },
};
</script>
