import Vue from 'vue';

export default Vue.extend({
  components: {},
  data() {
    return {
      value: null
    };
  },
  template: `
  <span v-tooltip="{ classes:'custom-double-tooltip', content: this.params?.data?.funnel?.internal_name + '<h6>' + this.params?.data?.funnel?.description + '</h6>', contentHtml: true  }">
        {{this.params.value}}
      </span>
    `
});
