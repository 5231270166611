<template>
  <div class="w-full">
    <info-panel class="my-4 relative info-panel-wrapper-inner w-full">
      <template slot="content">
        <div class="w-full h-full rounded-t-lg relative">
          <gd-tag
            size="sm"
            class="absolute top-2 right-2"
            :variant="funnel.is_live == 1 ? 'success' : 'warning'"
          >
            {{ funnel.is_live == 1 ? 'Live' : 'Draft' }}
          </gd-tag>
          <div
            class="
              show_panel
              absolute
              w-full
              h-full
              rounded-t-lg
              bg-grey-transparent
              opacity-0
              hover:opacity-100
              flex
              items-center
              justify-center
            "
          >
            <gd-button variant="primary" size="auto" @click="sendToDashboard">
              {{ $t('frontend_groovesell_manage_product_funnel') }}
            </gd-button>
          </div>
          <div class="w-full h-52 flex justify-center items-center text-primary py-6">
            <img
              class="w-full h-full object-contain"
              v-if="funnelImage"
              :src="funnelImage"
            />
            <gifts-icon v-else class="w-full h-full fill-current" />
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="w-full text-sm relative">
          <div class="h-full px-4 flex items-center">
            {{ funnel.name }}
            
          </div>

          <div
            class="
              flex
              justify-between
              border-t border-solid border-grey-200
              w-full
              show_panel
              opacity-0
              hover:opacity-100
              absolute
              top-0
              bg-white
              h-full
              p-4
              items-center
              rounded-b-lg
            "
          >
            <div class="flex items-center gap-x-4 md:gap-x-3 text-grey-600">
              <span v-for="(option, index) in menuItemsIcons" :key="index">
                <span
                  class="fill-current cursor-pointer"
                  @click="option.callback()"
                  v-tooltip="option.text"
                >
                  <component :is="option.icon" class="w-5 h-5 md:w-4 md:h-4 lg:w-5 lg:h-5 xl:w-5 xl:h-5" />
                </span>
              </span>
            </div>

            <drop-menu :menu-items="menuItems" dropMenuClass="absolute bottom-0 mt-13 z-40" small></drop-menu>
          </div>
        </div>
      </template>
    </info-panel>
  </div>
</template>

<script>
import GiftsIcon from '@/assets/images/icons/GiftsIcon.svg';
import MegaphoneIcon from '@/assets/images/icons/MegaphoneIcon.svg';
import ToolsIcon from '@/assets/images/icons/ToolsIcon.svg';
import TrophyIcon from '@/assets/images/icons/TrophyIcon.svg';
import HandshakeIcon from '@/assets/images/icons/HandshakeIcon.svg';
import DocumentIcon from '@/assets/images/icons/DocumentIcon.svg';
import GdTag from '@groovepages/gd-ui-kit/components/Tag';

export default {
  name: 'FunnelGridAsset',
  components: {
    GiftsIcon,
    GdTag,
  },
  props: {
    funnel: {},
  },
  computed: {
    funnelImage() {
      let funnelImage = false;

      if (
        this.funnel.main_product &&
        this.funnel.main_product.checkout_page_options.product_image &&
        this.funnel.main_product.checkout_page_options.product_image != ''
      )
        funnelImage =
          this.funnel.main_product.checkout_page_options.product_image;

      return funnelImage;
    },
    menuItemsIcons() {
      const menuItemsIcons = [];
      menuItemsIcons.push({
        icon: GiftsIcon,
        text: this.$t('frontend_funnels_manage_products'),
        callback: () => this.$emit('onManageProducts', this.funnel.id),
      });
      menuItemsIcons.push({
        icon: MegaphoneIcon,
        text: this.$t('frontend_funnels_affiliate_signup_promo_tools'),
        callback: () => this.$emit('onManagePromotools', this.funnel.id),
      });
      menuItemsIcons.push({
        icon: ToolsIcon,
        text: this.$t('forntend_checkout_links'),
        callback: () => this.$router.push({ name: 'productsCheckoutLinks', params: { id: this.funnel.id, product_id: this.funnel.main_product_id } }),
      });
      menuItemsIcons.push({
        icon: TrophyIcon,
        text: this.$t('frontend_leaderboards'),
        callback: () => this.$router.push({ name: 'leader_boards' }),
      });
      menuItemsIcons.push({
        icon: HandshakeIcon,
        text: this.$t('frontend_gduikit_sidebar_manage_affiliates'),
        callback: () => this.$router.push({ name: 'affiliates', query: { funnel_id: this.funnel.id} }),
      });
      menuItemsIcons.push({
        icon: DocumentIcon,
        text: this.$t('frontend_gduikit_sidebar_reports'),
        callback: () => this.$router.push({ name: 'vendor_report', query: { funnel_id: this.funnel.id} }),
      });
      return menuItemsIcons;
    },
    menuItems() {
      const menuItems = [];
      menuItems.push({
        text: this.$t('frontend_funnels_settings'),
        callback: () => this.$emit('onEditFunnel', this.funnel.id),
      });

     
      menuItems.push({
        text: this.$t('frontend_funnels_clone'),
        callback: () => this.$emit('onCloneFunnels', this.funnel.id),
      });
      menuItems.push({
        text: this.$t('frontend_funnels_delete'),
        callback: () => this.$emit('onDeleteFunnels', this.funnel.id),
      });


      return menuItems;
    },
  },
  methods: {
    backgroundImageUrl(url) {
      if (url) {
        return `url("${url}")`;
      }

      return '';
    },
    sendToManageProducts() {
      this.$emit('onManageProducts', this.funnel.id);
    },
    sendToDashboard() {
      this.$router.push({ name: 'funnelDashboard', params: { id: this.funnel.id } });
    },
  },
};
</script>

<style lang="scss">
.bg-grey-transparent {
  background-color: rgba(41, 43, 73, 0.3);
}
.info-panel-wrapper-inner:hover {
  .show_panel {
    opacity: 1;
  }
}
</style>
