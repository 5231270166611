<template>
  <div>
    <div class="w-full">
      <v-wait for="transactions">
        <gd-tabs :tabs="transactionTabs" :current-tab="transactionsTab"
          wrapper-class="px-0 gs-default-tabs" tab-class="pb-2 mr-4 gs-default-tabs-item"
          tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
          line-class="gs-default-tabs-active-line" @onClick="handleTransactionsTabChange" />
        <div>
          <header-filters ref="headerFilters" :filteredFunnels="filteredFunnels"
            :filteredAffiliates="filteredAffiliates" :filteredAdvancedTransactions="filteredAdvancedTransactions"
            :filteredSecondTierAffiliates="filteredSecondTierAffiliates"
            :transactionList="transactionList" :transactionsDateRange="transactionsDateRange" :reloadData="reloadData"
            :openPopUp="openPopUp" :currentTab="transactionsTab"
            :filteredTransactions="transactionsTab == 'captured' ? filteredTransactions : filteredFailedTransactions"
            @filter-changed="handleFiltersChange" @apply-filters="applyFilters" />
          <div v-if="transactionsTab === 'captured'" class="mt-4">
            <div class="mt-2">
              <div id="transactions-list-view" class="mt-4">
                <ag-grid-vue class="ag-theme-material" style="width: 100%" :dom-layout="domLayout"
                  :grid-options="gridOptions" :column-defs="transactionsTableColumnDefs" :pagination="true"
                  :row-height="transactionsTableRowHeight" :row-buffer="rowBuffer" :row-model-type="rowModelType"
                  :datasource="dataSource" :pagination-page-size="paginationPageSize"
                  :cache-overflow-size="cacheOverflowSize" :infinite-initial-row-count="infiniteInitialRowCount"
                  :max-blocks-in-cache="maxBlocksInCache" :cache-block-size="cacheBlockSize"
                  :suppress-row-click-selection="true" :suppress-cell-selection="true" :row-class="rowClass"
                  :row-class-rules="rowClassRules" :context="context" :framework-components="frameworkComponents"
                  :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests" @grid-ready="onGridReady"
                  @column-moved="onCapturedTransactionsColumnsMoved" scrollbarWidth=20></ag-grid-vue>
              </div>
            </div>
          </div>
          <div v-if="transactionsTab === 'failed'" class="mt-4">
            <div class="mt-2">
              <div class="flex flex-row mt-2 w-full"></div>
              <div id="failed-transactions-list-view" class="mt-4">
                <ag-grid-vue class="ag-theme-material" style="width: 100%" :dom-layout="failedTransactionsDomLayout"
                  :grid-options="failedTransactionsGridOptions" :column-defs="failedTransactionsTableColumnDefs"
                  :pagination="true" :row-height="transactionsTableRowHeight" :row-buffer="rowBuffer"
                  :row-model-type="rowModelType" :datasource="failedTransactionsDataSource"
                  :pagination-page-size="paginationPageSize" :cache-overflow-size="cacheOverflowSize"
                  :infinite-initial-row-count="infiniteInitialRowCount" :max-blocks-in-cache="maxBlocksInCache"
                  :cache-block-size="cacheBlockSize" :suppress-row-click-selection="true"
                  :suppress-cell-selection="true" :row-class="failedTransactionsRowClass"
                  :row-class-rules="failedTransactionsRowClassRules" :context="failedTransactionsContext"
                  :framework-components="failedTransactionsFrameworkComponents"
                  :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
                  @grid-ready="onFailedTransactionsGridReady"></ag-grid-vue>
              </div>
            </div>
          </div>
        </div>
        <!-- modals -->
        <advanced-filters-modal :filteredFunnels="filteredFunnels" :funnelsDropdown="funnelsDropdown"
          :filteredAdvancedTransactions="filteredAdvancedTransactions" :filteredAffiliates="filteredAffiliates"
          :filteredSecondTierAffiliates="filteredSecondTierAffiliates"
          @filter-changed="handleFiltersChange" @apply-filters="applyAdvancedFilters" />
        <advanced-failed-filters-modal :filteredFunnels="filteredFunnels" :funnelsDropdown="funnelsDropdown"
          :filteredAffiliates="filteredAffiliates" :affiliatesForFilter="affiliatesForFilter"
          @filter-changed="handleFiltersChange" @apply-filters="applyAdvancedFilters" />
        <transaction-detail-modal :selectedTransaction="selectedTransaction" :highLightTabs="highLightTabs"
          :reloadData="reloadData" :authenticatedUser="authenticatedUser" />
        <change-transaction-affiliate-modal @filter-changed="handleFiltersChange"
          :selectedTransaction="selectedTransaction" :reloadData="reloadData" />
        <change-affiliate-commission-modal @filter-changed="handleFiltersChange"
          :selectedTransaction="selectedTransaction" :reloadData="reloadData" />
        <filters-affiliates-modal :filteredAffiliates="filteredAffiliates" @filter-changed="handleFiltersChange" />
        <filters-second-tier-affiliates-modal :filteredSecondTierAffiliates="filteredSecondTierAffiliates" @filter-changed="handleFiltersChange" />
        <senders-detail-modal @filter-changed="handleFiltersChange" :loggedInUserEmail="loggedInUserEmail"
          :recipientOptions="recipientOptions" :filtersData="filtersData" :recipients="recipients"  :allPagesExport="allPagesExport" :transactionsTab="transactionsTab" />


      </v-wait>
    </div>
    <popup-alert v-if="alertProps.show" :variant="alertProps.variant" @close="alertProps.show = false">
      <template v-slot:title>
        {{ alertProps.title }}
      </template>
      <p>{{ alertProps.message }}</p>
    </popup-alert>
  </div>
</template>


<script>
import { mapState } from 'vuex';

import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Transaction from '@/models/Transaction';
import TransactionFullNameRenderer from '@/renderers/transactionFullNameRenderer';
import UnderlyingTransactionRenderer from '@/renderers/underlyingTransactionRenderer';
import FailedTransactionFullNameRenderer from '@/renderers/failedTransactionFullNameRenderer';
import FunnelNameRenderer from '@/renderers/funnelNameRenderer';
import ProductNameRenderer from '@/renderers/productNameRenderer';
import PricePointNameRenderer from '@/renderers/pricepointNameRenderer';
import createLinkMixin from '@/mixins/createLinkMixin';
import auth from '@groovepages/gd-lib/auth';

import HeaderFilters from './transactions_partials/parts/HeaderFilters';
import AdvancedFiltersModal from './transactions_partials/modals/AdvancedFiltersModal';
import AdvancedFailedFiltersModal from './transactions_partials/modals/AdvancedFailedFiltersModal';
import TransactionDetailModal from './transactions_partials/modals/TransactionDetailModal';
import ChangeTransactionAffiliateModal from './transactions_partials/modals/ChangeTransactionAffiliateModal';
import ChangeAffiliateCommissionModal from './transactions_partials/modals/ChangeAffiliateCommissionModal';
import FiltersAffiliatesModal from './transactions_partials/modals/FiltersAffiliatesModal';
import FiltersSecondTierAffiliatesModal from './transactions_partials/modals/FiltersSecondTierAffiliatesModal';
import SendersDetailModal from './transactions_partials/modals/SendersDetailModal';

import '@/assets/scss/analytics-transactions.scss'


export default {
  middleware: 'auth',
  metaInfo() {
    return { title: 'Transactions' };
  },
  components: {
    AgGridVue,
    HeaderFilters,
    AdvancedFiltersModal,
    AdvancedFailedFiltersModal,
    TransactionDetailModal,
    ChangeTransactionAffiliateModal,
    ChangeAffiliateCommissionModal,
    FiltersAffiliatesModal,
    FiltersSecondTierAffiliatesModal,
    SendersDetailModal,
  },
  mixins: [createLinkMixin],
  data() {
    return {
      alertProps: {
        variant: 'success',
        title: '',
        message: '',
        show: false,
      },
      allPagesExport: false,
      authenticatedUser: null,
      recipientOptions: [],
      recipients: [],
      transactionList: null,
      showCustomField: true,
      transactionsDateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      gridOptions: null,
      failedTransactionsGridOptions: null,
      gridApi: null,
      failedTransactionsGridApi: null,
      columnApi: null,
      failedTransactionsColumnApi: null,
      dataSource: null,
      failedTransactionsDataSource: null,
      transactions: [],
      failedTransactions: [],
      paginationPageSize: 25,
      transactionsTableRowHeight: 40,
      rowBuffer: null,
      rowModelType: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      domLayout: 'autoHeight',
      failedTransactionsDomLayout: 'autoHeight',
      transactionsTableColumnDefs: null,
      failedTransactionsTableColumnDefs: null,
      funnelsDropdown: [],
      filteredFunnels: [],
      filteredAffiliates: [{ id: 0, name: 'All' }],
      filteredSecondTierAffiliates: [{ id: 0, name: 'All' }],
      filteredTransactions: [0],
      filteredFailedTransactions: [0],
      filteredAdvancedTransactions: 0,
      searchPhrase: '',
      excludeFreeTransactions: false,
      affiliatesForFilter: [],
      rowClassRules: null,
      failedTransactionsRowClassRules: null,
      rowClass: 'transactions-row',
      failedTransactionsRowClass: 'transactions-row',
      selectedRowSize: 25,
      context: null,
      failedTransactionsContext: null,
      frameworkComponents: null,
      failedTransactionsFrameworkComponents: null,
      selectedTransaction: null,
      transactionTabs: [
        {
          title: this.$t('frontend_analytics_transactions_successful'),
          value: 'captured',
        },
        {
          title: this.$t(
            'frontend_analytics_transactions_failed_rebill_transactions'
          ),
          value: 'failed',
        },
        {
          title: this.$t('frontend_analytics_transactions_report'),
          value: 'report',
        },
      ],
      transactionsTab: 'captured',
      highLightTabs: [],
      selectedTransactionFunnelAffiliates: [],
      changedAffiliateId: 0,
    };
  },
  computed: {
    ...mapState(['user']),
    loggedInUserEmail() {
      return this.user.email; // Assuming the user object has an 'email' property
    },
    isGdButtonDisabled() {
      return !this.isToggleOn && this.recipientMail.length === 0;
    },
    genericUrl() {
      return window.config.apiUrl.substr(
        0,
        window.config.apiUrl.lastIndexOf('/')
      );
    },
    selectedFilteredAffiliates() {
      return this.filteredAffiliates.map((a) => a.id);
    },
    selectedFilteredSecondTierAffiliates() {
      return this.filteredSecondTierAffiliates.map((a) => a.id);
    },

    selectedTransactionFilters() {
      return this.filteredTransactions;
    },

    selectedFailedTransactionFilters() {
      return this.filteredFailedTransactions;
    },
    filtersData() {
      return {
        filteredFunnels: this.filteredFunnels,
        filteredAffiliates: this.filteredAffiliates,
        filteredSecondTierAffiliates: this.filteredSecondTierAffiliates,
        filteredTransactions: this.filteredTransactions,
        filteredFailedTransactions: this.filteredFailedTransactions,
        searchPhrase: this.searchPhrase,
        excludeFreeTransactions: this.excludeFreeTransactions,
        selectedTransactionFilters: this.selectedTransactionFilters,
        filteredAdvancedTransactions: this.filteredAdvancedTransactions,
        transactionsDateRange: this.transactionsDateRange,
        page: this.gridApi?.paginationGetCurrentPage() + 1,
        limit: this.gridApi?.paginationGetPageSize(),
        showCustomField: this.showCustomField,
        failedPage: this.failedTransactionsGridApi?.paginationGetCurrentPage() + 1,
        failedLimit: this.failedTransactionsGridApi?.paginationGetPageSize()
      }
    }
  },
  async created() {
    const userFunnel = await axios.post('transactions/filters-for-funnel', { funnels: self.filteredFunnels });
    this.funnelsDropdown = userFunnel.data
  },
  async beforeMount() {
    this.authenticatedUser = auth.authenticatedUser();

    this.gridOptions = {};
    this.failedTransactionsGridOptions = {};

    this.context = { componentParent: this };
    this.failedTransactionsContext = { componentParent: this };

    this.frameworkComponents = {
      transactionFullNameRenderer: TransactionFullNameRenderer,
      underlyingTransactionRenderer: UnderlyingTransactionRenderer,
      funnelNameRenderer: FunnelNameRenderer,
      productNameRenderer: ProductNameRenderer,
      pricepointNameRenderer: PricePointNameRenderer,
    };
    this.failedTransactionsFrameworkComponents = {
      transactionFullNameRenderer: FailedTransactionFullNameRenderer,
      underlyingTransactionRenderer: UnderlyingTransactionRenderer,
      funnelNameRenderer: FunnelNameRenderer,
      productNameRenderer: ProductNameRenderer,
      pricepointNameRenderer: PricePointNameRenderer,
    };

    this.transactionsTableColumnDefs = [
      {
        headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_customer_name'),
            field: 'rendered_buyer_fullname',
            cellRenderer: 'transactionFullNameRenderer',
            colId: 'buyerFullName',
            width: 300,
            pinned: 'left',
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_product_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_funnel_name'),
            field: 'funnel_name',
            cellRenderer: 'funnelNameRenderer',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_product_name'),
            field: 'product_name',
            cellRenderer: 'productNameRenderer',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_price_point'),
            field: 'price_point',
            cellRenderer: 'pricepointNameRenderer',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_price'),
            field: 'human_readable_price',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_date_and_time'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_date_time'
            ),
            field: 'formatted_date_time',
            resizable: true,
            minWidth: 250,
          },
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_days_since_free_signup'
            ),
            field: 'days_since_free_signup',
            resizable: true,
            minWidth: 250,
            hide: this.authenticatedUser.id !== '5daeb623b256c50e25993cc6',
          },
        ],
      },
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_buyer_details'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Email',
            field: 'rendered_buyer_email',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_phone'),
            field: 'rendered_phone',
            resizable: true,
          },
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_company'
            ),
            field: 'rendered_buyer_company',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_address'),
            field: 'rendered_buyer_address',
            resizable: true,
          },
          { headerName: this.$t('frontend_groovesell_transaction_table_country'), field: 'buyer_country', resizable: true },
          { headerName: this.$t('frontend_groovesell_transaction_table_state'), field: 'buyer_state', resizable: true },
          { headerName: 'IP', field: 'buyer_ip', resizable: true },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_assited_details'),
        headerClass: 'transaction-details-header',
        children: [

          {
            headerName: this.$t('frontend_groovesell_transaction_table_assited_affiliate_name'),
            field: 'assisted_affiliate_name',
            resizable: true,
          },

        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_details'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_username'),
            field: 'rendered_affiliate_username',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_name'),
            field: 'rendered_affiliate_name',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_email'),
            field: 'rendered_affiliate_email',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_comission'),
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_commission &&
                params.data.human_readable_commission != ''
              ) {
                return params.data.human_readable_commission;
              }
              return '';
            },
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_comission_type'),
            field: 'commission_type',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_username'),
            field: 'rendered_second_tier_affiliate_username',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_name'),
            field: 'rendered_second_tier_affiliate_name',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_email'),
            field: 'rendered_second_tier_affiliate_email',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_comission'),
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_second_tier_commission &&
                params.data.human_readable_second_tier_commission != ''
              ) {
                return params.data.human_readable_second_tier_commission;
              }
              return '';
            },
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_jv_comission_type'),
            field: 'second_tier_commission_type',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_processor_details'),
        headerClass: 'transaction-details-header',
        children: [
          { headerName: 'Gateway', field: 'gateway', resizable: true },
          { headerName: this.$t('frontend_groovesell_transaction_table_affiliate_processor_account'), field: 'gateway_name', resizable: true },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_processor_id_internal'),
            field: 'transaction_id',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_processor_id_gateway'),
            field: 'processor_transaction_id',
            resizable: true,
          },
          { headerName: this.$t('frontend_groovesell_transaction_table_affiliate_processor_type'), field: 'transaction_type', resizable: true },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_refound_cancelled_transaction'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_refound_cancelled_transaction_id'),
            field: 'underlying_transaction',
            cellRenderer: 'underlyingTransactionRenderer',
            colId: 'underlyingTransactionId',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_refound_cancelled_transaction_subscription'),
            field: 'underlying_transaction_days',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t('frontend_groovesell_transaction_table_affiliate_your_profit'),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t('frontend_groovesell_transaction_table_affiliate_your_profit'),
            field: 'human_readable_profit',
            resizable: true,
          },
        ],
      },
    ];


    this.failedTransactionsTableColumnDefs = [
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_customer_name'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_customer_name'
            ),
            field: 'rendered_buyer_fullname',
            cellRenderer: 'transactionFullNameRenderer',
            colId: 'buyerFullName',
            width: 300,
            pinned: 'left',
          },
        ],
      },
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_product_details'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_funnel_name'
            ),
            field: 'funnel_name',
            cellRenderer: 'funnelNameRenderer',
            resizable: true,
          },
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_product_name'
            ),
            field: 'product_name',
            cellRenderer: 'productNameRenderer',
            resizable: true,
          },
          {
            headerName: 'Price Point',
            field: 'price_point',
            cellRenderer: 'pricepointNameRenderer',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_price'),
            field: 'human_readable_price',
            resizable: true,
          },
        ],
      },
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_date_and_time'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_date_time'
            ),
            field: 'formatted_date_time',
            resizable: true,
            minWidth: 250,
          },
        ],
      },
      {
        headerName: this.$t(
          'frontend_groovesell_transaction_table_buyer_details'
        ),
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Email',
            field: 'rendered_buyer_email',
            resizable: true,
          },
          {
            headerName: this.$t('frontend_groovesell_transaction_table_phone'),
            field: 'rendered_phone',
            resizable: true,
          },
          {
            headerName: this.$t(
              'frontend_groovesell_transaction_table_company'
            ),
            field: 'rendered_buyer_company',
            resizable: true,
          },
          {
            headerName: 'Address',
            field: 'rendered_buyer_address',
            resizable: true,
          },
          { headerName: 'Country', field: 'buyer_country', resizable: true },
          { headerName: 'State', field: 'buyer_state', resizable: true },
          { headerName: 'IP', field: 'buyer_ip', resizable: true },
        ],
      },
      {
        headerName: 'Affiliate Details',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Username',
            field: 'affiliate_username',
            resizable: true,
          },
          { headerName: 'Name', field: 'affiliate_name', resizable: true },
          { headerName: 'Email', field: 'affiliate_email', resizable: true },
          {
            headerName: 'Commission',
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_commission &&
                params.data.human_readable_commission != ''
              ) {
                return params.data.human_readable_commission;
              }
              return '';
            },
            resizable: true,
          },
          {
            headerName: 'Commission Type',
            field: 'commission_type',
            resizable: true,
          },
        ],
      },
      {
        headerName: 'JV Broker Details',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'JV Broker Username',
            field: 'rendered_second_tier_affiliate_username',
            resizable: true,
          },
          {
            headerName: 'JV Broker Name',
            field: 'rendered_second_tier_affiliate_name',
            resizable: true,
          },
          {
            headerName: 'JV Broker Email',
            field: 'rendered_second_tier_affiliate_email',
            resizable: true,
          },
          {
            headerName: 'JV Broker Commission',
            valueGetter(params) {
              if (
                params.data &&
                params.data.human_readable_second_tier_commission &&
                params.data.human_readable_second_tier_commission != ''
              ) {
                return params.data.human_readable_second_tier_commission;
              }
              return '';
            },
            resizable: true,
          },
          {
            headerName: 'JV Broker Commission Type',
            field: 'second_tier_commission_type',
            resizable: true,
          },
        ],
      },
      {
        headerName: 'Processor Details',
        headerClass: 'transaction-details-header',
        children: [
          { headerName: 'Gateway', field: 'gateway', resizable: true },
          { headerName: 'Account', field: 'gateway_name', resizable: true },
          {
            headerName: 'Transaction Id (Internal)',
            field: 'transaction_id',
            resizable: true,
          },
          {
            headerName: 'Transaction Id (Gateway)',
            field: 'processor_transaction_id',
            resizable: true,
          },
          { headerName: 'Type', field: 'transaction_type', resizable: true },
        ],
      },
      {
        headerName: 'Refunded/Cancelled Transaction Detail',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Refund/Cancelled Transaction Id',
            field: 'underlying_transaction',
            cellRenderer: 'underlyingTransactionRenderer',
            colId: 'underlyingTransactionId',
            resizable: true,
          },
          {
            headerName: 'Subscription Length (In Days)',
            field: 'underlying_transaction_days',
            resizable: true,
          },
        ],
      },
      {
        headerName: 'Your Profit',
        headerClass: 'transaction-details-header',
        children: [
          {
            headerName: 'Your Profit',
            field: 'human_readable_profit',
            resizable: true,
          },
        ],
      },
    ];

    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.paginationPageSize = 25;
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 2;

    this.rowClassRules = {
      'fraud-transaction-row': 'data && data.suspected_affiliate_fraud == 1',
      'refunded-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Refund'",
      'initial-payment-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Initial Payment'",
      'cancellation-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Cancellation'",
      'sale-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Sale'",
      'chargeback-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Chargeback'",
      'rebill-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Rebill'",
      'free-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Free Transaction'",
    };

    this.failedTransactionsRowClassRules = {
      'fraud-transaction-row': 'data && data.suspected_affiliate_fraud == 1',
      'refunded-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Refund'",
      'initial-payment-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Initial Payment'",
      'cancellation-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Cancellation'",
      'sale-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Sale'",
      'chargeback-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Chargeback'",
      'rebill-transaction-row': "data && data.suspected_affiliate_fraud != 1 && data.transaction_type && data.transaction_type == 'Rebill'",
    };
  },
  methods: {
    handleFiltersChange(filters) {
      const { key, values, value } = filters;

      if (key === 'clear') {
        values.forEach(({ key, value }) => {
          if (this[key] !== undefined) this[key] = value;
        });
      } else {
        if (this[key] !== undefined) this[key] = value;
      }
    },
    // Below data is for showing the custom data in childrens column
    // add the custom fields when toggle is true
    addCustomFieldColumn() {
      const data = this.gridOptions.api.getDisplayedRowAtIndex(0)?.data;
      if (!data?.checkout_page_options?.form_fields) return;
      const formFields = data.checkout_page_options.form_fields;
      const customFields = formFields.filter((field) => field.type === 'custom');

      const parentColumn = {
        headerName: this.$t('frontend_groovesell_transaction_table_custom_field'),
        headerClass: 'transaction-details-header',
        children: [],
      };

      const childColumns = customFields.map((customField) => ({
        headerName: customField.label,
        field: `customFieldValues.${customField.id}`,
        resizable: true,
      }));

      parentColumn.children = childColumns;


      this.transactionsTableColumnDefs.push(parentColumn);
      this.gridOptions.api.setColumnDefs(this.transactionsTableColumnDefs);

    },
    // remove the custom fields when togle is false
    removeCustomFieldColumn() {
      const newColumnDefs = this.transactionsTableColumnDefs.filter((column) => {
        if (column.headerName === this.$t('frontend_groovesell_transaction_table_custom_field')) {
          return false; // Exclude the parent column and its children
        }
        return true; // Keep all other columns
      });

      this.transactionsTableColumnDefs = newColumnDefs;
      // this.gridOptions.api.setColumnDefs(newColumnDefs);
      this.gridOptions.api.setColumnDefs(this.transactionsTableColumnDefs);
    },
    onCapturedTransactionsColumnsMoved() { },
    async openTransactionDetail(transactionId) {
      this.$loader.start('openTransactionDetail');
      this.selectedTransaction = await Transaction.$find(transactionId);
      this.highLightTabs = [];

      if (this.selectedTransaction.suspected_affiliate_fraud == 1) {
        this.highLightTabs.push('affiliate');
      }

      this.$root.$emit('modal-open', 'transaction-detail-modal');
      this.$loader.end('openTransactionDetail');
    },
    handleTransactionsTabChange(newTab) {
      this.$refs.headerFilters.clearFilters();
      if (newTab == 'report') {
        this.$router.push({
          name: 'vendor_report',
          query: {
            funnels: JSON.stringify(this.filteredFunnels),
            affFilterType: this.filteredAffiliatesType,
            affiliates: JSON.stringify(this.selectedFilteredAffiliates),
            fromDate: moment(this.transactionsDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(this.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          },
        });
      } else {
        this.transactionsTab = newTab;
      }
    },
    reloadData() {
      this.applyFilters();
    },
    async fetchRecipients() {
      try {
        this.$loader.start('fetchRecipients');
        if (!this.currentAccount) {
          const mailAppUrl = this.whitelabelAppData('mail').url;
          const response = await axios.get(`${this.genericUrl}${mailAppUrl}recipient`);
          this.recipients = response?.data?.data?.map((item) => item.email);
          this.recipientOptions = this.recipients.map((email) => ({ id: email, label: email }));
        }
        this.$loader.end('fetchRecipients');
      } catch (error) {
        console.error(error);
        this.$loader.end('fetchRecipients');
      }
    },
    async openPopUp(exportAll = false) {
      this.allPagesExport = exportAll;
      await this.fetchRecipients();
      this.$root.$emit('modal-open', 'senders-detail-modal');
    },
    applyAdvancedFilters() {
      this.$refs.headerFilters.applyFilters();
    },
    applyFilters() {
      if (this.transactionsTab == 'captured') {
        this.gridApi.paginationGoToPage(0);
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
        this.gridApi.infiniteRowModel.resetCache();
        this.gridApi.paginationSetPageSize(Number(this.selectedRowSize));
      } else {
        this.failedTransactionsGridApi.paginationGoToPage(0);
        this.failedTransactionsGridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.selectedRowSize);
        this.failedTransactionsGridApi.infiniteRowModel.resetCache();
        this.failedTransactionsGridApi.paginationSetPageSize(
          Number(this.selectedRowSize)
        );
      }
    },
    async onFailedTransactionsGridReady() {
      this.failedTransactionsGridApi = this.failedTransactionsGridOptions.api;
      this.failedTransactionsColumnApi =
        this.failedTransactionsGridOptions.columnApi;
      this.failedTransactionsDataSource =
        await this.getFailedTransactionsDataSource(
          Number(this.selectedRowSize)
        );
    },
    async onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      if (this.$route.query.affiliate_id && this.$route.query.affiliate_id !== undefined) {
        const { data } = await axios.get(`transactions/fetch-affiliate-name/${this.$route.query.affiliate_id}`);
        if (data.status == 'success') {
          this.filteredAffiliates = [];
          this.filteredAffiliates.push({
            id: this.$route.query.affiliate_id,
            name: data.name,
          });
        }
      }

      if (this.$route.query.second_tier_affiliate_id && this.$route.query.second_tier_affiliate_id !== undefined) {
        const { data } = await axios.get(`transactions/fetch-affiliate-name/${this.$route.query.second_tier_affiliate_id}`);
        if (data.status == 'success') {
          this.filteredSecondTierAffiliates = [];
          this.filteredSecondTierAffiliates.push({
            id: this.$route.query.second_tier_affiliate_id,
            name: data.name,
          });
        }
      }

      if (this.$route.query.date_range && this.$route.query.date_range == 'all-time') {
        this.transactionsDateRange = {
          startDate: moment('20191201', 'YYYYMMDD'),
          endDate: moment(),
        };
      }

      if (this.$route.query.transaction_type && this.$route.query.transaction_type == 'all') {
        this.filteredTransactions = [0];
      } else if (this.$route.query.transaction_type && this.$route.query.transaction_type == 'refund') {
        this.filteredTransactions = [5];
      }
      this.dataSource = await this.getDataSource(Number(this.selectedRowSize));
      this.addCustomFieldColumn();
    },
    getDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getDataSource');

        const transactionsData = await Transaction.page(
          self.gridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.gridApi.paginationGetPageSize())
          .params({
            funnels: self.filteredFunnels,
            transactions: self.selectedTransactionFilters,
            advancedTransactions: self.filteredAdvancedTransactions,
            search: self.searchPhrase,
            excludeFreeTransactions: self.excludeFreeTransactions,
            affiliates: self.filteredAffiliates,
            secondTierAffiliates: self.filteredSecondTierAffiliates,
            fromDate: moment(self.transactionsDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(self.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          }).get();

        let rowsThisPage = [];
        rowsThisPage = transactionsData.data;
        rowsThisPage.forEach(transaction => {
          let correspondingFunnelKey = self.funnelsDropdown.findIndex(
            (x) => x.id == 'f_' + transaction.funnel_id
          );
          if (correspondingFunnelKey != -1) {
            let tid = transaction.product != null ? transaction.product.id : 0;
            let correspondingProductKey = self.funnelsDropdown[correspondingFunnelKey]['children'].findIndex(
              (p) => p.id == 'pr_' + tid
            );
            if (correspondingProductKey != -1) {
              let correspondingPricePointKey = self.funnelsDropdown[correspondingFunnelKey]['children'][correspondingProductKey]['children'].findIndex(
                (pp) => pp.id == 'pp_' + transaction.price_point_id
              )
              if (correspondingPricePointKey != -1) {
                transaction.price_point = Number(correspondingPricePointKey + 1) + '-' + transaction.price_point_name;
              }
            }

          }
        });
        const totalNumberOfRows = transactionsData.meta.total;
        self.affiliatesForFilter = [];
        self.affiliatesForFilter.push({ id: 0, name: self.$t('frontend_analytics_all') });
        self.affiliatesForFilter.push({ id: -2, name: self.$t('frontend_analytics_only_affiliates') });
        self.affiliatesForFilter.push({ id: -1, name: self.$t('frontend_analytics_only_non_affiliates') });
        self.affiliatesForFilter = self.affiliatesForFilter.concat(transactionsData.meta.affiliates);
        self.transactionList = transactionsData;
        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
    arrayColumn(array, column) {
      return array.map(item => item[column]);
    },
    getFailedTransactionsDataSource() {
      const self = this;
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }

      MyDatasource.prototype.getRows = async function (params) {
        self.$loader.start('getFailedTransactionsDataSource');

        const transactionsData = await Transaction.page(
          self.failedTransactionsGridApi.paginationGetCurrentPage() + 1
        )
          .limit(self.failedTransactionsGridApi.paginationGetPageSize())
          .params({
            funnels: self.filteredFunnels,
            transactions: self.selectedFailedTransactionFilters,
            search: self.searchPhrase,
            affiliates: self.filteredAffiliates,
            secondTierAffiliates: self.filteredSecondTierAffiliates,
            fromDate: moment(self.transactionsDateRange.startDate).format('MM/DD/YYYY'),
            toDate: moment(self.transactionsDateRange.endDate).format('MM/DD/YYYY'),
          })
          .custom('transactions/failed-transactions')
          .get();

        let rowsThisPage = [];
        rowsThisPage = transactionsData.data;
        const totalNumberOfRows = transactionsData.meta.total;

        setTimeout(() => {
          params.successCallback(rowsThisPage, totalNumberOfRows);
          self.$loader.end('getFailedTransactionsDataSource');
        }, 100);
      };

      return new MyDatasource(Number(self.selectedRowSize));
    },
  },
};
</script>
